import React from 'react';
import { AddToReportBtnUbo } from '@reusable/Buttons/redux/AddToReportBtn.containers';
import { FormattedMessage, injectIntl } from 'react-intl';
import { CATEGORY_NAMES } from '@constants';
import formatRichMessage from '@utils/formatRichMessage';

const CustomNoResultsDocumentItem = (props) => {
    const isEsgArticleType = props.articleType === CATEGORY_NAMES.ESG_RATINGS;
    let article = props.article;

    const contentType = formatRichMessage(
        { id: `General.categoryName.label.${isEsgArticleType ? props.articleType : 'ubo'}` },
        props.intl
    );
    const noDocText = formatRichMessage({ id: 'NoResultsDocumentItem.noDocuments' }, props.intl, { contentType });

    return (
        <div className="no-results-0 notranslate">
            <div className="no-documents-row">
                <div className="no-documents-title">
                    <span dangerouslySetInnerHTML={{ __html: noDocText }} />
                </div>
                <div className="no-documents-button">
                    <AddToReportBtnUbo article={article} articleType={props.articleType} />
                </div>
            </div>
            <div className="horizontal-divider" />
            <div className="no-documents-search-terms">
                <span className="search-terms">
                    <FormattedMessage id={'NoResultsDocumentItem.searchTerm'} />
                </span>
                <span>{props.title}</span>
            </div>
            <div className="no-documents-source">
                <span className="source-highlight">
                    <FormattedMessage id={'NoResultsDocumentItem.documentSource'} />{' '}
                </span>
                <span>
                    <FormattedMessage id={`${isEsgArticleType ? article.source : 'UboNoDocuments.Source'}`} />
                </span>
            </div>
        </div>
    );
};

export default injectIntl(CustomNoResultsDocumentItem);
