import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import {
    CATEGORIES,
    CATEGORY_NAMES,
    CATEGORY_SORT_FUNC,
    CATEGORIES_EXCLUDED_FROM_PERSON_SEARCH,
    QUERY_SEARCH_TYPES,
    CATEGORIES_EXCLUDED_FROM_ALERT_CONTENT_SELECTION,
    EDIT_SUBCATEGORIES_DISPLAY_MODE,
} from '@constants';
import categoryUtils from '@utils/categoryUtils';
import SourcesComponent from '@UserPreferences/components/SourcesComponent';
import {
    isSelectAllChecked,
    isValidAlertsCategory,
} from '@pages/EntityView/components/EntityViewGridTable/helpers/batchHelpers';

const ModalAlertContentSelection = ({
    isVisible,
    lawSources,
    contentTypes,
    selectAllSetting,
    updateContentTypes,
    updateContentLanguages,
    selectedSearchQueryTypes,
    label = 'Alerts.createAlert.contentSources',
}) => {
    const [sources, setSources] = useState([]);
    const displayContentSourcesWarning =
        !!sources.length && sources?.every(({ checked }) => !checked) && sources?.some(({ disabled }) => !disabled);

    // Exclude law sources, UBO and ESG Ratings and public records
    const isExcludedSource = ({ name }) =>
        !lawSources.includes(name) && CATEGORIES_EXCLUDED_FROM_ALERT_CONTENT_SELECTION.indexOf(name) === -1;

    const shouldEnableCategory = (name) => {
        // If the searchQueryType for any of the selected entities is "company" or if all entities are selected, display all categories
        // If only a "person" entity is selected, only display the categories relevant to that search type
        const enableAllCategories = selectedSearchQueryTypes.includes(QUERY_SEARCH_TYPES.SEARCH_FOR_COMPANY);
        if (enableAllCategories || isSelectAllChecked(selectAllSetting)) return true;

        return !CATEGORIES_EXCLUDED_FROM_PERSON_SEARCH.includes(name);
    };

    useEffect(() => {
        if (!isVisible) return;

        const defaultSources = contentTypes
            .filter(isExcludedSource)
            .map(({ name, value }) => {
                const children = categoryUtils.getChildrenForAlertSource(name, selectedSearchQueryTypes);
                const isCategoryEnabled = value && shouldEnableCategory(name) && isValidAlertsCategory(name, children);

                return {
                    key: name,
                    show: true,
                    checked: isCategoryEnabled,
                    disabled: !isCategoryEnabled,
                    order: CATEGORIES[name]['order'],
                    children,
                };
            })
            .sort(CATEGORY_SORT_FUNC);

        setSources(defaultSources);
    }, [isVisible, contentTypes, selectedSearchQueryTypes]);

    useEffect(() => {
        isVisible && updateContentTypes && updateContentTypes(sources);
    }, [sources]);

    const handleSourceSelection = (checked, key) => {
        const updatedSources = sources.map((source) => ({
            ...source,
            checked: source.key === key ? checked : source.checked,
            children:
                source.key === key && categoryUtils.isParent(key)
                    ? categoryUtils.getChildrenForAlertSource(key, selectedSearchQueryTypes, checked)
                    : source.children,
        }));

        setSources(updatedSources);
    };

    const handleSubSourcesSelection = (parentCategory, subSources) => {
        if (parentCategory === CATEGORY_NAMES.NEGATIVE_NEWS) handleSelectContentLanguages(subSources);

        const updatedSources = sources.map((source) =>
            source.key === parentCategory
                ? { ...source, checked: subSources.some(({ checked }) => checked), children: subSources }
                : source
        );

        setSources(updatedSources);
    };

    const handleSelectContentLanguages = (subSources) => {
        const selectedLanguages = subSources.filter(({ checked }) => checked).map(({ name }) => name);
        updateContentLanguages(selectedLanguages);
    };

    return (
        <>
            {isVisible && (
                <div className="popup-modal__contentSources">
                    <div className="popup-field-name">
                        <FormattedMessage id={label} />:
                    </div>
                    {displayContentSourcesWarning && (
                        <p className="contentSources-warning">
                            <FormattedMessage id="Alerts.createAlert.contentSources.warning" />
                        </p>
                    )}
                    <p data-testid="createAlert-contentSources-warning">
                        <FormattedMessage id="Alerts.createAlert.contentSources.preferencesWarning" />
                    </p>
                    <SourcesComponent
                        sources={sources}
                        contentTypes={contentTypes}
                        handleSourceSelection={handleSourceSelection}
                        handleSubSourcesSelection={handleSubSourcesSelection}
                        editSubcategoriesDisplayMode={EDIT_SUBCATEGORIES_DISPLAY_MODE.DROPDOWN}
                    />
                </div>
            )}
        </>
    );
};

export default ModalAlertContentSelection;
