import AddToReportBtn from '@reusable/Buttons/AddToReportBtn';
import currentReportActions from '@pages/ReportBuilder/redux/CurrentReport.actions';
import { connect } from 'react-redux';
import { withMIPUserHideView } from '@utils/contexts';
import categoryUtils from '@utils/categoryUtils';
import { UBO_MAIN_CATEGORY } from '@constants';
const mapStateToProps = (state, ownProps) => {
    const isAdded =
        state.currentReport.articlesSnippets &&
        !!state.currentReport.articlesSnippets.find((reportSnippet) => {
            // search for article to check if it is added (title is added in order to compare the 0 results, as they have the id -1
            if (ownProps.article.id === '-1') {
                return reportSnippet.title === ownProps.article.title;
            } else {
                return reportSnippet.documentId === ownProps.article.id;
            }
        });

    const isLoading =
        state.articlesManager.uploadingDocuments[ownProps.articleType] &&
        state.articlesManager.uploadingDocuments[ownProps.articleType].indexOf(ownProps.article.id) > -1;

    const isSearchComplete = state.searchStatus.isSearchComplete;
    const isReadOnly = !isSearchComplete;

    return { ...ownProps, isAdded, isLoading, readonly: isReadOnly, language: state.user.preferences.language };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
    onToggle: (article, isAdding) =>
        dispatch(
            isAdding
                ? currentReportActions.addArticlesToReportThunk({
                      articles: [article],
                      articleType: categoryUtils.getParent(ownProps.articleType) || ownProps.articleType,
                      originalArticleType: ownProps.articleType,
                  })
                : currentReportActions.removeArticleFromReportThunk(article, ownProps.articleType)
        ),
});

const mapUboDispatchToProps = (dispatch, ownProps) => ({
    onToggle: (article, isAdding) =>
        dispatch(
            isAdding
                ? currentReportActions.addArticlesToReportThunk({
                      articles: [article],
                      articleType: ownProps.articleType,
                      originalArticleType: ownProps.articleType,
                      postFilters: { category: UBO_MAIN_CATEGORY, uboDetails: { subcategory: 'Specific companies' } },
                  })
                : currentReportActions.removeArticleFromReportThunk(article, ownProps.articleType)
        ),
});

export const AddToReportBtnElastic = withMIPUserHideView(connect(mapStateToProps, mapDispatchToProps)(AddToReportBtn));

export const AddToReportBtnUbo = withMIPUserHideView(connect(mapStateToProps, mapUboDispatchToProps)(AddToReportBtn));
