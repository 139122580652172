import React from 'react';

const Snackbar = ({icon = null, message = null, onClose, id}) => {
    return(
        <div className="snackbar" data-testid={id}>
            <div className='snackbar-content' data-testid='snackbar-content'>
                {message ?? (<></>)}
            </div>
            <div className='snackbar-icon-container' data-testid='snackbar-icon-container'>
                {icon ?? (<></>)}
            </div>
            {onClose && (
                <div className='snackbar-close-icon-container' data-testid='snackbar-close' onClick={onClose}>
                   <div className='icon'>
                    <div className='la-CloseRemove' data-testid='snackbar-close-icon'/>
                   </div>
                </div>
            )}
        </div>
    )
};

export default Snackbar;