import React from 'react';
import { FormattedMessage } from 'react-intl';
import DraggableCategory from '@UserPreferences/components/DraggableCategory';
import { isEqual } from 'lodash';
import { UPDATE_CATEGORIES_ORDER } from '@ReportBuilder/ReportBuilderService';
import { withDragAndDrop } from 'scripts/reusable/HOC/withDragAndDrop';
import { DRAG_AND_DROP } from '@constants';

class CategoriesOrder extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            hoverIndex: null,
            currentIndex: null,
            droppedCategoryName: null,
            orderedCategoriesList: [],
            originalCategoriesOrder: [],
            visibleMenu: null,
        };
        this.updateVisualDropTarget = this.updateVisualDropTarget.bind(this);
        this.reorderCategories = this.reorderCategories.bind(this);

        this.setCategoriesOrderForReport = this.setCategoriesOrderForReport.bind(this);
        this.cancelCategoriesOrder = this.cancelCategoriesOrder.bind(this);
        this.saveCategoriesOrder = this.saveCategoriesOrder.bind(this);
        this.generateCategoriesListForReorder = this.generateCategoriesListForReorder.bind(this);
    }

    componentDidMount() {
        this.generateCategoriesListForReorder();
    }

    componentWillUnmount() {
        clearInterval(this.intervalId);
        this.props.closeOrderCategoriesSection();
    }

    generateCategoriesListForReorder() {
        if (!this.props.report || !this.props.report.researchSummary || !this.props.report.categories) return;
        let report = this.props.report;

        let orderedCategories = report.categories.map((cat) => {
            return {
                categoryName: cat.categoryName,
                show: cat.show,
            };
        });

        this.setState({
            orderedCategoriesList: orderedCategories,
            originalCategoriesOrder: orderedCategories,
        });
    }

    setCategoriesOrderForReport(newOrder) {
        this.setState({
            orderedCategoriesList: newOrder,
        });
    }

    cancelCategoriesOrder() {
        this.props.closeOrderCategoriesSection();
    }

    async saveCategoriesOrder() {
        const { orderedCategoriesList, originalCategoriesOrder } = this.state;

        if (!isEqual(orderedCategoriesList, originalCategoriesOrder)) {
            const { report, updateReportProperty } = this.props;
            await updateReportProperty({
                reportId: report.id,
                reportProperty: UPDATE_CATEGORIES_ORDER,
                propertyValue: orderedCategoriesList,
                report,
            });
        }
        this.cancelCategoriesOrder();
    }

    updateVisualDropTarget(currentIndex, hoverIndex) {
        let prevHoverIndex = this.state.hoverIndex;
        if ((hoverIndex === undefined || currentIndex === hoverIndex) && prevHoverIndex !== null) {
            this.setState({
                currentIndex: null,
                hoverIndex: null,
            });
        }
        if (prevHoverIndex !== hoverIndex && Math.abs(currentIndex - hoverIndex)) {
            this.setState({
                currentIndex,
                hoverIndex,
            });
        }
    }

    setActiveMenu = (id) => {
        this.setState({ visibleMenu: id });
    };

    reorderCategories(categories, currentIndex, hoverIndex, droppedCategoryName) {
        let newCategories = categories.slice();

        while (currentIndex < 0) {
            currentIndex += newCategories.length;
        }
        while (hoverIndex < 0) {
            hoverIndex += newCategories.length;
        }
        if (hoverIndex >= newCategories.length) {
            let k = hoverIndex - newCategories.length;
            while (k-- + 1) {
                newCategories.push(undefined);
            }
        }

        newCategories.splice(hoverIndex, 0, newCategories.splice(currentIndex, 1)[0]);
        this.setCategoriesOrderForReport(newCategories);

        this.setState({
            droppedCategoryName,
            hoverIndex: null,
        });

        clearInterval(this.intervalId);

        this.intervalId = setTimeout(() => {
            this.setState({
                droppedCategoryName: null,
            });
        }, 2500);
    }

    requestReorder = (index, hoverIndex, currentElement) => {
        const categories = this.state.orderedCategoriesList.filter((category) => category.show) || [];
        this.reorderCategories(categories, index, hoverIndex, currentElement.categoryName);
    };

    render() {
        const categories = this.state.orderedCategoriesList.filter((category) => category.show) || [];

        const { connectDropTarget } = this.props;
        const { hoverIndex, currentIndex, droppedCategoryName, visibleMenu } = this.state;

        return connectDropTarget(
            <div className="report-list-categories-container">
                <span className="report-list-categories-container_title">
                    <FormattedMessage id="UserPreferences_topic_report_builder_explanation" />
                </span>

                <div className="report-list-categories-container_list">
                    {categories.map((category, index) => (
                        <DraggableCategory
                            key={index}
                            name={category.categoryName}
                            index={index || 0}
                            categories={categories}
                            droppedCategoryName={droppedCategoryName}
                            showDropTarget={hoverIndex === (index || 0)}
                            insertAbove={hoverIndex < currentIndex}
                            updateVisualDropTarget={this.updateVisualDropTarget}
                            requestReorder={this.requestReorder}
                            setCategoriesOrderForReport={this.setCategoriesOrderForReport}
                            setActiveMenu={this.setActiveMenu}
                            visibleMenu={visibleMenu}
                        />
                    ))}
                </div>
                <div className="report-list-categories-container_buttons">
                    <button className="button-primary-sm" onClick={this.saveCategoriesOrder}>
                        <FormattedMessage id="General_CoreFunctionality_UIText_general.save" />
                    </button>
                    <button className="button-secondary-sm" onClick={this.cancelCategoriesOrder}>
                        <FormattedMessage id="General_CoreFunctionality_UIText_general.cancel" />
                    </button>
                </div>
            </div>
        );
    }
}

const dndHookInfo = {
    componentType: 'parent',
    accept: DRAG_AND_DROP.CATEGORY,
};

export default withDragAndDrop(dndHookInfo)(CategoriesOrder);

export { CategoriesOrder as TestCategoriesOrder };
