import update from 'immutability-helper';
import {
    UBO_SEARCH_INIT,
    UBO_CLEAR,
    UBO_SEARCH_SUCCESS,
    UBO_UPDATE_QUERY,
    UBO_ITEM_ADD,
    UBO_ITEM_REMOVE,
    UBO_ERROR,
    UBO_UPDATE_SELECTED_TERM,
    UBO_CLEAR_SUGGESTIONS,
    UBO_ADD_MEMO,
    UBO_SUBMIT,
    UBO_UPDATE_SORTING_AND_FILTERING,
    UBO_UPDATE_FILTERING,
    UBO_SET_POST_FILTERS,
    UBO_UPDATE_CATEGORY_POST_FILTERS,
} from './Ubo.actions';

function uboReducers(state = {}, action) {
    switch (action.type) {
        case UBO_SEARCH_INIT:
            return update(state, {
                company: {
                    isLoading: { $set: true },
                    query: { $set: action.payload.query },
                    list: { $set: [] },
                    searchTerm: { $set: action.payload.searchTerm },
                },
            });
        case UBO_ITEM_ADD:
            return update(state, {
                company: {
                    selected: { $push: [action.payload.item] },
                },
            });
        case UBO_ITEM_REMOVE:
            const position = action.payload.index;
            return update(state, {
                company: {
                    selected: { $splice: [[position, 1]] },
                },
            });
        case UBO_SEARCH_SUCCESS:
            return update(state, {
                company: {
                    isLoading: { $set: false },
                    list: { $set: action.payload },
                    count: { $set: action.payload.length },
                },
            });
        case UBO_CLEAR:
            return update(state, {
                company: {
                    isLoading: { $set: false },
                    query: { $set: '' },
                    count: { $set: 0 },
                    list: { $set: [] },
                    selected: { $set: [] },
                },
            });
        case UBO_ERROR:
            return update(state, {
                company: {
                    isLoading: { $set: false },
                    list: { $set: [] },
                },
            });
        case UBO_UPDATE_QUERY:
            return update(state, {
                company: {
                    query: { $set: action.payload },
                },
            });
        case UBO_UPDATE_SELECTED_TERM:
            return update(state, {
                company: {
                    selected: {
                        [action.payload.index]: { $set: action.payload.item },
                    },
                },
            });
        case UBO_CLEAR_SUGGESTIONS:
            return update(state, {
                company: {
                    list: { $set: [] },
                },
            });
        case UBO_SUBMIT:
            return update(state, {
                company: {
                    selected: { $set: action.payload.selected || state.company.selected },
                },
            });
        case UBO_ADD_MEMO:
            return update(state, {
                company: {
                    memo: { [action.payload.searchTerm]: { $set: action.payload.data } },
                },
            });
        case UBO_UPDATE_SORTING_AND_FILTERING:
            return update(state, {
                docViewDeliveryInfo: {
                    $set: action.payload,
                },
            });
        case UBO_UPDATE_FILTERING:
            return update(state, {
                docViewDeliveryInfo: {
                    filtering: {
                        $set: action.payload,
                    },
                },
            });
        case UBO_SET_POST_FILTERS:
            return update(state, {
                company: {
                    postFilters: { $set: action.payload },
                },
            });
        case UBO_UPDATE_CATEGORY_POST_FILTERS:
            const { category, postFilters } = action.payload;
            return update(state, {
                company: {
                    postFilters: {
                        [category]: { $merge: postFilters },
                    },
                },
            });
        default:
            return state;
    }
}

export default uboReducers;
