import { all, take, spawn } from 'redux-saga/effects';
import * as listeners from './listeners/investigationListeners';

/**
 * Main saga for investigation
 * Start listening FOR:
 *  - investigation start
 */

export default function* investigationSaga() {
    const data = yield take('USER_INFO_SUCCESS');
    if (data && data.payload && data.payload.useNewResearchSummaryFeature) {
        yield all([
            spawn(listeners.watchStartInvestigation),
            spawn(listeners.watchSetSameInvestigation),
            spawn(listeners.watchSearchPerformed),
            spawn(listeners.watchCategoryVisited),
            spawn(listeners.watchFilterActions),
            spawn(listeners.watchReportOperation),
            spawn(listeners.watchDocumentOperations),
            spawn(listeners.watchCountsDone),
            spawn(listeners.watchNegativeTermHandling),
            spawn(listeners.watchNegativeTermApplyOperation),
            spawn(listeners.watchloadDocuments),
        ]);
    }
}
