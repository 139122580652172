import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';

export const RadioOptionType = PropTypes.shape({
    label: PropTypes.any,
    checked: PropTypes.any,
    extra: PropTypes.object,
});

class RadioGroup extends Component {
    static propTypes = {
        onChange: PropTypes.func,
        options: PropTypes.arrayOf(RadioOptionType),
        selectAll: PropTypes.any,
        className: PropTypes.string,
        label: PropTypes.any,
        hasError: PropTypes.any,
        disabled: PropTypes.bool,
        required: PropTypes.bool,
        fieldId: PropTypes.string,
    };

    static defaultProps = {
        label: null,
        hasError: false,
        disabled: false,
        required: false,
        className: '',
        fieldId: 'radioGroup',
    };

    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
    }

    onChange(option, e) {
        const options =
            this.props.options &&
            this.props.options.length &&
            this.props.options.map((opt) =>
                opt === option ? { ...option, checked: !opt.checked } : { ...opt, checked: false }
            );

        option.checked = !option.checked;
        this.props.onChange(options, option, e);
    }

    render() {
        const radioButtons =
            this.props.options &&
            this.props.options.length &&
            this.props.options.map((option, index) => (
                <div key={this.props.fieldId + index} className={'radio-option custom-radio'}>
                    <input
                        type="radio"
                        name={this.props.fieldId}
                        id={this.props.fieldId + index}
                        checked={option.checked}
                        disabled={!!option.disabled || this.props.disabled}
                        onChange={(e) => this.onChange(option, e)}
                        {...option.extra}
                    />
                    <label className="radio-label" htmlFor={this.props.fieldId + index}>
                        {option.label}
                    </label>
                </div>
            ));

        return <div className="radios-wrapper__content">{radioButtons}</div>;
    }
}
export default injectIntl(RadioGroup, { forwardRef: true });
