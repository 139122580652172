import { call, put, select, all } from 'redux-saga/effects';
import { hashHistory } from 'react-router';

import * as mainSelectors from '../selectors/main';
import * as uboSelectors from '../selectors/uboSelectors';

// constants
import { RESET_LOAD_DOCS_LISTENER } from '@sagas/constants/investigationConstants';
import { COMPANY_SEARCH, LAUNCHED_SEARCH_FROM, CATEGORIES, CATEGORY_NAMES, MATOMO } from '@constants';
import searchResultsActions, { RESET_CATEGORIES_RESULTS } from '@pages/MainSearch/redux/SearchResults.actions.js';
import { SEARCH_EVENTS } from '../constants/searchConstants';
import { ACTION_TYPES } from '@sagas/constants/investigationConstants';

// actions
import investigationActions from '@pages/MainSearch/redux/Investigation.actions';
import suggestedNamesActions from '@reusable/SuggestedNames/redux/SuggestedNames.actions';
import userPreferencesActions from '@pages/UserPreferances/redux/UserPreferences.actions';
import searchParamsActions from '@pages/MainSearch/redux/SearchParams.actions';
import uboActions from '@pages/StartPage/redux/Ubo.actions';
import adHocSearchActions from '@reusable/AdHocSearch/redux/AdHocSearch.actions';
import sanctionsRiskActions from '@pages/MainSearch/components/sanctionsRisk/redux/SanctionsRisk.actions';
import mainActions from '@pages/Main/Main.actions';

//utilities
import utils from '@utils/utilities';
import searchUtils from '@pages/MainSearch/SearchUtils';
import categoryUtils, { withSearchResultsFilter } from '@utils/categoryUtils';
import costCodeUtils from '@utils/costCodeUtils';
import { buildResetSearchResultsPayload, mapParentCatPostFiltersForV4Payload } from '../helpers/searchHelpers';
import { computeUboPostFiltersForPayload } from '../helpers/uboHelper';
import historyActions from '@HistoryPage/redux/History.actions';
import callMatomoEvent from '@scripts/utils/matomoUtils';

const mainSearchURl = '/main-search';

// actions
const { resetInvestigation, setBillingId } = investigationActions;
const { resetFuzzyNames } = userPreferencesActions;
const { updateQueryType, updateQueryString, setBooleanTerms, setCategory, resetQueryParams } = searchParamsActions;
const { submit: submitUboAction } = uboActions;
const { resetAdHocSearch } = adHocSearchActions;
const { searchLaunchedFrom, setEntityId, setCurrentEntity } = mainActions;
const { resetSanctionsRisk } = sanctionsRiskActions;
const { updateHistoryCategory } = historyActions;

export function* resetCategoriesResults() {
    const searchResults = yield select(mainSelectors.searchResults);
    const store = yield select(mainSelectors.reduxState);

    const transformedSearchResults = yield call(buildResetSearchResultsPayload, searchResults, store);

    yield put({ type: RESET_CATEGORIES_RESULTS, payload: transformedSearchResults });
}

export function* runSearch(action) {
    const {
        searchQuery,
        searchType,
        prefilterQuery: receivedPrefilterQuery,
        launchedFrom,
        entityId,
        checkPreferencesAreObsolete,
        categoryName,
        currentEntity,
        location,
    } = action.payload;

    yield put(resetSanctionsRisk());

    yield put({ type: RESET_LOAD_DOCS_LISTENER, payload: null });

    const viewId = yield select(uboSelectors.viewId);
    
    let searchQueryType, postFilters = [], negativity = {}, entityData = {};
    if(entityId) {
        entityData = yield call(
            [searchUtils, 'getPostFiltersForEntity'],
            entityId, viewId
        ) || {};

        postFilters = entityData.postFilters;
        negativity = entityData.negativity;
    }

    searchQueryType = entityData.searchQueryType ?? searchType;

    const fuzzyNames = yield select(mainSelectors.fuzzyNames);
    const suggestedNames = yield select(mainSelectors.suggestedNames);
    const prefilterQuery = yield select(mainSelectors.prefilterQuery);
    const {
        useDocumentsCountEndpoint,
        useNewResearchSummary,
        preferences: {
            generalSettings: { contentTypes, showSnapshot },
        },
    } = yield select(mainSelectors.theUser);

    const isCompanySearch = searchQueryType === COMPANY_SEARCH;
    const isSearchLaunchedFromHeader = launchedFrom === LAUNCHED_SEARCH_FROM.HEADER;    
    const isSearchLaunchedFromScreening = launchedFrom === LAUNCHED_SEARCH_FROM.SCREENING_PAGE;
    const isSearchLaunchedFromStart = launchedFrom === LAUNCHED_SEARCH_FROM.START_PAGE;
    const sanitisedSearchQuery = yield call(utils.sanitiseQuery, searchQuery);

    const computedPrefilterQuery = receivedPrefilterQuery ?? prefilterQuery;
    const parentCategoriesPostFilters = yield call(mapParentCatPostFiltersForV4Payload, postFilters);

    const { postFiltersForPayload, entityCategories, disabledCategories } = yield call(
        utils.formatPostFiltersForApiPayload,
        postFilters
    );

    const path = {
        pathname: mainSearchURl,
        query: {},
    };

    if (!utils.areContentTypesApplicableWithSearchType(contentTypes, searchQueryType)) {
        utils.showNotificationsMessage({
            messageText: 'SearchResults_Notifications.noAvailableContentTypesForSearch',
            messageType: 'system-error',
        });
        return;
    }

    delete CATEGORIES.ubo.children;

    // reset the adhoc search by passing an empty object
    if(!isSearchLaunchedFromStart) {
        yield put(resetAdHocSearch());
    }

    // reset the query and prefilter query params
    yield put(resetQueryParams());

    //reset visitedContentTypes and the id of the investigation
    yield put(resetInvestigation());

    //save searchQuery, searchQueryType and prefilterQuery in redux store
    yield put(updateQueryString(sanitisedSearchQuery));
    yield put(updateQueryType(searchQueryType));
    yield put(setBooleanTerms(computedPrefilterQuery));

    yield call([searchUtils, 'resetPostFiltersConfiguration'], searchQueryType);

    const isUboEnabled = yield call([utils, 'isUboEnabled']);

    if (isUboEnabled) {
        const uboPostFilters = postFilters && postFilters.filter(
            (postFilter) => postFilter.category === CATEGORY_NAMES.DNB && postFilter.dunsFilter
        );

        if (!uboPostFilters.length) {
            // submit empty ubo when searching from header, basically removing the list of duns
            yield put(submitUboAction({ selected: null }));
        } else {
            const uboPostFiltersForPayload = computeUboPostFiltersForPayload(uboPostFilters);
            const uboActionPayload = {
                postFilters: uboPostFiltersForPayload,
                selected: uboPostFiltersForPayload[0],
            };

            yield put(submitUboAction(uboActionPayload));
        }
    }

    //reset search results
    yield put({ type: SEARCH_EVENTS.RESET_SEARCH_RESULTS, payload: null });

    if ((fuzzyNames && fuzzyNames.query !== sanitisedSearchQuery) || isCompanySearch) {
        yield put(resetFuzzyNames());
    }

    if (
        (suggestedNames && suggestedNames.query !== sanitisedSearchQuery) ||
        isCompanySearch ||
        isSearchLaunchedFromHeader
    ) {
        yield put(suggestedNamesActions.resetSuggestedNames());
    }

    // generate billing id || should we use the billing id that we have in redux store or generate another one for every new search
    const billingId = yield call(costCodeUtils.generateBillingId);
    const costCode = yield call(costCodeUtils.getCostCode);

    yield put(setBillingId(billingId));

    path.query.q = sanitisedSearchQuery;
    path.query.searchType = searchQueryType;

    if (computedPrefilterQuery) {
        path.query.prefilterQuery = computedPrefilterQuery;
    }

    if(isSearchLaunchedFromScreening) {
        const searchResultsBeforeUpdate = yield select(mainSelectors.searchResults);

        const calculateCategoryEnabled = (category) => entityCategories.includes(category) || (categoryUtils.hasChildren(category) && categoryUtils.getChildrenForParentCategory(category).some((subcategory) => entityCategories.includes(subcategory)));
    
        if(searchResultsBeforeUpdate) yield all(Object.keys(searchResultsBeforeUpdate).map((category) => put(searchResultsActions.updateCategoryProperty(category, 'enabled', calculateCategoryEnabled(category)))));
    }

    let receivedCategory = categoryName;

    const searchResults = yield select(mainSelectors.searchResults);

    if(isSearchLaunchedFromScreening) {

        if (!showSnapshot && categoryName && categoryUtils.hasChildren(categoryName)) {
            receivedCategory = withSearchResultsFilter(searchResults).getFirstChild(categoryName).key;
        }
    }

    const redirectToResultList = !!(!showSnapshot || receivedCategory);

    if (negativity) {
        yield call([searchUtils, 'updateEntityNegativity'], negativity);
        yield call([searchUtils, 'updateEntityNegativeTerms'], negativity.topNegativeTerms);

    };

    if (isSearchLaunchedFromScreening) {
        yield put(searchLaunchedFrom(LAUNCHED_SEARCH_FROM.SCREENING_PAGE));
    }

    if(isSearchLaunchedFromHeader) {
        path.query.searchFrom = LAUNCHED_SEARCH_FROM.HEADER;
        yield put(searchLaunchedFrom(LAUNCHED_SEARCH_FROM.HEADER));
    }

    if(isSearchLaunchedFromStart) {
        path.query.searchFrom = LAUNCHED_SEARCH_FROM.START_PAGE;
        yield put(searchLaunchedFrom(LAUNCHED_SEARCH_FROM.START_PAGE));
    }

    if (redirectToResultList) {
        path.query.list = true;
        receivedCategory = receivedCategory ?? entityCategories[0];
        if (categoryUtils.hasChildren(categoryName)) {
            receivedCategory = withSearchResultsFilter(searchResults).getFirstChild(categoryName).key;
        }
        let defaultSearchCategory = '';

        defaultSearchCategory = receivedCategory
            ? receivedCategory
            : yield call([searchUtils, 'getSearchDefaultCategory'], contentTypes, searchQueryType);
        path.query.category = defaultSearchCategory;
        if (location && location.pathname === mainSearchURl) {
            yield put({ type: ACTION_TYPES.loadDocuments, payload: { categoryName: defaultSearchCategory, filteredFields: location.query.filteredFields }});
        }
        
        yield call([searchUtils, 'executeBillingSearchEvent'], defaultSearchCategory, billingId, costCode);
    } else {
        delete path.query.list;
        delete path.query.category;
    }
    if (isSearchLaunchedFromHeader) delete path.query.uboSelected;

    if (isSearchLaunchedFromScreening) {
        yield put(setEntityId(entityId));
        yield put(setCategory(receivedCategory ?? null));
        path.query.searchFrom = LAUNCHED_SEARCH_FROM.SCREENING_PAGE;
        path.query.entityId = entityId;
    } else {
        yield put(setEntityId(null));
    }
    yield put(updateHistoryCategory(''));

    //push the new url to the browser to render the Results Snapshot/List component
    yield call(hashHistory.push, path);

    //here is an issue in the old flow because list will have a boolean value and it will be always different than string true -> TO BE CHECKED
    const countOnly = path.query.list !== 'true';
    const payload = {
        searchQuery: sanitisedSearchQuery,
        searchQueryType: searchQueryType,
        prefilterQuery: computedPrefilterQuery,
        countOnly: countOnly,
        preloadCategory: path.query.category || null,
        useNewResearchSummary: useNewResearchSummary,
    };

    if (isSearchLaunchedFromScreening) {
        payload.postFilters = postFiltersForPayload;
        payload.parentCategoriesPostFiltersV4 = parentCategoriesPostFilters;
        payload.entityCategories = entityCategories;
        payload.disabledCategories = disabledCategories;
    }

    
    const pageName = `nexisDiligence_${isSearchLaunchedFromHeader ? MATOMO.pageName.global : MATOMO.pageName.home}`;
    const component = `nexisDiligence_${isSearchLaunchedFromHeader ? MATOMO.component.header : ''}`;

    callMatomoEvent({
        pageName,
        component,
        subComponent: MATOMO.subComponent.delivery,
        event: MATOMO.event.searchRun,
        value: MATOMO.value.search,
        action: MATOMO.action.submit,
        contextData: {
            searchTerm: searchQuery,
            snapshotEnabled: showSnapshot,
        },
    });

    if (useDocumentsCountEndpoint) {
        const userPreferencesRefreshObject = {
            // TODO use the checkPreferencesAreObsolete from withPreferenceRefresh HOC
            checkPreferencesAreObsolete: checkPreferencesAreObsolete,
            isSnapshotEnabled: showSnapshot,
        };
        const reduxState = yield select(mainSelectors.reduxState);

        yield call(
            [searchUtils, 'getArticlesCounts'],
            payload,
            userPreferencesRefreshObject,
            !!isSearchLaunchedFromScreening,
            reduxState
        );
    } else {
        yield call([searchUtils, 'searchArticles'], payload);
    }

    if (currentEntity) {
        yield put(setCurrentEntity(currentEntity));
    }
}
