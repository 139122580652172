import { cloneDeep, findIndex, isEmpty } from 'lodash';
import {
    TOGGLE_ALERT,
    TOGGLE_ALERTS,
    SET_ALERTS,
    SET_ALERT_FILTER_NAME,
    SET_IS_NEW_ALERT,
    UPDATE_ALERTS_LOADING_STATUS,
    SET_PUBLIC_RECORD_ALERTS,
    TOGGLE_ALERT_SELECTION,
    SET_ALERTS_COUNT,
    SET_PUBLIC_RECORDS_COUNT,
    SET_DOWNLOAD_ALERTS_BUTTON_STATE,
    SET_ACTIVE_ALERTS_COUNT,
    UPDATE_POLLING_STATUS,
} from './ManageAlerts.actions';
import update from 'immutability-helper/index';
import { ALERT_TYPE } from '@constants';

function alertsManager(state = {}, action) {
    let selectedAlerts = [];
    let managedAlerts = [];
    let selectedAlert = '';
    let index = '';
    let alertType = null;
    let count = 0;
    let status = false;
    let pageNumber = 0;
    let pageSize = 10;

    switch (action.type) {
        case SET_PUBLIC_RECORD_ALERTS:
            if (!action.payload || isEmpty(action.payload)) {
                return state;
            }

            managedAlerts = action.payload.alerts;
            count = action.payload.count;
            status = action.payload.status;
            pageNumber = action.payload.pageNumber;
            pageSize = action.payload.pageSize;

            return update(state, {
                [ALERT_TYPE.PUBLIC_RECORD_ALERT]: {
                    alertsAreLoaded: { $set: status },
                    count: { $set: count },
                    pageSize: { $set: pageSize },
                    currentPageIndex: { $set: pageNumber },
                    list: { $set: managedAlerts },
                },
            });

        case SET_ALERTS:
            if (!action.payload || isEmpty(action.payload)) {
                return state;
            }

            alertType = action.payload.alertType;
            managedAlerts = action.payload.alerts;
            count = action.payload.count;
            status = action.payload.status;
            pageNumber = action.payload.pageNumber;
            pageSize = action.payload.pageSize;

            return update(state, {
                [alertType]: {
                    alertsAreLoaded: { $set: status },
                    count: { $set: count },
                    pageSize: { $set: pageSize },
                    currentPageIndex: { $set: pageNumber },
                    list: { $set: managedAlerts },
                },
            });

        case UPDATE_ALERTS_LOADING_STATUS:
            if (!action.payload || isEmpty(action.payload)) {
                return state;
            }

            status = action.payload.status;
            alertType = action.payload.alertType;

            return update(state, { [alertType]: { alertsAreLoaded: { $set: status } } });

        case TOGGLE_ALERT:
            if (!action.payload || isEmpty(action.payload)) {
                return state;
            }

            managedAlerts = cloneDeep(state[ALERT_TYPE.MY_ALERT].list);
            selectedAlert = action.payload.alertId;
            let newAlert = managedAlerts.find((alert) => {
                return alert.id === selectedAlert;
            });
            if (newAlert) {
                index = findIndex(managedAlerts, (alert) => {
                    return alert.id === selectedAlert;
                });
                if (index === -1) {
                    return state;
                }
                newAlert = cloneDeep(newAlert);
                newAlert.active = action.payload.isActive;
                return update(state, { [ALERT_TYPE.MY_ALERT]: { list: { [index]: { $set: newAlert } } } });
            } else {
                return state;
            }

        case TOGGLE_ALERTS:
            if (!action.payload || isEmpty(action.payload)) {
                return state;
            }

            selectedAlerts = action.payload.alertsIds;
            managedAlerts = cloneDeep(state[ALERT_TYPE.MY_ALERT].list);

            selectedAlerts.forEach((alertId) => {
                let currentAlert = managedAlerts.find((alert) => {
                    return alert.id === alertId;
                });
                if (currentAlert) {
                    currentAlert.active = action.payload.isActive;
                }
            });

            return update(state, { [ALERT_TYPE.MY_ALERT]: { list: { $set: managedAlerts } } });

        case SET_ALERT_FILTER_NAME:
            if (!action.payload || isEmpty(action.payload)) {
                return state;
            }

            return update(state, { filterAlertName: { $set: action.payload } });

        case SET_IS_NEW_ALERT:
            if (!action.payload || isEmpty(action.payload)) {
                return state;
            }

            return Object.assign({}, state, { isNewAlert: action.payload });

        case TOGGLE_ALERT_SELECTION:
            if (!action.payload || isEmpty(action.payload)) {
                return state;
            }

            alertType = action.payload.alertType;
            let isSelected = action.payload.isSelected;
            let alerts = cloneDeep(state[alertType].list);
            selectedAlerts = cloneDeep(action.payload.alerts);

            selectedAlerts.forEach((alert) => {
                let selectedAlert = alerts.find((item) => {
                    return item.id === alert.id;
                });
                if (selectedAlert) {
                    selectedAlert.isChecked = isSelected;
                } else {
                    console.log('alert not found', alert);
                }
            });
            return update(state, { [alertType]: { list: { $set: alerts } } });

        case SET_ALERTS_COUNT:
            if (action.payload === undefined || action.payload === null) {
                return state;
            }

            return update(state, { alertsCount: { $set: action.payload } });

        case SET_ACTIVE_ALERTS_COUNT:
            if (action.payload === undefined || action.payload === null) {
                return state;
            }

            return update(state, { activeAlertsCount: { $set: action.payload } });

        case SET_PUBLIC_RECORDS_COUNT:
            if (action.payload === undefined || action.payload === null) {
                return state;
            }

            return update(state, { publicRecordsCount: { $set: action.payload } });

        case SET_DOWNLOAD_ALERTS_BUTTON_STATE:
            return update(state, { isDownloadAlertsButtonDisabled: { $set: action.payload } });

        case UPDATE_POLLING_STATUS:
            return update(state, {
                pollingStatus: { $set: action.payload },
            });
        default:
            return state;
    }
}

export default alertsManager;
