// @flow
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { CATEGORY_NAMES, EDIT_SEARCH_URL, FE_LOCK_NAMES, SOURCES } from '@constants';
import SourcesComponent from '@pages/UserPreferances/components/SourcesComponent';
import AdminLock from '../AdminLock/AdminLock';
import { cloneDeep, isEqual } from 'lodash';
import SourcesDropdownUtils from '@utils/sourcesDropdownUtils';
import utils from '@utils/utilities';
import type { Sources, UserPreferencesContentTypes } from './redux/flow/SearchBar.type.guards';

const SOURCES_DROPDOWN_MESSAGE = {
    clearAllSelectedItems: 'SearchBarSourcesDropdown.clearAllSelectedItems',
    selectAllSelectedItems: 'SearchBarSourcesDropdown.selectAllSelectedItems',
    noSelectedItems: 'SelectedDocumentDropdown.noSelectedItems',
    selectedItems: 'SearchBarSourcesDropdown.selectedItemsHeader',
    selectedItemsButton: 'SearchBarSourcesDropdown.selectedItemsButton',
    selectedAllItemsButton: 'SearchBarSourcesDropdown.selectedAllItemsButton',
    selectionWarning: 'SearchBarSourcesDropdown.selectWarning',
};

type State = {
    originalSources: Array<Sources>,
    lastValidSourcesSelection: Array<Sources>,
    sources: Array<Sources>,
    selectedSources: number,
    isListVisible: boolean,
};

type Props = {
    userPreferencesSources: Array<UserPreferencesContentTypes>,
    adHocSearchSources: Array<Sources>,
    sources?: Array<Sources>,
    searchType: string,
    selectedSources: number,
    isListVisible: boolean,
    updateAdHocSources: (sources?: Array<Sources>) => void,
    updateSourcesDropdownContentTypes: (sources?: Array<Sources>) => void,
    updateDropdownChangedStatus: (status: boolean) => void,
    updateSelectedSourcesCount: (selectedSourcesCount: number) => void,
    isDropdownChanged: boolean,
    adHocSearch: Object,
    locked: boolean,
    updateAdHocProperty: (propertyName: string, value: string) => void,
    customQuery?: string,
    newsSearchSources?: string,
    lawSource?: string,
    refreshPreferencesIfNeeded: () => void,
};

class SourcesDropdownContent extends React.Component<Props, State> {
    static defaultProps = {
        isListVisible: false,
    };

    node: ?HTMLDivElement;

    constructor(props) {
        super(props);

        this.state = {
            lastValidSourcesSelection: SourcesDropdownUtils.formatSources(props.userPreferencesSources, props),
            isListVisible: props.isListVisible,
            sources: [],
            selectedSources: props.selectedSources,
            originalSources: SourcesDropdownUtils.formatSources(props.userPreferencesSources, props),
        };
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    UNSAFE_componentWillMount() {
        let contentTypes = this.props.userPreferencesSources;
        // if the user comes from Edit Search action, show content types used for that search
        if (this.props.adHocSearchSources && window.location.href.includes(EDIT_SEARCH_URL)) {
            contentTypes = this.props.adHocSearchSources;
            this.performSourcesUpdates(contentTypes);
            this.props.updateSourcesDropdownContentTypes(contentTypes);
        } else {
            contentTypes = SourcesDropdownUtils.formatSources(contentTypes, this.props);
            this.performSourcesUpdates(contentTypes);
            this.props.updateSourcesDropdownContentTypes(contentTypes);
        }
    }

    UNSAFE_componentWillUpdate(nextProps) {
        if (!isEqual(this.props.sources, nextProps.sources)) {
            this.performSourcesUpdates(nextProps.sources);
        }
        if (!isEqual(this.props.searchType, nextProps.searchType)) {
            const originalSources = SourcesDropdownUtils.formatSources(nextProps.userPreferencesSources, nextProps);
            this.setState({ originalSources: originalSources }, () => {
                this.performSourcesUpdates(originalSources);
                this.props.updateAdHocSources(originalSources);
            });
        }
    }

    performSourcesUpdates = (contentTypes?: Array<Sources>) => {
        const contentTypesChanged = !isEqual(this.state.originalSources, contentTypes);
        const selectedSources = utils.calculateCheckedSources(contentTypes);
        this.props.updateDropdownChangedStatus(contentTypesChanged);
        this.setState({
            sources: contentTypes,
            selectedSources: selectedSources,
            lastValidSourcesSelection: contentTypes,
        });
        this.props.updateSelectedSourcesCount(selectedSources);
    };

    handleSingleSourceSelection = (checked: boolean, sourceName: string) => {
        let contentTypes: Array<Sources> = cloneDeep(this.state.sources);
        contentTypes.forEach((item) => {
            if (item.key === sourceName) {
                item.checked = checked;
            }
        });
        this.performSelectionUpdates(contentTypes);
    };

    handleAllSourcesSelection = (checked: boolean) => {
        let contentTypes: Array<Sources> = cloneDeep(this.state.sources);
        contentTypes.forEach((item) => {
            if (
                !item.disabled &&
                item.show &&
                !SOURCES.legalSources.includes(item.key) &&
                item.key !== CATEGORY_NAMES.PUBLIC_RECORDS
            ) {
                item.checked = checked;
            }
        });
        this.performSelectionUpdates(contentTypes);
    };

    performSelectionUpdates = (contentTypes: Array<Sources>) => {
        let sources = cloneDeep(contentTypes);
        const selectedSources: number = utils.calculateCheckedSources(contentTypes);
        const contentTypesChanged: boolean = SourcesDropdownUtils.areSourcesChanged(
            this.state.originalSources,
            sources
        );
        sources = SourcesDropdownUtils.selectAppropriateLegalSubcategories(
            contentTypes,
            this.props.adHocSearch.lawSource || this.props.lawSource
        );
        this.setState({
            sources: sources,
            selectedSources: selectedSources,
        });
        this.props.updateSelectedSourcesCount(selectedSources);
        this.props.updateDropdownChangedStatus(contentTypesChanged);
    };

    handleClickOutside = (event: any) => {
        const isElementPartOfModal =
            event.target && event.target.parentElement && event.target.parentElement.className
                ? event.target.parentElement.className.indexOf('sources-count') > -1
                : false;
        const scrollBarClicked = event.target && event.target.localName === 'html';

        if (this.node?.contains(event.target) || isElementPartOfModal || scrollBarClicked) {
            return;
        }

        this.applySelection();
        this.setState({ isListVisible: false });
    };

    applySelection = () => {
        // if dropdown is expanded
        if (this.state.isListVisible === true) {
            //and user clears all sources
            if (this.state.selectedSources === 0) {
                // check sources from last valid selection
                const selectedSources = utils.calculateCheckedSources(this.state.lastValidSourcesSelection);
                this.props.updateSourcesDropdownContentTypes(this.state.lastValidSourcesSelection);
                this.setState((prevState) => ({
                    sources: prevState.lastValidSourcesSelection,
                    selectedSources: selectedSources,
                }));
                this.props.updateSelectedSourcesCount(selectedSources);
                if (isEqual(this.state.lastValidSourcesSelection, this.state.originalSources)) {
                    this.props.updateDropdownChangedStatus(false);
                }
            } else {
                // if there are changes in the dropdown update it with selected sources and save the selection
                if (this.props.isDropdownChanged) {
                    this.props.updateAdHocProperty(
                        'customQuery',
                        this.props.adHocSearch.customQuery || this.props.customQuery
                    );
                    this.props.updateAdHocProperty(
                        'newsSearchSources',
                        this.props.adHocSearch.newsSearchSources || this.props.newsSearchSources
                    );
                    this.props.updateAdHocSources(this.state.sources);
                    this.props.updateSourcesDropdownContentTypes(this.state.sources);
                    this.setState({ lastValidSourcesSelection: this.state.sources });
                } else {
                    // if there are no changes compared to the User Prefs, revert selection to User Prefs
                    this.props.updateSourcesDropdownContentTypes(this.state.originalSources);
                    this.setState({ lastValidSourcesSelection: this.state.originalSources });
                    if (Object.keys(this.props.adHocSearch).length !== 0) {
                        this.props.updateAdHocSources(this.state.originalSources);
                    }
                }
            }
        }
    };

    toggleListVisibility = () => {
        this.props.refreshPreferencesIfNeeded();
        this.applySelection();
        this.setState((prevState) => ({ isListVisible: !prevState.isListVisible }));
    };

    render() {
        const { selectedSources, isListVisible, sources } = this.state;
        const disabledSourcesCount: number = utils.calculateDisabledSources(sources);
        const allSourcesSelected: boolean =
            selectedSources === sources.length - SOURCES.legalSources.length - disabledSourcesCount;
        const messages: Object = SOURCES_DROPDOWN_MESSAGE;

        return (
            <React.Fragment>
                <div
                    onClick={this.toggleListVisibility}
                    data-testid="sources-dropdown-component"
                    className="search-dropdown"
                    ref={(node) => (this.node = node)}
                >
                    <DropdownToggleInfo
                        messages={messages}
                        allSourcesSelected={allSourcesSelected}
                        selectedSources={selectedSources}
                        isListVisible={isListVisible}
                    />
                    {isListVisible && (
                        <div
                            onClick={(event) => event.stopPropagation()}
                            data-testid="sources-dropdown-content"
                            className="selected-sources-dropdown"
                        >
                            <ActionBar
                                messages={messages}
                                allSourcesSelected={allSourcesSelected}
                                selectedSources={selectedSources}
                                locked={this.props.locked}
                                handleAllSourcesSelection={this.handleAllSourcesSelection}
                            />
                            <div
                                data-testid="sources-dropdown-content-component"
                                className={`selected-sources-dropdown-content ${
                                    selectedSources === 0 ? 'selected-sources-warning' : ''
                                }`}
                            >
                                <SourcesComponent
                                    sources={sources}
                                    handleSourceSelection={this.handleSingleSourceSelection}
                                    isReadonly={this.props.locked}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </React.Fragment>
        );
    }
}

const DropdownToggleInfo = (props) => {
    return (
        <React.Fragment>
            <div
                data-testid="sources-dropdown-toggle"
                data-track="search-bar-sources-dropdown-toggle"
                className={`sources-count ${props.isListVisible ? 'sources-count-opened' : ''}`}
            >
                <label className={'sources-count-label'}>
                    <span className={'sources-count-label__text'}>
                        {props.allSourcesSelected ? (
                            <FormattedMessage id={props.messages.selectedAllItemsButton} />
                        ) : (
                            <FormattedMessage
                                id={props.messages.selectedItemsButton}
                                values={{ number: props.selectedSources }}
                            />
                        )}
                    </span>
                </label>
                {props.isListVisible ? (
                    <div className="la-TriangleUp sources-count-arrow" />
                ) : (
                    <div className="la-TriangleDown sources-count-arrow" />
                )}
            </div>
        </React.Fragment>
    );
};

const ActionBar = (props) => {
    return (
        <React.Fragment>
            <h5 data-testid="sources-dropdown-content-header" className="selected-sources-dropdown__header">
                {props.locked && <AdminLock lockName={FE_LOCK_NAMES.sourcesIncluded} isDisabled={true} />}
            </h5>
            <div data-testid="sources-dropdown-content-buttons" className="selected-sources-dropdown-buttons">
                <div className="selected-entities">
                    <FormattedMessage id={props.messages.selectedItems} />
                </div>
                <button
                    className="select-all-button"
                    data-testid={'selection-add-all'}
                    onClick={() => {
                        props.handleAllSourcesSelection(true);
                    }}
                    disabled={props.locked || props.allSourcesSelected}
                >
                    <FormattedMessage id={props.messages.selectAllSelectedItems} />
                </button>
                <button
                    className="clear-all-button"
                    data-testid={'selection-clear-all'}
                    onClick={() => {
                        props.handleAllSourcesSelection(false);
                    }}
                    disabled={props.locked || props.selectedSources === 0}
                >
                    <FormattedMessage id={props.messages.clearAllSelectedItems} />
                </button>
            </div>
            {props.selectedSources === 0 ? (
                <label className={'selection-warning-label'}>
                    <FormattedMessage id={props.messages.selectionWarning} />
                </label>
            ) : null}
        </React.Fragment>
    );
};

export default (SourcesDropdownContent: React.AbstractComponent<Props>);
