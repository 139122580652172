import * as React from 'react';
import { injectIntl } from 'react-intl';
import formatRichMessage from '@utils/formatRichMessage';

const ArticleActionWrapper = ({ children, intl, isDisabled, customClass = '' }) => {
    if (!isDisabled) {
        return children;
    }

    return (
        <span
            className={`article-actions-disabled-action ${customClass}`}
            data-for="disabled-action"
            data-tip={formatRichMessage({ id: 'ArticleAction.notSupported' }, intl)}
        >
            {children}
        </span>
    );
};

export default injectIntl(ArticleActionWrapper);
