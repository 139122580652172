import React from 'react';
import { FormattedMessage } from 'react-intl';
import { withDragAndDrop } from 'scripts/reusable/HOC/withDragAndDrop';
import { DRAG_AND_DROP } from '@constants';
class DraggableCategory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const {
            isDragging,
            showDropTarget,
            insertAbove,
            droppedCategoryName,
            connectDragSource,
            connectDropTarget,
            isReadonly,
            index,
            article,
            categories,
        } = this.props;
        let showDroppedAnimation = droppedCategoryName === this.props.name;
        let id = article ? `article-${index}` : `report-${index}`;
        let hasMultipleItems = categories && categories.length > 1;
        let isCurrentMenuVisible = this.props.visibleMenu === id;
        let isDragAndDropDisabled = this.props.visibleMenu !== null;

        if (isReadonly && !this.props.userIsAdmin) {
            return (
                <div className={'user-preferences-category-container_row disabled'}>
                    <div className={'user-preferences-category-container_row_list-item'}>
                        <div className="draggable-icon" />
                        <div className="user-preferences-category-container_row_list-item_title">
                            <FormattedMessage id={'Preferences_Source.' + this.props.name} />
                        </div>
                    </div>
                </div>
            );
        }

        let documentItem = (
            <div
                className={`user-preferences-category-container_row ${isDragging ? ' dragging' : ''} ${
                    hasMultipleItems ? 'with-multiple-items' : ''
                } ${isCurrentMenuVisible ? 'selected-row' : ''} ${
                    isDragAndDropDisabled ? 'drag-and-drop-disabled' : ''
                }`}
            >
                <div
                    role="draggableCategory"
                    className={
                        'user-preferences-category-container_row_list-item ' +
                        (isDragging ? ' dragging' : '') +
                        (showDropTarget ? (insertAbove ? ' drop-top ' : ' drop-bottom ') : '') +
                        (showDroppedAnimation ? ' dropped' : '')
                    }
                >
                    <div className="draggable-icon" />
                    <div className="user-preferences-category-container_row_list-item_title">
                        <FormattedMessage id={'Preferences_Source.' + this.props.name} />
                    </div>
                </div>
            </div>
        );

        return isDragAndDropDisabled ? documentItem : connectDragSource(connectDropTarget(documentItem));
    }
}

const dndHookInfo = {
    componentType: 'child',
    accept: DRAG_AND_DROP.CATEGORY,
};

export default withDragAndDrop(dndHookInfo)(DraggableCategory);
