import React, {Component} from 'react';
import PropTypes from 'prop-types';

/**
 * Component that will render a component through render prop when user clicks it;
 * Sends the element position and bounds along with a close handler
 * This is used for popovers, tooltips.. whatever is rendered on user event and outside the app DOM nodes;
 */
export default class TriggerComponent extends Component {
    static propTypes = {
        render: PropTypes.func.isRequired,
        className: PropTypes.string,
        trigger: PropTypes.string,
        testId: PropTypes.string,
        callback: PropTypes.func,
    };

    static defaultProps = {
        className: '',
        event: 'onClick',
    };

    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
            position: { x: 0, y: 0 },
        };
        this.calculatePosition = this.calculatePosition.bind(this);
    }

    componentDidMount() {
        window.addEventListener('resize', this.calculatePosition);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.calculatePosition);
    }

    calculatePosition(e, open = false) {
        this.setState({
            isOpen: open ? open : this.state.isOpen,
            position: {
                x: this.getBounds().left, //e.pageX,
                y: this.getBounds().right, //e.pageY
            },
            bounds: this.getBounds(),
        });
    }

    getBounds() {
        let bounds = this.node.getBoundingClientRect();

        return {
            top: bounds.top + document.documentElement.scrollTop,
            left: bounds.left + document.documentElement.scrollLeft,
            width: this.node.offsetWidth,
            height: this.node.offsetHeight,
        };
    }

    open = (e) => !this.state.isOpen && this.calculatePosition(e, true);

    close = () => this.state.isOpen && this.setState({ isOpen: false });

    render() {
        const renderPropParams = {
            position: this.state.position,
            bounds: this.state.bounds,
            onClose: this.close,
            callback: this.props.callback
        };

        const triggerEvent = !this.props.disabled
            ? {
                  [this.props.event]: this.open,
              }
            : null;

        return (
            <span
                className={`${this.props.className} trigger-icon ${this.state.isOpen ? 'open' : ''}`}
                ref={(node) => (this.node = node)}
                data-testid={this.props.testId}
                {...triggerEvent}
            >
                {this.props.children}
                {this.state.isOpen && this.props.render(renderPropParams)}
            </span>
        );
    }
}
