import * as React from 'react';
import ReactTooltip from 'react-tooltip';
import ReactDOMServer from 'react-dom/server';
import { SANCTIONS_WATCHLISTS_SECTION } from "@constants";
import overridePosition from '../../utils/overridePosition';
import formatRichMessage from '@utils/formatRichMessage';
import RiskTooltip from 'scripts/reusable/GridTable/components/RiskTooltip';
import cellUtils from "@reusable/GridTable/utils/cellUtils";
import searchSagaActions from "@MainSearch/redux/searchSaga.actions";

export class SanctionsRiskTemplate extends React.Component {
    getCompatibleCell(uncertainCell) {
        const entity = uncertainCell.text;
        return { ...uncertainCell, entity };
    }

    update(cell, cellToMerge) {
        return this.getCompatibleCell({ ...cell, text: cellToMerge.text });
    }

    render(cell) {
        if (!cell || !cell.entity || cell?.entity?.counts === 0) {
            return (
              <div className="sanctions-column-empty risk-score-container non-clickable">
                  <div className="doughnut" />
                  <span className="sanctions-risk-score-value">-</span>
              </div>
            );
        }

        const {
            entity: { counts, sanctions, intl },
            text,
        } = cell;
        const { dispatch } = text.extraProps;
        const { formatMessage } = intl;

        const goToSearchResults = () => {
            const payload = cellUtils.computePayload(text, text.extraProps.searchResults);
            dispatch(searchSagaActions.runSearch(payload));
        };

        if (cell?.isLoading) {
            return <div className="skeleton-loading" />;
        }

        if (!sanctions || !sanctions.riskLevel || !sanctions.documentsCount) {
            return (
                <div className="risk-score-container"
                     onClick={goToSearchResults}>
                    <div className="doughnut" />
                    <span className="grid-table-cell-count-template sanctions">{counts}</span>
                </div>
            );
        }

        const overallRisk = sanctions.riskLevel;
        const totalArticlesValue = counts;

        //Negative news key should be fine since negative news tooltip
        //should be identical to sanctions risk tooltip
        const totalArticlesLabel = formatMessage({
            id: 'NegativeNewsVisualisations.snapshot.graph.label.totalArticles',
        });

        const riskLevelClass = `${
            cell.entity && sanctions.riskLevel ? sanctions.riskLevel.toLowerCase() : ''
        }`;
        const preferencesRedirect = SANCTIONS_WATCHLISTS_SECTION;
        const disclaimerLabel = formatRichMessage({ id: 'SanctionsWatchLists.label.disclaimer' }, intl, {
            a: (...chunks) => `<a href=#/user-preferences?section=${preferencesRedirect}>${chunks.join('')}</a>`,
        });

        const sanctionsPercentageLabels = ['high', 'medium', 'low']
          .map((riskLevel) => {

            return {
                itemRiskLevel: riskLevel,
                itemRiskLabel: formatMessage({ id: `NegativeNewsVisualisations.label.short.${riskLevel}` }),
                itemPercentage: (100 * sanctions.documentsCount[riskLevel]) / sanctions.count,
            };
        });
        const SanctionsRiskTooltipAsString = ReactDOMServer.renderToString(
            <RiskTooltip
                overallRisk={riskLevelClass}
                categoryLabel={formatMessage({ id: 'General.categoryName.label.sanctions' })}
                totalArticlesLabel={totalArticlesLabel}
                totalArticlesValue={totalArticlesValue}
                disclaimerLabel={disclaimerLabel}
                riskVisualisationsLabel={formatMessage({
                    id: `NegativeNewsVisualisations.label.${riskLevelClass}`,
                })}
                riskPercentageLabels={sanctionsPercentageLabels}
                dataTestId="sanctions-risk-tooltip"
            />
        );

        if (!cell.entity.sanctions || !cell.entity.sanctions.riskLevel || !cell.entity.sanctions.documentsCount) {
            return (
                <div className="grid-table-cell-count-template sanctions"
                     onClick={goToSearchResults}
                >
                    {counts}
                </div>
            );
        }

        return (
            <div className={`risk-score-container ${text?.displayName?.toLowerCase()}`} onClick={goToSearchResults}>
                {overallRisk && (
                    <ReactTooltip
                        type="light"
                        offset={{ bottom: 0, left: -135 }}
                        overridePosition={overridePosition}
                        border={true}
                        effect="solid"
                        place="bottom"
                        html={true}
                        className="tooltips sanctions-risk-tooltip"
                        id={`${cell.text.id}-sanctionstooltip`}
                        delayShow={500}
                    />
                )}
                <div className={`doughnut ${riskLevelClass}`} />
                <span
                    className="sanctions-risk-score-value"
                    data-tip={SanctionsRiskTooltipAsString}
                    data-for={`${cell.text.id}-sanctionstooltip`}
                    data-testid={`sanctions-risk-score-value-${text?.displayName?.toLowerCase()}`}
                >
                   {formatMessage({ id: `NegativeNewsVisualisations.label.${riskLevelClass}` })}
                </span>
            </div>
        );
    }
}
