// @flow
import categoryUtils from './categoryUtils';
import { CATEGORIES_ORDER, CATEGORY_NAMES } from '@constants';
import utils from './utilities';
import { cloneDeep, isEqual } from 'lodash';
import type {
    CustomNewsQuery,
    Sources,
    UserPreferencesContentTypes,
} from '@reusable/SearchBar/redux/flow/SearchBar.type.guards';

const SourcesDropdownUtils: Object = {
    getCustomNewsDisabledStatus(contentTypes: Array<UserPreferencesContentTypes>, props: Object) {
        let tempContentTypes: Array<UserPreferencesContentTypes> = cloneDeep(contentTypes);
        let availableQueries: string = this.getAvailableQueries(props);

        tempContentTypes.forEach((contentType) => {
            if (contentType.name === CATEGORIES_ORDER.customNews.key) {
                contentType.disabled = availableQueries === undefined;
                contentType.checked = contentType.checked && availableQueries !== undefined;
            }
        });
        return tempContentTypes;
    },

    getAvailableQueries(props: Object) {
        const customNewsQueries: Array<CustomNewsQuery> = [
            ...props.personCustomNewsQuery,
            ...props.companyCustomNewsQuery,
        ];
        const adminPersonCustomNews: Array<CustomNewsQuery> = props.adminPersonCustomNewsQuery;
        const adminCompanyCustomNews: Array<CustomNewsQuery> = props.adminCompanyCustomNewsQuery;
        const lnPersonCustomNewsQuery = props.lnPersonCustomNewsQuery;
        const lnCompanyCustomNewsQuery = props.lnCompanyCustomNewsQuery;

        if (adminCompanyCustomNews && adminPersonCustomNews) {
            customNewsQueries.push(...adminPersonCustomNews, ...adminCompanyCustomNews);
        }

        if (lnCompanyCustomNewsQuery && lnPersonCustomNewsQuery) {
            customNewsQueries.push(...lnPersonCustomNewsQuery, ...lnCompanyCustomNewsQuery);
        }

        return customNewsQueries.find(
            (customQuery) =>
                customQuery.checked &&
                (customQuery.isDeleted === false || !customQuery.isDeleted) &&
                customQuery.query &&
                customQuery.query.length > 0
        );
    },

    selectAppropriateLegalSubcategories(sources: Array<Sources>, lawSource: string) {
        let sourcesWithLegalSubcategories = cloneDeep(sources);
        const parentLawSource: Sources = sourcesWithLegalSubcategories.find(
            (source) => source.key === CATEGORY_NAMES.LAW_SOURCES
        );
        const isParentLawSourceChecked: boolean = !!parentLawSource ? parentLawSource.checked : false;

        sourcesWithLegalSubcategories.forEach((source) => {
            if (lawSource === 'all') {
                if (utils.isLegalSubcategory(source.key)) {
                    source.checked = isParentLawSourceChecked;
                }
            } else {
                if (source.key === lawSource) {
                    source.checked = isParentLawSourceChecked;
                }
            }
        });

        return sourcesWithLegalSubcategories;
    },

    areSourcesChanged(originalSources: Array<Sources>, currentSources: Array<Sources>) {
        // check for differences excluding legal subcategories to show/hide 'update preferences' checkbox
        let original = cloneDeep(originalSources).filter((source) => !utils.isLegalSubcategory(source.key));
        let current = cloneDeep(currentSources).filter((source) => !utils.isLegalSubcategory(source.key));

        return !isEqual(original, current);
    },

    formatSources(sources: Array<Sources>, props: Object) {
        const { lawSources, searchType } = props;
        let sourceItems: Array<Object> = [];
        sources = this.getCustomNewsDisabledStatus(sources, props);
        sources.forEach((item) => {
            const category = categoryUtils.getCategory(item.name);
            if (category) {
                sourceItems.push({
                    key: item.name,
                    name: category.name,
                    checked: item.value,
                    order: category.order,
                    disabled: item.disabled,
                    isNew: category.isNew,
                    show: lawSources.indexOf(item.name) < 0,
                });
            }
        });
        sourceItems = utils.removeSourcesNotAllowedForSearch(searchType, sourceItems);
        sourceItems = utils.sortByProperty(sourceItems, 'order');

        return sourceItems;
    },
};

export default SourcesDropdownUtils;
