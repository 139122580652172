//@flow
import * as React from 'react';
import utils from '@utils/utilities';
import TruncatedText from './TruncatedText';
import type { ReportTitleTooltipData } from '@pages/ReportBuilder/components/ReportsList/ReportTypes/sharedComponents/Header/components/ReportEditableTitle';

type Props = {|
    id: string,
    text: string,
    tooltipData: ?ReportTitleTooltipData | string,
    truncatedTextRef: Object,
|};

type TitleProps = {
    tooltipId: string,
    className: string,
    hasChild: boolean,
    tooltipHtml: boolean,
    isTruncated: boolean,
    checkTruncation: () => boolean,
    TruncatedComponent: string,
};

class TruncatedTitle extends React.Component<Props> {
    render() {
        return (
            <TruncatedText
                tooltipId={`${this.props.id}`}
                className={`${this.props.id}`}
                ref={this.props.truncatedTextRef}
                hasChild={true}
                tooltipHtml={false}
                checkTruncation={utils.isHtmlTextTruncated}
                TruncatedComponent={Title}
                {...this.props}
            />
        );
    }
}

const Title = (props: Props & TitleProps): React.Element<'span'> => {
    return (
        <span
            className="truncated-title__text"
            data-for={props.tooltipId}
            data-tip={props.isTruncated ? props.tooltipData : null}
        >
            {props.text}
        </span>
    );
};

export default (TruncatedTitle: React.AbstractComponent<Props>);
export { Title as TitleTest };
