import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { AlertsSortBtn } from '@scripts/reusable/Table/components/defaults';
import { SORT_DIRECTION } from '@scripts/constants';
import FilterDropdown from './FilterDropdown';


const TextHeaderCell = ({ rKey, title, filterData, updateFilterDropdownData, sorting, handleSorting, isColumnFilterActive, handleTextTruncation }) => {
    const cellName = useMemo(() => title.toLowerCase(), [title]);
    const getActiveClass = (sortingOptions) => {
        if (!sortingOptions.column || sortingOptions.column !== title) return '';
        return sorting.direction === SORT_DIRECTION.DESC ? 'la-TriangleUp-active' : 'la-TriangleDown-active';
    };
    const handleChangeSorting = (e) => {
        let sortingInfo = {
            column: e.target.dataset.sortedby,
            direction: e.target.dataset.direction,
        };
        handleSorting(sortingInfo.column, sortingInfo.direction);
    }
    return (
        <div key={rKey} data-testid={`user-preferences-category-container__authorities__simple-table__text-header-cell__${cellName}`} className='user-preferences-category-container__authorities__simple-table__text-header-cell'>
            <FormattedMessage id={`UserPreferences_topic_sources_sanctionsWatchLists.table.columns.${cellName}`} />
            <AlertsSortBtn
                className={getActiveClass(sorting)}
                onClick={handleChangeSorting}
                direction={
                    sorting.direction === SORT_DIRECTION.DESC ? SORT_DIRECTION.ASC : SORT_DIRECTION.DESC
                }
                sortedBy={title}
            />
            {
                // The filter dropdown was hidden because the country data is incomplete. See DIL-9663
            }
            {cellName !== 'country' && <FilterDropdown cellName={cellName} filterDropdownData={filterData} updateFilterDropdownData={updateFilterDropdownData} isColumnFilterActive={isColumnFilterActive} handleTextTruncation={handleTextTruncation}/>}
        </div>
    )
}

export default TextHeaderCell;