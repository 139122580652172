import {
    ADDITIONAL_MESSAGES_BE,
    CATEGORY_SEARCH_STATE,
    CONTENT_TYPES_BE,
    SEARCH_TYPES,
    BAD_REQUEST,
    INTERNAL_USER,
} from '../constants/metricConstants';

export function processDataForMetricGather({ searchType, start, end, userPermId, userCustomerClass }) {
    let data = [];
    const isInternal = userCustomerClass.toLowerCase() === INTERNAL_USER;

    if (start) {
        if (end) {
            const entries = Object.entries(end);

            if (entries && entries.length) {
                let entriesWithError = [];

                entries.forEach((entry) => {
                    const elapsedTime = entry[1].timeStamp - start;

                    if (CONTENT_TYPES_BE[entry[0]]) {
                        let timedMetricType = `${SEARCH_TYPES[searchType]}_${CONTENT_TYPES_BE[entry[0]]}${
                            ADDITIONAL_MESSAGES_BE.LOAD
                        }`;

                        if (entry[1].statusCode && entry[1].statusCode !== BAD_REQUEST) {
                            timedMetricType += `${ADDITIONAL_MESSAGES_BE.ERROR}`;
                            entriesWithError.push(elapsedTime);
                        }

                        data.push({ elapsedTime, timedMetricType, isInternal });
                    }
                });

                let searchCompleteLoadMetric = `${SEARCH_TYPES[searchType]}${ADDITIONAL_MESSAGES_BE.COMPLETE_LOAD}`;
                let searchMetricLoadState = CATEGORY_SEARCH_STATE.completeLoad;
                const searchCompleteLoadTime = Math.max.apply(
                    Math,
                    data.map((entry) => entry.elapsedTime)
                );

                if (entriesWithError.length) {
                    if (entriesWithError.length === entries.length) {
                        searchCompleteLoadMetric += `${ADDITIONAL_MESSAGES_BE.ERROR}`;
                        searchMetricLoadState = `${CATEGORY_SEARCH_STATE.completeLoad}${ADDITIONAL_MESSAGES_BE.ERROR}`;
                    }

                    const searchErrorMetric = `${SEARCH_TYPES[searchType]}${ADDITIONAL_MESSAGES_BE.SEARCH}${ADDITIONAL_MESSAGES_BE.ERROR}`;
                    const searchErrorTime = Math.max.apply(Math, entriesWithError);

                    data.push({ elapsedTime: searchErrorTime, timedMetricType: searchErrorMetric, isInternal });
                    data.push({
                        elapsedTime: searchErrorTime,
                        timedMetricType: CATEGORY_SEARCH_STATE.searchError,
                        userPermId,
                        isInternal,
                    });
                }

                data.push({
                    elapsedTime: searchCompleteLoadTime,
                    timedMetricType: searchMetricLoadState,
                    userPermId,
                    isInternal,
                });
                data.push({
                    elapsedTime: searchCompleteLoadTime,
                    timedMetricType: searchCompleteLoadMetric,
                    isInternal,
                });
            }
        }
    }

    return data;
}

export function areAllCategoriesLoaded(searchResults) {
    if (searchResults) {
        if (Object.keys(searchResults).length) {
            const enabledCategories = Object.keys(searchResults).filter((key) => searchResults[key].enabled);
            const loadedCategories = Object.keys(searchResults).filter(
                (key) => searchResults[key].enabled && searchResults[key].loaded
            );

            return enabledCategories.length === loadedCategories.length;
        }
    }

    return false;
}
