import React from 'react';
import {
    CATEGORY_NAMES,
    MODAL_TYPE,
    NO_DOCUMENTS_FOUND_DOC_ID,
    NO_DOCUMENTS_OF_INTEREST,
    POPUP_SIZE_LARGE,
    REPORT_EXPIRATION_DATE_RANGE,
    REPORT_EXPIRATION_DATE_RANGE_PUBLIC_RECORDS,
    DRAG_AND_DROP,
} from '@constants';
import ReportDocumentNote from './ReportDocumentNote';
import PopupModel from '@reusable/PopupModal/PopupBuilder';
import popupModelActions from '@reusable/PopupModal/redux/PopupModel.actions';
import reduxStore from '@reduxStore';
import MainSearchApi from '@MainSearch/MainSearch.api';
import utils from '@utils/utilities';
import formatRichMessage from '@utils/formatRichMessage';
import { FormattedMessage, injectIntl } from 'react-intl';
import uboApi from '@reusable/UBO/UBO.api';
import { withDragAndDrop } from 'scripts/reusable/HOC/withDragAndDrop';

class DocumentItem extends React.Component {
    constructor(props) {
        super(props);

        this.openArticleNote = this.openArticleNote.bind(this);
        this.showConfirmationModalforDelete = this.showConfirmationModalforDelete.bind(this);
        this.previewArticle = this.previewArticle.bind(this);
        this.previewElasticArticle = this.previewElasticArticle.bind(this);
        this.previewUboArticle = this.previewUboArticle.bind(this);
        this.getNoDocumentsNote = this.getNoDocumentsNote.bind(this);
        this.handleAutosave = this.handleAutosave.bind(this);
    }

    openArticleNote(reportId, selectedSnippet) {
        this.getElasticDocument(this.props.article)
            .then((elasticArticle) => {
                elasticArticle.id = selectedSnippet.id;
                elasticArticle.documentId = selectedSnippet.documentId;
                elasticArticle.note = selectedSnippet.note;
                elasticArticle.category = selectedSnippet.reportSnippetType;
                elasticArticle.documentPreviewFiltering = selectedSnippet.uboFiltering;
                elasticArticle.publicRecordsNoDocumentsFound = selectedSnippet.publicRecordsNoDocumentsFound;
                let popupModel = new PopupModel.Builder(MODAL_TYPE.EDIT_ARTICLE_NOTE)
                    .setPopupProperty('isVisible', true)
                    .setPopupProperty('reportId', reportId)
                    .setPopupProperty('article', elasticArticle)
                    .setPopupProperty('onAutosave', this.handleAutosave)
                    .build();
                reduxStore.dispatch(popupModelActions.setPopupModel(popupModel));
            })
            .catch(() => {
                utils.showNotificationsMessage({
                    messageText: 'ViewDocument.article_not_found',
                    messageType: 'system-error',
                });
            });
    }

    handleAutosave({ show, hasError }) {
        const { categoryId } = this.props;
        if (show) {
            this.props.showAutosaveMessage(categoryId);
        } else {
            this.props.removeAutosaveMessage(hasError, categoryId);
        }
    }

    showConfirmationModalforDelete(event) {
        let popupType = event.currentTarget.value;
        let popupModel = new PopupModel.Builder(popupType)
            .setPopupProperty('isVisible', true)
            .setPopupProperty('reportId', this.props.report.id)
            .setPopupProperty('articles', [this.props.article.id])
            .setPopupProperty('documentId', this.props.article.documentId)
            .setPopupProperty('onAutosave', this.handleAutosave)
            .setPopupProperty('category', this.props.category.categoryName)
            .setPopupProperty('onUpdateReportBuilderPage', this.props.onUpdateReportBuilderPage)
            .build();
        reduxStore.dispatch(popupModelActions.setPopupModel(popupModel));
    }

    getElasticDocument(article) {
        // consul flag to use the new highlight endpoint
        let useNewHighlightForDocsByLIst = reduxStore.getState().user.useNewHighlightForDocsByList;

        if (this.props.category.categoryName === CATEGORY_NAMES.ESG_RATINGS) {
            return new Promise((resolve) => resolve({}));
        }

        if (utils.isUboDocumentSnippet(article)) {
            if (article.documentId === NO_DOCUMENTS_FOUND_DOC_ID || article.documentId === NO_DOCUMENTS_OF_INTEREST) {
                return new Promise((resolve) => resolve({}));
            } else {
                return uboApi.fetchUbo(
                    article.documentId,
                    article.uboTradeUp,
                    article.title,
                    article.billingId,
                    article.costCode
                );
            }
        } else {
            let params = {
                id: useNewHighlightForDocsByLIst ? article.id : article.documentId,
                searchQuery: this.props.report.searchQuery,
                searchQueryType: this.props.report.searchQueryType,
                prefilterQuery: article.prefilterQuery,
                category: this.props.category.categoryName,
                costCode: this.props.report.costCode,
            };

            return useNewHighlightForDocsByLIst
                ? MainSearchApi.getReportBuilderElasticDocument(params)
                : MainSearchApi.getElasticDocument(params);
        }
    }

    previewArticle(article, category) {
        if (utils.isUboDocumentSnippet(article)) {
            this.previewUboArticle(article);
        } else {
            this.previewElasticArticle(article, category);
        }
    }

    previewUboArticle(article) {
        let popupBuilder = new PopupModel.Builder(MODAL_TYPE.PREVIEW_UBO_ARTICLE)
            .setPopupProperty('isVisible', true)
            .setPopupProperty('isUboArticle', true);
        uboApi
            .fetchUbo(article.documentId, article.uboTradeUp, article.title, article.billingId, article.costCode)
            .then((uboArticle) => {
                const title = uboArticle.tableData[0] && uboArticle.tableData[0].subjectName;
                uboArticle = {
                    ...uboArticle,
                    documentPreviewFiltering: article.uboFiltering,
                    persistedNodes: article.persistedNodes,
                };
                popupBuilder.setPopupProperty('article', uboArticle);
                popupBuilder.setPopupProperty('popupSize', POPUP_SIZE_LARGE);
                popupBuilder.setPopupProperty('title', title);
                reduxStore.dispatch(popupModelActions.setPopupModel(popupBuilder.build()));
            })
            .catch(() => {
                let popupTitle = formatRichMessage(
                    { id: 'Report.DocumentItem.title.noDocumentAvailable' },
                    this.props.intl
                );
                popupBuilder.setPopupProperty('article', null);
                popupBuilder.setPopupProperty('title', popupTitle);
                reduxStore.dispatch(popupModelActions.setPopupModel(popupBuilder.build()));
            });
    }

    previewElasticArticle(article, category) {
        let popupBuilder = new PopupModel.Builder(MODAL_TYPE.PREVIEW_ARTICLE).setPopupProperty('isVisible', true);
        this.getElasticDocument(article)
            .then((elasticArticle) => {
                popupBuilder.setPopupProperty('article', elasticArticle);
                popupBuilder.setPopupProperty('category', category);
                reduxStore.dispatch(popupModelActions.setPopupModel(popupBuilder.build()));
            })
            .catch(() => {
                let popupTitle = formatRichMessage(
                    { id: 'Report.DocumentItem.title.noDocumentAvailable' },
                    this.props.intl
                );
                popupBuilder.setPopupProperty('article', null);
                popupBuilder.setPopupProperty('title', popupTitle);
                reduxStore.dispatch(popupModelActions.setPopupModel(popupBuilder.build()));
            });
    }

    getNoDocumentsNote(article) {
        let noDocumentsNote = formatRichMessage({ id: 'ReportBuilderPage_NoDocumentsOfInterest' }, this.props.intl);
        if (article.documentId === NO_DOCUMENTS_OF_INTEREST) {
            if (article.contentLanguage) {
                noDocumentsNote +=
                    ' - ' +
                    formatRichMessage(
                        { id: 'UserPreferences.negativeNews.language.' + article.contentLanguage.toLowerCase() },
                        this.props.intl
                    );
            } else if (article.customNewsTitle) {
                noDocumentsNote += ' - ' + article.customNewsTitle;
            }
        }

        return noDocumentsNote;
    }

    render() {
        const {
            article,
            connectDragSource,
            isDragging,
            connectDropTarget,
            index,
            articleOrderIndex,
            report,
            showDropTarget,
            droppedArticleId,
            insertAbove,
            category,
        } = this.props;
        let showDroppedAnimation = article.id === droppedArticleId;
        let daysInMiliseconds = utils.daysToMSConverter(REPORT_EXPIRATION_DATE_RANGE);
        let expiryDate = utils.formatDateForExpiryDate(utils.addXDaysToDate(article.createdDate, daysInMiliseconds));
        let id = article ? `article-${index}` : `report-${index}`;
        let { reportSnippetType } = article;
        let snippets = report.categories.find((category) => category.categoryName === reportSnippetType).snippets;
        let hasMultipleSnippets = snippets && snippets.length > 1;
        let isCurrentMenuVisible = this.props.visibleMenu === id;
        let isDragAndDropDisabled = this.props.visibleMenu !== null;
        const isPreviewDisabled = category.categoryName === CATEGORY_NAMES.ESG_RATINGS;

        if (article.reportSnippetType === CATEGORY_NAMES.PUBLIC_RECORDS) {
            daysInMiliseconds = utils.daysToMSConverter(REPORT_EXPIRATION_DATE_RANGE_PUBLIC_RECORDS);
            expiryDate = utils.formatDateForExpiryDate(utils.addXDaysToDate(article.createdDate, daysInMiliseconds));
        }

        let documentItem = (
            <div
                className={`reports-list-article-row-container ${isDragging ? 'dragging' : ''} ${
                    hasMultipleSnippets ? 'with-multiple-items' : ''
                } ${isCurrentMenuVisible ? 'selected-row' : ''} ${
                    isDragAndDropDisabled ? 'drag-and-drop-disabled' : ''
                }`}
            >
                <li
                    role="draggableDocument"
                    className={
                        'reports-list-article-row ' +
                        (isDragging ? 'dragging' : '') +
                        (showDropTarget ? (insertAbove ? ' drop-top ' : ' drop-bottom ') : '') +
                        (showDroppedAnimation ? 'dropped' : '')
                    }
                    id={'qa-document_' + this.props.category.categoryName + '_' + articleOrderIndex}
                    key={article.reportId}
                >
                    <div className="document-title-source-harvest-date">
                        {article.documentId === '-1' ||
                        article.documentId === NO_DOCUMENTS_OF_INTEREST ||
                        article.publicRecordsNoDocumentsFound ? (
                            <div className="document-title-index">
                                {hasMultipleSnippets && <div className="draggable-icon" />}
                                <div className="index-of-report-article">
                                    {utils.formatNumbersByUserLocale(this.props.language, articleOrderIndex + 1)}
                                </div>
                                {article.documentId === '-1' && (
                                    <div className="reports-list-no-article-title">
                                        <FormattedMessage id={'ReportBuilderPage_NoDocumentsFound'} />
                                    </div>
                                )}
                                {article.publicRecordsNoDocumentsFound && (
                                    <div
                                        className="reports-list-no-article-title"
                                        dangerouslySetInnerHTML={{ __html: article.title }}
                                    ></div>
                                )}
                                {article.documentId === NO_DOCUMENTS_OF_INTEREST && (
                                    <div className="reports-list-no-article-title">
                                        {this.getNoDocumentsNote(article)}
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div>
                                <div className="document-title-index">
                                    {hasMultipleSnippets && <div className="draggable-icon" />}
                                    <div className="index-of-report-article">
                                        {utils.formatNumbersByUserLocale(this.props.language, articleOrderIndex + 1)}
                                    </div>
                                    {
                                        <div
                                            to="/article"
                                            onClick={() =>
                                                !isPreviewDisabled &&
                                                this.previewArticle(article, this.props.category.categoryName)
                                            }
                                            className={`reports-list-article-title ${
                                                isPreviewDisabled ? 'disabled-click' : ''
                                            }`}
                                            dangerouslySetInnerHTML={{ __html: article.title }}
                                        />
                                    }
                                </div>
                                <div className="document-source-harvest-date">
                                    <div>{article.source}</div>
                                    {article.publishedDate ? <div className="vertical-divider" /> : null}
                                    <div>{article.publishedDate}</div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="reports-list-article-date">{expiryDate}</div>
                    <div className="reports-list-article-row-actions">
                        <button
                            value={MODAL_TYPE.DELETE_DOCUMENT_FROM_REPORT}
                            id={
                                'qa-document-delete-button_' +
                                this.props.category.categoryName +
                                '_' +
                                articleOrderIndex
                            }
                            onClick={this.showConfirmationModalforDelete}
                            className="report-list-article-action-button"
                        >
                            <FormattedMessage id="Report.DocumentItem.deleteDocument" />
                        </button>
                        {article.note && article.note.length > 0 ? null : <div className="vertical-divider" />}
                        <button
                            id={
                                'qa-document-add-note-button_' +
                                this.props.category.categoryName +
                                '_' +
                                articleOrderIndex
                            }
                            disabled={article.note && article.note.length > 0}
                            onClick={() => {
                                this.openArticleNote(report.id, article);
                            }}
                            className="report-list-article-action-button add-note"
                            data-track={`document-add-note-button-${this.props.category.categoryName}-${articleOrderIndex}`}
                        >
                            <FormattedMessage id="Report.DocumentItem.addNoteToDocument" />
                        </button>
                    </div>
                </li>

                {article.note && article.note.length > 0 && (
                    <ReportDocumentNote
                        handleDeleteNoteArticle={this.showConfirmationModalforDelete}
                        openArticleNote={this.openArticleNote}
                        article={article}
                        isDragging={isDragging}
                        report={report}
                        categoryName={this.props.category.categoryName}
                    />
                )}
            </div>
        );

        // Only allow drag-and-drop when having multiple snippets and when drag&drop is allowed
        return hasMultipleSnippets && !isDragAndDropDisabled
            ? connectDragSource(connectDropTarget(documentItem))
            : documentItem;
    }
}

const dndHookInfo = {
    componentType: 'child',
    accept: DRAG_AND_DROP.ARTICLE,
};

export default injectIntl(withDragAndDrop(dndHookInfo)(DocumentItem));

export { DocumentItem as TestDocumentItem };
