/**
 * START PAGE ENTRY POINT
 *
 * @version 0.1
 */

import React from 'react';
import { withRouter } from 'react-router';

import IconButtonSearchType from '@reusable/IconButtonSearchType/IconButtonSearchType';
import ToggleForFuzzyName from '@reusable/ToggleForFuzzyNames/ToggleForFuzzyName';
import userPreferencesActions from '@UserPreferences/redux/UserPreferences.actions';
import TabbedContent from '@reusable/TabbedContent/TabbedContent';
import BooleanTerms from '@reusable/BooleanTerms/BooleanTerms';
import AdHocSearch from '@reusable/AdHocSearch/AdHocSearch';
import adHocSearchActions from '@reusable/AdHocSearch/redux/AdHocSearch.actions';
import historyActions from '../HistoryPage/redux/History.actions';
import { FormattedMessage, injectIntl } from 'react-intl';
import searchParamsActions from '@MainSearch/redux/SearchParams.actions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import investigationActions from '@MainSearch/redux/Investigation.actions';
import LoginHistoryBanner from '@reusable/LoginHistoryBanner/LoginHistoryBanner';
import {
    DISPLAY_RISK_SCORES,
    EDIT_SEARCH_PAGE_TITLE,
    EDIT_SEARCH_URL,
    HOMEPAGE_PAGE_TITLE,
    MODAL_TYPE,
    PERSON_SEARCH,
    PREFERENCES_KEY,
} from '@constants';
import uboActions from './redux/Ubo.actions';
import StartPageSearchBar from './components/StartPageSearchBar';
import editSearchActions from './redux/EditSearch.actions';
import actions from '@pages/UserPreferances/redux/UserPreferences.actions';
import ToggleForSuggestedNames from '@reusable/SuggestedNames/SuggestedNames';
import suggestedNamesActions from '@reusable/SuggestedNames/redux/SuggestedNames.actions';
import withPreferenceRefresh from '../Main/hoc/withPreferenceRefresh';
import PopupModel from '@reusable/PopupModal/PopupBuilder';
import popupModelActions from '@reusable/PopupModal/redux/PopupModel.actions';
import userPreferencesUtils from '@utils/userPreferencesUtils';
import formatRichMessage from '@utils/formatRichMessage';
import mainActions from '@pages/Main/Main.actions';
import { BREADCRUMBS_PREV_PATH, LAUNCHED_SEARCH_FROM } from 'scripts/constants';

class StartPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            visible: false,
        };

        if (window._paq) {
            window._paq.push(['setDocumentTitle', 'Homepage']);
            window._paq.push(['trackPageView']);
        }
    }

    handleVisibility = () => {
        this.setState({ visible: !this.state.visible });
    };

    componentDidMount() {
        document.title = EDIT_SEARCH_PAGE_TITLE;
        if (this.props.location.pathname !== EDIT_SEARCH_URL) {
            document.title = HOMEPAGE_PAGE_TITLE;
            this.props.resetFuzzyNames();
            this.props.resetSuggestedNames();
            this.props.updateAdHocSearchObject({});
            this.props.updateHistoryCategory('');
            this.props.updateCustomFuzzyFlag(false);
            this.props.emptyEditSearch({});
        }
        window.scroll(0, 0);

        if (
            this.props.location.pathname === EDIT_SEARCH_URL &&
            this.props.prevPath === BREADCRUMBS_PREV_PATH.SCREENING_PAGE
        ) {
            this.props.searchLaunchedFrom(LAUNCHED_SEARCH_FROM.EDIT_SEARCH_PAGE_FROM_SCREENING);
        } else {
            this.props.resetLaunchedFrom();
        }
    }

    updatePreferencesDateRanges = () => {
        if (this.areAdhocDatesAvailable()) {
            this.props.updateDateRange(this.props.adHocSearch.newsDateRange.range, 'newsSources');
            this.props.updateDateRange(this.props.adHocSearch.companyDateRange.range, 'companySources');
            this.props.updateDateRange(this.props.adHocSearch.legalDateRange.range, 'legalSources');

            return {
                newsSources: { ...this.props.prefNewsSources, dateRange: this.props.adHocSearch.newsDateRange.range },
                companySources: {
                    ...this.props.prefCompanySources,
                    dateRange: this.props.adHocSearch.newsDateRange.range,
                },
                legalSources: { ...this.props.prefLegalSources, dateRange: this.props.adHocSearch.newsDateRange.range },
            };
        }

        return {};
    };

    areAdhocDatesAvailable = () => {
        const adHocNewsDateRange = this.props.adHocSearch.newsDateRange;
        const adHocCompanyDateRange = this.props.adHocSearch.companyDateRange;
        const adHocLegalDateRange = this.props.adHocSearch.legalDateRange;

        return !!adHocNewsDateRange && !!adHocCompanyDateRange && !!adHocLegalDateRange;
    };

    togglePreferencesCheckbox = () => {
        if (this.areAdhocDatesAvailable()) {
            return (
                this.props.newsDateRange !== this.props.adHocSearch.newsDateRange.range ||
                this.props.companyDateRange !== this.props.adHocSearch.companyDateRange.range ||
                this.props.legalDateRange !== this.props.adHocSearch.legalDateRange.range
            );
        }
        return false;
    };

    refreshPreferencesIfNeeded = () => {
        return this.props.checkForUpdatesAndUpdatePreferences(this.props.user.preferences.payload, true, false);
    };

    updateRiskScores = (displayRiskScore) => {
        this.props.updateRiskScores(displayRiskScore);
        userPreferencesUtils.saveRiskScores(displayRiskScore);
    };

    displayRiskScoresPopup = () => {
        if (this.props.popupModel && this.props.popupModel.popupType !== MODAL_TYPE.APPLY_RISK_SCORES_PREFERENCE) {
            let popupModel = new PopupModel.Builder(MODAL_TYPE.APPLY_RISK_SCORES_PREFERENCE)
                .setPopupProperty('isVisible', true)
                .setPopupProperty('acceptAction', () => this.updateRiskScores(DISPLAY_RISK_SCORES.SHOW))
                .setPopupProperty('rejectAction', () => this.updateRiskScores(DISPLAY_RISK_SCORES.HIDE))
                .setPopupProperty('searchQueryType', this.props.searchParams.searchType)
                .setPopupProperty('userLanguage', this.props.language)
                .build();

            this.props.setPopupModel(popupModel);
        }
    };

    componentWillUnmount() {
        if (this.props.popupModel && this.props.popupModel.popupType === MODAL_TYPE.APPLY_RISK_SCORES_PREFERENCE) {
            this.props.clearPopup();
        }
    }

    render() {
        let termsLabel = formatRichMessage({ id: 'StartPage.tabLabel.terms' }, this.props.intl);
        let { searchType } = this.props.searchParams;
        let customQuery = this.props[PREFERENCES_KEY[searchType]].customQuery;
        let newsSearchSources = this.props[PREFERENCES_KEY[searchType]].newsSearchSources;

        const shouldShowPreferencesCheckbox = this.togglePreferencesCheckbox();

        return (
            <div className="start-page-wrapper notranslate">
                {this.props.displayRiskScores === DISPLAY_RISK_SCORES.NO_CHOICE && this.displayRiskScoresPopup()}
                <div className="start-page-container">
                    <LoginHistoryBanner
                        firstName={this.props.firstName}
                        lastName={this.props.lastName}
                        loginHistoryLink={this.props.loginHistoryLink}
                        lastLoginDate={this.props.lastLoginDate}
                        showLoginBanner={this.props.showLoginHistoryBanner}
                        hideLoginHistoryBanner={this.props.hideLoginHistoryBanner}
                    />
                    <IconButtonSearchType
                        searchType={this.props.searchType}
                        changeSearchType={this.props.changeSearchType}
                        userPreferencesSearchType={this.props.userPreferencesSearchType}
                        updateAdHocSearchObject={this.props.updateAdHocSearchObject}
                        location={this.props.location}
                    />
                    <AdHocSearch
                        contentTypes={this.props.contentTypes}
                        popupModel={this.props.popupModel}
                        lawSources={this.props.lawSources}
                        lawSource={this.props.lawSource}
                        newsSources={this.props.newsSources}
                        adHocSearch={this.props.adHocSearch}
                        costCodes={this.props.costCodesList}
                        costCode={this.props.selectedCostCode}
                        chooseOnlyFromCostCodesList={this.props.chooseOnlyFromCostCodesList}
                        isCostCodeRequired={this.props.isCostCodeRequired}
                        searchType={searchType}
                        customQuery={customQuery}
                        newsSearchSources={newsSearchSources}
                        userLocks={this.props.userLocks}
                        personCustomNewsQuery={this.props.personCustomNewsQuery}
                        companyCustomNewsQuery={this.props.companyCustomNewsQuery}
                        adminPersonCustomNewsQuery={this.props.adminPersonCustomNewsQuery}
                        adminCompanyCustomNewsQuery={this.props.adminCompanyCustomNewsQuery}
                        lnPersonCustomNewsQuery={this.props.lnPersonCustomNewsQuery}
                        lnCompanyCustomNewsQuery={this.props.lnCompanyCustomNewsQuery}
                        searchNamePartyEntity={this.props.searchNamePartyEntity}
                        searchBarContentTypes={this.props.searchBarContentTypes}
                        refreshPreferencesIfNeeded={this.refreshPreferencesIfNeeded}
                    />
                    <StartPageSearchBar
                        contentTypes={this.props.contentTypes}
                        adHocSearchSources={this.props.adHocSearch.sources}
                        searchBarContentTypes={this.props.searchBarContentTypes}
                        lawSources={this.props.lawSources}
                        lawSource={this.props.lawSource}
                        newsSources={this.props.newsSources}
                        adHocSearch={this.props.adHocSearch}
                        costCodes={this.props.costCodesList}
                        costCode={this.props.selectedCostCode}
                        chooseOnlyFromCostCodesList={this.props.chooseOnlyFromCostCodesList}
                        isCostCodeRequired={this.props.isCostCodeRequired}
                        userLocks={this.props.userLocks}
                        searchType={searchType}
                        customQuery={customQuery}
                        newsSearchSources={newsSearchSources}
                        personCustomNewsQuery={this.props.personCustomNewsQuery}
                        companyCustomNewsQuery={this.props.companyCustomNewsQuery}
                        adminPersonCustomNewsQuery={this.props.adminPersonCustomNewsQuery}
                        adminCompanyCustomNewsQuery={this.props.adminCompanyCustomNewsQuery}
                        lnPersonCustomNewsQuery={this.props.lnPersonCustomNewsQuery}
                        lnCompanyCustomNewsQuery={this.props.lnCompanyCustomNewsQuery}
                        searchNamePartyEntity={this.props.searchNamePartyEntity}
                        searchParams={this.props.searchParams}
                        userPreferencesSearchType={this.props.userPreferencesSearchType}
                        fuzzyNames={this.props.fuzzyNames}
                        suggestedNames={this.props.suggestedNames}
                        isSnapshotVisible={this.props.isSnapshotVisible}
                        isFuzzyEnabled={this.props.isFuzzyEnabled}
                        changeQuery={this.props.changeQuery}
                        updateBooleanTerms={this.props.updateBooleanTerms}
                        setBooleanTerms={this.props.setBooleanTerms}
                        resetQuery={this.props.resetQuery}
                        setBillingId={this.props.setBillingId}
                        selectedCostCode={this.props.selectedCostCode}
                        updateCustomFuzzyFlag={this.props.updateCustomFuzzyFlag}
                        onSubmit={this.props.onSubmit}
                        fuzzyOn={this.props.fuzzyOn}
                        fuzzyThreshold={this.props.fuzzyThreshold}
                        updateAdHocProperty={this.props.updateAdHocProperty}
                        shouldShowPreferencesCheckbox={shouldShowPreferencesCheckbox}
                        updatePreferencesDateRanges={this.updatePreferencesDateRanges}
                        dateRanges={this.props.dateRanges}
                        newsDateRange={this.props.newsDateRange}
                        legalDateRange={this.props.legalDateRange}
                        companyDateRange={this.props.companyDateRange}
                        newFuzzyEnabled={this.props.newFuzzyEnabled}
                        suggestedNamesEnabled={this.props.suggestedNamesEnabled}
                        loadedSuggestionsForTerms={this.props.suggestedNames.loadedSuggestionsForTerms}
                        resetSuggestedNames={this.props.resetSuggestedNames}
                        isSuggestedNamesPreferenceEnabled={this.props.isSuggestedNamesEnabled}
                        refreshPreferencesIfNeeded={this.refreshPreferencesIfNeeded}
                        userOfferings={this.props.userOfferings}
                    />
                    <ToggleForFuzzyName
                        searchParams={this.props.searchParams}
                        isFuzzyEnabled={this.props.isFuzzyEnabled}
                        isFuzzyLoaded={this.props.isFuzzyLoaded}
                        isCustomFuzzy={this.props.searchParams.isCustomFuzzy}
                        searchType={this.props.searchType}
                        fuzzyNames={this.props.fuzzyNames}
                        onFuzzySelectionChange={this.props.toggleFuzzySelection}
                        onAllSelectionChange={this.props.toggleAllFuzzySelection}
                        newFuzzyEnabled={this.props.newFuzzyEnabled}
                    />
                    {this.props.suggestedNamesEnabled && this.props.searchType === PERSON_SEARCH && (
                        <ToggleForSuggestedNames
                            searchParams={this.props.searchParams}
                            isSuggestedNamesPreferenceEnabled={this.props.isSuggestedNamesEnabled}
                            isSuggestedNamesLoaded={this.props.suggestedNames.isSuggestedNamesLoaded}
                            hasExceededSuggestedNames={this.props.suggestedNames.hasExceededSuggestedNames}
                            suggestedNames={this.props.suggestedNames}
                            onSuggestedNamesSelectionChange={this.props.updateSuggestedNamesSelection}
                            onAllSuggestedNamesSelectionChange={this.props.updateAllSuggestedNamesSelection}
                        />
                    )}
                    <div className="more-data-link">
                        {this.state.visible ? <div className="la-TriangleUp" /> : <div className="la-TriangleDown" />}
                        <span>
                            <button className="text-as-button" onClick={this.handleVisibility}>
                                {this.state.visible ? (
                                    <span
                                        className="more-data-link-label opened"
                                        data-track="fewer-search-options-toggle"
                                    >
                                        <FormattedMessage id="StartPage.lessSearchOption" />
                                    </span>
                                ) : (
                                    <span className="more-data-link-label" data-track="more-search-options-toggle">
                                        <FormattedMessage id="StartPage.moreSearchOption" />
                                    </span>
                                )}
                            </button>
                        </span>
                    </div>
                    <TabbedContent
                        visible={this.state.visible}
                        defaultTabIndex={0}
                        tabs={[
                            {
                                label: termsLabel,
                                content: (
                                    <BooleanTerms
                                        query={this.props.query}
                                        updateBooleanTerms={this.props.updateBooleanTerms}
                                    />
                                ),
                            },
                        ]}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        searchType: state.searchParams.searchType,
        userPreferencesSearchType: state.user.preferences.generalSettings.searchType,
        contentTypes: state.user.preferences.generalSettings.contentTypes,
        searchBarContentTypes: state.searchBar.sourcesDropdown.sources,
        searchParams: state.searchParams,
        fuzzyNames: state.fuzzyNames,
        suggestedNames: state.suggestedNames,
        isSnapshotVisible: state.user.preferences.generalSettings.showSnapshot,
        isFuzzyEnabled: state.user.preferences.generalSettings.isFuzzyEnabled,
        isFuzzyLoaded: state.user.preferences.generalSettings.isFuzzyLoaded,

        popupModel: state.popupModel,
        dateRanges: state.user.preferences.generalSettings.dateRanges,
        newsDateRange: state.user.preferences.generalSettings.newsSources.dateRange,
        legalDateRange: state.user.preferences.generalSettings.legalSources.dateRange,
        companyDateRange: state.user.preferences.generalSettings.companySources.dateRange,
        lawSources: state.user.preferences.generalSettings.lawSources,
        lawSource: state.user.preferences.generalSettings.legalSources.defaultDateSource,
        adHocSearch: state.adHocSearch,
        selectedCostCode: state.user.preferences.generalSettings.selectedCostCode,
        costCodesList: state.user.preferences.generalSettings.costCodesList,
        chooseOnlyFromCostCodesList: state.user.preferences.generalSettings.chooseOnlyFromCostCodesList,
        isCostCodeRequired: state.user.preferences.generalSettings.isCostCodeRequired,
        query: state.searchParams.query,
        firstName: state.user.firstName,
        lastName: state.user.lastName,
        loginHistoryLink: state.user.loginHistoryData.loginHistoryLink,
        lastLoginDate: state.user.loginHistoryData.lastLoginDate,
        showLoginHistoryBanner: state.user.loginHistoryData.showLoginHistoryBanner,
        personCheck: state.user.preferences.personCheck,
        companyCheck: state.user.preferences.companyCheck,
        userLocks: state.user.preferences.userLocks,
        searchNamePartyEntity: state.user.preferences.generalSettings.legalSources.searchNamePartyEntity,

        personCustomNewsQuery: state.user.preferences.personCheck.customNewsQuery,
        companyCustomNewsQuery: state.user.preferences.companyCheck.customNewsQuery,
        adminPersonCustomNewsQuery: state.user.preferences.personCheck.adminCustomNewsQuery,
        adminCompanyCustomNewsQuery: state.user.preferences.companyCheck.adminCustomNewsQuery,
        lnPersonCustomNewsQuery: state.user.preferences.personCheck.lnCustomNewsQueries,
        lnCompanyCustomNewsQuery: state.user.preferences.companyCheck.lnCustomNewsQueries,
        ubo: state.ubo,

        newFuzzyEnabled: state.user.newFuzzyEnabled,
        fuzzyOn: state.user.preferences.personCheck.fuzzyOn,
        fuzzyThreshold: state.user.preferences.personCheck.fuzzyThreshold,

        excludeNonBusinessNews: state.user.preferences.generalSettings.excludeNonBusinessNews,
        excludeNewsWires: state.user.preferences.generalSettings.excludeNewsWires,

        prefNewsSources: state.user.preferences.generalSettings.newsSources,
        prefLegalSources: state.user.preferences.generalSettings.legalSources,
        prefCompanySources: state.user.preferences.generalSettings.companySources,

        suggestedNamesEnabled: state.user.suggestedNamesEnabled,
        isSuggestedNamesEnabled: state.user.preferences.personCheck.suggestedNames,

        userPermId: state.user.userPermIdUnhashed,
        preferencesRefreshPeriod: state.user.preferencesRefreshPeriod,
        lastPreferencesUpdateInfo: state.user.lastPreferencesUpdateInfo,
        userOfferings: state.user.userOfferings,
        lastUserPreferencesRetrieval: state.user.preferences.lastUserPreferencesRetrieval,
        userPreferences: state.user.preferences.payload,
        user: state.user,
        displayRiskScores: state.user.preferences.generalSettings.displayRiskScores,
        language: state.user.preferences.language,
        prevPath: state.breadcrumbs.prevPath,
    };
};

const mapDispatchToProps = function (dispatch) {
    return bindActionCreators(
        {
            changeSearchType: searchParamsActions.updateQueryType,
            changeQuery: searchParamsActions.updateQueryString,
            updateBooleanTerms: searchParamsActions.updateBooleanTerms,
            setBooleanTerms: searchParamsActions.setBooleanTerms,
            resetQuery: searchParamsActions.resetQuery,
            updateCustomFuzzyFlag: searchParamsActions.updateCustomFuzzyFlag,
            setBillingId: investigationActions.setBillingId,
            removeFuzzyName: userPreferencesActions.removeFuzzyName,
            toggleFuzzySelection: userPreferencesActions.toggleFuzzySelection,
            toggleAllFuzzySelection: userPreferencesActions.toggleAllFuzzySelection,
            hideLoginHistoryBanner: userPreferencesActions.hideLoginHistoryBanner,
            resetFuzzyNames: userPreferencesActions.resetFuzzyNames,
            resetSuggestedNames: suggestedNamesActions.resetSuggestedNames,
            onSubmit: uboActions.submit,
            emptyEditSearch: editSearchActions.emptyEditSearch,
            updateHistoryCategory: historyActions.updateHistoryCategory,
            updateAdHocSearchObject: adHocSearchActions.updateAdHocSearchObject,
            updateAdHocProperty: adHocSearchActions.updateAdHocProperty,
            updateDateRange: actions.updateDateRange,
            updateSuggestedNamesSelection: suggestedNamesActions.updateSuggestedNamesSelection,
            updateAllSuggestedNamesSelection: suggestedNamesActions.updateAllSuggestedNamesSelection,
            updateRiskScores: actions.updateRiskScores,
            setPopupModel: popupModelActions.setPopupModel,
            clearPopup: popupModelActions.clearPopup,
            resetLaunchedFrom: mainActions.resetLaunchFrom,
            searchLaunchedFrom: mainActions.searchLaunchedFrom,
        },
        dispatch
    );
};

export { StartPage as TestStartPage };
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(withPreferenceRefresh(StartPage))));
