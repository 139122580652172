import React, { Fragment } from 'react';
import FilterItem from './FilterItem';
import { CATEGORY_SORT_FUNC } from '@constants';
import formatRichMessage from '@utils/formatRichMessage';
import utils from '@utils/utilities';
import categoryUtils, { withSearchResultsFilter } from '@utils/categoryUtils';
import { injectIntl } from 'react-intl';

const FilterList = (props) => {
    let filterList = [];
    Object.keys(props.searchResults).forEach((key) => {
        let searchCollection = props.searchResults[key];
        if (utils.isCategoryEnabled(key, props.searchResults) && !categoryUtils.isChild(key)) {
            let count = searchCollection.count; // will be used to override the count in case it is a parent
            let loaded = searchCollection.loaded;
            if (categoryUtils.hasChildren(key)) {
                // if parent, count is the sum of all its enabled children
                const childrenKeys = withSearchResultsFilter(props.searchResults).getCategoryKeys(key);
                count = 0;
                childrenKeys.forEach((childKey) => {
                    count += props.searchResults[childKey].count;
                    loaded &= props.searchResults[childKey].loaded;
                });
            }
            if (categoryUtils.getCategory(key)) {
                let customNewsTitle = utils.translateTitleForCustomNews(key);
                customNewsTitle = customNewsTitle.shouldBeTranslated
                    ? formatRichMessage(customNewsTitle.titleContent, props.intl)
                    : customNewsTitle.titleContent;

                filterList.push({
                    category: categoryUtils.getCategory(key),
                    articlesResult: { ...searchCollection, count, loaded },
                    title: customNewsTitle,
                });
            }
        }
    });

    filterList = filterList.sort((a, b) => CATEGORY_SORT_FUNC(a.category, b.category));

    return (
        <div className="category-checkbox-list">
            {filterList.map((filter, index) => (
                <Fragment key={index}>
                    <FilterItem
                        filter={filter}
                        language={props.language}
                        checked={
                            props.articleType === filter.category.key ||
                            categoryUtils.isChildOf(filter.category.key, props.articleType)
                        }
                        handleSelectCategory={(e) => {
                            props.handleSelectCategory(e, filter);
                        }}
                    />
                    {filter.category.children &&
                        categoryUtils.isChildOf(filter.category.key, props.articleType) &&
                        categoryUtils.getList(filter.category.key).map((subCategory) => {
                            if (utils.isCategoryEnabled(subCategory.key, props.searchResults)) {
                                let subCategoryTitleContent = utils.translateTitleForCustomNews(subCategory.key);
                                let subCategoryTitle = subCategoryTitleContent.shouldBeTranslated
                                    ? formatRichMessage(subCategoryTitleContent.titleContent, props.intl)
                                    : subCategoryTitleContent.titleContent;

                                return (
                                    <div className="legal-subcategory-checkbox" key={index + subCategory.key}>
                                        <FilterItem
                                            key={'subcategory_' + index}
                                            filter={{
                                                category: subCategory,
                                                articlesResult: props.searchResults[subCategory.key],
                                                title: subCategory.title || subCategoryTitle,
                                            }}
                                            language={props.language}
                                            searchResults={props.searchResults}
                                            checked={props.articleType === subCategory.key}
                                            handleSelectCategory={(e) => {
                                                props.handleSelectCategory(e, filter);
                                            }}
                                        />
                                    </div>
                                );
                            } else {
                                return null;
                            }
                        })}
                </Fragment>
            ))}
        </div>
    );
};

export default injectIntl(FilterList);
