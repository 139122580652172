import React from 'react';
import { ALL_ITEMS_LIST, SELECTED_ITEMS } from '@constants';
import formatRichMessage from '@utils/formatRichMessage';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

const ModalItemsList = (props) => {
    if (!props.isVisible) {
        return <span></span>;
    }

    return (
        <div className="popup-modal__result-list">
            <div className="radio-list">
                <div className="custom-radio">
                    <input
                        type="radio"
                        id="full_items"
                        checked={props.fullListCheckboxState}
                        onChange={props.itemSelectionChanged}
                        value={ALL_ITEMS_LIST}
                    />
                    <label htmlFor="full_items" className="radio-label">
                        <span
                            dangerouslySetInnerHTML={{
                                __html: formatRichMessage({ id: 'PopupModal.ModalItemsList.FullList' }, props.intl, {
                                    count: props.maxNumberOfHistoryEntries,
                                }),
                            }}
                        />
                    </label>
                </div>
                <div className="custom-radio">
                    <input
                        type="radio"
                        id="selected_doc"
                        disabled={props.selectedItemsCount === 0}
                        checked={!props.fullListCheckboxState}
                        onChange={props.itemSelectionChanged}
                        value={SELECTED_ITEMS}
                    />
                    <label htmlFor="selected_doc" className="radio-label">
                        <FormattedMessage id={'PopupModal.ModalItemsList.SelectedItems'} /> -{' '}
                        {props.selectedHistoryEntries}
                    </label>
                </div>
            </div>
        </div>
    );
};

ModalItemsList.propTypes = {
    isVisible: PropTypes.bool,
    selectedItemsCount: PropTypes.number,
    maxNumberOfHistoryEntries: PropTypes.number,
    fullListCheckboxState: PropTypes.bool,
    itemSelectionChanged: PropTypes.func,
};

ModalItemsList.defaultProps = {
    isVisible: false,
    selectedItemsCount: 0,
    fullListCheckboxState: true,
    maxNumberOfHistoryEntries: 200,
};

export default injectIntl(ModalItemsList);
