//@flow

import * as React from 'react';
import AdminLock from '@reusable/AdminLock/AdminLock';
import {
    CALENDAR_DATE_FORMAT_BE,
    CALENDAR_DATE_FORMAT_FE,
    CALENDAR_RANGE_DELIMITER,
    DATE_RANGE_MIXED,
    FE_LOCK_NAMES,
    RANGE_TYPE_CUSTOM,
} from '@constants';
import { FormattedMessage } from 'react-intl';
import { uniq } from 'lodash';
import { isCustomDateRange } from '@reusable/DateSelect/DateRangesDropdown';
import DateRangesDropDownSearchBar from './DateRangesDropDownSearchBar';
import type { DateRanges } from './flow/Dates.typeGuards';

type DateRangeGroupProps = {
    dateRanges: DateRanges,
    dateRange: string,
    handleChange: (date: string) => void,
    disabled: boolean,
    labelId: string,
    lockName: string,
    type: string,
    handleCustomDateSelection: () => Promise<any>,
};

type CategoriesDateRanges = {
    date: string,
    type: string,
    labelId: string,
    enabled: boolean,
};

type AllDatesProps = {
    categoriesDateRanges: Array<CategoriesDateRanges>,
    dateRanges: DateRanges,
    handleChange: (type: string, date: string) => void,
    disabled: boolean,
    handleCustomDateSelection: () => Promise<any>,
    locks: {
        all: boolean,
        newsDateRange: {
            value: boolean,
            name: string,
        },
        companyDateRange: {
            value: boolean,
            name: string,
        },
        legalDateRange: {
            value: boolean,
            name: string,
        },
    },
    allRanges: string,
};

type Props = {
    updateAdHocProperty: (property: string, value: any) => void,
    dateRanges: DateRanges,
    newsDateRange: string,
    legalDateRange: string,
    companyDateRange: string,
    userLocks: {
        categoriesDefaultDateRange: boolean,
        newsSearchesDateRange: boolean,
        companySource: boolean,
        legalSourceDefaultDateRange: boolean,
    },
    adminLocks: {
        all: boolean,
        newsDateRange: {
            value: boolean,
            name: string,
        },
        companyDateRange: {
            value: boolean,
            name: string,
        },
        legalDateRange: {
            value: boolean,
            name: string,
        },
    },
    handleDateChange: (type: string, date: string) => void,
    handleAllDatesChange: (date: string) => void,
    areLegalSourcesAvailable: boolean,
    areCompanySourcesAvailable: boolean,
};

type State = {
    showAllDates: boolean,
};

class DateRangesContent extends React.Component<Props, State> {
    state: State;

    constructor(props: Props) {
        super(props);

        this.state = {
            showAllDates: false,
        };
    }

    toggleDatesDisplay = (): void => {
        this.setState((prevState) => ({
            showAllDates: !prevState.showAllDates,
        }));
    };

    handleChange = (type: string, date: string): void => {
        this.props.handleDateChange(type, date);
    };

    handleBulkChange = (date: string): void => {
        this.props.handleAllDatesChange(date);
    };

    handleCustomDateSelection = (): Promise<any> => {
        this.forceUpdate();

        return new Promise((resolve) => {
            resolve();
        });
    };

    render(): React.Node {
        const categoriesDateRanges: Array<CategoriesDateRanges> = [
            {
                date: this.props.newsDateRange,
                type: 'newsDateRange',
                labelId: 'SearchBarDateDropdown.CategoriesDefaultDates.news',
                enabled: true,
            },
            {
                date: this.props.legalDateRange,
                type: 'legalDateRange',
                labelId: 'SearchBarDateDropdown.CategoriesDefaultDates.legal',
                enabled: this.props.areLegalSourcesAvailable,
            },
            {
                date: this.props.companyDateRange,
                type: 'companyDateRange',
                labelId: 'SearchBarDateDropdown.CategoriesDefaultDates.company',
                enabled: this.props.areCompanySourcesAvailable,
            },
        ];
        const availableCategoriesDateRanges = categoriesDateRanges.filter(
            (categoryDateRange) => categoryDateRange.enabled
        );
        const dateRange: string = reduceValue([
            this.props.newsDateRange,
            this.props.legalDateRange,
            this.props.companyDateRange,
        ]);
        const dateRanges: DateRanges = uniq([...this.props.dateRanges, dateRangeValue(dateRange)]);

        return (
            <div data-testid="dateRanges-dropdown-content-wrapper" className="dateRanges-dropdown-content-wrapper">
                <div className="dateRanges-dropdown-content">
                    <h5 data-testid="dateRanges-dropdown-content-header" className="dateRanges-dropdown-content-header">
                        <FormattedMessage id={'SearchBarDateDropdown.selectedItemsHeader'} />
                    </h5>

                    <div className="dateRanges-dropdown-content-body">
                        {availableCategoriesDateRanges.length > 1 && (
                            <DateRangeGroup
                                dateRanges={dateRanges}
                                dateRange={dateRange}
                                allRanges={dateRange}
                                handleChange={this.handleBulkChange}
                                disabled={!!this.props.adminLocks.all}
                                type={'all'}
                                handleCustomDateSelection={this.handleCustomDateSelection}
                                lockName={FE_LOCK_NAMES.categoriesDefaultDateRange}
                                labelId={'SearchBarDateDropdown.selectedAllCategoriesButton'}
                            />
                        )}
                        {(this.state.showAllDates || availableCategoriesDateRanges.length === 1) && (
                            <AllDates
                                categoriesDateRanges={availableCategoriesDateRanges}
                                handleChange={this.handleChange}
                                allRanges={dateRange}
                                handleCustomDateSelection={this.handleCustomDateSelection}
                                disabled={!!this.props.adminLocks.all}
                                locks={this.props.adminLocks}
                                dateRanges={dateRanges}
                            />
                        )}
                    </div>

                    {availableCategoriesDateRanges.length > 1 && (
                        <div
                            data-testid="dateRanges-dropdown-content-link"
                            className="dateRanges-dropdown-content-link"
                            onClick={this.toggleDatesDisplay}
                        >
                            {this.state.showAllDates ? (
                                <React.Fragment>
                                    <FormattedMessage
                                        id={'SearchBarDateDropdown.CategoriesDefaultDates.showLessCategories'}
                                        tagName={'span'}
                                    />
                                    <span className={'la-TriangleUp '} />
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <FormattedMessage
                                        id={'SearchBarDateDropdown.CategoriesDefaultDates.showMoreCategories'}
                                        tagName={'span'}
                                    />
                                    <span className={'la-TriangleDown'} />
                                </React.Fragment>
                            )}
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

const AllDates = ({
    categoriesDateRanges,
    dateRanges,
    handleChange,
    disabled,
    handleCustomDateSelection,
    locks,
    allRanges,
}: AllDatesProps) => {
    const handleDateChange = (type: string, date: string): void => {
        handleChange(type, date);
    };
    return categoriesDateRanges.map((categoryDate, index) => (
        <DateRangeGroup
            key={categoryDate.type + index}
            dateRanges={dateRanges}
            type={categoryDate.type}
            dateRange={categoryDate.date}
            handleChange={(date) => handleDateChange(categoryDate.type, date)}
            disabled={disabled || !!locks[categoryDate.type].value}
            lockName={FE_LOCK_NAMES[locks[categoryDate.type].name]}
            handleCustomDateSelection={handleCustomDateSelection}
            allRanges={allRanges}
            labelId={categoryDate.labelId}
        />
    ));
};

const DateRangeGroup = ({
    dateRanges,
    dateRange,
    handleChange,
    disabled,
    labelId,
    handleCustomDateSelection,
    lockName,
    type,
}: DateRangeGroupProps) => {
    let className = `${disabled ? ' disabled ' : ''} dateRanges-dropdown-content-body-date-group ${type}`;

    return (
        <div className={className}>
            <FormattedMessage id={labelId} tagName={'span'} />
            <div className="dropdown">
                <DateRangesDropDownSearchBar
                    dateRanges={dateRanges}
                    dateRange={dateRange}
                    onChange={handleChange}
                    type={type}
                    disabled={disabled}
                    handleCustomDateSelection={handleCustomDateSelection}
                    dateFormat={CALENDAR_DATE_FORMAT_FE}
                    dateFormatOutbound={CALENDAR_DATE_FORMAT_BE}
                    dateRangeDelimiter={CALENDAR_RANGE_DELIMITER}
                />
                {disabled && <AdminLock lockName={lockName} isDisabled={true} />}
            </div>
        </div>
    );
};

const reduceValue = (values: Array<string>): string => values.reduce(mixedReducer);

const mixedReducer = (accumulator: string, current: string): string =>
    accumulator === current ? accumulator : DATE_RANGE_MIXED;

const dateRangeValue = (dateRange: string): string => (isCustomDateRange(dateRange) ? RANGE_TYPE_CUSTOM : dateRange);

export default (DateRangesContent: React.AbstractComponent<Props>);
