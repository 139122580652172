/**
 * STORAGE
 *
 * This script represents a wrapper for the localStorage object. It provides APIs to store and retrieve
 * different types of data in/from the local storage.
 *
 * @author   Razvan Olaru
 * @version  0.1
 */

const storage = {
    set: function (key, value) {
        if (typeof key === 'string' && typeof value === 'string') {
            localStorage.setItem(key, value);
        }
    },

    get: function (key) {
        if (typeof key === 'string') {
            return localStorage.getItem(key);
        }

        return '';
    },

    remove: function (key) {
        if (typeof key === 'string') {
            localStorage.removeItem(key);
        }
    },

    setObject: function (key, value) {
        if (typeof key === 'string') {
            localStorage.setItem(key, JSON.stringify(value));
        }
    },

    getObject: function (key) {
        if (typeof key === 'string') {
            return JSON.parse(localStorage.getItem(key) || 'null');
        }
    },

    setInteger: function (key, value) {
        if (typeof key === 'string') {
            localStorage.setItem(key, parseInt(value, 10).toString());
        }
    },

    getInteger: function (key) {
        if (typeof key === 'string') {
            return parseInt(localStorage.getItem(key), 10);
        }
    },

    setFloat: function (key, value) {
        if (typeof key === 'string') {
            localStorage.setItem(key, parseFloat(value).toString());
        }
    },

    getFloat: function (key) {
        if (typeof key === 'string') {
            return parseFloat(localStorage.getItem(key));
        }
    },

    setBoolean: function (key, value) {
        if (typeof key === 'string') {
            localStorage.setItem(key, value ? 'true' : '');
        }
    },

    getBoolean: function (key) {
        if (typeof key === 'string') {
            return localStorage.getItem(key) ? true : false;
        }
    },
};

export default storage;
