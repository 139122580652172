import React, { Fragment, forwardRef } from 'react';
import ReactDOMServer from 'react-dom/server';
import ReactTooltip from 'react-tooltip';
import { FormattedMessage } from 'react-intl';
import BatchUploadFormatExample from './BatchUploadFormatExample';
import DragAndDropBox from '@reusable/DragAndDrop/DragAndDropBox';
import DragAndDropContent from '@reusable/DragAndDrop/DragAndDropContent';
import DragAndDropUploadedItem from '@reusable/DragAndDrop/DragAndDropUploadedItem';
import BatchUploadHelper from '../helpers/BatchUploadHelper';
import { UPLOAD_ENTITIES_VALIDATION_TYPES } from 'scripts/constants';
import { ReactComponent as BannerInfoIcon } from '../../../../assets/icons/BannerInfoIcon.svg';
import { ReactComponent as BannerInfoIconDark } from '../../../../assets/icons/BannerInfoIconDark.svg';
import { ReactComponent as BannerWarningIcon } from '../../../../assets/icons/BannerWarningIcon.svg';
import { ReactComponent as BannerWarningIconDark } from '../../../../assets/icons/BannerWarningIconDark.svg';
import { ReactComponent as BannerErrorIcon } from '../../../../assets/icons/BannerErrorIcon.svg';
import { ReactComponent as BannerErrorIconDark } from '../../../../assets/icons/BannerErrorIconDark.svg';

const ICON_TYPES = {
    EXCEL: 'excel',
};
const UPLOAD_STATUS = {
    SUCCESSFUL: 'completed',
};

const BatchUploadDragAndDrop = forwardRef(function BatchUploadDragAndDrop(props, ref) {
    const {
        intl: { formatMessage },
        isDarkMode,
    } = props;
    const firstRowAsHeader = {
        name: formatMessage({ id: 'BatchUpload.example.table.headers.name' }),
        type: formatMessage({ id: 'BatchUpload.example.table.headers.type' }),
    };
    const formatSampleHeaders = Array.from(Array(Object.keys(firstRowAsHeader).length)).map((_, index) =>
        String.fromCharCode(index + 65)
    );

    const formatSample = {
        headers: formatSampleHeaders,
        rows: [
            firstRowAsHeader,
            BatchUploadHelper.buildEntityUploadSampleRow(
                'Cardinal Health',
                'Company'
            ),
            BatchUploadHelper.buildEntityUploadSampleRow(
                'Microsoft OR MSFT',
                'Company'
            ),
            BatchUploadHelper.buildEntityUploadSampleRow(
                '"Julius Berger" AND Nigeria',
                'Person'
            ),
            BatchUploadHelper.buildEntityUploadSampleRow(
                'Angela Merkel',
                'Person'
            ),
        ],
    };
    const renderDnDChildren = () => {
        if (props.fileName) {
            return (
                <DragAndDropUploadedItem
                    iconType={ICON_TYPES.EXCEL}
                    uploadStatus={UPLOAD_STATUS.SUCCESSFUL}
                    fileName={BatchUploadHelper.getFormattedFilename(props.fileName)}
                    fileSize={props.fileSize}
                    onClick={props.onCancel}
                />
            );
        }

        return (
            <DragAndDropContent
                acceptedTypes={props.acceptedTypes}
                onChange={props.onChange}
                onClick={props.onClick}
                ref={ref}
                header={formatMessage({ id: 'BatchUpload.uploadSection.uploadTitle' })}
                browseMessage={formatMessage({ id: 'BatchUpload.uploadSection.browse' })}
                description={formatMessage({ id: 'BatchUpload.uploadSection.message' })}
            />
        );
    };

    const BatchTableFormattedAsString = ReactDOMServer.renderToString(
        <BatchUploadFormatExample
            infoLabel={formatMessage({ id: 'BatchUpload.example.table.title' })}
            headers={formatSample.headers}
            rows={formatSample.rows}
        />
    );

    const renderDescription = (messageId, values, multiline) => {
        const message = <FormattedMessage id={messageId} values={values} />;
        return multiline ? <li>{message}</li> : <p>{message}</p>;
    };

    const mappedMessages = (messages, multiline) => {
        if (multiline) {
            const description = messages.map((currMessage, index) => <Fragment key={`multiline-message-${index}`}>{renderDescription(currMessage.messageId, currMessage.values, multiline)}</Fragment>);
            return description;
        } else {
            return messages.map((mappedMessage, index) => {
                const message = mappedMessage.message ?? mappedMessage;
                const value = mappedMessage.value ?? {};
                return <div key={`entity-view-upload-message-${index}`}>
                    {renderDescription(message, value, multiline)}
                </div>
            }

            )
        }
    }

    const computeBannerIcon = (type, isDarkMode) => {
        if (type === UPLOAD_ENTITIES_VALIDATION_TYPES.info) {
            return isDarkMode ? <BannerInfoIconDark /> : <BannerInfoIcon />;
        } else if (type === UPLOAD_ENTITIES_VALIDATION_TYPES.warning) {
            return isDarkMode ? <BannerWarningIconDark /> : <BannerWarningIcon />;
        } else if (type === UPLOAD_ENTITIES_VALIDATION_TYPES.error) {
            return isDarkMode ? <BannerErrorIconDark /> : <BannerErrorIcon />;
        }
    }

    const renderBatchUploadFileMessages = (messages, type, multiline = false, isDarkMode) => {
        return (
            messages.length ? <div key={`batch-upload-message-${type}-${multiline}`} className={`embedded-message ${type} ${isDarkMode ? 'dark-mode' : ''} notranslate`}>
                <div className="embedded-message__icon">
                    {computeBannerIcon(type, isDarkMode)}
                </div>
                <div className="embedded-message__message">
                    <div style={{ overflowY: 'auto', maxHeight: '10rem' }}>
                        {mappedMessages(messages, multiline)}
                    </div>
                </div>
            </div> : null
        );
    }

    return (
        <>
            <div className='notifications-container'>
                {renderBatchUploadFileMessages(props.messages.errorMessage, UPLOAD_ENTITIES_VALIDATION_TYPES.error, false, isDarkMode)}
                {renderBatchUploadFileMessages(props.messages.multilineErrorMessage, UPLOAD_ENTITIES_VALIDATION_TYPES.error, true, isDarkMode)}
                {renderBatchUploadFileMessages(props.messages.warningMessage, UPLOAD_ENTITIES_VALIDATION_TYPES.warning, false, isDarkMode)}
                {renderBatchUploadFileMessages(props.messages.infoMessage, UPLOAD_ENTITIES_VALIDATION_TYPES.info, false, isDarkMode)}
            </div>
            <div className="batch-upload-drag-and-drop" >
                <DragAndDropBox disabled={!!props.fileName} isValid={props.isValid} onDrop={props.onDrop}>
                    {renderDnDChildren()}
                </DragAndDropBox>
                <div className="batch-upload-actions">
                    <div className="batch-upload-actions__download-template">
                        <a href="/assets/download/Sample_File.xlsx" download>{`${formatMessage({
                            id: 'BatchUpload.uploadSection.downloadTemplate',
                        })} (.xls)`}</a>
                        <span className="la-InfoHover" data-tip={BatchTableFormattedAsString} data-for="info-icon"></span>
                        <ReactTooltip
                            id="info-icon"
                            className="table-format-example-tooltip"
                            effect="solid"
                            type="light"
                            place="bottom"
                            html={true}
                            offset={{ right: 190 }}
                            event="click"
                            globalEventOff="click"
                        />
                    </div>
                </div>
            </div >
        </>
    );
});

export default BatchUploadDragAndDrop;
