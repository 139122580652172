//@flow
import * as React from 'react';
import { injectIntl } from 'react-intl';
import TruncatedTitle from '@reusable/TruncatedElements/TruncatedTitle';
import formatRichMessage from '@utils/formatRichMessage';

type Props = {
    id: string,
    index: number,
    title: string,
    reportType: string,
    hasUpdates: ?boolean,
    toolTipData: ReportTitleTooltipData | string,
    countMessage: string,
};

type InjectedProps = {
    +intl: Object,
};

export type ReportTitleTooltipData = {
    message: string,
    terms: {
        combinedReportTitle: string,
        childReportsTitles: string,
    },
};

const ReportTitle = (props: Props & InjectedProps): React$Element<'div'> => {
    const { index, title, toolTipData, intl, countMessage } = props;
    const formattedTooltipData =
        typeof toolTipData === 'object'
            ? formatRichMessage({ id: toolTipData.message }, intl, { ...toolTipData.terms })
            : toolTipData;
    const truncatedTextRef: { current: null } = React.createRef();

    return (
        <div className="report-title">
            <div id={`qa-report-title_${index}`} className="report-list-title">
                <div className="title">
                    <TruncatedTitle
                        id="truncated-title"
                        text={title}
                        tooltipData={formattedTooltipData}
                        truncatedTextRef={truncatedTextRef}
                    />
                </div>
                <span className="count" data-testid="documentsCount">
                    {countMessage}
                </span>
            </div>
        </div>
    );
};

export { ReportTitle as TestReportTitle };
export default (injectIntl(ReportTitle): React$StatelessFunctionalComponent<Props>);
