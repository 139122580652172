import marketing_overlay_bg_1 from '../../../../../marketingOverlayBg_1.svg';
import marketing_overlay_bg_2 from '../../../../../marketingOverlayBg_2.svg';
import marketing_overlay_bg_3 from '../../../../../marketingOverlayBg_3.svg';

export const modalTitles = [
    'Marketing_overlay.Diligence_plus_title_1',
    'Marketing_overlay.Diligence_plus_title_2',
    'Marketing_overlay.Diligence_plus_title_3',
];

export const modalDescriptions = [
    'Marketing_overlay.Diligence_plus_description_1',
    'Marketing_overlay.Diligence_plus_description_2',
    'Marketing_overlay.Diligence_plus_description_3',
];

export const upgradeText = 'Marketing_overlay.Diligence_plus_upgrade_text';

export const learnMoreText = 'Marketing_overlay.Diligence_plus_learn_more_text';

export const modalBackgrounds = [marketing_overlay_bg_1, marketing_overlay_bg_2, marketing_overlay_bg_3];

export const modalPages = [0, 1, 2];
