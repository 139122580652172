import React, { Component } from 'react';
import { cloneDeep } from 'lodash';
import { FormattedMessage } from 'react-intl';

const withSelectAll = (ChecksComponent, hasChildren = false) =>
    class WithSelectAll extends Component {
        constructor(props) {
            super(props);
            this.state = {
                allDeselected: false,
            };
            this.handleSelectAll = this.handleSelectAll.bind(this);
        }

        componentDidUpdate(prevProps, prevState) {
            // this method might need more checking, but works for now
            if (this.state.allDeselected && prevState.allDeselected) {
                this.setState({ allDeselected: false });
            }
        }

        selectAll = (options, toggle) =>
            hasChildren
                ? selectAllDeep(cloneDeep(options), toggle)
                : options.map((option) => ({ ...option, checked: toggle }));

        handleSelectAll(e) {
            if (areAllChecked(this.props.options) && !this.state.allDeselected) {
                this.setState({ allDeselected: true });
            } else {
                if (this.state.allDeselected) {
                    this.setState({ allDeselected: false });
                }
                this.props.onChange(this.selectAll(this.props.options, e.target.checked));
            }
        }

        render() {
            const selectedAll = areAllChecked(this.props.options) && !this.state.allDeselected;
            const options = this.state.allDeselected ? this.selectAll(this.props.options, false) : this.props.options;
            return (
                <div className={'select-all-checksgroup'}>
                    <div className="select-all custom-checkbox">
                        <input
                            type="checkbox"
                            id={'select-all-check' + this.props.postFilterType}
                            checked={selectedAll}
                            data-testid={'with-select-all-checkbox'}
                            onChange={this.handleSelectAll}
                        />
                        <label className="checkbox-label" htmlFor={'select-all-check' + this.props.postFilterType}>
                            <FormattedMessage id="General.label.selectAll"></FormattedMessage>
                        </label>
                    </div>
                    <div className="checkboxes-wrapper">
                        <ChecksComponent {...this.props} options={options} />
                    </div>
                </div>
            );
        }
    };

export default withSelectAll;

export const withSelectAllNested = (ChecksComponent) => withSelectAll(ChecksComponent, true);

export const areAllChecked = (options) => {
    let checked = true;
    options.forEach((option) => {
        if (!option.checked) {
            checked = false;
        } else if (option.children) {
            if (!areAllChecked(option.children)) {
                checked = false;
            }
        }
    });
    return checked;
};

export const selectAllDeep = (options, toggle) =>
    options.map((option) => {
        option.checked = toggle;
        if (option.children) {
            selectAllDeep(option.children, toggle);
        }
        return option;
    });
