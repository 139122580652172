import React from 'react';
import { Transition } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

export default class SearchDropDown extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside = (event) => {
        let isElementPartOfModal =
            event.target &&
            event.target.parentElement &&
            event.target.parentElement.className &&
            typeof event.target.parentElement.className === 'string'
                ? event.target.parentElement.className.indexOf('modal') > -1
                : false;

        if (this.node.contains(event.target) || isElementPartOfModal) {
            return;
        }

        this.props.setListVisibility(false);
    };

    render() {
        const { selectOption, selectedOption, toggleListVisibility, listVisible } = this.props;
        return (
            <div onClick={toggleListVisibility} className="search-dropdown" ref={(node) => (this.node = node)}>
                <span data-testid="selected-option">
                    {selectedOption === 'company' ? (
                        <p>
                            <FormattedMessage id={'General.label.companies'} />
                        </p>
                    ) : (
                        <p>
                            <FormattedMessage id={'General.label.persons'} />
                        </p>
                    )}
                    {listVisible ? <div className="la-TriangleUp" /> : <div className="la-TriangleDown" />}
                </span>
                <Transition.Group animation="fade down" duration="100">
                    {listVisible && (
                        <ul className="select-list">
                            {selectedOption === 'company' ? (
                                <li
                                    data-testid="persons-option"
                                    onClick={() => {
                                        selectOption('people');
                                    }}
                                >
                                    <span>
                                        <FormattedMessage id="General.label.persons" />
                                    </span>
                                </li>
                            ) : (
                                <li
                                    data-testid="companies-option"
                                    onClick={() => {
                                        selectOption('company');
                                    }}
                                >
                                    <span>
                                        <FormattedMessage id="General.label.companies" />
                                    </span>
                                </li>
                            )}
                        </ul>
                    )}
                </Transition.Group>
            </div>
        );
    }
}
