//@flow
import * as React from 'react';
import * as DilProps from '@utils/props';
import utils from '@utils/utilities';
import { FormattedMessage } from 'react-intl';
import withCounter from './withCounter';

/**
 * Adds a counter and displays loader while is loading, good for dynamic tables
 * @param TableComponent
 * Output example: 'Showing 1-10 of 12 Results'
 */

type RequiredProps = {
    +data: Object,
    +pagination: Object,
};

const withAlertsCounter = <Props: {} & RequiredProps>(
    TableComponent: React.AbstractComponent<Props>
): React.AbstractComponent<Props> | null => {
    const withAlertsCounter = (props: Props & RequiredProps): React.Node => {
        const { data } = props;
        const mappedRanges: Object = {
            firstAlertIndex: Math.min(data.pagination.pageSize * data.pagination.pageNumber + 1, data.totalCount),
            pageSize: Math.min(data.pagination.pageSize * (data.pagination.pageNumber + 1), data.totalCount),
            alertsCount: utils.formatNumbersByUserLocale(data.language, data.totalCount),
        };

        return (
            <div className="table-count">
                <FormattedMessage id={'Alerts.header.showingCountNumber'} values={{ ...mappedRanges }} />
            </div>
        );
    };

    withAlertsCounter.displayName = `withAlertsCounter(${utils.getDisplayName(TableComponent)})`;

    withAlertsCounter.propTypes = {
        data: DilProps.TableData,
    };

    return withCounter(TableComponent, withAlertsCounter);
};

export default withAlertsCounter;
