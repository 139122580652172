import PropTypes from 'prop-types';
import {
    UBO_BENEFICIARY_TYPE_BUSINESS,
    UBO_BENEFICIARY_TYPE_BUSINESS_CODE,
    UBO_BENEFICIARY_TYPE_INDIVIDUAL,
    UBO_BENEFICIARY_TYPE_INDIVIDUAL_CODE,
} from '@constants';

/**
 * This file contains all the custom props used in the application.
 */

export const TableHeaderItem = PropTypes.shape({
    label: PropTypes.any,
    columnKey: PropTypes.string, // key to match the values in row.columns
    isSortable: PropTypes.bool,
});

export const TableHeaders = PropTypes.arrayOf(TableHeaderItem);

export const TableRow = PropTypes.shape({
    key: PropTypes.string.isRequired, // unique key to help identify the rows
    object: PropTypes.object.isRequired, // raw object of the data, in case is needed in the pipe
    renderRow: PropTypes.func, // optional to have a custom render on a row
    columns: PropTypes.object, // each column to be displayed, object keys must match the columnKeys
});

export const TableRows = PropTypes.arrayOf(TableRow);

export const TableSelectedItem = PropTypes.shape({
    key: PropTypes.string.isRequired,
});

export const TableSelectedItems = PropTypes.arrayOf(TableSelectedItem);

export const TablePagination = PropTypes.shape({
    pageNumber: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    onChangePagination: PropTypes.func,
});

export const TableData = PropTypes.shape({
    rows: TableRows,
    totalCount: PropTypes.number,
    filterCount: PropTypes.number,
    hasError: PropTypes.bool,
    isLoading: PropTypes.bool,
    onLoadData: PropTypes.func,
    autoRefresh: PropTypes.bool,
});

export const TableSelection = PropTypes.shape({
    selectedItems: TableSelectedItems.isRequired,
    onChangeSelection: PropTypes.func.isRequired,
    onGetSelectedName: PropTypes.func.isRequired,
    actions: PropTypes.any,
});

export const TableSorting = PropTypes.shape({
    sortBy: PropTypes.string,
    direction: PropTypes.any,
    onChangeSorting: PropTypes.func,
});

export const TableFiltering = PropTypes.shape({
    searchString: PropTypes.string,
    filters: PropTypes.object,
    onChangeSearchString: PropTypes.func,
    onChangeFilters: PropTypes.func,
});

export const StateProvider = PropTypes.shape({
    state: PropTypes.object.isRequired,
    onUpdateState: PropTypes.func.isRequired,
});

export const PlainListItem = PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
});

export const PlainList = PropTypes.arrayOf(PlainListItem);

export const MyAlertsHandlers = PropTypes.shape({
    onAlertsPause: PropTypes.func,
    onAlertsResume: PropTypes.func,
    onAlertsDelete: PropTypes.func,
    onAlertsShare: PropTypes.func,
});

export const ModalButton = PropTypes.shape({
    text: PropTypes.string,
    primary: PropTypes.bool,
    secondary: PropTypes.bool,
    click: PropTypes.func,
});

export const ArticleNavigation = PropTypes.shape({
    index: PropTypes.number,
    list: PropTypes.arrayOf(PropTypes.object), // TODO: define the article prop
    totalCount: PropTypes.number,
    params: PropTypes.object, // TODO: define search params prop
});

export const Lock = PropTypes.shape({
    id: PropTypes.string.isRequired,
    masterId: PropTypes.string,
});

export const MatchItem = PropTypes.shape({
    onToggle: PropTypes.func,
    selected: PropTypes.bool,
    title: PropTypes.string,
    description: PropTypes.string,
});

export const UboLink = PropTypes.shape({
    source: PropTypes.number,
    target: PropTypes.number,
    id: PropTypes.number,
    sharePercentage: PropTypes.number,
    relationshipType: PropTypes.string,
});

export const UboNode = PropTypes.shape({
    id: PropTypes.number,
    isBeneficiary: PropTypes.bool,
    name: PropTypes.string,
    duns: PropTypes.string,
    beneficiaryType: PropTypes.oneOf([UBO_BENEFICIARY_TYPE_INDIVIDUAL, UBO_BENEFICIARY_TYPE_BUSINESS]),
    beneficiaryTypeCode: PropTypes.oneOf([UBO_BENEFICIARY_TYPE_INDIVIDUAL_CODE, UBO_BENEFICIARY_TYPE_BUSINESS_CODE]),
    controlOwnershipType: PropTypes.string,
    degreeOfSeparation: PropTypes.number,
    ownershipUnavailableReasons: PropTypes.array,
    directOwnershipPercentage: PropTypes.number,
    indirectOwnershipPercentage: PropTypes.number,
    beneficialOwnershipPercentage: PropTypes.number,
    locality: PropTypes.string,
    country: PropTypes.shape({
        name: PropTypes.string,
        isoCode: PropTypes.string,
    }),
    region: PropTypes.string,
    postalCode: PropTypes.string,
    address: PropTypes.string,
});

export const UboPersistedNode = PropTypes.shape({
    id: PropTypes.number,
});

export const UboTableDataRow = PropTypes.shape({
    subjectDuns: PropTypes.string,
    subjectName: PropTypes.string,
    beneficiaryName: PropTypes.string,
    beneficiaryDuns: PropTypes.string,
    beneficiaryType: PropTypes.string,
    beneficiaryTypeCode: PropTypes.number,
    directOwnershipPercentage: PropTypes.number,
    beneficiaryOwnershipPercentage: PropTypes.number,
});

export const UboTableData = PropTypes.arrayOf(UboTableDataRow);

export const UboSummary = PropTypes.shape({
    beneficialOwnersCount: PropTypes.number,
    relationshipsCount: PropTypes.number,
    maximumDegreeOfSeparation: PropTypes.number,
    totalAllocatedOwnershipPercentage: PropTypes.number,
    organizationsCount: PropTypes.number,
    individualsCount: PropTypes.number,
    unclassifedOwnersCount: PropTypes.number,
});

export const UboArticle = PropTypes.shape({
    links: PropTypes.arrayOf(UboLink),
    nodes: PropTypes.arrayOf(UboNode),
    tableData: UboTableData,
    summary: UboSummary,
});
