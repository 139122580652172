import searchUtils from '@MainSearch/SearchUtils';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import investigationActions from '@MainSearch/redux/Investigation.actions';
import searchResultsActions from '@MainSearch/redux/SearchResults.actions';

const HistoryEventsProvider = (props) => {
    const updateHistoryEntry = (articleType, filteredFields) => {
        const { context, updateSearchResultProperty } = props;

        if (!context.isUserAnonymized) {
            updateSearchResultProperty(articleType, 'markedInHistory', true);
            searchUtils.updateHistory(articleType, filteredFields);
        }
    };

    return props.provideHistoryEvents({ updateHistoryEntry });
};

const mapDispatchToProps = function (dispatch) {
    return bindActionCreators(
        {
            markCategoryAsVisited: investigationActions.markVisitedCategories,
            updateSearchResultProperty: searchResultsActions.updateCategoryProperty,
        },
        dispatch
    );
};

export default connect(null, mapDispatchToProps)(HistoryEventsProvider);
