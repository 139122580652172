import { RESET_FUZZY_NAMES } from '@UserPreferences/redux/UserPreferences.actions';

export const SEARCH_PARAM_UPDATE_QUERY = 'SEARCH_PARAM_UPDATE_QUERY';
export const SEARCH_PARAM_UPDATE_TYPE = 'SEARCH_PARAM_UPDATE_TYPE';
export const SEARCH_PARAM_BOOLEAN_TERMS = 'SEARCH_PARAM_BOOLEAN_TERMS';
export const SEARCH_PARAM_SET_BOOLEAN_TERMS = 'SEARCH_PARAM_SET_BOOLEAN_TERMS';
export const SEARCH_PARAM_RESET = 'SEARCH_PARAM_RESET';
export const SEARCH_UPDATE_IS_CUSTOM_FUZZY = 'SEARCH_UPDATE_IS_CUSTOM_FUZZY';
export const SEARCH_PARAM_SET_CATEGORY = 'SEARCH_PARAM_SET_CATEGORY';
export const SEARCH_PARAM_SET_SHARD_PREFERENCE = 'SEARCH_PARAM_SET_SHARD_PREFERENCE';

const searchParamsActions = {
    updateQueryString: function (query) {
        return {
            type: SEARCH_PARAM_UPDATE_QUERY,
            payload: query,
        };
    },

    updateQueryType: function (queryType) {
        return {
            type: SEARCH_PARAM_UPDATE_TYPE,
            payload: queryType,
        };
    },
    setCategory: (payload) => ({
        type: SEARCH_PARAM_SET_CATEGORY,
        payload,
    }),

    resetQuery: () => (dispatch) => {
        dispatch({ type: RESET_FUZZY_NAMES });
        dispatch({ type: SEARCH_PARAM_RESET });
    },

    resetQueryParams: () => ({
        type: SEARCH_PARAM_RESET,
    }),

    setBooleanTerms: (terms) => {
        return {
            type: SEARCH_PARAM_SET_BOOLEAN_TERMS,
            payload: terms,
        };
    },

    updateBooleanTerms: (term) => {
        return {
            type: SEARCH_PARAM_BOOLEAN_TERMS,
            payload: term,
        };
    },

    updateCustomFuzzyFlag: (isCustomFuzzy) => {
        return {
            type: SEARCH_UPDATE_IS_CUSTOM_FUZZY,
            payload: isCustomFuzzy,
        };
    },

    updateShardPreference: (shardPreference) => {
        return {
            type: SEARCH_PARAM_SET_SHARD_PREFERENCE,
            payload: shardPreference,
        };
    },
};

export default searchParamsActions;
