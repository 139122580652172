/**
 * ERROR PAGE
 *
 * @version 0.1
 */

import React from 'react';
import reduxStore from '@reduxStore';
import { FormattedMessage } from 'react-intl';
import { hashHistory } from 'react-router';

import headerActions from '@reusable/Header/Header.actions';
import popupActions from '@reusable/MessagePopup/redux/MessagePopup.actions';

import Page403 from './templates/ErrorPage.403.jsx';
import Page404 from './templates/ErrorPage.404.jsx';
import Page500 from './templates/ErrorPage.500.jsx';
import PageFailedLogin from './templates/ErrorPage.FailedLogin.jsx';
import Header from '@reusable/Header/Header.index.jsx';
import Footer from '@reusable/Footer/Footer.index.jsx';
import {connect} from "react-redux";

class ErrorPage extends React.Component {
    constructor(props) {
        super(props);
        this.goToHomePage = this.goToHomePage.bind(this);
    }

    UNSAFE_componentWillMount() {
        if (reduxStore.getState().popupMessages.messages.length > 0) {
            this.messageId = reduxStore.getState().popupMessages.messages[0].titleId;
        } else {
            this.messageId = '500';
        }

        this.errorBody = null;

        switch (this.messageId) {
            case 'General_CoreFunctionality_Error_error.403Title':
                headerActions.updateHeader('errorPage403');
                this.errorBody = <Page403 isDarkMode={this.props.isDarkMode}/>;
                break;

            case 'General_CoreFunctionality_Error_error.404Title':
                headerActions.updateHeader('errorPage404');
                this.errorBody = <Page404 isDarkMode={this.props.isDarkMode} />;
                break;

            case 'General_CoreFunctionality_Error_error.FailedLoginTitle':
                headerActions.updateHeader('errorPageFailedLogin');
                this.errorBody = <PageFailedLogin isDarkMode={this.props.isDarkMode}/>;
                break;

            default:
                headerActions.updateHeader('errorPage500');
                this.errorBody = <Page500 isDarkMode={this.props.isDarkMode}/>;
        }

        // Prevent the popup from appearing due to the fact that the message remains in the stack.
        popupActions.clearMessages();
    }

    componentDidMount() {
        let loaderPage = document.getElementById('diligence-loader-page');
        if (loaderPage) {
            loaderPage.parentNode.removeChild(loaderPage);
        }

        reduxStore.dispatch({ type: 'REMOVE_ALL_SPINNERS' });
    }

    goToHomePage() {
        hashHistory.push('/');
    }

    componentWillUnmount() {
        popupActions.clearMessages();
    }

    render() {
        return (
            <div className="app-wrapper" id="app-wrapper-id">
                <Header />
                <div className="error-page">
                    {this.errorBody}
                    <div className="error-page__navigation">
                        <button className="button-secondary-sm" id="qa-error-page-button" onClick={this.goToHomePage}>
                            <FormattedMessage id="General_CoreFunctionality_UIText_general.goToHomepage" />
                        </button>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

const mapStateToProps = (state) =>({
    isDarkMode: state.user.preferences.generalSettings.isDarkMode
});
export default connect(mapStateToProps)(ErrorPage);
