import React from 'react';
import utils from '@utils/utilities';
import * as DilProps from '@utils/props';
import { DefaultIsEmpty } from '../components/defaults';

const isEmpty =
    (IsEmptyComponent = DefaultIsEmpty) =>
    (TableComponent) => {
        const isEmpty = (props) =>
            props.data.rows && props.data.rows.length === 0 ? (
                <IsEmptyComponent {...props} />
            ) : (
                <TableComponent {...props} />
            );

        isEmpty.displayName = `isEmpty(${utils.getDisplayName(TableComponent)})`;

        isEmpty.propTypes = {
            data: DilProps.TableData.isRequired,
        };

        return isEmpty;
    };

export default isEmpty;
