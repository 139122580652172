import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import formatRichMessage from '@utils/formatRichMessage';

class ProximitySelect extends Component {
    constructor(props) {
        super(props);

        this.updateProximity = this.updateProximity.bind(this);
        this.translateProximity = this.translateProximity.bind(this);
    }

    updateProximity(event) {
        event.persist();
        this.props.changeProximity(event.target.value);
    }

    translateProximity(proximity) {
        return formatRichMessage({ id: 'General.Proximity.' + proximity }, this.props.intl);
    }

    render() {
        return (
            <div className="proximity-options">
                {this.props.children}
                <div className="proximity-options-select  dropdown">
                    <div data-track="proximity-options-select">
                        <select
                            onChange={this.updateProximity}
                            value={this.props.proximity}
                            disabled={this.props.disabledDropdown}
                        >
                            {this.props.proximities.map((proximity, index) => (
                                <option key={index} value={proximity}>
                                    {this.translateProximity(proximity)}
                                </option>
                            ))}
                        </select>
                        {this.props.adminLock}
                    </div>
                </div>
            </div>
        );
    }
}

export default injectIntl(ProximitySelect);
