import { NOTIFICATION_STORE_KEYS } from '@scripts/constants';
import { batch } from 'react-redux';
export const SEARCH_LAUNCHED_FROM = 'SEARCH_LAUNCHED_FROM';
export const RESET_LAUNCH_FROM = 'RESET_LAUNCH_FROM';
export const SET_ENTITY_ID = 'SET_ENTITY_ID';
export const SET_ALL_ENTITIES_SELECTED = 'SET_ALL_ENTITIES_SELECTED';
export const SHOULD_UPDATE_ENTITIES_COLUMNS_FROM_CONTENT_TYPES = 'SHOULD_UPDATE_ENTITIES_COLUMNS_FROM_CONTENT_TYPES';
export const USER_SET_ENTITY_VIEW_INFORMATION = 'USER_SET_ENTITY_VIEW_INFORMATION';
export const UPDATE_BATCH_QUEUE = 'UPDATE_BATCH_QUEUE';
export const UPDATE_BLOCKED_ENTITIES = 'UPDATE_BLOCKED_ENTITIES';
export const SET_SHOULD_GET_ALL_VIEWS = 'SET_SHOULD_GET_ALL_VIEWS';
export const SET_CURRENT_ENTITY = 'SET_CURRENT_ENTITY';
export const RESET_CURRENT_ENTITY = 'RESET_CURRENT_ENTITY';
export const SET_SNAPSHOT_DELIVERY = 'SET_SNAPSHOT_DELIVERY';
export const SET_UPLOAD_ENTITY_VIEW_POLLING = 'SET_UPLOAD_ENTITY_VIEW_POLLING';
export const SET_ENTITY_VIEW_TABLE_HAS_DATA = 'SET_ENTITY_VIEW_TABLE_HAS_DATA';
export const SET_ENTITY_VIEW_COUNT = 'SET_ENTITY_VIEW_COUNT';
export const SET_ENTITY_VIEW_DOWNLOAD_REPORTS_POLLING = 'SET_ENTITY_VIEW_DOWNLOAD_REPORTS_POLLING';
export const UPDATE_ENTITIES_ID_QUEUE = 'UPDATE_ENTITIES_ID_QUEUE';
export const CLOSE_ALL_NOTIFICATIONS = 'CLOSE_ALL_NOTIFICATIONS';
export const CLOSE_NOTIFICATION = 'CLOSE_NOTIFICATION';
export const UPDATE_BATCH_ID = 'UPDATE_BATCH_ID';
export const ADD_NEW_BATCH_ID = 'ADD_NEW_BATCH_ID';
export const SANITIZE_NOTIFICATIONS = 'SANITIZE_NOTIFICATIONS';
export const RESET_NOTIFICATION = 'RESET_NOTIFICATION';

const mainActions = {
    searchLaunchedFrom: function (payload) {
        return {
            type: SEARCH_LAUNCHED_FROM,
            payload,
        };
    },
    resetLaunchFrom: function () {
        return {
            type: RESET_LAUNCH_FROM,
            payload: null,
        };
    },
    setEntityId: function (payload) {
        return {
            type: SET_ENTITY_ID,
            payload,
        };
    },
    setAllEntitiesSelected: (payload) => {
        return {
            type: SET_ALL_ENTITIES_SELECTED,
            payload,
        };
    },
    setUpdateEntitiesColumnsFromContentTypes: (payload) => {
        return {
            type: SHOULD_UPDATE_ENTITIES_COLUMNS_FROM_CONTENT_TYPES,
            payload,
        };
    },
    updateEntityViewInformation: (payload) => {
        return {
            type: USER_SET_ENTITY_VIEW_INFORMATION,
            payload,
        };
    },
    updateBatchQueue: (payload) => {
        return {
            type: UPDATE_BATCH_QUEUE,
            payload,
        };
    },
    updateBlockedEntities: (payload) => {
        return {
            type: UPDATE_BLOCKED_ENTITIES,
            payload,
        };
    },
    setShouldGetAllViews: (payload) => {
        return {
            type: SET_SHOULD_GET_ALL_VIEWS,
            payload,
        };
    },
    setCurrentEntity: (payload) => {
        return {
            type: SET_CURRENT_ENTITY,
            payload,
        };
    },
    resetCurrentEntity: () => {
        return {
            type: RESET_CURRENT_ENTITY,
            payload: {},
        };
    },
    setSnapshotDelivery: (payload) => {
        return {
            type: SET_SNAPSHOT_DELIVERY,
            payload,
        };
    },
    setUploadPollingEntityView: (payload) => {
        return {
            type: SET_UPLOAD_ENTITY_VIEW_POLLING,
            payload,
        };
    },
    setEntityViewTableHasData: (payload) => {
        return {
            type: SET_ENTITY_VIEW_TABLE_HAS_DATA,
            payload,
        };
    },
    setEntityViewCount: (payload) => {
        return {
            type: SET_ENTITY_VIEW_COUNT,
            payload,
        };
    },
    setEntityViewDownloadReportsPollingActive: (payload) => {
        return {
            type: SET_ENTITY_VIEW_DOWNLOAD_REPORTS_POLLING,
            payload,
        };
    },
    addNewBatchId: (payload) => {
        return {
            type: ADD_NEW_BATCH_ID,
            payload,
        }
    },
    sanitizeNotifications: (payload) => {
        return {
            type: 'SANITIZE_NOTIFICATIONS',
            payload,
        }
    },
    resetNotification: (payload) => {
        return {
            type: 'RESET_NOTIFICATION',
            payload,
        }
    },
    updateBatchId: (payload) => {
        return {
            type: UPDATE_BATCH_ID,
            payload,
        }
    },
    updateNotificationStatusThunk: (payload) => (dispatch) => {
        const {
            addNewRefreshBatchIdsList = {},
            addNewUploadBatchIdsList = {},
            refreshBatchList = {},
            uploadBatchList = {},
            createAlertsBatchList = {},
            addNewCreateAlertBatchIdsList = {},

        } = payload;

        batch(() =>{
            if(Object.keys(addNewRefreshBatchIdsList).length) {
                for (const [key, value] of Object.entries(addNewRefreshBatchIdsList)) {
                    dispatch({
                        type: ADD_NEW_BATCH_ID,
                        payload: {batchId: key, data: value, keyInTheStore: NOTIFICATION_STORE_KEYS.REFRESH}
                    });
                }
            }
            if(Object.keys(addNewUploadBatchIdsList).length) {
                for (const [key, value] of Object.entries(addNewUploadBatchIdsList)) {
                    dispatch({
                        type: ADD_NEW_BATCH_ID,
                        payload: {batchId: key, data: value, keyInTheStore: NOTIFICATION_STORE_KEYS.UPLOAD}
                    });
                }
            }
            if(Object.keys(refreshBatchList).length) {
                for (const [key, value] of Object.entries(refreshBatchList)) {
                    dispatch({
                        type: UPDATE_BATCH_ID,
                        payload: {batchId: key, data: value, keyInTheStore: NOTIFICATION_STORE_KEYS.REFRESH}
                    });
                }
            }
            if(Object.keys(uploadBatchList).length) {
                for (const [key, value] of Object.entries(uploadBatchList)) {
                    dispatch({
                        type: UPDATE_BATCH_ID,
                        payload: {batchId: key, data: value, keyInTheStore: NOTIFICATION_STORE_KEYS.UPLOAD}
                    });
                }
            }
            if(Object.keys(createAlertsBatchList).length) {
                for (const [key, value] of Object.entries(createAlertsBatchList)) {
                    dispatch({
                        type: UPDATE_BATCH_ID,
                        payload: {batchId: key, data: value, keyInTheStore: NOTIFICATION_STORE_KEYS.CREATE}
                    });
                }
            }
            if(Object.keys(addNewCreateAlertBatchIdsList).length) {
                for (const [key, value] of Object.entries(addNewCreateAlertBatchIdsList)) {
                    dispatch({
                        type: ADD_NEW_BATCH_ID,
                        payload: {batchId: key, data: value, keyInTheStore: NOTIFICATION_STORE_KEYS.CREATE}
                    });
                }
            }
        })
    },
    updateEntitiesIdQueue: (payload,) => {
        return {
            type: UPDATE_ENTITIES_ID_QUEUE,
            payload,
        }
    },
    closeAllNotifications: (payload) => {
        return {
            type: CLOSE_ALL_NOTIFICATIONS,
            payload
        }
    },
    closeNotification: (payload) => {
        return {
            type: CLOSE_NOTIFICATION,
            payload
        }
    }
};

export default mainActions;
