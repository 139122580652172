import React from 'react';

const BatchUploadFormatExample = ({ infoLabel, headers, rows }) => {
    return (
        <div className="batch-upload-format-example">
            <span className="batch-upload-format-example__title" dangerouslySetInnerHTML={{ __html: infoLabel }} />
            <table data-track="batch-upload-format-example-table">
                <thead>
                    <tr>
                        {headers.map((header, headerIndex) => (
                            <th key={`format-example-header-${headerIndex}`}>{header}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {rows.map((row, rowIndex) => (
                        <tr key={`format-example-row-${rowIndex}`}>
                            {Object.values(row).map((columnValue, columnIndex) => (
                                <td key={`format-example-column-${rowIndex}${columnIndex}`}>{columnValue}</td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default BatchUploadFormatExample;
