import React, { Fragment } from 'react';
import NegativeNewsLanguageSelection from './NegativeNewsLanguageSelection';
import NegativityLevels from './NegativitiyLevels';
import { COMPANY_CHECK, NEGATIVE_NEWS_LANGUAGES, USER_PREFERENCES_SECTIONS } from '@constants';
import DefaultNewsSourcesSelection from './NewsSourcesSelection';
import SourcesSearchOptions from './SourcesSearchOptions';

const CategoryNewsSearches = (props) => {
    return (
        <Fragment>
            <DefaultNewsSourcesSelection
                changeLanguageSelection={props.changeLanguageSelection}
                defaultSources={props.defaultSources}
                segment={props.segment}
                // admin props
                disabledDropdown={!props.userIsAdmin && (props.masterLock || props.isNegativeSearchSourcesReadonly)}
                lockName={props.negativeSearchSourcesLockName}
                isReadonly={
                    (props.userIsAdmin && props.isNegativeSearchSourcesReadonly) ||
                    (!props.userIsAdmin && (props.isNegativeSearchSourcesReadonly || props.masterLock))
                }
                masterLock={props.masterLock}
                userIsAdmin={props.userIsAdmin}
                handleLockClick={props.handleLockClick}
                // auto-save props
                modifiedSections={props.modifiedSections}
                arePreferencesSaving={props.arePreferencesSaving}
                autosaveHasError={props.autosaveHasError}
                changeMade={props.changeMade}
                section={USER_PREFERENCES_SECTIONS[props.segment].NEWS_SEARCHES_SOURCES}
            />
            {props.segment === COMPANY_CHECK && (
                <Fragment>
                    <div className="sub-section-separator__no-margins" />
                    <div className="user-preferences-category-container__options">
                        <SourcesSearchOptions
                            segment={props.segment}
                            searchSpecifically={props.searchSpecifically}
                            changeSourcesSearchOption={props.changeSourcesSearchOption}
                            // admin props
                            userIsAdmin={props.userIsAdmin}
                            // auto-save props
                            modifiedSections={props.modifiedSections}
                            arePreferencesSaving={props.arePreferencesSaving}
                            autosaveHasError={props.autosaveHasError}
                            changeMade={props.changeMade}
                            section={USER_PREFERENCES_SECTIONS[props.segment].NEWS_SEARCH_OPTION}
                        />
                    </div>
                </Fragment>
            )}
            <div className="sub-section-separator__no-margins" />
            <div
                className={
                    props.userIsAdmin
                        ? 'section-enabled'
                        : props.isNegativeSearchLanguageReadonly || props.masterLock
                        ? 'section-disabled'
                        : 'section-enabled'
                }
            >
                <NegativeNewsLanguageSelection
                    toggleLanguageEditMode={props.toggleLanguageEditMode}
                    isEditModeEnabled={props.editLanguageSelection}
                    isLanguageDisabled={props.isLanguageDisabled}
                    showPopup={props.showPopup}
                    segment={props.segment}
                    negativeNewsLanguages={NEGATIVE_NEWS_LANGUAGES}
                    languages={props.languages}
                    handleNegativeNewsLanguageSelection={props.handleNegativeNewsLanguageSelection}
                    defaultSources={props.defaultSources}
                    // admin props
                    lockName={props.negativeSearchLanguageLockName}
                    isReadonly={
                        (props.userIsAdmin && props.isNegativeSearchLanguageReadonly) ||
                        (!props.userIsAdmin && (props.isNegativeSearchLanguageReadonly || props.masterLock))
                    }
                    userIsAdmin={props.userIsAdmin}
                    handleLockClick={props.handleLockClick}
                    masterLock={props.masterLock}
                    // auto-save props
                    modifiedSections={props.modifiedSections}
                    arePreferencesSaving={props.arePreferencesSaving}
                    autosaveHasError={props.autosaveHasError}
                    changeMade={props.changeMade}
                    section={USER_PREFERENCES_SECTIONS[props.segment].NEWS_LANGUAGE_SELECTION}
                />
            </div>

            <div className="sub-section-separator__no-margins" />
            <div
                className={
                    props.userIsAdmin
                        ? 'section-enabled'
                        : props.isNegativeSearchLevelReadonly || props.masterLock
                        ? 'section-disabled'
                        : 'section-enabled'
                }
            >
                <NegativityLevels
                    segment={props.segment}
                    handleSelectNegativityLevels={props.updateNegativityLevel}
                    negativityLevel={props.negativityLevel}
                    negativityLevels={props.negativityLevels}
                    // admin props
                    isReadonly={
                        (props.userIsAdmin && props.isNegativeSearchLevelReadonly) ||
                        (!props.userIsAdmin && (props.isNegativeSearchLevelReadonly || props.masterLock))
                    }
                    lockName={props.negativeSearchLevelLockName}
                    userIsAdmin={props.userIsAdmin}
                    handleLockClick={props.handleLockClick}
                    // auto-save props
                    modifiedSections={props.modifiedSections}
                    arePreferencesSaving={props.arePreferencesSaving}
                    autosaveHasError={props.autosaveHasError}
                    changeMade={props.changeMade}
                    section={USER_PREFERENCES_SECTIONS[props.segment].NEWS_NEGATIVITY_LEVELS}
                />
            </div>
        </Fragment>
    );
};

export default CategoryNewsSearches;
