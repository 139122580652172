import React from 'react';
import RadioGroup from '@reusable/Form/RadioGroup';
import withLimitToggle from '@reusable/Form/hoc/withLimitToggle';

const RadioComponent = withLimitToggle(RadioGroup);

const RadioFilterComponent = (props) => {
    return (
        <div className={'filter-radio-group'}>
            <div className="radios-wrapper">
                <RadioComponent {...props} />
            </div>
        </div>
    );
};

export default RadioFilterComponent;
