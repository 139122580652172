export const UPDATE_EDIT_SEARCH = 'UPDATE_EDIT_SEARCH';
export const UPDATE_EDIT_SEARCH_CATEGORY = 'UPDATE_EDIT_SEARCH_CATEGORY';
export const EMPTY_EDIT_SEARCH = 'EMPTY_EDIT_SEARCH';
export const UPDATE_EDIT_SEARCH_PROPERTY = 'UPDATE_EDIT_SEARCH_PROPERTY';
export const UPDATE_EDIT_SEARCH_COST_CODE = 'UPDATE_EDIT_SEARCH_COST_CODE';
export const UPDATE_EDIT_SEARCH_CONTENT_SOURCE = 'UPDATE_EDIT_SEARCH_CONTENT_SOURCE';

let editSearchActions = {
    updateEditSearch: (payload) => ({
        type: UPDATE_EDIT_SEARCH,
        payload,
    }),

    emptyEditSearch: (payload) => ({
        type: EMPTY_EDIT_SEARCH,
        payload,
    }),

    updateEditSearchProperty: function (categoryName, propertyName, propertyValue) {
        return {
            type: UPDATE_EDIT_SEARCH_PROPERTY,
            payload: { name: categoryName, propertyName, propertyValue },
        };
    },
};

export default editSearchActions;
