// @flow
import type { Sources } from './flow/SearchBar.type.guards';

export const UPDATE_SEARCH_BAR_SOURCES_DROPDOWN: 'UPDATE_SEARCH_BAR_SOURCES_DROPDOWN' =
    ('UPDATE_SEARCH_BAR_SOURCES_DROPDOWN': 'UPDATE_SEARCH_BAR_SOURCES_DROPDOWN');

let searchBar: Object = {
    updateSourcesDropdownContentTypes: (sourcesDropdownContentTypes: Array<Sources>) => {
        return {
            type: UPDATE_SEARCH_BAR_SOURCES_DROPDOWN,
            payload: sourcesDropdownContentTypes,
        };
    },
};

export default searchBar;
