import React, { Fragment } from 'react';
import * as DilProps from '@utils/props';
import utils from '@utils/utilities';
import ReactPaginate from 'react-paginate';
import PageSizeSelector from '@reusable/PageSizeSelector/PageSizeSelector';
import reduxStore from '@reduxStore';
import userPreferencesActions from '@pages/UserPreferances/redux/UserPreferences.actions';

/**
 * Handles pagination for any paginated component
 * @param TableComponent
 * @returns {function(*)}
 */

const withPagination = (TableComponent) => {
    const withPagination = (props) => {
        const handleChangePageSize = (e) => {
            props.pagination.onChangePagination(
                {
                    pageSize: parseInt(e.target.value, 10),
                    pageNumber: 0,
                },
                props.data.onLoadData
            );
            reduxStore.dispatch(userPreferencesActions.updateGeneralPageSize(e.target.value));
        };

        const handleChangePage = ({ selected }) => {
            props.pagination.onChangePagination({ pageNumber: selected }, props.data.onLoadData);
        };

        let pageNumbers = Math.ceil(props.data.totalCount / props.pagination.pageSize);

        return (
            <Fragment>
                <TableComponent {...props} />
                {props.data.totalCount > 0 && (
                    <div className="pagination-container">
                        <ReactPaginate
                            previousLabel={''}
                            nextLabel={''}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            forcePage={props.pagination.pageNumber}
                            pageCount={pageNumbers}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={4}
                            onPageChange={handleChangePage}
                            containerClassName={'pagination'}
                            subContainerClassName={'pages pagination'}
                            previousLinkClassName={'previous'}
                            nextLinkClassName={'next'}
                            activeClassName={'active'}
                        />
                        <PageSizeSelector
                            pageSize={props.pagination.pageSize}
                            handlePageSizeChange={handleChangePageSize}
                        />
                    </div>
                )}
            </Fragment>
        );
    };

    withPagination.displayName = `WithPagination(${utils.getDisplayName(TableComponent)})`;

    withPagination.propTypes = {
        data: DilProps.TableData,
        pagination: DilProps.TablePagination,
    };

    return withPagination;
};

export default withPagination;
