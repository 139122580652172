/**
 * APPLICATION MAIN ENTRY POINT
 *
 * This script includes the globals, store, components and router that are
 * needed to get the app running.
 *
 * @version  0.1
 */

import React from 'react';
import ReactDOM from 'react-dom';
import AppProviders from './scripts/providers/AppProviders';

//below import would be removed when upgrade to RR6 is done
import ReactRouterProvider from './scripts/router/ReactRouterProvider';
import { hashHistory } from 'react-router';
// Add polyfills first
import applyAllPolyfills from '@utils/polyfills';
// Add CSS
import './index.css';
import './assets/fonts.css';
// Import utility libraries (XHR and utils)
import xhr from '@utils/xhr';
import utils from '@utils/utilities';
import { pendoPositionController } from '@utils/pendoPositionController';
// Import Redux store
import reduxStore from '@reduxStore';
// Import Login API needed to get the app settings.
import api from '@api/LoginPage.api';

// Internationalization imports and settings
import {
    DEFAULT_LANGUAGE,
    PENDO_URL_ANNOTATIONS_CONFIG,
} from '@constants';

// Apply all necessary polyfills for the app to work correctly
applyAllPolyfills();

let previousLanguage = DEFAULT_LANGUAGE;

/**
 * This function injects the JS dependencies in the head section of the document.
 *
 * @callback  scriptOnLoadCallback
 *
 * @param     {string}                 The location of the JS file.
 * @param     {scriptOnLoadCallback}   The callback that will get executed after the script loads.
 * @return    nothing
 */

function loadRouter() {
    // Routing system
    ReactDOM.render(
        <AppProviders>
            <ReactRouterProvider useRouterVersion6={reduxStore.getState().user.useReactRouterVersion6 ?? false}/>
        </AppProviders>,
        document.getElementById('page-wrapper')
    );
}

function loadRouterWithLanguageRefresh() {
    const { pendo } = window;
    let isAnonymized = reduxStore.getState().user.isAnonymized;

    if (pendo && pendo.isReady && !isAnonymized) {
        /**
         * Important Note: This function will get called every time we check to see if the url has changed (~10x per sec).
         * @returns {{include: {page: string}, fragment: string}}
         *
         * `exclude` means we'll remove the page hash starting with '#/'
         * `include` means we'll add our custom annotation, and the URL will be transformed
         *  FROM: http://localhost:3000/#/main-search?q=amazon&searchType=company&uboSelected=%5B%5D
         *  TO:  http://localhost:3000/&page="{customAnnotation}"
         *
         *  `fragment` means we'll append all the query strings at the end of the annotated URL, and the output is:
         *  http://localhost:3000/&page={customAnnotation}#param1=value1&param2=value2...
         */
        const annotateUrl = () => {
            const hash = window.location.hash;

            if (global.LAST_PENDO_ANNOTATED_URL && hash === global.LAST_PENDO_ANNOTATED_URL.hash) {
                return global.LAST_PENDO_ANNOTATED_URL.annotation || {};
            }

            /* match the page name from an URL starting with '#/' string and ends with '?'.
               Where the question mask should be before the query params. */
            const startIndex = hash.indexOf('#') + 1;
            const endIndex = hash.indexOf('?') > -1 ? hash.indexOf('?') : hash.length;

            let page = hash.substring(startIndex, endIndex);

            const queryParams = utils.getAllUrlParams(hash);
            let queryString = Object.keys(queryParams)
                .map((key) => `${key}=${queryParams[key]}`)
                .join('&');

            const configKeys = Object.keys(PENDO_URL_ANNOTATIONS_CONFIG);

            // don't annotate the URL if it's not present in config
            if (configKeys.indexOf(page) === -1) {
                return null;
            }

            configKeys.forEach((pageName) => {
                const pageAnnotation = PENDO_URL_ANNOTATIONS_CONFIG[pageName];

                if (hash.indexOf(pageName) !== -1) {
                    const {
                        identifier: { match, annotation },
                        fallback,
                    } = pageAnnotation;
                    page = hash.indexOf(match) !== -1 ? annotation : fallback;
                }
            });

            const annotation = {
                include: { page },
                fragment: queryString,
            };

            /* Since this function will get called ~10x per sec from pendo, we're saving globally the last hash value
               and check if the current hash value is different than the new one. Otherwise this function will return null
               without generating annotations */
            global.LAST_PENDO_ANNOTATED_URL = { hash, annotation };

            return annotation;
        };

        pendo.initialize({
            visitor: {
                name: 'visitor',
            },
            account: {},
            disableGuides: reduxStore.getState().user.disablePendoGuides,
            annotateUrl,
            events: {
                guidesLoaded: function () {
                    const resourceCenterGuide = pendo.findGuideBy('launchMethod', 'badge');
                    const placeBadgeMethod = resourceCenterGuide && resourceCenterGuide.placeBadge;

                    if (placeBadgeMethod) {
                        resourceCenterGuide.placeBadge = function () {
                            const returnValue = placeBadgeMethod();
                            const badgeElement =
                                pendo.badgesShown &&
                                pendo.badgesShown[resourceCenterGuide.id] &&
                                pendo.badgesShown[resourceCenterGuide.id].element &&
                                pendo.badgesShown[resourceCenterGuide.id].element();

                            if (badgeElement) {
                                const pendoElement = document.getElementsByClassName(
                                    '_pendo-resource-center-badge-container'
                                )[0];
                                const footerElement = document.getElementsByClassName('footer__wrapper')[0];
                                pendoPositionController(pendoElement, footerElement);
                                window.onscroll = () => pendoPositionController(pendoElement, footerElement);
                                window.onresize = () => pendoPositionController(pendoElement, footerElement);
                            }
                            return returnValue;
                        };
                    }
                },
            },
        });

        window.pendoCallbacks = { openLiveChat: openLiveChat };
    }

    loadRouter();

    reduxStore.subscribe(() => {
        let currentLanguage = reduxStore.getState().user.preferences.language;

        if (currentLanguage !== previousLanguage) {
            previousLanguage = currentLanguage;
            loadRouter();
        }
    });
}

// This method is a duplicate of the one using on Newsdesk to populate the pendo liveChat form with the user details
// It will populate the fields and on submit it will open the real liveChat page with the fields filled
const openLiveChat = () => {
    const form = document.createElement('form');
    const productId = '1275';
    const liveChatUrl = `https://lexisnexis.custhelp.com/app/chat/chat_launch/p/${productId}`;
    const addField = (name, value) => {
        const input = document.createElement('input');
        input.setAttribute('type', 'hidden');
        input.setAttribute('name', name);
        input.setAttribute('value', value);
        form.appendChild(input);
    };

    const state = reduxStore.getState();

    const { firstName, lastName, email, userPermId } = state.user;
    addField('Product', productId);
    addField('FirstName', firstName);
    addField('LastName', lastName);
    addField('Email', email);
    addField('UserPermID', userPermId);

    form.style.visibility = 'hidden';
    form.method = 'POST';
    form.action = liveChatUrl;
    form.setAttribute('target', '_blank');
    form.setAttribute('method', 'post');

    document.body.appendChild(form);
    form.submit();
    document.body.removeChild(form);
};

function loadUserSettings() {
    return xhr.get({ url: '/api/user' }).then(async (response) => {
        reduxStore.dispatch({
            type: 'USER_INFO_SUCCESS',
            payload: response.body,
        });

        if (response.body && response.body.userPermIdUnhashed) {
            localStorage.setItem('userId', response.body.userPermIdUnhashed);
        }
    });
}

function buildErrorPage(error) {
    let loaderPage = document.getElementById('diligence-loader-page');
    if (loaderPage) {
        loaderPage.parentNode.removeChild(loaderPage);
    }

    document.getElementById('page-wrapper').innerHTML =
        '<div class="application-config-fetch-error">' +
        'Application failed to start. Unable to fetch configuration data.' +
        '</div>';
    utils.logError(error, 'index.window.onload');
}

/**
 * On completion of page load, request Net Insight connection information and then mount the router.
 *
 * @return  nothing
 */
function onPageLoad() {
    let lna2 = utils.getUrlQueryParam('lna2');
    let clientidauthtoken = utils.getUrlQueryParam('clientidauthtoken')
        ? 'clientidauthtoken=' + utils.getUrlQueryParam('clientidauthtoken')
        : null;
    let clientauthtoken = utils.getUrlQueryParam('clientauthtoken')
        ? 'clientauthtoken=' + utils.getUrlQueryParam('clientauthtoken')
        : null;
    let identityprofileid = utils.getUrlQueryParam('identityprofileid')
        ? 'identityprofileid=' + utils.getUrlQueryParam('identityprofileid')
        : null;
    let federationidp = utils.getUrlQueryParam('federationidp')
        ? 'federationidp=' + utils.getUrlQueryParam('federationidp')
        : null;
    if (lna2) {
        showLoaderPage();
        // if coming from WAM log in first
        return api
            .loginWam(window.location.search)
            .then(loadUserSettings)
            .then(() => {
                console.log('success logging in');
                let lnsi = utils.getUrlQueryParam('lnsi');

                if (lnsi) {
                    localStorage.setItem('diligence', JSON.stringify({ authInfo: { lnsi } }));
                }

                let deepLink = localStorage.getItem('diligence-deep-link');

                if (deepLink) {
                    let params = JSON.parse(deepLink);

                    window.location.replace(window.location.protocol + '//' + window.location.host + params.mainUrl);

                    localStorage.removeItem('diligence-deep-link');
                } else {
                    window.location.replace(window.location.protocol + '//' + window.location.host + '/#/start');
                }

                loadRouterWithLanguageRefresh();
            })
            .catch((err) => {
                // TODO: handle error specifically (401 vr rest of the world)
                if (err.status == 401) {
                    window.location =
                        err.response.body.wamLogin +
                        '?back=' +
                        encodeURIComponent(err.response.body.customerUi) +
                        '&aci=' +
                        err.response.body.wamAciCode;
                } else if (err.status == 403) {
                    window.location = err.response.body.wamAuthDenied;
                } else {
                    hashHistory.push('/error');
                    loadRouterWithLanguageRefresh();
                }
            });
    } else {
        if (
            window.location.hash === '#/logout' ||
            window.location.hash === '#/error' ||
            window.location.hash.startsWith('#/unsubscribe')
        ) {
            console.log('====== proceed with page without ping ===== ');
            loadRouterWithLanguageRefresh();
            return null;
        }
        // Check if already logged in
        return xhr
            .get({ url: '/api/user/ping' })
            .then(loadUserSettings)
            .then(() => {
                console.log('====== proceed with page login ===== ');
                loadRouterWithLanguageRefresh();
                showLoaderPage();
                return null;
            })
            .catch((err) => {
                console.log('=========== handling second response');
                console.log(window.location.hash);
                console.log(window.location);

                if (err.status == 401) {
                    localStorage.setItem(
                        'diligence-deep-link',
                        JSON.stringify({
                            mainUrl: window.location.hash,
                        })
                    );

                    let queryParams = [clientidauthtoken, clientauthtoken, identityprofileid, federationidp]
                        .filter(Boolean)
                        .join('&');
                    queryParams = queryParams && queryParams.length ? `?${queryParams}` : queryParams;
                    window.location = `${err.response.body.wamLogin}?back=${encodeURIComponent(
                        err.response.body.customerUi
                    )}${encodeURIComponent(queryParams)}&aci=${err.response.body.wamAciCode}`;
                } else {
                    buildErrorPage(err);
                }
            });
    }
}

function showLoaderPage() {
    document.getElementById('diligence-loader-page').style.display = 'block';

    const currentDate = new Date(),
        copyrightYear = currentDate.getFullYear();

    document.getElementById('footer-link-loader-page').innerHTML = `<span>&copy;</span>
            ${copyrightYear} &nbsp;
        <span>LexisNexis. All rights reserved.</span>`;
}

window.addEventListener('load', onPageLoad);
