import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

export class SanctionsRiskTemplate extends Component {
    getCompatibleCell(uncertainCell) {
        const text = uncertainCell.text;
        return { ...uncertainCell, text };
    }

    update(cell, cellToMerge) {
        return this.getCompatibleCell({ ...cell, text: cellToMerge.text });
    }

    render(cell) {
        const { text, rowIndex } = cell;
        const { content, onClick, onMouseEnter, onMouseLeave } = text;
        const riskLevel = content;
        return (
            <div className={`sanctions-table-risk${this.props.hoveredRow === rowIndex ? ' hovered' : ''}`}
                 onClick={onClick}
                 onMouseEnter={onMouseEnter}
                 onMouseLeave={onMouseLeave}>
                <div className="risk-score-container">
                    <div className={`doughnut ${riskLevel.toLowerCase()}`} />
                    <p className="risk-score" data-testid={`sw-table-risk-${rowIndex}`}>
                        <FormattedMessage id= {`SanctionsAndWatchlists.risk.table.${riskLevel.toLowerCase()}` } />
                    </p>
                </div>
            </div>
        );
    }
}
