/**
 * HEADER MAIN MENU VIEW TEMPLATE
 *
 * @version 0.1
 */

import React from 'react';
import { Link } from 'react-router';

function Logo({ resetLaunchedFrom, updatePrevPath, location, showDilligencePlusLogo = false }) {
    return (
        <div className="diligence-header-menu-left">
            <Link
                to="/start"
                className={`diligence-header__logo ${showDilligencePlusLogo ? 'diligence-plus' : ''}`}
                onClick={() => {
                    resetLaunchedFrom();
                    updatePrevPath(location);
                }}
            >
                <div className="diligence-header__logo--product" />
            </Link>
        </div>
    );
}

export default Logo;
