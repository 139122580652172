import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import PillList from '../PillList/PillList';
import { PERSON_SEARCH } from '@constants';
import utils from '@utils/utilities';
import LoadingPillList from '../PillList/LoadingPillList';
import ReactTooltip from 'react-tooltip';
import { compose } from 'redux';
import { withAppContext } from '@utils/contexts';
import formatRichMessage from '@utils/formatRichMessage';
import SuperscriptTag from '../Tag/SuperscriptTag';

class ToggleForFuzzyName extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};

        this.handleChange = this.handleChange.bind(this);
        this.deselectFuzzy = this.deselectFuzzy.bind(this);
        this.selectFuzzy = this.selectFuzzy.bind(this);
    }

    componentDidMount() {
        if (this.props.fuzzyNames !== null && this.pillListContainer) {
            this.setState({
                pillListContainerWidth: this.pillListContainer.clientWidth,
            });
        }
    }

    deselectFuzzy(item, index) {
        this.props.onFuzzySelectionChange(item, index, false);
    }

    selectFuzzy(item, index) {
        this.props.onFuzzySelectionChange(item, index, true);
    }

    handleChange(e) {
        if (!this.props.isCustomFuzzy) {
            utils.handleFuzzyToggle(e, this.props.context.isUserMIP);
        } else {
            e.preventDefault();
        }
    }

    render() {
        const customFuzzyMessage = formatRichMessage(
            { id: 'StartPage_CoreFunctionality_UIText_fuzzy.disabledHoverMsg' },
            this.props.intl
        );
        return (
            <div>
                <div className="toggle-for-fuzzy-names">
                    {this.props.searchType === PERSON_SEARCH && !this.props.newFuzzyEnabled && (
                        <div className="toggle-for-fuzzy-names-wrapper">
                            <div
                                className="custom-toggle"
                                data-for={'disabled-tooltip'}
                                data-place={'bottom'}
                                data-tip={customFuzzyMessage}
                            >
                                <input
                                    type="checkbox"
                                    className="custom-toggle-checkbox"
                                    checked={
                                        this.props.isFuzzyEnabled === true && !this.props.isCustomFuzzy ? 'checked' : ''
                                    }
                                    onChange={this.handleChange}
                                    id="isFuzzyEnabledOnStartPage"
                                />
                                <label className="custom-toggle-label" htmlFor="isFuzzyEnabledOnStartPage" />
                            </div>
                            {this.props.isCustomFuzzy && (
                                <ReactTooltip
                                    id={'disabled-tooltip'}
                                    getContent={[
                                        () => {
                                            return customFuzzyMessage;
                                        },
                                    ]}
                                />
                            )}
                            {this.props.isFuzzyEnabled ? (
                                <FormattedMessage id="StartPage_CoreFunctionality_UIText_fuzzy.enabled" />
                            ) : (
                                <FormattedMessage id="StartPage_CoreFunctionality_UIText_fuzzy.disabled" />
                            )}
                            <SuperscriptTag text={'Beta'} name={'blue-tag'} />
                        </div>
                    )}
                    <div
                        className="toggle-for-fuzzy-names-wrapper__container"
                        ref={(ref) => (this.pillListContainer = ref)}
                    >
                        {(this.props.isFuzzyEnabled || this.props.isCustomFuzzy) &&
                            this.props.isFuzzyLoaded &&
                            (this.props.searchType === PERSON_SEARCH || this.props.isCustomFuzzy) && (
                                <PillList
                                    onRemoveItem={!this.props.isCustomFuzzy && this.deselectFuzzy}
                                    onAddItem={!this.props.isCustomFuzzy && this.selectFuzzy}
                                    onAllSelectionChange={!this.props.isCustomFuzzy && this.props.onAllSelectionChange}
                                    items={this.props.fuzzyNames.list}
                                    pillListContainerWidth={this.state.pillListContainerWidth}
                                />
                            )}
                        {this.props.isFuzzyEnabled &&
                            this.props.isFuzzyLoaded === false &&
                            this.props.searchType === PERSON_SEARCH &&
                            !this.props.newFuzzyEnabled && (
                                <LoadingPillList pillListContainerWidth={this.state.pillListContainerWidth} />
                            )}
                    </div>
                </div>
            </div>
        );
    }
}

export default compose(withAppContext, injectIntl, withRouter)(ToggleForFuzzyName);
