import React from 'react';
import { injectIntl } from 'react-intl';
import formatRichMessage from '@utils/formatRichMessage';

const SELECTED_HIT_ID = 'hit-selected';

class HitsNavigator extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            hitIndex: -1,
            currentHit: null,
        };
    }

    componentDidMount() {
        this.selectDefaultHit(this.props.hits[0]);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.state.currentHit === null) {
            this.handleNavigation(1, false);
        } else {
            const hasSelectedHit = nextProps.hits.some((hit) => hit.id === SELECTED_HIT_ID);

            if (hasSelectedHit !== true) this.selectDefaultHit(nextProps.hits[0]);
        }
    }

    handleNavigation = (value, withScroll) => {
        if (this.state.currentHit) this.state.currentHit.removeAttribute('id');

        let hitIndex = this.state.hitIndex + value;
        this.selectHitElement(hitIndex, withScroll);
    };

    selectHitElement = (hitIndex, withScroll) => {
        let currentHit = this.props.hits[hitIndex];
        currentHit.setAttribute('id', SELECTED_HIT_ID);
        if (withScroll) this.scrollToElement(currentHit);

        this.setState({ currentHit, hitIndex });
    };

    selectDefaultHit = (defaultHit) => {
        defaultHit.setAttribute('id', SELECTED_HIT_ID);
        this.setState({ currentHit: defaultHit, hitIndex: 0 });
    };

    scrollToElement = (element) => {
        let yMiddle = element.getBoundingClientRect().top + window.pageYOffset - window.innerHeight / 2;
        window.scrollTo(0, yMiddle);
    };

    render() {
        return (
            <div className="hits-navigator">
                <span data-testid="hits_count_testId">
                    {formatRichMessage({ id: 'SingleArticleHeader.hitsCount' }, this.props.intl, {
                        index: this.state.hitIndex + 1,
                        total: this.props.hits.length,
                    })}
                </span>
                <div className="hits-navigator-buttons">
                    <button
                        className="hits-button previous"
                        data-testid="previous_hit_testId"
                        disabled={this.state.hitIndex <= 0}
                        onClick={() => this.handleNavigation(-1, true)}
                    >
                        <div className="la-TriangleUp" />
                    </button>
                    <button
                        className="hits-button next"
                        data-testid="next_hit_testId"
                        disabled={this.state.hitIndex >= this.props.hits.length - 1}
                        onClick={() => this.handleNavigation(1, true)}
                    >
                        <div className="la-TriangleDown" />
                    </button>
                </div>
            </div>
        );
    }
}

export default injectIntl(HitsNavigator);
