/**
 * Filters an array of user objects based on a search text.
 *
 * This function takes an array of user objects and a search text as input. It filters the user array
 * based on the combination of the first name and last name of each user, and returns a new array
 * containing only the users whose names match the search text.
 *
 * @param {Object[]} users - An array of user objects, each containing at least 'firstName' and 'lastName' properties.
 * @param {string} shareViewSearchbarText - The search text to filter the user array.
 *
 * @returns {Array} - An array of user objects that match the search text. If the search text is empty or undefined,
 *                   the original array of users is returned.
 *
 */

export const filterShareViewUsers = (users, shareViewSearchbarText) => {
    return shareViewSearchbarText?.length ? users.filter((user) => {
        const userName = `${user.firstName} ${user.lastName}`.toLowerCase();
        return userName.includes(shareViewSearchbarText?.toLowerCase());
    }) : users;
};