// @flow
import { UPDATE_SEARCH_BAR_SOURCES_DROPDOWN } from './SearchBar.actions';
import type { Actions, SearchBarState } from './flow/SearchBar.type.guards';
import update from 'immutability-helper';

function searchBar(state: Object = null, action: Actions): SearchBarState {
    switch (action.type) {
        case UPDATE_SEARCH_BAR_SOURCES_DROPDOWN: {
            let sourcesDropdownContentTypes = action.payload || {};
            return update(state, { sourcesDropdown: { sources: { $set: sourcesDropdownContentTypes } } });
        }
        default:
            return state;
    }
}

export default searchBar;
