import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import searchSagaActions from '@pages/MainSearch/redux/searchSaga.actions';
import cellUtils from '../../utils/cellUtils';
import utils from '@utils/utilities';
import { CATEGORY_NAMES } from '@constants';

export class CellCountTemplate extends Component {
    getCompatibleCell(uncertainCell) {
        const text = uncertainCell.text;
        return { ...uncertainCell, text };
    }

    update(cell, cellToMerge) {
        return this.getCompatibleCell({ ...cell, text: cellToMerge.text });
    }

    render(cell) {
        const { text, isLoading, toolTip } = cell;
        const { dispatch, language } = text.extraProps;
        const customClassName = `${text?.displayName?.toLowerCase()} ${text?.category}`;
        const userFormattedCount = utils.formatNumbersByUserLocale(language, text.counts);

        const goToSearchResults = () => {
            const payload = cellUtils.computePayload(text, text.extraProps.searchResults);
            dispatch(searchSagaActions.runSearch(payload));
        };

        let props = {
            onClick: () => false,
            countValue: '-',
            clickable: false,
        };

        if (text.counts >= 0) {
            props = {
                onClick: goToSearchResults,
                countValue: userFormattedCount,
                clickable: !isLoading
            };
        }
        if (text.counts === -1 && text.category === CATEGORY_NAMES.DNB) {
            props = {
                onClick: goToSearchResults,
                countValue: '-',
                clickable: !isLoading,
            };
        }

        const renderContent = () => {
            if (isLoading) {
                return <div className="skeleton-loading" />;
            }
            return props.countValue;
        };
        const shouldDisplayTooltip = toolTip && toolTip.display === true && text.counts === -1;
        const countIsClickable = props.clickable && !isLoading;

        return (
            <div
                className={`grid-table-cell-count-template ${
                    !countIsClickable ? 'non-clickable' : ''
                } ${customClassName}`}
                onClick={countIsClickable ? props.onClick : null}
                onMouseEnter={() => {
                    ReactTooltip.rebuild();
                }}
            >
                {shouldDisplayTooltip ? (
                    <div
                        id={toolTip.id}
                        data-tip={toolTip.toolTipMessage}
                        data-for="grid-table-counts-tooltip"
                        data-html={true}
                    >
                        {renderContent()}
                    </div>
                ) : (
                    renderContent()
                )}
            </div>
        );
    }
}
