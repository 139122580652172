import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as BannerWarningIcon } from '../../../assets/icons/BannerWarningIcon.svg';
import { ReactComponent as BannerWarningIconDark } from '../../../assets/icons/BannerWarningIconDark.svg';

export const withUboDegreeOfSeparationWarningNote = (WrappedComponent) => (props) => {
    return props.uboArticle.exceedsMaxDegreeOfSeparation ? (
        <Fragment>
            <div className={`tradeup-container ${props.isDarkMode ? 'dark-mode' : ''}`}>
                <div className='tradeup-container__icon'>
                    {props.isDarkMode ? <BannerWarningIconDark /> : <BannerWarningIcon />}
                </div>
                <div className='tradeup-container__message'>
                    <FormattedMessage
                        id={'UboDocumentView.Warning.DegreeOfSeparation'}
                        values={{ degrees: props.uboArticle.summary.maximumDegreeOfSeparation }}
                    />
                </div>
            </div>
            <WrappedComponent {...props} />
        </Fragment>
    ) : (
        <WrappedComponent {...props} />
    );
};
