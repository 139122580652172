import { orderBy } from "lodash";

const noName = '*N/A*';

export const flattenTreeStructure = (treeStructure) => {
    const flattenedData = [];

    treeStructure && treeStructure.forEach(element => {
        let flattenedObj = {
            source: element.name,
            risk: element.level
        }
        !!element.countries.length && element.countries.forEach((country) => {
            flattenedObj.country = getSanctionDisplayName(country.name);

            !!country.authorities.length && country.authorities.forEach((authority) => {
                flattenedObj.authority = getSanctionDisplayName(authority.name);

                !!authority.listNames.length && authority.listNames.forEach((listName) => {
                    flattenedObj.list = getSanctionDisplayName(listName.name);
                    flattenedObj.risk = getCorrectSanctionRisk(flattenedObj, listName.level);
                    flattenedData.push({ ...flattenedObj });
                });
            })
        });
    });
    return flattenedData;
}

export const getTreeStructureFromFlattenedData = (flattenedData) => {
    const treeStructure = [];
    const updatedFlattenedData = replaceDashWithNoName(flattenedData);

    updatedFlattenedData.forEach(element => {
        let source = treeStructure.find(item => item.name === element.source);
        if (!source) {
            source = { name: element.source, level: element.risk, countries: [] };
            treeStructure.push(source);
        }

        let country = source.countries.find(item => item.name === element.country);
        if (!country) {
            country = { name: element.country, authorities: [] };
            source.countries.push(country);
        }

        let authority = country.authorities.find(item => item.name === element.authority);
        if (!authority) {
            authority = { name: element.authority, listNames: [] };
            country.authorities.push(authority);
        }

        let listName = authority.listNames.find(item => item.name === element.list);
        if (!listName) {
            listName = { name: element.list, level: element.risk };
            authority.listNames.push(listName);
        }
    });

    return { sources: treeStructure };
}


export const getSanctionDisplayName = (name) => {
    return name === noName ? '-' : name;
};

export const getSanctionPreferenceName = (name) => {
    return name === '-' ? noName : name;
}

export const replaceDashWithNoName = (flattenedData) => {
    return flattenedData.map((entityObj) => {
        const updatedEntityObj = { ...entityObj, source: getSanctionPreferenceName(entityObj.source), country: getSanctionPreferenceName(entityObj.country), authority: getSanctionPreferenceName(entityObj.authority), list: getSanctionPreferenceName(entityObj.list) };
        return updatedEntityObj;
    })
}

export const getCorrectSanctionRisk = (sanctionEntity, childRiskLevel) => {
    return areSanctionEntitiesNotDefined(sanctionEntity) ? sanctionEntity.risk : childRiskLevel;
}

export const areSanctionEntitiesNotDefined = (sanctionEntity) => {
    return sanctionEntity.country === noName && sanctionEntity.authority === noName && sanctionEntity.list === noName;
};

export const getUniqueValues = (sanctionData, type) => {
    return new Set(sanctionData.map(item => item[type]));
}

export const getDefaultFilterDataForType = (sanctionsData, type) => {
    return [...getUniqueValues(sanctionsData, type)].map(item => ({ name: item, checked: false }));
}

export const getFiltersData = (sanctionsData) => {
    const sources = getDefaultFilterDataForType(sanctionsData, 'source');
    const countries = getDefaultFilterDataForType(sanctionsData, 'country');
    const authorities = getDefaultFilterDataForType(sanctionsData, 'authority');
    const lists = getDefaultFilterDataForType(sanctionsData, 'list');

    return { source: sortAlphabetically(sources, 'name'), country: sortAlphabetically(countries, 'name'), authority: sortAlphabetically(authorities, 'name'), list: sortAlphabetically(lists, 'name') };
}

export const sortAlphabetically = (array, sortingProperty, direction = ['asc']) => {
    const sortedArray = orderBy(array, sortingProperty, direction);

    return sortedArray;
}

export const filterTableDataBasedOnSearchString = (dataToFilter, searchStringFromInput) => {
    const updatedTableData = dataToFilter.filter((entry) => entry.source.toLowerCase().includes(searchStringFromInput.toLowerCase()) ||
        entry.country.toLowerCase().includes(searchStringFromInput.toLowerCase()) ||
        entry.authority.toLowerCase().includes(searchStringFromInput.toLowerCase()) ||
        entry.list.toLowerCase().includes(searchStringFromInput.toLowerCase()));

    return updatedTableData;
}

export const filterTableDataBasedOnColumnFilters = (dataToFilter, filtersData) => {
    const activeSourceFilters = filtersData.source.filter((entry) => entry.checked).map((entry) => entry.name);
    const activeCountryFilters = filtersData.country.filter((entry) => entry.checked).map((entry) => entry.name);
    const activeAuthorityFilters = filtersData.authority.filter((entry) => entry.checked).map((entry) => entry.name);
    const activeListFilters = filtersData.list.filter((entry) => entry.checked).map((entry) => entry.name);
    const updatedTableData = dataToFilter.filter((entry) => {
        let shouldAdd = true;
        if (activeSourceFilters.length && !activeSourceFilters.includes(entry.source)) {
            shouldAdd = false;
        }
        if (activeCountryFilters.length && !activeCountryFilters.includes(entry.country)) {
            shouldAdd = false;
        }
        if (activeAuthorityFilters.length && !activeAuthorityFilters.includes(entry.authority)) {
            shouldAdd = false;
        }
        if (activeListFilters.length && !activeListFilters.includes(entry.list)) {
            shouldAdd = false;
        }

        if (shouldAdd) return entry;
    });
    return updatedTableData;
}





