import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import userPreferencesUtils from '@utils/userPreferencesUtils';
import userPreferencesActions from '@UserPreferences/redux/UserPreferences.actions';

const useTablePreferences = (sortInfo, tableColumns, setTableColumns, clearAllSelectedItems) => {
    const userPreferencesPayload = useSelector((state) => state.user.preferences.payload);
    const columnsPreferences = useSelector((state) => state.user.preferences.screeningEntity);
    const dispatch = useDispatch();

    // update user preferences when sort is changing
    useEffect(() => {
        clearAllSelectedItems();
        const updatedColumns = tableColumns.map((elem) => ({
            ...elem,
            sortDirection: elem.columnId === (sortInfo && sortInfo.sortBy) ? sortInfo.direction : null,
        }));
        setTableColumns(updatedColumns);
        // check if sortInfo.sortBy has a value before updating preferences
        // to avoid posting to preferences endpoint twice when resetting table
        if (sortInfo.sortBy) {
            const screeningEntity = {
                columns: { ...columnsPreferences.columns },
                sorting: {
                    sortBy: sortInfo && sortInfo.sortBy,
                    direction: sortInfo && sortInfo.direction,
                },
            };
            updateEntityViewPreferences(screeningEntity);
        }
    }, [sortInfo, dispatch]);

    const formatUserPreferencesPayload = (screeningEntityOptions) => {
        return {
            ...userPreferencesPayload,
            userPreferences: {
                ...userPreferencesPayload.userPreferences,
                screeningEntity: screeningEntityOptions,
            },
        };
    };

    const updateEntityViewPreferences = (screeningEntityOptions) => {
        const { updateEntityViewPreferences, updateUserPreferencesPayload } = userPreferencesActions;
        const dispatchActions = [
            () => dispatch(updateEntityViewPreferences(screeningEntityOptions)),
            () => dispatch(updateUserPreferencesPayload(formatUserPreferencesPayload(screeningEntityOptions))),
        ];

        userPreferencesUtils.updateEntityViewPreferences(screeningEntityOptions, dispatchActions);
    };

    return {
        updateEntityViewPreferences,
    };
};

export default useTablePreferences;
