// @flow
import * as React from 'react';
import { ARTICLE_NOTE_LENGTH, MODAL_TYPE } from '@constants';
import { FormattedMessage } from 'react-intl';

type Props = {
    addNoteToReport: (reportId: string, note: string) => void,
    deleteReportNote: (popupType: string) => void,
    editMode: boolean,
    index: number,
    notes: string,
    reportId: string,
};

type State = {
    notes: string,
    editMode: boolean,
};

class ReportNotes extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            notes: '',
            editMode: true,
        };
    }

    UNSAFE_componentWillMount() {
        //TODO: find an other way to update state
        this.setState({
            notes: this.props.notes,
            editMode: !(this.props.notes.length > 0 && this.props.notes.length < ARTICLE_NOTE_LENGTH),
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        const { notes, editMode } = this.props;

        if (nextProps.notes !== notes || nextProps.editMode !== editMode) {
            this.setState({
                notes: nextProps.notes || '',
                editMode: nextProps.editMode,
            });
        }
    }

    addReportNoteInput: (event: SyntheticInputEvent<HTMLTextAreaElement>) => void = (event) => {
        this.setState({
            notes: event.target.value,
        });
    };

    cancelReportNote: () => void = () => {
        const isEditMode = this.props.notes.length === 0;
        this.setState({
            notes: this.props.notes || '',
            editMode: isEditMode,
        });
    };

    editReportNote: () => void = () => {
        this.setState({
            editMode: true,
        });
    };

    render(): React.Node {
        const saveBtnState =
            this.state.notes.length > 0 &&
            this.state.notes.length < ARTICLE_NOTE_LENGTH &&
            this.state.notes !== this.props.notes;
        return (
            <div id={'qa-report-note_' + this.props.index} className="report-note-wrapper">
                <div className="report-note-header-title" data-track={`report-notes-${this.props.index}`}>
                    <FormattedMessage id={'Report.notes'} />
                </div>
                {!this.state.editMode ? (
                    <div className="existing-report-note-container">
                        <div className="report-note-text">{this.state.notes}</div>
                        <div className="report-note-actions">
                            <button
                                className="report-note-button"
                                data-testid="edit-report-note"
                                onClick={this.editReportNote}
                            >
                                <FormattedMessage id="General_CoreFunctionality_UIText_general.edit" />
                            </button>
                            <div className="vertical-divider" />
                            <button
                                className="report-note-button"
                                data-testid="delete-report-note"
                                onClick={() => {
                                    this.props.deleteReportNote(MODAL_TYPE.DELETE_REPORT_NOTE);
                                }}
                            >
                                <FormattedMessage id="General_CoreFunctionality_UIText_general.delete" />
                            </button>
                        </div>
                    </div>
                ) : (
                    <div className="input-note-container">
                        <textarea
                            className="text-area-standard"
                            id={`text-area-add-note-to-report-${this.props.reportId}`}
                            data-testid="report-note-input-field"
                            onChange={this.addReportNoteInput}
                            value={this.state.notes}
                        />
                        <div className="input-note-actions">
                            <button
                                className="report-note-button"
                                data-testid="add-report-note"
                                disabled={!saveBtnState}
                                onClick={() => {
                                    this.props.addNoteToReport(this.props.reportId, this.state.notes);
                                }}
                            >
                                <FormattedMessage id="General_CoreFunctionality_UIText_general.save" />
                            </button>
                            <div className="vertical-divider" />
                            <button
                                className="report-note-button"
                                data-testid="cancel-report-note"
                                onClick={this.cancelReportNote}
                            >
                                <FormattedMessage id="General_CoreFunctionality_UIText_general.cancel" />
                            </button>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default ReportNotes;
