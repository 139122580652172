import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { withAnonymizedToggleView } from '@utils/contexts';
import { GOOGLE_ANONYMIZED_PAGE_TITLE } from '@constants';

class GoogleAnonymized extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        document.title = GOOGLE_ANONYMIZED_PAGE_TITLE;
    }

    render() {
        return (
            <iframe
                width="100%"
                style={{ minHeight: 'calc(100vh - 13rem)' }}
                src={this.props.googleAnonymizedURL}
                title="google anonymyzed"
            />
        );
    }
}

const mapStateToProps = function (state) {
    return {
        googleAnonymizedURL: state.user.googleAnonymizedURL,
    };
};

export { GoogleAnonymized as TestGoogleAnonymized };
export default withRouter(connect(mapStateToProps)(withAnonymizedToggleView(GoogleAnonymized)));
