import { isEmpty } from 'lodash';
import categoryUtils from './categoryUtils';
import {
    CATEGORIES,
    CATEGORIES_WITH_DIFFERENT_DELIVERY_PODS,
    CATEGORY_NAMES,
    NEGATIVITY_COLORS,
    NEGATIVITY_LEVELS,
    TERMS_NEGATIVITY_MAPPING,
} from '@constants';
import NegativeNewsVisualisationsUtils from '@pages/MainSearch/components/negativeNewsVisualisations/resultsList/NegativeNewsVisualisationsUtils';
import utils from '@utils/utilities';
import SuggestedNamesUtils from '@reusable/SuggestedNames/SuggestedNamesUtils';

const SnapshotDeliveryUtils = {
    buildTopNegativeTerms(topNegativeTerms) {
        if (topNegativeTerms && topNegativeTerms.length > 0) {
            return topNegativeTerms.map((term) => {
                return {
                    lineChart: {
                        data: NegativeNewsVisualisationsUtils.buildSeriesDataWithYProperties(term.buckets),
                        color: TERMS_NEGATIVITY_MAPPING.DEFAULT.color,
                    },
                    count: term.count,
                    languageCode: term.languageCode,
                    negativity: term.negativity,
                    term: term.term,
                };
            });
        }
    },

    buildNegativeNewsPod(negativeNewsVisualisation) {
        let negativeNewsPod = {};
        if (negativeNewsVisualisation && !isEmpty(negativeNewsVisualisation)) {
            const hasNegativityRisk =
                negativeNewsVisualisation.negativityRisk && !isEmpty(negativeNewsVisualisation.negativityRisk);
            const chartColors = Object.keys(NEGATIVITY_COLORS).reduce(
                (levels, key) => ({
                    ...levels,
                    [NEGATIVITY_LEVELS[key]]: NEGATIVITY_COLORS[key],
                }),
                {}
            );

            delete chartColors[NEGATIVITY_LEVELS.DEFAULT];
            negativeNewsPod.overallRisk = hasNegativityRisk
                ? negativeNewsVisualisation.negativityRisk.overallRiskLevel
                : null;
            negativeNewsPod.donutChart = {
                articlesCounts: {
                    ...negativeNewsVisualisation.negativityRisk.documentsCount,
                },
                chartColors,
            };

            negativeNewsPod.topNegativeTerms = this.buildTopNegativeTerms(negativeNewsVisualisation.topNegativeTerms);
        }

        return negativeNewsPod;
    },

    buildCustomizedPod({ categoryName, searchResults, negativeNewsVisualisation, dateRangeForCategories, sanctionsRisk }) {
        const categoryResults = searchResults[categoryName];
        let pod = {
            [categoryName]: {
                resultsCount: categoryResults.count,
                enabled: categoryResults.enabled,
                order: CATEGORIES[categoryName].order,
            },
        };

        if (searchResults && !isEmpty(searchResults) && categoryName === CATEGORY_NAMES.NEGATIVE_NEWS) {
            const count = searchResults[categoryName].count;
            const negativeNewsPodConfig = this.buildNegativeNewsPod(negativeNewsVisualisation, count);

            pod = {
                ...pod,
                [categoryName]: {
                    ...pod[categoryName],
                    dateRange: categoryResults.postFilters.dateRange || dateRangeForCategories[CATEGORY_NAMES.NEWS],
                    ...negativeNewsPodConfig,
                },
            };
        }

        if (searchResults && !isEmpty(searchResults) && categoryName === CATEGORY_NAMES.ESG_RATINGS) {
            pod = {
                ...pod,
                [categoryName]: {
                    ...pod[categoryName],
                    lni: searchResults[categoryName].documentId,
                    overallRating: searchResults[categoryName].overallRating,
                    range: searchResults[categoryName].range,
                },
            };
        }

        if (searchResults && !isEmpty(searchResults) && categoryName === CATEGORY_NAMES.SANCTIONS_WATCHLIST) {
            if (sanctionsRisk) {
                pod = {
                    ...pod,
                    [categoryName]: {
                        ...pod[categoryName],
                        count: sanctionsRisk.count,
                        riskLevel: sanctionsRisk.riskLevel,
                        documents: sanctionsRisk.documents,
                    },
                };
            }
        }

        return pod;
    },

    buildStandardPod({ categoryName, searchResults, dateRangeForCategories }) {
        const categoriesToExcludeDateRange = [
            CATEGORY_NAMES.BIOGRAPHICAL,
            CATEGORY_NAMES.SANCTIONS_WATCHLIST,
            CATEGORY_NAMES.PEPS,
            CATEGORY_NAMES.FINANCIAL_REPORT,
        ];
        const categoryResults = searchResults[categoryName];
        let dateRangeCategory = categoryName;

        if (categoryName === CATEGORY_NAMES.CUSTOM_NEWS) {
            dateRangeCategory = CATEGORY_NAMES.NEWS;
        }

        const dateRange = categoriesToExcludeDateRange.includes(dateRangeCategory)
            ? undefined
            : categoryResults.postFilters.dateRange || dateRangeForCategories[dateRangeCategory];

        if (searchResults && !isEmpty(searchResults)) {
            return {
                resultsCount: categoryResults.count,
                enabled: categoryResults.enabled,
                order: CATEGORIES[categoryName] ? CATEGORIES[categoryName].order : null,
                dateRange,
                sourceType: categoryName,
            };
        }
    },

    buildPodsForSnapshotDelivery({ searchResults, negativeNewsVisualisation, dateRangeForCategories, sanctionsRisk }) {
        let pods = { standard: [] };

        if (searchResults && !isEmpty(searchResults)) {
            Object.values(searchResults).forEach((category) => {
                if (!categoryUtils.isChild(category.name)) {
                    if (CATEGORIES_WITH_DIFFERENT_DELIVERY_PODS.indexOf(category.name) > -1) {
                        const pod = this.buildCustomizedPod({
                            categoryName: category.name,
                            searchResults,
                            negativeNewsVisualisation,
                            dateRangeForCategories,
                            sanctionsRisk,
                        });
                        pods = {
                            ...pods,
                            ...pod,
                        };
                    } else {
                        const pod = this.buildStandardPod({
                            categoryName: category.name,
                            searchResults,
                            dateRangeForCategories,
                        });
                        pods.standard.push(pod);
                    }
                }
            });
        }

        return pods;
    },

    getSnapshotDeliveryObject(state) {
        const currentDate: Date = new Date();

        const dateRangeForCategories = categoryUtils.getDateRangeForCategories({
            adHocNewsDateRange: state.adHocSearch.newsDateRange,
            adHocLegalDateRange: state.adHocSearch.legalDateRange,
            adHocCompanyDateRange: state.adHocSearch.companyDateRange,
            generalSettings: state.user.preferences.generalSettings,
        });

        const { searchResults } = state;
        let hasFiltersApplied;
        if (searchResults) {
            hasFiltersApplied = Object.values(searchResults).some(
                (categoryData: Object) => categoryData.hasFiltersApplied
            );
        }

        return {
            searchQuery: utils.mergeQueryAndBoolean(state.searchParams.query, state.searchParams.prefilterQuery),
            suggestedNames: SuggestedNamesUtils.getSelectedSuggestedNames(state.suggestedNames),
            deliveryDate: utils.formatDateWithShortMonth(currentDate, state.user.preferences.language),
            filtersApplied: hasFiltersApplied,
            displayRiskScores: state.user.preferences.generalSettings.displayRiskScores,
            pods: this.buildPodsForSnapshotDelivery({
                searchResults,
                negativeNewsVisualisation: state.negativeNewsVisualisation,
                dateRangeForCategories,
                sanctionsRisk: state.sanctionsRisk
            }),
        };
    },

    addSnapshotToPayload(state, contentsOptions, payload) {
        if (contentsOptions?.shouldContainSnapshot) {
            payload.snapshotDelivery = this.getSnapshotDeliveryObject(state);
        }
    },
};

export default SnapshotDeliveryUtils;
