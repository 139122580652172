// @flow
import * as React from 'react';
import { injectIntl } from 'react-intl';
import ReportHeader from './sharedComponents/Header/ReportHeader';
import { withHideForBatchReports } from '@ReportBuilder/HOC/withReportHoc';
import type { BatchReportType } from '@ReportBuilder/redux/flow/ReportBuilder.type.guards';
import type { ContentOptions } from '@reusable/PopupModal/Components/ModalContentOptions';
import utils from '@utils/utilities';
import formatRichMessage from '@utils/formatRichMessage';
import { MODAL_TYPE, REPORT_DELIVERY_STATUSES } from '@constants';
import Content from './batchReportComponents/Content';
import { CategoryDeleteRequest } from '@ReportBuilder/redux/flow/ReportBuilder.type.guards';

type Props = {
    index: number,
    row: BatchReportType,
    report: BatchReportType,
    language: string,
    contentsOptions: Array<ContentOptions>,
    prReportDeliveryAvailabilityDays?: number,
    reportDeliveryAvailabilityDays?: number,
    emailAddress: string,
    modifiedSections?: Array<string>,
    batchReportsPooling: Array<string>,
    removeAutosaveMessage?: (hasError: boolean, autosavedSectionId: string) => void,
    showAutosaveMessage?: (categoryId: string) => void,
    autosaveHasError?: (hasError: boolean) => void,
    jumpToReport?: (reportId: string) => void,
    addNoteToReport?: (reportId: string, note: string) => void,
    onDelete?: (reportId: string, reportTitle: string) => void,
    onCategoryDelete: (categoryDeleteRequest: CategoryDeleteRequest) => void,
    downloadReport: (event: any) => void,
    startBatchReportPooling: (model: any) => void,
    onToggleSelect?: (item: string, value: boolean) => void,
    onToggleReport: (reportId: string) => Promise<Object>,
    updateReportProperty: ({
        reportId: string,
        reportProperty: string,
        propertyValue: mixed,
        isSelected?: boolean,
    }) => void,
    updateProperty: (reportId: string, propertyName: string, propertyValue: mixed) => void,
    +onUpdateReportBuilderPage: (reportId: string) => void,
    +intl: Object,
};

type State = {
    showCategories: boolean,
};

class BatchReport extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            showCategories: false,
        };
    }

    componentDidMount() {
        const {
            report: { id: reportId, reportDelivery: reportDeliveryObject, showSpinner },
            batchReportsPooling,
            updateProperty,
        } = this.props;
        const lastReportDeliveryObject = reportDeliveryObject[reportDeliveryObject.length - 1];
        const finishedBatchReportStatuses = [
            REPORT_DELIVERY_STATUSES.ZIP_GENERATED,
            REPORT_DELIVERY_STATUSES.ZIP_GENERATED_WITH_ERRORS,
            REPORT_DELIVERY_STATUSES.ERROR,
            REPORT_DELIVERY_STATUSES.ZIP_NOT_GENERATED,
        ];
        const isBatchReportProcessing = !!lastReportDeliveryObject?.status
            ? finishedBatchReportStatuses.indexOf(lastReportDeliveryObject.status) === -1
            : false;
        const isBatchReportPooling = batchReportsPooling.indexOf(reportId) > -1;

        if ((isBatchReportPooling || isBatchReportProcessing) && !showSpinner) {
            updateProperty(reportId, 'showSpinner', true);
            updateProperty(reportId, 'buildStatus', 'STARTED');
        }

        if (!reportDeliveryObject.length || isBatchReportPooling) {
            return;
        }

        const model = {
            reportTitle: lastReportDeliveryObject.filename,
            docType: lastReportDeliveryObject.filetype,
            reportId,
            buildId: lastReportDeliveryObject.id,
            contentsOptions: this.props.contentsOptions,
            popupType: MODAL_TYPE.DOWNLOAD_BATCH_REPORT,
        };

        if (isBatchReportProcessing) {
            this.props.startBatchReportPooling(model);
        }
    }

    render(): React.Node {
        const { report, language, index } = this.props;
        const reportsCountMessageId: string = `${
            report.childReports.length === 1 ? 'General.report' : 'General.reports'
        }`;
        const documentCounts: string = `${
            report.childReports
                ? utils.formatNumbersByUserLocale(language, report.childReports.length)
                : report.childReports.length
        } ${formatRichMessage({ id: reportsCountMessageId }, this.props.intl)}`;

        return (
            <div className="report-row-wrapper">
                <ReportHeader
                    report={report}
                    index={index}
                    updateReportProperty={this.props.updateReportProperty}
                    reportDeliveryAvailabilityDays={this.props.reportDeliveryAvailabilityDays}
                    prReportDeliveryAvailabilityDays={this.props.prReportDeliveryAvailabilityDays}
                    downloadReport={this.props.downloadReport}
                    handleDeleteReport={this.props.onDelete}
                    handleCategoryDeleteReport={this.props.onCategoryDelete}
                    row={this.props.row}
                    onToggleSelect={this.props.onToggleSelect}
                    handleToggleReport={this.props.onToggleReport}
                    downloadLinkMessage={{
                        downloadNow: 'Report.reportReadyForDownload',
                        downloadAgain: 'Report.reportReadyForDownloadAgain',
                    }}
                    countMessage={documentCounts}
                />
                <Content
                    isReportExpanded={this.props.report.isSelected}
                    isLoading={this.props.report.loadingEntities}
                    report={this.props.report}
                    showDownloadReportPopup={false}
                    loader={{
                        loaderClassName: 'report-loader',
                        wrapperClassName: 'batch-report',
                    }}
                />
            </div>
        );
    }
}

export default (injectIntl(withHideForBatchReports(BatchReport)): React.AbstractComponent<Props, State>);

export { BatchReport as TestBatchReport };
