import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { ALERT_MESSAGE_TYPE } from '@constants';
import { ReactComponent as BannerInfoIcon } from '../../../assets/icons/BannerInfoIcon.svg';
import { ReactComponent as BannerInfoIconDark } from '../../../assets/icons/BannerInfoIconDark.svg';
import { ReactComponent as BannerWarningIcon } from '../../../assets/icons/BannerWarningIcon.svg';
import { ReactComponent as BannerWarningIconDark } from '../../../assets/icons/BannerWarningIconDark.svg';

const AlertMessage = ({ ids = [], type = ALERT_MESSAGE_TYPE.INFO }) => {
    const isDarkMode = useSelector((state) => state.user.preferences.generalSettings.isDarkMode);
    const Icon = type === ALERT_MESSAGE_TYPE.WARNING ? isDarkMode ? BannerWarningIconDark : BannerWarningIcon : isDarkMode ? BannerInfoIconDark : BannerInfoIcon;
    const alertTypeClass = type === ALERT_MESSAGE_TYPE.WARNING ? 'warning' : 'info';

    return (
        <div className={`alert-message-${alertTypeClass} ${isDarkMode ? 'dark-mode' : ''}`}>
            <div className="alert-message__icon">
                <Icon />
            </div>
            <div className="alert-message__message">
                {ids.map((id, index) => (
                    <div key={index}>
                        <FormattedMessage key={`alert-message-${index}`} id={id} />{' '}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default AlertMessage;
