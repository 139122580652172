import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SourcesComponent from '@UserPreferences/components/SourcesComponent';
import { cloneDeep, difference, intersection, uniq } from 'lodash';
import categoryUtils from '@utils/categoryUtils';
import { CATEGORIES_ORDER, CATEGORY_NAMES } from '@constants';
import AlertUtils from '@utils/alertsUtils';

export default class AlertsSourcesComponent extends Component {
    static propTypes = {
        contentTypes: PropTypes.array,
        legalSources: PropTypes.array,
        legalSource: PropTypes.any,
        onSourcesUpdate: PropTypes.func,
        sources: PropTypes.array,
        originalSources: PropTypes.array,
        originalContentLanguages: PropTypes.array,
        postFilters: PropTypes.array,
    };

    constructor(props) {
        super(props);
        this.handleSourceSelection = this.handleSourceSelection.bind(this);
        this.handleSubSourceSelection = this.handleSubSourceSelection.bind(this);
        this.getCustomNewsDisabledStatus = this.getCustomNewsDisabledStatus.bind(this);
    }

    hasCheckedSubCategories = (sources) => intersection(this.props.sources, sources).length > 0;

    handleSourceSelection(event, sourceName) {
        let sources = null;
        let sourcesFromProps = cloneDeep(this.props.sources);

        // Get the legal sources, if any
        if (categoryUtils.hasChildren(sourceName) && !categoryUtils.hasExtendedChildren(sourceName)) {
            const idSubCategories = categoryUtils.getList(sourceName).map((category) => category.key);

            // get only checked categories
            let checkedSubcategories = intersection(idSubCategories, this.props.originalSources);

            if (this.hasCheckedSubCategories(idSubCategories)) {
                sources = sourcesFromProps.filter((source) => idSubCategories.indexOf(source) === -1);
            } else {
                sources = uniq([...sourcesFromProps, ...checkedSubcategories]);
            }
        } else {
            if (categoryUtils.hasExtendedChildren(sourceName)) {
                const idSubCategories = categoryUtils.getList(sourceName).map((category) => category.key);
                if (idSubCategories.some((category) => sourcesFromProps.indexOf(category) >= 0)) {
                    sources = sourcesFromProps.filter((source) => idSubCategories.indexOf(source) === -1);
                } else {
                    sources = uniq([...sourcesFromProps, ...idSubCategories]);
                }
            } else {
                if (sourcesFromProps.indexOf(sourceName) > -1) {
                    // if exists in list, remove it
                    sources = sourcesFromProps.filter((source) => {
                        return source !== sourceName;
                    });
                } else {
                    // otherwise add it and trigger source update
                    sources = [...sourcesFromProps, sourceName];
                }
            }
        }
        this.props.onSourcesUpdate(sources);
    }

    handleSubSourceSelection(parentKey, subSources) {
        let sourcesFromProps = cloneDeep(this.props.sources);
        let allChildrenKeys = categoryUtils.getList(parentKey).map((child) => child.key);
        let selectedSources = subSources.map((child) => child.key);

        let updatedSources = [...difference(sourcesFromProps, allChildrenKeys), ...selectedSources];
        this.props.onSourcesUpdate(updatedSources);

        if (parentKey === CATEGORY_NAMES.NEGATIVE_NEWS) {
            let contentLanguages = subSources.map((child) => child.name);
            this.props.handleSelectContentLanguages(contentLanguages);
        }

        if (subSources.length === 0 && sourcesFromProps.indexOf(parentKey) > -1)
            this.handleSourceSelection(null, parentKey);
    }

    getCustomNewsDisabledStatus(sources) {
        let tempContentTypes = cloneDeep(sources);

        tempContentTypes.forEach((contentType) => {
            if (contentType.key === CATEGORIES_ORDER.customNews.key) {
                contentType.disabled = this.props.customNews.length === 0;
            }
        });

        return tempContentTypes;
    }

    render() {
        // function to map content type to source, if has one children, check if they are selected as well
        const mapContentTypeSource = (type) => {
            // show if the the sources were used to run the search or were saved on the alert at the moment of creation
            const shouldShow =
                this.props.originalSources.indexOf(type.name) > -1 ||
                categoryUtils
                    .getCategoryKeys(type.name)
                    .some((contentType) => this.props.originalSources.indexOf(contentType) >= 0);

            return {
                key: type.name,
                name: categoryUtils.getCategoryName(type.name),
                checked:
                    this.props.sources.indexOf(type.name) > -1 ||
                    this.hasCheckedSubCategories(categoryUtils.getCategoryKeys(type.name)),
                children: mapChildrenList(type),
                show: shouldShow,
            };
        };

        const mapChildrenList = (type) => {
            if (categoryUtils.getCategory(type.name).hasSelectableSubcategories) {
                let childrenList = categoryUtils
                    .getList(type.name)
                    .filter((child) => child && !categoryUtils.isDockets(child.key));

                return childrenList.map((child) => {
                    let name;

                    switch (type.name) {
                        case CATEGORY_NAMES.NEGATIVE_NEWS:
                            name = child.extendConfig().contentLanguage;
                            return {
                                name,
                                key: child.key,
                                checked:
                                    this.props.sources.indexOf(child.key) > -1 &&
                                    this.props.originalContentLanguages.indexOf(name) > -1,
                                enabled: this.props.originalContentLanguages.indexOf(name) > -1,
                            };
                        case CATEGORY_NAMES.LAW_SOURCES:
                            return {
                                name: child.name,
                                key: child.key,
                                checked:
                                    this.props.sources.indexOf(child.key) > -1 &&
                                    this.props.originalLegalSources.indexOf(child.key) > -1,
                                enabled: this.props.originalLegalSources.indexOf(child.key) > -1,
                            };

                        default:
                            return null;
                    }
                });
            }
        };

        // Filter out, leave only the first level (parents), transform and then sort
        const sources = this.props.contentTypes
            .filter((type) => AlertUtils.isParentAndIsAllowedForAlerts(type.name))
            .map(mapContentTypeSource)
            .sort((a, b) => (a.order < b.order ? -1 : 1));

        return (
            <SourcesComponent
                {...this.props}
                sources={this.getCustomNewsDisabledStatus(sources)}
                handleSourceSelection={this.handleSourceSelection}
                handleSubSourcesSelection={this.handleSubSourceSelection}
            />
        );
    }
}
