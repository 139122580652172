import React from 'react';
import HistoryApi from '@HistoryPage/History.api';

const withHistoryEndpoints = (HandlerProvider) => (props) => {
    const handleApiGet = (loadParams) => {
        const { pagination, publicRecordsEnabled } = loadParams;
        return HistoryApi.getHistory(pagination.pageNumber, pagination.pageSize, publicRecordsEnabled);
    };

    const handleHistoryDownload = (payload, selectedItems, shouldDownloadFullList) => {
        return HistoryApi.downloadHistory(payload, selectedItems, shouldDownloadFullList);
    };

    const handleGetHistorySearchData = (id, publicRecordsEnabled) => {
        return HistoryApi.getHistoryData(id, publicRecordsEnabled);
    };

    const handleApiCount = (loadParams) => {
        const { publicRecordsEnabled } = loadParams;
        return HistoryApi.getHistoryCount(publicRecordsEnabled);
    };

    const handleHistoryDelete = () => {
        return HistoryApi.deleteAllHistory();
    };

    const initHandlers = {
        history: {
            onDownloadHistory: handleHistoryDownload,
            onDeleteAllHistory: handleHistoryDelete,
        },
    };

    return (
        <HandlerProvider
            {...props}
            onApiGet={handleApiGet}
            onApiCount={handleApiCount}
            onApiGetAdditionalData={handleGetHistorySearchData}
            initHandlers={initHandlers}
        />
    );
};

export default withHistoryEndpoints;
