import React from 'react';
import useDragAndDrop from '@scripts/hooks/useDragAndDrop';
import { DRAG_AND_DROP } from '@constants';

const ModalReorderableItem = (props) => {
    const { items, currentItem, droppedMenuItemId, showDropTarget, insertAbove, visibleMenu } = props;

    const dndHookInfo = {
        componentType: 'child',
        accept: DRAG_AND_DROP.MODAL_REORDER_ITEMS_LIST,
    };

    const { connectDragSource, isDragging, connectDropTarget } = useDragAndDrop(props, dndHookInfo);

    const id = `modal-reorder-item-${props.index}`;
    const showDroppedAnimation = currentItem.id === droppedMenuItemId;
    const isCurrentMenuVisible = visibleMenu === id;
    const isDragAndDropDisabled = visibleMenu !== null;
    const hasSiblings = items && items.length > 1;

    const reorderableItemContent = (
        <div
            className={`modal-reorderable-item-container ${isDragging ? 'dragging' : ''} ${
                hasSiblings ? 'with-multiple-items' : ''
            } ${isCurrentMenuVisible ? 'selected-row' : ''} ${isDragAndDropDisabled ? 'drag-and-drop-disabled' : ''}`}
        >
            <li
                className={
                    'modal-reorderable-item-row ' +
                    (isDragging ? 'dragging' : '') +
                    (showDropTarget ? (insertAbove ? ' drop-top ' : ' drop-bottom ') : '') +
                    (showDroppedAnimation ? 'dropped' : '')
                }
                id={'qa-reorderable-item_' + currentItem.id}
                key={currentItem.id}
            >
                <div className="reorderable-item-title">
                    <div className="reorderable-item-title-index">
                        {hasSiblings && <div className="draggable-icon" />}
                        <span className={'item-title'}>{currentItem.title}</span>
                    </div>
                </div>
            </li>
        </div>
    );

    return hasSiblings ? connectDragSource(connectDropTarget(reorderableItemContent)) : reorderableItemContent;
};

export default ModalReorderableItem;

export { ModalReorderableItem as TestModalReorderableItem };
