import React from 'react';
import { hashHistory } from 'react-router';
import { DOCUMENT_PAGE_TITLE, ID_TYPES } from '@constants';
import categoryUtils from '@utils/categoryUtils';
const UNDEFINED = 'undefined';

class DeepLink extends React.Component {
    componentDidMount() {
        document.title = DOCUMENT_PAGE_TITLE;
        let params = this.props.location.query;
        let link, idType;

        const restQueryParams = this.convertVariablesToQueryParams({
            billingId: params.billingId,
            costCode: params.costCode,
            singleSource: params.singleSource,
        });

        if (params.pcsi) {
            idType = `${ID_TYPES.DOC_ID}=${params.id}`;
        } else if (params.docId) {
            idType = `${ID_TYPES.DOC_ID}=${params.docId}`;
        } else {
            idType = `${ID_TYPES.ID}=${params.id}`;
        }

        link =
            `document?query=${encodeURIComponent(params.query)}&type=${params.type}&${idType}&category=${
                params.category
            }` + restQueryParams;
        if (categoryUtils.isDnbCategory(params.category)) {
            link = `ubo-document/${params.id}/${encodeURIComponent(params.query)}/${
                params.uboTradeUp
            }/${encodeURIComponent(params.title)}`;
        }
        hashHistory.push(link);
    }

    convertVariablesToQueryParams = (vars) => {
        let queryParams = '';

        Object.keys(vars).forEach((key) => {
            if (vars[key] && vars[key] !== UNDEFINED) {
                queryParams += `&${key}=${vars[key]}`;
            }
        });

        return queryParams;
    };

    render() {
        return <div />;
    }
}
export default DeepLink;
