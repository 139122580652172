import { cloneDeep, isEmpty, isNil } from 'lodash';
import errorUtils from '@utils/errors/error-utils';
import update from 'immutability-helper';

export const RESET_SUGGESTED_NAMES = 'RESET_SUGGESTED_NAMES';
export const UPDATE_SUGGESTED_NAMES = 'UPDATE_SUGGESTED_NAMES';
export const UPDATE_ALL_SUGGESTED_NAMES_SELECTION = 'UPDATE_ALL_SUGGESTED_NAMES_SELECTION';
export const UPDATE_SUGGESTED_NAMES_SELECTION = 'UPDATE_SUGGESTED_NAMES_SELECTION';
export const SUGGESTED_NAMES_ARE_EXCEEDED = 'SUGGESTED_NAMES_ARE_EXCEEDED';
export const SUGGESTED_NAMES_ARE_LOADED = 'SUGGESTED_NAMES_ARE_LOADED';
export const UPDATE_LOADED_SUGGESTIONS_FOR_TERMS = 'UPDATED_LOADED_SUGGESTIONS_FOR_TERMS';
export const UPDATE_SUGGESTED_NAMES_QUERY = 'UPDATE_SUGGESTED_NAMES_QUERY';
export const UPDATE_LOADED_STATE_FOR_TERM = 'UPDATE_LOADED_STATE_FOR_TERM';
export const UPDATE_LOADED_TERMS = 'UPDATE_LOADED_TERMS';

const APP_MODULE = 'suggestedNames.reducers';

function suggestedNames(state = null, action) {
    switch (action.type) {
        case UPDATE_SUGGESTED_NAMES: {
            if (!action.payload || isEmpty(action.payload)) {
                errorUtils.logAppError(
                    'Missing parameter in ' + UPDATE_SUGGESTED_NAMES,
                    { payload: action.payload },
                    APP_MODULE
                );

                return state;
            }

            let suggestedNames = cloneDeep(state);
            suggestedNames = {
                ...suggestedNames,
                ...action.payload,
            };

            return update(state, { $set: suggestedNames });
        }

        case RESET_SUGGESTED_NAMES: {
            return update(state, {
                $set: {
                    query: '',
                    list: [],
                    isSuggestedNamesLoaded: false,
                    hasExceededSuggestedNames: false,
                    loadedSuggestionsForTerms: {},
                },
            });
        }

        case UPDATE_SUGGESTED_NAMES_SELECTION: {
            if (!action.payload || isEmpty(action.payload)) {
                errorUtils.logAppError(
                    'Missing parameter in ' + UPDATE_SUGGESTED_NAMES_SELECTION,
                    { payload: action.payload },
                    APP_MODULE
                );

                return state;
            }

            let { itemValue, selected } = action.payload;
            let suggestedNames = cloneDeep(state.list);

            if (itemValue) {
                suggestedNames = suggestedNames.map((item) => {
                    return item.name === itemValue.name && item.index === itemValue.index
                        ? { ...item, selected }
                        : item;
                });
            }

            return update(state, { list: { $set: suggestedNames } });
        }

        case UPDATE_ALL_SUGGESTED_NAMES_SELECTION: {
            if (!action.payload || isEmpty(action.payload)) {
                errorUtils.logAppError(
                    'Missing parameter in ' + UPDATE_ALL_SUGGESTED_NAMES_SELECTION,
                    { payload: action.payload },
                    APP_MODULE
                );

                return state;
            }

            let { visibleSuggestedNames, selected, index } = action.payload;
            let suggestedNames = cloneDeep(state.list);

            if (visibleSuggestedNames) {
                suggestedNames = suggestedNames.map((item) => {
                    const isInArray = visibleSuggestedNames.find(
                        (visibleItem) => visibleItem.name === item.name && item.index === +index
                    );

                    return isInArray ? { ...item, selected } : item;
                });
            }

            return update(state, { list: { $set: suggestedNames } });
        }

        case SUGGESTED_NAMES_ARE_LOADED: {
            if (action.payload !== false && action.payload !== true) {
                errorUtils.logAppError(
                    'Missing parameter in ' + SUGGESTED_NAMES_ARE_LOADED,
                    { 'toggle state': action.payload },
                    APP_MODULE
                );
                return state;
            }

            return update(state, { isSuggestedNamesLoaded: { $set: action.payload } });
        }

        case SUGGESTED_NAMES_ARE_EXCEEDED: {
            if (action.payload !== false && action.payload !== true) {
                errorUtils.logAppError(
                    'Missing parameter in ' + SUGGESTED_NAMES_ARE_EXCEEDED,
                    { 'toggle state': action.payload },
                    APP_MODULE
                );
                return state;
            }

            return update(state, { hasExceededSuggestedNames: { $set: action.payload } });
        }

        case UPDATE_LOADED_SUGGESTIONS_FOR_TERMS: {
            if (!action.payload || isEmpty(action.payload)) {
                errorUtils.logAppError(
                    'Missing parameter in ' + UPDATE_LOADED_SUGGESTIONS_FOR_TERMS,
                    { payload: action.payload },
                    APP_MODULE
                );

                return state;
            }

            let loadedTerms = { ...state.loadedSuggestionsForTerms };

            if (action.payload.loadedTerm && action.payload.index !== undefined) {
                loadedTerms[action.payload.index] = action.payload.loadedTerm;
            }

            return update(state, { loadedSuggestionsForTerms: { $set: loadedTerms } });
        }

        case UPDATE_LOADED_STATE_FOR_TERM: {
            if (!action || !action.payload || isEmpty(action.payload)) {
                errorUtils.logAppError(
                    'Missing parameter in ' + UPDATE_LOADED_STATE_FOR_TERM,
                    { payload: action.payload },
                    APP_MODULE
                );

                return state;
            }

            let loadedTerms = { ...state.loadedSuggestionsForTerms };

            if (!isNil(action.payload.index) && !isNil(action.payload.loaded)) {
                loadedTerms[action.payload.index] = action.payload.loaded;
            }

            return update(state, { loadedSuggestionsForTerms: { $set: loadedTerms } });
        }

        case UPDATE_LOADED_TERMS: {
            if (!action || !action.payload || isEmpty(action.payload)) {
                errorUtils.logAppError(
                    'Missing parameter in ' + UPDATE_LOADED_TERMS,
                    { payload: action.payload },
                    APP_MODULE
                );

                return state;
            }

            return update(state, { loadedSuggestionsForTerms: { $set: action.payload.loadedTerms } });
        }

        case UPDATE_SUGGESTED_NAMES_QUERY: {
            if (!action.payload || isEmpty(action.payload)) {
                errorUtils.logAppError(
                    'Missing parameter in ' + UPDATE_SUGGESTED_NAMES_QUERY,
                    { payload: action.payload },
                    APP_MODULE
                );

                return state;
            }

            return update(state, { query: { $set: action.payload.query } });
        }

        default:
            return state;
    }
}

export default suggestedNames;
