// @flow
import * as React from 'react';
import { useSelector } from 'react-redux';
import utils from '@utils/utilities';
import categoryUtils from '@utils/categoryUtils';
import formatRichMessage from '@utils/formatRichMessage';
import { injectIntl } from 'react-intl';
import ArticlesUtils from '../ArticlesUtils';
// $FlowFixMe
import { ReactComponent as BannerInfoIcon } from '../../../../assets/icons/BannerInfoIcon.svg';
// $FlowFixMe
import { ReactComponent as BannerInfoIconDark } from '../../../../assets/icons/BannerInfoIconDark.svg';

type RequiredProps = {
    category?: string,
    article: {
        publicationType: Array<string>,
    },
    intl: {
        formatMessage: ({ id: string }) => void,
    },
};

const withDisclaimer = <Props: {} & RequiredProps > (
    Component: React.AbstractComponent<Props>
        ): React.AbstractComponent<Props> | null => {
    const withDisclaimerMessage = (props: Props & RequiredProps) => {
        const isDarkMode = useSelector((state) => state.user.preferences.generalSettings.isDarkMode);
            let id;
            if (categoryUtils.isDockets(props.category)) {
                id = 'ArticleView.docketsNotice';
        }
            if (props.article && ArticlesUtils.isWebnewsArticle(props.article.publicationType)) {
                id = 'ArticleView.webNewsNotice';
        }

            const message = id ? (
            <div className={`article-view-disclaimer ${isDarkMode ? 'dark-mode' : ''}`} data-testid='disclaimer-message'>
                <div className='article-view-disclaimer__icon'>
                    {isDarkMode ? <BannerInfoIconDark /> : <BannerInfoIcon />}
                </div>
                <div className='article-view-disclaimer__message'>{formatRichMessage({ id }, props.intl)}</div>
            </div>
            ) : null;
            return <Component {...props} disclaimerMessage={message} />;
    };

            withDisclaimerMessage.displayName = `withDisclaimerMessage(${utils.getDisplayName(Component)})`;

            return injectIntl(withDisclaimerMessage);
};

            export default withDisclaimer;
