import React from 'react';
import AlertsApi from '@ManageAlerts/ManageAlertsApi';

const withMyAlertsEndpoints = (HandlerProvider) => (props) => {
    const handleApiCount = (loadParams) => {
        const { filtering } = loadParams;
        const nameFilter = filtering.filters && filtering.filters.nameFilter ? filtering.filters.nameFilter : '';
        return AlertsApi.getMyAlertsCount(false, nameFilter);
    };

    const handleApiGet = (loadParams) => {
        const { pagination, filtering, sorting } = loadParams;
        const nameFilter = filtering.filters && filtering.filters.nameFilter ? filtering.filters.nameFilter : '';
        return AlertsApi.getMyAlerts(
            pagination.pageNumber,
            pagination.pageSize,
            sorting.sortBy,
            sorting.direction,
            nameFilter
        );
    };

    const handleAlertsPause = (alerts) => {
        return AlertsApi.deactivateMyAlerts(alerts.map((alert) => alert.id));
    };

    const handleAlertsResume = (alerts) => {
        return AlertsApi.activateMyAlerts(alerts.map((alert) => alert.id));
    };

    const handleAlertsDelete = (alerts) => {
        return AlertsApi.deleteMyAlerts(alerts.map((alert) => alert.id));
    };

    const handleAlertsShare = (alerts) => {
        console.log(
            'handleAlertsShare',
            alerts.map((alert) => alert.id)
        );
    };

    const initHandlers = {
        alerts: {
            onAlertsPause: handleAlertsPause,
            onAlertsResume: handleAlertsResume,
            onAlertsDelete: handleAlertsDelete,
            onAlertsShare: handleAlertsShare,
        },
    };

    return (
        <HandlerProvider {...props} onApiGet={handleApiGet} onApiCount={handleApiCount} initHandlers={initHandlers} />
    );
};

export default withMyAlertsEndpoints;
