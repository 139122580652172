import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DateSelect from './DateSelect';
import { CALENDAR_DATE_FORMAT_FE } from '@constants';
import formatRichMessage from '@utils/formatRichMessage';
import { FormattedMessage, injectIntl } from 'react-intl';

class PostFilterDateRangeSelect extends Component {
    static propTypes = {
        onRangeSelect: PropTypes.func.isRequired,
        initialValues: PropTypes.shape({
            from: PropTypes.instanceOf(Date),
            to: PropTypes.instanceOf(Date),
        }),
        className: PropTypes.string,
        isValidating: PropTypes.bool,
        dateFormat: PropTypes.string,
        shouldUpdateCustomDatesState: PropTypes.bool,
        updateCustomDatesBasedOnNNV: PropTypes.func,
    };

    static defaultProps = {
        initialValues: null,
        className: '',
        isValidating: false,
        dateFormat: CALENDAR_DATE_FORMAT_FE,
    };

    constructor(props) {
        super(props);

        this.state = {
            from: props.initialValues ? props.initialValues.from : null,
            to: props.initialValues ? props.initialValues.to : null,
            showFromError: false,
            showToError: false,
        };

        this.handleSelectFromDate = this.handleSelectFromDate.bind(this);
        this.handleSelectToDate = this.handleSelectToDate.bind(this);
        this.applyCustomDate = this.applyCustomDate.bind(this);
    }

    handleSelectFromDate(from) {
        this.setState({ from }, this.updateRange);
    }

    handleSelectToDate(to) {
        this.setState({ to }, this.updateRange);
    }

    componentDidUpdate() {
        // When applying NNV filter, the state should be updated according to the graph selected value
        if (this.props.shouldUpdateCustomDatesState && this.props.initialValues) {
            if (this.props.initialValues.from !== this.state.from) {
                this.setState({ from: this.props.initialValues.from });
            }

            if (this.props.initialValues.to !== this.state.to) {
                this.setState({ to: this.props.initialValues.to });
            }

            this.props.updateCustomDatesBasedOnNNV(false);
        }
    }

    updateRange() {
        if (this.state.from) {
            this.setState({
                showFromError: false,
            });
        }

        if (this.state.to) {
            this.setState({
                showToError: false,
            });
        }
        if (this.state.from) {
            this.props.onRangeSelect(this.state.from, null);
        }

        if (this.state.to) {
            this.props.onRangeSelect(null, this.state.to);
        }
    }

    applyCustomDate(e) {
        if (this.props.initialValues && this.props.initialValues.from && this.props.initialValues.to) {
            this.props.applyCustomDate(e);
        } else {
            if (!this.props.initialValues.from) {
                this.setState({
                    showFromError: true,
                });
            }
            if (!this.props.initialValues.to) {
                this.setState({
                    showToError: true,
                });
            }
        }
    }

    render() {
        return (
            <div className={'date-range-select ' + this.props.className} data-testid={this.props.testid}>
                <DateSelect
                    onChange={this.handleSelectFromDate}
                    className={this.props.isValidating ? (this.state.from === null ? 'with-error' : '') : ''}
                    label={formatRichMessage({ id: 'General.range.from' }, this.props.intl)}
                    name={'date-from'}
                    value={this.props.initialValues ? this.props.initialValues.from : null}
                    maxDate={this.props.initialValues ? this.props.initialValues.to : null}
                    disabled={this.props.disabled}
                    radioGroupReference={this.props.radioGroupReference}
                    fromResultsList={true}
                />
                {this.state.showFromError && (
                    <div className="error-message">
                        <FormattedMessage id="Date_Range_Error.From" />
                    </div>
                )}
                <DateSelect
                    onChange={this.handleSelectToDate}
                    className={this.props.isValidating ? (this.state.to === null ? 'with-error' : '') : ''}
                    label={formatRichMessage({ id: 'General.range.to' }, this.props.intl)}
                    name={'date-to'}
                    value={this.props.initialValues ? this.props.initialValues.to : null}
                    minDate={this.props.initialValues ? this.props.initialValues.from : null}
                    disabled={this.props.disabled}
                    radioGroupReference={this.props.radioGroupReference}
                    fromResultsList={true}
                />
                {this.state.showToError && (
                    <div className="error-message">
                        <FormattedMessage id="Date_Range_Error.To" />
                    </div>
                )}

                <button
                    className="button-primary-sm"
                    disabled={this.props.disabled}
                    onClick={(e) => {
                        this.applyCustomDate(e);
                    }}
                >
                    OK
                </button>
            </div>
        );
    }
}

export default injectIntl(PostFilterDateRangeSelect);
