/**
 * BACK TO TOP ENTRY POINT
 *
 * The BACK TO TOP component appears when the page is scrolled down.
 * Pressing the button will scroll the page to top and hide the BACK TO TOP component.
 *
 * @version 0.1
 */

import React from 'react';

class BackToTop extends React.Component {
    constructor(props) {
        super(props);
        this.state = { isVisible: false };
        this.toggleBackToTopVisibility = this.toggleBackToTopVisibility.bind(this);
    }

    componentDidMount() {
        window.addEventListener('scroll', this.toggleBackToTopVisibility);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.toggleBackToTopVisibility);
    }

    jumpToTop() {
        window.scrollTo(0, 0);
    }

    toggleBackToTopVisibility() {
        let headerHeight = document.getElementsByClassName('diligence-header')[0].offsetHeight || 0,
            scrolledFromTop = window.scrollY || window.pageYOffset;
        if (scrolledFromTop <= headerHeight) {
            this.setState({ isVisible: false });
        } else if (scrolledFromTop > headerHeight) {
            this.setState({ isVisible: true });
        }
    }

    render() {
        const jumpToTop = (
            <button type="button" className="back-to-top la-JumpToTop noprint" onClick={this.jumpToTop} />
        );
        return this.state.isVisible ? jumpToTop : null;
    }
}

export default BackToTop;
