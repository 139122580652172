// @flow
import React from 'react';
import type { AbstractComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
    ACTION_TYPES,
    ALL_ARTICLES_REPORT,
    CATEGORY_NAMES,
    COMPANY_SEARCH,
    FE_LOCK_NAMES,
    MODAL_TYPE,
    NEGATIVE_NEWS_LANGUAGES,
    NEGATIVE_NEWS_SELECTED_LANGUAGE,
    NO_DOCUMENTS_OF_INTEREST,
    POPUP_BUTTON_TYPES,
    PREFERENCES_KEY,
    SELECTED_ARTICLES_REPORT,
    ALL_ITEMS_LIST,
    SELECTED_ITEMS,
    NO_DOCUMENTS_FOUND,
    IGNORE_DELIVERY_LIMITS,
    SHOW_DELIVERY_OPTIONS_RESULTS_LIST,
    SHOW_DELIVERY_OPTIONS_REPORT,
    DELIVERIES_TOC_OPTIONS,
    LEGAL_SOURCES_TITLES,
    FILENAME_CHARACTERS_LIMIT,
    MATOMO_DOCUMENT_ACTION_EVENT_TRANSFORMATION,
    MATOMO_DOCUMENT_ACTION_VALUE_TRANSFORMATION,
    ALERT_FREQUENCY_TYPE_DEFAULT,
    ALERT_FREQUENCY_TYPE_OPTIONS,
    ALERT_ATTACH_TYPE_DEFAULT,
    ALERT_DELIVERY_TYPE_DEFAULT,
    ALERT_ACCESS_TYPE_DEFAULT,
    ROUTES,
    MATOMO_SELECTED_ARTICLES_DEFAULT_DELIVERY_MESSAGE,
    MATOMO,
    MULTIPLE_ENTITIES_NONE_SELECTED_VIEW,
    DOWNLOAD_ENTITIES_FORMAT_TYPE,
    NOTIFICATION_STORE_KEYS,
    MULTIPLES_ENTITIES_CREATE_NEW_VIEW,
} from '@constants';
import callMatomoEvent from '@utils/matomoUtils';
import formatRichMessage from '@utils/formatRichMessage';
import Modal from '../Modal/Modal.index';
import { bindActionCreators, compose } from 'redux';
import popupModelActions from './redux/PopupModel.actions';
import ModalEmailsList from './Components/ModalEmailList';
import ModalResultList from './Components/ModalResultList.jsx';
import ModalComments from './Components/ModalComments.jsx';
import ModalFileFormat from './Components/ModalFileFormat';
import ModalFileName from './Components/ModalFileName';
import { cloneDeep, debounce, isEmpty, isEqual, union, isNumber, pick } from 'lodash';
import ModalEmail from './Components/ModalEmail';
import utils from '@utils/utilities';
import DeliveryService from '@utils/deliveryService';
import ModalReportList from './Components/ModalReportList';
import ModalWarning from './Components/ModalWarning';
import ModalDescription from './Components/ModalDescription';
import ModalActionTitle from './Components/ModalActionTitle';
import ModalExplanation from './Components/ModalExplanation';
import ModalPreviewArticle from './Components/ModalPreviewArticle';
import ModalEditArticleNote from './Components/ModalEditArticleNote';
import ModalUpdateCheckbox from './Components/ModalUpdateCheckbox';
import { FormattedMessage, injectIntl } from 'react-intl';
import ModalEditNegativeSearchQuery from './Components/ModalEditNegativeSearchQuery';
import { ModalAdHocSearch } from './Components/ModalAdHocSearch';
import AdminLock from '../AdminLock/AdminLock';
import ModalAlertContentSelection from './Components/ModalAlertContentSelection';
import ModalAlertFrequency from './Components/ModalAlertFrequency';
import ModalDismissibleMessage from './Components/ModalDismissibleMessage';
import ModalAnonymizedWarning from './Components/ModalAnonymizedWarning';
import ModalEditableTitle from './Components/ModalEditableTitle';
import ModalEditCustomNewsQuery from './Components/ModalEditCustomNewsQuery';
import ShareViewSearchbar from './Components/ShareViewSearchbar';
import ShareViewUserSelect from './Components/ShareViewUserSelect';
import ModalUploadEntities from './Components/ModalUploadEntities';
import ModalItemsList from './Components/ModalItemsList';
import { withAppContext } from '@utils/contexts';
import ModalContentOptions from './Components/ModalContentOptions';
import actions from '@pages/UserPreferances/redux/UserPreferences.actions';
import ModalTabbedContent from './Components/ModalTabbedContent';
import type { ContextType } from '@pages/MainSearch/components/typeGuards/ResultsList.typeGuards';
import ModalRiskScores from './Components/ModalRiskScores';
import ModalDeleteAllHistory from './Components/ModalDeleteAllHistory';
import ModalColumnsList from './Components/ModalColumnsList';
import type { Option } from './Components/ModalContentOptions';
import type { UserLocksType } from '@MainSearch/components/typeGuards/ResultsList.typeGuards';
import {
    BATCH_UPLOAD_DEFAULT_FILENAME,
    BATCH_UPLOAD_FILE_TYPES,
    DOC_TYPE,
    MATOMO_SELECTED_ARTICLES_NO_DOCUMENTS_OF_HISTORY_DELIVERY_MESSAGE,
    SORT_OPTIONS,
} from '../../constants';
import ModalSaveSearch from './Components/ModalSaveSearch';
import type { ArticleNavigationType } from '@reusable/ArticleNavigation/typeGuards/ArticleNavigation.typeGuards';
import type { RouterLocation } from '@utils/flow/utilities.type.guards';
import type { AlertFrequencyType } from '@pages/Alerts/typeGuards/AlertTypeGuards';
import ModalEntityLabel from './Components/ModalEntityLabel';
import ModalEntitySearchTerm from './Components/ModalEntitySearchTerm';
import ModalEntityType from './Components/ModalEntityType';
import ModalEntitySelectFilters from './Components/ModalEntitySelectFilters';
import BatchUploadDragAndDrop from './Components/BatchUploadDragAndDrop';
import { isEntityUploadLimitReached } from '@pages/EntityView/utils/validations';
import BatchUploadHelper from './helpers/BatchUploadHelper';
import * as EntityViewHelper from '@pages/EntityView/EntityViewHelper';
import adHocSearchActions from '../AdHocSearch/redux/AdHocSearch.actions';
import notificationService from '@utils/notificationService';
import mainActions from '@pages/Main/Main.actions';
import userPreferencesActions from '@UserPreferences/redux/UserPreferences.actions';
import costCodeUtils from '@utils/costCodeUtils';
import ModalAlertGDPRMessage from './Components/ModalAlertGDPRMessage';
import snackbarUtils from '@reusable/SnackbarWithAutohide/snackbarUtils';
import EntityViewApi from '@pages/EntityView/api/EntityViewApi';
import ModalDownloadEntitiesFormat from './Components/ModalDownloadEntitiesFormat';

const DEBOUNCE_TIMEOUT: number = 200;

type State = {
    fullListCheckboxState: boolean,
    noDocumentOfInterestCheckbox: boolean,
    noDocumentsFoundCheckbox: boolean,
    comments: string,
    sourceCategories: Array<string>,
    contentLanguages: Array<string>,
    charsLeft: number,
    fileName: string,
    emailAddress: string,
    emailSubject: string,
    emailComments: string,
    isEmailValid: boolean,
    isFileNameValid: boolean,
    isReportsSelectionValid: boolean,
    checkedAll: boolean,
    checkedReports: Array<string>,
    sources: Array<Object>,
    isUserPreferencesChecked: boolean,
    query: Object,
    customNewsQuery: Object,
    editableTitle: string,
    queryError: Object,
    wildcardError: Object,
    customNewsQueryError: boolean,
    customNewsWildcardError: boolean,
    resetQuery: Object,
    isPopupDirty: boolean,
    customNewsQueryChanged: boolean,
    isUserMIP: boolean,
    isFullDocsDisabled: boolean,
    fileType?: string,
    fileNameErrorMessage?: string,
    emailErrorMessage?: string,
    searchNamePartyEntity?: boolean,
    reportsSelectionErrorMessage?: string,
    customNewsCreatedBy: string,
    customNewsLnDefaultQuery: string,
    alert: AlertFrequencyType,
    elementsVisibility: Array<Object>,
    uploadedFile: Object,
    dateRange: Object,
    docType: string,
    sort: string,
    isSubmitting: boolean,
    entityViewCount: number,
    editEntity?: Object,
    shareViewSearchbarText: string,
    isShareViewSearchbarEnabled: boolean,
    downloadEntitiesFormat: string,
};

type View = {
    id: string,
    name: string,
    createdDate: number,
    lastUpdated: number,
    isShareable: boolean,
};

type NewView = {
    id: string,
    name: string,
    isShareable: boolean,
    newView: boolean,
}

type Props = {
    changeView: (arg: View) => void,
    createView: (arg: NewView) => void,
    resetView: () => void,
};
type InjectedProps = {|
    togglePrimaryButtonsStatus: (arg: boolean) => void,
    modifiedByUser: Object,
    deleteAdHocSearchContentType: (arg: Array<string>) => void,
    addAdHocSearchContentType: (arg: Array<string>) => void,
    lawSources: Array <string>,
    lawSource: string,
    updateAllButtonStatus: (arg: boolean) => void,
    updateDateRange: (arg1: string, arg2: string) => void,
    setResultListType: (arg: string) => void,
    setDocType: (arg: string) => void,
    toggleButtonStatusByType: (arg1: string, arg2: boolean) => void,
    hideModal: () => void,
    updateSearchNamePartyEntity: (arg1: boolean) => void,
    updateDeliveryContentOptions: (arg1: Option, arg2: string | boolean) => void,
    updateModifiedByUser: (arg1: boolean, arg2: string | null) => void,
    toggleDeliveryOptionsDisplay: (arg1: boolean) => void,
    reports: Object,
    setComments: (arg1: string) => void,
    setFileName: (arg1: string) => void,
    setEmailAddress: (arg1: string) => void,
    setEmailSubject: (arg1: string) => void,
    setEmailComments: (arg1: string) => void,
    updateCheckedLanguagesUponKey: (arg1: Array<string>) => void,
    updateModalComponents: (arg1: Object) => void,
    clearPopup: () => void,
    userLocks: UserLocksType,
    +intl: Object,
    context: ContextType,
    articleNavigation: ArticleNavigationType,
    location: RouterLocation,
    selectedArticlesInfo: [],
    toggleDeliveryOptionsDisplayPreference: (arg1: boolean, arg2: string) => void,
    updateAdhocProperties: (arg1: string, arg2: boolean | Array<string> | string) => void,
    setReportList: (selectedReportIds: Array<string>) => void,
    model: Object,
    newsDateRange: string,
    legalDateRange: string,
    companyDateRange: string,
    entityUploadLimit: number,
    isMultipleEntitiesTrial: boolean,
    uploadPollingActive: boolean,
    sources: Object,
    setUpdateEntitiesColumnsFromContentTypes: (arg1: boolean) => void,
    updateAdHocProperty: (arg1: string, arg2: Object) => void,
    setUploadPollingEntityView: (arg1: boolean) => void,
    addNewBatchId: (arg1: {}) => void,
    userEmail: string,
    multipleEntitiesDownloadReportsPollingActive: boolean,
    viewId: string,
    downloadEntitiesFormat: string,
    setDownloadEntitiesFormat: (arg1: string) => void,
    isDarkMode: boolean,
|};
class PopupModal extends React.Component<Props & InjectedProps, State> {
    state: State;
    initialState: Object;
    originalModel: Object;
    originalState: Object;
    commentChangeDelayed: () => void;
    fileNameChangeDelayed: () => void;
    emailAddressChangeDelayed: () => void;
    emailSubjectChangeDelayed: () => void;
    emailCommentsChangeDelayed: () => void;
    updateButtonStatusDelayed: (arg1: boolean) => void;
    uploadInputRef: Object;
    acceptedTypes: string;
    mounted: boolean;

    constructor(props: Props & InjectedProps) {
        super(props);
        this.initialState = {
            fullListCheckboxState: true,
            noDocumentOfInterestCheckbox: true,
            noDocumentsFoundCheckbox: true,
            comments: '',
            charsLeft: 1,
            fileName: '',
            emailAddress: this.props.model.emailAddress || '',
            emailSubject: '',
            emailComments: '',
            isEmailValid: true,
            isFileNameValid: true,
            isReportsSelectionValid: true,
            checkedAll: false,
            checkedReports: [],
            sources: [],
            isUserPreferencesChecked: false,
            query: this.props.model.customQuery || {},
            customNewsQuery: this.props.model.customQuery || '',
            customNewsCreatedBy: this.props.model.customNewsCreatedBy || '',
            customNewsLnDefaultQuery: this.props.model.customNewsLnDefaultQuery || '',
            editableTitle: this.props.model.editableTitle,
            queryError: {},
            wildcardError: {},
            customNewsQueryError: false,
            customNewsWildcardError: false,
            resetQuery: {},
            isPopupDirty: false,
            customNewsQueryChanged: false,
            isUserMIP: this.props.context.isUserMIP,
            isFullDocsDisabled: false,
            isSnapshotDisabled: this.props.model.isSnapshotDisabled,
            alert: {
                accessType: ALERT_ACCESS_TYPE_DEFAULT,
                frequencyType: ALERT_FREQUENCY_TYPE_DEFAULT,
                hourOfDay: ALERT_FREQUENCY_TYPE_OPTIONS.HOURS.defaultValue,
                dayOfMonth: ALERT_FREQUENCY_TYPE_OPTIONS.MONTH_DAYS.defaultValue,
                dayOfWeek: ALERT_FREQUENCY_TYPE_OPTIONS.WEEK_DAYS.defaultValue,
                attachType: ALERT_ATTACH_TYPE_DEFAULT,
                deliveryType: ALERT_DELIVERY_TYPE_DEFAULT,
                shouldTriggerForNoResults: false,
            },
            elementsVisibility: [],
            onDeliveryApiError: this.props.model.onDeliveryApiError,
            // Entity View Upload Entities popup state
            uploadedFile: {
                fileName: '',
                fileSize: null,
                fileContent: null,
                isValid: true,
                errorMessage: [],
                warningMessage: [],
                infoMessage: [],
                multilineErrorMessage: [],
            },
            dateRange: {
                companyDateRange: { range: this.props.companyDateRange },
                legalDateRange: { range: this.props.legalDateRange },
                newsDateRange: { range: this.props.newsDateRange },
            },
            docType: DOC_TYPE.PDF,
            sort: SORT_OPTIONS.DEFAULT_SORT_OPTION,
            isSubmitting: false,
            entityViewCount: 0,
            shareViewSearchbarText: '',
            isShareViewSearchbarEnabled: false,
            selectedViewForUpload: null,
            downloadEntitiesFormat: DOWNLOAD_ENTITIES_FORMAT_TYPE.TABLE_FORMAT
        };

        this.state = cloneDeep(this.initialState);

        if (this.props.model.popupType === MODAL_TYPE.EDIT_SEARCH_MULTIPLE_ENTITIES) {
            const { displayName, entityName, entityType } = this.props.model.entityInfo;

            this.state.editEntity = {
                displayName,
                displayNameError: null,
                entityName,
                entityNameError: null,
                entityType,
                entityResetFilters: false,
            };
        }

        this.originalModel = { ...props.model };
        this.originalState = { ...this.state };
        this.commentChangeDelayed = debounce(() => {
            this.props.setComments(this.state.comments);
        }, DEBOUNCE_TIMEOUT).bind(this);

        this.fileNameChangeDelayed = debounce(() => {
            this.props.setFileName(this.state.fileName);
        }, DEBOUNCE_TIMEOUT).bind(this);

        this.emailAddressChangeDelayed = debounce(() => {
            this.props.setEmailAddress(this.state.emailAddress);
        }, DEBOUNCE_TIMEOUT).bind(this);

        this.emailSubjectChangeDelayed = debounce(() => {
            this.props.setEmailSubject(this.state.emailSubject);
        }, DEBOUNCE_TIMEOUT).bind(this);

        this.emailCommentsChangeDelayed = debounce(() => {
            this.props.setEmailComments(this.state.emailComments);
        }, DEBOUNCE_TIMEOUT).bind(this);

        this.updateButtonStatusDelayed = debounce((hasError) => {
            this.props.toggleButtonStatusByType(POPUP_BUTTON_TYPES.save, hasError);
        }, DEBOUNCE_TIMEOUT).bind(this);

        // Entity View Upload Entities popup
        this.uploadInputRef = React.createRef();
        this.acceptedTypes = BATCH_UPLOAD_FILE_TYPES.join(',');
        this.mounted = true;
    }

    UNSAFE_componentWillMount() {
        const fileName: string = this.props.model.fileName ? this.props.model.fileName.replace(/['"]+/g, '') : '';

        switch (this.props.model.popupType) {
            case MODAL_TYPE.ADD_TO_REPORT:
                this.setState({
                    noDocumentOfInterestCheckbox: this.getSelectedArticlesCount() === 0,
                    noDocumentsFoundCheckbox: this.props.model.totalArticlesCount === 0,
                });
                break;
            case MODAL_TYPE.DOWNLOAD_HISTORY:
                let selectedItemsCount = this.props.model.items.length || 0,
                    totalItemsCount = this.props.model.totalArticlesCount;

                this.setState({
                    fullListCheckboxState: selectedItemsCount === 0 || selectedItemsCount === totalItemsCount,
                    fileType: this.props.model.docType,
                    fileName,
                });
                break;
            case MODAL_TYPE.PRINT_REPORTS:
            case MODAL_TYPE.PRINT_REPORTS_COMBINED:
            case MODAL_TYPE.PRINT_REPORTS_SEPARATE:
            case MODAL_TYPE.EMAIL_REPORTS:
            case MODAL_TYPE.EMAIL_REPORTS_SEPARATE:
            case MODAL_TYPE.EMAIL_REPORTS_COMBINED:
            case MODAL_TYPE.DOWNLOAD_REPORTS:
            case MODAL_TYPE.DOWNLOAD_REPORTS_SEPARATE:
            case MODAL_TYPE.DOWNLOAD_REPORTS_COMBINED:
                this.setState({ fileName });
                let selectedReportIds = this.props.model.selectedReports.map((report) => report.id);
                this.props.setReportList(selectedReportIds);
                break;
            case MODAL_TYPE.DOWNLOAD_RESULT_LIST:
            case MODAL_TYPE.DOWNLOAD_BATCH_SCREENING_FULL_REPORT:
            case MODAL_TYPE.ELASTIC_DOWNLOAD_DOCUMENT:
                //validate the generated filename because custom news' titles aren't validated
                this.fileNameChange({ target: { value: fileName } });
                this.setState({ fullListCheckboxState: this.getSelectedArticlesCount() === 0 });
                break;
            case MODAL_TYPE.DOWNLOAD_BATCH_SCREENING:
            case MODAL_TYPE.DOWNLOAD_BATCH_SCREENING_SNAPSHOT:
            case MODAL_TYPE.DOWNLOAD_ENTITIES:
                this.setState({ fileName });
                break;
            default:
                this.setState({
                    fullListCheckboxState: this.getSelectedArticlesCount() === 0,
                    fileName,
                });
        }
    }

    componentDidMount() {
        this.updateModalBasedOnProperties();

        if (this.props.model.popupType === MODAL_TYPE.UPLOAD_ENTITIES) {
            EntityViewHelper.returnAllEntitiesCount().then((entityViewCount) => {
                this.initialState.entityViewCount = entityViewCount;
                this.mounted && this.setState({ entityViewCount });
            });
        }
    }

    componentDidUpdate(prevProps) {
        if(prevProps.selectedView?.id !== this.props.selectedView?.id) {
            this.setState({ selectedViewForUpload: this.props.selectedView });
        }
        if (prevProps.model.resultListType !== this.props.model.resultListType) {
            this.updateModalBasedOnProperties();
        }
    }

    componentWillUnmount() {
        let modified: boolean = this.toggleDeliveryOptionsDisplayPreference();
        if (modified) {
            switch (this.props.model.popupType) {
                case MODAL_TYPE.EMAIL_RESULT_LIST:
                case MODAL_TYPE.PRINT_RESULT_LIST:
                case MODAL_TYPE.DOWNLOAD_RESULT_LIST:
                case MODAL_TYPE.DOWNLOAD_BATCH_SCREENING_FULL_REPORT:
                    utils.updatePreference({
                        resultsListShowDeliveryOptions: false,
                    });
                    break;
                case MODAL_TYPE.DOWNLOAD_REPORTS:
                case MODAL_TYPE.DOWNLOAD_REPORTS_SEPARATE:
                case MODAL_TYPE.DOWNLOAD_REPORTS_COMBINED:
                case MODAL_TYPE.DOWNLOAD_REPORT_CATEGORY:
                case MODAL_TYPE.DOWNLOAD_REPORT:
                case MODAL_TYPE.PRINT_REPORTS:
                case MODAL_TYPE.PRINT_REPORTS_SEPARATE:
                case MODAL_TYPE.PRINT_REPORTS_COMBINED:
                case MODAL_TYPE.PRINT_REPORT_CATEGORY:
                case MODAL_TYPE.EMAIL_REPORT_CATEGORY:
                case MODAL_TYPE.EMAIL_REPORTS:
                case MODAL_TYPE.EMAIL_REPORTS_SEPARATE:
                case MODAL_TYPE.EMAIL_REPORTS_COMBINED:
                    utils.updatePreference({
                        showDeliveryOptions: false,
                    });
                    break;
                case MODAL_TYPE.CHANGE_COLUMNS_VISIBILITY_MULTIPLE_ENTITIES:
                    this.setState({
                        elementsVisibility: [...this.props.model.elementsVisibility],
                    });
                    break;
            }
        }
        this.mounted = false;
    }

    updateElementsVisibility = (e) => {
        let updatedArray = this.state.elementsVisibility;
        let foundIndex = updatedArray.findIndex((el) => el.columnId === e.target.name);
        if (foundIndex) {
            updatedArray[foundIndex] = { ...updatedArray[foundIndex], hidden: !e.target.checked };
            this.setState({ elementsVisibility: updatedArray });
        }
    };

    updateModalBasedOnProperties = () => {
        let popupType: string = this.props.model.popupType;

        const ignoreDeliveryLimits = [
            MODAL_TYPE.USER_PREFERENCES_EDIT_NEGATIVE_SEARCH_TERMS,
            MODAL_TYPE.USER_PREFERENCES_ADD_CUSTOM_NEWS_QUERY,
            MODAL_TYPE.USER_PREFERENCES_EDIT_CUSTOM_NEWS_QUERY,
            MODAL_TYPE.PRINT_REPORTS,
            MODAL_TYPE.PRINT_REPORTS_COMBINED,
            MODAL_TYPE.PRINT_REPORTS_SEPARATE,
            MODAL_TYPE.EMAIL_REPORTS,
            MODAL_TYPE.EMAIL_REPORTS_SEPARATE,
            MODAL_TYPE.EMAIL_REPORTS_COMBINED,
            MODAL_TYPE.DOWNLOAD_REPORTS,
            MODAL_TYPE.DOWNLOAD_REPORTS_SEPARATE,
            MODAL_TYPE.DOWNLOAD_REPORTS_COMBINED,
        ];

        if (
            popupType !== MODAL_TYPE.USER_PREFERENCES_EDIT_NEGATIVE_SEARCH_TERMS &&
            popupType !== MODAL_TYPE.USER_PREFERENCES_ADD_CUSTOM_NEWS_QUERY &&
            popupType !== MODAL_TYPE.USER_PREFERENCES_EDIT_CUSTOM_NEWS_QUERY
        ) {
            this.checkPrimaryButtonDisabledStatus();

            if (ignoreDeliveryLimits.indexOf(popupType) < 0) {
                this.updateModalBasedOnDeliveryLimit();
            }
        }

        if (popupType === MODAL_TYPE.CHANGE_COLUMNS_VISIBILITY_MULTIPLE_ENTITIES) {
            this.setState({ elementsVisibility: [...this.props.model.elementsVisibility] });
        }

        if (popupType === MODAL_TYPE.EDIT_SEARCH_MULTIPLE_ENTITIES) {
            this.props.togglePrimaryButtonsStatus(true);
        }
    };

    hasUpdatedProperties = () => {
        const modalChanges: Object = utils.getDifferences(this.originalModel, this.props.model);
        const filteredChanges = Object.keys(modalChanges).filter((prop) => prop !== 'buttons'); // exclude buttons transforms from differences

        return filteredChanges.length > 0;
    };

    checkContentOptionsSelectedStatus = () => {
        let contentsOptions = this.props.model.contentsOptions;

        if (contentsOptions && !isEmpty(contentsOptions)) {
            let values = Object.keys(contentsOptions).map((key) => contentsOptions[key]);
            let checkedOptions = values.filter((value) => value && value !== DELIVERIES_TOC_OPTIONS.none);
            return checkedOptions && checkedOptions.length === 0;
        }
        return false;
    };

    checkPrimaryButtonDisabledStatus = (): void => {
        const contentOptionsDisabled = this.checkContentOptionsSelectedStatus();
        const fileNameInvalid = !this.state.isFileNameValid;
        const isLimitExceeded = this.isDeliveryItemsLimitExceeded();

        if (contentOptionsDisabled || fileNameInvalid || isLimitExceeded) {
            return this.props.togglePrimaryButtonsStatus(true);
        }

        return this.props.togglePrimaryButtonsStatus(false);
    };

    toggleDeliveryOptionsDisplayPreference = () => {
        let key = this.getModifiedByUserKey(this.props.model.popupType);
        if (key && !this.props.modifiedByUser[key] && this.props.model.showDeliveryOptions) {
            this.props.toggleDeliveryOptionsDisplayPreference(false, key);
            return true;
        }
        return false;
    };

    getModifiedByUserKey(popupType: string): string | null {
        let key: string | null = null;
        switch (popupType) {
            case MODAL_TYPE.DOWNLOAD_RESULT_LIST:
            case MODAL_TYPE.DOWNLOAD_BATCH_SCREENING_FULL_REPORT:
            case MODAL_TYPE.EMAIL_RESULT_LIST:
            case MODAL_TYPE.PRINT_RESULT_LIST:
                key = SHOW_DELIVERY_OPTIONS_RESULTS_LIST;
                break;
            case MODAL_TYPE.DOWNLOAD_REPORTS:
            case MODAL_TYPE.DOWNLOAD_REPORTS_SEPARATE:
            case MODAL_TYPE.DOWNLOAD_REPORTS_COMBINED:
            case MODAL_TYPE.DOWNLOAD_REPORT_CATEGORY:
            case MODAL_TYPE.DOWNLOAD_REPORT:
            case MODAL_TYPE.PRINT_REPORTS:
            case MODAL_TYPE.PRINT_REPORTS_SEPARATE:
            case MODAL_TYPE.PRINT_REPORTS_COMBINED:
            case MODAL_TYPE.PRINT_REPORT_CATEGORY:
            case MODAL_TYPE.EMAIL_REPORT_CATEGORY:
            case MODAL_TYPE.EMAIL_REPORTS:
            case MODAL_TYPE.EMAIL_REPORTS_SEPARATE:
            case MODAL_TYPE.EMAIL_REPORTS_COMBINED:
            case MODAL_TYPE.UPLOAD_ENTITIES_COMPANY_SOURCES_WITHOUT_COMPANIES:
            case MODAL_TYPE.UPLOAD_ENTITIES_COMPANY_SOURCES_WITH_COMPANIES:
            case MODAL_TYPE.DOWNLOAD_BATCH_SCREENING:
            case MODAL_TYPE.DOWNLOAD_BATCH_SCREENING_SNAPSHOT:
                key = SHOW_DELIVERY_OPTIONS_REPORT;
                break;
            default:
                console.log('modified by user key is missing, or not necessary');
        }

        return key;
    }

    //adHock

    //@TODO remove this after all refactoring for "Legal Sources" is done
    changeLegalSource = (source: string) => {
        let expandedLawSources = cloneDeep(this.props.model.lawSources);
        this.props.updateAllButtonStatus(false);
        this.props.updateAdhocProperties('lawSource', source);
        if (source === 'all') {
            expandedLawSources.push(source);
            this.props.addAdHocSearchContentType(expandedLawSources);
        } else {
            expandedLawSources.push(source);
            this.props.deleteAdHocSearchContentType(expandedLawSources);
            this.props.addAdHocSearchContentType([source]);
        }
    };

    changeUpdateCheckbox = (event) => {
        this.setState(
            {
                isUserPreferencesChecked: event.target.checked,
            },
            () => {
                this.props.updateAdhocProperties('updateUserPreferencesCheckbox', this.state.isUserPreferencesChecked);
            }
        );
    };

    doReset = () => {
        this.setState(
            {
                isUserPreferencesChecked: false,
            },
            () => {
                let searchType = this.props.model.searchQueryType;
                let customQuery = this.props[PREFERENCES_KEY[searchType]].customQuery;
                let newsSearchSources = this.props[PREFERENCES_KEY[searchType]].newsSearchSources;

                this.props.updateAdhocProperties('lawSources', this.props.lawSources);
                this.props.updateAdhocProperties('lawSource', this.props.lawSource);
                this.props.updateAdhocProperties('customQuery', customQuery);
                this.props.updateAdhocProperties('newsSearchSources', newsSearchSources);
                this.props.updateAdhocProperties('costCode', this.originalModel.costCode);
                this.props.updateAdhocProperties('isCustomCostCode', false);
                this.props.updateAdhocProperties('updateUserPreferencesCheckbox', false);
                this.props.updateAllButtonStatus(true);
            }
        );
    };

    updateAdHocDateRange = (range, section) => {
        this.props.updateAllButtonStatus(false);
        this.props.updateDateRange(range, section);
    };

    changeAdHocCostCode = (costCode: string, isCustomCostCode: boolean = false) => {
        this.props.updateAllButtonStatus(false);
        this.props.updateAdhocProperties('costCode', costCode);
        this.props.updateAdhocProperties('isCustomCostCode', isCustomCostCode);
    };

    //end adHock

    //********** result list ***********//
    articleSelectionChanged = (event) => {
        if (this.props.model.popupType === MODAL_TYPE.ADD_TO_REPORT) {
            this.setState(
                {
                    noDocumentOfInterestCheckbox: event.target.value === NO_DOCUMENTS_OF_INTEREST,
                    noDocumentsFoundCheckbox: event.target.value === NO_DOCUMENTS_FOUND,
                },
                () => {
                    let reportListType = this.state.noDocumentsFoundCheckbox
                        ? NO_DOCUMENTS_FOUND
                        : this.state.noDocumentOfInterestCheckbox
                            ? NO_DOCUMENTS_OF_INTEREST
                            : SELECTED_ARTICLES_REPORT;
                    this.props.setResultListType(reportListType);
                }
            );
        } else {
            this.setState(
                {
                    fullListCheckboxState: event.target.value === ALL_ARTICLES_REPORT,
                },
                () => {
                    let reportListType = this.state.fullListCheckboxState
                        ? ALL_ARTICLES_REPORT
                        : SELECTED_ARTICLES_REPORT;
                    this.props.setResultListType(reportListType);
                }
            );
        }
    };

    getSelectedArticlesCount = () => {
        let articles = this.props.model.articles;
        return (articles && articles.length) || 0;
    };

    getSelectedHistoryEntries = () => {
        let items = this.props.model.items;
        return (items && items.length) || 0;
    };

    //********** fileName ***********//
    fileNameChange = (event: SyntheticInputEvent<HTMLInputElement> | Object): void => {
        const fileName = event.target.value;
        const validationState = this.validateFileName(fileName);

        this.setState(
            () => ({
                fileName: fileName.substring(0, FILENAME_CHARACTERS_LIMIT),
                isFileNameValid: validationState.isValid,
                fileNameErrorMessage: validationState.errorMessage,
            }),
            () => {
                this.fileNameChangeDelayed();
                this.disableDownloadButton();
            }
        );
    };

    validateFileName = (fileName: string): { isValid: boolean, errorMessage: string } => {
        const popupType = this.props.model.popupType;
        const reportFileNameValidation = utils.isReportFileNameValid(fileName);
        let isValid;

        switch (popupType) {
            case MODAL_TYPE.DOWNLOAD_REPORT:
            case MODAL_TYPE.DOWNLOAD_REPORTS:
            case MODAL_TYPE.DOWNLOAD_REPORTS_SEPARATE:
            case MODAL_TYPE.DOWNLOAD_REPORTS_COMBINED:
            case MODAL_TYPE.DOWNLOAD_REPORT_CATEGORY:
            case MODAL_TYPE.DOWNLOAD_HISTORY:
            case MODAL_TYPE.DOWNLOAD_RESULT_SNAPSHOT:
            case MODAL_TYPE.DOWNLOAD_SNAPSHOT_PAGE:
            case MODAL_TYPE.DOWNLOAD_RESULT_LIST:
            case MODAL_TYPE.DOWNLOAD_BATCH_SCREENING_FULL_REPORT:
            case MODAL_TYPE.ELASTIC_DOWNLOAD_DOCUMENT:
            case MODAL_TYPE.DOWNLOAD_BATCH_SCREENING:
            case MODAL_TYPE.DOWNLOAD_BATCH_SCREENING_SNAPSHOT:
            case MODAL_TYPE.DOWNLOAD_ENTITIES:
                isValid = !(reportFileNameValidation.invalidCharacters || reportFileNameValidation.invalidLength);
                break;
            default:
                isValid = utils.isFileNameValid(fileName);
        }

        let errorMessage = '';
        if (!isValid) {
            if (reportFileNameValidation.invalidLength)
                errorMessage = formatRichMessage(
                    { id: 'DownloadReportFileNameValidation.CharactersLimitCount' },
                    this.props.intl,
                    { count: reportFileNameValidation.maxLength }
                );

            if (reportFileNameValidation.invalidCharacters || reportFileNameValidation.currentLength === 0)
                errorMessage = formatRichMessage(
                    { id: 'DownloadModalFileNameValidation.ValidationMessage' },
                    this.props.intl
                );
        }

        return {
            isValid,
            errorMessage,
        };
    };

    //********** end fileName ***********//
    disableDownloadButton = (): void => {
        const popupType = this.props.model.popupType;

        switch (popupType) {
            case MODAL_TYPE.DOWNLOAD_HISTORY:
            case MODAL_TYPE.DOWNLOAD_RESULT_SNAPSHOT:
            case MODAL_TYPE.DOWNLOAD_SNAPSHOT_PAGE:
            case MODAL_TYPE.DOWNLOAD_REPORTS:
            case MODAL_TYPE.DOWNLOAD_REPORTS_SEPARATE:
            case MODAL_TYPE.DOWNLOAD_REPORTS_COMBINED:
            case MODAL_TYPE.DOWNLOAD_REPORT:
            case MODAL_TYPE.DOWNLOAD_REPORT_CATEGORY:
            case MODAL_TYPE.DOWNLOAD_RESULT_LIST:
            case MODAL_TYPE.DOWNLOAD_BATCH_SCREENING_FULL_REPORT:
            case MODAL_TYPE.ELASTIC_DOWNLOAD_DOCUMENT:
            case MODAL_TYPE.DOWNLOAD_BATCH_SCREENING:
            case MODAL_TYPE.DOWNLOAD_BATCH_SCREENING_SNAPSHOT:
            case MODAL_TYPE.DOWNLOAD_ENTITIES:
                this.checkPrimaryButtonDisabledStatus();
                break;
        }
    };
    //********** fileType ***********//
    docTypeChanged = (docType: string) => {
        this.props.setDocType(docType);
    };

    downloadEntitiesFormatChanged = (downloadFormat: string) => {
        this.setState({ downloadEntitiesFormat: downloadFormat });
    }

    getModalType = () => {
        switch (this.props.model.popupType) {
            case MODAL_TYPE.DOWNLOAD_REPORT:
            case MODAL_TYPE.DOWNLOAD_REPORTS:
            case MODAL_TYPE.DOWNLOAD_REPORTS_SEPARATE:
            case MODAL_TYPE.DOWNLOAD_REPORTS_COMBINED:
            case MODAL_TYPE.DOWNLOAD_REPORT_CATEGORY:
            case MODAL_TYPE.PRINT_REPORTS:
            case MODAL_TYPE.PRINT_REPORTS_SEPARATE:
            case MODAL_TYPE.PRINT_REPORTS_COMBINED:
            case MODAL_TYPE.PRINT_REPORT_CATEGORY:
            case MODAL_TYPE.EMAIL_REPORTS:
            case MODAL_TYPE.EMAIL_REPORTS_SEPARATE:
            case MODAL_TYPE.EMAIL_REPORTS_COMBINED:
            case MODAL_TYPE.EMAIL_REPORT_CATEGORY:
                return 'report';
            case MODAL_TYPE.DOWNLOAD_RESULT_LIST:
            case MODAL_TYPE.DOWNLOAD_BATCH_SCREENING_FULL_REPORT:
            case MODAL_TYPE.PRINT_RESULT_LIST:
            case MODAL_TYPE.EMAIL_RESULT_LIST:
                return 'resultsList';
            case MODAL_TYPE.DOWNLOAD_BATCH_SCREENING:
            case MODAL_TYPE.DOWNLOAD_BATCH_SCREENING_SNAPSHOT:
            case MODAL_TYPE.CHANGE_COLUMNS_VISIBILITY_MULTIPLE_ENTITIES:
            case MODAL_TYPE.DOWNLOAD_ENTITIES:
                return 'batchScreening';
            default:
                return 'resultsList';
        }
    };

    //*********** alert frequencies ***********//
    handleDropdownChange: (event: SyntheticInputEvent<HTMLSelectElement>, section: string) => void = (
        event,
        section
    ) => {
        this.setState({
            alert: {
                ...this.state.alert,
                [section]: event.target.value,
            },
        });
    };

    handleNoResultsToggle: () => void = () => {
        this.setState((prevState) => ({
            alert: {
                ...this.state.alert,
                shouldTriggerForNoResults: !prevState.alert.shouldTriggerForNoResults,
            },
        }));
    };

    updateContentTypes = (contentTypes) => {
        this.setState({
            sourceCategories: contentTypes,
        });
        const isButtonDisabled = contentTypes.every(({ checked }) => !checked);
        this.props.togglePrimaryButtonsStatus(isButtonDisabled);
    };

    updateContentLanguages = (contentLanguages) => {
        this.setState({
            contentLanguages: contentLanguages,
        });
    };

    //*********** comments ***********//
    popupCommentsChange = (event) => {
        const { commentsCharLimit } = this.props.model;
        let comment = event.target.value;
        const charsNumber = comment.length;

        if (!isNumber(commentsCharLimit)) {
            this.setState(
                {
                    comments: comment,
                },
                () => {
                    this.commentChangeDelayed();
                }
            );

            return;
        }
        const charsLeft = commentsCharLimit - charsNumber;

        if (charsLeft <= 0) {
            comment = comment.substring(0, commentsCharLimit);
        }

        this.setState(
            {
                comments: comment,
                charsLeft: charsLeft,
            },
            () => {
                this.commentChangeDelayed();
            }
        );
    };

    //********** email ***********//
    validateEmail = (bulkEmails: string) => {
        let emails = bulkEmails.split(',');
        let errorMessage;
        let isValid = true;

        emails.forEach((email) => {
            if (utils.isEmailAddressValid(email.trim())) {
                isValid = isValid && true;
            } else {
                isValid = isValid && false;
                errorMessage = 'InvalidEmail';
            }
        });

        if (bulkEmails.trim() === '') {
            isValid = false;
            errorMessage = 'EmailRequired';
        }

        if (emails.length > 10) {
            isValid = false;
            errorMessage = 'TooManyEmails';
        }
        return {
            isValid,
            errorMessage,
        };
    };

    handleEmailListChange = (event: SyntheticInputEvent<HTMLInputElement>): void => {
        const validationState: { isValid: boolean, errorMessage: string } = utils.validateEmail(event.target.value);

        this.setState(
            {
                emailAddress: event.target.value,
                isEmailValid: validationState.isValid,
                emailErrorMessage: validationState.errorMessage,
            },
            () => {
                if (!this.state.isEmailValid) {
                    this.props.togglePrimaryButtonsStatus(true);
                } else {
                    this.props.togglePrimaryButtonsStatus(false);
                }
            }
        );
    };

    emailAddressChange = (event) => {
        let validationState = this.validateEmail(event.target.value);
        this.setState(
            {
                emailAddress: event.target.value,
                isEmailValid: validationState.isValid,
                emailErrorMessage: validationState.errorMessage,
            },
            () => {
                this.emailAddressChangeDelayed();
            }
        );
    };

    emailSubjectChange = (event) => {
        this.setState(
            {
                emailSubject: event.target.value,
            },
            () => {
                this.emailSubjectChangeDelayed();
            }
        );
    };

    emailCommentsChange = (event) => {
        this.setState(
            {
                emailComments: event.target.value,
            },
            () => {
                this.emailCommentsChangeDelayed();
            }
        );
    };

    //********* reportList **************//

    validateReportSelection = (selectedReports: Array<string>) => {
        let errorMessage;
        let isValid = true;
        if (selectedReports.length === 0) {
            isValid = false;
            errorMessage = 'reportsSelectionErrorMessage';
        }

        return {
            isValid,
            errorMessage,
        };
    };

    handleCheck = (event) => {
        let checkedReports = this.state.checkedReports;
        let reportId = event.target.value;
        if (event.target.checked) {
            checkedReports.push(reportId);
        } else {
            checkedReports.splice(checkedReports.indexOf(reportId), 1);
        }
        let reportsValidationState = this.validateReportSelection(checkedReports);

        let checkedAll = checkedReports.length === this.props.reports.length;
        this.setState({
            checkedReports,
            checkedAll,
            isReportsSelectionValid: reportsValidationState.isValid,
            reportsSelectionErrorMessage: reportsValidationState.errorMessage,
        });
        this.props.setReportList(checkedReports);
    };

    handleSelectAll = (selectAll: boolean) => {
        let previousCheckedReports = [];

        if (selectAll) {
            this.props.reports.forEach((report) => {
                previousCheckedReports.push(report.id);
            });
            this.props.setReportList(previousCheckedReports);
        } else {
            this.props.setReportList(previousCheckedReports);
        }
        let reportsValidationState = this.validateReportSelection(previousCheckedReports);
        this.setState({
            checkedReports: previousCheckedReports,
            checkedAll: selectAll,
            isReportsSelectionValid: reportsValidationState.isValid,
            reportsSelectionErrorMessage: reportsValidationState.errorMessage,
        });
    };

    isReportChecked = (reportId: string) => {
        return this.state.checkedReports.indexOf(reportId) > -1;
    };

    //********* general **************//

    checkEmail = () => {
        let emailValidationState = this.validateEmail(this.state.emailAddress);
        if (!emailValidationState.isValid) {
            this.setState({
                isEmailValid: false,
                emailErrorMessage: emailValidationState.errorMessage,
            });
            return false;
        }
        return true;
    };

    checkReportSelection = () => {
        if (!this.props.model.reportListIds || !this.props.model.reportListIds.length > 0) {
            let reportsValidationState = this.validateReportSelection(this.props.model.reportListIds || []);
            this.setState({
                isReportsSelectionValid: false,
                reportsSelectionErrorMessage: reportsValidationState.errorMessage,
            });
            return false;
        }
        return true;
    };

    checkFileName = () => {
        let fileNameValidationState = this.validateFileName(this.state.fileName);
        if (!fileNameValidationState.isValid) {
            this.setState({
                isFileNameValid: false,
                fileNameErrorMessage: fileNameValidationState.errorMessage,
            });
            return false;
        }
        return true;
    };

    changeCustomNewsQuery = (query: string) => {
        let data = {
            customNewsQuery: query,
            error: false,
            customNewsWildcardError: false,
        };

        if (query.trim() === '') {
            data.error = true;
        }
        this.updateCustomNewsQuery(data);
    };

    resetCustomNewsQuery = (defaultQuery: string) => {
        let data = {
            customNewsQuery: defaultQuery,
            error: false,
            customNewsWildcardError: false,
        };

        this.updateCustomNewsQuery(data);
    };

    updateCustomNewsQuery = (data: Object) => {
        let customNewsWildcardError = data.customNewsWildcardError;

        if (utils.isValidQuery(data.customNewsQuery) === false) {
            customNewsWildcardError = true;
        }
        this.setState(
            (prevState) => ({
                customNewsQuery: data.customNewsQuery,
                customNewsQueryError: prevState.editableTitle.trim() === '' || data.error,
                customNewsQueryChanged: true,
                customNewsWildcardError,
                isPopupDirty: true,
            }),
            () => {
                this.updateButtonStatusDelayed(data.error);
                if (customNewsWildcardError) {
                    this.updateButtonStatusDelayed(customNewsWildcardError);
                }
            }
        );
    };

    changeCustomSearchQuery = (query: string, queryType: string, language: string) => {
        let data: Object = {
            queryType,
            language,
            query: query,
            error: false,
            wildcardError: false,
            reset: this.state.resetQuery[queryType],
        };

        if (query.trim() === '') {
            data.error = true;
        }
        this.updateQueryState(data);
    };

    resetCustomSearchQuery = (queryType: string, language: string) => {
        let data = {
            queryType,
            language,
            query: this.props.model.defaultCustomQuery[queryType],
            error: false,
            reset: true,
        };
        this.updateQueryState(data);
    };

    updateQueryState = (data: Object) => {
        let newQuery: Object = cloneDeep(this.state.query);
        let queryError: Object = cloneDeep(this.state.queryError);
        let wildcardError: Object = cloneDeep(this.state.wildcardError);
        let resetQuery: Object = cloneDeep(this.state.resetQuery) || {};

        newQuery[data.queryType] = data.query;
        resetQuery[data.queryType] = data.reset;
        queryError[data.queryType] = data.error;
        wildcardError[data.queryType] = data.wildcardError;

        if (
            data.language !== NEGATIVE_NEWS_LANGUAGES.german.value &&
            data.language !== NEGATIVE_NEWS_LANGUAGES.turkish.value
        ) {
            if (utils.isValidQuery(data.query) === false) {
                wildcardError[data.queryType] = true;
            }
        }

        this.setState(
            {
                query: newQuery,
                queryError,
                wildcardError,
                resetQuery,
            },
            () => {
                this.props.toggleButtonStatusByType(POPUP_BUTTON_TYPES.save, false);
                if (wildcardError && !isEmpty(wildcardError)) {
                    Object.keys(wildcardError).map((type) => {
                        if (wildcardError[type] === true) {
                            this.props.toggleButtonStatusByType(POPUP_BUTTON_TYPES.save, true);
                        }
                    });
                }
            }
        );
    };

    changeLanguageSelection = (option: string) => {
        let languagesToBeChecked = [];

        this.props.updateAdhocProperties('newsSearchSources', option);

        Object.keys(NEGATIVE_NEWS_SELECTED_LANGUAGE).forEach((key) => {
            if (NEGATIVE_NEWS_SELECTED_LANGUAGE[key].name === option) {
                languagesToBeChecked = NEGATIVE_NEWS_SELECTED_LANGUAGE[key].values;
            }
        });

        this.props.updateCheckedLanguagesUponKey(languagesToBeChecked);
        this.props.updateAllButtonStatus(false);
    };

    handleEditableTitleSave = (title: string) => {
        switch (this.props.model.popupType) {
            case MODAL_TYPE.USER_PREFERENCES_ADD_CUSTOM_NEWS_QUERY:
            case MODAL_TYPE.USER_PREFERENCES_EDIT_CUSTOM_NEWS_QUERY:
                this.setState(
                    (prevState) => ({
                        editableTitle: title,
                        customNewsQueryError:
                            (prevState.customNewsQueryChanged && prevState.customNewsQuery.trim() === '') ||
                            title.trim() === '',
                        isPopupDirty: true,
                    }),
                    () => {
                        if (this.state.customNewsQuery.trim() !== '' && !this.state.customNewsQueryError) {
                            this.updateButtonStatusDelayed(false);
                        }
                    }
                );
                break;
            default:
                console.warn('No handler set for this type of modal');
        }
    };

    getResultListActionTitle = () => {
        let listActionTitle = '';
        let term: mixed = '';

        switch (this.props.model.popupType) {
            case MODAL_TYPE.PRINT_REPORTS:
                term = this.props.model.selectedItemsCount;
                listActionTitle =
                    term === 1 ? 'PopupBuilder.actionTitle.printOneReport' : 'PopupBuilder.actionTitle.printReports';

                break;
            case MODAL_TYPE.DOWNLOAD_REPORTS:
                term = this.props.model.selectedItemsCount;
                listActionTitle =
                    term === 1
                        ? 'PopupBuilder.actionTitle.downloadOneReport'
                        : 'PopupBuilder.actionTitle.downloadReports';

                break;
            case MODAL_TYPE.EMAIL_REPORTS:
                term = this.props.model.selectedItemsCount;
                listActionTitle =
                    term === 1 ? 'PopupBuilder.actionTitle.emailOneReport' : 'PopupBuilder.actionTitle.emailReports';

                break;
            case MODAL_TYPE.ELASTIC_PRINT_DOCUMENT:
            case MODAL_TYPE.PRINT_REPORT_CATEGORY:
                break;
            case MODAL_TYPE.ADHOC_SEARCH:
                listActionTitle = 'PopupBuilder.actionTitle.addHocSearch';
                break;
            default:
                listActionTitle = '';
        }
        return !isEmpty(listActionTitle)
            ? formatRichMessage({ id: listActionTitle }, this.props.intl, { count: term })
            : '';
    };

    getExplanation = () => {
        let explanation = '';
        let term = '';

        switch (this.props.model.popupType) {
            case MODAL_TYPE.USER_PREFERENCES_EDIT_NEGATIVE_SEARCH_TERMS:
                explanation = 'PopupBuilder.explanation.editNegativeSearchTerms';
                break;
            case MODAL_TYPE.USER_PREFERENCES_VIEW_CUSTOM_NEWS_QUERY:
                explanation = 'DynamicMessage';
                term = ' ';
                break;

            default:
                explanation = 'PopupBuilder.explanation.generalExplanation';
        }
        return {
            explanation,
            term,
        };
    };

    getDescription = () => {
        let description = '';
        let term = '';
        let count;
        let uboAditionalReportDescription = '';
        switch (this.props.model.popupType) {
            case MODAL_TYPE.USER_PREFERENCES_ADD_CUSTOM_NEWS_QUERY:
            case MODAL_TYPE.USER_PREFERENCES_EDIT_CUSTOM_NEWS_QUERY:
                return this.props.model.description;
            case MODAL_TYPE.USER_PREFERENCES_VIEW_CUSTOM_NEWS_QUERY:
                description = this.props.model.description;
                break;
            case MODAL_TYPE.DELETE_REPORT:
                description = 'PopupBuilder.description.deleteReports';
                break;
            case MODAL_TYPE.DELETE_REPORT_NOTE:
            case MODAL_TYPE.DELETE_NOTE_FROM_DOCUMENT:
                description = 'PopupBuilder.description.deleteNote';
                break;
            case MODAL_TYPE.DELETE_DOCUMENT_FROM_REPORT:
                description = 'PopupBuilder.description.deleteDocument';
                break;
            case MODAL_TYPE.SHOW_SNAPSHOT:
                description = 'PopupBuilder.description.showSnapshot';
                break;
            case MODAL_TYPE.DELETE_ALERT:
            case MODAL_TYPE.DELETE_ALERTS:
                description = this.props.model.description;
                break;
            case MODAL_TYPE.APPLY_ADMIN_CHANGES:
                description = `PopupBuilder.description.applyAdminChanges`;
                term = this.props.model.usersInPreferenceGroup;
                break;
            case MODAL_TYPE.DISCARD_ADMIN_CHANGES:
                description = `PopupBuilder.description.discardAdminChanges`;
                break;
            case MODAL_TYPE.USER_PREFERENCES_DELETE_CUSTOM_NEWS_QUERY:
                description = 'PopupBuilder.description.deleteCustomNewsQuery';
                term = this.props.model.queryTitle;
                break;
            case MODAL_TYPE.DOWNLOAD_REPORT:
            case MODAL_TYPE.DOWNLOAD_REPORTS:
                description = 'PopupBuilder.explanation.downloadReport';
                break;
            case MODAL_TYPE.DOWNLOAD_BATCH_SCREENING_SNAPSHOT:
            case MODAL_TYPE.DOWNLOAD_RESULT_SNAPSHOT:
            case MODAL_TYPE.DOWNLOAD_BATCH_SCREENING_FULL_REPORT:
                count =
                    this.props.model.articleType === 'ubo'
                        ? this.props.model.totalArticlesCount
                        : this.props.context.maxNumberOfArticles;
                uboAditionalReportDescription =
                    this.props.model.searchQueryType === COMPANY_SEARCH
                        ? formatRichMessage(
                            { id: 'PopupModal.ModalResultList.Snapshot.All.uboDescription' },
                            this.props.intl
                        )
                        : '';
                description = this.props.model.snapshotAllResultsDownload
                    ? 'PopupModal.ModalResultList.Snapshot.All.description'
                    : 'PopupModal.ModalResultList.Snapshot.ContentType.description';
                break;
            case MODAL_TYPE.UPLOAD_ENTITIES_COMPANY_SOURCES_WITH_COMPANIES:
                description = 'PopupBuilder.description.uploadEntitiesCompanySelectedSourcesWithCompanies';
                break;
            case MODAL_TYPE.UPLOAD_ENTITIES_COMPANY_SOURCES_WITHOUT_COMPANIES:
                description = 'PopupBuilder.description.uploadEntitiesCompanySelectedSourcesWithoutCompanies';
                break;
            case MODAL_TYPE.SAVE_SEARCH:
                description = 'PopupBuilder.description.saveSearch';
                break;
            default:
                description = 'PopupBuilder.description.genericAction';
        }

        return formatRichMessage({ id: description }, this.props.intl, {
            term: term,
            count: count,
            uboDescription: uboAditionalReportDescription,
        });
    };

    componentShouldBeVisible = (components, component) => {
        return components?.indexOf(component) > -1;
    };

    validateModal = () => {
        let modalIsValid: boolean = true;
        //TODO: validate all type of popups
        switch (this.props.model.popupType) {
            case MODAL_TYPE.EMAIL_REPORTS:
            case MODAL_TYPE.EMAIL_REPORTS_SEPARATE:
            case MODAL_TYPE.EMAIL_REPORTS_COMBINED:
                modalIsValid = this.checkEmail() && this.checkReportSelection();
                break;
            case MODAL_TYPE.EMAIL_REPORT_CATEGORY:
            case MODAL_TYPE.EMAIL_RESULT_LIST:
            case MODAL_TYPE.ELASTIC_EMAIL_DOCUMENT:
                modalIsValid = this.checkEmail();
                break;
            case MODAL_TYPE.DOWNLOAD_RESULT_LIST:
            case MODAL_TYPE.DOWNLOAD_BATCH_SCREENING_FULL_REPORT:
            case MODAL_TYPE.DOWNLOAD_REPORT_CATEGORY:
            case MODAL_TYPE.DOWNLOAD_RESULT_SNAPSHOT:
            case MODAL_TYPE.DOWNLOAD_SNAPSHOT_PAGE:
            case MODAL_TYPE.DOWNLOAD_BATCH_SCREENING:
            case MODAL_TYPE.DOWNLOAD_BATCH_SCREENING_SNAPSHOT:
            case MODAL_TYPE.DOWNLOAD_ENTITIES:
                modalIsValid = this.checkFileName();
                break;
            case MODAL_TYPE.DOWNLOAD_REPORT:
                modalIsValid = this.checkFileName();
                break;
            case MODAL_TYPE.DOWNLOAD_REPORTS:
            case MODAL_TYPE.DOWNLOAD_REPORTS_SEPARATE:
            case MODAL_TYPE.DOWNLOAD_REPORTS_COMBINED:
                modalIsValid = this.checkReportSelection() && this.checkFileName();
                break;
            case MODAL_TYPE.PRINT_REPORTS:
            case MODAL_TYPE.PRINT_REPORTS_SEPARATE:
            case MODAL_TYPE.PRINT_REPORTS_COMBINED:
                modalIsValid = this.checkReportSelection();
                break;
            case MODAL_TYPE.DOWNLOAD_HISTORY:
                modalIsValid = this.checkFileName();
                break;
            case MODAL_TYPE.EDIT_ARTICLE_NOTE:
                if (
                    this.props.model.article &&
                    this.props.model.article.note &&
                    this.props.model.article.note.length > 0
                ) {
                    return;
                } else {
                    modalIsValid = false;
                }
        }

        return modalIsValid;
    };

    computeSelectedData = (selectedArticlesInfo, resultListType) => {
        const selectedDocumentsInfo = { documents: '' };
        let data = [selectedDocumentsInfo];

        if (!selectedArticlesInfo.length || resultListType === ALL_ARTICLES_REPORT) {
            data[0].documents = MATOMO_SELECTED_ARTICLES_DEFAULT_DELIVERY_MESSAGE;
        } else if (!selectedArticlesInfo.length || resultListType === NO_DOCUMENTS_OF_INTEREST) {
            data[0].documents = MATOMO_SELECTED_ARTICLES_NO_DOCUMENTS_OF_HISTORY_DELIVERY_MESSAGE;
        } else {
            data = selectedArticlesInfo;
        }

        return data;
    };

    doAction = () => {
        if (this.validateModal() === false) {
            return;
        }

        const {
            model: { originalArticleType, buttons, resultListType, articles, actionCallback },
            articleNavigation: { index },
            location: { pathname },
            hideModal,
            selectedArticlesInfo,
        } = this.props;
        this.toggleDeliveryOptionsDisplayPreference();
        let deliveryService = new DeliveryService(this.props.model);
        let currentDocumentInfo = {};

        deliveryService.doDelivery(this.state);

        //callback to be called after the delivery started e.g clear selected items
        actionCallback && actionCallback();

        hideModal();

        if (articles && articles.length === 1) {
            const { id, pcsi } = articles[0];
            currentDocumentInfo = {
                documentRank: index + 1,
                pcsi,
                lni: id,
            };
        }

        if (window['_mtm'] && pathname !== ROUTES.START_PAGE) {
            const firstButtonLabel = buttons[0].label;
            const modalActionType = firstButtonLabel.substr(firstButtonLabel.lastIndexOf('.') + 1);
            let selectedDocumentsData = this.computeSelectedData(selectedArticlesInfo, resultListType);

            const documentData = pathname === ROUTES.DOCUMENT ? currentDocumentInfo : selectedDocumentsData;

            callMatomoEvent({
                pageName: MATOMO.pageName.resultsList,
                component: MATOMO.component.searchResultsList,
                subComponent: MATOMO.subComponent.delivery,
                event: MATOMO_DOCUMENT_ACTION_EVENT_TRANSFORMATION[modalActionType],
                value: MATOMO_DOCUMENT_ACTION_VALUE_TRANSFORMATION[modalActionType],
                action: MATOMO.action.click,
                contextData: {
                    documentInfo: documentData,
                    contentType: originalArticleType,
                },
            });
        }
    };

    closeModal = () => {
        this.props.hideModal();
    };

    // Items
    downloadHistorySelectionChanged = (event) => {
        if (this.props.model.popupType === MODAL_TYPE.DOWNLOAD_HISTORY) {
            this.setState(
                {
                    fullListCheckboxState: event.target.value === ALL_ITEMS_LIST,
                },
                () => {
                    let reportListType = this.state.fullListCheckboxState ? ALL_ITEMS_LIST : SELECTED_ITEMS;
                    this.props.setResultListType(reportListType);
                }
            );
        }
    };
    // END Items

    changeSearchNamePartyEntity = (isNamePartySearchEnabled: boolean) => {
        this.setState(
            {
                searchNamePartyEntity: isNamePartySearchEnabled,
            },
            () => {
                this.props.updateAllButtonStatus(false);
                this.props.updateAdhocProperties('searchNamePartyEntity', isNamePartySearchEnabled);
                this.props.updateSearchNamePartyEntity(isNamePartySearchEnabled);
            }
        );
    };

    onChangeDeliveryOptions = (option: Option, checked: string | boolean) => {
        this.props.updateDeliveryContentOptions(option, checked);
    };

    updateComponentsVisibleState = (isLimitExceeded: boolean) => {
        let components;

        if (isLimitExceeded) {
            components = union(this.props.model.staticComponents, this.props.model.warningComponents);
        } else {
            components = union(this.props.model.staticComponents, this.props.model.dynamicComponents);

            if (this.props.model.components.indexOf('ModalDismissibleMessage') !== -1) {
                components.push('ModalDismissibleMessage');
            }
        }

        if (!isEqual(this.props.model.components, components)) {
            this.props.updateModalComponents(components);
        }
    };

    updateModalBasedOnDeliveryLimit = () => {
        let isLimitExceeded = this.isDeliveryItemsLimitExceeded();
        this.updateComponentsVisibleState(isLimitExceeded);
        this.checkPrimaryButtonDisabledStatus();
    };

    isDeliveryItemsLimitExceeded = () => {
        let popupType = this.props.model.popupType;

        if (IGNORE_DELIVERY_LIMITS.indexOf(this.props.model.resultListType) > -1) {
            return false;
        }

        switch (popupType) {
            case MODAL_TYPE.ADD_TO_REPORT:
            case MODAL_TYPE.PRINT_RESULT_LIST:
            case MODAL_TYPE.EMAIL_RESULT_LIST:
            case MODAL_TYPE.DOWNLOAD_RESULT_LIST:
            case MODAL_TYPE.DOWNLOAD_BATCH_SCREENING_FULL_REPORT:
                return this.getSelectedArticlesCount() > this.props.context.maxNumberOfArticles;

            case MODAL_TYPE.DOWNLOAD_HISTORY:
                return this.getSelectedHistoryEntries() > this.props.context.maxNumberOfHistoryEntries;

            default:
                return false;
        }
    };

    toggleDeliveryOptionsDisplay = (status) => {
        let key = this.getModifiedByUserKey(this.props.model.popupType);
        this.props.toggleDeliveryOptionsDisplay(status);
        this.props.updateModifiedByUser(true, key);
    };

    getSnapshotResultListFullListTitle = (): string => {
        let messageId = 'ModalResultList_FullResultsList',
            { popupType, articleType, components } = this.props.model;

        if (popupType === MODAL_TYPE.DOWNLOAD_RESULT_SNAPSHOT) {
            if (typeof articleType === 'string') {
                switch (articleType) {
                    case CATEGORY_NAMES.NEGATIVE_NEWS:
                        messageId = 'PopupModal.ModalResultList.FullResultsList.NegativeNews';
                        break;
                    case CATEGORY_NAMES.CUSTOM_NEWS:
                        messageId = 'PopupModal.ModalResultList.FullResultsList.CustomNews';
                        break;
                }
            } else if (Array.isArray(articleType)) {
                if (articleType.length > 0) {
                    let legalCategories = articleType.filter(
                        (category) => Object.keys(LEGAL_SOURCES_TITLES).indexOf(category) !== -1
                    );

                    if (components.indexOf('ModalDismissibleMessage') !== -1) {
                        messageId = 'PopupModal.ModalResultList.FullResultsList.Full.label';
                    } else if (legalCategories.length === articleType.length) {
                        messageId = 'PopupModal.ModalResultList.FullResultsList.Legal';
                    }
                }
            }
        }

        return messageId;
    };

    acceptAction = () => {
        const { popupType, acceptAction } = this.props.model;

        if (popupType === MODAL_TYPE.EDIT_SEARCH_MULTIPLE_ENTITIES) {
            const { editEntity } = this.state;
            const {
                entityInfo: { entityId },
            } = this.props.model;
            const { editEntity: originalEditEntity } = this.originalState;
            const dataNeeded = ['displayName', 'entityName', 'entityType', 'entityResetFilters'];

            acceptAction({
                entityId,
                newData: pick(editEntity, dataNeeded),
                oldData: pick(originalEditEntity, dataNeeded),
            });
        } else {
            acceptAction();
        }
        this.closeModal();
        this.props.clearPopup();
    };

    rejectAction = () => {
        this.props.model.rejectAction();
        this.closeModal();
        this.props.clearPopup();
    };

    saveVisibilityAction = () => {
        this.props.model.action(this.state.elementsVisibility);
        this.closeModal();
    };

    editEntityAction = (updates) => {
        // If entity type is changed the reset filters checkbox should be checked and disabled
        const currentEntityType = updates.entityType ?? this.state.editEntity.entityType;
        if (this.originalState.editEntity.entityType !== currentEntityType) {
            updates.entityResetFilters = true;
        }

        this.setState(
            {
                editEntity: {
                    ...this.state.editEntity,
                    ...updates,
                },
            },
            () => {
                const {
                    editEntity,
                    editEntity: { displayNameError, entityNameError },
                } = this.state;
                const { togglePrimaryButtonsStatus } = this.props;
                const { editEntity: originalEditEntity } = this.originalState;

                const isAnythingChanged = JSON.stringify(editEntity) !== JSON.stringify(originalEditEntity);
                const areAllFieldsValid = !displayNameError && !entityNameError;
                const isSaveButtonEnabled = isAnythingChanged && areAllFieldsValid;

                togglePrimaryButtonsStatus(!isSaveButtonEnabled);
            }
        );
    };

    //********* uploadEntities **************//

    clearFileInput = () => {
        if (this.uploadInputRef && this.uploadInputRef.current) {
            this.uploadInputRef.current.value = '';
        }
    };

    handleCancelSelection = () => {
        const { uploadedFile } = this.initialState;

        this.setState({ uploadedFile }, () => {
            this.clearFileInput();
        });
    };

    handleDroppedFile = (e) => {
        const { files } = e.dataTransfer;

        this.handleFileSelected({ target: { files: [files[0]] } }, files.length > 1);
    };

    handleFileSelected = async (e, multipleFilesDragged) => {
        try {
            const { sources, userEmail } = this.props;
            // entity limit check
            const entityUploadLimitReached = isEntityUploadLimitReached(
                this.state.entityViewCount,
                this.props.entityUploadLimit,
                this.props.isMultipleEntitiesTrial
            );

            const uploadedFile = await BatchUploadHelper.validateFileRequirements(
                e,
                entityUploadLimitReached,
                multipleFilesDragged,
                sources,
                userEmail
            );
            this.setState({ uploadedFile });
        } catch (e) {
            console.log('Error selecting file', e);
        }
    };

    updateSelectDates = () => {
        this.props.updateAdHocProperty('newsDateRange', this.state.dateRange.newsDateRange);
        this.props.updateAdHocProperty('legalDateRange', this.state.dateRange.legalDateRange);
        this.props.updateAdHocProperty('companyDateRange', this.state.dateRange.companyDateRange);
    };

    changeDateRange = ({ newsDateRange, legalDateRange, companyDateRange }) => {
        this.setState(
            {
                dateRange: {
                    newsDateRange,
                    legalDateRange,
                    companyDateRange,
                },
            },
            () => {
                this.updateSelectDates();
            }
        );
    };

    handleChangeSorting = (e) => {
        this.setState({ sort: e.target.value });
    };

    handleChangeView = (view) => this.setState({ selectedViewForUpload: view });

    createNewView = (view) => this.setState({ selectedViewForUpload: view });

    buildBatchPayload = () => {
        const {
            uploadedFile: { fileContent },
            dateRange,
            sort,
            selectedViewForUpload,
        } = this.state;

        const { timezone, language, startEachArticleOnNewPage, billingId, sources, userEmail, fuzzyOn } = this.props;
        const screeningEntityData = BatchUploadHelper.buildEntities(fileContent, userEmail);
        const costCode = costCodeUtils.getCostCode();
        const categoryOrder = sources
            .filter((source) => source.value)
            .map((source) => ({
                sourceType: source.name,
                order: source.reportOrder,
            }));
        const fileName =
            BATCH_UPLOAD_DEFAULT_FILENAME + utils.formatReportDateWithtTimezoneOffset(utils.getCurrentTime());

        return {
            batchFileName: fileName,
            timezone,
            language,
            startEachArticleOnNewPage,
            billingId,
            costCode,
            categoryOrder,
            screeningEntityData,
            dateRange,
            sort,
            viewId: selectedViewForUpload?.id,
            fuzzyOn
        };
    };

    onUploadPollingFinished = () => {
        const { setUploadPollingEntityView } = this.props;

        if (this.mounted) {
            this.setState(this.initialState, () => {
                this.updateSelectDates();
                this.clearFileInput();
            });
        }
        this.props.setUpdateEntitiesColumnsFromContentTypes(true);
        this.props.model.handleTotalEntitiesCountChange();
        this.closeModal();
        setUploadPollingEntityView(false);
        notificationService.pollInProgressEntitiesData({route: ROUTES.SCREENING});
    };

    createNewViewHandler = async () => {

        await this.props.createView({name: this.state.selectedViewForUpload.name});
    };

    submitBatchReport = async (payload) => {
        const { setUploadPollingEntityView, addNewBatchId, viewId, createView } = this.props;
        const newPayload = {...payload};

        if(newPayload.viewId === MULTIPLES_ENTITIES_CREATE_NEW_VIEW) {
            const [response, error] = await createView(this.state.selectedViewForUpload?.name)
            if(error){
                this.props.handleEntityViewApiError(error, { viewId: response.viewId })
            } else {
                newPayload.viewId = response.viewId;
                this.setState({ selectedViewForUpload: {...this.state.selectedViewForUpload, id: response.viewId} })
            }
        }

        setUploadPollingEntityView(true);
        const [batchId, error] = await BatchUploadHelper.submitBatchReport(newPayload, { viewId: newPayload.viewId ?? viewId});

        if (batchId) {
            //set the initial data for the upload snackbar
            
            const [data, error] = await EntityViewApi.refreshPolling(batchId);

            if (!error) {
                const { totalCount } = data;
                //set the initial data for the copy notification/snackbar
                const uploadNotificationData = snackbarUtils.getProcessingStatusInitialData({
                    actionType: ACTION_TYPES.UPLOAD,
                    totalCount: totalCount,
                });

                addNewBatchId({batchId, data: uploadNotificationData, keyInTheStore: NOTIFICATION_STORE_KEYS.UPLOAD});

                notificationService.pollUploadEntityView(
                    {
                        batchId,
                        route: ROUTES.SCREENING,
                        onFinished: () => this.onUploadPollingFinished(),
                    },
                    true
                );
            }
        }
        if (error) {
            let uploadedFile = {
                ...this.state.uploadedFile,
                fileName: '',
                fileSize: null,
                fileContent: null,
                isValid: false,
            };
            if (typeof error === 'string') {
                uploadedFile.errorMessage.push(error);
                this.setState({ uploadedFile });
            } else {
                uploadedFile.warningMessage.push(error);
                this.setState({ uploadedFile });
            }
            setUploadPollingEntityView(false);
        }
    };

    submitUpload = async () => {
        if (this.props.uploadPollingActive || !this.state.uploadedFile.fileName.length) {
            return;
        }
        const payload = this.buildBatchPayload();

        await this.submitBatchReport(payload);
        this.changeView();

    };

    changeView = () => {
        const { selectedViewForUpload } = this.state;
        let viewAction = selectedViewForUpload.id === MULTIPLE_ENTITIES_NONE_SELECTED_VIEW ? this.props.resetView : () => this.props.changeView(selectedViewForUpload);
        viewAction();
    }

    //********* end uploadEntities **************//

    //********* sharedViews **************//

    setIsShareViewSearchbarEnabled = (value) =>
        this.setState({
            isShareViewSearchbarEnabled: value,
        });

    setShareViewUserSearchText = (value) =>
        this.setState({
            shareViewSearchbarText: value,
        });

    //********* end sharedViews **************//

    render() {
        let buttons = [];
        let buttonAction = {};
        let buttonLoading = false;
        let historyItems = this.props.model.items || [];
        let uboTradeUpDocTitle =
            this.props.model.article && this.props.model.article.organization
                ? this.props.model.article.organization.primaryName
                : null;
        const fileNameRemainingCharactersMessage = formatRichMessage(
            { id: 'DownloadReportFileNameValidation.RemainingCharacters' },
            this.props.intl,
            { count: FILENAME_CHARACTERS_LIMIT - this.state.fileName.length }
        );
        const dragAndDropMessages = {
            errorMessage: this.state.uploadedFile.errorMessage,
            warningMessage: this.state.uploadedFile.warningMessage,
            infoMessage: this.state.uploadedFile.infoMessage,
            multilineErrorMessage: this.state.uploadedFile.multilineErrorMessage,
        };

        this.props.model.buttons.forEach((button) => {
            let buttonDisabled = button.isDisabled;

            if (button.type === POPUP_BUTTON_TYPES.reset) {
                buttonAction = this.doReset;
            } else if (button.type === POPUP_BUTTON_TYPES.accept) {
                buttonAction = this.acceptAction;
            } else if (button.type === POPUP_BUTTON_TYPES.reject) {
                buttonAction = this.rejectAction;
            } else if (
                button.type === POPUP_BUTTON_TYPES.save &&
                this.props.model.popupType === MODAL_TYPE.CHANGE_COLUMNS_VISIBILITY_MULTIPLE_ENTITIES
            ) {
                buttonAction = this.saveVisibilityAction;
            } else if (
                button.type === POPUP_BUTTON_TYPES.upload &&
                this.props.model.popupType === MODAL_TYPE.UPLOAD_ENTITIES
            ) {
                buttonLoading = this.props.uploadPollingActive;
                buttonDisabled = BatchUploadHelper.areThereAnyFileErrors(dragAndDropMessages) || !this.state.uploadedFile.fileSize || this.state.selectedViewForUpload.name === '';

                button.primary || button.primaryAction
                    ? (buttonAction = this.submitUpload)
                    : (buttonAction = this.closeModal);
            } else if (this.props.model.popupType === MODAL_TYPE.DOWNLOAD_BATCH_SCREENING) {
                buttonLoading = this.props.multipleEntitiesDownloadReportsPollingActive;

                button.primary || button.primaryAction
                    ? (buttonAction = this.doAction)
                    : (buttonAction = this.closeModal);
            } else {
                button.primary || button.primaryAction
                    ? (buttonAction = this.doAction)
                    : (buttonAction = this.closeModal);
            }

            buttons.push({
                text: button.label || 'Undefined',
                primary: button.primary || false,
                click: buttonAction,
                isDisabled: buttonDisabled,
                dataTrack: button.dataTrack,
                testid: button.testid,
                loading: buttonLoading,
            });
        });

        const modalTitle = uboTradeUpDocTitle || this.props.model.title;
        const commentsLabel = this.props.model.commentsLabel
            ? this.props.model.commentsLabel
            : 'PopupModal.label.comments';

        return (
            <div className="notranslate">
                <Modal
                    closeButtonHandler={this.closeModal}
                    buttons={buttons}
                    popupType={this.props.model.popupType}
                    popupSize={this.props.model.popupSize}
                >
                    <div className={`popup-modal notranslate ${this.props.model.popupType}`}>
                        <div className="popup-modal-header">
                            {modalTitle && <FormattedMessage id={modalTitle} data-testid="modal-title-testid" />}
                            <ModalDismissibleMessage
                                isVisible={this.componentShouldBeVisible(
                                    this.props.model.components,
                                    'ModalDismissibleMessage'
                                )}
                                messageId={this.getExplanation().explanation}
                                data-testid="modal-dismissible-message-testid"
                            />
                        </div>
                        <div className="popup-modal-body">
                            {this.props.model.adHocMasterLock ? (
                                <div className="addHoc-scrollable-area__message__admin-lock">
                                    <FormattedMessage id={'AdHocSearch.Popup.Locked'} />
                                    {/*{(props.newsSearchesLock || props.masterLock) &&*/}
                                    <AdminLock
                                        lockName={FE_LOCK_NAMES.newsSearchesDateRange}
                                        isDisabled={true}
                                        data-testid="modal-action-title-lock-testid"
                                    />
                                </div>
                            ) : (
                                <ModalActionTitle
                                    isVisible={this.componentShouldBeVisible(
                                        this.props.model.components,
                                        'ModalActionTitle'
                                    )}
                                    actionTitle={this.getResultListActionTitle()}
                                    data-testid="modal-action-title-testid"
                                />
                            )}
                            <ModalExplanation
                                popupType={this.props.model.popupType}
                                isVisible={this.componentShouldBeVisible(
                                    this.props.model.components,
                                    'ModalExplanation'
                                )}
                                explanation={this.getExplanation()}
                                data-testid="modal-explanation-testid"
                            />
                            <ModalTabbedContent
                                {...this.props}
                                isVisible={this.componentShouldBeVisible(
                                    this.props.model.components,
                                    'ModalTabbedContent'
                                )}
                                docTypeChanged={this.docTypeChanged}
                                popupComments={this.state.comments}
                                popupCommentsChange={this.popupCommentsChange}
                                onChangeDeliveryOptions={this.onChangeDeliveryOptions}
                                toggleDeliveryOptionsDisplay={this.toggleDeliveryOptionsDisplay}
                                isFullDocsDisabled={this.state.isFullDocsDisabled}
                                getModalType={this.getModalType}
                                isFileNameValid={this.state.isFileNameValid}
                                fileNameErrorMessage={this.state.fileNameErrorMessage}
                                fileName={this.state.fileName}
                                fileNameChange={this.fileNameChange}
                                fileNameRemainingCharactersMessage={fileNameRemainingCharactersMessage}
                                isEmailValid={this.state.isEmailValid}
                                emailErrorMessage={this.state.emailErrorMessage}
                                emailAddress={this.state.emailAddress}
                                emailSubject={this.state.emailSubject}
                                emailComments={this.state.emailComments}
                                emailAddressChange={this.emailAddressChange}
                                emailSubjectChange={this.emailSubjectChange}
                                emailCommentsChange={this.emailCommentsChange}
                                data-testid="modal-tabbed-content-testid"
                            />
                            <ModalDescription
                                popupType={this.props.model.popupType}
                                isVisible={this.componentShouldBeVisible(
                                    this.props.model.components,
                                    'ModalDescription'
                                )}
                                description={this.getDescription()}
                                data-testid="modal-description-testid"
                            />
                            <ModalResultList
                                isVisible={this.componentShouldBeVisible(
                                    this.props.model.components,
                                    'ModalResultList'
                                )}
                                popupType={this.props.model.popupType}
                                maxNumberOfArticles={this.props.context.maxNumberOfArticles}
                                fullListCheckboxState={this.state.fullListCheckboxState}
                                noDocumentOfInterestCheckbox={this.state.noDocumentOfInterestCheckbox}
                                selectedArticlesCount={this.getSelectedArticlesCount()}
                                totalArticlesCount={this.props.model.totalArticlesCount}
                                noDocumentsFoundCheckbox={this.state.noDocumentsFoundCheckbox}
                                articleSelectionChanged={this.articleSelectionChanged}
                                fullListOptionKey={this.getSnapshotResultListFullListTitle()}
                                data-testid="modal-results-list-testid"
                                category={this.props.model.postFilters?.category}
                            />
                            <ModalItemsList
                                isVisible={this.componentShouldBeVisible(this.props.model.components, 'ModalItemsList')}
                                popupType={this.props.model.popupType}
                                selectedItemsCount={historyItems.length}
                                fullListCheckboxState={this.state.fullListCheckboxState}
                                itemSelectionChanged={this.downloadHistorySelectionChanged}
                                maxNumberOfHistoryEntries={this.props.context.maxNumberOfHistoryEntries}
                                selectedHistoryEntries={this.getSelectedHistoryEntries()}
                                data-testid="modal-items-list-testid"
                            />
                            <ModalColumnsList
                                isVisible={this.componentShouldBeVisible(
                                    this.props.model.components,
                                    'ModalColumnsList'
                                )}
                                popupType={this.props.model.popupType}
                                elementsVisibility={this.state.elementsVisibility}
                                updateElementsVisibility={this.updateElementsVisibility}
                                data-testid="modal-columns-list-testid"
                                closeModal={this.closeModal}
                            />
                            <ModalContentOptions
                                isVisible={this.componentShouldBeVisible(
                                    this.props.model.components,
                                    'ModalContentOptions'
                                )}
                                contentsOptions={this.props.model.contentsOptions}
                                showDeliveryOptions={this.props.model.showDeliveryOptions}
                                handleChange={this.onChangeDeliveryOptions}
                                toggleDeliveryOptionsDisplay={this.toggleDeliveryOptionsDisplay}
                                userLocks={this.props.userLocks}
                                isFullDocsDisabled={this.state.isFullDocsDisabled}
                                modalType={this.getModalType()}
                                data-testid="modal-content-options-testid"
                                isSnapshotVisible={this.props.isSnapshotVisible}
                                isSnapshotDisabled={this.state.isSnapshotDisabled}
                                popupType={this.props.model.popupType}
                            />
                            <ModalReportList
                                isVisible={this.componentShouldBeVisible(
                                    this.props.model.components,
                                    'ModalReportList'
                                )}
                                popupType={this.props.model.popupType}
                                reports={this.props.reports}
                                checkedAll={this.state.checkedAll}
                                handleCheck={this.handleCheck}
                                isReportsSelectionValid={this.state.isReportsSelectionValid}
                                reportsSelectionErrorMessage={this.state.reportsSelectionErrorMessage}
                                handleSelectAll={this.handleSelectAll}
                                isReportChecked={this.isReportChecked}
                                data-testid="modal-report-list-testid"
                            />
                            <ModalFileFormat
                                isVisible={this.componentShouldBeVisible(
                                    this.props.model.components,
                                    'ModalFileFormat'
                                )}
                                docType={this.props.model.docType}
                                docTypeChanged={this.docTypeChanged}
                                popupType={this.props.model.popupType}
                                data-testid="modal-file-format-testid"
                            />
                            <ModalDownloadEntitiesFormat
                                isVisible={this.componentShouldBeVisible(
                                    this.props.model.components,
                                    'ModalDownloadEntitiesFormat'
                                )}
                                downloadEntitiesFormat={this.state.downloadEntitiesFormat}
                                downloadEntitiesFormatChanged={this.downloadEntitiesFormatChanged}
                                popupType={this.props.model.popupType}
                                data-testid="modal-download-entities-format-testid"
                            />
                            <ModalEmail
                                popupType={this.props.model.popupType}
                                isVisible={this.componentShouldBeVisible(this.props.model.components, 'ModalEmail')}
                                isEmailValid={this.state.isEmailValid}
                                emailErrorMessage={this.state.emailErrorMessage}
                                emailAddress={this.state.emailAddress}
                                emailSubject={this.state.emailSubject}
                                emailComments={this.state.emailComments}
                                emailAddressChange={this.emailAddressChange}
                                emailSubjectChange={this.emailSubjectChange}
                                emailCommentsChange={this.emailCommentsChange}
                                data-testid="modal-email-testid"
                            />
                            <ModalAnonymizedWarning
                                isVisible={
                                    this.componentShouldBeVisible(
                                        this.props.model.components,
                                        'ModalAnonymizedWarning'
                                    ) && this.props.isAnonymized
                                }
                            />

                            <ModalEmailsList
                                isVisible={this.componentShouldBeVisible(
                                    this.props.model.components,
                                    'ModalEmailsList'
                                )}
                                isEmailValid={this.state.isEmailValid}
                                emailErrorMessage={this.state.emailErrorMessage}
                                emails={this.state.emailAddress}
                                handleEmailAddressChange={this.handleEmailListChange}
                                showEmailList={null}
                                hideEmailList={null}
                                handleEmailKeyPress={null}
                                showEmailDropdownList={false}
                            />
                            <ModalFileName
                                popupType={this.props.model.popupType}
                                isVisible={this.componentShouldBeVisible(this.props.model.components, 'ModalFileName')}
                                isFilenameValid={this.state.isFileNameValid}
                                fileNameErrorMessage={this.state.fileNameErrorMessage}
                                fileName={this.state.fileName}
                                fileNameChange={this.fileNameChange}
                                fileNameRemainingCharactersMessage={fileNameRemainingCharactersMessage}
                                data-testid="modal-file-name-testid"
                            />
                            <ModalComments
                                popupType={this.props.model.popupType}
                                label={commentsLabel}
                                description={this.props.model.commentsDescription}
                                isVisible={this.componentShouldBeVisible(this.props.model.components, 'ModalComments')}
                                popupComments={this.state.comments}
                                popupCommentsCharsLeft={this.state.charsLeft}
                                popupCommentsChange={this.popupCommentsChange}
                                data-testid="modal-comments-testid"
                            />
                            <ModalWarning
                                popupType={this.props.model.popupType}
                                isVisible={this.componentShouldBeVisible(this.props.model.components, 'ModalWarning')}
                                message={this.props.model.warning}
                                data-testid="modal-warning-testid"
                            />
                            <ModalEditableTitle
                                isVisible={this.componentShouldBeVisible(
                                    this.props.model.components,
                                    'ModalEditableTitle'
                                )}
                                title={this.state.editableTitle}
                                handleEditableTitleSave={this.handleEditableTitleSave}
                                isReadOnly={this.props.model.isReadOnly}
                                data-testid="modal-editable-title-testid"
                                queryError={this.state.customNewsQueryError}
                            />
                            <ModalEditNegativeSearchQuery
                                isVisible={this.componentShouldBeVisible(
                                    this.props.model.components,
                                    'ModalEditNegativeSearchTerms'
                                )}
                                query={this.state.query}
                                defaultQuery={this.props.model.defaultCustomQuery}
                                queryError={this.state.queryError}
                                wildcardError={this.state.wildcardError}
                                resetQuery={this.state.resetQuery}
                                changeCustomSearchQuery={this.changeCustomSearchQuery}
                                resetCustomSearchQuery={this.resetCustomSearchQuery}
                                searchQueryType={this.props.model.searchQueryType}
                                language={this.props.model.clickedTerm}
                                negativityLevels={this.props.model.negativityLevels}
                                data-testid="modal-edit-negative-search-query-testid"
                            />
                            <ModalEditCustomNewsQuery
                                isVisible={this.componentShouldBeVisible(
                                    this.props.model.components,
                                    'ModalEditCustomNewsQuery'
                                )}
                                query={this.state.customNewsQuery}
                                queryError={this.state.customNewsQueryError}
                                customNewsWildcardError={this.state.customNewsWildcardError}
                                changeCustomNewsQuery={this.changeCustomNewsQuery}
                                resetCustomNewsQuery={this.resetCustomNewsQuery}
                                segment={this.props.model.segment}
                                customNewsCreatedBy={this.state.customNewsCreatedBy}
                                customNewsLnDefaultQuery={this.state.customNewsLnDefaultQuery}
                                isPopupDirty={this.state.isPopupDirty}
                                isReadOnly={this.props.model.isReadOnly}
                                data-testid="modal-edit-custom-news-query-testid"
                            />
                            <ModalAlertContentSelection
                                isVisible={this.componentShouldBeVisible(
                                    this.props.model.components,
                                    'ModalAlertContentSelection'
                                )}
                                lawSources={this.props.lawSources}
                                contentTypes={this.props.model.alertContentTypes ?? this.props.contentTypes}
                                updateContentTypes={this.updateContentTypes}
                                updateContentLanguages={this.updateContentLanguages}
                                selectedSearchQueryTypes={this.props.model.selectedSearchQueryTypes}
                                selectAllSetting={this.props.model.selectAllSetting}
                            />
                            <ModalAlertFrequency
                                isVisible={this.componentShouldBeVisible(
                                    this.props.model.components,
                                    'ModalAlertFrequency'
                                )}
                                alert={this.state.alert}
                                handleDropdownChange={this.handleDropdownChange}
                                handleNoResultsToggle={this.handleNoResultsToggle}
                            />

                            <ModalAlertGDPRMessage
                                isVisible={this.componentShouldBeVisible(
                                    this.props.model.components,
                                    'ModalAlertGDPRMessage'
                                )}
                            />
                            <ModalPreviewArticle
                                article={this.props.model.article}
                                isUboArticle={this.props.model.isUboArticle}
                                isVisible={this.componentShouldBeVisible(
                                    this.props.model.components,
                                    'ModalPreviewArticle'
                                )}
                                popupType={this.props.model.popupType}
                                category={this.props.model.category}
                                data-testid="modal-preview-article-testid"
                            />
                            <ModalEditArticleNote
                                article={this.props.model.article}
                                isVisible={this.componentShouldBeVisible(
                                    this.props.model.components,
                                    'ModalEditArticleNote'
                                )}
                                owner={this}
                                data-testid="modal-edit-article-note-testid"
                            />
                            <ModalAdHocSearch
                                isVisible={this.componentShouldBeVisible(
                                    this.props.model.components,
                                    'ModalAdHocSearch'
                                )}
                                sources={this.props.model.sources}
                                userLocks={this.props.model.userLocks}
                                lawSources={this.props.model.lawSources}
                                lawSource={this.props.model.lawSource}
                                changeLegalSource={this.changeLegalSource}
                                newsSearchSources={this.props.model.newsSearchSources}
                                changeLanguageSelection={this.changeLanguageSelection}
                                searchQueryType={this.props.model.searchQueryType}
                                adHocMasterLock={this.props.model.adHocMasterLock}
                                costCode={this.props.model.costCode}
                                costCodes={this.props.model.costCodes}
                                chooseOnlyFromCostCodesList={this.props.model.chooseOnlyFromCostCodesList}
                                isCustomCostCode={this.props.model.isCustomCostCode}
                                isCostCodeRequired={this.props.model.isCostCodeRequired}
                                changeCostCode={this.changeAdHocCostCode}
                                changeSearchNamePartyEntity={this.changeSearchNamePartyEntity}
                                searchNamePartyEntity={this.props.model.searchNamePartyEntity}
                                data-testid="modal-adHoc-search-testid"
                            />
                            <ModalUpdateCheckbox
                                isVisible={this.componentShouldBeVisible(
                                    this.props.model.components,
                                    'ModalUpdateCheckbox'
                                )}
                                isChecked={this.state.isUserPreferencesChecked}
                                disabled={!this.hasUpdatedProperties()}
                                changeUpdateCheckbox={this.changeUpdateCheckbox}
                                id="popupModal"
                                data-testid="modal-update-checkbox-testid"
                            />
                            <ModalRiskScores
                                popupType={this.props.model.popupType}
                                searchType={this.props.model.searchQueryType}
                                isVisible={this.componentShouldBeVisible(
                                    this.props.model.components,
                                    'ModalRiskScores'
                                )}
                                language={this.props.model.userLanguage}
                                data-testid="modal-risk-scores"
                            />
                            <ModalDeleteAllHistory
                                popupType={this.props.model.popupType}
                                isVisible={this.componentShouldBeVisible(
                                    this.props.model.components,
                                    'ModalDeleteAllHistory'
                                )}
                                data-testid="modal-delete-history"
                            />
                            <ModalSaveSearch
                                popupType={this.props.model.popupType}
                                isVisible={this.componentShouldBeVisible(
                                    this.props.model.components,
                                    'ModalSaveSearch'
                                )}
                                data-testid="modal-save-search"
                            />
                            {this.componentShouldBeVisible(this.props.model.components, 'ModalEntityLabel') && (
                                <ModalEntityLabel
                                    entityLabel={this.state.editEntity.displayName}
                                    displayNameError={this.state.editEntity.displayNameError}
                                    editAction={this.editEntityAction}
                                />
                            )}
                            {this.componentShouldBeVisible(this.props.model.components, 'ModalEntitySearchTerm') && (
                                <ModalEntitySearchTerm
                                    entitySearchTerm={this.state?.editEntity?.entityName}
                                    entityNameError={this.state?.editEntity?.entityNameError}
                                    editAction={this.editEntityAction}
                                />
                            )}
                            {this.componentShouldBeVisible(this.props.model.components, 'ModalEntityType') && (
                                <ModalEntityType
                                    entityType={this.state?.editEntity?.entityType}
                                    editAction={this.editEntityAction}
                                />
                            )}
                            {this.componentShouldBeVisible(this.props.model.components, 'ModalEntitySelectFilters') && (
                                <ModalEntitySelectFilters
                                    isDarkMode={this.props.isDarkMode}
                                />
                            )}
                            {this.componentShouldBeVisible(this.props.model.components, 'BatchUploadDragAndDrop') && (
                                <BatchUploadDragAndDrop
                                    intl={this.props.intl}
                                    ref={this.uploadInputRef}
                                    acceptedTypes={this.acceptedTypes}
                                    onDrop={this.handleDroppedFile}
                                    onChange={this.handleFileSelected}
                                    onClick={this.clearFileInput}
                                    onCancel={this.handleCancelSelection}
                                    fileName={this.state.uploadedFile.fileName}
                                    fileSize={this.state.uploadedFile.fileSize}
                                    isValid={this.state.uploadedFile.isValid}
                                    messages={dragAndDropMessages}
                                    isReadOnly={this.props.uploadPollingActive}
                                    isDarkMode={this.props.isDarkMode}
                                />
                            )}
                            {this.componentShouldBeVisible(this.props.model.components, 'ModalUploadEntities') && (
                                <ModalUploadEntities
                                    handleChangeView={this.handleChangeView}
                                    createNewView={this.createNewView}
                                    views={this.props.model.views}
                                    disabled={this.props.disabled}
                                />
                                )}

                            {this.componentShouldBeVisible(this.props.model.components, 'ShareViewSearchbar') && (
                                <ShareViewSearchbar
                                    disabled={!this.state.isShareViewSearchbarEnabled}
                                    setShareViewUserSearchText={this.setShareViewUserSearchText}
                                    setSearchbarEnabled={this.setIsShareViewSearchbarEnabled}
                                />
                            )}
                            {this.componentShouldBeVisible(this.props.model.components, 'ShareViewUserSelect') && (
                                <ShareViewUserSelect
                                    selectedViewId={this.props.model.selectedViewId}
                                    closeModal={this.closeModal}
                                    setSearchbarEnabled={this.setIsShareViewSearchbarEnabled}
                                    searchbarEnabled={this.state.searchbarEnabled}
                                    shareViewSearchbarText={this.state.shareViewSearchbarText}
                                />
                            )}
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        model: state.popupModel,
        reports: state.reportBuilder.reports,
        contentTypes: state.user.preferences.generalSettings.contentTypes,
        dateRanges: state.user.preferences.generalSettings.dateRanges,
        lawSources: state.user.preferences.generalSettings.lawSources,
        lawSource: state.user.preferences.generalSettings.legalSources.defaultDateSource,
        companyCheck: state.user.preferences.companyCheck,
        personCheck: state.user.preferences.personCheck,
        modifiedByUser: state.user.preferences.modifiedByUser,
        searchNamePartyEntity: state.user.preferences.generalSettings.legalSources.searchNamePartyEntity,
        userLocks: state.user.preferences.userLocks,
        areCombinedReportsAvailable: state.user.areCombinedReportsAvailable,
        articleNavigation: state.articleNavigation,
        selectedArticlesInfo: state.articlesManager.selectedArticlesInfo,
        isSnapshotVisible: state.user.preferences.generalSettings.showSnapshot,
        isSnapshotDisabled: state.popupModel.isSnapshotDisabled,
        // Entity View Upload Entities popup
        appSettings: state.user.appSettings,
        breadcrumbs: state.breadcrumbs,
        timezone: state.user.timezone,
        language: state.user.preferences.language,
        startEachArticleOnNewPage: state.user.preferences.startEachArticleOnNewPage,
        generalSettings: state.user.preferences.generalSettings,
        userOfferings: state.user.userOfferings,
        sources: state.user.preferences.generalSettings.contentTypes,
        personCheckPreferences: state.user.preferences.personCheck,
        companyCheckPreferences: state.user.preferences.companyCheck,
        adHocSearch: state.adHocSearch,
        newsDateRange: state.user.preferences.generalSettings.newsSources?.dateRange,
        legalDateRange: state.user.preferences.generalSettings.legalSources?.dateRange,
        companyDateRange: state.user.preferences.generalSettings.companySources?.dateRange,
        billingId: state.investigation.billingId,
        popupModel: state.popupModel,
        isBatchContentTypesCollapsed: state.user.preferences.generalSettings.isBatchContentTypesCollapsed,
        userEmail: state.user.email,
        entityUploadLimit: state.user.preferences.generalSettings.entityUploadLimit,
        isMultipleEntitiesTrial: state.user.appSettings.isMultipleEntitiesTrial,
        uploadPollingActive: state.entityViewInformation.uploadPollingActive,
        multipleEntitiesDownloadReportsPollingActive: state.entityViewInformation.downloadReportsPollingActive,
        shareViewSearchbarText: state.shareViewSearchbarText,
        viewId: state.user.preferences.screeningEntity.lastSelectedView,
        isAnonymized: state.user.isAnonymized,
        isDarkMode: state.user.preferences.generalSettings.isDarkMode,
        displayRiskScores: state.user.preferences.generalSettings.displayRiskScores,
        sortingInfo: state.user.preferences.screeningEntity,
        fuzzyOn: state.user.preferences.personCheck.fuzzyOn,
    };
};

const mapDispatchToProps = function (dispatch) {
    return bindActionCreators(
        {
            hideModal: popupModelActions.closePopup,
            setComments: popupModelActions.setComments,
            setDocType: popupModelActions.setDocType,
            setFileName: popupModelActions.setFileName,
            setEmailAddress: popupModelActions.setEmailAddress,
            setEmailSubject: popupModelActions.setEmailSubject,
            setEmailComments: popupModelActions.setEmailComments,
            setReportList: popupModelActions.setReportListIds,
            setResultListType: popupModelActions.setResultListType,
            updateDateRange: popupModelActions.updateAdHocDateRange,
            addAdHocSearchContentType: popupModelActions.addAdHocSearchContentType,
            deleteAdHocSearchContentType: popupModelActions.deleteAdHocSearchContentType,
            updateAdhocProperties: popupModelActions.updateAdhocProperties,
            toggleButtonStatusByType: popupModelActions.toggleButtonStatusByType,
            togglePrimaryButtonsStatus: popupModelActions.togglePrimaryButtonsStatus,
            updateAllButtonStatus: popupModelActions.updateAllButtonStatus,
            updateCheckedLanguagesUponKey: popupModelActions.updateCheckedLanguagesUponKey,
            updateCustomNewsQueryTitle: popupModelActions.updateCustomNewsQueryTitle,
            updateSearchNamePartyEntity: popupModelActions.updateSearchNamePartyEntity,
            updateDeliveryContentOptions: popupModelActions.updateDeliveryContentOptions,
            updateModalComponents: popupModelActions.updateModalComponents,
            toggleDeliveryOptionsDisplay: popupModelActions.toggleDeliveryOptionsDisplay,
            reportTypeChange: popupModelActions.reportTypeChange,
            clearPopup: popupModelActions.clearPopup,
            toggleDeliveryOptionsDisplayPreference: actions.toggleDeliveryOptionsDisplayPreference,
            updateModifiedByUser: actions.updateModifiedByUser,
            updateAdHocProperty: adHocSearchActions.updateAdHocProperty,
            updateBatchContentTypesVisibility: userPreferencesActions.updateBatchContentTypesVisibility,
            setPopupModel: popupModelActions.setPopupModel,
            setUpdateEntitiesColumnsFromContentTypes: mainActions.setUpdateEntitiesColumnsFromContentTypes,
            setUploadPollingEntityView: mainActions.setUploadPollingEntityView,
            addNewBatchId: mainActions.addNewBatchId,
        },
        dispatch
    );
};

const PopupModalTest: void = injectIntl(PopupModal);
export { PopupModalTest };
export default (compose(
    connect(mapStateToProps, mapDispatchToProps),
    withAppContext,
    injectIntl,
    withRouter,
)(PopupModal): AbstractComponent <Props, State>);
