// @flow
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { DefaultLoader } from '@reusable/Table/components/defaults';
import UboSimpleGraph from '@reusable/UBO/uboSimpleGraph';
import UboGraph from '@reusable/UBO/uboGraph';
import { uboApiMap, uboApiSimpleMap } from '@sagas/helpers/uboHelper';
import UboTable from '@reusable/UBO/UboTable';
import Tabs from '@reusable/TabbedContent/Tabs';
import withUboTradeUpWarningNote from '@reusable/UBO/withUboTradeUpWarningNote';
import { withUboDegreeOfSeparationWarningNote } from '@reusable/UBO/withUboDegreeOfSeparationWarningNote';
import { withDocumentTabsViewTitle } from '@reusable/HOC/withDocumentTabsViewTitle';
import { compose } from 'redux';
import { tagGraph } from '@sagas/helpers/uboGraphHelpers';
import type {
    sortingAndFilteringType,
    UboDocumentViewArticleType,
    UboPersistedNodeType,
} from './redux/flow/UboDocument.type.guards';
import utils from '@utils/utilities';
import withThemeSwitcherContext from '@scripts/contexts/hoc/withThemeSwitcher';

type State = {
    activeTab: number,
};

type Props = {
    uboArticle: UboDocumentViewArticleType,
    updateSortingAndFiltering: (args: Object) => void,
    persistedNodes: Array<UboPersistedNodeType>,
    duns: string,
    articleTitle: string,
    isTradeUp: boolean,
    onPersistNodes: (args: Array<UboPersistedNodeType>) => void,
    filtering: Object,
    documentPreview?: boolean,
    filteringDropdownSelected?: number,
    isDarkMode: boolean,
};

type DefaultProps = {
    persistedNodes: Array<UboPersistedNodeType>,
    onPersistNodes: null | ((args: Array<UboPersistedNodeType>) => void),
};

class UboDocumentView extends React.Component<Props, State> {
    static defaultProps: DefaultProps = {
        persistedNodes: [],
        onPersistNodes: null,
    };

    constructor(props: Props) {
        super(props);

        this.state = {
            activeTab: 0,
        };
    }

    handleChangeTab: (number) => void = (activeTab: number) => {
        this.setState({ activeTab });
    };

    handleUpdateSortingAndFiltering: (sortingAndFilteringType) => void = (args: sortingAndFilteringType) => {
        this.props.updateSortingAndFiltering(args);
    };

    render(): React.Node {
        let extendedGraph = {};
        let uboSimpleGraphData = {};
        let uboCreatedDate = {};
        if (this.props.uboArticle && this.props.uboArticle.links) {
            extendedGraph = tagGraph(uboApiMap(this.props.uboArticle));

            const nodes = this.props.uboArticle.nodes.map((node) => ({
                ...node,
                persisted: !!this.props.persistedNodes.find((n) => n.id === node.id),
            }));

            const uboSimpleData = {
                ...this.props.uboArticle,
                nodes,
            };

            uboSimpleGraphData = uboApiSimpleMap(uboSimpleData);
            uboCreatedDate = this.props.uboArticle.createdDate;
        }

        const uboTabs = [
            {
                label: <FormattedMessage id={'UboDocumentView.Tabs.TreeView'} />,
                content: (
                    <UboSimpleGraph
                        data={uboSimpleGraphData}
                        duns={this.props.duns}
                        persistedNodes={this.props.persistedNodes}
                        onPersistNodes={this.props.onPersistNodes}
                        filtering={this.props.filtering}
                        documentPreview={this.props.documentPreview}
                        updateSortingAndFiltering={this.handleUpdateSortingAndFiltering}
                    />
                ),
            },
            {
                label: <FormattedMessage id={'UboDocumentView.Tabs.ExpandedTreeView'} />,
                content: (
                    <UboGraph
                        data={extendedGraph}
                        dun={this.props.duns}
                        filtering={this.props.filtering}
                        updateSortingAndFiltering={this.handleUpdateSortingAndFiltering}
                        documentPreview={this.props.documentPreview}
                        isDarkMode={this.props.isDarkMode}
                    />
                ),
            },
            {
                label: <FormattedMessage id={'UboDocumentView.Tabs.TableView'} />,
                content: (
                    <UboTable
                        data={this.props.uboArticle}
                        filtering={this.props.filtering}
                        documentPreview={this.props.documentPreview}
                        key={this.props.filtering.filteringDropdownSelected}
                        updateSortingAndFiltering={this.handleUpdateSortingAndFiltering}
                    />
                ),
            },
        ];

        return !this.props.uboArticle.links ? (
            <DefaultLoader />
        ) : (
            <div>
                <div className="tabs-wrapper">
                    <Tabs tabs={uboTabs} activeTabIndex={this.state.activeTab} onChange={this.handleChangeTab} />
                </div>
                {uboTabs.map((tab, tabIndex) => (
                    <div
                        className={this.state.activeTab === tabIndex ? 'visible_graph' : 'hidden_graph'}
                        key={`tab_${tabIndex}`}
                    >
                        {tab.content}
                    </div>
                ))}
                <div className="center">
                    <FormattedMessage
                        id={'UboDocumentView.retrievalDateMessage'}
                        values={{
                            time: <span> {utils.formatTimeWithHoursAndMinutes(uboCreatedDate)} </span>,
                            date: <span>{utils.formatDateDayOfMonthDateTime(uboCreatedDate)}</span>,
                        }}
                    />
                </div>
            </div>
        );
    }
}

export { UboDocumentView as TestUboDocumentView };

export default (compose(
    withThemeSwitcherContext,
    withUboTradeUpWarningNote,
    withUboDegreeOfSeparationWarningNote,
    withDocumentTabsViewTitle
)(UboDocumentView): React.AbstractComponent<Props>);
