import React, { useEffect, useState } from "react";
import { ReactGrid } from '@silevis/reactgrid';
import { hashHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import { SanctionsCellTemplate } from './cell-types/SanctionsCell';
import { SanctionsHeaderTemplate } from './cell-types/SanctionsHeader';
import { SanctionsResultsTemplate } from './cell-types/SanctionsResults';
import { SanctionsRiskTemplate } from './cell-types/SanctionsRisk';

import { getSanctionColumnMap, getSanctionsColumns, getSanctionsRows } from './sanctionsHelper';
import { CATEGORY_NAMES, LAUNCHED_SEARCH_FROM, SANCTIONS_RISK_SCORE } from "@scripts/constants";
import costCodeUtils from '@scripts/utils/costCodeUtils';
import mainActions from '@scripts/pages/Main/Main.actions';
import articleNavigationActions from '@scripts/pages/MainSearch/redux/ArticleNavigation.actions';
import articlesManagerActions from '@scripts/pages/MainSearch/redux/ArticlesManager.actions';
import categoryUtils from '@scripts/utils/categoryUtils';
import * as investigationEvents from '@sagas/constants/investigationConstants';
import { withRouter } from 'react-router';
import withInvestigationEvents from '../../../hoc/WithInvestigationEvents';
import InvestigationEventsProvider from '../../../providers/InvestigationEventsProvider';
import { compose } from 'redux';
import { SanctionsDocumentMetadataTemplate } from './cell-types/SanctionsDocumentMetadata';

const SanctionsTable = ({ data, ...props }) => {
    const dispatch = useDispatch();
    const searchParams = useSelector(state => state.searchParams);
    const investigation = useSelector(state => state.investigation);
    const searchResults = useSelector(state => state.searchResults);
    const isSnapshotVisible = useSelector(state => state.user.preferences.generalSettings.showSnapshot);

    const [sortColumn, setSortColumn] = useState(null);
    const [sortDirection, setSortDirection] = useState(null);
    const [sortedData, setSortedData] = useState(data);
    const [hoveredRow, setHoveredRow] = useState(null);

    useEffect(() => {
        setSortedData(data); // Update documents when snapshot is reset, otherwise stale data is shown
    }, [data]);

    const onDocumentSnippetClick = (documentId) => {
        const payload = {
            lni: documentId,
            searchQuery: searchParams.query,
            searchQueryType: searchParams.searchType,
            category: CATEGORY_NAMES.SANCTIONS_WATCHLIST,
            billingId: investigation.billingId,
            costCode: costCodeUtils.getCostCode(),
        };

        const article = {
            id: payload.lni
        }

        dispatch(mainActions.searchLaunchedFrom(LAUNCHED_SEARCH_FROM.SNAPSHOT_PAGE));
        dispatch(articlesManagerActions.clearSelectedArticle());

        dispatch(articlesManagerActions.selectArticle(
            article,
            null,
            searchParams.query,
            searchParams.searchType
        ));

        dispatch(
            articleNavigationActions.initArticleNavigation({
                index: null,
                list: [],
                startPage: null,
                pageSize: null,
                totalCount: null,
                params: {},
                isShowMoreActive: false,
            })
        );

        const categoryData = searchResults[payload.category];
        const { filteredFields } = props.location.query;

        if (categoryUtils.isVisitedCategory(categoryData, investigation)) {
            props.investigationEventsProviderProps.sendInvestigationEvent({
                type: investigationEvents.EVENT_SUBTYPES.revisited,
                payload: { categoryData },
            });
        } else {
            props.investigationEventsProviderProps.sendInvestigationEvent({
                type: investigationEvents.ACTION_TYPES.loadDocuments,
                payload: { categoryName: payload.category, filteredFields, isSnapshotVisible },
            });
        }

        const linkTo = `/document?query=${encodeURIComponent(payload.searchQuery)}&category=${payload.category
            }&type=${payload.searchQueryType}&id=${payload.lni}&costCode=${payload.costCode}`;

        hashHistory.push(linkTo);
    };

    const handleSortClick = (column) => {
      const direction = (sortDirection === 'asc') ? 'desc' : 'asc';

      setSortColumn(column);
      setSortDirection(direction);

      setSortedData([...sortedData].sort((a,b) => {
        if (a[column] == null) return 1; // sort nulls to end
        if (b[column] == null) return -1; // sort nulls to end

        if (column === 'riskLevel') {
          return direction === 'asc' ?
            SANCTIONS_RISK_SCORE[b[column]] - SANCTIONS_RISK_SCORE[a[column]]
            : SANCTIONS_RISK_SCORE[a[column]] - SANCTIONS_RISK_SCORE[b[column]];

        } else {
          return direction === 'asc' ? b[column].localeCompare(a[column])
            : a[column].localeCompare(b[column]);
        }
      }));
    };

    const columnMap = getSanctionColumnMap(handleSortClick, sortColumn, sortDirection ?? '');
    const columns = getSanctionsColumns();
    const rows = getSanctionsRows(sortedData, columns, columnMap, onDocumentSnippetClick, hoveredRow, setHoveredRow);

    return (
        <div className="sanctions-table-container">
            <ReactGrid
                columns={columns}
                rows={rows}
                columnMap={columnMap}
                customCellTemplates={{
                    sanctionsHeader: new SanctionsHeaderTemplate(),
                    sanctionsCell: new SanctionsCellTemplate({hoveredRow}),
                    sanctionsResults: new SanctionsResultsTemplate({hoveredRow}),
                    sanctionsDocumentMetadata: new SanctionsDocumentMetadataTemplate({hoveredRow}),
                    sanctionsRisk: new SanctionsRiskTemplate({hoveredRow}),
                }}
                stickyLeftColumns={1}
                stickyTopRows={1}
                enableRowSelection={false}
                enableColumnSelection={false}
                enableRangeSelection={false}
            />
        </div>
    );
};

export default (compose(
    withRouter,
    withInvestigationEvents(InvestigationEventsProvider)
)(SanctionsTable));
