import update from 'immutability-helper';
import SearchUtils from '@MainSearch/SearchUtils';

import {
    RESET_ALL_POSTFILTERS_CONFIG_VALUES,
    SET_CATEGORY_POSTFILTERS,
    UPDATE_POSTFILTER_CONFIG_TREE_STATE,
    UPDATE_POSTFILTER_CONFIG_VALUES,
    UPDATE_POSTFILTER_CONFIG_VALUES_LIST,
    UPDATE_POSTFILTER_SORT,
} from './PostFilterConfiguration.actions';
import { generateDefaultPostFilterConfig } from '@reduxStore';
import { COMPANY_SEARCH, PERSON_SEARCH, PERSONS_SEARCH } from '@constants';
import { cloneDeep, isEmpty } from 'lodash';
import errorUtils from '@utils/errors/error-utils';
import utils from '@utils/utilities';

const APP_MODULE = 'postFilterConfiguration.reducers';

function postFilterConfiguration(state = {}, action) {
    switch (action.type) {
        case UPDATE_POSTFILTER_CONFIG_VALUES: {
            let { categoryName, postFilterType, values, enabled } = action.payload;

            if (!categoryName || !postFilterType || !values) {
                errorUtils.logAppError(
                    `Missing parameter in ${UPDATE_POSTFILTER_CONFIG_VALUES}`,
                    { categoryName: categoryName, postFilterType: postFilterType, value: values, enabled: enabled },
                    APP_MODULE
                );
                return state;
            }

            const componentType = state[categoryName][postFilterType].componentType;

            const { sort } = cloneDeep(state[categoryName][postFilterType]);
            if (sort) {
                values = utils.sortPostFilterValues(values, sort);
            }
            values = utils.moveUnclassifiedToTheEnd(values);

            return update(state, {
                [categoryName]: {
                    [postFilterType]: {
                        values: { $set: values },
                        enabled: { $set: enabled },
                        selected: {
                            $set: SearchUtils.getPostfilterSelection({ componentType, values, type: postFilterType }),
                        },
                    },
                },
            });
        }

        case UPDATE_POSTFILTER_CONFIG_VALUES_LIST: {
            let newState = { ...state };
            if (!action || !action?.payload || !action?.payload.length) {
                return state;
            }
            action.payload.forEach((elem) => {
                let { categoryName, postFilterType, values, enabled } = elem;

                if (
                    !categoryName ||
                    !postFilterType ||
                    !values ||
                    !state ||
                    !state[categoryName] ||
                    !state[categoryName][postFilterType]
                ) {
                    errorUtils.logAppError(
                        `Missing parameter in ${UPDATE_POSTFILTER_CONFIG_VALUES_LIST}`,
                        { categoryName: categoryName, postFilterType: postFilterType, value: values, enabled: enabled },
                        APP_MODULE
                    );
                    return state;
                }

                const componentType = state[categoryName][postFilterType].componentType;

                let { sort } = cloneDeep(state[categoryName][postFilterType]);
                if (sort) {
                    values = utils.sortPostFilterValues(values, sort);
                }
                newState = update(newState, {
                    [categoryName]: {
                        [postFilterType]: {
                            values: { $set: values },
                            enabled: { $set: enabled },
                            selected: {
                                $set: SearchUtils.getPostfilterSelection({
                                    componentType,
                                    values,
                                    type: postFilterType,
                                }),
                            },
                        },
                    },
                });
            });
            return { ...newState };
        }

        case UPDATE_POSTFILTER_SORT: {
            let { categoryName, postFilterType, sort } = action.payload;

            return update(state, {
                [categoryName]: {
                    [postFilterType]: {
                        sort: { $set: sort },
                    },
                },
            });
        }

        case SET_CATEGORY_POSTFILTERS: {
            let { category, postFilters } = action.payload;
            if (!category || !postFilters || isEmpty(postFilters)) {
                errorUtils.logAppError(
                    'Missing parameter in ' + SET_CATEGORY_POSTFILTERS,
                    { categoryName: category, postFilters: postFilters },
                    APP_MODULE
                );
                return state;
            }
            return update(state, {
                [category]: { $set: postFilters },
            });
        }

        case RESET_ALL_POSTFILTERS_CONFIG_VALUES:
            let { searchQueryType, subjectAndIndustryAvailable, newFuzzyEnabled, suggestedNamesEnabled } =
                action.payload;
            if (
                searchQueryType !== PERSON_SEARCH &&
                searchQueryType !== PERSONS_SEARCH &&
                searchQueryType !== COMPANY_SEARCH
            )
                return state;
            return generateDefaultPostFilterConfig(
                searchQueryType,
                subjectAndIndustryAvailable,
                newFuzzyEnabled,
                suggestedNamesEnabled
            );

        case UPDATE_POSTFILTER_CONFIG_TREE_STATE:
            let { categoryName, searchFieldName } = action.payload;
            let postFilterType = searchFieldName.charAt(0).toUpperCase() + searchFieldName.slice(1);

            return update(state, {
                [categoryName]: {
                    [postFilterType]: {
                        hasFullTreeLoaded: { $set: true },
                    },
                },
            });
    }

    return state;
}

export default postFilterConfiguration;
