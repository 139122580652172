import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Loader } from 'semantic-ui-react';
import Button from '@reusable/Buttons/Button';
import AlertApi from '@Alerts/AlertsApi.api';
import { hashHistory, Link } from 'react-router';
import utils from '@utils/utilities';
import reduxStore from '@reduxStore';
import alertsManagerActions from '@ManageAlerts/redux/ManageAlerts.actions';
import backgroundActions from '@reusable/BackgroundMessage/redux/BackgroundMessages.actions';
import { ERRORS, MESSAGE_TIMEOUT } from '@constants';
import { FormattedMessage } from 'react-intl';

const MODE_SAVING = 'btn-saving';
const MODE_SAVED_MSG = 'btn-saved-msg';
const MODE_NOT_SAVED = 'btn-not-saved';

export default class SaveAlertButton extends Component {
    static propTypes = {
        createAlertObject: PropTypes.func,
        alertId: PropTypes.string,
    };

    static defaultProps = {
        alertId: null,
    };

    constructor(props) {
        super(props);

        this.state = {
            saving: false,
            isSavedMessageVisible: false,
        };

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        const buttonMode = this.getMode();

        if (buttonMode !== MODE_SAVING && buttonMode !== MODE_SAVED_MSG) {
            let alert = this.props.createAlertObject();
            if (alert) {
                this.setState(
                    {
                        saving: true,
                    },
                    () => {
                        if (this.props.alertId) {
                            const editedAlert = { ...alert, id: this.props.alertId };
                            AlertApi.editAlert(editedAlert, this.props.alertId)
                                .then((response) => {
                                    this.setState(
                                        {
                                            saving: false,
                                            isSavedMessageVisible: true,
                                        },
                                        () => {
                                            hashHistory.push('/manage-alerts');
                                            reduxStore.dispatch(backgroundActions.clearBackgroundMessages());
                                            reduxStore.dispatch(
                                                backgroundActions.setSuccessBackgroundMessages({
                                                    message: 'Alerts.editAlert.alertEdited',
                                                    messageParameters: alert.name,
                                                    isVisible: true,
                                                })
                                            );
                                            setTimeout(() => {
                                                reduxStore.dispatch(backgroundActions.clearBackgroundMessages());
                                            }, MESSAGE_TIMEOUT);
                                        }
                                    );
                                    return response;
                                })
                                .catch((error) => {
                                    this.handleError(error.response.text, 'Alerts.editAlert.alertEditError');
                                });
                        } else {
                            AlertApi.saveAlert(alert, this.props.alertId)
                                .then((response) => {
                                    reduxStore.dispatch(alertsManagerActions.setIsNewAlert(true));
                                    this.setState(
                                        {
                                            saving: false,
                                            isSavedMessageVisible: true,
                                        },
                                        () => {
                                            hashHistory.push('/manage-alerts');
                                            reduxStore.dispatch(backgroundActions.clearBackgroundMessages());
                                            reduxStore.dispatch(
                                                backgroundActions.setSuccessBackgroundMessages({
                                                    message: 'Alerts.createAlert.alertCreation',
                                                    messageParameters: alert.name,
                                                    isVisible: true,
                                                })
                                            );
                                            setTimeout(() => {
                                                reduxStore.dispatch(backgroundActions.clearBackgroundMessages());
                                            }, MESSAGE_TIMEOUT);
                                        }
                                    );

                                    return response;
                                })
                                .catch((error) => {
                                    this.handleError(error.response.text, 'Alerts.createAlert.alertCreationError');
                                });
                        }
                    }
                );
            }
        }
    }

    handleError(errorType, messageID) {
        this.setState(
            {
                saving: false,
                isSavedMessageVisible: false,
            },
            () => {
                let userPreferencesLink = '/user-preferences';
                if (errorType === ERRORS.INVALID_QUERY) {
                    utils.showNotificationsMessage({
                        messageText: 'Alerts.negativeQueryError',
                        terms: {
                            term: (
                                <Link className="navigation-link" to={userPreferencesLink}>
                                    <FormattedMessage id="HeaderFooterNav_CoreFunctionality_Dropdown_nav.user.userPreferences" />
                                </Link>
                            ),
                        },
                        messageType: 'system-error',
                    });
                } else {
                    utils.showNotificationsMessage({
                        messageText: messageID,
                        messageType: 'system-error',
                    });
                }
            }
        );
    }

    getMode() {
        if (this.state.saving) {
            return MODE_SAVING;
        } else if (this.state.isSavedMessageVisible) {
            return MODE_SAVED_MSG;
        } else {
            return MODE_NOT_SAVED;
        }
    }

    render() {
        let buttonContent;
        const buttonMode = this.getMode();

        switch (buttonMode) {
            case MODE_SAVED_MSG:
                buttonContent = (
                    <div className="save-alert">
                        <div className="la-DeliveryComplete icon"></div>
                        <div className="save-alert-name">
                            <FormattedMessage id={'General_CoreFunctionality_UIText_general.saved'} />
                        </div>
                    </div>
                );
                break;
            case MODE_NOT_SAVED:
                buttonContent = (
                    <div className="save-alert">
                        <div className="save-alert-name">
                            <FormattedMessage id={'General_CoreFunctionality_UIText_general.save'} />
                        </div>
                    </div>
                );
                break;
            case MODE_SAVING:
                buttonContent = (
                    <div className="save-alert">
                        <Loader size={'medium'} inline="centered" active inverted />
                    </div>
                );
                break;
            default:
                break;
        }

        const buttonClass = 'button-primary-sm notranslate ' + buttonMode;

        return (
            <Button className={buttonClass} data-testid="create-alert-save-button" onClick={this.handleClick}>
                {buttonContent}
            </Button>
        );
    }
}
