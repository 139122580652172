import { CLEAR_ALL_SELECTED_ALERTS, CLEAR_SELECTED_ALERTS, SELECT_ALERTS } from './ManageSelectedAlerts.actions';
import update from 'immutability-helper/index';
import { cloneDeep, differenceBy } from 'lodash';

function selectedAlerts(state = {}, action) {
    let alerts = [];
    let alertType;

    switch (action.type) {
        case SELECT_ALERTS:
            alerts = cloneDeep(action.payload.alerts);
            alertType = action.payload.alertType;
            let alertsToBeAdded = state[alertType];
            alerts.forEach((alert) => {
                let existInSelection = state[alertType].find((item) => {
                    return item.id === alert.id;
                });
                if (!existInSelection) {
                    alert.isChecked = true;
                    alertsToBeAdded.push(alert);
                }
            });
            return update(state, { [alertType]: { $set: alertsToBeAdded } });

        case CLEAR_SELECTED_ALERTS:
            alerts = cloneDeep(action.payload.alerts);
            alertType = action.payload.alertType;
            let alertsToBeRemoved = [];

            alerts.forEach((alert) => {
                alert.isChecked = false;
                alertsToBeRemoved.push(alert);
            });

            return update(state, { [alertType]: { $set: differenceBy(state[alertType], alertsToBeRemoved, 'id') } });

        case CLEAR_ALL_SELECTED_ALERTS:
            alertType = action.payload.alertType;
            return update(state, { [alertType]: { $set: [] } });
        default:
            return state;
    }
}

export default selectedAlerts;
