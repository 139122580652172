import utils from '@utils/utilities';
import costCodeUtils from '@utils/costCodeUtils';

export const theUbo = (state) => state.ubo.company;

export const theUboList = (state) => state.ubo.company.list;

export const theUboCount = (state) => state.ubo.company.count;

export const theUboSelected = (state) => state.ubo.company.selected;

export const theUboSelectedDunses = (state) => state.ubo.company.selected.map((company) => company.duns);

export const theUboSelectedTitles = (state) => state.ubo.company.selected.map((company) => company.title);

export const costCode = () => costCodeUtils.getCostCode();

export const billingId = (state) => state.investigation.billingId;

export const theUboQuery = (state) => state.ubo.company.query;

export const isItemSelected = (item) => (state) => state.ubo.company.selected.indexOf(item) > -1;

export const theSelectedItem = (item) => (state) => state.ubo.company.selected.find(item);

export const theSelectedItemIndex = (item) => (state) => state.ubo.company.selected.indexOf(item);

export const theMemoFor = (searchTerm) => (state) => state.ubo.company.memo[searchTerm];

export const isUboEnabled = (state) => utils.isUboEnabled(state);

export const theUboCategoryPostFilters = (state, category) => state.ubo.company.postFilters[category];

export const theUboPostFilters = (state) => state.ubo.company.postFilters;

export const theUboDocViewDeliveryInfo = (state) => state.ubo.docViewDeliveryInfo;

export const isUboCategoryChecked = (state) => state.searchResults.ubo.enabled;

export const historyCategoryName = (state) => state.historyCategory.categoryName;

export const isSnapshotEnabled = (state) => state.user.preferences.generalSettings.showSnapshot;

export const entityId = (state) => state.searchState.entityId;

export const user = (state) => state.user;

export const investigation = (state) => state.investigation;

export const useNewResearchSummary = (state) => state.user.useNewResearchSummary;

export const contentTypes = (state) => state.user.preferences.generalSettings.contentTypes;

export const searchResults = (state) => state.searchResults;

export const postFiltersConfig = (state) => state.postFilterConfiguration;

export const searchParams = (state) => state.searchParams;

export const popupModel = (state) => state.popupModel;

export const viewId = (state) => state.user.preferences.screeningEntity.lastSelectedView;