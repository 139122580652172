import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import InputComponent from './InputComponent';
import formatRichMessage from '@utils/formatRichMessage';

class RadioWrapperComponent extends Component {
    handleChange = (options, option, e) => {
        // for now just pass the handler up, any transformations should happen here

        this.props.onChange(this.props.parentOption, option, e);
    };

    render() {
        const options = this.props.options.map((option) => ({
            ...option,
            label: formatRichMessage({ id: 'Delivery.options.' + option.key.toLowerCase() }, this.props.intl),
            type: 'radio',
        }));

        return (
            <div className={this.props.className}>
                <InputComponent
                    {...this.props}
                    options={options}
                    onChange={this.handleChange}
                    fieldId={'delivery-options'}
                />
            </div>
        );
    }
}

export default injectIntl(RadioWrapperComponent);
