import React from 'react';
import TriggerComponent from '@reusable/Popover/TriggerComponent';
import Dropdown, { DropdownRow } from '@reusable/Dropdown/Dropdown';
import { FormattedMessage } from 'react-intl';

const ActionButtonPopover = ({ disabled, id, testid = '', classNames, popover, onClick, callback }) => {
    return (
        <TriggerComponent
            disabled={disabled || popover.isOpen}
            id={id}
            data-testid={testid}
            callback={callback}
            render={(params) => (
                <Dropdown
                    className={popover.dropdownClassName}
                    align="left"
                    closeOnMouseLeave={false}
                    shouldExitOnEscape={false}
                    positionAuto={true}
                    testId={`qa-refresh-dropdown`}
                    {...params}
                >
                    {popover.dropdownRows.map((dropdownRow) => (
                        <DropdownRow
                            {...params}
                            key={'qa-batch-screening-action_' + dropdownRow.id}
                            id={'qa-batch-screening-action_' + dropdownRow.id}
                            value={'qa-batch-screening-action_' + dropdownRow.id}
                            data-testid={'qa-batch-screening-action_' + dropdownRow.id}
                            testId={'qa-batch-screening-action_' + dropdownRow.id}
                            onClick={() => {
                                dropdownRow.onClick();
                                params.onClose();
                            }}
                        >
                            <span>
                                <FormattedMessage id={dropdownRow.formatMessageId} />
                            </span>
                        </DropdownRow>
                    ))}
                </Dropdown>
            )}
        >
            <button
                type="button"
                className="icon-box"
                id={id}
                data-testid={testid}
                disabled={disabled}
                onClick={onClick}
            >
                {
                    classNames.split(' ')
                        .map((className, index) => className !== 'disabled'
                            ? (<span key={index} className={`${className}${classNames.includes('disabled') ? ' disabled' : ''}`} />)
                            : '')
                }
            </button>
        </TriggerComponent>
    );
};

export default ActionButtonPopover;
