import React from 'react';
import AlertMessage from '@reusable/AlertMessage/AlertMessage';

const ModalAlertGDPRMessage = ({ isVisible }) => {
    return isVisible ? (
        <div className="gdpr-alert-wrapper">
            <AlertMessage ids={['Alerts.createAlert.GDPR']} />{' '}
        </div>
    ) : null;
};

export default ModalAlertGDPRMessage;
