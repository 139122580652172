export const SET_SCROLL = 'SET_SCROLL';

let scrollingPageActions = {
    setScrollY: function (page, scrollY) {
        return {
            type: SET_SCROLL,
            payload: { page, scrollY },
        };
    },
};

export default scrollingPageActions;
