//@flow
import { reduxStore } from '@reduxStore';
import reportBuilderActions from './redux/ReportBuilder.action';
import ReportBuilderApi from './ReportBuilderMain.api';
import utils from '@utils/utilities';
import { CATEGORY_NAMES } from '@constants';
import errorUtils from '@utils/errors/error-utils';
import ArticlesUtils from '@MainSearch/ArticlesUtils';
import reportBuilderUtils from './utils/ReportBuilderUtils';
import currentReportActions from './redux/CurrentReport.actions';
import backgroundActions from '@reusable/BackgroundMessage/redux/BackgroundMessages.actions';
import type {
    Category,
    CombinedReportType,
    DeliveryData,
    ExpandedRegularReportType,
    RegularReportType,
    Report,
    ResearchSummaryEntry,
    CategoryOrder,
    GeneralReportType,
    GenericReportType, CategoryDeleteRequest
} from "./redux/flow/ReportBuilder.type.guards";
import { isNoDocumentNote } from '@utils/deliveryService';

const APP_MODULE = 'ReportBuilderService';

export const UPDATE_CATEGORIES_ORDER = 'categoriesOrder';
export const UPDATE_TITLE = 'title';

function sortReports(reports) {
    reports = reports || [];
    reports.sort((a, b) => {
        if (a.lastUpdated < b.lastUpdated) {
            return -1;
        }
        if (a.lastUpdated > b.lastUpdated) {
            return 1;
        }
        return 0;
    });
    return reports.reverse();
}

function getArticlesByReportIdRecursively(reportId, pageNumber, pageSize, loadedSnippets) {
    return ReportBuilderApi.getReportSnippets(reportId, pageNumber, pageSize).then((reportSnippets) => {
        reportSnippets = reportSnippets || [];
        if (reportSnippets.length > 0) {
            loadedSnippets.push(...reportSnippets);
            if (reportSnippets.length < pageSize) {
                return loadedSnippets;
            } else {
                return getArticlesByReportIdRecursively(reportId, pageNumber + 1, pageSize, loadedSnippets);
            }
        } else {
            return loadedSnippets;
        }
    });
}

function getReports(pageNumber, pageSize, praOn, timezone, prReportOrder) {
    return ReportBuilderApi.getReportsByUser(pageNumber, pageSize, praOn, timezone, prReportOrder)
        .then((response) => {
            let { reports, count } = response;
            reports.forEach((report) => {
                let availableDownloads =
                    report.reportDelivery && report.reportDelivery.length > 0 ? report.reportDelivery : [];
                report.availableDownloads = availableDownloads;
                report.latestDocument =
                    availableDownloads.length === 0 ? null : availableDownloads[availableDownloads.length - 1];
            });
            return { reports, count };
        })
        .catch(() => {
            utils.showNotificationsMessage({
                messageText: 'ReportBuilderPage_Errors.reportsCount',
                messageType: 'system-error',
            });
            return { reports: [], count: 0 };
        });
}

const ReportBuilderService = {
    async loadReportDeliveryState(reportId: string): Function {
        const [report] = await ReportBuilderApi.getReportDelivery(reportId);

        if (report) {
            return reportBuilderUtils.processDeliveryData(report);
        }
        return null;
    },

    async loadReportDeliveryStateForMultipleReports(
        reportIds: Array<?string>
    ): Promise<{ deliveryData: Array<DeliveryData>, reports: Array<Report> | void }> {
        const [reports: Array<Report> | void, error: Error | void] =
            await ReportBuilderApi.getMultipleReportsDeliveryStatus(reportIds);
        const deliveryData = [];

        if (error) {
            console.error('something went wrong with updating the delivery state');
        }
        if (reports) {
            reports.forEach((report) => {
                deliveryData.push(reportBuilderUtils.processDeliveryData(report));
            });
        }

        return {
            deliveryData,
            reports,
        };
    },

    changeUpdatesFlagForSingleReport(report: GeneralReportType, hasUpdates: boolean): Function {
        if (report && report.hasUpdates) {
            reduxStore.dispatch(reportBuilderActions.changeUpdatesFlag([report.id], hasUpdates));
        }
    },

    changeUpdatesFlagForMultipleReports(reports: Array<GeneralReportType> | any, hasUpdates: boolean) {
        const reportIds = [];

        if (reports?.length) {
            reports.forEach((report) => {
                if (report.hasUpdates) {
                    reportIds.push(report.id);
                }
            });
            if (reportIds.length) {
                reduxStore.dispatch(reportBuilderActions.changeUpdatesFlag(reportIds, hasUpdates));
            }
        }
    },

    async sortArticlesInCategory(
        reportId: string,
        categoryName: string,
        articleId: string,
        hoverIndex: number,
        onResponse: Function,
        onError: Function
    ) {
        const [response, error] = await ReportBuilderApi.sortArticlesInCategory(
            reportId,
            categoryName,
            articleId,
            hoverIndex
        );

        if (response) {
            onResponse(response);
            reduxStore.dispatch(reportBuilderActions.updateDeliveryStatusesForChildAndParent(reportId));
        }

        if (error) {
            utils.showNotificationsMessage({
                messageText: 'ReportBuilderPage_Errors.changeArticleOrder',
                messageType: 'system-error',
            });
            onError();
        }
    },

    async sortChildReportsForCombined(
        reportId: string,
        orderedList: Array<any>,
        onResponse: Function,
        onError: Function
    ) {
        const [response, error] = await ReportBuilderApi.sortChildReportsForCombined(reportId, orderedList);

        if (response) {
            reduxStore.dispatch(reportBuilderActions.updateChildReportsSort(reportId, response.childReports));
            reduxStore.dispatch(reportBuilderActions.updateDeliveryStatusesForChildAndParent(reportId));
            onResponse();
        }

        if (error) {
            utils.showNotificationsMessage({
                messageText: 'ReportBuilderPage_Errors.childReportsOrder',
                messageType: 'system-error',
            });
            onError();
        }
    },

    getArticlesByReport(report: GenericReportType, pageSize: number | any): Promise<Object> {
        if (!report) {
            errorUtils.logAppError('Try to load articles for a null report', { report: report }, APP_MODULE);
            return Promise.resolve([]);
        }

        if (report.articleLoaded) {
            return Promise.resolve(report.categories);
        }

        reduxStore.dispatch(reportBuilderActions.updateReportProperty(report.id, 'loadingArticles', true));

        let pageNumber = 0;
        return getArticlesByReportIdRecursively(report.id, pageNumber, pageSize, [])
            .then((reportSnippets) => {
                let reportCategories = reportBuilderUtils.sortCategoriesAndArticles(report, reportSnippets);
                reduxStore.dispatch(reportBuilderActions.updateReportProperty(report.id, 'articleLoaded', true));
                reduxStore.dispatch(reportBuilderActions.updateReportProperty(report.id, 'isSelected', true));
                reduxStore.dispatch(reportBuilderActions.updateReportProperty(report.id, 'loadingArticles', false));
                reduxStore.dispatch(
                    reportBuilderActions.updateReportProperty(report.id, 'categories', reportCategories)
                );
                return reportCategories;
            })
            .catch(() => {
                errorUtils.logAppError('Error loading articles for report', { report: report }, APP_MODULE);
                utils.showNotificationsMessage({
                    messageText: 'ReportBuilderPage_Errors.articlesPerReport',
                    messageType: 'system-error',
                });
                return Promise.resolve([]);
            });
    },

    async getEntitiesForBatchReport(report: GeneralReportType): Promise<Object> {
        if (!report) {
            errorUtils.logAppError('Try to load articles for a null report', { report: report }, APP_MODULE);
            return Promise.resolve([]);
        }

        if (report.entitiesLoaded) {
            return Promise.resolve(report.batchEntities);
        }
        reduxStore.dispatch(reportBuilderActions.updateReportProperty(report.id, 'loadingEntities', true));

        const [response: Object, error: Error] = await ReportBuilderApi.getMultipleEntitiesReportById(report.id);

        if (error) {
            errorUtils.logAppError('Error loading articles for report', { report: report }, APP_MODULE);
            utils.showNotificationsMessage({
                messageText: 'ReportBuilderPage_Errors.articlesPerReport',
                messageType: 'system-error',
            });
        }

        if (response) {
            reduxStore.dispatch(reportBuilderActions.updateReportProperty(report.id, 'isSelected', true));
            reduxStore.dispatch(reportBuilderActions.updateReportProperty(report.id, 'entitiesLoaded', true));
            reduxStore.dispatch(reportBuilderActions.updateReportProperty(report.id, 'loadingEntities', false));
            reduxStore.dispatch(
                reportBuilderActions.updateReportProperty(report.id, 'batchEntities', response.innerReports)
            );
        }
    },

    loadReports(
        currentPage: number,
        pageSize: number,
        publicRecordsOn: boolean
    ): { reports: Array<Report>, count: number } {
        let timezone = reduxStore.getState().user.timezone;
        reduxStore.dispatch(reportBuilderActions.setLoadingStatus(true));
        let contentTypes = reduxStore.getState().user.preferences.generalSettings.contentTypes;
        let prReportOrder = 0;
        if (contentTypes) {
            let prContentType = contentTypes.find((contentType) => contentType.name === CATEGORY_NAMES.PUBLIC_RECORDS);
            if (prContentType) {
                prReportOrder = prContentType.reportOrder;
            }
        }
        return getReports(currentPage, pageSize, publicRecordsOn, timezone, prReportOrder).then((response) => {
            let { reports, count } = response;
            reduxStore.dispatch(reportBuilderActions.setCurrentPage(currentPage));
            reduxStore.dispatch(reportBuilderActions.setReportsCount(count));
            reduxStore.dispatch(reportBuilderActions.setReports(reports));
            reduxStore.dispatch(reportBuilderActions.setLoadingStatus(false));
            return { reports, count };
        });
    },
    // @TODO refactor this using async/await

    loadReport(
        reportId: ?string,
        publicRecordsOn: boolean,
        pageSize: number
    ): { reports: Array<Report>, count: number } | Object {
        //reset initial state
        reduxStore.dispatch(reportBuilderActions.resetReportBuilderState());
        reduxStore.dispatch(reportBuilderActions.setLoadingStatus(true));
        let timezone = reduxStore.getState().user.timezone;
        let contentTypes = reduxStore.getState().user.preferences.generalSettings.contentTypes;
        let prReportOrder = 0;
        if (contentTypes) {
            let prContentType = contentTypes.find((contentType) => contentType.name === CATEGORY_NAMES.PUBLIC_RECORDS);
            if (prContentType) {
                prReportOrder = prContentType.reportOrder;
            }
        }

        return ReportBuilderService.loadReportsRecursively(
            0,
            reportId,
            pageSize,
            publicRecordsOn,
            timezone,
            prReportOrder
        ).then((response) => {
            let { reports, count } = response;
            reduxStore.dispatch(reportBuilderActions.setReportsCount(count));
            reduxStore.dispatch(reportBuilderActions.setReports(reports));
            reduxStore.dispatch(reportBuilderActions.setLoadingStatus(false));
            let currentReport = reports.find((report) => report.id === reportId);

            if (currentReport) {
                reduxStore.dispatch(reportBuilderActions.setSelectedReport(currentReport.id));
                //load articles recursively
                let snippetsPageSize = reduxStore.getState().user.reportSnippetsPageSize;

                return ReportBuilderService.getArticlesByReport(currentReport, snippetsPageSize).then(async () => {
                    await ReportBuilderService.getConnectedReports(currentReport.id);
                    await ReportBuilderService.getDocumentsCountPerCategory(currentReport.id);

                    // used to scroll to report
                    reduxStore.dispatch(reportBuilderActions.setSelectedReportById(currentReport.id));
                    return { reports, count };
                });
            } else {
                reduxStore.dispatch(reportBuilderActions.setSelectedReport(null));
                return { reports, count };
            }
        });
    },

    /**
     * load reports recursively; if reportId is provided, load reports until the report is found
     * @param currentPage
     * @param reportId
     * @param pageSize
     * @param praOn
     * @param timezone
     * @param prReportOrder
     * @returns {*}
     */
    loadReportsRecursively(
        currentPage: number,
        reportId: string,
        pageSize: number = 10,
        praOn: boolean | any,
        timezone: any,
        prReportOrder: number
    ): Promise<{ reports: Array<Report>, count: number }> {
        let that = ReportBuilderService;
        reduxStore.dispatch(reportBuilderActions.setCurrentPage(currentPage));
        return getReports(currentPage, pageSize, praOn, timezone, prReportOrder)
            .then((response) => {
                let { reports, count } = response;
                let totalReportsCount = count;

                let currentReport = reports.find((report) => {
                    if (reportId === undefined) {
                        return false;
                    } else {
                        return reportId === report.id;
                    }
                });
                // reportId is provided
                // and the report is not found in current batch,
                // and total reports count > already loaded reports
                // load an other page
                if (
                    reportId !== undefined &&
                    currentReport === undefined &&
                    reports.length > 0 &&
                    totalReportsCount > pageSize + currentPage * pageSize
                ) {
                    return that.loadReportsRecursively(
                        currentPage + 1,
                        reportId,
                        pageSize,
                        praOn,
                        timezone,
                        prReportOrder
                    );
                } else {
                    let loadedReports = sortReports(reports);

                    return new Promise((resolve) => {
                        return resolve({ reports: loadedReports, count });
                    });
                }
            })
            .catch((error) => {
                console.error(error);
            });
    },

    addNoteToReport(reportId: string, note: string) {
        ReportBuilderApi.addNoteToReportApi(reportId, note)
            .then((response) => {
                reduxStore.dispatch(reportBuilderActions.updateReportProperty(reportId, 'note', note));
                reduxStore.dispatch(reportBuilderActions.updateDeliveryStatusesForChildAndParent(reportId));
                return response;
            })
            .catch(() => {
                utils.showNotificationsMessage({
                    messageText: 'ReportBuilderPage_Errors.addNoteToReport',
                    messageType: 'system-error',
                });
            });
    },

    async updateReportProperty({
        reportId,
        reportProperty,
        propertyValue,
        report,
        isSelected,
    }: {
        reportId: string,
        reportProperty: string,
        propertyValue: mixed,
        report: ExpandedRegularReportType,
        isSelected: boolean,
    }): Promise<any> {
        let error: Error | void, response: Report;

        switch (reportProperty) {
            case UPDATE_TITLE:
                if (typeof propertyValue === 'string') {
                    [response, error] = await ReportBuilderApi.updateReportInfo(
                        encodeURIComponent(propertyValue),
                        reportId
                    );
                    if (response) {
                        reduxStore.dispatch(
                            reportBuilderActions.updateReportProperty(response.id, reportProperty, propertyValue)
                        );

                        // close expanded reports other than the changed report
                        if (!isSelected) {
                            reduxStore.dispatch(reportBuilderActions.setSelectedReport(''));
                        }
                        if (reportBuilderUtils.isReportCombined(response.deliveryType)) {
                            reduxStore.dispatch(
                                reportBuilderActions.updateDeliveryStatusesForChildAndParent(response.id)
                            );

                            if (response.childReports) {
                                response.childReports.forEach((childId) => {
                                    reduxStore.dispatch(
                                        reportBuilderActions.updateReportProperty(
                                            childId,
                                            'connectedReportsLoaded',
                                            false
                                        )
                                    );
                                });
                            }
                        } else {
                            if (response.parentReports && response.parentReports.length) {
                                reduxStore.dispatch(
                                    reportBuilderActions.updateDeliveryStatusesForChildAndParent(
                                        response.id,
                                        response.parentReports
                                    )
                                );

                                //also update liteChildReports title
                                response.parentReports.forEach((parentId) => {
                                    reduxStore.dispatch(
                                        reportBuilderActions.updateLiteChildReportsTitle(
                                            parentId,
                                            response.id,
                                            response.title
                                        )
                                    );
                                });
                            }
                        }
                    }
                } else {
                    error = new Error();
                }
                break;

            case UPDATE_CATEGORIES_ORDER:
                const newCategoryOrder: Array<CategoryOrder> = ArticlesUtils.getNewCategoryOrder(propertyValue);

                [response, error] = await ReportBuilderApi.updateReportSummaryOrder(reportId, newCategoryOrder);

                if (response) {
                    const {
                        id,
                        researchSummary,
                        categoryOrder,
                    }: {
                        id: string,
                        researchSummary: Array<ResearchSummaryEntry | CategoryOrder>,
                        categoryOrder: Array<CategoryOrder>,
                    } = response;
                    const categories = report.categories;
                    const isResearchSummaryObjectPopulated = utils.isArrayPopulated(researchSummary);
                    const catOrder = isResearchSummaryObjectPopulated ? researchSummary : categoryOrder;
                    let orderedCategories: Array<Category>;

                    if (categories) {
                        orderedCategories = reportBuilderUtils.updateCategoriesOrder(categories, catOrder);
                        reduxStore.dispatch(
                            reportBuilderActions.updateReportProperty(id, 'categories', orderedCategories)
                        );
                    }

                    if (isResearchSummaryObjectPopulated) {
                        reduxStore.dispatch(
                            reportBuilderActions.updateReportProperty(id, 'researchSummary', researchSummary)
                        );
                    }
                    reduxStore.dispatch(reportBuilderActions.updateDeliveryStatusesForChildAndParent(id));
                }
                break;
            default:
                break;
        }

        if (error) {
            utils.showNotificationsMessage({
                messageText: `ReportBuilderPage_Errors.${reportProperty}`,
                messageType: 'system-error',
            });
        }
    },

    deleteReports(reports: Array<any>): Function {
        reports = reports || [];
        const reportIds = reports.map((report) => report.id);
        const currentReportId = reduxStore.getState().currentReport.reportId;

        return ReportBuilderApi.deleteReports(reportIds)
            .then(() => {
                if (reportIds.includes(currentReportId)) {
                    reduxStore.dispatch(
                        currentReportActions.setCurrentReport({
                            reportId: null,
                            articlesSnippets: [],
                            researchSummary: [],
                        })
                    );
                    reduxStore.dispatch(reportBuilderActions.setSelectedReport(null));
                }

                reportIds.forEach((reportId) => {
                    reduxStore.dispatch(reportBuilderActions.deleteReport(reportId));
                });
            })
            .catch(() => {
                utils.showNotificationsMessage({
                    messageText: 'ReportBuilderPage_Errors.deleteReports',
                    messageType: 'system-error',
                });
            });
    },

    async deleteReportCategory(deleteRequest: CategoryDeleteRequest): Function {
        const payload = {category: deleteRequest.category, snippetIds: deleteRequest.snippetIds}
        const [response, error] = await ReportBuilderApi.deleteReportCategory(deleteRequest.reportId, payload);
        if (response) {
            reduxStore.dispatch(reportBuilderActions.updateDeliveryStatusesForChildAndParent(deleteRequest.reportId));

            deleteRequest.snippetIds.forEach((reportSnippetId) => {
                reduxStore.dispatch(
                  reportBuilderActions.deleteDocument(deleteRequest.reportId, reportSnippetId, undefined)
                );
                if (!isNoDocumentNote(reportSnippetId) && !!deleteRequest.category) {
                    reduxStore.dispatch(reportBuilderActions.updateDocumentsCounts(deleteRequest.reportId, [reportSnippetId], deleteRequest.category));
                }
            });
        }
        if (error) {
            utils.showNotificationsMessage({
                messageText: 'ReportBuilderPage_Errors.deleteReports',
                messageType: 'system-error',
            });
        }
    },

    async deleteReport(reportId: string, reportTitle: string): Function {
        let currentReportId = reduxStore.getState().currentReport.reportId;

        const [response, error] = await ReportBuilderApi.deleteReport(reportId);

        if (error) {
            reduxStore.dispatch(backgroundActions.clearBackgroundMessages());

            utils.showNotificationsMessage({
                messageText: 'ReportBuilderPage_Errors.deleteReport',
                terms: {
                    term: reportTitle,
                },
                messageType: 'system-error',
            });
        }

        if (response) {
            if (reportId === currentReportId) {
                reduxStore.dispatch(
                    currentReportActions.setCurrentReport({ reportId: null, articlesSnippets: [], researchSummary: [] })
                );
                reduxStore.dispatch(reportBuilderActions.setSelectedReport(null));
            }

            reduxStore.dispatch(
                backgroundActions.setSuccessBackgroundMessages({
                    message: 'ReportBuilderPage_Notifications.deleteReport',
                    messageParameters: reportTitle,
                    isVisible: true,
                })
            );

            reduxStore.dispatch(reportBuilderActions.deleteReport(reportId));
        }

        return [response, error];
    },

    async getConnectedReports(id: string): Promise<any> {
        reduxStore.dispatch(reportBuilderActions.updateReportProperty(id, 'loadingConnectedReports', true));

        const [response: ExpandedReport, error: Error] = await ReportBuilderApi.getParentsAndChildren(id);

        if (error) {
            utils.showNotificationsMessage({
                messageText: 'ReportBuilderPage_Errors.connectedReports',
                messageType: 'system-error',
            });
        }

        if (response) {
            const {
                reportId,
                parentReports,
                childReports,
            }: { reportId: string, parentReports: Array<?CombinedReportType>, childReports: Array<RegularReportType> } =
                response;
            childReports &&
                childReports.length &&
                reduxStore.dispatch(reportBuilderActions.updateReportProperty(reportId, 'childReports', childReports));
            parentReports &&
                reduxStore.dispatch(
                    reportBuilderActions.updateReportProperty(reportId, 'parentReports', parentReports)
                );
            reduxStore.dispatch(reportBuilderActions.updateReportProperty(reportId, 'connectedReportsLoaded', true));
            reduxStore.dispatch(reportBuilderActions.updateReportProperty(reportId, 'loadingConnectedReports', false));
        }
    },

    async getDocumentsCountPerCategory(id: string): Promise<any> {
        reduxStore.dispatch(reportBuilderActions.updateReportProperty(id, 'loadingDocumentsCount', true));

        const [response: Array<{ category: string, count: number }>, error: Error] =
            await ReportBuilderApi.getDocumentsCountPerCategory(id);

        if (error) {
            utils.showNotificationsMessage({
                messageText: 'ReportBuilderPage_Errors.documentCounts',
                messageType: 'system-error',
            });
        }

        if (response) {
            reduxStore.dispatch(reportBuilderActions.updateReportProperty(id, 'documentsCountsLoaded', true));
            reduxStore.dispatch(reportBuilderActions.updateReportProperty(id, 'loadingDocumentsCount', false));
            reduxStore.dispatch(
                reportBuilderActions.updateReportProperty(id, 'numberOfDocumentsPerCategory', response)
            );
        }
    },

    async removeIncludedReport({
        childId,
        parentId,
        currentPage,
        pageSize,
        publicRecordsOn,
    }: {
        childId: string,
        parentId: string,
        currentPage: number,
        pageSize: number,
        publicRecordsOn: boolean,
    }): Promise<any> {
        const [response: CombinedReportType, error: Error] = await ReportBuilderApi.removeIncludedReport(
            childId,
            parentId
        );

        if (error) {
            utils.showNotificationsMessage({
                messageText: 'ReportBuilderPage_Errors.removeIncludedReport',
                messageType: 'system-error',
            });
        }

        if (response) {
            const childReports = response.childReports && response.childReports.filter((id) => id !== childId);

            if (response.deleted) {
                ReportBuilderService.loadReports(currentPage, pageSize, publicRecordsOn);
            }

            if (!response.deleted && childReports && childReports.length) {
                reduxStore.dispatch(reportBuilderActions.removeIncludedReport(response.id, childReports));
                reduxStore.dispatch(reportBuilderActions.updateReportDelivery(response.id));
                reduxStore.dispatch(
                    reportBuilderActions.updateReportProperty(childId, 'connectedReportsLoaded', false)
                );
                reduxStore.dispatch(
                    reportBuilderActions.updateReportProperty(response.id, 'snippetsCount', response.snippetsCount)
                );
            }
        }
    },
};

export default ReportBuilderService;
