/**
 * CONSTANTS FILE
 *
 * @version 0.1
 */

import DEFAULT_TRANSLATIONS from '@i18n/default.json';
import { find } from 'lodash';

export const DEVELOPMENT_ENVIRONMENT = true;

export const HEADER_HEIGHT = 80;
export const FOOTER_HEIGHT = 70;
export const HEADER_AND_FOOTER_HEIGHT = 150;

export const USER_SESSION_EXPIRY_TIME = 20 * 60 * 1000;
export const POLLING_TIMEOUT = 2000;
export const POLLING_BATCH_TIMEOUT = 60000;
export const POLLING_BATCH_SCREENING_TIMEOUT = 5000;
export const MULTIPLE_ENTITIES_SEARCH_CHARACTER_LIMITATION = 1;
export const POLLING_ENTITIES_UPLOAD = 3000;
export const POLLING_ENTITIES_REFRESH = 2000;
export const POLLING_MAX_CONSECUTIVE_UNCHANGED_RESPONSES = 60;

export const SNACKBAR_TIMEOUT = 2000;

// The 2 debounce constants should be in sync in order to have query value when search is started
export const DEBOUNCE_QUERY_CHANGED_TIMEOUT = 250;
export const DEBOUNCE_SEARCH_EXTRA_TIMEOUT = 250;
export const DEBOUNCE_SEARCH_INPUT = 1000;
export const DEBOUNCE_MAX_DOCUMENTS_PER_CATEGORY_IN_REPORT_INPUT = 2000;

export const BILLING_ID = 'billingId';
export const VISITED_ARTICLES_LIMIT = 1000; // saved to local storage

export const BATCH_SCREENING_PAGE_SIZE = 200;

export const MAX_INPUT_LENGTH = 250;

export const DEFAULT_MAX_NUMBER_OF_DOCUMENTS_PER_CATEGORY_IN_REPORT = 200;

export const ID_TYPES = {
    DOC_ID: 'docId',
    ID: 'id',
    LNI: 'lni',
};

export const GET = 'GET';
export const POST = 'POST';

export const AND = 'AND';
export const OR = 'OR';
export const OPERATOR_AND = ` ${AND} `;
export const OPERATOR_OR = ` ${OR} `;
export const OPERATOR_LIST = [OPERATOR_AND, OPERATOR_OR];
export const API_SEARCH_VERSION = 'v2';
export const API_DOCUMENTS_COUNT_SEARCH_VERSION = 'v3';
export const API_SEARCH_VERSION_4 = 'v4';

export const XHR_LOG_RESPONSE_BODY_MAX_SIZE = 1000 * 10;
export const MAX_LOG_XHR_STACK_SIZE = 10; // x last number of calls to keep
export const LOCAL_STORAGE_XHR_LOG_KEY = 'xhr-log-stack';
export const XHR_LOG_EXCLUDE_URL_LIST = ['/api/system/translations'];

export const EDIT_SEARCH_URL = '/start/edit-search';
// Help guide and Getting started guide links

export const SUPERSCRIPT_TAG_WIDTHS = {
    small: { maxWidth: '3rem' },
    regular: { maxWidth: '5rem' },
    large: { maxWidth: '6.5rem' },
    xlarge: { maxWidth: '8.3rem' },
};
export const SUPERSCRIPT_TAG_WIDTH_LANGUAGE_MAP = {
    'en-gb': SUPERSCRIPT_TAG_WIDTHS.regular,
    'en-us': SUPERSCRIPT_TAG_WIDTHS.regular,
    fr: SUPERSCRIPT_TAG_WIDTHS.xlarge,
    de: SUPERSCRIPT_TAG_WIDTHS.xlarge,
    es: SUPERSCRIPT_TAG_WIDTHS.large,
    nl: SUPERSCRIPT_TAG_WIDTHS.large,
    pt: SUPERSCRIPT_TAG_WIDTHS.large,
    'zh-tw': SUPERSCRIPT_TAG_WIDTHS.regular,
    ru: SUPERSCRIPT_TAG_WIDTHS.large,
    ja: SUPERSCRIPT_TAG_WIDTHS.xlarge,
    'zh-cn': SUPERSCRIPT_TAG_WIDTHS.regular,
    tr: SUPERSCRIPT_TAG_WIDTHS.regular,
};

export const URL_DILIGENCE_PLUS_LEARN_MORE_ENGLISH =
    'https://professional.lexisnexis.com/en-us/diligence-plus-customer';
export const URL_DILIGENCE_PLUS_LEARN_MORE_GERMAN = 'https://professional.lexisnexis.com/de-de/eddm-diligenceplus';
export const URL_DILIGENCE_PLUS_LEARN_MORE_DUTCH = 'https://professional.lexisnexis.com/nl-nl/eddm-diligenceplus';
export const URL_DILIGENCE_PLUS_LEARN_MORE_FRENCH =
    'https://professional.lexisnexis.com/fr-fr/eddm-comingsoondiligenceplus';
export const DILIGENCE_PLUS_LEARN_MORE_LANGUAGE_MAP = {
    'en-gb': URL_DILIGENCE_PLUS_LEARN_MORE_ENGLISH,
    'en-us': URL_DILIGENCE_PLUS_LEARN_MORE_ENGLISH,
    de: URL_DILIGENCE_PLUS_LEARN_MORE_GERMAN,
    nl: URL_DILIGENCE_PLUS_LEARN_MORE_DUTCH,
    fr: URL_DILIGENCE_PLUS_LEARN_MORE_FRENCH,
};

export const DEFAULT_LANGUAGE = 'en-gb';
export const DEFAULT_LANGUAGE_US = 'en-us';

export const GENERIC_PRIVACY_POLICY_LINK = {
    enabled: true, // used to easy switch generic/local privacy policy instead of change the rest of the code
    url: 'https://www.lexisnexis.com/product-resources/nexis/privacy-policy.html',
};

export const COMPLETE = 'complete';
export const ERROR = 'error';

export const LOCALES = {
    'en-us': {
        id: 'HeaderFooterNav_CoreFunctionality_Dropdown_nav.language.englishUs',
        locale: 'en-gb',
        dbKey: 'en_us',
        help: 'en_US',
        cookie: 'en-us',
        about: 'https://www.lexisnexis.com/en-us/gateway.page',
        privacy: 'https://www.lexisnexis.com/en-us/terms/privacy-policy.page',
        copyright: 'https://www.lexisnexis.com/terms/copr/lngp/us/',
        terms: 'https://www.lexisnexis.com/en-us/terms/general/default.page',
        logo: 'https://www.lexisnexis.com/en-us/gateway.page',
        translations: {},
    },
    'en-gb': {
        id: 'HeaderFooterNav_CoreFunctionality_Dropdown_nav.language.englishUk',
        locale: 'en-gb',
        dbKey: 'en_gb',
        help: 'en_GB',
        cookie: 'en-gb',
        about: 'https://www.lexisnexis.co.uk/en-uk/contact-us.page#globoff',
        privacy: 'https://www.lexisnexis.com/global/privacy/en/privacy-policy-uk.page',
        copyright: 'https://www.lexisnexis.com/terms/copr/lngp/uk/',
        terms: 'https://www.lexisnexis.com/en-us/terms/general/default.page',
        logo: 'https://www.lexisnexis.co.uk/en-uk/home.page',
        translations: DEFAULT_TRANSLATIONS,
    },
    fr: {
        id: 'HeaderFooterNav_CoreFunctionality_Dropdown_nav.language.french',
        locale: 'en-gb',
        dbKey: 'fr',
        help: 'fr_FR',
        cookie: 'fr-fr',
        about: 'https://www.lexisnexis.fr/',
        privacy: 'https://www.lexisnexis.com/global/privacy/fr/privacy-policy-bis.page',
        copyright: 'https://www.lexisnexis.com/terms/copr/lngp/fr/',
        terms: 'https://www.lexisnexis.com/terms/lngp/gnb/fr/default.asp',
        logo: 'https://www.lexisnexis.fr/',
        translations: {},
    },
    de: {
        id: 'HeaderFooterNav_CoreFunctionality_Dropdown_nav.language.german',
        locale: 'en-gb',
        dbKey: 'de',
        help: 'de_DE',
        cookie: 'de-de',
        about: 'https://www.lexisnexis.co.uk/en-uk/contact-us.page#globoff',
        privacy: 'https://www.lexisnexis.com/global/privacy/de/privacy-centre-bis.page',
        copyright: 'https://www.lexisnexis.com/terms/copr/lngp/de/',
        terms: 'https://www.lexisnexis.com/terms/lngp/de/de_terms_include.htm',
        logo: 'https://www.lexisnexis.de/',
        translations: {},
    },
    es: {
        id: 'HeaderFooterNav_CoreFunctionality_Dropdown_nav.language.spanish',
        locale: 'en-gb',
        dbKey: 'es',
        help: 'es_ES',
        cookie: 'en-gb',
        about: 'https://www.lexisnexis.co.uk/en-uk/contact-us.page#globoff',
        privacy: 'https://www.lexisnexis.com/global/privacy/en/privacy-policy-uk.page',
        copyright: 'https://www.lexisnexis.com/terms/copr/lngp/uk/',
        terms: 'https://www.lexisnexis.com/en-us/terms/general/default.page',
        logo: 'https://internationalsales.lexisnexis.com/',
        translations: {},
        numbersFormattingOptions: {
            overrideLocale: 'pt',
        },
    },
    nl: {
        id: 'HeaderFooterNav_CoreFunctionality_Dropdown_nav.language.dutch',
        locale: 'en-gb',
        dbKey: 'nl_nl',
        help: 'nl_NL',
        cookie: 'en-gb',
        about: 'https://www.lexisnexis.co.uk/en-uk/contact-us.page#globoff',
        privacy: 'https://www.lexisnexis.com/global/privacy/en/privacy-policy-nl.page',
        copyright: 'https://www.lexisnexis.com/terms/copr/lngp/nl/',
        terms: 'https://www.lexisnexis.com/terms/lngp/nl/default.asp',
        logo: 'https://www.lexisnexis.nl/',
        translations: {},
    },
    pt: {
        id: 'HeaderFooterNav_CoreFunctionality_Dropdown_nav.language.portuguese',
        locale: 'en-gb',
        dbKey: 'pt',
        help: 'pt_BR',
        cookie: 'en-gb',
        about: 'https://www.lexisnexis.co.uk/en-uk/contact-us.page#globoff',
        privacy: 'https://www.lexisnexis.com/global/privacy/en/privacy-policy-uk.page',
        copyright: 'https://www.lexisnexis.com/terms/copr/lngp/uk/',
        terms: 'https://www.lexisnexis.com/en-us/terms/general/default.page',
        logo: 'https://internationalsales.lexisnexis.com/',
        translations: {},
    },
    'zh-tw': {
        id: 'HeaderFooterNav_CoreFunctionality_Dropdown_nav.language.chinese_traditional',
        locale: 'en-gb',
        dbKey: 'zh_tw',
        help: 'en_GB',
        cookie: 'en-gb',
        about: 'https://www.lexisnexis.co.uk/en-uk/contact-us.page#globoff',
        privacy: 'https://www.lexisnexis.com/global/privacy/en/privacy-policy-uk.page',
        copyright: 'https://www.lexisnexis.com/terms/copr/lngp/uk/',
        terms: 'https://www.lexisnexis.com/en-us/terms/general/default.page',
        logo: 'https://internationalsales.lexisnexis.com/',
        translations: {},
    },
    ru: {
        id: 'HeaderFooterNav_CoreFunctionality_Dropdown_nav.language.russian',
        locale: 'en-gb',
        dbKey: 'ru',
        help: 'en_GB',
        cookie: 'en-gb',
        about: 'https://www.lexisnexis.co.uk/en-uk/contact-us.page#globoff',
        privacy: 'https://www.lexisnexis.com/global/privacy/en/privacy-policy-uk.page',
        copyright: 'https://www.lexisnexis.com/terms/copr/lngp/uk/',
        terms: 'https://www.lexisnexis.com/terms/lngp/ru/default.asp',
        logo: 'https://internationalsales.lexisnexis.com/',
        translations: {},
    },
    ja: {
        id: 'HeaderFooterNav_CoreFunctionality_Dropdown_nav.language.japanese',
        locale: 'en-gb',
        dbKey: 'ja',
        help: 'en_GB',
        cookie: 'en-gb',
        about: 'https://www.lexisnexis.co.uk/en-uk/contact-us.page#globoff',
        privacy: 'https://www.lexisnexis.com/global/privacy/en/privacy-policy-uk.page',
        copyright: 'https://www.lexisnexis.com/terms/copr/lngp/uk/',
        terms: 'https://www.lexisnexis.com/en-us/terms/general/default.page',
        logo: 'https://internationalsales.lexisnexis.com/',
        translations: {},
    },

    'zh-cn': {
        id: 'HeaderFooterNav_CoreFunctionality_Dropdown_nav.language.chinese_simplified',
        locale: 'en-gb',
        dbKey: 'zh_cn',
        help: 'en_GB',
        cookie: 'en-gb',
        about: 'https://www.lexisnexis.co.uk/en-uk/contact-us.page#globoff',
        privacy: 'https://www.lexisnexis.com/global/privacy/en/privacy-policy-uk.page',
        copyright: 'https://www.lexisnexis.com/terms/copr/lngp/uk/',
        terms: 'https://www.lexisnexis.com/en-us/terms/general/default.page',
        logo: 'https://internationalsales.lexisnexis.com/',
        translations: {},
    },
    tr: {
        id: 'HeaderFooterNav_CoreFunctionality_Dropdown_nav.language.turkish',
        locale: 'en-gb',
        dbKey: 'tr',
        help: 'en_GB',
        cookie: 'en-gb',
        about: 'https://www.lexisnexis.co.uk/en-uk/contact-us.page#globoff',
        privacy: 'https://www.lexisnexis.com/global/privacy/en/privacy-policy-uk.page',
        copyright: 'https://www.lexisnexis.com/terms/copr/lngp/uk/',
        terms: 'https://www.lexisnexis.com/en-us/terms/general/default.page',
        logo: 'https://internationalsales.lexisnexis.com/',
        translations: {},
    },
};

export const LANGUAGE_SELECT_OPTIONS = {
    all: 'General_CoreFunctionality_UIText_languageSelect.allLanguages',
    en: 'General_CoreFunctionality_UIText_languageSelect.english',
    fr: 'General_CoreFunctionality_UIText_languageSelect.french',
    de: 'General_CoreFunctionality_UIText_languageSelect.german',
};

// Console settings
export const consoleSettings = {
    style: {
        sucess: ['background: green', 'color: white', 'display: block', 'width: 100%', 'text-align: center'].join(';'),
        failure: ['background: red', 'color: white', 'width: 100%', 'display: block', 'text-align: center'].join(';'),
    },
};

export const PAGE_SIZE = 10;
export const GENERAL_PAGE_SIZE_KEY = 'generalPageSize';
export const COMPANY_SEARCH = 'company';
export const COMPANY_SEARCH_QUERY_TYPE = 'SEARCH_FOR_COMPANY';
export const PERSON_SEARCH = 'people';
export const PERSONS_SEARCH = 'persons';
export const QUERY_SEARCH_TYPES = {
    SEARCH_FOR_COMPANY: 'company',
    SEARCH_FOR_PERSON: 'people',
};

export const PREFERENCES_KEY = {
    [PERSON_SEARCH]: 'personCheck',
    [COMPANY_SEARCH]: 'companyCheck',
    [PERSONS_SEARCH]: 'personCheck',
};
//post filters
export const FUZZY_NAMES = 'fuzzyNames';
export const SUGGESTED_NAMES = 'suggestedNames';
export const PRE_FILTER_QUERY = 'prefilterQuery';
export const IS_CUSTOM_FUZZY = 'isCustomFuzzy';
export const POST_FILTER_GEOGRAPHY = 'geography';
export const POST_FILTER_FULL_GEOGRAPHY = 'fullGeography';
export const POST_FILTER_LANGUAGE = 'language';
export const POST_FILTER_PROXIMITY = 'proximity';
export const POST_FILTER_DATE_RANGE = 'dateRange';
export const POST_FILTER_INCLUDE_TERMS = 'includeTerms';
export const POST_FILTER_EXCLUDE_TERMS = 'excludeTerms';
export const POST_FILTER_SORT = 'sort';
export const POST_FILTER_SOURCE = 'source';
export const POST_FILTER_SOURCE_NAME = 'sourceName';
export const POST_FILTER_SOURCE_TYPE = 'sourceType';
export const POST_FILTER_COMPANY_MENTIONS = 'companyMentions';
export const POST_FILTER_PERSON_MENTIONS = 'personMentions';
export const POST_FILTER_COMPANY = 'company';
export const POST_FILTER_SEARCH_QUERY = 'searchQuery';
export const POST_FILTER_SEARCH_QUERY_TYPE = 'searchQueryType';
export const POST_FILTER_SEARCH_CONTENT_TYPE = 'contentType';
export const POST_FILTER_SEARCH_PUBLICATION_NAME = 'publicationName';
export const POST_FILTER_SEARCH_SOURCE_NAME = 'sourceName';
export const POST_FILTER_SEARCH_SPECIFICALLY = 'searchSpecifically';
export const POST_FILTER_SEARCH_NAME_PARTY_ENTITY = 'searchNamePartyEntity';
export const POST_FILTER_DOCKET_STATUS = 'status';
export const POST_FILTER_DUNS_FILTER = 'dunsFilter';
export const POST_FILTER_CONTENT_SOURCE = 'contentSource';
export const POST_FILTER_SUBJECT = 'subject';
export const POST_FILTER_INDUSTRY = 'industry';
export const POST_FILTER_FUZZY_SEARCH = 'fuzzyOn';
export const POST_FILTER_FUZZY_THRESHOLD = 'fuzzyThreshold';
export const POST_FILTER_EXCLUDE_NON_BUSINESS_NEWS = 'excludeNonBusinessNews';
export const POST_FILTER_EXCLUDE_NEWS_WIRES = 'excludeNewsWires';
export const POST_FILTER_NEGATIVITY_LEVELS = 'negativityLevels';
export const POST_FILTER_TERMS = 'terms';
export const POST_FILTER_ESG_FACTORS = 'esgFactors';
export const POST_FILTER_UNCLASSIFIED_OPTION = 'Unclassified';

export const DOC_TYPE = {
    PDF: 'PDF',
    DOCX: 'DOCX',
};

export const DOWNLOAD_ENTITIES_FORMAT_TYPE = {
    TABLE_FORMAT: 'table-format',
    UPLOAD_FORMAT: 'upload-format',
};

export const REPORT_TYPE = {
    COMBINED: 'COMBINED',
    REPORTS: 'REPORTS',
    BATCH: 'BATCH',
};

export const POST_FILTER_TYPES = [
    PRE_FILTER_QUERY,
    POST_FILTER_SORT,
    POST_FILTER_INCLUDE_TERMS,
    POST_FILTER_EXCLUDE_TERMS,
    POST_FILTER_PROXIMITY,
    POST_FILTER_DATE_RANGE,
    POST_FILTER_GEOGRAPHY,
    POST_FILTER_LANGUAGE,
    POST_FILTER_SOURCE,
    POST_FILTER_COMPANY_MENTIONS,
    POST_FILTER_PERSON_MENTIONS,
    POST_FILTER_SOURCE_TYPE,
    POST_FILTER_COMPANY,
    POST_FILTER_SEARCH_CONTENT_TYPE,
    POST_FILTER_SEARCH_PUBLICATION_NAME,
    POST_FILTER_SEARCH_SOURCE_NAME,
    FUZZY_NAMES,
    SUGGESTED_NAMES,
    POST_FILTER_DOCKET_STATUS,
    POST_FILTER_DUNS_FILTER,
    POST_FILTER_SUBJECT,
    POST_FILTER_INDUSTRY,
    POST_FILTER_FUZZY_SEARCH,
    POST_FILTER_NEGATIVITY_LEVELS,
    POST_FILTER_TERMS,
    POST_FILTER_ESG_FACTORS,
];

export const DNB_LABEL = 'Dun & Bradstreet';

export const BOOLEAN_AND = ' AND ';
export const BOOLEAN_OR = ' OR ';
export const BOOLEAN_AND_NOT = ' AND NOT ';

export const ALERT_TYPE = {
    MY_ALERT: 'MyAlert',
    PUBLIC_RECORD_ALERT: 'PublicRecordAlert',
    SHARED_ALERT: 'SHARED_ALERT',
};

export const MODAL_TYPE = {
    PRINT_REPORTS: 'printReports',
    PRINT_REPORTS_COMBINED: 'printReportsCombined',
    PRINT_REPORTS_SEPARATE: 'printReportsSeparate',
    EMAIL_REPORTS: 'emailReports',
    EMAIL_REPORTS_COMBINED: 'emailReportsCombined',
    EMAIL_REPORTS_SEPARATE: 'emailReportsSeparate',
    DOWNLOAD_REPORTS: 'downloadReports',
    DOWNLOAD_REPORTS_COMBINED: 'downloadReportsCombined',
    DOWNLOAD_REPORTS_SEPARATE: 'downloadReportsSeparate',
    DOWNLOAD_REPORT: 'downloadReport',
    DOWNLOAD_BATCH_REPORT: 'downloadBatchReport',
    PRINT_REPORT_CATEGORY: 'printReportCategory',
    EMAIL_REPORT_CATEGORY: 'emailReportCategory',
    DOWNLOAD_REPORT_CATEGORY: 'downloadReportCategory',
    DELETE_REPORT_CATEGORY: 'deleteReportCategory',
    PRINT_RESULT_LIST: 'printResultList',
    EMAIL_RESULT_LIST: 'emailResultList',
    DOWNLOAD_RESULT_LIST: 'downloadResultList',
    DOWNLOAD_RESULT_SNAPSHOT: 'downloadResultSnapshot',
    DOWNLOAD_SNAPSHOT_PAGE: 'downloadSnapshotPage',
    DELETE_REPORT: 'deleteReport',
    DELETE_REPORT_NOTE: 'deleteReportNote',
    DELETE_DOCUMENT_FROM_REPORT: 'deleteDocumentFromReport',
    DELETE_NOTE_FROM_DOCUMENT: 'deleteNoteFromDocument',
    ELASTIC_DOWNLOAD_DOCUMENT: 'downloadElasticDocument',
    ELASTIC_PRINT_DOCUMENT: 'printElasticDocument',
    ELASTIC_EMAIL_DOCUMENT: 'emailElasticDocument',
    PREVIEW_ARTICLE: 'previewArticle',
    PREVIEW_UBO_ARTICLE: 'previewUboArticle',
    EDIT_ARTICLE_NOTE: 'editArticleNote',
    USER_PREFERENCES_EDIT_NEGATIVE_SEARCH_TERMS: 'userPreferencesEditNegativeSearchTerms',
    SHOW_SNAPSHOT: 'showHideSnapShot',
    DELETE_ALERT: 'deleteAlert',
    DELETE_PUBLIC_RECORD_ALERT: 'deletePublicRecordAlert',
    DELETE_PUBLIC_RECORD_ALERTS: 'deletePublicRecordAlerts',
    DELETE_ALERTS: 'deleteAlerts',
    ADHOC_SEARCH: 'adHocSearch',
    ADD_TO_REPORT: 'addToReport',
    APPLY_ADMIN_CHANGES: 'applyAdminChanges',
    DISCARD_ADMIN_CHANGES: 'discardAdminChanges',
    USER_PREFERENCES_ADD_CUSTOM_NEWS_QUERY: 'addCustomNewsQuery',
    USER_PREFERENCES_EDIT_CUSTOM_NEWS_QUERY: 'editCustomNewsQuery',
    USER_PREFERENCES_DELETE_CUSTOM_NEWS_QUERY: 'deleteCustomNewsQuery',
    USER_PREFERENCES_VIEW_CUSTOM_NEWS_QUERY: 'viewCustomQuery',
    DOWNLOAD_HISTORY: 'downloadHistory',
    PREVIEW_ARTICLE_SLIDE: 'previewArticleSlide',
    APPLY_RISK_SCORES_PREFERENCE: 'applyRiskScoresPreference',
    DELETE_ALL_HISTORY: 'deleteAllHistory',
    UNDEFINED: 'undefined',
    UPLOAD_ENTITIES_COMPANY_SOURCES_WITH_COMPANIES: 'uploadEntitiesCompanySourcesWithCompanies',
    UPLOAD_ENTITIES_COMPANY_SOURCES_WITHOUT_COMPANIES: 'uploadEntitiesCompanySourcesWithoutCompanies',
    SAVE_SEARCH: 'saveSearch',
    CREATE_ALERTS: 'createAlerts',
    ADD_TO_MULTIPLE_ENTITIES: 'addToMultipleEntities',
    DOWNLOAD_BATCH_SCREENING: 'downloadBatchScreening',
    DOWNLOAD_BATCH_SCREENING_SNAPSHOT: 'downloadBatchScreeningSnapshot',
    DOWNLOAD_BATCH_SCREENING_FULL_REPORT: 'downloadBatchScreeningFullReport',
    CHANGE_COLUMNS_VISIBILITY_MULTIPLE_ENTITIES: 'changeColumnsVisibilityMultipleEntities',
    DOWNLOAD_ENTITIES: 'downloadEntities',
    EDIT_SEARCH_MULTIPLE_ENTITIES: 'editSearchMultipleEntities',
    UPLOAD_ENTITIES: 'uploadEntities',
    SHARE_VIEW: 'shareView',
};

export const MODAL_TITLE_MAP = {
    [MODAL_TYPE.PRINT_RESULT_LIST]: 'PopupBuilder.title.print',
    [MODAL_TYPE.ELASTIC_PRINT_DOCUMENT]: 'PopupBuilder.title.print',
    [MODAL_TYPE.PRINT_REPORT_CATEGORY]: 'PopupBuilder.title.print',
    [MODAL_TYPE.PRINT_REPORTS]: 'PopupBuilder.title.printReports',
    [MODAL_TYPE.PRINT_REPORTS_SEPARATE]: 'PopupBuilder.title.printReports',
    [MODAL_TYPE.PRINT_REPORTS_COMBINED]: 'PopupBuilder.title.printCombinedReport',
    [MODAL_TYPE.EMAIL_RESULT_LIST]: 'PopupBuilder.title.email',
    [MODAL_TYPE.ELASTIC_EMAIL_DOCUMENT]: 'PopupBuilder.title.email',
    [MODAL_TYPE.EMAIL_REPORT_CATEGORY]: 'PopupBuilder.title.email',
    [MODAL_TYPE.EMAIL_REPORTS]: 'PopupBuilder.title.emailReports',
    [MODAL_TYPE.EMAIL_REPORTS_SEPARATE]: 'PopupBuilder.title.emailReports',
    [MODAL_TYPE.EMAIL_REPORTS_COMBINED]: 'PopupBuilder.title.emailCombinedReport',
    [MODAL_TYPE.DOWNLOAD_REPORTS]: 'PopupBuilder.title.downloadReports',
    [MODAL_TYPE.DOWNLOAD_REPORTS_SEPARATE]: 'PopupBuilder.title.downloadReports',
    [MODAL_TYPE.DOWNLOAD_REPORTS_COMBINED]: 'PopupBuilder.title.downloadCombinedReport',
    [MODAL_TYPE.DOWNLOAD_RESULT_SNAPSHOT]: 'PopupBuilder.title.downloadSnapshot',
    [MODAL_TYPE.DOWNLOAD_BATCH_SCREENING_FULL_REPORT]: 'PopupBuilder.title.generateResulstList',
    [MODAL_TYPE.DOWNLOAD_BATCH_SCREENING_SNAPSHOT]: 'PopupBuilder.title.generateEVSnapshot',
    [MODAL_TYPE.DOWNLOAD_RESULT_LIST]: 'PopupBuilder.title.downloadSnapshot',
    [MODAL_TYPE.ELASTIC_DOWNLOAD_DOCUMENT]: 'PopupBuilder.title.downloadSnapshot',
    [MODAL_TYPE.DOWNLOAD_REPORT_CATEGORY]: 'PopupBuilder.title.download',
    [MODAL_TYPE.DOWNLOAD_HISTORY]: 'PopupBuilder.title.download',
    [MODAL_TYPE.DOWNLOAD_BATCH_SCREENING]: 'ArticleAction.generateReport',
    [MODAL_TYPE.SHARE_VIEW]: 'PopupBuilder.title.shareView',
    [MODAL_TYPE.DELETE_REPORT]: 'PopupBuilder.title.deleteThisReport',
    [MODAL_TYPE.DELETE_REPORT_NOTE]: 'PopupBuilder.title.deleteThisNote',
    [MODAL_TYPE.DELETE_DOCUMENT_FROM_REPORT]: 'PopupBuilder.title.deleteThisDocument',
    [MODAL_TYPE.DELETE_NOTE_FROM_DOCUMENT]: 'PopupBuilder.title.deleteThisDocumentNote',
    [MODAL_TYPE.DOWNLOAD_REPORT]: 'PopupBuilder.title.downloadYourReport',
    [MODAL_TYPE.DOWNLOAD_ENTITIES]: 'BatchScreening.page.download.entities',
    [MODAL_TYPE.EDIT_ARTICLE_NOTE]: 'PopupBuilder.title.addNote',
    [MODAL_TYPE.PREVIEW_ARTICLE]: 'Report.DocumentItem.title.reviewDocument',
    [MODAL_TYPE.SHOW_SNAPSHOT]: 'PopupBuilder.title.hideSnapshotView',
    [MODAL_TYPE.USER_PREFERENCES_EDIT_NEGATIVE_SEARCH_TERMS]: '',
    [MODAL_TYPE.DELETE_ALERT]: 'PopupBuilder.title.deleteAlert',
    [MODAL_TYPE.DELETE_PUBLIC_RECORD_ALERT]: 'PopupBuilder.title.deleteAlert',
    [MODAL_TYPE.DELETE_ALERTS]: 'PopupBuilder.title.deleteAlerts',
    [MODAL_TYPE.DELETE_PUBLIC_RECORD_ALERTS]: 'PopupBuilder.title.deleteAlerts',
    [MODAL_TYPE.ADHOC_SEARCH]: 'PopupBuilder.title.searchPreferences',
    [MODAL_TYPE.ADD_TO_REPORT]: 'PopupBuilder.title.addToReport',
    [MODAL_TYPE.APPLY_ADMIN_CHANGES]: 'PopupBuilder.title.applyChangesTitle',
    [MODAL_TYPE.DISCARD_ADMIN_CHANGES]: 'PopupBuilder.title.discardChangesTitle',
    [MODAL_TYPE.USER_PREFERENCES_ADD_CUSTOM_NEWS_QUERY]: 'UserPreferences.CustomNewsSearches.addButton',
    [MODAL_TYPE.USER_PREFERENCES_EDIT_CUSTOM_NEWS_QUERY]: 'PopupBuilder.title.editCustomNewsQuery',
    [MODAL_TYPE.USER_PREFERENCES_VIEW_CUSTOM_NEWS_QUERY]: 'PopupBuilder.title.viewCustomNewsQuery',
    [MODAL_TYPE.USER_PREFERENCES_DELETE_CUSTOM_NEWS_QUERY]: 'PopupBuilder.title.deleteCustomNewsQuery',
    [MODAL_TYPE.DOWNLOAD_SNAPSHOT_PAGE]: 'ResultsSnapshot.downloadSnapshot',
    [MODAL_TYPE.APPLY_RISK_SCORES_PREFERENCE]: 'PopupBuilder.title.riskScores',
    [MODAL_TYPE.DELETE_ALL_HISTORY]: 'PopupBuilder.title.clearHistory',
    [MODAL_TYPE.UPLOAD_ENTITIES_COMPANY_SOURCES_WITH_COMPANIES]: 'BatchScreening.page.button.uploadEntities',
    [MODAL_TYPE.UPLOAD_ENTITIES_COMPANY_SOURCES_WITHOUT_COMPANIES]: 'BatchScreening.page.button.uploadEntities',
    [MODAL_TYPE.UPLOAD_ENTITIES]: 'BatchScreening.page.button.uploadEntities',
    [MODAL_TYPE.SAVE_SEARCH]: 'PopupBuilder.title.saveSearch',
    [MODAL_TYPE.CREATE_ALERTS]: 'Alerts.createAlert.alertDeliveryOptions',
    [MODAL_TYPE.CHANGE_COLUMNS_VISIBILITY_MULTIPLE_ENTITIES]: 'BatchScreening.page.manageColumns',
    [MODAL_TYPE.EDIT_SEARCH_MULTIPLE_ENTITIES]: 'ManageAlerts.editAlert.editSearch',

    'default': 'Undefined',
};

export const TERMS_DEFINITIONS = 'termsDefinitions';

export const BREADCRUMBS = {
    HOME: 'home',
    SNAPSHOT: 'snapshot',
    RESULTS: 'results',
    REPORT_BUILDER: 'reportBuilder',
    HISTORY: 'history',
    DOCUMENT: 'document',
    PREFERENCES: 'preferences',
    ADMIN_PREFERENCES: 'adminPreferences',
    CREATE_ALERT: 'createAlert',
    EDIT_ALERT: 'editAlert',
    MANAGE_ALERTS: 'manageAlerts',
    PUBLIC_RECORDS: 'publicRecords',
    UPLOAD_ENTITIES: 'uploadEntities',
    BATCH_SCREENING: 'batchScreening',
};

export const BREADCRUMBS_PREV_PATH = {
    HISTORY_PAGE: '/history',
    MANAGE_ALERTS_PAGE: '/manage-alerts',
    SCREENING_PAGE: '/screening',
    EDIT_SEARCH: '/start/edit-search',
    EDIT_ALERT_SEARCH: '/edit-alert-search',
    START_PAGE: '/start',
};

export const DOCUMENT_DATA = {
    ELASTIC: {
        ID: 'id',
        TITLE: 'title',
    },
    UBO: {
        ID: 'dunsNumber',
        TITLE: 'name',
        TRADEUP: 'tradeUp',
    },
};

export const SANCTIONS_COLUMNS = {
    SOURCE: 'source',
    AUTHORITY:  'authority',
    LIST: 'list'
}

export const CATEGORY_NAMES = {
    NEGATIVE_NEWS: 'negativeNews',
    NEGATIVE_NEWS_EN: 'negativeNewsEn',
    NEGATIVE_NEWS_FR: 'negativeNewsFr',
    NEGATIVE_NEWS_NL: 'negativeNewsNl',
    NEGATIVE_NEWS_DE: 'negativeNewsDe',
    NEGATIVE_NEWS_ES: 'negativeNewsEs',
    NEGATIVE_NEWS_TR: 'negativeNewsTr',
    NEGATIVE_NEWS_SV: 'negativeNewsSv',
    NEGATIVE_NEWS_MS: 'negativeNewsMs',
    NEGATIVE_NEWS_PT: 'negativeNewsPt',
    NEGATIVE_NEWS_JA: 'negativeNewsJa',
    NEGATIVE_NEWS_ZH: 'negativeNewsZh',
    NEGATIVE_NEWS_IT: 'negativeNewsIt',
    NEGATIVE_NEWS_AR: 'negativeNewsAr',
    NEGATIVE_NEWS_RU: 'negativeNewsRu',
    NEGATIVE_NEWS_PL: 'negativeNewsPl',
    NEWS: 'news',
    FINANCIAL_REPORT: 'financialReports',
    COMPANY_RESOURCES: 'directors',
    SANCTIONS_WATCHLIST: 'sanctions',
    PEPS: 'peps',
    BIOGRAPHICAL: 'biographical',
    LAW_SOURCES: 'lawSources',
    CASES: 'legal',
    STATE_DOCKETS: 'dockets',
    FEDERAL_DOCKETS: 'federalDockets',
    AGENCY_DECISION: 'agencyDecision',
    VERDICTS: 'verdicts',
    LAW_REVIEWS: 'lawReviews',
    PUBLIC_RECORDS: 'publicRecords',
    CUSTOM_NEWS: 'customNews',
    CUSTOM_NEWS1: 'customNews1',
    CUSTOM_NEWS2: 'customNews2',
    CUSTOM_NEWS3: 'customNews3',
    LN_CUSTOM_NEWS1: 'lnCustomNews1',
    ADMIN_CUSTOM_NEWS1: 'adminCustomNews1',
    ADMIN_CUSTOM_NEWS2: 'adminCustomNews2',
    ADMIN_CUSTOM_NEWS3: 'adminCustomNews3',
    DNB: 'ubo',
    ESG_RATINGS: 'esgRatings',
};

export const CATEGORIES_EXCLUDED_FROM_PERSON_SEARCH = [
    CATEGORY_NAMES.DNB,
    CATEGORY_NAMES.FINANCIAL_REPORT,
    CATEGORY_NAMES.PUBLIC_RECORDS,
    CATEGORY_NAMES.ESG_RATINGS,
];

export const CATEGORIES_EXCLUDED_FROM_ALERT_CONTENT_SELECTION = [
    CATEGORY_NAMES.DNB,
    CATEGORY_NAMES.PUBLIC_RECORDS,
    CATEGORY_NAMES.ESG_RATINGS,
];

export const CATEGORIES_WITH_INFO_ICON = [
    CATEGORY_NAMES.NEGATIVE_NEWS,
    CATEGORY_NAMES.NEWS,
    CATEGORY_NAMES.CUSTOM_NEWS,
    CATEGORY_NAMES.COMPANY_RESOURCES,
    CATEGORY_NAMES.FINANCIAL_REPORT,
    CATEGORY_NAMES.SANCTIONS_WATCHLIST,
    CATEGORY_NAMES.PEPS,
    CATEGORY_NAMES.BIOGRAPHICAL,
    CATEGORY_NAMES.LAW_SOURCES,
    CATEGORY_NAMES.PUBLIC_RECORDS,
];

export const SOURCES = {
    newsSources: [
        CATEGORY_NAMES.NEGATIVE_NEWS,
        CATEGORY_NAMES.NEGATIVE_NEWS_EN,
        CATEGORY_NAMES.NEGATIVE_NEWS_FR,
        CATEGORY_NAMES.NEGATIVE_NEWS_NL,
        CATEGORY_NAMES.NEGATIVE_NEWS_DE,
        CATEGORY_NAMES.NEGATIVE_NEWS_ES,
        CATEGORY_NAMES.NEGATIVE_NEWS_SV,
        CATEGORY_NAMES.NEGATIVE_NEWS_TR,
        CATEGORY_NAMES.NEGATIVE_NEWS_MS,
        CATEGORY_NAMES.NEGATIVE_NEWS_PT,
        CATEGORY_NAMES.NEGATIVE_NEWS_JA,
        CATEGORY_NAMES.NEGATIVE_NEWS_IT,
        CATEGORY_NAMES.NEGATIVE_NEWS_ZH,
        CATEGORY_NAMES.NEGATIVE_NEWS_AR,
        CATEGORY_NAMES.NEGATIVE_NEWS_RU,
        CATEGORY_NAMES.NEGATIVE_NEWS_PL,
        CATEGORY_NAMES.NEWS,
        CATEGORY_NAMES.CUSTOM_NEWS,
        CATEGORY_NAMES.CUSTOM_NEWS1,
        CATEGORY_NAMES.CUSTOM_NEWS2,
        CATEGORY_NAMES.CUSTOM_NEWS3,
        CATEGORY_NAMES.LN_CUSTOM_NEWS1,
        CATEGORY_NAMES.ADMIN_CUSTOM_NEWS1,
        CATEGORY_NAMES.ADMIN_CUSTOM_NEWS2,
        CATEGORY_NAMES.ADMIN_CUSTOM_NEWS3,
    ],
    companySources: [CATEGORY_NAMES.COMPANY_RESOURCES],
    legalSources: [
        CATEGORY_NAMES.CASES,
        CATEGORY_NAMES.FEDERAL_DOCKETS,
        CATEGORY_NAMES.STATE_DOCKETS,
        CATEGORY_NAMES.AGENCY_DECISION,
        CATEGORY_NAMES.VERDICTS,
        CATEGORY_NAMES.LAW_REVIEWS,
    ],
};

export const ALL_CATEGORIES = 'all';
export const ALL_ITEMS = 'all';

export const DOC_TYPE_SEARCH_EVENT_REQUEST = {
    TREE: 'TREE',
    DOC: 'DOC',
    SIMPLE: 'SIMPLE',
    EXPANDED: 'EXPANDED',
};

const shouldDisplayLanguage = (state, language, categoryKey) => {
    let searchType = 'companyCheck';
    // TODO: In app there are instances of searchType "persons" (redux) and also "people" (url) -- we need to pick one
    if (state.searchParams.searchType === 'persons' || state.searchParams.searchType === 'people') {
        searchType = 'personCheck';
    }

    const adHocPrefs = state.adHocSearch;
    const userPrefs = state.user.preferences;
    const launchedFrom = state.searchState.launchedFrom;

    // if search is launched from screening page, check if the category is enabled from the search results state
    if(launchedFrom === LAUNCHED_SEARCH_FROM.SCREENING_PAGE) {
        const searchResults = state.searchResults;

        return searchResults && searchResults[categoryKey] && searchResults[categoryKey].enabled;
    }

    if (adHocPrefs && adHocPrefs.sources && adHocPrefs.customQuery) {
        // check if parent category is selected
        let source = find(adHocPrefs.sources, ['key', CATEGORY_NAMES.NEGATIVE_NEWS]);

        if (source && source.checked && adHocPrefs.customQuery.length)
            return adHocPrefs.customQuery.filter((item) => item.language === language && item.checked).length > 0;

        return false;
    }

    if (state.historyCategory.categoryName === categoryKey) {
        return true;
    }

    if (userPrefs && userPrefs[searchType] && userPrefs[searchType].customQuery) {
        let source = find(userPrefs.generalSettings.contentTypes, ['name', CATEGORY_NAMES.NEGATIVE_NEWS]);

        if (source && source.value && userPrefs[searchType].customQuery.length)
            return (
                userPrefs[searchType].customQuery.filter((item) => item.language === language && item.checked).length >
                0
            );

        return false;
    }

    return false;
};

const shouldDisplayCustomNews = (state, categoryKey) => {
    const adHocPrefs = state.adHocSearch;
    const userPrefs = state.user.preferences;

    if (isCustomNewsChecked(adHocPrefs, userPrefs)) {
        let searchType = PREFERENCES_KEY[state.searchParams.searchType];
        let customNewsQuery = state.user.preferences[searchType].customNewsQuery;
        return customNewsQuery && customNewsQuery.length > 0
            ? find(customNewsQuery, ['name', categoryKey]).checked
            : false;
    }

    return false;
};

const shouldDisplayLNCustomNews = (state, categoryKey) => {
    const adHocPrefs = state.adHocSearch;
    const userPrefs = state.user.preferences;

    if (isCustomNewsChecked(adHocPrefs, userPrefs)) {
        let searchType = PREFERENCES_KEY[state.searchParams.searchType];
        let customNewsQuery = state.user.preferences[searchType].lnCustomNewsQueries;
        return customNewsQuery && customNewsQuery.length > 0
            ? find(customNewsQuery, ['name', categoryKey]).checked
            : false;
    }

    return false;
};

const isCustomNewsChecked = (adHocPrefs, userPrefs) => {
    if (adHocPrefs && adHocPrefs.sources && adHocPrefs.customQuery) {
        // check if parent category is selected
        let source = find(adHocPrefs.sources, ['key', CATEGORY_NAMES.CUSTOM_NEWS]);
        return source && source.checked;
    }
    let customNewsSource = find(userPrefs.generalSettings.contentTypes, ['name', CATEGORY_NAMES.CUSTOM_NEWS]);
    return customNewsSource && customNewsSource.value;
};

const shouldDisplayAdminCustomNews = (state, categoryKey) => {
    const adHocPrefs = state.adHocSearch;
    const userPrefs = state.user.preferences;

    if (isCustomNewsChecked(adHocPrefs, userPrefs)) {
        let searchType = PREFERENCES_KEY[state.searchParams.searchType];
        let customNewsQuery = state.user.preferences[searchType].adminCustomNewsQuery;
        return customNewsQuery && customNewsQuery.length > 0
            ? find(customNewsQuery, ['name', categoryKey]).checked
            : false;
    }

    return false;
};

const getNegativeNewsQuery = (state, searchType, language, categoryName) => {
    const postFilterNegativityLevels =
        state.searchResults &&
        state.searchResults[categoryName] &&
        state.searchResults[categoryName].postFilters &&
        state.searchResults[categoryName].postFilters.negativityLevels;
    let negativeNewsQuery,
        lowQueryString,
        mediumQueryString,
        highQueryString = null;
    let typeCheck = state.user.preferences[searchType];

    if (!typeCheck || !typeCheck.customQuery) {
        return;
    }

    let customQuery = typeCheck.customQuery.find((customNews) => {
        return customNews.language === language;
    });

    const negativityLevels =
        postFilterNegativityLevels && postFilterNegativityLevels.length
            ? postFilterNegativityLevels
            : typeCheck.negativityLevel;

    if (negativityLevels.indexOf(NEGATIVITY_LEVELS.LOW) > -1) {
        lowQueryString = customQuery['lowQueryString'];
    }

    if (negativityLevels.indexOf(NEGATIVITY_LEVELS.MEDIUM) > -1) {
        mediumQueryString = customQuery['mediumQueryString'];
    }

    if (negativityLevels.indexOf(NEGATIVITY_LEVELS.HIGH) > -1) {
        highQueryString = customQuery['highQueryString'];
    }

    if (customQuery) {
        negativeNewsQuery = {
            contentLanguage: language,
            negativeNewsQueries: {
                lowQueryString,
                mediumQueryString,
                highQueryString,
            },
        };
    }

    return negativeNewsQuery;
};

const getCustomNewsQuery = (state, searchType, categoryKey) => {
    let customNewsQuerySearchObject = null;
    let typeCheck = state.user.preferences[searchType];
    if (!typeCheck || !typeCheck.customNewsQuery) return customNewsQuerySearchObject;
    let customNewsQuery = typeCheck.customNewsQuery.find((customNews) => {
        return customNews.name === categoryKey;
    });

    if (customNewsQuery) {
        customNewsQuerySearchObject = {
            title: customNewsQuery.title,
            query: customNewsQuery.query,
            name: customNewsQuery.name,
        };
    }
    return customNewsQuerySearchObject;
};

const getLNCustomNewsQuery = (state, searchType, categoryKey) => {
    let customNewsQuerySearchObject = null;
    let typeCheck = state.user.preferences[searchType];
    if (!typeCheck || !typeCheck.lnCustomNewsQueries) return customNewsQuerySearchObject;
    let customNewsQuery = typeCheck.lnCustomNewsQueries.find((customNews) => {
        return customNews.name === categoryKey;
    });

    if (customNewsQuery) {
        customNewsQuerySearchObject = {
            title: customNewsQuery.title || CUSTOM_NEWS_QUERY_ESG_TITLE,
            query: customNewsQuery.query ? customNewsQuery.query : customNewsQuery.defaultQuery,
            name: customNewsQuery.name,
        };
    }
    return customNewsQuerySearchObject;
};

const getAdminCustomNewsQuery = (state, searchType, categoryKey) => {
    let customNewsQuerySearchObject = null;
    let typeCheck = state.user.preferences[searchType];
    if (!typeCheck || !typeCheck.adminCustomNewsQuery) return customNewsQuerySearchObject;
    let customNewsQuery = typeCheck.adminCustomNewsQuery.find((customNews) => {
        return customNews.name === categoryKey;
    });

    if (customNewsQuery) {
        customNewsQuerySearchObject = {
            title: customNewsQuery.title,
            query: customNewsQuery.query,
            name: customNewsQuery.name,
        };
    }
    return customNewsQuerySearchObject;
};

export const CATEGORIES = {
    negativeNews: {
        name: 'Negative News',
        order: 0,
        key: 'negativeNews',
        hasSelectableSubcategories: true,
        children: {
            negativeNewsEn: {
                extendConfig: (config) => ({
                    ...config,
                    contentLanguage: NEGATIVE_NEWS_LANGUAGES.english.value,
                    category: CATEGORY_NAMES.NEGATIVE_NEWS,
                }),
                matchConfig: function (config) {
                    return (
                        config.category === CATEGORY_NAMES.NEGATIVE_NEWS &&
                        config.contentLanguage === NEGATIVE_NEWS_LANGUAGES.english.value
                    );
                },
                newsQuery: (state, searchType) =>
                    getNegativeNewsQuery(
                        state,
                        searchType,
                        NEGATIVE_NEWS_LANGUAGES.english.value,
                        CATEGORY_NAMES.NEGATIVE_NEWS_EN
                    ),
                shouldDisplay: (state) =>
                    shouldDisplayLanguage(state, NEGATIVE_NEWS_LANGUAGES.english.value, 'negativeNewsEn'),
                key: 'negativeNewsEn',
                name: 'Negative News English',
                order: 0,
            },
            negativeNewsFr: {
                extendConfig: (config) => ({
                    ...config,
                    contentLanguage: 'French',
                    category: 'negativeNews',
                }),
                newsQuery: (state, searchType) =>
                    getNegativeNewsQuery(
                        state,
                        searchType,
                        NEGATIVE_NEWS_LANGUAGES.french.value,
                        CATEGORY_NAMES.NEGATIVE_NEWS_FR
                    ),
                matchConfig: (config) => config.category === 'negativeNews' && config.contentLanguage === 'French',
                shouldDisplay: (state) => shouldDisplayLanguage(state, 'French', 'negativeNewsFr'),
                key: 'negativeNewsFr',
                name: 'Negative News French',
                order: 0,
            },
            negativeNewsNl: {
                extendConfig: (config) => ({
                    ...config,
                    contentLanguage: 'Dutch',
                    category: 'negativeNews',
                }),
                newsQuery: (state, searchType) =>
                    getNegativeNewsQuery(
                        state,
                        searchType,
                        NEGATIVE_NEWS_LANGUAGES.dutch.value,
                        CATEGORY_NAMES.NEGATIVE_NEWS_NL
                    ),
                matchConfig: (config) => config.category === 'negativeNews' && config.contentLanguage === 'Dutch',
                shouldDisplay: (state) => shouldDisplayLanguage(state, 'Dutch', 'negativeNewsNl'),
                key: 'negativeNewsNl',
                name: 'Negative News Dutch',
                order: 0,
            },
            negativeNewsDe: {
                extendConfig: (config) => ({
                    ...config,
                    contentLanguage: 'German',
                    category: 'negativeNews',
                }),
                newsQuery: (state, searchType) =>
                    getNegativeNewsQuery(
                        state,
                        searchType,
                        NEGATIVE_NEWS_LANGUAGES.german.value,
                        CATEGORY_NAMES.NEGATIVE_NEWS_DE
                    ),
                matchConfig: (config) => config.category === 'negativeNews' && config.contentLanguage === 'German',
                shouldDisplay: (state) => shouldDisplayLanguage(state, 'German', 'negativeNewsDe'),
                key: 'negativeNewsDe',
                name: 'Negative News German',
                order: 0,
            },
            negativeNewsEs: {
                extendConfig: (config) => ({
                    ...config,
                    contentLanguage: 'Spanish',
                    category: 'negativeNews',
                }),
                newsQuery: (state, searchType) =>
                    getNegativeNewsQuery(
                        state,
                        searchType,
                        NEGATIVE_NEWS_LANGUAGES.spanish.value,
                        CATEGORY_NAMES.NEGATIVE_NEWS_ES
                    ),
                matchConfig: (config) => config.category === 'negativeNews' && config.contentLanguage === 'Spanish',
                shouldDisplay: (state) => shouldDisplayLanguage(state, 'Spanish', 'negativeNewsEs'),
                key: 'negativeNewsEs',
                name: 'Negative News Spanish',
                order: 0,
            },
            negativeNewsTr: {
                extendConfig: (config) => ({
                    ...config,
                    contentLanguage: 'Turkish',
                    category: 'negativeNews',
                }),
                newsQuery: (state, searchType) =>
                    getNegativeNewsQuery(
                        state,
                        searchType,
                        NEGATIVE_NEWS_LANGUAGES.turkish.value,
                        CATEGORY_NAMES.NEGATIVE_NEWS_TR
                    ),
                matchConfig: (config) => config.category === 'negativeNews' && config.contentLanguage === 'Turkish',
                shouldDisplay: (state) => shouldDisplayLanguage(state, 'Turkish', 'negativeNewsTr'),
                key: 'negativeNewsTr',
                name: 'Negative News Turkish',
                order: 0,
            },
            negativeNewsSv: {
                extendConfig: (config) => ({
                    ...config,
                    contentLanguage: 'Swedish',
                    category: 'negativeNews',
                }),
                newsQuery: (state, searchType) =>
                    getNegativeNewsQuery(
                        state,
                        searchType,
                        NEGATIVE_NEWS_LANGUAGES.swedish.value,
                        CATEGORY_NAMES.NEGATIVE_NEWS_SV
                    ),
                matchConfig: (config) => config.category === 'negativeNews' && config.contentLanguage === 'Swedish',
                shouldDisplay: (state) => shouldDisplayLanguage(state, 'Swedish', 'negativeNewsSv'),
                key: 'negativeNewsSv',
                name: 'Negative News Swedish',
                order: 0,
            },
            negativeNewsMs: {
                extendConfig: (config) => ({
                    ...config,
                    contentLanguage: 'Malay',
                    category: 'negativeNews',
                }),
                newsQuery: (state, searchType) =>
                    getNegativeNewsQuery(
                        state,
                        searchType,
                        NEGATIVE_NEWS_LANGUAGES.malay.value,
                        CATEGORY_NAMES.NEGATIVE_NEWS_MS
                    ),
                matchConfig: (config) => config.category === 'negativeNews' && config.contentLanguage === 'Malay',
                shouldDisplay: (state) => shouldDisplayLanguage(state, 'Malay', 'negativeNewsMs'),
                key: 'negativeNewsMs',
                name: 'Negative News Malay',
                order: 0,
            },
            negativeNewsPt: {
                extendConfig: (config) => ({
                    ...config,
                    contentLanguage: 'Portuguese',
                    category: 'negativeNews',
                }),
                newsQuery: (state, searchType) =>
                    getNegativeNewsQuery(
                        state,
                        searchType,
                        NEGATIVE_NEWS_LANGUAGES.portuguese.value,
                        CATEGORY_NAMES.NEGATIVE_NEWS_PT
                    ),
                matchConfig: (config) => config.category === 'negativeNews' && config.contentLanguage === 'Portuguese',
                shouldDisplay: (state) => shouldDisplayLanguage(state, 'Portuguese', 'negativeNewsPt'),
                key: 'negativeNewsPt',
                name: 'Negative News Portuguese',
                order: 0,
            },
            negativeNewsJa: {
                extendConfig: (config) => ({
                    ...config,
                    contentLanguage: 'Japanese',
                    category: 'negativeNews',
                }),
                newsQuery: (state, searchType) =>
                    getNegativeNewsQuery(
                        state,
                        searchType,
                        NEGATIVE_NEWS_LANGUAGES.japanese.value,
                        CATEGORY_NAMES.NEGATIVE_NEWS_JA
                    ),
                matchConfig: (config) => config.category === 'negativeNews' && config.contentLanguage === 'Japanese',
                shouldDisplay: (state) => shouldDisplayLanguage(state, 'Japanese', 'negativeNewsJa'),
                key: 'negativeNewsJa',
                name: 'Negative News Japanese',
                order: 0,
            },
            negativeNewsZh: {
                extendConfig: (config) => ({
                    ...config,
                    contentLanguage: 'Chinese',
                    category: 'negativeNews',
                }),
                newsQuery: (state, searchType) =>
                    getNegativeNewsQuery(
                        state,
                        searchType,
                        NEGATIVE_NEWS_LANGUAGES.chinese.value,
                        CATEGORY_NAMES.NEGATIVE_NEWS_ZH
                    ),
                matchConfig: (config) => config.category === 'negativeNews' && config.contentLanguage === 'Chinese',
                shouldDisplay: (state) => shouldDisplayLanguage(state, 'Chinese', 'negativeNewsZh'),
                key: 'negativeNewsZh',
                name: 'Negative News Chinese',
                order: 0,
            },
            negativeNewsIt: {
                extendConfig: (config) => ({
                    ...config,
                    contentLanguage: 'Italian',
                    category: 'negativeNews',
                }),
                newsQuery: (state, searchType) =>
                    getNegativeNewsQuery(
                        state,
                        searchType,
                        NEGATIVE_NEWS_LANGUAGES.italian.value,
                        CATEGORY_NAMES.NEGATIVE_NEWS_IT
                    ),
                matchConfig: (config) => config.category === 'negativeNews' && config.contentLanguage === 'Italian',
                shouldDisplay: (state) => shouldDisplayLanguage(state, 'Italian', 'negativeNewsIt'),
                key: 'negativeNewsIt',
                name: 'Negative News Italian',
                order: 0,
            },
            negativeNewsAr: {
                extendConfig: (config) => ({
                    ...config,
                    contentLanguage: 'Arabic',
                    category: 'negativeNews',
                }),
                newsQuery: (state, searchType) =>
                    getNegativeNewsQuery(
                        state,
                        searchType,
                        NEGATIVE_NEWS_LANGUAGES.arabic.value,
                        CATEGORY_NAMES.NEGATIVE_NEWS_AR
                    ),
                matchConfig: (config) => config.category === 'negativeNews' && config.contentLanguage === 'Arabic',
                shouldDisplay: (state) => shouldDisplayLanguage(state, 'Arabic', 'negativeNewsAr'),
                key: 'negativeNewsAr',
                name: 'Negative News Arabic',
                order: 0,
            },
            negativeNewsRu: {
                extendConfig: (config) => ({
                    ...config,
                    contentLanguage: 'Russian',
                    category: 'negativeNews',
                }),
                newsQuery: (state, searchType) =>
                    getNegativeNewsQuery(
                        state,
                        searchType,
                        NEGATIVE_NEWS_LANGUAGES.russian.value,
                        CATEGORY_NAMES.NEGATIVE_NEWS_RU
                    ),
                matchConfig: (config) => config.category === 'negativeNews' && config.contentLanguage === 'Russian',
                shouldDisplay: (state) => shouldDisplayLanguage(state, 'Russian', 'negativeNewsRu'),
                key: 'negativeNewsRu',
                name: 'Negative News Russian',
                order: 0,
            },
            negativeNewsPl: {
                extendConfig: (config) => ({
                    ...config,
                    contentLanguage: 'Polish',
                    category: 'negativeNews',
                }),
                newsQuery: (state, searchType) =>
                    getNegativeNewsQuery(
                        state,
                        searchType,
                        NEGATIVE_NEWS_LANGUAGES.polish.value,
                        CATEGORY_NAMES.NEGATIVE_NEWS_PL
                    ),
                matchConfig: (config) => config.category === 'negativeNews' && config.contentLanguage === 'Polish',
                shouldDisplay: (state) => shouldDisplayLanguage(state, 'Polish', 'negativeNewsPl'),
                key: 'negativeNewsPl',
                name: 'Negative News Polish',
                order: 0,
            },
        },
        getConfigKeys: () => {
            return {
                matchConfigKey: 'contentLanguage',
                configKey: 'contentLanguages',
            };
        },
    },
    news: { name: 'News', order: 1, key: 'news' },
    customNews: {
        name: 'Custom News',
        order: 2,
        key: 'customNews',
        shouldDisplay: (state) => {
            let searchType = PREFERENCES_KEY[state.searchParams.searchType];
            let isEnabledOne =
                find(state.user.preferences[searchType].customNewsQuery, { checked: true, isDeleted: false }) ||
                find(state.user.preferences[searchType].adminCustomNewsQuery, { checked: true, isDeleted: false }) ||
                find(state.user.preferences[searchType].lnCustomNewsQueries, { checked: true });
            return !!isEnabledOne;
        },
        children: {
            customNews1: {
                name: 'CustomNews1',
                shouldDisplay: (state) => shouldDisplayCustomNews(state, 'customNews1'),
                newsQuery: (state, searchType) => getCustomNewsQuery(state, searchType, 'customNews1'),
                matchConfig: function (config) {
                    return (
                        config.customNews === this.key ||
                        (Array.isArray(config.customNews) && config.customNews[0] === 'customNews1')
                    );
                },
                extendConfig: (config) => ({
                    ...config,
                    category: 'customNews',
                    customNews: 'customNews1',
                }),
                order: 2,
                key: 'customNews1',
            },
            customNews2: {
                name: 'CustomNews2',
                shouldDisplay: (state) => shouldDisplayCustomNews(state, 'customNews2'),
                newsQuery: (state, searchType) => getCustomNewsQuery(state, searchType, 'customNews2'),
                matchConfig: function (config) {
                    return (
                        config.customNews === this.key ||
                        (Array.isArray(config.customNews) && config.customNews[0] === 'customNews2')
                    );
                },
                extendConfig: (config) => ({
                    ...config,
                    category: 'customNews',
                    customNews: 'customNews2',
                }),
                order: 2,
                key: 'customNews2',
            },
            customNews3: {
                name: 'CustomNews3',
                shouldDisplay: (state) => shouldDisplayCustomNews(state, 'customNews3'),
                newsQuery: (state, searchType) => getCustomNewsQuery(state, searchType, 'customNews3'),
                matchConfig: function (config) {
                    return (
                        config.customNews === this.key ||
                        (Array.isArray(config.customNews) && config.customNews[0] === 'customNews3')
                    );
                },
                extendConfig: (config) => ({
                    ...config,
                    category: 'customNews',
                    customNews: 'customNews3',
                }),
                order: 2,
                key: 'customNews3',
            },
            lnCustomNews1: {
                name: 'LnCustomNews1',
                shouldDisplay: (state) => shouldDisplayLNCustomNews(state, 'lnCustomNews1'),
                newsQuery: (state, searchType) => getLNCustomNewsQuery(state, searchType, 'lnCustomNews1'),
                matchConfig: function (config) {
                    return (
                        config.customNews === this.key ||
                        (Array.isArray(config.customNews) && config.customNews[0] === 'lnCustomNews1')
                    );
                },
                extendConfig: (config) => ({
                    ...config,
                    category: 'customNews',
                    customNews: 'lnCustomNews1',
                }),
                order: 2,
                key: 'lnCustomNews1',
            },
            adminCustomNews1: {
                name: 'AdminCustomNews1',
                shouldDisplay: (state) => shouldDisplayAdminCustomNews(state, 'adminCustomNews1'),
                newsQuery: (state, searchType) => getAdminCustomNewsQuery(state, searchType, 'adminCustomNews1'),
                matchConfig: function (config) {
                    return (
                        config.customNews === this.key ||
                        (Array.isArray(config.customNews) && config.customNews[0] === 'adminCustomNews1')
                    );
                },
                extendConfig: (config) => ({
                    ...config,
                    category: 'customNews',
                    customNews: 'adminCustomNews1',
                }),
                order: 2,
                key: 'adminCustomNews1',
            },
            adminCustomNews2: {
                name: 'AdminCustomNews2',
                shouldDisplay: (state) => shouldDisplayAdminCustomNews(state, 'adminCustomNews2'),
                newsQuery: (state, searchType) => getAdminCustomNewsQuery(state, searchType, 'adminCustomNews2'),
                matchConfig: function (config) {
                    return (
                        config.customNews === this.key ||
                        (Array.isArray(config.customNews) && config.customNews[0] === 'adminCustomNews2')
                    );
                },
                extendConfig: (config) => ({
                    ...config,
                    category: 'customNews',
                    customNews: 'adminCustomNews2',
                }),
                order: 2,
                key: 'adminCustomNews2',
            },
            adminCustomNews3: {
                name: 'adminCustomNews3',
                shouldDisplay: (state) => shouldDisplayAdminCustomNews(state, 'adminCustomNews3'),
                newsQuery: (state, searchType) => getAdminCustomNewsQuery(state, searchType, 'adminCustomNews3'),
                matchConfig: function (config) {
                    return (
                        config.customNews === this.key ||
                        (Array.isArray(config.customNews) && config.customNews[0] === 'adminCustomNews3')
                    );
                },
                extendConfig: (config) => ({
                    ...config,
                    category: 'customNews',
                    customNews: 'adminCustomNews3',
                }),
                order: 2,
                key: 'adminCustomNews3',
            },
        },
        getConfigKeys: () => {
            return {
                matchConfigKey: 'customNews',
                configKey: 'customNews',
            };
        },
    },
    esgRatings: {
        name: 'ESG Ratings',
        order: 3,
        key: 'esgRatings',
        shouldDisplay: (state) => state.searchParams.searchType === COMPANY_SEARCH,
    },
    ubo: {
        name: 'D&B',
        order: 4,
        dynamic: true,
        key: 'ubo',
        shouldDisplay: (state) => state.searchParams.searchType === COMPANY_SEARCH,
        childrenBlueprint: ({ name, key }) => ({ name, key }),
    },
    directors: { name: 'Company Sources', order: 5, key: 'directors' },
    financialReports: {
        name: 'Credit Reports',
        order: 6,
        key: 'financialReports',
        shouldDisplay: (state) => {
            return state.searchParams.searchType === COMPANY_SEARCH;
        },
    },
    sanctions: { name: 'Sanctions & Watchlists', order: 7, key: 'sanctions' },
    peps: { name: 'Politically Exposed Persons', order: 8, key: 'peps' },
    biographical: { name: 'Biographical References & Directories', order: 9, key: 'biographical' },
    lawSources: {
        name: 'Legal Sources',
        order: 10,
        key: 'lawSources',
        hasSelectableSubcategories: true,
        children: {
            legal: { name: 'Cases', order: 9, key: 'legal' },
            federalDockets: { name: 'Federal Dockets', order: 10, key: 'federalDockets' },
            dockets: { name: 'State Dockets', order: 11, key: 'dockets' },
            agencyDecision: { name: 'Agency Decisions', order: 12, key: 'agencyDecision' },
            verdicts: { name: 'Verdicts', order: 13, key: 'verdicts' },
            lawReviews: { name: 'Law Reviews', order: 14, key: 'lawReviews' },
        },
    },
    publicRecords: { name: 'Public Records', order: 11, key: 'publicRecords' },
};

// this object should not be modified as it contains the order of sources

export const CATEGORIES_ORDER = {
    negativeNews: { key: 'negativeNews' },
    news: { key: 'news' },
    customNews: { key: 'customNews' },
    esgRatings: { key: 'esgRatings' },
    ubo: { key: 'ubo' },
    directors: { key: 'directors' },
    financialReports: { key: 'financialReports' },
    sanctions: { key: 'sanctions' },
    peps: { key: 'peps' },
    biographical: { key: 'biographical' },
    lawSources: { key: 'lawSources' },
    legal: { key: 'legal' },
    federalDockets: { key: 'federalDockets' },
    dockets: { key: 'dockets' },
    agencyDecision: { key: 'agencyDecision' },
    verdicts: { key: 'verdicts' },
    publicRecords: { key: 'publicRecords' },
    lawReviews: { key: 'lawReviews' },
};

export const CATEGORY_SORT_FUNC = (a, b) => a.order - b.order;

export const LEGAL_SOURCES_TITLES = {
    legal: { name: 'Cases' },
    federalDockets: { name: 'Federal Dockets' },
    dockets: { name: 'State Dockets' },
    agencyDecision: { name: 'Agency Decisions' },
    verdicts: { name: 'Verdicts' },
    lawReviews: { name: 'Law Reviews' },
    all: { name: 'All Legal Sources ' },
};

export const POSTFILTER_COMPONENT_TYPE = {
    RADIO_LIST: 'RADIO_LIST',
    CHECKBOX_LIST: 'CHECKBOX_LIST',
    GEOGRAPHY: 'GEOGRAPHY',
    NESTED_CHECKBOX_LIST: 'NESTED_CHECKBOX_LIST',
    DATE_RADIO_LIST: 'DATE_RADIO_LIST',
    TEXT_FILTER: 'TEXT_FILTER',
};

export const FILTER_INFO = {
    PUBLICATION_NAME: 'publicationName',
    PUBLICATION_DATE: 'publicationDate',
    SOURCE_NAME: 'sourceName',
    PUBLICATION_TYPE: 'publicationType',
    CONTENT_TYPE: 'contentType',
    LANGUAGE: 'language',
    GEOGRAPHY: 'geography',
    FULL_GEOGRAPHY: 'fullGeography',
    INDUSTRY: 'industry',
    SUBJECT: 'subject',
    COMPANY: 'company',
    COMPANY_MENTIONS: 'companyMentions',
    PERSON_MENTIONS: 'personMentions',
    DOCKET_STATUS: 'status',

    PROXIMITY: 'proximity',
    DATE_RANGE: 'dateRange',
    FUZZY_NAMES: 'fuzzyNames',
    SUGGESTED_NAMES: 'suggestedNames',
    DUNS_FILTER: 'dunsFilter',
    FUZZY_SEARCH: 'fuzzyOn',
    NEGATIVITY_LEVELS: 'negativityLevels',
    ESG_FACTORS: 'esgFactors',
};

export const POSTFILTER_TYPE = {
    SIMILAR_NAMES: 'SimilarNames',
    SUGGESTED_NAMES: 'SuggestedNames',
    DATE_RANGE: 'DateRange',
    COMPANY_MENTIONS: 'CompanyMentions',
    PERSON_MENTIONS: 'PersonMentions',
    PROXIMITY_NEGATIVE_TERMS: 'ProximityNegativeTerms',
    SOURCE: 'Source',
    SOURCE_NAME: 'SourceName',
    SOURCE_TYPE: 'Source Type',
    LANGUAGE: 'Language',
    COMPANY: 'Company',
    GEOGRAPHY: 'Geography',
    DOCKET_STATUS: 'DocketStatus',
    DUNS_FILTER: 'DunsFilter',
    SUBJECT: 'Subject',
    INDUSTRY: 'Industry',
    FUZZY_SEARCH: 'FuzzySearch',
    NEGATIVITY_LEVELS: 'NegativityLevels',
    TERMS: 'terms',
    ESG_FACTORS: 'EsgFactors',
};

export const FILTER_SORT_OPTIONS = {
    ALPHABETICALLY_ASC: 'alphabeticallyAsc',
    HIGHEST: 'highest',
};

//--- summary --
// to be populated only with sortable postFilters
// the postFilter key should be the postFilterType
export const POST_FILTERS_DEFAULT_SORT = {
    [POSTFILTER_TYPE.SOURCE]: FILTER_SORT_OPTIONS.HIGHEST,
    [POSTFILTER_TYPE.SOURCE_NAME]: FILTER_SORT_OPTIONS.HIGHEST,
};

export const POSTFILTER_COMPONENTS = {
    SIMILAR_NAMES: {
        label: 'PostFilters.Title.SimilarNames',
        type: POSTFILTER_TYPE.SIMILAR_NAMES,
        componentType: POSTFILTER_COMPONENT_TYPE.CHECKBOX_LIST,
        defaultValue: ALL_ITEMS,
        //if is custom Fuzzy, will be visible also in company search type
        // displayInQueryType: PERSON_SEARCH,
        dynamic: false,
        eventSubType: 'SIMILAR_NAMES_SELECTION',
    },

    SUGGESTED_NAMES: {
        label: 'PostFilters.Title.SuggestedNames',
        type: POSTFILTER_TYPE.SUGGESTED_NAMES,
        componentType: POSTFILTER_COMPONENT_TYPE.CHECKBOX_LIST,
        defaultValue: ALL_ITEMS,
        displayInQueryType: PERSON_SEARCH,
        dynamic: false,
        eventSubType: 'SUGGESTED_NAMES_SELECTION',
    },

    DATE_RANGE: {
        label: 'PostFilters.Title.DateRange',
        type: POSTFILTER_TYPE.DATE_RANGE,
        componentType: POSTFILTER_COMPONENT_TYPE.DATE_RADIO_LIST,
        dynamic: false,
        eventSubType: 'DATE_RANGE_SELECTION', // used for investigation events
    },

    COMPANY_MENTIONS: {
        label: 'PostFilters.Title.CompanyMentions',
        type: POSTFILTER_TYPE.COMPANY_MENTIONS,
        componentType: POSTFILTER_COMPONENT_TYPE.RADIO_LIST,
        displayInQueryType: COMPANY_SEARCH,
        dynamic: false,
        eventSubType: 'COMPANY_MENTIONS_SELECTION',
    },

    PERSON_MENTIONS: {
        label: 'PostFilters.Title.PersonMentions',
        type: POSTFILTER_TYPE.PERSON_MENTIONS,
        componentType: POSTFILTER_COMPONENT_TYPE.RADIO_LIST,
        displayInQueryType: PERSON_SEARCH,
        dynamic: false,
        eventSubType: 'PERSON_MENTIONS_SELECTION',
    },

    PROXIMITY_NEGATIVE_TERMS: {
        label: 'PostFilters.Title.ProximityNegativeTerms',
        type: POSTFILTER_TYPE.PROXIMITY_NEGATIVE_TERMS,
        searchFieldName: 'proximity',
        componentType: POSTFILTER_COMPONENT_TYPE.RADIO_LIST,
        dynamic: false,
        eventSubType: 'PROXIMITY_SELECTION',
    },

    SOURCE: {
        label: 'PostFilters.Title.Source',
        type: POSTFILTER_TYPE.SOURCE,
        componentType: POSTFILTER_COMPONENT_TYPE.CHECKBOX_LIST,
        searchFieldName: 'source',
        defaultValue: ALL_ITEMS,
        listed: true,
        sort: POST_FILTERS_DEFAULT_SORT[POSTFILTER_TYPE.SOURCE],
        //flag for postFilters we get from elastic search
        dynamic: true,
        eventSubType: 'SOURCE_SELECTION',
    },
    SOURCE_NAME: {
        label: 'PostFilters.Title.Source',
        type: POSTFILTER_TYPE.SOURCE_NAME,
        componentType: POSTFILTER_COMPONENT_TYPE.CHECKBOX_LIST,
        searchFieldName: 'sourceName',
        defaultValue: ALL_ITEMS,
        listed: true,
        sort: POST_FILTERS_DEFAULT_SORT[POSTFILTER_TYPE.SOURCE_NAME],
        //flag for postFilters we get from elastic search
        dynamic: true,
        eventSubType: 'SOURCE_SELECTION',
    },

    SOURCE_TYPE: {
        label: 'PostFilters.Title.SourceType',
        type: POSTFILTER_TYPE.SOURCE_TYPE,
        componentType: POSTFILTER_COMPONENT_TYPE.CHECKBOX_LIST,
        searchFieldName: 'sourceType',
        defaultValue: ALL_ITEMS,
        displayInQueryType: COMPANY_SEARCH,
        listed: true,
        //flag for postFilters we get from elastic search
        dynamic: true,
        eventSubType: 'SOURCE_SELECTION',
    },

    LANGUAGE: {
        label: 'PostFilters.Title.Language',
        type: POSTFILTER_TYPE.LANGUAGE,
        searchFieldName: 'language',
        componentType: POSTFILTER_COMPONENT_TYPE.CHECKBOX_LIST,
        defaultValue: ALL_ITEMS,
        visibleItems: 20,
        listed: true,
        dynamic: true,
        eventSubType: 'LANGUAGE_SELECTION',
    },

    COMPANY: {
        label: 'PostFilters.Title.Company',
        type: POSTFILTER_TYPE.COMPANY,
        searchFieldName: 'company',
        componentType: POSTFILTER_COMPONENT_TYPE.CHECKBOX_LIST,
        // for biographical category show company post-filter only in person search
        specialDisplaySettings: {
            category: CATEGORY_NAMES.BIOGRAPHICAL,
            displayInSearchType: PERSON_SEARCH,
        },
        listed: true,
        defaultValue: ALL_ITEMS,
        //flag for postFilters we get from elastic search
        dynamic: true,
        eventSubType: 'COMPANY_SELECTION',
    },

    GEOGRAPHY: {
        label: 'PostFilters.Title.Geography',
        type: POSTFILTER_TYPE.GEOGRAPHY,
        searchFieldName: 'geography',
        componentType: POSTFILTER_COMPONENT_TYPE.GEOGRAPHY,
        listed: true,
        dynamic: true,
        eventSubType: 'GEOGRAPHY_SELECTION',
        hasFullTreeLoaded: false,
    },

    DOCKET_STATUS: {
        label: 'PostFilters.Title.DocketStatus',
        type: POSTFILTER_TYPE.DOCKET_STATUS,
        searchFieldName: 'status',
        componentType: POSTFILTER_COMPONENT_TYPE.CHECKBOX_LIST,
        defaultValue: ALL_ITEMS,
        listed: true,
        dynamic: false,
        eventSubType: 'DOCKETS_STATUS_SELECTION',
    },

    DUNS_FILTER: {
        label: 'PostFilters.Title.DunsFilter',
        type: POSTFILTER_TYPE.DUNS_FILTER,
        searchFieldName: FILTER_INFO.DUNS_FILTER,
        componentType: POSTFILTER_COMPONENT_TYPE.TEXT_FILTER,
        defaultValue: '',
        listed: true,
        dynamic: false,
        eventSubType: 'DUNS_FILTER_SELECTION',
    },

    SUBJECT: {
        label: 'PostFilters.Title.Subject',
        type: POSTFILTER_TYPE.SUBJECT,
        searchFieldName: 'subject',
        componentType: POSTFILTER_COMPONENT_TYPE.NESTED_CHECKBOX_LIST,
        listed: true,
        dynamic: true,
        eventSubType: 'SUBJECT_FILTER_SELECTION',
        hasFullTreeLoaded: false,
    },

    INDUSTRY: {
        label: 'PostFilters.Title.Industry',
        type: POSTFILTER_TYPE.INDUSTRY,
        searchFieldName: 'industry',
        componentType: POSTFILTER_COMPONENT_TYPE.NESTED_CHECKBOX_LIST,
        listed: true,
        dynamic: true,
        eventSubType: 'INDUSTRY_FILTER_SELECTION',
        hasFullTreeLoaded: false,
    },

    FUZZY_SEARCH: {
        label: 'PostFilters.Title.FuzzySearch',
        type: POSTFILTER_TYPE.FUZZY_SEARCH,
        searchFieldName: 'fuzzyOn',
        componentType: POSTFILTER_COMPONENT_TYPE.RADIO_LIST,
        dynamic: false,
        eventSubType: 'FUZZY_FILTER_SELECTION',
    },

    NEGATIVITY_LEVELS: {
        label: 'PostFilters.Title.Negativity',
        type: POSTFILTER_TYPE.NEGATIVITY_LEVELS,
        componentType: POSTFILTER_COMPONENT_TYPE.CHECKBOX_LIST,
        dynamic: false,
        eventSubType: 'NEGATIVITY_LEVELS_SELECTION',
    },

    TERMS: {
        label: 'PostFilters.Title.Term',
        type: POSTFILTER_TYPE.TERMS,
        dynamic: false,
        eventSubType: 'TERMS_SELECTION',
    },

    ESG_FACTORS: {
        label: 'PostFilters.Title.EsgFactors',
        type: POSTFILTER_TYPE.ESG_FACTORS,
        componentType: POSTFILTER_COMPONENT_TYPE.NESTED_CHECKBOX_LIST,
        searchFieldName: 'esgFactors',
        defaultValue: ALL_ITEMS,
        listed: true,
        dynamic: true,
        eventSubType: 'ESG_FACTORS_SELECTION',
        hasFullTreeLoaded: false,
    },
};

export const REMOVE_ALL_INCLUDE_TERMS = 'removeAllIncludeTerms';
export const INCLUDE = 'includeTerms';
export const EXCLUDE = 'excludeTerms';
export const TERMS = 'terms';

// used to determine the investigation subtype

export const ADDITIONAL_POSTFILTER_COMPONENTS = {
    SEARCH_WITHIN_INCLUDE: {
        type: INCLUDE,
        eventSubType: 'INCLUDE_TERMS_SELECTION',
        dynamic: false,
    },
    SEARCH_WITHIN_EXCLUDE: {
        type: EXCLUDE,
        eventSubType: 'EXCLUDE_TERMS_SELECTION',
        dynamic: false,
    },
    SEARCH_WITHIN_TERM: {
        type: TERMS,
        eventSubType: 'TERMS_SELECTION',
        dynamic: false,
    },
};

export const FE_LOCK_NAMES = {
    sourcesIncluded: 'sourcesIncluded',
    categoriesDefaultDateRange: 'categoriesDefaultDateRange', // master lock bypasses the next 3
    legalSourceDefaultDateRange: 'legalSourceDefaultDateRange',
    companySource: 'companySource',
    newsSearchesDateRange: 'newsSearchesDateRange',
    masterLockNewsCompanyLegalSearches: 'masterLockNewsCompanyLegalSearches', // master lock bypasses the next 1
    newsSearchesProximitySetting: 'newsSearchesProximitySetting',
    masterLockLegalSourceSearches: 'masterLockLegalSourceSearches', // master lock bypasses the next 2
    legalSourceDefaultLegalSources: 'legalSourceDefaultLegalSources',
    legalSearchNamePartyEntity: 'legalSearchNamePartyEntity',
    searchResults: 'searchResults',
    rearrangeOrder: 'rearrangeOrder',
    masterLockNewsSearchesPerson: 'masterLockNewsSearchesPerson', // master lock bypasses the next 5
    negativeNewsLanguagesDropdownPerson: 'negativeNewsLanguagesDropdownPerson',
    newsSearchSourcesPerson: 'newsSearchSourcesPerson',
    negativeSearchLanguagePerson: 'negativeSearchLanguagePerson',
    similarNameMatchPerson: 'similarNameMatchPerson',
    fuzzyThresholdPerson: 'fuzzyThresholdPerson',
    suggestedNames: 'suggestedNames',
    negativeSearchNegativityLevelsPerson: 'negativeSearchNegativityLevelsPerson',
    masterLockNewsSearchesCompany: 'masterLockNewsSearchesCompany', // master lock bypasses the next 4
    newsSearchSourcesCompany: 'newsSearchSourcesCompany',
    negativeSearchLanguageCompany: 'negativeSearchLanguageCompany',
    negativeSearchNegativityLevelsCompany: 'negativeSearchNegativityLevelsCompany',
    customNewsQueryPersonAll: 'customNewsQueryPersonAll',
    adminCustomNews1personCheck: 'adminCustomNews1personCheck',
    adminCustomNews2personCheck: 'adminCustomNews2personCheck',
    adminCustomNews3personCheck: 'adminCustomNews3personCheck',
    customNewsQueryCompanyAll: 'customNewsQueryCompanyAll',
    adminCustomNews1companyCheck: 'adminCustomNews1companyCheck',
    adminCustomNews2companyCheck: 'adminCustomNews2companyCheck',
    adminCustomNews3companyCheck: 'adminCustomNews3companyCheck',
    companySearchSpecifically: 'companySearchSpecifically',
    deliverySettingsAllOptions: 'deliverySettingsAllOptions',
    deliveryIncludeResearchSummary: 'deliveryIncludeResearchSummary',
    deliveryDocumentOnNewPage: 'deliveryDocumentOnNewPage',
    sanctionsAndWatchlistsRisk: 'sanctionsAndWatchlistsRisk',
    maxDocumentsInReportCategory: 'maxDocumentsInReportCategory',
    
};

// Used in BE request. Keys should be identical to FE_LOCK_NAME keys
export const BE_LOCK_NAMES = {
    sourcesIncluded: 'ADMIN_PREF_CONTENT_TYPE',
    categoriesDefaultDateRange: 'ADMIN_PREF_CATEGORIES_DEFAULT_DATE_RANGE', // master lock bypasses the next 3
    legalSourceDefaultDateRange: 'ADMIN_PREF_SEARCH_LEGAL_SOURCES',
    companySource: 'ADMIN_PREF_SEARCH_COMPANY_DATE_RANGE',
    newsSearchesDateRange: 'ADMIN_PREF_SEARCH_NEWS_DATE_RANGE',
    masterLockNewsCompanyLegalSearches: 'ADMIN_PREF_SEARCH_ALL_OPTIONS', // master lock bypasses the next 1
    newsSearchesProximitySetting: 'ADMIN_PREF_SEARCH_NEWS_PROXIMITY',
    masterLockLegalSourceSearches: 'ADMIN_PREF_LEGAL_ALL_OPTIONS', // master lock bypasses the next 2
    legalSourceDefaultLegalSources: 'ADMIN_PREF_SEARCH_LEGAL_DATE_RANGE',
    legalSearchNamePartyEntity: 'ADMIN_PREF_SEARCH_NAME_PARTY_ENTITY',
    searchResults: 'ADMIN_PREF_SHOW_SNAPSHOT',
    createNegativeTermsPerson: 'ADMIN_PREF_PERSON_EXTRA_TERM',
    similarNameMatchPerson: 'ADMIN_PREF_PERSON_SIMILAR_NAME',
    fuzzyThresholdPerson: 'ADMIN_PREF_PERSON_FUZZY_SEARCH',
    suggestedNames: 'ADMIN_PREF_PERSON_SUGGESTED_NAMES',
    createNegativeTermsCompany: 'ADMIN_PREF_COMPANY_EXTRA_TERM',
    rearrangeOrder: 'ADMIN_PREF_REPORT_ORDER_CONTENT',
    masterLockNewsSearchesPerson: 'ADMIN_PREF_PERSON_SEARCH_NEWS',
    masterLockNewsSearchesCompany: 'ADMIN_PREF_COMPANY_SEARCH_NEWS',
    newsSearchSourcesCompany: 'ADMIN_PREF_COMPANY_SEARCH_NEWS_SOURCES',
    newsSearchSourcesPerson: 'ADMIN_PREF_PERSON_SEARCH_NEWS_SOURCES',
    negativeSearchLanguagePerson: 'ADMIN_PREF_PERSON_NEGATIVE_TERM_QUERY',
    negativeSearchLanguageCompany: 'ADMIN_PREF_COMPANY_NEGATIVE_TERM_QUERY',
    negativeSearchNegativityLevelsCompany: 'ADMIN_PREF_PERSON_NEGATIVITY_LEVEL',
    negativeSearchNegativityLevelsPerson: 'ADMIN_PREF_COMPANY_NEGATIVITY_LEVEL',
    customNewsQueryPersonAll: 'ADMIN_PREF_PERSON_CUSTOM_NEWS_QUERY_ALL',
    adminCustomNews1personCheck: 'ADMIN_PREF_PERSON_CUSTOM_NEWS_QUERY_1',
    adminCustomNews2personCheck: 'ADMIN_PREF_PERSON_CUSTOM_NEWS_QUERY_2',
    adminCustomNews3personCheck: 'ADMIN_PREF_PERSON_CUSTOM_NEWS_QUERY_3',
    customNewsQueryCompanyAll: 'ADMIN_PREF_COMPANY_CUSTOM_NEWS_QUERY_ALL',
    adminCustomNews1companyCheck: 'ADMIN_PREF_COMPANY_CUSTOM_NEWS_QUERY_1',
    adminCustomNews2companyCheck: 'ADMIN_PREF_COMPANY_CUSTOM_NEWS_QUERY_2',
    adminCustomNews3companyCheck: 'ADMIN_PREF_COMPANY_CUSTOM_NEWS_QUERY_3',
    companySearchSpecifically: 'ADMIN_PREF_COMPANY_SEARCH_SPECIFICALLY',
    deliverySettingsAllOptions: 'ADMIN_PREF_DELIVERY_SETTINGS_ALL_OPTIONS',
    deliveryIncludeResearchSummary: 'ADMIN_PREF_DELIVERY_INCLUDE_RESEARCH_SUMMARY',
    deliveryDocumentOnNewPage: 'ADMIN_PREF_DELIVERY_DOCUMENT_ON_NEW_PAGE',
    sanctionsAndWatchlistsRisk: 'ADMIN_PREF_SANCTIONS_RISK',
    maxDocumentsInReportCategory: 'ADMIN_PREF_MAX_DOCUMENTS_IN_REPORT_CATEGORY'
};

// Mock for /api/user/preferences and /api/user/admin/preferences;
// Can be used in Main.reducers.js with USER_GET_PREFERENCES and ADMIN_GET_PREFERENCES in userLocks variable
// Item 'missing' from array means that the section is locked.
export const USER_LOCKS_RESPONSE = [
    // "ADMIN_PREF_CONTENT_TYPE",
    'ADMIN_PREF_SEARCH_ALL_OPTIONS',
    'ADMIN_PREF_SEARCH_NEWS_DATE_RANGE',
    'ADMIN_PREF_SEARCH_NEWS_PROXIMITY',
    'ADMIN_PREF_SEARCH_NEWS_SOURCES',
    'ADMIN_PREF_SEARCH_COMPANY_DATE_RANGE',
    'ADMIN_PREF_SEARCH_LEGAL_DATE_RANGE',
    'ADMIN_PREF_SEARCH_NAME_PARTY_ENTITY',
    'ADMIN_PREF_SHOW_SNAPSHOT',
    'ADMIN_PREF_COMPANY_NEGATIVE_TERM',
    'ADMIN_PREF_COMPANY_EXTRA_TERM',
    'ADMIN_PREF_PERSON_NEGATIVE_TERM',
    'ADMIN_PREF_PERSON_EXTRA_TERM',
    'ADMIN_PREF_PERSON_SIMILAR_NAME',
    'ADMIN_PREF_REPORT_ORDER_CONTENT',
    'ADMIN_PREF_COMPANY_SEARCH_SPECIFICALLY',
    'ADMIN_PREF_SANCTIONS_RISK',
    'ADMIN_PREF_MAX_DOCUMENTS_IN_REPORT_CATEGORY',
];

export const OPTION_CHECK = {
    CHECKED: true,
    PARTIAL: null,
    UNCHECKED: false,
};

export const BOOLEAN_TERM_TYPE = {
    INCLUDE_ALL: 'includeAll',
    INCLUDE_EXACT: 'includeExact',
    INCLUDE_ANY: 'includeAny',
    EXCLUDE_ALL: 'excludeAll',
};

export const PAGE_NAMES = {
    SEARCH_PAGE: 'searchPage',
};

export const CALENDAR_MONTH_YEAR_FORMAT_FE = 'MMMM YYYY';
export const CALENDAR_SHORT_DAY_FORMAT_FE = 'ddd';
export const CALENDAR_DATE_FORMAT_FE = 'll';
export const CALENDAR_DATE_FORMAT_BE = 'DD/MM/YYYY';
export const CALENDAR_RANGE_DELIMITER = ';';
export const RANGE_TYPE_TODAY = 'today';

export const ARTICLE_NOTE_LENGTH = 3000;

export const COOKIES_POLICY_URL = 'https://www.lexisnexis.com/global/privacy/privacy-cookies/?gid=1912&asa=';

export const DEFAULT_HELP_URL_ROOT = 'https://help.lexisnexis.com/Flare/diligencehelp/';

export const HELP_URLS = {
    HELP_URL_LOCALE: 'GB/',
    HELP_URL: {
        key: 'HELP_URL',
        path: '/Content/Home.htm',
    },
    CUSTOMER_SUPPORT_URL: {
        key: 'CUSTOMER_SUPPORT_URL',
        path: '/Content/reference/contactsupport_ref.htm',
    },
    FUZZY_HELP_URL: {
        key: 'FUZZY_HELP_URL',
        path: '/Content/reference/fuzzymatching_ref.htm',
    },
    CUSTOM_NEWS_SEARCH_URL: {
        key: 'CUSTOM_NEWS_SEARCH_URL',
        path: '/Content/task/customnewsquery_hdi.htm',
    },
};

export const ACTION_MODAL_TYPE_REPORTS_CATEGORY = 'REPORTS_CATEGORY';

export const MESSAGE_TIMEOUT = 5000;

export const SORT_OPTIONS = {
    DEFAULT_SORT_OPTION: 'relevance',
    SORT_OPTION_NEWEST: 'newest',
    SORT_OPTION_OLDEST: 'oldest',
};

export const BACKGROUND_MESSAGE_TYPE_PRINT = 'print';
export const BACKGROUND_MESSAGE_TYPE_DOWNLOAD = 'download';

export const COMPANY = 'Company';
export const PERSON = 'Person';

export const COMBINED_REPORT_DEFAULT_TITLE = 'Combined Report';

export const DEFAULT_FILENAME = 'Nexis_Diligence';
export const DOWNLOAD_DEFAULT_FILENAME = `${DEFAULT_FILENAME}(R)_Report_`;
export const DOWNLOAD_HISTORY_DEFAULT_FILENAME = `${DEFAULT_FILENAME}_History_`;
export const DOWNLOAD_SNAPSHOT_DEFAULT_FILENAME = `${DEFAULT_FILENAME}(R)_Snapshot_`;
export const BATCH_UPLOAD_DEFAULT_FILENAME = 'Batch_Upload_';
export const FILENAME_CHARACTERS_LIMIT = 150;
export const DOWNLOAD_BATCH_SCREENING_DEFAULT_FILENAME = `${DEFAULT_FILENAME}`;
export const MULTIPLE_ENTITIES_PREFIX_FILENAME = 'Entity_View';

export const SELECTED_ARTICLES = 'SELECTED_ARTICLES';
export const ALL_ARTICLES_REPORT = 'ALL_ARTICLES_REPORT';
export const NO_DOCUMENTS_OF_INTEREST = 'noDocumentsOfInterest';
export const NO_DOCUMENTS_FOUND = 'noDocumentsFound';
export const NO_DOCUMENTS_FOUND_DOC_ID = '-1';
export const SELECTED_ARTICLES_REPORT = 'SELECTED_ARTICLES_REPORT';

export const SELECTED_ITEMS = 'SELECTED_ITEMS';
export const ALL_ITEMS_LIST = 'ALL_ITEMS_LIST';

export const LOADED = 'loaded';
export const REPORT_EXPIRATION_DATE_RANGE = 30; //Number of days
export const REPORT_EXPIRATION_DATE_RANGE_PUBLIC_RECORDS = 1; //Number of days

export const ALERT_ACCESS_TYPE = {
    PRIVATE: 'PRIVATE',
    SHARED: 'SHARED',
};

export const CREATE_ALERT_DISABLED_LABEL = 'ResultsListHeader.CreateAlert.Disabled';

export const ITEM_VALUE_FIELD = 'count';

export const VIEWNAME_CHARACTERS_LIMIT = 25;

const hoursGenerator = (number) => {
    let hours = {};
    for (let i = 0; i < number; i++) {
        let label = i < 10 ? '0' + i + ':00' : i + ':00';
        hours[i] = {
            label: label,
            value: i,
        };
    }
    return hours;
};

const monthDaysGenerator = (number) => {
    let days = {};
    for (let i = 1; i <= number; i++) {
        days[i] = {
            label: 'Alerts.createAlert.frequency.label.day.' + i,
            value: i,
        };
    }
    return days;
};

export const ALERT_FREQUENCY_TYPE_OPTIONS_TAGS = {
    MONTH_DAYS: 'dayOfMonth',
    WEEK_DAYS: 'dayOfWeek',
    HOURS: 'hourOfDay',
};

export const ALERT_FREQUENCY_TYPE_OPTIONS = {
    MONTH_DAYS: {
        tag: ALERT_FREQUENCY_TYPE_OPTIONS_TAGS.MONTH_DAYS,
        values: monthDaysGenerator(28),
        defaultValue: 1,
        connector: 'Alerts.createAlert.frequency.connector.day',
    },
    WEEK_DAYS: {
        tag: ALERT_FREQUENCY_TYPE_OPTIONS_TAGS.WEEK_DAYS,
        connector: 'Alerts.createAlert.frequency.connector.day',
        defaultValue: 1,
        values: {
            MONDAY: {
                label: 'Alerts.createAlert.frequency.label.day.monday',
                value: 1,
            },
            TUESDAY: {
                label: 'Alerts.createAlert.frequency.label.day.tuesday',
                value: 2,
            },
            WEDNESDAY: {
                label: 'Alerts.createAlert.frequency.label.day.wednesday',
                value: 3,
            },
            THURSDAY: {
                label: 'Alerts.createAlert.frequency.label.day.thursday',
                value: 4,
            },
            FRIDAY: {
                label: 'Alerts.createAlert.frequency.label.day.friday',
                value: 5,
            },
            SATURDAY: {
                label: 'Alerts.createAlert.frequency.label.day.saturday',
                value: 6,
            },
            SUNDAY: {
                label: 'Alerts.createAlert.frequency.label.day.sunday',
                value: 7,
            },
        },
    },
    HOURS: {
        tag: ALERT_FREQUENCY_TYPE_OPTIONS_TAGS.HOURS,
        values: hoursGenerator(24),
        defaultValue: 8,
        connector: 'Alerts.createAlert.frequency.connector.time',
    },
};

export const FILE_FORMAT_TYPES = {
    PDF: {
        label: 'General_CoreFunctionality_pdf',
        englishLabel: 'PDF',
        value: DOC_TYPE.PDF,
    },
    DOCX: {
        label: 'General_CoreFunctionality_docx',
        englishLabel: 'Microsoft Word (.docx)',
        value: DOC_TYPE.DOCX,
    },
};

export const REPORT_CONTENT_TYPES = {
    SNAPSHOT: 'SNAPSHOT',
    FULL_REPORT: 'FULL_REPORT'
};

export const ALERT_FREQUENCY_TYPE = {
    HOURLY: {
        label: 'Alerts.createAlert.frequency.label.hourly',
        value: 'HOURLY',
    },
    DAILY: {
        label: 'Alerts.createAlert.frequency.label.daily',
        value: 'DAILY',
        options: [ALERT_FREQUENCY_TYPE_OPTIONS.HOURS],
    },
    WEEKLY: {
        label: 'Alerts.createAlert.frequency.label.weekly',
        value: 'WEEKLY',
        options: [ALERT_FREQUENCY_TYPE_OPTIONS.WEEK_DAYS, ALERT_FREQUENCY_TYPE_OPTIONS.HOURS],
    },
    MONTHLY: {
        label: 'Alerts.createAlert.frequency.label.monthly',
        value: 'MONTHLY',
        options: [ALERT_FREQUENCY_TYPE_OPTIONS.MONTH_DAYS, ALERT_FREQUENCY_TYPE_OPTIONS.HOURS],
    },
};

export const DAILY_NO_RESULTS_FROM_HOURLY = 'DAILY_NO_RESULTS_FROM_HOURLY';

export const SHOW_NO_RESULTS_ALERTS_WARNING = [ALERT_FREQUENCY_TYPE.HOURLY.value, DAILY_NO_RESULTS_FROM_HOURLY];

export const ALERT_DELIVERY_TYPE = {
    INLINE: {
        label: 'Alerts.createAlert.sendAs.label.inline',
        value: 'INLINE',
    },
    ATTACHMENT: {
        label: 'Alerts.createAlert.sendAs.label.attachment',
        value: 'ATTACHMENT',
    },
};

export const ALERT_ATTACHMENT_TYPE_LABELS = {
    PDF: 'General_CoreFunctionality_pdf',
    DOCX: 'General_CoreFunctionality_docx',
};

export const NEGATIVE_NEWS_LANGUAGES = {
    english: {
        label: 'UserPreferences.negativeNews.language.english',
        value: 'English',
    },
    french: {
        label: 'UserPreferences.negativeNews.language.french',
        value: 'French',
    },
    portuguese: {
        label: 'UserPreferences.negativeNews.language.portuguese',
        value: 'Portuguese',
    },
    german: {
        label: 'UserPreferences.negativeNews.language.german',
        value: 'German',
    },
    dutch: {
        label: 'UserPreferences.negativeNews.language.dutch',
        value: 'Dutch',
    },
    italian: {
        label: 'UserPreferences.negativeNews.language.italian',
        value: 'Italian',
    },
    spanish: {
        label: 'UserPreferences.negativeNews.language.spanish',
        value: 'Spanish',
    },
    turkish: {
        label: 'UserPreferences.negativeNews.language.turkish',
        value: 'Turkish',
    },
    swedish: {
        label: 'UserPreferences.negativeNews.language.swedish',
        value: 'Swedish',
    },
    malay: {
        label: 'UserPreferences.negativeNews.language.malay',
        value: 'Malay',
    },
    chinese: {
        label: 'UserPreferences.negativeNews.language.chinese',
        value: 'Chinese',
    },
    arabic: {
        label: 'UserPreferences.negativeNews.language.arabic',
        value: 'Arabic',
    },
    russian: {
        label: 'UserPreferences.negativeNews.language.russian',
        value: 'Russian',
    },
    polish: {
        label: 'UserPreferences.negativeNews.language.polish',
        value: 'Polish',
    },
    japanese: {
        label: 'UserPreferences.negativeNews.language.japanese',
        value: 'Japanese',
    },
    danish: {
        label: 'UserPreferences.negativeNews.language.danish',
        value: 'Danish',
    },
    norwegian: {
        label: 'UserPreferences.negativeNews.language.norwegian',
        value: 'Norwegian',
    },
    finnish: {
        label: 'UserPreferences.negativeNews.language.finnish',
        value: 'Finnish',
    },
    icelandic: {
        label: 'UserPreferences.negativeNews.language.icelandic',
        value: 'Icelandic',
    },
    others: {
        label: 'UserPreferences.negativeNews.language.others',
        value: 'Others',
    },

    multiple: {
        label: 'UserPreferences.negativeNews.language.multiple',
        value: 'Multiple',
    },
};

export const NEGATIVE_NEWS_SELECTED_LANGUAGE = {
    allNewsAllLanguages: {
        name: 'All News, All Languages',
        label: 'UserPreferences.negativeNews.languageDropdown.allNewsAllLanguages',
        values: [
            'English',
            'German',
            'Dutch',
            'French',
            'Spanish',
            'Portuguese',
            'Italian',
            'Arabic',
            'Turkish',
            'Chinese',
            'Japanese',
            'Russian',
            'Danish',
            'Finnish',
            'Icelandic',
            'Malay',
            'Norwegian',
            'Polish',
            'Swedish',
            'Others',
        ],
    },
    allEnglishLanguageNews: {
        name: 'All English Language News',
        label: 'UserPreferences.negativeNews.languageDropdown.allEnglishLanguageNews',
        values: ['English'],
    },
    allNonEnglishLanguageNews: {
        name: 'All Non-English Language News',
        label: 'UserPreferences.negativeNews.languageDropdown.allNonEnglishLanguageNews',
        values: [
            'German',
            'Dutch',
            'French',
            'Spanish',
            'Portuguese',
            'Italian',
            'Arabic',
            'Turkish',
            'Chinese',
            'Japanese',
            'Russian',
            'Danish',
            'Finnish',
            'Icelandic',
            'Malay',
            'Norwegian',
            'Polish',
            'Swedish',
            'Others',
        ],
        contentSource: 'news',
        contentLanguage: ['non-english'],
    },
    majorWorldPublications: {
        name: 'Major World Publications (English only)',
        label: 'UserPreferences.negativeNews.languageDropdown.majorWorldPublications',
        values: ['English'],
        contentSource: 'Major World Publications (English only)',
    },
    majorWorldNews: {
        name: 'Major World News (English only)',
        label: 'UserPreferences.negativeNews.languageDropdown.majorWorldNews',
        values: ['English'],
        contentSource: 'Major World News (English only)',
    },
    ukPublications: {
        name: 'UK Publications',
        label: 'UserPreferences.negativeNews.languageDropdown.ukPublications',
        values: ['English'],
        contentSource: 'UK Publications',
    },
    usNews: {
        name: 'US News',
        label: 'UserPreferences.negativeNews.languageDropdown.usNews',
        values: ['English'],
        contentSource: 'US News',
    },
    asiaPacificNews: {
        name: 'Asia Pacific News',
        label: 'UserPreferences.negativeNews.languageDropdown.asiaPacificNews',
        values: ['English'],
        contentSource: 'Asia Pacific News',
    },
    arabicLanguageNews: {
        name: 'Arabic Language News',
        label: 'UserPreferences.negativeNews.languageDropdown.arabicLanguageNews',
        values: ['Arabic'],
    },
    frenchLanguageNews: {
        name: 'French Language News',
        label: 'UserPreferences.negativeNews.languageDropdown.frenchLanguageNews',
        values: ['French'],
    },
    germanLanguageNews: {
        name: 'German Language News',
        label: 'UserPreferences.negativeNews.languageDropdown.germanLanguageNews',
        values: ['German'],
    },
    italianLanguageNews: {
        name: 'Italian Language News',
        label: 'UserPreferences.negativeNews.languageDropdown.italianLanguageNews',
        values: ['Italian'],
    },
    spanishLanguageNews: {
        name: 'Spanish Language News',
        label: 'UserPreferences.negativeNews.languageDropdown.spanishLanguageNews',
        values: ['Spanish'],
    },
    turkishLanguageNews: {
        name: 'Turkish Language News',
        label: 'UserPreferences.negativeNews.languageDropdown.turkishLanguageNews',
        values: ['Turkish'],
    },
    swedishLanguageNews: {
        name: 'Swedish Language News',
        label: 'UserPreferences.negativeNews.languageDropdown.swedishLanguageNews',
        values: ['Swedish'],
    },
    malayLanguageNews: {
        name: 'Malay Language News',
        label: 'UserPreferences.negativeNews.languageDropdown.malayLanguageNews',
        values: ['Malay'],
    },
    portugueseLanguageNews: {
        name: 'Portuguese Language News',
        label: 'UserPreferences.negativeNews.languageDropdown.portugueseLanguageNews',
        values: ['Portuguese'],
    },
    dutchLanguageNews: {
        name: 'Dutch Language News',
        label: 'UserPreferences.negativeNews.languageDropdown.dutchLanguageNews',
        values: ['Dutch'],
    },
    russianLanguageNews: {
        name: 'Russian Language News',
        label: 'UserPreferences.negativeNews.languageDropdown.russianLanguageNews',
        values: ['Russian'],
    },
    chineseLanguageNews: {
        name: 'Chinese Language News',
        label: 'UserPreferences.negativeNews.languageDropdown.chineseLanguageNews',
        values: ['Chinese'],
    },
    japaneseLanguageNews: {
        name: 'Japanese language news - Last 90 days',
        label: 'UserPreferences.negativeNews.languageDropdown.japaneseLanguageNews',
        values: ['Japanese'],
    },
    polishLanguageNews: {
        name: 'Polish Language News',
        label: 'UserPreferences.negativeNews.languageDropdown.polishLanguageNews',
        values: ['Polish'],
    },
};

// temporary until we implement all negative sources
export const MAP_MISSING_NEGATIVE_LANGUAGE_KEYS = {
    majorWorldPublications: {
        name: 'Major World Publications (English only)',
        values: ['English'],
        contentSource: 'Major World Publications (English only)',
    },
    majorWorldNews: {
        name: 'Major World News (English only)',
        values: ['English'],
        contentSource: 'Major World News (English only)',
    },
    ukPublications: {
        name: 'UK Publications',
        values: ['English'],
        contentSource: 'UK Publications',
    },
    usNews: {
        name: 'US News',
        values: ['English'],
        contentSource: 'US News',
    },
    asiaPacificNews: {
        name: 'Asia Pacific News',
        values: [
            'English',
            'French',
            'Spanish',
            'Arabic',
            'Turkish',
            'Chinese',
            'Japanese',
            'Russian',
            'Malay',
            'Others',
        ],
        contentSource: 'Asia Pacific News',
    },
    arabicLanguageNews: {
        name: 'Arabic Language News',
        values: ['Arabic'],
    },
    frenchLanguageNews: {
        name: 'French Language News',
        values: ['French'],
    },
    germanLanguageNews: {
        name: 'German Language News',
        values: ['German'],
    },
    italianLanguageNews: {
        name: 'Italian Language News',
        values: ['Italian'],
    },
    spanishLanguageNews: {
        name: 'Spanish Language News',
        values: ['Spanish'],
    },
    turkishLanguageNews: {
        name: 'Turkish Language News',
        values: ['Turkish'],
    },
    swedishLanguageNews: {
        name: 'Swedish Language News',
        values: ['Swedish'],
    },
    malayLanguageNews: {
        name: 'Malay Language News',
        values: ['Malay'],
    },
    portugueseLanguageNews: {
        name: 'Portuguese Language News',
        values: ['Portuguese'],
    },
    dutchLanguageNews: {
        name: 'Dutch Language News',
        values: ['Dutch'],
    },
    russianLanguageNews: {
        name: 'Russian Language News',
        values: ['Russian'],
    },
    chineseLanguageNews: {
        name: 'Chinese Language News',
        values: ['Chinese'],
    },
    japaneseLanguageNews: {
        name: 'Japanese language news - Last 90 days',
        values: ['Japanese'],
    },
    polishLanguageNews: {
        name: 'Polish language news - Last 90 days',
        values: ['Polish'],
    },
};

export const ALERT_COMMENTS_MAX_CHARS = 250;
export const ENTITIES_COMMENTS_MAX_CHARS = 250;
export const COST_CODE_MAX_CHARS = 30;
export const ALERT_ACCESS_TYPE_DEFAULT = ALERT_ACCESS_TYPE.PRIVATE;
export const ALERT_ATTACH_TYPE_DEFAULT = DOC_TYPE.PDF;
export const ALERT_FREQUENCY_TYPE_DEFAULT = ALERT_FREQUENCY_TYPE.HOURLY.value;
export const ALERT_DELIVERY_TYPE_DEFAULT = ALERT_DELIVERY_TYPE.INLINE.value;

export const SELECTED_DROPDOWN_RESULTS_LIST = 'dropdownResultsList';
export const SELECTED_DROPDOWN_ALERTS_LIST = 'dropdownAlertsList';
export const SELECTED_DROPDOWN_HISTORY_LIST = 'dropdownHistoryList';

export const ALERT_SORT_BY = 'lastUpdated';
export const ALERT_DIRECTION = 'desc';

export const SORT_DIRECTION = {
    DEFAULT: 'default',
    ASC: 'asc',
    DESC: 'desc',
};

export const UBO_FILTER_DROPDOWN_OPTIONS = [
    {
        'UboDocumentView.Tabs.TableView.Filtering_Dropdown_ShowAllOwners': 0,
    },
    {
        'UboDocumentView.Tabs.TableView.Filtering_Dropdown_Show25%+Owners': 25,
    },
    {
        'UboDocumentView.Tabs.TableView.Filtering_Dropdown_Show50%+Owners': 50,
    },
];

export const UBO_DEFAULT_SORTING_AND_FILTERING = {
    sorting: {
        sortBy: 'beneficialOwnershipPercentage',
        direction: SORT_DIRECTION.DESC,
    },
    filtering: {
        filteringDropdownSelected: 0,
    },
};

export const DATE_RANGE_MIXED = 'mixed';

export const FILTERS_MAX_CHARS = 10;
export const COMPANY_FILTER_MAX_CHARS = 20;
export const FILTERS_DATE_RANGE_DEFAULTS = ['1m', '6m', '2y', '5y', 'all', 'custom'];

export const FILTER_BAR_DATE_RANGE_CUSTOM_LABEL = 'Custom';

export const CUSTOM_QUERY_NEGATIVITY_STRING_LANGUAGE = 'English';
export const ALERTS_ENABLED_DEFAULT = true;
export const PUBLIC_RECORDS_ENABLED_DEFAULT = false;

export const POPUP_SIZE_LARGE = 'large';
export const POPUP_SIZE_MEDIUM = 'medium';
export const POPUP_SIZE_SMALL = 'small';
export const POPUP_SIZE_EXTRA_SMALL = 'xsmall';

export const POPUP_BUTTON_TYPES = {
    save: 'save',
    cancel: 'cancel',
    reset: 'reset',
    ok: 'ok',
    download: 'download',
    accept: 'accept',
    reject: 'reject',
    upload: 'upload',
};

export const TOOLTIP_MAX_SIZE = 120;

export const RANGE_TYPE_CUSTOM = 'custom';
export const RANGE_TYPE_ALL = 'all';

export const COST_CODE = {
    customCostCode: {
        name: 'customCostCode',
        value: 'customCostCode',
        label: 'UserPreferences_CostCode.customCostCode',
    },

    empty: {
        name: '',
        value: '',
        label: 'UserPreferences_cost_code.dropdown.selectOption',
    },
};

export const EMPTY = 'empty';
export const COST_CODE_KEY = 'costCode';
export const POST_FILTERS_KEY = 'postFilters';
export const CONTENT_SOURCE_KEY = 'contentSource';
export const BILLING_ID_DATE_FORMAT = 'YYYYMMDDHHmmss';

export const MAX_NUMBER_OF_CUSTOM_NEWS_QUERIES = 3;
export const CHANGED_BY_ADMIN = 'changedByAdmin';

export const ERRORS = {
    TABLE_LOAD_DATA_GET: 'errors_alert_get',
    INVALID_QUERY: 'INVALID_QUERY',
    TOO_MANY_REPORTS_DOWNLOAD: 'Too many reports to be downloaded',
    TOO_MANY_REPORTS_EMAIL: 'Too many reports to be emailed',
    TOO_MANY_REPORTS_PRINT: 'Too many reports to be printed',
};

export const ERROR_CODES = {
    INTERNAL_SERVER_ERROR: 500,
    INVALID_QUERY_STRING: 400,
};

const uboErrorComposedMessage = {
    firstPart: 'UboSingleDocumentView.ErrorMessage.documentNotFound.body',
    secondPart: 'UboSingleDocumentView.ErrorMessage.documentNotFound.body.extraDetails',
};

export const UBO_ERRORS = {
    DNB_API_ERROR: uboErrorComposedMessage, //generic catch all for DNB API errors
    10009: uboErrorComposedMessage,
    10010: uboErrorComposedMessage,
    maxNodes: {
        firstPart: 'UboDocumentView.Errors.maxNodes',
    },
};

export const START_LOCATION_HASH = '#/start';
export const EDIT_LOCATION_HASH = '#/start/edit-search';
export const GOOGLE_ANONYMIZE_LOCATION_HASH = '#/google-anonymized';

export const USER_PREFERENCES = 'userPreferences';
export const PERSON_CHECK = 'personCheck';
export const COMPANY_CHECK = 'companyCheck';
export const GENERAL_SETTINGS = 'generalSettings';
export const DELIVERY_SETTINGS = 'deliverySettings';
export const PERSON_CHECK_NEWS_LANGUAGES_SELECTION = 'personCheckNewsLanguageSelection';
export const COMPANY_CHECK_NEWS_LANGUAGES_SELECTION = 'companyCheckNewsLanguageSelection';
export const SANCTIONS_WATCHLISTS_SECTION = 'sanctionsWatchListsSection';

export const USER_PREFERENCES_SECTIONS = {
    [GENERAL_SETTINGS]: {
        SOURCES: 'sources',
        NEWS_SOURCES: 'newsSources',
        COMPANY_SOURCES: 'companySources',
        LEGAL_SOURCES: 'legalSources',
        SEARCH_RESULTS: 'searchResults',
        COST_CODE: 'costCode',
        DEFAULT_DATE: 'defaultDate',
        SANCTIONS_WATCHLIST: 'sanctionsSources',
    },
    [PERSON_CHECK]: {
        // languages & custom query
        NEWS_SEARCHES_SOURCES: 'personCheckNewsSearchesSources',
        NEWS_LANGUAGE_SELECTION: 'personCheckNewsLanguageSelection',
        NEWS_NEGATIVITY_LEVELS: 'personCheckNewsNegativityLevels',
        SIMILAR_NAMES: 'similarNames',
        SUGGESTED_NAMES: 'suggestedNames',
        CUSTOM_NEWS: 'personCheckCustomNews',
    },
    [COMPANY_CHECK]: {
        // languages & custom query
        NEWS_SEARCHES_SOURCES: 'companyCheckNewsSearchesSources',
        NEWS_LANGUAGE_SELECTION: 'companyCheckNewsLanguageSelection',
        NEWS_NEGATIVITY_LEVELS: 'companyCheckNewsNegativityLevels',
        NEWS_SEARCH_OPTION: 'companyCheckNewsSearchOption',
        CUSTOM_NEWS: 'companyCheckCustomNews',
    },
    [DELIVERY_SETTINGS]: {
        REPORT_BUILDER: 'reportBuilder',
        DELIVERY_SETTINGS: 'deliverySettings',
        MAX_DOCUMENTS_IN_REPORT_CATEGORY: 'maxDocumentsInReportCategory'
    },
};

// when navigating articles; how many positions until the border to load the next page
export const ARTICLE_NAVIGATION_LOAD_THRESHOLD = 3;
export const CHANGED_PREFERENCES_IGNORED_KEYS = [
    'prefsUpdatedByAdmin',
    'language',
    'searchType',
    'sort',
    'isTrendsExpanded, isFilterSectionCollapsed',
];
export const CHANGED_PREFERENCES_MESSAGE_IDS_MAPPING = {
    mainSectionsIds: {
        generalSettings: 'UserPreferences.leftSideNavigation.generalSettings',
        personCheck: 'UserPreferences.leftSideNavigation.personCheck',
        companyCheck: 'UserPreferences.leftSideNavigation.companyCheck',
        deliverySettings: 'UserPreferences.leftSideNavigation.deliverySettings',
    },

    getSubSectionsMessageIds: (subSection) => {
        switch (subSection) {
            case 'contentTypes':
                return ['UserPreferences_topic_sources_defaultSearch', 'UserPreferences_topic_sources_included'];
            case 'newsSources':
            case 'proximity':
                return ['UserPreferences_topic_sources_defaultSearch', 'UserPreferences_topic_news_source_searches'];
            case 'companySources':
                return ['UserPreferences_topic_sources_defaultSearch', 'UserPreferences_topic_company_source_searches'];
            case 'legalSources':
                return ['UserPreferences_topic_sources_defaultSearch', 'UserPreferences_topic_legal_source_searches'];
            case 'newsSources_dateRange':
                return [
                    'UserPreferences_topic_sources_defaultSearch',
                    'AdHocSearch.Popup.DateRanges.CategoryName',
                    'UserPreferences.CategoriesDefaultDates.news',
                ];
            case 'companySources_dateRange':
                return [
                    'UserPreferences_topic_sources_defaultSearch',
                    'AdHocSearch.Popup.DateRanges.CategoryName',
                    'UserPreferences.CategoriesDefaultDates.company',
                ];
            case 'legalSources_dateRange':
                return [
                    'UserPreferences_topic_sources_defaultSearch',
                    'AdHocSearch.Popup.DateRanges.CategoryName',
                    'UserPreferences.CategoriesDefaultDates.legal',
                ];
            case 'showSnapshot':
                return ['UserPreferences_topic_sources_defaultSearch', 'UserPreferences_topic_show_snapshot'];
            case 'costCodesList':
            case 'isCostCodeRequired':
            case 'chooseOnlyFromCostCodesList':
                return ['UserPreferences_topic_sources_defaultSearch', 'UserPreferences_topic_cost_code'];
            case 'fuzzyMatch':
                return ['UserPreferences_topic_sources_similarNameMatch'];
            case 'enableFuzzy':
                return ['UserPreferences_topic_sources_similarNameMatch'];
            case 'fuzzyThreshold':
                return ['UserPreferences_topic_sources_fuzzyThreshold'];
            case 'fuzzyOn':
                return ['UserPreferences_topic_sources_fuzzyThreshold'];
            case 'suggestedNames':
                return ['UserPreferences_topic_sources_suggestedNames'];
            case 'adminCustomNewsQuery':
                return ['UserPreferences_topic_customNewsSearches'];
            case 'customQuery':
                return ['UserPreferences_topic_newsSearches', 'UserPreferences.negativeNews.languages.topic'];
            case 'newsSearchSources':
                return ['UserPreferences_topic_newsSearches', 'UserPreferences_news_searches_sources_label'];
            case 'negativityLevel':
                return ['UserPreferences_topic_newsSearches', 'UserPreferences.negativeTerms.negativityLevels'];
            case 'reportOrder':
                return ['UserPreferences_topic_reportBuilder'];
            case 'resultsContentOptions':
            case 'results':
                return ['BREADCRUMBS.results'];
            case 'contentsOptions':
            case 'reportBuilder':
                return ['BREADCRUMBS.reportBuilder'];
            case 'shouldContainResearchSummary':
                return ['UserPreferences.IncludeResearchSummary'];
            case 'startEachArticleOnNewPage':
                return ['UserPreferences.DocumentOnNewPage'];
            case 'excludeNonBusinessNews':
                return [
                    'UserPreferences_topic_sources_defaultSearch',
                    'UserPreferences_topic_news_source_searches',
                    'UserPreferences_topic_news_source_searches_exclude_news_wires',
                ];
            case 'excludeNewsWires':
                return [
                    'UserPreferences_topic_sources_defaultSearch',
                    'UserPreferences_topic_news_source_searches',
                    'UserPreferences_topic_news_source_searches_exclude_non_business_news',
                ];
            default:
                return [];
        }
    },
};

export const COMPANY_SOURCES_SEARCH_OPTION = {
    SPECIFICALLY: 'specifically',
    MENTIONS: 'mentions',
};

export const LEGAL_SOURCES_ALL_OF_THEM = 'all';

export const INVALID_CHARACTERS_SEARCH = /\r?\n|\r|\\|\/|\?|#/g;

export const DOCUMENT_VIEW_TYPE_PREVIEW = 'PREVIEW';

export const UBO_MIN_QUERY_LENGTH = 3;
export const UBO_URL_PARAM = 'uboSelected';
export const UBO_MAIN_CATEGORY = 'ubo';
export const UBO_CHILD_BLUEPRINT_TAG = 'dnbChildBlueprint';
export const UBO_BENEFICIARY_TYPE_INDIVIDUAL = 'Individuals';
export const UBO_BENEFICIARY_TYPE_BUSINESS = 'Businesses';
export const UBO_BENEFICIARY_TYPE_INDIVIDUAL_CODE = 119;
export const UBO_BENEFICIARY_TYPE_BUSINESS_CODE = 118;
export const UBO_USE_MEMO = true;
export const UBO_EXTENDED_GRAPH_MAX_NODES = 100;
export const UBO_GRAPH = {
    width: 1800,
    height: 1200,
    layerSize: 300,
    leafWidth: 250,
    percentageTextOffsetX: 0,
    percentageTextOffsetY: 0,
    forceDistance: 250,
    forceStrength: 0.5,
    chargeStrength: -400,
    boxHeight: 100,
    boxWidth: 200,
    arrowAngle: 30,
    zoomStep: 0.02,
    zoomLevels: [0.2, 2],
    curveOffsetX: 40,
    zoomAdjustThrottleTime: 1000,
};

export const UBO_DOCUMENT_NAME_REFERENCE = 'ubo_';
export const CATEGORY_TYPE_UBO = 'category_type_ubo';
export const CATEGORY_TYPE_UBO_CHILD = 'category_type_ubo_child';
export const CATEGORY_TYPE_REGULAR = 'category_type_regular';
export const UBO_CATEGORY_DUNS = 'duns_category';
export const UBO_CATEGORY_TERM = 'term_category';

// ubo post filter assignment
export const UBO_POST_FILTERS = {
    [UBO_CATEGORY_TERM]: [POSTFILTER_COMPONENTS.DUNS_FILTER],
    [UBO_CATEGORY_DUNS]: [],
};

/*** BRAZILIAN OWNERSHIP ***/
export const BRAZILIAN_OWNERSHIP_PCSI = 442331;
export const BOV_TEXT_WRAPPING_MODE = {
    NODE: 'NODE',
    CONTAINER: 'CONTAINER',
};

export const BOV_TOOLTIP_CONTENT_ORDER = ['cadastralSituation', 'street', 'city', 'state', 'postalCode', 'country'];

export const BOV_GRAPH = {
    width: 1800,
    height: 1200,
    forceDistance: 400,
    forceDistanceFromMainNode: 400,
    forceStrength: 0.5,
    chargeStrength: -2500,
    chargeStrengthFromMainNode: -5000,
    zoomStep: 0.04,
    zoomLevels: [0.4, 2],
    zoomAdjustThrottleTime: 500,
};

export const BOV_NODE_TYPES = {
    ROOT: 'root',
    PERSON: 'person',
    COMPANY: 'company',
};

export const BOV_RELATIONSHIPS_TYPES = {
    PROFESSIONAL: 'PROFESSIONAL',
    FAMILY: 'FAMILY',
    UNKNOWN: 'UNKNOWN',
};

export const DOCKET_STATUS_OPTION = {
    OPEN: 'Open',
    CLOSED: 'Closed',
};

export const ISO_COUNTRIES = {
    AF: 'Afghanistan',
    AX: 'Aland Islands',
    AL: 'Albania',
    DZ: 'Algeria',
    AS: 'American Samoa',
    AD: 'Andorra',
    AO: 'Angola',
    AI: 'Anguilla',
    AQ: 'Antarctica',
    AG: 'Antigua And Barbuda',
    AR: 'Argentina',
    AM: 'Armenia',
    AW: 'Aruba',
    AU: 'Australia',
    AT: 'Austria',
    AZ: 'Azerbaijan',
    BS: 'Bahamas',
    BH: 'Bahrain',
    BD: 'Bangladesh',
    BB: 'Barbados',
    BY: 'Belarus',
    BE: 'Belgium',
    BZ: 'Belize',
    BJ: 'Benin',
    BM: 'Bermuda',
    BT: 'Bhutan',
    BO: 'Bolivia',
    BA: 'Bosnia And Herzegovina',
    BW: 'Botswana',
    BV: 'Bouvet Island',
    BR: 'Brazil',
    IO: 'British Indian Ocean Territory',
    BN: 'Brunei Darussalam',
    BG: 'Bulgaria',
    BF: 'Burkina Faso',
    BI: 'Burundi',
    KH: 'Cambodia',
    CM: 'Cameroon',
    CA: 'Canada',
    CV: 'Cape Verde',
    KY: 'Cayman Islands',
    CF: 'Central African Republic',
    TD: 'Chad',
    CL: 'Chile',
    CN: 'China',
    CX: 'Christmas Island',
    CC: 'Cocos (Keeling) Islands',
    CO: 'Colombia',
    KM: 'Comoros',
    CG: 'Congo',
    CD: 'Congo, Democratic Republic',
    CK: 'Cook Islands',
    CR: 'Costa Rica',
    CI: "Cote D'Ivoire",
    HR: 'Croatia',
    CU: 'Cuba',
    CY: 'Cyprus',
    CZ: 'Czech Republic',
    DK: 'Denmark',
    DJ: 'Djibouti',
    DM: 'Dominica',
    DO: 'Dominican Republic',
    EC: 'Ecuador',
    EG: 'Egypt',
    SV: 'El Salvador',
    GQ: 'Equatorial Guinea',
    ER: 'Eritrea',
    EE: 'Estonia',
    ET: 'Ethiopia',
    FK: 'Falkland Islands (Malvinas)',
    FO: 'Faroe Islands',
    FJ: 'Fiji',
    FI: 'Finland',
    FR: 'France',
    GF: 'French Guiana',
    PF: 'French Polynesia',
    TF: 'French Southern Territories',
    GA: 'Gabon',
    GM: 'Gambia',
    GE: 'Georgia',
    DE: 'Germany',
    GH: 'Ghana',
    GI: 'Gibraltar',
    GR: 'Greece',
    GL: 'Greenland',
    GD: 'Grenada',
    GP: 'Guadeloupe',
    GU: 'Guam',
    GT: 'Guatemala',
    GG: 'Guernsey',
    GN: 'Guinea',
    GW: 'Guinea-Bissau',
    GY: 'Guyana',
    HT: 'Haiti',
    HM: 'Heard Island & Mcdonald Islands',
    VA: 'Holy See (Vatican City State)',
    HN: 'Honduras',
    HK: 'Hong Kong',
    HU: 'Hungary',
    IS: 'Iceland',
    IN: 'India',
    ID: 'Indonesia',
    IR: 'Iran, Islamic Republic Of',
    IQ: 'Iraq',
    IE: 'Ireland',
    IM: 'Isle Of Man',
    IL: 'Israel',
    IT: 'Italy',
    JM: 'Jamaica',
    JP: 'Japan',
    JE: 'Jersey',
    JO: 'Jordan',
    KZ: 'Kazakhstan',
    KE: 'Kenya',
    KI: 'Kiribati',
    KR: 'Korea',
    KW: 'Kuwait',
    KG: 'Kyrgyzstan',
    LA: "Lao People's Democratic Republic",
    LV: 'Latvia',
    LB: 'Lebanon',
    LS: 'Lesotho',
    LR: 'Liberia',
    LY: 'Libyan Arab Jamahiriya',
    LI: 'Liechtenstein',
    LT: 'Lithuania',
    LU: 'Luxembourg',
    MO: 'Macao',
    MK: 'Macedonia',
    MG: 'Madagascar',
    MW: 'Malawi',
    MY: 'Malaysia',
    MV: 'Maldives',
    ML: 'Mali',
    MT: 'Malta',
    MH: 'Marshall Islands',
    MQ: 'Martinique',
    MR: 'Mauritania',
    MU: 'Mauritius',
    YT: 'Mayotte',
    MX: 'Mexico',
    FM: 'Micronesia, Federated States Of',
    MD: 'Moldova',
    MC: 'Monaco',
    MN: 'Mongolia',
    ME: 'Montenegro',
    MS: 'Montserrat',
    MA: 'Morocco',
    MZ: 'Mozambique',
    MM: 'Myanmar',
    NA: 'Namibia',
    NR: 'Nauru',
    NP: 'Nepal',
    NL: 'Netherlands',
    AN: 'Netherlands Antilles',
    NC: 'New Caledonia',
    NZ: 'New Zealand',
    NI: 'Nicaragua',
    NE: 'Niger',
    NG: 'Nigeria',
    NU: 'Niue',
    NF: 'Norfolk Island',
    MP: 'Northern Mariana Islands',
    NO: 'Norway',
    OM: 'Oman',
    PK: 'Pakistan',
    PW: 'Palau',
    PS: 'Palestinian Territory, Occupied',
    PA: 'Panama',
    PG: 'Papua New Guinea',
    PY: 'Paraguay',
    PE: 'Peru',
    PH: 'Philippines',
    PN: 'Pitcairn',
    PL: 'Poland',
    PT: 'Portugal',
    PR: 'Puerto Rico',
    QA: 'Qatar',
    RE: 'Reunion',
    RO: 'Romania',
    RU: 'Russian Federation',
    RW: 'Rwanda',
    BL: 'Saint Barthelemy',
    SH: 'Saint Helena',
    KN: 'Saint Kitts And Nevis',
    LC: 'Saint Lucia',
    MF: 'Saint Martin',
    PM: 'Saint Pierre And Miquelon',
    VC: 'Saint Vincent And Grenadines',
    WS: 'Samoa',
    SM: 'San Marino',
    ST: 'Sao Tome And Principe',
    SA: 'Saudi Arabia',
    SN: 'Senegal',
    RS: 'Serbia',
    SC: 'Seychelles',
    SL: 'Sierra Leone',
    SG: 'Singapore',
    SK: 'Slovakia',
    SI: 'Slovenia',
    SB: 'Solomon Islands',
    SO: 'Somalia',
    ZA: 'South Africa',
    GS: 'South Georgia And Sandwich Isl.',
    ES: 'Spain',
    LK: 'Sri Lanka',
    SD: 'Sudan',
    SR: 'Suriname',
    SJ: 'Svalbard And Jan Mayen',
    SZ: 'Swaziland',
    SE: 'Sweden',
    CH: 'Switzerland',
    SY: 'Syrian Arab Republic',
    TW: 'Taiwan',
    TJ: 'Tajikistan',
    TZ: 'Tanzania',
    TH: 'Thailand',
    TL: 'Timor-Leste',
    TG: 'Togo',
    TK: 'Tokelau',
    TO: 'Tonga',
    TT: 'Trinidad And Tobago',
    TN: 'Tunisia',
    TR: 'Turkey',
    TM: 'Turkmenistan',
    TC: 'Turks And Caicos Islands',
    TV: 'Tuvalu',
    UG: 'Uganda',
    UA: 'Ukraine',
    AE: 'United Arab Emirates',
    GB: 'United Kingdom',
    US: 'United States',
    UM: 'United States Outlying Islands',
    UY: 'Uruguay',
    UZ: 'Uzbekistan',
    VU: 'Vanuatu',
    VE: 'Venezuela',
    VN: 'Viet Nam',
    VG: 'Virgin Islands, British',
    VI: 'Virgin Islands, U.S.',
    WF: 'Wallis And Futuna',
    EH: 'Western Sahara',
    YE: 'Yemen',
    ZM: 'Zambia',
    ZW: 'Zimbabwe',
};

export const DELIVERY_CONTENT_OPTIONS = {
    TOC: 'tableOfContents',
    snapshot: 'shouldContainSnapshot',
    coverPage: 'shouldContainCoverPage',
    fullDocs: 'shouldContainFullDocs',
    researchSummary: 'shouldContainResearchSummary',
};

export const SHOW_DELIVERY_OPTIONS_REPORT = 'showDeliveryOptions';
export const SHOW_DELIVERY_OPTIONS_RESULTS_LIST = 'resultsListShowDeliveryOptions';
export const START_ARTICLE_ON_NEW_PAGE = 'startEachArticleOnNewPage';

export const CONTENT_OPTIONS_ORDER = [
    DELIVERY_CONTENT_OPTIONS.coverPage,
    DELIVERY_CONTENT_OPTIONS.snapshot,
    DELIVERY_CONTENT_OPTIONS.TOC,
    DELIVERY_CONTENT_OPTIONS.fullDocs,
    DELIVERY_CONTENT_OPTIONS.researchSummary,
];

export const DELIVERIES_TOC_OPTIONS = {
    expanded: 'EXPANDED',
    basic: 'BASIC',
    none: 'NONE',
};

export const IGNORE_DELIVERY_LIMITS = [ALL_ARTICLES_REPORT, ALL_ITEMS_LIST, NO_DOCUMENTS_OF_INTEREST];

export const DEFAULT_DELIVERY_OPTIONS = {
    snapshotDeliveries: {
        shouldContainSnapshot: true,
        shouldContainCoverPage: true,
        shouldContainFullDocs: false,
        shouldContainResearchSummary: true,
        tableOfContents: DELIVERIES_TOC_OPTIONS.expanded,
    },
    documentViewDeliveries: {
        shouldContainCoverPage: true,
        shouldContainFullDocs: true,
        shouldContainResearchSummary: true,
        tableOfContents: DELIVERIES_TOC_OPTIONS.none,
    },
    snapshotPageOnlyDelivery: {
        shouldContainSnapshot: true,
        shouldContainCoverPage: false,
        shouldContainFullDocs: false,
        shouldContainResearchSummary: false,
        tableOfContents: DELIVERIES_TOC_OPTIONS.none,
    },
};

export const ARTICLE_NUMBER_TYPES = {
    SIC: 'SIC',
    NAICS: 'NAICS',
};

export const ARTICLE_PUBLICATION_TYPES = {
    Webnews: 'WebLinks',
};

export const NEWS_CONTENT_TYPES = [CATEGORY_NAMES.NEGATIVE_NEWS, CATEGORY_NAMES.NEWS, CATEGORY_NAMES.CUSTOM_NEWS];

export const DOCKETS_SOURCES = [CATEGORY_NAMES.FEDERAL_DOCKETS, CATEGORY_NAMES.STATE_DOCKETS];

export const DAY_TO_MILISECONDS = 86400000;
export const HOUR_TO_MILISECONDS = 3600000;

export const POST_FILTER_LABELS = {
    INDUSTRY: 'industryLabels',
    SUBJECT: 'subjectLabels',
    ESG_FACTORS: 'esgFactorsLabels',
};

export const POST_FILTERS_WITH_PATH = [
    POST_FILTER_GEOGRAPHY,
    POST_FILTER_INDUSTRY,
    POST_FILTER_SUBJECT,
    POST_FILTER_ESG_FACTORS,
];
export const CUSTOM_POST_FILTER_ARRAY = [
    POSTFILTER_TYPE.SUBJECT,
    POSTFILTER_TYPE.INDUSTRY,
    POSTFILTER_TYPE.ESG_FACTORS,
];
export const CUSTOM_UNCHECK_PREVENT_ARRAY = [
    POSTFILTER_TYPE.SUGGESTED_NAMES,
    POSTFILTER_TYPE.SIMILAR_NAMES,
    POSTFILTER_TYPE.ESG_FACTORS,
];

export const NESTED_POST_FILTER_PATH = {
    INITIAL: '',
    FULL: 'all',
};

export const MODAL_TAB_TYPE = {
    BASIC: 'BASIC',
    ADVANCED: 'ADVANCED',
};

export const DEFAULT_FILE_NAMES = {
    excel: 'NexisDiligence_Table',
};

export const DEFAULT_DATE_RANGES = [
    'all',
    'today',
    '1w',
    '1m',
    '3m',
    '6m',
    '1y',
    '2y',
    '3y',
    '4y',
    '5y',
    '6y',
    '7y',
    '8y',
    '9y',
    '10y',
    'custom',
];

export const REGISTERED_TRADEMARK = '&#174;';
export const TRADEMARK = '&#8482;';

export const DEFAULT_EXCLUDE_NEWS_TOGGLE_VALUES = {
    [POST_FILTER_EXCLUDE_NON_BUSINESS_NEWS]: true,
    [POST_FILTER_EXCLUDE_NEWS_WIRES]: true,
};

export const MAX_TERMS_WITH_SUGGESTIONS = 3;
export const MAX_SUGGESTIONS_PER_TERM = 12;

export const USER_ROLES = {
    ADMINISTRATOR: 'Administrator',
    USER: 'User',
};

export const CUSTOM_NEWS_QUERY_TYPES_ID = {
    ADMIN: 'UserPreferences_topic_customNewsSearches_table_queryType_admin',
    USER: 'UserPreferences_topic_customNewsSearches_table_queryType_user',
    LN: 'UserPreferences_topic_customNewsSearches_table_queryType_lexisNexis',
};

export const CUSTOM_NEWS_QUERY_ESG_TITLE = 'ESG News';

export const USER_ROLE_CHANGED = 'userRoleChanged';
export const ADMIN_PERMISSION_CHANGED = 'adminPermissionChanged';

export const MENTION_TYPES = {
    PERSON: 'Person',
    COMPANY: 'Company',
    ORGANIZATION: 'Organization',
    UBO_INDIVIDUAL: UBO_BENEFICIARY_TYPE_INDIVIDUAL,
    UBO_BUSINESS: UBO_BENEFICIARY_TYPE_BUSINESS,
};
export const NEGATIVITY_LEVELS = {
    LOW: 'low',
    MEDIUM: 'medium',
    HIGH: 'high',
    DEFAULT: 'default',
};

export const NEGATIVITY_COLORS = {
    LOW: '#616161',
    MEDIUM: '#2874ba',
    HIGH: '#c00d0d',
    DEFAULT: '#373739',
};

export const NEGATIVITY_BUCKET_NAMES = {
    LOW: 'lowNegativeNewsBuckets',
    MEDIUM: 'mediumNegativeNewsBuckets',
    HIGH: 'highNegativeNewsBuckets',
    DEFAULT: 'negativeNewsBuckets',
};

export const TERMS_NEGATIVITY_MAPPING = {
    LOW: {
        field: NEGATIVITY_LEVELS.LOW,
        color: NEGATIVITY_COLORS.LOW,
    },
    MEDIUM: {
        field: NEGATIVITY_LEVELS.MEDIUM,
        color: NEGATIVITY_COLORS.MEDIUM,
    },
    HIGH: {
        field: NEGATIVITY_LEVELS.HIGH,
        color: NEGATIVITY_COLORS.HIGH,
    },
    DEFAULT: {
        field: NEGATIVITY_LEVELS.DEFAULT,
        color: NEGATIVITY_COLORS.DEFAULT,
    },
};

export const NEGATIVE_NEWS_MAX_FOR_DISPLAYING_LABELS = {
    YEARS: 7,
    MONTHS: 3,
};

export const NEGATIVE_NEWS_MAX_NUMBER_OF_LABELS = {
    YEARLY_AND_MONTHLY_LABELS: 10,
    DAILY_LABELS: 5,
};

export const NEGATIVITY_LEVELS_ORDER = [NEGATIVITY_LEVELS.HIGH, NEGATIVITY_LEVELS.MEDIUM, NEGATIVITY_LEVELS.LOW];

export const SNAPSHOT_NEGATIVE_TERMS_HEADERS = ['topNegativeTerms', 'termNegativity', 'results', 'history'];

export const NEGATIVITY_BUCKETS_TO_LEVEL = {
    lowNegativeNewsBuckets: 'low',
    mediumNegativeNewsBuckets: 'medium',
    highNegativeNewsBuckets: 'high',
};

export const NEGATIVITY_STRINGS_TO_LEVEL = {
    lowQueryString: 'low',
    mediumQueryString: 'medium',
    highQueryString: 'high',
};

export const NEGATIVITY_PILL_STATES = {
    SELECTED: 'selected',
    DESELECTED: 'deselected',
    DISABLED: 'disabled',
};

export const CUSTOM_DATES_LABEL = 'Custom dates';
export const NEGATIVITY = 'negativity';

export const FUZZY_SEARCH_TYPE = {
    OFF: 'OFF',
    CLOSE: 'CLOSE',
    MODERATE: 'MODERATE',
    LOOSE: 'LOOSE',
};

const TOP_NEGATIVE_TERMS = 'topNegativeTerms';
const RESULTS = 'results';
export const NEGATIVE_TERMS_HEADERS = [TOP_NEGATIVE_TERMS, RESULTS];

export const BETA_TAG_STATIC_LABEL = 'Beta';

export const CATEGORIES_WITH_DIFFERENT_DELIVERY_PODS = [CATEGORY_NAMES.NEGATIVE_NEWS, CATEGORY_NAMES.ESG_RATINGS, CATEGORY_NAMES.SANCTIONS_WATCHLIST];

/** The order of these config keys is important. **/
export const PENDO_URL_ANNOTATIONS_CONFIG = {
    '/main-search': {
        identifier: {
            match: 'list=true',
            annotation: 'resultsList',
        },
        fallback: 'snapshotPage',
    },
    '/start': {
        identifier: {
            match: null,
            annotation: null,
        },
        fallback: 'startPage',
    },
    '/start/edit-search': {
        identifier: {
            match: null,
            annotation: null,
        },
        fallback: 'editSearchPage',
    },
};

export const BATCH_UPLOAD_FILE_EXTENSIONS = ['.csv', '.xls', '.xlsx'];

export const BATCH_UPLOAD_FILE_TYPES = [
    ...BATCH_UPLOAD_FILE_EXTENSIONS,
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-excel',
];

export const REPORT_ERROR_STATUSES = {
    ALL_FINISHED_WITH_ERRORS: 'ALL_FINISHED_WITH_ERRORS',
    ERROR: 'ERROR',
    ERROR_REPORT_TOO_BIG: 'ERROR_REPORT_TOO_BIG',
    ZIP_GENERATED_WITH_ERRORS: 'ZIP_GENERATED_WITH_ERRORS',
    ZIP_NOT_GENERATED: 'ZIP_NOT_GENERATED',
};

export const REPORT_SUCCESS_STATUSES = {
    ALL_FINISHED_SUCCESSFULLY: 'ALL_FINISHED_SUCCESSFULLY',
    FINISHED: 'FINISHED',
    ZIP_GENERATED: 'ZIP_GENERATED',
};

export const DOWNLOADABLE_REPORT_STATUSES = {
    ...REPORT_SUCCESS_STATUSES,
    ZIP_GENERATED_WITH_ERRORS: 'ZIP_GENERATED_WITH_ERRORS',
};

export const REPORT_DELIVERY_STATUSES = {
    ...REPORT_ERROR_STATUSES,
    ...REPORT_SUCCESS_STATUSES,
    FAILED_RETRY: 'FAILED_RETRY',
    STARTED: 'STARTED',
};

export const SCREENING_ENTITY_STATUSES = {
    DATA_AVAILABLE: 'DATA_AVAILABLE',
    DATA_AVAILABLE_WITH_ERRORS: 'DATA_AVAILABLE_WITH_ERRORS',
    PROCESSING: 'PROCESSING',
    NOT_FOUND: 'NOT_FOUND',
};

export const POLLING_STATUS = {
    FINISHED: 'FINISHED',
    IN_PROGRESS: 'IN_PROGRESS',
    NOT_STARTED: 'NOT_STARTED',
};

export const HOMEPAGE_PAGE_TITLE = 'Nexis Diligence\u2122';
export const SNAPSHOT_PAGE_TITLE = 'Nexis Diligence\u2122 - Snapshot';
export const RESULTS_PAGE_TITLE = 'Nexis Diligence\u2122 - Results';
export const EDIT_SEARCH_PAGE_TITLE = 'Nexis Diligence\u2122 - Edit Search';
export const DOCUMENT_PAGE_TITLE = 'Nexis Diligence\u2122 - Document';
export const REPORT_BUILDER_PAGE_TITLE = 'Nexis Diligence\u2122 - Reports';
export const MANAGE_ALERTS_PAGE_TITLE = 'Nexis Diligence\u2122 - Alerts';
export const EDIT_ALERT_SEARCH_PAGE_TITLE = 'Nexis Diligence\u2122 - Alerts Edit Search';
export const CREATE_ALERT_PAGE_TITLE = 'Nexis Diligence\u2122 - Create Alert';
export const EDIT_ALERT_DELIVERY_PAGE_TITLE = 'Nexis Diligence\u2122 - Edit Alert';
export const USER_PREFERENCES_PAGE_TITLE = 'Nexis Diligence\u2122 - User Preferences';
export const ADMIN_PREFERENCES_PAGE_TITLE = 'Nexis Diligence\u2122 - Admin Preferences';
export const HISTORY_PAGE_TITLE = 'Nexis Diligence\u2122 - History';
export const GOOGLE_ANONYMIZED_PAGE_TITLE = 'Nexis Diligence\u2122 - Google Anonymized';
export const BATCH_UPLOAD_PAGE_TITLE = 'Nexis Diligence\u2122 - Upload Entities';
export const PUBLIC_RECORDS_PAGE_TITLE = 'Nexis Diligence\u2122 - Public Records';
export const BATCH_SCREENING_PAGE_TITLE = 'Nexis Diligence\u2122 - Diligence Entity View';

export const DISPLAY_RISK_SCORES = {
    NO_CHOICE: 'noChoice',
    SHOW: 'showScores',
    HIDE: 'hideScores',
};

export const ESG_RATINGS_COLORS = {
    default: '#E9E9EA',
    NAColor: '#D9D9D9',
    0: '#BC0000',
    30: '#E65100',
    40: '#FFB300',
    50: '#7E57C0',
    60: '#1565C0',
    80: '#2B8642',
};

export const ESG_RATINGS_VISUALISATIONS = {
    MARGIN: -10,
    POD_COUNT_DONUT: {
        SIZE: 90,
        FONT_SIZE: 22,
        FILL_COLOR: 'green',
        //this is the same as $button-link-primary-color
        TITLE_COLOR: '#006ebb',
        //this is the same as $default-text-color
        DEFAULT_TITLE_COLOR: '#212121',
    },
    OVERALL_DONUT: {
        SIZE: 175,
        FONT_SIZE: 26,
    },
    CATEGORY_DONUT: {
        SIZE: 130,
        FONT_SIZE: 22,
    },
    SUBCATEGORY_BAR: {
        HEIGHT: 15,
        WIDTH: 100,
    },
    MULTIPLE_ENTITIES: {
        SIZE: 36,
        FONT_SIZE: 14,
        TITLE_SIZE: 14,
        TITLE_COLOR: '#006ebb',
    },
};

export const ROUTES = {
    ADMIN_PREFERENCES: '/admin-preferences',
    ARTICLE: '/article',
    CREATE_ALERT: '/create-alert',
    DEEP_LINK: '/deep-link',
    DOCUMENT: '/document',
    EDIT_ALERT: '/edit-alert/:alertId',
    EDIT_ALERT_SEARCH: '/edit-alert-search/:alertId',
    EDIT_SEARCH: '/start/edit-search',
    ERROR: '/error',
    GOOGLE_ANONYMIZED: '/google-anonymized',
    HISTORY: '/history',
    LOGOUT: '/logout',
    MAIN_SEARCH: '/main-search',
    MANAGE_ALERTS: '/manage-alerts',
    PRINT: '/print',
    PUBLIC_RECORDS: '/public-records',
    UBO_DOCUMENT: '/ubo-document/:duns/:query(/:isBranch)(/:name)',
    USER_PREFERENCES: '/user-preferences',
    REPORT_BUILDER: '/report-builder',
    SCREENING: '/screening',
    START_PAGE: '/start',
    UNSUBSCRIBE: '/unsubscribe',
};

export const SEARCH_PARAMS_URL_LIST = [
    ROUTES.ARTICLE,
    ROUTES.DOCUMENT,
    ROUTES.REPORT_BUILDER,
    ROUTES.HISTORY,
    ROUTES.PRINT,
    ROUTES.MANAGE_ALERTS,
];
export const TOOLTIP_PLACEMENT = {
    RIGHT: 'right',
    LEFT: 'left',
    BOTTOM: 'bottom',
    TOP: 'top',
};
export const API_RESPONSE_MESSAGE_SUCCESS = 'Success';

export const MATOMO_DOCUMENT_ACTION_EVENT_TRANSFORMATION = {
    print: 'print',
    send: 'email',
    download: 'download',
    addToReport: 'add_to_report',
};

export const MATOMO_DOCUMENT_ACTION_VALUE_TRANSFORMATION = {
    ...MATOMO_DOCUMENT_ACTION_EVENT_TRANSFORMATION,
    send: 'send',
    addToReport: 'add to report',
};

export const MATOMO_SELECTED_ARTICLES_DEFAULT_DELIVERY_MESSAGE = 'first 200 documents selected';
export const MATOMO_SELECTED_ARTICLES_NO_DOCUMENTS_OF_HISTORY_DELIVERY_MESSAGE = 'no documents of interest';

export const LAUNCHED_SEARCH_FROM = {
    HEADER: 'header',
    START_PAGE: 'start',
    SCREENING_PAGE: 'screening',
    EDIT_SEARCH_PAGE_FROM_SCREENING: 'edit-search-screening',
    RESULTS_PAGE: 'results-page',
    SNAPSHOT_PAGE: 'snapshot',
};

export const MATOMO = {
    action: {
        click: 'click',
        submit: 'submit',
    },
    component: {
        document: 'document',
        header: 'Header',
        negativityLevel: 'negativityLevel',
        searchResultsList: 'searchResultsList',
    },
    event: {
        addToReport: 'add_to_report',
        docOpen: 'doc_open',
        docPreview: 'doc_preview',
        docNext: 'doc_next',
        pagination: 'resultslist_pagination',
        podClick: 'pod_click',
        resultslistPagination: 'resultslist_pagination',
        searchRun: 'search_run',
    },
    pageName: {
        document: 'nexisDiligence_document',
        global: 'gobal',
        home: 'home',
        resultsList: 'nexisDiligence_results',
        snapshot: 'nexisDiligence_snapshot',
    },
    subComponent: {
        delivery: 'delivery',
        pagination: 'pagination',
    },
    value: {
        addToReport: 'add to report',
        preview: 'preview',
        search: 'search',
    },
};

export const DRAG_AND_DROP = {
    CHILD_REPORT: 'CHILD_REPORT',
    ARTICLE: 'ARTICLE',
    MODAL_REORDER_ITEMS_LIST: 'MODAL_REORDER_ITEMS_LIST',
    CATEGORY: 'Category',
};

export const UI_EVENTS = {
    WHEEL: 'wheel',
};

export const KEYBOARD_KEYS = {
    ENTER: 'Enter',
    ESCAPE: 'Esc',
};

export const COMPONENT_OFFSET = 130;
export const SANCTIONS_COMPONENT_OFFSET = 300;

export const entitiesTableFocus = { columnId: 'entityName', rowId: 'header' };

export const entityViewColumnSetupInfo = {
    negativeNews: {
        columnWidth: 148,
    },
    news: {
        columnWidth: 95,
    },
    customNews: {
        columnWidth: 124,
    },
    esgRatings: {
        columnWidth: 105,
    },
    ubo: {
        columnWidth: 133,
    },
    directors: {
        columnWidth: 113,
    },
    sanctions: {
        columnWidth: 148,
    },
    financialReports: {
        columnWidth: 125,
    },
    peps: {
        columnWidth: 125,
    },
    biographical: {
        columnWidth: 125,
    },
    lawSources: {
        columnWidth: 112,
    },
    dateCreated: {
        columnWidth: 200
    },
    lastUpdated: {
        columnWidth: 200
    }
};

export const MULTIPLE_ENTITIES_ENTITY_NAME = 'entityName';
export const MULTIPLE_ENTITIES_COMMENTS = 'comments';
export const ENTITY_VIEW_CREATED_DATE = 'dateCreated'
export const ENTITY_VIEW_LAST_UPDATE_DATE = 'lastUpdated'
export const MULTIPLE_ENTITIES_NONE_SELECTED_VIEW = 'none';
export const MULTIPLES_ENTITIES_CREATE_NEW_VIEW = 'create-new-view';
export const TOOLBAR_ACTIONS = {
    refresh: 'refresh',
    copy: 'copy',
    alert: 'alert',
    generateReport: 'generateReport',
    createView: 'createView',
    changeView: 'changeView',
    updateView: 'updateView',
    shareView: 'shareView',
    searchEntity: 'searchEntity',
    deleteEntity: 'deleteEntity',
    actionPolling: 'actionPolling',
};

export const BATCH_SCREENING_ERROR_MESSAGE = 'BatchScreening.generalError.message';
export const BATCH_SCREENING_DROPDOWN_MESSAGES = {
    clearAllSelectedItems: 'SelectedDocumentDropdown.clearAllSelectedItems',
    noSelectedItems: 'SelectedDocumentDropdown.noSelectedItems',
    selectedItems: 'SelectedDocumentDropdown.selectedItems',
    selectedItemsButton: 'SelectedDocumentDropdown.selectedItemsButton',
    myItems: 'BatchScreening.page.title',
};

export const ACTION_TYPES = {
    ADD_FROM_ALERTS: 'ADD_FROM_ALERTS',
    CREATE: 'CREATE',
    COPY: 'COPY',
    DELETE: 'DELETE',
    REFRESH: 'REFRESH',
    UPLOAD: 'UPLOAD',
    DELETE_ALERT: 'DELETE_ALERT',
    RESET_USER_PREFERENCES: 'RESET_USER_PREFERENCES',
};

export const NOTIFICATION_STORE_KEYS = {
    COPY: 'copyEntitiesStatus',
    CREATE: 'createAlertsStatus',
    DELETE: 'deleteEntitiesStatus',
    REFRESH: 'refreshEntitiesStatus',
    UPLOAD: 'uploadEntitiesStatus',
    DELETE_ALERT: 'deleteAlertsStatus',
}

export const POPUP_MESSAGE_TYPES = {
    SUCCESS: 'success',
    INFO: 'info',
    WARNING: 'user-error',
    CRITICAL: 'system-error',
};

export const SEARCH_ENTITY_INITIAL_VALUE = '';

export const PUBLIC_RECORDS = 'publicRecords';

export const API_URL_IS_TRIAL_USER = '/api/user/isTrialUser';
export const URL_NON_DILIGENCE_PLUS_UPGRADE_LIST = '/api/user/isNonDiligencePlusUserForUpgrade';

export const SCREENING_ENTITY_API_V2 = `api/v2/screening-entity`;
export const SCREENING_ENTITY_APIS_V2 = {
    DELETE_ENTITIES: `${SCREENING_ENTITY_API_V2}`,
    UPLOAD_ENTITIES: `${SCREENING_ENTITY_API_V2}/upload`
};

export const SCREENING_ENTITY_API_V1 = '/api/screening-entity';
export const SCREENING_ENTITY_APIS_V1 = {
    API_VALIDATE_FILE: `${SCREENING_ENTITY_API_V1}/validate`,
    UPDATE_ENTITY_DISPLAY_NAME: `${SCREENING_ENTITY_API_V1}`,
    UPDATE_ENTITY_SEARCH_DATA: `/update-search-data`,
    UPLOAD_ENTITIES: `${SCREENING_ENTITY_API_V1}/screening-entities`,
}

export const SAVE_SEARCH_LEAVE_ACTION = {
    logout: 'logout',
    searchBarHeader: 'searchBarHeader',
};

export const FILE_CONTENT_COLUMNS_ENUM = {
    SearchQuery: 0,
    SearchType: 1,
    AlertName: 2,
    Emails: 3,
    Comments: 4,
    AlertFrequency: 5,
    AlertDeliveryType: 6,
    AlertFileType: 7,
};

export const UPLOAD_ENTITIES_WARNINGS_AND_ERRORS = {
    noWarning: null,
    companyAndPersonWarning: 'PopupBuilder.description.uploadEntitiesCompanySelectedSourcesWithCompanies',
    personWarning: 'PopupBuilder.description.uploadEntitiesCompanySelectedSourcesWithoutCompanies',
    multipleFiles: 'BatchUpload.warningMessage.multipleFilesDragged',
    entitiesLimit: 'BatchUpload.validationMessage.entitiesLimit',
    emptyFile: 'BatchUpload.validationMessage.emptyFile',
    invalidFile: 'BatchUpload.validationMessage.invalidFile',
    invalidFilename: 'DownloadModalFileNameValidation.ValidationMessage',
    invalidSearchType: 'BatchUpload.validationMessage.invalidSearchTypeParameter',
    uploadError: 'BatchUpload.submit.errorMessage',
    alertsLimitReached: 'ManageAlerts.ErrorMessage.alertsLimitReached',
    pushingToAlertsLimit: 'ManageAlerts.ErrorMessage.pushingToAlertLimit',
};

export const NON_BLOCKING_UPLOAD_ENTITIES_WARNINGS = [
    UPLOAD_ENTITIES_WARNINGS_AND_ERRORS.companyAndPersonWarning,
    UPLOAD_ENTITIES_WARNINGS_AND_ERRORS.multipleFiles,
];

export const UPLOAD_ENTITIES_VALIDATION_TYPES = {
    info: 'info',
    warning: 'warning',
    error: 'error',
};

export const EMAIL_ERROR_MESSAGES_MAP = {
    EmailRequired: null,
    InvalidEmail: 'EmailValidation.InvalidEmail.BatchUpload',
    TooManyEmails: 'EmailModalEmailValidation.TooManyEmails',
};

export const EDIT_SUBCATEGORIES_DISPLAY_MODE = {
    MODAL: 'modal',
    DROPDOWN: 'dropdown',
};

export const ALERT_MESSAGE_TYPE = {
    INFO: 'info',
    WARNING: 'warning',
};

export const FILTER_TYPES_CONSTANTS = {
    CONTENT_TYPES: 'ContentTypes',
    SEARCH_WITHIN: 'SearchWithin',
    SIMILAR_NAMES: 'SimilarNames',
    SUGGESTED_NAMES: 'SuggestedNames',
    DATE_RANGE: 'DateRange',
    COMPANY_MENTIONS: 'CompanyMentions',
    PERSON_MENTIONS: 'PersonMentions',
    PROXIMITY_NEGATIVE_TERMS: 'ProximityNegativeTerms',
    SOURCE: 'Source',
    SOURCE_TYPE: 'Source Type',
    LANGUAGE: 'Language',
    COMPANY: 'Company',
    GEOGRAPHY: 'Geography',
    DOCKET_STATUS: 'DocketStatus',
    DUNS_FILTER: 'DunsFilter',
    SUBJECT: 'Subject',
    INDUSTRY: 'Industry',
    FUZZY_SEARCH: 'FuzzySearch',
    NEGATIVE_LEVELS: 'NegativityLevels',
    ESG_FACTORS: 'EsgFactors'
}

export const FILTER_TYPES = {
    [FILTER_TYPES_CONSTANTS.CONTENT_TYPES]: false,
    [FILTER_TYPES_CONSTANTS.SEARCH_WITHIN]: false,
    [FILTER_TYPES_CONSTANTS.SIMILAR_NAMES]: false,
    [FILTER_TYPES_CONSTANTS.SUGGESTED_NAMES]: false,
    [FILTER_TYPES_CONSTANTS.DATE_RANGE]: false,
    [FILTER_TYPES_CONSTANTS.COMPANY_MENTIONS]: false,
    [FILTER_TYPES_CONSTANTS.PERSON_MENTIONS]: false,
    [FILTER_TYPES_CONSTANTS.PROXIMITY_NEGATIVE_TERMS]: false,
    [FILTER_TYPES_CONSTANTS.SOURCE]: false,
    [FILTER_TYPES_CONSTANTS.SOURCE_TYPE]: false,
    [FILTER_TYPES_CONSTANTS.LANGUAGE]: false,
    [FILTER_TYPES_CONSTANTS.COMPANY]: false,
    [FILTER_TYPES_CONSTANTS.GEOGRAPHY]: false,
    [FILTER_TYPES_CONSTANTS.DOCKET_STATUS]: false,
    [FILTER_TYPES_CONSTANTS.DUNS_FILTER]: false,
    [FILTER_TYPES_CONSTANTS.SUBJECT]: false,
    [FILTER_TYPES_CONSTANTS.INDUSTRY]: false,
    [FILTER_TYPES_CONSTANTS.FUZZY_SEARCH]: false,
    [FILTER_TYPES_CONSTANTS.NEGATIVE_LEVELS]: false,
    [FILTER_TYPES_CONSTANTS.ESG_FACTORS]: false,
};

export const SANCTIONS_TABLE_COLUMN = {
    RISK: 'riskScore',
    AUTHORITY_LIST_AND_SOURCE: 'sanctionsDocumentMetadata',
    PUB_DATE: 'publishedOn',
    DOCUMENT_NAME: 'documentName',
    RESULTS: 'results',
};

export const SANCTIONS_TABLE_CELL_TYPE = {
    HEADER: 'sanctionsHeader',
    RISK: 'sanctionsRisk',
    RESULTS: 'sanctionsResults',
    AUTHORITY_LIST_AND_SOURCE: 'sanctionsDocumentMetadata',
    DEFAULT: 'sanctionsCell',
};

// START - Sanctions & Watchlists Preferences constants

export const SANCTIONS_WATCHLISTS_TABLE_COLUMNS = ['source', 'country', 'authority', 'list', 'risk'];

export const FILTER_DROPDOWN_LIST_ELEMENT_WIDTH = 248;

// END - Sanctions & Watchlists Preferences constants

export const SANCTIONS_RISK_SCORE = {
    LOW: 1,
    MEDIUM: 2,
    HIGH: 3,
};

export const EMPTY_POLLING_CHECK_LIMIT = 2;

export const REPORTS_MAX_DOWNLOAD_LIMIT = 10;
