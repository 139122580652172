import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CompanyMatchItem } from './MatchItem';
import { FormattedMessage } from 'react-intl';
import { SelectableMatchList } from './SelectableMatchList';

export class CompanyMatchesContainer extends Component {
    static propTypes = {
        onSelectCompany: PropTypes.func,
        className: PropTypes.string,
    };

    static defaultProps = {
        className: '',
    };

    constructor(props) {
        super(props);

        this.handleChangeSelection = this.handleChangeSelection.bind(this);
        this.handleSelect = this.handleSelect.bind(this);

        this.state = {
            selectedIndex: null,
        };
    }

    handleChangeSelection(selectedIndex, item, callback) {
        this.setState({ selectedIndex }, callback);
    }

    handleSelect(index, option) {
        if (option.isCancelOption) {
            //if the Show All is clicked/selected
            this.props.onClearSuggestions();
        } else {
            this.props.onSelectCompany(option);
        }
    }

    render() {
        if (this.props.matches && this.props.matches.length > 0) {
            // we add the
            const matches = [
                ...this.props.matches,
                {
                    title: 'Show whatever I am searching',
                    className: 'hidden',
                    isCancelOption: true,
                },
            ];
            const isExtraOptionSelected = this.state.selectedIndex === matches.length - 1;

            return (
                <div className={`match-container ${this.props.className}`}>
                    <div className={'float-container'}>
                        <div className={'match-header company-match-header'}>
                            <div className={'match-header-name'}>
                                <div className={'match-header-title'}>
                                    <FormattedMessage
                                        id={'StartPage.UBO.suggestions.matchesTitle'}
                                        values={{ count: this.props.matchCount }}
                                    />
                                </div>
                            </div>
                            <div className={'match-header-address'}>
                                <FormattedMessage id={'StartPage.UBO.suggestions.address'} />
                            </div>
                            <div className={'match-header-duns'}>
                                <FormattedMessage id={'StartPage.UBO.suggestions.duns'} />
                            </div>
                        </div>
                        <SelectableMatchList
                            MatchItemComponent={CompanyMatchItem}
                            selectedIndex={this.state.selectedIndex}
                            onChangeSelection={this.handleChangeSelection}
                            matches={matches}
                            matchCount={this.props.matchCount}
                            onCancel={this.props.onClearSuggestions}
                            onSelect={this.handleSelect}
                        />
                        <div
                            className={`just-search-for ${isExtraOptionSelected ? 'selected' : ''}`}
                            onClick={this.props.onClearSuggestions}
                        >
                            <FormattedMessage
                                id={'StartPage.UBO.suggestions.justSearch'}
                                values={{ term: this.props.searchTerm }}
                            />
                        </div>
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }
}
