import React from 'react';
import utils from '@utils/utilities';

const RiskTooltip = ({
    overallRisk,
    categoryLabel,
    totalArticlesLabel,
    totalArticlesValue,
    disclaimerLabel,
    riskVisualisationsLabel,
    riskPercentageLabels,
    dataTestId,
}) => {
    const formattedArticlesCount = utils.numberFormatter(totalArticlesValue, 2);

    return (
        <div className="risk-tooltip-container" data-testid={dataTestId} >
            <div className="display-count-negativity-risk">
                <p className="display-count-negativity-risk-category">{categoryLabel}</p>
                <p className="display-count-negativity-risk-total-articles">{formattedArticlesCount}</p>
                <p className="display-count-negativity-risk-total-articles-label">{totalArticlesLabel}</p>
                <ul className="display-count-negativity-risk-percentage-list">
                    {riskPercentageLabels.map((percentageItem) => {
                        const { itemRiskLevel, itemRiskLabel, itemPercentage } = percentageItem;

                        return (
                            <li key={itemRiskLevel} className="display-count-negativity-risk-percentage-list-item">
                                <div className="display-count-negativity-risk-percentage-list-item-label">
                                    <div className={`doughnut ${itemRiskLevel}`} />
                                    <span>{itemRiskLabel}</span>
                                </div>
                                <span className="display-count-negativity-risk-percentage-list-item-percentage">
                                    {utils.numberFormatter(itemPercentage, 2)}%
                                </span>
                            </li>
                        );
                    })}
                </ul>
                <p className={`display-count-negativity-risk-level ${overallRisk}`}>
                    {riskVisualisationsLabel}
                </p>
                <p className="card-disclaimer" dangerouslySetInnerHTML={{ __html: disclaimerLabel }} />
            </div>
        </div>
    );
};

export default RiskTooltip;
