import React from 'react';

const RadioButtonsCell = ({ rKey, element, onChangeHandler, disabled }) => {

    return (
        <div key={rKey} className='user-preferences-category-container__authorities__simple-table__radio-cell'>
            <div className='custom-radio'>
                <input
                    id={`${element.source}-${element.authority}-${element.country}-${element.list}-risk-type-high`}
                    type='radio'
                    name={`user-preferences-sanctions-authority-${element.source}-${element.authority}-${element.country}-${element.list}-radio`}
                    checked={element.risk === 'HIGH'}
                    value='HIGH'
                    onChange={(e) =>
                        onChangeHandler(e.target.value, element)
                    }
                    disabled={disabled}
                    data-testid={`user-preferences-checkbox--${element.source}-${element.authority}-${element.country}-${element.list}-risk-type-high-radio`}
                />
                <label className='radio-label' />
            </div>
            <div className='custom-radio'>
                <input
                    id={`${element.source}-${element.authority}-${element.country}-${element.list}-risk-type-medium`}
                    type='radio'
                    name={`user-preferences-sanctions-authority-${element.source}-${element.authority}-${element.country}-${element.list}-radio`}
                    checked={element.risk === 'MEDIUM'}
                    value='MEDIUM'
                    onChange={(e) =>
                        onChangeHandler(
                            e.target.value,
                            element,
                        )
                    }
                    disabled={disabled}
                    data-testid={`user-preferences-checkbox--${element.source}-${element.authority}-${element.country}-${element.list}-risk-type-medium-radio`}
                />
                <label className='radio-label' />
            </div>
            <div className='custom-radio'>
                <input
                    id={`${element.source}-${element.authority}-${element.country}-${element.list}-risk-type-low`}
                    type='radio'
                    name={`user-preferences-sanctions-authority-${element.source}-${element.authority}-${element.country}-${element.list}-radio`}
                    checked={element.risk === 'LOW'}
                    value='LOW'
                    onChange={(e) =>
                        onChangeHandler(
                            e.target.value,
                            element,
                        )
                    }
                    disabled={disabled}
                    data-testid={`user-preferences-checkbox--${element.source}-${element.authority}-${element.country}-${element.list}-risk-type-low-radio`}
                />
                <label className='radio-label' />
            </div>
        </div>
    )
}

export default RadioButtonsCell;