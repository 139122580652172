import { takeLeading, takeEvery } from 'redux-saga/effects';

import { SEARCH_EVENTS } from '../constants/searchConstants';
import * as flows from '../flows/searchFlows';

export function* watchRunSearch() {
    yield takeLeading(SEARCH_EVENTS.RUN_NEW_SEARCH, flows.runSearch);
}

export function* watchResetSearchResults() {
    yield takeEvery(SEARCH_EVENTS.RESET_SEARCH_RESULTS, flows.resetCategoriesResults);
}
