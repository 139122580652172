import {
    EMPTY_EDIT_SEARCH,
    UPDATE_EDIT_SEARCH,
    UPDATE_EDIT_SEARCH_CATEGORY,
    UPDATE_EDIT_SEARCH_PROPERTY,
} from './EditSearch.actions';
import errorUtils from '@utils/errors/error-utils';
import update from 'immutability-helper/index';
import { isEmpty } from 'lodash';
const APP_MODULE = 'editSearch.reducers';

function editSearch(state = {}, action) {
    switch (action.type) {
        case UPDATE_EDIT_SEARCH: {
            if (!action || !action.payload || isEmpty(action.payload)) {
                errorUtils.logAppError(
                    'Missing parameter in ' + UPDATE_EDIT_SEARCH_CATEGORY,
                    { payload: action.payload },
                    APP_MODULE
                );

                return state;
            }
            let categoryName = action.payload.category;
            return update(state, { [categoryName]: { $set: action.payload } });
        }
        case EMPTY_EDIT_SEARCH: {
            return update(state, { $set: action.payload });
        }
        case UPDATE_EDIT_SEARCH_PROPERTY:
            let { name, propertyName, propertyValue } = action.payload;
            if (!name || !propertyName || propertyValue === undefined || propertyValue === null) {
                //check if propertyValue !== undefined or null because usually it can get values as true/false or integer (count)
                errorUtils.logAppError(
                    'Missing parameter in ' + UPDATE_EDIT_SEARCH_PROPERTY,
                    { propertyName: propertyName, propertyValue: propertyValue },
                    APP_MODULE
                );
                return state;
            }
            return update(state, { [name]: { [propertyName]: { $set: propertyValue } } });

        default:
            return state;
    }
}

export default editSearch;
