// @flow
/**
 * HEADER MAIN MENU VIEW TEMPLATE
 *
 * @version 0.1
 */

import * as React from 'react';
import { withAnonymizedHideView } from '@utils/contexts';
import { REGISTERED_TRADEMARK, TRADEMARK } from '@constants';
import api from '@api/LoginPage.api';
import utils from '@utils/utilities';
import ProductItem from './ProductItem';
import type { Context } from '@utils/flow/utilities.type.guards';

type Props = {
    context: Context,
    language: string,
};

type State = {
    allProducts: Array<Product>,
};

type Product = {
    authorized?: boolean,
    name: string,
    url: string,
};
class ProductSwitcher extends React.Component<Props, State> {
    _isMounted: boolean = false;

    constructor(props: Props) {
        super(props);

        this.state = {
            allProducts: [],
        };
    }

    componentDidMount() {
        this._isMounted = true;
        const products = [];
        const diligenceProduct = {
            name: `Nexis${REGISTERED_TRADEMARK} Diligence`,
            url: '',
        };
        if (!this.state.allProducts.length) {
            api.getAvailableProducts()
                .then((response: Array<Product>): void => {
                    response.map((product: Product): void => {
                        products.push(product);
                    });

                    // add Diligence product at the top of the list
                    products.splice(0, 0, diligenceProduct);
                    products.sort((a: Product, b: Product) =>
                        utils.replaceHtmlEntities(a.name) > utils.replaceHtmlEntities(b.name) ? 1 : -1
                    );

                    this._isMounted &&
                        this.setState({
                            allProducts: products,
                        });
                })
                .catch(() => {
                    utils.showNotificationsMessage({
                        messageText: 'General_CoreFunctionality_Error_error.500Message',
                        messageType: 'error',
                    });
                });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    renderProductItems: (products: Array<Product>) => Array<React.Node> = (products) =>
        products.map((item: Product, index: number): React.Node => {
            const displayedName = item.name.includes(REGISTERED_TRADEMARK)
                ? item.name.split(REGISTERED_TRADEMARK)
                : item.name.split(TRADEMARK);
            const language = this.props.language.replace('-', '_');
            const defaultStyle = 'default-product';
            const selectedStyle = 'selected-product';

            if (item.name.includes('Diligence')) {
                return (
                    <ProductItem key={`product_${index}`} relp="noopener noreferrer" className={selectedStyle}>
                        <span className="product-span">
                            {displayedName[0]}
                            {displayedName[1]}
                            <sup>&trade;</sup>
                        </span>
                    </ProductItem>
                );
            }

            if (item.name.includes('Dossier')) {
                return (
                    <ProductItem
                        key={`product_${index}`}
                        url={`${item.url}&lang=${language}&unique=${Math.floor(Math.random() * 10000000000000)}`}
                        className={defaultStyle}
                    >
                        <span className="product-span">
                            {displayedName[0]}
                            <sup>&reg;</sup>
                            {displayedName[1]}
                        </span>
                    </ProductItem>
                );
            }

            if (item.name.includes(REGISTERED_TRADEMARK)) {
                return (
                    <ProductItem
                        key={`product_${index}`}
                        url={item.url}
                        relp="noopener noreferrer"
                        className={defaultStyle}
                    >
                        <span className="product-span">
                            {displayedName[0]}
                            <sup>&reg;</sup>
                            {displayedName[1]}
                        </span>
                    </ProductItem>
                );
            }
            if (item.name.includes(TRADEMARK)) {
                return (
                    <ProductItem
                        key={`product_${index}`}
                        url={item.url}
                        relp="noopener noreferrer"
                        className={defaultStyle}
                    >
                        <span className="product-span">
                            {displayedName[0]}
                            <sup>&trade;</sup>
                            {displayedName[1]}
                        </span>
                    </ProductItem>
                );
            } else {
                return (
                    <ProductItem
                        key={`product_${index}`}
                        url={item.url}
                        relp="noopener noreferrer"
                        className={defaultStyle}
                    >
                        <span className="product-span">{item.name}</span>
                    </ProductItem>
                );
            }
        });

    render(): React.Node {
        const firstColumnProducts = this.state.allProducts.slice(0, 6);
        const secondColumnProducts = this.state.allProducts.slice(6);
        return this.state.allProducts.length > 1 ? (
            <li className="diligence-header__menu-item diligence-header__menu-item__productSwitcher">
                <div className="diligence-header__menu-link diligence-header__menu-link__productSwitcher grid-header"></div>
                <div className="dropdown-content dropdown-content__productSwitcher">
                    {this.renderProductItems(firstColumnProducts)}
                </div>
                {secondColumnProducts.length > 0 && (
                    <div className="dropdown-second-column dropdown-content dropdown-content__productSwitcher">
                        {this.renderProductItems(secondColumnProducts)}
                    </div>
                )}
            </li>
        ) : (
            ''
        );
    }
}

export default (withAnonymizedHideView(ProductSwitcher): React.AbstractComponent<Props, State>);
export { ProductSwitcher as PureProductSwitcher }; // for testing purposes
