import { put, fork, select, call } from 'redux-saga/effects';
import articlesManagerActions from '@pages/MainSearch/redux/ArticlesManager.actions';
import * as selectors from '../selectors/articleNavigationSelectors';
import { getDocumentRequestParams, getLoadPageRequestParams } from '../requests/articleNavigationRequests';
import { theSearchResultCategory } from '../selectors/main';
import { requestWithRetry } from '../helpers/mainHelper';
import { ARTICLE_NAVIGATION_LOAD_THRESHOLD } from '@constants';
import articleNavigationActions from '@pages/MainSearch/redux/ArticleNavigation.actions';
import { hashHistory } from 'react-router';
import { isEmpty } from 'lodash';
import categoryUtils from '@utils/categoryUtils';
import { mapOrganizationToCategory } from '../helpers/uboHelper';

export function* loadArticleAtIndex(index) {
    // while we load, show the info that we have already stored in the list
    yield fork(loadArticleExcerpt, index);
    const article = yield select(selectors.theArticleAt(index));
    const articleId = article.id;
    const {
        query,
        searchType,
        category,
        prefilterQuery,
        proximity,
        includeTerms,
        costCode,
        singleSource,
        fuzzyThreshold,
        suggestedNames,
        terms,
    } = yield select(selectors.theSearchParams);
    const billingId = yield select(selectors.theBillingId);

    yield call(
        loadArticle,
        articleId,
        query,
        searchType,
        category,
        billingId,
        null,
        prefilterQuery,
        proximity,
        includeTerms,
        costCode,
        singleSource,
        fuzzyThreshold,
        suggestedNames,
        article,
        terms
    );
}

export function* loadArticle(...args) {
    try {
        const { data } = yield call(requestWithRetry, getDocumentRequestParams(...args));
        let articleType = yield select(selectors.theCurrentCategoryName);
        
        if (data.category && articleType !== data.category) articleType = data.category;

        let articleInfoForReport = yield select(selectors.theArticleInfo);

        // also keeping the original state of the selected article for the UBO category for adding the article to the report
        if (categoryUtils.isDnbCategory(articleType) && articleInfoForReport && !isEmpty(articleInfoForReport)) {
            Object.assign(data, {
                ...articleInfoForReport,
                ...data,
            });
        }

        yield put(
            articlesManagerActions.selectArticle({
                ...data,
                // add the article type in article; originally this prop was injected in components and not present natively in the article object
                articleType,
            })
        );
    } catch (err) {
        console.log(err);
        yield put(articleNavigationActions.hasError());
    }
}

export function* loadArticleExcerpt(index) {
    const articleExcerpt = yield select(selectors.theArticleExcerpt(index));
    yield put(articlesManagerActions.selectArticle(articleExcerpt));
}

export function* checkBorder() {
    const threshold = ARTICLE_NAVIGATION_LOAD_THRESHOLD;
    const { index, list, totalCount } = yield select(selectors.theArticleNavigation);
    // check for left limit
    if (index > threshold && !list[index - threshold]) {
        yield put(articleNavigationActions.loadPreviousPage());
    }
    // check for right limit
    if (index + threshold < totalCount && !list[index + threshold]) {
        yield put(articleNavigationActions.loadNextPage());
    }
}

export function* loadPage(pageNumber) {
    const categoryName = yield select(selectors.theCurrentCategoryName);
    const pageSize = yield select(selectors.thePageSize);
    const results = yield loadPageResults(categoryName, pageNumber);
    let fillParams;

    if (categoryUtils.isDnbCategory(categoryName)) {
        // for ubo we dont refresh the count
        const totalCount = yield select(selectors.theTotalCount);
        fillParams = {
            start: pageSize * pageNumber,
            list: results && results.resultsList && results.resultsList.map(mapOrganizationToCategory),
            totalCount,
        };
    } else {
        fillParams = {
            start: pageSize * pageNumber,
            list: results.entity,
            totalCount: results.totalSize,
        };
    }

    yield put(articleNavigationActions.fillList(fillParams));
}

export function* loadPageResults(categoryName, pageNumber) {
    try {
        const category = yield select(theSearchResultCategory(categoryName));
        const { data } = yield call(requestWithRetry, getLoadPageRequestParams(category, pageNumber));
        return data;
    } catch (err) {
        hashHistory.push('/error');
        console.log('Failed to fetch page ' + pageNumber + ' in category ' + categoryName);
    }
}
