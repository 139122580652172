import React from 'react';
import { NEGATIVE_NEWS_SELECTED_LANGUAGE } from '@constants';
import formatRichMessage from '@utils/formatRichMessage';
import AdminLock from '@reusable/AdminLock/AdminLock';
import { injectIntl } from 'react-intl';

const NewsSourcesDropdown = (props) => {
    return (
        <div className="news-sources-dropdown dropdown">
            {props.children}
            <select
                onChange={(e) => {
                    props.onChangeHandler(e);
                }}
                value={props.defaultSources}
                disabled={props.disabledDropdown}
                data-testid="select-negative-news-source"
            >
                {Object.keys(NEGATIVE_NEWS_SELECTED_LANGUAGE).map((key, index) => (
                    <option key={index} value={NEGATIVE_NEWS_SELECTED_LANGUAGE[key].name}>
                        {formatRichMessage({ id: NEGATIVE_NEWS_SELECTED_LANGUAGE[key].label }, props.intl)}
                    </option>
                ))}
            </select>
            {(props.userIsAdmin || (!props.masterLock && props.isReadonly)) && (
                <AdminLock
                    lockName={props.lockName}
                    onClickLock={props.handleLockClick}
                    isLocked={props.isReadonly}
                    isDisabled={!props.userIsAdmin && (props.masterLock || props.isReadonly)}
                />
            )}
        </div>
    );
};

export default injectIntl(NewsSourcesDropdown);
