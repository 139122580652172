import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
    CATEGORY_NAMES,
    DEFAULT_FILENAME,
    DOC_TYPE,
    MULTIPLE_ENTITIES_COMMENTS,
    MULTIPLE_ENTITIES_ENTITY_NAME,
    ENTITY_VIEW_CREATED_DATE,
    ENTITY_VIEW_LAST_UPDATE_DATE,
    MULTIPLE_ENTITIES_NONE_SELECTED_VIEW,
    entityViewColumnSetupInfo,
    POLLING_STATUS,
    SORT_DIRECTION,
    TOOLBAR_ACTIONS,
} from '@constants';
import utils from '@utils/utilities';
import { isEmpty } from 'lodash';
import formatRichMessage from 'scripts/utils/formatRichMessage';
import { getAllTerms, sanitizeTerm } from '@sagas/helpers/uboHelper';
import categoryUtils from '@utils/categoryUtils';

const {
    NEGATIVE_NEWS,
    NEWS,
    CUSTOM_NEWS,
    ESG_RATINGS,
    DNB,
    COMPANY_RESOURCES,
    SANCTIONS_WATCHLIST,
    FINANCIAL_REPORT,
    PEPS,
    BIOGRAPHICAL,
    LAW_SOURCES,
} = CATEGORY_NAMES;

export const isValidAlertsCategory = (name, children) => {
    const hasChildren = categoryUtils.hasChildren(name);
    return !hasChildren || (hasChildren && children?.length > 0) || name === CATEGORY_NAMES.CUSTOM_NEWS;
};

/**
    Determines whether the selection should be cleared based on various conditions.
    @param {Object} withSelectionProps - Props related to selection, passed from EntityViewTable.jsx {selectedItems,setSelectedItems,selectAllSetting,setSelectAllSetting,setUnselectedItemIds}
    @param {Object} selection - The current selection object. {actions, selectedItems}
    @param {string} lastPerformedAction - The last performed action in the toolbar. Either empty or equal to one of the TOOLBAR_ACTIONS.
    @returns {boolean} - True if the selection should be cleared, false otherwise.
    Selection should be cleared if:
    - withSelectionProps.selectedItems is empty AND selection.selectedItems is not empty
    - lastPerformedAction is equal 'createView' OR 'changeView' OR 'searchEntity'
*/
export const shouldClearSelection = (withSelectionProps, selection, lastPerformedAction) => {
    return (
        Boolean(withSelectionProps && !withSelectionProps.selectedItems.length && selection.selectedItems.length) ||
        lastPerformedAction === TOOLBAR_ACTIONS.createView ||
        lastPerformedAction === TOOLBAR_ACTIONS.changeView ||
        lastPerformedAction === TOOLBAR_ACTIONS.searchEntity ||
        lastPerformedAction === TOOLBAR_ACTIONS.updateView ||
        lastPerformedAction === TOOLBAR_ACTIONS.deleteEntity ||
        lastPerformedAction === TOOLBAR_ACTIONS.actionPolling
    );
};

export const shouldGetTableData = (lastPerformedAction) => {
    return (
        lastPerformedAction !== TOOLBAR_ACTIONS.changeView &&
        lastPerformedAction !== TOOLBAR_ACTIONS.createView &&
        lastPerformedAction !== TOOLBAR_ACTIONS.actionPolling
    );
};

export const getSortColumnInfo = (columnId = '', sortInfo = null) => {
    return columnId === (sortInfo && sortInfo.sortBy) ? sortInfo.direction : null;
};

export const getColumnWidthInfo = (columnId = '', columnsInfo = null) => {
    if (!columnsInfo || !columnsInfo[columnId]) {
        return entityViewColumnSetupInfo[columnId].columnWidth;
    }

    return columnsInfo[columnId].width;
};

export const getColumns = (sortInfo = null, columnsInfo = null) => [
    {
        columnId: MULTIPLE_ENTITIES_ENTITY_NAME,
        width: 250,
        sortDirection: getSortColumnInfo(MULTIPLE_ENTITIES_ENTITY_NAME, sortInfo),
    },
    {
        columnId: NEGATIVE_NEWS,
        width: getColumnWidthInfo(NEGATIVE_NEWS, columnsInfo),
        reorderable: true,
        resizable: true,
        sortDirection: getSortColumnInfo(NEGATIVE_NEWS, sortInfo),
    },
    {
        columnId: NEWS,
        width: getColumnWidthInfo(NEWS, columnsInfo),
        reorderable: true,
        resizable: true,
        sortDirection: getSortColumnInfo(NEWS, sortInfo),
    },
    {
        columnId: CUSTOM_NEWS,
        width: getColumnWidthInfo(CUSTOM_NEWS, columnsInfo),
        reorderable: true,
        resizable: true,
        sortDirection: getSortColumnInfo(CUSTOM_NEWS, sortInfo),
    },
    {
        columnId: ESG_RATINGS,
        width: getColumnWidthInfo(ESG_RATINGS, columnsInfo),
        reorderable: true,
        resizable: true,
        sortDirection: getSortColumnInfo(ESG_RATINGS, sortInfo),
    },
    {
        columnId: DNB,
        width: getColumnWidthInfo(DNB, columnsInfo),
        reorderable: true,
        resizable: true,
        sortDirection: getSortColumnInfo(DNB, sortInfo),
    },
    {
        columnId: COMPANY_RESOURCES,
        width: getColumnWidthInfo(COMPANY_RESOURCES, columnsInfo),
        reorderable: true,
        resizable: true,
        sortDirection: getSortColumnInfo(COMPANY_RESOURCES, sortInfo),
    },
    {
        columnId: SANCTIONS_WATCHLIST,
        width: getColumnWidthInfo(SANCTIONS_WATCHLIST, columnsInfo),
        reorderable: true,
        resizable: true,
        sortDirection: getSortColumnInfo(SANCTIONS_WATCHLIST, sortInfo),
    },
    {
        columnId: FINANCIAL_REPORT,
        width: getColumnWidthInfo(FINANCIAL_REPORT, columnsInfo),
        reorderable: true,
        resizable: true,
        sortDirection: getSortColumnInfo(FINANCIAL_REPORT, sortInfo),
    },
    {
        columnId: PEPS,
        width: getColumnWidthInfo(PEPS, columnsInfo),
        reorderable: true,
        resizable: true,
        sortDirection: getSortColumnInfo(PEPS, sortInfo),
    },
    {
        columnId: BIOGRAPHICAL,
        width: getColumnWidthInfo(BIOGRAPHICAL, columnsInfo),
        reorderable: true,
        resizable: true,
        sortDirection: getSortColumnInfo(BIOGRAPHICAL, sortInfo),
    },
    {
        columnId: LAW_SOURCES,
        width: getColumnWidthInfo(LAW_SOURCES, columnsInfo),
        reorderable: true,
        resizable: true,
        sortDirection: getSortColumnInfo(LAW_SOURCES, sortInfo),
    },
    {
        columnId: ENTITY_VIEW_CREATED_DATE,
        width: getColumnWidthInfo(ENTITY_VIEW_CREATED_DATE, columnsInfo),
        reorderable: true,
        resizable: true,
        sortDirection: getSortColumnInfo(ENTITY_VIEW_CREATED_DATE, sortInfo),
    },
    {
        columnId: ENTITY_VIEW_LAST_UPDATE_DATE,
        width: getColumnWidthInfo(ENTITY_VIEW_LAST_UPDATE_DATE, columnsInfo),
        reorderable: true,
        resizable: true,
        sortDirection: getSortColumnInfo(ENTITY_VIEW_LAST_UPDATE_DATE, sortInfo),
    },
    {
        columnId: MULTIPLE_ENTITIES_COMMENTS,
        width: 110,
        sortDirection: getSortColumnInfo(MULTIPLE_ENTITIES_COMMENTS, sortInfo),
    },
];

export const getColumnMap = (shouldDisplayRiskScore) => {
    return {
        entityName: <FormattedMessage id="BatchScreening.page.table.label.entity" />,
        negativeNews: (
            <FormattedMessage
                id={
                    shouldDisplayRiskScore
                        ? 'NegativeNewsRisk.label.newsRisk'
                        : 'General.categoryName.label.negativeNews'
                }
            />
        ),
        news: <FormattedMessage id="General.categoryName.label.news" />,
        customNews: <FormattedMessage id="General.categoryName.label.customNews" />,
        esgRatings: <FormattedMessage id="General.categoryName.label.esgRatings" />,
        ubo: <FormattedMessage id="General.categoryName.label.ubo" />,
        directors: <FormattedMessage id="General.categoryName.label.directors" />,
        sanctions: <FormattedMessage id="General.categoryName.label.sanctions" />,
        financialReports: <FormattedMessage id="General.categoryName.label.financialReports" />,
        peps: <FormattedMessage id="General.categoryName.label.peps" />,
        biographical: <FormattedMessage id="General.categoryName.label.biographical" />,
        lawSources: <FormattedMessage id="General.categoryName.label.lawSources" />,
        dateCreated: <FormattedMessage id="General.label.createdDate" />,
        lastUpdated: <FormattedMessage id="General.label.lastUpdateDate" />,
        comments: <FormattedMessage id="General.label.comments" />,
    };
};

export const getRowStructure = (entity, shouldDisplayRiskScore, isSnapshotVisible, columnsOrder, intl, extraProps) => {
    const commentsPerRow = extraProps?.comments?.commentsList[entity.idx];
    const hasComments = commentsPerRow && commentsPerRow.length;

    const defaultRowStruct = [
        { type: 'entityName', columnId: 'entityName', text: { ...entity, intl, extraProps }, nonEditable: true },
        {
            type: shouldDisplayRiskScore ? 'negativeNews' : 'count',
            columnId: 'negativeNews',
            text: {
                ...entity,
                intl,
                extraProps,
                category: CATEGORY_NAMES.NEGATIVE_NEWS,
                counts: entity.counts?.negativeNews,
            },
            nonEditable: true,
        },
        {
            type: 'count',
            columnId: 'news',
            text: { ...entity, category: CATEGORY_NAMES.NEWS, counts: entity.counts?.news, extraProps },
            nonEditable: true,
        },
        {
            type: 'count',
            columnId: 'customNews',
            text: { ...entity, category: CATEGORY_NAMES.CUSTOM_NEWS, counts: entity.counts?.customNews, extraProps },
            nonEditable: true,
        },
        {
            type: entity.counts?.esgRatings === 1 ? 'esgRatings' : 'count',
            columnId: 'esgRatings',
            text: { ...entity, category: CATEGORY_NAMES.ESG_RATINGS, counts: entity.counts?.esgRatings, extraProps },
            nonEditable: true,
        },
        {
            type: 'count',
            columnId: 'ubo',
            text: { ...entity, category: CATEGORY_NAMES.DNB, counts: entity.counts?.ubo, extraProps },
            nonEditable: true,
            toolTip: {
                id: 'batch-screening-ubo-tool-tip',
                toolTipMessage: formatRichMessage({ id: 'BatchScreening.page.table.ubo.counts.tooltip' }, intl),
                display: true,
            },
        },
        {
            type: 'count',
            columnId: 'directors',
            text: {
                ...entity,
                category: CATEGORY_NAMES.COMPANY_RESOURCES,
                counts: entity.counts?.directors,
                extraProps,
            },
            nonEditable: true,
        },
        {
            type: shouldDisplayRiskScore ? 'sanctions' : 'count',
            columnId: 'sanctions',
            text: {
                ...entity,
                intl,
                category: CATEGORY_NAMES.SANCTIONS_WATCHLIST,
                counts: entity.counts?.sanctions,
                isSnapshotVisible,
                extraProps,
            },
            nonEditable: true,
        },
        {
            type: 'count',
            columnId: 'financialReports',
            text: {
                ...entity,
                category: CATEGORY_NAMES.FINANCIAL_REPORT,
                counts: entity.counts?.financialReports,
                extraProps,
            },
            nonEditable: true,
        },
        {
            type: 'count',
            columnId: 'peps',
            text: { ...entity, category: CATEGORY_NAMES.PEPS, counts: entity.counts?.peps, extraProps },
            nonEditable: true,
        },
        {
            type: 'count',
            columnId: 'biographical',
            text: { ...entity, category: CATEGORY_NAMES.BIOGRAPHICAL, counts: entity.counts?.biographical, extraProps },
            nonEditable: true,
        },
        {
            type: 'count',
            columnId: 'lawSources',
            text: { ...entity, category: CATEGORY_NAMES.LAW_SOURCES, counts: entity.counts?.lawSources, extraProps },
            nonEditable: true,
        },
        {
            type: 'date',
            columnId: 'dateCreated',
            text: entity.dateCreated,
            nonEditable: true,
        },
        {
            type: 'date',
            columnId: 'lastUpdated',
            text: entity.lastUpdated,
            nonEditable: true,
        },
        {
            type: 'comments',
            columnId: 'comments',
            entity,
            nrOfUnhiddenColumns: columnsOrder.length,
            nonEditable: true,
            entityId: entity.id,
            rowId: entity.idx,
            openPopover: extraProps.comments.handleCommentIconClick,
            hasComments,
            activeRowId: extraProps.comments.activeCommentsSectionRowId,
        },
    ];

    // We suppose if entity.counts is empty then the counts are still loading
    const { blockedEntities } = extraProps;
    if (isEntityLoading(entity, blockedEntities)) {
        defaultRowStruct.forEach((item) => {
            if (['count', 'negativeNews', 'esgRatings', 'date','sanctions'].includes(item.type)) {
                item.isLoading = true;
            }
        });
    }

    const rowStructAfterReorder = columnsOrder.map((columnName) => {
        return defaultRowStruct.find((defaultRow) => defaultRow.columnId === columnName);
    });

    return rowStructAfterReorder;
};

export const isEntityLoading = (entity, blockedEntities) => {
    // If the entity is not ready, is ready but has failed or is blocked, remove the skeleton loader from it
    if (blockedEntities?.includes(entity.id) && !entity.isReady) return false;
    // Otherwise determine loading status by counts or isReady or if the entity is ready but is failed
    return (isEmpty(entity.counts) && !entity.failed) || !entity.isReady;
};

//checks if the given content type name (string) is enabled in the user preferences general settings object
export const checkIsEnabledInGeneralSettings = (contentTypes, name) => {
    const contentType =
        contentTypes.find(
            ({ name: contentTypeFromGeneralSettingsName }) => contentTypeFromGeneralSettingsName === name
        ) ?? {};
    const { value: isContentTypeEnabled } = contentType;
    return isContentTypeEnabled;
};

export const getDefaultColumnsPreferences = (shouldDisplayRiskScore, contentTypes) => {
    return [
        {
            columnId: MULTIPLE_ENTITIES_ENTITY_NAME,
            label: <FormattedMessage id="BatchScreening.page.table.label.entity" />,
            hidden: false,
            order: -1,
        },
        {
            columnId: NEGATIVE_NEWS,
            label: (
                <FormattedMessage
                    id={
                        shouldDisplayRiskScore
                            ? 'NegativeNewsRisk.label.newsRisk'
                            : 'General.categoryName.label.negativeNews'
                    }
                />
            ),
            hidden: false,
            enabledInGeneralSettings: checkIsEnabledInGeneralSettings(contentTypes, NEGATIVE_NEWS),
            order: 0,
        },
        {
            columnId: NEWS,
            label: <FormattedMessage id="General.categoryName.label.news" />,
            hidden: false,
            enabledInGeneralSettings: checkIsEnabledInGeneralSettings(contentTypes, NEWS),
            order: 1,
        },
        {
            columnId: CUSTOM_NEWS,
            label: <FormattedMessage id="General.categoryName.label.customNews" />,
            hidden: false,
            enabledInGeneralSettings: checkIsEnabledInGeneralSettings(contentTypes, CUSTOM_NEWS),
            order: 2,
        },
        {
            columnId: ESG_RATINGS,
            label: <FormattedMessage id="General.categoryName.label.esgRatings" />,
            hidden: false,
            enabledInGeneralSettings: checkIsEnabledInGeneralSettings(contentTypes, ESG_RATINGS),
            order: 3,
        },
        {
            columnId: DNB,
            label: <FormattedMessage id="General.categoryName.label.ubo" />,
            hidden: false,
            enabledInGeneralSettings: checkIsEnabledInGeneralSettings(contentTypes, DNB),
            order: 4,
        },
        {
            columnId: COMPANY_RESOURCES,
            label: <FormattedMessage id="General.categoryName.label.directors" />,
            hidden: false,
            enabledInGeneralSettings: checkIsEnabledInGeneralSettings(contentTypes, COMPANY_RESOURCES),
            order: 5,
        },
        {
            columnId: SANCTIONS_WATCHLIST,
            label: <FormattedMessage id="General.categoryName.label.sanctions" />,
            hidden: false,
            enabledInGeneralSettings: checkIsEnabledInGeneralSettings(contentTypes, SANCTIONS_WATCHLIST),
            order: 6,
        },
        {
            columnId: FINANCIAL_REPORT,
            label: <FormattedMessage id="General.categoryName.label.financialReports" />,
            hidden: false,
            enabledInGeneralSettings: checkIsEnabledInGeneralSettings(contentTypes, FINANCIAL_REPORT),
            order: 7,
        },
        {
            columnId: PEPS,
            label: <FormattedMessage id="General.categoryName.label.peps" />,
            hidden: false,
            enabledInGeneralSettings: checkIsEnabledInGeneralSettings(contentTypes, PEPS),
            order: 8,
        },
        {
            columnId: BIOGRAPHICAL,
            label: <FormattedMessage id="General.categoryName.label.biographical" />,
            hidden: false,
            enabledInGeneralSettings: checkIsEnabledInGeneralSettings(contentTypes, BIOGRAPHICAL),
            order: 9,
        },
        {
            columnId: LAW_SOURCES,
            label: <FormattedMessage id="General.categoryName.label.lawSources" />,
            hidden: false,
            enabledInGeneralSettings: checkIsEnabledInGeneralSettings(contentTypes, LAW_SOURCES),
            order: 10,
        },
        {
            columnId: ENTITY_VIEW_CREATED_DATE,
            label: <FormattedMessage id="General.label.createdDate" />,
            hidden: false,
            enabledInGeneralSettings: true,
            order: 11,
        },

        {
            columnId: ENTITY_VIEW_LAST_UPDATE_DATE,
            label: <FormattedMessage id="General.label.lastUpdateDate" />,
            hidden: false,
            enabledInGeneralSettings: true,
            order: 12,
        },
        {
            columnId: MULTIPLE_ENTITIES_COMMENTS,
            label: <FormattedMessage id="General.label.comments" />,
            hidden: false,
            order: 13,
        },
    ];
};

export const disableTableScroll = () => {
    utils.disableElementScroll('reactgrid-scrollarea');
};

export const enableTableScroll = () => {
    utils.enableElementScroll('reactgrid-scrollarea');
};

export const extractCommentsList = (entities) => (entities?.length ? entities.map((entity) => entity.notes ?? []) : []);

export const updateCommentsListOnEdit = (comments, rowId, commentId, newText) =>
    comments.map((commentsListPerEntity, index) => {
        if (index === rowId) {
            return commentsListPerEntity.map((comm) =>
                comm.id === commentId
                    ? {
                          ...comm,
                          text: newText,
                      }
                    : comm
            );
        }

        return commentsListPerEntity;
    });

export const updateCommentsListOnAdd = (comments, rowId, addedComment) =>
    comments.map((commentsListPerEntity, index) =>
        index === rowId ? [...commentsListPerEntity, addedComment] : commentsListPerEntity
    );

export const getEsgRatingsCellType = (entity) => {
    if (entity.counts.esgRatings !== 1) {
        return 'count';
    } else {
        return entity.esgRatings ? 'esgRatings' : 'count';
    }
};

export const getParamsForUrl = ({ lastSelectedView = null, searchEntity = null }) => {
    const params = {};
    if (lastSelectedView?.length && lastSelectedView !== MULTIPLE_ENTITIES_NONE_SELECTED_VIEW)
        params.viewId = lastSelectedView;

    if (searchEntity?.length) params.searchTerm = searchEntity;

    return params;
};

export const createQueryString = ({ searchTerm = null, viewId = null, filename = null}) => {
    let result = '';

    if (searchTerm && viewId && viewId !== MULTIPLE_ENTITIES_NONE_SELECTED_VIEW)
        result = `?searchTerm=${searchTerm}&viewId=${viewId}`;
    else if (searchTerm && !viewId) result = `?searchTerm=${searchTerm}`;
    else if (!searchTerm && viewId && viewId !== MULTIPLE_ENTITIES_NONE_SELECTED_VIEW) result = `?viewId=${viewId}`;

    if(result.length && filename) {
        result = result.concat(`&fileName=${filename}`);
    } else if (filename) {
        result = result.concat(`?fileName=${filename}`);
    }
    return result;
};

/**
 *  A helper method that determines the translated tooltip message for the action bar buttons.
 * @param conditions    various conditions that differ depending on the action type and used for computing the correct message.
 * <p>There are a few cases: </p>
 * <br>
 * <ul>
 *     <li>Add to Report, Copy, Create Alert, Refresh: areEntitiesRefreshing & isButtonDisabled should be sent</li>
 *     <li>Download: isButtonDisabled should be sent</li>
 *     <li>Share View: isShareViewButtonDisabled, noViewSelected & isTrial should be sent</li>
 * </ul>
 * @param actionType    the type of the action button
 * @param intl  The `intl` object, which is an instance of the `react-intl` library's Internationalization API.
 * It provides methods for formatting and translating messages based on the user's locale and preferences.
 * @param enabledTooltipMessage the translation key of the tooltip message
 * @returns {string}    the translated tooltip message
 */
export const getTooltipMessage = (
    conditions,
    actionType,
    intl,
    enabledTooltipMessage = 'UserPreferences_topic_customNewsSearches_table_header_action',
) => {
    const { areEntitiesRefreshing, isButtonDisabled, isShareViewButtonDisabled, noViewSelected, isTrial } = conditions;
    let tooltipMessage;

    switch (actionType) {
        case TOOLBAR_ACTIONS.generateReport:
        case TOOLBAR_ACTIONS.copy:
        case TOOLBAR_ACTIONS.alert:
        case TOOLBAR_ACTIONS.refresh:
            if (isButtonDisabled) {
                tooltipMessage = 'BatchScreening.page.actionButton.disabledButtons';
                break;
            }

            tooltipMessage = areEntitiesRefreshing
                ? 'ArticleAction.toolbarDisabledUntilRefreshIsComplete'
                : enabledTooltipMessage;
            break;
        case TOOLBAR_ACTIONS.deleteEntity:
            tooltipMessage = isButtonDisabled ? 'BatchScreening.page.actionButton.disabledButtons' : enabledTooltipMessage;
            break;
        case TOOLBAR_ACTIONS.shareView:
            if (isTrial) {
                tooltipMessage = 'BatchScreening.page.actionButton.notShareableTrialUser';
            } else {
                tooltipMessage = isShareViewButtonDisabled
                    ? noViewSelected
                        ? 'BatchScreening.page.actionButton.notInViewError'
                        : 'BatchScreening.page.actionButton.notShareable'
                    : 'BatchScreening.page.actionButton.shareView';
            }
            break;
        default:
            tooltipMessage = enabledTooltipMessage;
            break;
    }

    return formatRichMessage({ id: tooltipMessage }, intl);
};

/*
 *this function will return the correct context menu options for the multiple entities table columns depending on the params given
 * @param  {Object[]} tableMenuOptions array of objects containing the menu options for the table
 * @param  {Object} menuOptionConditions object containing the various conditions for getting the correct menu options
 * @param  {Object} sortInfo object containing the current sort info for the table in state
 * @return {Object[]} the correct array of context menu options for the table
 */
export const getMenuOptions = (tableMenuOptions, menuOptionConditions, sortInfo) => {
    const { sortAsc, sortDesc } = tableMenuOptions;
    const { shouldAddBothSortOptions, shouldAddOneSortOption } = menuOptionConditions;

    let newMenuOptions = [];

    //case where a sort has not yet been selected, so hence adding both sort options
    if (shouldAddBothSortOptions) {
        newMenuOptions.push(...[sortAsc, sortDesc]);
    }
    //case where a sort has already been selected, so hence adding only one sort option (opposite of what was selected)
    if (shouldAddOneSortOption) {
        sortInfo.direction === SORT_DIRECTION.ASC ? newMenuOptions.push(sortDesc) : newMenuOptions.push(sortAsc);
    }

    return newMenuOptions;
};

/*
 *this function will return a value that will be used to determine if the entities are still loading, can be used in a useEffect with the params as dependancies
 * @param  {String} pollingStatus the current status of the polling for multiple entites upload which comes from the redux value
 * @param  {Boolean} tableHasData whether the table currently has data or not
 * @param  {Object[]} tableData array of objects containing the curent data for the table
 * @return {Boolean} true if any entities are still loading, false otherwise
 */
export const getEntitiesLoadingStatus = (pollingStatus, tableHasData, tableData) => {
    if (pollingStatus === POLLING_STATUS.IN_PROGRESS) return false;
    //if polling status is undefined or not started after starting (happens if user navigates away from page and then back again or refreshes page)
    //we will then use isReady from the tableData to determine if the entities are still loading
    if (!pollingStatus || pollingStatus === POLLING_STATUS.NOT_STARTED) {
        return tableHasData ? !tableData?.some((item) => !item.isReady) : false;
    } else {
        return tableHasData;
    }
};

export const generateEditEntityPayload = ({
    displayName,
    entityName,
    searchQueryType,
    prefilterQuery,
    contentTypes,
    timezone,
    language,
    startEachArticleOnNewPage,
    billingId,
    costCode,
    viewId,
}) => {

    const categoryOrder = contentTypes
        .filter((source) => source.value)
        .map((source) => ({
            sourceType: source.name,
            order: source.reportOrder,
        }))
        .sort((a, b) => a.order - b.order);

    const searchTerms = getAllTerms(entityName)
        .map(sanitizeTerm)
        .filter((term) => term.length);
    const currentDate = utils.getCurrentTime();
    const fileName =
        DEFAULT_FILENAME +
        utils.formatReportFileName(entityName + '_') +
        utils.formatReportDateWithtTimezoneOffset(currentDate);

    const searchEntityInfo = {
        searchQuery: entityName,
        searchQueryType: searchQueryType,
        prefilterQuery,
        searchTerms,
        downloadInfo: {
            fileType: DOC_TYPE.PDF,
            fileName,
        },
        alertData: {},
    };

    return {
        displayName,
        timezone,
        language,
        startEachArticleOnNewPage,
        billingId,
        costCode,
        categoryOrder,
        searchEntity: searchEntityInfo,
        viewId,
    };
};

export const isViewNotFoundException = (error, viewId) => {
    try {
        const { exception } = JSON.parse(error.response.text);
        const viewNotFound = exception === `View with id ${viewId} was not found!`;
        return viewNotFound;
    } catch {
        return false;
    }
};

export const getViewName = (views, viewId) => {
    const view = views.find(({ id }) => id === viewId);
    return view?.name;
};

export const isSelectAllChecked = (selectAllSetting) => {
    // Added checks to handle missing parameters in the useViews hook to prevent test failures and ensure the correct return value
    return selectAllSetting?.includeDB || !!selectAllSetting?.limit;
};

export const onlyLoadedEntitiesAreSelected = (selectAllSetting) => {
    return !selectAllSetting.includeDB && !!selectAllSetting.limit;
};

export const getSelectionPayload = (
    selectAllSetting,
    selectedItemIds,
    unselectedItemIds,
    sortInfo,
    shouldDisplayRiskScores
) => {
    const payload = {
        entityIds: selectedItemIds,
        isForAll: isSelectAllChecked(selectAllSetting),
        sortBy: sortInfo?.sortBy,
        sortDirection: sortInfo?.direction,
        displayRiskScores: shouldDisplayRiskScores,
    };

    if (onlyLoadedEntitiesAreSelected(selectAllSetting)) {
        payload.selectAllLimit = selectAllSetting.limit;
    }

    if (isSelectAllChecked(selectAllSetting)) {
        delete payload.entityIds;
        payload.unselectedEntityIds = unselectedItemIds;
    }

    return payload;
};

export const buildEntityIdDisplayNameMap = (selectedItems) => {
    return selectedItems.reduce((entityIdDisplayNameMap, item) => {
        entityIdDisplayNameMap[item.id] = item.displayName;
        return entityIdDisplayNameMap;
    }, {});
};

export const buildUpdateViewPayload = ({ userPermId, selectedItems, searchTerm, currentViewId, isForAll }) => {
    const entityIdDisplayNameMap = buildEntityIdDisplayNameMap(selectedItems);
    const payload = {
        userPermId,
        ...(isForAll && { isForAll }),
        entityIdDisplayNameMap: isForAll ? {} : entityIdDisplayNameMap,
    };

    if (isForAll) {
        if (searchTerm) payload.searchTerm = searchTerm;
        if (currentViewId && currentViewId !== MULTIPLE_ENTITIES_NONE_SELECTED_VIEW)
            payload.currentViewId = currentViewId;
    }

    return payload;
};

export const getSelectedItemsCount = (
    selectAllSetting,
    entityViewCount,
    unselectedItemsCount,
    selectedItemsCount
) => {
    if (isSelectAllChecked(selectAllSetting)) {
        return selectAllSetting.includeDB
            ? entityViewCount - unselectedItemsCount
            : selectAllSetting.limit - unselectedItemsCount;
    }

    return selectedItemsCount;
};
