import React from 'react';
import withStateProvider from '@reusable/Table/providers/hoc/withStateProvider';
import TableHandlersProvider from '@reusable/Table/providers/TableHandlersProvider';
import TableStateProvider from '@reusable/Table/providers/TableStateProvider';

import reduxStore from '@reduxStore';

import withHistoryEndpoints from './endpoints/withHistoryEndpoints';
import MyHistoryTableConsumer from './consumers/MyHistoryTableConsumer';
import { withAppContext } from '@utils/contexts';
import { compose } from 'redux';

const HistoryTableHandlersProvider = withHistoryEndpoints(TableHandlersProvider); // adds history specific endpoints

const HistoryTable = (props) => {
    return (
        <HistoryTableHandlersProvider
            {...props.stateProviderProps}
            state={{
                ...props.stateProviderProps.state,
                pagination: {
                    ...props.stateProviderProps.state.pagination,
                    pageSize: reduxStore.getState().user.preferences.generalSettings.generalPageSize,
                },
                publicRecordsEnabled: props.publicRecordsEnabled,
            }}
            provideHandlers={(handlerProviderProps) => (
                <MyHistoryTableConsumer
                    {...handlerProviderProps}
                    onHistoryRunSearch={props.onHistoryRunSearch}
                    publicRecordsEnabled={props.publicRecordsEnabled}
                    timezone={props.timezone}
                    language={props.language}
                    maxNumberOfHistoryEntries={props.context.maxNumberOfHistoryEntries}
                    contentTypes={props.contentTypes}
                />
            )}
        />
    );
};

export default compose(withAppContext, withStateProvider(TableStateProvider))(HistoryTable);
