// @flow
import * as React from 'react';
import Calendar from 'react-calendar';
import { CALENDAR_DATE_FORMAT_FE, CALENDAR_SHORT_DAY_FORMAT_FE, CALENDAR_MONTH_YEAR_FORMAT_FE } from '@constants';
import utils from '@utils/utilities';

type State = {
    isCalendarOpen: boolean,
    calendarMarginTop: number,
};

type Props = {
    minDate: Date,
    maxDate: Date,
    label: string,
    className: string,
    onChange: (day: string) => void,
    value: Date,
    config: Object,
    name: string,
    dateFormat: string,
    disabled: boolean,
    fromResultsList: boolean,
    radioGroupReference: HTMLDivElement,
};

class DateRangeSelect extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isCalendarOpen: false,
            calendarMarginTop: 0,
        };
    }

    node: ?HTMLDivElement;

    componentDidMount() {
        document.addEventListener('mousedown', this.handleMouseDown, false);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleMouseDown, false);
    }

    /**
     * Method that checks if the clicks are inside the component, if not, hide the calendar
     * @param e
     */
    handleMouseDown = (e: MouseEvent) => {
        if (this.props.fromResultsList) {
            this.setCalendarTopPosition();
        }

        if (this.node && e.target instanceof Node && this.node.contains(e.target)) {
            this.openCalendar();
            return;
        }

        this.closeCalendar();
    };

    /**
     * Opens the calendar
     */
    openCalendar = () => {
        this.setState({ isCalendarOpen: true });
    };

    /**
     * Closes the calendar
     */
    closeCalendar = () => {
        this.setState({ isCalendarOpen: false });
    };

    /**
     * Date selection handler
     * @param day
     */
    handleClickDay = (day: string) => {
        this.props.onChange(day);
        this.closeCalendar();
    };

    setCalendarTopPosition = () => {
        if (this.node && this.props.radioGroupReference) {
            const scrollToTop = this.props.radioGroupReference.scrollTop;
            const dateSelectHeight = this.node.getBoundingClientRect().height;
            const offsetTop = this.node ? this.node.offsetTop : 0;

            this.setState({
                calendarMarginTop: offsetTop - scrollToTop + dateSelectHeight - 5,
            });
        }
    };

    render() {
        const { minDate, maxDate, label, className, value, name, config } = this.props;
        const doubleChevronRight = (
            <div className={'icon-double-chevron-right'}>
                <i className={'la-ChevronRight'} />
                <i className={'la-ChevronRight'} />
            </div>
        );
        const doubleChevronLeft = (
            <div className={'icon-double-chevron-left'}>
                <i className={'la-ChevronLeft'} />
                <i className={'la-ChevronLeft'} />
            </div>
        );

        return (
            <div
                ref={(node) => (this.node = node)}
                className={
                    (this.props.disabled ? 'disabled ' : this.state.isCalendarOpen ? 'is-open ' : '') +
                    'date-select ' +
                    className
                }
                onClick={this.handleMouseDown}
            >
                {label && <label htmlFor={name}>{label}</label>}
                <input
                    type={'text'}
                    id={name}
                    readOnly
                    className={'date-view'}
                    value={
                        value !== null && value !== undefined
                            ? utils
                                  .getUserLocaleDateWithoutTimezone(value)
                                  .format(this.props.dateFormat || CALENDAR_DATE_FORMAT_FE)
                            : ''
                    }
                />
                <i className="la-Calendar" />

                {this.state.isCalendarOpen && (
                    <div
                        className={`calendar-container ${this.props.fromResultsList ? 'with-top' : ''}`}
                        style={{ marginTop: this.state.calendarMarginTop + 'px' }}
                    >
                        <Calendar
                            maxDate={maxDate}
                            nextLabel={<i className={'la-ChevronRight'} />}
                            next2Label={doubleChevronRight}
                            prevLabel={<i className={'la-ChevronLeft'} />}
                            prev2Label={doubleChevronLeft}
                            showFixedNumberOfWeeks={true}
                            calendarType={'US'}
                            minDate={minDate}
                            minDetail={'decade'}
                            value={value}
                            formatMonthYear={(locale, date) =>
                                utils.getUserLocaleDateWithoutTimezone(date).format(CALENDAR_MONTH_YEAR_FORMAT_FE)
                            }
                            formatShortWeekday={(locale, date) =>
                                utils.getUserLocaleDateWithoutTimezone(date).format(CALENDAR_SHORT_DAY_FORMAT_FE)
                            }
                            onChange={this.handleClickDay}
                            className={'date-select-object'}
                            {...config}
                        />
                    </div>
                )}
            </div>
        );
    }
}

export default (DateRangeSelect: React.AbstractComponent<Props>);
