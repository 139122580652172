import React, { memo } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import EsgRatingsUtils from '../EsgRatingsUtils';
import { ESG_RATINGS_VISUALISATIONS } from '@constants';

const EsgRatingsDonutChart = (props) => {
    const {
        size,
        fontSize,
        rating,
        ratingCategory,
        columnIndex,
        onChartClick,
        titleColor = ESG_RATINGS_VISUALISATIONS.POD_COUNT_DONUT.DEFAULT_TITLE_COLOR,
    } = props;
    const fillColor = EsgRatingsUtils.getColorsBasedOnCategory(ratingCategory).fillColor;
    const { defaultColor = EsgRatingsUtils.getColorsBasedOnCategory(ratingCategory).defaultColor } = props;
    const ratingNumber = rating === 'N/A' || rating === undefined ? 0 : +rating;
    const tooltipPosition = EsgRatingsUtils.getTooltipPositionBasedOnColumnIndex(columnIndex);

    const graphOptions = {
        chart: {
            type: 'pie',
            animation: false,
            height: size,
            width: size,
            margin: ESG_RATINGS_VISUALISATIONS.MARGIN,
            style: {
                cursor: onChartClick ? 'pointer' : 'none',
            },
            events: {
                click: () => onChartClick && onChartClick(),
            },
        },
        title: {
            text: rating || 'N/A',
            align: 'center',
            y: (size + ESG_RATINGS_VISUALISATIONS.MARGIN) / 2,
            style: {
                fontFamily: 'Lato',
                fontSize: `${fontSize}px`,
                color: titleColor,
                cursor: onChartClick ? 'pointer' : 'none',
            },
        },
        plotOptions: {
            pie: {
                size: '100%',
                innerSize: '85%',
                borderWidth: 0,
                dataLabels: {
                    enabled: false,
                },
            },
            series: {
                enableMouseTracking: false,
            },
        },
        series: [
            {
                data: [
                    {
                        y: ratingNumber,
                        color: fillColor,
                    },
                    {
                        y: 100 - ratingNumber,
                        color: defaultColor,
                    },
                ],
                size: '100%',
            },
        ],
        credits: { enabled: false },
        tooltip: { enabled: false },
    };

    // This is only for testing purposes - https://api.highcharts.com/class-reference/Highcharts#.useSerialIds
    if (process.env && process.env.NODE_ENV === 'test') {
        Highcharts.useSerialIds(true);
    }

    return (
        <div
            data-tip={props.tooltip}
            data-for={`category-and-subcategory-${tooltipPosition}-tooltip`}
            className="donut-container"
            data-track="esg_ratings-donut-container"
            id="esg-ratings-donut-container"
        >
            <HighchartsReact highcharts={Highcharts} isPureConfig={true} options={graphOptions} />
        </div>
    );
};

export default memo(EsgRatingsDonutChart);
