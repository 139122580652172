// @flow
import * as React from 'react';
import { withRouter } from 'react-router';
import { FormattedMessage } from 'react-intl';
import {
    ALL_ITEMS,
    CATEGORY_NAMES,
    EXCLUDE,
    FILTER_INFO,
    FILTER_TYPES,
    FILTER_TYPES_CONSTANTS,
    INCLUDE,
    POST_FILTER_DUNS_FILTER,
    POST_FILTER_FUZZY_THRESHOLD,
    POST_FILTERS_DEFAULT_SORT,
    POSTFILTER_TYPE,
    RANGE_TYPE_CUSTOM,
} from '@constants';
import SearchWithinResults from './components/SearchWithinResults';
import FilterList from './components/FilterList';
import FilterPod from '../Form/FilterPod';
import PostFiltersContainer from '@pages/MainSearch/components/PostFiltersContainer';
import SearchUtils, { determineDateRange } from '@pages/MainSearch/SearchUtils';
import { cloneDeep, debounce } from 'lodash';
import utils from '@utils/utilities';
import PropTypes from 'prop-types';
import { withAppContext } from '@utils/contexts';
import categoryUtils from '@utils/categoryUtils';
import * as investigationEvents from '@sagas/constants/investigationConstants';
import type { Context, RouterLocation } from '@utils/flow/utilities.type.guards';
import type { SearchCategoryType } from '@pages/MainSearch/components/typeGuards/ResultsList.typeGuards';
import userPreferenceApi from '@scripts/pages/UserPreferances/UserPreferencesApi.api';

const CATEGORY = 'category';
const SEARCH_WITHIN = 'searchWithin';

function getSelectedValues(options: Options, postFilterType: string): any {
    let allItemsSelected = true;

    switch (postFilterType) {
        case POSTFILTER_TYPE.SOURCE:
        case POSTFILTER_TYPE.SOURCE_TYPE:
        case POSTFILTER_TYPE.SOURCE_NAME:
        case POSTFILTER_TYPE.LANGUAGE:
        case POSTFILTER_TYPE.GEOGRAPHY:
        case POSTFILTER_TYPE.COMPANY:
        case POSTFILTER_TYPE.DOCKET_STATUS:
        case POSTFILTER_TYPE.SUBJECT:
        case POSTFILTER_TYPE.INDUSTRY:
        case POSTFILTER_TYPE.ESG_FACTORS: {
            let selectedValues = [];
            options.forEach((option) => {
                if (option.checked) {
                    let field = utils.isPostFilterCustomType(postFilterType) ? option.labelId : option.label;

                    selectedValues.push(field);
                } else {
                    allItemsSelected = false;
                }
            });
            if (allItemsSelected) {
                //all items are selected, no values will be sent to search
                return undefined;
            }
            return selectedValues;
        }

        case POSTFILTER_TYPE.SIMILAR_NAMES: {
            let selectedFuzzy = [];
            options.forEach((option) => {
                if (option.checked) {
                    selectedFuzzy.push({ name: option.label, selected: true });
                }
            });
            return { list: selectedFuzzy };
        }
        case POSTFILTER_TYPE.SUGGESTED_NAMES: {
            let selectedSuggestedNames = [];
            options.forEach((option) => {
                if (option.checked) {
                    selectedSuggestedNames.push({ name: option.label, selected: true });
                }
            });
            return { list: selectedSuggestedNames };
        }
        case POSTFILTER_TYPE.PROXIMITY_NEGATIVE_TERMS:
        case POSTFILTER_TYPE.COMPANY_MENTIONS:
        case POSTFILTER_TYPE.PERSON_MENTIONS:
        case POSTFILTER_TYPE.FUZZY_SEARCH: {
            let selectedValue = null;
            for (let i = 0; i < options.length; i++) {
                if (options[i].checked) {
                    selectedValue = options[i].count;
                    break;
                }
            }
            return selectedValue;
        }

        case POSTFILTER_TYPE.DATE_RANGE: {
            let selectedDate = options.find((option) => {
                return option.checked === true;
            });
            return selectedDate ? selectedDate.count : null;
        }
        case POSTFILTER_TYPE.DUNS_FILTER:
            // text filter, has one option, the first
            return options[0];
        default:
            console.log('%s not implemented');
    }
}

type Props = {
    adHocSearch: {
        newsDateRange: {
            range: string,
        },
        companyDateRange: {
            range: string,
        },
        legalDateRange: {
            range: string,
        },
    },
    addTerms(inputSearchWithin: string, searchWhitinValue: string, articleType: string): any,
    searchWithin(string, Object): void,
    updatePostFilterConfig(articleType: string, postFilterType: string, options: Options): void,
    updatePostFilterForCategory(articleType: string, postFilterFieldName: string, selectedPostFilters: Object): void,
    resetPostFilterConfigValues(articleType: string, postFilterType: string, values: Object): void,
    resetPostFilterToDefaultValues(
        articleType: string,
        postFilterType: string,
        postFilterFieldName: string,
        values: Object,
        dynamic: boolean
    ): void,
    updatePostFilterTreeData(searchFieldName: string): void,
    searchResults: Object,
    articleType: string,
    contentLanguage: string,
    context?: Context,
    excludeTerms: Array<string>,
    generalSettings: {
        newsSources: {
            dateRange: string,
        },
        companySources: {
            dateRange: string,
        },
        legalSources: {
            dateRange: string,
        },
    },
    handleSelectCategory(): any,
    historyCategory: {
        categoryName: string,
    },
    includeTerms: Array<string>,
    isOpenLegalCategory: ?boolean,
    isSearchRerun: boolean,
    language: string,
    location?: RouterLocation,
    onPostfilterChange(articleType: string, postFilterType: string, options?: Options): void,
    onSearchWhithinChange?: (articleType: string, searchWhitinValue: string) => void,
    onSortPostFilters(articleType: string, postFilterType: string, sort: string): any,
    postFiltersConfig: Object,
    user: Object,
    searchType: string,
    showFilters: boolean,
    shouldUpdateCustomDatesState: boolean,
    updateCustomDatesBasedOnNNV?: (shouldUpdate: boolean) => void,
    isFilterSectionCollapsed: boolean,
    toggleFilterSectionVisibility: (isFilterSectionCollapsed: boolean) => void,
    postFilterContainerRef: { current: Object | HTMLDivElement },
    toggleFilterVisibilityButtonRef: { current: Object | HTMLDivElement },
    searchCategory: (SearchCategoryType) => void,
    expandedFiltersSection: string,
    updateExpandedFiltersSection: (expandedFiltersSection: string) => void,
};

type State = {
    inputSearchWithin: string,
    errorMessage: string,
    searchWhitinValue: string,
    categoryDropDown?: boolean,
    searchWithinDropDown?: boolean,
    lastOptionsWithinDebouncedSearch: Array<Option>,
    activePostFilters: Array<string>,
    filtersCollapsingState: any,
};

type Option = {
    label?: string,
    labelId?: string,
    count?: number | boolean,
    checked: boolean,
};

type Options = Array<Option>;

class SearchTypeFilter extends React.Component<Props, State> {
    static propTypes = {
        onPostfilterChange: PropTypes.func,
    };
    static defaultProps = {
        onPostfilterChange: () => { },
    };

    debouceSearch: (Object) => void;

    constructor(props) {
        super(props);
        this.state = {
            inputSearchWithin: '',
            errorMessage: '',
            searchWhitinValue: INCLUDE,
            lastOptionsWithinDebouncedSearch: [],
            activePostFilters: [],
            filtersCollapsingState: FILTER_TYPES,
        };
        this.debouceSearch = debounce(this.deboucedSearch, 1500).bind(this);
    }

    componentDidMount() {
        this.handleExpandedFilterSectionVisibility();
    }

    componentDidUpdate(prevProps, prevState) {
        // if the user clicks on the same filter twice (except ContentTypes), update the preference, but keep the same state in Redux
        if (prevState.filtersCollapsingState !== this.state.filtersCollapsingState) {
            const areAllFiltersCollapsed = Object.values(this.state.filtersCollapsingState).every(value => !value);
            areAllFiltersCollapsed && this.props.expandedFiltersSection !== FILTER_TYPES_CONSTANTS.CONTENT_TYPES && this.updateExpandedFilterSectionPreference(FILTER_TYPES_CONSTANTS.CONTENT_TYPES);
        }

        // if the user changes the expanded filter, update the preference
        if (prevProps.expandedFiltersSection !== this.props.expandedFiltersSection) {
            this.updateExpandedFilterSectionPreference(this.props.expandedFiltersSection);
        }

        if (prevProps.articleType !== this.props.articleType) {
            this.handleExpandedFilterSectionVisibility()
        }
    }

    getDateDefaultValues = (): ?string => {
        let { adHocSearch, generalSettings, articleType, postFiltersConfig, isSearchRerun } = this.props;
        let defaultDate;

        // Default date range from ad hoc/preferences
        const newsDateRange = adHocSearch.newsDateRange
            ? adHocSearch.newsDateRange.range
            : generalSettings.newsSources.dateRange;
        const companyDateRange = adHocSearch.companyDateRange
            ? adHocSearch.companyDateRange.range
            : generalSettings.companySources.dateRange;
        const legalDateRange = adHocSearch.legalDateRange
            ? adHocSearch.legalDateRange.range
            : generalSettings.legalSources.dateRange;
        const otherCategoriesDateRange = determineDateRange(adHocSearch)
            ? determineDateRange(adHocSearch)
            : 'all';

        // If search is rerun, we already have a date range set on the post filters configuration
        let selectedDateRange = null;

        if (isSearchRerun && postFiltersConfig) {
            if (postFiltersConfig[articleType] && postFiltersConfig[articleType][POSTFILTER_TYPE.DATE_RANGE]) {
                let dateRangeProperties = postFiltersConfig[articleType][POSTFILTER_TYPE.DATE_RANGE];
                let visibleValues = dateRangeProperties.values
                    ? dateRangeProperties.values.filter((option) => option.show === true)
                    : null;

                if (visibleValues && visibleValues.length > 0) {
                    selectedDateRange = visibleValues[visibleValues.length - 1].count;
                } else if (dateRangeProperties.selected) {
                    selectedDateRange = dateRangeProperties.selected;
                }
            }
        }

        switch (articleType) {
            case CATEGORY_NAMES.NEWS:
            case CATEGORY_NAMES.NEGATIVE_NEWS:
            case CATEGORY_NAMES.NEGATIVE_NEWS_EN:
            case CATEGORY_NAMES.NEGATIVE_NEWS_FR:
            case CATEGORY_NAMES.NEGATIVE_NEWS_NL:
            case CATEGORY_NAMES.NEGATIVE_NEWS_DE:
            case CATEGORY_NAMES.NEGATIVE_NEWS_ES:
            case CATEGORY_NAMES.NEGATIVE_NEWS_TR:
            case CATEGORY_NAMES.NEGATIVE_NEWS_SV:
            case CATEGORY_NAMES.NEGATIVE_NEWS_MS:
            case CATEGORY_NAMES.NEGATIVE_NEWS_PT:
            case CATEGORY_NAMES.NEGATIVE_NEWS_JA:
            case CATEGORY_NAMES.NEGATIVE_NEWS_ZH:
            case CATEGORY_NAMES.NEGATIVE_NEWS_IT:
            case CATEGORY_NAMES.NEGATIVE_NEWS_AR:
            case CATEGORY_NAMES.NEGATIVE_NEWS_RU:
            case CATEGORY_NAMES.NEGATIVE_NEWS_PL:
            case CATEGORY_NAMES.CUSTOM_NEWS:
            case CATEGORY_NAMES.CUSTOM_NEWS1:
            case CATEGORY_NAMES.CUSTOM_NEWS2:
            case CATEGORY_NAMES.CUSTOM_NEWS3:
            case CATEGORY_NAMES.LN_CUSTOM_NEWS1:
            case CATEGORY_NAMES.ADMIN_CUSTOM_NEWS1:
            case CATEGORY_NAMES.ADMIN_CUSTOM_NEWS2:
            case CATEGORY_NAMES.ADMIN_CUSTOM_NEWS3:
                defaultDate = selectedDateRange || newsDateRange;
                break;
            case CATEGORY_NAMES.COMPANY_RESOURCES:
                defaultDate = selectedDateRange || companyDateRange;
                break;
            case CATEGORY_NAMES.AGENCY_DECISION:
            case CATEGORY_NAMES.STATE_DOCKETS:
            case CATEGORY_NAMES.FEDERAL_DOCKETS:
            case CATEGORY_NAMES.LAW_REVIEWS:
            case CATEGORY_NAMES.CASES:
            case CATEGORY_NAMES.VERDICTS:
                defaultDate = selectedDateRange || legalDateRange;
                break;
            case CATEGORY_NAMES.BIOGRAPHICAL:
            case CATEGORY_NAMES.PEPS:
            case CATEGORY_NAMES.SANCTIONS_WATCHLIST:
            case CATEGORY_NAMES.FINANCIAL_REPORT:
                defaultDate = selectedDateRange || otherCategoriesDateRange;
                break;
        }
        return defaultDate;
    };

    handleKeyPress = (event: SyntheticKeyboardEvent<>): void => {
        if (event.key === 'Enter' || event.keyCode === 13) {
            this.searchIncludeTerms();
        }
    };

    searchIncludeTerms = (): void => {
        let { searchWhitinValue, inputSearchWithin } = this.state;
        let { articleType, excludeTerms = [], includeTerms = [] } = this.props;

        if (inputSearchWithin === '') {
            this.setState({
                errorMessage: 'SearchWithin.emptyIncludeParameter.ValidationMessage',
            });
            return;
        }
        if (articleType !== CATEGORY_NAMES.NEGATIVE_NEWS_DE) {
            if (utils.isValidQuery(inputSearchWithin) === false) {
                this.setState({
                    errorMessage: 'SearchResults_Notifications.insufficientCharacters',
                });
                return;
            }
        }
        if (includeTerms.indexOf(inputSearchWithin) > -1 || excludeTerms.indexOf(inputSearchWithin) > -1) {
            this.setState({
                errorMessage: 'SearchWithin.includeParameterExist.ValidationMessage',
            });
            return;
        }

        this.setState(
            {
                inputSearchWithin: '',
                errorMessage: '',
            },
            () => {
                if (searchWhitinValue === INCLUDE || searchWhitinValue === EXCLUDE) {
                    this.props.addTerms(inputSearchWithin, searchWhitinValue, articleType);
                }
                if (this.props.onSearchWhithinChange) {
                    this.props.onSearchWhithinChange(articleType, searchWhitinValue);
                }
                const postFilterData = {
                    options: [inputSearchWithin],
                    postFilterType: searchWhitinValue,
                };
                this.props.searchWithin(investigationEvents.EVENT_TYPES.filterApplied, postFilterData);
            }
        );
    };

    handleIncludeTermsChange = (event: SyntheticInputEvent<>): void => {
        this.setState({
            inputSearchWithin: event.target.value,
        });
    };

    handleVisibility = (event: SyntheticInputEvent<>): void => {
        if (event.target.name === CATEGORY) {
            this.setState({
                categoryDropDown: !this.state.categoryDropDown,
            });
        }
        if (event.target.name === SEARCH_WITHIN) {
            this.setState({
                searchWithinDropDown: !this.state.searchWithinDropDown,
            });
        }
    };

    changeIncludeExclude = (event: SyntheticInputEvent<>): void => {
        this.setState({
            searchWhitinValue: event.target.value,
        });
    };

    onChangePostFilters = (
        options: Options,
        option: Option,
        event: SyntheticInputEvent<>,
        postFilterType: string,
        postFilterFieldName: string
    ): void => {
        const { articleType } = this.props;
        let lastSelectedOption = !option && options.length ? { rawLabel: ALL_ITEMS, checked: true } : option;

        this.setState({ activePostFilters: [postFilterType] });

        //update postFilterConfig
        this.props.updatePostFilterConfig(articleType, postFilterType, options);
        this.props.onPostfilterChange(articleType, postFilterType, options);

        //update postFilters with selected values
        let selectedPostFilters = getSelectedValues(options, postFilterType);
        // let postFilterFieldName = getPostFilterFieldName(articleType, postFilterType);
        if (postFilterType && postFilterFieldName) {
            if (postFilterType === POSTFILTER_TYPE.DATE_RANGE) {
                postFilterFieldName = FILTER_INFO.DATE_RANGE;
            }

            if (postFilterType === POSTFILTER_TYPE.DOCKET_STATUS && selectedPostFilters !== undefined) {
                // Need to send a string, instead of sending an array
                selectedPostFilters = utils.getDocketStatusValue(selectedPostFilters);
            }

            this.props.updatePostFilterForCategory(articleType, postFilterFieldName, selectedPostFilters);

            // un-debounced handler for duns filter
            if (postFilterType === POSTFILTER_TYPE.DUNS_FILTER) {
                lastSelectedOption = { rawLabel: POST_FILTER_DUNS_FILTER, checked: true };

                this.setState(
                    (prevState) => {
                        return {
                            lastOptionsWithinDebouncedSearch: [
                                ...prevState.lastOptionsWithinDebouncedSearch,
                                lastSelectedOption,
                            ],
                        };
                    },
                    () => this.deboucedSearch({ options, option, event, postFilterType, postFilterFieldName })
                );
            } else {
                if (
                    postFilterType !== POSTFILTER_TYPE.DATE_RANGE ||
                    (postFilterType === POSTFILTER_TYPE.DATE_RANGE && option.count !== RANGE_TYPE_CUSTOM)
                ) {
                    this.setState(
                        (prevState) => {
                            return {
                                lastOptionsWithinDebouncedSearch: [
                                    ...prevState.lastOptionsWithinDebouncedSearch,
                                    lastSelectedOption,
                                ],
                            };
                        },
                        () => this.debouceSearch({ options, option, event, postFilterType, postFilterFieldName })
                    );
                }
            }
        } else {
            console.log('missing info for postFilterType %s or postFilterInfo %s', postFilterType, postFilterFieldName);
        }
    };

    onChangePostFiltersSort = (postFilterType: string, sort: string): void => {
        let { articleType } = this.props;
        let options = this.props.postFiltersConfig[articleType][postFilterType].values;

        this.props.onSortPostFilters(articleType, postFilterType, sort);
        this.props.updatePostFilterConfig(articleType, postFilterType, options);
    };

    onFuzzyThresholdChange = (option: Option): void => {
        let { articleType } = this.props;
        let value = this.props.user.preferences.personCheck.fuzzyThreshold;

        if (option && option.count === false) {
            value = '';
        }

        this.props.updatePostFilterForCategory(articleType, POST_FILTER_FUZZY_THRESHOLD, value);
    };

    resetFilter = async (postFilterType: string, postFilterFieldName: string): Promise<any> => {
        let { articleType, postFiltersConfig, context } = this.props;
        let postFilterConfig = cloneDeep(postFiltersConfig[articleType]);
        let values = postFilterConfig[postFilterType].values;
        let investigationData = cloneDeep(values);

        // for geography, select all
        if (postFilterType === POSTFILTER_TYPE.GEOGRAPHY) {
            values = values.map((value) => ({ ...value, checked: true }));
        } else if (postFilterType === POSTFILTER_TYPE.DUNS_FILTER) {
            values = [''];
        }
        let dynamic = postFilterConfig[postFilterType].values.dynamic;
        await this.props.resetPostFilterConfigValues(articleType, postFilterType, values);

        if (context && context.editingAlert) {
            this.props.onPostfilterChange(articleType, postFilterType);
        }

        if (postFilterType && postFilterFieldName) {
            if (postFilterType === POSTFILTER_TYPE.DATE_RANGE) {
                postFilterFieldName = FILTER_INFO.DATE_RANGE;
            }

            //if all values are selected, do not send anything
            switch (postFilterType) {
                case POSTFILTER_TYPE.LANGUAGE:
                case POSTFILTER_TYPE.SOURCE:
                case POSTFILTER_TYPE.SOURCE_TYPE:
                case POSTFILTER_TYPE.SOURCE_NAME:
                case POSTFILTER_TYPE.COMPANY:
                case POSTFILTER_TYPE.GEOGRAPHY:
                    values = [];
                    break;
                case POSTFILTER_TYPE.DUNS_FILTER:
                    values = [''];
                    break;
                default:
                    break;
            }

            // Reset post-filter sort option to default one
            if (Object.keys(POST_FILTERS_DEFAULT_SORT).indexOf(postFilterType) > -1) {
                this.props.onSortPostFilters(articleType, postFilterType, POST_FILTERS_DEFAULT_SORT[postFilterType]);
            }

            this.props.resetPostFilterToDefaultValues(
                articleType,
                postFilterType,
                postFilterFieldName,
                values,
                dynamic
            );

            this.props.searchCategory({
                categoryName: articleType,
                investigationEventType: investigationEvents.EVENT_TYPES.filterReset,
                investigationSubType: null,

                additionalData: {
                    postFilterType: postFilterType,
                    options: investigationData,
                },
            });
        } else {
            console.log('missing info for postFilterType %s or postFilterInfo %s', postFilterType, postFilterFieldName);
        }
    };

    deboucedSearch(data) {
        let { articleType } = this.props;
        const isAtLeastOneOptionChecked: boolean = !!this.state.lastOptionsWithinDebouncedSearch.find(
            (option) => option.checked
        );
        const investigationEventType: string = isAtLeastOneOptionChecked
            ? investigationEvents.EVENT_TYPES.filterApplied
            : investigationEvents.EVENT_TYPES.filterRemoved;

        this.props.searchCategory({
            categoryName: articleType,
            investigationEventType: investigationEventType,
            investigationSubType: null,
            additionalData: data,
        });

        this.setState({ lastOptionsWithinDebouncedSearch: [], activePostFilters: [] });
    }

    updateHistory = (articleType: string, filteredFields: Object): ?Promise<any> => {
        if (this.props.context && !this.props.context.isUserAnonymized) {
            return SearchUtils.updateHistory(articleType, filteredFields);
        }
    };

    togglePostfilterCategoryVisibility = (key: string, value: boolean) => {
        if (this.state.filtersCollapsingState[key] !== value) {
            let updatedFiltersCollapsingState = { ...this.state.filtersCollapsingState };

            for (let filterKey in updatedFiltersCollapsingState) {
                updatedFiltersCollapsingState[filterKey] = (key === filterKey) ? value : false;
            }

            this.setState({ filtersCollapsingState: updatedFiltersCollapsingState });
        }

        this.props.updateExpandedFiltersSection(key);
    }

    updateExpandedFilterSectionPreference = (preferenceValue: string) => {
        userPreferenceApi
            .updateUserPreference({
                userPreferences: {
                    resultListPage: {
                        expandedFiltersSection: preferenceValue,
                    }
                },
            })
            .catch(() => {
                utils.showNotificationsMessage({
                    messageText: 'General_CoreFunctionality_Error_general.saveError',
                    messageType: 'system-error',
                });
            });
    }

    handleExpandedFilterSectionVisibility = () => {
        // ContentTypes is available for all categories
        // SearchWithin is available for all categories except UBO and ESG Ratings
        // The other filters are different for each category
        // ESG Ratings has only the ContentTypes available

        // if ESG Ratings is selected, checking for the postFiltersConfig is redundant
        if (this.props.articleType !== CATEGORY_NAMES.ESG_RATINGS) {
            // checking if the filter section from preferences exists for the selected content type
            const postFilterConfigForContentType = this.props.postFiltersConfig[this.props.articleType];
            if (postFilterConfigForContentType[this.props.expandedFiltersSection]) {
                this.togglePostfilterCategoryVisibility(this.props.expandedFiltersSection, true);
                return;
            }
        }

        // checking if the filter section from preferences is SearchWithin
        if (this.props.expandedFiltersSection === FILTER_TYPES_CONSTANTS.SEARCH_WITHIN && !categoryUtils.isDnbCategory(this.props.articleType) &&
            this.props.articleType !== CATEGORY_NAMES.ESG_RATINGS) {
            this.togglePostfilterCategoryVisibility(FILTER_TYPES_CONSTANTS.SEARCH_WITHIN, true);
            return;
        }
        // defaulting to selecting the ContentType if the other conditions are not met
        this.togglePostfilterCategoryVisibility(FILTER_TYPES_CONSTANTS.CONTENT_TYPES, true);
    }

    render(): React.Node {
        const postFilterConfig = this.props.postFiltersConfig[this.props.articleType];
        const defaultDate = this.getDateDefaultValues();
        const areCategoryAndSearchFiltersDisabled = this.state.activePostFilters.length !== 0;

        return (
            <React.Fragment>
                <div
                    className={`filters-toggle-wrapper ${this.props.isFilterSectionCollapsed ? 'collapsed' : ''}`}
                    onClick={() => this.props.toggleFilterSectionVisibility(!this.props.isFilterSectionCollapsed)}
                    ref={this.props.toggleFilterVisibilityButtonRef}
                >
                    <div className="filters-toggle" data-track="active-filters">
                        <div className="filters-toggle-text">
                            <span className="la-FilterToggle" />
                            <span>
                                <FormattedMessage id="ActiveFilters.Filters" />
                            </span>
                        </div>
                    </div>
                </div>
                <div
                    className={`type-filter-wrapper notranslate ${this.props.isFilterSectionCollapsed ? 'collapsed' : ''
                        }`}
                    ref={this.props.postFilterContainerRef}
                >
                    <FilterPod
                        key={'Filter' + FILTER_TYPES_CONSTANTS.CONTENT_TYPES}
                        label='Filters.Categories'
                        name={FILTER_TYPES_CONSTANTS.CONTENT_TYPES}
                        isOpen={this.state.filtersCollapsingState[FILTER_TYPES_CONSTANTS.CONTENT_TYPES]}
                        className='type-filter-category-container'
                        isDisabled={areCategoryAndSearchFiltersDisabled}
                        togglePostfilterVisibility={this.togglePostfilterCategoryVisibility}
                    >
                        <FilterList
                            categoryDropDown={this.state.categoryDropDown}
                            searchType={this.props.searchType}
                            handleVisibility={this.handleVisibility}
                            searchResults={this.props.searchResults}
                            articleType={this.props.articleType}
                            handleSelectCategory={this.props.handleSelectCategory}
                            language={this.props.language}
                        />
                    </FilterPod>
                    {!categoryUtils.isDnbCategory(this.props.articleType) &&
                        this.props.articleType !== CATEGORY_NAMES.ESG_RATINGS ? (
                        <FilterPod
                            key={'Filter' + FILTER_TYPES_CONSTANTS.SEARCH_WITHIN}
                            label='Filters.SearchWithin'
                            name={FILTER_TYPES_CONSTANTS.SEARCH_WITHIN}
                            isOpen={this.state.filtersCollapsingState[FILTER_TYPES_CONSTANTS.SEARCH_WITHIN]}
                            isDisabled={areCategoryAndSearchFiltersDisabled}
                            className='type-filter-category-container'
                            togglePostfilterVisibility={this.togglePostfilterCategoryVisibility}
                        >
                            <SearchWithinResults
                                searchWithinDropDown={this.state.searchWithinDropDown}
                                inputSearchWithin={this.state.inputSearchWithin}
                                handleVisibility={this.handleVisibility}
                                handleIncludeTermsChange={this.handleIncludeTermsChange}
                                searchIncludeTerms={this.searchIncludeTerms}
                                handleKeyPress={this.handleKeyPress}
                                errorMessage={this.state.errorMessage}
                                changeIncludeExclude={this.changeIncludeExclude}
                                searchWhitinValue={this.state.searchWhitinValue}
                            />
                        </FilterPod>
                    ) : null}
                    {this.props.showFilters && (
                        <PostFiltersContainer
                            category={this.props.articleType}
                            searchType={this.props.searchType}
                            postFilterConfig={postFilterConfig}
                            resetFilter={this.resetFilter}
                            onChange={this.onChangePostFilters}
                            onChangeSort={this.onChangePostFiltersSort}
                            onFuzzyThresholdChange={this.onFuzzyThresholdChange}
                            defaultDate={defaultDate}
                            language={this.props.language}
                            contentLanguage={this.props.contentLanguage}
                            updatePostFilterConfig={this.props.updatePostFilterConfig}
                            historyCategory={this.props.historyCategory}
                            updatePostFilterTreeData={this.props.updatePostFilterTreeData}
                            shouldUpdateCustomDatesState={this.props.shouldUpdateCustomDatesState}
                            updateCustomDatesBasedOnNNV={this.props.updateCustomDatesBasedOnNNV}
                            user={this.props.user}
                            activePostFilters={this.state.activePostFilters}
                            togglePostfilterCategoryVisibility={this.togglePostfilterCategoryVisibility}
                            filtersCollapsingState={this.state.filtersCollapsingState}
                        />
                    )}
                </div>
            </React.Fragment>
        );
    }
}

export default (withRouter(withAppContext(SearchTypeFilter)): React.AbstractComponent <Props, State>);
