import React from 'react';
import PropTypes from 'prop-types';
import { ERRORS } from '@constants';

//TODO remove the class --> functional component
export default class TableHandlersProvider extends React.Component {
    static propTypes = {
        provideHandlers: PropTypes.func.isRequired, // render prop to provide handlers further down
        onApiGet: PropTypes.func.isRequired, // a promise that returns a number
        onApiCount: PropTypes.func.isRequired, // a promise that returns an array of objects
        onApiGetAdditionalData: PropTypes.func,
        state: PropTypes.object.isRequired,
        onUpdateState: PropTypes.func.isRequired,
        initHandlers: PropTypes.object,
    };

    static defaultProps = {
        onGetSelectedName: (row) => row.object.name,
        initHandlers: {},
    };

    constructor(props) {
        super(props);

        this.handleChangeSelection = this.handleChangeSelection.bind(this);
        this.handleChangeFilters = this.handleChangeFilters.bind(this);
        this.handleSelectOption = this.handleSelectOption.bind(this);
        this.handleChangeSearchString = this.handleChangeSearchString.bind(this);
        this.handleChangePagination = this.handleChangePagination.bind(this);
        this.handleChangeSorting = this.handleChangeSorting.bind(this);
        this.handleLoadData = this.handleLoadData.bind(this);
        this.handleUpdateItems = this.handleUpdateItems.bind(this);
        this.handleResetState = this.handleResetState.bind(this);
        this.handleChangeLoadState = this.handleChangeLoadState.bind(this);
        this.handleRetrieveAdditionalData = this.handleRetrieveAdditionalData.bind(this);
        this.setIsAdditionalDataLoading = this.setIsAdditionalDataLoading.bind(this);
    }

    _setState = (state, callback = null, useForce = false) => {
        if (!this.props.state.data.isLoading || useForce) {
            this.props.onUpdateState(state, callback);
        }
    };

    _getLoadParams = () => {
        return {
            sorting: this.props.state.sorting,
            pagination: this.props.state.pagination,
            filtering: this.props.state.filtering,
            language: this.props.language,
            publicRecordsEnabled: this.props.state.publicRecordsEnabled,
        };
    };

    setIsLoading(isLoading, callback) {
        this._setState(
            {
                data: {
                    ...this.props.state.data,
                    isLoading: isLoading,
                },
            },
            callback,
            true
        );
    }

    handleLoadData() {
        this._setState({
            data: {
                rows: null,
                hasError: false,
            },
        });
        this.setIsLoading(true);

        const promises = [this.props.onApiCount(this._getLoadParams()), this.props.onApiGet(this._getLoadParams())];

        return Promise.all(promises)
            .then((responses) => {
                this.setIsLoading(false, () => {
                    const data = {
                        rows: responses[1] || [],
                        filterCount: (responses[1] && responses[1].length) || 0,
                        totalCount: responses[0] || 0,
                        hasError: false,
                    };
                    this._setState({ data });
                });
            })
            .catch((e) => {
                console.error(e);
                this.setIsLoading(false, () => {
                    this._setState({
                        data: {
                            rows: null,
                            hasError: true,
                        },
                    });
                });
                throw ERRORS.TABLE_LOAD_DATA_GET;
            });
    }

    setIsAdditionalDataLoading(isLoading, callback) {
        this._setState(
            {
                additionalData: {
                    ...this.props.state.additionalData,
                    isLoading: isLoading,
                },
            },
            callback,
            true
        );
    }

    handleRetrieveAdditionalData(id, isPublicRecordsEnabled, callBack) {
        this.setIsAdditionalDataLoading(true);

        return this.props
            .onApiGetAdditionalData(id, isPublicRecordsEnabled)
            .then((response) => {
                this.setIsAdditionalDataLoading(false, () => {
                    const additionalData = {
                        data: response,
                    };
                    this._setState({ additionalData });
                    callBack(response);
                });

                return response;
            })
            .catch((e) => {
                console.error(e);
                this.setIsAdditionalDataLoading(false, () => {
                    const additionalData = {
                        data: null,
                    };
                    this._setState({ additionalData });
                });
                throw ERRORS.TABLE_LOAD_DATA_GET;
            });
    }

    /**
     * Updates in rows and selected items using the "identity" field
     * @param items
     * @param identity
     */
    handleUpdateItems(items, identity) {
        this._setState({
            data: {
                ...this.props.state.data,
                rows: this.props.state.data.rows.map(
                    (row) => items.find((item) => item[identity] === row[identity]) || row
                ),
            },
            selection: {
                ...this.props.state.selection,
                selectedItems: this.props.state.selection.selectedItems.map((selectedItem) => ({
                    ...selectedItem,
                    object:
                        items.find((item) => item[identity] === selectedItem.object[identity]) || selectedItem.object,
                })),
            },
        });
    }

    handleChangeSelection(selectedItems, callback = null) {
        this._setState(
            {
                selection: {
                    ...this.props.state.selection,
                    selectedItems,
                },
            },
            callback
        );
    }

    handleChangeFilters(filters, callback = null) {
        this._setState(
            {
                filtering: {
                    ...this.props.state.filtering,
                    filters,
                },
            },
            callback
        );
    }

    handleChangeSearchString(searchString, callback = null) {
        this._setState(
            {
                filtering: {
                    ...this.props.state.filtering,
                    searchString,
                },
            },
            callback,
            true
        );
    }

    handleSelectOption(filteringDropdownSelected, callback = null) {
        this._setState(
            {
                filtering: {
                    ...this.props.state.filtering,
                    filteringDropdownSelected,
                },
            },
            callback,
            true
        );
    }

    handleChangePagination(pagination, callback = null) {
        this._setState(
            {
                pagination: {
                    ...this.props.state.pagination,
                    ...pagination,
                },
            },
            callback
        );
    }

    handleChangeSorting(sorting, callback = null) {
        this._setState(
            {
                sorting: {
                    ...this.props.state.sorting,
                    ...sorting,
                },
            },
            callback
        );
    }

    /**
     * Function to call when things go wrong, try to reset the table state, maybe it is out of sync
     */
    handleResetState() {
        this.handleChangeSelection([]);
        this.handleChangePagination({ pageNumber: 0 });
        this.handleLoadData();
    }

    handleChangeLoadState(loadState, callback = null) {
        this.setIsLoading(loadState, callback);
    }

    render() {
        const handlers = {
            data: {
                ...this.props.state.data,
                language: this.props.state.language,
                onLoadData: this.handleLoadData,
                onUpdateItems: this.handleUpdateItems,
                onResetState: this.handleResetState,
                onChangeLoadState: this.handleChangeLoadState,
            },
            sorting: {
                ...this.props.state.sorting,
                onChangeSorting: this.handleChangeSorting,
            },
            pagination: {
                ...this.props.state.pagination,
                onChangePagination: this.handleChangePagination,
            },
            selection: {
                ...this.props.state.selection,
                onChangeSelection: this.handleChangeSelection,
                onGetSelectedName: this.props.onGetSelectedName,
            },
            filtering: {
                ...this.props.state.filtering,
                onChangeSearchString: this.handleChangeSearchString,
                onChangeFilters: this.handleChangeFilters,
                onSelectOption: this.handleSelectOption,
            },
            additionalData: {
                ...this.props.state.additionalData,
                setPopupModel: this.props.state.setPopupModel,
                billingId: this.props.state.billingId,
                timezone: this.props.state.timezone,
                startEachArticleOnNewPage: this.props.state.startEachArticleOnNewPage,
                isBatchReportsEnabled: this.props.state.isBatchReportsEnabled,
                onRetrieveAdditionalData: this.handleRetrieveAdditionalData,
            },
            ...this.props.initHandlers,
        };

        return this.props.provideHandlers(handlers);
    }
}
