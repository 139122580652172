/**
 * LOADING-SPINNER VIEW TEMPLATE
 *
 * Description here.
 *
 * @version 0.1
 */

import React from 'react';
import PureRenderMixin from 'react-addons-pure-render-mixin';

class LoadingSpinner extends React.Component {
    constructor(props) {
        super(props);
        this.shouldComponentUpdate = PureRenderMixin.shouldComponentUpdate.bind(this);
    }

    render() {
        let classes = 'loading-spinner';

        classes += this.props.isVisible ? ' shown' : '';
        classes += this.props.isInner ? ' inner' : '';

        /*
         * This quick fix is a "middle ground" for both bugs RMM-368 ({@link https://jira.moreover.com/browse/RMM-368})
         * and RMM-333 ({@link https://jira.moreover.com/browse/RMM-333}). In order to prevent both scrolling and page
         * shifting when the loading spinner covers the page, and, respectively, after it disappears from the page, we
         * need to add a fake scrollbar on the HTML element that can't be scrolled. Thus, the page can not be scrolled
         * and it also won't shift to the right after the loading spinner disappears.
         */
        if (this.props.fixedSpinner) {
            if (this.props.isVisible) {
                document.body.setAttribute('class', 'disable-body-scroll');
                document.documentElement.setAttribute('class', 'fake-scrollbar');
            } else {
                document.body.removeAttribute('class');
                document.documentElement.removeAttribute('class');
            }
        }

        return (
            <div className={classes}>
                <div className="loading-spinner__animation"></div>
            </div>
        );
    }
}

export default LoadingSpinner;
