import { React, Component, Fragment } from 'react';
import NestedChecksGroup from '@reusable/Form/NestedChecksGroup';
import { withSelectAllNested } from '@reusable/Form/hoc/withSelectAll';
import preventAllUnchecks from '@reusable/Form/hoc/preventAllUnchecks';
import { compose } from 'redux';
import { OPTION_CHECK } from '@constants';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import utils from '@utils/utilities';

const SelectAllNestedChecks = compose(withSelectAllNested, preventAllUnchecks)(NestedChecksGroup);

class NestedCheckboxFilter extends Component {
    render() {
        const formatOption = (option) => ({
            ...option,
            checked: option.checked === false ? containsSelection(option.children) : option.checked,
            label: (
                <Fragment>
                    <div className={'label-text'}>
                        {option.name === 'missingUnclassified' ? (
                            <FormattedMessage id='"General.postFilters.unclassified"' />
                        ) : (
                            option.name
                        )}
                    </div>
                    <span className={'filter-count'}>
                        {option.checked !== OPTION_CHECK.UNCHECKED ? (
                            utils.formatNumbersByUserLocale(this.props.language, option.count)
                        ) : (
                            <span className="la-ZoomOut" />
                        )}
                    </span>
                </Fragment>
            ),
        });

        const options = formatDeep(this.props.options, formatOption);

        return <SelectAllNestedChecks {...this.props} options={options} />;
    }
}

const containsSelection = (options) => {
    if (!options) return false;
    return options.filter((option) => option.checked || option.checked === null).length > 0 && null;
};

const formatDeep = (options, formatFunc) =>
    options.map((option) => ({
        ...formatFunc(option),
        children: option.children ? formatDeep(option.children, formatFunc) : null,
    }));

const mapStateToProps = (state) => ({
    language: state.user.preferences.language,
});

export default connect(mapStateToProps)(NestedCheckboxFilter);
