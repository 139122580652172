import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Loader } from 'semantic-ui-react';
import Button from './Button';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { MATOMO, ROUTES } from '@constants';
import callMatomoEvent from '@utils/matomoUtils';

const MODE_LOADING = 'btn-loading';
const MODE_ADDED_MSG = 'btn-added-msg';
const MODE_ADDED = 'btn-added';
const MODE_NOT_ADDED = 'btn-not-added';
const ADDED_MSG_TIME = 2000;

class AddToReportBtn extends Component {
    static propTypes = {
        isAdded: PropTypes.bool,
        onToggle: PropTypes.func.isRequired,
        article: PropTypes.object.isRequired,
        isLoading: PropTypes.bool,
        readonly: PropTypes.bool,
    };

    static defaultProps = {
        isAdded: false,
        isLoading: false,
        readonly: false,
    };

    constructor(props) {
        super(props);

        this.state = {
            isAddMessageVisible: false,
        };
    }

    handleClick = () => {
        const {
            readonly,
            onToggle,
            isAdded,
            article,
            articleType,
            location: { pathname },
            articleNavigation: { index },
        } = this.props;

        if (readonly) return;

        const buttonMode = this.getMode();

        if (!isAdded) {
            const { id, pcsi } = article;
            const documentInfo = {
                documentRank: index + 1,
                pcsi,
                lni: id,
            };
            let component = MATOMO.component.searchResultsList;
            let pageName = MATOMO.pageName.resultsList;
            if (pathname === ROUTES.DOCUMENT) {
                component = MATOMO.component.document;
                pageName = MATOMO.pageName.document;
            }

            callMatomoEvent({
                pageName,
                component,
                subComponent: MATOMO.subComponent.delivery,
                event: MATOMO.event.addToReport,
                value: MATOMO.value.addToReport,
                action: MATOMO.action.click,
                contextData: {
                    documentInfo,
                    contentType: articleType,
                },
            });
        }

        if (buttonMode !== MODE_LOADING && buttonMode !== MODE_ADDED_MSG) {
            onToggle(article, !isAdded).then(() => {
                if (!isAdded) {
                    this.startAddedTimer();
                }
            });
        }
    };

    startAddedTimer() {
        this.setState({ isAddMessageVisible: true });
        setTimeout(() => {
            this.setState({ isAddMessageVisible: false });
        }, ADDED_MSG_TIME);
    }

    getMode() {
        if (this.props.isLoading) {
            return MODE_LOADING;
        } else if (this.state.isAddMessageVisible) {
            return MODE_ADDED_MSG;
        } else {
            return this.props.isAdded ? MODE_ADDED : MODE_NOT_ADDED;
        }
    }

    render() {
        let buttonContent;
        const buttonMode = this.getMode();

        switch (buttonMode) {
            case MODE_ADDED:
                buttonContent = (
                    <div className="add-to-report-content" data-testid="delete-from-report">
                        <FormattedMessage id="AddToReportBtn.deleteFromReport" />
                    </div>
                );
                break;
            case MODE_ADDED_MSG:
                buttonContent = (
                    <div className="add-to-report-content">
                        <div className="la-DeliveryComplete icon" />
                        <div className="add-to-report-name" data-testid="added-report">
                            <FormattedMessage id="AddToReportBtn.added" />
                        </div>
                    </div>
                );
                break;
            case MODE_NOT_ADDED:
                buttonContent = (
                    <div className="add-to-report-content">
                        <div className="la-ZoomIn icon" />
                        <div className="add-to-report-name" data-testid="add-to-report">
                            <FormattedMessage id="AddToReportBtn.addToReport" />
                        </div>
                    </div>
                );
                break;
            case MODE_LOADING:
                buttonContent = (
                    <div className="add-to-report-content">
                        <Loader size={'medium'} inline="centered" active inverted />
                    </div>
                );
        }

        const buttonClass = 'add-to-report-btn notranslate ' + buttonMode + (this.props.readonly ? ' readonly' : '');

        return (
            <Button
                className={buttonClass}
                disabled={this.props.readonly}
                onClick={this.handleClick}
                data-track={this.props.dataTrack}
            >
                {buttonContent}
            </Button>
        );
    }
}

const mapStateToProps = (state) => ({
    articleNavigation: state.articleNavigation,
});

export default withRouter(connect(mapStateToProps, null)(AddToReportBtn));
