export const printStuff = (stuff) => {
    const frame = document.getElementById('print-portal');
    const doc = frame.contentDocument || frame.contentWindow.document;

    const goPrint = (domDoc, domFrame) => {
        const userAgent = window.navigator.userAgent;
        const isInternetExplorer = userAgent.indexOf('MSIE') > 0 || userAgent.indexOf('Trident') > 0;

        if (isInternetExplorer) {
            domFrame.onafterprint = () => {
                removeContent(doc);
            };
            domDoc.execCommand('print', false, null);
        } else {
            window.frames['print-portal'].onafterprint = () => {
                removeContent(doc);
            };
            window.frames['print-portal'].focus();
            window.frames['print-portal'].print();
        }
    };

    doc.open();
    doc.write(stuff);
    doc.close();
    _waitForAllImagesToLoadThen(doc, frame, goPrint);
};

const removeContent = (doc) => {
    doc.removeChild(doc.documentElement);
};

function _waitForAllImagesToLoadThen(domDoc, domFrame, doWhat) {
    const imagesToWaitFor = domDoc.images;
    let stillWaitingForCount = imagesToWaitFor.length;
    const onImageResult = () => {
        // Note (if ever needed):
        //    ev.type === 'error' if image download failed for some reason
        //    ev.type === 'load' if image download succeded
        stillWaitingForCount--;
        if (stillWaitingForCount === 0) {
            doWhat(domDoc, domFrame);
        }
    };

    if (stillWaitingForCount === 0) {
        // No images are present in this document
        doWhat(domDoc, domFrame);
    } else {
        for (let i = 0; i < domDoc.images.length; i++) {
            const img = domDoc.images[i];

            // Regardless of the load result, we have to make sure that we show the user the print dialog
            img.onload = onImageResult;
            img.onerror = onImageResult;
        }
    }
}
