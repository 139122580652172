import React from 'react';
import CheckboxFilter from '../CheckboxFilter';

const NegativityLevelsPostFilter = (props) => {
    return (
        <div className={'negativityLevels-postfilter'}>
            <CheckboxFilter {...props} fieldId={'negativityLevels-postfilter'} />
        </div>
    );
};

export default NegativityLevelsPostFilter;
