// @flow
import * as React from 'react';
import ReportHeader from './sharedComponents/Header/ReportHeader';
import { withHideForCombinedReports } from '@ReportBuilder/HOC/withReportHoc';
import Content from './regularReportComponents/Content';
import type { CategoryDeleteRequest, RegularReportType } from '@ReportBuilder/redux/flow/ReportBuilder.type.guards';
import type { ContentOptions } from '@reusable/PopupModal/Components/ModalContentOptions';
import { injectIntl } from 'react-intl';
import utils from '@utils/utilities';
import formatRichMessage from '@utils/formatRichMessage';

type Props = {
    index: number,
    row?: RegularReportType,
    report: RegularReportType,
    language: string,
    contentsOptions: Array<ContentOptions>,
    prReportDeliveryAvailabilityDays?: number,
    reportDeliveryAvailabilityDays?: number,
    emailAddress: string,
    modifiedSections?: Array<string>,
    removeAutosaveMessage?: (hasError: boolean, autosavedSectionId: string) => void,
    showAutosaveMessage?: (categoryId: string) => void,
    autosaveHasError?: (hasError: boolean) => void,
    jumpToReport?: (reportId: string) => void,
    addNoteToReport?: (reportId: string, note: string) => void,
    onDelete?: (reportId: string, reportTitle: string) => void,
    onCategoryDelete: (categoryDeleteRequest: CategoryDeleteRequest) => void,
    downloadReport: (event: any) => void,
    showDownloadReportPopup: (popupType: string) => void,
    onToggleSelect?: (item: string, value: boolean) => void,
    onToggleReport: (reportId: string) => Promise<Object>,
    updateReportProperty: ({
        reportId: string,
        reportProperty: string,
        propertyValue: mixed,
        isSelected?: boolean,
    }) => void,
    updateProperty?: () => void,
    +onUpdateReportBuilderPage: (reportId: string) => void,
    +intl: Object,
};

type State = {
    showCategories: boolean,
};

class RegularReport extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            showCategories: false,
        };

        (this: any).onToggleReport = this.onToggleReport.bind(this);
        (this: any).openOrderCategoriesSection = this.openOrderCategoriesSection.bind(this);
        (this: any).closeOrderCategoriesSection = this.closeOrderCategoriesSection.bind(this);
    }

    async onToggleReport(reportId: string): Promise<Object> {
        const response = await this.props.onToggleReport(reportId);

        if (!response) {
            this.setState({
                showCategories: false,
            });
        }
    }

    async openOrderCategoriesSection(event: SyntheticInputEvent<HTMLButtonElement>): Promise<Object> | void {
        event.stopPropagation();

        if (!this.state.showCategories) {
            if (!this.props.report.isSelected) {
                const reportId = event.target.value;
                await this.onToggleReport(reportId);
            }

            this.setState({
                showCategories: true,
            });
        }
    }

    closeOrderCategoriesSection() {
        this.setState({
            showCategories: false,
        });
    }

    render(): React.Node {
        let { report, language, index } = this.props;
        const id: string = `${report.snippetsCount === 1 ? 'General.document' : 'General.documents'}`;
        const documentCounts: string = `${
            report.snippetsCount
                ? utils.formatNumbersByUserLocale(language, report.snippetsCount)
                : report.snippetsCount
        } ${formatRichMessage({ id: id }, this.props.intl)}`;

        return (
            <div className="report-row-wrapper">
                <ReportHeader
                    report={report}
                    index={index}
                    updateReportProperty={this.props.updateReportProperty}
                    handleToggleReport={this.onToggleReport}
                    reportDeliveryAvailabilityDays={this.props.reportDeliveryAvailabilityDays}
                    prReportDeliveryAvailabilityDays={this.props.prReportDeliveryAvailabilityDays}
                    showDownloadReportPopup={this.props.showDownloadReportPopup}
                    downloadReport={this.props.downloadReport}
                    handleDeleteReport={this.props.onDelete}
                    openOrderCategoriesSection={this.openOrderCategoriesSection}
                    row={this.props.row}
                    onToggleSelect={this.props.onToggleSelect}
                    downloadLinkMessage={{
                        downloadNow: 'Report.reportReadyForDownload',
                        downloadAgain: 'Report.reportReadyForDownloadAgain',
                    }}
                    countMessage={documentCounts}
                />
                <Content
                    index={index}
                    isReportExpanded={this.props.report.isSelected}
                    isLoading={this.props.report.loadingArticles}
                    report={this.props.report}
                    showDownloadReportPopup={this.props.showDownloadReportPopup}
                    downloadReport={this.props.downloadReport}
                    handleDeleteReport={this.props.onDelete}
                    handleCategoryDeleteReport={this.props.onCategoryDelete}
                    addNoteToReport={this.props.addNoteToReport}
                    updateReportProperty={this.props.updateReportProperty}
                    updateProperty={this.props.updateProperty}
                    emailAddress={this.props.emailAddress}
                    showCategories={this.state.showCategories}
                    closeOrderCategoriesSection={this.closeOrderCategoriesSection}
                    contentsOptions={this.props.contentsOptions}
                    loader={{
                        loaderClassName: 'report-loader',
                        wrapperClassName: 'regular-report',
                    }}
                    onUpdateReportBuilderPage={this.props.onUpdateReportBuilderPage}
                    viewParentReport={this.props.jumpToReport}
                    language={this.props.language}
                    showAutosaveMessage={this.props.showAutosaveMessage}
                    removeAutosaveMessage={this.props.removeAutosaveMessage}
                    modifiedSections={this.props.modifiedSections}
                    autosaveHasError={this.props.autosaveHasError}
                />
            </div>
        );
    }
}

export default (injectIntl(withHideForCombinedReports(RegularReport)): React.AbstractComponent<Props, State>);

export { RegularReport as TestReport };
