import React from 'react';

const SuperscriptTag = ({ name, text, style }) => {
    return (
        <div className={name} style={style}>
            {text}
        </div>
    );
};

export default SuperscriptTag;
