import React from 'react';
import { injectIntl } from 'react-intl';
import Spinnable from '@reusable/Wrappers/SpinnerWrapper/SpinnerWrapper.jsx';
import GoogleTranslateActions from './redux/GoogleTranslate.actions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import formatRichMessage from '@utils/formatRichMessage';

const googleTranslateLoaderClassName = 'VIpgJd-ZVi9od-aZ2wEe-wOHMyf';

class GoogleTranslateWithoutI18n extends React.Component {
    constructor(props) {
        super(props);

        this.previousLanguage = '';
    }

    /**
     * Add the Google Translate plugin "dynamically" by creating a script element, appending
     * it to the body and removing previously remaining Google Translate DOM elements.
     */
    UNSAFE_componentWillMount() {
        const scriptElement = document.querySelector('script[src*="//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"]');
        if(!scriptElement) {
            this.props.spinner.showSpinner();
            this.isDropdownLoaded = false;
    
            window.googleTranslateElementInit = this.googleTranslateElementInit.bind(this);
    
            let script = document.createElement('script');
            script.src = '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
            document.body.appendChild(script);
        }
    }

    /**
     * When unmounting the component, clear the DOM elements generated by Google Translate and also clear the
     * translations by simulating a click on the close button of the Google Translate menu.
     */
    componentWillUnmount() {
        this.props.setGoogleTranslateStatus(false);

        // Remove click event handler from the languages menu
        let languagesMenuIframe = document.getElementsByClassName('goog-te-combo')[0];
        languagesMenuIframe && languagesMenuIframe.removeEventListener('click', this.handleLanguageChange);

        // Remove click event handlers from the Google Toolbar buttons
        if (this.googleCloseButton) {
            this.googleCloseButton.removeEventListener('click', this.clearSelectedLanguage);
        }
        if (this.showOriginalButton) {
            this.showOriginalButton.removeEventListener('click', this.showOriginalEvent);
        }
        if (this.googleTranslateButton) {
            this.googleTranslateButton.removeEventListener('click', this.resetLanguageToPrevious);
        }

        // Trigger a click on the Google translate close button
        let googleMenuIframe = document.getElementById(':1.container');
        if (googleMenuIframe) {
            let iframeDocument = googleMenuIframe.contentWindow.document,
                closeButton = iframeDocument.getElementById(':1.close');

            closeButton.click();
            closeButton.removeEventListener('click', this.clearSelectedLanguage);
        }

        // In case the intervals didn't get cleared before fast-navigating away from the page
        clearInterval(this.iframeAvailabilityChecker);
        if (this.closeButtonAvailabilityChecker) {
            clearInterval(this.closeButtonAvailabilityChecker);
        }

        let scriptTag = document.querySelector(
            'script[src="//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"]'
        ),
            iframes = document.querySelectorAll('body > .skiptranslate'),
            spinner = document.querySelector('div.goog-te-spinner-pos'),
            googleTranslateLoaders = document.getElementsByClassName(googleTranslateLoaderClassName);

        if (scriptTag) {
            document.body.removeChild(scriptTag);
        }
        if (iframes.length > 0) {
            for (let i = 0; i < iframes.length; i++) {
                document.body.removeChild(iframes[i]);
            }
        }
        if (googleTranslateLoaders.length > 0) {
            for (let i = 0; i < googleTranslateLoaders.length; i++) {
                document.body.removeChild(googleTranslateLoaders[i]);
            }
        }
        if (spinner) {
            document.body.removeChild(spinner);
        }
    }

    /**
     * When the component gets mounted, the languages menu and the options bar, which are iframes, still aren't loaded.
     * Thus, there is a necessity for a function to run at a certain interval and check when the iframes become
     * available. The interval is cleared once the languages menu is loaded. It also checks when the elements inside the iframes are available so you can add click events on them.
     */
    componentDidMount() {
        this.iframeAvailabilityChecker = setInterval(() => {
            let languagesMenuIframe = document.getElementsByClassName('goog-te-combo')[0];
            // DO NOT REMOVE: This is useful for QA purposes.
            // console.log('Trying to find the Google Translate iframes...');
            if (this.checkGoogleToolbar()) {
                this.setGoogleStatus();
            }
            if (languagesMenuIframe && languagesMenuIframe.options.length) {
                languagesMenuIframe.addEventListener('change', this.handleLanguageChange);
                clearInterval(this.iframeAvailabilityChecker);

                if (this.props.metadataLanguage !== '') {
                    this.applySavedLanguage();
                    this.closeButtonAvailabilityChecker = setInterval(this.addGoogleToolbarEventHandlers, 250);
                } else {
                    this.props.spinner.hideSpinner();
                }
            }
        }, 250);
    }

    checkGoogleToolbar = () => {
        if (
            document.getElementById(':1.container') &&
            document.getElementById(':1.container').style.visibility === 'visible' &&
            this.props.metadataLanguage.length
        ) {
            return true;
        }
        return false;
    }

    setGoogleStatus = () => {
        this.props.setGoogleTranslateStatus(true);
    }

    /**
     * This method runs at a certain interval to check if the Google Translate close and "Show original"
     * buttons are available so that click event handlers can be attached to them.
     */
    addGoogleToolbarEventHandlers = () => {
        let googleMenuIframe = document.getElementById(':1.container');
        let googleTranslateLoaders = document.getElementsByClassName(googleTranslateLoaderClassName);

        // DO NOT REMOVE: This is useful for QA purposes.
        // console.log('Trying to find the Google Translate Toolbar (the one at the top of the screen)...');

        if (googleMenuIframe) {
            let googleMenuIframeDocument = googleMenuIframe.contentWindow.document;
            let languagesMenuIframe = document.getElementsByClassName('VIpgJd-ZVi9od-xl07Ob-OEVmcd');
            this.googleCloseButton = googleMenuIframeDocument.getElementById(':1.close');
            this.showOriginalButton = googleMenuIframeDocument.querySelector('button[id=":1.restore"]');
            this.googleTranslateButton = googleMenuIframeDocument.querySelector('button[id=":1.confirm"]');
            if (
                this.googleCloseButton &&
                this.showOriginalButton &&
                this.googleTranslateButton &&
                languagesMenuIframe.length === 2
            ) {
                if (googleTranslateLoaders.length) {
                    for (let loader of googleTranslateLoaders)
                        loader.style.marginTop = '35px';
                }

                this.googleCloseButton.addEventListener('click', this.clearSelectedLanguage);
                this.showOriginalButton.addEventListener('click', this.showOriginalEvent);
                this.googleTranslateButton.addEventListener('click', this.resetLanguageToPrevious);

                clearInterval(this.closeButtonAvailabilityChecker);
                this.props.spinner.hideSpinner();
            }
        }
    }

    /**
     * This callback is invoked after the google translate script is loaded.
     */
    googleTranslateElementInit = () => {
        if (!window.google) {
            return;
        }

        if (!this.isDropdownLoaded) {
            new window.google.translate.TranslateElement(
                {
                    layout: window.google.translate.TranslateElement.InlineLayout.HORIZONTAL,
                },
                'google-translate-element'
            );
            this.isDropdownLoaded = true;
            this.props.spinner.hideSpinner();
        }
    }

    /**
     * Detect which language button was clicked from the iframe and get the language code from it in order to save it
     * in the Redux store.
     *
     * @param   {Event} event   Click event triggered when clicking in the language menu iframe.
     */
    handleLanguageChange = (event) => {
        let selectedItem = event.target;

        if (this.props.metadataLanguage === '') {
            this.closeButtonAvailabilityChecker = setInterval(this.addGoogleToolbarEventHandlers, 250);
        }

        // When selecting a language add the value to Redux and set the Google Translate status to true (which means the top bar is displayed)
        if (selectedItem.value) {
            this.props.setUserLanguage(selectedItem.value);
            this.props.setGoogleTranslateStatus(true);
        } else {
            this.props.setGoogleTranslateStatus(false);
        }
    }

    /**
     * Event handler for manual close button click.
     */
    clearSelectedLanguage = () => {
        this.props.setUserLanguage('');
        this.props.setGoogleTranslateStatus(false);
    }

    resetLanguageToPrevious = () => {
        this.props.setUserLanguage(this.previousLanguage);
        if (this.props.metadataLanguage !== '') {
            this.props.setGoogleTranslateStatus(true);
        }
    }

    showOriginalEvent = () => {
        this.props.setGoogleTranslateStatus(true);
        this.previousLanguage = this.props.metadataLanguage;
    }

    /**
     * Applies the language that was saved in the Redux store by clicking on the corresponding language button from
     * the Google Translate iframe.
     */
    applySavedLanguage = () => {
        let languageItems = document.getElementsByClassName('goog-te-combo')[0],
            savedLanguage = this.props.metadataLanguage;
        if (savedLanguage) {
            for (let languageItem of languageItems) {
                if (languageItem.value === savedLanguage) {
                    languageItems.value = savedLanguage;
                    languageItems.dispatchEvent(new Event('change'));
                    break;
                }
            }
            this.props.setGoogleTranslateStatus(true);
        } else {
            this.props.setGoogleTranslateStatus(false);
        }
    }

    render() {
        return (
            <div
                className="notranslate google-translate__element-wrapper"
                title={formatRichMessage(
                    { id: 'General_CoreFunctionality_UIText_general.googleTranslate.disclaimer' },
                    this.props.intl
                )}
            >
                <div id="google-translate-element"></div>
            </div>
        );
    }
}

const mapStateToProps = function (state) {
    return {
        metadataLanguage: state.user.preferences.metadataLanguage,
        googleTranslateStatus: state.user.preferences.googleTranslateStatus,
    };
};

const mapDispatchToProps = function (dispatch) {
    return bindActionCreators(
        {
            setUserLanguage: GoogleTranslateActions.setUserLanguage,
            setGoogleTranslateStatus: GoogleTranslateActions.setGoogleTranslateStatus,
        },
        dispatch
    );
};

export const TestGoogleTranslate = GoogleTranslateWithoutI18n;
let GoogleTranslate = injectIntl(GoogleTranslateWithoutI18n);
export default connect(mapStateToProps, mapDispatchToProps)(Spinnable(GoogleTranslate, 'google-translate'));
