import manageAlertsActions from './ManageAlerts.actions';

export const SELECT_ALERTS = 'SELECT_ALERTS';
export const CLEAR_SELECTED_ALERTS = 'CLEAR_SELECTED_ALERTS';
export const CLEAR_ALL_SELECTED_ALERTS = 'CLEAR_ALL_SELECTED_ALERTS';

let manageSelectedAlertsActions = {
    toggleAlertSelection: (alerts, alertType, isSelected) => (dispatch) => {
        dispatch(manageAlertsActions.toggleAlertSelection(alerts, alertType, isSelected));
        if (isSelected) {
            dispatch({
                type: SELECT_ALERTS,
                payload: { alerts, alertType },
            });
        } else {
            dispatch({
                type: CLEAR_SELECTED_ALERTS,
                payload: { alerts, alertType },
            });
        }
    },

    clearAllSelectedAlerts: (alertType) => {
        return {
            type: CLEAR_ALL_SELECTED_ALERTS,
            payload: { alertType },
        };
    },
};

export default manageSelectedAlertsActions;
