//@flow
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import utils from '@utils/utilities';
import { HOUR_TO_MILISECONDS, DAY_TO_MILISECONDS } from '@constants';
import type { GenericReportType } from '@ReportBuilder/redux/flow/ReportBuilder.type.guards';

type Props = {
    downloadMessage: {
        downloadAgain: string,
        downloadNow: string,
    },
    downloadReport: (event: SyntheticEvent<HTMLButtonElement>) => void,
    index: number,
    reportDeliveryAvailabilityDays: number,
    prReportDeliveryAvailabilityDays: number,
    report: GenericReportType,
};

const DownloadReportLink = (props: Props): React$Element<'div'> | null => {
    if (!props.report.latestDocument) {
        return null;
    }
    const { isDelivered, createdDate, firstDocumentExpiryDate, id: latestDocumentId } = props.report.latestDocument;
    let expiryDateRegular, expiryDateWithPR;

    // we'll keep this for one sprint, after that all deliveries should contain the new variable and we'll use only the last calculation method
    const reportDeliveryAvailability = DAY_TO_MILISECONDS * props.reportDeliveryAvailabilityDays; //don't remove in the following sprint
    const prReportDeliveryAvailability = DAY_TO_MILISECONDS * props.prReportDeliveryAvailabilityDays;
    const regularReportBuildExpiryTime: number = utils.calculateReportAvailability(
        createdDate,
        reportDeliveryAvailability,
        DAY_TO_MILISECONDS
    ); //don't remove in the following sprint
    const prReportExpiryCounter: number = utils.calculateReportAvailability(
        props.report.lastAddedPrDocumentDate,
        prReportDeliveryAvailability,
        HOUR_TO_MILISECONDS
    );

    if (firstDocumentExpiryDate) {
        const reportExpiryDate: number = utils.calculateTimeUntilReportExpires(
            firstDocumentExpiryDate,
            DAY_TO_MILISECONDS
        );
        const prReportExpiryDate: number = utils.calculateTimeUntilReportExpires(
            firstDocumentExpiryDate,
            HOUR_TO_MILISECONDS
        );
        expiryDateRegular = Math.min(regularReportBuildExpiryTime, reportExpiryDate);
        expiryDateWithPR = Math.min(props.prReportDeliveryAvailabilityDays * 24, prReportExpiryDate);
    }

    return (
        <div>
            <DownloadLink
                isDelivered={isDelivered}
                downloadReport={props.downloadReport}
                reportId={props.report.id}
                latestDocumentId={latestDocumentId}
                downloadMessage={props.downloadMessage}
                index={props.index}
            />
            {!utils.checkPrRecordsExpired(prReportDeliveryAvailability, props.report.lastAddedPrDocumentDate) ? (
                <div className="report-list-download-expiration-message">
                    <FormattedMessage
                        id="Report.fileAvailableForDownloadPublicRecords"
                        values={{ count: expiryDateWithPR ? expiryDateWithPR : prReportExpiryCounter }}
                    />
                </div>
            ) : (
                <div className="report-list-download-expiration-message">
                    <FormattedMessage
                        id="Report.fileAvailableForDownload"
                        values={{ count: expiryDateRegular ? expiryDateRegular : regularReportBuildExpiryTime }}
                    />
                </div>
            )}
        </div>
    );
};

type LinkProps = {
    isDelivered: boolean,
    downloadReport: (event: SyntheticEvent<HTMLButtonElement>) => void,
    reportId: string,
    latestDocumentId: string,
    index: number,
    downloadMessage: {
        downloadAgain: string,
        downloadNow: string,
    },
};

export const DownloadLink = (props: LinkProps): React$Element<React$FragmentType> => {
    const { isDelivered, downloadReport, reportId, latestDocumentId, index, downloadMessage } = props;
    return (
        <Fragment>
            <div className="circle-green" />
            <button
                onClick={downloadReport}
                value={reportId + '|' + latestDocumentId}
                id={'qa-report-download-ready_' + index}
                className="report-list-download-button"
            >
                {downloadMessage && (
                    <FormattedMessage id={isDelivered ? downloadMessage.downloadAgain : downloadMessage.downloadNow} />
                )}
            </button>
        </Fragment>
    );
};

export default DownloadReportLink;
