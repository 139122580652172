import xhr from '@utils/xhr';

const uboApi = {
    fetchUbo(duns, tradeUp, title, billingId, costCode) {
        return xhr
            .get({
                url: `/api/ubo/details?duns=${duns}&tradeUp=${tradeUp}&name=${title}&billingId=${billingId}&costCode=${costCode}`,
                method: 'get',
            })
            .then((res) => {
                const ubo = res.body;
                return ubo;
            });
    },

    fetchUboArticles({ searchTerm, dunsList, pageSize, pageNumber, billingSearchEvent, postFilters }) {
        return xhr
            .post({
                url: `/api/ubo/resultsList`,
                data: { searchTerm, dunsList, pageSize, pageNumber, billingSearchEvent, postFilters },
            })
            .then((res) => res.body);
    },
};

export default uboApi;
