import React from 'react';

const RegularActionButton = ({ disabled, handleClick, id, testid = '', className }) => {
    return (
        <button
            type="button"
            className="icon-box"
            id={id}
            data-testid={testid}
            disabled={disabled}
            onClick={handleClick}
        >
            <span className={className} />
        </button>
    );
};

export default RegularActionButton;
