import { cloneDeep, find } from 'lodash';
import { CATEGORY_NAMES, PUBLIC_RECORDS } from '@constants';
import reduxStore from '@reduxStore';
import userPreferencesActions from '@pages/UserPreferances/redux/UserPreferences.actions';
import { permissions, userHas } from '@permissions';
import { ADD_PR_TYPES, TOGGLE_PR } from '@pages/Main/Main.reducers';

export const PublicRecordsUtils = {
    disablePublicRecords: () => {
        reduxStore.dispatch({
            type: TOGGLE_PR,
            payload: false,
        });

        reduxStore.dispatch({
            type: ADD_PR_TYPES,
            payload: [],
        });

        removePublicRecordsContentType();
    },

    enablePublicRecords: (publicRecordTypes) => {
        reduxStore.dispatch({
            type: TOGGLE_PR,
            payload: true,
        });

        reduxStore.dispatch({
            type: ADD_PR_TYPES,
            payload: publicRecordTypes,
        });
        addPublicRecordsContentType();
    },

    doesReportContainOnlyPublicRecords: (report) => {
        return report.categoryOrder?.every((categoryOrder) => categoryOrder.sourceType === PUBLIC_RECORDS);
    },

    isCategoryPublicRecords: (categoryName) => {
        return categoryName === PUBLIC_RECORDS;
    },
};

export const removePublicRecordsContentType = () => {
    let removeContentType = (contentTypes, action) => {
        if (find(contentTypes, ['name', CATEGORY_NAMES.PUBLIC_RECORDS])) {
            let newContentTypes = contentTypes.filter(
                (contentType) => contentType.name !== CATEGORY_NAMES.PUBLIC_RECORDS
            );
            reduxStore.dispatch(action(newContentTypes));
        }
    };

    let contentTypes = reduxStore.getState().user.preferences.generalSettings.contentTypes;
    removeContentType(contentTypes, userPreferencesActions.updateContentTypes);

    if (userHas(permissions.admin.edit)) {
        contentTypes = reduxStore.getState().user.adminPreferences.generalSettings.contentTypes;
        removeContentType(contentTypes, userPreferencesActions.updateAdminContentTypes);
    }
};

export const addPublicRecordsContentType = () => {
    let addContentType = (sources, action) => {
        let contentTypes = cloneDeep(sources);

        if (!find(contentTypes, ['name', CATEGORY_NAMES.PUBLIC_RECORDS])) {
            let publicRecordContentType = {
                name: CATEGORY_NAMES.PUBLIC_RECORDS,
                value: true,
                reportOrder: contentTypes.length,
            };
            contentTypes.push(publicRecordContentType);
            reduxStore.dispatch(action(contentTypes));
        }
    };

    let contentTypes = reduxStore.getState().user.preferences.generalSettings.contentTypes;
    addContentType(contentTypes, userPreferencesActions.updateContentTypes);

    if (userHas(permissions.admin.edit)) {
        contentTypes = reduxStore.getState().user.adminPreferences.generalSettings.contentTypes;
        addContentType(contentTypes, userPreferencesActions.updateAdminContentTypes);
    }
};
