import userPreferenceApi from '@pages/UserPreferances/UserPreferencesApi.api';
import reduxStore from '@reduxStore';
import actions from '@pages/UserPreferances/redux/UserPreferences.actions';
import { hashHistory } from 'react-router';
import utils from './utilities';
import { isEmpty } from 'lodash';

const userPreferencesUtils = {
    saveSearchTypeToPreferences(searchParamsSearchType) {
        userPreferenceApi
            .updateUserPreference({
                userPreferences: {
                    generalSettings: {
                        searchType: searchParamsSearchType,
                    },
                },
            })
            .then((response) => {
                reduxStore.dispatch(actions.updateSearchType(searchParamsSearchType));

                return response;
            })
            .catch(() => {
                return userPreferenceApi
                    .getUserPreferences()
                    .then((response) => {
                        utils.showNotificationsMessage({
                            messageText: 'General_CoreFunctionality_Error_general.saveError',
                            messageType: 'system-error',
                        });
                        let selectedSearchType = response.body.userPreferences.generalSettings.searchType;
                        reduxStore.dispatch(actions.updateLanguage(selectedSearchType));

                        return response;
                    })
                    .catch(() => {
                        hashHistory.push('/error');
                    });
            });
    },

    // saves sortOption to userPreferences
    saveSortTypeToPreferences(sortType) {
        userPreferenceApi
            .updateUserPreference({
                userPreferences: {
                    generalSettings: {
                        sort: sortType,
                    },
                },
            })
            .then((response) => {
                reduxStore.dispatch(actions.updateSort(sortType));
                return response;
            })
            .catch(() => {
                utils.showNotificationsMessage({
                    messageText: 'General_CoreFunctionality_Error_general.saveError',
                    messageType: 'system-error',
                });
            });
    },

    saveRiskScores(displayRiskScores) {
        return userPreferenceApi
            .updateUserPreference({
                userPreferences: {
                    generalSettings: {
                        displayRiskScores,
                    },
                },
            })
            .then((response) => {
                return response;
            })
            .catch(() => {
                utils.showNotificationsMessage({
                    messageText: 'General_CoreFunctionality_Error_general.saveError',
                    messageType: 'system-error',
                });
            });
    },
    updateEntityViewPreferences(screeningEntity, successCbs = null) {
        userPreferenceApi
            .updateUserPreference({
                userPreferences: {
                    screeningEntity,
                },
            })
            .then((response) => {
                if (successCbs && !isEmpty(successCbs)) successCbs.forEach((cb) => cb());
                return response;
            })
            .catch(() => {
                utils.showNotificationsMessage({
                    messageText: 'General_CoreFunctionality_Error_general.saveError',
                    messageType: 'system-error',
                });
            });
    },
};

export default userPreferencesUtils;
