import React, { Fragment } from 'react';
import DuplicateArticlesList from './DuplicateArticlesList';
import ElasticDocumentContent from './ElasticDocumentContent';
import { MATOMO } from '@constants';
import callMatomoEvent from '@utils/matomoUtils';

class ElasticDocumentItem extends React.Component {
    constructor(props) {
        super(props);
    }

    checkArticle = (article, event) => {
        const articleIndex = this.props.index + 1;
        const selectedArticleInfo = {
            documentRank: articleIndex,
            pcsi: article.pcsi,
            lni: article.id,
        };
        this.props.setSelectedArticles([article], this.props.articleType, event.target.checked);
        this.props.setSelectedArticlesInfo([selectedArticleInfo], event.target.checked);
    };

    isArticleSelected = (article) => {
        let selectedArticles = this.props.selectedArticles[this.props.articleType] || [];
        let selectedArticle = selectedArticles.find((item) => {
            return item.id === article.id;
        });

        return selectedArticle !== undefined;
    };

    showSingleArticle = () => {
        const { article, articleType, index, prefilterQuery, proximity, includeTerms, terms, showSingleArticle } =
            this.props;

        callMatomoEvent({
            pageName: MATOMO.pageName.resultsList,
            component: MATOMO.component.searchResultsList,
            subComponent: MATOMO.subComponent.delivery,
            event: MATOMO.event.docOpen,
            value: article.unhighlightedTitle,
            action: MATOMO.action.click,
            contextData: {
                documentInfo: {
                    documentRank: index + 1,
                    pcsi: article.pcsi,
                    lni: article.id,
                },
                contentType: articleType,
            },
        });
        showSingleArticle({ ...article, articleType, index, prefilterQuery, proximity, includeTerms, terms });
    };

    getArticleSnipetsForReport(sanction) {
        let snippetArray = sanction.documentSnippets.map((item) => {
            return item;
        });
        return snippetArray.map((snippet, index) => {
            return <div key={index} dangerouslySetInnerHTML={{ __html: snippet }} />;
        });
    }

    render() {
        let { article, index, isArticleItemSelected, selectedArticlesList } = this.props;
        let { query, searchType, prefilterQuery } = this.props.searchParams;

        return (
            <Fragment>
                <ElasticDocumentContent
                    isArticleSelected={this.isArticleSelected}
                    showSingleArticle={this.showSingleArticle}
                    getArticleSnipetsForReport={this.getArticleSnipetsForReport}
                    checkArticle={this.checkArticle}
                    article={article}
                    index={index}
                    query={query}
                    prefilterQuery={prefilterQuery}
                    searchType={searchType}
                    articleType={this.props.articleType}
                    showIndex={true}
                    proximity={this.props.proximity}
                    includeTerms={this.props.includeTerms}
                    handlePreviewArticle={this.props.handlePreviewArticle}
                    singleSource={this.props.singleSource}
                    fuzzyThreshold={this.props.fuzzyThreshold}
                    newFuzzyEnabled={this.props.newFuzzyEnabled}
                    terms={this.props.terms}
                    isArticleItemSelected={isArticleItemSelected}
                    language={this.props.language}
                />

                <DuplicateArticlesList
                    articleType={this.props.articleType}
                    showSingleArticle={this.showSingleArticle}
                    setSelectedDuplicates={this.props.setSelectedDuplicates}
                    setSelectedArticles={this.props.setSelectedArticles}
                    selectedArticles={this.props.selectedArticles}
                    setVisibleDuplicates={this.props.setVisibleDuplicates}
                    getArticleSnipetsForReport={this.getArticleSnipetsForReport}
                    showDuplicatesState={this.props.showDuplicatesState}
                    setDuplicatesSectionState={this.props.setDuplicatesSectionState}
                    setDuplicatesForArticle={this.props.setDuplicatesForArticle}
                    duplicateArticles={this.props.duplicateArticles}
                    prefilterQuery={prefilterQuery}
                    query={query}
                    searchType={searchType}
                    heroArticle={article}
                    handlePreviewArticle={this.props.handlePreviewArticle}
                    singleSource={this.props.singleSource}
                    proximity={this.props.proximity}
                    fuzzyThreshold={this.props.fuzzyThreshold}
                    newFuzzyEnabled={this.props.newFuzzyEnabled}
                    selectedArticlesList={selectedArticlesList}
                    language={this.props.language}
                />
            </Fragment>
        );
    }
}

export default ElasticDocumentItem;
