import { cloneDeep, isEmpty } from 'lodash';
import { reduxStore } from '@reduxStore';
import ReportBuilderApi from '../ReportBuilder/ReportBuilderMain.api';
import currentReportActions from '../ReportBuilder/redux/CurrentReport.actions';
import utils, { addNewsSource } from '@utils/utilities';
import SearchUtils from './SearchUtils';
import _ from 'lodash';
import categoryUtils, { getCategoryOrder } from '@utils/categoryUtils';
import { withContentSourceFilter } from '@utils/utilities';
import DilQueue, { URL_CREATE_REPORT } from '@utils/promiseQueue';
import {
    CATEGORY_NAMES,
    FILTER_INFO,
    POST_FILTER_LABELS,
    POSTFILTER_TYPE,
    UBO_MAIN_CATEGORY,
    ARTICLE_PUBLICATION_TYPES,
    BRAZILIAN_OWNERSHIP_PCSI,
    DOC_TYPE_SEARCH_EVENT_REQUEST,
} from '@constants';
import * as investigationEvents from '@sagas/constants/investigationConstants';
import { EVENT_SUBTYPES } from '@sagas/constants/investigationConstants';

function createReport({ query, searchType, prefilterQuery, costCode, investigationId, language }) {
    if (!query) {
        return Promise.reject({ messageText: 'ReportBuilderPage_Errors.createReport', messageType: 'system-error' });
    }

    const { timezone, useNewResearchSummary, preferences } = reduxStore.getState().user;
    const reportSummary = !useNewResearchSummary ? ArticlesUtils.getReportSummary() : undefined;
    const categoryOrder = useNewResearchSummary
        ? getCategoryOrder(preferences.generalSettings.contentTypes)
        : undefined;
    const currentDate = new Date();
    const title = query + ' - ' + utils.formatDateWithTimezoneOffset(currentDate.getTime());

    const createReportParams = {
        searchQuery: query,
        searchQueryType: searchType,
        prefilterQuery,
        costCode,
        title,
        timezone,
        researchSummary: reportSummary, //sent only for old rs flow
        categoryOrder, //sent only for new rs flow
        language,
        investigationId: useNewResearchSummary ? investigationId : undefined,
    };

    if (reduxStore.getState().searchState.snapshotDelivery) {
        createReportParams.snapshotDelivery = reduxStore.getState().searchState.snapshotDelivery;
    }

    return ReportBuilderApi.createReport(createReportParams)
        .then((report) => {
            report.articlesSnippets = [];
            report.reportId = report.id;
            report.visitedContentTypes = reduxStore.getState().investigation.visitedContentTypes;
            reduxStore.dispatch(currentReportActions.setCurrentReport(report));
            reduxStore.dispatch({
                type: investigationEvents.EVENT_TYPES.reportOperation,
                payload: {
                    reportId: report.id,
                    eventSubtype: EVENT_SUBTYPES.created,
                },
            });
            return report;
        })
        .catch((error) => {
            //set report to undefined
            let resetedReport = {
                reportId: null,
                articlesSnippets: [],
            };
            reduxStore.dispatch(currentReportActions.setCurrentReport(resetedReport));
            return Promise.reject({
                error,
                messageText: 'ReportBuilderPage_Errors.createReport',
                messageType: 'system-error',
            });
        });
}

const ArticlesUtils = {
    getReport({ searchParams, costCode, investigation, language }) {
        const { query, searchType, prefilterQuery } = searchParams;
        let report = reduxStore.getState().currentReport;
        let queue = DilQueue();
        let createReportCall = queue.executionPipe().find((item) => item.data.url.indexOf(URL_CREATE_REPORT) > -1);

        if (report.reportId) {
            return Promise.resolve(report);
        } else {
            if (createReportCall && utils.isSameQuery(createReportCall.data.data, { searchType, query })) {
                return createReportCall.xhrPromise.then(
                    (response) => {
                        return response.body;
                    },
                    (error) => {
                        return Promise.reject({
                            error,
                            messageText: 'ReportBuilderPage_Errors.createReport',
                            messageType: 'system-error',
                        });
                    }
                );
            } else {
                return createReport({
                    query,
                    searchType,
                    prefilterQuery,
                    costCode,
                    investigationId: investigation.id,
                    language,
                });
            }
        }
    },

    buildElasticDocumentsList(buildParams, state) {
        let {
            articles,
            category,
            searchQueryType,
            searchQuery,
            results,
            billingId,
            forcedFilters,
            prefilterQuery,
            originalArticleType,
            singleSource,
        } = buildParams;
        let documentIds = [];
        let document;
        let searchResults = state.searchResults;
        let postFilters = {};

        if (originalArticleType) {
            postFilters = utils.formatPostFilters(searchResults[originalArticleType].postFilters);
        } else {
            postFilters = utils.formatPostFilters(searchResults[category].postFilters);
        }

        let postFilterConfiguration = _.values(state.postFilterConfiguration[category]);

        const uboCategory = categoryUtils.isDnbCategory(category) && category;
        category = uboCategory ? UBO_MAIN_CATEGORY : category;

        Object.keys(postFilters).forEach((key) => {
            let values = postFilters[key];
            const postFilterConf = postFilterConfiguration.filter((filter) => filter.searchFieldName === key);

            if ((values || postFilterConf) && forcedFilters.indexOf(postFilterConf.searchFieldName) > -1) {
                values = SearchUtils.getFilteredPostfilters(postFilterConf[0], values);
                postFilters[key] = values;
            }
        });

        let sort = state.searchResults[category].postFilters.sort;

        articles.forEach((article) => {
            const uboTitle = article.name ? article.name : article.title;
            const elasticTitle = article.title;
            let sortingFields = undefined,
                documentAccessType = undefined;

            if (uboCategory) {
                const docViewDeliveryInfo = state.ubo.docViewDeliveryInfo;
                sortingFields = docViewDeliveryInfo.sorting;
            }

            if (category === CATEGORY_NAMES.ESG_RATINGS) {
                documentAccessType = state.articleNavigation.isShowMoreActive
                    ? DOC_TYPE_SEARCH_EVENT_REQUEST.EXPANDED
                    : DOC_TYPE_SEARCH_EVENT_REQUEST.SIMPLE;
            }
            documentIds.push({
                id: article.id,
                title: uboCategory ? uboTitle : elasticTitle,
                source: article.source,
                singleSource: article.singleSource,
                publishedDate: article.date,
                uboTradeUp: article.isBranch ? article.isBranch : false,
                persistedNodes: article.persistedNodes,
                sortingFields,
                documentAccessType,
            });
        });

        postFilters.results = results;
        postFilters.category = utils.isLegalSubcategoryBoolean(originalArticleType) ? originalArticleType : category;
        postFilters.sort = sort;

        document = {
            documentsList: documentIds,
            searchQueryType: searchQueryType,
            searchQuery: searchQuery,
            prefilterQuery: prefilterQuery,
            postFilters: this._getPostFiltersForESDocumentListInsertion(postFilters, originalArticleType, state),
            billingId: billingId,
            singleSource: singleSource,
        };

        if (uboCategory) {
            const docViewDeliveryInfo = state.ubo.docViewDeliveryInfo;
            const sortingFields = docViewDeliveryInfo.sorting;
            const filteringFields = docViewDeliveryInfo.filtering;
            document = {
                ...document,
                sortingFields,
                filteringFields,
            };
        }

        return document;
    },
    // extracts a report summary from current search information.
    getReportSummary() {
        let reportSummary = [];
        let state = reduxStore.getState();
        let { searchResults, searchParams, user } = state;
        let { resultsForMainCategory, resultsForSubcategory } = utils.deconstructResultsByMainAndSubcategories(
            searchResults,
            searchParams
        );
        let reportContentTypes = [];
        let visitedContentTypes = reduxStore.getState().investigation.visitedContentTypes;

        user.preferences.generalSettings.contentTypes.forEach((content) => {
            if (content.value) {
                reportContentTypes.push(content);
            }
        });

        reportContentTypes = reportContentTypes.filter(function (el) {
            return el != null;
        });

        Object.keys(resultsForMainCategory).forEach((categoryName) => {
            let index;
            let source = reportContentTypes.find((contentType) => contentType.name === categoryName);

            if (source) {
                index = source.reportOrder;
            }
            // filter enabled subcategories by current parent category
            let subcategories = utils.filterSubCategoriesByParent(resultsForSubcategory, categoryName);

            let postFilters = this.getPostFilterInfoForResearchSummary({
                categoryName,
                visitedContentTypes,
                subcategories,
                searchResults,
                resultsForSubcategory,
                resultsForMainCategory,
                state,
            });

            // create research summary
            reportSummary.push({
                results: searchResults[categoryName].count,
                sourceType: categoryName,
                order: index,
                visited: visitedContentTypes[categoryName] || false,
                postFilters,
            });
        });

        // for UBO
        if (resultsForMainCategory[UBO_MAIN_CATEGORY]) {
            const uboCategorySummary = reportSummary.find((item) => item && item.sourceType === UBO_MAIN_CATEGORY);
            // clear the default postfilters
            if (uboCategorySummary) {
                uboCategorySummary.postFilters = [];
            }
            // concatenating ubo categories postfilters
            Object.keys(resultsForMainCategory).forEach((categoryName) => {
                if (categoryUtils.isDnbChildCategory(categoryName) && resultsForMainCategory[UBO_MAIN_CATEGORY]) {
                    const subcategorySummary = reportSummary.find((item) => item && item.sourceType === categoryName);
                    if (subcategorySummary && subcategorySummary.postFilters) {
                        reportSummary.map((summary) => {
                            if (summary.sourceType === UBO_MAIN_CATEGORY) {
                                summary.postFilters.push(...subcategorySummary.postFilters);
                            }
                            return summary;
                        });
                        /**/
                    }
                }
            });

            // cleaning out the ubo subcategories
            Object.keys(resultsForMainCategory).forEach((categoryName) => {
                if (categoryUtils.isDnbChildCategory(categoryName)) {
                    reportSummary = reportSummary.filter((summary) => summary.sourceType !== categoryName);
                }
            });
        }

        return reportSummary;
    },

    addFullPostFiltersList(categoryFilters, state, categoryName, needsOnlySelected = false) {
        let categoryPostFiltersConfig = state.postFilterConfiguration[categoryName];

        // Add subject labels on the payload
        if (categoryFilters[FILTER_INFO.SUBJECT]) {
            if (
                categoryPostFiltersConfig &&
                categoryPostFiltersConfig[POSTFILTER_TYPE.SUBJECT] &&
                categoryPostFiltersConfig[POSTFILTER_TYPE.SUBJECT].values
            ) {
                let fullSubject = categoryPostFiltersConfig[POSTFILTER_TYPE.SUBJECT].values;
                let labelIds =
                    needsOnlySelected && categoryPostFiltersConfig[POSTFILTER_TYPE.SUBJECT].selected
                        ? categoryPostFiltersConfig[POSTFILTER_TYPE.SUBJECT].selected
                        : categoryFilters[FILTER_INFO.SUBJECT];
                categoryFilters[POST_FILTER_LABELS.SUBJECT] = SearchUtils.getFilterLabelsBasedOnIds(
                    labelIds,
                    fullSubject
                );
            }
        }

        // Add industry labels on the payload
        if (categoryFilters[FILTER_INFO.INDUSTRY]) {
            if (
                categoryPostFiltersConfig &&
                categoryPostFiltersConfig[POSTFILTER_TYPE.INDUSTRY] &&
                categoryPostFiltersConfig[POSTFILTER_TYPE.INDUSTRY].values
            ) {
                let fullIndustry = categoryPostFiltersConfig[POSTFILTER_TYPE.INDUSTRY].values;
                let labelIds =
                    needsOnlySelected && categoryPostFiltersConfig[POSTFILTER_TYPE.INDUSTRY].selected
                        ? categoryPostFiltersConfig[POSTFILTER_TYPE.INDUSTRY].selected
                        : categoryFilters[FILTER_INFO.INDUSTRY];
                categoryFilters[POST_FILTER_LABELS.INDUSTRY] = SearchUtils.getFilterLabelsBasedOnIds(
                    labelIds,
                    fullIndustry
                );
            }
        }

        // Add esg factors labels on the payload
        if (categoryFilters[FILTER_INFO.ESG_FACTORS]) {
            if (
                categoryPostFiltersConfig &&
                categoryPostFiltersConfig[POSTFILTER_TYPE.ESG_FACTORS] &&
                categoryPostFiltersConfig[POSTFILTER_TYPE.ESG_FACTORS].values
            ) {
                let fullEsgFactors = categoryPostFiltersConfig[POSTFILTER_TYPE.ESG_FACTORS].values;
                let labelIds =
                    needsOnlySelected && categoryPostFiltersConfig[POSTFILTER_TYPE.ESG_FACTORS].selected
                        ? categoryPostFiltersConfig[POSTFILTER_TYPE.ESG_FACTORS].selected
                        : categoryFilters[FILTER_INFO.ESG_FACTORS];
                categoryFilters[POST_FILTER_LABELS.ESG_FACTORS] = SearchUtils.getFilterLabelsBasedOnIds(
                    labelIds,
                    fullEsgFactors
                );
            }
        }

        return categoryFilters;
    },

    getPostFilterInfoForResearchSummary(params) {
        let postFilters = [];
        let {
            categoryName,
            subcategories,
            visitedContentTypes,
            searchResults,
            resultsForSubcategory,
            resultsForMainCategory,
            state,
        } = params;

        // if the category has children
        if (!isEmpty(subcategories)) {
            Object.keys(subcategories).forEach((subcategoryName) => {
                let isVisited = visitedContentTypes[subcategoryName] || false;
                let existingPostFilters = utils.formatPostFilters(searchResults[subcategoryName].postFilters);

                // add to a postfilter array the basic configuration of a category child (for example language)
                // add the visited flag
                let categoryFilters = withContentSourceFilter().extendConfig(
                    subcategoryName,
                    {
                        ...existingPostFilters,
                        category: subcategoryName,
                        results: resultsForSubcategory[subcategoryName].count,
                        visited: isVisited,
                    },
                    state
                );
                let shouldAddFullPostFiltersList =
                    categoryFilters[FILTER_INFO.SUBJECT] ||
                    categoryFilters[FILTER_INFO.INDUSTRY] ||
                    categoryFilters[FILTER_INFO.ESG_FACTORS];

                if (shouldAddFullPostFiltersList) {
                    categoryFilters = this.addFullPostFiltersList(categoryFilters, state, subcategoryName);
                }

                postFilters.push(addNewsSource(subcategoryName, categoryFilters, state));
            });
        } else {
            let isVisited = visitedContentTypes[categoryName] || false;
            let existingPostFilters = utils.formatPostFilters(searchResults[categoryName].postFilters);
            let postFilterEntry = {
                ...existingPostFilters,
                results: resultsForMainCategory[categoryName].count,
                category: categoryName,
                visited: isVisited,
            };

            // Normal news (non-negative or custom) categories who don't have children (sub-categories) should add their
            // contentSource info (if available) so that it can be correctly picked up by the "Research Summary" section
            // when building a report
            if (CATEGORY_NAMES.NEWS === categoryName) {
                const filters = withContentSourceFilter().extendConfig(categoryName, postFilterEntry, state);
                postFilterEntry = addNewsSource(categoryName, filters, state);
            }

            let shouldAddFullPostFiltersList =
                postFilterEntry[FILTER_INFO.SUBJECT] ||
                postFilterEntry[FILTER_INFO.INDUSTRY] ||
                postFilterEntry[FILTER_INFO.ESG_FACTORS];

            if (shouldAddFullPostFiltersList) {
                postFilterEntry = this.addFullPostFiltersList(postFilterEntry, state, categoryName);
            }

            postFilters.push(postFilterEntry);
        }

        return postFilters;
    },

    getNewCategoryOrder(reportContentTypes) {
        const categoryOrder = [];
        const { searchResults } = reduxStore.getState();

        Object.keys(searchResults).forEach((p) => {
            reportContentTypes.map((category, index) => {
                if (category.categoryName === p) {
                    categoryOrder.push({
                        sourceType: p,
                        order: index,
                    });
                }
            });
        });

        return categoryOrder;
    },

    _getPostFiltersForESDocumentListInsertion(postFilters, categoryName, state) {
        let clonedPostFilters = cloneDeep(postFilters);

        /**
         * Normally, we only extend the postFilters if the categoryName has subcategories.
         * This currently applies to negative-news and custom-news, yet, the extendConfig logic
         * can handle simple news as well.
         */
        if (categoryUtils.isNewsSource(categoryName)) {
            clonedPostFilters = withContentSourceFilter().extendConfig(categoryName, clonedPostFilters, state);
            clonedPostFilters = addNewsSource(categoryName, clonedPostFilters, state);
        }
        delete clonedPostFilters.searchQuery;
        delete clonedPostFilters.searchQueryType;

        return clonedPostFilters;
    },

    isWebnewsArticle(publicationTypes) {
        if (!publicationTypes || !Array.isArray(publicationTypes)) {
            return false;
        }

        const webnewsPublicationType = publicationTypes.filter((type) => type === ARTICLE_PUBLICATION_TYPES.Webnews);
        return webnewsPublicationType.length !== 0;
    },

    isBrazilianArticle(article) {
        return article && +article.pcsi === BRAZILIAN_OWNERSHIP_PCSI;
    },
};

export default ArticlesUtils;
