import React from 'react';

const ActiveFilterItem = (props) => {
    return (
        <div className="active-filter-item">
            <span className="active-filter-item__name">
                <span className="active-filter-item__label">{props.name}</span>
                <span className="active-filter-item__value">{props.filter}</span>
            </span>
            {props.removeFilter && (
                <button
                    className="la-CloseRemove"
                    onClick={props.removeFilter}
                    value={props.filter}
                    name={props.label}
                />
            )}
        </div>
    );
};

export default ActiveFilterItem;
