import React, { Component } from 'react';
import { cloneDeep, isEqual } from 'lodash';
import AutosavingMessage from '@reusable/AutsaveMessage/AutosavingMessage';

const withAutosave = () => (WrappedComponent) => {
    return class AutoSaveHOC extends Component {
        constructor(props) {
            super(props);

            this.state = {
                savedSections: [],
                sectionsStatus: {},
            };
        }

        UNSAFE_componentWillMount() {
            this.autosavingIntervalIds = [];
        }

        componentDidUpdate(prevProps) {
            if (this.props.modifiedSections) {
                // keep record of saving sections
                if (
                    this.props.modifiedSections.length > 0 &&
                    !isEqual(this.props.modifiedSections, prevProps.modifiedSections)
                ) {
                    this.setState((prevState) => ({
                        savedSections: [...prevState.savedSections, ...this.props.modifiedSections],
                    }));
                }

                if (
                    !this.props.arePreferencesSaving &&
                    prevProps.modifiedSections &&
                    !isEqual(this.props.modifiedSections, prevProps.modifiedSections)
                ) {
                    this.showSavedMessage(prevProps);
                }

                if (
                    this.autosavingIntervalIds.length === 0 &&
                    this.state.savedSections.length > 0 &&
                    this.props.modifiedSections.length === 0
                ) {
                    this.setState({
                        savedSections: [],
                    });
                }
            }
        }

        componentWillUnmount() {
            this.autosavingIntervalIds.forEach((intervalId) => {
                clearInterval(intervalId);
            });
        }

        showSavedMessage = (prevProps) => {
            let sectionsStatus = cloneDeep(this.state.sectionsStatus);

            let status =
                prevProps.modifiedSections.indexOf(this.props.section) > -1 &&
                this.state.savedSections.indexOf(this.props.section) > -1;
            sectionsStatus[this.props.section] = status;

            if (status) {
                let intervalID = setInterval(this.removeSavedMessage, 3000);
                this.autosavingIntervalIds.push(intervalID);
                this.setState({
                    sectionsStatus: sectionsStatus,
                });
            }
        };

        removeSavedMessage = () => {
            clearInterval(this.autosavingIntervalIds[0]);
            this.autosavingIntervalIds.shift();

            let sectionsStatus = cloneDeep(this.state.sectionsStatus);
            sectionsStatus[this.props.section] = false;

            this.setState({
                sectionsStatus: sectionsStatus,
            });
        };

        handleAutosave = (handler, params) => {
            const validChange = handler(...params);
            //This was added for the case when the user tries to deselect the last negative language or the last content type selected (when the modal shows up)
            //so it won't trigger the display of the autosave message.
            const shouldTriggerSavingMessage = typeof validChange === 'boolean' ? validChange : true;
            if (!this.props.userIsAdmin && this.props.changeMade && shouldTriggerSavingMessage) {
                this.props.changeMade(this.props.section);
            }
        };

        render() {
            let sectionsSaving = {};
            sectionsSaving[this.props.section] =
                this.props.modifiedSections && this.props.modifiedSections.indexOf(this.props.section) > -1;

            return (
                <WrappedComponent
                    {...this.props}
                    sectionsSaving={sectionsSaving}
                    sectionsStatus={this.state.sectionsStatus}
                    autoSaveMessage={
                        <AutosavingMessage
                            isSectionSaving={sectionsSaving[this.props.section]}
                            isSectionSaved={this.state.sectionsStatus[this.props.section]}
                            hasError={this.props.autosaveHasError}
                            section={this.props.section}
                        />
                    }
                    handleAutosave={this.handleAutosave}
                />
            );
        }
    };
};

export default withAutosave;
