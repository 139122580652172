import React from 'react';
import TruncatedText from './TruncatedText';
import utils from '@utils/utilities';

const TruncatedLine = (props) => {
    return (
        <TruncatedText
            tooltipId={`truncated-line-${props.id}`}
            className={`truncated-line-${props.id}`}
            hasChild={true}
            tooltipHtml={false}
            checkTruncation={utils.isHtmlTextTruncated}
            TruncatedComponent={Term}
            {...props}
        />
    );
};

const Term = (props) => {
    return (
        <div data-for={props.tooltipId} data-tip={props.isTruncated ? props.text : null}>
            {props.text}
        </div>
    );
};

export default TruncatedLine;
export { Term };
