import {
    RESET_SUGGESTED_NAMES,
    SUGGESTED_NAMES_ARE_EXCEEDED,
    SUGGESTED_NAMES_ARE_LOADED,
    UPDATE_ALL_SUGGESTED_NAMES_SELECTION,
    UPDATE_SUGGESTED_NAMES,
    UPDATE_SUGGESTED_NAMES_SELECTION,
    UPDATE_LOADED_SUGGESTIONS_FOR_TERMS,
    UPDATE_SUGGESTED_NAMES_QUERY,
    UPDATE_LOADED_STATE_FOR_TERM,
    UPDATE_LOADED_TERMS,
} from './SuggestedNames.reducers';

const suggestedNamesActions = {
    updateSuggestedNamesAndQuery: function (suggestedNames, query) {
        return {
            type: UPDATE_SUGGESTED_NAMES,
            payload: { list: suggestedNames, query },
        };
    },

    resetSuggestedNames: function () {
        return {
            type: RESET_SUGGESTED_NAMES,
        };
    },

    updateSuggestedNamesLoadedStatus: function (isSuggestedNamesLoaded) {
        return {
            type: SUGGESTED_NAMES_ARE_LOADED,
            payload: isSuggestedNamesLoaded,
        };
    },

    setSuggestedNamesExceeded: function (hasExceededSuggestedNames) {
        return {
            type: SUGGESTED_NAMES_ARE_EXCEEDED,
            payload: hasExceededSuggestedNames,
        };
    },

    updateSuggestedNamesSelection: function (itemValue, selected) {
        return {
            type: UPDATE_SUGGESTED_NAMES_SELECTION,
            payload: { itemValue, selected },
        };
    },

    updateAllSuggestedNamesSelection: function (visibleSuggestedNames, selected, index) {
        return {
            type: UPDATE_ALL_SUGGESTED_NAMES_SELECTION,
            payload: { visibleSuggestedNames, selected, index },
        };
    },

    updateLoadedSuggestionsForTerms: function (loadedTerm, index) {
        return {
            type: UPDATE_LOADED_SUGGESTIONS_FOR_TERMS,
            payload: { loadedTerm, index },
        };
    },

    updateLoadedStateForTerm: function (index, loaded) {
        return {
            type: UPDATE_LOADED_STATE_FOR_TERM,
            payload: { index, loaded },
        };
    },

    updateLoadedTerms: function (loadedTerms) {
        return {
            type: UPDATE_LOADED_TERMS,
            payload: { loadedTerms },
        };
    },

    updateSuggestedNamesQuery: function (query) {
        return {
            type: UPDATE_SUGGESTED_NAMES_QUERY,
            payload: { query },
        };
    },
};

export default suggestedNamesActions;
