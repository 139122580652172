import React, {useEffect} from 'react';
import { injectIntl } from 'react-intl';
import utils from '@utils/utilities';
import NegativeNewsResultsLineGraph from './lineGraph/NegativeNewsResultsLineGraph';
import NegativityLevelPillContainer from '../negativityLevel/NegativityLevelPillContainer';
import { NEGATIVE_TERMS_HEADERS, PERSON_SEARCH } from '@constants';
import NegativityTermsContainer from '../negativityTerms/NegativityTermsContainer';
import NegativityTermItem from '../negativityTerms/negativityTermsItems/NegativityTermItem';
import NegativeNewsVisualisationsUtils from './NegativeNewsVisualisationsUtils';
import formatRichMessage from '@utils/formatRichMessage';

const NegativeNewsResultsGraphContainer = (props) => {
    const {
        isTrendsExpanded,
        language,
        negativeNewsBuckets,
        onNegativeNewsTimelineChange,
        topNegativeTerms,
        onNegativityLevelChange,
        isHistoryCategoryCurrentOne,
        searchType,
        companyNegativityLevel,
        personNegativityLevel,
        disableTrendsGraphCloseOnScroll,
        toggleShowTrendsGraphTemporarily,
    } = props;
    const graphProps = { language, negativeNewsBuckets, onNegativeNewsTimelineChange, onNegativityLevelChange };
    const displayTopTerms = topNegativeTerms && topNegativeTerms.length > 0;
    const isInteractive = !isHistoryCategoryCurrentOne;
    const preferencesNegativityLevels = !isHistoryCategoryCurrentOne
        ? searchType === PERSON_SEARCH
            ? personNegativityLevel
            : companyNegativityLevel
        : [];

    const negativityLevels = NegativeNewsVisualisationsUtils.getNegativityPillsForGraph(
        preferencesNegativityLevels,
        negativeNewsBuckets
    );

    const handleTrendsToggle = (isTrendsExpanded) => {
        utils.saveNegativeNewsVisualisationToggle(!isTrendsExpanded);
        disableTrendsGraphCloseOnScroll(isTrendsExpanded);
    }

    useEffect(() => {
        toggleShowTrendsGraphTemporarily();
    }, []);

    return (
        <section className="negativeNews-graph-wrapper">
            <div className="negativeNews-graph-wrapper-content">
                <div
                    className={`negativeNews-graph-content ${isTrendsExpanded ? 'extended' : ''} ${
                        displayTopTerms ? 'hasNegativeTerms' : ''
                    }`}
                >
                    <div className={`${displayTopTerms ? 'negativeNews-graph-elements' : ''}`}>
                        <div className="negativeNews-graph-legend">
                            <NegativityLevelPillContainer
                                levelsList={negativityLevels}
                                isInteractive={isInteractive}
                                isShortLabel={false}
                                onNegativityLevelChange={onNegativityLevelChange}
                                dataTrack="results-list-negativity"
                            />
                        </div>
                        <div className="negativeNews-graph-container" data-track="negativeNews-graph">
                            <NegativeNewsResultsLineGraph {...graphProps} />
                        </div>
                    </div>

                    {displayTopTerms && (
                        <div className="negativeNews-graph-terms">
                            <NegativityTermsContainer negativeTermsHeaders={NEGATIVE_TERMS_HEADERS}>
                                {topNegativeTerms.map((topNegativeTerm, index) => (
                                    <NegativityTermItem
                                        termItem={topNegativeTerm}
                                        language={language}
                                        key={`${topNegativeTerm.term}-${index}`}
                                        onNegativeTermsClick={props.onNegativeTermsClick}
                                    />
                                ))}
                            </NegativityTermsContainer>
                        </div>
                    )}
                </div>
                <div
                    className={`negativeNews-graph-toggle ${isTrendsExpanded ? 'extended' : ''}`}
                    onClick={() => handleTrendsToggle(isTrendsExpanded)}
                >
                    <p className="negativeNews-graph-toggle-label-wrapper">
                        <span className="negativeNews-graph-toggle-label">
                            {formatRichMessage(
                                { id: `NegativeNews.toggleCta.${isTrendsExpanded ? 'isOpened' : 'isClosed'}` },
                                props.intl
                            )}
                        </span>
                        <span className={`la-Triangle${isTrendsExpanded ? 'Up' : 'Down'}`} />
                    </p>
                </div>
            </div>
        </section>
    );
};

export { NegativeNewsResultsGraphContainer as TestNegativeNewsResultsGraph };
export default injectIntl(NegativeNewsResultsGraphContainer);
