import update from 'immutability-helper';
import {
    UPDATE_NEGATIVITY_POD_RISK,
    UPDATE_TOP_NEGATIVE_TERMS,
    CLEAR_NEGATIVE_NEWS_VISUALIZATION,
} from './NegativeNewsVisualisation.actions';
import errorUtils from '@utils/errors/error-utils';

const APP_MODULE = 'negativeNewsVisualisation.reducers';

function negativeNewsVisualisation(state = {}, action) {
    switch (action.type) {
        case UPDATE_NEGATIVITY_POD_RISK:
            if (!action.payload) {
                errorUtils.logAppError(
                    'Missing parameter in ' + UPDATE_NEGATIVITY_POD_RISK,
                    { payload: action.payload },
                    APP_MODULE
                );

                return state;
            }

            return update(state, { negativityRisk: { $set: action.payload } });

        case UPDATE_TOP_NEGATIVE_TERMS:
            if (!action.payload) {
                errorUtils.logAppError(
                    'Missing parameter in ' + UPDATE_TOP_NEGATIVE_TERMS,
                    { payload: action.payload },
                    APP_MODULE
                );

                return state;
            }

            return update(state, { topNegativeTerms: { $set: action.payload } });

        case CLEAR_NEGATIVE_NEWS_VISUALIZATION:
            return update(state, {
                $set: {
                    negativityRisk: {},
                    topNegativeTerms: [],
                },
            });

        default:
            return state;
    }
}

export default negativeNewsVisualisation;
