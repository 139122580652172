import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { OPTION_CHECK } from '@constants';
import { camelCase } from 'lodash';

import { selectAllDeep } from './hoc/withSelectAll';
// Move to FormTypes
export const CheckOptionType = PropTypes.shape({
    label: PropTypes.any,
    value: PropTypes.any,
    extra: PropTypes.object,
});

class NestedChecksGroup extends Component {
    static propTypes = {
        onChange: PropTypes.func,
        options: PropTypes.arrayOf(CheckOptionType),
        selectAll: PropTypes.any,
        classNames: PropTypes.string,
        label: PropTypes.any,
        hasError: PropTypes.any,
        disabled: PropTypes.bool,
        required: PropTypes.bool,
        fieldId: PropTypes.string,
        expanded: PropTypes.bool,
        hasFullTreeLoaded: PropTypes.bool,
    };

    static defaultProps = {
        selectAll: null,
        label: null,
        hasError: false,
        disabled: false,
        required: false,
        classNames: '',
        expanded: false,
        hasFullTreeLoaded: false,
    };

    constructor(props) {
        super(props);

        this.state = {
            selectedAll: !!props.selectAll,
            expanded: props.options.map(() => false),
        };

        this.handleOptionChange = this.handleOptionChange.bind(this);
        this.onToggleExpand = this.onToggleExpand.bind(this);
    }

    handleOptionChange = (option, e) => {
        const checked = nextCheckState(option.checked);
        let newOption = { ...option, checked };
        let options = this.props.options.map((item) =>
            option === item
                ? {
                      ...item,
                      checked: newOption.checked,
                      children: item.children ? selectAllDeep(item.children, checked) : null,
                  }
                : item
        );
        this.props.onChange(options, newOption, e);
    };

    handleChangeChildren = (parentOption) => (options, option, e) => {
        let newOptions = this.props.options.map((item) => {
            return {
                ...item,
                children: parentOption === item ? options : item.children,
                checked: parentOption === item ? getOptionCheckState(options) : item.checked,
            };
        });
        this.props.onChange(newOptions, option, e);
    };

    onToggleExpand = (index) => () => {
        let expanded = this.state.expanded;
        expanded[index] = !expanded[index];
        this.setState({ expanded });
    };

    onLoadExtendedView = (index) => () => {
        this.onToggleExpand(index)();
        this.props.updatePostFilterTreeData(this.props.searchFieldName);
    };

    render() {
        const checkboxes = this.props.options.map((option, index) => (
            <div key={this.props.fieldId + index} className={'option-item'}>
                <div className={'option-info'}>
                    <div className={'option-controls'}>
                        {option.children && option.children.length ? (
                            <span
                                onClick={this.onToggleExpand(camelCase(option.name))}
                                className={
                                    'expand-icon ' +
                                    (this.state.expanded[camelCase(option.name)] ? 'la-ZoomOut' : 'la-ZoomIn')
                                }
                            />
                        ) : this.props.hasFullTreeLoaded === false ? (
                            <span
                                data-testid={'initial-tree-state'}
                                onClick={this.onLoadExtendedView(camelCase(option.name))}
                                className={'expand-icon la-ZoomIn'}
                            />
                        ) : (
                            <span className={'no-expand-icon'} />
                        )}
                    </div>
                    <div className="custom-checkbox">
                        <input
                            type="checkbox"
                            name={this.props.fieldId}
                            className={option.checked === OPTION_CHECK.PARTIAL ? 'partial-checked' : ''}
                            value={option.value}
                            id={this.props.fieldId + camelCase(option.name)}
                            checked={option.checked === OPTION_CHECK.CHECKED || option.checked === OPTION_CHECK.PARTIAL}
                            disabled={!!option.disabled || this.props.disabled}
                            onChange={(e) => this.handleOptionChange(option, e)}
                            {...option.extra}
                        />
                        <label className="checkbox-label" htmlFor={this.props.fieldId + camelCase(option.name)}>
                            {option.label}
                        </label>
                    </div>
                </div>
                {option.children && this.state.expanded[camelCase(option.name)] && (
                    <NestedChecksGroup
                        {...this.props}
                        options={option.children}
                        classNames={'option-children ' + (this.state.expanded[camelCase(option.name)] ? '' : 'hidden')}
                        onChange={this.handleChangeChildren(option)}
                    />
                )}
            </div>
        ));

        return <div className={this.props.classNames + ' nested-checkbox-group'}>{checkboxes}</div>;
    }
}
export default NestedChecksGroup;

const nextCheckState = (check) => {
    switch (check) {
        case OPTION_CHECK.PARTIAL:
            return OPTION_CHECK.CHECKED;
        case OPTION_CHECK.CHECKED:
            return OPTION_CHECK.UNCHECKED;
        case OPTION_CHECK.UNCHECKED:
            return OPTION_CHECK.CHECKED;
        default:
            return false;
    }
};

const getOptionCheckState = (options) => {
    if (options) {
        const checkedItems = options.filter((child) => child.checked);
        const partiallyCheckedItems = options.filter((child) => child.checked === OPTION_CHECK.PARTIAL);
        if (checkedItems.length === 0 && partiallyCheckedItems.length === 0) {
            return OPTION_CHECK.UNCHECKED;
        } else if (checkedItems.length === options.length) {
            return OPTION_CHECK.CHECKED;
        } else {
            return OPTION_CHECK.PARTIAL;
        }
    }
};
