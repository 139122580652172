import { useMemo } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { DRAG_AND_DROP } from '@constants';

const useDragAndDrop = (elementProps, dndHookInfo) => {
    const { accept, componentType } = dndHookInfo;

    let useDropParam = {
        accept,
        collect(monitor) {
            return {
                isOver: monitor.isOver(),
                isOverCurrent: monitor.isOver({ shallow: true }),
                canDrop: monitor.canDrop(),
                itemType: monitor.getItemType(),
            };
        },
    };

    if (componentType === 'child') {
        useDropParam = {
            ...useDropParam,
            hover(props) {
                const dragIndex = props.index;
                const hoverIndex = elementProps.index;

                props.updateVisualDropTarget(dragIndex, hoverIndex);
            },

            drop(item) {
                const hoverIndex = elementProps.index;
                let thirdParam;

                switch (accept) {
                    case DRAG_AND_DROP.ARTICLE:
                        thirdParam = item.article;
                        break;
                    case DRAG_AND_DROP.CHILD_REPORT:
                        thirdParam = item.childReport;
                        break;
                    case DRAG_AND_DROP.MODAL_REORDER_ITEMS_LIST:
                        thirdParam = item.currentItem;
                        break;
                    default:
                        thirdParam = item;
                }

                item.requestReorder(item.index, hoverIndex, thirdParam);
            },
        };
    }

    const [{ isOver, isOverCurrent, canDrop, itemType }, connectDropTarget] = useDrop(useDropParam);

    if (componentType === 'child') {
        const [{ isDragging }, connectDragSource] = useDrag({
            type: accept,
            item: () => {
                return elementProps;
            },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),

            end(item, monitor) {
                if (!monitor.didDrop()) {
                    item.updateVisualDropTarget(item.index);
                    return;
                }
            },
        });

        return useMemo(() => {
            return {
                isOver,
                canDrop,
                connectDropTarget,
                isDragging,
                connectDragSource,
            };
        }, [isOver, canDrop, connectDropTarget, isDragging, connectDragSource]);
    } else {
        return useMemo(() => {
            return {
                isOver,
                isOverCurrent,
                canDrop,
                itemType,
                connectDropTarget,
            };
        }, [isOver, isOverCurrent, canDrop, itemType, connectDropTarget]);
    }
};

export default useDragAndDrop;
