// @flow
import * as React from 'react';
import ReportHeader from './sharedComponents/Header/ReportHeader';
import { withShowForCombinedReports } from '@ReportBuilder/HOC/withReportHoc';
import Content from './combinedReportComponents/Content';
import type { CategoryDeleteRequest, CombinedReportType } from '@ReportBuilder/redux/flow/ReportBuilder.type.guards';
import { injectIntl } from 'react-intl';
import utils from '@utils/utilities';
import formatRichMessage from '@utils/formatRichMessage';

type Props = {
    index: number,
    row?: CombinedReportType,
    report: CombinedReportType,
    modifiedSections?: Array<string>,
    removeAutosaveMessage?: (hasError: boolean, autosavedSectionId: string) => void,
    showAutosaveMessage?: (categoryId: string) => void,
    removeIncludedReport?: (parentId: string, childReports: Array<string>) => void,
    jumpToReport?: (reportId: string) => void,
    addNoteToReport?: (reportId: string, note: string) => void,
    onDelete?: (reportId: string, reportTitle: string) => void,
    onCategoryDelete: (categoryDeleteRequest: CategoryDeleteRequest) => void,
    downloadReport: (event: any) => void,
    showDownloadReportPopup: (popupType: string) => void,
    onToggleSelect?: (item: string, value: boolean) => void,
    reportDeliveryAvailabilityDays?: number,
    prReportDeliveryAvailabilityDays?: number,
    onToggleReport: (reportId: string) => Promise<Object>,
    updateReportProperty: ({
        reportId: string,
        reportProperty: string,
        propertyValue: mixed,
        isSelected?: boolean,
    }) => void,
    +intl: Object,
    language: string,
};

const CombinedReport = (props: Props) => {
    const snippetsCount: number = props.report.snippetsCount;
    const reportsCount: number = props.report.childReports
        ? props.report.childReports.filter(
              (childReport: any): boolean => !childReport.isExpired && !childReport.isDeleted
          ).length
        : 0;
    const reportsMessageId: string = `${reportsCount === 1 ? 'General.report' : 'General.reports'}`;
    const documentsMessageId: string = `${snippetsCount === 1 ? 'General.document' : 'General.documents'}`;
    const documentCounts: string = `${
        reportsCount && utils.formatNumbersByUserLocale(props.language, reportsCount)
    } ${formatRichMessage({ id: reportsMessageId }, props.intl)} (${
        snippetsCount && utils.formatNumbersByUserLocale(props.language, snippetsCount)
    } ${formatRichMessage({ id: documentsMessageId }, props.intl)})`;

    return (
        <div className="report-row-wrapper">
            <ReportHeader
                report={props.report}
                reportType={props.report.deliveryType}
                index={props.index}
                updateReportProperty={props.updateReportProperty}
                handleToggleReport={props.onToggleReport}
                showDownloadReportPopup={props.showDownloadReportPopup}
                prReportDeliveryAvailabilityDays={props.prReportDeliveryAvailabilityDays}
                reportDeliveryAvailabilityDays={props.reportDeliveryAvailabilityDays}
                downloadReport={props.downloadReport}
                handleDeleteReport={props.onDelete}
                row={props.row}
                onToggleSelect={props.onToggleSelect}
                downloadLinkMessage={{
                    downloadNow: 'Report.combined.reportReadyForDownload',
                    downloadAgain: 'Report.combined.reportReadyForDownloadAgain',
                }}
                countMessage={documentCounts}
            />

            <Content
                index={props.index}
                isReportExpanded={props.report.isSelected}
                isLoading={props.report.loadingConnectedReports}
                report={props.report}
                showDownloadReportPopup={props.showDownloadReportPopup}
                downloadReport={props.downloadReport}
                handleDeleteReport={props.onDelete}
                handleCategoryDeleteReport={props.onCategoryDelete}
                addNoteToReport={props.addNoteToReport}
                loader={{
                    loaderClassName: 'report-loader',
                    wrapperClassName: 'combined-report',
                }}
                language={props.language}
                viewIncludedReport={props.jumpToReport}
                removeIncludedReport={props.removeIncludedReport}
                showAutosaveMessage={props.showAutosaveMessage}
                removeAutosaveMessage={props.removeAutosaveMessage}
                modifiedSections={props.modifiedSections}
            />
        </div>
    );
};

export default (injectIntl(withShowForCombinedReports(CombinedReport)): React$StatelessFunctionalComponent<Props>);
