import React, { Component } from 'react';

export class SanctionsCellTemplate extends Component {
    getCompatibleCell(uncertainCell) {
        const text = uncertainCell.text;
        return { ...uncertainCell, text };
    }

    update(cell, cellToMerge) {
        return this.getCompatibleCell({ ...cell, text: cellToMerge.text });
    }

    render(cell) {
        const { text, rowIndex, columnId } = cell;
        const { content, onClick, onMouseEnter, onMouseLeave } = text;
        const isContentAvailable = content !== '-';
        const onClickHandler = () => isContentAvailable && onClick();

        return (
            <div className={`sanctions-table-cell${isContentAvailable? '' : ' disabled'} ${this.props.hoveredRow === rowIndex ? ' hovered' : ''}`}
                 data-testid={`sw-table-${columnId}-${rowIndex}`}
                 onClick={onClickHandler}
                 onMouseEnter={onMouseEnter}
                 onMouseLeave={onMouseLeave}>
                <p>{content}</p>
            </div>
        );
    }
}
