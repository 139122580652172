import reduxStore from '@reduxStore';
import {
    MAX_LOG_XHR_STACK_SIZE,
    LOCAL_STORAGE_XHR_LOG_KEY,
    XHR_LOG_RESPONSE_BODY_MAX_SIZE,
    XHR_LOG_EXCLUDE_URL_LIST,
    ERROR_CODES,
} from '@constants';
import utils from '@utils/utilities';
import { cloneDeep, groupBy, uniq } from 'lodash';
import { cleanFunc, requestFilterFn } from './error-config';
import categoryUtils from '@utils/categoryUtils';
const storage = window.sessionStorage;

const preventLog = (req) => {
    try {
        let prevent = false;
        requestFilterFn.forEach((filterFn) => {
            prevent = prevent || filterFn(req);
        });
        return prevent;
    } catch (e) {
        return false;
    }
};

const errorUtils = {
    failedCategoriesCollector: {
        errors: {
            [ERROR_CODES.INVALID_QUERY_STRING]: [],
            [ERROR_CODES.INTERNAL_SERVER_ERROR]: [],
        },
        add(err) {
            if (err.error_code) {
                this.errors[err.error_code].push(err.categoryName);
            }
        },
        groupErrorsByCategoryKey() {
            let groupedErrors = {};
            Object.keys(this.errors).forEach((errorCode) => {
                if (this.errors[errorCode].length === 0) return;
                const errorsByErrorCode = this.errors[errorCode];
                const groupedByParentKey = groupBy(
                    errorsByErrorCode,
                    (categoryKey) => categoryUtils.getParent(categoryKey) || 'rootCategories'
                );
                groupedErrors[errorCode] = groupedByParentKey;
            });

            return groupedErrors;
        },
        getByErrorCode(errCode) {
            const groupedErrors = this.groupErrorsByCategoryKey();
            if (!groupedErrors[errCode]) return null;

            let errorsTerm = [];
            Object.keys(groupedErrors[errCode]).forEach((key) => {
                if (categoryUtils.hasChildren(key)) {
                    let structByParentKey = groupedErrors[errCode][key];
                    let allAvailableChildren = categoryUtils
                        .getCategoryKeys(key)
                        .filter((child) => utils.isCategoryEnabled(child));
                    if (structByParentKey.length === allAvailableChildren.length) {
                        errorsTerm.push(key);
                    } else {
                        errorsTerm.push(...structByParentKey);
                    }
                } else {
                    errorsTerm.push(...groupedErrors[errCode][key]);
                }
            });

            return uniq(errorsTerm);
        },
        getCategoryKeyErrorCode(categoryKey) {
            let errCodeResult = null;
            Object.keys(this.errors).forEach((errCode) => {
                this.errors[errCode].forEach((errorCatKey) => {
                    if (errorCatKey === categoryKey) errCodeResult = errCode;
                });
            });

            return errCodeResult;
        },
        getFlatList() {
            return [
                ...this.errors[ERROR_CODES.INVALID_QUERY_STRING],
                ...this.errors[ERROR_CODES.INTERNAL_SERVER_ERROR],
            ];
        },
        clear() {
            this.errors = {
                [ERROR_CODES.INVALID_QUERY_STRING]: [],
                [ERROR_CODES.INTERNAL_SERVER_ERROR]: [],
            };
        },
        hasError(categoryName) {
            const mergedFailedCategories = this.getFlatList();
            let hasError = false;
            mergedFailedCategories.forEach((failedCategoryName) => {
                if (failedCategoryName === categoryName || categoryUtils.isChildOf(categoryName, failedCategoryName)) {
                    hasError = true;
                }
            });

            return hasError;
        },
    },

    logAppError(message, fullError, appModule) {
        let payload = { message, fullError, appModule };
        console.error('logging to console for now', payload);
        //TODO: add mocking for error utils
        // this.sendError(payload);
    },
    logError(req, other = {}) {
        if (preventLog(req)) {
            return;
        }
        try {
            const requestStack = JSON.parse(storage.getItem(LOCAL_STORAGE_XHR_LOG_KEY));
            const store = reduxStore.getState();

            // cleaning the user redux state of large QueryString objects
            let userReduxState = cloneDeep(store.user);
            utils.cleanObject(userReduxState, cleanFunc);

            const payload = {
                message: req.error.message,
                fullError: req.error,
                reduxState: {
                    user: userReduxState,
                    postFilterConfiguration: store.postFilterConfiguration,
                },
                requestStack: requestStack,
                ...other,
            };

            this.sendError(payload);
        } catch (e) {
            // in case even the tracker somehow crashes
            this.sendError({
                message: 'Tracking error: logError',
                fullError: JSON.stringify(e, Object.getOwnPropertyNames(e)),
            });
        }
    },

    sendError(payload) {
        if (!reduxStore.getState().user.isAnonymized) {
            fetch('/api/user/logError', {
                method: 'post',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            });
        }
    },

    // logs <x> last xhr calls
    logXHR(param) {
        try {
            // exclude some urls from log
            if (XHR_LOG_EXCLUDE_URL_LIST.indexOf(param.url) > -1) {
                return;
            }
            const requestBody = param.body || (!!param.error && param.error.rawResponse) || {};
            const trimmedBody = utils.limitText(JSON.stringify(requestBody), XHR_LOG_RESPONSE_BODY_MAX_SIZE);
            const currentRequest = {
                url: param.url,
                data: param.data || null,
                params: param.params || null,
                responseBody: trimmedBody,
                error: param.error,
                status: param.status,
                method: param.method,
            };

            let stack = [];
            const requestStack = storage.getItem(LOCAL_STORAGE_XHR_LOG_KEY) || [];

            if (requestStack.length) {
                stack = JSON.parse(requestStack);
                // if over the stack limit, pop one out
                if (stack.length >= MAX_LOG_XHR_STACK_SIZE) {
                    stack.pop();
                }
                // adding the request at the beginnning
                stack.unshift(currentRequest);
            } else {
                stack = [currentRequest];
            }

            storage.setItem(LOCAL_STORAGE_XHR_LOG_KEY, JSON.stringify(stack));
        } catch (e) {
            this.sendError({
                message: 'Tracking error: logXHR',
                fullError: JSON.stringify(e, Object.getOwnPropertyNames(e)),
            });
        }
    },
};

export default errorUtils;
