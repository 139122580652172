import React from 'react';
import { FormattedMessage } from 'react-intl';

function ModalFileName(props) {
    if (!props.isVisible) {
        return <span></span>;
    }

    return (
        <div className="popup-modal__file-name">
            <div className="download-field-name">
                <div className="popup-field-name">
                    <FormattedMessage id="PopupModal.label.fileName" />
                </div>
            </div>
            {!props.isFilenameValid && <div className="validationAlertMessage">{props.fileNameErrorMessage}</div>}
            <input
                className={`text-field-standard-lg ${!props.isFilenameValid ? 'text-field-error' : ''}`}
                id="qa-file-name-report-text-field"
                value={props.fileName}
                onChange={props.fileNameChange}
            />
            <div className="remainingCharactersMessage">{props.fileNameRemainingCharactersMessage}</div>
        </div>
    );
}

export default ModalFileName;
