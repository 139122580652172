import React from 'react';
import { ThemeSwitcherContext } from '../ThemeSwitcherContext';
import utils from '@utils/utilities';

const withThemeSwitcherContext = (Component) => {
    const withThemeSwitcherContext = (props) => (
        <ThemeSwitcherContext.Consumer>
            {(context) => <Component isDarkMode={context.isDarkMode} toggleTheme={context.toggleTheme} {...props} />}
        </ThemeSwitcherContext.Consumer>
    );

    withThemeSwitcherContext.displayName = `withThemeSwitcherContext(${utils.getDisplayName(Component)})`;

    return withThemeSwitcherContext;
};

export default withThemeSwitcherContext;
