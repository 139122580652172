import * as React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import utils from '@utils/utilities';
import NegativeNewsVisualisationsUtils from '@MainSearch/components/negativeNewsVisualisations/resultsList/NegativeNewsVisualisationsUtils';
import {
    NEGATIVITY_LEVELS_ORDER,
    NEGATIVITY_STRINGS_TO_LEVEL,
    POST_FILTER_NEGATIVITY_LEVELS,
    POSTFILTER_TYPE,
    MATOMO,
} from '@constants';
import callMatomoEvent from '@utils/matomoUtils';
import formatRichMessage from '@utils/formatRichMessage';
import { injectIntl } from 'react-intl';
import DonutChartLabel from './NegativeNewsDonutChartLabel';
import OverlaySpinner from '@reusable/OverlaySpinner/OverlaySpinner';
import categoryUtils, { withSearchResultsFilter } from '@utils/categoryUtils';
import searchUtils from '@MainSearch/SearchUtils';
import withInvestigationEvents from '@MainSearch/components/hoc/WithInvestigationEvents';
import InvestigationEventsProvider from '@MainSearch/components/providers/InvestigationEventsProvider';
import { compose } from 'redux';

export const buildNegativityPostFilterConfig = (value) => {
    return Object.values(NEGATIVITY_LEVELS_ORDER).map((level) => ({
        label: level,
        checked: level === value,
        count: true,
    }));
};

class NegativeNewsDonutChart extends React.Component {
    
    state = {
        isLoading: false,
        negativityLevelSelected: null, // this is the segment name :string
    };

    componentDidMount = () => {
        const { categoryName, searchResults, negativityRisk, refreshSelectedNegativityLevels } = this.props;
        const defaultCategoryName = categoryUtils.getDefaultSubcategoryName(categoryName, searchResults);
        const categoryData = searchResults[defaultCategoryName];
        const currentNegativityLevels =
            categoryData?.postFilters?.negativityLevels && categoryData.postFilters.negativityLevels;

        
        if (searchUtils.shouldUpdateCounts(refreshSelectedNegativityLevels, currentNegativityLevels, negativityRisk)) {
            this.refreshDocumentsCount(defaultCategoryName, currentNegativityLevels, false);
            this.props.updateRefreshSelectedNegativityLevels(currentNegativityLevels);
        }

    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevState.isLoading !== this.state.isLoading && this.state.isLoading) {
            const { categoryName, onSegmentClick, searchResults } = this.props;
            const { negativityLevelSelected } = this.state;
            const defaultCategoryName = categoryUtils.getDefaultSubcategoryName(categoryName, searchResults);
            const categoryData = searchResults[defaultCategoryName];
            const currentNegativityLevels =
                categoryData?.postFilters?.negativityLevels && categoryData.postFilters.negativityLevels[0];

            if (currentNegativityLevels !== negativityLevelSelected || prevState.negativityLevelSelected !== negativityLevelSelected) {
                this.refreshDocumentsCount(defaultCategoryName, [negativityLevelSelected]);
            } else {
                onSegmentClick({
                    target: {
                        value: categoryName,
                    },
                });
            }
        }
    };

    updatePostFiltersConfig = (segmentName) => {
        const { categoryName, searchResults, updatePostFilterForCategory, updatePostFilterConfig } = this.props;
        const negativityPostFilterConfig = buildNegativityPostFilterConfig(segmentName);

        if (categoryUtils.hasChildren(categoryName)) {
            const filteredSearchResult = withSearchResultsFilter(searchResults);
            const childrenList = filteredSearchResult.getCategory(categoryName).children;
            const childrenKeys = Object.values(childrenList).map((child) => child.key);

            childrenKeys.forEach((subCategory) => {
                updatePostFilterForCategory(subCategory, POST_FILTER_NEGATIVITY_LEVELS,
                    Array.isArray(segmentName) ? segmentName : [segmentName]);
                updatePostFilterConfig(subCategory, POSTFILTER_TYPE.NEGATIVITY_LEVELS, negativityPostFilterConfig);
            });
        } else {
            updatePostFilterForCategory(categoryName,
                POST_FILTER_NEGATIVITY_LEVELS,
                Array.isArray(segmentName)
                    ? segmentName
                    : [segmentName]
            );
        }
    };

    onFinishUpdateCounts = (segmentClick, negativityLevel) => {
        const { categoryName, searchResults, isNewResearchSummaryEnabled, investigation, onSegmentClick } = this.props;
        const defaultCategoryName = categoryUtils.getDefaultSubcategoryName(categoryName, searchResults);
        const categoryData = searchResults[defaultCategoryName];
        const { negativityLevelSelected } = this.state;

        if (negativityLevelSelected) {
            this.updatePostFiltersConfig(negativityLevelSelected);
        } else {
            this.updatePostFiltersConfig(
                negativityLevel
                    ? Array.isArray(negativityLevel) ? negativityLevel : [negativityLevel]
                    : negativityLevelSelected
            );
        }

        if (segmentClick) {
            onSegmentClick({
                target: {
                    value: categoryName,
                },
            });
        }

        if (categoryUtils.isVisitedCategory(categoryData, investigation)) {
            return searchUtils.loadMoreDocumentsForAvailableCategories(
                defaultCategoryName,
                0,
                false,
                isNewResearchSummaryEnabled
            );
        }

        this.setState({ isLoading: false });
    };

    refreshDocumentsCount = (defaultCategoryName, negativityLevel, segmentClick = true) => {
        const { categoryName, searchQuery, searchType, prefilterQuery, isNewResearchSummaryEnabled, searchResults } =
            this.props;

        let postFilters = [];

        if (categoryUtils.hasChildren(categoryName)) {
            const filteredSearchResult = withSearchResultsFilter(searchResults);
            const childrenList = filteredSearchResult.getCategory(categoryName)?.children || {};

            Object.values(childrenList).forEach((child) => {
                const { postFilters: childPostfilters, enabled } = searchResults[child.key] || {};

                if (!(childPostfilters && enabled)) return;

                childPostfilters.category = categoryName;
                childPostfilters.fuzzyNames = (
                    childPostfilters.fuzzyNames?.list ||
                    childPostfilters.fuzzyNames?.fuzzyNames?.list ||
                    []
                ).map(({ name }) => name);

                postFilters.push(childPostfilters);

                childPostfilters.newsQueries?.forEach((query) => {
                    if (query && query.negativeNewsQueries) {
                        Object.keys(query.negativeNewsQueries).forEach((negativeNewsQuery) => {
                            if (!negativityLevel.includes(NEGATIVITY_STRINGS_TO_LEVEL[negativeNewsQuery])) {
                                delete query.negativeNewsQueries[negativeNewsQuery];
                            }
                        });
                    }
                });
            });
        }
        //update the articles count using the new negativityLevel
        return searchUtils.updateCategoryCounts(
            {
                categoryName,
                searchQuery,
                searchQueryType: searchType,
                prefilterQuery,
                countOnly: true,
                preloadCategory: defaultCategoryName || null,
                useNewResearchSummary: isNewResearchSummaryEnabled,
                negativityLevel,
                postFilters,
            },
            () => this.onFinishUpdateCounts(segmentClick, negativityLevel),
            segmentClick
        );
    };

    handleSegmentClick = (e) => {
        const segmentName = e.point.name;

        this.setState({
            negativityLevelSelected: segmentName,
            isLoading: true,
        });

        callMatomoEvent({
            pageName: MATOMO.pageName.snapshot,
            component: MATOMO.component.negativityLevel,
            componentCustom: `negativityLevel_${segmentName}`,
            event: MATOMO.event.podClick,
            value: segmentName,
            action: MATOMO.action.click,
        });
    };

    generateGraphOptions = ({ value, negativityRisk, negativityLevels }) => {
        const { intl } = this.props;
        const graphSeries = NegativeNewsVisualisationsUtils.buildDonutChartSeries(
            negativityRisk.documentsCount,
            negativityLevels
        );
        const formattedArticlesCount = utils.numberFormatter(value, 2);

        return {
            chart: {
                type: 'pie',
                animation: false,
                height: 270,
                width: 400,
                margin: [0, 0, 0, 0],
                style: {
                    marginLeft: 'auto',
                },
            },
            title: {
                useHTML: true,
                text: formattedArticlesCount,
                style: {
                    fontFamily: 'Lato',
                    fontSize: '28px',
                    fontWeight: 'bold',
                },
            },
            subtitle: {
                useHTML: true,
                text: formatRichMessage({ id: 'NegativeNewsVisualisations.snapshot.graph.label.totalArticles' }, intl),
                style: {
                    fontSize: '9px',
                    color: 'black',
                },
            },
            plotOptions: {
                pie: {
                    innerSize: '85%',
                },
            },
            series: [
                {
                    name: 'default',
                    data: [...graphSeries],
                    size: '70%',
                    cursor: 'pointer',
                    point: {
                        events: {
                            click: this.handleSegmentClick,
                        },
                    },
                    states: {
                        hover: {
                            enabled: true,
                        },
                    },
                    dataLabels: {
                        formatter: function () {
                            const percentage = Highcharts.numberFormat(this.percentage, 2);
                            const label = formatRichMessage(
                                { id: `NegativeNewsVisualisations.label.${this.key}` },
                                intl
                            );
                            return renderToStaticMarkup(
                                <DonutChartLabel risk={this.key} label={label} percentage={percentage} />
                            );
                        },
                        distance: 20,
                        enabled: true,
                        useHTML: true,
                    },
                },
            ],
            credits: { enabled: false },
            tooltip: { enabled: false },
        };
    };

    render() {
        const { value, negativityRisk, negativityLevels, categoryName, searchResults } = this.props;
        const defaultCategoryName = categoryUtils.getDefaultSubcategoryName(categoryName, searchResults);
        const currentNegativityLevels = searchResults[defaultCategoryName]?.postFilters?.negativityLevels;

        const graphOptions = this.generateGraphOptions({
            value,
            negativityRisk,
            negativityLevels: currentNegativityLevels ? currentNegativityLevels : negativityLevels
        });


        if (this.state.isLoading) {
            return <OverlaySpinner className="loader-overlay" size="massive" />;
        }

        return graphOptions && !utils.isEmptyObject(graphOptions) ? (
            <div className={'donut-chart'} data-track={'negative-news-donut-chart'}>
                <HighchartsReact highcharts={Highcharts} options={graphOptions} />
            </div>
        ) : null;
    }
}

export { NegativeNewsDonutChart as TestNegativeNewsDonutChart };
export default compose(injectIntl, withInvestigationEvents(InvestigationEventsProvider))(NegativeNewsDonutChart);
