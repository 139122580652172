// @flow
import * as React from 'react';
import RadioFilterComponent from '../RadioFilterComponent';
import formatRichMessage from '@utils/formatRichMessage';
import { injectIntl } from 'react-intl';

type FuzzySearchOption = {
    checked: boolean,
    count: boolean | number,
    label: string,
};

type Props = {
    options: Array<FuzzySearchOption>,
    onChange: (options: Array<FuzzySearchOption>, option: FuzzySearchOption) => void,
    onFuzzyThresholdChange: (option: FuzzySearchOption) => void,
};

type InjectedProps = {|
    +intl: Object,
|};

const FuzzySearchPostFilter = (props: Props & InjectedProps) => {
    const handleChange = (allOptions: Array<FuzzySearchOption>, selectedOption: FuzzySearchOption) => {
        // for now just pass the handler up, any transformations should happen here
        props.onChange(allOptions, selectedOption);
        props.onFuzzyThresholdChange(selectedOption);
    };

    const options = props.options.map((option) => ({
        ...option,
        label: formatRichMessage({ id: 'General.postFilters.fuzzySearch.' + option.count.toString() }, props.intl),
    }));

    return (
        <div className={'fuzzy-search-postfilter'}>
            <RadioFilterComponent
                {...props}
                options={options}
                onChange={handleChange}
                fieldId={'fuzzy-search-postfilter'}
            />
        </div>
    );
};

export default (injectIntl(FuzzySearchPostFilter): React$StatelessFunctionalComponent<Props>);
