import React, { useEffect } from 'react';

const ModalPagination = ({ contentIndex, setContentIndex, pages }) => {
    const isFirstPage = contentIndex === 0;
    const isLastPage = contentIndex === pages.length - 1;

    useEffect(() => {
        return () => setContentIndex(0);
    }, []);

    return (
        <div className="modal_pagination">
            <button
                className={`modal_pagination__btn ${isFirstPage ? '_disabled' : ''}`}
                disabled={isFirstPage}
                onClick={() => setContentIndex((prev) => prev - 1)}
            >
                &lt;
            </button>
            <div className="modal_pagination__dots-container">
                {pages.map((page) => (
                    <div
                        key={page}
                        className={`modal_pagination__dot ${
                            page === contentIndex ? 'modal_pagination__dot--active' : ''
                        }`}
                    ></div>
                ))}
            </div>
            <button
                className={`modal_pagination__btn ${isLastPage ? '_disabled' : ''}`}
                disabled={isLastPage}
                onClick={() => setContentIndex((prev) => prev + 1)}
            >
                &gt;
            </button>
        </div>
    );
};

export default ModalPagination;
