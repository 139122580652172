// @flow
import * as React from 'react';
import NegativeNewsVisualisationsUtils from '../NegativeNewsVisualisationsUtils';
import LineGraph from '@MainSearch/components/negativeNewsVisualisations/LineGraph';
import utilities from '@utils/utilities';
import type { LineGraphLineType } from '@MainSearch/components/negativeNewsVisualisations/flow/NegativeNewsVisualisations.typeGuards';
import formatRichMessage from '@utils/formatRichMessage';
import { injectIntl } from 'react-intl';

type Props = {
    language: string,
    negativeNewsBuckets: Object,
    onNegativeNewsTimelineChange: (dateRange: string) => void,
};

type InjectedProps = {|
    +intl: Object,
|};

const NegativeNewsResultsLineGraph = (props: Props & InjectedProps) => {
    const { language, negativeNewsBuckets, onNegativeNewsTimelineChange } = props;
    const labels = {
        dateLabel: formatRichMessage({ id: 'NegativeNewsVisualisations.tooltip.date' }, props.intl) || 'Date:',
        articleLabel: formatRichMessage({ id: 'NegativeNewsVisualisations.tooltip.article' }, props.intl) || 'Article',
        articlesLabel:
            formatRichMessage({ id: 'NegativeNewsVisualisations.tooltip.articles' }, props.intl) || 'Articles',
    };
    const buckets: Object = negativeNewsBuckets;
    const noDataMessage = null;

    if (!buckets || utilities.isEmptyObject(buckets)) {
        return noDataMessage;
    }

    let firstSeriesValues = [];
    const defaultTickInterval = 1;
    const defaultDivideBy = 2;
    const distinctValues: Array<number> = NegativeNewsVisualisationsUtils.buildDistinctDatesForLineGraph(buckets);

    // Build series based on buckets
    const seriesForHistogram: Array<LineGraphLineType> = NegativeNewsVisualisationsUtils.buildSeriesForHistogram(
        distinctValues,
        buckets
    );

    // Extract first series to build labels
    if (seriesForHistogram && seriesForHistogram.length) {
        firstSeriesValues = seriesForHistogram[0].data;
    }

    // Build labels displayed on x axis
    const tooltipLabels: Array<string> = NegativeNewsVisualisationsUtils.buildLabelsForLineGraph(
        firstSeriesValues,
        language
    );

    // Build tick interval based on the bucket size
    const tickInterval: number = NegativeNewsVisualisationsUtils.getTickIntervalForLineGraph(
        firstSeriesValues,
        defaultTickInterval,
        defaultDivideBy
    );

    const shouldDisplayMarker = distinctValues.length === 1;

    const options = {
        chart: {
            height: 200,
            reflow: true,
            marginRight: 30,
        },
        credits: {
            enabled: false,
        },
        title: {
            text: '',
        },
        xAxis: {
            categories: tooltipLabels,
            tickInterval: tickInterval || defaultTickInterval,
            tickLength: 0,
            crosshair: {
                width: 1,
                color: '#bdbfc1',
            },
        },
        yAxis: {
            title: {
                text: '',
            },
        },
        plotOptions: {
            line: {
                marker: {
                    enabled: shouldDisplayMarker,
                    symbol: 'circle',
                },
            },
            series: {
                connectNulls: true,
                label: {
                    connectorAllowed: false,
                },
                cursor: 'pointer',
                events: {
                    click: function (event) {
                        onNegativeNewsTimelineChange(event.point.dateRange);
                    },
                },
            },
        },
        legend: {
            enabled: false,
        },
        series: [...seriesForHistogram],
        tooltip: {
            formatter: function () {
                return NegativeNewsVisualisationsUtils.buildTooltipForLineGraph(this, props.language, labels);
            },
        },
    };

    return options && !utilities.isEmptyObject(options) ? <LineGraph options={options} /> : noDataMessage;
};

export default (injectIntl(NegativeNewsResultsLineGraph): React$StatelessFunctionalComponent<Props>);
