export const theArticleNavigation = (state) => state.articleNavigation;

export const theArticleIndex = (state) => state.articleNavigation.index;

export const thePageSize = (state) => state.articleNavigation.pageSize;

export const theCurrentCategoryName = (state) => state.articleNavigation.params.category;

export const theCurrentPage = (state) =>
    (state.articleNavigation.index - (state.articleNavigation.index % state.articleNavigation.pageSize)) /
    state.articleNavigation.pageSize;

export const theStartingPage = (state) => state.articleNavigation.pageNumber;

export const theArticleIdList = (state) => state.articleNavigation.list.map((article) => article.id);

export const theArticleIdAt = (position) => (state) =>
    (state.articleNavigation.list[position] && state.articleNavigation.list[position].id) || null;

export const theArticleAt = (position) => (state) =>
    (state.articleNavigation.list[position] && state.articleNavigation.list[position]) || {};

export const theCurrentArticleId = (state) =>
    state.articleNavigation.list[state.articleNavigation.index] &&
    state.articleNavigation.list[state.articleNavigation.index].id;

export const theTotalCount = (state) => state.articleNavigation.totalCount || 0;

export const theArticleExcerpt = (position) => (state) => state.articleNavigation.list[position] || { content: null };

export const theSearchParams = (state) => state.articleNavigation.params;

export const theBillingId = (state) => state.investigation.billingId;

export const theArticleInfo = (state) => state.articlesManager.selectedArticle.article;
