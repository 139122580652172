import React, { useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { RICH_TAGS_NOTIFICATIONS } from '@utils/formatRichMessage';
import { POPUP_MESSAGE_TYPES } from '@constants';
import { ReactComponent as DismissIcon } from '../../../assets/icons/BannerDismissIcon.svg';
import { ReactComponent as DismissIconDark } from '../../../assets/icons/DismissIconDark.svg';
import { ReactComponent as BannerErrorIcon } from '../../../assets/icons/BannerErrorIcon.svg';
import { ReactComponent as BannerInfoIcon } from '../../../assets/icons/BannerInfoIcon.svg';
import { ReactComponent as BannerSuccessIcon } from '../../../assets/icons/BannerSuccessIcon.svg';
import { ReactComponent as BannerWarningIcon } from '../../../assets/icons/BannerWarningIcon.svg';
import { ReactComponent as BannerErrorIconDark } from '../../../assets/icons/BannerErrorIconDark.svg';
import { ReactComponent as BannerInfoIconDark } from '../../../assets/icons/BannerInfoIconDark.svg';
import { ReactComponent as BannerSuccessIconDark } from '../../../assets/icons/BannerSuccessIconDark.svg';
import { ReactComponent as BannerWarningIconDark } from '../../../assets/icons/BannerWarningIconDark.svg';


import actions from './redux/MessagePopup.actions';

const MessagePopup = ({ popupData, popupClass, popupType, isDarkMode }) => {
    const { popupTitle, messages, popupCount } = popupData;
    const multipleMessages = messages.length > 1;
    const dispatch = useDispatch();
    const clearMessagesAction = useCallback(() => dispatch(actions.clearMessages), []);

    const renderDescription = (messageId, values, multipleMsgs = false) => {
        const message = (
            <FormattedMessage
                id={messageId}
                values={{
                    ...values,
                    ...RICH_TAGS_NOTIFICATIONS,
                }}
            />
        );

        return multipleMsgs ? <li key={messageId}>{message}</li> : <p key={messageId}>{message}</p>;
    };

    const iconComponents = {
        [POPUP_MESSAGE_TYPES.CRITICAL]: isDarkMode ? <BannerErrorIconDark/> : <BannerErrorIcon />,
        [POPUP_MESSAGE_TYPES.INFO]: isDarkMode ? <BannerInfoIconDark/> : <BannerInfoIcon />,
        [POPUP_MESSAGE_TYPES.SUCCESS]: isDarkMode ? <BannerSuccessIconDark/> : <BannerSuccessIcon />,
        [POPUP_MESSAGE_TYPES.WARNING]: isDarkMode ? <BannerWarningIconDark/> : <BannerWarningIcon />,
    };

    const mappedMessages = messages.map((currMessage, index) => {
        const description = renderDescription(currMessage.messageId, currMessage.values, multipleMessages);
        const title =
            typeof currMessage.titleId === 'string' ? (
                <h3>
                    <FormattedMessage id={currMessage.titleId} />
                </h3>
            ) : null;

        const IconComponent = iconComponents[popupType];

        return (
            <div key={'popup-message-' + index} className="message-popup__message-container">
                {IconComponent && <div className="__icon">{IconComponent}</div>}
                <div>
                    {title}
                    {description}
                </div>
            </div>
        );
    });

    let customClasses =
        (mappedMessages.length > 0 && popupCount > 0) || popupTitle ? 'message-popup slide-down' : 'message-popup';

    if (multipleMessages) {
        customClasses += ' ' + 'multiple-messages';
    }

    return (
        <div className={`${customClasses} ${popupClass} notranslate`}>
            <div className="message-popup__text">
                {popupTitle && (
                    <h2>
                        <FormattedMessage id={popupTitle} />
                    </h2>
                )}
                {mappedMessages}
            </div>
            <button
                type="button"
                className="message-popup__button"
                onClick={clearMessagesAction}
                data-testid={`message-popup-${popupType}-dismiss-button`}
            >
                {isDarkMode ? <DismissIconDark /> : <DismissIcon />}
            </button>
        </div>
    );
};

/**
 * Wrapper containing a popup of each type
 */
const MessagePopups = ({ popupMessages, googleTranslateStatus }) => {
    const isDarkMode = useSelector((state) => state.user.preferences.generalSettings.isDarkMode);
    const mappedMessagesPerType = useMemo(
        () =>
            Object.values(POPUP_MESSAGE_TYPES).map((messageType) => {
                const filteredMessages = popupMessages?.messages?.filter(
                    (message) => message.messageType === messageType
                );
                const popupClass = `${messageType}-popup ${isDarkMode ? 'dark-mode' : ''}`;
                const popupData = {
                    popupTitle: popupMessages.title,
                    messages: filteredMessages ? filteredMessages : [],
                    popupCount: popupMessages.popupCount,
                };

                return (
                    <MessagePopup
                        key={messageType}
                        popupType={messageType}
                        popupClass={popupClass}
                        popupData={popupData}
                        isDarkMode={isDarkMode}
                    />
                );
            }),
        [popupMessages]
    );

    return (
        <div className={`message-popups-wrapper ${googleTranslateStatus ? 'google-translate-toolbar-on' : ''}`}>
            {mappedMessagesPerType}
        </div>
    );
};

export default MessagePopups;
