import React from 'react';
import DunsInput from '@reusable/Form/DunsInput';

export const DunsFilterPostFilter = (props) => {
    const handleChange = (duns) => {
        // since postfilters expects a list of options and a value and this is a text filter component - we use options[0] as our value and as our only selected option
        props.onChange([duns], duns, {});
    };

    const value = (props.options && props.options[0]) || '';

    return (
        <div className={'dunsFilter-postfilter'}>
            <DunsInput onChange={handleChange} hasConfirm value={value} key={`dunsFilter_${value}`} />
        </div>
    );
};

export default DunsFilterPostFilter;
