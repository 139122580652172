// @flow
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import withAutosave from '../HOC/WithAutosave';
import { compose } from 'redux';
import InformationIcon from '@reusable/InformationIcon/InformationIcon';
import { withAppContext } from '@utils/contexts';

type Props = {
    type: string,
    title: string,
    messageId: string,
    checked: boolean,
    withInformationIcon?: boolean,
    userIsAdmin: boolean,
    masterLock: boolean,
    onChange: (event: SyntheticInputEvent<HTMLInputElement>) => void,
    infoModal?: {
        content?: Array<React$Element<'div'>>,
        title?: Array<React$Element<'div'>>,
        infoModalClass?: string,
    },
};

type InjectedProps = {|
    handleAutosave: (handler: (params: Object) => void, params: Object) => void,
|};

const ExcludeNews = (props: Props & InjectedProps) => {
    return (
        <Fragment>
            <div className={`toggle-exclude-${props.type}`}>
                <div className="custom-toggle">
                    <div
                        className={!props.userIsAdmin && props.masterLock ? 'custom-toggle disabled' : 'custom-toggle'}
                    >
                        <input
                            id={`user-preferences-exclude-${props.type}`}
                            className="custom-toggle-checkbox"
                            type="checkbox"
                            title={props.title}
                            checked={props.checked === true ? 'checked' : ''}
                            onChange={props.onChange}
                            data-testid={`user-preferences-exclude-${props.type}-${props.checked ? 'enabled' : 'disabled'}`}
                        />
                        <label
                            htmlFor={`user-preferences-exclude-${props.type}`}
                            className="custom-toggle-label"
                        ></label>
                    </div>
                </div>
                <span>
                    <FormattedMessage id={props.messageId} />
                </span>
                {props.withInformationIcon && <InformationIcon {...props.infoModal} />}
            </div>
        </Fragment>
    );
};

export default (compose(withAutosave(), withAppContext)(ExcludeNews): React$StatelessFunctionalComponent<Props>);
