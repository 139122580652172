import React from 'react';
import { FormattedMessage } from 'react-intl';
import AdminLock from '@reusable/AdminLock/AdminLock';
import { CATEGORY_NAMES } from '@constants';
import NewsSourcesDropdown from '@pages/UserPreferances/components/NewsSourcesDropdown';

export const ModalDefaultSources = (props) => {
    const isCategoryTypeChecked = (categoryName) => props.sources.find((source) => source.key === categoryName).checked;
    const changeLanguageSelection = (e) => props.changeLanguageSelection(e.target.value);

    const isNewsSourcesDropdownDisabled =
        (!isCategoryTypeChecked(CATEGORY_NAMES.CUSTOM_NEWS) &&
            !isCategoryTypeChecked(CATEGORY_NAMES.NEGATIVE_NEWS) &&
            !isCategoryTypeChecked(CATEGORY_NAMES.NEWS)) ||
        props.searchTypeLock.masterLock ||
        props.searchTypeLock.newsLock;

    return (
        <div className="popup-modal__addHoc-scrollable-area__default-sources">
            <div className="horizontal-divider" />
            <div className="default-sources-container">
                <div className="default-sources-explanation">
                    <div className="default-news-sources">
                        <FormattedMessage id="AdHocSearch.Popup.NewsSearch" />
                    </div>
                    <div className="explanation">
                        <FormattedMessage id="AdHocSearch.Popup.NewsSearch.explanation" />
                    </div>
                </div>
                <div className="default-sources-dropdown">
                    <NewsSourcesDropdown
                        onChangeHandler={changeLanguageSelection}
                        defaultSources={props.newsSearchSources}
                        segment={props.segment}
                        disabledDropdown={isNewsSourcesDropdownDisabled}
                    />
                    {(props.searchTypeLock.masterLock || props.searchTypeLock.newsLock) && !props.adHocMasterLock && (
                        <div className="default-sources-dropdown-locked">
                            <AdminLock lockName={props.searchTypeLock.newsLockName} isDisabled={true} />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ModalDefaultSources;
