import { all } from 'redux-saga/effects';
import * as listeners from './listeners/articleNavigationListeners';
import * as flows from './flows/articleNavigationFlows';

// main articlesNavigationSaga, start listening for 2 flows article navigation actions and article view actions
export default function* articleNavigationSaga() {
    yield all([
        listeners.articleNavigation(flows.articleNavigation), // for the next/prev actions
        listeners.articleLoader(flows.articleLoader), // loads an article
        listeners.singleArticleView(flows.singleArticleView), // for hotlinking to a single article
        listeners.searchDocAccessEvent(flows.searchDocAccessEvent),
    ]);
}
