import React from 'react';
import utils from '@utils/utilities';

/**
 * HOC to connect provider to another handler component
 * @param ProviderComponent
 */

const withInvestigationEvents = (ProviderComponent) => (WrappedComponent) => {
    const withInvestigationEvents = (props) => (
        <ProviderComponent
            {...props}
            provideInvestigationEvents={(investigationEventsProviderProps) => (
                <WrappedComponent investigationEventsProviderProps={investigationEventsProviderProps} {...props} />
            )}
        />
    );

    withInvestigationEvents.displayName = `withInvestigationEvents(${utils.getDisplayName(
        ProviderComponent
    )})(${utils.getDisplayName(WrappedComponent)})`;

    return withInvestigationEvents;
};

export default withInvestigationEvents;
