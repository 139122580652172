/**
 * COMMON API ENDPOINTS
 */

import reduxStore from '@reduxStore';
import xhr from '@utils/xhr';
import { consoleSettings, DEFAULT_LANGUAGE, LOCALES } from '@constants';
import utils from '@utils/utilities';
import userPrefsActions from '@pages/UserPreferances/redux/UserPreferences.actions';

import { UPDATE_USER_PREFERENCES } from '@pages/Main/Main.reducers';

import { SET_ADMIN_REPORT_CATEGORIES_ORDER, SET_REPORT_CATEGORIES_ORDER } from '@pages/Main/Main.reducers';
import { permissions, userHas } from '@permissions';
import searchParamsActions from '@pages/MainSearch/redux/SearchParams.actions';
import { PublicRecordsUtils } from '@utils/publicRecordsUtils';
import CommonApi from './Common.api';

const actions = {
    updateUserPrefs: function (prefs) {
        reduxStore.dispatch({
            type: UPDATE_USER_PREFERENCES,
            payload: prefs,
        });
    },

    loadPageMeta() {
        let promises = [];

        const { isAnonymized } = reduxStore.getState().user;
        const helpUrl = isAnonymized ? '/login/anonHelpUrlRoot' : '/login/helpUrlRoot';

        promises.push(
            xhr.get({ url: helpUrl }).then((response) => {
                reduxStore.dispatch({
                    type: 'SET_HELP_URL_ROOT',
                    payload: response.text,
                });
            })
        );

        promises.push(
            xhr.get({ url: '/login/version' }).then((response) => {
                reduxStore.dispatch({
                    type: 'SET_VERSION',
                    payload: response.text,
                });
            })
        );

        promises.push(
            xhr.get({ url: '/api/user/settings' }).then((response) => {
                reduxStore.dispatch({
                    type: 'USER_AUTHENTICATION_TYPE',
                    payload: response.body,
                });
            })
        );

        promises.push(actions.fetchIntlTranslations());

        return promises;
    },

    loadPageProfile(language) {
        let promises = [];
        promises.push(
            new Promise((resolve) => {
                return resolve('mock promise');
            })
                .then(() => {
                    return CommonApi.fetchContentTypeSources().then((response) => {
                        const sources = response.body.sources;
                        reduxStore.dispatch(userPrefsActions.setUserSourcesInformation(sources));
                        reduxStore.dispatch(userPrefsActions.setUserOfferings(utils.mapUserOfferings(sources)));
                        return response;
                    });
                })
                .then(() => {
                    if (userHas(permissions.admin.edit)) {
                        return CommonApi.adminPreferences(language).then((response) => {
                            reduxStore.dispatch(userPrefsActions.getAdminPreferencesValues(response.body));
                            return response;
                        });
                    }
                })
                .then(() => {
                    return CommonApi.userPreferences(language).then(async (response) => {
                        reduxStore.dispatch(
                            searchParamsActions.updateQueryType(
                                response.body.userPreferences.generalSettings.searchType
                            )
                        );
                        reduxStore.dispatch(userPrefsActions.getUserPrefencesValues(response.body));

                        return response;
                    });
                })
                .then(() => {
                    return CommonApi.publicRecordsAuthorization().then((response) => {
                        // set url list to state
                        // console.log(JSON.stringify(response));
                        let publicRecords = response.body;
                        // publicRecords = ["1003010", "1004626", "1003098", "1003083", "1003093", "1003153", "1003268", "1003273", "1003014", "1004621", "1003248"];

                        let links = [];
                        publicRecords.forEach((pr) => {
                            links.push({
                                featureId: pr,
                            });
                        });

                        if (publicRecords.length === 0) {
                            PublicRecordsUtils.disablePublicRecords();
                        } else {
                            PublicRecordsUtils.enablePublicRecords(links);
                        }
                        return response;
                    });
                })
                .then(() => {
                    return CommonApi.uboAuthorization()
                        .then((response) => {
                            if (response.body === true) {
                                utils.enableUbo();
                            } else {
                                utils.disableUbo();
                            }
                            return response;
                        })
                        .catch((error) => {
                            utils.logError(error);
                            utils.disableUbo();
                        });
                })
                .then(() => {
                    return CommonApi.entityViewAuthorization()
                        .then((response) => {
                            if (response.body === true) {
                                utils.enableEntityView();
                            } else {
                                utils.disableEntityView();
                            }
                            return response;
                        })
                        .catch((error) => {
                            utils.logError(error);
                            utils.disableEntityView();
                        });
                })
                .then((entityViewResponse) => {
                    // this must follow right after /api/multipleentities/isAuthorized
                    return CommonApi.trialUserAuthorization()
                        .then((trailUserResponse) => {
                            // only when Multiple Entities are not enabled, but the Trial is enabled, the trial version should be used
                            if (!entityViewResponse?.body && trailUserResponse.body) {
                                // enable multiple entities also for trial users
                                utils.enableEntityView();
                                utils.setEntityViewTrial();
                            } else if (!entityViewResponse?.body && !trailUserResponse.body) {
                                // non-Diligence+ user - multiple entities disabled and non-trial user
                                utils.unsetEntityViewTrial();
                            } else {
                                // unset trial when Multiple Entities are enabled or when the trial flag is not set or when multiple entities and trial flags are not set at the same time
                                utils.unsetEntityViewTrial();
                            }
                            return trailUserResponse;
                        })
                        .catch((error) => {
                            console.error(error);
                            utils.unsetEntityViewTrial();
                        });
                })
                .then(() => {
                    return CommonApi.isNonDiligencePlusUserForUpgradeAuthorization()
                        .then((response) => {
                            if (response.body === true) {
                                utils.setIsSpecifiedCustomer();
                                utils.displayEntityViewMarketingInfo();
                            } else {
                                utils.unsetIsSpecifiedCustomer();
                                utils.hideEntityViewMarketingInfo();
                            }
                            return response;
                        })
                        .catch((error) => {
                            utils.logError(error);
                            utils.unsetIsSpecifiedCustomer();
                        });
                })
        );

        return promises;
    },

    /**
     * Fetches translations from database and updates app's default translations
     * @return {Promise}
     */
    fetchIntlTranslations() {
        let intlTranslations = LOCALES;
        let requestedKeys = [],
            checkedKeys = {},
            languagesDefaultCheck = {};

        for (let language in intlTranslations) {
            languagesDefaultCheck[language] = false;
        }

        for (let key in intlTranslations[DEFAULT_LANGUAGE].translations) {
            requestedKeys.push(key);
            checkedKeys[key] = Object.assign({}, languagesDefaultCheck);
        }

        return xhr
            .post({
                url: '/api/system/translations',
                data: requestedKeys,
            })
            .then((response) => {
                const t = response.body;
                // For each translation language
                for (let language in intlTranslations) {
                    // For each translation key(tKey) in received DB Translations(t), build translation object
                    for (let tKey in t) {
                        if (
                            t[tKey][intlTranslations[language].dbKey] &&
                            t[tKey][intlTranslations[language].dbKey].trim().length
                        ) {
                            intlTranslations[language].translations[tKey] = t[tKey][intlTranslations[language].dbKey];
                            checkedKeys[tKey][language] = true;
                        }
                    }
                }

                let missingKeys = {};

                // Check if all keys and language translation from database exist
                for (let key in checkedKeys) {
                    for (let language in checkedKeys[key]) {
                        if (!checkedKeys[key][language]) {
                            if (!missingKeys[key]) {
                                missingKeys[key] = [];
                            }
                            missingKeys[key].push(language);
                            intlTranslations[language].translations[key] =
                                intlTranslations[DEFAULT_LANGUAGE].translations[key];
                        }
                    }
                }
                if (!utils.isEmptyObject(missingKeys)) {
                    console.warn('%c !!! MISSING TRANSLATIONS !!! ', consoleSettings.style.failure);
                    console.log(missingKeys);
                } else {
                    console.info('%c <<< TRANSLATIONS :: ALL OK >>>', consoleSettings.style.sucess);
                }
            })
            .catch((error) => {
                // if call fails altogether, use default language for all LOCALES
                for (let language in intlTranslations) {
                    if (language !== DEFAULT_LANGUAGE) {
                        intlTranslations[language].translations = intlTranslations[DEFAULT_LANGUAGE].translations;
                    }
                }
                utils.logError(error, 'index.fetchIntlTranslations(): ');
            });
    },
    updateContentOrderForReportBuilder: function (categories) {
        return {
            type: SET_REPORT_CATEGORIES_ORDER,
            payload: categories,
        };
    },

    updateAdminContentOrderForReportBuilder: function (categories) {
        return {
            type: SET_ADMIN_REPORT_CATEGORIES_ORDER,
            payload: categories,
        };
    },
};

export default actions;
