import React from 'react';
import { AddToReportBtnElastic } from '@reusable/Buttons/redux/AddToReportBtn.containers';
import { FormattedMessage, injectIntl } from 'react-intl';
import categoryUtils from '@utils/categoryUtils';
import { ARTICLE_NUMBER_TYPES, CATEGORY_NAMES, NEGATIVITY_PILL_STATES, PERSON_SEARCH, MATOMO } from '@constants';
import costCodeUtils from '@utils/costCodeUtils';
import TruncatedLink from '@reusable/TruncatedElements/TruncatedLink';
import TruncatedLine from '@reusable/TruncatedElements/TruncatedLine';
import NegativityLevelPillContainer from './negativeNewsVisualisations/negativityLevel/NegativityLevelPillContainer';
import formatRichMessage from '@utils/formatRichMessage';
import utils from '@utils/utilities';
import callMatomoEvent from '@utils/matomoUtils';

const ElasticDocumentContent = (props) => {
    const checkArticle = (e, article) => {
        props.checkArticle(article, e);
    };
    const handlePreviewArticle = (e, article, articleType) => {
        const { pcsi, id } = article;
        const documentInfo = {
            documentRank: props.index + 1,
            pcsi,
            lni: id,
        };

        callMatomoEvent({
            pageName: MATOMO.pageName.resultsList,
            component: MATOMO.component.searchResultsList,
            subComponent: MATOMO.subComponent.delivery,
            event: MATOMO.event.docPreview,
            value: MATOMO.value.preview,
            action: MATOMO.action.click,
            contextData: {
                documentInfo,
                contentType: articleType,
            },
        });
        props.handlePreviewArticle(e, article, articleType);
    };
    const articleSnippets = props.getArticleSnipetsForReport(props.article);
    const costCode = costCodeUtils.getCostCode();
    const articleSubInfo = getArticleSubInfo();
    const showWordCount =
        props.articleType !== CATEGORY_NAMES.STATE_DOCKETS && props.articleType !== CATEGORY_NAMES.FEDERAL_DOCKETS;
    const shouldDisplayNegativeLevels =
        categoryUtils.isChildOf(CATEGORY_NAMES.NEGATIVE_NEWS, props.articleType) && props.article.negativityLevels;

    function getArticleSubInfo() {
        let subInfo = [
            props.article.docketNumber,
            props.article.filingType,
            props.article.courtName,
            props.article.office,
        ];
        return subInfo
            .filter((info) => info)
            .join(', ')
            .toUpperCase();
    }

    function buildNumbersString(type, numbersArray) {
        let numbersString = '';

        numbersArray.forEach((number, index) => {
            let { name, code } = number;

            if (index > 0) {
                numbersString += ', ';
            }

            if (name && code) {
                numbersString += `${type}: ${name} (${code})`;
            } else if (name) {
                numbersString += `${type}: ${name}`;
            } else if (code) {
                numbersString += `${type}: ${code}`;
            }
        });

        return numbersString;
    }

    function getArticleSicAndNaicsNumbers() {
        let { primarySic, secondarySic, primaryNaics, secondaryNaics } = props.article;
        let hasSICNumbers = (primarySic && primarySic.length > 0) || (secondarySic && secondarySic.length > 0);
        let hasNAICSNumbers =
            (primaryNaics && primaryNaics.length > 0) || (secondaryNaics && secondaryNaics.length > 0);
        let SICNumbers = hasSICNumbers
            ? buildNumbersString(ARTICLE_NUMBER_TYPES.SIC, [...primarySic, ...secondarySic])
            : null;
        let NAICSNumbers = hasNAICSNumbers
            ? buildNumbersString(ARTICLE_NUMBER_TYPES.NAICS, [...primaryNaics, ...secondaryNaics])
            : null;

        let sicAnsNiacsNumbers = [SICNumbers, NAICSNumbers];

        return sicAnsNiacsNumbers.filter((info) => info).join(', ');
    }

    function getArticleAddress() {
        let { location } = props.article;
        let { addressLine, street, city, state, postalCode, country } = location || {};

        let address = null;

        if (addressLine) {
            address = addressLine;
        } else {
            address = [street, city, state, postalCode, country].filter((info) => info).join(', ');
        }

        return address;
    }

    const getNegativityLevels = () => {
        let negativityLevels = {};

        if (props.article && props.article.negativityLevels) {
            props.article.negativityLevels.forEach((level) => {
                negativityLevels[level] = NEGATIVITY_PILL_STATES.DESELECTED;
            });
        }

        return negativityLevels;
    };

    let articleAddress = getArticleAddress();
    let articleNumbers = getArticleSicAndNaicsNumbers();
    const extendedArticle = {
        ...props.article,
        singleSource: props.singleSource,
        fuzzyThreshold: props.fuzzyThreshold,
    };
    const fuzzyThreshold =
        props.newFuzzyEnabled && props.searchType === PERSON_SEARCH ? '&fuzzyThreshold=' + props.fuzzyThreshold : '';
    const negativityLevels = getNegativityLevels();

    return (
        <li key={props.article.id} className="article-row" data-testid={'article-row'}>
            <div className="article-item-content-checkbox-wrapper">
                <div className="custom-checkbox">
                    <input
                        className="checkbox"
                        id={props.article.id}
                        type="checkbox"
                        checked={props.isArticleSelected(props.article)}
                        onChange={(e) => checkArticle(e, extendedArticle)}
                        data-testid={'article-checkbox'}
                    />
                    <label htmlFor={props.article.id} className="checkbox-label" />
                </div>

                {props.showIndex && (
                    <span className="index-of-article">
                        {utils.formatNumbersByUserLocale(props.language, props.index + 1) + '.'}
                    </span>
                )}
            </div>

            <div
                data-track="article-item-content-wrapper"
                className={`article-item-content-wrapper ${props.isArticleItemSelected ? 'selected' : ''}`}
            >
                <span className="article-item-content">
                    <div className="article-list-head">
                        <h4 className="article-title" data-track={`article-title-${props.index}`}>
                            <TruncatedLink
                                linkTo={
                                    '/document?query=' +
                                    encodeURIComponent(props.query) +
                                    '&category=' +
                                    props.articleType +
                                    '&type=' +
                                    props.searchType +
                                    '&id=' +
                                    props.article.id +
                                    '&prefilterQuery=' +
                                    encodeURIComponent(props.prefilterQuery) +
                                    '&proximity=' +
                                    props.proximity +
                                    '&costCode=' +
                                    costCode +
                                    '&singleSource=' +
                                    props.singleSource +
                                    fuzzyThreshold
                                }
                                article={props.article}
                                onClick={props.showSingleArticle}
                            />
                        </h4>
                    </div>
                    {props.articleType === CATEGORY_NAMES.SANCTIONS_WATCHLIST && props.article.listName && (
                        <div className="article-list-name">
                            <p className="article-list-name-label">
                                {formatRichMessage(
                                    { id: 'General_CoreFunctionality_UIText_general.listName' },
                                    props.intl
                                )}
                                :
                            </p>
                            <div className="articles-list-name-content">
                                <TruncatedLine id="article-list-name" text={props.article.listName} />
                            </div>
                        </div>
                    )}
                    {props.article.source && (
                        <div className="article-information">
                            <div className="article-information-data">
                                <div className="article-source">{props.article.source}</div>
                                {showWordCount ? (
                                    <div className="article-word-count">
                                        {utils.formatNumbersByUserLocale(props.language, props.article.wordCount) + ' '}
                                        <FormattedMessage id={'ElasticDocumentContent.articleWorldCount'} />
                                    </div>
                                ) : (
                                    <div className="article-no-word-count" />
                                )}
                                <div className="article-date">{props.article.date}</div>
                            </div>
                            {shouldDisplayNegativeLevels && (
                                <div className="article-information-negativity">
                                    <div
                                        className="article-negativity-level"
                                        data-track={`article-negativity-level-${props.index}`}
                                    >
                                        <NegativityLevelPillContainer
                                            levelsList={negativityLevels}
                                            isInteractive={false}
                                            isShortLabel={true}
                                            dataTrack={`elastic-document-${props.index}-negativity`}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                    {(props.article.docketNumber ||
                        props.article.filingType ||
                        props.article.courtName ||
                        props.article.office) && <div className="article-sub-information">{articleSubInfo}</div>}
                    {articleAddress && (
                        <div className="article-address">
                            <TruncatedLine text={articleAddress} id="article-address" />
                        </div>
                    )}
                    {articleNumbers && (
                        <div className="article-numbers">
                            <TruncatedLine text={articleNumbers} id="article-numbers" />
                        </div>
                    )}
                    {articleSnippets.length > 0 && (
                        <div className="article-summary" data-testid={'article-summary'}>
                            {props.getArticleSnipetsForReport(props.article)}
                        </div>
                    )}
                </span>
                <span className="article-row__buttons">
                    <AddToReportBtnElastic
                        dataTrack={`add-to-report-button-${props.index}`}
                        article={extendedArticle}
                        articleType={props.articleType}
                    />
                    {!categoryUtils.isChildOf(CATEGORY_NAMES.LAW_SOURCES, props.articleType) && (
                        <button
                            className="article-row__buttons__preview-article"
                            onClick={(e) => handlePreviewArticle(e, extendedArticle, props.articleType)}
                            data-testid={'article-preview'}
                            data-track={`preview-article-button-${props.index}`}
                        >
                            <FormattedMessage id={'General_CoreFunctionality_UIText_general.preview'} />
                        </button>
                    )}
                </span>
            </div>
        </li>
    );
};

export default injectIntl(ElasticDocumentContent);
