// Import Redux store
import reduxStore from '@reduxStore';
// Import utility libraries (XHR and utils)
import xhr from '@utils/xhr';
import utils from '@utils/utilities';
// Import Login API needed to get the app settings.
import LoginPageApi from '@api/LoginPage.api';


// Import the popup messages actions to add
import popupActions from '@scripts/reusable/MessagePopup/redux/MessagePopup.actions';

import commonActions from '@api/Common.actions';
import { routeCheckPermission } from "@scripts/permissions";

let pendingLoadPrefs = false;
let loadedGenericMeta = false;

// This array is used to store resumeApp() calls in the order they should be called. The loadPrefs() function is called
// twice by the React router and in both cases we need to call resumeApp() so that the app starts. Unfortunately, all
// resumeApp calls need to be called after api.getHelpUrlRoot() is resolved.
let resumeAppCalls = [];

 const routerUtils = {
     /**
     * Checks if the initial app data has been loaded.
     *
     * @return  {boolean}   Flag which tells if the initial app data has been loaded.
     */
    isInitialDataLoaded: function() {
        let helpUrlRoot = reduxStore.getState().user.helpUrlRoot,
        version = reduxStore.getState().header.version;

        return helpUrlRoot !== '' && version !== '-';
    },
    /**
    * This function is used to consume resumeApp() calls in the order they are
    * stored and at a certain moment in time, not at each loadPrefs() call.
    *
    * @return  nothing
    */
    consumeResumeAppCalls: function () {
        for (let i = 0; i < resumeAppCalls.length; i++) {
            resumeAppCalls[i]();
        }
    },
    loadPrefs: function (nextState, redirectRoute, resumeApp) {
        const language = reduxStore.getState().user.language;
      
        if (pendingLoadPrefs) {
            resumeAppCalls.push(resumeApp);
            return;
        }
        pendingLoadPrefs = true;
      
        resumeAppCalls.push(resumeApp);
      
        if (loadedGenericMeta) {
            Promise.all(commonActions.loadPageProfile(language))
                   .then(() => {
                       pendingLoadPrefs = false;
                       routerUtils.consumeResumeAppCalls();
                   })
                   .catch((error) => {
                       utils.logError(error, 'index.loadPrefs(): ');
                       pendingLoadPrefs = false;
                       xhr.handleAllErrors(error);
                        resumeApp();
                        routerUtils.consumeResumeAppCalls();
                   });
        } else {
            Promise.all(commonActions.loadPageMeta())
                   .then(() => {
                       loadedGenericMeta = true;
                   })
                   .then(() => {
                       return Promise.all(commonActions.loadPageProfile(language))
                   })
                   .then(() => {
                       pendingLoadPrefs = false;
                       routerUtils.consumeResumeAppCalls();
                   })
                   .catch((error) => {
                       utils.logError(error, 'index.loadPrefs(): ');
                       pendingLoadPrefs = false;
                       xhr.handleAllErrors(error);
                        resumeApp();
                        routerUtils.consumeResumeAppCalls();
                   });
        }
    },
    handlePrefsPermissions: function (nextState, redirectRoute, resumeApp) {
        routerUtils.loadPrefs(nextState, redirectRoute, () => {
            routeCheckPermission({}, nextState, redirectRoute);
            resumeApp();
        });
    },
    loadErrorPageData: function (nextState, redirectRoute, resumeApp) {
        //   If the help URL root and version were fetched before, don't fetch them again.
         if (!routerUtils.isInitialDataLoaded()) {
            LoginPageApi.getHelpUrlRoot()
                .then(() => {
                    resumeApp();
                })
                .catch((error) => {
                    utils.logError(error, 'index.loadErrorPageData(): ');
                    resumeApp();
                });
         } else {
             resumeApp();
         }
    
    },
    loadNotFoundPageData: function (nextState, redirectRoute, resumeApp) {
        popupActions.setMessages({
            messages: [
                {
                    titleId:     'General_CoreFunctionality_Error_error.404Title',
                    messageType: 'system-error',
                    messageId:   'General_CoreFunctionality_Error_error.404Message'
                }
            ]
        });
   
        routerUtils.loadErrorPageData(nextState, redirectRoute, resumeApp);
    }
 }
export default routerUtils;