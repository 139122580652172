// @flow
import React from 'react';

type Props = {
    iconType: string,
    uploadStatus: string,
    fileName: string,
    fileSize: string,
    onClick: () => void,
};

const DragAndDropUploadedItem = (props: Props): React$Element<'div'> => {
    const { iconType, uploadStatus, fileName, fileSize, onClick } = props;
    return (
        <div className="uploaded-item-wrapper">
            <div className="uploaded-item-info">
                <span className={`uploaded-item-info__${iconType}-icon`}></span>
                <div className="uploaded-item-info__content">
                    <p className="uploaded-item-info-title">{fileName}</p>
                    <p className="uploaded-item-info-size">{fileSize}</p>
                </div>
            </div>
            <div className="uploaded-item-actions-area">
                <span className={`uploaded-item-status__${uploadStatus}-icon`}></span>
                <button className="la-CloseRemove" data-testid="drag-and-drop-uploaded-item-clear-button" onClick={onClick}></button>
            </div>
        </div>
    );
};

export default DragAndDropUploadedItem;
