import update from 'immutability-helper';
import { RESET_METRIC, SET_METRIC_END, SET_METRIC_START } from './Metrics.actions';
import errorUtils from '@utils/errors/error-utils';

const APP_MODULE = 'metrics.reducers';

function metrics(state = {}, action) {
    switch (action.type) {
        case RESET_METRIC:
            const currentMetric = {
                ...state,
                timeStart: null,
                timeEnd: {},
            };

            return update(state, { $set: currentMetric });

        case SET_METRIC_START:
            if (!action.payload || !action.payload.createdDate) {
                errorUtils.logAppError(
                    'Missing parameter in ' + SET_METRIC_START,
                    {
                        payload: action.payload,
                    },
                    APP_MODULE
                );

                return state;
            }

            const { createdDate } = action.payload;
            return update(state, { timeStart: { $set: createdDate } });

        case SET_METRIC_END:
            if (!action.payload) {
                errorUtils.logAppError(
                    'Missing parameter in ' + SET_METRIC_END,
                    {
                        payload: action.payload,
                    },
                    APP_MODULE
                );

                return state;
            }

            const timeEnd = {
                ...state.timeEnd,
                ...action.payload,
            };

            return update(state, { timeEnd: { $set: timeEnd } });

        default:
            return state;
    }
}

export default metrics;
