import React from 'react';
import { FormattedMessage } from 'react-intl';
import AdminLock from '@reusable/AdminLock/AdminLock';
import { FE_LOCK_NAMES } from '@constants';
import withAutosave from '../HOC/WithAutosave';
import { withAppContext } from '@utils/contexts';
import { compose } from 'redux';

const SimilarNameMatch = (props) => {
    const handleFuzzyEnabled = (event) => {
        props.handleAutosave(props.handleFuzzyEnabled, [event]);
    };

    const handleSelectFuzzyMatch = (event) => {
        props.handleAutosave(props.handleSelectFuzzyMatch, [event]);
    };

    return (
        <div className="user-preferences-category-container user-preferences-similar-names container-section__segment">
            {props.userIsAdmin ? (
                <AdminLock
                    lockName={FE_LOCK_NAMES.similarNameMatchPerson}
                    onClickLock={props.handleLockClick}
                    isSectionLock={true}
                    isLocked={props.isReadonly}
                />
            ) : (
                props.isReadonly && <AdminLock lockName={FE_LOCK_NAMES.similarNameMatchPerson} isDisabled={true} />
            )}
            <div className="user-preferences-category-container__row container-section__segment__header">
                <h3 className="user-preferences-similar-names-topic">
                    <FormattedMessage id="UserPreferences_topic_sources_similarNameMatch" />
                </h3>
                {props.autoSaveMessage}
            </div>

            <div className="include-similar-name-match">
                <div className={!props.userIsAdmin && props.isReadonly ? 'custom-toggle disabled' : 'custom-toggle'}>
                    <input
                        type="checkbox"
                        checked={props.isFuzzyEnabled === true ? 'checked' : ''}
                        onChange={handleFuzzyEnabled}
                        className="custom-toggle-checkbox"
                        id="mytoggle"
                    />
                    <label className="custom-toggle-label" htmlFor="mytoggle" />
                </div>
                <FormattedMessage id={'UserPreferences_topic_sources_similarNameMatch.include'} />
            </div>
            <div className="include-similar-match-explanation">
                <FormattedMessage id={'UserPreferences_topic_sources_similarNameMatch.explanation'} />
            </div>
            <div className="include-similar-match-explanation2">
                <FormattedMessage id={'UserPreferences_topic_sources_similarNameMatch.explanation2'} />
            </div>

            <div className="custom-radio">
                <input
                    id="moderateMatch"
                    value="moderate"
                    checked={props.fuzzyMatch === 'moderate'}
                    onChange={handleSelectFuzzyMatch}
                    name="radio"
                    disabled={!props.userIsAdmin && props.isReadonly}
                    type="radio"
                />
                <label htmlFor="moderateMatch" className="radio-label">
                    <FormattedMessage id={'UserPreferences_topic_sources_similarNameMatch.moderate'} />
                </label>
            </div>
            <div className="custom-radio">
                <input
                    id="looseMatch"
                    value="loose"
                    checked={props.fuzzyMatch === 'loose'}
                    onChange={handleSelectFuzzyMatch}
                    name="radio"
                    disabled={!props.userIsAdmin && props.isReadonly}
                    type="radio"
                />
                <label htmlFor="looseMatch" className="radio-label">
                    <FormattedMessage id={'UserPreferences_topic_sources_similarNameMatch.loose'} />
                </label>
            </div>
        </div>
    );
};

export default compose(withAutosave(), withAppContext)(SimilarNameMatch);
