// @flow
import React, { forwardRef } from 'react';

type Props = {
    header: string,
    acceptedTypes: string,
    browseMessage: string,
    description: string,
    onChange: (e: SyntheticInputEvent<HTMLInputElement>) => void,
    onClick: (e: SyntheticMouseEvent<HTMLInputElement>) => void,
};

const DragAndDropContent: React$AbstractComponent<Props, mixed> = forwardRef(function DragAndDropContent(
    props: Props,
    ref: { current: null | HTMLInputElement }
) {
    return (
        <div className="drop-area-content">
            <CloudSvg />
            <h3 className="drop-area-content-header">{props.header}</h3>
            <label className="upload-btn" htmlFor="upload-btn" tabIndex={0} data-track="batch-upload-file-btn">
                <input
                    type="file"
                    id="upload-btn"
                    data-testid="batch-upload-button-testid"
                    ref={ref}
                    onChange={props.onChange}
                    onClick={props.onClick}
                    accept={props.acceptedTypes}
                    hidden
                />
                <span>{props.browseMessage}</span>
            </label>
            <p className="drop-area-content-description">{props.description}</p>
        </div>
    );
});

const CloudSvg = () => (
    <svg
        version="1.1"
        id="interface_essential_by_mad-lab_studio"
        width="75.8"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0"
        y="0"
        viewBox="0 0 24 24"
        xmlSpace="preserve"
    >
        <path
            fill="#0D47A1"
            d="M22.2,15.6c-0.7,1.9-2.6,3-4.6,3h0h-4.8c0,0-0.1,0-0.1-0.1v-5.9c0-0.1,0.1-0.1,0.2-0.1l2.6,2.6l0.8-0.8l-4-4
        c0,0-0.1,0-0.1,0l-4,4l0.8,0.8l2.6-2.7c0.1-0.1,0.2,0,0.2,0.1v6c0,0,0,0.1-0.1,0.1H5.6c-2.6,0-4.6-2.3-4.1-5c0.4-2,2.3-3.3,4.3-3.3
        h0h0.1c-0.4-3.4,2-6.4,5.4-6.8c0.2,0,0.5,0,0.7,0c3.1,0,5.7,2.3,6.1,5.4c0,0,0,0.1,0.1,0.1C21.3,9.2,23.4,12.4,22.2,15.6z"
        />
    </svg>
);

export default DragAndDropContent;
