import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { MatchItem as MatchItemPropType } from '@utils/props';

export class SelectableMatchList extends Component {
    static propTypes = {
        MatchItemComponent: PropTypes.object.isRequired,
        onChangeSelection: PropTypes.func.isRequired,
        onSelect: PropTypes.func.isRequired,
        selectedIndex: PropTypes.number,
        matches: PropTypes.arrayOf(MatchItemPropType).isRequired,
        matchCount: PropTypes.number.isRequired,
        onCancel: PropTypes.func,
        className: PropTypes.string,
    };

    static defaultProps = {
        className: '',
    };

    constructor(props) {
        super(props);

        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleArrowDownKey = this.handleArrowDownKey.bind(this);
        this.handleArrowUpKey = this.handleArrowUpKey.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.handleClick = this.handleClick.bind(this);

        this.itemsRef = [];
    }

    componentDidMount() {
        document.addEventListener('keydown', this.handleKeyDown, false);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyDown, false);
    }

    handleKeyDown(e) {
        switch (e.keyCode) {
            case 38:
                this.handleArrowUpKey(e);
                return;
            case 40:
                this.handleArrowDownKey(e);
                return;
            case 13:
                this.handleEnterKey(e);
                return;
            case 27:
                this.handleEscapeKey(e);
        }
    }

    handleArrowDownKey(e) {
        e.preventDefault();
        if (this.props.selectedIndex === null) {
            this.props.onChangeSelection(0, this.props.matches[0]);
        } else if (this.props.selectedIndex < this.props.matches.length - 1) {
            const newIndex = this.props.selectedIndex + 1;
            this.props.onChangeSelection(newIndex, this.props.matches[newIndex]);
            this.itemsRef[newIndex].scrollIntoView({ block: 'nearest', behavior: 'smooth' });
        }
    }

    handleArrowUpKey(e) {
        e.preventDefault();
        if (this.props.selectedIndex === null) {
            return;
        } else if (this.props.selectedIndex === 0) {
            this.props.onChangeSelection(null, null);
        } else if (this.props.selectedIndex < this.props.matches.length + 1) {
            const newIndex = this.props.selectedIndex - 1;
            this.props.onChangeSelection(newIndex, this.props.matches[newIndex]);
            this.itemsRef[newIndex].scrollIntoView({ block: 'nearest', behavior: 'smooth' });
        }
    }

    handleClick(index) {
        this.handleSelect(index);
    }

    handleEnterKey(e) {
        if (this.props.selectedIndex !== null) {
            this.handleSelect(this.props.selectedIndex);
            e.preventDefault();
        }
    }

    handleEscapeKey(e) {
        if (this.props.onCancel) {
            this.props.onCancel(e);
        }
    }

    handleSelect(index) {
        this.props.onSelect(index, this.props.matches[index]);
    }

    render() {
        const matches = this.props.matches.map((match, index) => ({
            ...match,
            selected: this.props.selectedIndex === index,
        }));

        const MatchItemComponent = this.props.MatchItemComponent;

        return (
            <div className={`match-list ${this.props.className}`} data-testid="selectable-match-list">
                {matches.map((match, i) => (
                    <MatchItemComponent
                        {...match}
                        ref={(el) => (this.itemsRef[i] = el)}
                        onClick={this.handleClick}
                        itemIndex={i}
                        key={i}
                    />
                ))}
            </div>
        );
    }
}
