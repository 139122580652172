import React from 'react';

const ModalActionTitle = (props) => {
    if (!props.isVisible) {
        return <span></span>;
    }

    return (
        <div className="popup-action-title">
            {' '}
            {props.actionTitle ? <span dangerouslySetInnerHTML={{ __html: props.actionTitle }} /> : null}
        </div>
    );
};

export default ModalActionTitle;
