import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import AdminLock from '@reusable/AdminLock/AdminLock';
import withAutosave from '../HOC/WithAutosave';
import { NEGATIVITY_LEVELS_ORDER } from '@constants';

const NegativityLevels = (props) => {
    const handleSelectNegativityLevels = (segment, value, checked) => {
        props.handleAutosave(props.handleSelectNegativityLevels, [segment, value, checked]);
    };

    const orderedNegativityLevels = [];

    props.negativityLevels.forEach((level) => {
        const checkboxIndex = NEGATIVITY_LEVELS_ORDER.findIndex((item) => item === level);
        orderedNegativityLevels[checkboxIndex] = level;
    });

    const checkboxGroup = orderedNegativityLevels.map((value, index) => {
        const isChecked = props.negativityLevel && props.negativityLevel.length && props.negativityLevel.indexOf(value) > -1;

        return (
            <div
                className="user-preference-checkbox"
                key={index}
                data-track={`${props.fieldId}-${props.segment}-${value}`}
            >
                <input
                    id={props.fieldId + props.segment + value}
                    value={value}
                    onChange={() =>
                        handleSelectNegativityLevels(props.segment, value, !(props.negativityLevel.indexOf(value) > -1))
                    }
                    checked={isChecked}
                    name={props.fieldId + props.segment}
                    disabled={!props.userIsAdmin && props.isReadonly}
                    type="checkbox"
                    data-testid={`user-preferences-checkbox-${props.fieldId + props.segment + value}-${isChecked ? 'enabled' : 'disabled'}`}
                />
                <label htmlFor={props.fieldId + props.segment + value} className="preference-checkbox-label">
                    <FormattedMessage id={`UserPreferences.negativeTerms.negativityLevels.option.${value}`} />
                </label>
            </div>
        );
    });

    return (
        <div className="user-preferences-negativity-levels-container">
            {props.userIsAdmin ? (
                <AdminLock
                    lockName={props.lockName}
                    onClickLock={props.handleLockClick}
                    isSectionLock={true}
                    isLocked={props.isReadonly}
                />
            ) : (
                props.isReadonly && <AdminLock lockName={props.lockName} isDisabled={true} />
            )}
            <div className="user-preferences-category-container__row  container-section__segment__header">
                <h3 className="negativity-levels-container__header" data-track="negativity-levels-container__header">
                    <FormattedMessage id="UserPreferences.negativeTerms.negativityLevels" />
                </h3>
                {props.autoSaveMessage}
            </div>
            <div className="negativity-levels-container__explanation">
                <FormattedMessage id={'UserPreferences.negativeTerms.negativityLevels.explanation'} />
            </div>
            <div
                className="negativity-levels-container__checkbox-group"
                data-track="negativity-levels-container__checkbox-group"
            >
                {checkboxGroup}
            </div>
        </div>
    );
};

NegativityLevels.defaultProps = {
    fieldId: 'negativityLevels',
};

NegativityLevels.propTypes = {
    fieldId: PropTypes.string,
    segment: PropTypes.string,
    handleSelectNegativityLevels: PropTypes.func,
    negativityLevel: PropTypes.array,
    isReadonly: PropTypes.bool,
    adminLock: PropTypes.bool,
};

export default withAutosave()(NegativityLevels);
