import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { ReactComponent as BannerInfoIcon } from '../../../assets/icons/BannerInfoIcon.svg';
import { ReactComponent as BannerInfoIconDark } from '../../../assets/icons/BannerInfoIconDark.svg';

import EsgRatingsUtils from '@EsgRatings/EsgRatingsUtils';

const EsgRatingsDocumentHeader = (props) => {
    const isDarkMode = useSelector((state) => state.user.preferences.generalSettings.isDarkMode);
    const { dataSchema, dataSources, ratingsMethodology, logo } = EsgRatingsUtils.buildPropertiesForEsgHeader(
        props.content
    );

    return (
        <React.Fragment>
            <div className={`embedded-message informative notranslate ${isDarkMode ? 'dark-mode' : ''}`}>
                {' '}
                <div className="embedded-message__icon">
                    {isDarkMode ? <BannerInfoIconDark /> : <BannerInfoIcon />}
                </div>
                <div className="embedded-message__message">
                    <FormattedMessage id="EsgRatings.document.header" />
                </div>
            </div>
            <div className="single-article-content-logo-container">
                <span className="single-article-content-logo-powered-by">Powered By</span>
                <img
                    className="single-article-content-logo"
                    src={
                        logo ||
                        'https://content.moreover.com/eApolloRequest?data=rihd5wGTUzijHi1FD-_wG4EjqjD4ZXkfTB1W94bWsLM'
                    }
                    data-testid="single-article-logo-testid"
                    alt="logo"
                    data-track="csrhub-single-article-logo"
                />
                <span className="single-article-content-logo-consensus">Consensus ESG Rating</span>
            </div>
            <div className="esg-ratings-links">
                <a
                    target="_blank"
                    href={ratingsMethodology}
                    rel="noreferrer"
                    data-track="esg-ratings-link-ratings-methodology"
                >
                    Ratings methodology
                </a>
                <a target="_blank" href={dataSources} rel="noreferrer" data-track="esg-ratings-link-data-sources">
                    Data sources
                </a>
                <a target="_blank" href={dataSchema} rel="noreferrer" data-track="esg-ratings-link-data-schema">
                    Data schema
                </a>
            </div>
        </React.Fragment>
    );
};

export default EsgRatingsDocumentHeader;
