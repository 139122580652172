import React from 'react';
import { Link } from 'react-router';
import ReactTooltip from 'react-tooltip';
import { injectIntl, FormattedMessage } from 'react-intl';
import formatRichMessage from '@utils/formatRichMessage';
import { ROUTES } from '@constants';
import PropTypes from 'prop-types';

const ModalColumnsList = (props) => {
    const { isVisible, elementsVisibility: contentTypes, updateElementsVisibility, closeModal } = props;

    if (!isVisible) {
        return <span></span>;
    }

    const popupText = formatRichMessage({ id: 'BatchUpload.hideColumnsPopup.preferencesPopupText' });

    const someContentTypesAreDisabled = contentTypes.some(
        ({ enabledInGeneralSettings }) => enabledInGeneralSettings === false
    );

    const renderContentTypes = () => {
        return contentTypes.map((contentType, index) => {
            const { enabledInGeneralSettings, columnId, hidden, label } = contentType;
            const shouldContentTypeBeDisabled =
                index === 0 || index === contentTypes.length - 1 || !enabledInGeneralSettings;

            return (
                <div key={index} className="custom-checkbox">
                    <input
                        id={`${columnId}`}
                        disabled={shouldContentTypeBeDisabled}
                        type="checkbox"
                        name={columnId}
                        checked={!hidden || !enabledInGeneralSettings}
                        onChange={updateElementsVisibility}
                        data-testid={`checkbox-${columnId}-${
                            hidden || !enabledInGeneralSettings ? 'hidden' : 'unhidden'
                        }-testid`}
                    />
                    <label htmlFor={`${columnId}`} className="checkbox-label">
                        {label}
                    </label>
                </div>
            );
        });
    };

    return (
        <div className="popup-modal__columns-list">
            <div className="popup-modal__entity_view_content_types">
                <Link
                    className="report-button"
                    to={ROUTES.USER_PREFERENCES}
                    onClick={closeModal}
                    data-tip={popupText}
                    data-for="column-disabled-in-preferences"
                >
                    <span>
                        <FormattedMessage id="BatchUpload.hideColumnsPopup.preferencesLink" />
                    </span>
                </Link>
                {contentTypes.length !== 0 && renderContentTypes()}
            </div>
            {someContentTypesAreDisabled && (
                <ReactTooltip
                    id="column-disabled-in-preferences"
                    type="light"
                    event="mouseenter"
                    eventOff="mouseleave"
                    border={true}
                    effect="solid"
                    className="tooltips"
                    place="right"
                />
            )}
        </div>
    );
};

ModalColumnsList.propTypes = {
    isVisible: PropTypes.bool,
    elementsVisibility: PropTypes.array,
    updateElementsVisibility: PropTypes.func,
    closeModal: PropTypes.func,
};

export default injectIntl(ModalColumnsList);
