//@flow
import * as React from 'react';

import {
    ALERT_COMMENTS_MAX_CHARS,
    CATEGORY_NAMES,
    FUZZY_NAMES,
    PERSON_SEARCH,
    POST_FILTER_TYPES,
    SUGGESTED_NAMES,
} from '@constants';
import AlertDeliveryOptions from '@Alerts/components/AlertDeliveryOptions';
import AlertContent from '@Alerts/components/AlertContent';
import AlertFrequency from '@Alerts/components/AlertFrequency';
import utils from '@utils/utilities';
import { cloneDeep, union, uniq } from 'lodash';
import ConfirmationModal from '@reusable/ConfirmationModal/ConfirmationModal';
import SearchUtils, { updateNewsQueriesBasedOnNegativityLevels } from '@MainSearch/SearchUtils';
import AlertApi from '@Alerts/AlertsApi.api';
import { FormattedMessage } from 'react-intl';
import { withAppContext } from '@utils/contexts';
import categoryUtils from '@utils/categoryUtils';
import { withContentSourceFilter } from '@utils/utilities';
import { mergePostFilters } from '@MainSearch/SearchUtils';
import AlertMessage from '@reusable/AlertMessage/AlertMessage';
import type { SearchResultsType } from '@MainSearch/typeGuards/SearchResults.typeGuard';
import type { DefaultPostFilterType, PostFilterType } from '@MainSearch/typeGuards/PostFilter.typeGuard';
import type { AlertPayloadType, AlertType } from '../typeGuards/AlertTypeGuards';
import type { AdHocSearchType } from '@reusable/AdHocSearch/flow/AdHocSearch.typeGuards';
import type { ContextTypeGuard } from '@utils/flow/typeGuards/context.typeGuards';
import type { PreferencesType } from '@MainSearch/components/typeGuards/ResultsList.typeGuards';

// Semi-controlled component, initialize with alert object prop

type Props = {
    alert: AlertType,
    fuzzyNames: {
        list: Array<string | null>,
        query: string,
    },
    suggestedNames: {
        list: Array<string | null>,
        query: string,
    },
    adHocSearch: AdHocSearchType,
    searchParams: {
        isCustomFuzzy: boolean,
        prefilterQuery: string,
        query: string,
        searchType: string,
    },
    searchResults: SearchResultsType,
    context: ContextTypeGuard,
    legalSources?: Array<string>,
    contentTypes: Array<{
        name: string,
        reportOrder: number,
        value: boolean,
    }>,
    renderControls: (() => AlertPayloadType | void) => React.Node,
    legalSource?: string,
    suggestedNamesEnabled?: boolean,
    preferences: PreferencesType,
};

type State = {
    name: string,
    emails: string,
    sources: Array<string>,
    comment: string,
    postFilters: Array<PostFilterType>,
    contentLanguages: Array<string> | null,
    customNews: Array<string> | null,
    charsLeft: number,
    accessType: string,
    frequencyType: string,
    hourOfDay: number,
    dayOfMonth: number,
    dayOfWeek: number,
    attachType: string,
    deliveryType: string,
    showEmailDropdownList: boolean,
    isEmailValid: boolean,
    showSourceSelectionWarningModal: boolean,
    shouldTriggerForNoResults: boolean,
    recentEmails: Array<string | null>,
    emailErrorMessage: string,
};

class AlertForm extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
    }

    // initializing state with alert data
    state: State = {
        name: this.props.alert.name,
        emails: this.props.alert.emails,
        sources: this.props.alert.sources,
        comment: this.props.alert.comment ?? '',
        postFilters: this.props.alert.postFilters || [],
        contentLanguages: this.props.alert.contentLanguages || null,
        customNews: this.props.alert.customNews || null,
        charsLeft: ALERT_COMMENTS_MAX_CHARS - (this.props.alert.comment?.length ?? 0),
        accessType: this.props.alert.accessType,
        frequencyType: this.props.alert.frequencyType,
        hourOfDay: this.props.alert.hourOfDay,
        dayOfMonth: this.props.alert.dayOfMonth,
        dayOfWeek: this.props.alert.dayOfWeek,
        attachType: this.props.alert.attachType,
        deliveryType: this.props.alert.deliveryType,
        showEmailDropdownList: false,
        isEmailValid: true,
        showSourceSelectionWarningModal: false,
        shouldTriggerForNoResults: this.props.alert.shouldTriggerForNoResults,
        recentEmails: [],
        emailErrorMessage: '',
    };
    commaEmailsListIntervalId: TimeoutID;
    emailsListIntervalId: TimeoutID;

    async UNSAFE_componentWillMount() {
        await this.getRecentEmails();
    }

    componentWillUnmount() {
        clearTimeout(this.emailsListIntervalId);
        clearTimeout(this.commaEmailsListIntervalId);
    }

    getRecentEmails: () => Promise<any> = async () => {
        const [emails: Array<string>, error: Error] = await AlertApi.getRecentEmails();
        if (emails) {
            this.setState({
                recentEmails: emails,
            });
        }

        if (error) {
            console.error('unable to retrieve recent emails');
        }
    };

    handleSources: (sources: Array<string>) => void = (sources) => {
        const selectedSources = uniq(sources);
        if (selectedSources.length === 0) {
            this.handleShowModal();
        } else {
            this.setState({ sources: selectedSources });
        }
    };

    handleSelectContentLanguages: (subSources: Array<string>) => void = (subSources) => {
        this.setState({ contentLanguages: subSources });
    };

    handleDropdownChange: (event: SyntheticInputEvent<HTMLSelectElement>, section: string) => void = (
        event,
        section
    ) => {
        this.setState({
            [section]: event.target.value,
        });
    };

    handleAlertNameChange: (event: SyntheticInputEvent<HTMLInputElement>) => void = (event) => {
        this.setState({
            name: event.target.value,
        });
    };

    handleCommentChange: (event: SyntheticInputEvent<HTMLInputElement>) => void = (event) => {
        const maxChars: number = ALERT_COMMENTS_MAX_CHARS;
        const textInput: string = event.target.value;
        const charsLeft: number = maxChars - textInput.length;

        if (charsLeft > 0) {
            this.setState({
                comment: textInput,
                charsLeft: maxChars - textInput.length,
            });
        } else {
            let comment = textInput.substring(0, ALERT_COMMENTS_MAX_CHARS);
            this.setState({
                comment: comment,
                charsLeft: maxChars - (comment?.length ?? 0),
            });
        }
    };

    handleEmailAddressChange: (event: SyntheticInputEvent<HTMLInputElement>) => void = (event) => {
        const validationState: { isValid: boolean, errorMessage: string } = utils.validateEmail(event.target.value);
        this.setState({
            emails: event.target.value,
            isEmailValid: validationState.isValid,
            emailErrorMessage: validationState.errorMessage,
        });

        if (event.target.value.length === 0) {
            this.setState({
                showEmailDropdownList: true,
            });
        } else {
            this.setState({
                showEmailDropdownList: false,
            });
        }
    };

    handleEmailKeyPress: (event: SyntheticKeyboardEvent<HTMLInputElement>) => void = (event) => {
        if (event.key === ',') {
            this.commaEmailsListIntervalId = setTimeout(
                function () {
                    this.setState({
                        showEmailDropdownList: true,
                    });
                }.bind(this),
                200
            );
        }
    };

    handleCommentKeyPress: (event: SyntheticInputEvent<HTMLInputElement>) => boolean = (event) => {
        const maxChars: number = ALERT_COMMENTS_MAX_CHARS;
        const textInput: string = event.target.value;
        const charsLeft: number = maxChars - textInput.length;

        return charsLeft < 0;
    };

    handleNoResultsToggle: () => void = () => {
        this.setState((prevState) => ({
            shouldTriggerForNoResults: !prevState.shouldTriggerForNoResults,
        }));
    };

    chooseFromRecentEmails: (email: string) => void = (email) => {
        const emails: string = this.state.emails + email;
        const validationState: { isValid: boolean, errorMessage: string } = utils.validateEmail(emails);
        this.setState({
            emails,
            isEmailValid: validationState.isValid,
            emailErrorMessage: validationState.errorMessage,
            showEmailDropdownList: false,
        });
    };

    closeModal: () => void = () => {
        this.setState({
            showSourceSelectionWarningModal: false,
        });
    };

    showEmailList: () => void = () => {
        this.setState({
            showEmailDropdownList: true,
        });
    };

    handleShowModal: () => void = () => {
        this.setState({
            showSourceSelectionWarningModal: true,
        });
    };

    hideEmailList: () => void = () => {
        this.emailsListIntervalId = setTimeout(
            function () {
                this.setState({
                    showEmailDropdownList: false,
                });
            }.bind(this),
            200
        );
    };

    buildPostFilters: (
        alert: AlertPayloadType,
        searchResults: SearchResultsType,
        sources: Array<string>
    ) => Array<PostFilterType> = (alert, searchResults, sources) => {
        const postFilters = cloneDeep(alert.postFilters);
        let postFilter: PostFilterType = {};
        const { searchQueryType, searchQuery } = alert;
        const defaultPostFilter: DefaultPostFilterType = SearchUtils.generateDefaultPostFilter(
            searchQuery,
            searchQueryType
        );

        sources.forEach((categoryName) => {
            if (postFilters) {
                postFilter = postFilters.find((entry) => entry.category === categoryName);
            } else {
                //build a new postFilter based on defaults and other setting currently in alert object
                //this is for alerts that not contain postfilters for specific category
                const defaultPostFilterForCategory = { ...defaultPostFilter };
                postFilter = mergePostFilters(
                    null,
                    defaultPostFilterForCategory,
                    categoryName,
                    POST_FILTER_TYPES,
                    searchQuery
                );
                postFilter.category = categoryName;
                postFilter[FUZZY_NAMES] = alert.fuzzyNames;
                postFilter[SUGGESTED_NAMES] = alert.suggestedNames;
                postFilter.isCustomFuzzy = alert.isCustomFuzzy;
                postFilter.prefilterQuery = alert.prefilterQuery;

                postFilter = withContentSourceFilter().extendConfig(categoryName, postFilter);
                postFilters.push(postFilter);
            }
        });

        return postFilters.map((postFilter) => {
            if (postFilter && postFilter.negativityLevels && postFilter.negativityLevels.length) {
                // Update news queries based on negativity levels
                postFilter = updateNewsQueriesBasedOnNegativityLevels(postFilter);
            } else if (postFilter.category === CATEGORY_NAMES.NEGATIVE_NEWS) {
                postFilter.negativityLevels =
                    searchQueryType === PERSON_SEARCH
                        ? this.props.preferences.personCheck.negativityLevel
                        : this.props.preferences.companyCheck.negativityLevel;
            }

            return postFilter;
        });
    };

    getCustomFuzzyFromSavedPostfilters: (alert: AlertType) => Array<string | null> = (alert) => {
        let fuzzyNames = [];
        if (alert.fuzzyNames && alert.fuzzyNames.length > 0) {
            fuzzyNames = alert.fuzzyNames;
        }
        alert.postFilters.forEach((item) => {
            fuzzyNames = union(fuzzyNames, item.fuzzyNames || []);
        });
        return fuzzyNames;
    };

    createAlertObject: () => AlertPayloadType | void = () => {
        if (this.validateForm()) {
            const emails = utils.splitStringByComma(this.state.emails);

            const contentTypes = uniq(
                this.state.sources.map((source) =>
                    categoryUtils.isExtended(source) ? categoryUtils.getParent(source) : source
                )
            );
            const originalContentTypes = uniq(
                this.props.alert.originalSources.map((source) =>
                    categoryUtils.isExtended(source) ? categoryUtils.getParent(source) : source
                )
            );

            const customNews: Array<string> | null =
                originalContentTypes.indexOf(CATEGORY_NAMES.CUSTOM_NEWS) > -1 ? this.state.customNews : [];

            let suggestedNames: Array<string | null> = [];
            if (
                utils.isPersonSearch(this.props.alert.searchQueryType) ||
                utils.isPersonSearch(this.props.searchParams.searchType)
            ) {
                if (this.props.suggestedNamesEnabled) {
                    suggestedNames =
                        this.props.alert.suggestedNames || utils.getFuzzyNameList(this.props.suggestedNames);
                }
            }

            const alert: AlertPayloadType = {
                accessType: this.state.accessType,
                name: utils.sanitizeSearchStringInput(this.state.name),
                recipientData: {
                    emails: emails,
                    comments: this.state.comment,
                    deliveryType: this.state.deliveryType,
                    attachType: this.state.attachType,
                },
                scheduleMeta: {
                    frequency: this.state.frequencyType,
                    dayOfWeek: this.state.dayOfWeek,
                    hourOfDay: this.state.hourOfDay,
                    dayOfMonth: this.state.dayOfMonth,
                },
                shouldTriggerForNoResults: this.state.shouldTriggerForNoResults,
                searchQuery: this.props.alert.searchQuery || utils.sanitizeSearchStringInput(this.props.searchParams.query),
                searchQueryType: this.props.alert.searchQueryType || this.props.searchParams.searchType,
                prefilterQuery: this.props.alert.prefilterQuery || this.props.searchParams.prefilterQuery,
                isCustomFuzzy: this.props.alert.isCustomFuzzy,
                fuzzyNames: this.props.alert.fuzzyNames || utils.getFuzzyNameList(this.props.fuzzyNames),
                suggestedNames: suggestedNames,
                contentLanguages: this.state.contentLanguages,
                customNews,
                contentTypes,
                postFilters: this.props.alert.postFilters,
                active: !!this.props.alert.active,
            };
            alert.postFilters = this.buildPostFilters(alert, this.props.searchResults, contentTypes);
            return alert;
        }
    };

    validateForm: () => boolean = () => {
        let isValid: boolean = true;
        let emailValidationState: { isValid: boolean, errorMessage: string } = utils.validateEmail(this.state.emails);
        if (!emailValidationState.isValid) {
            this.setState({
                isEmailValid: false,
                emailErrorMessage: emailValidationState.errorMessage,
            });
            isValid = false;
            window.scrollTo(0, 0);
        }
        return !!this.state.name && isValid;
    };

    render(): React.Node {
        const buttons = [
            {
                text: 'OK',
                primary: true,
                click: this.closeModal,
            },
        ];

        const isAnonymized = this.props.context && this.props.context.isUserAnonymized;

        return (
            <div className="create-alert-wrapper_body" data-testid="create-alert-wrapper_body">
                {this.state.showSourceSelectionWarningModal && (
                    <ConfirmationModal closeButtonHandler={this.closeModal} buttons={buttons}>
                        <h2 className="modal-header">
                            <FormattedMessage id="UserPreferences.popup.sourceSelection" />
                        </h2>
                        <div className="modal-description font-body">
                            <FormattedMessage id="Alerts.alertForm.sourceSelection.warning" />
                        </div>
                    </ConfirmationModal>
                )}
                <AlertDeliveryOptions
                    name={this.state.name}
                    emailErrorMessage={this.state.emailErrorMessage}
                    isEmailValid={this.state.isEmailValid}
                    comment={this.state.comment}
                    charsLeft={this.state.charsLeft}
                    recentEmails={this.state.recentEmails}
                    showEmailDropdownList={this.state.showEmailDropdownList && !isAnonymized}
                    emails={this.state.emails}
                    showEmailList={this.showEmailList}
                    hideEmailList={this.hideEmailList}
                    handleAlertNameChange={this.handleAlertNameChange}
                    handleCommentChange={this.handleCommentChange}
                    handleCommentKeyPress={this.handleCommentKeyPress}
                    handleEmailAddressChange={this.handleEmailAddressChange}
                    handleEmailKeyPress={this.handleEmailKeyPress}
                    chooseFromRecentEmails={this.chooseFromRecentEmails}
                />
                <div className="horizontal-divider" />
                <AlertContent
                    sources={this.state.sources}
                    originalSources={this.props.alert.originalSources}
                    originalContentLanguages={this.props.alert.originalContentLanguages}
                    originalLegalSources={this.props.alert.originalLegalSources}
                    onSourcesUpdate={this.handleSources}
                    handleSelectContentLanguages={this.handleSelectContentLanguages}
                    postFilters={this.state.postFilters}
                    legalSources={this.props.legalSources}
                    legalSource={this.props.legalSource}
                    contentTypes={this.props.contentTypes}
                    customNews={this.state.customNews}
                />
                <div className="horizontal-divider" />
                <AlertFrequency
                    frequencyType={this.state.frequencyType}
                    hourOfDay={this.state.hourOfDay}
                    dayOfMonth={this.state.dayOfMonth}
                    dayOfWeek={this.state.dayOfWeek}
                    deliveryType={this.state.deliveryType}
                    attachType={this.state.attachType}
                    accessType={this.state.accessType}
                    shouldTriggerForNoResults={this.state.shouldTriggerForNoResults}
                    handleDropdownChange={this.handleDropdownChange}
                    handleNoResultsToggle={this.handleNoResultsToggle}
                    shouldEnableNoResultsWarning={true}
                />

                <AlertMessage ids={['Alerts.createAlert.GDPR']} />

                {this.props.renderControls(this.createAlertObject)}
            </div>
        );
    }
}

export { AlertForm as TestAlertForm };
export default (withAppContext(AlertForm): React.AbstractComponent<Props, State>);
