import React from 'react';
import EsgRatingsDonutChart from './EsgRatingsDonutChart';
import { ESG_RATINGS_VISUALISATIONS } from '@constants';

const EsgRatingsOverall = (props) => {
    const { overallRating, overallRatingCategory, sourceCount, dateRange } = props;

    return (
        <div className="esg-ratings-overall-information" data-track="esg_ratings-overall-information">
            <EsgRatingsDonutChart
                size={ESG_RATINGS_VISUALISATIONS.OVERALL_DONUT.SIZE}
                fontSize={ESG_RATINGS_VISUALISATIONS.OVERALL_DONUT.FONT_SIZE}
                rating={overallRating}
                ratingCategory={overallRatingCategory}
            />
            <div className="esg-ratings-overall" data-track="esg_ratings-overall-container">
                <h4>OVERALL CSRHub Rating</h4>
                <div className="esg-ratings-title" data-track="esg_ratings-overall-title">
                    CSRHub Ratings give the perceived performance on an absolute 0-100 scale.
                </div>
                <div className="esg-ratings-source-date" data-track="esg_ratings-overall-source-date">
                    <span className="source-count" data-track="esg_ratings-overall-source-count">
                        {sourceCount}
                    </span>
                    <span className="date-range" data-track="esg_ratings-overall-date-range">
                        {dateRange}
                    </span>
                </div>
                <ul className="esg-ratings-legend" data-track="esg_ratings-overall-legend">
                    <li className="Rating_0">Low 0-29</li>
                    <li className="Rating_30">30-39</li>
                    <li className="Rating_40">40-49</li>
                    <li className="Rating_50">50-59</li>
                    <li className="Rating_60">60-79</li>
                    <li className="Rating_80">80-100 High</li>
                </ul>
            </div>
        </div>
    );
};

export default EsgRatingsOverall;
