export const theSearchResultCategory = (category) => (state) => state.searchResults[category];

export const theSearchType = (state) => state.searchParams.searchType;

export const theSearchQuery = (state) => state.searchParams.query;

export const thePreFilterQuery = (state) => state.searchParams.prefilterQuery;

export const theFuzzyNamesTerms = (state) => state.fuzzyNames.list.map((term) => term.name);

export const theFuzzyQuery = (state) => state.fuzzyNames.query;

export const fuzzyNames = (state) => state.fuzzyNames;

export const suggestedNames = (state) => state.suggestedNames;

export const prefilterQuery = (state) => state.searchParams.prefilterQuery;

export const thePostfilterConfiguration = (category) => (state) => state.postFilterConfiguration[category];

export const isCategoryLoaded = (category) => (state) =>
    state.searchResults[category] && state.searchResults[category].loaded;

export const isCategoryWithError = (category) => (state) =>
    state.searchResults[category] && state.searchResults[category].hasError;

export const isPublicRecordsOn = (state) => state.user.appSettings.prsOn;

export const getCostCode = (state) =>
    state.adHocSearch.costCode ? state.adHocSearch.costCode : state.user.preferences.generalSettings.selectedCostCode;

export const getUserTimezone = (state) => state.user.timezone;

export const getUserPermId = (state) => state.user.userPermId;

export const getUserCustomerClass = (state) => state.user.customerClass;

export const searchResults = (state) => state.searchResults;

export const reduxState = (state) => state;

export const theUser = (state) => state.user;
