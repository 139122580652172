import React, { Fragment } from 'react';
import hasError from '@reusable/Table/hoc/hasError';
import { hashHistory } from 'react-router';
import hasLoader from '@reusable/Table/hoc/hasLoader';
import withCounter from '@reusable/Table/hoc/withCounter';
import withPagination from '@reusable/Table/hoc/withPagination';
import withSelection from '@reusable/Table/hoc/withSelection';
import withSorting from '@reusable/Table/hoc/withSorting';
import isEmpty from '@reusable/Table/hoc/isEmpty';
import withAutoLoad from '@reusable/Table/hoc/withAutoLoad';
import composeTable from '@reusable/Table/composeTable';
import { compose } from 'redux';
import { ERRORS } from '@constants';
import { DefaultHeader, DefaultSelectableRows, DeleteBtn } from '@reusable/Table/components/defaults';
import { FormattedMessage } from 'react-intl';
import utils from '@utils/utilities';
import { AlertName, CheckNewResultsBtn, PRAlertActions, MyAlertsEmpty } from '@ManageAlerts/components/misc';
import withConfirmationModal from '@reusable/Modal/hoc/withConfirmationModal';

/**
 * This is the Table Consumer component for MyAlerts
 * @type {any | *}
 */
const Table = compose(
    // Composing the functionalities
    hasError(/* Default error component */),
    withAutoLoad,
    withSelection,
    withCounter,
    withSorting,
    withPagination
)(
    // Now composing the table
    composeTable(
        DefaultHeader,
        compose(
            // Adding a loader when is loading and
            hasLoader(/* Default loader component */),
            isEmpty(MyAlertsEmpty)
        )(DefaultSelectableRows)
    )
);

/**
 * This is the main Alerts Consumer table
 * @param props
 * @returns {XML}
 * @constructor
 */
const PRAlertsTableConsumer = (props) => {
    const transformRow = (object) => ({
        key: object.id,
        columns: {
            name: <AlertName alert={object} />,
            lastRun: object.lastUpdateAsDate ? utils.formatDateWithHoursAndMinutes(object.lastUpdateAsDate) : '',
            checkResults: <CheckNewResultsBtn alert={object} onClick={handleCheckNewPrResults} />,
            actions: <PRAlertActions object={object} onEdit={handleEditPRAlert} onDelete={handleDeleteAlert} />,
        },
        object,
    });

    const getSelectedAlertObjects = () => props.selection.selectedItems.map((selectedItem) => selectedItem.object);

    const handleCheckNewPrResults = (alert) => {
        let path = '/public-records?param=' + btoa(alert.newResultsUrl);
        hashHistory.push(path);
    };

    const handleEditPRAlert = (alert) => {
        let path = '/public-records?param=' + btoa(alert.editUrl);
        hashHistory.push(path);
    };

    const handleDeleteAlert = (alert) => {
        const modalProps = {
            primaryBtnText: 'General_CoreFunctionality_UIText_general.delete',
            title: <FormattedMessage id={'PopupBuilder.title.deleteAlert'} />,
            description: <FormattedMessage id={'ManageAlerts.alertDeletion'} values={{ alert: alert.name }} />,
        };
        props.onConfirmModal(modalProps, () => {
            // removes item from selection if exists
            props.selection.onChangeSelection(props.selection.selectedItems.filter((item) => item.key !== alert.id));
            props.data.onChangeLoadState(true);
            props.alerts
                .onAlertsDelete([alert])
                .then(() => {
                    if (props.data.rows.length === 1 && props.pagination.pageNumber > 0) {
                        props.data.onChangeLoadState(false, () => {
                            props.onChangePagination({ pageNumber: props.pagination.pageNumber - 1 }, handleLoadData);
                        });
                    } else {
                        props.data.onChangeLoadState(false, handleLoadData);
                    }
                })
                .catch(() => {
                    utils.showNotificationsMessage({
                        messageText: 'ManageAlerts.ErrorMessage.deleteAlert',
                        messageType: 'system-error',
                    });
                    props.data.onChangeLoadState(false);
                });
        });
    };

    const handleBulkDelete = () => {
        const modalProps = {
            primaryBtnText: 'General_CoreFunctionality_UIText_general.delete',
            title: <FormattedMessage id={'PopupBuilder.title.deleteAlerts'} />,
            description: (
                <FormattedMessage
                    id={'ManageAlerts.multipleAlertDeletion'}
                    values={{ alertsCount: getSelectedAlertObjects().length }}
                />
            ),
        };
        props.onConfirmModal(modalProps, () => {
            props.data.onChangeLoadState(true);
            props.alerts
                .onAlertsDelete(getSelectedAlertObjects())
                .then(() => {
                    props.data.onChangeLoadState(false, props.data.onResetState);
                })
                .catch(() => {
                    utils.showNotificationsMessage({
                        messageText: 'ManageAlerts.ErrorMessage.deleteAlerts',
                        messageType: 'system-error',
                    });
                    props.data.onChangeLoadState(false);
                });
        });
    };

    const areAlertsSelected = () => props.selection.selectedItems.length > 0;
    const areAlertsUpdating = () => props.data.isLoading;

    const actions = (
        <Fragment>
            <DeleteBtn onClick={handleBulkDelete} disabled={!areAlertsSelected() || areAlertsUpdating()} />
        </Fragment>
    );

    const headers = [
        { columnKey: 'name', label: <FormattedMessage id="ManageAlertsTableHeader.alertName" /> },
        { columnKey: 'lastRun', label: <FormattedMessage id="ManageAlertsTableHeader.lastRun" /> },
        { columnKey: 'checkResults', label: null },
        { columnKey: 'actions', label: null },
    ];

    // adds specific error message when we cant get the alerts
    const handleLoadData = () => {
        return props.data.onLoadData().catch((e) => {
            if (e === ERRORS.TABLE_LOAD_DATA_GET) {
                utils.showNotificationsMessage({
                    messageText: 'ManageAlerts.ErrorMessage.getAlerts',
                    messageType: 'system-error',
                });
            }
        });
    };

    return (
        <div className={'generic-table alerts-table PRAlerts-table'}>
            <Table
                {...props}
                headers={headers}
                data={{
                    ...props.data,
                    rows: props.data.rows && props.data.rows.map(transformRow),
                    onLoadData: handleLoadData,
                }}
                onTransformRow={transformRow}
                selection={{
                    ...props.selection,
                    actions,
                }}
            />
        </div>
    );
};

export default withConfirmationModal(PRAlertsTableConsumer);
