import React from 'react';
import { FormattedMessage } from 'react-intl';
import { MODAL_TYPE } from '@constants';
import { withHideForCombinedReports } from '@ReportBuilder/HOC/withReportHoc';

const AdditionalActions = ({ handleDeleteReport, id, title, index, openOrderCategoriesSection, reportType }) => {
    return (
        <div className="reports-list-row-actions">
            <DeleteReport onDelete={handleDeleteReport} id={id} title={title} index={index} />
            <ToggledReorderedButton
                id={id}
                index={index}
                openOrderCategoriesSection={openOrderCategoriesSection}
                reportType={reportType}
            />
        </div>
    );
};

const DeleteReport = ({ index, id, title, onDelete }) => {
    return (
        <div className="report-list-action-button">
            <span className="la-Delete" />
            <span>
                <button
                    className="report-list-action-button_delete"
                    value={MODAL_TYPE.DELETE_REPORT}
                    id={`qa-report-delete_${index}`}
                    onClick={(e) => {
                        e.stopPropagation();
                        onDelete(id, title);
                    }}
                    data-track={`delete-button-report-${index}`}
                >
                    <FormattedMessage id="General_CoreFunctionality_UIText_general.delete" />
                </button>
            </span>
        </div>
    );
};

const ReorderButton = ({ openOrderCategoriesSection, id, index }) => {
    return (
        <div className="report-list-action-button">
            <span className="la-NavigateTerms" />
            <span>
                <button
                    className="report-list-action-button_reorder"
                    onClick={openOrderCategoriesSection}
                    value={id}
                    data-track={`reorder-button-report-${index}`}
                >
                    <FormattedMessage id="Report.reorderReport" />
                </button>
            </span>
        </div>
    );
};

const ToggledReorderedButton = withHideForCombinedReports(ReorderButton);

export default AdditionalActions;
