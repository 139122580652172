import { all, takeLatest, takeEvery } from 'redux-saga/effects';
import * as flows from '@flows/metricFlows';
import { ACTIONS } from '../constants/metricConstants';

/**
 * Listener that will wait for METRIC_SEARCH_STARTED action and then launch flow
 * The action is triggered every time a search is run
 */
export function* watchSearchStarted() {
    yield all([takeLatest(ACTIONS.metricSearchStarted, flows.startSearch)]);
}

/**
 * Listener that will wait for METRIC_SEARCH_END action and then launch flow
 * The action is triggered every time a search is ended (snapshot)
 */
export function* watchSearchEnd() {
    yield all([takeEvery(ACTIONS.metricSearchEnd, flows.endSearch)]);
}

/**
 * Listener that will wait for METRIC_GATHER action and then launch flow
 * The action is triggered every time a search is complete
 * A search is complete when the regular categories promise is done or when UBO is complete
 */
export function* watchGatherMetrics() {
    yield all([takeLatest(ACTIONS.metricGather, flows.gatherMetrics)]);
}
