// @flow
import * as React from 'react';
import withSelection from '@reusable/Table/hoc/withSelection';
import { FormattedMessage } from 'react-intl';
import { DEFAULT_MESSAGES } from '@reusable/Table/components/SelectedItemsDropdown';
import { cloneDeep } from 'lodash';
import GeneralReportWrapper from './GeneralReportWrapper';
import type {
    Report,
    CurrentReport,
    CombinedReportType,
    Snippet,
    ExpandedRegularReportType,
    CategoryDeleteRequest,
} from '@ReportBuilder/redux/flow/ReportBuilder.type.guards';
import type { ContentOptions } from '@reusable/PopupModal/Components/ModalContentOptions';
import utils from '@utils/utilities';

type Props = {
    selectedReport: Report,
    discardScrollToReport: () => void,
    data: {
        rows: Array<Report>,
    },
    language: string,
    loadedReports: number,
    totalReportsCount: number,
    lazyLoadArticlesForReport: (report: Report) => void,
    openEmailModal: () => void,
    openPrintModal: () => void,
    openDownloadModal: () => void,
    report: Report,
    updateReportProperty: (reportId: string, propertyName: string, propertyValue: mixed) => void,
    updateSnippetsInCurrentReport: ({
        currentReport: Report,
        snippets: Array<Snippet>,
    }) => void,
    discardSelectedReport: (reportId: string) => void,
    addNoteToReport: (reportId: string, note: string) => void,
    reports: Array<Report>,
    currentReport: CurrentReport,
    setSelectedReport: (reportId: string) => void,
    contentsOptions: Array<ContentOptions>,
    emailAddress: string,
    showDeliveryOptions: boolean,
    updateProperty: (reportId: string, propertyName: string, propertyValue: mixed) => void,
    updateReportDeliveredStatus: (reportId: string) => void,
    reportDeliveryAvailabilityDays: number,
    prReportDeliveryAvailabilityDays: number,
    onDelete: (reportId: string, reportTitle: string) => void,
    onCategoryDelete: (categoryDeleteRequest: CategoryDeleteRequest) => void,
    onSelectionChange: (remainingItems: Array<Report>) => void,
    onUpdateReportBuilderPage: (reportId: string) => void,
    getConnectedReports: (report: Report) => void,
    jumpToReport: (reportId: string) => void,
    removeIncludedReport: (childReportId: string, combinedReportId: CombinedReportType) => void,
    onToggleSelect: (row: Report) => void,
    getDocumentsCountPerCategory: (report: ExpandedRegularReportType | CombinedReportType) => void,
    getBatchReportEntities: (report: Report) => void,
    batchReportsPooling: Array<string>,
};

type State = {
    modifiedSections: Array<string>,
    autosaveHasError: boolean,
};

class ReportsList extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
    }

    state: State = {
        modifiedSections: [],
        autosaveHasError: false,
    };

    tables: Array<{
        id: string,
        el: null | React$ElementRef<'div'>,
    }> = [];

    handleScrollToReport() {
        let elem = '';
        const selectedReport = this.props.selectedReport;

        if (selectedReport && selectedReport.id) {
            elem = this.tables.find((item) => {
                return item.id === selectedReport.id;
            });
            if (elem) {
                //@todo add height calculation based on sticky+notification
                const positionToScroll = elem.el ? elem.el.offsetTop - 130 : 0;
                window.scrollTo(0, positionToScroll);
                this.props.discardScrollToReport();
            }
        }
    }

    componentDidUpdate() {
        this.handleScrollToReport();
    }

    //@TODO I think auto-save should be in the report wrapper since it report based
    showAutosaveMessage: any = (autosavedSectionId: string) => {
        this.setState((state: State) => {
            return {
                modifiedSections: [...state.modifiedSections, autosavedSectionId],
            };
        });
    };

    //@TODO I think auto-save should be in the report wrapper since it report based
    removeAutosaveMessage: any = (hasError: boolean, autosavedSectionId: string) => {
        let sections = cloneDeep(this.state.modifiedSections);
        const index = sections.indexOf(autosavedSectionId);

        if (index > -1) {
            sections.splice(index, 1);
        }
        this.setState({
            autosaveHasError: hasError,
            modifiedSections: sections,
        });
    };

    render(): React.Node {
        const reports = this.props.data.rows;
        this.tables = [];

        return (
            <div className="reports-list-wrapper">
                <div className="reports-list-columns">
                    <div className="reports-list-header-column__title">
                        <FormattedMessage
                            id={'LoadMoreReports.viewingReports'}
                            values={{
                                loadedReports: utils.formatNumbersByUserLocale(
                                    this.props.language,
                                    this.props.loadedReports
                                ),
                                totalReportsCount: utils.formatNumbersByUserLocale(
                                    this.props.language,
                                    this.props.totalReportsCount
                                ),
                            }}
                        />
                    </div>
                    <div className="reports-list-header-column__update">
                        <FormattedMessage id="ReportBuilderPage_reportColumns.lastUpdated" />
                    </div>
                </div>
                <ol className="reports-list-main">
                    {reports.map((report, index) => (
                        <div
                            key={report.id}
                            ref={(el) => {
                                if (el) {
                                    this.tables.push({ id: report.id, el: el });
                                }
                            }}
                        >
                            <GeneralReportWrapper
                                reportType={report.deliveryType}
                                report={report}
                                language={this.props.language}
                                lazyLoadArticlesForReport={this.props.lazyLoadArticlesForReport}
                                getBatchReportEntities={this.props.getBatchReportEntities}
                                index={index}
                                openEmailModal={this.props.openEmailModal}
                                openPrintModal={this.props.openPrintModal}
                                openDownloadModal={this.props.openDownloadModal}
                                editMode={
                                    !(this.props.report && this.props.report.note && this.props.report.note.length > 0)
                                }
                                updateReportProperty={this.props.updateReportProperty}
                                updateSnippetsInCurrentReport={this.props.updateSnippetsInCurrentReport}
                                discardSelectedReport={this.props.discardSelectedReport}
                                addNoteToReport={this.props.addNoteToReport}
                                reports={this.props.reports}
                                currentReport={this.props.currentReport}
                                setSelectedReport={this.props.setSelectedReport}
                                contentsOptions={this.props.contentsOptions}
                                showDeliveryOptions={this.props.showDeliveryOptions}
                                updateProperty={this.props.updateProperty}
                                emailAddress={this.props.emailAddress}
                                updateReportDeliveredStatus={this.props.updateReportDeliveredStatus}
                                reportDeliveryAvailabilityDays={this.props.reportDeliveryAvailabilityDays}
                                prReportDeliveryAvailabilityDays={this.props.prReportDeliveryAvailabilityDays}
                                onDelete={this.props.onDelete}
                                onCategoryDelete={this.props.onCategoryDelete}
                                onSelectionChange={this.props.onSelectionChange}
                                onUpdateReportBuilderPage={this.props.onUpdateReportBuilderPage}
                                getConnectedReports={this.props.getConnectedReports}
                                getDocumentsCountPerCategory={this.props.getDocumentsCountPerCategory}
                                jumpToReport={this.props.jumpToReport}
                                removeIncludedReport={this.props.removeIncludedReport}
                                batchReportsPooling={this.props.batchReportsPooling}
                                // For selection
                                onToggleSelect={this.props.onToggleSelect}
                                row={report}
                                // for autosave
                                showAutosaveMessage={this.showAutosaveMessage}
                                removeAutosaveMessage={this.removeAutosaveMessage}
                                modifiedSections={this.state.modifiedSections}
                                autosaveHasError={this.state.autosaveHasError}
                            />
                        </div>
                    ))}
                </ol>
            </div>
        );
    }
}

// overriding the message in dropdown for the report builder
const selectionConfig = {
    dropdownMessages: {
        ...DEFAULT_MESSAGES,
        myItems: 'BREADCRUMBS.reportBuilder',
        noSelectedItems: 'Report.DocumentItem.noReportsSelected',
    },
};

export default (withSelection(ReportsList, selectionConfig): React.AbstractComponent<Props, State>);
export { ReportsList as TestReportsList };
