import React from 'react';
import { hashHistory, withRouter } from 'react-router';
import BreadCrumbsModel from '@utils/breadCrumbBuilder';
import {
    BREADCRUMBS,
    CATEGORY_NAMES,
    EDIT_ALERT_DELIVERY_PAGE_TITLE,
    FUZZY_NAMES,
    POST_FILTER_DATE_RANGE,
    POST_FILTER_TYPES,
} from '@constants';
import breadcrumbsActions from '@reusable/Breadcrumbs/redux/Breadcrumbs.actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AlertApi from './AlertsApi.api';
import SaveAlertButton from './components/SaveAlertButton';
import AlertForm from './components/AlertForm';
import { withAlertRouteToggle } from '@utils/contexts';
import categoryUtils from '@utils/categoryUtils';
import utils, { withContentSourceFilter } from '@utils/utilities';
import { FormattedMessage } from 'react-intl';
import { cloneDeep, intersection, union } from 'lodash';
import SearchUtils, { mergePostFilters } from '@MainSearch/SearchUtils';
import errorUtils from '@utils/errors/error-utils';
import AlertUtils from '@utils/alertsUtils';

class EditAlert extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            alert: null,
        };
    }

    // TODO: Set correct breadcrumbs
    setBreadcrumbs() {
        let query = this.props.location.query;

        let breadcrumbs = new BreadCrumbsModel.Builder(BREADCRUMBS.EDIT_ALERT)
            .setProperty('category', query.category)
            .setProperty('searchQuery', query.searchQuery)
            .setProperty('searchQueryType', this.props.searchParams.searchType)
            .build().breadcrumbs;
        this.props.updateBreadcrumbs(breadcrumbs);
    }

    getAttributeFromSavedPostfilters = (alert, attribute) => {
        let values = [];
        if (alert[attribute] && alert[attribute].length > 0) {
            values = alert[attribute];
        }
        alert.postFilters.forEach((item) => {
            values = union(values, item[attribute] || []);
        });
        return values;
    };

    UNSAFE_componentWillMount() {
        this.setBreadcrumbs();
    }

    componentDidMount() {
        document.title = EDIT_ALERT_DELIVERY_PAGE_TITLE;
        window.scrollTo(0, 0);
        AlertApi.getAlert(this.props.params.alertId)
            .then((alert) => {
                let extendedSources = alert.contentTypes.filter((type) => categoryUtils.hasExtendedChildren(type));
                let sources = alert.contentTypes.filter((type) => AlertUtils.isChildAndIsAllowedForAlert(type));

                // adds for the removed parent all the children depending on the config (ex: alert.contentLanguages or alert.customNews)
                extendedSources.forEach((parentSource) => {
                    let keys = categoryUtils.getConfigKeys(parentSource);
                    if (keys) {
                        sources.push(
                            ...categoryUtils.getAvailableSubcategories(
                                parentSource,
                                alert[keys.configKey],
                                keys.matchConfigKey
                            )
                        );
                    } else {
                        console.warn('Please provide the children key from config');
                    }
                });
                let fuzzyNames = this.getAttributeFromSavedPostfilters(alert);
                let suggestedNames = [];
                if (utils.isPersonSearch(alert.searchQueryType)) {
                    if (this.props.suggestedNamesEnabled) {
                        suggestedNames = alert.suggestedNames || utils.getFuzzyNameList(this.props.suggestedNames);
                    }
                }
                let postFilters = this.buildPostFilters(alert, this.props.searchResults, fuzzyNames, sources);
                const originalContentLanguages = postFilters
                    .filter(
                        (postFilter) =>
                            postFilter.category === CATEGORY_NAMES.NEGATIVE_NEWS &&
                            postFilter.category !== CATEGORY_NAMES.PUBLIC_RECORDS
                    )
                    .map((postFilter) => postFilter.contentLanguage);

                const originalLegalSources = postFilters
                    .filter(
                        (postFilter) =>
                            categoryUtils.isLegalSource(postFilter.category) &&
                            !categoryUtils.isDockets(postFilter.category)
                    )
                    .map((postFilter) => postFilter.category);

                this.setState({
                    alert: {
                        name: alert.name,
                        active: alert.active,
                        emails: alert.recipientData.emails.join(', '),
                        sources: sources,
                        // retrieves original sources from postfilters
                        originalSources: postFilters.map((postFilter) =>
                            categoryUtils.postfilterToCategory(postFilter)
                        ),
                        isCustomFuzzy: alert.isCustomFuzzy,
                        comment: alert.recipientData.comments,
                        contentLanguages: alert.contentLanguages || [],
                        originalContentLanguages,
                        originalLegalSources,
                        customNews: alert.customNews || [],
                        postFilters: postFilters.filter(
                            (postFilter) =>
                                postFilter.category !== CATEGORY_NAMES.PUBLIC_RECORDS &&
                                !categoryUtils.isDockets(postFilter.category)
                        ),
                        accessType: alert.accessType,
                        frequencyType: alert.scheduleMeta.frequency,
                        hourOfDay: alert.scheduleMeta.hourOfDay,
                        dayOfMonth: alert.scheduleMeta.dayOfMonth,
                        dayOfWeek: alert.scheduleMeta.dayOfWeek,
                        attachType: alert.recipientData.attachType,
                        deliveryType: alert.recipientData.deliveryType,
                        searchQueryType: alert.searchQueryType,
                        searchQuery: alert.searchQuery,
                        fuzzyNames: fuzzyNames,
                        suggestedNames: suggestedNames,
                        prefilterQuery: alert.prefilterQuery,
                        shouldTriggerForNoResults: !!alert.shouldTriggerForNoResults,
                    },
                });

                return alert;
            })
            .catch((error) => {
                errorUtils.logError({ error });
                console.error('Cannot find alert', error);
                hashHistory.push('/manage-alerts');
            });
    }

    buildPostFilters(alert, searchResults, previousFuzzyNames, sources) {
        let postFilters = cloneDeep(alert.postFilters);
        let postFilter = {};
        let { searchQueryType, searchQuery } = alert;
        let defaultPostFilter = SearchUtils.generateDefaultPostFilter(searchQuery, searchQueryType);
        sources.forEach((categoryName) => {
            if (postFilters) {
                postFilter = postFilters.find((postFilterItem) => {
                    return postFilterItem.category === categoryName;
                });
            }
            if (!postFilter) {
                //build a new postFilter based on defaults and other setting currently in alert object
                //this is for alerts that not contain postfilters for specific category
                let defaultPostFilterForCategory = { ...defaultPostFilter };
                defaultPostFilterForCategory[POST_FILTER_DATE_RANGE] = defaultPostFilter.dateRange[categoryName];
                postFilter = mergePostFilters(
                    null,
                    defaultPostFilterForCategory,
                    categoryName,
                    POST_FILTER_TYPES,
                    searchQuery
                );
                postFilter.category = categoryName;
                postFilter[FUZZY_NAMES] = previousFuzzyNames;
                postFilter.isCustomFuzzy = alert.isCustomFuzzy;

                postFilter = withContentSourceFilter().extendConfig(categoryName, postFilter, this.props.state);
                postFilters[categoryName] = postFilter;
            }
        });

        return postFilters;
    }

    hasCheckedSubCategories = (sources) => {
        return intersection(this.state.alert.sources, sources).length > 0;
    };

    render() {
        return (
            <div className="create-alert-wrapper">
                <div className="create-alert-wrapper_header">
                    <h2>
                        <FormattedMessage id={'BREADCRUMBS.editAlert'} />{' '}
                    </h2>
                </div>
                {this.state.alert && (
                    <AlertForm
                        {...this.props}
                        alert={this.state.alert}
                        // overriding contentTypes prop to check/uncheck values based on alert sources
                        contentTypes={this.props.contentTypes.map((contentType) => ({
                            ...contentType,
                            value:
                                this.state.alert.sources.indexOf(contentType.name) > -1 ||
                                this.hasCheckedSubCategories(categoryUtils.getCategoryKeys(contentType.name)),
                        }))}
                        // controls can be replaced
                        renderControls={(createAlertObject) => (
                            <SaveEditControls
                                createAlertObject={createAlertObject}
                                alertId={this.props.params.alertId}
                            />
                        )}
                    />
                )}
            </div>
        );
    }
}

const SaveEditControls = ({ createAlertObject, alertId }) => (
    <div className="create-alert-actions">
        <SaveAlertButton createAlertObject={createAlertObject} alertId={alertId} />
        <button className="button-secondary-sm" onClick={() => hashHistory.go(-1)}>
            Cancel
        </button>
    </div>
);

const mapStateToProps = (state) => {
    return {
        selectedReport: state.reportBuilder.selectedReport,
        searchParams: state.searchParams,
        searchResults: state.searchResults,
        breadcrumbs: state.breadcrumbs,
        preferences: state.user.preferences,
        isSnapshotVisible: state.user.preferences.generalSettings.showSnapshot,
        contentTypes: state.user.preferences.generalSettings.contentTypes,
        legalSources: state.user.preferences.generalSettings.lawSources,
        legalSource: state.user.preferences.generalSettings.legalSources.defaultDateSource,
        fuzzyNames: state.fuzzyNames,
        suggestedNames: state.suggestedNames,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            updateBreadcrumbs: breadcrumbsActions.updateBreadcrumbs,
        },
        dispatch
    );
};

export { EditAlert as TestEditAlert };
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withAlertRouteToggle(EditAlert)));
