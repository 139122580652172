import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import SourcesInformationIcon from './SourcesInformationIcon';
import { TagPill } from '@reusable/Table/components/defaults';
import EditSubcategories from '@reusable/EditSubcategories/EditSubcategories';
import { EDIT_SUBCATEGORIES_DISPLAY_MODE } from '@constants';

const SourcesComponent = ({
    sources,
    handleSourceSelection,
    handleSubSourcesSelection,
    sourcesInformation,
    isReadonly,
    editSubcategoriesDisplayMode = EDIT_SUBCATEGORIES_DISPLAY_MODE.MODAL,
}) => {
    return (
        <div>
            <ul
                className="user-preferences-category-container__list"
                data-testid="sources-component-container"
                data-track="sources-component-container"
            >
                {sources?.map((source, index) => {
                    const { key, name, checked, show, disabled, hasInformationIcon, isNew, children } = source;
                    return (
                        show && (
                            <li key={index} className="user-preference-list-item" id={key}>
                                <div
                                    className="user-preference-checkbox"
                                    data-track={`user-preference-list-item-${key}`}
                                >
                                    <input
                                        id={`user-preferences-check_${key}`}
                                        type="checkbox"
                                        data-testid={`source-component-checkbox-${key}-${
                                            checked ? 'enabled' : 'disabled'
                                        }`}
                                        title={name}
                                        checked={checked === true ? 'checked' : ''}
                                        onChange={() => {
                                            handleSourceSelection(!checked, key);
                                        }}
                                        disabled={isReadonly || disabled}
                                    />
                                    <label
                                        htmlFor={`user-preferences-check_${key}`}
                                        className={
                                            isReadonly
                                                ? 'preference-checkbox-label disabled'
                                                : 'preference-checkbox-label'
                                        }
                                    >
                                        <FormattedMessage id={`Preferences_Source.${key}`} />
                                    </label>
                                    {hasInformationIcon && (
                                        <SourcesInformationIcon
                                            sourceKey={key}
                                            sourcesInformation={sourcesInformation}
                                        />
                                    )}
                                    {isNew && (
                                        <span className="sources-new-label">
                                            <TagPill />
                                        </span>
                                    )}
                                    <EditSubcategories
                                        source={source}
                                        onSave={handleSubSourcesSelection}
                                        disabled={isReadonly || disabled || !children}
                                        displayMode={editSubcategoriesDisplayMode}
                                    />
                                </div>
                            </li>
                        )
                    );
                })}
            </ul>
        </div>
    );
};

export default injectIntl(SourcesComponent);
