import React from 'react';
import { FormattedMessage } from 'react-intl';
import withAutosave from '@UserPreferences/HOC/WithAutosave';

const Heading = (props) => {
    return (
        <div className={'combined-report-content__heading-container'}>
            <div className={'combined-report-content__heading'}>
                <FormattedMessage id={'CombinedReport.expanded.heading'} />
            </div>
            {props.autoSaveMessage}
        </div>
    );
};

const SubHeading = () => {
    return (
        <div className="combined-report-content__subheading">
            <span>
                <FormattedMessage id="CombinedReport.expanded.subheading.title" />
            </span>
            <span>
                <FormattedMessage id="ReportCategoryList.documentExpiryDate" />
            </span>
        </div>
    );
};

const ReportInfoHeading = withAutosave()(Heading);

export { Heading as TestCombinedReportHeading };
export { SubHeading as TestCombinedReportSubHeading };

export { ReportInfoHeading, SubHeading };
