import React, { useEffect, useState } from 'react';
import { Loader } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import {SCREENING_ENTITY_STATUSES, COMPLETE, SNACKBAR_TIMEOUT, ERROR, ACTION_TYPES} from '@constants';
import Snackbar from 'scripts/reusable/Snackbar/Snackbar';
import snackbarUtils from './snackbarUtils';

const SnackbarWithAutohide = (props) => {
    const { id, actionType = '', processing = false, reset, messageProps = {ready: 0, total: 0, error: 0}, onClose} = props;
    const [isVisible, setIsVisible] = useState(false);
    let message, icon;

    useEffect(() => {
        processing && setIsVisible(true);
    }, [processing]);

    useEffect(() => {
        if(isVisible && !processing) {
            const timeout = setTimeout(() => {
                setIsVisible(false);
                reset();
            }, SNACKBAR_TIMEOUT)
            return () => {
                clearTimeout(timeout)
            };
        }
    }, [isVisible, processing]);

    const displaySpinner = () => (
        <div className='snackbar-spinner-content' data-testid='snackbar-spinner-content'>
            <Loader size="medium" active={true} centered="true" inline inverted />
        </div>
    );

    const handleCloseSnackbar = () => {
        setIsVisible(false);
        onClose && onClose(actionType);
    }

    const displayReadyIcon = () => (
        <div className={'la-DeliveryComplete'} data-testid='snackbar-icon-complete'/>
    );

    const displayErrorIcon = () => (
        <div className={'la-WarningIcon'} data-testid='snackbar-icon-warning'/>
    )

    if(processing) {
        message = snackbarUtils.getSnackbarMessage(actionType, SCREENING_ENTITY_STATUSES.PROCESSING);
        icon = displaySpinner();
    } else {
        if(messageProps.error > 0 && actionType === ACTION_TYPES.DELETE_ALERT) {
            message = snackbarUtils.getSnackbarMessage(actionType, ERROR)
            icon = displayErrorIcon();
        } else {
            message = snackbarUtils.getSnackbarMessage(actionType, COMPLETE);
            icon = displayReadyIcon();
        }
    };

    return(
        <div className={`snackbar-row ${isVisible ? 'snackbar-show' : ''}`} data-testid='snackbar-with-autohide'>
            {isVisible ?
                (<Snackbar
                    id={id}
                    icon={icon}
                    message={(           
                        <FormattedMessage
                            id={message}
                            values={messageProps}
                        />)}
                    onClose={handleCloseSnackbar}
                 />) :
                <></>
            }
        </div>
    )

}

export default SnackbarWithAutohide;