import * as React from 'react';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
import { BOV_GRAPH } from '@constants';
import * as d3 from 'd3';
import { FormattedMessage, injectIntl } from 'react-intl';
import { initSimulation, simulationTick } from '@graph/brazilian/graph.js';
import { flatTooltipContent } from './bovHelpers';
import errorUtils from '@utils/errors/error-utils';
import formatRichMessage from '@utils/formatRichMessage';
import { throttle } from 'lodash';
import { ReactComponent as BannerWarningIcon } from '../../../assets/icons/BannerWarningIcon.svg';
import { ReactComponent as BannerWarningIconDark } from '../../../assets/icons/BannerWarningIconDark.svg';
import { connect } from 'react-redux';

class BovTreeView extends React.Component {
    static propTypes = {
        data: PropTypes.object.isRequired,
        width: PropTypes.number,
        height: PropTypes.number,
        forceDistance: PropTypes.number,
        forceStrength: PropTypes.number,
        chargeDistance: PropTypes.number,
    };

    static defaultProps = {
        ...BOV_GRAPH,
    };

    constructor(props) {
        super(props);

        this.state = {
            height: BOV_GRAPH.height,
            width: BOV_GRAPH.width,
        };

        this.zoomLevel = null;
        this.zoom = null;
        this.isZoomBlocked = false;

        this.generateGraph = this.generateGraph.bind(this);
        this.handleNodeHover = this.handleNodeHover.bind(this);
        this.handleNodeMouseOut = this.handleNodeMouseOut.bind(this);
        this.handleZoomIn = this.handleZoomIn.bind(this);
        this.handleZoomOut = this.handleZoomOut.bind(this);
        this.handleNodeMouseMove = this.handleNodeMouseMove.bind(this);
        this.adjustZoom = throttle(this.adjustZoom, this.props.zoomAdjustThrottleTime);

        this.svg = null;
        this.simulation = null;
        this.svgElement = null;
        this.container = null;
        this.hovercard = null;
    }

    generateGraph(data) {
        const { forceDistance, forceDistanceFromMainNode, chargeStrength, chargeStrengthFromMainNode, forceStrength } =
            this.props;
        const width = (this.container && this.container.clientWidth) || 800,
            height = 800;
        this.graphWidth = width;
        this.graphHeight = height;

        const { nodes, links } = data;

        if (!nodes || nodes.length === 0) {
            errorUtils.logAppError(
                'could not draw the brazilian ownership graph',
                'nodes data missing',
                'BOV_TREE_VIEW'
            );

            return;
        }

        this.svgElement = d3.select('#bov-simple-graph-container svg#bovSimpleGraph').on('wheel', function () {
            this.isZoomBlocked = true;
        });

        // init the svg
        this.svg = this.svgElement.attr('width', width).attr('height', height).select('#simple-graph-container');

        // add zoom handlers
        this.zoom = d3
            .zoom()
            .scaleExtent(this.props.zoomLevels)
            .on(
                'zoom',
                function (event) {
                    this.svg.attr('transform', event.transform);
                    this.zoomLevel = event.transform;
                }.bind(this)
            )
            .on('start', function () {
                d3.select(this).style('cursor', 'move');
            })
            .on('end', function () {
                d3.select(this).style('cursor', 'auto');
            });

        this.svgElement.call(this.zoom);

        // prepare config for simulation
        const simulationConfig = {
            forceDistance,
            forceDistanceFromMainNode,
            chargeStrength,
            chargeStrengthFromMainNode,
            forceStrength,
            width,
            height,
        };

        const handlers = {
            onHover: this.handleNodeHover,
            onMouseOut: this.handleNodeMouseOut,
            onMouseMove: this.handleNodeMouseMove,
        };

        this.simulation = initSimulation(d3, this.svg, nodes, links, simulationConfig, handlers);

        if (this.simulation) {
            this.simulation.on('tick', () => {
                simulationTick(d3, this.svg);
                this.adjustZoom();
            });
        }
    }

    calculateInitialRatio() {
        const { width, height } = this.svg.node().getBBox();
        const ratio = Math.min(this.graphWidth / width, this.graphHeight / height);
        return ratio - 0.3 * ratio;
    }

    adjustZoom() {
        if (!this.isZoomBlocked) {
            const theSvgElement = d3.select('#bov-simple-graph-container svg');
            // take smallest ratio X vs Y
            const calcRatio = this.calculateInitialRatio();
            if (calcRatio < 1) {
                // zoom out only
                this.zoom.scaleTo(theSvgElement.transition().duration(750), calcRatio); // (0.3 * ratio) - an extra nudge to the zoom out
            }
        }
    }

    handleZoomIn() {
        const theSvgElement = d3.select('#bov-simple-graph-container svg');
        this.zoom.scaleBy(theSvgElement.transition().duration(750), 1.3);
        this.isZoomBlocked = true;
    }

    handleZoomOut() {
        const theSvgElement = d3.select('#bov-simple-graph-container svg');
        this.zoom.scaleBy(theSvgElement.transition().duration(750), 0.7);
        this.isZoomBlocked = true;
    }

    handleNodeMouseMove(event) {
        if (!this.hovercard) {
            return;
        }
        this.hovercard.style('left', `${event.clientX + 5}px`).style('top', `${event.clientY}px`);
    }

    handleNodeHover(event, target) {
        const hovercardData = {
            title: target.name,
            content: target.isMain
                ? flatTooltipContent({ ...target.address, cadastralSituation: target.cadastralSituation })
                : [],
        };

        this.hovercard = d3.select('#bov-simple-graph-container').append('div').attr('class', 'hovercard-container');
        this.hovercard
            .style('left', `${event.clientX + 5}px`)
            .style('top', `${event.clientY}px`)
            .transition()
            .style('opacity', 0.98);

        this.hovercard.append('div').attr('class', 'hovercard-header').append('h6').html(`${hovercardData.title}`);

        const content = this.hovercard.append('div').attr('class', 'hovercard-body');

        hovercardData.content.forEach((value) => {
            content.append('p').attr('class', 'hovercard-content').html(`${value}`);
        });
    }

    handleNodeMouseOut() {
        this.hovercard.style('opacity', 0).remove();
    }

    componentDidMount() {
        if (!this.props.hasParsingError) {
            this.generateGraph(this.props.data);
        }
    }

    componentWillUnmount() {
        if (this.simulation) {
            this.simulation.stop();
        }
    }

    render() {
        const { hasParsingError, intl } = this.props;

        return hasParsingError ? (
            <BovParsingError
                message={formatRichMessage({ id: 'BovDocumentView.TreeView.error' }, intl)}
                isDarkMode={this.props.isDarkMode}
            />
        ) : (
            <div
                id={'bov-simple-graph-container'}
                ref={(el) => (this.container = el)}
                data-testid="bov-graph-container-testid"
            >
                <div id={'graph-legend'} data-testid="graph-legend-testid">
                    <div xmlns="http://www.w3.org/1999/xhtml" className={'container'}>
                        <div className={'legend activeCompany'}>
                            <span />
                            <FormattedMessage id="BovDocumentView.TreeViewLegend.ActiveCompany" />
                        </div>
                        <div className={'legend inactiveCompany'}>
                            <span />
                            <FormattedMessage id="BovDocumentView.TreeViewLegend.InactiveCompany" />
                        </div>
                        <div className={'legend familyRelationship'}>
                            <span>
                                <FormattedMessage id="BovDocumentView.TreeViewLegend.FamilyRelationship" />
                            </span>
                        </div>
                        <div className={'legend professionalRelationship'}>
                            <span>
                                <FormattedMessage id="BovDocumentView.TreeViewLegend.ProfessionalRelationship" />
                            </span>
                        </div>
                    </div>
                </div>
                <ZoomControls
                    label={formatRichMessage({ id: 'BovDocumentView.TreeView.ZoomLabel' }, intl)}
                    onZoomIn={this.handleZoomIn}
                    onZoomOut={this.handleZoomOut}
                />
                <svg
                    ref={(ref) => (this.svgElement = ref)}
                    id={'bovSimpleGraph'}
                    xmlns="http://www.w3.org/2000/svg"
                    data-testid="svg-container-testid"
                >
                    <g id={'simple-graph-container'} />
                </svg>
            </div>
        );
    }
}

export const ZoomControls = ({ label, onZoomIn, onZoomOut }) => {
    return (
        <React.Fragment>
            <ReactTooltip type="dark" border={true} effect="solid" className="tooltips notranslate" place={'left'} />
            <div data-testid="zoom-wrapper-testid" className={'bov-graph-controls'} data-tip={label}>
                <div data-testid="zoom-in-testid" className={'zoom-control zoom-in'} onClick={onZoomIn}>
                    <span className="la-ZoomIn" />
                </div>
                <div data-testid="zoom-out-testid" className={'zoom-control zoom-out'} onClick={onZoomOut}>
                    <span className="la-ZoomOut" />
                </div>
            </div>
        </React.Fragment>
    );
};

const BovParsingError = ({ message, isDarkMode }) => {
    return (
        <div className={`bov-parsing-error ${isDarkMode ? 'dark-mode' : ''}`} data-testid='bov-parsing-error-testid'>
            <div className='bov-parsing-error__icon'>
            {isDarkMode ? <BannerWarningIconDark /> : <BannerWarningIcon />}
            </div>
            <div className='bov-parsing-error__message'>
                <span dangerouslySetInnerHTML={{ __html: message }}></span>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        isDarkMode: state.user.preferences.generalSettings.isDarkMode,
    };
};

export default connect(mapStateToProps)(injectIntl(BovTreeView));
