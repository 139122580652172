import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import reduxStore from '@reduxStore';
import backgroundActions from '@reusable/BackgroundMessage/redux/BackgroundMessages.actions';
import { COST_CODE_MAX_CHARS, MESSAGE_TIMEOUT } from '@constants';
import utils from '@utils/utilities';
import formatRichMessage from '@utils/formatRichMessage';
import { cloneDeep } from 'lodash';

class AddPredefinedCostCodes extends Component {
    constructor(props) {
        super(props);

        this.state = {
            inputText: '',
            inputValid: true,
            annimatedIndex: '',
            adminCostCodesList: cloneDeep(utils.removeEmptyValues(this.props.adminCostCodesList)).sort(),
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.costCodeInputValidation = this.costCodeInputValidation.bind(this);
        this.validateCostCodeInput = this.validateCostCodeInput.bind(this);
        this.addNewCostCode = this.addNewCostCode.bind(this);
        this.deleteCostCodeFromList = this.deleteCostCodeFromList.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const adminCostCodesList = utils.removeEmptyValues(nextProps.adminCostCodesList);
        let annimatedIndex = '';
        let addedElement = [];

        if (
            adminCostCodesList &&
            this.state.adminCostCodesList &&
            adminCostCodesList.length > this.state.adminCostCodesList.length
        ) {
            addedElement = adminCostCodesList.filter((costCode) => {
                return this.state.adminCostCodesList.indexOf(costCode) < 0;
            });
        }

        this.setState(
            {
                adminCostCodesList,
            },
            () => {
                annimatedIndex = this.state.adminCostCodesList.findIndex((costCode) => {
                    return costCode === addedElement[0];
                });
                this.setState({
                    annimatedIndex,
                });
            }
        );
    }

    handleInputChange(e) {
        const inputText = e.target.value.substring(0, COST_CODE_MAX_CHARS);
        this.setState({
            inputText,
        });
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.costCodeInputValidation();
        }
    }

    costCodeInputValidation() {
        const inputText = this.state.inputText.trim();
        this.setState(
            {
                inputValid: this.validateCostCodeInput(inputText),
                inputText,
            },
            () => {
                if (this.state.inputValid) {
                    this.addNewCostCode(this.state.inputText);
                }
            }
        );
    }

    validateCostCodeInput(inputText) {
        if (inputText.length > 0 && inputText.length < COST_CODE_MAX_CHARS) {
            return this.props.adminCostCodesList.indexOf(inputText) <= -1;
        } else {
            return true;
        }
    }

    addNewCostCode(newCostCode) {
        this.setState(
            {
                inputText: '',
                inputValid: true,
            },
            () => {
                this.props.addCostCodeToList(newCostCode);
                reduxStore.dispatch(
                    backgroundActions.setSuccessBackgroundMessages({
                        message: 'UserPreferences_CostCode.notification.addCostCode',
                        messageParameters: newCostCode,
                        isVisible: true,
                    })
                );
                setTimeout(() => {
                    reduxStore.dispatch(backgroundActions.clearBackgroundMessages());
                }, MESSAGE_TIMEOUT);
            }
        );
    }

    deleteCostCodeFromList(costCode) {
        this.props.deleteCostCodeFromList(costCode);
    }

    render() {
        return (
            <div className="add-predefined-cost-codes">
                <input
                    id="text-field-lg"
                    type="text"
                    className="text-field-standard-lg"
                    placeholder={formatRichMessage(
                        { id: 'UserPreferences_CostCode_addPredefinedCostCodes.placeholder' },
                        this.props.intl
                    )}
                    onChange={(e) => {
                        this.handleInputChange(e);
                    }}
                    onKeyPress={(e) => {
                        this.handleKeyPress(e);
                    }}
                    value={this.state.inputText}
                />
                <button
                    className="button-primary-sm"
                    disabled={this.state.inputText.length === 0}
                    onClick={() => {
                        this.costCodeInputValidation();
                    }}
                >
                    <FormattedMessage id={'UserPreferences_CostCode_addPredefinedCostCodes_button'} />
                </button>
                {!this.state.inputValid && (
                    <div className="duplicate-error">
                        <FormattedMessage id={'UserPreferences_CostCode.error.doubleCostCode'} />
                    </div>
                )}
                {this.state.adminCostCodesList && this.state.adminCostCodesList.length > 0 && (
                    <div className="cost-code-table-content">
                        <div className="cost-code-table-header">
                            <FormattedMessage id={'UserPreferences_CostCode_header'} />
                        </div>
                        <ul className="cost-code-table-body">
                            {this.state.adminCostCodesList.map((costCode, index) => {
                                return (
                                    <li
                                        className={`cost-code-line-item ${
                                            this.state.annimatedIndex === index ? 'greenAnimation' : ''
                                        }`}
                                        key={index}
                                    >
                                        <div className="cost-code-line-item__name">{costCode}</div>
                                        <div className="cost-code-line-item__button">
                                            <span className="la-RecycleBin"></span>
                                            <button
                                                className="cost-code-line-item__button__delete"
                                                onClick={() => {
                                                    this.deleteCostCodeFromList(costCode);
                                                }}
                                            >
                                                <FormattedMessage
                                                    id={'General_CoreFunctionality_UIText_general.delete'}
                                                />
                                            </button>
                                        </div>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                )}
            </div>
        );
    }
}

export default injectIntl(AddPredefinedCostCodes);
