import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import uboActions from '../StartPage/redux/Ubo.actions';
import { connect } from 'react-redux';
import { UboArticle } from '@utils/props';
import UboDocumentView from './UboDocumentView';

export class UboDocumentReportView extends Component {
    static propTypes = {
        updateSortingAndFiltering: PropTypes.func.isRequired,
        uboArticle: UboArticle.isRequired,
    };

    UNSAFE_componentWillUpdate(nextProps) {
        if (this.props.filtering.filteringDropdownSelected !== nextProps.uboArticle.documentPreviewFiltering) {
            let documentPreviewFiltering = { filteringDropdownSelected: nextProps.uboArticle.documentPreviewFiltering };
            this.props.updateFiltering(documentPreviewFiltering);
        }
    }

    render() {
        const isTradeUp =
            this.props.uboArticle &&
            this.props.uboArticle.inquiryDetails &&
            this.props.uboArticle.inquiryDetails.tradeUp === 'true';
        return (
            <div id="uboDocumentReportView" className="single-article-content ubo-document-body">
                <UboDocumentView
                    uboArticle={this.props.uboArticle}
                    persistedNodes={
                        this.props.uboArticle &&
                        this.props.uboArticle.persistedNodes &&
                        this.props.uboArticle.persistedNodes.map((node) => ({ id: parseInt(node.id) }))
                    }
                    updateSortingAndFiltering={this.props.updateSortingAndFiltering}
                    documentPreview={true}
                    isTradeUp={isTradeUp}
                    filtering={this.props.filtering}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        filtering: state.ubo.docViewDeliveryInfo.filtering,
    };
};

const mapDispatchToProps = function (dispatch) {
    return bindActionCreators(
        {
            updateSortingAndFiltering: uboActions.updateSortingAndFiltering,
            updateFiltering: uboActions.updateFiltering,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(UboDocumentReportView);
