// @flow
/**
 * UTILITY BELT
 *
 * A minimal utility belt file which contains various general use helpers.
 *
 * @version 0.1
 */

import { hashHistory } from 'react-router';
import {
    cloneDeep,
    isNumber,
    each,
    endsWith,
    find,
    intersection,
    isEqual,
    isObject,
    toNumber,
    transform,
    trimEnd,
    trimStart,
    isString,
    values,
    difference,
} from 'lodash';
import storage from './storage';
import moment from 'moment';
import 'moment/min/locales';
import 'moment-timezone';

import {
    UI_EVENTS,
    AND,
    API_SEARCH_VERSION,
    BE_LOCK_NAMES,
    BOOLEAN_AND,
    BOOLEAN_OR,
    CATEGORIES_EXCLUDED_FROM_PERSON_SEARCH,
    CATEGORY_NAMES,
    CHANGED_BY_ADMIN,
    COMPANY_SEARCH,
    COST_CODE,
    CUSTOM_QUERY_NEGATIVITY_STRING_LANGUAGE,
    DEFAULT_LANGUAGE,
    DEVELOPMENT_ENVIRONMENT,
    DOWNLOAD_DEFAULT_FILENAME,
    FILENAME_CHARACTERS_LIMIT,
    FILTER_SORT_OPTIONS,
    FUZZY_NAMES,
    HELP_URLS,
    LOCALES,
    NEGATIVE_NEWS_SELECTED_LANGUAGE,
    OPERATOR_AND,
    PERSON_SEARCH,
    PERSONS_SEARCH,
    POST_FILTER_DOCKET_STATUS,
    POST_FILTER_SEARCH_PUBLICATION_NAME,
    POST_FILTER_SEARCH_SOURCE_NAME,
    POSTFILTER_COMPONENTS,
    POSTFILTER_TYPE,
    PREFERENCES_KEY,
    SORT_DIRECTION,
    SORT_OPTIONS,
    SOURCES,
    SUGGESTED_NAMES,
    UBO_MAIN_CATEGORY,
    USER_SESSION_EXPIRY_TIME,
    VISITED_ARTICLES_LIMIT,
    CUSTOM_POST_FILTER_ARRAY,
    PERSON,
    POST_FILTER_LABELS,
    POST_FILTER_PROXIMITY,
    FILTER_INFO,
    LEGAL_SOURCES_TITLES,
    LAUNCHED_SEARCH_FROM,
    SCREENING_ENTITY_STATUSES,
    POST_FILTER_UNCLASSIFIED_OPTION,
    VIEWNAME_CHARACTERS_LIMIT,
    ROUTES,
} from '@constants';

import errorMessagesActions from '@reusable/MessagePopup/redux/MessagePopup.actions';
import popupActions from '@reusable/MessagePopup/redux/MessagePopup.actions';
import reduxStore from '@reduxStore';
import userPreferenceApi from '@pages/UserPreferances/UserPreferencesApi.api';
import actions from '@pages/UserPreferances/redux/UserPreferences.actions';
import SearchUtils from '@pages/MainSearch/SearchUtils';
import categoryUtils, { withAdminCustomNewsTitle, withCustomNewsTitle, withLNCustomNewsTitle } from './categoryUtils';
import {
    TOGGLE_UBO,
    TOGGLE_ENTITY_VIEW_ENABLE,
    TOGGLE_ENTITY_VIEW_TRIAL,
    TOGGLE_MARKETING_ENTITY_VIEW,
    TOGGLE_IS_SPECIFIED_CUSTOMER,
} from '@pages/Main/Main.reducers';
import { permissions, userHas } from '@permissions';
import type { NewsSourceType, ReportFileNameValidation, ViewNameValidation } from './flow/utilities.type.guards';
import type { ContentTypesType } from '@pages/MainSearch/components/typeGuards/ResultsList.typeGuards';
import { updateNewsQueriesBasedOnNegativityLevels } from '@pages/MainSearch/SearchUtils';
import searchResultsActions from '../pages/MainSearch/redux/SearchResults.actions';
import mainActions from '@pages/Main/Main.actions';
import postFilterConfigActions from '../pages/MainSearch/redux/PostFilterConfiguration.actions';
import { retrievePostFilterData } from '../pages/MainSearch/SearchUtils';
import { UPLOAD_ENTITIES_WARNINGS_AND_ERRORS } from 'scripts/constants';
import formatRichMessage from "@utils/formatRichMessage";

let utils = {
    /**
     * ERROR HANDLING AND NAVIGATION
     */

    goTo404Page() {
        errorMessagesActions.setMessages({
            messages: [
                {
                    titleId: 'General_CoreFunctionality_Error_error.404Title',
                    messageType: 'system-error',
                    messageId: 'General_CoreFunctionality_Error_error.404Message',
                },
            ],
        });

        hashHistory.push('/error');
    },

    /**
     * TYPE CHECKING
     */

    /**
     * Checks if a given value is an array.
     *
     * @param   value       The value to be checked.
     * @return  {boolean}   True if the given value is an array or false otherwise.
     */
    isArray: function (value) {
        return Object.prototype.toString.call(value) === '[object Array]';
    },

    isString: function (value) {
        return typeof value === 'string' || value instanceof String;
    },

    /**
     * Checks if a given value is an object.
     *
     * @param    value       The value to be checked.
     * @returns  {boolean}   True if the given value is an object or false otherwise.
     */
    isObject: function (value) {
        return Object.prototype.toString.call(value) === '[object Object]';
    },

    /**
     * Compares if two given parameters of any value are deeply equal.
     *
     * @param   a           The first object to compare.
     * @param   b           The second object to compare.
     * @return  {boolean}   True if the objects are deeply equal or false otherwise.
     */
    isDeepEqual: function (a, b) {
        if (Object.prototype.toString.call(a) !== Object.prototype.toString.call(b)) {
            return false;
        }

        if (utils.isArray(a) || utils.isObject(a)) {
            for (let key in a) {
                if (utils.isArray(a[key]) || utils.isObject(a[key])) {
                    if (!utils.isDeepEqual(a[key], b[key])) {
                        return false;
                    }
                } else if (a[key] !== b[key]) {
                    return false;
                }
            }
        } else if (a !== b) {
            return false;
        }

        return true;
    },

    /**
     * Deep diff between two object, using lodash
     * @param  {Object} object Object compared
     * @param  {Object} base   Object to compare with
     * @return {Object}        Return a new object which represents the diff
     */
    getDifferences: function (object, base) {
        function changes(object, base) {
            return transform(object, function (result, value, key) {
                if (!isEqual(value, base[key])) {
                    result[key] = isObject(value) && isObject(base[key]) ? changes(value, base[key]) : value;
                }
            });
        }
        return changes(object, base);
    },

    /**
     * Checks if an object is empty.
     *
     * @param   {Object}  obj   The object to be checked.
     * @return  {boolean}       Returns true if the object is empty or false otherwise.
     */
    isEmptyObject(obj) {
        return utils.isDeepEqual(obj, {});
    },

    /**
     * MISCELLANEOUS
     */

    /**
     * UUID generator. Algorithm written by Stack Overflow user "broofa":
     * @see {@link http://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript#2117523}
     *
     * @return  {string}   The generated UUID.
     */
    generateUUID: function () {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            let r = (Math.random() * 16) | 0,
                v = c === 'x' ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        });
    },

    /**
     * LOGGING METHODS
     */

    logError: function (error, description = '') {
        if (DEVELOPMENT_ENVIRONMENT) {
            console.log('--------------------------------------------------');
            console.log('ERROR: ');

            if (description) {
                console.log(description);
            }

            console.log(error);
            console.log('--------------------------------------------------');
        }
    },

    /**
     * USER SESSION
     */

    setUserSession: function (sessionData) {
        storage.setObject(
            'userSession',
            Object.assign({}, sessionData, {
                uuid: utils.generateUUID(),
                time: new Date(),
            })
        );
    },

    isUserSessionValid: function () {
        let userSession = storage.getObject('userSession');

        if (userSession) {
            if (!userSession.rememberMe) {
                return true;
            }

            if (userSession.time && new Date() - Date.parse(userSession.time) < USER_SESSION_EXPIRY_TIME) {
                return true;
            }
        }

        return false;
    },

    clearUserSession: function () {
        storage.remove('userSession');
    },

    getUserSessionData(key) {
        let userSession = storage.getObject('userSession');
        if (userSession) {
            return userSession[key] ? userSession[key] : null;
        }

        return null;
    },

    getDateFromString(date, format) {
        let dateFormat = moment(date, format);
        if (dateFormat.format(format) === date) return dateFormat.toDate();
        else return null;
    },

    getUserLocaleDate(date) {
        date = new Date(date);
        let userLocale = reduxStore.getState().user.preferences.language;
        let timezone = reduxStore.getState().user.timezone;
        return moment.tz(date, timezone).locale(userLocale);
    },

    getUserLocaleDateWithoutTimezone(date) {
        date = new Date(date);
        let userLocale = reduxStore.getState().user.preferences.language;
        return moment(date).locale(userLocale);
    },

    formatDateForReportName(date) {
        return date.replace(/[^\w\s]/gim, '_');
    },

    formatReportDateWithtTimezoneOffset(date) {
        let dateFormat = utils.getUserLocaleDate(date).format('L');
        let timeFormat = utils.getUserLocaleDate(date).format('hh-mm');
        let formatedDateWithTime = `${utils.formatDateForReportName(dateFormat)}_${timeFormat}`;
        return formatedDateWithTime;
    },

    formatDateWithHoursAndMinutes(date) {
        let dateFormat = utils.getUserLocaleDate(date).format('lll');
        return dateFormat;
    },

    formatDateWithoutHoursAndMinutes(date) {
        let dateFormat = utils.getUserLocaleDate(date).format('LL');
        return dateFormat;
    },

    formatTimeWithHoursAndMinutes(date) {
        let formatter = utils.getUserLocaleDate(date);
        let dateFormat = formatter.format('HH:mm z');
        if (dateFormat.includes('+') || dateFormat.includes('-')) {
            return formatter.format('HH:mm [GMT] Z');
        } else {
            return dateFormat;
        }
    },

    formatDateWithTimezoneOffset(date) {
        let dateFormat = utils.getUserLocaleDate(date).format('lll');
        return dateFormat;
    },

    formatDateWithShortMonth(date, language) {
        const languageKey = this.getLanguageKeyForDate(language);

        if (date && date instanceof Date) {
            const month = date.toLocaleString(languageKey || 'default', { month: 'short' });
            const year = date.getFullYear();
            const day = date.getDate();

            return `${day} ${month} ${year}`;
        }

        return utils.getUserLocaleDate(date.getTime()).format('DD/MM/YYYY');
    },

    formatDateForExpiryDate(date) {
        let dateFormat = utils.getUserLocaleDate(date).format('ll');
        return dateFormat;
    },

    formatDateDayDateTime(date) {
        let dateFormat = utils.getUserLocaleDate(date).format('LLLL');
        return dateFormat;
    },

    formatDateDayOfMonthDateTime(date) {
        let dateFormat = utils.getUserLocaleDate(date).format('Do MMMM');
        return dateFormat;
    },

    isValidDate: function (val) {
        var dateWrapper = new Date(val);
        return !isNaN(dateWrapper.getDate());
    },

    formatLegacyCreatedDate: function (date) {
        let newDate = new Date(date);
        newDate = utils.formatDateWithHoursAndMinutes(newDate.getTime());
        return newDate;
    },

    tryFormatDate: function (date) {
        let formattedDate;

        try {
            formattedDate = new Date(date).getTime();

            if (isNaN(formattedDate)) {
                formattedDate = 0;
            }
        } catch (error) {
            formattedDate = 0;
        }
        return formattedDate;
    },

    dateRangeToText(dateRange) {
        // console.log('!!! this method should use internationalization')
        switch (dateRange) {
            case 'all':
                return 'All available dates';
            case 'today':
                return 'Today';
            case 'custom':
                return 'Custom dates';
            default:
                break;
        }
        const dateTypes = { d: 'days', w: 'weeks', m: 'months', y: 'years' };

        let date;

        Object.keys(dateTypes).forEach((type) => {
            if (endsWith(dateRange, type)) {
                const numPart = toNumber(trimEnd(dateRange, type));
                if (numPart > 1) {
                    date = `Last ${numPart} ${dateTypes[type]}`;
                } else {
                    const singularDateType = trimEnd(dateTypes[type], 's');
                    date = `Last ${singularDateType}`;
                }
            }
        });

        return date;
    },

    addXDaysToDate(date, miliseconds) {
        return date + miliseconds;
    },

    showMultipleNotificationsMessages: (messages, timeout) => {
        popupActions.setMessages({
            popupTitle: null,
            messages,
        });

        setTimeout(popupActions.removeMessage, timeout);
    },

    showNotificationsMessage: function (settings) {
        //$FlowFixMe
        settings = Object.assign(
            {
                messageText: null,
                messageType: 'info',
                terms: {},
                timeout: 5000,
            },
            settings
        );

        popupActions.setMessages({
            popupTitle: null,
            messages: [
                {
                    messageId: settings.messageText,
                    messageType: settings.messageType,
                    values: settings.terms,
                },
            ],
        });

        setTimeout(popupActions.removeMessage, settings.timeout);
    },

    showAdminNotificationsMessage: function (settings) {
        settings = Object.assign(
            {
                messageText: null,
                messageType: 'info',
                terms: {},
                timeout: 5000,
            },
            settings
        );

        if (
            Object.values(settings.terms).indexOf(CHANGED_BY_ADMIN) > -1 &&
            window.location.hash.indexOf('user-preferences') > -1
        ) {
            popupActions.setMessages({
                popupTitle: null,
                messages: [
                    {
                        messageId: settings.messageText,
                        messageType: settings.messageType,
                        values: settings.terms,
                    },
                ],
            });
        }

        // Hide the message after 5 seconds.
        setTimeout(popupActions.removeMessage, settings.timeout);
    },

    sortByProperty: function (array, property) {
        array.sort((a, b) => {
            if (a[property] < b[property]) {
                return -1;
            } else if (a[property] > b[property]) {
                return 1;
            } else {
                return 0;
            }
        });
        return array;
    },

    replaceHtmlEntities(str: string): string {
        return str.replace(/(&.+;)/, '');
    },

    camelCase(str) {
        return str
            .toLowerCase()
            .split(' ')
            .map(function (word) {
                return word.charAt(0).toUpperCase() + word.slice(1);
            })
            .join(' ');
    },

    formatFileName(fileName: string, isCustomNewsCategory?: boolean): string {
        const formattedFileName = fileName.replace(/&/g, '').replace(/\//g, '_');

        return isCustomNewsCategory ? formattedFileName.replace(/\s/g, '_') : formattedFileName.replace(/\s/g, '');
    },

    formatReportFileName(fileName: string): string {
        if (!fileName) {
            return '';
        }

        return fileName
            .replace(/[?!@#$%^&*()|\-_+=:;'"“”`.,<>~£[\]{}\\/]/g, ' ')
            .replace(/\s\s+/g, '_')
            .replace(/\s/g, '_');
    },

    isEmailAddressValid(email) {
        let re =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g;
        return re.test(email);
    },

    isFileNameValid(fileName: string): boolean {
        let fName = fileName ? fileName : DOWNLOAD_DEFAULT_FILENAME;
        let re =
            /[^a-zA-Z0-9_(),.\-: \u011F\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\u3000\u3400-\u4DBF\u4dff\u4E00-\u9FFF\u0600-\u06FF\u0400-\u04FF\u0E00-\u0E7F\u0150-\u0151\u0170-\u0171{0,9}$]/g;
        return !re.test(fName);
    },

    isReportFileNameValid(fileName: string): ReportFileNameValidation {
        const isEmptyString: RegExp = /^ *$/g;
        const invalidCharactersRegex: RegExp =
            /[^a-zA-Z0-9_(@#!%^+=~'`£?*[\])&\-:; \u011F\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\u3000\u3400-\u4DBF\u4dff\u4E00-\u9FFF\u0600-\u06FF\u0400-\u04FF\u0E00-\u0E7F\u0150-\u0151\u0170-\u0171{0,9}$]/g;
        const invalidLengthRegex: RegExp = new RegExp(`^.{1,${FILENAME_CHARACTERS_LIMIT}}$`, 'gi');

        return {
            invalidCharacters: invalidCharactersRegex.test(fileName) || isEmptyString.test(fileName),
            invalidLength: !invalidLengthRegex.test(fileName),
            currentLength: fileName.length,
            maxLength: FILENAME_CHARACTERS_LIMIT,
        };
    },

    isViewNameValid(viewName: string): ViewNameValidation {
        const isEmptyString: RegExp = /^ *$/g;
        const invalidCharactersRegex: RegExp =
            /[^a-zA-Z0-9_(@#!%^+=~'`£?*[\])&\-:; \u011F\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\u3000\u3400-\u4DBF\u4dff\u4E00-\u9FFF\u0600-\u06FF\u0400-\u04FF\u0E00-\u0E7F\u0150-\u0151\u0170-\u0171{0,9}$]/g;
        const invalidLengthRegex: RegExp = new RegExp(`^.{1,${VIEWNAME_CHARACTERS_LIMIT}}$`, 'gi');

        return {
            invalidCharacters: invalidCharactersRegex.test(viewName) || isEmptyString.test(viewName),
            invalidLength: !invalidLengthRegex.test(viewName),
            currentLength: viewName.length,
            maxLength: VIEWNAME_CHARACTERS_LIMIT,
        };
    },

    updateUserPreferencesPayloadAndTimestamp() {
        return userPreferenceApi
            .getUserPreferences()
            .then((response) => {
                reduxStore.dispatch(actions.updateUserPreferencesPayloadAndRetrievalTimestamp(response.body));
                return response;
            })
            .catch(() => {
                utils.showNotificationsMessage({
                    messageText: 'UserPreferences_Notifications.errorUpdatePreferencesMessage',
                    messageType: 'system-error',
                });
            });
    },

    updatePreferencesAfterAdHoc(newPrefs) {
        return userPreferenceApi
            .updateUserPreferencesAfterAdHoc(newPrefs)
            .then((response) => {
                utils.updateUserPreferencesPayloadAndTimestamp();
                return response;
            })
            .catch(() => {
                utils.showNotificationsMessage({
                    messageText: 'UserPreferences_Notifications.errorUpdatePreferencesMessage',
                    messageType: 'system-error',
                });
                return userPreferenceApi
                    .getUserPreferences()
                    .then((response) => {
                        reduxStore.dispatch(actions.getUserPrefencesValues(response.body));
                        return response;
                    })
                    .catch(() => {
                        console.log('navigate to error page');
                        hashHistory.push('/error');
                    });
            });
    },

    isValidQuery(query) {
        let isValid = true;
        if (query && query.includes('*')) {
            // regex to check if a query that contains a '*' wildcard has a minimum of 3 characters before it
            let re = /(?=[^*]{3,}[*]{1,})/g;
            let words = query.split(' ');
            words.map((word) => {
                if (word.includes('*')) {
                    if (!re.test(word)) {
                        isValid = false;
                    }
                }
            });
            return isValid;
        }

        return isValid;
    },

    hasMismatchedQuotesNumber(query) {
        let quoteMismatch: boolean = false;
        if (query && query.length) {
            const doubleQuoteCount: number = (query.match(/"/g) || []).length;
            quoteMismatch = doubleQuoteCount && doubleQuoteCount % 2 !== 0;
        }
        return quoteMismatch;
    },

    validateSearchQuery(searchQuery) {
        if (!searchQuery || searchQuery === '') {
            return 'SearchResults_Notifications.missingQueryParameter';
        }

        if (!utils.isValidQuery(searchQuery)) {
            return 'SearchResults_Notifications.insufficientCharacters';
        }

        if (utils.hasMismatchedQuotesNumber(searchQuery)) {
            return 'SearchResults_Notifications.mismatchedQuotationMarks';
        }

        return null;
    },

    validateSearchType(searchType) {
        if (!searchType || searchType === '') {
            return 'SearchResults_Notifications.missingSearchTypeParameter';
        }
        const type = searchType.toLowerCase();

        if (
            type !== PERSON.toLowerCase() &&
            type !== PERSONS_SEARCH &&
            type !== PERSON_SEARCH &&
            type !== COMPANY_SEARCH
        ) {
            return 'BatchUpload.validationMessage.invalidSearchTypeParameter';
        }

        return null;
    },

    uppercaseFirstLetter(topic) {
        topic = topic.toString();
        topic = topic.replace(/"/g, '');
        topic = topic.toLowerCase();
        return topic.replace(/\b\w/g, (newTopic) => newTopic.toUpperCase());
    },

    capitalizeNegativeTerms(negativeTerms) {
        negativeTerms = negativeTerms.map((topic) => {
            topic.name = topic.name.toString();
            topic.name = topic.name.replace(/"/g, '');
            topic.name = topic.name.toLowerCase();
            return topic.name.replace(/\b\w/g, (newTopic) => newTopic.toUpperCase());
        });

        negativeTerms = negativeTerms.toString();
        negativeTerms = negativeTerms.replace(/,/g, ', ');
        return negativeTerms;
    },

    updatePreference(preferences) {
        let keys = Object.keys(preferences);

        userPreferenceApi
            .updateUserPreference({
                userPreferences: preferences,
            })
            .then((response) => {
                keys.forEach((key) => {
                    reduxStore.dispatch(actions.updateUserPreference(key, preferences[key]));
                });

                return response;
            })
            .then((response) => {
                utils.updateUserPreferencesPayloadAndTimestamp();
                return response;
            })
            .catch(() => {
                return userPreferenceApi
                    .getUserPreferences()
                    .then((response) => {
                        utils.showNotificationsMessage({
                            messageText: 'General_CoreFunctionality_Error_general.saveError',
                            messageType: 'system-error',
                        });
                        keys.forEach((key) => {
                            let revertedPreference = response.body.userPreferences[key];
                            reduxStore.dispatch(actions.updateUserPreference(key, revertedPreference));
                        });

                        return response;
                    })
                    .catch(() => {
                        hashHistory.push('/error');
                    });
            });
    },

    handleFuzzyToggle(event, isUserMIP) {
        let isFuzzyEnabled = event.target.checked;
        reduxStore.dispatch(actions.updateFuzzyFunctionality(isFuzzyEnabled));

        if (isUserMIP) {
            let { query, searchType } = reduxStore.getState().searchParams;
            if (query !== '' && searchType !== '') {
                SearchUtils.doFuzzySearch(query, searchType, isFuzzyEnabled).then((response) => {
                    if (response === null) {
                        reduxStore.dispatch(actions.resetFuzzyNames());
                    }

                    return response;
                });
            }
        } else {
            return userPreferenceApi
                .updateUserPreference({
                    userPreferences: {
                        generalSettings: {
                            enableFuzzy: isFuzzyEnabled,
                        },
                    },
                })
                .then((response) => {
                    let { query, searchType } = reduxStore.getState().searchParams;
                    if (query !== '' && searchType !== '') {
                        SearchUtils.doFuzzySearch(query, searchType, isFuzzyEnabled).then((response) => {
                            if (response === null) {
                                reduxStore.dispatch(actions.resetFuzzyNames());
                            }
                        });
                    }
                    return response;
                })
                .catch(() => {
                    return userPreferenceApi
                        .getUserPreferences()
                        .then((response) => {
                            utils.showNotificationsMessage({
                                messageText: 'UserPreferences_Notifications.errorUpdatePreferencesMessage',
                                messageType: 'system-error',
                            });

                            let isFuzzyEnabled = response.body.userPreferences.generalSettings.enableFuzzy;
                            reduxStore.dispatch(actions.updateFuzzyFunctionality(isFuzzyEnabled));

                            return response;
                        })
                        .catch(() => {
                            hashHistory.push('/error');
                        });
                });
        }
    },

    daysToMSConverter(days) {
        return 86400 * 1000 * days;
    },

    /**
     * Deep diff between two object, using lodash
     * @param  {Object} object Object compared
     * @param  {Object} base   Object to compare with
     * @return {Object}        Return a new object who represent the diff
     */
    difference(object, base) {
        function changes(object, base) {
            return transform(object, function (result, value, key) {
                if (!isEqual(value, base[key])) {
                    result[key] = isObject(value) && isObject(base[key]) ? changes(value, base[key]) : value;
                }
            });
        }

        return changes(object, base);
    },

    getUrlQueryParam(name, url) {
        if (!url) {
            url = window.location.href;
        }
        name = name.replace(/[[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) {
            return null;
        }
        if (!results[2]) {
            return '';
        }
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    },

    validateEmail(bulkEmails) {
        if (!isString(bulkEmails)) {
            return {
                isValid: false,
                errorMessage: 'InvalidEmail',
            };
        }

        let emails = bulkEmails.split(',');
        let errorMessage;
        let isValid = true;

        emails.forEach((email) => {
            if (utils.isEmailAddressValid(email.trim())) {
                isValid = isValid && true;
            } else {
                isValid = isValid && false;
                errorMessage = 'InvalidEmail';
            }
        });

        if (bulkEmails.trim() === '') {
            isValid = false;
            errorMessage = 'EmailRequired';
        }

        if (emails.length > 10) {
            isValid = false;
            errorMessage = 'TooManyEmails';
        }
        return {
            isValid,
            errorMessage,
        };
    },

    /* due to the js serialization,
     - a parameter with null value (param=null), will be encoded as param=
     - a parameter with an empty array (param=[]), will be missed in encoding
     rules for using fuzzyNames on backend
     1. list of names are provided, backend will use the list
     2. list empty array, backend will receive as names == null due to the js serialization/encoding
     3. list is null, backend will receive as names =[] and will use as an empty list
     */
    getFuzzyNameList(fuzzyNames) {
        if (fuzzyNames && fuzzyNames.length === 0) {
            return [];
        }

        if (!fuzzyNames || fuzzyNames.loadFuzzyOnBackend) {
            return null;
        }

        if (fuzzyNames.list) {
            const { list } = fuzzyNames;
            return list.filter(({ selected }) => selected).map(({ name }) => name);
        } else if (Array.isArray(fuzzyNames)) {
            return fuzzyNames;
        }

        return null;
    },

    getDocketStatusValue(value) {
        if (Array.isArray(value)) {
            if (value.length === 2 || value.length === 0) {
                return null;
            } else {
                return value[0];
            }
        }

        return value;
    },

    formatPostFilters(postFilters) {
        let formattedPostFilters = {};

        if (!postFilters) {
            return formattedPostFilters;
        }

        Object.keys(postFilters).forEach((key) => {
            if (key === FUZZY_NAMES) {
                formattedPostFilters.fuzzyNames = utils.getFuzzyNameList(postFilters.fuzzyNames);
            } else if (key === SUGGESTED_NAMES) {
                formattedPostFilters.suggestedNames = utils.getFuzzyNameList(postFilters.suggestedNames);
            } else if (key === POST_FILTER_DOCKET_STATUS) {
                formattedPostFilters[key] = utils.getDocketStatusValue(postFilters[key]);
            } else {
                formattedPostFilters[key] = postFilters[key];
            }
        });

        return formattedPostFilters;
    },

    sortPostFilterValues(values, sort) {
        switch (sort) {
            case FILTER_SORT_OPTIONS.ALPHABETICALLY_ASC:
                values = values.sort((a, b) => {
                    let labelA = a.label.toLowerCase();
                    let labelB = b.label.toLowerCase();

                    if (labelA > labelB) {
                        return 1;
                    } else if (labelA <= labelB) {
                        return -1;
                    }
                });
                break;
            case FILTER_SORT_OPTIONS.HIGHEST:
            default:
                values = values.sort((a, b) => b.count - a.count);
                break;
        }

        return values;
    },

    moveUnclassifiedToTheEnd(postFilters) {
        if (!postFilters) {
            return postFilters;
        }

        const unclassifiedIndex = postFilters.findIndex((filter) => filter.label === POST_FILTER_UNCLASSIFIED_OPTION);
        if (unclassifiedIndex === -1 || (unclassifiedIndex === 0 && postFilters.length === 1)) {
            return postFilters;
        }

        const unclassifiedFilter = postFilters.splice(unclassifiedIndex, 1)[0];
        postFilters.push(unclassifiedFilter);
        // postFilters = postFilters.map(filter => filter.label !== 'Unclassified' ? filter : unclassifiedFilter);

        return postFilters;
    },

    sortContentTypesByOrder(contentTypes) {
        let sortedContentTypes = contentTypes.slice(0);

        sortedContentTypes.sort((a, b) => a.reportOrder - b.reportOrder);
        sortedContentTypes.forEach((item, index) => (item.reportOrder = index));

        return sortedContentTypes;
    },

    proximityToText(proximity) {
        //TODO: add translation
        if (proximity === 'all') {
            return 'Within the same document';
        } else {
            return 'Within ' + proximity + ' words';
        }
    },

    mergeQueryAndBoolean(query, prefilterQuery) {
        let mergedQuery = query;
        if (prefilterQuery) {
            mergedQuery = query + ' ' + prefilterQuery;
        }
        return mergedQuery;
    },

    getAllUrlParams(url) {
        if (!url) {
            url = window.location.href;
        }
        const allParams = url.substr(url.indexOf('?') + 1);
        const params = allParams.split('&').map((param) => param.split('='));
        let obj = {};
        params.forEach((param) => {
            obj[param[0]] = param[1];
        });
        return obj;
    },

    removeFirstBooleanOperator(prefilter) {
        if (!prefilter) prefilter = '';

        let stripedQuery;
        let connectorAND = BOOLEAN_AND.trim();
        let trimmedPrefilter = prefilter.trim();

        if (trimmedPrefilter.indexOf(connectorAND) === 0) {
            stripedQuery = trimStart(trimmedPrefilter, connectorAND).trim();
        } else {
            stripedQuery = trimmedPrefilter;
        }

        if (prefilter.trim().indexOf(BOOLEAN_OR.trim()) === 0) {
            stripedQuery = trimStart(prefilter, BOOLEAN_OR);
        }

        return stripedQuery;
    },

    extractQueryFromBooleanTerms(fullQuery) {
        let parsedQuery = { query: '', prefilterQuery: '', message: '' };

        const queryTerm = fullQuery.substr(0, fullQuery.indexOf(OPERATOR_AND));
        const prefilterQueryTerms = fullQuery.substr(fullQuery.indexOf(OPERATOR_AND) + 1);
        const hasConnectorBetweenQuotes = queryTerm.indexOf('"') !== -1 && prefilterQueryTerms.indexOf('"') !== -1;
        parsedQuery.query = fullQuery;

        if (queryTerm && !hasConnectorBetweenQuotes) {
            parsedQuery.query = queryTerm.trim();
            parsedQuery.prefilterQuery = prefilterQueryTerms;
        } else if (hasConnectorBetweenQuotes) {
            const regexQuotes = /"(.*?)"/g;
            const regexAND = / AND /g;
            let match = {};
            let arrayWithQuotes = [];
            let arrayWithANDs = [];

            while ((match = regexQuotes.exec(fullQuery)) != null) {
                arrayWithQuotes.push({ start: match.index, end: match.index + match[0].length - 1 });
            }

            while ((match = regexAND.exec(fullQuery)) != null) {
                arrayWithANDs.push(match.index);
            }

            if (arrayWithANDs && arrayWithANDs.length) {
                const validIndex = arrayWithANDs.find((entry) => {
                    if (
                        arrayWithQuotes &&
                        arrayWithQuotes.length &&
                        arrayWithQuotes.every((item) => !(item.start < entry && entry < item.end))
                    ) {
                        return entry;
                    }
                });

                if (validIndex) {
                    parsedQuery.query = fullQuery.substr(0, validIndex).trim();
                    parsedQuery.prefilterQuery = fullQuery.substr(validIndex).trim();
                }
            }
        }

        return parsedQuery;
    },

    addFirstBooleanOperator(prefilterQuery) {
        if (!prefilterQuery) prefilterQuery = '';

        if (prefilterQuery !== '') {
            prefilterQuery = prefilterQuery.trim();

            if (prefilterQuery.indexOf(AND) !== 0) {
                prefilterQuery = `${AND} ${prefilterQuery}`;
            }
        }

        return prefilterQuery;
    },

    setCorrectPrefilterQuery(prefilterQuery) {
        return prefilterQuery && prefilterQuery.length ? utils.addFirstBooleanOperator(prefilterQuery) : '';
    },

    initializeTracking(appState: Object) {
        const diligenceUserRole: string = userHas(permissions.admin.edit) ? 'admin' : 'endUser';
        if (!window.pendo) return;
        window.pendo.updateOptions({
            visitor: {
                id: appState.user.userId ? appState.user.userId : 'unknown-user-id',
                custom: null,
                interfaceLanguage: appState.user.preferences.language,
                appVersion: appState.header.version,
                diligenceUserRole,
            },

            account: {
                id: appState.user.customerId ? appState.user.customerId : 'unknown-account-id',
                name: appState.user.customerName,
                // planLevel:    // Optional
                // planPrice:    // Optional
                // creationDate: // Optional

                // You can add any additional account level key-values here,
                // as long as it's not one of the above reserved names.
            },
        });
    },

    getSearchSources(sources) {
        let sourceItems = [];
        sources.forEach((item) => {
            const category = categoryUtils.getCategory(item.name);
            if (category) {
                sourceItems.push({
                    key: item.name,
                    name: category.name,
                    checked: item.value,
                    order: category.order,
                    disabled: item.disabled,
                    isNew: category.isNew,
                });
            }
        });

        return utils.sortByProperty(sourceItems, 'order');
    },

    isCategoryEnabled(categoryName, categories = null) {
        if (categoryName === CATEGORY_NAMES.PUBLIC_RECORDS) return false;
        if (!categories) {
            categories = reduxStore.getState().searchResults;
        }
        let adHocSearchSources = reduxStore.getState().adHocSearch.sources;
        let historyCategoryName = reduxStore.getState().historyCategory.categoryName;

        // if searching from history and the category we are searching is not enabled, enable it
        if (
            historyCategoryName &&
            categoryName === historyCategoryName &&
            categories[historyCategoryName].enabled === false
        ) {
            return true;
        }

        // if searching form ad hoc
        if (adHocSearchSources && adHocSearchSources.length > 0) {
            // for the category representative in ad hoc object
            let adHocCategory = find(adHocSearchSources, ['key', categoryName]);

            if (adHocCategory) {
                // return its checked state
                return (
                    adHocCategory.checked && categoryUtils.shouldDisplayForState(categoryName, reduxStore.getState())
                );
            } else {
                if (categoryUtils.isExtended(categoryName)) {
                    // if the parent category was unchecked uncheck the children too
                    adHocCategory = find(adHocSearchSources, ['key', categoryUtils.getParent(categoryName)]);
                    if (adHocCategory.checked === false) return false;
                }
            }
        }

        // if all of the above did not apply, apply the enabled state form search results
        let searchResultsCategory = find(categories, ['name', categoryName]);
        if (searchResultsCategory) {
            // return the category's enabled status from search results categories
            return searchResultsCategory.enabled;
        }
        return false;
    },

    isCategoryChecked(category, state = null) {
        if (!state) {
            state = reduxStore.getState();
        }
        let adHocSearch = state.adHocSearch;
        let userEnabledSources = [];

        if (adHocSearch.sources) {
            let contentSources = adHocSearch.sources || [];
            userEnabledSources = contentSources.filter((source) => source.checked).map((source) => source.key);
        } else {
            let contentSources = state.user.preferences.generalSettings.contentTypes || [];
            userEnabledSources = contentSources.filter((source) => source.value).map((source) => source.name);
        }

        return userEnabledSources.indexOf(category) > -1;
    },

    areContentTypesChangedInAdHoc(sources) {
        let contentTypes = reduxStore.getState().user.preferences.generalSettings.contentTypes;
        let preparedArrayForApi = [];

        contentTypes.forEach((contentType) => {
            sources.forEach((source) => {
                if (contentType.name === source.key && contentType.value !== source.checked) {
                    preparedArrayForApi.push({ name: source.key, value: source.checked });
                }
            });
        });

        return preparedArrayForApi;
    },

    isDateRangeChangedInAdHoc(sources, categoryName, dateRange) {
        let generalSettings = reduxStore.getState().user.preferences.generalSettings;
        let isDateRangeChanged = false;

        sources.forEach((contentType) => {
            if (
                contentType.key === categoryName &&
                !contentType.disabled &&
                dateRange.range !== generalSettings[dateRange.label]?.dateRange
            ) {
                isDateRangeChanged = true;
            }
        });

        return isDateRangeChanged;
    },

    isNegativeNewsSubcategory(category) {
        if (
            category === CATEGORY_NAMES.NEGATIVE_NEWS_DE ||
            category === CATEGORY_NAMES.NEGATIVE_NEWS_EN ||
            category === CATEGORY_NAMES.NEGATIVE_NEWS_ES ||
            category === CATEGORY_NAMES.NEGATIVE_NEWS_TR ||
            category === CATEGORY_NAMES.NEGATIVE_NEWS_SV ||
            category === CATEGORY_NAMES.NEGATIVE_NEWS_MS ||
            category === CATEGORY_NAMES.NEGATIVE_NEWS_FR ||
            category === CATEGORY_NAMES.NEGATIVE_NEWS_JA ||
            category === CATEGORY_NAMES.NEGATIVE_NEWS_PT ||
            category === CATEGORY_NAMES.NEGATIVE_NEWS_ZH ||
            category === CATEGORY_NAMES.NEGATIVE_NEWS_IT ||
            category === CATEGORY_NAMES.NEGATIVE_NEWS_NL ||
            category === CATEGORY_NAMES.NEGATIVE_NEWS_AR ||
            category === CATEGORY_NAMES.NEGATIVE_NEWS_RU ||
            category === CATEGORY_NAMES.NEGATIVE_NEWS_PL
        ) {
            return category;
        } else {
            return false;
        }
    },

    isDefaultNewsSourcesChangedInAdHoc(newsSearchSources, searchQueryType) {
        let searchTypeUserPrefs = reduxStore.getState().user.preferences[PREFERENCES_KEY[searchQueryType]];
        return newsSearchSources !== searchTypeUserPrefs.newsSearchSources;
    },

    isLegalSubcategory(category) {
        if (
            category === CATEGORY_NAMES.STATE_DOCKETS ||
            category === CATEGORY_NAMES.FEDERAL_DOCKETS ||
            category === CATEGORY_NAMES.AGENCY_DECISION ||
            category === CATEGORY_NAMES.LAW_REVIEWS ||
            category === CATEGORY_NAMES.VERDICTS ||
            category === CATEGORY_NAMES.CASES
        ) {
            return category;
        } else {
            return false;
        }
    },

    isLegalSubcategoryBoolean(category) {
        return (
            category === CATEGORY_NAMES.STATE_DOCKETS ||
            category === CATEGORY_NAMES.FEDERAL_DOCKETS ||
            category === CATEGORY_NAMES.AGENCY_DECISION ||
            category === CATEGORY_NAMES.LAW_REVIEWS ||
            category === CATEGORY_NAMES.VERDICTS ||
            category === CATEGORY_NAMES.CASES
        );
    },

    isNotLegalCategory(category) {
        if (
            category !== CATEGORY_NAMES.STATE_DOCKETS &&
            category !== CATEGORY_NAMES.FEDERAL_DOCKETS &&
            category !== CATEGORY_NAMES.AGENCY_DECISION &&
            category !== CATEGORY_NAMES.LAW_REVIEWS &&
            category !== CATEGORY_NAMES.VERDICTS &&
            category !== CATEGORY_NAMES.CASES &&
            category !== CATEGORY_NAMES.LAW_SOURCES
        ) {
            return category;
        }
    },

    getLegalSubCategory(): string {
        return SOURCES.legalSources.find((source) => utils.isCategoryEnabled(source));
    },

    isPersonSearch(searchType) {
        return searchType === PERSON_SEARCH;
    },

    calculateDisabledSources(sources) {
        let disabledSources = 0;
        sources.map((source) => {
            if (!utils.isLegalSubcategory(source.key) && (source.disabled || !source.show)) {
                disabledSources++;
            }
        });

        return disabledSources;
    },

    calculateCheckedSources(sources) {
        let checkedSources = 0;

        if (sources && sources.length) {
            sources.map((source) => {
                if (!utils.isLegalSubcategory(source.key) && source.checked && source.show) {
                    checkedSources++;
                }
                if (!utils.isLegalSubcategory(source.name) && source.value) {
                    checkedSources++;
                }
            });
        }

        return checkedSources;
    },

    mapUserOfferings(sourcesInfo) {
        if (!sourcesInfo) return [];

        const sources = cloneDeep(sourcesInfo);
        const userOfferings = Object.keys(sources).map((contentType) => ({
            contentType,
            enabled: sources[contentType] && sources[contentType].length > 0,
            available: !!sources[contentType],
        }));

        return userOfferings;
    },

    filterContentTypesByOfferings(userOfferings, contentType) {
        if (!userOfferings || userOfferings.length === 0) return false;

        if (contentType.name === CATEGORY_NAMES.LAW_SOURCES) {
            const availableLegalSubCategories = SOURCES.legalSources.filter((subCategory) => {
                return !!userOfferings.find((item) => item.contentType === subCategory && item.enabled);
            });

            return availableLegalSubCategories && availableLegalSubCategories.length > 0;
        }

        return !!userOfferings.find((item) => item.contentType === contentType.name && item.enabled);
    },

    removeSourcesNotAllowedForSearch(searchType, sources) {
        let filteredCategoriesForPersonSearch = cloneDeep(sources);
        if (searchType === PERSON_SEARCH) {
            filteredCategoriesForPersonSearch.forEach((source) => {
                if (
                    categoryUtils.isDnbCategory(source.key) ||
                    source.key === CATEGORY_NAMES.FINANCIAL_REPORT ||
                    source.key === CATEGORY_NAMES.PUBLIC_RECORDS ||
                    source.key === CATEGORY_NAMES.ESG_RATINGS
                ) {
                    source.show = false;
                }
            });
        } else {
            filteredCategoriesForPersonSearch.forEach((source) =>
                !utils.isLegalSubcategory(source.key) && source.key !== CATEGORY_NAMES.PUBLIC_RECORDS
                    ? (source.show = true)
                    : (source.show = false)
            );
        }

        return filteredCategoriesForPersonSearch;
    },

    getCustomQueryEnglishNegativityString(customQuery) {
        let englishQuery = '';
        if (customQuery) {
            let languages = customQuery.map((item) => {
                return item.language;
            });
            let index = languages.indexOf(CUSTOM_QUERY_NEGATIVITY_STRING_LANGUAGE);
            if (index > -1) {
                englishQuery = customQuery[index].negativityString;
            }
        }
        return englishQuery;
    },

    diff(a, b, reversible) {
        let r = {};

        function deepDiff(a, b, r, reversible) {
            each(a, function (v, k) {
                // already checked this or equal...
                if (Object.prototype.hasOwnProperty.call(r, k) || b[k] === v) return;
                // but what if it returns an empty object? still attach?
                r[k] = isObject(v) ? utils.diff(v, b[k], reversible) : v;
            });
        }

        deepDiff(a, b, r, reversible);
        if (reversible) deepDiff(b, a, r, reversible);
        if (r === {}) r = null;
        return r;
    },

    deepDiffMapper() {
        return {
            VALUE_CREATED: 'created',
            VALUE_UPDATED: 'updated',
            VALUE_DELETED: 'deleted',
            VALUE_UNCHANGED: 'unchanged',
            map: function (obj1, obj2) {
                if (this.isFunction(obj1) || this.isFunction(obj2)) {
                    throw 'Invalid argument. Function given, object expected.';
                }
                if (this.isValue(obj1) || this.isValue(obj2)) {
                    return {
                        type: this.compareValues(obj1, obj2),
                        data: obj1 === undefined ? obj2 : obj1,
                    };
                }

                var diff = {};
                for (var key in obj1) {
                    if (this.isFunction(obj1[key])) {
                        continue;
                    }

                    var value2 = undefined;
                    if ('undefined' != typeof obj2[key]) {
                        value2 = obj2[key];
                    }

                    diff[key] = this.map(obj1[key], value2);
                }
                for (var theKey in obj2) {
                    if (this.isFunction(obj2[theKey]) || 'undefined' != typeof diff[theKey]) {
                        continue;
                    }

                    diff[theKey] = this.map(undefined, obj2[theKey]);
                }

                return diff;
            },
            compareValues: function (value1, value2) {
                if (value1 === value2) {
                    return this.VALUE_UNCHANGED;
                }
                if (this.isDate(value1) && this.isDate(value2) && value1.getTime() === value2.getTime()) {
                    return this.VALUE_UNCHANGED;
                }
                if ('undefined' == typeof value1) {
                    return this.VALUE_CREATED;
                }
                if ('undefined' == typeof value2) {
                    return this.VALUE_DELETED;
                }

                return this.VALUE_UPDATED;
            },
            isFunction: function (obj) {
                return {}.toString.apply(obj) === '[object Function]';
            },
            isArray: function (obj) {
                return {}.toString.apply(obj) === '[object Array]';
            },
            isObject: function (obj) {
                return {}.toString.apply(obj) === '[object Object]';
            },
            isDate: function (obj) {
                return {}.toString.apply(obj) === '[object Date]';
            },
            isValue: function (obj) {
                return !this.isObject(obj) && !this.isArray(obj);
            },
        };
    },

    /**
     * Fetches query parameter values from URLs.
     * Source: {@link https://stackoverflow.com/questions/4068373/center-a-popup-window-on-screen}
     *
     * @param   {string} url    url to open in the popup
     * @param   {string} title  popup title
     * @param   {string} w      popup width
     * @param   {string} h      popup height
     */
    openNewWindowAsCenteredPopup(url, title, w, h) {
        // Fixes dual-screen position                         Most browsers      Firefox
        let dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : window.screen.left;
        let dualScreenTop = window.screenTop != undefined ? window.screenTop : window.screen.top;

        let width = window.innerWidth
            ? window.innerWidth
            : document.documentElement.clientWidth
            ? document.documentElement.clientWidth
            : window.screen.width;
        let height = window.innerHeight
            ? window.innerHeight
            : document.documentElement.clientHeight
            ? document.documentElement.clientHeight
            : window.screen.height;

        let left = width / 2 - w / 2 + dualScreenLeft;
        let top = height / 2 - h / 2 + dualScreenTop;
        let newWindow = window.open(
            url,
            title,
            'scrollbars=yes, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left
        );

        // Puts focus on the newWindow
        if (window.focus) {
            newWindow.focus();
        }
    },

    convertToHierarchy(paths /* array of array of strings */) {
        // Build the node structure
        const rootNode = { name: 'root', children: [] };

        for (let path of paths) {
            utils.buildNodeRecursive(rootNode, path.split('/'), 0);
        }

        return rootNode;
    },

    buildNodeRecursive(node, path, idx) {
        if (idx < path.length) {
            let item = path[idx];
            let dir = node.children.find((child) => child.name == item);
            if (!dir) {
                node.children.push((dir = { name: item, children: [] }));
            }
            utils.buildNodeRecursive(dir, path, idx + 1);
        }
    },

    flattenTreeValues: (options, showChildren = true) => {
        let values = [];
        let walk = (options, value = '') => {
            options.map((option) => {
                values.push({
                    count: option.count,
                    label: value + option.name,
                    checked: option.checked,
                    labelId: option.labelId,
                });
                const notAllChecked = option.children && option.children.filter((child) => !child.checked).length > 0;
                if (option.children && (showChildren || notAllChecked)) {
                    walk(option.children, value + option.name + '/');
                }
            });
        };
        walk(options);
        return values;
    },

    convertFlatOptions: (options) => {
        let counts = {};
        const paths = options.map((item) => {
            counts[item.label] = { count: item.count, checked: item.checked, labelId: item.labelId };
            return item.label;
        });

        const addCounts = (paths, path = '') => {
            return paths.map((item) => {
                const newPath = path + (path !== '' ? '/' : '') + item.name;
                const { count, checked, labelId } = counts[newPath] || { count: 0, checked: true };
                return {
                    ...item,
                    count,
                    checked,
                    value: item.name,
                    children: item.children ? addCounts(item.children, newPath) : null,
                    labelId,
                };
            });
        };

        // transform from flat to hierarchic
        return addCounts(utils.convertToHierarchy(paths).children);
    },

    areAllChecked: (options) => {
        let checked = true;
        options.forEach((option) => {
            if (!option.checked) {
                checked = false;
            } else if (option.children) {
                if (!utils.areAllChecked(option.children)) {
                    checked = false;
                }
            }
        });
        return checked;
    },

    getComponentInfo(postfilterType) {
        let componentInfo;
        Object.keys(POSTFILTER_COMPONENTS).forEach((key) => {
            if (POSTFILTER_COMPONENTS[key].type === postfilterType) {
                componentInfo = { key, ...POSTFILTER_COMPONENTS[key] };
            }
        });
        return componentInfo;
    },

    getFirstAvailableCategory: (postFilters) => {
        const allCategories = categoryUtils.getFlatList().map((category) => category.key);
        const postFilterCategories = postFilters.map((postFilter) => categoryUtils.postfilterToCategory(postFilter));
        const availableCategories = intersection(allCategories, postFilterCategories);
        return availableCategories[0];
    },

    convertUserLocksArrToObj(userLocksArr) {
        // FE needs the complete object with true/false values (reversed from values received from BE)
        // Example: BE sends unlocked locks, evaluated to true; FE considers true as locked, so the values are reversed here;
        let userLocksObj = Object.assign({}, BE_LOCK_NAMES);
        for (let property in userLocksObj) {
            if (Object.prototype.hasOwnProperty.call(userLocksObj, property)) {
                userLocksObj[property] = !~userLocksArr.indexOf(BE_LOCK_NAMES[property]);
            }
        }
        return userLocksObj;
    },

    convertUserLocksObjToArr(userLocksObj) {
        // BE endpoint needs ONLY the unlocked locks, so we reverse the FE values and filter the array.
        let userLocksArr = [];
        let userLocksRequest = Object.assign({}, userLocksObj);
        for (let property in userLocksRequest) {
            if (Object.prototype.hasOwnProperty.call(userLocksRequest, property) && !userLocksRequest[property]) {
                userLocksArr.push(BE_LOCK_NAMES[property]);
            }
        }
        return userLocksArr;
    },

    negativeNewsLanguageObject(languagesArrayFromPayload, language) {
        return languagesArrayFromPayload.find((object) => object.language === language);
    },

    removeEmptyValues(values) {
        values = values || [];
        return values.filter((value) => {
            return value !== ' ';
        });
    },

    createArrayWithEmptyValues(values) {
        values = values || [];
        return values.filter((value) => {
            return value === ' ';
        });
    },
    getDisplayName(WrappedComponent) {
        return WrappedComponent.displayName || WrappedComponent.name || 'Component';
    },

    translateTitleForCustomNews(subCategory) {
        switch (subCategory) {
            case CATEGORY_NAMES.CUSTOM_NEWS1:
            case CATEGORY_NAMES.CUSTOM_NEWS2:
            case CATEGORY_NAMES.CUSTOM_NEWS3:
                return {
                    titleContent: withCustomNewsTitle(subCategory),
                    shouldBeTranslated: false,
                };
            case CATEGORY_NAMES.LN_CUSTOM_NEWS1:
                return {
                    titleContent: withLNCustomNewsTitle(subCategory),
                    shouldBeTranslated: false,
                };
            case CATEGORY_NAMES.ADMIN_CUSTOM_NEWS1:
            case CATEGORY_NAMES.ADMIN_CUSTOM_NEWS2:
            case CATEGORY_NAMES.ADMIN_CUSTOM_NEWS3:
                return {
                    titleContent: withAdminCustomNewsTitle(subCategory),
                    shouldBeTranslated: false,
                };
            default:
                return {
                    titleContent: { id: 'General.categoryName.label.' + subCategory },
                    shouldBeTranslated: true,
                };
        }
    },

    checkPrRecordsExpired(maxAvailableTime: number, lastAddedRecord?: Date) {
        return lastAddedRecord ? Date.now() - lastAddedRecord > maxAvailableTime : true;
    },
    // this will be removed a sprint from now
    calculateReportAvailability(lastReportUpdate: Date, maxAvailableTime: number, unit: number) {
        const availableTime = Math.ceil((lastReportUpdate - Date.now() + maxAvailableTime) / unit);
        return availableTime <= 0 ? 0 : availableTime;
    },

    calculateTimeUntilReportExpires(firstDocumentExpiryDate: Date, unit: number) {
        const availableTime = Math.ceil((firstDocumentExpiryDate - Date.now()) / unit);
        return availableTime < 0 ? 0 : availableTime;
    },

    filterObject(object, callback) {
        return Object.keys(object)
            .filter((key) => callback(object, key))
            .reduce((obj, key) => {
                obj[key] = object[key];
                return obj;
            }, {});
    },
    getReadableFileSize(bytes: number): string {
        const digitalStorageUnits = {
            BYTES: 'B',
            KYLOBYTES: 'KB',
            MEGABYTES: 'MB',
        };
        if (!isNumber(bytes)) {
            return `0${digitalStorageUnits.BYTES}`;
        }

        if (bytes < 1000) {
            return `${bytes}${digitalStorageUnits.BYTES}`;
        }

        if (bytes < 1000000) {
            return `${Math.round(bytes / 1000)}${digitalStorageUnits.KYLOBYTES}`;
        }

        return `${Math.round(bytes / 1000000)}${digitalStorageUnits.MEGABYTES}`;
    },

    deconstructResultsByMainAndSubcategories(searchResults, searchParams) {
        const getEnabledCategoriesFromResults = (searchResults, categoryName) => {
            return (
                searchResults[categoryName].enabled &&
                categoryUtils.shouldShowInSummary(categoryName) &&
                (searchParams.searchType === COMPANY_SEARCH || categoryName !== CATEGORY_NAMES.FINANCIAL_REPORT)
            );
        };

        const getEnabledSubCategoriesFromResults = (searchResults, categoryName) => {
            return searchResults[categoryName].enabled && categoryUtils.isExtended(categoryName);
        };

        // deconstruct search results in enabled parent categories and enabled child categories
        // the enabled flag reflects ad hoc enabled categories or user preferences enabled categories
        return {
            resultsForMainCategory: utils.filterObject(searchResults, getEnabledCategoriesFromResults),
            resultsForSubcategory: utils.filterObject(searchResults, getEnabledSubCategoriesFromResults),
        };
    },

    filterSubCategoriesByParent(resultsForSubcategory, categoryName) {
        const filterByParent = (obj, childCategoryName) => {
            return categoryUtils.getParent(childCategoryName) === categoryName;
        };

        // filter enabled subcategories by current parent category
        return utils.filterObject(resultsForSubcategory, filterByParent);
    },

    prepareUserPreferencesForSave(prefCopy) {
        delete prefCopy.language;
        delete prefCopy.metadataLanguage;
        delete prefCopy.googleTranslateStatus;
        delete prefCopy.generalSettings.proximities;
        delete prefCopy.generalSettings.dateRanges;
        delete prefCopy.generalSettings.lawSources;
        delete prefCopy.generalSettings.showFilters;
        delete prefCopy.generalSettings.costCodesList;
        delete prefCopy.generalSettings.isCostCodeRequired;
        delete prefCopy.generalSettings.negativityLevel;
        delete prefCopy.generalSettings.chooseOnlyFromCostCodesList;
        prefCopy.generalSettings.enableFuzzy = prefCopy.generalSettings.isFuzzyEnabled;
        delete prefCopy.generalSettings.isFuzzyEnabled;
        if (prefCopy.generalSettings.selectedCostCode === COST_CODE.customCostCode.name) {
            prefCopy.generalSettings.selectedCostCode = '';
            reduxStore.dispatch(actions.changeCostCode(''));
        }
        delete prefCopy.companyCheck.categories;
        delete prefCopy.personCheck.categories;

        return prefCopy;
    },

    htmlDecode(input) {
        let e = document.createElement('textarea');
        e.innerHTML = input;
        // handle case of empty input
        const unescapedText = e.childNodes.length === 0 ? '' : e.childNodes[0].nodeValue;
        let tmp = document.createElement('DIV');
        tmp.innerHTML = unescapedText;
        return tmp.textContent || tmp.innerText || '';
    },

    getHelpUrl(userState: Object, helpUrlType: string): string {
        const { preferences, helpUrlRoot, isAnonymized } = userState;
        let helpLanguage;

        if (isAnonymized) {
            // for anonymized help there is only DE and EN_GB language available currently
            helpLanguage = preferences.language === 'de' ? LOCALES['de'].help : LOCALES['en-gb'].help;
        } else {
            helpLanguage = LOCALES[preferences.language]
                ? LOCALES[preferences.language].help
                : LOCALES[DEFAULT_LANGUAGE].help;
        }

        return helpUrlRoot + HELP_URLS.HELP_URL_LOCALE + helpLanguage + HELP_URLS[helpUrlType].path;
    },

    isSameQuery(requestData, currentQueryParams) {
        let { query, searchType } = currentQueryParams;

        if (requestData.searchQuery !== query || requestData.searchQueryType !== searchType) {
            return false;
        }
        return true;
    },

    // limits string to length, adds
    limitText(text, length, appendText = '...') {
        if (length > -1 && text.length > length + appendText.length) {
            return text.substr(0, length) + appendText;
        } else {
            return text;
        }
    },

    getRenderedHitsFromReferences(refs) {
        let hitsResult = [];
        refs.forEach((ref) => {
            let hits = ref && ref.children ? [...ref.getElementsByClassName('hit')] : null;

            if (hits) {
                hits = hits.filter((element) => element.offsetParent); // get hits only from the article content (exclude title / article title from <head/>)
                hitsResult = [...hitsResult, ...hits];
            }
        });

        return hitsResult;
    },

    isHtmlTextTruncated(el) {
        if (!el) return false;
        return Math.abs(el.scrollHeight - el.clientHeight) > 1 || Math.abs(el.scrollWidth - el.clientWidth) > 1; //support for all browsers;
    },

    visitedArticlesController: {
        getVisitedArticles: () => {
            const visitedArticlesArray = localStorage.getItem('visited_articles_history');
            return visitedArticlesArray ? JSON.parse(visitedArticlesArray) : [];
        },
        setVisitedArticle: (articleId) => {
            let visitedArticles = utils.visitedArticlesController.getVisitedArticles() || [];

            if (!visitedArticles.includes(articleId)) {
                if (visitedArticles.length >= VISITED_ARTICLES_LIMIT) {
                    visitedArticles.pop();
                }
                visitedArticles = [articleId, ...visitedArticles];
                localStorage.setItem('visited_articles_history', JSON.stringify(visitedArticles));
            }
        },
        isVisited: (articleId) => {
            let visitedArticles = utils.visitedArticlesController.getVisitedArticles();
            return visitedArticles && visitedArticles.length ? visitedArticles.indexOf(articleId) > -1 : false;
        },
    },

    isValidSortOption(sortOption) {
        return Object.values(SORT_OPTIONS).indexOf(sortOption) > -1 ? true : false;
    },

    // Clean an object of keys by a certain "condition" -- don't forget to clone your "obj" before passing to this function!
    cleanObject(obj, condition) {
        for (let prop in obj) {
            if (condition(prop, obj[prop])) delete obj[prop];
            else if (typeof obj[prop] === 'object') this.cleanObject(obj[prop], condition);
        }
    },

    getURLParameter(url, name) {
        return (url.split(name + '=')[1] || '').split('&')[0];
    },

    /**
     * Function that is called to sanitize string before any input is being saved on Search string
     * @param queryString
     * @returns {String}
     */

    sanitizeSearchStringInput(queryString: string | null): string {
        if (!queryString) return '';

        return queryString
            .replace(/[\u2018\u2019]/g, "'")
            .replace(/[\u201A\u201B\u201C\u201D\u201F\u201E]/g, '"')
            .replace(/[<>]/ig, '');

    },

    removeLineBreaks(string: string): string {
        return string.replace(/\r?\n|\r/g, '');
    },

    getExtendedSearchParameters(state, categoryKey) {
        let searchType = PREFERENCES_KEY[state.searchParams.searchType];
        let newsQueries = categoryUtils.getNewsQuery(categoryKey, state, searchType);

        if (newsQueries) newsQueries.query = utils.sanitizeSearchStringInput(newsQueries.query);

        return newsQueries;
    },

    /**
     * Adds 'selectedKey' bool to true to each array item in sourceArr that are also present in selectedArr, otherwise
     * false
     * @param sourceArr
     * @param selectedArr
     * @param compareFn
     * @param selectedKey
     */
    markSelectedItems(sourceArr = [], selectedArr = [], compareFn = isEqual, selectedKey = 'selected') {
        const newArr = cloneDeep(sourceArr);
        return newArr.map((item) => {
            let selected = false;
            selectedArr.forEach((selectedItem) => {
                if (compareFn(item, selectedItem)) {
                    selected = true;
                }
            });
            item[selectedKey] = selected;
            return item;
        });
    },

    sanitiseQuery(string) {
        return string.replace(/\r?\n|\r/g, ' ').trim();
    },

    isUboEnabled(state = null) {
        if (!state) {
            state = reduxStore.getState();
        }
        return state.user.appSettings.uboEnabled && utils.isCategoryChecked(CATEGORY_NAMES.DNB, state);
    },

    isUboTypeaheadEnabled(state = null) {
        if (!state) {
            state = reduxStore.getState();
        }
        return state.user.appSettings.uboTypeahead;
    },

    isUboDocumentSnippet(snippet) {
        return snippet.reportSnippetType === UBO_MAIN_CATEGORY;
    },

    isUboArticle(article) {
        return article.links && article.nodes && article.summary && article.tableData;
    },

    disableUbo() {
        reduxStore.dispatch({
            type: TOGGLE_UBO,
            payload: false,
        });

        utils.removeUboContentType();
    },

    enableUbo() {
        // if the whole UBO feature is off, we should not make it available for a certain user
        if (reduxStore.getState().user.appSettings.uboEnabled) {
            reduxStore.dispatch({
                type: TOGGLE_UBO,
                payload: true,
            });

            utils.addUboContentType();
        }
    },

    removeUboContentType() {
        let removeContentType = (contentTypes, action) => {
            if (find(contentTypes, ['name', CATEGORY_NAMES.DNB])) {
                let newContentTypes = contentTypes.filter((contentType) => contentType.name !== CATEGORY_NAMES.DNB);
                reduxStore.dispatch(action(newContentTypes));
            }
        };
        let contentTypes = reduxStore.getState().user.preferences.generalSettings.contentTypes;

        removeContentType(contentTypes, actions.updateContentTypes);
        if (userHas(permissions.admin.edit)) {
            contentTypes = reduxStore.getState().user.adminPreferences.generalSettings.contentTypes;
            removeContentType(contentTypes, actions.updateAdminContentTypes);
        }
    },

    addUboContentType() {
        let addContentType = (sources, action) => {
            let contentTypes = cloneDeep(sources);

            if (!find(contentTypes, ['name', CATEGORY_NAMES.DNB])) {
                let publicRecordContentType = {
                    name: CATEGORY_NAMES.DNB,
                    value: true,
                    reportOrder: contentTypes.length,
                };
                contentTypes.push(publicRecordContentType);
                reduxStore.dispatch(action(contentTypes));
            }
        };

        let contentTypes = reduxStore.getState().user.preferences.generalSettings.contentTypes;
        addContentType(contentTypes, actions.updateContentTypes);

        if (userHas(permissions.admin.edit)) {
            contentTypes = reduxStore.getState().user.adminPreferences.generalSettings.contentTypes;
            addContentType(contentTypes, actions.updateAdminContentTypes);
        }
    },

    // function to be used as argument for sort method from 'lodash'
    // helpful for components that use withSorting hoc
    compare(sortingObject) {
        return function (a, b) {
            let fieldToSortBy = sortingObject.sortBy;

            const firstValue = a[fieldToSortBy]
                ? typeof a[fieldToSortBy] === 'string'
                    ? a[fieldToSortBy].toString().toUpperCase()
                    : a[fieldToSortBy]
                : '';
            const secondValue = b[fieldToSortBy]
                ? typeof b[fieldToSortBy] === 'string'
                    ? b[fieldToSortBy].toString().toUpperCase()
                    : b[fieldToSortBy]
                : '';

            let comparison = 0;
            if (firstValue > secondValue) {
                comparison = 1;
            } else if (firstValue < secondValue) {
                comparison = -1;
            }

            if (sortingObject.direction === SORT_DIRECTION.DESC) {
                return comparison * -1;
            }
            return comparison;
        };
    },

    isPostFilterCustomType(type, customPostFiltersArray: [] = CUSTOM_POST_FILTER_ARRAY) {
        return customPostFiltersArray.some((customFilterType) => type === customFilterType);
    },

    calculateMainBannersHeight() {
        const UPBanner = document.getElementsByClassName('diligence-header__user-preferences slide')[0];
        const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
        const UPBannerHeight = UPBanner && UPBanner.offsetHeight;

        if (UPBannerHeight && UPBannerHeight > 0) {
            return isIE11 ? UPBannerHeight - 1 : UPBannerHeight;
        }

        return isIE11 ? -1 : 0;
    },

    isSingleSourceSearch(postFilterConfiguration) {
        if (!postFilterConfiguration) {
            return '0';
        }

        const sourceFilterType = postFilterConfiguration[POSTFILTER_TYPE.SOURCE];

        if (sourceFilterType) {
            const fieldName = sourceFilterType.searchFieldName;

            if (fieldName === POST_FILTER_SEARCH_PUBLICATION_NAME || fieldName === POST_FILTER_SEARCH_SOURCE_NAME) {
                const checkedSources = sourceFilterType.values.filter((source) => source.checked);
                return checkedSources.length === 1 ? '1' : '0';
            }
        }

        return '0';
    },

    /**
     * Return the last part from the translation key.
     * Used to translate the news source from the deliveries.
     * @param newsSourceName
     */
    mapNewsSourceNameToKey(newsSourceName: string): string {
        const newsSource: NewsSourceType = find(NEGATIVE_NEWS_SELECTED_LANGUAGE, ['name', newsSourceName]);
        if (newsSource) {
            return newsSource.label.split('.').pop();
        }

        return newsSourceName;
    },

    convertLastUpdatedInfoKeys(
        firstKey: string,
        secondKey: string
    ): { lastUpdatedPreferencesDate: number, updatedByAdmin: boolean } {
        const lastUpdatedPreferencesDate = parseInt(firstKey);
        const updatedByAdmin = utils.convertStringToBoolean(secondKey);
        return { lastUpdatedPreferencesDate, updatedByAdmin };
    },

    convertStringToBoolean(value: string): boolean {
        return value === 'false' ? false : true;
    },

    shouldUpdateLastPreferencesUpdateTimestamp(newPrefUpdateInfo: {
        lastupdatedpreferencesdate: string,
        updatedbyadmin: string,
    }): void {
        if (newPrefUpdateInfo) {
            const { lastupdatedpreferencesdate, updatedbyadmin } = newPrefUpdateInfo;
            const convertedNewPrefUpdateInfo = utils.convertLastUpdatedInfoKeys(
                lastupdatedpreferencesdate,
                updatedbyadmin
            );
            reduxStore.dispatch(actions.shouldUpdateTimestampInfo(convertedNewPrefUpdateInfo));
        }
    },

    isArrayPopulated(value: ?Array<any>): boolean {
        return !!value && Array.isArray(value) && value.length > 0;
    },

    sortContentTypesValuesInArray(contentTypes: Array<ContentTypesType>): Array<ContentTypesType> {
        if (contentTypes && this.isArrayPopulated(contentTypes)) {
            contentTypes = utils.sortByProperty(contentTypes, 'reportOrder');
        }

        return contentTypes;
    },

    // saves negative news visualisation toggle
    saveNegativeNewsVisualisationToggle: function (isTrendsExpanded: boolean) {
        reduxStore.dispatch(actions.updateToggleNegativeNewsVisualisation(isTrendsExpanded));
        return userPreferenceApi
            .updateUserPreference({
                userPreferences: {
                    generalSettings: {
                        isTrendsExpanded,
                    },
                },
            })
            .then((response) => {
                return response;
            })
            .catch(() => {
                utils.showNotificationsMessage({
                    messageText: 'General_CoreFunctionality_Error_general.saveError',
                    messageType: 'system-error',
                });
            });
    },

    // saves the styling theme toggle
    saveStylingThemeToggle: function (isDarkMode: boolean) {
        reduxStore.dispatch(actions.updateStylingTheme(isDarkMode));
        return userPreferenceApi
            .updateUserPreference({
                userPreferences: {
                    generalSettings: {
                        isDarkMode,
                    },
                },
            })
            .then((response) => {
                return response;
            })
            .catch(() => {
                utils.showNotificationsMessage({
                    messageText: 'General_CoreFunctionality_Error_general.saveError',
                    messageType: 'system-error',
                });
            });
    },

    numberFormatter: function (number, digits) {
        const lookup = [
            { value: 1, symbol: '' },
            { value: 1e3, symbol: 'k' },
            { value: 1e6, symbol: 'M' },
            { value: 1e9, symbol: 'G' },
            { value: 1e12, symbol: 'T' },
            { value: 1e15, symbol: 'P' },
            { value: 1e18, symbol: 'E' },
        ];

        const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
        const formatterElement = lookup
            .slice()
            .reverse()
            .find(function (formatter) {
                return number >= formatter.value;
            });
        const result = formatterElement
          ? (number / formatterElement.value).toFixed(digits).replace(rx, '$1') + formatterElement.symbol
          : '0';
        return result === '0' && number ? (number).toFixed(digits) : result;
    },

    getLanguageKeyForDate(language) {
        if (!language) {
            return '';
        }

        return language === 'en-gb' || language === 'en-us' ? 'en' : language;
    },

    formatNumbersByUserLocale: function (locale: string, value: number | string, options: Object = {}): string {
        locale = locale || DEFAULT_LANGUAGE;
        const notAvailable = 'N/A';

        if (typeof value === 'string' && value === notAvailable) {
            return notAvailable;
        }

        const formattingOptions = LOCALES[locale].numbersFormattingOptions;
        locale = (formattingOptions && formattingOptions.overrideLocale) || locale;

        return value !== undefined && value !== null ? value.toLocaleString(locale, options) : value;
    },

    getCurrentTime: () => new Date().getTime(),

    splitStringByComma: (str: string): Array<string> => str.replace(/\s/g, '').toLowerCase().split(','),

    //This should be moved in searchUtils.js with the other search helper methods
    getPostFiltersFromSearch: function (sources, searchResults, postFilterConfiguration, searchParams) {
        const postFilters = [];

        sources.forEach((category) => {
            let initialPostFilter = cloneDeep(utils.formatPostFilters(searchResults[category].postFilters));

            //Overriding with values from postFilterConfiguration
            if (postFilterConfiguration) {
                let selectedPostfilters = values(postFilterConfiguration[category]);

                selectedPostfilters.forEach((selectedPostFilter) => {
                    if (selectedPostFilter && selectedPostFilter.searchFieldName !== POST_FILTER_PROXIMITY) {
                        if (
                            selectedPostFilter.searchFieldName !== FUZZY_NAMES &&
                            selectedPostFilter.searchFieldName !== SUGGESTED_NAMES
                        ) {
                            initialPostFilter[selectedPostFilter.searchFieldName] =
                                SearchUtils.getPostfilterSelection(selectedPostFilter);
                        }

                        if (
                            selectedPostFilter.searchFieldName === FILTER_INFO.SUBJECT &&
                            initialPostFilter[selectedPostFilter.searchFieldName]
                        ) {
                            if (
                                postFilterConfiguration[category] &&
                                postFilterConfiguration[category][POSTFILTER_TYPE.SUBJECT] &&
                                postFilterConfiguration[category][POSTFILTER_TYPE.SUBJECT].values
                            ) {
                                let fullSubject = postFilterConfiguration[category][POSTFILTER_TYPE.SUBJECT].values;
                                initialPostFilter[POST_FILTER_LABELS.SUBJECT] = SearchUtils.getFilterLabelsBasedOnIds(
                                    initialPostFilter[selectedPostFilter.searchFieldName],
                                    fullSubject
                                );
                            }
                        }

                        if (
                            selectedPostFilter.searchFieldName === FILTER_INFO.INDUSTRY &&
                            initialPostFilter[selectedPostFilter.searchFieldName]
                        ) {
                            if (
                                postFilterConfiguration[category] &&
                                postFilterConfiguration[category][POSTFILTER_TYPE.INDUSTRY] &&
                                postFilterConfiguration[category][POSTFILTER_TYPE.INDUSTRY].values
                            ) {
                                let fullIndustry = postFilterConfiguration[category][POSTFILTER_TYPE.INDUSTRY].values;
                                initialPostFilter[POST_FILTER_LABELS.INDUSTRY] = SearchUtils.getFilterLabelsBasedOnIds(
                                    initialPostFilter[selectedPostFilter.searchFieldName],
                                    fullIndustry
                                );
                            }
                        }

                        if (
                            selectedPostFilter.searchFieldName === FILTER_INFO.ESG_FACTORS &&
                            initialPostFilter[selectedPostFilter.searchFieldName]
                        ) {
                            if (
                                postFilterConfiguration[category] &&
                                postFilterConfiguration[category][POSTFILTER_TYPE.ESG_FACTORS] &&
                                postFilterConfiguration[category][POSTFILTER_TYPE.ESG_FACTORS].values
                            ) {
                                let fullEsgFactors =
                                    postFilterConfiguration[category][POSTFILTER_TYPE.ESG_FACTORS].values;
                                initialPostFilter[POST_FILTER_LABELS.ESG_FACTORS] =
                                    SearchUtils.getFilterLabelsBasedOnIds(
                                        initialPostFilter[selectedPostFilter.searchFieldName],
                                        fullEsgFactors
                                    );
                            }
                        }
                    }
                });
            }

            let { pageSize, count, currentPageIndex } = searchResults[category];
            const postFilter = Object.assign(initialPostFilter, {
                pageSize,
                category: category,
                numberOfResults: count,
                pageNumber: currentPageIndex,
            });
            if (searchParams.isCustomFuzzy) {
                postFilter.isCustomFuzzy = searchParams.isCustomFuzzy;
            }
            postFilters.push(withContentSourceFilter().extendConfig(category, postFilter));
        });

        return postFilters;
    },

    formatPostFiltersForApiPayload(initialPostFilter) {
        let postFiltersForPayload = {};
        let entityCategories, allCategories, disabledCategories;
        entityCategories = allCategories = disabledCategories = [];

        if (!initialPostFilter || !initialPostFilter.length) {
            return { postFiltersForPayload, entityCategories, disabledCategories };
        }

        // fixing issue generated by public records added
        const currentPostFilters = initialPostFilter.filter(
            (postFilter) => postFilter.category !== CATEGORY_NAMES.PUBLIC_RECORDS
        );
        let updatedCategories = [];
        currentPostFilters.forEach((postFilter) => {
            if (postFilter && postFilter.negativityLevels && postFilter.negativityLevels.length) {
                // Update news queries based on negativity levels
                postFilter = updateNewsQueriesBasedOnNegativityLevels(postFilter);
            }
            updatedCategories.push({
                name: postFilter.category,
                postFilters: postFilter,
                enabled: true,
            });
            const category = categoryUtils.postfilterToCategory(postFilter);
            postFiltersForPayload[category] = {
                ...postFilter,
                category,
            };
        });

        reduxStore.dispatch(searchResultsActions.updateCategoriesList(updatedCategories));

        // disabling the rest of categories
        const entityCategoriesFromPostfilters = currentPostFilters.map((postFilter) => categoryUtils.postfilterToCategory(postFilter));
        allCategories = categoryUtils.getFlatList().map((category) => category.key);
        entityCategories = allCategories.filter((category) => entityCategoriesFromPostfilters.includes(category));
        disabledCategories = difference(allCategories, entityCategories);

        return { postFiltersForPayload, entityCategories, disabledCategories };
    },

    updateFilteredCategoriesAndPostfilterConfig(entityCategories, defaultPostFilters, postFilters, searchQueryType, searchRunFromScreening) {
        if(!searchRunFromScreening) {
            let updatedCategories = [];
            entityCategories.forEach((categoryName) => {
                updatedCategories.push({
                    name: categoryName,
                    enabled: true,
                });
            });
            reduxStore.dispatch(searchResultsActions.updateCategoriesList(updatedCategories));
        }

        let postFilterDataToBeUpdated = [];
        entityCategories.forEach((categoryName) => {
            let postFilterConfig = cloneDeep(reduxStore.getState().postFilterConfiguration[categoryName]);
            let categoryPostfilters = postFilters[categoryName];

            if (Object.keys(LEGAL_SOURCES_TITLES).indexOf(categoryName) !== -1) {
                defaultPostFilters.searchNamePartyEntity =
                    reduxStore.getState().adHocSearch.searchNamePartyEntity ||
                    reduxStore.getState().user.preferences.generalSettings.legalSources.searchNamePartyEntity ||
                    false;
            }

            const postFilterObj = retrievePostFilterData(
                postFilterConfig,
                categoryPostfilters,
                categoryName,
                searchQueryType
            );
            postFilterDataToBeUpdated.push(...postFilterObj);
        });

        reduxStore.dispatch(postFilterConfigActions.updatePostFilterConfigValuesList(postFilterDataToBeUpdated));
    },

    updateDisabledCategories(disabledCategories) {
        return disabledCategories.map((categoryName) => ({
            name: categoryName,
            enabled: false,
        }));
    },

    disableElementScroll: (elementId) => {
        const tableScrollableArea = document.getElementById(elementId);

        tableScrollableArea && tableScrollableArea.addEventListener(UI_EVENTS.WHEEL, preventScroll);
    },

    enableElementScroll: (elementId) => {
        const tableScrollableArea = document.getElementById(elementId);

        tableScrollableArea && tableScrollableArea.removeEventListener(UI_EVENTS.WHEEL, preventScroll);
    },

    onlySpaces: (str) => {
        return str.trim().length === 0;
    },

    isEntityNameValid: (entityName) => {
        const isEmptyString = /^ *$/g;
        const invalidCharactersRegex = /[^a-zA-Z0-9_(@#!%^+=~'`£?*[\])&\-:; \u011F\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\u3000\u3400-\u4DBF\u4dff\u4E00-\u9FFF\u0600-\u06FF\u0400-\u04FF\u0E00-\u0E7F\u0150-\u0151\u0170-\u0171{0,9}$./]/g;

        return !(invalidCharactersRegex.test(entityName) || isEmptyString.test(entityName));
    },

    areContentTypesApplicableWithSearchType: (contentTypes, searchType) => {
        if (searchType === COMPANY_SEARCH) {
            return true;
        }
        const preferedContentTypes = contentTypes.filter((contentType) => contentType.value);

        return preferedContentTypes.some(
            (preferedType) => !CATEGORIES_EXCLUDED_FROM_PERSON_SEARCH.includes(preferedType.name)
        );
    },

    getTypeOfUploadEntitiesWarningMessageBasedOnContentTypes: (
        companyOnlyPostFilters,
        companyEntitiesCount,
        personEntitiesCount
    ) => {
        if (!companyOnlyPostFilters) {
            return UPLOAD_ENTITIES_WARNINGS_AND_ERRORS.noWarning;
        }
        if (companyOnlyPostFilters && companyEntitiesCount && !personEntitiesCount) {
            return UPLOAD_ENTITIES_WARNINGS_AND_ERRORS.noWarning;
        }
        if (companyEntitiesCount && personEntitiesCount) {
            return UPLOAD_ENTITIES_WARNINGS_AND_ERRORS.companyAndPersonWarning;
        }
        if (!companyEntitiesCount && personEntitiesCount) {
            return UPLOAD_ENTITIES_WARNINGS_AND_ERRORS.personWarning;
        }
        return UPLOAD_ENTITIES_WARNINGS_AND_ERRORS.noWarning;
    },

    disableEntityView() {
        reduxStore.dispatch({
            type: TOGGLE_ENTITY_VIEW_ENABLE,
            payload: false,
        });
    },

    enableEntityView() {
        // if the whole EV feature is off by default, we should not make it available for a certain user
        reduxStore.dispatch({
            type: TOGGLE_ENTITY_VIEW_ENABLE,
            payload: true,
        });
    },

    unsetEntityViewTrial() {
        reduxStore.dispatch({
            type: TOGGLE_ENTITY_VIEW_TRIAL,
            payload: false,
        });
    },

    setEntityViewTrial() {
        // the user also needs to have multiple entities enable. Trial version doesn’t automatically enable EV
        reduxStore.dispatch({
            type: TOGGLE_ENTITY_VIEW_TRIAL,
            payload: true,
        });
    },

    unsetIsSpecifiedCustomer() {
        reduxStore.dispatch({
            type: TOGGLE_IS_SPECIFIED_CUSTOMER,
            payload: false,
        });
    },

    setIsSpecifiedCustomer() {
        reduxStore.dispatch({
            type: TOGGLE_IS_SPECIFIED_CUSTOMER,
            payload: true,
        });
    },

    displayEntityViewMarketingInfo() {
        reduxStore.dispatch({
            type: TOGGLE_MARKETING_ENTITY_VIEW,
            payload: true,
        });
    },

    hideEntityViewMarketingInfo() {
        reduxStore.dispatch({
            type: TOGGLE_MARKETING_ENTITY_VIEW,
            payload: false,
        });
    },

    getExcelEntityObject: ([
        entityName = '',
        entityType = '',
        alertName = '',
        sendTo = '',
        comments = '',
        frequency = '',
        sendAs = '',
        fileFormat = '',
    ]) => {
        return {
            'Entity Name': entityName,
            'Entity Type': entityType,
            'Alert Name': alertName,
            'Send This Alert To:': sendTo,
            Comments: comments,
            Frequency: frequency,
            'Send as': sendAs,
            'File Format': fileFormat,
        };
    },
    removeBatchFromQueue(batch) {
        const batchQueue = reduxStore.getState().entityViewInformation.batchQueue;
        const filteredBatch = batchQueue.filter((batchId) => batchId !== batch);
        reduxStore.dispatch(mainActions.updateBatchQueue(filteredBatch));
    },
    clearBatchQueue: function () {
        reduxStore.dispatch(mainActions.updateBatchQueue([]));
    },
    clearBlockedEntities: function () {
        reduxStore.dispatch(mainActions.updateBlockedEntities([]));
    },
    getInProgressBatchIds: function(list) {
        let sanitizedList = {};
        let notificationWasSanitized = false;
        Object.keys(list).forEach((batchId) => {
            const { processing } = list[batchId];
            if (processing) {
                sanitizedList[batchId] = list[batchId];
            } else {
                //if the batchId has finished processing it won't be included in the list,
                //therefore the list was sanitized
                notificationWasSanitized = true;
            }
        });
        return {
            notificationWasSanitized,
            sanitizedList
        };
    },
    sanitizeNotifications: function(notificationStatus = {}) {
        const notificationStatusKeys = Object.keys(notificationStatus);
        if (!notificationStatusKeys.length) return;

        const sanitizedNotificationStatus = {};
        let notificationsWereSanitized = false;

        notificationStatusKeys.forEach((notificationKey) => {
            if (Object.keys(notificationStatus[notificationKey].batchIdsList).length !== 0) {
                const { sanitizedList, notificationWasSanitized } = this.getInProgressBatchIds(notificationStatus[notificationKey].batchIdsList);

                notificationsWereSanitized = notificationsWereSanitized || notificationWasSanitized;
                sanitizedNotificationStatus[notificationKey] = {
                    batchIdsList: sanitizedList,
                    hidden: notificationStatus[notificationKey].hidden || Object.keys(sanitizedList).length === 0
                };
            } else {
                sanitizedNotificationStatus[notificationKey] = {
                    batchIdsList: {},
                    hidden: true
                };
            }
        });

        return {
            notificationsWereSanitized,
            sanitizedNotificationStatus
        };
    },
    /**
     * Retrieves the count if any date range in postFilterConfigValues is checked, otherwise returns the default date provided.
     * @param {string} categoryName - The name of the category.
     * @param {Array<Object>} postFilterConfigValues - The configuration values for post filters.
     * @param {string} date - The default date range based on the user preference for that source, or the range provided in an adhoc search.
     * @returns {string} - The count associated with the date range or the provided default date. e.g. 'all', '1m'
     */
    getDateRangeCount(categoryName, postFilterConfigValues, date) {
        const postFilterDateRange = postFilterConfigValues?.find((date) => date.checked);
        if (postFilterDateRange) return postFilterDateRange.count;

        console.log(`Setting date range for category: ${categoryName} to default: ${date}`);
        return date;
    },
    shouldDisplaySaveSearchButton: function (isBatchReportsEnabled, launchedFrom, currentFlow) {
        // Check if launched from entity view
        const launchedFromEntityView = isBatchReportsEnabled && launchedFrom === LAUNCHED_SEARCH_FROM.SCREENING_PAGE;
        // Check if launched from results list
        const launchedFromResultsList = isBatchReportsEnabled && launchedFrom === LAUNCHED_SEARCH_FROM.RESULTS_PAGE;
        // Display the save search button only if launched from entity view
        if (currentFlow === LAUNCHED_SEARCH_FROM.SNAPSHOT_PAGE) return launchedFromEntityView;
        // Display the save search button if launched from entity view or results list
        if (currentFlow === LAUNCHED_SEARCH_FROM.RESULTS_PAGE) return launchedFromEntityView || launchedFromResultsList;
        // Default case: do not display the save search button
        return false;
    },
    retractKeyFromObject(object, key) {
        // eslint-disable-next-line unused-imports/no-unused-vars
        const { [key]: deletedKey, ...rest } = object;
        return { ...rest };
    },
    computeNotificationStatusPayload(notificationStatus, status, batchId, processedCount, totalCount, errorCount) {
        if (!Object.keys(notificationStatus).length) return notificationStatus;
        let { refreshEntitiesStatus, uploadEntitiesStatus, ...rest } = notificationStatus;

        if (batchId in refreshEntitiesStatus) {
            if (status === SCREENING_ENTITY_STATUSES.NOT_FOUND) {
                refreshEntitiesStatus = this.retractKeyFromObject(refreshEntitiesStatus, batchId);
            } else {
                refreshEntitiesStatus[batchId] = {
                    ...refreshEntitiesStatus[batchId],
                    status,
                    batchId,
                    processedCount,
                    totalCount,
                    errorCount,
                    processing: status === SCREENING_ENTITY_STATUSES.PROCESSING,
                };
            }
        }
        if (batchId in uploadEntitiesStatus) {
            if (status === SCREENING_ENTITY_STATUSES.NOT_FOUND) {
                uploadEntitiesStatus = this.retractKeyFromObject(uploadEntitiesStatus, batchId);
            } else {
                uploadEntitiesStatus[batchId] = {
                    ...uploadEntitiesStatus[batchId],
                    status,
                    batchId,
                    processedCount,
                    totalCount,
                    errorCount,
                    processing: status === SCREENING_ENTITY_STATUSES.PROCESSING,
                };
            }
        }

        return {
            ...rest,
            refreshEntitiesStatus,
            uploadEntitiesStatus,
        };
    },

    getNextAlertBatchIdForPolling(createAlertsStatus) {
        if(!createAlertsStatus) return;

        let nextBatchId;
        for (const [id, alert] of Object.entries(createAlertsStatus)) {
            const { processing } = alert;
            if (processing) nextBatchId = id;
        }

        return nextBatchId;
    },

    validateViewName(viewName, initialState, intl) {
        let validationResult = { ...initialState }

        const viewNameValidation = utils.isViewNameValid(viewName);

        if (viewNameValidation.invalidLength) {
            validationResult.errorMessage = formatRichMessage(
                {id: 'BatchScreening.page.createViews.ViewNameValidation.CharactersLimitCount'},
                intl,
                {count: viewNameValidation.maxLength}
            );
        }

        if (viewNameValidation.invalidCharacters || viewNameValidation.currentLength === 0){
            validationResult.errorMessage = formatRichMessage(
                { id: 'BatchScreening.page.createViews.ViewNameValidation.ValidReportTitle' },
                intl
            );
        }

        validationResult.isValid = !(viewNameValidation.invalidCharacters || viewNameValidation.invalidLength);

        return validationResult;
    },
};

export const withOptimizedSearch = ({ contentSource, state }) => ({
    ...categoryUtils,
    extendConfig: (categoryKey, config) => {
        const extendConfig = categoryUtils.extendConfig(categoryKey, config);
        if (!extendConfig) {
            return null;
        }
        const store = state || reduxStore.getState();
        const queryParams = window.location.href ? utils.getAllUrlParams(window.location.href) : {};
        let newsSourceName;
        if(store.breadcrumbs.prevPath === ROUTES.SCREENING) {
            if(queryParams.searchFrom && queryParams.searchFrom === 'header') {
                newsSourceName = categoryUtils.getContentNewsSource(store, categoryKey);
            } else {
                newsSourceName = contentSource;
            }
        } else {
            newsSourceName = contentSource || categoryUtils.getContentNewsSource(store, categoryKey);
        }
        const newsQuery = utils.getExtendedSearchParameters(store, categoryKey, config);
        const contentLanguage = categoryUtils.getLanguagesForCustomNews(store, categoryKey);

        let newConfig = {
            ...extendConfig,
            version: API_SEARCH_VERSION,
        };

        if (newsQuery) {
            newConfig = { ...newConfig, newsQueries: [newsQuery] };
            if (categoryUtils.isChildOf(CATEGORY_NAMES.CUSTOM_NEWS, categoryKey)) {
                // If the new config already has a content language, it has been set from the alert, we should use it
                if (newConfig.contentLanguage) {
                    newsQuery.contentLanguage = newConfig.contentLanguage;
                    newsSourceName = newConfig.contentSource;
                } else {
                    if (contentLanguage && store.breadcrumbs.prevPath !== ROUTES.SCREENING) {
                        newsQuery.contentLanguage = newConfig.contentLanguage = contentLanguage;
                    }
                }
            }
        }

        if (categoryKey === CATEGORY_NAMES.NEWS) {
            if (newConfig.contentLanguage) {
                newsSourceName = newConfig.contentSource;
            } else {
                if (contentLanguage && store.breadcrumbs.prevPath !== ROUTES.SCREENING) {
                    newConfig.contentLanguage = contentLanguage;
                }
            }
        }

        if (newsSourceName) newConfig = { ...newConfig, contentSource: newsSourceName };
        return newConfig;
    },
});

export const withOptimizedSearchCount = (contentSource) => ({
    ...categoryUtils,
    extendConfig: (categoryKeys, config) => {
        if (!Array.isArray(categoryKeys) || categoryKeys.length === 0) {
            return config;
        }
        //TODO: implement the old way
        let newsQueries = [];
        let extendConfig = categoryUtils.extendConfig(categoryKeys[0], config);
        let state = reduxStore.getState();
        const queryParams = window.location.href ? utils.getAllUrlParams(window.location.href) : {};

        let newsSourceName;
        if(state.breadcrumbs.prevPath === ROUTES.SCREENING) {
            if(queryParams.searchFrom && queryParams.searchFrom === 'header') {
                newsSourceName = categoryUtils.getContentNewsSource(state, categoryKeys[0]);
            } else {
                newsSourceName = contentSource ?? null;
            }
        } else {
            newsSourceName = contentSource || categoryUtils.getContentNewsSource(state, categoryKeys[0]);
        }

        let newConfig = {
            ...extendConfig,
            version: API_SEARCH_VERSION,
            contentSource: newsSourceName,
        };
        if (state.searchParams.searchType !== COMPANY_SEARCH) {
            newConfig = {
                ...newConfig,
                fuzzyMatch: state.user.preferences.generalSettings.fuzzyMatch,
                enableFuzzy: state.user.preferences.generalSettings.isFuzzyEnabled,
                proximity: state.user.preferences.generalSettings.proximity,
            };
        }
        categoryKeys.forEach((categoryKey) => {
            let newsQuery = utils.getExtendedSearchParameters(state, categoryKey);
            if (newsQuery) {
                if (categoryUtils.isChildOf(CATEGORY_NAMES.CUSTOM_NEWS, categoryKey)) {
                    newsQuery.contentLanguage = categoryUtils.getLanguagesForCustomNews(
                        reduxStore.getState(),
                        categoryKey
                    );
                }
                newsQueries.push(newsQuery);
            }
        });
        newConfig = {
            ...newConfig,
            newsQueries,
        };

        return newConfig;
    },
});

/**
 * Used to add any news source to the post-filters when needed
 * adds the name of the source as it appears in user preferences
 * add any source not just the one of the five sent to search
 * used on deliveries and add to report action to show to news source on the cover page
 * @param categoryKey
 * @param config
 * @param state
 */
export const addNewsSource = (categoryKey: string, config: Object, state: Object): Object => {
    const newsSourceLabel: string = categoryUtils.getContentNewsSource(state, categoryKey, 'label');

    return {
        ...config,
        newsSource: newsSourceLabel.split('.').pop(), //send only the last part from the translation key
    };
};

/**
 * Used to add contentSource to the category configuration when needed
 * when searching from history, the content source is passed
 * @param contentSource
 */
export const withContentSourceFilter = (contentSource) => ({
    ...categoryUtils,
    extendConfig: (categoryKey, config, state) => {
        const extendConfig = categoryUtils.extendConfig(categoryKey, config);
        if (!extendConfig) {
            return null;
        }
        //@TODO this is not ideal, but adding state as a param to all the calls of this method means a heavy refactoring, and it is included in a tech debt ticket
        if (!state) state = reduxStore.getState();

        if (state.user.useOptimizedSearch === true) {
            //the optimized way to run search with all parameters sent from frontend
            return withOptimizedSearch({ contentSource, state }).extendConfig(categoryKey, config);
        }
        const newsSourceName = contentSource || categoryUtils.getContentNewsSource(state, categoryKey);

        let newConfig = {
            ...extendConfig,
            contentSource: newsSourceName,
        };
        //add the corresponding language for custom news
        if (
            categoryUtils.isChildOf(CATEGORY_NAMES.CUSTOM_NEWS, categoryKey) ||
            categoryKey === CATEGORY_NAMES.CUSTOM_NEWS ||
            categoryKey === CATEGORY_NAMES.NEWS
        ) {
            const contentLanguage = categoryUtils.getLanguagesForCustomNews(state, categoryKey);

            if (contentLanguage) {
                newConfig.contentLanguage = contentLanguage;
            }
        }
        return newConfig;
    },
});

const preventScroll = (e) => {
    e.preventDefault();
    e.stopPropagation();

    return false;
};

export default utils;

/*
// To enable tracking, uncomment this and watch the console, but don't commit it!

// tracking functions
const trackedFunctions = [
    "getLegalSubCategory",
    "getHelpUrl",
    "replaceAllStr",
    "sanitiseQuery"
]

export default {
    ...utils,
    ...getWrappedFnTracks(trackedFunctions, utils)
};*/
