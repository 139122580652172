import React from 'react';
import formatRichMessage from '@utils/formatRichMessage';
import { injectIntl } from 'react-intl';

const ModalRiskScores = (props) => {
    if (!props.isVisible) {
        return <span />;
    }

    const topMessageText = formatRichMessage({ id: 'RiskScoresPopup.message.riskScoresTerms' }, props.intl, {
        a: (chunks) => `<a href='#/user-preferences'>${chunks.join('')}</a>`,
    });

    const bottomMessageText = formatRichMessage({ id: 'RiskScoresPopup.message.riskScoresLiability' }, props.intl, {
        a: (chunks) => `<a href='#/user-preferences'>${chunks.join('')}</a>`,
    });
    return (
        <div className="popup-modal__risk-scores-body">
            <div className="risk-scores-top-message" dangerouslySetInnerHTML={{ __html: topMessageText }} />
            <div className="risk-scores-bottom-message" dangerouslySetInnerHTML={{ __html: bottomMessageText }} />
        </div>
    );
};

export default injectIntl(ModalRiskScores);
