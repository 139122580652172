import React from 'react';
import { Loader } from 'semantic-ui-react';

const OverlaySpinner = ({ className, size }) => (
    <div className={className}>
        <Loader size={size} active />
    </div>
);

export default OverlaySpinner;
