//@flow

import ReportBuilderService from '../ReportBuilderService';
import currentReportActions from './CurrentReport.actions';
import ReportBuilderUtils from '@pages/ReportBuilder/utils/ReportBuilderUtils';
import ReportBuilderApi from '../ReportBuilderMain.api';
import _ from 'lodash';
import type {
    State,
    Action,
    ChildReport,
    CurrentReport,
    DeliveryData,
    LatestDocument,
    Category,
    Snippet,
    Report,
    ExpandedReport,
    CombinedReportType,
    ReportBuilderState,
    LiteChildReport,
    GenericReportType,
    ParentReport,
} from './flow/ReportBuilder.type.guards';

export const SELECTED_REPORT: 'SELECTED_REPORT' = ('SELECTED_REPORT': 'SELECTED_REPORT');
export const SELECTED_REPORT_BY_ID: 'SELECTED_REPORT_BY_ID' = ('SELECTED_REPORT_BY_ID': 'SELECTED_REPORT_BY_ID');
export const DISCARD_REPORT: 'DISCARDED_REPORT' = ('DISCARDED_REPORT': 'DISCARDED_REPORT');
export const SET_REPORT_COUNT: 'SET_REPORT_COUNT' = ('SET_REPORT_COUNT': 'SET_REPORT_COUNT');
export const SET_REPORTS: 'SET_REPORTS' = ('SET_REPORTS': 'SET_REPORTS');
export const SET_LOADING_STATUS: 'SET_LOADING_STATUS' = ('SET_LOADING_STATUS': 'SET_LOADING_STATUS');
export const RESET_REPORTBUILDER_STATE: 'RESET_REPORTBUILDER_STATE' =
    ('RESET_REPORTBUILDER_STATE': 'RESET_REPORTBUILDER_STATE');
export const DELETE_REPORT: 'DELETE_REPORT' = ('DELETE_REPORT': 'DELETE_REPORT');
export const DELETE_DOCUMENT: 'DELETE_DOCUMENT' = ('DELETE_DOCUMENT': 'DELETE_DOCUMENT');
export const UPDATE_NOTE_ON_ARTICLE: 'UPDATE_NOTE_ON_ARTICLE' = ('UPDATE_NOTE_ON_ARTICLE': 'UPDATE_NOTE_ON_ARTICLE');
export const DELETE_NOTE_FROM_ARTICLE: 'DELETE_NOTE_FROM_ARTICLE' =
    ('DELETE_NOTE_FROM_ARTICLE': 'DELETE_NOTE_FROM_ARTICLE');
export const UPDATE_CURRENT_PAGE: 'UPDATE_CURRENT_PAGE' = ('UPDATE_CURRENT_PAGE': 'UPDATE_CURRENT_PAGE');
export const UPDATE_REPORT_DELIVERY: 'UPDATE_REPORT_DELIVERY' = ('UPDATE_REPORT_DELIVERY': 'UPDATE_REPORT_DELIVERY');
export const UPDATE_REPORT_DELIVERED_STATUS: 'UPDATE_REPORT_DELIVERED_STATUS' =
    ('UPDATE_REPORT_DELIVERED_STATUS': 'UPDATE_REPORT_DELIVERED_STATUS');
export const UPDATE_REPORT_PROPERTY: 'UPDATE_REPORT_PROPERTY' = ('UPDATE_REPORT_PROPERTY': 'UPDATE_REPORT_PROPERTY');
export const UPDATE_LITE_CHILD_REPORTS_TITLE: 'UPDATE_LITE_CHILD_REPORTS_TITLE' =
    ('UPDATE_LITE_CHILD_REPORTS_TITLE': 'UPDATE_LITE_CHILD_REPORTS_TITLE');
export const DISCARD_SELECTED_REPORT: 'DISCARD_SELECTED_REPORT' =
    ('DISCARD_SELECTED_REPORT': 'DISCARD_SELECTED_REPORT');
export const REORDER_CHILD_REPORTS_FOR_COMBINED: 'UPDATE_CHILD_REPORTS_ORDER' =
    ('UPDATE_CHILD_REPORTS_ORDER': 'UPDATE_CHILD_REPORTS_ORDER');
export const UPDATE_REPORT_DOCUMENTS_COUNTS: 'UPDATE_REPORT_DOCUMENTS_COUNTS' =
    ('UPDATE_REPORT_DOCUMENTS_COUNTS': 'UPDATE_REPORT_DOCUMENTS_COUNTS');
export const ADD_BATCH_REPORT_TO_POOLING: 'ADD_BATCH_REPORT_TO_POOLING' =
    ('ADD_BATCH_REPORT_TO_POOLING': 'ADD_BATCH_REPORT_TO_POOLING');
export const REMOVE_BATCH_REPORT_FROM_POOLING: 'REMOVE_BATCH_REPORT_FROM_POOLING' =
    ('REMOVE_BATCH_REPORT_FROM_POOLING': 'REMOVE_BATCH_REPORT_FROM_POOLING');
export const UPDATE_LAST_BATCH_REPORT_PROPERTY: 'UPDATE_LAST_BATCH_REPORT_PROPERTY' =
    ('UPDATE_LAST_BATCH_REPORT_PROPERTY': 'UPDATE_LAST_BATCH_REPORT_PROPERTY');
export const UPDATE_POLLING_STATUS: 'UPDATE_POLLING_STATUS' = ('UPDATE_POLLING_STATUS': 'UPDATE_POLLING_STATUS');

type GetState = () => State;
type PromiseAction = Promise<Action>;
type ThunkAction = (dispatch: Dispatch, getState: GetState) => any;
type Dispatch = (action: Action | ThunkAction | PromiseAction | Array<Action>) => any;

const reportBuilderActions = {
    addBatchReportToPooling(reportId: string): Action {
        return {
            type: ADD_BATCH_REPORT_TO_POOLING,
            payload: reportId,
        };
    },
    removeBatchReportFromPooling(reportId: string): Action {
        return {
            type: REMOVE_BATCH_REPORT_FROM_POOLING,
            payload: reportId,
        };
    },

    updateLastBatchReportProperty(batchPropertyName: string, batchPropertyValue: mixed): Action {
        return {
            type: UPDATE_LAST_BATCH_REPORT_PROPERTY,
            payload: { batchPropertyName, batchPropertyValue },
        };
    },

    updateReportProperty(reportId: string, propertyName: string, propertyValue: mixed): Action {
        return {
            type: UPDATE_REPORT_PROPERTY,
            payload: { reportId, propertyName, propertyValue },
        };
    },

    updateLiteChildReportsTitle(parentId: string, childReportId: string, childReportTitle: string): Action {
        return {
            type: UPDATE_LITE_CHILD_REPORTS_TITLE,
            payload: { parentId, childReportId, childReportTitle },
        };
    },

    resetReportBuilderState(): { type: string } {
        return {
            type: RESET_REPORTBUILDER_STATE,
        };
    },

    setReportsCount(reportCount: number): Action {
        return {
            type: SET_REPORT_COUNT,
            payload: reportCount,
        };
    },

    setSelectedReport(selectedReportId: string | null): Action {
        return {
            type: SELECTED_REPORT,
            payload: selectedReportId,
        };
    },

    discardSelectedReport(reportId: string): Action {
        return {
            type: DISCARD_REPORT,
            payload: reportId,
        };
    },

    setSelectedReportById(reportId: string): Action {
        return {
            type: SELECTED_REPORT_BY_ID,
            payload: reportId,
        };
    },

    discardSelectedReportById(): Action {
        return {
            type: DISCARD_SELECTED_REPORT,
        };
    },

    updateChildReportsSort(reportId: string, childReports: Array<ChildReport>): Action {
        return {
            type: REORDER_CHILD_REPORTS_FOR_COMBINED,
            payload: { reportId, childReports },
        };
    },

    setReports(reports: Array<Report>): Action {
        return {
            type: SET_REPORTS,
            payload: reports,
        };
    },

    setLoadingStatus(status: boolean): Action {
        return {
            type: SET_LOADING_STATUS,
            payload: status,
        };
    },

    updateDeliveryStatusesForChildAndParent:
        (reportId: string, parentIds: Array<string | void> = []): ThunkAction =>
        async (dispatch, getState) => {
            if (reportId && parentIds && !parentIds.length) {
                parentIds = ReportBuilderUtils.extractParentReportIds(getState(), reportId);
            }

            const { deliveryData, reports }: { deliveryData: Array<DeliveryData>, reports: Array<Report> | void } =
                await ReportBuilderService.loadReportDeliveryStateForMultipleReports([reportId, ...parentIds]);

            deliveryData.forEach((data) => {
                dispatch({
                    type: UPDATE_REPORT_DELIVERY,
                    payload: { ...data },
                });
            });

            if (reports) {
                reports.forEach((report) => {
                    dispatch({
                        type: UPDATE_REPORT_PROPERTY,
                        payload: { reportId: report.id, propertyName: 'hasUpdates', propertyValue: report.hasUpdates },
                    });
                });
            }
        },

    deleteDocument:
        (reportId: string, articleId: string, onUpdateReportBuilderPage?: (string) => void): ThunkAction =>
        async (dispatch, getState) => {
            const state = getState();
            let remainingSnippets: number = 0;

            const { currentReport }: { currentReport: CurrentReport } = state;

            const report: Report | void = state.reportBuilder.reports.find(
                (entry: Report): boolean => entry.id === reportId
            );

            if (report && report.categories) {
                remainingSnippets = report.categories.reduce((snippets: number, category: Category | null) => {
                    let categorySnippets: number = !!category && !!category.snippets ? category.snippets.length : 0;
                    return snippets + categorySnippets;
                }, 0);
            }

            dispatch({
                type: DELETE_DOCUMENT,
                payload: { reportId, articleId },
            });

            if (currentReport.reportId === reportId) {
                const snippetsForCurrentReport = currentReport.articlesSnippets.filter(
                    (snippet: Snippet): boolean => snippet.id !== articleId
                );

                dispatch(currentReportActions.updateSnippets(currentReport, snippetsForCurrentReport));
            }

            if (remainingSnippets === 1 && report) {
                await ReportBuilderService.deleteReport(reportId, report.title);

                dispatch({
                    type: DELETE_REPORT,
                    payload: reportId,
                });

                //if deleted report had parentReports, also update liteChildReports for each parent
                if (report.parentReports) {
                    report.parentReports.forEach((report: ParentReport | Object) => {
                        const parentReport: Report | void = state.reportBuilder.reports.find(
                            (entry: Report): boolean => entry.id === report.reportId
                        );

                        if (parentReport?.liteChildReports) {
                            const updatedLiteChildReports: Array<LiteChildReport> =
                                parentReport.liteChildReports.filter(
                                    (liteChildReport) => liteChildReport.reportId !== reportId
                                );

                            dispatch({
                                type: UPDATE_REPORT_PROPERTY,
                                payload: {
                                    reportId: parentReport.id,
                                    propertyName: 'liteChildReports',
                                    propertyValue: updatedLiteChildReports,
                                },
                            });
                        }
                    });
                }

                if (typeof onUpdateReportBuilderPage === 'function') {
                    onUpdateReportBuilderPage(reportId);
                }
            }
        },

    deleteReport(reportId: string): Action {
        return {
            type: DELETE_REPORT,
            payload: reportId,
        };
    },

    updateReportDelivery:
        (reportId: string): ThunkAction =>
        async (dispatch) => {
            const deliveryInfo = await ReportBuilderService.loadReportDeliveryState(reportId);

            if (deliveryInfo) {
                dispatch({
                    type: UPDATE_REPORT_DELIVERY,
                    payload: { reportId, ...deliveryInfo },
                });
                dispatch({
                    type: UPDATE_REPORT_PROPERTY,
                    payload: { reportId, propertyName: 'showSpinner', propertyValue: false },
                });
            }
        },

    updateReportDeliveredStatus:
        (reportId: string): ThunkAction =>
        (dispatch, getState) => {
            const state = getState();
            let latestAvailableDownload: LatestDocument = {};

            const report: GenericReportType | void = state.reportBuilder.reports.find((entry) => entry.id === reportId);

            if (report) {
                latestAvailableDownload = _.cloneDeep(report.latestDocument);
                latestAvailableDownload.isDelivered = true;
            }

            dispatch({
                type: UPDATE_REPORT_DELIVERED_STATUS,
                payload: { reportId, latestAvailableDownload },
            });
        },

    updateNoteOnArticle(reportId: string, articleId: string, note: string): Action {
        return {
            type: UPDATE_NOTE_ON_ARTICLE,
            payload: { reportId, articleId, note },
        };
    },

    deleteNoteFromArticle(reportId: string, articleId: string): Action {
        return {
            type: DELETE_NOTE_FROM_ARTICLE,
            payload: { reportId, articleId },
        };
    },

    setCurrentPage(currentPage: number): Action {
        return {
            type: UPDATE_CURRENT_PAGE,
            payload: currentPage,
        };
    },

    removeIncludedReport:
        (parentId: string, childReports: Array<ChildReport>): ThunkAction =>
        (dispatch, getState) => {
            const state = getState();
            const report: ExpandedReport | void = state.reportBuilder.reports.find(
                (entry: Report) => entry.id === parentId
            );

            if (report && report.childReports && childReports) {
                const updatedChildReports: Array<ChildReport> = report.childReports.filter(
                    (child) => child.reportId && childReports.indexOf(child.reportId) > -1
                );

                dispatch({
                    type: UPDATE_REPORT_PROPERTY,
                    payload: { reportId: parentId, propertyName: 'childReports', propertyValue: updatedChildReports },
                });

                // also update liteChildReports
                if (report.liteChildReports && report.liteChildReports.length) {
                    const updatedLiteChildReports: Array<LiteChildReport> = Array<LiteChildReport>(
                        report.liteChildReports
                    ).filter((child) => child.reportId && childReports.indexOf(child.reportId) > -1);

                    dispatch({
                        type: UPDATE_REPORT_PROPERTY,
                        payload: {
                            reportId: parentId,
                            propertyName: 'liteChildReports',
                            propertyValue: updatedLiteChildReports,
                        },
                    });
                }
            }
        },

    changeUpdatesFlag:
        (reportIds: Array<string>, hasUpdates: boolean): ThunkAction =>
        async (dispatch) => {
            const [reports]: [Array<Report> | void] = await ReportBuilderApi.changeHasUpdatesFlag(
                reportIds,
                hasUpdates
            );

            if (reports) {
                reports.forEach((report: Report) => {
                    dispatch({
                        type: UPDATE_REPORT_PROPERTY,
                        payload: { reportId: report.id, propertyName: 'hasUpdates', propertyValue: report.hasUpdates },
                    });
                });
            }
        },

    updateDocumentsCounts:
        (reportId: string, snippetIds: Array<string>, category: string): ThunkAction =>
        async (dispatch, getState) => {
            if (!!reportId) {
                const parentIds: Array<string | void> = ReportBuilderUtils.extractParentReportIds(getState(), reportId);

                if (!!snippetIds && !!category) {
                    dispatch({
                        type: UPDATE_REPORT_DOCUMENTS_COUNTS,
                        payload: { reportId, snippetIds, category },
                    });
                }

                if (!!parentIds && parentIds.length) {
                    const state: ReportBuilderState = getState().reportBuilder;

                    parentIds.forEach((parentId: string | void) => {
                        if (parentId) {
                            const parentReport: CombinedReportType | void = state.reports.find(
                                (report) => report.id === parentId
                            );

                            if (parentReport) {
                                const value: number = parentReport.snippetsCount - snippetIds.length;

                                dispatch({
                                    type: UPDATE_REPORT_PROPERTY,
                                    payload: {
                                        reportId: parentId,
                                        propertyName: 'snippetsCount',
                                        propertyValue: value,
                                    },
                                });
                            }
                        }
                    });
                }
            }
        },
    updatePollingStatus(status: string): Action {
        return {
            type: UPDATE_POLLING_STATUS,
            payload: status,
        };
    },
};

export default reportBuilderActions;
