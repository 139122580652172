import React from 'react';
import { FormattedMessage } from 'react-intl';

const EntitiesUsed = ({ entityViewCount, entityUploadLimit = 0 }) => {
    return (
        <div className="entities-used-wrapper">
            <div className="la-InfoHover information-icon"></div>
            <div className="divider"></div>
            <div className="entity-used-label">
                <FormattedMessage id="BatchScreening.page.entitiesUsed" />
            </div>{' '}
            <div className="entity-used-value">
                {entityViewCount} <FormattedMessage id="BatchScreening.page.entitiesUsed.of" />{' '}
                {entityUploadLimit}
            </div>
        </div>
    );
};

export default EntitiesUsed;
