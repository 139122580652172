import React from 'react';
import { withRouter } from 'react-router';
import { BREADCRUMBS, PUBLIC_RECORDS_PAGE_TITLE } from '@constants';
import BreadCrumbsModel from '@utils/breadCrumbBuilder';
import breadcrumbsActions from '@reusable/Breadcrumbs/redux/Breadcrumbs.actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class PublicRecords extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            publicRecordsQueryParams: '#',
        };
    }

    componentDidMount() {
        document.title = PUBLIC_RECORDS_PAGE_TITLE;
        this.setBreadcrumbs();
        this.updateSearchParamsFromUrl(this.props);
    }

    updateSearchParamsFromUrl(props) {
        let { param } = props.location.query;
        this.setState(
            {
                publicRecordsQueryParams: param ? atob(param) : '#',
            },
            () => {
                window.scrollTo(0, 0);
            }
        );
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.location.query.param !== this.props.location.query.param) {
            this.updateSearchParamsFromUrl(nextProps);
        }
    }

    setBreadcrumbs() {
        let breadcrumbs = new BreadCrumbsModel.Builder(BREADCRUMBS.PUBLIC_RECORDS).build().breadcrumbs;
        this.props.updateBreadcrumbs(breadcrumbs);
    }

    render() {
        return <iframe width="100%" style={{ minHeight: '100vh' }} src={this.state.publicRecordsQueryParams}></iframe>;
    }
}

const mapStateToProps = function (state) {
    return {
        searchResults: state.searchResults,
    };
};

const mapDispatchToProps = function (dispatch) {
    return bindActionCreators(
        {
            updateBreadcrumbs: breadcrumbsActions.updateBreadcrumbs,
        },
        dispatch
    );
};

export { PublicRecords as TestPublicRecords };
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PublicRecords));
