// @flow

import * as React from 'react';
import { injectIntl } from 'react-intl';
import SearchDropDown from '../SearchDropDown/SearchDropDown';
import {
    COMPANY_SEARCH,
    EDIT_LOCATION_HASH,
    GOOGLE_ANONYMIZE_LOCATION_HASH,
    START_LOCATION_HASH,
    LAUNCHED_SEARCH_FROM,
} from '@constants';
import utils from '@utils/utilities';
import formatRichMessage from '@utils/formatRichMessage';
import { FormattedMessage } from 'react-intl';
import SearchBox from '../SearchBox/SearchBox';
import reduxStore from '@reduxStore';
import { connect } from 'react-redux';
import {
    DEBOUNCE_QUERY_CHANGED_TIMEOUT,
    BILLING_ID,
    DEBOUNCE_SEARCH_EXTRA_TIMEOUT,
    SEARCH_PARAMS_URL_LIST,
    SAVE_SEARCH_LEAVE_ACTION,
} from '@constants';
import { debounce } from 'lodash';
import { withAppContext, withNotOnPageToggleView } from '@utils/contexts';
import adHocSearchActions from '../AdHocSearch/redux/AdHocSearch.actions';
import PropTypes from 'prop-types';
import historyActions from '@pages/HistoryPage/redux/History.actions';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import costCodeUtils from '@utils/costCodeUtils';
import uboActions from '@pages/StartPage/redux/Ubo.actions';
import withPreferenceRefresh from '@pages/Main/hoc/withPreferenceRefresh';
import type { SearchParams } from '../SearchBar/redux/flow/SearchBar.type.guards';
import type { FuzzyNamesType } from '@utils/flow/deliveryService.type.guards';
import type { SuggestedNamesType } from './flow/SearchBarHeader.typeGuards';
import { bindActionCreators } from 'redux';
import searchBar from '../SearchBar/redux/SearchBar.actions';
import SourcesDropdownUtils from '@utils/sourcesDropdownUtils';
import searchResultsActions from '@pages/MainSearch/redux/SearchResults.actions';
import userPreferencesUtils from '@utils/userPreferencesUtils';
import negativeNewsVisualisationActions from '@pages/MainSearch/components/negativeNewsVisualisations/redux/NegativeNewsVisualisation.actions';
import { toggleStatus } from '@pages/MainSearch/redux/SearchStatus.action';
import investigationActions from '@pages/MainSearch/redux/Investigation.actions';
import breadcrumbsActions from '@reusable/Breadcrumbs/redux/Breadcrumbs.actions';
import mainActions from '@pages/Main/Main.actions';
import { searchStatusActions } from '@pages/MainSearch/redux/SearchStatus.action';
import sanctionsRiskActions from '@scripts/pages/MainSearch/components/sanctionsRisk/redux/SanctionsRisk.actions';
import searchSagaActions from '@scripts/pages/MainSearch/redux/searchSaga.actions';

type State = {
    isListVisible: boolean,
    searchType: string,
    inputField: string,
    searchConfirmModal: ?boolean | Array<string>,
    searchRerun: boolean,
};

type DefaultProps = {
    isListVisible: boolean,
    searchType: string,
    inputField: string,
};

type Props = {
    checkPreferencesAreObsolete: () => void,
    isSearchComplete: boolean,
    useDocumentsCountEndpoint: boolean,
    searchParams: SearchParams,
    prevPath: string,
    fuzzyNames: FuzzyNamesType,
    suggestedNames: SuggestedNamesType,
    isSnapshotEnabled: boolean,
    contentTypes: Array<string>,
    changeSearchType: (searchType: string) => void,
    changeQuery: (searchQuery: string) => void,
    setBooleanTerms: (prefilterQuery: string) => void,
    resetQuery: () => void,
    setBillingId: (billingId: string) => void,
    resetSuggestedNames: () => void,
    location: Object,
    selectedCostCode: string,
    isCostCodeRequired: boolean,
    chooseOnlyFromCostCodesList: boolean,
    costCodesList: Array<string>,
    useNewResearchSummary: boolean,
    adHocSearch: Object,
    emptyEditSearch: (emptyObject: Object) => void,
    isListVisible: boolean,
    searchType: string,
    inputField: string,
    userPreferencesSearchType: string,
    updateSourcesDropdownContentTypes: () => void,
    setEntityId: (entityId: string) => void,
};

type InjectedProps = {|
    +intl: Object,
    context: Object,
    updateAdHocSearchObject: (Object) => void,
    updateHistoryCategory: (string) => void,
    clearNegativeNewsVisualization: () => void,
    sendInvestigationAction: (Object) => void,
    resetInvestigation: () => void,
|};

class SearchBarHeader extends React.Component<Props & InjectedProps, State> {
    static propTypes = {
        isListVisible: PropTypes.bool,
        searchType: PropTypes.string,
        inputField: PropTypes.string,
    };

    static defaultProps: DefaultProps = {
        isListVisible: false,
        searchType: '',
        inputField: '',
    };

    debouceSearch: (query: string) => void;

    constructor(props: Props & InjectedProps) {
        super(props);

        this.state = {
            isListVisible: this.props.isListVisible,
            searchType: this.props.searchParams.searchType,
            inputField: utils.mergeQueryAndBoolean(
                utils.sanitizeSearchStringInput(this.props.searchParams.query),
                this.props.searchParams.prefilterQuery
            ),
            searchConfirmModal: false,
            searchRerun: false,
        };

        this.debouceSearch = debounce(
            this.deboucedSearch,
            DEBOUNCE_QUERY_CHANGED_TIMEOUT + DEBOUNCE_SEARCH_EXTRA_TIMEOUT
        ).bind(this);

        this.searchBtnRef = React.createRef();
        this.searchButtonTrigger = null;
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props & InjectedProps) {
        if (
            utils.sanitizeSearchStringInput(this.props.searchParams.query) !== utils.sanitizeSearchStringInput(nextProps.searchParams.query) ||
            this.props.searchParams.prefilterQuery !== nextProps.searchParams.prefilterQuery
        ) {
            this.setState({
                inputField: utils.mergeQueryAndBoolean(
                    utils.sanitizeSearchStringInput(nextProps.searchParams.query),
                    nextProps.searchParams.prefilterQuery
                ),
            });
        }

        if (this.props.searchParams.searchType !== nextProps.searchParams.searchType) {
            this.setState({ searchType: nextProps.searchParams.searchType });
        }
    }

    componentDidMount() {
        this.updateSearchParamsFromUrl(this.props);
    }

    componentDidUpdate(prevProps) {
        const nextShowSaveSearchModalBeforeLeave = this.props.showSaveSearchModalBeforeLeave;
        const prevShowSaveSearchModalBeforeLeave = prevProps.showSaveSearchModalBeforeLeave;

        /* 
            nextShowSaveSearchModalBeforeLeave can be null after closing the popup without choosing to Save or Discard the changes.
            In this special case, we should not trigger the click on the search button so the user will remain on the same page. 
            So we can not write !nextShowSaveSearchModalBeforeLeave
        */
        if (
            prevShowSaveSearchModalBeforeLeave === SAVE_SEARCH_LEAVE_ACTION.searchBarHeader &&
            nextShowSaveSearchModalBeforeLeave === false
        ) {
            clearTimeout(this.searchButtonTrigger);
            // simulate click on search after the popup modal when leaving results list page
            this.searchButtonTrigger = setTimeout(() => this.searchBtnRef.current.click(), 250);
        }
    }

    resetQuery: () => void = () => {
        this.setState({ inputField: '' });
    };

    queryChanged: (newQuery: string) => void = (newQuery) => {
        const value = utils.removeLineBreaks(utils.sanitizeSearchStringInput(newQuery));
        this.setState({ inputField: value });
    };

    updateSearchParamsFromUrl(props: Props) {
        //read investigation id from localStorage
        let billingId = localStorage.getItem(BILLING_ID);
        // TODO: get the prevPath from redux store for now because there is a race-condition. We should check for
        const reduxPrevPath = reduxStore.getState().breadcrumbs.prevPath;

        if (!billingId) {
            billingId = costCodeUtils.generateBillingId();
        }
        this.props.setBillingId(billingId);

        if (props.searchParams.query !== props.location.query.q) {
            this.props.changeQuery(props.location.query.q);
        }
        if (props.searchParams.searchType !== props.location.query.searchType) {
            this.props.changeSearchType(props.location.query.searchType);
        }
        if (props.searchParams.prefilterQuery !== props.location.query.prefilterQuery) {
            this.props.setBooleanTerms(props.location.query.prefilterQuery);
        }

        if (SEARCH_PARAMS_URL_LIST.indexOf(reduxPrevPath) === -1) {
            this.doSearch(props.location.query.q, props.location.query.searchType, props.location.query.prefilterQuery, props.location.query.searchFrom, props.location.query.entityId, props.location.query.category);
        }
    }

    updateSearchDropdown() {
        const contentTypesUpdate = SourcesDropdownUtils.formatSources(this.props.contentTypes, this.props);
        reduxStore.dispatch(searchBar.updateSourcesDropdownContentTypes(contentTypesUpdate));
        reduxStore.dispatch(searchResultsActions.resetCategoriesResults());
    }

    deboucedSearch(newQuery: string) {
        this.updateSearchDropdown();

        if (this.props.context && this.props.context.editingAlert) {
            // saving the arguments in case user wants to continue
            this.setState({ searchConfirmModal: Array.from(arguments) });
            return;
        }
        let parsedQuery = {};

        if (!newQuery) {
            parsedQuery = utils.extractQueryFromBooleanTerms(this.state.inputField);

            if (parsedQuery.message !== '' && parsedQuery.query === '') {
                utils.showNotificationsMessage({
                    messageText: parsedQuery.message,
                    messageType: 'system-error',
                });
                return;
            }

            if (
                utils.hasMismatchedQuotesNumber(parsedQuery.query) ||
                utils.hasMismatchedQuotesNumber(parsedQuery.prefilterQuery)
            ) {
                utils.showNotificationsMessage({
                    messageText: 'SearchResults_Notifications.mismatchedQuotationMarks',
                    messageType: 'user-error',
                });
                return;
            }

            this.props.changeQuery(utils.sanitiseQuery(parsedQuery.query));
            this.props.setBooleanTerms(parsedQuery.prefilterQuery);
        } else if (this.state.inputField !== newQuery) {
            this.setState({ inputField: newQuery }, () => {
                if (utils.sanitizeSearchStringInput(this.props.searchParams.query) !== newQuery) {
                    this.props.changeQuery(utils.sanitiseQuery(newQuery));
                }
            });
        } else {
            parsedQuery = utils.extractQueryFromBooleanTerms(newQuery);

            if (parsedQuery.message !== '' && parsedQuery.query === '') {
                utils.showNotificationsMessage({
                    messageText: parsedQuery.message,
                    messageType: 'system-error',
                });
                return;
            }
            this.props.changeQuery(utils.sanitiseQuery(parsedQuery.query));
            this.props.setBooleanTerms(parsedQuery.prefilterQuery);
        }

        if (this.state.searchType !== this.props.searchParams.searchType) {
            this.props.changeSearchType(this.state.searchType);
        }
        this.props.updateAdHocSearchObject({});
        this.props.updateHistoryCategory('');

        const billingId = costCodeUtils.generateBillingId();
        this.props.setBillingId(billingId);
        this.doSearch(parsedQuery.query, this.state.searchType, parsedQuery.prefilterQuery, LAUNCHED_SEARCH_FROM.HEADER);

        if (utils.isUboEnabled()) {
            // submit empty ubo when searching from header, basically removing the list of duns
            reduxStore.dispatch(uboActions.submit({ selected: null }));
        }
    }

    handleSearchClick: (e: SyntheticEvent<*>, newQuery: string) => void = (e, newQuery) => {
        const parsedQuery = utils.extractQueryFromBooleanTerms(newQuery || this.state.inputField);
        const { contentTypes, arePostFiltersChanged, showSaveSearchModal, isSearchComplete } = this.props;

        const searchTermPresent = !!parsedQuery.query || !!this.state.inputField;
        const insufficientQuery =
            !utils.isValidQuery(parsedQuery.query) || !utils.isValidQuery(parsedQuery.prefilterQuery);
        const hasMismatchedQuotes =
            utils.hasMismatchedQuotesNumber(parsedQuery.query) ||
            utils.hasMismatchedQuotesNumber(parsedQuery.prefilterQuery);
        const contentTypesApplicable = utils.areContentTypesApplicableWithSearchType(
            contentTypes,
            this.state.searchType
        );

        if (insufficientQuery) {
            utils.showNotificationsMessage({
                messageText: 'SearchResults_Notifications.insufficientCharacters',
                messageType: 'system-error',
            });
            return;
        }

        if (hasMismatchedQuotes) {
            utils.showNotificationsMessage({
                messageText: 'SearchResults_Notifications.mismatchedQuotationMarks',
                messageType: 'user-error',
            });
            return;
        }

        if (!contentTypesApplicable) {
            utils.showNotificationsMessage({
                messageText: 'SearchResults_Notifications.noAvailableContentTypesForSearch',
                messageType: 'system-error',
            });
            return;
        }

        if (!isSearchComplete) {
            utils.showNotificationsMessage({
                messageText: 'SearchResults_Notifications.searchInProgress',
                messageType: 'info',
            });

            return;
        }

        if (!searchTermPresent) {
            utils.showNotificationsMessage({
                messageText: 'QUERY.validation.missingSearchTerm',
                messageType: 'user-error',
            });
            return;
        }

        if (arePostFiltersChanged) {
            showSaveSearchModal(SAVE_SEARCH_LEAVE_ACTION.searchBarHeader);
            return;
        }

        reduxStore.dispatch(toggleStatus(false));
        e.persist();

        // dispatch an action that reset prevPath to main-search
        this.props.updatePrevPath({ pathname: '/main-search', query: parsedQuery });
        // save searchType to preferences if the user changed selection and performed a search
        if (this.state.searchType !== this.props.userPreferencesSearchType) {
            userPreferencesUtils.saveSearchTypeToPreferences(this.state.searchType);
        }

        this.props.clearNegativeNewsVisualization();
        this.props.emptyEditSearch({});
        this.debouceSearch(utils.sanitizeSearchStringInput(newQuery));
    };

    validateSearchParams: (searchQuery: string, searchQueryType: string, prefilterQuery: string) => boolean = (
        searchQuery,
        searchQueryType,
        prefilterQuery
    ) => {
        let areAllParamsValid = true;
        let costCode = this.props.adHocSearch.costCode || this.props.selectedCostCode;

        if (utils.hasMismatchedQuotesNumber(searchQuery) || utils.hasMismatchedQuotesNumber(prefilterQuery)) {
            utils.showNotificationsMessage({
                messageText: 'SearchResults_Notifications.mismatchedQuotationMarks',
                messageType: 'user-error',
            });
            areAllParamsValid = false;
        }

        if (this.props.isCostCodeRequired && !costCode) {
            utils.showNotificationsMessage({
                messageText: 'SearchResults_Notifications.missingCostCodeSearchBarHeader',
                messageType: 'user-error',
            });
            areAllParamsValid = false;
        }

        if (this.props.chooseOnlyFromCostCodesList && this.props.costCodesList.indexOf(costCode) < 0) {
            utils.showNotificationsMessage({
                messageText: 'SearchResults_Notifications.missingCostCodeSearchBarHeader',
                messageType: 'user-error',
            });
            areAllParamsValid = false;
        }

        if (searchQuery === '' || !searchQuery) {
            areAllParamsValid = false;
            utils.showNotificationsMessage({
                messageText: 'SearchResults_Notifications.missingQueryParameter',
                messageType: 'system-error',
            });
        }
        if (searchQueryType === '') {
            areAllParamsValid = false;
            utils.showNotificationsMessage({
                messageText: 'SearchResults_Notifications.missingSearchTypeParameter',
                messageType: 'system-error',
            });
        }

        return areAllParamsValid;
    };

    doSearch: (searchQuery: string, searchQueryType: string, prefilterQuery: Object, launchedFrom: string, entityId: string, categoryName: string) => void = (
        searchQuery,
        searchQueryType,
        prefilterQuery,
        launchedFrom,
        entityId,
        categoryName,
    ) => {
        if (!searchQuery || !this.validateSearchParams(searchQuery, searchQueryType, prefilterQuery)) return;
        let payload = {
            searchQuery: searchQuery,
            searchType: searchQueryType,
            prefilterQuery: utils.setCorrectPrefilterQuery(prefilterQuery),
            launchedFrom: launchedFrom,
            entityId: entityId,
            checkPreferencesAreObsolete: () => null,
            location: this.props.location,
        };
        if(categoryName) {
            payload.categoryName = categoryName;
        }
        reduxStore.dispatch(searchSagaActions.runSearch(payload));
    }

    selectOption: (selectedOption: string) => void = (selectedOption) => {
        this.setState({ searchType: selectedOption });
    };

    toggleListVisibility: (event: SyntheticEvent<*>) => void = (event) => {
        event.persist();
        this.setState({
            isListVisible: !this.state.isListVisible,
        });
    };

    setListVisibility: (boolean) => void = (isVisible) => {
        this.setState({
            isListVisible: isVisible,
        });
    };

    closeModal: (callback: Function) => void = (callback) => {
        this.setState({ searchConfirmModal: false }, () => {
            if (typeof callback === 'function') callback();
        });
    };

    closeModalAndContinue: () => void = () => {
        const savedArgs = this.state.searchConfirmModal;
        if (this.props.context && this.props.context.onChangeContext) {
            this.props.context.onChangeContext({ editingAlert: false });
        }
        this.closeModal(() => {
            this.deboucedSearch.call(this, ...savedArgs);
        });
    };

    render(): React.Node {
        const { isListVisible, searchType, searchConfirmModal, inputField } = this.state;
        const buttons = [
            {
                text: formatRichMessage({ id: 'General_CoreFunctionality_UIText_general.continue' }, this.props.intl),
                primary: true,
                click: this.closeModalAndContinue,
            },
            {
                text: formatRichMessage({ id: 'General_CoreFunctionality_UIText_general.cancel' }, this.props.intl),
                primary: false,
                click: this.closeModal,
            },
        ];

        return (
            <div className="diligence-header__menu-item diligence-header__menu-item__search notranslate">
                <div className="search-bar-header">
                    <SearchBox
                        dataTrack="header-search-bar-input-field"
                        placeholder={
                            searchType === COMPANY_SEARCH
                                ? formatRichMessage(
                                      { id: 'General_CoreFunctionality_UIText_general.searchBox.company' },
                                      this.props.intl
                                  )
                                : formatRichMessage(
                                      { id: 'General_CoreFunctionality_UIText_general.searchBox.person' },
                                      this.props.intl
                                  )
                        }
                        handleSearchEvent={this.handleSearchClick}
                        queryChanged={this.queryChanged}
                        resetQuery={this.resetQuery}
                        query={inputField}
                    />
                    <SearchDropDown
                        toggleListVisibility={this.toggleListVisibility}
                        setListVisibility={this.setListVisibility}
                        selectOption={this.selectOption}
                        selectedOption={searchType}
                        listVisible={isListVisible}
                    />
                    <button
                        type="button"
                        className="la-SWResearch"
                        data-track="header-search-event-trigger"
                        onClick={this.handleSearchClick}
                        ref={this.searchBtnRef}
                    />
                </div>
                {searchConfirmModal && (
                    <ConfirmationModal closeButtonHandler={this.closeModal} buttons={buttons}>
                        <h2 className="modal-header">
                            <FormattedMessage id={'Alerts.editAlert.changeTermModalTitle'} />
                        </h2>
                        <div className="modal-description font-body">
                            <p>
                                <FormattedMessage id={'Alerts.editAlert.changeTermModalContent'} />
                            </p>
                            <p>
                                <FormattedMessage id={'General_CoreFunctionality_UIText_general.areYouSure'} />
                            </p>
                        </div>
                    </ConfirmationModal>
                )}
            </div>
        );
    }
}

const mapDispatchToProps = function (dispatch) {
    return bindActionCreators(
        {
            updateAdHocSearchObject: adHocSearchActions.updateAdHocSearchObject,
            updateHistoryCategory: historyActions.updateHistoryCategory,
            updateSourcesDropdownContentTypes: searchBar.updateSourcesDropdownContentTypes,
            clearNegativeNewsVisualization: negativeNewsVisualisationActions.clearNegativeNewsVisualization,
            resetInvestigation: investigationActions.resetInvestigation,
            sendInvestigationAction: investigationActions.investigationAction,
            updatePrevPath: breadcrumbsActions.updatePrevPath,
            searchLaunchedFrom: mainActions.searchLaunchedFrom,
            showSaveSearchModal: searchStatusActions.showSaveSearchModal,
            setEntityId: mainActions.setEntityId,
            resetSanctionsRisk: sanctionsRiskActions.resetSanctionsRisk,
            updateCategoryProperty: searchResultsActions.updateCategoryProperty,
        },
        dispatch
    );
};

const mapStateToProps = (state) => {
    return {
        userPreferencesSearchType: state.user.preferences.generalSettings.searchType,
        lawSources: state.user.preferences.generalSettings.lawSources,
        searchType: state.searchParams.searchType,
        personCustomNewsQuery: state.user.preferences.personCheck.customNewsQuery,
        companyCustomNewsQuery: state.user.preferences.companyCheck.customNewsQuery,
        adminPersonCustomNewsQuery: state.user.preferences.personCheck.adminCustomNewsQuery,
        adminCompanyCustomNewsQuery: state.user.preferences.companyCheck.adminCustomNewsQuery,
        sourcesDropdown: state.searchBar.sourcesDropdown,
        arePostFiltersChanged: state.searchStatus.arePostFiltersChanged,
        showSaveSearchModalBeforeLeave: state.searchStatus.showSaveSearchModalBeforeLeave,
        reduxStoreState: state,
    };
};

export { SearchBarHeader as TestSearchBarHeader };
export default (injectIntl(
    withAppContext(
        withPreferenceRefresh(
            withNotOnPageToggleView(connect(mapStateToProps, mapDispatchToProps)(SearchBarHeader), [
                START_LOCATION_HASH,
                GOOGLE_ANONYMIZE_LOCATION_HASH,
                EDIT_LOCATION_HASH,
            ])
        )
    )
): React.AbstractComponent<Props, State>);
