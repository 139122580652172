import React from 'react';
import ReportsList from './components/ReportsList/ReportsList';
import { BREADCRUMBS, HEADER_HEIGHT, REPORT_BUILDER_PAGE_TITLE } from '@constants';
import { ReportBuilderHeader } from './components/ReportBuilderHeader/ReportBuilderHeader';
import breadcrumbsActions from '@reusable/Breadcrumbs/redux/Breadcrumbs.actions';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import reportBuilderActions from './redux/ReportBuilder.action';
import PopupModal from '@reusable/PopupModal/PopupModal.index';
import BreadCrumbsModel from '@utils/breadCrumbBuilder';
import currentReportActions from './redux/CurrentReport.actions';
import articlesManagerActions from '@MainSearch/redux/ArticlesManager.actions';
import { FormattedMessage, injectIntl } from 'react-intl';
import ReactPaginate from 'react-paginate';
import PageSizeSelector from '@reusable/PageSizeSelector/PageSizeSelector';
import userPreferencesActions from '@UserPreferences/redux/UserPreferences.actions';
import { Loader, Segment } from 'semantic-ui-react';
import ReportBuilderService from './ReportBuilderService';
import ReportActions from './components/ReportBuilderActions/ReportActions';
import withConfirmationModal from '@reusable/Modal/hoc/withConfirmationModal';
import { hashHistory } from 'react-router';
import ReportBuilderUtils from './utils/ReportBuilderUtils';
import utils from '@utils/utilities';
import stickybits from 'stickybits';
import { withAppContext } from '@utils/contexts';

class ReportBuilderMain extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedItems: [],
            popupModalVisible: false,
        };
        this.getNumberOfDocToPrintDownload = this.getNumberOfDocToPrintDownload.bind(this);
        this.showPopupModal = this.showPopupModal.bind(this);
        this.closePopupModal = this.closePopupModal.bind(this);
        this.onPageSizeChanged = this.onPageSizeChanged.bind(this);
        this.onPageChanged = this.onPageChanged.bind(this);
        this.handleSelectReport = this.handleSelectReport.bind(this);
        this.handleGetSelectedName = this.handleGetSelectedName.bind(this);
        this.lazyLoadArticlesForReport = this.lazyLoadArticlesForReport.bind(this);
        this.updateReportProperty = this.updateReportProperty.bind(this);
        this.handleDeleteMultiple = this.handleDeleteMultiple.bind(this);
        this.addNoteToReport = this.addNoteToReport.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleDeleteCategory = this.handleDeleteCategory.bind(this);
        this.getConnectedReports = this.getConnectedReports.bind(this);
        this.isReportOnCurrentPage = this.isReportOnCurrentPage.bind(this);
        this.jumpToReport = this.jumpToReport.bind(this);
        this.makeActionsSticky = this.makeActionsSticky.bind(this);
        this.removeCurrentReportIdFromUrl = this.removeCurrentReportIdFromUrl.bind(this);
        this.removeIncludedReport = this.removeIncludedReport.bind(this);
        this.getNumberOfSelectedItems = this.getNumberOfSelectedItems.bind(this);
    }

    closePopupModal() {
        this.setState({
            popupModalVisible: false,
        });
    }

    showPopupModal() {
        this.setState({
            popupModalVisible: true,
        });
    }

    setBreadcrumbs() {
        let query = this.props.location.query;
        let breadcrumbs = new BreadCrumbsModel.Builder(BREADCRUMBS.REPORT_BUILDER)
            .setProperty('reportId', query.reportId)
            .setProperty('category', query.category)
            .setProperty('searchQuery', utils.sanitizeSearchStringInput(this.props.searchParams.query))
            .setProperty('searchQueryType', this.props.searchParams.searchType)
            .setProperty('isSnapshotVisible', this.props.isSnapshotVisible)
            .setProperty('prevPath', this.props.breadcrumbs.prevPath)
            .build().breadcrumbs;
        this.props.updateBreadcrumbs(breadcrumbs);
    }

    async componentDidMount() {
        document.title = REPORT_BUILDER_PAGE_TITLE;

        this.setBreadcrumbs();
        const currentReportId = this.props.location.query.reportId;
        const prevPath = this.props.breadcrumbs.prevPath;
        let shouldReload = prevPath.indexOf('/print') < 0 || prevPath.indexOf('/article') < 0;
        this.props.setLoadingStatus(true);

        if (shouldReload) {
            await ReportBuilderService.loadReport(currentReportId, this.props.publicRecordsOn, this.props.pageSize);
        }
    }
    UNSAFE_componentWillReceiveProps() {
        this.setBreadcrumbs();
    }

    componentDidUpdate() {
        this.makeActionsSticky();
    }

    componentWillUnmount() {
        this.props.resetReportBuilderState();
    }

    makeActionsSticky() {
        // pin delivery options to the top of the window
        stickybits('.selection-header', {
            stickyBitStickyOffset: utils.calculateMainBannersHeight() + HEADER_HEIGHT,
        });
    }

    addNoteToReport(reportId, note) {
        ReportBuilderService.addNoteToReport(reportId, note);
    }

    lazyLoadArticlesForReport(report) {
        if (!report.articleLoaded) {
            return ReportBuilderService.getArticlesByReport(report, this.props.reportSnippetsPageSize);
        } else {
            return Promise.resolve(report.categories || []);
        }
    }

    getBatchReportEntities(report) {
        if (!report.entitiesLoaded) {
            return ReportBuilderService.getEntitiesForBatchReport(report);
        } else {
            return Promise.resolve(report.batchEntities || []);
        }
    }

    async getConnectedReports(report) {
        if (!report.connectedReportsLoaded || report.hasUpdates)
            await ReportBuilderService.getConnectedReports(report.id);
    }

    async getDocumentsCountPerCategory(report) {
        if (!report.documentsCountsLoaded) await ReportBuilderService.getDocumentsCountPerCategory(report.id);
    }

    updateReportProperty({ reportId, reportProperty, propertyValue, report, isSelected }) {
        return ReportBuilderService.updateReportProperty({
            reportId,
            reportProperty,
            propertyValue,
            report,
            isSelected,
        });
    }

    loadAnotherPage(selectedPage, pageSize) {
        pageSize = pageSize || this.props.pageSize;
        ReportBuilderService.loadReports(selectedPage, pageSize, this.props.publicRecordsOn);
    }

    getNumberOfDocToPrintDownload(currentReportId, currentReportCategory) {
        const reports = this.props.reports;
        let currentReport = reports.filter((report) => {
            return report.id === currentReportId;
        })[0];

        if (currentReport) {
            let currentCategory = currentReport.categories.filter((category) => {
                return category.categoryName === currentReportCategory;
            })[0];
            if (currentCategory) {
                return currentCategory.snippets.length;
            }
        }
    }

    onPageChanged(e) {
        this.props.setLoadingStatus(true);
        this.loadAnotherPage(e.selected);
    }

    onPageSizeChanged(e) {
        this.props.setLoadingStatus(true);
        this.props.updateGeneralPageSize(e.target.value);
        this.loadAnotherPage(0, e.target.value);
    }

    handleSelectReport(selectedItems) {
        this.setState({ selectedItems });
    }

    handleGetSelectedName(item) {
        return item.title;
    }

    handleDeleteMultiple() {
        let count = this.state.selectedItems.length;
        const { query, pathname } = this.props.location;

        const modalProps = {
            primaryBtnText: 'General_CoreFunctionality_UIText_general.delete',
            title: <FormattedMessage id={'Report.confirmDeleteReport'} />,
            description:
                count === 1 ? (
                    <FormattedMessage id={'PopupBuilder.description.deleteReports'} />
                ) : (
                    <FormattedMessage id={'PopupBuilder.description.deleteMultipleReports'} values={{ count }} />
                ),
        };
        this.props.onConfirmModal(modalProps, () => {
            ReportBuilderService.deleteReports(this.state.selectedItems).then(() => {
                this.setState({ selectedItems: [] });
                this.loadAnotherPage(0);
                this.removeCurrentReportIdFromUrl({ query, pathname });
            });
        });
    }

    handleDelete(reportId, reportTitle) {
        const modalProps = {
            primaryBtnText: 'General_CoreFunctionality_UIText_general.delete',
            title: <FormattedMessage id={'Report.confirmDeleteReport'} />,
            description: <FormattedMessage id={'PopupBuilder.description.deleteReports'} />,
        };

        this.props.onConfirmModal(modalProps, async () => {
            await ReportBuilderService.deleteReport(reportId, reportTitle);

            this.handleUpdateReportBuilderPageAfterReportDeletion(reportId);
        });
    }

    handleDeleteCategory(categoryDeleteRequest) {
        const modalProps = {
            primaryBtnText: 'General_CoreFunctionality_UIText_general.delete',
            title: <FormattedMessage id={'Report.confirmDeleteReportDocument'} />,
            description: <FormattedMessage id={'PopupBuilder.description.deleteReportDocuments'} />

        };
        this.props.onConfirmModal(modalProps, async () => {
            await ReportBuilderService.deleteReportCategory(categoryDeleteRequest.params);
        });
    }

    handleUpdateReportBuilderPageAfterReportDeletion = async (reportId) => {
        ReportBuilderUtils.updateReportBuilderSelection(this.state.selectedItems, reportId, this.updateSelectedItems);
        const { query, pathname } = this.props.location;
        const currentReportId = this.props.currentReport.reportId === reportId ? null : reportId;
        this.removeCurrentReportIdFromUrl({ query, pathname });

        await ReportBuilderService.loadReport(currentReportId, this.props.publicRecordsOn, this.props.pageSize);
    };

    removeCurrentReportIdFromUrl({ query, pathname }) {
        if (!this.props.currentReport.reportId && query) {
            let path = pathname;
            const params = [];
            Object.keys(query).forEach((key) => {
                if (key === 'reportId') return;
                params.push({ key, value: query[key] });
            });
            if (params.length > 0) {
                path = `${path}?`;
                params.forEach((item, index) => {
                    path += `${item.key}=${item.value + (index + 1 === params.length ? '' : '&')}`;
                });
            }
            hashHistory.replace(path);
        }
    }

    updateSelectedItems = (remainingItems = []) => {
        let remainingSelectedItems = [];

        this.state.selectedItems.forEach((item) => {
            let items = remainingItems && remainingItems.length > 0 ? remainingItems : this.props.reports;
            let reportObject = items.find((report) => report.id === item.id);

            if (reportObject !== undefined) {
                remainingSelectedItems.push(item);
            }
        });

        this.setState({ selectedItems: remainingSelectedItems });
    };

    async jumpToReport(reportId) {
        const report = this.props.reports.find((report) => report.id === reportId);

        if (!!report) {
            // this is used for scrolling to report se handleScrollToReport in ReportBuilderList.jsx (discarted soon after the scroll)
            this.props.setSelectedReportById(reportId);
            // this is used to keep the report expanded - the general method used for this
            this.props.setSelectedReport(reportId);
            ReportBuilderUtils.isReportCombined(report.deliveryType) &&
                ReportBuilderService.changeUpdatesFlagForSingleReport(report, false);

            if (!ReportBuilderUtils.isReportCombined(report.deliveryType)) {
                await this.lazyLoadArticlesForReport(report);
                await this.getDocumentsCountPerCategory(report);
            }
            await this.getConnectedReports(report);
        } else {
            await ReportBuilderService.loadReport(reportId, this.props.publicRecordsOn, this.props.pageSize);
        }
    }

    removeIncludedReport(childId, parentId) {
        ReportBuilderService.removeIncludedReport({
            childId,
            parentId,
            currentPage: 0,
            pageSize: this.props.pageSize,
            publicRecordsOn: this.props.publicRecordsOn,
        });
    }

    isReportOnCurrentPage(reportId) {
        const report = this.props.reports.find((report) => report.id === reportId);
        return !!report;
    }

    getNumberOfSelectedItems(selectedItems) {
        const items = [];

        selectedItems.forEach((report) => {
            if (!ReportBuilderUtils.isReportCombined(report.deliveryType)) {
                items.push(report.id);
            } else {
                if (report.connectedReportsLoaded) {
                    report.childReports.forEach((child) => {
                        if (child && !child.isDeleted && !child.isExpired) {
                            items.push(child.reportId);
                        }
                    });
                } else {
                    items.push(...report.childReports);
                }
            }
        });
        const uniqueSelectedItems = new Set(items);
        return uniqueSelectedItems.size;
    }

    render() {
        const numberOfSelectedItems = this.getNumberOfSelectedItems(this.state.selectedItems);
        const actionsDisabled =
            numberOfSelectedItems > this.props.context.maxNumberOfReports || numberOfSelectedItems === 0;

        let pageNumbers = Math.ceil(this.props.totalReportsCount / this.props.pageSize);
        const reportActions = (
            <ReportActions
                onDelete={this.handleDeleteMultiple}
                selectedItems={this.state.selectedItems}
                actionsDisabled={actionsDisabled}
                showDeliveryOptions={this.props.showDeliveryOptions}
                numberOfSelectedItems={numberOfSelectedItems}
                contentsOptions={this.props.contentsOptions}
                pageSize={this.props.pageSize}
                publicRecordsOn={this.props.publicRecordsOn}
                timezone={this.props.timezone}
                interfaceLanguage={this.props.interfaceLanguage}
                startEachArticleOnNewPage={this.props.startEachArticleOnNewPage}
                deselectAllReports={this.handleSelectReport}
                emailAddress={this.props.emailAddress}
            />
        );
        let loadedReports = this.props.currentPage * this.props.pageSize + this.props.loadedReports;

        return (
            <div className="reports-wrapper">
                {this.props.popupModel.isVisible && <PopupModal closeButtonHandler={this.closePopupModal} />}
                <ReportBuilderHeader
                    reports={this.props.reports}
                    showReportsList={true}
                    intl={this.props.intl}
                    loadedReports={loadedReports}
                    totalReportsCount={this.props.totalReportsCount}
                    publicRecordsOn={this.props.publicRecordsOn}
                />
                {!this.props.loadingStatus ? (
                    <ReportsList
                        lazyLoadArticlesForReport={this.lazyLoadArticlesForReport}
                        getBatchReportEntities={this.getBatchReportEntities}
                        pageNumber={this.props.currentPage}
                        updateReportProperty={this.updateReportProperty}
                        updateSnippetsInCurrentReport={this.props.updateSnippetsInCurrentReport}
                        discardSelectedReport={this.props.discardSelectedReport}
                        selectedReport={this.props.selectedReport}
                        setSelectedReport={this.props.setSelectedReport}
                        currentReport={this.props.currentReport}
                        reports={this.props.reports}
                        addNoteToReport={this.addNoteToReport}
                        loadedReports={loadedReports}
                        totalReportsCount={this.props.totalReportsCount}
                        contentsOptions={this.props.contentsOptions}
                        showDeliveryOptions={this.props.showDeliveryOptions}
                        updateProperty={this.props.updateProperty}
                        updateReportDeliveredStatus={this.props.updateReportDeliveredStatus}
                        reportDeliveryAvailabilityDays={this.props.reportDeliveryAvailabilityDays}
                        prReportDeliveryAvailabilityDays={this.props.prReportDeliveryAvailabilityDays}
                        onDelete={this.handleDelete}
                        onCategoryDelete={this.handleDeleteCategory}
                        onSelectionChange={this.updateSelectedItems}
                        onUpdateReportBuilderPage={this.handleUpdateReportBuilderPageAfterReportDeletion}
                        getConnectedReports={this.getConnectedReports}
                        jumpToReport={this.jumpToReport}
                        removeIncludedReport={this.removeIncludedReport}
                        discardScrollToReport={this.props.discardScrollToReport}
                        getDocumentsCountPerCategory={this.getDocumentsCountPerCategory}
                        emailAddress={this.props.emailAddress}
                        // For selection
                        data={{ rows: this.props.reports.map(mapReportsToRows) }}
                        selection={{
                            selectedItems: this.state.selectedItems,
                            onChangeSelection: this.handleSelectReport,
                            onGetSelectedName: this.handleGetSelectedName,
                            actions: reportActions,
                        }}
                        language={this.props.language}
                        batchReportsPooling={this.props.batchReportsPooling}
                    />
                ) : (
                    <Segment basic>
                        <Loader size="large" active />
                    </Segment>
                )}

                {this.props.totalReportsCount !== 0 && (
                    <div className="pagination-container">
                        <ReactPaginate
                            forcePage={this.props.currentPage}
                            previousLabel={''}
                            nextLabel={''}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            pageCount={pageNumbers}
                            marginPagesDisplayed={3}
                            pageRangeDisplayed={3}
                            onPageChange={this.onPageChanged}
                            containerClassName={'pagination'}
                            subContainerClassName={'pages pagination'}
                            activeClassName={'active'}
                            nextLinkClassName={'next'}
                        />
                        <PageSizeSelector
                            pageSize={this.props.pageSize}
                            handlePageSizeChange={this.onPageSizeChanged}
                        />
                    </div>
                )}
                {!this.props.loadingStatus && this.props.totalReportsCount === 0 && (
                    <div className="pagination-container">
                        <div className="reports-list-no-article-title">
                            <FormattedMessage id={'ReportBuilderPage_NoDocumentsFound'} />
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

// add the "key" to the rows, needed for HOC's down the road
const mapReportsToRows = (report) => ({
    ...report,
    key: `report_${report.id}`,
    object: report,
});

const mapDispatchToProps = function (dispatch) {
    return bindActionCreators(
        {
            updateBreadcrumbs: breadcrumbsActions.updateBreadcrumbs,
            setLoadingStatus: reportBuilderActions.setLoadingStatus,
            setSelectedReport: reportBuilderActions.setSelectedReport,
            setSelectedReportById: reportBuilderActions.setSelectedReportById,
            discardSelectedReport: reportBuilderActions.discardSelectedReport,
            discardScrollToReport: reportBuilderActions.discardSelectedReportById,
            setReportsCount: reportBuilderActions.setReportsCount,
            setCurrentPage: reportBuilderActions.setCurrentPage,
            resetReportBuilderState: reportBuilderActions.resetReportBuilderState,
            updateProperty: reportBuilderActions.updateReportProperty,
            setSelectedArticle: articlesManagerActions.selectArticle,
            updateReportDeliveredStatus: reportBuilderActions.updateReportDeliveredStatus,
            updateSnippetsInCurrentReport: currentReportActions.updateSnippets,
            updateGeneralPageSize: userPreferencesActions.updateGeneralPageSize,
        },
        dispatch
    );
};

const mapStateToProps = (state) => {
    return {
        batchReportsPooling: state.reportBuilder.batchReportsPooling,
        selectedReport: state.reportBuilder.selectedReport,
        searchParams: state.searchParams,
        breadcrumbs: state.breadcrumbs,
        currentReport: state.currentReport,
        reports: state.reportBuilder.reports,
        loadingStatus: state.reportBuilder.loadingStatus,
        totalReportsCount: state.reportBuilder.totalReportsCount,
        currentPage: state.reportBuilder.currentPage,
        loadedReports: state.reportBuilder.reports.length,
        pageSize: state.user.preferences.generalSettings.generalPageSize,
        isSnapshotVisible: state.user.preferences.generalSettings.showSnapshot,
        isSnapshotDisabled: state.popupModel.isSnapshotDisabled,
        popupModel: state.popupModel,
        publicRecordsOn: state.user.appSettings.prsOn,
        reportSnippetsPageSize: state.user.reportSnippetsPageSize,
        contentsOptions: state.user.preferences.contentsOptions,
        showDeliveryOptions: state.user.preferences.showDeliveryOptions,
        reportDeliveryAvailabilityDays: state.user.normalReportDeliveryAvailabilityDays,
        prReportDeliveryAvailabilityDays: state.user.prReportDeliveryAvailabilityDays,
        interfaceLanguage: state.user.preferences.language,
        startEachArticleOnNewPage: state.user.preferences.startEachArticleOnNewPage,
        timezone: state.user.timezone,
        emailAddress: state.user.email,
        language: state.user.preferences.language,
    };
};
export { ReportBuilderMain as TestReportBuilderMain };

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    injectIntl,
    withConfirmationModal,
    withAppContext
)(ReportBuilderMain);
