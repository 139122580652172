export const UPDATE_SANCTIONS_POD_RISK = 'UPDATE_SANCTIONS_POD_RISK';
export const RESET_SANCTIONS_POD_RISK = 'CLEAR_SANCTIONS_POD_RISK';

const sanctionsRiskActions = {
    updateSanctionsRisk: (sanctionsRisk) => {
        return {
            type: UPDATE_SANCTIONS_POD_RISK,
            payload: sanctionsRisk,
        };
    },
    resetSanctionsRisk: () => {
        return {
            type: RESET_SANCTIONS_POD_RISK,
        };
    },
};

export default sanctionsRiskActions;
