import React from 'react';
import { compose } from 'redux';
import hasError from '../Table/hoc/hasError';
import withSorting from '../Table/hoc/withSorting';
import composeTable from '../Table/composeTable';
import { DefaultSortableHeader, DefaultRows } from '../Table/components/defaults';
import { FormattedMessage } from 'react-intl';
import withFilter from '../Table/hoc/withFilter';
import { SORT_DIRECTION } from '@constants';
import withPagination from '../Table/hoc/withPagination';
import withAutoLoad from '../Table/hoc/withAutoLoad';
import UboNoFilteringResults from '@pages/MainSearch/components/UboNoFilteringResults';

/**
 * This is the Table Consumer component for UboTableConsumer
 * @type {any | *}
 */

const Table = compose(
    // Composing the functionalities
    hasError(/* Default error component */),
    withAutoLoad,
    withFilter,
    withSorting,
    withPagination
)(
    // Now composing the table
    composeTable(DefaultSortableHeader, compose()(DefaultRows))
);

/**
 * This is the main Ubo table
 * @param props
 * @returns {XML}
 * @constructor
 */
const UboTableConsumer = (props) => {
    const transformRow = (object) => ({
        key: (Math.random() * 10).toString(),
        columns: {
            subjectDuns: object.subjectDuns,
            subjectName: object.subjectName,
            beneficiaryName: object.beneficiaryName,
            beneficiaryDuns: object.beneficiaryDuns,
            beneficiaryType: object.beneficiaryType,
            directOwnershipPercentage: object.directOwnershipPercentage,
            beneficialOwnershipPercentage: object.beneficialOwnershipPercentage,
        },
        object,
    });

    const headers = [
        {
            columnKey: 'subjectDuns',
            label: <FormattedMessage id="UboDocumentViewTableHeader.subjectDuns" />,
            sortable: true,
        },
        {
            columnKey: 'subjectName',
            label: <FormattedMessage id="UboDocumentViewTableHeader.subjectName" />,
            sortable: true,
        },
        {
            columnKey: 'beneficiaryName',
            label: <FormattedMessage id="UboDocumentViewTableHeader.beneficiaryName" />,
            sortable: true,
        },
        {
            columnKey: 'beneficiaryDuns',
            label: <FormattedMessage id="UboDocumentViewTableHeader.beneficiaryDuns" />,
            sortable: true,
        },
        {
            columnKey: 'beneficiaryType',
            label: <FormattedMessage id="UboDocumentViewTableHeader.beneficiaryType" />,
            sortable: true,
        },
        {
            columnKey: 'directOwnershipPercentage',
            label: <FormattedMessage id="UboDocumentViewTableHeader.directOwnershipPercentage" />,
            sortable: true,
        },
        {
            columnKey: 'beneficialOwnershipPercentage',
            label: <FormattedMessage id="UboDocumentViewTableHeader.beneficialOwnershipPercentage" />,
            sortable: true,
        },
    ];

    const transformHeaders = (headers) => {
        headers.map((header, index) => {
            if (header.columnKey === props.sorting.sortBy) {
                headers[index] = {
                    ...headers[index],
                    sortedBy: true,
                    direction: props.sorting.direction,
                };
            } else {
                headers[index] = {
                    ...headers[index],
                    sortedBy: false,
                    direction:
                        props.sorting.direction === SORT_DIRECTION.ASC ? SORT_DIRECTION.DESC : SORT_DIRECTION.ASC,
                };
            }
        });

        return headers;
    };

    const handleLoadData = () => {
        return props.data.onLoadData().catch((e) => {
            console.error(e);
        });
    };

    return (
        <div className={'generic-table ubo-table'}>
            <Table
                {...props}
                headers={transformHeaders(headers)}
                data={{
                    ...props.data,
                    onLoadData: handleLoadData,
                    rows: props.data.rows && props.data.rows.map(transformRow),
                }}
                onTransformRow={transformRow}
            />
            {props.data.rows.length === 0 && (
                <UboNoFilteringResults selectedFiltering={props.filtering.filteringDropdownSelected} />
            )}
        </div>
    );
};

export default UboTableConsumer;
