import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import AdminLock from '@reusable/AdminLock/AdminLock';
import UserCostCodeDropdown from './UserCostCodeDropdown';
import AdminCostCodeContainer from './AdminCostCodeContainer';
import { CATEGORY_NAMES, FE_LOCK_NAMES, USER_PREFERENCES_SECTIONS } from '@constants';
import DefaultSearchSettings from './DefaultSearchSettings';
import NewsSearches from './NewsSearches';
import LegalSearches from './LegalSearches';
import SearchResults from './SearchResults';
import PreferencesCategoriesGroupDateSelect from './PreferencesCategoriesGroupDateSelect';
import SanctionsAndWatchListsSection from './SanctionsAndWatchlistsSection';

const GeneralSettingsContainer = (props) => {
    const lawSources = props.sources.find((source) => source.key === CATEGORY_NAMES.LAW_SOURCES);
    const isLegalChecked = lawSources && lawSources.checked;
    const sanctionsAndWatchListsPreferences = useSelector((state) => state.user.sanctionsAndWatchListsPreferences);

    const categoriesDateRanges = {
        newsSources: props.generalSettings.newsSources.dateRange,
        legalSources: props.generalSettings.legalSources.dateRange,
        companySources: props.generalSettings.companySources.dateRange,
    };

    return (
        <div className="user-preferences-category-container">
            <h2 className="user-preferences-category-container__segment general-settings">
                <FormattedMessage id="UserPreferences.leftSideNavigation.generalSettings" />
                {props.userIsAdmin && (
                    <div className="admin-apply-preferences-controls">
                        <button
                            type="button"
                            className="button-primary-lg"
                            disabled={!props.isPageDirty}
                            onClick={props.toggleAdminSaveModal}
                        >
                            <FormattedMessage id="General_CoreFunctionality_UIText_general.save" />
                        </button>
                        <button
                            type="button"
                            className="button-secondary-lg"
                            disabled={!props.isPageDirty}
                            onClick={props.toggleAdminCancelModal}
                        >
                            <FormattedMessage id="General_CoreFunctionality_UIText_general.cancel" />
                        </button>
                    </div>
                )}
            </h2>
            {props.userIsAdmin && (
                <>
                    <AdminCostCodeContainer
                        adminIsCostCodeRequired={props.adminIsCostCodeRequired}
                        adminChooseOnlyFromCostCodesList={props.adminChooseOnlyFromCostCodesList}
                        adminCostCodesList={props.adminCostCodesList}
                        updateAdminCostCodeIsRequired={props.updateAdminCostCodeIsRequired}
                        updateAdminCostCodeOnlyFromCostCodeList={props.updateAdminCostCodeOnlyFromCostCodeList}
                        addCostCodeToList={props.addCostCodeToList}
                        deleteCostCodeFromList={props.deleteCostCodeFromList}
                    />
                    <div className="section-separator" />
                </>
            )}
            <div>
                <DefaultSearchSettings
                    handleSourceSelection={props.handleSourceSelection}
                    sources={props.sources}
                    sourcesInformation={props.sourcesInformation}
                    // admin props
                    sourcesLocked={props.userLocks.sourcesIncluded}
                    userIsAdmin={props.userIsAdmin}
                    onClickLock={props.onClickLock}
                    // auto-save props
                    section={USER_PREFERENCES_SECTIONS[props.containerName].SOURCES}
                    changeMade={props.changeMade}
                    autosaveHasError={props.autosaveHasError}
                    modifiedSections={props.modifiedSections}
                    arePreferencesSaving={props.arePreferencesSaving}
                />
                <div className="section-separator" />
                <div
                    className={
                        props.userIsAdmin
                            ? 'section-enabled'
                            : props.userLocks.categoriesDefaultDateRange
                            ? 'section-disabled'
                            : 'section-enabled'
                    }
                >
                    <div className="container-section">
                        <PreferencesCategoriesGroupDateSelect
                            values={categoriesDateRanges}
                            onChange={props.changeDateRange}
                            dateRanges={props.generalSettings.dateRanges}
                            userOfferings={props.userOfferings}
                            // auto-save props
                            section={USER_PREFERENCES_SECTIONS[props.containerName].DEFAULT_DATE}
                            changeMade={props.changeMade}
                            autosaveHasError={props.autosaveHasError}
                            modifiedSections={props.modifiedSections}
                            arePreferencesSaving={props.arePreferencesSaving}
                        />
                    </div>
                </div>
                <div className="section-separator" />
                <div
                    className={
                        props.userIsAdmin
                            ? 'section-enabled'
                            : props.userLocks.masterLockNewsCompanyLegalSearches
                            ? 'section-disabled'
                            : 'section-enabled'
                    }
                >
                    <div className="container-section">
                        {props.userIsAdmin ? (
                            <AdminLock
                                lockName={FE_LOCK_NAMES.masterLockNewsCompanyLegalSearches}
                                onClickLock={props.onClickLock}
                                isSectionLock={true}
                                isLocked={props.userLocks.masterLockNewsCompanyLegalSearches}
                            />
                        ) : (
                            props.userLocks.masterLockNewsCompanyLegalSearches && (
                                <AdminLock
                                    lockName={FE_LOCK_NAMES.masterLockNewsCompanyLegalSearches}
                                    isDisabled={true}
                                />
                            )
                        )}
                        <NewsSearches
                            changeDateRange={props.changeDateRange}
                            changeProximity={props.changeProximity}
                            dateRanges={props.generalSettings.dateRanges}
                            dateRange={props.generalSettings.newsSources.dateRange}
                            proximities={props.proximities}
                            proximity={props.proximity}
                            excludeNonBusinessNews={props.excludeNonBusinessNews}
                            changeExcludeNonBusinessNews={props.changeExcludeNonBusinessNews}
                            excludeNewsWires={props.excludeNewsWires}
                            changeExcludeNewsWires={props.changeExcludeNewsWires}
                            // admin props
                            dateRangeLock={props.userLocks.newsSearchesDateRange}
                            proximityLock={props.userLocks.newsSearchesProximitySetting}
                            userIsAdmin={props.userIsAdmin}
                            onClickLock={props.onClickLock}
                            masterLock={props.userLocks.masterLockNewsCompanyLegalSearches}
                            // auto-save props
                            section={USER_PREFERENCES_SECTIONS[props.containerName].NEWS_SOURCES}
                            changeMade={props.changeMade}
                            autosaveHasError={props.autosaveHasError}
                            modifiedSections={props.modifiedSections}
                            arePreferencesSaving={props.arePreferencesSaving}
                            newsExcludeTogglesEnabled={props.newsExcludeTogglesEnabled}
                        />
                    </div>
                </div>
                {sanctionsAndWatchListsPreferences && (
                    <>
                        <div className="section-separator" />
                        <div
                            className={
                                props.userIsAdmin
                                    ? 'section-enabled'
                                    : props.userLocks.sanctionsAndWatchlistsRisk
                                    ? 'section-disabled'
                                    : 'section-enabled'
                            }
                        >
                            <div className="container-section">
                                {(props.userIsAdmin || props.userLocks.sanctionsAndWatchlistsRisk) && (
                                    <AdminLock
                                        lockName={FE_LOCK_NAMES.sanctionsAndWatchlistsRisk}
                                        onClickLock={props.onClickLock}
                                        isSectionLock={true}
                                        isLocked={props.userLocks.sanctionsAndWatchlistsRisk}
                                        isDisabled={!props.userIsAdmin && props.userLocks.sanctionsAndWatchlistsRisk}
                                    />
                                )}
                                <>
                                    <SanctionsAndWatchListsSection
                                        authoritiesTreePreferences={props.authoritiesTreePreferences}
                                        updateSanctionsSourcesRisks={props.updateSanctionsSourcesRisks}
                                        setSanctionsSectionOffset={props.setSanctionsSectionOffset}
                                        getSanctionsDefaultPreferences={props.getSanctionsDefaultPreferences}
                                        // admin props
                                        sanctionsAndWatchlistsRiskLock={props.userLocks.sanctionsAndWatchlistsRisk}
                                        userIsAdmin={props.userIsAdmin}
                                        isPageDirty={props.isPageDirty}
                                        // auto-save props
                                        section={USER_PREFERENCES_SECTIONS[props.containerName].SANCTIONS_WATCHLIST}
                                        changeMade={props.changeMade}
                                        autosaveHasError={props.autosaveHasError}
                                        modifiedSections={props.modifiedSections}
                                        arePreferencesSaving={props.arePreferencesSaving}
                                    />
                                </>
                            </div>
                        </div>
                    </>
                )}
                {isLegalChecked && (
                    <>
                        <div className="section-separator" />
                        <div
                            className={
                                props.userIsAdmin
                                    ? 'section-enabled'
                                    : props.userLocks.masterLockLegalSourceSearches
                                    ? 'section-disabled'
                                    : 'section-enabled'
                            }
                        >
                            <div className="container-section">
                                {props.userIsAdmin ? (
                                    <AdminLock
                                        lockName={FE_LOCK_NAMES.masterLockLegalSourceSearches}
                                        onClickLock={props.onClickLock}
                                        isSectionLock={true}
                                        isLocked={props.userLocks.masterLockLegalSourceSearches}
                                    />
                                ) : (
                                    props.userLocks.masterLockLegalSourceSearches && (
                                        <AdminLock
                                            lockName={FE_LOCK_NAMES.masterLockLegalSourceSearches}
                                            isDisabled={true}
                                        />
                                    )
                                )}

                                <>
                                    <LegalSearches
                                        changeDateRange={props.changeDateRange}
                                        changeLegalSource={props.changeLegalSource}
                                        dateRanges={props.generalSettings.dateRanges}
                                        dateRange={props.generalSettings.legalSources.dateRange}
                                        legalSources={props.legalSources}
                                        legalSource={props.legalSource}
                                        searchNamePartyEntity={props.generalSettings.legalSources.searchNamePartyEntity}
                                        changeSearchNamePartyEntity={(option, isAdminUser) =>
                                            props.updateSearchNamePartyEntity(option, isAdminUser)
                                        }
                                        sourcesInformation={props.sourcesInformation}
                                        // admin props
                                        dateRangeLock={props.userLocks.legalSourceDefaultDateRange}
                                        legalSourceLock={props.userLocks.legalSourceDefaultLegalSources}
                                        masterLock={props.userLocks.masterLockLegalSourceSearches}
                                        onClickLock={props.onClickLock}
                                        userIsAdmin={props.userIsAdmin}
                                        //auto-save props
                                        section={USER_PREFERENCES_SECTIONS[props.containerName].LEGAL_SOURCES}
                                        changeMade={props.changeMade}
                                        autosaveHasError={props.autosaveHasError}
                                        modifiedSections={props.modifiedSections}
                                        arePreferencesSaving={props.arePreferencesSaving}
                                    />
                                </>
                            </div>
                        </div>
                    </>
                )}
                {!props.userIsAdmin && (
                    <div className="section-enabled">
                        <div className=" sub-section-separator sub-section-separator__no-margin-top" />
                        <UserCostCodeDropdown
                            costCodes={props.costCodes}
                            costCode={props.costCode}
                            changeCostCode={props.changeCostCode}
                            isCostCodeRequired={props.isCostCodeRequired}
                            chooseOnlyFromCostCodesList={props.chooseOnlyFromCostCodesList}
                            // auto-save props
                            section={USER_PREFERENCES_SECTIONS[props.containerName].COST_CODE}
                            changeMade={props.changeMade}
                            autosaveHasError={props.autosaveHasError}
                            modifiedSections={props.modifiedSections}
                            arePreferencesSaving={props.arePreferencesSaving}
                        >
                            <div className="user-preferences-category-container__note">
                                <FormattedMessage id="UserPreferences_cost_code_label" />
                            </div>
                        </UserCostCodeDropdown>
                    </div>
                )}
                <div className="section-separator" />
                <div
                    className={
                        props.userIsAdmin
                            ? 'section-enabled'
                            : props.userLocks.searchResults
                            ? 'section-disabled'
                            : 'section-enabled'
                    }
                >
                    <SearchResults
                        handleShowSnapshot={props.handleShowSnapshot}
                        showSnapshot={props.showSnapshot}
                        // admin props
                        searchResultsLock={props.userLocks.searchResults}
                        onClickLock={props.onClickLock}
                        userIsAdmin={props.userIsAdmin}
                        // auto-save props
                        section={USER_PREFERENCES_SECTIONS[props.containerName].SEARCH_RESULTS}
                        changeMade={props.changeMade}
                        autosaveHasError={props.autosaveHasError}
                        modifiedSections={props.modifiedSections}
                        arePreferencesSaving={props.arePreferencesSaving}
                    />
                </div>
            </div>
        </div>
    );
};

export default GeneralSettingsContainer;
