import React from 'react';
import { injectIntl } from 'react-intl';
import { NEGATIVITY_PILL_STATES } from '@constants';
import formatRichMessage from '@utils/formatRichMessage';

const NegativityLevelPill = ({
    level,
    isShortLabel,
    isInteractive,
    intl,
    className,
    onNegativityLevelChange,
    dataTrack,
}) => {
    const isPillClickable =
        typeof onNegativityLevelChange === 'function' && className !== NEGATIVITY_PILL_STATES.DISABLED && isInteractive;

    return (
        <div
            className={`negativityLevelPillWrapper ${isInteractive ? 'interactive ' : ''}${level} ${
                className ? className : ''
            }`}
            onClick={() => isPillClickable && onNegativityLevelChange(level)}
            data-track={`${dataTrack}-pill-level-${level}`}
        >
            <span className={`negativityLevelPillLabel ${level}`}>
                {formatRichMessage(
                    { id: `NegativeNewsVisualisations.label${isShortLabel ? '.short' : ''}.${level}` },
                    intl
                )}
            </span>
        </div>
    );
};

export default injectIntl(NegativityLevelPill);
