import React, { Component } from 'react';
import CheckboxFilter from '../CheckboxFilter';

export default class LanguagePostFilter extends Component {
    handleChange = (options, option, e) => {
        // for now just pass the handler up as it is, any transformations should happen here
        this.props.onChange(options, option, e);
    };

    render() {
        return (
            <div className={'language-postfilter'}>
                <CheckboxFilter {...this.props} onChange={this.handleChange} fieldId={'language-postfilter'} />
            </div>
        );
    }
}
