import React from 'react';
import PropTypes from 'prop-types';
import { COMPANY_SEARCH, MODAL_TYPE, NEGATIVE_NEWS_SELECTED_LANGUAGE, PERSON_SEARCH } from '@constants';
import formatRichMessage from '@utils/formatRichMessage';
import PopupModel from '@reusable/PopupModal/PopupBuilder';
import popupModelActions from '@reusable/PopupModal/redux/PopupModel.actions';
import reduxStore from '@reduxStore';
import { COMPANY_CHECK } from '@Main/Main.reducers';
import CategoryNewsSearches from './CategoryNewsSearches';
import { injectIntl } from 'react-intl';

class NegativeNewsSearchTerms extends React.Component {
    static propTypes = {
        onReset: PropTypes.func,
        segment: PropTypes.string,
        isReadonly: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        this.state = {
            editLanguageSelection: false,
        };

        this.showPopup = this.showPopup.bind(this);
        this.toggleLanguageEditMode = this.toggleLanguageEditMode.bind(this);
        this.getLanguageQuery = this.getLanguageQuery.bind(this);
        this.isLanguageDisabled = this.isLanguageDisabled.bind(this);
        this.changeLanguageSelection = this.changeLanguageSelection.bind(this);
    }

    getLanguageQuery(language) {
        let query = {};
        let defaultQuery = {};

        this.props.languages.forEach((settings) => {
            if (settings.language === language) {
                query.low = settings.lowQueryString || '';
                query.medium = settings.mediumQueryString || '';
                query.high = settings.highQueryString || '';

                defaultQuery.low = settings.lowDefaultQueryString || '';
                defaultQuery.medium = settings.mediumDefaultQueryString || '';
                defaultQuery.high = settings.highDefaultQueryString || '';
            }
        });

        return {
            query,
            defaultQuery,
        };
    }

    isLanguageDisabled(language, languageSelection) {
        let isLanguageChecked = false;
        Object.keys(NEGATIVE_NEWS_SELECTED_LANGUAGE).forEach((key) => {
            if (NEGATIVE_NEWS_SELECTED_LANGUAGE[key].name === languageSelection) {
                NEGATIVE_NEWS_SELECTED_LANGUAGE[key].values.forEach((value) => {
                    if (value === language) {
                        isLanguageChecked = true;
                    }
                });
            }
        });

        return isLanguageChecked;
    }

    changeLanguageSelection(type, option) {
        let languagesToBeChecked = [];
        this.props.updateNewsSourcesSelection(type, option);
        Object.keys(NEGATIVE_NEWS_SELECTED_LANGUAGE).forEach((key) => {
            if (NEGATIVE_NEWS_SELECTED_LANGUAGE[key].name === option) {
                languagesToBeChecked = NEGATIVE_NEWS_SELECTED_LANGUAGE[key].values;
            }
        });
        this.props.updateCheckedLanguagesUponKey(type, languagesToBeChecked);
    }

    showPopup(event) {
        let popupType = event.currentTarget.value;
        let clickedTopic = event.currentTarget.title;
        let type = event.currentTarget.name === COMPANY_CHECK ? COMPANY_SEARCH : PERSON_SEARCH;
        let formattedNegativeNewsString = '';
        let query;
        let defaultQuery;
        let title;

        if (popupType === MODAL_TYPE.USER_PREFERENCES_EDIT_NEGATIVE_SEARCH_TERMS) {
            query = this.getLanguageQuery(clickedTopic).query;
            defaultQuery = this.getLanguageQuery(clickedTopic).defaultQuery;
            title = formatRichMessage({ id: 'UserPreferences.modal.editNegativeNewsTerms' }, this.props.intl, {
                term: clickedTopic,
            });
        }

        let popupModel = new PopupModel.Builder(popupType)
            .setPopupProperty('isVisible', true)
            .setPopupProperty('searchQueryType', type)
            .setPopupProperty('articleType', this.props.articleType)
            .setPopupProperty('clickedTerm', clickedTopic)
            .setPopupProperty('formattedNegativeNewsString', formattedNegativeNewsString)
            .setPopupProperty('segment', this.props.segment)
            .setPopupProperty('customQuery', query)
            .setPopupProperty('defaultCustomQuery', defaultQuery)
            .setPopupProperty('negativityLevels', this.props.negativityLevels)
            .setPopupProperty('title', title)
            .setPopupProperty('userIsAdmin', this.props.userIsAdmin)
            .build();
        reduxStore.dispatch(popupModelActions.setPopupModel(popupModel));
    }

    toggleLanguageEditMode(e) {
        this.setState({
            editLanguageSelection: e.target.checked,
        });
    }

    render() {
        return (
            <div className="user-preferences-category-container">
                <CategoryNewsSearches
                    segment={this.props.segment}
                    toggleLanguageEditMode={this.toggleLanguageEditMode}
                    editLanguageSelection={this.state.editLanguageSelection}
                    showPopup={this.showPopup}
                    updateNegativityLevel={this.props.updateNegativityLevel}
                    negativityLevel={this.props.negativityLevel}
                    negativityLevels={this.props.negativityLevels}
                    changeLanguageSelection={this.changeLanguageSelection}
                    handleNegativeNewsLanguageSelection={this.props.handleNegativeNewsLanguageSelection}
                    defaultSources={this.props.defaultSources}
                    languages={this.props.languages}
                    isLanguageDisabled={this.isLanguageDisabled}
                    customNewsQuery={this.props.customNewsQuery}
                    searchSpecifically={this.props.searchSpecifically}
                    changeSourcesSearchOption={this.props.changeSourcesSearchOption}
                    userLocks={this.props.userLocks}
                    // admin props
                    userIsAdmin={this.props.userIsAdmin}
                    handleLockClick={this.props.handleLockClick}
                    masterLock={this.props.masterLock}
                    isNegativeSearchSourcesReadonly={this.props.isNegativeSearchSourcesReadonly}
                    negativeSearchSourcesLockName={this.props.negativeSearchSourcesLockName}
                    negativeSearchLanguageLockName={this.props.negativeSearchLanguageLockName}
                    isNegativeSearchLanguageReadonly={this.props.isNegativeSearchLanguageReadonly}
                    isNegativeSearchLevelReadonly={this.props.isNegativeSearchLevelReadonly}
                    negativeSearchLevelLockName={this.props.negativeSearchLevelLockName}
                    // auto-save props
                    modifiedSections={this.props.modifiedSections}
                    arePreferencesSaving={this.props.arePreferencesSaving}
                    autosaveHasError={this.props.autosaveHasError}
                    changeMade={this.props.changeMade}
                />
            </div>
        );
    }
}

export default injectIntl(NegativeNewsSearchTerms);
