/**
 * LANGUAGE SELECTOR
 *
 * @version 0.1
 */

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { DEFAULT_LANGUAGE, LOCALES } from '@constants';

import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import userPreferenceApi from '@pages/UserPreferances/UserPreferencesApi.api';
import utils from '@utils/utilities';
import reduxStore from '@reduxStore';
import userPreferencesActions from '@pages/UserPreferances/redux/UserPreferences.actions';
import { withAppContext } from '@utils/contexts';
import { compose } from 'redux';

class LanguageSelector extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            previousLanguage: DEFAULT_LANGUAGE,
            displayedLanguage: (
                <FormattedMessage
                    id={LOCALES[this.props.prefs.language ? this.props.prefs.language : DEFAULT_LANGUAGE].id}
                />
            ),
            languagesArr: Object.keys(LOCALES),
        };

        this.changeLanguage = this.changeLanguage.bind(this);
    }

    changeLanguage(event) {
        let selectedLanguage = event.currentTarget.getAttribute('data-language');

        if (this.props.context.isUserMIP) {
            reduxStore.dispatch(userPreferencesActions.updateLanguage(selectedLanguage));
        } else {
            reduxStore.dispatch(userPreferencesActions.updateLanguage(selectedLanguage));

            userPreferenceApi
                .updateUserPreference({
                    userPreferences: {
                        language: selectedLanguage,
                    },
                })
                .catch(() => {
                    utils.showNotificationsMessage({
                        messageText: 'General_CoreFunctionality_Error_general.saveError',
                        messageType: 'system-error',
                    });
                });
        }

        this.setState({
            previousLanguage: selectedLanguage,
            displayedLanguage: <FormattedMessage id={LOCALES[selectedLanguage].id} />,
        });
    }

    render() {
        return (
            <div className="diligence-header__menu-link diligence-header__menu-link--language" data-testid={'diligence-header__menu-link__language'}>
                {this.state.displayedLanguage}

                <div className="dropdown-content dropdown-content__language-dropdown">
                    <ul>
                        {this.state.languagesArr.map((value) => {
                            return (
                                <li key={value} data-language={value} data-testid={`dropdown-content__language-dropdown:${value}`} onClick={this.changeLanguage}>
                                    <FormattedMessage id={LOCALES[value].id} />
                                    {this.props.prefs.language === value ? <span className="la-Check" /> : null}
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>
        );
    }
}

const mapStateToProps = function (state) {
    return {
        prefs: state.user.preferences,
    };
};

export default compose(connect(mapStateToProps), withRouter, withAppContext)(LanguageSelector);
export { LanguageSelector as TestLanguageSelector };
