import React from 'react';
import { FormattedMessage } from 'react-intl';
import { withMIPUserHideView } from '@utils/contexts';

function ModalUpdateCheckbox(props) {
    if (!props.isVisible) {
        return <span></span>;
    }

    return (
        <div className={`popup-modal__updateCheckbox ${props.id}`}>
            <div className="user-preference-checkbox">
                <input
                    id={`update-user-preferences-adhoc-${props.id}`}
                    data-testid={'update-user-preferences-checkbox-test-id'}
                    type="checkbox"
                    checked={props.isChecked === true ? 'checked' : ''}
                    onChange={props.changeUpdateCheckbox}
                    disabled={props.disabled}
                />
                <label
                    htmlFor={`update-user-preferences-adhoc-${props.id}`}
                    className="preference-checkbox-label"
                    disabled={props.disabled}
                >
                    <FormattedMessage id={'AdHocSearch.Popup.UpdateUserPreferences'}></FormattedMessage>
                </label>
            </div>
        </div>
    );
}

export default withMIPUserHideView(ModalUpdateCheckbox);
