import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DateRangeRadioGroup from '@reusable/Form/DateRangeRadioGroup';

// TODO: More source related logic goes here
export default class RadioDateFilterComponent extends Component {
    static propTypes = {
        onChange: PropTypes.func,
    };

    render() {
        return (
            <div className={'filter-radio-group'}>
                <div className="radios-wrapper">
                    <DateRangeRadioGroup {...this.props} onChange={this.props.onChange} />
                </div>
            </div>
        );
    }
}
