import { useEffect, useState } from 'react';
import usePrevious from './usePrevious';

function useStateWithPrev(initialValue) {
    const [value, setValue] = useState(initialValue);
    const prevState = usePrevious(value);

    useEffect(() => {
        setValue(value);
    }, [value]);
    return [value, setValue, prevState];
}

export default useStateWithPrev;
