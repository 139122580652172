import React, { Component, Fragment } from 'react';
import Modal from '@reusable/Modal/Modal.index';
import { POPUP_SIZE_SMALL } from '@constants';
import InputComponent from '@reusable/CheckboxesRadioButtons/InputComponent';

const withDownloadMultipleReportsModal = (WrappedComponent, config = {}) => {
    class RadioButtonsModal extends Component {
        constructor(props) {
            super(props);

            this.state = {
                modalVisible: false,
                secondaryBtnText: 'General_CoreFunctionality_UIText_general.cancel',
            };
            this.handleMultipleReportsDelivery = this.handleMultipleReportsDelivery.bind(this);
            this.handleCloseModal = this.handleCloseModal.bind(this);
            this.handleChange = this.handleChange.bind(this);
            this.onConfirmAction = null;

            this.config = {
                popupSize: POPUP_SIZE_SMALL,
                className: 'confirmation-modal',
                ...config,
            };
        }

        handleMultipleReportsDelivery(modalProps, onConfirm) {
            this.onConfirmAction = () => {
                this.setState({ modalVisible: false });
                onConfirm(this.state.options);
            };

            this.setState({
                modalVisible: true,
                ...modalProps,
            });
        }

        handleChange(options, option, e) {
            const checked = e.target.checked;
            this.setState({
                options: options.map((item) =>
                    item.key === option.key ? { ...item, value: checked } : { ...item, value: !checked }
                ),
            });
        }

        handleCloseModal() {
            this.setState({ modalVisible: false });
        }

        render() {
            const buttons = [
                {
                    text: this.state.primaryBtnText,
                    primary: true,
                    click: this.onConfirmAction,
                },
                {
                    text: this.state.secondaryBtnText,
                    secondary: true,
                    click: this.handleCloseModal,
                },
            ];

            return (
                <Fragment>
                    {this.state.modalVisible && (
                        <Modal closeButtonHandler={this.handleCloseModal} buttons={buttons} {...this.config}>
                            <div className="popup-modal notranslate">
                                <div className="popup-modal-header">{this.state.title}</div>
                                <div className="popup-modal-body">
                                    <div className="popup-modal__description">{this.state.description}</div>
                                    {this.state.options && (
                                        <div className="popup-modal-body__multiple-delivery-options">
                                            <InputComponent
                                                {...this.props}
                                                options={this.state.options}
                                                onChange={this.handleChange}
                                                fieldId={'multiple-delivery-options'}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </Modal>
                    )}
                    <WrappedComponent {...this.props} onMultipleReportsDelivery={this.handleMultipleReportsDelivery} />
                </Fragment>
            );
        }
    }

    return RadioButtonsModal;
};

export default withDownloadMultipleReportsModal;
