import xhr from '@utils/xhr';

const userPreferenceApi = {
    getUserPreferences() {
        return xhr.get({
            url: '/api/user/preferences',
        });
    },

    changeDateRange(dateRange, section) {
        return xhr
            .post({
                url: '/api/user/preferences',
                data: {
                    generalSettings: {
                        [section]: {
                            dateRange: dateRange,
                        },
                    },
                },
            })
            .then((response) => {
                return response.body;
            });
    },

    changeLegalSource(source, section) {
        return xhr
            .post({
                url: '/api/user/preferences',
                data: {
                    generalSettings: {
                        [section]: {
                            defaultDateSource: source,
                        },
                    },
                },
            })
            .then((response) => {
                return response.body;
            });
    },

    updateUserPreference(userPreferenceObject) {
        return xhr
            .post({
                url: '/api/user/preferences',
                data: userPreferenceObject,
            })
            .then((response) => {
                return response.body;
            });
    },

    updateAdminUserPreference(adminPreferenceObject) {
        return xhr
            .post({
                url: '/api/user/admin/preferences',
                data: adminPreferenceObject,
            })
            .then((response) => {
                return response.body;
            });
    },

    updateUserPreferencesAfterAdHoc(payload) {
        return xhr.post({
            url: '/api/user/preferences',
            data: payload,
        });
    },

    getPreferencesLastUpdatedInfo(userPermId) {
        return xhr
            .get({
                url: '/api/user/preferencesLastUpdatedInfo',
                data: { userPermId },
            })
            .then((response) => {
                // No response body for anonymized users because of "anonymized" userPermId
                if (!response.body) {
                    const { lastupdatedpreferencesdate, updatedbyadmin } = response.headers;

                    if (lastupdatedpreferencesdate) {
                        response.body = {
                            lastUpdatedPreferencesDate: lastupdatedpreferencesdate,
                            updatedByAdmin: updatedbyadmin,
                            userPermId,
                        };
                    }
                }

                return response;
            });
    },

    getDefaultUserSanctionsPreferences() {
        return xhr
            .post({
                url: '/api/user/reset-sanctions',
            })
            .then((response) => {
                return response.body;
            }).catch((error) => {
                return error;
            });
    },

    getDefaultAdminSanctionsPreferences() {
        return xhr
            .post({
                url: '/api/user/admin/reset-sanctions',
            })
            .then((response) => {
                return response.body;
            }).catch((error) => {
                return error;
            });
    }
};

export default userPreferenceApi;
