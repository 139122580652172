//@flow

import * as React from 'react';
import { POPUP_SIZE_SMALL } from '@constants';
import Slider from '@reusable/Slider/Slider.index';
import { Loader } from 'semantic-ui-react';
import BoxErrorMessage from '@reusable/ErrorMessage/BoxErrorMessage';
import ArticlesUtils from '@pages/MainSearch/ArticlesUtils';
import formatRichMessage from '@utils/formatRichMessage';
import { injectIntl } from 'react-intl';
// $FlowFixMe
import { ReactComponent as BannerInfoIcon } from '../../../../assets/icons/BannerInfoIcon.svg';
// $FlowFixMe
import { ReactComponent as BannerInfoIconDark } from '../../../../assets/icons/BannerInfoIconDark.svg';
import withThemeSwitcherContext from '@scripts/contexts/hoc/withThemeSwitcher';

type State = {
    modalVisible: boolean,
    headers: Array<any>,
    headerButtons: Array<any>,
    title: ?string,
    content: ?string,
    publicationType: Array<?string>,
    slideOut: boolean,
    logo: ?string,
    hasError?: boolean,
    showLoader?: boolean,
};

type Config = {
    popupSize?: string,
    className?: string,
    active?: boolean,
};

type Props = {
    intl: Object,
    isDarkMode: boolean,
};

const withSliderAnimation = (
    WrappedComponent: React.AbstractComponent<Props, Config>,
    config: Config = {}
): React.AbstractComponent<Props, Config> => {
    class withSliderAnimationComponent extends React.Component<Props, State> {
        config: Object;
        buttons: Object;

        constructor(props) {
            super(props);

            this.state = {
                modalVisible: false,
                headers: [],
                headerButtons: [],
                title: null,
                content: null,
                publicationType: [],
                slideOut: false,
                logo: null,
            };

            this.config = {
                popupSize: POPUP_SIZE_SMALL,
                className: 'slider-modal',
                active: true,
                ...config,
            };
        }

        handleSlideModal = (modalProps: State): void => {
            setTimeout(() => {
                // block window scroll
                if (document.body) {
                    document.body.style.overflowY = 'hidden';
                }
            }, 600);

            this.setState({
                modalVisible: true,
                ...modalProps,
            });
        };

        handleUpdateModalInfo = (modalProps: State): void => {
            setTimeout(() => {
                this.setState({
                    ...modalProps,
                });
            }, 600);
        };

        handleCloseModal = (): void => {
            this.setState({ slideOut: true });

            if (document.body) {
                document.body.style.overflowY = 'scroll';
            }
            setTimeout(() => {
                this.setState({
                    modalVisible: false,
                    slideOut: false,
                    headers: [],
                    headerButtons: [],
                    title: null,
                    content: null,
                });
            }, 1000);
        };

        render() {
            const disclaimerMessageId = ArticlesUtils.isWebnewsArticle(this.state.publicationType)
                ? 'ArticleView.webNewsNotice'
                : null;

            return (
                <React.Fragment>
                    {this.state.modalVisible && (
                        <Slider
                            closeButtonHandler={this.handleCloseModal}
                            buttons={this.buttons}
                            {...this.config}
                            slideOut={this.state.slideOut}
                        >
                            {this.state.showLoader ? (
                                <Loader size="medium" active={true} />
                            ) : (
                                <div className="slider-modal " data-doc-id={this.state.id}>
                                    <div className="slider-modal-header notranslate">
                                        {this.state.headerButtons
                                            ? this.state.headerButtons.map((button) => button)
                                            : null}
                                        {disclaimerMessageId && (
                                            <div className={`article-view-disclaimer ${this.props.isDarkMode ? 'dark-mode' : ''}`} data-testid='disclaimer-message'>
                                                <div className='article-view-disclaimer__icon'>
                                                {this.props.isDarkMode ? <BannerInfoIconDark /> : <BannerInfoIcon />}
                                                    </div>
                                                <div className='article-view-disclaimer__message'>{formatRichMessage({ id: disclaimerMessageId }, this.props.intl)}</div>
                                            </div>
                                        )}
                                        {this.state.logo && (
                                            <img
                                                className="slider-modal-header-logo"
                                                src={this.state.logo}
                                                alt="slider logo"
                                            />
                                        )}
                                        <h4 dangerouslySetInnerHTML={{ __html: this.state.title }} />
                                        <div className="slider-text-top">
                                            {this.state.headers.map((header, index) => (
                                                <span key={index}>{header}</span>
                                            ))}
                                        </div>
                                    </div>
                                    <div
                                        className="slider-modal-body"
                                        dangerouslySetInnerHTML={{ __html: this.state.content }}
                                    />
                                </div>
                            )}
                            {this.state.hasError && <BoxErrorMessage messageId={'SingleArticleContent.Error'} />}
                        </Slider>
                    )}
                    <WrappedComponent
                        {...this.props}
                        onSlideModal={this.handleSlideModal}
                        onUpdateModalInfo={this.handleUpdateModalInfo}
                    />
                </React.Fragment>
            );
        }
    }

    return withThemeSwitcherContext(injectIntl(withSliderAnimationComponent));
};

export default withSliderAnimation;
