//@flow
import { isEmpty } from 'lodash';
import type { AdHocSearchType } from '../flow/AdHocSearch.typeGuards';
import type { Sources } from '@reusable/SearchBar/redux/flow/SearchBar.type.guards';
export const UPDATE_ADHOC_SEARCH: 'UPDATE_ADHOC_SEARCH' = 'UPDATE_ADHOC_SEARCH';
export const UPDATE_ADHOC_SEARCH_SOURCES: 'UPDATE_ADHOC_SEARCH_SOURCES' = 'UPDATE_ADHOC_SEARCH_SOURCES';
export const UPDATE_ADHOC_PROPERTY: 'UPDATE_ADHOC_PROPERTY' = 'UPDATE_ADHOC_PROPERTY';
export const RESET_ADHOC_OBJECT: 'RESET_ADHOC_OBJECT' = 'RESET_ADHOC_OBJECT';

export type Action =
    | { type: typeof UPDATE_ADHOC_SEARCH, payload: AdHocSearchType }
    | { type: typeof UPDATE_ADHOC_SEARCH_SOURCES, payload: Array<Sources> }
    | { type: typeof UPDATE_ADHOC_PROPERTY, payload: {| property: string, value: mixed |} }
    | { type: typeof RESET_ADHOC_OBJECT };

export type State = {
    AdHocSearch: AdHocSearchType,
};

type GetState = () => State;
type PromiseAction = Promise<Action>;
type ThunkAction = (dispatch: Dispatch, getState: GetState) => any;
type Dispatch = (action: Action | ThunkAction | PromiseAction | Array<Action>) => any;

const adHocSearchActions = {
    updateAdHocSearchObject:
        (adHocSettings: AdHocSearchType): ThunkAction =>
        async (dispatch) => {
            if (isEmpty(adHocSettings)) {
                dispatch({
                    type: RESET_ADHOC_OBJECT,
                });
            } else {
                dispatch({
                    type: UPDATE_ADHOC_SEARCH,
                    payload: { adHocSettings },
                });
            }
        },

    updateAdHocSources: (adHocSources: Array<Sources>): Action => {
        return {
            type: UPDATE_ADHOC_SEARCH_SOURCES,
            payload: adHocSources,
        };
    },

    updateAdHocProperty: (property: string, value: mixed): Action => {
        return {
            type: UPDATE_ADHOC_PROPERTY,
            payload: { property, value },
        };
    },

    resetAdHocSearch: (): Action => ({
        type: RESET_ADHOC_OBJECT,
    }),
};

export default adHocSearchActions;
