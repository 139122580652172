import React from 'react';
import ReactTooltip from 'react-tooltip';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link } from 'react-router';
import { withEditAlertContext } from '@contexts/EditAlert';
import { withAlertToggleView, withAppContext, withMIPUserHideView } from '@utils/contexts';
import { compose } from 'redux';
import { AnonymizedWarningRedirectHandler } from '@Alerts/hoc/withAnonymized';
import { CATEGORY_NAMES, CREATE_ALERT_DISABLED_LABEL } from '@constants';
import formatRichMessage from '@utils/formatRichMessage';
import categoryUtils from '@scripts/utils/categoryUtils';

// Regular Create Alert Button
export const CreateAlertBtn = ({
    articleType,
    onClick = null,
    alertsLimitReached,
    activeAlertsLimit,
    isSearchComplete = true,
}) => {
    const isUbo = articleType.indexOf(CATEGORY_NAMES.DNB) > -1;
    const isEsgArticleType = articleType === CATEGORY_NAMES.ESG_RATINGS;

    const isButtonDisabled =
        articleType === CATEGORY_NAMES.FEDERAL_DOCKETS ||
        articleType === CATEGORY_NAMES.STATE_DOCKETS ||
        isUbo ||
        isEsgArticleType ||
        alertsLimitReached ||
        !isSearchComplete;

    return isButtonDisabled ? (
        <CreateAlertBtnDisabledInt
            articleType={articleType}
            isUbo={isUbo}
            isEsgArticleType={isEsgArticleType}
            alertsLimitReached={alertsLimitReached}
            activeAlertsLimit={activeAlertsLimit}
            isSearchComplete={isSearchComplete}
        />
    ) : (
        <Link
            className="results-list-header_create-alert"
            to={`/create-alert?category=${articleType}`}
            onClick={onClick}
            data-track="create-alert-button"
        >
            <span className="la-Alerts" />
            <span>
                <FormattedMessage id={'General_CoreFunctionality_UIText_general.createAlert'} />
            </span>
        </Link>
    );
};

const CreateAlertBtnDisabled = ({
    isUbo,
    isEsgArticleType,
    articleType,
    intl,
    alertsLimitReached,
    activeAlertsLimit,
    isSearchComplete,
}) => {
    let tooltipMessage;

    const articleTypeResolved = categoryUtils.resolvedToTranslatableCategory(articleType);
    tooltipMessage = isUbo ? `${CREATE_ALERT_DISABLED_LABEL}.ubo` : `${CREATE_ALERT_DISABLED_LABEL}.${articleTypeResolved}`;

    if (alertsLimitReached && !isUbo && !isEsgArticleType) {
        tooltipMessage = `${CREATE_ALERT_DISABLED_LABEL}.alertsLimitReached`;
    }

    return (
        <React.Fragment>
            {isSearchComplete && (
                <ReactTooltip
                    id="disabled-create-alert"
                    multiline={true}
                    place="bottom"
                    type="light"
                    border={true}
                    effect="solid"
                    className="tooltips create-alert-disabled"
                />
            )}
            <span
                className="results-list-header_create-alert disabled"
                data-for="disabled-create-alert"
                data-tip={formatRichMessage({ id: tooltipMessage }, intl, { count: activeAlertsLimit })}
            >
                <span className="la-Alerts disabled" />
                <span>
                    <FormattedMessage id={'General_CoreFunctionality_UIText_general.createAlert'} />
                </span>
            </span>
        </React.Fragment>
    );
};

const CreateAlertBtnDisabledInt = injectIntl(CreateAlertBtnDisabled);

// Create alert button with anonymized modal warning
export const CreateAlertBtnAnonymized = ({ articleType, alertsLimitReached, activeAlertsLimit }) => {
    return (
        <AnonymizedWarningRedirectHandler
            redirectUrl={`/create-alert?category=${articleType}`}
            overrideHandler={(anonymizedWarningHandler) => (
                <CreateAlertBtn
                    articleType={articleType}
                    onClick={anonymizedWarningHandler}
                    alertsLimitReached={alertsLimitReached}
                    activeAlertsLimit={activeAlertsLimit}
                />
            )}
        />
    );
};

export const SaveAlertBtn = ({ disabled = false, onClick = null }) => (
    <span
        className={'results-list-header_create-alert save-alert ' + (disabled ? 'disabled' : '')}
        onClick={!disabled && onClick}
    >
        <span className="la-Alerts" />
        <span>
            <FormattedMessage id={'General_CoreFunctionality_UIText_general.saveAlert'} />
        </span>
    </span>
);

const AlertBtn = compose(
    withAlertToggleView,
    withEditAlertContext,
    withAppContext
)((props) =>
    props.editingAlert ? (
        <SaveAlertBtn onClick={props.onSaveAlert} disabled={props.saveAlertDisabled} />
    ) : props.context.isUserAnonymized ? (
        <CreateAlertBtnAnonymized
            articleType={props.articleType}
            alertsLimitReached={props.alertsLimitReached}
            activeAlertsLimit={props.activeAlertsLimit}
            isSearchComplete={props.isSearchComplete}
        />
    ) : (
        <CreateAlertBtn
            articleType={props.articleType}
            alertsLimitReached={props.alertsLimitReached}
            activeAlertsLimit={props.activeAlertsLimit}
            onClick={() => props.updatePrevPath(props.prevPath)}
            isSearchComplete={props.isSearchComplete}
        />
    )
);

export default withMIPUserHideView(AlertBtn);
