import React, { Fragment } from 'react';
import * as DilProps from '@utils/props';
import utils from '@utils/utilities';
import { FormattedMessage } from 'react-intl';

/**
 * Handles delete all, adds a button and provides delete handlers
 * @param TableComponent
 * @returns {function(*)}
 */

const withDeleteAll = (TableComponent) => {
    const withDeleteAll = (props) => {
        const { popupType, onDeleteData } = props.data.delete;
        const isDisabled = props.data.isLoading || !props.data?.rows?.length;
        return (
            <Fragment>
                {!props.publicRecordsEnabled && (
                    <button
                        className={`clear-all clear-header button-primary-sm ${isDisabled ? 'disabled' : ''}`}
                        disabled={isDisabled ?? true}
                        data-track="history-clear-all-button-action"
                        value={popupType}
                        onClick={() => onDeleteData(popupType)}
                    >
                        <span>
                            <FormattedMessage id={'History.buttonLabel.clearHistory'} />
                        </span>
                    </button>
                )}
                <TableComponent {...props} />
            </Fragment>
        );
    };

    withDeleteAll.propTypes = {
        data: DilProps.TableData.isRequired,
    };

    withDeleteAll.displayName = `withDeleteAll(${utils.getDisplayName(TableComponent)})`;

    return withDeleteAll;
};

export default withDeleteAll;
