/**
 * MESSAGE POPUP REDUX REDUCERS
 *
 * @version 0.1
 */

const SET_MESSAGES = 'SET_MESSAGES';
const CLEAR_MESSAGES = 'CLEAR_MESSAGES';
const REMOVE_MESSAGE = 'REMOVE_MESSAGE';
const APPEND_MESSAGES = 'APPEND_MESSAGES';

const popupMessages = (state = { popupTitle: null, messages: [], popupCount: 0 }, action) => {
    let count;

    switch (action.type) {
        case SET_MESSAGES: {
            const { popupTitle = state.popupTitle, messages } = action.payload;

            count = state.popupCount + 1;

            return {
                popupTitle,
                messages: [...messages],
                popupCount: count,
            };
        }

        case APPEND_MESSAGES:
            return {
                ...state,
                messages: [...state.messages, ...action.payload],
            };

        case REMOVE_MESSAGE:
            count = Math.max(state.popupCount - 1, 0);

            return {
                ...state,
                messages: count > 0 ? state.messages : [],
                popupCount: count,
            };

        case CLEAR_MESSAGES:
            return {
                popupTitle: null,
                messages: [],
                popupCount: 0,
            };

        default:
            return state;
    }
};

export default popupMessages;
