const BOTTOM_DISTANCE = 25;

export const pendoPositionController = (pendo, footer) => {
    if (pendo && footer) {
        const footerPosition = footer.getBoundingClientRect();
        const docHeight = document.documentElement.clientHeight;

        if (footerPosition.bottom >= 0 && footerPosition.top <= (window.innerHeight || docHeight)) {
            pendo.style.bottom = docHeight - footerPosition.top + BOTTOM_DISTANCE + 'px';
        } else {
            pendo.style.bottom = BOTTOM_DISTANCE + 'px';
        }
    }
};
