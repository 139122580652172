import DilQueue, { PROMISE_CANCELED, URL_SUGGESTED_NAMES } from '@utils/promiseQueue';
import reduxStore from '@reduxStore';
import suggestedNamesActions from './redux/SuggestedNames.actions';
import { MAX_SUGGESTIONS_PER_TERM, MAX_TERMS_WITH_SUGGESTIONS, OPERATOR_OR } from '@constants';
import MainSearchApi from '@MainSearch/MainSearch.api';

const SuggestedNamesUtils = {
    buildNewSuggestedNamesList({ response, index }) {
        const suggestedNames = reduxStore.getState().suggestedNames;
        let suggestedNamesList =
            suggestedNames && suggestedNames.list ? suggestedNames.list.filter((item) => item.index !== index) : [];

        if (response && response.suggestions && response.suggestions.length > 0) {
            const newItems = response.suggestions
                .filter((item, index) => index < MAX_SUGGESTIONS_PER_TERM)
                .map((item) => {
                    return {
                        name: item,
                        selected: false,
                        index,
                    };
                });

            suggestedNamesList = [...suggestedNamesList, ...newItems];
        }

        return suggestedNamesList;
    },

    requestSuggestedNamesForTerm({ term, index, query, queryTerms }) {
        return MainSearchApi.getSuggestedNames(term)
            .then((response) => {
                const suggestedNamesList = this.buildNewSuggestedNamesList({ response, index });

                reduxStore.dispatch(suggestedNamesActions.updateSuggestedNamesAndQuery(suggestedNamesList, query));
                reduxStore.dispatch(
                    suggestedNamesActions.updateLoadedSuggestionsForTerms(
                        {
                            term,
                            isLoaded: true,
                        },
                        index
                    )
                );

                return { list: suggestedNamesList, query };
            })
            .catch((error) => {
                if (error.message !== PROMISE_CANCELED) {
                    console.log('Error loading suggested names', error);
                }
                this.removeTermFromSuggestedNames({ queryTerms, index, query });
                reduxStore.dispatch(suggestedNamesActions.updateSuggestedNamesLoadedStatus(true));

                return {};
            });
    },

    filterSuggestedNamesList({ queryTerms, suggestedNames, filter }) {
        if (suggestedNames && suggestedNames.list) {
            let filteredNames = suggestedNames.list.filter(filter).map((name) => {
                let queryLength = queryTerms.length - 1;
                if (name.index > queryLength) {
                    name.index = name.index - 1;
                }

                return name;
            });

            suggestedNames = {
                ...suggestedNames,
                list: filteredNames,
            };
        }

        return suggestedNames;
    },

    filterLoadedTerms({ queryTerms, loadedTerms, filter }) {
        const updatedTerms = {};

        if (loadedTerms) {
            loadedTerms = Object.keys(loadedTerms)
                .filter(filter)
                .forEach((key) => {
                    key = +key;
                    let newKey = key;
                    let queryLength = queryTerms.length - 1;

                    if (key > queryLength) {
                        newKey = key - 1;
                    }

                    updatedTerms[newKey] = {
                        term: loadedTerms[key].term,
                        isLoaded: true,
                    };
                });
        }

        return updatedTerms;
    },

    getSuggestedNamesBasedOnRemovedTerms({ queryTerms, suggestedNames, loadedTerms }) {
        const trimmedQuery = queryTerms.map((term) => term.trim());
        const missingIndexes = Object.keys(loadedTerms)
            .map((key) => {
                if (trimmedQuery.indexOf(loadedTerms[key].term) === -1) {
                    return +key;
                }
            })
            .filter((item) => item !== undefined);

        const listFilter = (name) => missingIndexes.indexOf(name.index) === -1;
        suggestedNames = this.filterSuggestedNamesList({ queryTerms, suggestedNames, filter: listFilter });

        const loadedTermsFilter = (key) => missingIndexes.indexOf(+key) === -1;
        loadedTerms = this.filterLoadedTerms({ queryTerms, loadedTerms, filter: loadedTermsFilter });

        return { suggestedNames, loadedTerms };
    },

    removeTermFromSuggestedNames({ queryTerms, index, query }) {
        let suggestedNames = reduxStore.getState().suggestedNames;
        let loadedTerms = reduxStore.getState().suggestedNames.loadedSuggestionsForTerms;

        const listFilter = (name) => name.index !== index;
        suggestedNames = this.filterSuggestedNamesList({ queryTerms, suggestedNames, filter: listFilter });
        const loadedTermsFilter = (key) => +key !== index;
        loadedTerms = this.filterLoadedTerms({ queryTerms, loadedTerms, filter: loadedTermsFilter });

        reduxStore.dispatch(suggestedNamesActions.updateSuggestedNamesAndQuery(suggestedNames.list, query));
        reduxStore.dispatch(suggestedNamesActions.updateLoadedTerms(loadedTerms));
    },

    checkIfTermMatchesRegex(searchTerm) {
        if (!searchTerm) {
            return false;
        }

        const terms = searchTerm.trim().split(' ');

        return terms && terms.length >= 2
            ? terms.every((term) => {
                  const regex = new RegExp(/([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u017E\u0400-\u04FF*']+)/, 'ig');
                  const matchedTermInRegex = term.match(regex);

                  return matchedTermInRegex && matchedTermInRegex[0] === term && term !== "'" && term !== '*';
              })
            : false;
    },

    getSuggestedNames(query, suggestedNames, loadedTerms) {
        if (query) {
            const queue = DilQueue();
            queue.abortCallByUrl(URL_SUGGESTED_NAMES);
            reduxStore.dispatch(suggestedNamesActions.updateSuggestedNamesLoadedStatus(false));
            reduxStore.dispatch(suggestedNamesActions.setSuggestedNamesExceeded(false));

            const queryTerms = query.split(OPERATOR_OR);
            const hasRemovedTerms = queryTerms.length < Object.keys(loadedTerms).length;

            if (hasRemovedTerms) {
                const newData = this.getSuggestedNamesBasedOnRemovedTerms({ queryTerms, suggestedNames, loadedTerms });
                suggestedNames = newData.suggestedNames;
                loadedTerms = newData.loadedTerms;
            }

            queryTerms.map((term, index) => {
                if (index < MAX_TERMS_WITH_SUGGESTIONS) {
                    // Strip out w/, pre/ connectors, extra spaces, double quotes, sequences of single quotes
                    term = term
                        .replace(/(w|pre)\/[^\s]*/g, '')
                        .replace(/\s\s+/g, ' ')
                        .replace(/"/g, '')
                        .replace(/''+/g, "'");
                    reduxStore.dispatch(suggestedNamesActions.setSuggestedNamesExceeded(false));
                    reduxStore.dispatch(suggestedNamesActions.updateSuggestedNamesQuery(query));

                    const isNewTerm = !loadedTerms[index];
                    const hasTermChanged = loadedTerms[index] && loadedTerms[index].term !== term;
                    const doesTermMatchRegex = this.checkIfTermMatchesRegex(term);

                    if (isNewTerm || hasTermChanged) {
                        if (doesTermMatchRegex) {
                            reduxStore.dispatch(suggestedNamesActions.updateLoadedStateForTerm(index, false));
                            this.requestSuggestedNamesForTerm({ term, index, query, queryTerms });
                        } else {
                            this.removeTermFromSuggestedNames({ queryTerms, index, query });
                            reduxStore.dispatch(suggestedNamesActions.updateSuggestedNamesLoadedStatus(true));
                        }
                    }
                    if (hasRemovedTerms) {
                        reduxStore.dispatch(
                            suggestedNamesActions.updateSuggestedNamesAndQuery(suggestedNames.list, query)
                        );
                        reduxStore.dispatch(suggestedNamesActions.updateLoadedTerms(loadedTerms));
                        reduxStore.dispatch(suggestedNamesActions.updateSuggestedNamesLoadedStatus(true));
                    }
                } else {
                    reduxStore.dispatch(suggestedNamesActions.setSuggestedNamesExceeded(true));
                    reduxStore.dispatch(suggestedNamesActions.updateSuggestedNamesQuery(query));
                    reduxStore.dispatch(suggestedNamesActions.updateSuggestedNamesLoadedStatus(true));
                }
            });
        }
    },

    getSelectedSuggestedNames(suggestedNames) {
        let selectedSuggestedNames = [];

        if (suggestedNames && suggestedNames.list && suggestedNames.list.length > 0) {
            suggestedNames.list.forEach((suggestion) => {
                if (suggestion.selected) {
                    selectedSuggestedNames.push(suggestion.name);
                }
            });
        }

        return selectedSuggestedNames;
    },
};

export default SuggestedNamesUtils;
