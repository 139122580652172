import React from 'react';
import withStateProvider from '@reusable/Table/providers/hoc/withStateProvider';
import TableHandlersProvider from '@reusable/Table/providers/TableHandlersProvider';
import TableStateProvider from '@reusable/Table/providers/TableStateProvider';
import { SORT_DIRECTION } from '@constants';
import UboTableConsumer from './UboTableConsumer';
import { UBO_FILTER_DROPDOWN_OPTIONS } from '@constants';
import withUboEndpoints from './withUboEndpoints';

/**
 *  This is the main UBO table component, basically composing it with the following:
 *  - Table Handler Consumer with all the specifics for UBO (handlers, row configuration, header configuration)
 *  - All these attached to a generic Table State Provider, initiated with a default sorting and filtering
 */

const UboTableHandlersProvider = withUboEndpoints(TableHandlersProvider);

const UboTable = (props) => {
    const handleSelectFilter = (filter) => {
        props.updateSortingAndFiltering({
            filtering: { filteringDropdownSelected: parseInt(filter) },
        });
    };

    return (
        <UboTableHandlersProvider
            {...props.stateProviderProps}
            state={{
                ...props.stateProviderProps.state,
                filtering: {
                    ...props.stateProviderProps.state.filtering,
                    filteringDropdownSelected: props.filtering.filteringDropdownSelected,
                },
                uboData: { ...props.data },
                updateSortingAndFiltering: props.updateSortingAndFiltering,
            }}
            provideHandlers={(handlerProviderProps) => {
                return (
                    <UboTableConsumer
                        {...handlerProviderProps}
                        filtering={{
                            ...handlerProviderProps.filtering,
                            onSelectOption: handleSelectFilter,
                        }}
                        uboData={props.data}
                        documentPreview={props.documentPreview}
                    />
                );
            }}
        />
    );
};

const initAlertState = {
    sorting: {
        sortBy: 'beneficialOwnershipPercentage',
        direction: SORT_DIRECTION.DESC,
    },
    filtering: {
        filteringDropdownSelected: 0,
        filteringDropdownOptions: UBO_FILTER_DROPDOWN_OPTIONS,
    },
    pagination: {
        pageNumber: 0,
        pageSize: 50,
    },
};

export default withStateProvider(TableStateProvider, initAlertState)(UboTable);
