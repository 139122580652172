import React from 'react';
import { FormattedMessage } from 'react-intl';

const ModalDismissibleMessage = (props) => {
    const closeMessage = () => {
        const slide = document.getElementById('dismissible-message');
        slide.classList.remove('slide-down');
    };

    if (!props.isVisible) {
        return <span></span>;
    }

    return (
        <div className="dismissible-message slide-down" id="dismissible-message">
            <div className="dismissible-message__text">
                <FormattedMessage id={props.messageId} />
            </div>
            <button type="button" className="dismissible-message__button" onClick={closeMessage}>
                <FormattedMessage id="General_CoreFunctionality_UIText_general.dismiss" />
            </button>
        </div>
    );
};

export default ModalDismissibleMessage;
