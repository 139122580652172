import React from 'react';
import { FormattedMessage } from 'react-intl';

function ModalEmail(props) {
    if (!props.isVisible) {
        return <span />;
    }

    return (
        <div className="popup-modal__email-info">
            <div className="popup-modal__email-info-to">
                <div className="popup-field-name">
                    <FormattedMessage id="PopupModal.label.recipientsEmailAddress" />
                </div>
                {!props.isEmailValid && (
                    <div className="validationAlertMessage">
                        <FormattedMessage id={'EmailModalEmailValidation.' + props.emailErrorMessage} />
                    </div>
                )}
                <input
                    className={`text-field-standard-lg ${!props.isEmailValid ? 'text-field-error' : ''}`}
                    id="qa-report-email-text-field"
                    type="email"
                    value={props.emailAddress}
                    onChange={props.emailAddressChange}
                />
                <div className="popup-field-subname">
                    <FormattedMessage id="PopupModal.label.emailsCount" />
                </div>
                <div className="popup-field-subname">
                    <FormattedMessage id="PopupModal.label.emailPrivacyMessage" />
                    &nbsp;
                    <a
                        href="https://www.nexis.com/auth/selectLbu.do?mode=Policy"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="footer__link"
                    >
                        <FormattedMessage id="HeaderFooterNav_Legal_UIText_footer.privacy" />
                    </a>
                    .
                </div>
            </div>
            <div className="popup-modal__email-info-subject">
                <div className="popup-field-name">
                    <FormattedMessage id="PopupModal.label.subject" />
                </div>
                <input
                    className="text-field-standard-lg"
                    id="qa-report-email-subject-text-field"
                    value={props.emailSubject}
                    onChange={props.emailSubjectChange}
                />
            </div>
            <div className="popup-modal__email-info-comments">
                <div className="popup-field-name">
                    <FormattedMessage id="PopupModal.label.comments" />
                </div>
                <textarea
                    className="text-area-standard"
                    id="qa-file-name-report-text-field"
                    value={props.emailComments}
                    onChange={props.emailCommentsChange}
                />
            </div>
        </div>
    );
}

export default ModalEmail;
