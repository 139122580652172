import xhr from '@utils/xhr';
import axios from '@utils/axios';
import {
    BATCH_SCREENING_PAGE_SIZE,
    CATEGORY_NAMES,
    MULTIPLE_ENTITIES_NONE_SELECTED_VIEW,
    SCREENING_ENTITY_API_V1,
    SCREENING_ENTITY_APIS_V1,
    SCREENING_ENTITY_APIS_V2,
} from '@constants';
import { createQueryString } from '@pages/EntityView/components/EntityViewGridTable/helpers/batchHelpers';

const CancelToken = axios.CancelToken;
let cancelFilterEntities;
//the cancelToken is exported for testing purpose
export let cancelToken;

const EntityViewApi = {
    uploadScreeningEntities: (data, queryParams) => {
        return xhr
            .post({
                url: `${SCREENING_ENTITY_APIS_V2.UPLOAD_ENTITIES}${createQueryString(queryParams)}`,
                data: data,
            })
            .then((response) => [response.body, null])
            .catch((error) => [null, error]);
    },

    entityViewUploadPolling: (batchId, v2) => {
        const oldUrl = `/api/screening-entity-upload/status?batchId=${batchId}`;
        const newUrl = `/api/screening-entity/action-status/${batchId}`;

        const url = v2 ? newUrl : oldUrl;

        return xhr
            .get({ url: url })
            .then((response) => [response.body, null])
            .catch((error) => [null, error]);
    },

    uploadFile: (url, formData, fileType) =>
        axios
            .post(url, formData, {
                headers: {
                    'Content-Type': fileType,
                },
            })
            .then((response) => [response, null])
            .catch((error) => [null, error]),

    getBatchEntities: (sortInfo = null, shouldDisplayRiskScore = null, pageNumber = 0) => {
        let url = `api/v2/screening-entity/?pageNumber=${pageNumber}&pageSize=${BATCH_SCREENING_PAGE_SIZE}`;
        if (sortInfo && sortInfo.direction && sortInfo.sortBy) {
            url = `api/v2/screening-entity/?pageNumber=${pageNumber}&pageSize=${BATCH_SCREENING_PAGE_SIZE}&sortBy=${sortInfo.sortBy}&direction=${sortInfo.direction}`;
        }
        if (sortInfo && sortInfo.sortBy === 'negativeNews') {
            url = `${url}&displayRiskScores=${shouldDisplayRiskScore}`;
        }
        cancelFilterEntities && cancelFilterEntities('Canceled');
        cancelToken = new CancelToken(function executor(c) {
            cancelFilterEntities = c;
        });
        return axios
            .get(url, {
                cancelToken,
            })
            .then((response) => [response.data, null])
            .catch(() => [null, true]);
    },

    getInProgressEntitiesIds: (data) => 
        xhr
            .post({
                url: `api/screening-entity/by-id`,
                data,
            })
            .then((response) => [response.body, null])
            .catch((error) => [null, error])
    ,

    deleteBatchEntities: (data = {}, urlParams = {}) => {
        return xhr
            .delete({
                url: `${SCREENING_ENTITY_APIS_V2.DELETE_ENTITIES}/${createQueryString(urlParams)}`,
                data,
            })
            .then((response) => [response.text, null])
            .catch((error) => [null, error]);
    },

    deletePolling: (batchId) =>
        xhr
            .get({
                url: `/api/screening-entity/action-status/${batchId}`,
            })
            .then((response) => [response.body, null])
            .catch((error) => [null, error]),

    copyBatchEntities: (data = {}, urlParams = {}) =>
        xhr
            .post({
                url: 'api/v2/screening-entity/copy' + createQueryString(urlParams),
                data,
            })
            .then((response) => [response.text, null])
            .catch((error) => [null, error]),

    refreshBatchEntities: (data = {}, urlParams = {}) =>
        xhr
            .post({
                url: 'api/v2/screening-entity/refresh' + createQueryString(urlParams),
                data,
            })
            .then((response) => [response.text, null])
            .catch((error) => [null, error]),

    refreshPolling: (batchId) =>
        xhr
            .get({
                url: `/api/screening-entity/action-status/${batchId}`,
            })
            .then((response) => [response.body, null])
            .catch((error) => [null, error]),

    updateBatchEntities: (entityId, data) =>
        xhr
            .put({
                url: `api/screening-entity/${entityId}`,
                data,
            })
            .then((response) => [response.text, null])
            .catch((error) => [null, error]),

    createMultipleAlerts: (data, urlParams = {}) =>
        xhr
            .post({
                url: 'api/v2/screening-entity/alerts' + createQueryString(urlParams),
                data,
            })
            .then((response) => [response, null])
            .catch((error) => [null, error]),

    createFromSearch: (payload) =>
        xhr
            .post({ url: 'api/v2/screening-entity/from-search', data: payload })
            .then((response) => [response.body, null])
            .catch((error) => [null, error]),

    createFromAlerts: (payload) =>
        xhr
            .post({ url: 'api/v2/screening-entity/from-alerts', data: { requests: payload } })
            .then((response) => [response.body, null])
            .catch((error) => [null, error]),

    downloadEntities: (data, urlParams = {}) => {
        return xhr
            .post({
                url: 'api/v2/screening-entity/download' + createQueryString(urlParams),
                data,
            })
            .then((response) => [response.text, null])
            .catch((error) => [null, error]);
    },

    entityViewDownloadReportsPolling: (batchId) => {
        const url = `api/report/action-status/${batchId}`;

        return xhr
            .get({ url: url })
            .then((response) => [response.body, null])
            .catch((error) => [null, error]);
    },

    getNumberOfBatchEntities: ({ searchTerm, viewId }) => {
        let url = 'api/screening-entity/all';

        if (searchTerm && viewId) url += `?displayName=${searchTerm}&viewId=${viewId}`;
        if (searchTerm && !viewId) url += `?displayName=${searchTerm}`;
        if (!searchTerm && viewId) url += `?viewId=${viewId}`;

        return xhr
            .get({
                url,
            })
            .then((response) => [response.body.count, null])
            .catch((error) => [null, error]);
    },

    //endpoints for comment section
    addComment: (entityId, text, createdBy, createdOn) =>
        xhr
            .post({
                url: `/api/screening-entity-notes/${entityId}`,
                data: {
                    text,
                    createdBy,
                    createdOn,
                },
            })
            .then((response) => [response.body, null])
            .catch((error) => [null, error]),

    editComment: (entityId, commentId, text) =>
        xhr
            .put({
                url: `/api/screening-entity-notes/${entityId}`,
                data: {
                    id: commentId,
                    text,
                },
            })
            .then((response) => [response.body, null])
            .catch((error) => [null, error]),

    deleteComment: (entityId, commentId) =>
        xhr
            .delete({
                url: `/api/screening-entity-notes/${entityId}/${commentId}`,
            })
            .then(() => [true, null])
            .catch((error) => [null, error]),

    getCommentsForEntity: (entityId) =>
        xhr
            .get({
                url: `/api/screening-entity-notes/${entityId}`,
            })
            .then((response) => [response.body, null])
            .catch((error) => [null, error]),

    updateEntityDisplayName: (entityId, data) =>
        xhr
            .patch({
                url: `${SCREENING_ENTITY_API_V1}/${entityId}`,
                data,
            })
            .then((response) => [response.body, null])
            .catch((error) => [null, error]),

    updateEntitySearchData: (entityId, data) =>
        xhr
            .patch({
                url: `${SCREENING_ENTITY_API_V1}/${entityId}${SCREENING_ENTITY_APIS_V1.UPDATE_ENTITY_SEARCH_DATA}`,
                data,
            })
            .then((response) => [response.text, null])
            .catch((error) => [null, error]),

    downloadEntityViewTable: (filename, payload, isTableFormat) => {
        const { viewId, searchTerm, ...data } = payload;
        const view = viewId !== MULTIPLE_ENTITIES_NONE_SELECTED_VIEW ? viewId : '';
        const urlType = isTableFormat ? '/api/screening-entity/export' : '/api/v2/screening-entity/export-in-upload-format';
        return isTableFormat ? xhr
            .post({
                url:
                    urlType +
                    createQueryString({
                        viewId: view,
                        searchTerm,
                    }),
                data: {
                    filename,
                    ...data,
                },
                headers: {
                    'Accept-Language': payload.language,
                },
            })
            .then((response) => [response.body.id, null])
            .catch((error) => [null, error]) :
            xhr
                .get({
                    url:
                        urlType +
                        createQueryString({
                            viewId: view,
                            searchTerm,
                            filename
                        }),
                    headers: {
                        'Accept-Language': payload.language,
                    },
                })
                .then((response) => [response.body.id, null])
                .catch((error) => [null, error])
    },

    filterEntities: ({ searchQuery, pageSize, pageNumber, viewId, sortBy, sortDirection, displayRiskScores }) => {
        cancelFilterEntities && cancelFilterEntities('Canceled');
        cancelToken = new CancelToken(function executor(c) {
            cancelFilterEntities = c;
        });

        let url = `api/screening-entity/filter?displayName=${searchQuery}&pageSize=${pageSize}&pageNumber=${pageNumber}`;

        if (viewId && viewId !== MULTIPLE_ENTITIES_NONE_SELECTED_VIEW) {
            url += `&viewId=${viewId}`;
        }

        if (sortBy?.length) {
            url += `&sortBy=${sortBy}`;

            if (sortBy === CATEGORY_NAMES.NEGATIVE_NEWS && displayRiskScores) {
                url += `&displayRiskScores=${displayRiskScores}`;
            }

            if (sortDirection) {
                url += `&sortDirection=${sortDirection}`;
            }
        }

        return axios
            .get(url, {
                cancelToken,
            })
            .then((response) => {
                return [response.data, null];
            })
            .catch((error) => {
                return [null, error];
            });
    },

    getAllViews: (userPermId) =>
        xhr
            .get({
                url: `/api/screening-entity/view/?userPermId=${userPermId}&sortBy=name&sortDirection=asc`,
            })
            .then((response) => [response.body, null])
            .catch((error) => [null, error]),

    createView: (data, urlParams = {}) =>
        xhr
            .post({
                url: '/api/v3/screening-entity/view/' + createQueryString(urlParams),
                data,
            })
            .then((response) => [response.body, null])
            .catch((error) => [null, error]),

    createViewPolling: (batchId) =>
        xhr
            .get({
                url: `/api/screening-entity/action-status/${batchId}`,
            })
            .then((response) => [response.body, null])
            .catch((error) => [null, error]),

    getViewEntities: (data) =>
        xhr
            .post({
                url: '/api/v2/screening-entity/view/entities',
                data,
            })
            .then((response) => [response.body, null])
            .catch((error) => [null, error]),

    deleteView: (viewId) =>
        xhr
            .delete({
                url: `/api/screening-entity/view/${viewId}`,
            })
            .then(() => [true, null])
            .catch((error) => [null, error]),

    updateView: (viewId, data) =>
        xhr
            .patch({
                url: `/api/screening-entity/view/${viewId}`,
                data,
            })
            .then((response) => [response.body, null])
            .catch((error) => [null, error]),

    updateEntityCategoryCount: (entityId, category, count, viewId) =>
        xhr
            .patch({
                url: `/api/screening-entity/${entityId}/category-count`,
                data: {
                    category,
                    count,
                    viewId,
                },
            })
            .then((response) => [response.body, null])
            .catch((error) => [null, error]),

    updateEntityPostfilters: (entityId, postfilters, viewId) =>
        xhr
            .patch({
                url: `/api/v2/screening-entity/${entityId}/postFilters`,
                data: {
                    viewId,
                    postfilters,
                },
            })
            .then((response) => [response.body, null])
            .catch((error) => [null, error]),

    getShareViewUsers: (viewId) => {
        const getShareViewUsersUrl = '/api/screening-entity/view/users';

        return xhr
            .get({
                url: `${getShareViewUsersUrl}/${viewId}`,
            })
            .then((response) => [response.body, null])
            .catch((error) => [null, error]);
    },

    patchShareViewUsers: (addedUsers, removedUsers, viewId) => {
        const getShareViewUsersUrl = '/api/screening-entity/view/users';

        return xhr
            .patch({
                url: `${getShareViewUsersUrl}/${viewId}`,
                data: {
                    addedUsers,
                    removedUsers,
                },
            })
            .then((response) => [response, null])
            .catch((error) => [null, error]);
    },

    getUserNotifications: () => {
        return xhr
            .get({
                url: 'api/screening-entity-view-notification/',
            })
            .then((response) => [response.body, null])
            .catch((error) => [null, error]);
    },

    patchUserNotifications: (notificationIds) => {
        return xhr
            .patch({
                url: 'api/screening-entity-view-notification/update-displayed-notifications/',
                data: notificationIds,
            })
            .then((response) => [response, null])
            .catch((error) => [null, error]);
    },


    getActionsInProgress: () => 
        xhr
            .get({
                url: `api/v2/screening-entity/actions-in-progress`
            })
            .then((response) => [response.body, null])
            .catch((error) => [null, error]),
};

export default EntityViewApi;
