import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import withAutosave from '../HOC/WithAutosave';
import {
    CUSTOM_NEWS_QUERY_ESG_TITLE,
    CUSTOM_NEWS_QUERY_TYPES_ID,
    FE_LOCK_NAMES,
    MAX_NUMBER_OF_CUSTOM_NEWS_QUERIES,
    MESSAGE_TIMEOUT,
    MODAL_TYPE,
} from '@constants';
import PopupModel from '@reusable/PopupModal/PopupBuilder';
import { isEqual, cloneDeep } from 'lodash';
import AdminLock from '@reusable/AdminLock/AdminLock';
import Table from '@reusable/Table/variants/Table';
import formatRichMessage from '@utils/formatRichMessage';
import TriggerComponent from '@reusable/Popover/TriggerComponent';
import { RowActionBtn } from '@reusable/Table/components/defaults';
import { CustomNewsMenuDropdown } from '@pages/ManageAlerts/components/misc';

class CustomNewsSearches extends Component {
    constructor(props) {
        super(props);

        this.tableHeaders = [
            {
                columnKey: 'name',
                label: <FormattedMessage id="UserPreferences_topic_customNewsSearches_table_header_name" />,
            },
            {
                columnKey: 'created',
                label: <FormattedMessage id="UserPreferences_topic_customNewsSearches_table_header_createdBy" />,
            },
            {
                columnKey: 'action',
                label: <FormattedMessage id="UserPreferences_topic_customNewsSearches_table_header_action" />,
            },
        ];
        this.customNewsQueriesActions = {
            edit: {
                label: 'edit',
                type: MODAL_TYPE.USER_PREFERENCES_EDIT_CUSTOM_NEWS_QUERY,
                descriptionId: 'PopupBuilder.explanation.customNewsQuery.edit',
            },
            view: {
                label: 'view',
                type: MODAL_TYPE.USER_PREFERENCES_VIEW_CUSTOM_NEWS_QUERY,
            },
            delete: {
                label: 'delete',
                type: MODAL_TYPE.USER_PREFERENCES_DELETE_CUSTOM_NEWS_QUERY,
            },
        };
    }

    componentDidUpdate = (prevProps) => {
        if (
            !isEqual(this.props.customNewsQuery, prevProps.customNewsQuery) ||
            !isEqual(this.props.lnCustomNewsQueries, prevProps.lnCustomNewsQueries)
        ) {
            if (!this.props.userIsAdmin) {
                this.props.changeMade(this.props.section);
            }
            this.props.checkCustomNewsSourceAvailability();

            let previousAvailableQueries = prevProps.customNewsQuery.filter(
                (customQuery) => customQuery.isDeleted === false
            );
            let currentlyAvailableQueries = this.props.customNewsQuery.filter(
                (customQuery) => customQuery.isDeleted === false
            );

            if (currentlyAvailableQueries.length > previousAvailableQueries.length) {
                this.props.setSuccessBackgroundMessages({
                    message: 'UserPreferences_CostCode.notification.customNewsQueryAdded',
                    isVisible: true,
                });
            }
            setTimeout(() => {
                this.props.clearBackgroundMessages();
            }, MESSAGE_TIMEOUT);
        }
    };

    handleCustomNewsQueryToggle = (segment, queryName, createdBy, checked) => {
        const toggleAction =
            createdBy === CUSTOM_NEWS_QUERY_TYPES_ID.ADMIN && !this.props.userIsAdmin
                ? this.props.checkAdminCustomNewsQuery
                : createdBy === CUSTOM_NEWS_QUERY_TYPES_ID.LN
                ? this.props.checkLnCustomNewsQuery
                : this.props.checkCustomNewsQuery;
        this.props.handleAutosave(toggleAction, [segment, queryName, checked]);
    };

    showPopup = (data, type, descriptionId) => {
        if (data) {
            const queryTitle = data.title
                ? data.title
                : formatRichMessage({ id: 'PopupBuilder.title.defaultCustomNewsSearchTitle' }, this.props.intl);
            const buttonIsDisabled = data.query ? data.query.length > 0 : true;
            const lockName = data.lock ? data.lock.lockName : null;
            const popupModel = new PopupModel.Builder(type)
                .setPopupProperty('isVisible', true)
                .setPopupProperty('segment', this.props.segment)
                .setPopupProperty('queryTitle', queryTitle)
                .setPopupProperty('queryName', data.name)
                .setPopupProperty('customQuery', data.query)
                .setPopupProperty('editableTitle', queryTitle)
                .setPopupProperty('buttonIsDisabled', buttonIsDisabled)
                .setPopupProperty('userIsAdmin', this.props.userIsAdmin)
                .setPopupProperty('lockName', lockName)
                .setPopupProperty('customNewsCreatedBy', data.createdBy)
                .setPopupProperty('customNewsLnDefaultQuery', data.defaultQuery);

            switch (type) {
                case MODAL_TYPE.USER_PREFERENCES_ADD_CUSTOM_NEWS_QUERY:
                case MODAL_TYPE.USER_PREFERENCES_EDIT_CUSTOM_NEWS_QUERY:
                    const description = formatRichMessage({ id: descriptionId }, this.props.intl, {
                        a: (chunks) =>
                            `<a href="${this.props.customNewsSearchHelpUrl}" target='_blank'>${chunks.join('')}</a>`,
                    });
                    popupModel.setPopupProperty('description', description);
                    break;
                case MODAL_TYPE.USER_PREFERENCES_VIEW_CUSTOM_NEWS_QUERY:
                    const viewDescription = formatRichMessage({ id: descriptionId }, this.props.intl);
                    popupModel.setPopupProperty('description', viewDescription);
                    popupModel.setPopupProperty('isReadOnly', true);
                    break;
                default:
                    break;
            }

            popupModel.build();
            this.props.setPopupModel(popupModel);
        }
    };

    transformCustomNewsQueriesData = (userQueries = [], lnQueries = [], adminQueries = []) => {
        if (!this.props.userIsAdmin) {
            const filteredUserQueries = cloneDeep(userQueries).map((query) => {
                const queryId = `${query.name}${this.props.segment}`;

                query.id = queryId;
                query.createdBy = CUSTOM_NEWS_QUERY_TYPES_ID.USER;
                query.actions = [this.customNewsQueriesActions.edit, this.customNewsQueriesActions.delete];
                query.isDisabled = this.props.isReadonly;
                query.lock = {};
                query.lock.isLocked = false;

                return query;
            });
            const filteredLNQueries = cloneDeep(lnQueries).map((query) => {
                const queryId = `${query.name}${this.props.segment}`;

                query.id = queryId;
                query.title = query.title ? query.title : CUSTOM_NEWS_QUERY_ESG_TITLE;
                query.query = query.query ? query.query : query.defaultQuery;
                query.createdBy = CUSTOM_NEWS_QUERY_TYPES_ID.LN;
                query.actions = [
                    {
                        ...this.customNewsQueriesActions.view,
                        descriptionId: 'PopupBuilder.explanation.customNewsQuery.viewLn',
                    },
                    {
                        ...this.customNewsQueriesActions.edit,
                        descriptionId: 'PopupBuilder.explanation.customNewsQuery.editLn',
                    },
                ];
                query.isDisabled = this.props.isReadonly;
                query.lock = {};
                query.lock.isLocked = false;

                return query;
            });
            const filteredAdminQueries = cloneDeep(adminQueries).map((query) => {
                const queryId = `${query.name}${this.props.segment}`;
                const isLocked = this.props.userLocks[queryId];

                query.id = queryId;
                query.createdBy = CUSTOM_NEWS_QUERY_TYPES_ID.ADMIN;
                query.actions = [
                    {
                        ...this.customNewsQueriesActions.view,
                        descriptionId: 'PopupBuilder.explanation.customNewsQuery.view',
                    },
                ];
                query.isDisabled = this.props.isReadonly || isLocked;
                query.lock = {};
                query.lock.isLocked = isLocked;
                query.lock.handleLockClick = this.props.handleLockClick;
                query.lock.lockName = FE_LOCK_NAMES[queryId];

                return query;
            });

            return [...filteredUserQueries, ...filteredLNQueries, ...filteredAdminQueries];
        }

        return cloneDeep(userQueries).map((query) => {
            const queryId = `${query.name}${this.props.segment}`;
            const isLocked = this.props.userLocks[queryId];

            query.id = queryId;
            query.createdBy = CUSTOM_NEWS_QUERY_TYPES_ID.ADMIN;
            query.actions = [this.customNewsQueriesActions.edit, this.customNewsQueriesActions.delete];
            query.isDisabled = false;
            query.lock = {};
            query.lock.isLocked = isLocked;
            query.lock.handleLockClick = this.props.handleLockClick;
            query.lock.lockName = FE_LOCK_NAMES[queryId];

            return query;
        });
    };

    transformRow = (data) => ({
        key: data.name,
        columns: {
            name: (
                <NameSelectionCell
                    queryInfo={data}
                    onChangeHandler={this.handleCustomNewsQueryToggle}
                    segment={this.props.segment}
                />
            ),
            created: (
                <div className={data.isDisabled ? 'disabled' : ''}>
                    <FormattedMessage id={data.createdBy} />
                </div>
            ),
            action: (
                <CustomNewsSearchMenu queryInfo={data} onClick={this.showPopup} userIsAdmin={this.props.userIsAdmin} />
            ),
        },
    });

    render() {
        const availableCustomNewsQueries =
            this.props.customNewsQuery && this.props.customNewsQuery.filter((query) => !query.isDeleted);
        const availableLnCustomNewsQueries =
            this.props.lnCustomNewsQueries && this.props.lnCustomNewsQueries.filter((query) => !query.isDeleted);
        const availableAdminCustomNewsQueries =
            this.props.adminCustomNewsQuery && this.props.adminCustomNewsQuery.filter((query) => !query.isDeleted);
        const customQueries = this.transformCustomNewsQueriesData(
            availableCustomNewsQueries,
            availableLnCustomNewsQueries,
            availableAdminCustomNewsQueries
        );
        const shouldDisplayTable = customQueries && customQueries.length > 0;

        return (
            <div className="user-preferences-category-container container-section__segment custom-news-searches">
                {this.props.userIsAdmin ? (
                    <AdminLock
                        lockName={this.props.lockName}
                        onClickLock={this.props.handleLockClick}
                        isSectionLock={true}
                        isLocked={this.props.isReadonly}
                    />
                ) : (
                    this.props.isReadonly && <AdminLock lockName={this.props.lockName} isDisabled={true} />
                )}
                <div className="user-preferences-category-container__row  container-section__segment__header">
                    <h3
                        className="user-preferences-category-container__topic"
                        data-track={`custom-news-searches-title_${this.props.segment}`}
                    >
                        <FormattedMessage id="UserPreferences_topic_customNewsSearches" />
                    </h3>
                    {this.props.autoSaveMessage}
                </div>
                <div className="user-preferences-category-container__explanation">
                    {this.props.userIsAdmin ? (
                        <FormattedMessage id={'UserPreferences_topic_customNewsSearchesExplanation'} />
                    ) : (
                        <FormattedMessage id={'UserPreferences_topic_customNewsSearchesExplanationUser'} />
                    )}
                </div>
                <div className="custom-news-searches__query-container">
                    <div>
                        <button
                            className="custom-news-searches__query-container__add-query button-secondary-lg notranslate"
                            onClick={() =>
                                this.showPopup(
                                    {},
                                    MODAL_TYPE.USER_PREFERENCES_ADD_CUSTOM_NEWS_QUERY,
                                    'PopupBuilder.explanation.customNewsQuery.add'
                                )
                            }
                            disabled={
                                (!this.props.userIsAdmin && this.props.isReadonly) ||
                                (availableCustomNewsQueries &&
                                    availableCustomNewsQueries.length >= MAX_NUMBER_OF_CUSTOM_NEWS_QUERIES) ||
                                !this.props.customNewsQuery ||
                                this.props.customNewsQuery.length === 0
                            }
                        >
                            <span className="la-ZoomIn" />
                            <span className="label">
                                <FormattedMessage id={'UserPreferences.CustomNewsSearches.addButton'} />
                            </span>
                        </button>
                    </div>
                    {shouldDisplayTable && (
                        <div
                            className="generic-table custom-news-queries"
                            data-track={`custom-news-queries-library_${this.props.segment}`}
                        >
                            <Table
                                headers={this.tableHeaders}
                                data={{
                                    rows: customQueries.map(this.transformRow),
                                }}
                            />
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

const NameSelectionCell = ({ queryInfo, onChangeHandler, segment }) => {
    const { id, isDisabled, checked, name, createdBy, title } = queryInfo;
    const checkboxId = `user-preferences-check_${id}`;

    return (
        <div className="user-preference-checkbox">
            <input
                id={checkboxId}
                type="checkbox"
                disabled={isDisabled}
                checked={checked}
                onChange={() => onChangeHandler(segment, name, createdBy, !checked)}
            />
            <label htmlFor={checkboxId} className="preference-checkbox-label">
                <span>{title}</span>
            </label>
        </div>
    );
};

const CustomNewsSearchMenu = ({ queryInfo, onClick, userIsAdmin }) => {
    const {
        isDisabled,
        lock: { isLocked, lockName, handleLockClick },
    } = queryInfo;

    return (
        <div className="custom-news-search-action-wrapper" data-testid="custom-news-search-menu">
            <TriggerComponent
                render={(params) => <CustomNewsMenuDropdown params={params} onClick={onClick} queryInfo={queryInfo} />}
                disabled={isDisabled}
            >
                <RowActionBtn icon="la-DeliveryOptions" className="custom-news-search-menu" disabled={isDisabled} />
            </TriggerComponent>
            {(isLocked || userIsAdmin) && (
                <AdminLock
                    lockName={lockName}
                    onClickLock={handleLockClick}
                    isLocked={isLocked}
                    isDisabled={!userIsAdmin}
                />
            )}
        </div>
    );
};

export default withAutosave()(injectIntl(CustomNewsSearches));
export { CustomNewsSearches as TestCustomNewsSearches };
