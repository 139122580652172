function initSimulation(d3, svg, nodes, links, config, handlers = {}) {
    const chargeStrengthScale = d3
        .scaleLinear()
        .domain([0, 15, 40, 60, 100])
        .range([
            config.chargeStrength * 4,
            config.chargeStrength * 2,
            config.chargeStrength,
            config.chargeStrength,
            config.chargeStrength,
        ]);

    const chargeStrength = chargeStrengthScale(nodes.length);
    const simulation = d3
        .forceSimulation()
        .nodes(nodes)
        .force('link', d3.forceLink(links).distance(config.forceDistance))
        .force('charge', d3.forceManyBody().strength(chargeStrength))
        .force('gravity', d3.forceManyBody().strength(95))
        .force('center', d3.forceCenter(config.width / 2, config.height / 2))
        //.force("x", d3.forceX().strength(0.5).x( function(d){ return x(d.group) } ))
        .force('collision', d3.forceCollide().radius(20));

    paintSimpleLinks(svg, links);
    paintRootNode(svg, [nodes[0]], d3);
    paintBeneficialOwners(
        svg,
        nodes.filter((node) => node.degreeOfSeparation > 0),
        handlers
    );
    generateSimpleDefs(svg);

    return simulation;
}

function simulationTick(d3, svg, nodes) {
    return function () {
        let rootNode;
        svg.select('#node_root').each((d) => {
            rootNode = d;
        });
        // place the nodes
        svg.selectAll('.node').attr('transform', (d) => `translate(${d.x}, ${d.y})`);

        // place the icon in its position, for business nodes have them aligned center on X
        svg.selectAll('g.leafs').attr('transform', (d) => `translate(${d.x}, ${d.y})`);

        // draw the lines between the nodes
        const arrowHeadLength = 8;

        svg.selectAll('.line')
            .attr('d', (d) => {
                let x1 = d.source.x,
                    y1 = d.source.y,
                    x2 = d.target.x,
                    y2 = d.target.y,
                    angle = Math.atan2(y2 - y1, x2 - x1);

                let nodeRadius = 100,
                    sourceX = x1,
                    sourceY = y1,
                    targetX = x2 - Math.cos(angle) * (nodeRadius + arrowHeadLength),
                    targetY = y2 - Math.sin(angle) * (nodeRadius + arrowHeadLength);

                return `M${sourceX},${sourceY} L${targetX},${targetY}`;
            })
            .attr('id', (d) => `link_${d.id}`)
            .attr('angle', (d) => {
                let x1 = d.source.x,
                    y1 = d.source.y,
                    x2 = d.target.x,
                    y2 = d.target.y,
                    angle = Math.atan2(y2 - y1, x2 - x1),
                    angleDegree = (angle * 180) / Math.PI;
                return angleDegree;
            })
            .attr('transform-origin', (d) => `${d.source.x} ${d.source.y}`)
            //.attr('marker-start', d => `url(#marker_node_${d.source.id})`)
            .attr('marker-end', `url(#arrow)`);

        var q = d3.quadtree(nodes),
            i = 0,
            n = nodes.length;

        while (++i < n) {
            q.visit(collide(nodes[i]));
        }

        svg.selectAll('.nodeDetails')
            .attr('transform', function (d) {
                const angle = getAngle(d, rootNode);
                const width = this.getBBox().width + 8;
                const height = 25;
                let x = 0,
                    y = 0;
                if (angle > 90) {
                    y = height * -1;
                } else if (angle > 0 && angle <= 90) {
                    y = height * -1;
                    x = width * -1;
                } else if (angle <= 0 && angle > -90) {
                    x = width * -1;
                    y = height;
                } else {
                    y = height;
                }
                return `translate(${x},${y})`;
            })
            .attr('x', function (d) {
                return d.x;
            })
            .attr('y', function (d) {
                return d.y;
            });
    };
}

const paintSimpleLinks = (svg, links) => {
    svg.selectAll('.link')
        .data(links)
        .enter()
        .append('g')
        .attr('class', (link) => 'lines ' + (link.tags ? link.tags.join(' ') : ''))
        .append('path')
        .style('stroke-dasharray', (link) => (link.source.degreeOfSeparation === 1 ? '' : '3, 3'))
        .attr('class', 'line')
        .attr('id', (d) => d.source.id);
};

function generateSimpleDefs(group) {
    // filters go in defs element
    let defs = group.append('defs');
    addArrowMarker(defs);
}

function paintRootNode(svg, businessNodes, d3) {
    const radius = 100;

    let businessNodeGroup = svg
        .selectAll('g.businessNodes')
        .data(businessNodes)
        .enter()
        .append('g')
        .attr('id', 'node_root')
        .attr('class', 'leafs root_node');

    businessNodeGroup.append('circle').attr('r', radius).style('fill', '#002363');

    businessNodeGroup
        .append('text')
        .attr('x', -90)
        .attr('y', -30)
        .attr('fill', '#FFF')
        .attr('font-size', 24)
        .attr('width', 180)
        .attr('height', 120)
        .attr('class', 'root_node_text')
        .attr('id', (d) => 'node_text_' + d.id)
        .text(function (d) {
            return d.name;
        })
        .call(wrap, 180, d3, undefined, 3, '...')
        .call(centerLines, d3);
}

/**
 * Paints the individual nodes with the appropriate styled elements
 * @param svg
 * @param individualNodes
 */
function paintBeneficialOwners(svg, individualNodes, handlers = {}) {
    let individualNodeGroup = svg
        .selectAll('g.individualNodes')
        .data(individualNodes)
        .enter()
        .append('g')
        .attr('id', (node) => `node_${node.id}`)
        .attr('class', (node) => 'leafs ' + (node.tags ? node.tags.join(' ') : ''))
        .on('click', handlers.onClick)
        .on('mouseover', handlers.onHover)
        .on('mouseout', handlers.onMouseOut);

    const nodeDetails = individualNodeGroup
        .append('g')
        .attr(
            'class',
            (node) => `nodeDetails ${node.beneficialOwnershipPercentage >= 25 || node.persisted ? 'visible' : 'hidden'}`
        );

    nodeDetails
        .append('text')
        .text((node) => (node.beneficialOwnershipPercentage > 0 ? `${node.beneficialOwnershipPercentage} %` : 'N/A'))
        .attr('font-size', 15)
        .each(function (d) {
            if (d.beneficialOwnershipPercentage > 0) {
                d.textWidth = 50;
            } else {
                d.textWidth = 30;
            }
        });

    nodeDetails
        .append('circle')
        .attr('r', 10)
        .attr('fill', '#CAC9CA')
        .attr('cx', (node) => node.textWidth + 14)
        .attr('cy', -8)
        .attr('class', (node) => (node.degreeOfSeparation === 1 ? 'hidden' : 'visible'));

    nodeDetails
        .append('text')
        .text((node) => node.degreeOfSeparation)
        .attr('font-size', 15)
        .attr('id', (node) => `node_text_${node.id}`)
        .attr('fill', '#002363')
        .attr('y', '-4')
        .attr('x', (node) => node.textWidth + 10)
        .attr('class', (node) => (node.degreeOfSeparation === 1 ? 'hidden' : 'visible'));

    nodeDetails
        .append('text')
        .text((node) => node.name)
        .attr('font-size', 13)
        .attr('y', 15)
        .attr('font-weight', 'bold');

    individualNodeGroup.append('circle').attr('r', 7).attr('class', 'bo-circle').style('fill', '#5A5A5A');
}

// function paintKey(d3) {
//     d3.select("#graph-key")
//         .append("foreignObject")
//         .attr("width", 250)
//         .attr("height", 250)
//         .append("xhtml:div")

// }

function addArrowMarker(defs) {
    defs.append('svg:marker')
        .attr('id', 'arrow')
        .attr('viewBox', '0 -5 10 10')
        .attr('fill', '#656565')
        .attr('refX', 10) //so that it comes towards the center.
        .attr('markerWidth', 7)
        .attr('markerHeight', 7)
        .attr('orient', 'auto')
        .append('svg:path')
        .attr('d', 'M0,-5L10,0L0,5');

    defs.append('svg:marker')
        .attr('id', 'arrowHover')
        .attr('viewBox', '0 -5 10 10')
        .attr('fill', '#002363')
        .attr('refX', 10) //so that it comes towards the center.
        .attr('markerWidth', 7)
        .attr('markerHeight', 7)
        .attr('orient', 'auto')
        .append('svg:path')
        .attr('d', 'M0,-5L10,0L0,5');
}

function collide(node) {
    return function (quad) {
        var updated = false;
        if (quad.point && quad.point !== node) {
            var x = node.x - quad.point.x,
                y = node.y - quad.point.y,
                xSpacing = (quad.point.width + node.width) / 2,
                ySpacing = (quad.point.height + node.height) / 2,
                absX = Math.abs(x),
                absY = Math.abs(y),
                l,
                lx,
                ly;

            if (absX < xSpacing && absY < ySpacing) {
                l = Math.sqrt(x * x + y * y);

                lx = (absX - xSpacing) / l;
                ly = (absY - ySpacing) / l;

                // the one that's barely within the bounds probably triggered the collision
                if (Math.abs(lx) > Math.abs(ly)) {
                    lx = 0;
                } else {
                    ly = 0;
                }

                node.x -= x *= lx;
                node.y -= y *= ly;
                quad.point.x += x;
                quad.point.y += y;

                updated = true;
            }
        }
        return updated;
    };
}

function wrap(textNodes, width, d3, font, maxLines = 3, ellipsis = ' ...') {
    textNodes.each(function () {
        var text = d3.select(this),
            words = text.text().split(/\s+/).reverse(),
            word,
            line = [],
            lineNumber = 0,
            lines = 1,
            lineHeight = 1.4, // ems
            x = text.attr('x'),
            y = text.attr('y'),
            dy = 0,
            tspan = text
                .text(null)
                .append('tspan')
                .attr('x', x)
                .attr('y', y)
                .attr('dy', dy + 'em');
        // eslint-disable-next-line  no-cond-assign
        while ((word = words.pop())) {
            line.push(word);
            tspan.text(line.join(' '));

            const isComputedFunctionDefined = tspan.node() && tspan.node().getComputedTextLength;

            if (isComputedFunctionDefined && tspan.node().getComputedTextLength(font) > width) {
                lines++;
                line.pop();
                tspan.text(line.join(' '));

                let lineWidth = tspan.node().getComputedTextLength(font);
                tspan.attr('x', (width - lineWidth) / 2 + parseInt(x));

                let maxLinesReached;
                if (lines === maxLines) {
                    if (words.length) {
                        word = word + ellipsis;
                    }
                    maxLinesReached = true;
                }

                line = [word];
                tspan = text
                    .append('tspan')
                    .attr('x', x)
                    .attr('y', y)
                    .attr('dy', ++lineNumber * lineHeight + dy + 'em')
                    .text(word);
                lineWidth = tspan.node().getComputedTextLength(font);
                tspan.attr('x', (width - lineWidth) / 2 + parseInt(x));

                if (maxLinesReached) {
                    break;
                }
            } else {
                let lineWidth = isComputedFunctionDefined ? tspan.node().getComputedTextLength() : null;
                tspan.attr('x', (width - lineWidth) / 2 + parseInt(x));
            }
        }
        text.attr('y', ((maxLines - lines) / 2) * 30);
    });
}

function centerLines(text, d3, maxLines = 3) {
    var lineHeight = 1.4; // ems
    text.each(function () {
        var lines = d3.select(this).selectAll('tspan');
        var lineCount = lines.size();
        if (lineCount < maxLines) {
            lines.each(function () {
                var line = d3.select(this);
                var dy = parseInt(line.attr('dy'));
                line.attr('dy', ((maxLines - lineCount) / 2) * lineHeight + dy + 'em');
            });
        }
    });
}

const getAngle = ({ x: x1, y: y1 }, { x: x2, y: y2 }) => {
    const angle = Math.atan2(y2 - y1, x2 - x1),
        angleDegree = (angle * 180) / Math.PI;

    return angleDegree;
};

try {
    module.exports = {
        wrap: wrap,
        initSimulation: initSimulation,
        simulationTick: simulationTick,
    };
} catch (e) {
    console.log(e);
}
