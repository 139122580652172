import React, { useEffect, useState } from 'react';
import withAutosave from '../HOC/WithAutosave';
import { FormattedMessage } from 'react-intl';
import { DEBOUNCE_MAX_DOCUMENTS_PER_CATEGORY_IN_REPORT_INPUT, DEFAULT_MAX_NUMBER_OF_DOCUMENTS_PER_CATEGORY_IN_REPORT, FE_LOCK_NAMES } from '@scripts/constants';
import AdminLock from '@scripts/reusable/AdminLock/AdminLock';

const ReportArticlesNumber = (props) => {
    const [noOfArticles, setNoOfArticles] = useState(DEFAULT_MAX_NUMBER_OF_DOCUMENTS_PER_CATEGORY_IN_REPORT);
    const [isNumberInvalid, setIsNumberInvalid] = useState(false);
    let debounceTimeout;

    useEffect(() => {
        setNoOfArticles(props.maxDocumentsPerCategoryInReport);
    }, [props.maxDocumentsPerCategoryInReport]);

    useEffect(() => {
        setIsNumberInvalid(validateMaxNumberOfArticles(noOfArticles))
    }, [noOfArticles]);

    useEffect(() => {
        
        if(props.maxDocumentsPerCategoryInReport !== noOfArticles) {
            debounceTimeout = setTimeout(() => {
                const shouldTriggerSave = !isNumberInvalid && props.maxDocumentsPerCategoryInReport !== noOfArticles;
                shouldTriggerSave && props.handleAutosave(props.handleMaxDocumentsPerCategoryInReport, [Number(noOfArticles)]);
            }, DEBOUNCE_MAX_DOCUMENTS_PER_CATEGORY_IN_REPORT_INPUT);
        }

        return () => {
            debounceTimeout && clearTimeout(debounceTimeout);
        }
    }, [noOfArticles, isNumberInvalid]);

    const validateMaxNumberOfArticles = (value) => {
        return Boolean(Number(value) > DEFAULT_MAX_NUMBER_OF_DOCUMENTS_PER_CATEGORY_IN_REPORT || Number(value) <= 0);
    };

    const handleChange = (e) => {
        setNoOfArticles(e.target.value);
        clearTimeout(debounceTimeout);
    };

    const inputDisabled = !props.userIsAdmin && props.isReadonly;

    return (
        <div className={props.userIsAdmin ? 'section-enabled' : props.isReadonly ? 'section-disabled' : 'section-enabled'}>
            <div className='user-preferences-category-container number-of-articles-container'>
                <div className='user-preferences-category-container__row'>
                    <h3 className='user-preferences-category-container__segment number-of-articles-container'>
                        <FormattedMessage id='UserPreferences_topic_deliverySettings_numberOfArticles.title' />
                    </h3>
                    {props.autoSaveMessage}
                    {props.userIsAdmin ? (
                        <AdminLock
                            lockName={FE_LOCK_NAMES.maxDocumentsInReportCategory}
                            onClickLock={props.handleLockClick}
                            isSectionLock={true}
                            isLocked={props.isReadonly}
                        />
                    ) : (
                        props.isReadonly && (
                            <AdminLock lockName={FE_LOCK_NAMES.maxDocumentsInReportCategory} isDisabled={true} />
                        )
                    )}
                </div>
                <div className='user-preferences-category-container__row'>
                    <span className='user-preferences-category-container__note'>
                        <FormattedMessage id='UserPreferences_topic_deliverySettings_numberOfArticles.description' />
                    </span>
                </div>
                <div className='user-preferences-category-container__row'>
                    <input
                        id='user-preferences-max-number-of-reports-input'
                        data-testid='user-preferences-max-number-of-reports-input'
                        type='number'
                        max={200}
                        min={1}
                        className='text-field-standard-lg'
                        onChange={handleChange}
                        onKeyPress={handleChange}
                        value={noOfArticles}
                        disabled={inputDisabled}
                    />
                </div>
                {isNumberInvalid && <div className='user-preferences-category-container__row'>
                    <span className='user-preferences-category-container__note validation-error'>
                        <FormattedMessage id='UserPreferences_topic_deliverySettings_numberOfArticles.validation.error' />
                    </span>
                </div>}
            </div>
        </div>
    )
}

export default withAutosave()(ReportArticlesNumber);