import React from 'react';
import ReportBuilderContentTypes from './ReportBuilderContentTypes';
import { DELIVERY_SETTINGS } from '@Main/Main.reducers';
import DeliveryPreferences from './DeliveryPreferences';
import AdminLock from '@reusable/AdminLock/AdminLock';
import { FE_LOCK_NAMES, USER_PREFERENCES_SECTIONS } from '@constants';
import ReportArticlesNumber from './ReportArticlesNumber';

const DeliverySettings = (props) => {
    return (
        <div
            className={
                props.userIsAdmin
                    ? 'section-enabled'
                    : props.userLocks.deliverySettingsAllOptions
                        ? 'section-disabled section-master-lock'
                        : 'section-enabled'
            }
        >
            <div className="user-preferences-category-container delivery-settings-container">
                {props.userIsAdmin ? (
                    <AdminLock
                        lockName={FE_LOCK_NAMES.deliverySettingsAllOptions}
                        onClickLock={props.handleLockClick}
                        isSectionLock={true}
                        isLocked={props.userLocks.deliverySettingsAllOptions}
                    />
                ) : (
                    props.userLocks.deliverySettingsAllOptions && (
                        <AdminLock
                            isSectionLock={true}
                            lockName={FE_LOCK_NAMES.deliverySettingsAllOptions}
                            isDisabled={true}
                        />
                    )
                )}
                <DeliveryPreferences
                    handleToggleDeliverySettings={props.handleToggleDeliverySettings}
                    deliverySettings={props.deliverySettings}
                    // auto-save props
                    modifiedSections={props.modifiedSections}
                    arePreferencesSaving={props.arePreferencesSaving}
                    autosaveHasError={props.autosaveHasError}
                    changeMade={props.changeMade}
                    section={USER_PREFERENCES_SECTIONS[DELIVERY_SETTINGS].DELIVERY_SETTINGS}
                />
                <div className="sub-section-separator__no-margins" />
                <ReportBuilderContentTypes
                    categories={props.categories}
                    setReportBuilderContentOrder={props.setReportBuilderContentOrder}
                    section={USER_PREFERENCES_SECTIONS[DELIVERY_SETTINGS].REPORT_BUILDER}
                    isReadonly={
                        (props.userIsAdmin && props.userLocks.rearrangeOrder) ||
                        (!props.userIsAdmin &&
                            (props.userLocks.rearrangeOrder || props.userLocks.deliverySettingsAllOptions))
                    }
                    userIsAdmin={props.userIsAdmin}
                    handleLockClick={props.handleLockClick}
                    masterLock={props.userLocks.deliverySettingsAllOptions}
                    // auto-save props
                    modifiedSections={props.modifiedSections}
                    arePreferencesSaving={props.arePreferencesSaving}
                    autosaveHasError={props.autosaveHasError}
                    changeMade={props.changeMade}
                />
                <div className="sub-section-separator__no-margins" />
                <ReportArticlesNumber
                    maxDocumentsPerCategoryInReport={props.maxDocumentsPerCategoryInReport}
                    handleMaxDocumentsPerCategoryInReport={props.handleMaxDocumentsPerCategoryInReport}
                    section={USER_PREFERENCES_SECTIONS[DELIVERY_SETTINGS].MAX_DOCUMENTS_IN_REPORT_CATEGORY}
                    isReadonly={
                        (props.userIsAdmin && props.userLocks.maxDocumentsInReportCategory) ||
                        (!props.userIsAdmin &&
                            (props.userLocks.maxDocumentsInReportCategory || props.userLocks.deliverySettingsAllOptions))
                    }
                    userIsAdmin={props.userIsAdmin}
                    handleLockClick={props.handleLockClick}
                    masterLock={props.userLocks.deliverySettingsAllOptions}
                    // auto-save props
                    modifiedSections={props.modifiedSections}
                    arePreferencesSaving={props.arePreferencesSaving}
                    autosaveHasError={props.autosaveHasError}
                    changeMade={props.changeMade}
                />
            </div>
        </div>
    );
};

export default DeliverySettings;
