import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import EsgRatingsUtils from '../EsgRatingsUtils';
import ReactTooltip from 'react-tooltip';

const EsgRatingsBarChart = (props) => {
    const { width, height, rating, ratingCategory, columnIndex } = props;
    const { defaultColor, fillColor } = EsgRatingsUtils.getColorsBasedOnCategory(ratingCategory);
    const ratingNumber = rating === 'N/A' ? 0 : +rating;
    const tooltipPosition = EsgRatingsUtils.getTooltipPositionBasedOnColumnIndex(columnIndex);

    const graphOptions = {
        chart: {
            type: 'bar',
            animation: false,
            height: height,
            width: width,
            margin: [0, 0, 0, 0],
        },
        title: {
            text: null,
        },
        yAxis: {
            visible: false,
            labels: {
                enabled: false,
            },
            min: 0,
            max: 100,
        },
        plotOptions: {
            bar: {
                borderWidth: 0,
                dataLabels: {
                    enabled: false,
                },
            },
            series: {
                stacking: 'normal',
                enableMouseTracking: false,
            },
        },
        series: [
            {
                data: [100 - ratingNumber],
                color: defaultColor,
            },
            {
                data: [ratingNumber],
                color: fillColor,
            },
        ],
        credits: { enabled: false },
        tooltip: { enabled: false },
    };

    return (
        <div
            data-tip={props.tooltip}
            data-for={`category-and-subcategory-${tooltipPosition}-tooltip`}
            className="bar-chart-container"
            data-track="esg_ratings-bar-chart-container"
            onMouseEnter={() => {
                ReactTooltip.rebuild();
            }}
        >
            <HighchartsReact highcharts={Highcharts} options={graphOptions} />
        </div>
    );
};

export default EsgRatingsBarChart;
