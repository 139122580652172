import React from 'react';
import { Link } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { withAnonymizedToggleView, withOnPageToggleView } from '@utils/contexts';
import { START_LOCATION_HASH } from '@constants';

const GoogleAnonymizedSelector = () => {
    return (
        <li className="diligence-header__menu-item diligence-header__menu-item__google-anonymized notranslate">
            <Link to="/google-anonymized">
                <div className="diligence-header__menu-link">
                    <span className="diligence-header__menu-link__google-anonymized">
                        <FormattedMessage id="Anonymized.GoogleAnonymized.selector" />
                    </span>
                </div>
            </Link>
        </li>
    );
};

export default withAnonymizedToggleView(withOnPageToggleView(GoogleAnonymizedSelector, [START_LOCATION_HASH]));
