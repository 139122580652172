import xhr from '@utils/xhr';
import { withContentSourceFilter } from '@utils/utilities';

export function getApiVersion(payload) {
    return payload.version ? payload.version + '/' : '';
}

const api = {
    getCountsForCategories(payload, ts, isEsgRating = false) {
        const version = getApiVersion(payload);
        const url = isEsgRating
            ? `/api/${version}search/documents-count/esg?ts=${ts}`
            : `/api/${version}search/documents-count?ts=${ts}`;
        return xhr.post({
            url,
            data: payload,
        });
    },

    getPostFiltersAndCounts(screeningEntityId, viewId) {
        const url = viewId ?
        `/api/screening-entity/${screeningEntityId}/?viewId=${viewId}` :
        `/api/screening-entity/${screeningEntityId}`
        return xhr.get({ url }).then((response) => {
            return response.body;
        });
    },

    //searchQuery,  searchQueryType, pageNumber, pageSize, includeTerms, excludeTerms, category, sort, fuzzyNames, dateRanges
    getElasticSanctionsSearchResults(payload, ts) {
        let version = getApiVersion(payload);
        return xhr
            .post({
                url: '/api/' + version + 'search/documents?ts=' + ts,
                data: payload,
            })
            .then((response) => {
                const { lastupdatedpreferencesdate, updatedbyadmin } = response.headers;
                response.body.lastPreferencesUpdateInfo = { lastupdatedpreferencesdate, updatedbyadmin };
                return response.body;
            });
    },

    getDuplicateDocuments(duplicateGroupIds) {
        let version = getApiVersion(duplicateGroupIds);
        return xhr
            .post({
                url: 'api/' + version + 'search/documents-similar-doc-count',
                data: duplicateGroupIds,
            })
            .then((response) => {
                return response.body;
            });
    },

    getFilterInfo(payload, ts) {
        let version = getApiVersion(payload);
        return xhr
            .post({
                url: '/api/' + version + 'search/documents/filterInfo?ts=' + ts,
                data: payload,
            })
            .then((response) => {
                return response.body;
            });
    },

    getElasticDocument(params) {
        let {
            id,
            searchQuery,
            searchQueryType,
            prefilterQuery,
            category,
            billingId,
            proximity,
            includeTerms,
            costCode,
            documentViewType,
            singleSource,
            fuzzyThreshold,
            suggestedNames,
            terms,
        } = params;
        let extraProperties = {};

        if (fuzzyThreshold !== null && fuzzyThreshold !== undefined && fuzzyThreshold !== '') {
            extraProperties.fuzzyThreshold = fuzzyThreshold;
        }

        let data = {
            lni: id,
            searchQuery,
            searchQueryType,
            prefilterQuery,
            category,
            billingId,
            proximity,
            includeTerms,
            costCode,
            documentViewType,
            singleSource,
            suggestedNames,
            terms,
            ...extraProperties,
        };

        data = withContentSourceFilter().extendConfig(category, data);
        let version = getApiVersion(data);
        if (version && data.newsQueries && data.newsQueries[0]) {
            data.query = data.newsQueries[0].query;
            data.negativeNewsQueries = data.newsQueries[0].negativeNewsQueries;
            delete data.newsQueries;
        }
        return xhr
            .post({
                url: '/api/search/document',
                data,
            })
            .then((response) => {
                return response.body;
            });
    },

    getReportBuilderElasticDocument(params) {
        let { id, searchQuery, searchQueryType, costCode } = params;

        return xhr
            .get({
                url:
                    '/api/elastic-search/report/report-snippet/' +
                    id +
                    '?searchQuery=' +
                    searchQuery +
                    '&searchQueryType=' +
                    searchQueryType +
                    '&costCode=' +
                    costCode,
            })
            .then((response) => {
                return response.body;
            });
    },

    getElasticDocumentDuplicates(searchQueryType, query, categoryName, prefilterQuery, duplicateGroupId) {
        let data = {
            category: categoryName,
            duplicateGroupId,
            searchQueryType,
            searchQuery: query,
            prefilterQuery,
        };
        data = withContentSourceFilter().extendConfig(categoryName, data);
        let version = getApiVersion(data);
        if (version && data.newsQueries && data.newsQueries[0]) {
            data.query = data.newsQueries[0].query;
            delete data.newsQueries;
        }

        // Custom news needs to be an array
        if (version && data.customNews && typeof data.customNews === 'string') {
            data.customNews = data.customNews.split();
        }

        return xhr
            .post({
                url: '/api/' + version + 'search/documents/duplicates',
                data,
            })
            .then((response) => {
                return response.body;
            });
    },

    billingForSearch(payload) {
        return xhr
            .post({
                url: '/api/billing/searchEvent',
                data: payload,
            })
            .then((response) => {
                return response.body;
            });
    },

    fuzzySearch(name) {
        return xhr
            .get({
                url: '/api/search/fuzzy',
                data: {
                    name,
                },
            })
            .then((response) => {
                return response.body;
            });
    },

    getSuggestedNames(name) {
        return xhr
            .get({
                url: '/api/search/suggestedNames',
                data: {
                    names: name,
                },
            })
            .then((response) => {
                return response.body;
            });
    },
    getSanctionsRisk(ts, payload) {
        return xhr
            .post({
                url: `/api/v1/snapshot/sanctions?ts=${ts}`,
                data: payload,
            })
            .then((response) => {
                return response.body;
            })
            .catch((error) => {
                console.log('Error getting sanctions risk: ', error);
                return {
                    count: 0,
                    riskScore: null,
                    documents: [],
                };
            });
    },
};

export default api;
