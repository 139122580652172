import React, { Component } from 'react';
import { isNumber } from 'lodash';

const COMMENTS_ICONS = {
    ADD_COMMENT: 'la-AddComment',
    SHOW_COMMENTS: 'la-CommentsList',
};

const getElementsPosition = (rowId, nrOfUnhiddenColumns) => {
    const elem = document.querySelector(`[data-comments-cell-rowid="${rowId}"]`);
    const bounds = elem.getBoundingClientRect();
    const leftPos = bounds.left + document.documentElement.scrollLeft;

    return {
        bounds: {
            top: bounds.top + document.documentElement.scrollTop,
            left: nrOfUnhiddenColumns > 3 ? leftPos : leftPos + 300, // when the nr of shown columns is too low, the comment section will go outside of the page left bound
            width: elem.offsetWidth,
            height: elem.offsetHeight,
        },
        position: {
            x: leftPos,
            y: undefined,
        },
    };
};
export class CommentsCellTemplate extends Component {
    getCompatibleCell(uncertainCell) {
        const text = uncertainCell.text;
        return { ...uncertainCell, text };
    }

    isFocusable() {
        return false;
    }

    update(cell, cellToMerge) {
        return this.getCompatibleCell({ ...cell, text: cellToMerge.text });
    }

    render(cell) {
        const { entityId, rowId, openPopover, hasComments, activeRowId, nrOfUnhiddenColumns } = cell;
        const iconToShow = hasComments ? COMMENTS_ICONS.SHOW_COMMENTS : COMMENTS_ICONS.ADD_COMMENT;
        const isRowActive = activeRowId === rowId;
        const iconClassName = isRowActive ? iconToShow : `${iconToShow}Inactive`;
        const handleClick = () => {
            if (!isNumber(rowId)) {
                return;
            }

            const popoverPosition = getElementsPosition(rowId, nrOfUnhiddenColumns);
            openPopover(popoverPosition, rowId, entityId);
        };

        return (
            <div className="grid-table-comments-cell-template">
                <span
                    data-comments-cell-rowid={rowId}
                    data-testid={`show-comments-section-icon-${cell?.entity?.displayName?.toLowerCase()}`}
                    className={`show-comments-section ${iconClassName}`}
                    onClick={handleClick}
                />
            </div>
        );
    }
}
