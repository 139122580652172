import { SET_CURRENT_REPORT, UPDATE_SNIPPETS } from './CurrentReport.actions';
import { cloneDeep } from 'lodash';
import update from 'immutability-helper';

function currentReport(state = {}, action) {
    let currentReport;
    switch (action.type) {
        case SET_CURRENT_REPORT:
            return Object.assign({}, action.payload);

        case UPDATE_SNIPPETS:
            currentReport = cloneDeep(action.payload.currentReport);
            currentReport.articlesSnippets = action.payload.snippets;
            return update(state, { $set: currentReport });

        default:
            return state;
    }
}
export default currentReport;
