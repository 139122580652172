export const getUboSearchRequestParams = ({ searchTerm }) => {
    return {
        url: `/api/ubo/suggestions`,
        method: 'post',
        data: { searchTerm },
    };
};

export const getUboCountSearchRequestParams = ({ dunsList, searchTerms, billingSearchEvent, postFilters }) => {
    return {
        url: `/api/ubo/count`,
        data: { searchTerms, dunsList, billingSearchEvent, postFilters },
        method: 'post',
    };
};
