export const UBO_SEARCH_INIT = 'UBO_SEARCH_INIT';
export const UBO_SEARCH_COUNT = 'UBO_SEARCH_COUNT';
export const UBO_ITEM_ADD = 'UBO_ITEM_ADD';
export const UBO_ITEM_REMOVE = 'UBO_ITEM_REMOVE';
export const UBO_INIT = 'UBO_INIT';
export const UBO_SEARCH_SUCCESS = 'UBO_SEARCH_SUCCESS';
export const UBO_CLEAR = 'UBO_CLEAR';
export const UBO_CLEAR_SUGGESTIONS = 'UBO_CLEAR_SUGGESTIONS';
export const UBO_ERROR = 'UBO_ERROR';
export const UBO_SUBMIT = 'UBO_SUBMIT';
export const UBO_UPDATE_QUERY = 'UBO_UPDATE_QUERY';
export const UBO_UPDATE_SELECTED_TERM = 'UBO_UPDATE_SELECTED_TERM';
export const UBO_ADD_MEMO = 'UBO_ADD_MEMO';
export const UBO_UPDATE_SORTING = 'UBO_UPDATE_SORTING';
export const UBO_UPDATE_FILTERING = 'UBO_UPDATE_FILTERING';
export const UBO_UPDATE_SORTING_AND_FILTERING = 'UBO_UPDATE_SORTING_AND_FILTERING';
export const UBO_SET_POST_FILTERS = 'UBO_SET_POST_FILTERS';
export const UBO_RESET_POST_FILTERS = 'UBO_RESET_POST_FILTERS';
export const UBO_UPDATE_CATEGORY_POST_FILTERS = 'UBO_UPDATE_CATEGORY_POST_FILTERS';

let uboActions = {
    init: (payload) => ({
        type: UBO_INIT,
        payload,
    }),
    initSearch: ({ query, cursorPosition, searchTerm }) => ({
        type: UBO_SEARCH_INIT,
        payload: { query, cursorPosition, searchTerm },
    }),
    addItem: (item) => ({
        type: UBO_ITEM_ADD,
        payload: { item },
    }),
    removeItem: ({ index }) => ({
        type: UBO_ITEM_REMOVE,
        payload: { index },
    }),
    searchSuccess: (payload) => ({
        type: UBO_SEARCH_SUCCESS,
        payload,
    }),
    clear: (payload) => ({
        type: UBO_CLEAR,
        payload,
    }),
    clearSuggestions: () => ({
        type: UBO_CLEAR_SUGGESTIONS,
    }),
    hasError: (payload) => ({
        type: UBO_ERROR,
        payload,
    }),
    updateSelectedItemIndex: (item, index) => ({
        type: UBO_UPDATE_SELECTED_TERM,
        payload: { item, index },
    }),
    updateQuery: (payload) => ({
        type: UBO_UPDATE_QUERY,
        payload,
    }),

    submit: (payload) => ({
        type: UBO_SUBMIT,
        payload,
    }),
    searchCount: ({ dunsList, searchTerms }) => ({
        type: UBO_SEARCH_COUNT,
        payload: { dunsList, searchTerms },
    }),
    addMemo: ({ searchTerm, data }) => ({
        type: UBO_ADD_MEMO,
        payload: { searchTerm, data },
    }),
    updateSortingAndFiltering: (payload) => ({
        type: UBO_UPDATE_SORTING_AND_FILTERING,
        payload,
    }),

    updateFiltering: (payload) => ({
        type: UBO_UPDATE_FILTERING,
        payload,
    }),
    setPostFilters: (payload) => ({
        type: UBO_SET_POST_FILTERS,
        payload,
    }),
    updateCategoryPostFilters: ({ category, postFilter }) => ({
        type: UBO_UPDATE_CATEGORY_POST_FILTERS,
        payload: { category, postFilter },
    }),
};

export default uboActions;
