import React from 'react';
import utils from '@utils/utilities';

const NegativityTermItem = ({ termItem, onNegativeTermsClick, language }) => {
    const { term, count, negativity } = termItem;
    const formattedCount = utils.formatNumbersByUserLocale(language, count);

    return (
        <div className="negative-terms-container-item">
            <div className="negative-terms-container-item-label">
                <p onClick={() => onNegativeTermsClick(term)}>{term}</p>
            </div>
            <div className="negative-terms-container-item-count">
                <span className={`negative-terms-container-item-count-value ${negativity}`}>{formattedCount}</span>
            </div>
        </div>
    );
};

export default NegativityTermItem;
