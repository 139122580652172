import React from 'react';
import utils from './utilities';

// hoc utility functions
/**
 * Takes a HOC as parameter along with its "args" and runs it against an empty component and supply the output through
 * the children of the WrappedComponent.
 * This is useful when you need send as children the output of a HOC that is generating content outside the DOM of the
 * wrapped component
 * Caution: not to be used on HOCs that override the props of the WrappedComponent
 * @param hoc
 * @param args
 */
export const asChildBefore =
    (hoc, ...args) =>
    (WrappedComponent) => {
        const asChild = (props) => {
            const HocComponent = hoc(EmptyComponent, ...args);
            return (
                <WrappedComponent {...props}>
                    {props.children}
                    <HocComponent {...props} />
                </WrappedComponent>
            );
        };

        asChild.displayName = utils.getDisplayName(WrappedComponent);

        return asChild;
    };

export const asChildAfter =
    (hoc, ...args) =>
    (WrappedComponent) => {
        const asChild = (props) => {
            const HocComponent = hoc(EmptyComponent, ...args);
            return (
                <WrappedComponent {...props}>
                    <HocComponent {...props} />
                    {props.children}
                </WrappedComponent>
            );
        };

        asChild.displayName = utils.getDisplayName(WrappedComponent);

        return asChild;
    };

/**
 * Takes propName, HOC as parameter along with its "args" and runs it against an empty component and supply the output
 * through the "propName" prop to the WrappedComponent.
 * This is useful when you need send as a prop the output of a HOC that is generating content outside the DOM of the
 * wrapped component
 * Caution: not to be used on HOCs that override the props of the WrappedComponent
 * @param propName
 * @param hoc
 * @param args
 */
export const asProp =
    (propName, hoc, ...args) =>
    (WrappedComponent) => {
        const asProp = (props) => {
            const HocComponent = hoc(EmptyComponent, ...args);
            const extraProps = { [propName]: <HocComponent {...props} /> };
            return <WrappedComponent {...props} {...extraProps} />;
        };

        asProp.displayName = utils.getDisplayName(WrappedComponent);

        return asProp;
    };

export const EmptyComponent = () => null;
