import React, { Fragment } from 'react';
import ReactTooltip from 'react-tooltip';
import { throttle } from 'lodash';

class TruncatedText extends React.Component {
    constructor(props) {
        super(props);
        this.element = null;

        this.state = {
            isTruncated: false,
        };

        this.trottledFunction = throttle(this.updateTruncationState, 1000);
    }

    componentDidMount() {
        this.updateTruncationState();
        window.addEventListener('resize', this.trottledFunction, false);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.trottledFunction, false);
    }

    updateTruncationState = () => {
        this.setState({ isTruncated: this.props.checkTruncation(this.element) });
    };

    hideCurrentTooltip = () => {
        ReactTooltip.hide(this.element);
    };

    render() {
        const { TruncatedComponent, tooltipId, tooltipHtml, hasChild } = this.props;

        return (
            <Fragment>
                {this.state.isTruncated && (
                    <ReactTooltip
                        id={tooltipId}
                        html={tooltipHtml}
                        place="bottom"
                        type="light"
                        border={true}
                        effect="solid"
                        className="tooltips notranslate"
                    />
                )}
                <div
                    className={this.props.className}
                    ref={(element) => (this.element = hasChild ? element && element.firstChild : element)}
                >
                    <TruncatedComponent isTruncated={this.state.isTruncated} {...this.props} />
                </div>
            </Fragment>
        );
    }
}

export default TruncatedText;
