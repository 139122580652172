import React from 'react';
import { IndexRedirect, Route } from 'react-router';
import routerUtils from '../routerUtils'
import { routeCheckPermission} from "@scripts/permissions";
import {
    EDIT_SEARCH_URL,
    ROUTES
} from '@constants';
// Import components
import Main from '@pages/Main/Main.index.jsx';
import ErrorPage from '@pages/ErrorPage/ErrorPage.index.jsx';
import StartPage from '@pages/StartPage/StartPage.index.jsx';
import MainSearch from '@pages/MainSearch/MainSearch.jsx';
import UnsubscribePage from '@pages/UnsubscribePage/UnsubscribePage';
import UboDocument from '@pages/UboDocoument/UboDocument';
import ReportBuilderMain from '@pages/ReportBuilder/ReportBuilderMain';
import CreateAlert from '@pages/Alerts/CreateAlerts';
import EditAlert from '@pages/Alerts/EditAlert';
import EditAlertSearch from '@pages/Alerts/EditAlertSearch';
import UserPreferences from '@pages/UserPreferances/UserPreferencesPage.index';
import AdminPreferences from '@pages/AdminPreferences/AdminPreferences';
import History from '@pages/HistoryPage/History.index';
import LogoutPage from '@pages/LogoutPage/LogoutPage.index';
import ElasticSingleDocument from '@pages/MainSearch/components/ElasticSingleDocument';
import DeepLink from '@pages/DeepLink/DeepLink';
import ManageAlerts from '@pages/ManageAlerts/ManageAlerts.index';
import PublicRecords from '@pages/PublicRecords/PublicRecords.index';
import GoogleAnonymized from '@pages/GoogleAnonymized/GoogleAnonymized';
import EntityView from '@pages/EntityView/EntityView.index';

const routes = (
    <>
        <Route path="/" component={Main} onEnter={routerUtils.handlePrefsPermissions} onChange={routeCheckPermission}>
            <IndexRedirect to={ROUTES.START_PAGE} />
            <Route path={ROUTES.START_PAGE} component={StartPage} />
            <Route path={EDIT_SEARCH_URL} component={StartPage} />
            <Route path={ROUTES.MAIN_SEARCH} component={MainSearch} />
            <Route path={ROUTES.USER_PREFERENCES} component={UserPreferences} withoutRefresh={true} />
            <Route path={ROUTES.ADMIN_PREFERENCES} component={AdminPreferences} />
            <Route path={ROUTES.DOCUMENT} component={ElasticSingleDocument} />
            <Route path={ROUTES.DEEP_LINK} component={DeepLink} />
            <Route path={ROUTES.REPORT_BUILDER} component={ReportBuilderMain} />
            <Route path={ROUTES.HISTORY} component={History} />
            <Route path={ROUTES.CREATE_ALERT} component={CreateAlert} />
            <Route path={ROUTES.EDIT_ALERT} component={EditAlert} />
            <Route path={ROUTES.EDIT_ALERT_SEARCH} component={EditAlertSearch} />
            <Route path={ROUTES.MANAGE_ALERTS} component={ManageAlerts} />
            <Route path={ROUTES.PUBLIC_RECORDS} component={PublicRecords} />
            <Route path={ROUTES.GOOGLE_ANONYMIZED} component={GoogleAnonymized} />
            <Route path={ROUTES.UBO_DOCUMENT} component={UboDocument} />
            <Route path={ROUTES.SCREENING} component={EntityView} />
        </Route>

        <Route path={ROUTES.LOGOUT} component={LogoutPage} />
        <Route path={ROUTES.UNSUBSCRIBE} component={UnsubscribePage} />
        <Route path={ROUTES.ERROR} component={ErrorPage} onEnter={routerUtils.loadErrorPageData} />
        <Route path="*" component={ErrorPage} onEnter={routerUtils.loadNotFoundPageData} />
    </>
);

export default routes;