import React from 'react';
import withAutosave from '../HOC/WithAutosave';
import { FormattedMessage } from 'react-intl';
import { FE_LOCK_NAMES } from '@constants';
import SourcesComponent from './SourcesComponent';
import AdminLock from '@reusable/AdminLock/AdminLock';

const DefaultSearchSettings = (props) => {
    const handleSourceSelection = (isSourceChecked, sourceName) => {
        props.handleAutosave(props.handleSourceSelection, [isSourceChecked, sourceName]);
    };

    return (
        <div
            className={
                props.userIsAdmin ? 'section-enabled' : props.sourcesLocked ? 'section-disabled' : 'section-enabled'
            }
        >
            <div className="user-preferences-default-search-settings">
                {props.userIsAdmin ? (
                    <AdminLock
                        lockName={FE_LOCK_NAMES.sourcesIncluded}
                        isSectionLock={true}
                        onClickLock={props.onClickLock}
                        isLocked={props.sourcesLocked}
                    />
                ) : (
                    props.sourcesLocked && <AdminLock lockName={FE_LOCK_NAMES.sourcesIncluded} isDisabled={true} />
                )}

                <div className="user-preferences-category-container__row">
                    <h3
                        className="user-preferences-category-container__topic"
                        data-track="user-references-topic-sources-included"
                    >
                        <FormattedMessage id="UserPreferences_topic_sources_included" />
                    </h3>
                    {props.autoSaveMessage}
                </div>

                <span className="user-preferences-category-container__note">
                    <FormattedMessage id="UserPreferences_topic_general_explanation" />
                </span>

                <SourcesComponent
                    sources={props.sources}
                    handleSourceSelection={handleSourceSelection}
                    isReadonly={!props.userIsAdmin && props.sourcesLocked}
                    sourcesInformation={props.sourcesInformation}
                />
            </div>
        </div>
    );
};

export default withAutosave()(DefaultSearchSettings);
