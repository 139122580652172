import xhr from '@utils/xhr';

const api = {
    createReport(createReportParams) {
        return xhr
            .post({
                url: '/api/report/save',
                data: {
                    ...createReportParams,
                },
            })
            .then((response) => {
                return response.body;
            });
    },

    deleteArticleFromReport(reportSnippetId) {
        return xhr
            .delete({
                url: '/api/report/report-snippet/' + reportSnippetId,
            })
            .then((response) => [response, undefined])
            .catch((error) => [undefined, error]);
    },

    deleteReport(reportId) {
        return xhr
            .delete({
                url: '/api/report/' + reportId,
            })
            .then((response) => [response.body, undefined])
            .catch((error) => [undefined, error]);
    },

    deleteReports(reportIds) {
        return xhr
            .delete({
                url: '/api/report/',
                data: reportIds,
            })
            .then((response) => {
                return response.body;
            });
    },

    deleteReportCategory(reportId, payload) {
        return xhr
            .patch({
                url: `/api/report/${reportId}/delete-report-snippets`,
                data: payload,

            })
            .then((response) => [response.statusCode, undefined])
            .catch((error) => [undefined, error]);
    },

    getReportsByUser(pageNumber, pageSize, praOn, timezone, prReportOrder) {
        return xhr
            .get({
                url:
                    '/api/report/count-with-listing' +
                    '?pageNumber=' +
                    pageNumber +
                    '&pageSize=' +
                    pageSize +
                    '&praOn=' +
                    praOn +
                    '&timezone=' +
                    timezone +
                    '&prReportOrder=' +
                    prReportOrder,
            })
            .then((response) => {
                return response.body;
            });
    },

    removeIncludedReport(childReportId, combinedReportId) {
        return xhr
            .delete({
                url: `/api/report/combined/${combinedReportId}/${childReportId}`,
            })
            .then((response) => [response.body, undefined])
            .catch((error) => [undefined, error]);
    },

    getReportDelivery(reportId) {
        return xhr
            .get({
                url: '/api/reportdelivery/report/' + reportId,
            })
            .then((response) => [response.body, undefined])
            .catch((error) => [undefined, error]);
    },

    getReportsCountByUser(praOn, timezone) {
        return xhr
            .get({
                url: '/api/report/count?praOn=' + praOn + '&timezone=' + timezone,
            })
            .then((response) => {
                return response.body;
            });
    },

    getReportBySearchQuery(searchQuery, searchQueryType, praOn, prReportOrder) {
        return xhr
            .post({
                url: '/api/report/search?praOn=' + praOn + '&prReportOrder=' + prReportOrder,
                data: {
                    searchQuery: searchQuery,
                    searchQueryType: searchQueryType,
                },
            })
            .then((response) => {
                return response.body;
            });
    },
    getReportSnippets(reportId, pageNumber = 0, pageSize = 1000) {
        return xhr
            .get({
                url: '/api/report/report-snippets/' + reportId + '?pageNumber=' + pageNumber + '&pageSize=' + pageSize,
            })
            .then((response) => {
                return response.body;
            });
    },

    getReportById(reportId) {
        return xhr
            .get({
                url: '/api/report/' + reportId,
            })
            .then((response) => {
                return response.body;
            });
    },

    updateReportInfo(title, reportId) {
        return xhr
            .put({
                url: '/api/report/update?title=' + title,
                data: {
                    id: reportId,
                },
            })
            .then((response) => [response.body, undefined])
            .catch((err) => [undefined, err]);
    },

    updateReportSummary(reportId, reportSummary) {
        return xhr
            .put({
                url: '/api/report/research-summary/update/' + reportId,
                data: reportSummary,
            })
            .then((response) => {
                return response.body;
            });
    },

    updateReportSummaryOrder(reportId, categoryOrder) {
        return xhr
            .put({
                url: '/api/report/research-summary/update/' + reportId + '/order',
                data: categoryOrder,
            })
            .then((response) => [response.body, undefined])
            .catch((error) => [undefined, error]);
    },

    getReportSummary(reportId) {
        return xhr
            .get({
                url: '/api/report/research-summary/' + reportId,
            })
            .then((response) => {
                return response;
            });
    },
    // PAYLOAD EXAMPLE
    // data: {
    // filetype: docType,
    // filename: fileName,
    // startEachArticleOnNewPage: true,
    // language: interfaceLanguage,
    // contentsOptions: {
    //     shouldContainCoverPage:true,
    //     tableOfContents:'BASIC',
    //     shouldContainFullDocs:false,
    //     shouldContainResearchSummary:true
    // }
    //}
    getReportBuild(reportId, data) {
        return xhr
            .post({
                url: '/api/reportbuilder/build/' + reportId,
                data: data,
            })
            .then((response) => {
                return response.body;
            });
    },

    getReportBuildForBucket(reportId) {
        return xhr
            .post({
                url: '/api/reportbuilder/build/' + reportId + '?filetype=PDF',
            })
            .then((response) => {
                return response.body;
            });
    },

    getReportStatus(reportId, buildId) {
        return xhr
            .get({
                url: `/api/reportdelivery/report/download/${reportId}/${buildId}/status`,
            })
            .then((response) => [response.body, null])
            .catch(() => [null, true]);
    },

    getReportDownload(reportId, buildId) {
        return xhr
            .get({
                url: '/api/reportdelivery/report/download/' + reportId + '/' + buildId,
            })
            .then((response) => {
                return response;
            });
    },

    downloadReports(downloadData) {
        return xhr
            .post({
                url: '/api/elastic-search/report/download-reports',
                data: downloadData,
            })
            .then((response) => [response.body, undefined])
            .catch((error) => [undefined, error]);
    },

    downloadReportCategories(downloadData) {
        return xhr
            .post({
                url: '/api/elastic-search/report/download-category',
                data: downloadData,
            })
            .then((response) => {
                return response.body;
            });
    },

    sendEmailReports(data) {
        return xhr
            .post({
                url: '/api/elastic-search/report/email-reports',
                data: data,
            })
            .then((response) => {
                return response.body;
            });
    },

    getEmailReportStatus(emailDeliveryId) {
        return xhr
            .get({
                url: `/api/reportdelivery/email/${emailDeliveryId}/status`,
            })
            .then((response) => [response.body, null])
            .catch(() => [null, true]);
    },

    sendEmailCategoryReports(payload) {
        return xhr
            .post({
                url: '/api/elastic-search/report/email-category',
                data: payload,
            })
            .then((response) => {
                return response.body;
            });
    },

    getPrintStatus(reportId, buildId) {
        return xhr
            .get({
                url: `/api/reportdelivery/print/${reportId}/${buildId}/status`,
            })
            .then((response) => response.body)
            .catch(() => null);
    },

    getPrintDownload(reportId, buildId) {
        return xhr
            .get({
                url: `/api/reportdelivery/print/${reportId}/${buildId}`,
            })
            .then((response) => response.text)
            .catch(() => null);
    },

    sendPrintReports(data) {
        return xhr
            .post({
                url: '/api/elastic-search/report/print-reports',
                data,
            })
            .then((response) => {
                return response.body;
            });
    },

    sendPrintReportCategories(printData) {
        return xhr
            .post({
                url: '/api/elastic-search/report/print-category',
                data: printData,
            })
            .then((response) => {
                return response.body;
            });
    },

    addNoteToReportSnippet(reportSnippetId, input) {
        return xhr
            .post({
                url: '/api/report/save/report-snippet/' + reportSnippetId + '/note',
                data: input,
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                },
            })
            .then((response) => {
                return response.body;
            });
    },

    deleteNoteFromReportSnippet(reportSnippetId) {
        return xhr
            .delete({
                url: '/api/report/report-snippet/' + reportSnippetId + '/note/delete',
            })
            .then((response) => {
                return response.body;
            });
    },

    addNoteToReportApi(reportId, input) {
        return xhr
            .post({
                url: '/api/report/save/' + reportId + '/note',
                data: input,
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                },
            })
            .then((response) => {
                return response.body;
            });
    },

    deleteNoteFromReportApi(reportId) {
        return xhr
            .delete({
                url: '/api/report/' + reportId + '/note/delete',
            })
            .then((response) => {
                return response.body;
            });
    },

    addElasticDocumentsToReportApi(reportId, payload) {
        return xhr
            .post({
                url: '/api/elastic-search/report/' + reportId + '/report-snippets',
                data: payload,
            })
            .then((response) => {
                return response.body;
            });
    },
    //printInfo, documentIds, researchSummary, searchQuery, searchQueryType, postFilters
    printElasticSelectedArticles(payload) {
        return xhr
            .post({
                url: '/api/elastic-search/report/print-selected-articles',
                data: payload,
            })
            .then((response) => {
                return response.body;
            });
    },

    //postFilters, searchQuery, searchQueryType, fuzzyNames, reasearchSummary, printInfo
    printElasticAllArticles(payload) {
        return xhr
            .post({
                url: '/api/elastic-search/report/print-all-articles',
                data: payload,
            })
            .then((response) => {
                return response.body;
            });
    },

    //emailInfo, documentIds,  researchSummary, searchQuery, searchQueryType, category, postFilters
    emailElasticSelectedArticles(payload) {
        return xhr
            .post({
                url: '/api/elastic-search/report/email-selected-articles',
                data: payload,
            })
            .then((response) => {
                return response.body;
            });
    },
    //postFilters, searchQuery, searchQueryType, fuzzyNames, reasearchSummary, emailInfo
    emailElasticAllArticles(payload) {
        return xhr
            .post({
                url: '/api/elastic-search/report/email-all-articles',
                data: payload,
            })
            .then((response) => {
                return response.body;
            });
    },
    //downloadInfo, documentIds, researchSummary, searchQuery, searchQueryType, postFilters
    downloadElasticSelectedArticles(payload) {
        return xhr
            .post({
                url: '/api/elastic-search/report/download-selected-articles',
                data: payload,
            })
            .then((response) => {
                return response.body;
            });
    },
    // postFilters, searchQuery, searchQueryType, prefilterQuery, researchSummary, fuzzyName, downloadInfo
    downloadElasticAllArticles(payload) {
        return xhr
            .post({
                url: '/api/elastic-search/report/download-all-articles',
                data: payload,
            })
            .then((response) => {
                return response.body;
            });
    },

    sortArticlesInCategory(reportId, categoryName, reportSnippetId, order) {
        return xhr
            .post({
                url: '/api/elastic-search/report/' + reportId + '/reportSnippetOrder',
                data: { reportSnippetId, order, category: categoryName },
            })
            .then((response) => [response.body, undefined])
            .catch((error) => [undefined, error]);
    },

    sortChildReportsForCombined(reportId, orderedList) {
        return xhr
            .put({
                url: `/api/report/${reportId}/reorderChildren`,
                data: orderedList,
            })
            .then((response) => [response.body, undefined])
            .catch((error) => [undefined, error]);
    },

    getParentsAndChildren(reportId) {
        return xhr
            .get({
                url: `api/report/${reportId}/withChildrenAndParents`,
            })
            .then((response) => [response.body, undefined])
            .catch((error) => [undefined, error]);
    },

    getMultipleReportsDeliveryStatus(reportIds) {
        return xhr
            .get({
                url: `/api/report/withDeliveries?reportIds=${reportIds.join(',')}`,
            })
            .then((response) => [response.body, undefined])
            .catch((error) => [undefined, error]);
    },

    changeHasUpdatesFlag(reportIds, hasUpdates) {
        return xhr
            .put({
                url: `/api/report/hasUpdates?&hasUpdates=${hasUpdates}`,
                data: reportIds,
            })
            .then((response) => [response.body, undefined])
            .catch((error) => [undefined, error]);
    },

    getDocumentsCountPerCategory(reportId) {
        return xhr
            .get({
                url: `api/report/report-snippets-counts-per-category/${reportId}`,
            })
            .then((response) => [response.body, undefined])
            .catch((error) => [undefined, error]);
    },

    getMultipleEntitiesReportById(multipleEntitiesReportId) {
        return xhr
            .get({
                url: `/api/screening-entity/reports/report/${multipleEntitiesReportId}`,
            })
            .then((response) => [response.body, undefined])
            .catch((error) => [undefined, error]);
    },

    updateReportSnapshotDelivery(reportId, snapshotDelivery) {
        return xhr
            .put({
                url: '/api/report/update/snapshotDelivery',
                data: {
                    reportId,
                    snapshotDelivery,
                },
            })
            .then((response) => [response.body, undefined])
            .catch((error) => [undefined, error]);
    },
};

export default api;
