import React, {Fragment} from 'react';
// Import Redux store
import reduxStore from '@reduxStore';
// i18n
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import ThemeSwitcherProvider from '@contexts/ThemeSwitcherContext';
import {
    DEFAULT_LANGUAGE,
    LOCALES
} from '@constants';

/**
 * Get specific data for a certain locale (id, locale or translations).
 *
 * @param   {string}        key   The property to be fetched.
 * @return  {string|Object}       The data from the specified property.
 */
export function getLocale(key, user) {
    let preferences = user?.preferences,
        language = preferences?.language ? preferences.language : DEFAULT_LANGUAGE;
        return LOCALES[language][key];
}

/**
 * This component is used to wrap translations from <FormattedMessage> and other React Intl components.
 *
 * @param   {Array<ReactElement>} children   The text node that needs to be rendered inside.
 * @return  {HTMLElement}
 */
export const ReactIntlTextComponent = ({children}) => {
    return (<Fragment>{children}</Fragment>);
}


const AppProviders = ({children}) => {
    let user = reduxStore.getState().user;
    return (
        <Provider store={reduxStore}>
            <ThemeSwitcherProvider>
                <IntlProvider locale={getLocale('locale', user)}
                                onError={(error)=>{console.log('--------->', error)}}
                                messages={getLocale('translations', user)}
                                textComponent={ReactIntlTextComponent}>
                    {children}
                </IntlProvider>
            </ThemeSwitcherProvider>
        </Provider>
    )
}

export default AppProviders;