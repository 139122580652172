import React from 'react';
import { FormattedMessage } from 'react-intl';
import utils from '@utils/utilities';
import SearchUtils, { filterNegativeQueriesByAvailableLevels } from '@MainSearch/SearchUtils';
import { getAllTerms, sanitizeTerm } from '@sagas/helpers/uboHelper';
import EntityViewApi from '@pages/EntityView/api/EntityViewApi';
import {
    CATEGORY_NAMES,
    PERSON_SEARCH,
    COMPANY_SEARCH,
    BATCH_UPLOAD_FILE_EXTENSIONS,
    UPLOAD_ENTITIES_WARNINGS_AND_ERRORS,
    NON_BLOCKING_UPLOAD_ENTITIES_WARNINGS,
    SCREENING_ENTITY_APIS_V1
} from '@constants';


const BatchUploadHelper = {
    validateFileName: (formatMessage, fileName) => {
        const reportFileNameValidation = utils.isReportFileNameValid(fileName);
        const isValid = !(reportFileNameValidation.invalidCharacters || reportFileNameValidation.invalidLength);

        let errorMessage = '';

        if (!isValid) {
            if (reportFileNameValidation.invalidLength)
                errorMessage = formatMessage(
                    { id: 'DownloadReportFileNameValidation.CharactersLimitCount' },
                    { count: reportFileNameValidation.maxLength }
                );

            if (reportFileNameValidation.invalidCharacters || reportFileNameValidation.currentLength === 0)
                errorMessage = formatMessage({ id: 'DownloadModalFileNameValidation.ValidationMessage' });
        }

        return {
            isValid,
            errorMessage,
        };
    },

    getFormattedFilename: (filename) => {
        const FILENAME_CHARS_LIMIT = 35;
        const fileExtension = filename.split('.').pop();
        const suffix = `... .${fileExtension}`;

        return utils.limitText(filename, FILENAME_CHARS_LIMIT - suffix.length, suffix);
    },

    buildBatchPostFilters: (sources, generalSettings, searchTypePreferences) => {
        const {
            sort,
            proximity,
            dateRange: { newsDateRange, companyDateRange, legalDateRange },
            excludeNewsWires,
            excludeNonBusinessNews,
            searchNamePartyEntity,
            availableQueryLanguages,
        } = generalSettings;
        const generalPostFilters = { sort, proximity };
        const { fuzzyThreshold, fuzzyOn } = searchTypePreferences[0];
        const { searchSpecifically } = searchTypePreferences[1];
        const regularCategoriesPostFilters = BatchUploadHelper.buildPostFiltersForRegularCategories(
            sources,
            generalPostFilters,
            companyDateRange.range,
            { dateRange: legalDateRange.range, searchNamePartyEntity },
            searchSpecifically,
            fuzzyThreshold,
            fuzzyOn
        );
        const searchTypePostfilters = searchTypePreferences.map((preferences, index) =>
            BatchUploadHelper.buildPostFiltersBasedOnSearchType(
                sources,
                generalPostFilters,
                { excludeNewsWires, excludeNonBusinessNews },
                preferences,
                newsDateRange.range,
                availableQueryLanguages,
                !!index
            )
        );

        return {
            regular: regularCategoriesPostFilters,
            people: searchTypePostfilters[0],
            company: searchTypePostfilters[1],
        };
    },

    submitBatchReport: async (payload, urlParams) => {
        const [data, error] = await EntityViewApi.uploadScreeningEntities(payload, urlParams);

        if (error) {
            const { batchAlertResponse } = error.response?.body || {};

            if (!batchAlertResponse) {
                return [null, UPLOAD_ENTITIES_WARNINGS_AND_ERRORS.uploadError];
            }
            const { availableAlerts, requestedAlerts, activeAlertsLimit } = batchAlertResponse;
            const overAlertLimitNumber = requestedAlerts > availableAlerts;

            if (overAlertLimitNumber && !!availableAlerts) {
                return [null, { message: UPLOAD_ENTITIES_WARNINGS_AND_ERRORS.pushingToAlertsLimit, value: { maximumNumber: activeAlertsLimit } }];
            } else if (overAlertLimitNumber && !availableAlerts) {
                return [null, { message: UPLOAD_ENTITIES_WARNINGS_AND_ERRORS.alertsLimitReached, value: { maximumNumber: activeAlertsLimit } }];
            }
            return [null, UPLOAD_ENTITIES_WARNINGS_AND_ERRORS.noWarning];
        } else {
            utils.showNotificationsMessage({
                messageText: 'BatchUpload.notification.spreadsheetIsProcessed',
                messageType: 'success',
            });

            return [data.batchId, null];
        }
    },
    buildEntityUploadSampleRow: (name, type) => {
        return { name, type };
    },

    buildEntities: (fileContent) =>
        fileContent.map((entity) => {
            const rowValues = Object.values(entity);

            return BatchUploadHelper.buildEntity(
                rowValues[0],
                rowValues[1],
            );
        }),

    buildEntity: (searchQuery, searchType) => {
        const isCompanyEntity = searchType.toLowerCase() === COMPANY_SEARCH;
        const searchQueryType = isCompanyEntity ? COMPANY_SEARCH : PERSON_SEARCH;
        const isCustomFuzzy = !!SearchUtils.isCustomFuzzy(searchQuery);
        const sanitizedQuery = utils.sanitizeSearchStringInput(searchQuery);
        const searchTerms = getAllTerms(sanitizedQuery)
            .map(sanitizeTerm)
            .filter((term) => term.length);
        const { query, prefilterQuery } = utils.extractQueryFromBooleanTerms(sanitizedQuery);
        const filteredPrefilterQuery = utils.removeFirstBooleanOperator(prefilterQuery);

        return {
            searchEntity: {
                searchQuery: query,
                searchQueryType,
                prefilterQuery: filteredPrefilterQuery,
                isCustomFuzzy,
                searchTerms,
            },
            displayName: sanitizedQuery,
        };
    },

    buildPostFiltersBasedOnSearchType: (
        sources,
        generalPostFilters,
        newsOnlyPreferences,
        preferences,
        dateRange,
        availableLanguages,
        isCompany
    ) => {
        const postFilters = [];
        const {
            customQuery,
            negativityLevel,
            newsSearchSources,
            customNewsQuery,
            lnCustomNewsQueries,
            adminCustomNewsQuery,
            fuzzyThreshold,
            fuzzyOn,
            searchSpecifically,
        } = preferences;
        const newsSource = utils.mapNewsSourceNameToKey(newsSearchSources);
        const commonPostFilters = {
            ...generalPostFilters,
            ...newsOnlyPreferences,
            newsSource,
            dateRange,
            fuzzyOn: !isCompany ? fuzzyOn : undefined,
            fuzzyThreshold: !isCompany && fuzzyOn ? fuzzyThreshold : undefined,
        };
        sources.forEach((source) => {
            switch (source.sourceType) {
                case CATEGORY_NAMES.NEGATIVE_NEWS: {
                    const negativeNewsPostFilters = customQuery
                        .filter(
                            (negativeQuery) =>
                                negativeQuery.checked && availableLanguages.indexOf(negativeQuery.language) > -1
                        )
                        .map((negativeQuery) => {
                            const { language, lowQueryString, mediumQueryString, highQueryString } = negativeQuery;
                            const negativeNewsQueries = filterNegativeQueriesByAvailableLevels(
                                { lowQueryString, mediumQueryString, highQueryString },
                                negativityLevel
                            );
                            return {
                                ...commonPostFilters,
                                category: source.sourceType,
                                contentLanguage: language,
                                newsQueries: [
                                    {
                                        contentLanguage: language,
                                        negativeNewsQueries,
                                    },
                                ],
                            };
                        });
                    postFilters.push(...negativeNewsPostFilters);
                    break;
                }

                case CATEGORY_NAMES.CUSTOM_NEWS: {
                    const customNewsQueries = [...customNewsQuery, ...lnCustomNewsQueries, ...adminCustomNewsQuery];
                    const customNewsPostFilters = customNewsQueries
                        .filter((query) => query.checked)
                        .map((q) => {
                            const { name, query, title } = q;

                            return {
                                ...commonPostFilters,
                                category: source.sourceType,
                                customNews: name,
                                newsQueries: [
                                    {
                                        name,
                                        query,
                                        title,
                                    },
                                ],
                            };
                        });
                    postFilters.push(...customNewsPostFilters);
                    break;
                }

                case CATEGORY_NAMES.NEWS:
                    postFilters.push({ ...commonPostFilters, category: source.sourceType });
                    break;

                case CATEGORY_NAMES.DNB:
                case CATEGORY_NAMES.ESG_RATINGS:
                    if (isCompany) {
                        postFilters.push({ ...generalPostFilters, category: source.sourceType });
                    }
                    break;

                case CATEGORY_NAMES.FINANCIAL_REPORT:
                    if (isCompany) {
                        postFilters.push({ ...generalPostFilters, category: source.sourceType, searchSpecifically });
                    }
                    break;
                default:
                    break;
            }
        });

        return postFilters;
    },

    buildPostFiltersForRegularCategories: (
        sources,
        generalPostFilters,
        companyDateRange,
        legalPostFilters,
        searchSpecifically,
        fuzzyThreshold,
        fuzzyOn
    ) => {
        const postFilters = [];
        const sharedPostFilters = {
            ...generalPostFilters,
            fuzzyOn,
            fuzzyThreshold: fuzzyOn ? fuzzyThreshold : undefined,
        };

        sources.forEach((source) => {
            switch (source.sourceType) {
                case CATEGORY_NAMES.COMPANY_RESOURCES: {
                    const directorsPostFilters = {
                        category: source.sourceType,
                        ...sharedPostFilters,
                        dateRange: companyDateRange,
                        searchSpecifically,
                    };
                    postFilters.push(directorsPostFilters);
                    break;
                }

                case CATEGORY_NAMES.CASES:
                case CATEGORY_NAMES.FEDERAL_DOCKETS:
                case CATEGORY_NAMES.STATE_DOCKETS:
                case CATEGORY_NAMES.AGENCY_DECISION:
                case CATEGORY_NAMES.VERDICTS:
                case CATEGORY_NAMES.LAW_REVIEWS:
                    postFilters.push({ ...sharedPostFilters, ...legalPostFilters, category: source.sourceType, fuzzyOn });
                    break;

                case CATEGORY_NAMES.BIOGRAPHICAL:
                case CATEGORY_NAMES.PEPS:
                case CATEGORY_NAMES.SANCTIONS_WATCHLIST:
                    postFilters.push({ ...sharedPostFilters, category: source.sourceType });
                    break;

                default:
                    break;
            }
        });

        return postFilters;
    },

    readExcelData: async (file) => {
        let fileContents = [];
        let fileErrors = {};
        let responseError = null;
        const formData = new FormData();
        formData.append('entitiesFile', file);

        const [response, error] = await EntityViewApi.uploadFile(SCREENING_ENTITY_APIS_V1.API_VALIDATE_FILE, formData, file.fileType);

        if (error?.response || !response?.data?.contents.length) {
            responseError = error?.response ?? 'Empty file';
            return { fileContents, fileErrors, responseError };
        }
        if (response?.data) {
            fileContents = response?.data?.contents?.map((eachRow) => utils.getExcelEntityObject(eachRow));
            fileErrors = response?.data?.errors;
        }

        return { fileContents, fileErrors, responseError };
    },

    validateFileContent: async (fileContent) => {
        const response = await BatchUploadHelper.readExcelData(fileContent);

        const { validationError } =
            BatchUploadHelper.validateUploadedFileMeta(response?.fileContents, response?.responseError) ??
            BatchUploadHelper.buildTheErrorsObject(response?.fileErrors);

        return [validationError, response?.fileContents];
    },

    validateUploadedFileMeta: (parsedSheet, responseError) => {
        if (!parsedSheet) {
            return { validationError: UPLOAD_ENTITIES_WARNINGS_AND_ERRORS.invalidFile };
        }
        if (responseError) {
            return { validationError: UPLOAD_ENTITIES_WARNINGS_AND_ERRORS.emptyFile };
        }
        return UPLOAD_ENTITIES_WARNINGS_AND_ERRORS.noWarning;
    },

    validateUploadedFileName: (fileName) => {
        const fileExtensionIndex = fileName.lastIndexOf('.');
        const fileNameWithoutExtension = fileName.replace(/\.[^.]+$/, '');
        const extension = fileName.slice(fileExtensionIndex);
        const { invalidCharacters: invalidFileName } = utils.isReportFileNameValid(fileNameWithoutExtension);
        if (invalidFileName) {
            return UPLOAD_ENTITIES_WARNINGS_AND_ERRORS.invalidFilename;
        }
        const invalidExtension = !BATCH_UPLOAD_FILE_EXTENSIONS.some(
            (fileExtension) => fileExtension === extension.toLowerCase()
        );
        if (invalidExtension) {
            return UPLOAD_ENTITIES_WARNINGS_AND_ERRORS.invalidFile;
        }
        return UPLOAD_ENTITIES_WARNINGS_AND_ERRORS.noWarning;
    },

    formatErrorMessagePerLine: (errorMessage: string, lineNumber: number, placeholderValues: any = {}) => ({
        messageId: 'BatchUpload.validationMessage.template',
        values: {
            errorMessage: <FormattedMessage id={errorMessage} values={placeholderValues} />,
            errorLine: (
                <FormattedMessage
                    id="BatchUpload.validationMessage.errorOnLine"
                    values={{
                        lineNumber,
                    }}
                />
            ),
        },
    }),

    buildTheErrorsObject: (fileErrors) => {
        if (!Object.keys(fileErrors).length) return { validationError: null };
        let errors = [];
        Object.keys(fileErrors).forEach((entityRowNum) => {
            fileErrors[entityRowNum].forEach((errorKey) => {
                const error = BatchUploadHelper.formatErrorMessagePerLine(errorKey, entityRowNum);
                errors.push(error);
            });
        });
        return { validationError: errors.length > 0 ? errors : null };
    },

    getUploadEntitiesWarningMessageBasedOnPostfiltersAndFileContents: (screeningEntityData, sources) => {
        const searchEntities = screeningEntityData.map((el) => el.searchEntity);

        const companyOnlyPostFilters = !utils.areContentTypesApplicableWithSearchType(
            sources,
            PERSON_SEARCH
        );
        const companyEntitiesCount = searchEntities.filter(
            (searchEntity) => searchEntity.searchQueryType === COMPANY_SEARCH
        ).length;
        const personEntitiesCount = searchEntities.length - companyEntitiesCount;

        return utils.getTypeOfUploadEntitiesWarningMessageBasedOnContentTypes(companyOnlyPostFilters, companyEntitiesCount, personEntitiesCount);
    },

    areThereAnyFileErrors: (validationMessages = {}) => Object.keys(validationMessages)
        // filter out the info messages, as they can't cause validation errors
        .filter((key) => key !== 'infoMessage')
        // concatenate all validation messages (errors, multiline errors and warnings) into a single array
        .reduce((a, e) => [...a, ...validationMessages[e]], [])
        // filter out the non-blocking validation messages
        .filter((validationMessage) => !NON_BLOCKING_UPLOAD_ENTITIES_WARNINGS.includes(validationMessage))
        // check if we have blocking error messages in the array 
        .length > 0,

    validateFileRequirements: async (e, entityUploadLimitReached, multipleFilesDragged, sources, userEmail) => {
        const file = e.target.files[0];

        // if no file is selected, return nothing
        if (!file) return;

        const fileName = file.name;
        const fileSize = file.size;

        try {
            const validationMessages = {
                errorMessage: [],
                warningMessage: [],
                infoMessage: [],
                multilineErrorMessage: []
            }
            let errorMessage = '';

            // validate filename
            errorMessage = BatchUploadHelper.validateUploadedFileName(fileName);
            errorMessage && validationMessages.errorMessage.push(errorMessage);

            // validate multiple files
            errorMessage = multipleFilesDragged ? UPLOAD_ENTITIES_WARNINGS_AND_ERRORS.multipleFiles : UPLOAD_ENTITIES_WARNINGS_AND_ERRORS.noWarning;
            errorMessage && validationMessages.warningMessage.push(errorMessage);

            // validate upload limit
            errorMessage = entityUploadLimitReached && UPLOAD_ENTITIES_WARNINGS_AND_ERRORS.entitiesLimit;
            errorMessage && validationMessages.warningMessage.push(errorMessage);

            // validate file content (invalid file, invalid structure or empty file)
            const [validationError, validatedFile] = await BatchUploadHelper.validateFileContent(file);
            if (typeof validationError === 'string') { validationMessages.warningMessage.push(validationError); }
            if (Array.isArray(validationError)) { validationMessages.multilineErrorMessage = [...validationError]; }

            // validate user postfilters with uploaded file
            if (validatedFile && !validationMessages.multilineErrorMessage.length) {
                const screeningEntityData = BatchUploadHelper.buildEntities(validatedFile, userEmail);
                const postFiltersRelatedMessage = BatchUploadHelper.getUploadEntitiesWarningMessageBasedOnPostfiltersAndFileContents(screeningEntityData, sources);

                errorMessage = postFiltersRelatedMessage;
                errorMessage === UPLOAD_ENTITIES_WARNINGS_AND_ERRORS.companyAndPersonWarning && validationMessages.infoMessage.push(errorMessage);
                errorMessage === UPLOAD_ENTITIES_WARNINGS_AND_ERRORS.personWarning && validationMessages.errorMessage.push(errorMessage);
            }

            if (BatchUploadHelper.areThereAnyFileErrors(validationMessages)) {
                return {
                    fileName: '',
                    fileSize: null,
                    fileContent: null,
                    isValid: false,
                    ...validationMessages
                }
            } else {
                return {
                    fileName,
                    fileSize: utils.getReadableFileSize(fileSize),
                    fileContent: validatedFile,
                    isValid: true,
                    ...validationMessages
                }
            }
        }

        catch (error) {
            console.log('Error selecting file', error);
        }
    },
}

export default BatchUploadHelper;