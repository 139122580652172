import React from 'react';
import { Loader } from 'semantic-ui-react';
import utils from '@utils/utilities';

const FilterItem = (props) => {
    return (
        <div className="category-list-item">
            <div className="custom-radio">
                <input
                    type="radio"
                    id={'radio-' + props.filter.category.key}
                    value={props.filter.category.key}
                    checked={!!props.checked}
                    onChange={props.handleSelectCategory}
                />
                <label htmlFor={'radio-' + props.filter.category.key} className="radio-label">
                    {props.filter.title}
                </label>
            </div>
            <div className={`category-count-number ${props.checked ? 'checked' : ''}`}>
                {props.filter.articlesResult.loaded ? (
                    utils.formatNumbersByUserLocale(props.language, props.filter.articlesResult.count)
                ) : (
                    <Loader size="mini" inline active />
                )}
            </div>
        </div>
    );
};

export default FilterItem;
