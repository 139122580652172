//@flow

import * as React from 'react';
import DateRangesContent from './DateRangesContent';
import { FormattedMessage } from 'react-intl';
import type { DateRanges } from './flow/Dates.typeGuards';

export const ALL_DATES: string = 'all';

type Props = {
    updateAdHocProperty: (property: string, value: any) => void,
    dateRanges: DateRanges,
    newsDateRange: string,
    legalDateRange: string,
    companyDateRange: string,
    userLocks: {
        categoriesDefaultDateRange: boolean,
        newsSearchesDateRange: boolean,
        companySource: boolean,
        legalSourceDefaultDateRange: boolean,
    },
    onChange?: (args: Object) => void,
    refreshPreferencesIfNeeded: () => void,
    areLegalSourcesAvailable: boolean,
    areCompanySourcesAvailable: boolean,
    isReadOnly: boolean,
};

type State = {
    showContent: boolean,
    newsDateRange: { range: string },
    legalDateRange: { range: string },
    companyDateRange: { range: string },
};

class DateRangesWrapper extends React.Component<Props, State> {
    contentRef: {
        current: null | HTMLDivElement,
    };

    state: State;

    constructor(props: Props) {
        super(props);

        this.state = {
            showContent: false,

            newsDateRange: { range: this.props.newsDateRange },
            legalDateRange: { range: this.props.legalDateRange },
            companyDateRange: { range: this.props.companyDateRange },
        };

        this.contentRef = React.createRef();
    }

    componentDidMount(): void {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentDidUpdate(prevProps: Props): void {
        if (
            prevProps.newsDateRange !== this.props.newsDateRange ||
            prevProps.legalDateRange !== this.props.legalDateRange ||
            prevProps.companyDateRange !== this.props.companyDateRange
        ) {
            this.setState({
                newsDateRange: { range: this.props.newsDateRange },
                legalDateRange: { range: this.props.legalDateRange },
                companyDateRange: { range: this.props.companyDateRange },
            });
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside = (event: MouseEvent): void => {
        if (
            this.contentRef &&
            this.contentRef.current &&
            !this.contentRef.current.contains((event.target: any)) &&
            this.state.showContent
        ) {
            this.setState(
                {
                    showContent: false,
                },
                () => {
                    if (this.props.onChange && typeof this.props.onChange === 'function') {
                        this.props.onChange({
                            newsDateRange: this.state.newsDateRange,
                            legalDateRange: this.state.legalDateRange,
                            companyDateRange: this.state.companyDateRange,
                        });
                    } else {
                        this.props.updateAdHocProperty('dateRages', this.props.dateRanges);
                        this.props.updateAdHocProperty('newsDateRange', this.state.newsDateRange);
                        this.props.updateAdHocProperty('legalDateRange', this.state.legalDateRange);
                        this.props.updateAdHocProperty('companyDateRange', this.state.companyDateRange);
                    }
                }
            );
        }
    };

    toggleContent = async (): Promise<void> => {
        if (typeof this.props.refreshPreferencesIfNeeded === 'function') {
            await this.props.refreshPreferencesIfNeeded();
        }

        this.setState((prevState: State) => ({
            showContent: !prevState.showContent,
        }));
    };

    handleDateChange = (type: string, date: string): void => {
        this.setState({
            [type]: { range: date },
        });
    };

    handleAllDatesChange = (date: string): void => {
        this.setState((prevState: State) => ({
            newsDateRange: this.props.userLocks.newsSearchesDateRange
                ? { range: prevState.newsDateRange.range }
                : { range: date },
            legalDateRange: this.props.userLocks.legalSourceDefaultDateRange
                ? { range: prevState.legalDateRange.range }
                : { range: date },
            companyDateRange: this.props.userLocks.companySource
                ? { range: prevState.companyDateRange.range }
                : { range: date },
        }));
    };

    render(): React.Node {
        const areAllDatesSetAsAll: boolean =
            this.state.companyDateRange.range === ALL_DATES &&
            this.state.newsDateRange.range === ALL_DATES &&
            this.state.legalDateRange.range === ALL_DATES;

        return (
            <div
                ref={this.contentRef}
                data-testid="dateRanges-dropdown-search-bar"
                className="dateRanges-dropdown-search-bar"
            >
                <div
                    tabIndex={0}
                    data-testid="dateRanges-dropdown-search-bar-header"
                    data-track="dateRanges-dropdown-search-bar-header"
                    onClick={this.toggleContent}
                    className={`dateRanges-dropdown-search-bar-header ${this.props.isReadOnly ? 'disabled' : ''}`}
                >
                    <span
                        data-testid="dateRanges-dropdown-search-bar-message"
                        className={`dateRanges-dropdown-button ${this.state.showContent ? 'selected' : '' } ${this.props.isReadOnly ? 'disabled' : ''}`}
                    >
                        <span className="dateRanges-dropdown-button__text">
                            {areAllDatesSetAsAll ? (
                                <FormattedMessage id={'SearchBarDateDropdown.selectedAllDatesButton'} />
                            ) : (
                                <FormattedMessage id={'SearchBarDateDropdown.selectedDatesButton'} />
                            )}
                        </span>
                    </span>
                    <span
                        data-testid="dateRanges-dropdown-search-bar-arrow"
                        className={`${
                            this.state.showContent ? 'la-TriangleUp ' : 'la-TriangleDown'
                        } dateRanges-dropdown-arrow`}
                    />
                </div>

                {this.state.showContent && (
                    <DateRangesContent
                        {...this.props}
                        adminLocks={{
                            all:
                                this.props.userLocks.categoriesDefaultDateRange ||
                                (this.props.userLocks.newsSearchesDateRange &&
                                    this.props.userLocks.companySource &&
                                    this.props.userLocks.legalSourceDefaultDateRange),
                            newsDateRange: {
                                value: this.props.userLocks.newsSearchesDateRange,
                                name: 'newsSearchesDateRange',
                            },
                            companyDateRange: {
                                value: this.props.userLocks.companySource,
                                name: 'companySource',
                            },
                            legalDateRange: {
                                value: this.props.userLocks.legalSourceDefaultDateRange,
                                name: 'legalSourceDefaultDateRange',
                            },
                        }}
                        newsDateRange={this.state.newsDateRange.range}
                        legalDateRange={this.state.legalDateRange.range}
                        companyDateRange={this.state.companyDateRange.range}
                        handleDateChange={this.handleDateChange}
                        handleAllDatesChange={this.handleAllDatesChange}
                        areLegalSourcesAvailable={this.props.areLegalSourcesAvailable}
                        areCompanySourcesAvailable={this.props.areCompanySourcesAvailable}
                    />
                )}
            </div>
        );
    }
}

export default (DateRangesWrapper: React.AbstractComponent<Props>);
