import React from 'react';
import { withToggleVisibility } from '../HOC/withToggleVisibility';
import ModalReorderableItem from './ModalReorderableItem';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import popupModelActions from '../redux/PopupModel.actions';
import { withDragAndDrop } from 'scripts/reusable/HOC/withDragAndDrop';
import { DRAG_AND_DROP } from '@constants';

class ModalReorderItems extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            overlayIsVisible: false,
            droppedMenuItemId: null,
            hoverIndex: null,
            currentIndex: null,
            canDrop: true,
            visibleMenu: null,
        };

        this.requestReorder = this.requestReorder.bind(this);
        this.setOverlay = this.setOverlay.bind(this);
        this.updateVisualDropTarget = this.updateVisualDropTarget.bind(this);
        this.removeVisualTarget = this.removeVisualTarget.bind(this);
    }

    UNSAFE_componentWillMount() {
        this.intervalId = null;
    }

    componentWillUnmount() {
        clearInterval(this.intervalId);
    }

    UNSAFE_componentWillReceiveProps() {
        this.setState({
            canDrop: true,
        });
    }

    setOverlay(overlayIsVisible) {
        this.setState({
            overlayIsVisible: overlayIsVisible,
        });
    }

    updateVisualDropTarget(currentIndex, hoverIndex) {
        let prevHoverIndex = this.state.hoverIndex;
        if ((hoverIndex === undefined || currentIndex === hoverIndex) && prevHoverIndex !== null) {
            this.setState({
                currentIndex: null,
                hoverIndex: null,
                canDrop: true,
            });
        }
        if (prevHoverIndex !== hoverIndex && Math.abs(currentIndex - hoverIndex)) {
            this.setState({
                currentIndex,
                hoverIndex,
                canDrop: true,
            });
        }
    }
    removeVisualTarget() {
        this.setState({
            canDrop: false,
        });
    }

    requestReorder(index, hoverIndex, currentElement) {
        const { items } = this.props;
        const droppedMenuItemId = currentElement.id || currentElement.item.id;

        let sortedItems = items.slice();
        while (index < 0) {
            index += sortedItems.length;
        }
        while (hoverIndex < 0) {
            hoverIndex += sortedItems.length;
        }
        if (hoverIndex >= sortedItems.length) {
            let k = hoverIndex - sortedItems.length;
            while (k-- + 1) {
                sortedItems.push(undefined);
            }
        }

        sortedItems.splice(hoverIndex, 0, sortedItems.splice(index, 1)[0]);
        this.updateModalReportsOrdering(sortedItems);

        this.setState({
            droppedMenuItemId,
            hoverIndex: null,
        });

        clearInterval(this.intervalId);
        this.intervalId = setTimeout(() => {
            this.setState({
                droppedMenuItemId: null,
            });
        }, 2500);
    }

    updateModalReportsOrdering = (orderedList) => {
        this.props.reorderModalSelectedReports(orderedList);
        this.props.updateReportListIDs(orderedList.map((item) => item.id));
    };

    setActiveMenu = (id) => {
        this.setState({ visibleMenu: id });
    };

    render() {
        const { connectDropTarget, dropTargetId, items } = this.props;
        const { droppedMenuItemId, hoverIndex, currentIndex, visibleMenu, canDrop } = this.state;
        const hasMenuOpen = visibleMenu !== null;

        return connectDropTarget(
            <ul className={`popup-modal__reorder-items ${hasMenuOpen ? 'with-menu-open' : ''}`}>
                {items.map((item, index) => (
                    <ModalReorderableItem
                        key={`reorderable-item-${index}`}
                        data-testid={'reorderable-item-test-id'}
                        index={index}
                        items={items}
                        currentItem={item}
                        dropTargetId={dropTargetId}
                        droppedMenuItemId={droppedMenuItemId}
                        requestReorder={this.requestReorder}
                        setOverlay={this.setOverlay}
                        showDropTarget={hoverIndex === (index || 0)}
                        insertAbove={hoverIndex < currentIndex}
                        updateVisualDropTarget={this.updateVisualDropTarget}
                        canDrop={canDrop}
                        visibleMenu={visibleMenu}
                        setActiveMenu={this.setActiveMenu}
                    />
                ))}
            </ul>
        );
    }
}

const mapDispatchToProps = function (dispatch) {
    return bindActionCreators(
        {
            reorderModalSelectedReports: popupModelActions.reorderSelectedReports,
            updateReportListIDs: popupModelActions.setReportListIds,
        },
        dispatch
    );
};

const dndHookInfo = {
    componentType: 'parent',
    accept: DRAG_AND_DROP.MODAL_REORDER_ITEMS_LIST,
};

export default compose(
    connect(null, mapDispatchToProps),
    withToggleVisibility,
    withDragAndDrop(dndHookInfo)
)(ModalReorderItems);

export { ModalReorderItems as TestModalReorderItems };
