import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { COMPANY_SOURCES_SEARCH_OPTION, FE_LOCK_NAMES } from '@constants';
import withAutosave from '../HOC/WithAutosave';
import withAdminLock from '@reusable/AdminLock/hoc/withAdminLock';

const SourcesSearchOptions = (props) => {
    let handleSearchOptionChange = (event) => {
        event.persist();
        props.handleAutosave(props.changeSourcesSearchOption, [event.target.value, props.userIsAdmin]);
    };

    return (
        <Fragment>
            <div className="label">
                <FormattedMessage id={'UserPreferences.CompanyCheck.searchSpecifically.label'} />
                {props.autoSaveMessage}
            </div>
            <div className="custom-radio">
                <input
                    id={`specificallyOption`}
                    value="specifically"
                    checked={props.searchSpecifically === COMPANY_SOURCES_SEARCH_OPTION.SPECIFICALLY}
                    onChange={handleSearchOptionChange}
                    name={`specificallyOption`}
                    type="radio"
                />
                <label htmlFor={`specificallyOption`} className="radio-label">
                    <FormattedMessage id={'UserPreferences.CompanyCheck.searchSpecifically.specifically'} />
                </label>
            </div>
            <div className="custom-radio">
                <input
                    id={`mentionsOption`}
                    value="mentions"
                    checked={props.searchSpecifically === COMPANY_SOURCES_SEARCH_OPTION.MENTIONS}
                    onChange={handleSearchOptionChange}
                    name={`mentionsOption`}
                    type="radio"
                />
                <label htmlFor={`mentionsOption`} className="radio-label">
                    <FormattedMessage id={'UserPreferences.CompanyCheck.searchSpecifically.mentions'} />
                </label>
            </div>
        </Fragment>
    );
};

SourcesSearchOptions.propTypes = {
    autoSaveMessage: PropTypes.object,
    searchSpecifically: PropTypes.oneOf([
        COMPANY_SOURCES_SEARCH_OPTION.SPECIFICALLY,
        COMPANY_SOURCES_SEARCH_OPTION.MENTIONS,
    ]),
    handleSearchOptionChange: PropTypes.func,
};

SourcesSearchOptions.defaultProps = {
    autoSaveMessage: {},
    searchSpecifically: COMPANY_SOURCES_SEARCH_OPTION.SPECIFICALLY,
};

export default withAutosave()(
    withAdminLock(
        FE_LOCK_NAMES.companySearchSpecifically,
        FE_LOCK_NAMES.masterLockNewsSearchesCompany,
        true
    )(SourcesSearchOptions)
);
