import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import withAutosave from '../HOC/WithAutosave';
import NewsSourcesDropdown from './NewsSourcesDropdown';

class NewsSourcesSelection extends Component {
    constructor(props) {
        super(props);

        this.updateNegativeNewsLanguage = this.updateNegativeNewsLanguage.bind(this);
    }

    updateNegativeNewsLanguage(event) {
        event.persist();
        this.props.handleAutosave(this.props.changeLanguageSelection, [this.props.segment, event.target.value]);
    }

    render() {
        return (
            <div className="user-preferences-category-container container-section__segment">
                <div className="user-preferences-category-container__row  container-section__segment__header">
                    <h3 className="user-preferences-category-container__topic">
                        <FormattedMessage id="UserPreferences_topic_newsSearches" />
                    </h3>
                </div>
                <div className="user-preferences-category-container__explanation">
                    <FormattedMessage id={'UserPreferences_topic_newsSearchesExplanation'} />
                </div>

                <div
                    className="user-preferences-category-container__news_searches_sources-wrapper"
                    data-track="user-preferences-category-container__news_searches_sources"
                >
                    <div className="user-preferences-category-container__note">
                        <FormattedMessage id={'UserPreferences_news_searches_sources_label'} />
                        {this.props.autoSaveMessage}
                    </div>
                    <NewsSourcesDropdown
                        onChangeHandler={this.updateNegativeNewsLanguage}
                        defaultSources={this.props.defaultSources}
                        disabledDropdown={this.props.disabledDropdown}
                        lockName={this.props.lockName}
                        isReadonly={this.props.isReadonly}
                        masterLock={this.props.masterLock}
                        userIsAdmin={this.props.userIsAdmin}
                        handleLockClick={this.props.handleLockClick}
                    />
                </div>
            </div>
        );
    }
}

export default withAutosave()(NewsSourcesSelection);
