import React from 'react';
import { COMPANY_SEARCH, PERSON_SEARCH } from '@constants';
import { FormattedMessage } from 'react-intl';
import { EDIT_SEARCH_URL } from 'scripts/constants';

class IconButtonSearchType extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        // whenever user switches to start page, the searchType should be set from userPreferences, not from the last selection (unless the user performed a search or edits an existing search)
        if (this.props.location?.pathname === EDIT_SEARCH_URL) {
            this.props.changeSearchType(this.props.searchType);
        } else {
            this.props.changeSearchType(this.props.userPreferencesSearchType);
        }
    }

    handleSearchType = (event) => {
        this.props.changeSearchType(event.target.name);
        this.props.updateAdHocSearchObject({});
    };

    render() {
        return (
            <div className="search-type">
                <div className="search-type-person">
                    <label
                        htmlFor="person"
                        className={`label-for-person ${this.props.searchType === PERSON_SEARCH ? 'label-active' : ''}`}
                    >
                        <FormattedMessage id="UserPreferences.leftSideNavigation.personCheck" />
                    </label>
                    <input
                        name={PERSON_SEARCH}
                        id="person"
                        type="button"
                        className={
                            this.props.searchType === PERSON_SEARCH
                                ? 'button-search-people active'
                                : 'button-search-people'
                        }
                        data-testid="search-person"
                        data-track="search-type-person"
                        onClick={this.handleSearchType}
                    ></input>
                </div>
                <div className="devider" />
                <div className="search-type-company">
                    <label
                        htmlFor="company"
                        className={`label-for-company ${
                            this.props.searchType === COMPANY_SEARCH ? 'label-active' : ''
                        }`}
                    >
                        <FormattedMessage id="UserPreferences.leftSideNavigation.companyCheck" />
                    </label>
                    <input
                        name={COMPANY_SEARCH}
                        type="button"
                        id="company"
                        className={
                            this.props.searchType === COMPANY_SEARCH
                                ? 'button-search-company active'
                                : 'button-search-company'
                        }
                        data-testid="search-company"
                        data-track="search-type-company"
                        onClick={this.handleSearchType}
                    ></input>
                </div>
            </div>
        );
    }
}

export default IconButtonSearchType;
