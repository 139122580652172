import React from 'react';
import { PREFERENCES_SIDE_NAVIGATION } from '@Main/Main.reducers';
import { FormattedMessage } from 'react-intl';
import { isNil } from 'lodash';

class LeftSideNavigation extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            top: 0,
        };
    }

    componentDidMount() {
        const element = this.button;
        let height = element.clientHeight;

        this.setState({
            top: height,
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.top !== this.props.top && prevState && prevState.top) {
            this.setState({
                top: 0,
            });
        }
    }

    render() {
        let navigationStyle;

        if (!isNil(this.props.top)) {
            navigationStyle = { top: this.state.top + this.props.top };
        }

        return (
            <div className="user-preferences-menu" style={navigationStyle}>
                {PREFERENCES_SIDE_NAVIGATION.map((navigationItem, index) => (
                    <div className="user-preferences-menu-item" key={navigationItem}>
                        <button
                            className={`user-preference-button ${
                                this.props.isActiveTab === navigationItem ? 'active' : ''
                            }`}
                            onClick={() => {
                                this.props.handleScrollToPage(navigationItem);
                            }}
                            ref={(ref) => (this.button = ref)}
                        >
                            <FormattedMessage id={`UserPreferences.leftSideNavigation.${navigationItem}`} />
                        </button>
                        {index !== PREFERENCES_SIDE_NAVIGATION.length - 1 ? <div className="horizontal-divider" /> : ''}
                    </div>
                ))}
            </div>
        );
    }
}

export default LeftSideNavigation;
