//@flow
import React from 'react';
import ReportBuilderUtils from '@pages/ReportBuilder/utils/ReportBuilderUtils';
import DownloadReportLink from './DownloadReportLink';
import { MODAL_TYPE, REPORT_ERROR_STATUSES, REPORT_SUCCESS_STATUSES, DOWNLOADABLE_REPORT_STATUSES } from '@constants';
import { FormattedMessage } from 'react-intl';
import type { Report, AvailableDownload } from '@ReportBuilder/redux/flow/ReportBuilder.type.guards';

type Props = {
    className: string,
    downloadMessage: {
        downloadAgain: string,
        downloadNow: string,
    },
    downloadReport: (event: SyntheticEvent<HTMLButtonElement>) => void,
    index: number,
    reportDeliveryAvailabilityDays: number,
    prReportDeliveryAvailabilityDays: number,
    report: Report,
    showDownloadReportPopup: (popupType: string) => void,
    showSpinner?: boolean,
};

const checkForEntititesWithErrors = (report: Report, lastReportDeliveryObject: AvailableDownload): boolean => {
    return (
        buildErrorStatuses.includes(lastReportDeliveryObject?.status) || buildErrorStatuses.includes(report.buildStatus)
    );
};

const buildErrorStatuses = Object.values(REPORT_ERROR_STATUSES);
const buildFinishedStatuses = Object.values({ ...REPORT_SUCCESS_STATUSES, ...REPORT_ERROR_STATUSES });
const downloadableReportsStatuses = Object.values(DOWNLOADABLE_REPORT_STATUSES);

const DownloadAction = (props: Props): React$Element<'div'> | null => {
    const {
        report,
        index,
        showDownloadReportPopup,
        downloadReport,
        reportDeliveryAvailabilityDays,
        prReportDeliveryAvailabilityDays,
        downloadMessage,
        className,
    } = props;

    if (report) {
        const isReportBatch = ReportBuilderUtils.isReportBatch(report.deliveryType);
        const isBuildAvailable = report.latestDocument && ReportBuilderUtils.isBuildValid(report);
        const isBuildFinished =
            report.availableDownloads &&
            report.availableDownloads.length > 0 &&
            report.latestDocument &&
            buildFinishedStatuses.includes(report.latestDocument.status);
        const reportDeliveryObject = report.reportDelivery;
        const lastReportDeliveryObject = reportDeliveryObject[reportDeliveryObject.length - 1];
        const isReportDownloadable =
            report.latestDocument && downloadableReportsStatuses.includes(report.latestDocument?.status);
        const hasBatchReportEntitiesWithError = checkForEntititesWithErrors(report, lastReportDeliveryObject);

        return (
            <div className={className} id="download-action">
                {isBuildAvailable && isBuildFinished && isReportDownloadable && (
                    <DownloadReportLink
                        report={report}
                        index={index}
                        downloadReport={downloadReport}
                        downloadMessage={downloadMessage}
                        reportDeliveryAvailabilityDays={reportDeliveryAvailabilityDays}
                        prReportDeliveryAvailabilityDays={prReportDeliveryAvailabilityDays}
                    />
                )}

                {isReportBatch && hasBatchReportEntitiesWithError && (
                    <div className="batch-report header-message" data-testid="header-message">
                        <div className="batch-entry_error-dot" />
                        <p className="report-list-download-expiration-message">
                            <FormattedMessage id="ReportBuilderPage_BackgroundMessage.batchProcessingFinishedWithErrors" />
                        </p>
                    </div>
                )}

                {((isBuildAvailable && !isBuildFinished && !isReportBatch) ||
                    (!isBuildAvailable && !isReportBatch)) && (
                    <DownloadButton
                        index={index}
                        title={report.title}
                        showDownloadReportPopup={showDownloadReportPopup}
                    />
                )}
            </div>
        );
    }
    return null;
};

type ButtonProps = {
    index: number,
    title: string,
    showDownloadReportPopup: (popupType: string) => void,
};
const DownloadButton = (props: ButtonProps): React$Element<'button'> => {
    const { index, title, showDownloadReportPopup } = props;
    const download = (e: SyntheticEvent<HTMLButtonElement>): void => {
        e.stopPropagation();
        showDownloadReportPopup(MODAL_TYPE.DOWNLOAD_REPORT);
    };

    return (
        <button
            className="button-primary-lg"
            id={'qa-report-download_' + index}
            title={title}
            onClick={download}
            data-track={`download-button-report-${index}`}
        >
            <FormattedMessage id="Report.downloadReport" />
        </button>
    );
};
export default DownloadAction;
