import { headerStyleObj, emptyCellStyleObj, cellStyleObj, loadingCellStyleObj } from './styleObjects';

// a helper function used to populate the rows of the grid table
export const getRows = (
    data,
    columnsOrder,
    columnMap,
    getRowStructure,
    shouldDisplay,
    isSnapshotVisible,
    emptyTableComponent,
    isLoading,
    handleToggleSelect,
    intl,
    extraProps,
    sortInfo
) => {
    let rows = [
        {
            rowId: 'header',
            cells: Object.getOwnPropertyNames(columnMap).map((column, index) => {
                let obj = {
                    type: 'batchHeader',
                    text: columnMap[columnsOrder[index]],
                    nonEditable: true,
                    style: headerStyleObj,
                    sort: sortInfo[index],
                    tableHasData: !!extraProps?.tableHasData,
                };
                return obj;
            }),
            height: 95,
        },
    ];
    if (!data.length && !isLoading) {
        emptyTableComponent &&
            rows.push({
                rowId: 'emptyTable',
                cells: Object.getOwnPropertyNames(columnMap).map(() => ({
                    type: 'emptyTable',
                    text: '',
                    nonEditable: true,
                    colspan: columnsOrder.length,
                    style: emptyCellStyleObj,
                    searchEntity: extraProps?.searchEntity,
                })),
                height: 500,
            });
    }
    if (isLoading && !data.length) {
        rows.push({
            rowId: 'loader',
            cells: [
                {
                    type: 'loader',
                    text: '',
                    nonEditable: true,
                    colspan: columnsOrder.length,
                    style: loadingCellStyleObj,
                },
            ],
            height: 62,
        });
    }
    if (data.length) {
        rows = [
            ...rows,
            ...data.map((entity, idx) => ({
                rowId: idx,
                cells: getRowStructure(
                    { ...entity, handleToggleSelect, idx },
                    shouldDisplay,
                    isSnapshotVisible,
                    columnsOrder,
                    intl,
                    extraProps
                )
                    .filter((columnCell) => Object.getOwnPropertyNames(columnMap).includes(columnCell.columnId))
                    .map((cell) => ({ ...cell, style: cellStyleObj(idx) })),
                height: 62,
            })),
        ];
    }
    if (isLoading && data.length > 24) {
        rows.push({
            rowId: 'loader',
            cells: [
                {
                    type: 'loader',
                    text: '',
                    nonEditable: true,
                    colspan: columnsOrder.length,
                    style: loadingCellStyleObj,
                },
            ],
            height: 62,
        });
    }
    return rows;
};

// a helper function used to reorder arbitrary arrays
export const reorderArray = (arr, idxs, to) => {
    const movedElements = arr.filter((_, idx) => idxs.includes(idx));
    const targetIdx = Math.min(...idxs) < to ? (to += 1) : (to -= idxs.filter((idx) => idx < to).length);
    const leftSide = arr.filter((_, idx) => idx < targetIdx && !idxs.includes(idx));
    const rightSide = arr.filter((_, idx) => idx >= targetIdx && !idxs.includes(idx));
    return [...leftSide, ...movedElements, ...rightSide];
};

export const defaultGetSelectedName = (row) => row.name;

export const isRowSelected = (selection, row) => !!selection.selectedItems.find((item) => item.id === row.id);

export const byKey = (item) => item.id;

export const getNumberOfAllSelectedEntities = (tableData, selectedItemsList, entityViewCount) => {
    const displayedEntitiesCount = tableData.length;
    const selectedItemsCount = selectedItemsList.length;
    return entityViewCount - (displayedEntitiesCount - selectedItemsCount);
};
