import React from 'react';
import utils from '@utils/utilities';
import * as DilProps from '@utils/props';
import { DefaultError } from '../components/defaults';

const hasError =
    (ErrorComponent = DefaultError) =>
    (TableComponent) => {
        const hasErrorComponent = (props) =>
            props.data.hasError ? <ErrorComponent {...props} /> : <TableComponent {...props} />;

        hasErrorComponent.displayName = `hasError(${utils.getDisplayName(TableComponent)})`;

        hasErrorComponent.propTypes = {
            data: DilProps.TableData.isRequired,
        };

        return hasErrorComponent;
    };

export default hasError;
