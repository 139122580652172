import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { debounce } from 'lodash';
import { DEBOUNCE_SEARCH_INPUT, MULTIPLE_ENTITIES_SEARCH_CHARACTER_LIMITATION } from '@constants';

const SearchInput = ({
    disabled,
    searchEntity,
    setSearchEntity,
    placeholderIntlId,
    handleSelectAllEntities,
    extraProps,
}) => {
    const [value, setValue] = useState('');
    const intl = useIntl();

    useEffect(() => {
        // make a search request while updating the search value
        if (value && value.length >= MULTIPLE_ENTITIES_SEARCH_CHARACTER_LIMITATION) {
            debouncedChangeHandler(value);
        }
    }, [value]);

    useEffect(() => {
        if (!searchEntity.length) {
            setValue('');
        }
    }, [searchEntity]);

    const clearSelection = () => {
        handleSelectAllEntities();
        extraProps?.setSelection({
            selectedItems: [],
            actions: {},
        });
    };

    const handleSearch = (searchTerm) => {
        if (searchTerm.length === 0) {
            clearSelection();
        }
        setSearchEntity(searchTerm);
    };

    const debouncedChangeHandler = useMemo(() => {
        return debounce(handleSearch, DEBOUNCE_SEARCH_INPUT);
    }, []);

    function handleOnChange(e) {
        let value = e.target.value;
        setValue(value);
        debouncedChangeHandler(value);
    }


    const resetInputField = () => {
        setValue('');
        setSearchEntity('');
        clearSelection();
    };

    return (
        <div className="filter-search-box">
            <input
                id="multiple-entities-search-input"
                type="text"
                placeholder={intl.formatMessage({ id: placeholderIntlId }, intl)}
                value={value}
                onChange={handleOnChange}
                className="text-field-standard-sm"
                disabled={disabled}
            />

            {value.length ? (
                <button className="la-ClearSearch" onClick={resetInputField} data-testid={'clear-search-button'} />
            ) : (
                <button
                    className='la-Search'
                    data-testid={'search-submit-button'}
                    disabled
                />
            )}
        </div>
    );
};

export default SearchInput;
