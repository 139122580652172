// @flow
import * as React from 'react';
import GoogleTranslateWithoutI18n from '@reusable/GoogleTranslate/GoogleTranslate';
import ReactTooltip from 'react-tooltip';
import { Link } from 'react-router';
import { Loader } from 'semantic-ui-react';
import { withEditAlertContext } from '@contexts/EditAlert';
import PopupModel from '@reusable/PopupModal/PopupBuilder';
import {
    CATEGORY_NAMES,
    DOWNLOAD_DEFAULT_FILENAME,
    MODAL_TYPE,
    SELECTED_DROPDOWN_RESULTS_LIST,
    SORT_OPTIONS,
} from '@constants';
import formatRichMessage from '@utils/formatRichMessage';
import reduxStore from '@reduxStore';
import popupModelActions from '@reusable/PopupModal/redux/PopupModel.actions';
import utils from '@utils/utilities';
import SelectedDocumentsDropdown from './SelectedDocumentsDropdown';
import ArticlesUtils from '../ArticlesUtils';
import { FormattedMessage, injectIntl } from 'react-intl';
import categoryUtils, { getCategoryOrder, withCustomNewsTranslation } from '@utils/categoryUtils';
import { withContentSourceFilter } from '@utils/utilities';
import { withAppContext } from '@utils/contexts';
import { Fragment } from 'react';
import NegativeNewsResultsGraph from './negativeNewsVisualisations/resultsList/NegativeNewsResultsGraphContainer';
import type {
    ArticleListType,
    ContextType,
    GeneralSettingsType,
    PostFiltersConfigType,
    SearchParamsType,
    SearchResultsType,
} from './typeGuards/ResultsList.typeGuards';
import { compose } from 'redux';
import type { UserType } from '@sagas/investigationTypeGuards/investigationSelectors.type.guards';
import type { ContentOptions } from '@reusable/PopupModal/Components/ModalContentOptions';
import type { CurrentReport } from '@ReportBuilder/redux/flow/ReportBuilder.type.guards';
import type { AdHocSearchType } from '@reusable/AdHocSearch/flow/AdHocSearch.typeGuards';
import ArticleActionWrapper from './ArticleActionWrapper';

type Props = {
    editingAlert?: Object | boolean,
    articleType: string,
    uploadingDocuments: Object,
    changeSorting: (event: SyntheticEvent<HTMLElement>) => void,
    postFiltersConfig: PostFiltersConfigType,
    searchResults: SearchResultsType,
    newsSource: string | AdHocSearchType,
    sort: string,
    searchParams: SearchParamsType,
    useNewResearchSummary: boolean,
    generalSettings: GeneralSettingsType,
    selectedArticlesCount: number,
    user: UserType,
    selectedArticles: Object,
    articleList: ArticleListType,
    contentsOptions: ContentOptions,
    showDeliveryOptions: boolean,
    useNegativeNewsVisualisationsFeature?: boolean,
    isTrendsExpanded: boolean,
    report: CurrentReport,
    language: string,
    checkAllArticleHandler: () => void,
    selectAllCheckboxState: boolean,
    selectedDuplicates?: Object,
    setSelectedArticles: (arg: Object, arg2: string, arg3: boolean) => void,
    setSelectedDuplicates: (arg: Object, arg2: string, arg3: boolean) => void,
    clearSelectedArticles: (arg: Array<string>) => void,
    clearSelectedDuplicates: (args: Object) => void,
    clearSelectedAlerts?: (arg: Array<string>) => void,
    clearSelectedAlert?: () => void,
    isSearchComplete: boolean,
    onNegativeNewsTimelineChange: () => void,
    onNegativityLevelChange: () => void,
    companyNegativityLevel: Array<string>,
    personNegativityLevel: Array<string>,
    isSearchRerun?: boolean,
    prevPath: string,
    updatePrevPath: () => void,
    totalArticleCount: {
        count: number,
        formatted: string,
    },
    isHistoryCategoryCurrentOne: boolean,
    onNegativeTermsClick: () => void,
};

type InjectedProps = {|
    +intl: Object,
    context: ContextType,
|};

class ArticleActions extends React.Component<Props & InjectedProps> {
    constructor(props: Props & InjectedProps) {
        super(props);
    }

    documentUploadComplete = (): boolean => {
        let category = this.props.articleType;

        if (categoryUtils.isExtended(category)) {
            category = categoryUtils.getParent(category);
        }
        let uploadingArticles = this.props.uploadingDocuments[category] || [];
        return uploadingArticles.length === 0;
    };

    changeSort = (event: SyntheticEvent<HTMLElement>): void => {
        this.props.changeSorting(event);
    };

    showPopup = (event: SyntheticEvent<HTMLButtonElement>): void => {
        const singleSource = utils.isSingleSourceSearch(this.props.postFiltersConfig[this.props.articleType]);
        const popupType = event.currentTarget.value;
        const postFilters = this.props.searchResults[this.props.articleType].postFilters;
        const isCustomNewsSource = categoryUtils.isCustomNewsSource(this.props.articleType);
        const categoryName = isCustomNewsSource
            ? withCustomNewsTranslation(this.props.articleType, this.props.searchParams.searchType)
            : categoryUtils.getCategoryName(this.props.articleType);

        if (categoryUtils.isNewsSource(this.props.articleType)) {
            postFilters.newsSource = utils.mapNewsSourceNameToKey(this.props.newsSource);
        }

        const currentDate = new Date();
        const sortStrategy =
            typeof this.props.sort === 'undefined' || this.props.sort === SORT_OPTIONS.DEFAULT_SORT_OPTION
                ? SORT_OPTIONS.DEFAULT_SORT_OPTION
                : this.props.sort;
        const fileNameExtension = this.props.searchParams.query + '_';
        const fileName =
            DOWNLOAD_DEFAULT_FILENAME +
            utils.formatReportFileName(fileNameExtension) +
            utils.formatFileName(categoryName, isCustomNewsSource) +
            '_' +
            utils.formatReportDateWithtTimezoneOffset(currentDate.getTime());
        const categoryConfig = withContentSourceFilter().extendConfig(this.props.articleType);
        const contentSource = this.props.searchResults[this.props.articleType].contentSource;
        //TO DO: Temporary fix, this should be sent only for old rs flow reports
        const researchSummary = ArticlesUtils.getReportSummary();
        const categoryOrder = this.props.useNewResearchSummary
            ? getCategoryOrder(this.props.generalSettings.contentTypes)
            : undefined;
        const selectedArticles = this.props.selectedArticlesCount;
        const maxNumberOfArticles = this.props.context.maxNumberOfArticles;
        const buttonIsDisabled = selectedArticles > maxNumberOfArticles;
        let warningMessage;

        if (selectedArticles > maxNumberOfArticles) {
            warningMessage = formatRichMessage(
                { id: 'PopupBuilder.warning.tooManyDocumentsSelected' },
                this.props.intl,
                { count: maxNumberOfArticles }
            );
        } else {
            warningMessage = null;
        }

        let popupModel = new PopupModel.Builder(popupType)
            .setPopupProperty('isVisible', true)
            .setPopupProperty('fileName', fileName)
            .setPopupProperty('emailAddress', this.props.user.email)
            .setPopupProperty('articles', this.props.selectedArticles[this.props.articleType] || [])
            .setPopupProperty('useNewResearchSummary', this.props.useNewResearchSummary)
            .setPopupProperty('researchSummary', researchSummary)
            .setPopupProperty('categoryOrder', categoryOrder)
            .setPopupProperty('totalArticlesCount', this.props.articleList.count)
            .setPopupProperty('searchQuery', utils.sanitizeSearchStringInput(this.props.searchParams.query))
            .setPopupProperty('searchQueryType', this.props.searchParams.searchType)
            .setPopupProperty('prefilterQuery', this.props.searchParams.prefilterQuery)
            .setPopupProperty('postFilters', postFilters)
            .setPopupProperty('articleType', categoryConfig ? categoryConfig.category : this.props.articleType)
            .setPopupProperty('sortStrategy', sortStrategy)
            .setPopupProperty('originalArticleType', this.props.articleType)
            .setPopupProperty('maxNumberOfArticles', maxNumberOfArticles)
            .setPopupProperty('buttonIsDisabled', buttonIsDisabled)
            .setPopupProperty('warning', warningMessage)
            .setPopupProperty('contentsOptions', this.props.contentsOptions)
            .setPopupProperty('showDeliveryOptions', this.props.showDeliveryOptions)
            .setPopupProperty('singleSource', singleSource);

        //TODO: should pass the full category to take advantage of categoryUtil extend functionality
        if (
            categoryConfig &&
            (categoryConfig.category === CATEGORY_NAMES.NEGATIVE_NEWS ||
                categoryConfig.category === CATEGORY_NAMES.CUSTOM_NEWS)
        ) {
            popupModel.setPopupProperty('contentLanguage', categoryConfig.contentLanguage);
            popupModel.setPopupProperty(
                'contentSource',
                categoryConfig.contentSource ? categoryConfig.contentSource : contentSource
            );
            popupModel.setPopupProperty('customNews', categoryConfig.customNews ? categoryConfig.customNews : null);
        }

        popupModel.build();

        reduxStore.dispatch(popupModelActions.setPopupModel(popupModel));
    };

    render(): React.Node {
        const { useNegativeNewsVisualisationsFeature, isTrendsExpanded } = this.props;
        const negativeNewsBuckets =
            this.props.searchResults[this.props.articleType] && this.props.searchResults[this.props.articleType].buckets
                ? this.props.searchResults[this.props.articleType].buckets
                : {};
        const topNegativeTerms =
            this.props.searchResults[this.props.articleType] &&
            this.props.searchResults[this.props.articleType].topNegativeTerms
                ? this.props.searchResults[this.props.articleType].topNegativeTerms
                : [];
        const reportLink =
            '/report-builder' +
            (this.props.report && this.props.report.reportId === null
                ? ''
                : '?reportId=' + this.props.report.reportId + '&category=' + this.props.articleType);
        const shouldDisplayNegativeNewsVisualisation =
            useNegativeNewsVisualisationsFeature &&
            categoryUtils.isChildOf(CATEGORY_NAMES.NEGATIVE_NEWS, this.props.articleType) &&
            !this.props.searchResults[this.props.articleType].hasError &&
            this.props.totalArticleCount.count !== 0;
        const actionButtonsDisabled = false;

        return (
            <div className="notranslate sticky">
                <ReactTooltip
                    type="light"
                    event="mouseenter"
                    eventOff="mouseleave"
                    border={true}
                    effect="solid"
                    className="tooltips"
                    place="bottom"
                />
                <div className="articles-list-general-header">
                    <h4>
                        {this.props.totalArticleCount.count ? (
                            <Fragment>
                                {this.props.totalArticleCount.formatted + ' '}
                                {+this.props.totalArticleCount.count === 1 ? (
                                    <FormattedMessage id="ArticleAction.singular.resultsCount" />
                                ) : (
                                    <FormattedMessage id="ArticleAction.plural.resultsCount" />
                                )}
                            </Fragment>
                        ) : (
                            <FormattedMessage id="ArticleAction.zeroResultsCount" />
                        )}
                    </h4>
                    {!this.props.context.isUserMIP && (
                        <span className="report-button-wrapper" data-track="report-button">
                            <Link
                                className="report-button"
                                to={reportLink}
                                onClick={() => this.props.updatePrevPath(this.props.prevPath)}
                            >
                                <span>
                                    <FormattedMessage id="ArticleAction.yourReport" />
                                </span>
                            </Link>
                            {this.documentUploadComplete() ? (
                                <span className="report-articles-circle">
                                    <span className="report-articles-count">
                                        {utils.formatNumbersByUserLocale(
                                            this.props.language,
                                            this.props.report.articlesSnippets.length
                                        )}
                                    </span>
                                </span>
                            ) : (
                                <span className="ui-spinner-count">
                                    <Loader size="medium" inline="centered" inverted active />
                                </span>
                            )}
                        </span>
                    )}
                </div>
                {shouldDisplayNegativeNewsVisualisation && (
                    <NegativeNewsResultsGraph
                        isTrendsExpanded={isTrendsExpanded && this.props.showTrendsGraphTemporarily}
                        language={this.props.language}
                        negativeNewsBuckets={negativeNewsBuckets}
                        onNegativeNewsTimelineChange={this.props.onNegativeNewsTimelineChange}
                        topNegativeTerms={topNegativeTerms}
                        onNegativityLevelChange={this.props.onNegativityLevelChange}
                        companyNegativityLevel={this.props.companyNegativityLevel}
                        personNegativityLevel={this.props.personNegativityLevel}
                        searchType={this.props.searchParams.searchType}
                        isHistoryCategoryCurrentOne={this.props.isHistoryCategoryCurrentOne}
                        onNegativeTermsClick={this.props.onNegativeTermsClick}
                        disableTrendsGraphCloseOnScroll={this.props.disableTrendsGraphCloseOnScroll}
                        toggleShowTrendsGraphTemporarily = {this.props.toggleShowTrendsGraphTemporarily}
                    />
                )}
                <div className="article-actions">
                    <span className="left-actions">
                        <div className="select-all-checkbox">
                            <div className="custom-checkbox">
                                <input
                                    type="checkbox"
                                    id="results-check-all"
                                    onChange={this.props.checkAllArticleHandler}
                                    checked={this.props.selectAllCheckboxState}
                                    disabled={this.props.articleList.count === 0 || actionButtonsDisabled}
                                    value={this.props.articleType}
                                />
                                <label htmlFor="results-check-all" className="checkbox-label" />
                            </div>
                        </div>
                        <SelectedDocumentsDropdown
                            selectedArticles={this.props.selectedArticles}
                            selectedDuplicates={this.props.selectedDuplicates}
                            selectedCount={this.props.selectedArticlesCount}
                            articleType={this.props.articleType}
                            setSelectedArticles={this.props.setSelectedArticles}
                            setSelectedDuplicates={this.props.setSelectedDuplicates}
                            clearSelectedArticles={this.props.clearSelectedArticles}
                            clearSelectedDuplicates={this.props.clearSelectedDuplicates}
                            clearSelectedAlerts={this.props?.clearSelectedAlerts}
                            clearSelectedAlert={this.props.clearSelectedAlert}
                            dropdownType={SELECTED_DROPDOWN_RESULTS_LIST}
                        />

                        <div data-tack="delivery-actions" className="delivery-actions">
                            {actionButtonsDisabled && (
                                <ReactTooltip
                                    id="disabled-action"
                                    multiline={true}
                                    place="bottom"
                                    type="light"
                                    border={true}
                                    effect="solid"
                                    event="mouseenter"
                                    eventOff="mouseleave"
                                    className="tooltips create-alert-disabled"
                                />
                            )}
                            <ArticleActionWrapper isDisabled={actionButtonsDisabled}>
                                <button
                                    data-tip={formatRichMessage(
                                        { id: 'ArticleAction.printThisSearch' },
                                        this.props.intl
                                    )}
                                    className="icon-box"
                                    value={MODAL_TYPE.PRINT_RESULT_LIST}
                                    disabled={!this.props.isSearchComplete || actionButtonsDisabled}
                                    onClick={this.showPopup}
                                    data-testid={MODAL_TYPE.PRINT_RESULT_LIST}
                                >
                                    <div className="la-Print" />
                                </button>
                            </ArticleActionWrapper>
                            <ArticleActionWrapper isDisabled={actionButtonsDisabled}>
                                <button
                                    data-tip={formatRichMessage(
                                        { id: 'ArticleAction.emailThisSearch' },
                                        this.props.intl
                                    )}
                                    className="icon-box"
                                    disabled={
                                        !this.props.isSearchComplete ||
                                        (this.props.context && this.props.context.isUserAnonymized) ||
                                        actionButtonsDisabled
                                    }
                                    value={MODAL_TYPE.EMAIL_RESULT_LIST}
                                    onClick={this.showPopup}
                                    data-testid={MODAL_TYPE.EMAIL_RESULT_LIST}
                                >
                                    <div className="la-Email" />
                                </button>
                            </ArticleActionWrapper>
                            <ArticleActionWrapper isDisabled={actionButtonsDisabled}>
                                <button
                                    data-tip={formatRichMessage(
                                        { id: 'ArticleAction.downloadThisSearch' },
                                        this.props.intl
                                    )}
                                    className="icon-box"
                                    disabled={!this.props.isSearchComplete || actionButtonsDisabled}
                                    value={MODAL_TYPE.DOWNLOAD_RESULT_LIST}
                                    onClick={this.showPopup}
                                    data-testid={MODAL_TYPE.DOWNLOAD_RESULT_LIST}
                                >
                                    <div className="la-Download" />
                                </button>
                            </ArticleActionWrapper>
                        </div>
                        {!this.props.context.isUserMIP && (
                            <ArticleActionWrapper
                                isDisabled={actionButtonsDisabled}
                                customClass={'actionWrapperBorder'}
                            >
                                <button
                                    data-tip={formatRichMessage({ id: 'ArticleAction.addToReport' }, this.props.intl)}
                                    className="icon-box"
                                    disabled={!this.props.isSearchComplete || actionButtonsDisabled}
                                    value={MODAL_TYPE.ADD_TO_REPORT}
                                    onClick={this.showPopup}
                                >
                                    <div className="la-AddToReport" />
                                </button>
                            </ArticleActionWrapper>
                        )}
                    </span>
                    <span className="right-actions">
                        <div className="google-translate-dropdown">
                            {!reduxStore.getState().user.isAnonymized && <GoogleTranslateWithoutI18n />}
                        </div>
                        {!categoryUtils.isDnbCategory(this.props.articleType) ? (
                            <div className="sort-box" data-track="sort-box">
                                <span>
                                    <FormattedMessage id="ArticleAction.sortBy" />:
                                </span>
                                <select
                                    disabled={this.props.articleList.count === 0}
                                    name={this.props.articleType}
                                    value={
                                        typeof this.props.sort === 'undefined' ||
                                        this.props.sort === SORT_OPTIONS.DEFAULT_SORT_OPTION
                                            ? SORT_OPTIONS.DEFAULT_SORT_OPTION
                                            : this.props.sort
                                    }
                                    onChange={(event) => {
                                        this.props.changeSorting(event);
                                    }}
                                >
                                    <option value={SORT_OPTIONS.DEFAULT_SORT_OPTION}>
                                        {formatRichMessage({ id: 'ArticleAction.sortByRelevance' }, this.props.intl)}
                                    </option>
                                    <option value={SORT_OPTIONS.SORT_OPTION_NEWEST}>
                                        {formatRichMessage({ id: 'ArticleAction.sortByNewestFirst' }, this.props.intl)}
                                    </option>
                                    <option value={SORT_OPTIONS.SORT_OPTION_OLDEST}>
                                        {formatRichMessage({ id: 'ArticleAction.sortByOldestFirst' }, this.props.intl)}
                                    </option>
                                </select>
                            </div>
                        ) : null}
                    </span>
                </div>
            </div>
        );
    }
}

export default (compose(
    injectIntl,
    withEditAlertContext,
    withAppContext
)(ArticleActions): React.AbstractComponent<Props>);
