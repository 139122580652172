import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Loader } from 'semantic-ui-react';
import { MODAL_TYPE, CATEGORY_NAMES } from '@constants';
import { FormattedMessage } from 'react-intl';
import { NO_DOCUMENTS_OF_INTEREST } from '@constants';
import utils from '@utils/utilities';
import UboDocumentReportView from '@pages/UboDocoument/UboDocumentReportView';
import withDisclaimer from '@pages/MainSearch/HOC/withDisclaimer';

class ModalPreviewArticle extends Component {
    static propTypes = {
        article: PropTypes.object,
        popupType: PropTypes.string,
    };
    static defaultProps = {
        article: null,
    };

    render() {
        if (this.props?.article?.category === CATEGORY_NAMES.ESG_RATINGS) {
            return null;
        }

        if (this.props.isVisible) {
            if (
                this.props.article &&
                (this.props.article.documentId === '-1' ||
                    this.props.article.documentId === NO_DOCUMENTS_OF_INTEREST ||
                    this.props.article.publicRecordsNoDocumentsFound)
            ) {
                return null;
            } else if (this.props.article === null) {
                return this.props.isUboArticle ? (
                    <div className="preview-article-box">
                        <FormattedMessage id={'UboSingleDocumentView.ErrorMessage.documentNotFound.body'} />
                    </div>
                ) : (
                    <div className="preview-article-box">
                        <FormattedMessage id={'SingleDocumentView.ErrorMessage.documentNotFound.body'} />
                    </div>
                );
            } else if (utils.isUboArticle(this.props.article)) {
                return (
                    <div className={'preview-article-box'}>
                        <UboDocumentReportView uboArticle={this.props.article} />
                    </div>
                );
            } else if (this.props.article) {
                return (
                    <div
                        className={
                            'preview-article-box ' +
                            (this.props.popupType === MODAL_TYPE.EDIT_ARTICLE_NOTE ? 'article-edit-note' : '')
                        }
                    >
                        <div className="preview-article-box-header">
                            {this.props.disclaimerMessage}
                            {this.props.article.logo && (
                                <img className="preview-article-box-header-logo" src={this.props.article.logo} />
                            )}
                            <h2
                                className="preview-article-box-header-title"
                                dangerouslySetInnerHTML={{ __html: this.props.article.title }}
                            />
                            {this.props.article.source && (
                                <div className="preview-article-box-header-subTitle">
                                    <span className="preview-article-box-header-source">
                                        {this.props.article.source}
                                    </span>
                                    <span className="vertical-divider"></span>
                                    <span className="preview-article-box-header-date">{this.props.article.date}</span>
                                </div>
                            )}
                        </div>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: this.props.article.content || this.props.article.fullContent,
                            }}
                        />
                    </div>
                );
            } else {
                return (
                    <div className={'preview-article-box'}>
                        <Loader size={'large'} inline="centered" active />
                    </div>
                );
            }
        } else {
            return null;
        }
    }
}

export { ModalPreviewArticle as TestModalPreviewArticle };
export default withDisclaimer(ModalPreviewArticle);
