import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as BannerInfoIcon } from '../../../../assets/icons/BannerInfoIcon.svg';
import { ReactComponent as BannerInfoIconDark } from '../../../../assets/icons/BannerInfoIconDark.svg';

const ModalEntitySelectFilters = ({ isDarkMode }) => {
    return (
        <div className='popup-modal__columns-list'>
            <div className={`embedded-message info ${isDarkMode ? 'dark-mode' : ''}`}>
                <div className="embedded-message__icon">
                    {isDarkMode ? <BannerInfoIconDark /> : <BannerInfoIcon />}
                </div>
                <div className="embedded-message__message">
                    <FormattedMessage id="BatchScreening.popup.editSearch.disclaimer" />
                </div>
            </div>
        </div>
    );
};

export default ModalEntitySelectFilters;
