import * as React from 'react';
import BoxErrorMessage from '@reusable/ErrorMessage/BoxErrorMessage';
import { DefaultLoader } from '@reusable/Table/components/defaults';
import EsgRatingsDocumentView from '@EsgRatings/EsgRatingsDocumentView';
import EsgRatingsDocumentHeader from '@EsgRatings/EsgRatingsDocumentHeader';

const ElasticSingleDocumentContent = ({
    disclaimerMessage,
    article,
    setArticleTitleRef,
    setArticleContentRef,
    hasError,
    originalArticleInfo,
    searchDocAccessEvent,
    isEsgRatingsCategory,
}) => {
    const { logo, title, source, date, content, unhighlightedTitle } = article;

    return (
        <React.Fragment>
            {disclaimerMessage}
            {logo && (
                <img
                    className="single-article-content-logo"
                    src={logo}
                    data-testid={'single-article-logo-testid'}
                    alt="logo"
                />
            )}
            {isEsgRatingsCategory && <EsgRatingsDocumentHeader content={content} />}
            <h2
                className="single-article-title"
                ref={setArticleTitleRef}
                dangerouslySetInnerHTML={{ __html: isEsgRatingsCategory ? unhighlightedTitle : title }}
                data-testid={'single-article-title-testid'}
            />
            <div className="single-article-subTitle" data-testid={'single-article-subTitle-testid'}>
                <span className="single-article-source">{source}</span>
                <span className="single-article-date">{date}</span>
            </div>
            {hasError ? (
                <BoxErrorMessage messageId={'SingleArticleContent.Error'} data-testid={'box-error-testid'} />
            ) : content === null ? (
                <DefaultLoader data-testid={'article-loader-testid'} />
            ) : isEsgRatingsCategory ? (
                <EsgRatingsDocumentView
                    content={content}
                    originalArticleInfo={originalArticleInfo}
                    searchDocAccessEvent={searchDocAccessEvent}
                />
            ) : (
                <div
                    className="single-article-summary"
                    ref={setArticleContentRef}
                    dangerouslySetInnerHTML={{ __html: content }}
                    data-testid={'single-article-content-testid'}
                />
            )}
        </React.Fragment>
    );
};

export default ElasticSingleDocumentContent;
