import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { hashHistory, withRouter } from 'react-router';
import { FormattedMessage, injectIntl } from 'react-intl';
import { ALERT_TYPE, BACKGROUND_MESSAGE_TYPE_DOWNLOAD, BREADCRUMBS, MANAGE_ALERTS_PAGE_TITLE, ROUTES } from '@constants';
import BreadCrumbsModel from '@utils/breadCrumbBuilder';
import breadcrumbsActions from '@reusable/Breadcrumbs/redux/Breadcrumbs.actions';
import { bindActionCreators } from 'redux';
import TabbedContent from '@reusable/TabbedContent/TabbedContent';
import manageAlertsApi from './ManageAlertsApi';
import alertsManagerActions from './redux/ManageAlerts.actions';
import manageSelectedAlertsActions from './redux/ManageSelectedAlerts.actions';
import utils from '@utils/utilities';
import formatRichMessage from '@utils/formatRichMessage';
import userPreferencesActions from '@UserPreferences/redux/UserPreferences.actions';
import PopupModal from '@reusable/PopupModal/PopupModal.index';
import ReactTooltip from 'react-tooltip';
import { withAlertRouteToggle } from '@utils/contexts';
import adHocSearchActions from '@reusable/AdHocSearch/redux/AdHocSearch.actions';
import MyAlertsTable from './components/tables/MyAlerts';
import PRAlertsTable from './components/tables/PRAlerts';
import editSearchActions from '../StartPage/redux/EditSearch.actions';
import backgroundActions from '@reusable/BackgroundMessage/redux/BackgroundMessages.actions';
import { getMongoLanguageKey } from '@utils/deliveryService';
import popupModelActions from '@reusable/PopupModal/redux/PopupModel.actions';
import NotificationsContainer from '@scripts/reusable/NotificationsContainer/NotificationsContainer';
import searchParamsActions from '@MainSearch/redux/SearchParams.actions';

class ManageAlerts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            notificationsToDisplay: []
        }
    }

    setBreadcrumbs() {
        let breadcrumbs = new BreadCrumbsModel.Builder(BREADCRUMBS.MANAGE_ALERTS)
            .setProperty('isNewAlert', this.props.isNewAlert)
            .setProperty('searchType', this.props.searchParams.searchType)
            .setProperty('q', utils.sanitizeSearchStringInput(this.props.searchParams.query))
            .setProperty('list', true)
            .setProperty('category', this.props.breadcrumbs.query.category)
            .setProperty('isSnapshotVisible', this.props.isSnapshotVisible)
            .build().breadcrumbs;
        this.props.updateBreadcrumbs(breadcrumbs);
        this.props.setIsNewAlert(false);
    }

    componentDidMount() {
        document.title = MANAGE_ALERTS_PAGE_TITLE;
        this.props.resetQuery();
        this.props.updatePrevPath(this.props.location);
        this.setBreadcrumbs();
        this.props.updateAdHocSearchObject({});
        this.props.emptyEditSearch({});
        this.setState({ deleteAlertsNotificationHidden: this.props.deleteAlertsNotificationHidden });

        if(!this.props.deleteAlertsNotificationHidden){
            const mapStoreKeysToHiddenValues = {
                deleteAlertsStatus: this.props.deleteAlertsNotificationHidden,
            }
            const notificationsToDisplayList = Object.keys(mapStoreKeysToHiddenValues).filter((key) => !mapStoreKeysToHiddenValues[key]);
            this.setState({ notificationsToDisplay: notificationsToDisplayList });
        }
    }
    
    componentDidUpdate(prevProps) {
        if (prevProps.deleteAlertsNotificationHidden !== this.props.deleteAlertsNotificationHidden) {
            const mapStoreKeysToHiddenValues = {
                deleteAlertsStatus: this.props.deleteAlertsNotificationHidden,
            }
            const notificationsToDisplayList = Object.keys(mapStoreKeysToHiddenValues).filter((key) => !mapStoreKeysToHiddenValues[key]);
            this.setState({ notificationsToDisplay: notificationsToDisplayList });
        }
    }

    getAlertForPublicRecordsAlerts = (pageNumber, pageSize, alertSortBy, alertDirection, nameFilter) => {
        this.props.updateLoadingStatus(ALERT_TYPE.PUBLIC_RECORD_ALERT, false);
        manageAlertsApi
            .getPublicRecordAlerts(pageNumber, pageSize, alertSortBy, alertDirection, nameFilter)
            .then((response) => {
                this.props.setPublicRecordAlerts(response.items || [], response.total, true, pageNumber, pageSize);
            })
            .catch(() => {
                utils.showNotificationsMessage({
                    messageText: 'ManageAlerts.ErrorMessage.getAlerts',
                    messageType: 'system-error',
                });
                this.props.updateLoadingStatus(true);
            });
    };

    viewPublicRecordSearchAlert = (event) => {
        let prUrl = event.currentTarget.value;
        let path = '/public-records?param=' + btoa(prUrl);
        hashHistory.push(path);
    };

    editPublicRecordAlert = (event) => {
        let prUrl = event.currentTarget.value;
        let path = '/public-records?param=' + btoa(prUrl);
        hashHistory.push(path);
    };

    goBackToSearchResults = () => {
        hashHistory.push(
            '/main-search?searchType=' +
                this.props.searchParams.searchType +
                '&q=' +
                utils.sanitizeSearchStringInput(this.props.searchParams.query) +
                '&list=true&category=' +
                this.props.breadcrumbs.query.category
        );
    };

    myAlertsContent = () => {
        return {
            label: formatRichMessage({ id: 'ManageAlerts.alertTab.myAlerts' }, this.props.intl),
            content: <MyAlertsTable setPopupModel={this.props.setPopupModel} />,
        };
    };

    publicAlertsContent = () => {
        return {
            label: formatRichMessage({ id: 'ManageAlerts.alertTab.publicRecordsAlerts' }, this.props.intl),
            content: <PRAlertsTable />,
        };
    };

    alertsTabContent = () => {
        let alertsTabContent = [];

        alertsTabContent.push(this.myAlertsContent());
        //TODO: check if alerts are enabled
        if (this.props.publicRecordsOn) {
            alertsTabContent.push(this.publicAlertsContent());
        }
        //TODO: add shared alert in the future

        return alertsTabContent;
    };

    downloadAlertsList = () => {
        const {
            intl,
            timezone,
            language,
            alertsSorting,
            clearBackgroundMessages,
            setSuccessBackgroundMessages,
            setFailureBackgroundMessages,
            setDownloadAlertsButtonDisabledState,
        } = this.props;
        const userLanguage = getMongoLanguageKey(language);
        const filenamePrefix = formatRichMessage({ id: 'ManageAlerts.label.alertsList' }, intl);
        const currentDate: Date = new Date();
        const filename: string = `${utils.formatReportFileName(
            filenamePrefix
        )}_${utils.formatReportDateWithtTimezoneOffset(currentDate.getTime())}`;
        setDownloadAlertsButtonDisabledState(true);

        manageAlertsApi
            .downloadAlertsList({ filename, userLanguage, alertsSorting, timezone })
            .then((response) => {
                clearBackgroundMessages();
                setSuccessBackgroundMessages({
                    title: 'DeliveryService.downloadReady',
                    message: 'DynamicMessage',
                    messageParameters: filename,
                    type: BACKGROUND_MESSAGE_TYPE_DOWNLOAD,
                    link: `/api/alerts/export/${response.id}`,
                    isVisible: true,
                });
                setDownloadAlertsButtonDisabledState(false);
            })
            .catch(() => {
                clearBackgroundMessages();
                setFailureBackgroundMessages({
                    title: 'DeliveryService.downloadArticleFail.title',
                    message: 'DeliveryService.downloadArticleFail.description2',
                    isVisible: true,
                });
                setDownloadAlertsButtonDisabledState(false);
            });
    };

    render() {
        const { alertsCount, publicRecordsCount, isDownloadAlertsButtonDisabled } = this.props;
        const isDownloadButtonDisabled =
            (alertsCount === 0 && publicRecordsCount === 0) || isDownloadAlertsButtonDisabled;

        return (
            <>
                {this.props.popupModel.isVisible && <PopupModal closeButtonHandler={this.closePopupModal} />}
                <div className="manage-alerts-header">
                    <h2 className="manage-alerts-header-title notranslate">
                        {this.props.breadcrumbs.prevPath === '/create-alert' && (
                            <Fragment>
                                <ReactTooltip
                                    event="mouseenter"
                                    eventOff="mouseleave"
                                    type="light"
                                    border={true}
                                    effect="solid"
                                    className="tooltips"
                                />
                                <button
                                    className="icon-box"
                                    data-tip={formatRichMessage(
                                        { id: 'ManageAlerts.backToResultsButton' },
                                        this.props.intl
                                    )}
                                    onClick={this.goBackToSearchResults}
                                >
                                    <div className="la-TriangleLeft" />
                                </button>
                            </Fragment>
                        )}
                        <FormattedMessage id="BREADCRUMBS.manageAlerts" />
                    </h2>
                    <button
                        className="button-secondary-lg manage-alerts-header-download-cta"
                        id="download-alerts-button"
                        data-track="alerts-page-download-alerts-button"
                        disabled={isDownloadButtonDisabled}
                        onClick={this.downloadAlertsList}
                    >
                        <div className="la-Download" />
                        <FormattedMessage id="ManageAlerts.label.downloadAlertsList" />
                    </button>
                </div>

                <div className="manage-alerts-body">
                    <TabbedContent visible={true} defaultTabIndex={0} tabs={this.alertsTabContent()} />
                </div>
                {this.state.notificationsToDisplay.length? (
                        <NotificationsContainer route={ROUTES.MANAGE_ALERTS} notificationsNames={this.state.notificationsToDisplay}/>
                    ) : <></>
                }
            </>
        );
    }
}

const mapStateToProps = function (state) {
    return {
        breadcrumbs: state.breadcrumbs,
        isSnapshotVisible: state.user.preferences.generalSettings.showSnapshot,
        alerts: state.alerts,
        publicRecordsOn: state.user.appSettings.prsOn,
        alertsSorting: state.user.preferences.generalSettings.alertsOptions.sorting,

        isNewAlert: state.alerts.isNewAlert,
        filterAlertName: state.alerts.filterAlertName,
        alertsCount: state.alerts.alertsCount,
        publicRecordsCount: state.alerts.publicRecordsCount,
        isDownloadAlertsButtonDisabled: state.alerts.isDownloadAlertsButtonDisabled,
        selectedAlerts: state.selectedAlerts,
        generalPageSize: state.user.preferences.generalSettings.generalPageSize,
        popupModel: state.popupModel,
        searchParams: state.searchParams,
        language: state.user.preferences.language,
        timezone: state.user.timezone,
        deleteAlertsNotificationHidden : state.notificationStatus.deleteAlertsStatus.hidden
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            updateBreadcrumbs: breadcrumbsActions.updateBreadcrumbs,
            updatePrevPath: breadcrumbsActions.updatePrevPath,
            setAlerts: alertsManagerActions.setAlerts,
            setPublicRecordAlerts: alertsManagerActions.setPublicRecordAlerts,
            setDownloadAlertsButtonDisabledState: alertsManagerActions.setDownloadAlertsButtonDisabledState,
            toggleAlertSelection: manageSelectedAlertsActions.toggleAlertSelection,
            resetQuery: searchParamsActions.resetQuery,
            setIsNewAlert: alertsManagerActions.setIsNewAlert,
            toggleAlerts: alertsManagerActions.toggleAlerts,
            toggleAlert: alertsManagerActions.toggleAlert,
            updateLoadingStatus: alertsManagerActions.updateLoadingStatus,
            setFilterAlertName: alertsManagerActions.setFilterAlertName,
            updateGeneralPageSize: userPreferencesActions.updateGeneralPageSize,
            emptyEditSearch: editSearchActions.emptyEditSearch,
            updateAdHocSearchObject: adHocSearchActions.updateAdHocSearchObject,
            setSuccessBackgroundMessages: backgroundActions.setSuccessBackgroundMessages,
            setFailureBackgroundMessages: backgroundActions.setFailureBackgroundMessages,
            clearBackgroundMessages: backgroundActions.clearBackgroundMessages,
            setPopupModel: popupModelActions.setPopupModel,
        },
        dispatch
    );
};

export { ManageAlerts as TestManageAlerts };
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withAlertRouteToggle(injectIntl(ManageAlerts))));
