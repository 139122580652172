import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as BannerWarningIcon } from '@assets/icons/BannerWarningIcon.svg';
import { ReactComponent as BannerWarningIconDark } from '@assets/icons/BannerWarningIconDark.svg';

function Page403({isDarkMode}) {
    return (
        <div className="error-403">
            <div className={`embedded-message ${isDarkMode ? 'dark-mode' : ''}`}>
                <div className="embedded-message__icon">
                    {isDarkMode ? <BannerWarningIconDark/> : <BannerWarningIcon/>}
                </div>
                <div className="embedded-message__message">
                    <FormattedMessage id="General_CoreFunctionality_Error_error.403Title"/>
                </div>
            </div>
        </div>
    );
}

export default Page403;
