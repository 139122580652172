//@flow
import type { StateType } from '../investigationTypeGuards/investigationSelectors.type.guards';
import type { CurrentReport } from '@ReportBuilder/redux/flow/ReportBuilder.type.guards';
import type { FuzzyConfigType } from '../investigationTypeGuards/investigationFlows.type.guards';

export const shouldUseNewResearchSummary = (state: StateType): boolean => state.user.useNewResearchSummary;

export const isNewRSFeatureEnabled = (state: StateType): boolean => state.user.useNewResearchSummaryFeature;

export const isUserAnonymized = (state: StateType): boolean => state.user.isAnonymized;

export const getInvestigationId = (state: StateType): string | null => state.investigation.id;

export const getInvestigation = (state: StateType): Object => state.investigation;

export const getCurrentReport = (state: StateType): mixed => state.investigation.report;

export const getFullCurrentReport = (state: StateType): CurrentReport => state.currentReport;

export const getBillingId = (state: StateType): string => state.investigation.billingId;

export const getVisitedContentTypes = (state: StateType): Array<string> => state.investigation.visitedContentTypes;

export const getArticlesManager = (state: StateType): mixed => state.articlesManager;

export const getSearchResults = (state: StateType): mixed => state.searchResults;

export const getNewsContentSources = (state: StateType, searchType: string): string =>
    state.adHocSearch.newsSearchSources
        ? state.adHocSearch.newsSearchSources
        : state.user.preferences[searchType].newsSearchSources;

export const getFuzzyConfig =
    (searchType: string): ((state: StateType) => FuzzyConfigType) =>
    (state: StateType): FuzzyConfigType => {
        const { fuzzyOn, fuzzyThreshold } = state.user.preferences[searchType];

        return {
            fuzzyOn,
            fuzzyThreshold,
        };
    };
