import {
    ALL_ARTICLES_REPORT,
    DOC_TYPE,
    MODAL_TYPE,
    NO_DOCUMENTS_OF_INTEREST,
    POPUP_BUTTON_TYPES,
    POPUP_SIZE_LARGE,
    POPUP_SIZE_SMALL,
    POPUP_SIZE_EXTRA_SMALL,
    SELECTED_ARTICLES_REPORT,
    SELECTED_ITEMS,
    ALL_ITEMS_LIST,
    NO_DOCUMENTS_FOUND,
    MODAL_TAB_TYPE,
    MODAL_TITLE_MAP
} from '@constants';

class PopupModel {
    constructor(build) {
        this.popupType = build.popupType || MODAL_TYPE.UNDEFINED;
        this.title = build.title || 'Undefined title';
        this.articles = build.articles;
        this.isVisible = build.isVisible;
        this.isUboArticle = build.isUboArticle;
        this.buttons = build.buttons;
        this.docType = build.docType;
        this.components = build.components || [];
        this.dynamicComponents = build.dynamicComponents || [];
        this.staticComponents = build.staticComponents || [];
        this.warningComponents = build.warningComponents || [];
        this.tabbedComponents = build.tabbedComponents || [];
        this.dismissibleMessageComponents = build.dismissibleMessageComponents || [];
        this.fileName = build.fileName;
        this.comments = build.comments;
        this.commentsLabel = build.commentsLabel;
        this.commentsDescription = build.commentsDescription;
        this.commentsCharLimit = build.commentsCharLimit;
        this.useNewResearchSummary = build.useNewResearchSummary;
        this.researchSummary = build.researchSummary;
        this.categoryOrder = build.categoryOrder;
        this.totalArticlesCount = build.totalArticlesCount;
        this.searchQuery = build.searchQuery;
        this.searchQueryType = build.searchQueryType;
        this.selectedSearchQueryTypes = build.selectedSearchQueryTypes;
        this.alertContentTypes = build.alertContentTypes;
        this.prefilterQuery = build.prefilterQuery;
        this.includeTerms = build.includeTerms;
        this.excludeTerms = build.excludeTerms;
        this.articleType = build.articleType;
        this.contentLanguage = build.contentLanguage;
        this.customNews = build.customNews;
        this.customNewsCreatedBy = build.customNewsCreatedBy;
        this.customNewsLnDefaultQuery = build.customNewsLnDefaultQuery;
        this.article = build.article;
        this.reports = build.reports;
        this.sortStrategy = build.sortStrategy;
        this.resultListType = build.resultListType;
        this.reportListType = build.reportListType;
        this.reportListIds = build.reportListIds;
        this.reportId = build.reportId;
        this.reportCategory = build.reportCategory;
        this.count = build.count;
        this.warning = build.warning;
        this.description = build.description;
        this.explanation = build.explanation;
        this.negativeTopics = build.negativeTopics;
        this.clickedTerm = build.clickedTerm;
        this.terms = build.terms;
        this.formattedNegativeNewsString = build.formattedNegativeNewsString;
        this.segment = build.segment;
        this.alertIds = build.alertIds;
        this.alertType = build.alertType;
        this.alertName = build.alertName;
        this.postFilters = build.postFilters;
        this.originalSources = build.originalSources;
        this.sources = build.sources;
        this.dateRanges = build.dateRanges;
        this.newsDateRange = build.newsDateRange;
        this.legalDateRange = build.legalDateRange;
        this.companyDateRange = build.companyDateRange;
        this.lawSources = build.lawSources;
        this.lawSource = build.lawSource;
        this.costCode = build.costCode;
        this.costCodes = build.costCodes;
        this.chooseOnlyFromCostCodesList = build.chooseOnlyFromCostCodesList;
        this.isCostCodeRequired = build.isCostCodeRequired;
        this.updateUserPreferencesCheckbox = build.updateUserPreferencesCheckbox;
        this.buttonIsDisabled = build.buttonIsDisabled;
        this.action = build.action;
        this.popupSize = build.popupSize || POPUP_SIZE_SMALL;
        this.customQuery = build.customQuery;
        this.defaultCustomQuery = build.defaultCustomQuery;
        this.negativityLevels = build.negativityLevels;
        this.userIsAdmin = build.userIsAdmin;
        this.newsSearchSources = build.newsSearchSources;
        this.userLocks = build.userLocks;
        this.adHocMasterLock = build.adHocMasterLock;
        this.editableTitle = build.editableTitle;
        this.queryName = build.queryName;
        this.lockName = build.lockName;
        this.searchNamePartyEntity = build.searchNamePartyEntity;
        this.selectedItemsCount = build.selectedItemsCount;
        this.selectedReports = build.selectedReports;
        this.contentsOptions = build.contentsOptions;
        this.showDeliveryOptions = build.showDeliveryOptions;
        this.onAutosave = build.onAutosave;
        this.category = build.category;
        this.onSelectionChange = build.onSelectionChange;
        this.deselectAllReports = build.deselectAllReports;
        this.deliveryData = build.deliveryData;
        this.snapshotAllResultsDownload = build.snapshotAllResultsDownload;
        this.reportType = build.reportType;
        this.documentId = build.documentId;
        this.onUpdateReportBuilderPage = build.onUpdateReportBuilderPage;
        this.usersInPreferenceGroup = build.usersInPreferenceGroup;
        this.terms = build.terms;
        this.snapshotDelivery = build.snapshotDelivery;
        this.storeProperties = build.storeProperties;
        this.isReadOnly = build.isReadOnly;
        this.acceptAction = build.acceptAction;
        this.rejectAction = build.rejectAction;
        this.userLanguage = build.userLanguage;
        this.emailAddress = build.emailAddress;
        this.elementsVisibility = build.elementsVisibility;
        this.actionCallback = build.actionCallback;
        this.exportEntitiesPayload = build.exportEntitiesPayload;
        this.paramsForUrl = build.paramsForUrl;
        this.isSnapshotDisabled = build.isSnapshotDisabled;
        this.entityInfo = build.entityInfo;
        this.timezone = build.timezone;
        this.onDeliveryApiError = build.onDeliveryApiError;
        this.selectAllSetting = build.selectAllSetting;
        this.getPayloadCallback = build.getPayloadCallback;
        this.onDeliverySuccess = build.onDeliverySuccess;
        this.handleTotalEntitiesCountChange = build.handleTotalEntitiesCountChange;
        this.views = build.views;
    }

    static get Builder() {

        function getTitle(popupType) {
            return MODAL_TITLE_MAP[popupType] || MODAL_TITLE_MAP['default'];
        }

        function getButtons(popupType, buttonIsDisabled) {
            switch (popupType) {
                case MODAL_TYPE.PRINT_RESULT_LIST:
                    return [
                        {
                            label: getTitle(popupType),
                            primary: true,
                            isDisabled: buttonIsDisabled,
                            dataTrack: `${popupType}-modal-print-button`,
                        },
                        {
                            label: 'General_CoreFunctionality_UIText_general.cancel',
                            dataTrack: `${popupType}-modal-cancel-button`,
                        },
                    ];
                case MODAL_TYPE.ELASTIC_PRINT_DOCUMENT:
                case MODAL_TYPE.PRINT_REPORT_CATEGORY:
                case MODAL_TYPE.PRINT_REPORTS:
                case MODAL_TYPE.PRINT_REPORTS_SEPARATE:
                case MODAL_TYPE.PRINT_REPORTS_COMBINED:
                    return [
                        {
                            label: 'PopupBuilder.title.print',
                            primary: true,
                            isDisabled: buttonIsDisabled,
                            type: POPUP_BUTTON_TYPES.download,
                            dataTrack: `${popupType}-modal-print-button`,
                        },
                        {
                            label: 'General_CoreFunctionality_UIText_general.cancel',
                            dataTrack: `${popupType}-modal-cancel-button`,
                        },
                    ];
                case MODAL_TYPE.EMAIL_RESULT_LIST:
                    return [
                        {
                            label: 'PopupBuilder.buttonLabel.send',
                            primary: true,
                            isDisabled: buttonIsDisabled,
                            dataTrack: `${popupType}-modal-send-button`,
                        },
                        {
                            label: 'General_CoreFunctionality_UIText_general.cancel',
                            dataTrack: `${popupType}-modal-cancel-button`,
                        },
                    ];
                case MODAL_TYPE.ELASTIC_EMAIL_DOCUMENT:
                case MODAL_TYPE.EMAIL_REPORT_CATEGORY:
                    return [
                        {
                            label: 'PopupBuilder.buttonLabel.send',
                            primary: true,
                            isDisabled: buttonIsDisabled,
                            type: POPUP_BUTTON_TYPES.download,
                            dataTrack: `${popupType}-modal-send-button`,
                        },
                        {
                            label: 'General_CoreFunctionality_UIText_general.cancel',
                            dataTrack: `${popupType}-modal-cancel-button`,
                        },
                    ];
                case MODAL_TYPE.EMAIL_REPORTS:
                case MODAL_TYPE.EMAIL_REPORTS_SEPARATE:
                case MODAL_TYPE.EMAIL_REPORTS_COMBINED:
                    return [
                        {
                            label: 'PopupBuilder.buttonLabel.send',
                            primary: true,
                            isDisabled: buttonIsDisabled,
                            type: POPUP_BUTTON_TYPES.download,
                            dataTrack: `${popupType}-modal-send-button`,
                        },
                        {
                            label: 'General_CoreFunctionality_UIText_general.cancel',
                            dataTrack: `${popupType}-modal-cancel-button`,
                        },
                    ];
                case MODAL_TYPE.DOWNLOAD_RESULT_LIST:
                case MODAL_TYPE.DOWNLOAD_HISTORY:
                    return [
                        {
                            label: getTitle(popupType),
                            primary: true,
                            isDisabled: buttonIsDisabled,
                            dataTrack: `${popupType}-modal-download-button`,
                        },
                        {
                            label: 'General_CoreFunctionality_UIText_general.cancel',
                            dataTrack: `${popupType}-modal-cancel-button`,
                        },
                    ];
                case MODAL_TYPE.DOWNLOAD_BATCH_SCREENING_FULL_REPORT:
                    return [
                        {
                            label: 'ArticleAction.generate',
                            primary: true,
                            isDisabled: buttonIsDisabled,
                            type: POPUP_BUTTON_TYPES.download,
                            dataTrack: `${popupType}-modal-download-button`,
                        },
                        {
                            label: 'General_CoreFunctionality_UIText_general.cancel',
                            dataTrack: `${popupType}-modal-cancel-button`,
                        },
                    ];
                case MODAL_TYPE.DOWNLOAD_ENTITIES:
                case MODAL_TYPE.DOWNLOAD_RESULT_SNAPSHOT:
                    return [
                        {
                            label: 'PopupBuilder.title.download',
                            primary: true,
                            isDisabled: buttonIsDisabled,
                            type: POPUP_BUTTON_TYPES.download,
                            dataTrack: `${popupType}-modal-download-button`,
                        },
                        {
                            label: 'General_CoreFunctionality_UIText_general.cancel',
                            dataTrack: `${popupType}-modal-cancel-button`,
                        },
                    ];
                case MODAL_TYPE.ELASTIC_DOWNLOAD_DOCUMENT:
                case MODAL_TYPE.DOWNLOAD_REPORT_CATEGORY:
                    return [
                        {
                            label: getTitle(popupType),
                            primary: true,
                            isDisabled: buttonIsDisabled,
                            type: POPUP_BUTTON_TYPES.download,
                            dataTrack: `${popupType}-modal-download-button`,
                        },
                        {
                            label: 'General_CoreFunctionality_UIText_general.cancel',
                            dataTrack: `${popupType}-modal-cancel-button`,
                        },
                    ];
                case MODAL_TYPE.DOWNLOAD_BATCH_SCREENING:
                case MODAL_TYPE.DOWNLOAD_BATCH_SCREENING_SNAPSHOT:
                    return [
                        {
                            label: 'ArticleAction.generate',
                            primary: true,
                            isDisabled: buttonIsDisabled,
                            type: POPUP_BUTTON_TYPES.download,
                            dataTrack: `${popupType}-modal-download-button`,
                        },
                        {
                            label: 'General_CoreFunctionality_UIText_general.cancel',
                            dataTrack: `${popupType}-modal-cancel-button`,
                        },
                    ];
                case MODAL_TYPE.DOWNLOAD_REPORTS:
                case MODAL_TYPE.DOWNLOAD_REPORTS_COMBINED:
                case MODAL_TYPE.DOWNLOAD_REPORTS_SEPARATE:
                case MODAL_TYPE.DOWNLOAD_REPORT:
                    return [
                        {
                            label: 'PopupBuilder.title.download',
                            primary: true,
                            type: POPUP_BUTTON_TYPES.download,
                            dataTrack: `${popupType}-modal-download-button`,
                        },
                        {
                            label: 'General_CoreFunctionality_UIText_general.cancel',
                            dataTrack: `${popupType}-modal-cancel-button`,
                        },
                    ];
                case MODAL_TYPE.DELETE_REPORT:
                case MODAL_TYPE.DELETE_REPORT_NOTE:
                case MODAL_TYPE.DELETE_DOCUMENT_FROM_REPORT:
                case MODAL_TYPE.SHOW_SNAPSHOT:
                case MODAL_TYPE.DELETE_NOTE_FROM_DOCUMENT:
                    return [
                        {
                            label: 'General_CoreFunctionality_UIText_general.ok',
                            primary: true,
                            dataTrack: `${popupType}-modal-ok-button`,
                        },
                        {
                            label: 'General_CoreFunctionality_UIText_general.cancel',
                            dataTrack: `${popupType}-modal-cancel-button`,
                        },
                    ];
                case MODAL_TYPE.USER_PREFERENCES_EDIT_NEGATIVE_SEARCH_TERMS:
                    return [
                        {
                            label: 'General_CoreFunctionality_UIText_general.save',
                            primary: true,
                            isDisabled: true,
                            type: POPUP_BUTTON_TYPES.save,
                            dataTrack: `${popupType}-modal-save-button`,
                        },
                        {
                            label: 'General_CoreFunctionality_UIText_general.cancel',
                            isDisabled: false,
                            type: POPUP_BUTTON_TYPES.cancel,
                            dataTrack: `${popupType}-modal-cancel-button`,
                        },
                    ];
                case MODAL_TYPE.EDIT_ARTICLE_NOTE:
                case MODAL_TYPE.CREATE_ALERTS:
                    const primaryBtnId = `${popupType}-modal-save-button`;
                    const secondaryBtnId = `${popupType}-modal-cancel-button`;
                    return [
                        {
                            label: 'General_CoreFunctionality_UIText_general.save',
                            primary: true,
                            dataTrack: primaryBtnId,
                            testid: primaryBtnId,
                        },
                        {
                            label: 'General_CoreFunctionality_UIText_general.cancel',
                            dataTrack: secondaryBtnId,
                            testid: secondaryBtnId,
                        },
                    ];
                case MODAL_TYPE.DELETE_ALERT:
                case MODAL_TYPE.DELETE_PUBLIC_RECORD_ALERT:
                    return [
                        {
                            label: 'General_CoreFunctionality_UIText_general.delete',
                            primary: true,
                            dataTrack: `${popupType}-modal-delete-button`,
                        },
                        {
                            label: 'General_CoreFunctionality_UIText_general.cancel',
                            dataTrack: `${popupType}-modal-cancel-button`,
                        },
                    ];
                case MODAL_TYPE.DELETE_ALERTS:
                case MODAL_TYPE.DELETE_PUBLIC_RECORD_ALERTS:
                    return [
                        {
                            label: 'General_CoreFunctionality_UIText_general.delete',
                            primary: true,
                            dataTrack: `${popupType}-modal-delete-button`,
                        },
                        {
                            label: 'General_CoreFunctionality_UIText_general.cancel',
                            dataTrack: `${popupType}-modal-cancel-button`,
                        },
                    ];
                case MODAL_TYPE.ADD_TO_REPORT:
                    return [
                        {
                            label: 'ArticleAction.addToReport',
                            primary: true,
                            isDisabled: buttonIsDisabled,
                            dataTrack: `${popupType}-modal-add-to-report-button`,
                        },
                        {
                            label: 'General_CoreFunctionality_UIText_general.cancel',
                            dataTrack: `${popupType}-modal-cancel-button`,
                        },
                    ];
                case MODAL_TYPE.ADHOC_SEARCH:
                    return [
                        {
                            label: 'General.label.apply',
                            primary: true,
                            isDisabled: buttonIsDisabled,
                            type: POPUP_BUTTON_TYPES.save,
                            dataTrack: `${popupType}-modal-apply-button`,
                        },
                        {
                            label: 'General.label.reset',
                            isDisabled: buttonIsDisabled,
                            type: POPUP_BUTTON_TYPES.reset,
                            dataTrack: `${popupType}-modal-reset-button`,
                        },
                    ];
                case MODAL_TYPE.APPLY_ADMIN_CHANGES:
                    return [
                        {
                            label: 'General.label.saveChanges',
                            primary: false,
                            primaryAction: true,
                            isDisabled: buttonIsDisabled,
                            type: POPUP_BUTTON_TYPES.save,
                            dataTrack: `${popupType}-modal-save-changes-button`,
                        },
                        {
                            label: 'General.label.cancel',
                            isDisabled: buttonIsDisabled,
                            type: POPUP_BUTTON_TYPES.cancel,
                            dataTrack: `${popupType}-modal-cancel-button`,
                        },
                    ];
                case MODAL_TYPE.DISCARD_ADMIN_CHANGES:
                    return [
                        {
                            label: 'General.label.yes',
                            primary: true,
                            isDisabled: buttonIsDisabled,
                            type: POPUP_BUTTON_TYPES.save,
                            dataTrack: `${popupType}-modal-save-button`,
                        },
                        {
                            label: 'General.label.no',
                            isDisabled: buttonIsDisabled,
                            type: POPUP_BUTTON_TYPES.cancel,
                            dataTrack: `${popupType}-modal-cancel-button`,
                        },
                    ];
                case MODAL_TYPE.USER_PREFERENCES_DELETE_CUSTOM_NEWS_QUERY:
                    return [
                        {
                            label: 'General_CoreFunctionality_UIText_general.delete',
                            primary: true,
                            type: POPUP_BUTTON_TYPES.save,
                            dataTrack: `${popupType}-modal-delete-button`,
                        },
                        {
                            label: 'General_CoreFunctionality_UIText_general.cancel',
                            type: POPUP_BUTTON_TYPES.cancel,
                            dataTrack: `${popupType}-modal-cancel-button`,
                        },
                    ];
                case MODAL_TYPE.USER_PREFERENCES_ADD_CUSTOM_NEWS_QUERY:
                case MODAL_TYPE.USER_PREFERENCES_EDIT_CUSTOM_NEWS_QUERY:
                    return [
                        {
                            label: 'General_CoreFunctionality_UIText_general.save',
                            isDisabled: buttonIsDisabled,
                            primary: true,
                            type: POPUP_BUTTON_TYPES.save,
                            dataTrack: `${popupType}-modal-save-button`,
                        },
                        {
                            label: 'General_CoreFunctionality_UIText_general.cancel',
                            type: POPUP_BUTTON_TYPES.cancel,
                            dataTrack: `${popupType}-modal-cancel-button`,
                        },
                    ];
                case MODAL_TYPE.DOWNLOAD_SNAPSHOT_PAGE:
                    return [
                        {
                            label: 'PopupBuilder.title.download',
                            primary: true,
                            isDisabled: buttonIsDisabled,
                            type: POPUP_BUTTON_TYPES.download,
                            dataTrack: `${popupType}-modal-download-button`,
                        },
                        {
                            label: 'General_CoreFunctionality_UIText_general.cancel',
                            dataTrack: `${popupType}-modal-cancel-button`,
                        },
                    ];
                case MODAL_TYPE.USER_PREFERENCES_VIEW_CUSTOM_NEWS_QUERY:
                    return [
                        {
                            label: 'General_CoreFunctionality_UIText_general.cancel',
                            dataTrack: `${popupType}-modal-cancel-button`,
                        },
                    ];
                case MODAL_TYPE.APPLY_RISK_SCORES_PREFERENCE:
                    return [
                        {
                            label: 'PopupBuilder.button.showRiskScores',
                            primary: true,
                            type: POPUP_BUTTON_TYPES.accept,
                            dataTrack: `${popupType}-modal-show-risk-scores-button`,
                        },
                        {
                            label: 'PopupBuilder.button.hideRiskScores',
                            type: POPUP_BUTTON_TYPES.reject,
                            dataTrack: `${popupType}-modal-hide-risk-scores-button`,
                        },
                    ];
                case MODAL_TYPE.DELETE_ALL_HISTORY:
                    return [
                        {
                            label: 'PopupBuilder.buttonLabel.clear',
                            primary: true,
                            type: POPUP_BUTTON_TYPES.accept,
                            dataTrack: `${popupType}-modal-delete-button`,
                        },
                        {
                            label: 'General_CoreFunctionality_UIText_general.cancel',
                            type: POPUP_BUTTON_TYPES.cancel,
                            dataTrack: `${popupType}-modal-cancel-button`,
                        },
                    ];
                case MODAL_TYPE.UPLOAD_ENTITIES_COMPANY_SOURCES_WITH_COMPANIES:
                    return [
                        {
                            label: 'General.label.yes',
                            primary: true,
                            type: POPUP_BUTTON_TYPES.accept,
                            dataTrack: `${popupType}-modal-yes-button`,
                        },
                        {
                            label: 'General.label.no',
                            type: POPUP_BUTTON_TYPES.cancel,
                            dataTrack: `${popupType}-modal-no-button`,
                        },
                    ];
                case MODAL_TYPE.UPLOAD_ENTITIES_COMPANY_SOURCES_WITHOUT_COMPANIES:
                    return [
                        {
                            label: 'General_CoreFunctionality_UIText_general.ok',
                            primary: true,
                            dataTrack: `${popupType}-modal-ok-button`,
                        },
                    ];
                case MODAL_TYPE.SAVE_SEARCH:
                    return [
                        {
                            label: 'General_CoreFunctionality_UIText_general.updateMyResults',
                            primary: true,
                            type: POPUP_BUTTON_TYPES.accept,
                            dataTrack: `${popupType}-modal-save-search-button`,
                        },
                    ];
                case MODAL_TYPE.CHANGE_COLUMNS_VISIBILITY_MULTIPLE_ENTITIES:
                    const primaryButtonId = `${popupType}-modal-save-button`;
                    const secondaryButtonId = `${popupType}-modal-cancel-button`;
                    return [
                        {
                            label: 'General_CoreFunctionality_UIText_general.save',
                            isDisabled: buttonIsDisabled,
                            primary: true,
                            type: POPUP_BUTTON_TYPES.save,
                            dataTrack: primaryButtonId,
                            testid: primaryButtonId,
                        },
                        {
                            label: 'General_CoreFunctionality_UIText_general.cancel',
                            type: POPUP_BUTTON_TYPES.cancel,
                            dataTrack: secondaryButtonId,
                            testid: secondaryButtonId,
                        },
                    ];

                case MODAL_TYPE.EDIT_SEARCH_MULTIPLE_ENTITIES:
                    return [
                        {
                            label: 'General_CoreFunctionality_UIText_general.save',
                            primary: true,
                            testid: `${popupType}-modal-save-button`,
                            type: POPUP_BUTTON_TYPES.accept,
                        },
                        {
                            label: 'General_CoreFunctionality_UIText_general.cancel',
                            testid: `${popupType}-modal-cancel-button`,
                        },
                    ];
                case MODAL_TYPE.UPLOAD_ENTITIES:
                    return [
                        {
                            label: 'General_CoreFunctionality_UIText_general.submit',
                            primary: true,
                            isDisabled: buttonIsDisabled,
                            type: POPUP_BUTTON_TYPES.upload,
                            dataTrack: `${popupType}-modal-upload-button`,
                        },
                        {
                            label: 'General_CoreFunctionality_UIText_general.cancel',
                            dataTrack: `${popupType}-modal-cancel-button`,
                        },
                    ];

                default:
                    return [];
            }
        }

        function setPopupComponents(popupType, isDismissibleMessageEnabled, isWarningEnabled) {
            let components = [];
            // components that are organised in tabs
            let tabbedComponents = [];
            //components that are hidden under certain circumstances
            let dynamicComponents = [];
            //components that are not hidden under no circumstance
            let staticComponents = [];
            let warningComponents = ['ModalWarning'];
            let dismissibleMessageComponents = ['ModalDismissibleMessage'];

            switch (popupType) {
                case MODAL_TYPE.PRINT_RESULT_LIST:
                    staticComponents = ['ModalResultList'];
                    dynamicComponents = ['ModalContentOptions', 'ModalComments'];
                    break;
                case MODAL_TYPE.ELASTIC_PRINT_DOCUMENT:
                    staticComponents = ['ModalComments'];
                    break;
                case MODAL_TYPE.PRINT_REPORTS:
                    staticComponents = ['ModalContentOptions', 'ModalComments'];
                    break;
                case MODAL_TYPE.PRINT_REPORTS_SEPARATE:
                case MODAL_TYPE.PRINT_REPORTS_COMBINED:
                    staticComponents = ['ModalTabbedContent'];
                    tabbedComponents = [
                        {
                            label: 'PopupBuilder.tabbedContent.basicFormatting',
                            type: MODAL_TAB_TYPE.BASIC,
                            content: ['ModalContentOptions', 'ModalComments'],
                        },
                        {
                            label: 'PopupBuilder.tabbedContent.advancedFormatting',
                            type: MODAL_TAB_TYPE.ADVANCED,
                            content: ['ModalDescription', 'ModalReorderItems'],
                        },
                    ];

                    break;

                case MODAL_TYPE.DOWNLOAD_BATCH_SCREENING:
                case MODAL_TYPE.DOWNLOAD_BATCH_SCREENING_SNAPSHOT:
                    staticComponents = ['ModalFileFormat', 'ModalFileName'];
                    break;
                case MODAL_TYPE.DOWNLOAD_ENTITIES:
                    staticComponents = ['ModalDownloadEntitiesFormat', 'ModalFileName'];
                    break;
                case MODAL_TYPE.DOWNLOAD_REPORTS:
                    staticComponents = [
                        'ModalDescription',
                        'ModalContentOptions',
                        'ModalReportType',
                        'ModalFileFormat',
                        'ModalComments',
                        'ModalFileName',
                    ];
                    break;
                case MODAL_TYPE.DOWNLOAD_REPORTS_SEPARATE:
                case MODAL_TYPE.DOWNLOAD_REPORTS_COMBINED:
                    staticComponents = ['ModalTabbedContent'];
                    tabbedComponents = [
                        {
                            label: 'PopupBuilder.tabbedContent.basicFormatting',
                            type: MODAL_TAB_TYPE.BASIC,
                            content: [
                                'ModalDescription',
                                'ModalContentOptions',
                                'ModalFileFormat',
                                'ModalFileName',
                                'ModalComments',
                            ],
                        },
                        {
                            label: 'PopupBuilder.tabbedContent.advancedFormatting',
                            type: MODAL_TAB_TYPE.ADVANCED,
                            content: ['ModalDescription', 'ModalReorderItems'],
                        },
                    ];

                    break;
                case MODAL_TYPE.EMAIL_REPORTS:
                    staticComponents = ['ModalContentOptions', 'ModalFileFormat', 'ModalEmail'];
                    break;
                case MODAL_TYPE.EMAIL_REPORTS_SEPARATE:
                case MODAL_TYPE.EMAIL_REPORTS_COMBINED:
                    staticComponents = ['ModalTabbedContent'];
                    tabbedComponents = [
                        {
                            label: 'PopupBuilder.tabbedContent.basicFormatting',
                            type: MODAL_TAB_TYPE.BASIC,
                            content: ['ModalContentOptions', 'ModalFileFormat', 'ModalEmail'],
                        },
                        {
                            label: 'PopupBuilder.tabbedContent.advancedFormatting',
                            type: MODAL_TAB_TYPE.ADVANCED,
                            content: ['ModalDescription', 'ModalReorderItems'],
                        },
                    ];
                    break;
                case MODAL_TYPE.ELASTIC_EMAIL_DOCUMENT:
                    staticComponents = ['ModalFileFormat', 'ModalEmail'];
                    break;
                case MODAL_TYPE.EMAIL_RESULT_LIST:
                    staticComponents = ['ModalResultList'];
                    dynamicComponents = ['ModalContentOptions', 'ModalFileFormat', 'ModalEmail'];
                    break;
                case MODAL_TYPE.DOWNLOAD_RESULT_SNAPSHOT:
                case MODAL_TYPE.DOWNLOAD_BATCH_SCREENING_FULL_REPORT:
                    staticComponents = [
                        'ModalDescription',
                        'ModalContentOptions',
                        'ModalFileFormat',
                        'ModalComments',
                        'ModalFileName',
                    ];
                    break;
                case MODAL_TYPE.ELASTIC_DOWNLOAD_DOCUMENT:
                    staticComponents = ['ModalFileFormat', 'ModalComments', 'ModalFileName'];
                    break;
                case MODAL_TYPE.DOWNLOAD_RESULT_LIST:
                    staticComponents = ['ModalResultList'];
                    dynamicComponents = ['ModalContentOptions', 'ModalFileFormat', 'ModalComments', 'ModalFileName'];
                    break;
                case MODAL_TYPE.ADD_TO_REPORT:
                    staticComponents = ['ModalResultList'];
                    break;
                case MODAL_TYPE.PRINT_REPORT_CATEGORY:
                    staticComponents = ['ModalActionTitle', 'ModalContentOptions', 'ModalComments'];
                    break;
                case MODAL_TYPE.DOWNLOAD_REPORT_CATEGORY:
                    staticComponents = [
                        'ModalActionTitle',
                        'ModalContentOptions',
                        'ModalFileFormat',
                        'ModalComments',
                        'ModalFileName',
                    ];
                    break;
                case MODAL_TYPE.EMAIL_REPORT_CATEGORY:
                    staticComponents = ['ModalActionTitle', 'ModalContentOptions', 'ModalFileFormat', 'ModalEmail'];
                    break;
                case MODAL_TYPE.DOWNLOAD_REPORT:
                    staticComponents = ['ModalDescription', 'ModalContentOptions', 'ModalFileName', 'ModalFileFormat'];
                    break;
                case MODAL_TYPE.USER_PREFERENCES_EDIT_NEGATIVE_SEARCH_TERMS:
                    staticComponents = ['ModalExplanation', 'ModalEditNegativeSearchTerms', 'ConfirmationModal'];
                    break;
                case MODAL_TYPE.EDIT_ARTICLE_NOTE:
                    staticComponents = ['ModalPreviewArticle', 'ModalEditArticleNote'];
                    break;
                case MODAL_TYPE.PREVIEW_UBO_ARTICLE:
                case MODAL_TYPE.PREVIEW_ARTICLE:
                    staticComponents = ['ModalPreviewArticle'];
                    break;
                case MODAL_TYPE.DELETE_ALERT:
                case MODAL_TYPE.DELETE_PUBLIC_RECORD_ALERT:
                case MODAL_TYPE.DELETE_PUBLIC_RECORD_ALERTS:
                    staticComponents = ['ModalActionTitle', 'ModalDescription'];
                    break;
                case MODAL_TYPE.DELETE_ALERTS:
                case MODAL_TYPE.USER_PREFERENCES_DELETE_CUSTOM_NEWS_QUERY:
                    staticComponents = ['ModalActionTitle', 'ModalDescription'];
                    break;
                case MODAL_TYPE.ADHOC_SEARCH:
                    staticComponents = [
                        'ModalActionTitle',
                        'ModalInformation',
                        'ModalAdHocSearch',
                        'ModalUpdateCheckbox',
                    ];
                    break;
                case MODAL_TYPE.DELETE_REPORT:
                case MODAL_TYPE.DELETE_REPORT_NOTE:
                case MODAL_TYPE.DELETE_DOCUMENT_FROM_REPORT:
                case MODAL_TYPE.DELETE_NOTE_FROM_DOCUMENT:
                case MODAL_TYPE.SHOW_SNAPSHOT:
                    staticComponents = ['ModalDescription'];
                    break;
                case MODAL_TYPE.APPLY_ADMIN_CHANGES:
                case MODAL_TYPE.DISCARD_ADMIN_CHANGES:
                    staticComponents = ['ModalDescription'];
                    break;
                case MODAL_TYPE.USER_PREFERENCES_ADD_CUSTOM_NEWS_QUERY:
                case MODAL_TYPE.USER_PREFERENCES_EDIT_CUSTOM_NEWS_QUERY:
                case MODAL_TYPE.USER_PREFERENCES_VIEW_CUSTOM_NEWS_QUERY:
                    staticComponents = ['ModalEditableTitle', 'ModalDescription', 'ModalEditCustomNewsQuery'];
                    break;
                case MODAL_TYPE.DOWNLOAD_HISTORY:
                    staticComponents = ['ModalActionTitle', 'ModalItemsList'];
                    dynamicComponents = ['ModalFileFormat', 'ModalFileName'];
                    break;
                case MODAL_TYPE.DOWNLOAD_SNAPSHOT_PAGE:
                    staticComponents = ['ModalFileFormat', 'ModalFileName'];
                    break;
                case MODAL_TYPE.APPLY_RISK_SCORES_PREFERENCE:
                    staticComponents = ['ModalRiskScores'];
                    break;
                case MODAL_TYPE.DELETE_ALL_HISTORY:
                    staticComponents = ['ModalDeleteAllHistory'];
                    break;
                case MODAL_TYPE.UPLOAD_ENTITIES_COMPANY_SOURCES_WITH_COMPANIES:
                    staticComponents = ['ModalDescription'];
                    break;
                case MODAL_TYPE.UPLOAD_ENTITIES_COMPANY_SOURCES_WITHOUT_COMPANIES:
                    staticComponents = ['ModalDescription'];
                    break;
                case MODAL_TYPE.SAVE_SEARCH:
                    staticComponents = ['ModalSaveSearch'];
                    break;
                case MODAL_TYPE.CREATE_ALERTS:
                    staticComponents = [
                        'ModalEmailsList',
                        'ModalComments',
                        'ModalAlertContentSelection',
                        'ModalAlertFrequency',
                        'ModalAlertGDPRMessage',
                        'ModalAnonymizedWarning',
                    ];
                    break;
                case MODAL_TYPE.CHANGE_COLUMNS_VISIBILITY_MULTIPLE_ENTITIES:
                    dynamicComponents = ['ModalColumnsList'];
                    break;
                case MODAL_TYPE.EDIT_SEARCH_MULTIPLE_ENTITIES:
                    staticComponents = [
                        'ModalEntityLabel',
                        'ModalEntitySearchTerm',
                        'ModalEntityType',
                        'ModalEntitySelectFilters',
                    ];
                    break;
                case MODAL_TYPE.UPLOAD_ENTITIES:
                    staticComponents = [
                        'BatchUploadDragAndDrop',
                        'ModalUploadEntities',
                    ];
                    break;
                case MODAL_TYPE.SHARE_VIEW:
                    staticComponents = ['ShareViewSearchbar', 'ShareViewUserSelect'];
                    break;
            }

            components = [...staticComponents, ...dynamicComponents];

            if (isWarningEnabled) components.push(...warningComponents);

            if (isDismissibleMessageEnabled) components.push(...dismissibleMessageComponents);

            return {
                components,
                staticComponents,
                dynamicComponents,
                warningComponents,
                tabbedComponents,
                dismissibleMessageComponents,
            };
        }

        class Builder {
            constructor(popupType) {
                this.popupType = popupType;
            }

            setPopupProperty(propertyName, value) {
                this[propertyName] = value;
                return this;
            }

            build() {
                if (!this.title) {
                    this.title = getTitle(this.popupType);
                }
                if (this.buttons === undefined) {
                    this.buttons = getButtons(this.popupType, this.buttonIsDisabled);
                }
                if (this.components === undefined) {
                    let allComponents = setPopupComponents(
                        this.popupType,
                        this.isDismissibleMessageEnabled,
                        this.warning
                    );

                    this.components = allComponents.components;
                    this.dynamicComponents = allComponents.dynamicComponents;
                    this.staticComponents = allComponents.staticComponents;
                    this.warningComponents = allComponents.warningComponents;
                    this.tabbedComponents = allComponents.tabbedComponents;
                    this.dismissibleMessageComponents = allComponents.dismissibleMessageComponents;
                }
                if (this.docType === undefined) {
                    this.docType = DOC_TYPE.PDF;
                }

                switch (this.popupType) {
                    case MODAL_TYPE.DOWNLOAD_RESULT_LIST:
                    case MODAL_TYPE.DOWNLOAD_RESULT_SNAPSHOT:
                    case MODAL_TYPE.DOWNLOAD_BATCH_SCREENING_FULL_REPORT:
                    case MODAL_TYPE.EMAIL_RESULT_LIST:
                    case MODAL_TYPE.PRINT_RESULT_LIST:
                        this.resultListType =
                            this.articles && this.articles.length > 0 ? SELECTED_ARTICLES_REPORT : ALL_ARTICLES_REPORT;
                        break;
                    case MODAL_TYPE.ADD_TO_REPORT:
                        this.resultListType =
                            this.articles && this.articles.length > 0
                                ? SELECTED_ARTICLES_REPORT
                                : this.totalArticlesCount === 0
                                ? NO_DOCUMENTS_FOUND
                                : NO_DOCUMENTS_OF_INTEREST;
                        break;
                    case MODAL_TYPE.USER_PREFERENCES_EDIT_NEGATIVE_SEARCH_TERMS:
                    case MODAL_TYPE.USER_PREFERENCES_ADD_CUSTOM_NEWS_QUERY:
                    case MODAL_TYPE.USER_PREFERENCES_EDIT_CUSTOM_NEWS_QUERY:
                    case MODAL_TYPE.USER_PREFERENCES_VIEW_CUSTOM_NEWS_QUERY:
                        this.popupSize = POPUP_SIZE_LARGE;
                        break;
                    case MODAL_TYPE.ADHOC_SEARCH:
                    case MODAL_TYPE.CREATE_ALERTS:
                    case MODAL_TYPE.EDIT_SEARCH_MULTIPLE_ENTITIES:
                        this.popupSize = POPUP_SIZE_SMALL;
                        break;
                    case MODAL_TYPE.DOWNLOAD_HISTORY:
                        let areParticularItemsSelected =
                            this.items &&
                            this.items.length > 0 &&
                            this.totalArticlesCount &&
                            this.items.length !== this.totalArticlesCount;

                        this.resultListType = areParticularItemsSelected ? SELECTED_ITEMS : ALL_ITEMS_LIST;
                        break;
                    case MODAL_TYPE.DELETE_ALL_HISTORY:
                    case MODAL_TYPE.CHANGE_COLUMNS_VISIBILITY_MULTIPLE_ENTITIES:
                        this.popupSize = POPUP_SIZE_SMALL;
                        break;
                    case MODAL_TYPE.APPLY_RISK_SCORES_PREFERENCE:
                        this.popupSize = POPUP_SIZE_LARGE;
                        break;
                    case MODAL_TYPE.SAVE_SEARCH:
                        this.popupSize = POPUP_SIZE_SMALL;
                        break;
                    case MODAL_TYPE.UPLOAD_ENTITIES:
                        this.popupSize = POPUP_SIZE_SMALL;
                        break;
                    case MODAL_TYPE.SHARE_VIEW:
                        this.popupSize = POPUP_SIZE_EXTRA_SMALL;
                        break;
                }
                return new PopupModel(this);
            }
        }

        return Builder;
    }
}

export default PopupModel;
