import React, { Component } from 'react';
import CheckboxFilter from '../CheckboxFilter';

export default class SourceTypePostFilter extends Component {
    render() {
        return (
            <div className={'source-type-postfilter'}>
                <CheckboxFilter {...this.props} fieldId={'source-type-postfilter'} />
            </div>
        );
    }
}
