/**
 * 404 PAGE TEMPLATE
 *
 *
 * @version 0.1
 */

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as BannerWarningIcon } from '@assets/icons/BannerWarningIcon.svg';
import { ReactComponent as BannerWarningIconDark } from '@assets/icons/BannerWarningIconDark.svg';

function PageFailLogin({isDarkMode}) {
    let logout = () => {
        let dilSettings = JSON.parse(localStorage.getItem('diligence'));
        let auth = dilSettings.authInfo;

        window.location =
            auth.wamLogout +
            '&redirecturl=' +
            encodeURIComponent(auth.customerUi) +
            '&lnsi=' +
            dilSettings.authInfo.lnsi;
    };

    return (
        <div className="error-404">
            <div className={`embedded-message ${isDarkMode ? 'dark-mode' : ''}`}>
                <div className="embedded-message__icon">
                    {isDarkMode ? <BannerWarningIconDark/> : <BannerWarningIcon/>}
                </div>
                <div className="embedded-message__message">
                    <FormattedMessage id="General_CoreFunctionality_Error_error.FailedLoginTitle"/>
                </div>
            </div>
            <br/>
            <button className="button-secondary-sm" id="qa-error-page-button" onClick={logout}>
                <FormattedMessage id="HeaderFooterNav_CoreFunctionality_Dropdown_nav.user.signout" />
            </button>
        </div>
    );
}

export default PageFailLogin;
