import React, { useState, useEffect, useRef, useCallback } from 'react';
import { FormattedMessage, injectIntl} from 'react-intl';
import formatRichMessage from 'scripts/utils/formatRichMessage';
import ActionButton from '../ActionButton/ActionButton';
import ReactTooltip from 'react-tooltip';
import {
    MULTIPLE_ENTITIES_NONE_SELECTED_VIEW,
    SEARCH_ENTITY_INITIAL_VALUE,
    BATCH_SCREENING_PAGE_SIZE,
    VIEWNAME_CHARACTERS_LIMIT,
} from '@constants';
import utils from '@utils/utilities';

const initialState = {isValid : true, errorMessage: ''};

const CreateViewFilter = ({
    selectedView,
    setSelectedView,
    views,
    selectedItems,
    createView,
    deleteView,
    changeView,
    resetView,
    updateView,
    lastSelectedView,
    searchEntity,
    setSearchEntity,
    selectAllSetting,
    intl,
}) => {
    // refs
    const popoverRef = useRef(null);
    const createNewViewRef = useRef(null);

    // state
    const [isOpen, setIsOpen] = useState(false);
    const [viewName, setViewName] = useState('');
    const [validationState, setValidationState] = useState(initialState);

    // constants
    const noViewSelected = lastSelectedView === MULTIPLE_ENTITIES_NONE_SELECTED_VIEW;
    const isViewSelectionMade = !!selectedView.id || !noViewSelected;
    const defaultViewText = isViewSelectionMade
        ? selectedView.name
        : formatRichMessage({ id: 'BatchScreening.page.manageViews' });
    const manageViewsText = formatRichMessage({ id: 'BatchScreening.page.manageViews' });
    const lastSelectedViewItem =
        !lastSelectedView || noViewSelected ? {} : views.find((view) => view.id === lastSelectedView);
    const lastSelectedViewText = !lastSelectedViewItem ? '' : lastSelectedViewItem.name;

    const handleOpen = () => {
        setIsOpen((prevIsOpen) => !prevIsOpen);
        setValidationState(initialState)
        isOpen && setViewName('');
    };

    const handleClickOutside = useCallback(
        (event) => {
            if (
                popoverRef.current &&
                !popoverRef.current.contains(event.target) &&
                !createNewViewRef.current.contains(event.target)
            ) {
                setViewName('');
                handleOpen();
            }
        },
        [popoverRef, createNewViewRef]
    );

    const handleSelectView = (currentView) => {
        setSelectedView(currentView);
        changeView(currentView);
        setSearchEntity(SEARCH_ENTITY_INITIAL_VALUE);
    };

    const handleDeleteView = (viewId) => {
        if (!noViewSelected && lastSelectedView === viewId) {
            resetView();
        }
        setSearchEntity(SEARCH_ENTITY_INITIAL_VALUE);
        deleteView(viewId);
    };

    const handleUpdateView = (viewId, selectedItems) => {
        handleOpen();

        const isForAll = selectedItems.length >= BATCH_SCREENING_PAGE_SIZE && selectAllSetting.includeDB;
        const updateViewPayload = {
            currentViewId: lastSelectedView,
            searchTerm: searchEntity,
            selectedItems,
            isForAll,
            viewId,
        };

        updateView(updateViewPayload);
    };

    const handleViewNameChange = (event) => {
        const inputText = event.target.value;
        const validationResult = utils.validateViewName(inputText, initialState, intl);

        setViewName(inputText.substring(0, VIEWNAME_CHARACTERS_LIMIT));
        setValidationState(validationResult);
    };

    useEffect(() => {
        isOpen && document.addEventListener('mousedown', handleClickOutside);
        ReactTooltip.rebuild();

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [handleClickOutside, isOpen]);

    // Close view dropdown when selected view changes
    useEffect(() => {
        if (defaultViewText && isOpen) setIsOpen(false);
    }, [defaultViewText]);

     const remainingCharactersCount = VIEWNAME_CHARACTERS_LIMIT - viewName.length;
     const remainingCharactersMessage = formatRichMessage(
         { id: 'BatchScreening.page.createViews.ViewNameValidation.RemainingCharacters' },
         intl,
         { count: remainingCharactersCount }
     );

    return (
        <div className="create-view-filter-container">
            <div
                ref={createNewViewRef}
                className={`view-name-container ${isOpen ? 'active' : ''}`}
                onClick={handleOpen}
                data-track="create-view-filter-button"
                data-testid="create-view-filter-button"
            >
                <p>{lastSelectedViewText || defaultViewText}</p>
                <div className={isOpen ? 'la-TriangleUp active' : 'la-TriangleDown'} />
            </div>
            <ReactTooltip
                id="manageViews-tooltip"
                type="light"
                event="mouseenter"
                eventOff="mouseleave"
                border={true}
                effect="solid"
                className="tooltips small"
                place="right"
            />
            <ReactTooltip
                id="addView-tooltip"
                type="light"
                event="mouseenter"
                eventOff="mouseleave"
                border={true}
                effect="solid"
                className="tooltips small"
                place="top"
            />

            {isOpen && (
                <>
                    <ReactTooltip
                        id="updateView-tooltip"
                        type="light"
                        event="mouseenter"
                        eventOff="mouseleave"
                        border={true}
                        effect="solid"
                        className="tooltips"
                        place="left"
                        delayShow={1000}
                    />
                    <div ref={popoverRef} className="create-view-popover" data-testid="create-view-popover">
                        <div className="create-view-popover__header">
                            <div className="create-view-popover__header__title">
                                <h4>{manageViewsText}</h4>
                                <span
                                    className="la-InfoFilled information-icon"
                                    data-for="manageViews-tooltip"
                                    data-tip={formatRichMessage({
                                        id: 'BatchScreening.page.manageViews.popup.text',
                                    })}
                                />
                            </div>
                            <button
                                className="create-view-popover__header__buttons__reset-view"
                                data-track="create-view-popover-reset-view-button"
                                data-testid="create-view-popover-reset-view-button"
                                disabled={!isViewSelectionMade}
                                onClick={resetView}
                            >
                                <FormattedMessage id="BatchScreening.page.createViews.resetView" />
                            </button>
                        </div>

                        <div className="create-view-popover__container">
                            <span
                                className="characters-count remaining"
                                data-testid="characters-count-remaining"
                            >
                                {remainingCharactersMessage}
                            </span>
                            <div className="create-view-popover__container__input">
                                <input
                                    type="text"
                                    className={`text-field-standard-sm ${
                                        !validationState.isValid ? 'text-field-error' : ''
                                    }`}
                                    data-track="create-view-popover-input"
                                    data-testid="create-view-popover-input"
                                    value={viewName}
                                    onChange={handleViewNameChange}
                                />
                                <button
                                    className="create-view-popover__input__add-button button-secondary-lg"
                                    data-track="create-view-popover-add-new-button"
                                    data-testid="create-view-popover-add-new-button"
                                    data-for="addView-tooltip"
                                    data-tip={formatRichMessage({
                                        id: 'General.label.add',
                                    })}
                                    disabled={!viewName.length}
                                    onClick={() => {
                                        createView(viewName);
                                        setViewName('');
                                        setValidationState(initialState);
                                    }}
                                >
                                    <span className="la-ZoomIn icon"></span>
                                </button>
                            </div>

                            <span
                                className="characters-count max-limit"
                                data-testid="characters-count-max-limit"
                            >
                                    {validationState.errorMessage}
                            </span>
                        </div>
                        <div className="create-view-popover__saved-views">
                            <h5>
                                <FormattedMessage id="BatchScreening.page.createViews.savedViews" />
                            </h5>
                            <div className="create-view-popover__saved-views__list">
                                {views.map((view) => {
                                    const isUpdateDisabled = !selectedItems.length || view.id === lastSelectedView;
                                    return (
                                        <div key={view.id} className="create-view-popover__saved-views__list__item">
                                            <p onClick={() => handleSelectView(view)}>{view.name}</p>
                                            <div className="saved-views-list-item-actions">
                                                <ActionButton
                                                    disabled={isUpdateDisabled}
                                                    testid={`create-view-popover-update-view-${view.name}`}
                                                    dataFor={isUpdateDisabled ? null : 'updateView-tooltip'}
                                                    dataTip={formatRichMessage({
                                                        id: 'BatchScreening.page.updateView.popup.text',
                                                    })}
                                                    handleClick={() => handleUpdateView(view.id, selectedItems)}
                                                    className={`la-UpdateView ${isUpdateDisabled ? 'disabled' : ''}`}
                                                />
                                                <ActionButton
                                                    testid={`create-view-popover-saved-views-list-item-${view.name}`}
                                                    className="la-Delete"
                                                    handleClick={() => handleDeleteView(view.id)}
                                                />
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default injectIntl(CreateViewFilter);
