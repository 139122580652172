import React from 'react';
import { SANCTIONS_TABLE_COLUMN, SANCTIONS_TABLE_CELL_TYPE } from '@constants';
import { headerStyleObj, cellStyleObj } from '@reusable/GridTable/styleObjects';
import { FormattedMessage } from 'react-intl';

const { RISK, AUTHORITY_LIST_AND_SOURCE, DOCUMENT_NAME, PUB_DATE, RESULTS } = SANCTIONS_TABLE_COLUMN;

export const getSanctionColumnMap = (handleSortClick, sortColumn, sortDirection) => {
    return {
        riskScore: (
            <span data-testid="sanctions-pod-table-header-sort-risk" className={'sanctions-pod-sort-container'} onClick={() => handleSortClick('riskLevel')}>
                <FormattedMessage id="SanctionsAndWatchlists.snapshot.table.risk" />
                {SortComponent(sortColumn, sortDirection, 'riskLevel')}
            </span>
        ),
        sanctionsDocumentMetadata: (
            <span>
                <FormattedMessage id="SanctionsAndWatchlists.snapshot.table.listAuthorityAndSource" />
            </span>
        ),
        publishedOn: (
            <span data-testid="sanctions-pod-table-header-sort-published-on" className={'sanctions-pod-sort-container'} onClick={() => handleSortClick('publishedOn')}>
                <FormattedMessage id="SanctionsAndWatchlists.snapshot.table.pubDate" />
                {SortComponent(sortColumn, sortDirection, 'publishedOn')}
            </span>
        ),
        documentName: (
            <span>
                <FormattedMessage id="SanctionsAndWatchlists.snapshot.table.documentName" />
            </span>
        ),
        results: (
            <span>
                <FormattedMessage id="SanctionsAndWatchlists.snapshot.table.results" />
            </span>
        ),
    };
};

export const SortComponent = (sortColumn, sortDirection, columnKey) => {
    return (
      <span style={{ display: 'flex', flexDirection: 'column', marginLeft: '1rem', marginTop:'0.1rem' }}>
          <span style={{marginBottom: '-1rem'}}>
              <svg width="10" height="10" viewBox="-2 -2 10.67 10.67">
                  <path d="M3.33 0 L6.67 5.77 L0 5.77 Z" fill={(sortDirection === 'desc' && columnKey === sortColumn) ? '#455A64' : 'none'} stroke="#455A64" strokeWidth="1.5" />
              </svg>
          </span>
          <span style={{marginTop: '-0.3rem'}}>
              <svg width="10" height="10" viewBox="-2 -2 10.67 10.67">
                  <path d="M3.33 6.67 L6.67 0.89 L0 0.89 Z" fill={(sortDirection === 'asc' && columnKey === sortColumn) ? '#455A64' : 'none'} stroke="#455A64" strokeWidth="1.5" />
              </svg>
          </span>
      </span>
    );
};

export const getSanctionsColumns = () => [
    {
        columnId: RISK,
        width: 145,
    },
    {
        columnId: AUTHORITY_LIST_AND_SOURCE,
        width: 585,
    },
    {
        columnId: PUB_DATE,
        width: 185,
    },
    {
        columnId: DOCUMENT_NAME,
        width: 375,
    },
    {
        columnId: RESULTS,
        width: 654,
    },
];

const getCellType = (key) => {
    switch (key) {
        case RISK:
            return SANCTIONS_TABLE_CELL_TYPE.RISK;
        case RESULTS:
            return SANCTIONS_TABLE_CELL_TYPE.RESULTS;
        case AUTHORITY_LIST_AND_SOURCE:
            return SANCTIONS_TABLE_CELL_TYPE.AUTHORITY_LIST_AND_SOURCE;
        case DOCUMENT_NAME:
            return SANCTIONS_TABLE_CELL_TYPE.RESULTS;
        default:
            return SANCTIONS_TABLE_CELL_TYPE.DEFAULT;
    }
};

export const getSanctionsRows = (data, columns, columnMap, onDocumentSnippetClick, hoveredRow, setHoveredRow) => {
    let rows = [
        {
            rowId: 'header',
            cells: columns.map((col) => ({
                type: SANCTIONS_TABLE_CELL_TYPE.HEADER,
                text: columnMap[col.columnId],
                nonEditable: true,
                style: {
                    ...headerStyleObj,
                    border: {
                        top: { width: '0' },
                        bottom: { width: '0' },
                        left: { width: '0' },
                        right: { width: '0' },
                    },
                },
            })),
            height: 50,
        },
    ];
    if (data.length) {
        rows = [
            ...rows,
            ...data.map((result, rowIndex) => ({
                rowId: rowIndex,
                cells: Object.keys(columnMap).map((key) => {
                    return {
                        type: getCellType(key),
                        columnId: key,
                        rowIndex: rowIndex,
                        text: {
                            content: getDocumentData(key, result) || '-',
                            onClick: () => onDocumentSnippetClick(result.documentId),
                            onMouseEnter: () => setHoveredRow(rowIndex),
                            onMouseLeave: () => setHoveredRow(null),
                        },
                        nonEditable: true,
                        style: cellStyleObj(rowIndex),
                    };
                }),
                height: 65,
            })),
        ];
    }

    return rows;
};

export const getDocumentData = (key, data) => {
    switch (key) {
        case AUTHORITY_LIST_AND_SOURCE:
            return { authority: data.authorityName, list: data.list, source: data.source};
        case DOCUMENT_NAME:
            return data.title;
        case RISK:
            return data.riskLevel;
        default:
            return data[key];
    }
};
