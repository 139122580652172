import React, { useState } from 'react';
import { Link } from 'react-router';
import { useSelector } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import formatRichMessage from '@utils/formatRichMessage';
import { withMIPUserHideView } from '@utils/contexts';
import SuperscriptTag from '../Tag/SuperscriptTag';
import { ROUTES, SUPERSCRIPT_TAG_WIDTH_LANGUAGE_MAP, DEFAULT_LANGUAGE } from '@constants';
import MarketingTooltip from '@reusable/MarketingTooltip/MarketingTooltip';
import MarketingOverlayModal from '@reusable/CustomModals/MarketingOverlayModal/MarketingOverlayModal';
import utils from '@utils/utilities';

const EntityViewLink = ({ isEnabled, intl }) => {
    const shouldDisplayEntityViewMarketingInfo = useSelector(
        (state) => state.user.appSettings?.shouldDisplayEntityViewMarketingInfo
    );
    const isSpecifiedCustomer = useSelector((state) => state.user.appSettings.isSpecifiedCustomer);
    const currentLanguage = useSelector((state) => state.user.preferences?.language);
    const isMultipleEntitiesTrial = useSelector((state) => state.user.appSettings.isMultipleEntitiesTrial);

    const [isMarketingOverlayOpen, setIsMarketingOverlayOpen] = useState(false);

    const tradeMark = formatRichMessage({ id: 'Trademark_symbol_superscript' }, intl);
    const upgradeText = formatRichMessage({ id: 'Marketing_overlay.Diligence_plus_upgrade_text' }, intl);

    const isSpecifiedCustomerWithoutAuthorizationOrTrial =
        isSpecifiedCustomer && !isEnabled && !isMultipleEntitiesTrial;

    const shouldDisplayUpgradeTag = isSpecifiedCustomerWithoutAuthorizationOrTrial;

    const upgradeTagSuperScriptStyle =
        SUPERSCRIPT_TAG_WIDTH_LANGUAGE_MAP[currentLanguage] || SUPERSCRIPT_TAG_WIDTH_LANGUAGE_MAP[DEFAULT_LANGUAGE];

    const isMarketingTooltipVisible =
        isSpecifiedCustomerWithoutAuthorizationOrTrial && shouldDisplayEntityViewMarketingInfo;

    const handleMarketingOverlayClose = () => {
        if (isMarketingOverlayOpen) {
            setIsMarketingOverlayOpen(false);
            utils.hideEntityViewMarketingInfo();
        }
    };

    const handleOpenMarketingOverlay = (e) => {
        if (!isSpecifiedCustomerWithoutAuthorizationOrTrial) {
            return;
        }
        e.preventDefault();
        e.stopPropagation();
        setIsMarketingOverlayOpen(true);
    };

    return (
        <>
            <li className="diligence-header__menu-item diligence-header__menu-item__batch-upload notranslate">
                <Link to={ROUTES.SCREENING} onClick={handleOpenMarketingOverlay}>
                    <MarketingTooltip
                        direction="bottom"
                        content={`${formatRichMessage({ id: 'BatchScreening.marketing.diligencePlusTooltip' }, intl, {
                            tradeMarkSymbol: tradeMark,
                        })}`}
                        isActive={isMarketingTooltipVisible}
                    >
                        <div
                            className="diligence-header__menu-link"
                            data-track="diligence-header__menu-link__batch-upload"
                        >
                            <span className="diligence-header__menu-link__batch-upload">
                                <FormattedMessage id="BatchScreening.page.title" />
                            </span>
                            {shouldDisplayUpgradeTag && (
                                <SuperscriptTag
                                    text={upgradeText}
                                    name={'red-gradient-tag'}
                                    style={upgradeTagSuperScriptStyle}
                                />
                            )}
                        </div>
                    </MarketingTooltip>
                </Link>
            </li>
            <MarketingOverlayModal
                isOpen={isMarketingOverlayOpen}
                onClose={handleMarketingOverlayClose}
                currentLanguage={currentLanguage}
            />
        </>
    );
};

export default injectIntl(withMIPUserHideView(EntityViewLink));
