/**
 * MODAL ENTRY POINT
 *
 * This component can be used to display a modal with custom content and custom
 * buttons. The buttons can have event handlers attached if specified.
 *
 * @version 0.1
 */

import React from 'react';
import ReactDOM from 'react-dom';
import FocusTrap from 'focus-trap-react';
import { Loader } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import { POPUP_SIZE_SMALL } from '@constants';

class Modal extends React.Component {
    constructor(props) {
        super(props);
        this.handlerEscape = this.handlerEscape.bind(this);
    }

    handlerEscape(event) {
        if (event.key === 'Escape' || event.key === 'Esc' || event.keyCode === 27) this.props.closeButtonHandler();
    }

    render() {
        let buttons = [];

        if (this.props.buttons) {
            for (let i = 0; i < this.props.buttons.length; i++) {
                let button = this.props.buttons[i],
                    classes = button.primary ? 'button-primary-' : 'button-secondary-';
                classes += 'sm';
                classes += button.isDisabled ? ' disabled' : '';
                classes += button.primary && button.loading ? ' loading' : '';

                buttons.push(
                    <button
                        type="button"
                        key={'app-modal-button-' + Math.random() + '-' + i}
                        data-testid={button.testid}
                        className={classes + ' notranslate'}
                        disabled={button.isDisabled}
                        onClick={button.click ? button.click.bind(this) : null}
                        tabIndex={button.tabIndex ? button.tabIndex : null}
                        data-track={button.dataTrack}
                    >
                        {button.primary && button.loading ?
                            <Loader size={'medium'} inline="centered" active inverted /> :
                            <FormattedMessage id={button.text} />}
                    </button>
                );
            }
        }

        const modalButtons = <div className="app-modal__buttons app-modal__buttons__right-aligned">{buttons}</div>;
        const modalPortal = document.getElementById('modal-portal');
        const modalContent = (
            <FocusTrap
                focusTrapOptions={{
                    fallbackFocus: '#dialog',
                }}
            >
                <div id="dialog" tabIndex="-1" className={`app-modal app-modal__for-${this.props.popupType}`}>
                    <div
                        className={`app-modal__wrapper ${this.props.className} ${this.props.popupType}`}
                        onKeyDown={this.handlerEscape}
                    >
                        <div
                            className={
                                this.props.popupSize
                                    ? 'app-modal__content-box ' + this.props.popupSize
                                    : 'app-modal__content-box ' + POPUP_SIZE_SMALL
                            }
                        >
                            <div className="app-modal__content">
                                {typeof this.props.closeButtonHandler === 'function' ? (
                                    <button
                                        type="button"
                                        className="la-CloseRemove app-modal__close-button"
                                        onClick={this.props.closeButtonHandler}
                                        tabIndex={buttons.length ? '-1' : '1'}
                                    ></button>
                                ) : null}

                                {this.props.children}
                            </div>
                            {buttons.length > 0 ? modalButtons : null}
                        </div>
                    </div>
                </div>
            </FocusTrap>
        );

        return ReactDOM.createPortal(modalContent, modalPortal);
    }
}

export default Modal;
