import React, { useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { useSelector } from 'react-redux';
import { injectIntl } from 'react-intl';
import formatRichMessage from '@utils/formatRichMessage';
import { isEntityUploadLimitReached } from '@pages/EntityView/utils/validations';
import { returnAllEntitiesCount } from '@pages/EntityView/EntityViewHelper';

const AddToEntityViewButton = ({ onClickHandler, isButtonDisabled, intl }) => {
    const entityUploadLimit = useSelector((state) => state.user.preferences.generalSettings.entityUploadLimit);
    const isMultipleEntitiesTrial = useSelector((state) => state.user.appSettings.isMultipleEntitiesTrial);

    const [entityViewCount, setEntityViewCount] = useState(0);

    const isEntityLimitReached = isEntityUploadLimitReached(
        entityViewCount,
        entityUploadLimit,
        isMultipleEntitiesTrial
    );

    useEffect(() => {
        returnAllEntitiesCount().then((count) => setEntityViewCount(count));
    }, []);

    const handleClick = () => {
        if (!isEntityLimitReached) {
            onClickHandler();
        }
    };

    return (
        <>
            <span
                data-tip={`${formatRichMessage(
                    { id: 'BatchScreening.addToMultipleEntities.uploadLimitReached' },
                    intl,
                    {
                        limit: entityUploadLimit,
                    }
                )}`}
                data-for="add-to-multiple-entites-tooltip"
            >
                <button
                    className="add-to-report-btn add-to-multiple-entities-btn"
                    type="button"
                    disabled={isButtonDisabled || isEntityLimitReached}
                    onClick={handleClick}
                    data-track="add-to-multiple-entities"
                    data-testid="add-to-multiple-entities-id"
                >
                    <span className="la-ZoomIn icon" />
                    {formatRichMessage({ id: `General_CoreFunctionality_UIText_general.addToMultipleEntities` }, intl)}
                </button>
            </span>
            {isEntityLimitReached && (
                <ReactTooltip
                    id="add-to-multiple-entites-tooltip"
                    type="light"
                    border={true}
                    effect="solid"
                    className="tooltips"
                    place="bottom"
                />
            )}
        </>
    );
};

export default injectIntl(AddToEntityViewButton);
