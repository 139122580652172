import React from 'react';
import { injectIntl } from 'react-intl';
import { FormattedMessage } from 'react-intl';
import TabbedContent from '@reusable/TabbedContent/TabbedContent';
import { NEGATIVE_NEWS_LANGUAGES, NEGATIVITY_LEVELS_ORDER } from '@constants';
import formatRichMessage from '@utils/formatRichMessage';
import {useSelector} from "react-redux";
import { ReactComponent as BannerInfoIcon } from '../../../../assets/icons/BannerInfoIcon.svg';
import { ReactComponent as BannerInfoIconDark } from '../../../../assets/icons/BannerInfoIconDark.svg';

function ModalEditNegativeSearchQuery(props) {
    if (!props.isVisible) {
        return <span />;
    }

    const onChange = (event, queryType, language) => {
        props.changeCustomSearchQuery(event.target.value, queryType, language);
    };

    const resetQuery = (even, queryType, language) => {
        props.resetCustomSearchQuery(queryType, language);
    };

    const tabs = [];

    const isDarkMode = useSelector((state) => state.user.preferences.generalSettings.isDarkMode);
    const getContent = (type) => {
        const isQueryError =
            (props.queryError[type] && props.language !== NEGATIVE_NEWS_LANGUAGES.others.value) ||
            props.wildcardError[type];
        return (
            <div className="popup-modal__edit-negative-search-terms__textarea">
                {props.queryError[type] && props.language !== NEGATIVE_NEWS_LANGUAGES.others.value && (
                    <span className="popup-modal__edit-negative-search-terms__textarea__error-message">
                        <FormattedMessage id={'PopupBuilder.body.error.blankQuery'} />
                    </span>
                )}

                {props.wildcardError[type] && (
                    <span className="popup-modal__edit-negative-search-terms__textarea__error-message">
                        <FormattedMessage id={'SearchResults_Notifications.insufficientCharacters'} />
                    </span>
                )}

                {props.resetQuery[type] && (
                    <div className={`popup-modal__edit-negative-search-terms__textarea__reset-message embedded-message info${isDarkMode ? ' dark-mode' : ''}`}>
                        <div className="embedded-message__icon">
                            {isDarkMode ? <BannerInfoIconDark/> : <BannerInfoIcon/>}
                        </div>
                        <div className="embedded-message__message">
                            <FormattedMessage id={'PopupBuilder.body.reset.part1'}/>
                            &nbsp;
                            <span className="embedded-message__message__bolder">
                                <FormattedMessage id={'PopupBuilder.body.reset.part2'}/>
                            </span>
                        </div>
                    </div>
                )}

                <textarea
                    value={(props.query && props.query[type]) || ''}
                    onChange={(e) => onChange(e, type, props.language)}
                    placeholder={
                        props.language === NEGATIVE_NEWS_LANGUAGES.others.value
                            ? formatRichMessage(
                                  { id: 'PopupBuilder.body.placeholder.customSearchQuery.' + type },
                                  props.intl,
                                  { term: props.searchQueryType }
                              )
                            : undefined
                    }
                    data-track={'popup-modal__edit-negative-search-terms__textarea'}
                    className={isQueryError ? 'textarea-error' : ''}
                />

                {props.query[type] !== props.defaultQuery[type] && (
                    <button
                        className="popup-modal__edit-negative-search-terms__textarea__reset"
                        onClick={(e) => resetQuery(e, type, props.language)}
                    >
                        <FormattedMessage id={'PopupBuilder.explanation.editNegativeSearchTerms.reset'} />
                    </button>
                )}

                <div className="horizontal-divider" />
            </div>
        );
    };

    const orderedNegativityLevels = [];

    props.negativityLevels.forEach((level) => {
        const tabPosition = NEGATIVITY_LEVELS_ORDER.findIndex((item) => item === level);
        orderedNegativityLevels[tabPosition] = level;
    });

    orderedNegativityLevels.forEach((value) => {
        tabs.push({
            label: <FormattedMessage id={`UserPreferences.negativeTerms.negativityLevels.${value}`} />,
            content: getContent(value),
            dataTrack: `user-preferences-negativeTerms-negativityLevels-${value}`,
        });
    });

    return (
        <div className="popup-modal__edit-negative-search-terms">
            <TabbedContent visible={true} defaultTabIndex={0} tabs={tabs} />
        </div>
    );
}

export default injectIntl(ModalEditNegativeSearchQuery);
