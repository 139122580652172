import React, { Component } from 'react';
import CheckboxFilter from '../CheckboxFilter';
import { FILTER_SORT_OPTIONS, POST_FILTERS_DEFAULT_SORT, POSTFILTER_TYPE } from '@constants';

export default class SourcePostFilter extends Component {
    render() {
        let inlineFilterSortProperties = {
            defaultOption: POST_FILTERS_DEFAULT_SORT[POSTFILTER_TYPE.SOURCE],
            sortOptions: [
                {
                    value: FILTER_SORT_OPTIONS.HIGHEST,
                    label: 'General.postFilters.sortBy.highest',
                },
                {
                    value: FILTER_SORT_OPTIONS.ALPHABETICALLY_ASC,
                    label: 'General.postFilters.sortBy.alphabeticallyAscending',
                },
            ],
        };

        return (
            <div className={'source-postfilter'}>
                <CheckboxFilter
                    {...this.props}
                    fieldId={'source-postfilter'}
                    inlineFilterSortProperties={inlineFilterSortProperties}
                    className="with-inline-sort-filter"
                />
            </div>
        );
    }
}
