import React from 'react';
import { Segment } from 'semantic-ui-react';
import ReportNotes from '../sharedComponents/Notes/ReportNotes';
import ReportFooter from '../sharedComponents/Footer/ReportFooter';
import CategoryHeader from './contentComponents/CategoryHeader';
import ReportCategoryList from './contentComponents/ReportCategoryList';
import { compose } from 'redux';
import CategoriesOrder from './contentComponents/CategoriesOrder';
import { injectIntl } from 'react-intl';
import ConnectedReports from './contentComponents/ConnectedReports';
import { withLoadingSpinner } from '@reusable/HOC/withLoadingSpinner';
import { withToggleViewForExpandedReport } from '@ReportBuilder/HOC/withReportHoc';

const Content = (props) => {
    const { report } = props;

    return (
        <div className="regular-report">
            <Segment>
                <ConnectedReports
                    parentReports={report.parentReports}
                    isReportExpanded={props.isReportExpanded}
                    viewParentReport={props.viewParentReport}
                />

                {props.showCategories && props.isReportExpanded && (
                    <div className="report-list-categories">
                        <CategoriesOrder
                            report={report}
                            closeOrderCategoriesSection={props.closeOrderCategoriesSection}
                            updateReportProperty={props.updateReportProperty}
                            updateProperty={props.updateProperty}
                        />
                    </div>
                )}

                {report.categories &&
                    report.categories.map((category, index) => {
                        if (category.snippets && category.snippets.length > 0) {
                            return (
                                <div className="article-list-category-wrapper" key={index}>
                                    <CategoryHeader
                                        categoryName={category.categoryName}
                                        categoryLabel={category.categoryLabel}
                                        reportId={report.id}
                                        searchQuery={report.searchQuery}
                                        snippetsCount={category.snippets.length}
                                        language={props.language}
                                        index={index}
                                        emailAddress={props.emailAddress}
                                        contentsOptions={props.contentsOptions}
                                        showDeliveryOptions={props.showDeliveryOptions}
                                        numberOfDocumentsPerCategory={report.numberOfDocumentsPerCategory}
                                        loadingDocumentsCounts={report.loadingDocumentsCount}
                                        documentsCountsLoaded={report.documentsCountsLoaded}
                                        // auto-save props
                                        modifiedSections={props.modifiedSections}
                                        section={`${report.id}_${category.categoryName}`}
                                        autosaveHasError={props.autosaveHasError}
                                        onCategoryDelete={props.handleCategoryDeleteReport}
                                        categorySnippets={category.snippets}
                                    />
                                    <ReportCategoryList
                                        report={report}
                                        category={category}
                                        language={props.language}
                                        selectedReport={props.selectedReport}
                                        setSelectedArticle={props.setSelectedArticle}
                                        onSelectionChange={props.onSelectionChange}
                                        onUpdateReportBuilderPage={props.onUpdateReportBuilderPage}
                                        //autosave props
                                        showAutosaveMessage={props.showAutosaveMessage}
                                        removeAutosaveMessage={props.removeAutosaveMessage}
                                    />
                                </div>
                            );
                        }
                    })}
                <ReportNotes
                    editMode={!(report && report.note && report.note.length > 0)}
                    reportId={report.id}
                    notes={report.note || ''}
                    addNoteToReport={props.addNoteToReport}
                    deleteReportNote={props.showDownloadReportPopup}
                    index={props.index}
                />
                <ReportFooter
                    report={report}
                    handleDeleteReport={props.handleDeleteReport}
                    downloadReport={props.showDownloadReportPopup}
                    index={props.index}
                />
            </Segment>
        </div>
    );
};
export { Content as TestRegularReportContent };
export default compose(withLoadingSpinner, withToggleViewForExpandedReport, injectIntl)(Content);
