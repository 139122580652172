import React, { Fragment } from 'react';
import * as DilProps from '@utils/props';
import utils from '@utils/utilities';
import { debounce } from 'lodash';
import { DEBOUNCE_SEARCH_INPUT } from '@constants';
import FilterSearchBox from '@reusable/FilterSearchBox/FilterSearchBox';

/**
 * Handles search, adds a search box and provides search handlers
 * @param TableComponent
 * @returns {function(*)}
 */

const withSearch = (TableComponent) => {
    const withSearch = (props) => {
        const handleChangeSearchString = debounce((str) => {
            props.filtering.onChangeSearchString(str);
            handleSearch(str);
        }, DEBOUNCE_SEARCH_INPUT);

        const handleSearch = (searchString) => {
            // if it is paginated, jump to first page
            props.pagination && props.pagination.onChangePagination({ pageNumber: 0 });
            // Add the search string to the "nameFilter" from filters when submitting search
            props.filtering.onChangeFilters({ nameFilter: searchString }, props.data.onLoadData);
        };

        return (
            <Fragment>
                <FilterSearchBox handleSearch={handleSearch} setControlledInputValue={handleChangeSearchString} placeholder='FilterSearchBox.findAlert'/>
                <TableComponent {...props} />
            </Fragment>
        );
    };

    withSearch.propTypes = {
        filtering: DilProps.TableFiltering.isRequired,
        data: DilProps.TableData.isRequired,
        pagination: DilProps.TablePagination,
    };

    withSearch.displayName = `WithSearch(${utils.getDisplayName(TableComponent)})`;

    return withSearch;
};

export default withSearch;
