import { put, call } from 'redux-saga/effects';
import metricsActions from '@pages/MainSearch/redux/Metrics.actions';
import { requestWithRetry } from '../helpers/mainHelper';
import * as requests from '../requests/metricRequests';

export function* resetMetric() {
    yield put(metricsActions.resetMetric());
}

export function* setSearchStart(data) {
    yield put(metricsActions.setMetricStart({ ...data }));
}

export function* setSearchEnd(data) {
    yield put(metricsActions.setMetricEnd({ ...data }));
}

/**
 * Gather multiple metrics
 * Used to send metrics for multiple elements, metricData is an array
 */
export function* setGatherMetrics(metricData) {
    yield call(requestWithRetry, requests.sendMetrics(metricData));
}

/**
 * Gather single metric
 * Used to send metrics for a single element, metricData is an object
 */
export function* setGatherMetric(metricData) {
    yield call(requestWithRetry, requests.sendMetric(metricData));
}
