/**
 * 500 PAGE TEMPLATE
 *
 *
 * @version 0.1
 */

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as BannerWarningIcon } from '@assets/icons/BannerWarningIcon.svg';
import { ReactComponent as BannerWarningIconDark } from '@assets/icons/BannerWarningIconDark.svg';

function Page500({isDarkMode}) {

    return (
        <div className="error-500">
            <div className={`embedded-message ${isDarkMode ? 'dark-mode' : ''}`}>
                <div className="embedded-message__icon">
                    {isDarkMode ? <BannerWarningIconDark/> : <BannerWarningIcon/>}
                </div>
                <div className="embedded-message__message">
                    <FormattedMessage id="General_CoreFunctionality_Error_error.500Title"/>
                </div>
            </div>
        </div>
    );
}

export default Page500;
