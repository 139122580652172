import React from 'react';
import EsgRatingsBarChart from './EsgRatingsBarChart';
import { ESG_RATINGS_VISUALISATIONS } from '@constants';

const EsgRatingsSubcategory = (props) => {
    const { title, rating } = props;

    return (
        <div className="esg-ratings-subcategory" data-track="esg_ratings-subcategory-container">
            <div className="esg-ratings-subcategory-title" data-track="esg_ratings-subcategory-title">
                {title}
            </div>
            <div className="esg-ratings-subcategory-rating" data-track="esg_ratings-subcategory-rating">
                {rating}
            </div>
            {rating && (
                <EsgRatingsBarChart
                    {...props}
                    width={ESG_RATINGS_VISUALISATIONS.SUBCATEGORY_BAR.WIDTH}
                    height={ESG_RATINGS_VISUALISATIONS.SUBCATEGORY_BAR.HEIGHT}
                />
            )}
        </div>
    );
};

export default EsgRatingsSubcategory;
