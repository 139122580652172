import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { camelCase } from 'lodash';
import { injectIntl } from 'react-intl';
import { OPTION_CHECK } from '@constants';

export const CheckOptionType = PropTypes.shape({
    label: PropTypes.any,
    checked: PropTypes.any,
    extra: PropTypes.object,
});

class ChecksGroup extends Component {
    static propTypes = {
        onChange: PropTypes.func,
        options: PropTypes.arrayOf(CheckOptionType),
        selectAll: PropTypes.any,
        className: PropTypes.string,
        label: PropTypes.any,
        hasError: PropTypes.any,
        disabled: PropTypes.bool,
        required: PropTypes.bool,
        fieldId: PropTypes.string,
        renderItem: PropTypes.func,
    };

    static defaultProps = {
        label: null,
        hasError: false,
        disabled: false,
        required: false,
        className: '',
        fieldId: 'checksGroup',
        renderItem: (props, index) => <CheckOption {...props} index={index} key={index} />, // gives ability to replace the item component
    };

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
    }

    nextCheckState = (check) => {
        switch (check) {
            case OPTION_CHECK.CHECKED:
                return OPTION_CHECK.UNCHECKED;
            case OPTION_CHECK.UNCHECKED:
                return OPTION_CHECK.CHECKED;
        }
    };

    handleChange(option) {
        const options = this.props.options.map((opt) => (opt === option ? { ...option, checked: !opt.checked } : opt));
        const newOption = { ...option, checked: this.nextCheckState(option.checked) };
        this.props.onChange(options, newOption);
    }

    render() {
        const checkboxes = this.props.options.map((option, index) =>
            this.props.renderItem(
                {
                    onChange: (e) => this.handleChange(option, e),
                    option: {
                        ...option,
                        disabled: option.disabled || this.props.disabled,
                        fieldId: index + camelCase(option.rawLabel),
                        containerId: this.props.fieldId,
                    },
                },
                index
            )
        );

        return <div className="checkboxes-wrapper__content">{checkboxes}</div>;
    }
}

export const CheckOption = (props) => {
    const handleChange = () => {
        props.onChange(props.option);
    };

    return (
        <div className={'check-option ' + (props.className ? props.className : '') + 'custom-checkbox'}>
            <input
                type="checkbox"
                name={props.option.containerId}
                id={props.option.containerId + '-' + props.option.fieldId}
                checked={props.option.checked}
                disabled={!!props.option.disabled}
                onChange={handleChange}
                {...props.option.extra}
            />
            <label className="checkbox-label" htmlFor={props.option.containerId + '-' + props.option.fieldId}>
                {props.option.label}
            </label>
        </div>
    );
};

export default injectIntl(ChecksGroup, { forwardRef: true });
