import update from 'immutability-helper';
import {
    SET_ARTICLE_NAVIGATION,
    VIEW_ARTICLE,
    INIT_ARTICLE_NAVIGATION,
    ARTICLE_NAVIGATION_FILL,
    ARTICLE_NAVIGATION_ERROR,
    INIT_VIEW_ARTICLE,
    SET_ARTICLE_SHOW_MORE_ACTIVE,
} from './ArticleNavigation.actions';
import errorUtils from '@utils/errors/error-utils';
import { isEmpty } from 'lodash';
const APP_MODULE = 'articleNavigation.reducers';

function articleNavigation(state = {}, action) {
    switch (action.type) {
        case INIT_ARTICLE_NAVIGATION:
            if (!action.payload || isEmpty(action.payload)) {
                errorUtils.logAppError(
                    'Missing all parameters in ' + INIT_ARTICLE_NAVIGATION,
                    {
                        payload: action.payload,
                    },
                    APP_MODULE
                );

                return state;
            }
            return update(state, { $set: { ...action.payload } });
        case SET_ARTICLE_NAVIGATION:
            return update(state, { $merge: action.payload });
        case VIEW_ARTICLE:
            return update(state, { index: { $set: action.payload }, hasError: { $set: false } });
        case ARTICLE_NAVIGATION_ERROR:
            return update(state, { hasError: { $set: true } });
        case INIT_VIEW_ARTICLE:
            return update(state, { hasError: { $set: false } });
        case ARTICLE_NAVIGATION_FILL:
            let newList = Object.assign({}, state.list);
            for (let i = 0; i < action.payload.list.length; i++) {
                newList[action.payload.start + i] = action.payload.list[i];
            }
            return {
                ...state,
                list: newList,
                totalCount: action.payload.totalCount,
            };
        case SET_ARTICLE_SHOW_MORE_ACTIVE:
            return update(state, { isShowMoreActive: { $set: action.payload } });
        default:
            return state;
    }
}

export default articleNavigation;
