import React from 'react';
import { BOOLEAN_AND, BOOLEAN_AND_NOT, BOOLEAN_OR, BOOLEAN_TERM_TYPE } from '@constants';
import { cloneDeep } from 'lodash';
import utils from '@utils/utilities';
import formatRichMessage from '@utils/formatRichMessage';
import { FormattedMessage, injectIntl } from 'react-intl';

class BooleanTerms extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            includeAll: '',
            includeExact: '',
            includeAny: '',
            excludeAll: '',
            hasInsufficientCharacters: {
                includeAll: false,
                includeExact: false,
                includeAny: false,
                excludeAll: false,
            },
        };

        this.handleBooleanTerms = this.handleBooleanTerms.bind(this);
        this.keyPressHandler = this.keyPressHandler.bind(this);
        this.setBooleanSearch = this.setBooleanSearch.bind(this);
    }

    validateTerm = (type, query) => {
        if (query.length) {
            const termsArray = query.split(' ');
            const termsToValidate = termsArray.toString().replace(/,/g, ' ');
            const isValidQuery = utils.isValidQuery(termsToValidate);

            let hasInsufficientCharacters = cloneDeep(this.state.hasInsufficientCharacters);
            hasInsufficientCharacters[type] = !isValidQuery;

            this.setState({ hasInsufficientCharacters });
        }
    };

    changeValueAndValidate = (type, query) => {
        this.setState({
            [type]: query,
        });

        this.validateTerm(type, query);
    };

    keyPressHandler(event, target) {
        if (event.key === 'Enter' || event.keyCode === 13) {
            this.setBooleanSearch(target);
        }
    }

    handleBooleanTerms(event) {
        let booleanTermType = event.target.value;
        this.setBooleanSearch(booleanTermType);
    }

    setBooleanSearch(booleanTermType) {
        let searchTerm = '';
        let termsArray = this.state[booleanTermType].split(' ');
        if (this.props.query === '') {
            utils.showNotificationsMessage({
                messageText: 'BooleanSearch.MissingSearchTerm',
                messageType: 'system-error',
            });
            return;
        }

        let termsToValidate = termsArray.toString().replace(/,/g, ' ');
        if (utils.isValidQuery(termsToValidate) === false) {
            utils.showNotificationsMessage({
                messageText: 'SearchResults_Notifications.insufficientCharacters',
                messageType: 'system-error',
            });
            return;
        }
        switch (booleanTermType) {
            case BOOLEAN_TERM_TYPE.INCLUDE_ALL:
                searchTerm = BOOLEAN_AND + this.concatTerms(termsArray, BOOLEAN_AND);
                this.setState({ includeAll: '' });
                break;
            case BOOLEAN_TERM_TYPE.INCLUDE_EXACT:
                searchTerm = BOOLEAN_AND + '"' + this.state[booleanTermType] + '"';
                this.setState({ includeExact: '' });
                break;
            case BOOLEAN_TERM_TYPE.INCLUDE_ANY:
                searchTerm = BOOLEAN_AND + this.concatTerms(termsArray, BOOLEAN_OR);
                this.setState({ includeAny: '' });
                break;
            case BOOLEAN_TERM_TYPE.EXCLUDE_ALL:
                searchTerm = BOOLEAN_AND_NOT + this.concatTerms(termsArray, BOOLEAN_AND_NOT);
                this.setState({ excludeAll: '' });
                break;
        }

        this.props.updateBooleanTerms(searchTerm);
    }

    concatTerms(terms, operator) {
        let searchTerms = '';
        terms = terms
            .map((item) => {
                return item.trim();
            })
            .filter((item) => {
                return item !== '';
            });

        if (terms.length === 1) {
            searchTerms = terms.join();
        } else if (operator !== BOOLEAN_AND_NOT) {
            searchTerms = '(' + terms.join(operator) + ')';
        } else {
            searchTerms = terms.join(operator);
        }
        return searchTerms;
    }

    render() {
        return (
            <div className="more-search-options">
                <div className="boolean-terms-wrapper">
                    <div className="boolean-terms-row">
                        <div className="boolean-terms-item">
                            <label htmlFor="include-all-terms">
                                <FormattedMessage id="BooleanTerms.includeAllTerms" />
                            </label>
                            <div className="boolean-terms-input">
                                <input
                                    id="include-all-terms"
                                    type="text"
                                    placeholder={formatRichMessage(
                                        { id: 'BooleanTerms.includeAll.placeHolder' },
                                        this.props.intl
                                    )}
                                    onChange={(event) =>
                                        this.changeValueAndValidate(BOOLEAN_TERM_TYPE.INCLUDE_ALL, event.target.value)
                                    }
                                    onKeyPress={(e) => {
                                        this.keyPressHandler(e, BOOLEAN_TERM_TYPE.INCLUDE_ALL);
                                    }}
                                    value={this.state.includeAll}
                                    className={`text-field-standard-lg ${
                                        this.state.hasInsufficientCharacters[BOOLEAN_TERM_TYPE.INCLUDE_ALL]
                                            ? 'text-field-error'
                                            : ''
                                    }`}
                                />
                                <button
                                    className="button-secondary-lg"
                                    value={BOOLEAN_TERM_TYPE.INCLUDE_ALL}
                                    disabled={
                                        !this.state.includeAll ||
                                        this.state.hasInsufficientCharacters[BOOLEAN_TERM_TYPE.INCLUDE_ALL]
                                    }
                                    data-testid="include-all-terms-button"
                                    onClick={this.handleBooleanTerms}
                                >
                                    <FormattedMessage id="General.label.add" />
                                </button>
                            </div>
                            {this.state.hasInsufficientCharacters[BOOLEAN_TERM_TYPE.INCLUDE_ALL] && (
                                <div className="boolean-term-error">
                                    <FormattedMessage id={'SearchResults_Notifications.insufficientCharacters'} />
                                </div>
                            )}
                        </div>
                        <div className="boolean-terms-item">
                            <label htmlFor="include-any-terms">
                                <FormattedMessage id="BooleanTerms.includeAny" />
                            </label>
                            <div className="boolean-terms-input">
                                <input
                                    id="include-any-terms"
                                    type="text"
                                    placeholder={formatRichMessage(
                                        { id: 'BooleanTerms.includeAny.placeHolder' },
                                        this.props.intl
                                    )}
                                    onChange={(event) =>
                                        this.changeValueAndValidate(BOOLEAN_TERM_TYPE.INCLUDE_ANY, event.target.value)
                                    }
                                    onKeyPress={(e) => {
                                        this.keyPressHandler(e, BOOLEAN_TERM_TYPE.INCLUDE_ANY);
                                    }}
                                    value={this.state.includeAny}
                                    className={`text-field-standard-lg ${
                                        this.state.hasInsufficientCharacters[BOOLEAN_TERM_TYPE.INCLUDE_ANY]
                                            ? 'text-field-error'
                                            : ''
                                    }`}
                                />
                                <button
                                    className="button-secondary-lg"
                                    value={BOOLEAN_TERM_TYPE.INCLUDE_ANY}
                                    disabled={
                                        !this.state.includeAny ||
                                        this.state.hasInsufficientCharacters[BOOLEAN_TERM_TYPE.INCLUDE_ANY]
                                    }
                                    data-testid="include-any-terms-button"
                                    onClick={this.handleBooleanTerms}
                                >
                                    <FormattedMessage id="General.label.add" />
                                </button>
                            </div>
                            {this.state.hasInsufficientCharacters[BOOLEAN_TERM_TYPE.INCLUDE_ANY] && (
                                <div className="boolean-term-error">
                                    <FormattedMessage id={'SearchResults_Notifications.insufficientCharacters'} />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="boolean-terms-row">
                        <div className="boolean-terms-item">
                            <label htmlFor="include-exact-phrase">
                                <FormattedMessage id="BooleanTerms.includeExact" />
                            </label>
                            <div className="boolean-terms-input">
                                <input
                                    id="include-exact-phrase"
                                    type="text"
                                    placeholder={formatRichMessage(
                                        { id: 'BooleanTerms.includeExact.placeHolder' },
                                        this.props.intl
                                    )}
                                    onChange={(event) =>
                                        this.changeValueAndValidate(BOOLEAN_TERM_TYPE.INCLUDE_EXACT, event.target.value)
                                    }
                                    onKeyPress={(e) => {
                                        this.keyPressHandler(e, BOOLEAN_TERM_TYPE.INCLUDE_EXACT);
                                    }}
                                    value={this.state.includeExact}
                                    className={`text-field-standard-lg ${
                                        this.state.hasInsufficientCharacters[BOOLEAN_TERM_TYPE.INCLUDE_EXACT]
                                            ? 'text-field-error'
                                            : ''
                                    }`}
                                />
                                <button
                                    className="button-secondary-lg"
                                    value={BOOLEAN_TERM_TYPE.INCLUDE_EXACT}
                                    disabled={
                                        !this.state.includeExact ||
                                        this.state.hasInsufficientCharacters[BOOLEAN_TERM_TYPE.INCLUDE_EXACT]
                                    }
                                    data-testid="include-exact-phrase-button"
                                    onClick={this.handleBooleanTerms}
                                >
                                    <FormattedMessage id="General.label.add" />
                                </button>
                            </div>
                            {this.state.hasInsufficientCharacters[BOOLEAN_TERM_TYPE.INCLUDE_EXACT] && (
                                <div className="boolean-term-error">
                                    <FormattedMessage id={'SearchResults_Notifications.insufficientCharacters'} />
                                </div>
                            )}
                        </div>
                        <div className="boolean-terms-item">
                            <label htmlFor="exclude-these-terms">
                                <FormattedMessage id="BooleanTerms.excludeTerms" />
                            </label>
                            <div className="boolean-terms-input">
                                <input
                                    id="exclude-these-terms"
                                    type="text"
                                    placeholder={formatRichMessage(
                                        { id: 'BooleanTerms.excludeAll.placeHolder' },
                                        this.props.intl
                                    )}
                                    onChange={(event) =>
                                        this.changeValueAndValidate(BOOLEAN_TERM_TYPE.EXCLUDE_ALL, event.target.value)
                                    }
                                    onKeyPress={(e) => {
                                        this.keyPressHandler(e, BOOLEAN_TERM_TYPE.EXCLUDE_ALL);
                                    }}
                                    value={this.state.excludeAll}
                                    className={`text-field-standard-lg ${
                                        this.state.hasInsufficientCharacters[BOOLEAN_TERM_TYPE.EXCLUDE_ALL]
                                            ? 'text-field-error'
                                            : ''
                                    }`}
                                />
                                <button
                                    className="button-secondary-lg"
                                    value={BOOLEAN_TERM_TYPE.EXCLUDE_ALL}
                                    disabled={
                                        !this.state.excludeAll ||
                                        this.state.hasInsufficientCharacters[BOOLEAN_TERM_TYPE.EXCLUDE_ALL]
                                    }
                                    data-testid="exclude-these-terms-button"
                                    onClick={this.handleBooleanTerms}
                                >
                                    <FormattedMessage id="General.label.add" />
                                </button>
                            </div>
                            {this.state.hasInsufficientCharacters[BOOLEAN_TERM_TYPE.EXCLUDE_ALL] && (
                                <div className="boolean-term-error">
                                    <FormattedMessage id={'SearchResults_Notifications.insufficientCharacters'} />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default injectIntl(BooleanTerms);
