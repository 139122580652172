// @flow
import * as React from 'react';
import UserPreferencesBanner from '@reusable/UserPreferencesBanner/UserPreferencesBanner';
import userPreferenceApi from '@UserPreferences/UserPreferencesApi.api';
import utils from '@utils/utilities';
import reduxStore from '@reduxStore';
import userPrefsActions from '@UserPreferences/redux/UserPreferences.actions';
import { isEqual, keys, uniqWith, isEmpty } from 'lodash';
import { CHANGED_PREFERENCES_MESSAGE_IDS_MAPPING, CHANGED_PREFERENCES_IGNORED_KEYS, HEADER_HEIGHT } from '@constants';
import stickybits from 'stickybits';
import type { PreferencesType, UserPreferencesType } from '@MainSearch/components/typeGuards/ResultsList.typeGuards';

type PreferencesUpdatedInfoType = {
    lastUpdatedPreferencesDate: number,
    updatedByAdmin: boolean,
};

type RequiredProps = {
    userPreferences: PreferencesType,
    withoutRefresh: boolean,
    preferencesRefreshPeriod: number,
    isMIP: boolean,
    user: {
        preferences: PreferencesType,
        lastPreferencesUpdateInfo: PreferencesUpdatedInfoType,
    },
    lastPreferencesUpdateInfo: PreferencesUpdatedInfoType,
    lastUserPreferencesRetrieval: number,
    userPermId: string,
    lastUpdated: number,
    route: {
        withoutRefresh?: boolean,
    },
};

type State = {
    showUserPreferencesBanner: boolean,
    messageID: string,
    messageIDs?: {},
    setIntervalId?: IntervalID,
};

const withPreferenceRefresh = <Props: {} & RequiredProps>(
    WrappedComponent: React.AbstractComponent<Props>
): React.AbstractComponent<Props> | null => {
    return class PreferencesRefresh extends React.Component<Props, State> {
        constructor(props) {
            super(props);

            this.state = {
                showUserPreferencesBanner: false,
                messageID: '',
            };

            this.isDarkMode = this.props.user.preferences.payload?.userPreferences?.generalSettings?.isDarkMode ?? this.props.user.preferences.generalSettings?.isDarkMode;
        }



        refreshPreferences = async (
            originalPreferences: UserPreferencesType,
            updatedByAdmin: boolean,
            displayBanner: boolean = true
        ): Promise<any> => {
            const prefs: UserPreferencesType = originalPreferences ? originalPreferences : this.props.userPreferences;

            return await userPreferenceApi.getUserPreferences().then((response) => {
                let messageIDs = this.processDifferences(response.body, prefs);
                let showUserPreferencesBanner = true;

                if (isEmpty(messageIDs) || !displayBanner) {
                    showUserPreferencesBanner = false;

                    return false;
                }
                this.setState({
                    messageID: this.updateMessageId(updatedByAdmin),
                    showUserPreferencesBanner: showUserPreferencesBanner,
                    messageIDs: messageIDs,
                });
                reduxStore.dispatch(userPrefsActions.getUserPrefencesValues(response.body));

                return true;
            });
        };

        checkForUpdatesAndUpdatePreferences = async (
            preferences?: UserPreferencesType,
            shouldRefreshPrefs?: boolean = true,
            displayBanner?: boolean = true
        ): Object | null => {
            const { userPermId, lastUserPreferencesRetrieval } = this.props;
            const lastUpdated = await userPreferenceApi.getPreferencesLastUpdatedInfo(userPermId);

            if (lastUpdated && lastUpdated.body) {
                const { lastUpdatedPreferencesDate, updatedByAdmin } = lastUpdated.body;
                const differentRetrieval = lastUpdatedPreferencesDate > lastUserPreferencesRetrieval;

                if (differentRetrieval && shouldRefreshPrefs) {
                    reduxStore.dispatch(
                        userPrefsActions.shouldUpdateTimestampInfo({
                            lastUpdatedPreferencesDate,
                            updatedByAdmin,
                        })
                    );

                    const preferencesBannerNeeded = await this.refreshPreferences(
                        this.props.userPreferences || preferences,
                        lastUpdated.body.updatedByAdmin,
                        displayBanner
                    );
                    if (!preferencesBannerNeeded) {
                        return null;
                    }

                    return lastUpdated;
                } else {
                    return null;
                }
            }
        };

        UNSAFE_componentWillMount = () => {
            //interval is set to minutes (30)
            if (!this.props.withoutRefresh && !this.props.route.withoutRefresh) {
                let refreshInterval = this.props.preferencesRefreshPeriod * 60 * 1000;
                if (!this.props.isMIP) {
                    let setIntervalId: IntervalID = setInterval(
                        this.checkForUpdatesAndUpdatePreferences,
                        refreshInterval
                    );
                    this.setState({
                        setIntervalId,
                    });
                }
            }
        };

        componentWillUnmount = () => {
            if (!this.props.isMIP) {
                if (this.state.setIntervalId) {
                    clearInterval(this.state.setIntervalId);
                }
            }
        };

        getContentTypes = (response: UserPreferencesType) => {
            return (
                response &&
                response.userPreferences &&
                response.userPreferences.generalSettings &&
                response.userPreferences.generalSettings.contentTypes
            );
        };

        processDifferences = (response: UserPreferencesType, originalPreferences: UserPreferencesType): {} => {
            const responseContentTypes = this.getContentTypes(response);
            const originalPreferencesContentTypes = this.getContentTypes(originalPreferences);

            if (responseContentTypes && utils.isArrayPopulated(responseContentTypes)) {
                response.userPreferences.generalSettings.contentTypes =
                    utils.sortContentTypesValuesInArray(responseContentTypes);
            }

            if (originalPreferencesContentTypes && utils.isArrayPopulated(originalPreferencesContentTypes)) {
                originalPreferences.userPreferences.generalSettings.contentTypes = utils.sortContentTypesValuesInArray(
                    originalPreferencesContentTypes
                );
            }

            let differences = utils.getDifferences(response, originalPreferences);
            let messageIDs = {};

            if (differences.userPreferences) {
                Object.keys(differences.userPreferences).forEach((mainPreferencesSection) => {
                    if (CHANGED_PREFERENCES_IGNORED_KEYS.indexOf(mainPreferencesSection) === -1) {
                        messageIDs[mainPreferencesSection] = [];
                        let secondLevelSections = differences.userPreferences[mainPreferencesSection];
                        let secondLevelSectionsKeys = keys(differences.userPreferences[mainPreferencesSection]);

                        if (secondLevelSectionsKeys.length) {
                            secondLevelSectionsKeys.forEach((changedSection) => {
                                switch (changedSection) {
                                    case 'fuzzyMatch':
                                    case 'enableFuzzy':
                                        messageIDs[mainPreferencesSection].push([
                                            CHANGED_PREFERENCES_MESSAGE_IDS_MAPPING.mainSectionsIds['personCheck'],
                                            ...CHANGED_PREFERENCES_MESSAGE_IDS_MAPPING.getSubSectionsMessageIds(
                                                changedSection
                                            ),
                                        ]);
                                        break;
                                    case 'contentTypes':
                                        messageIDs[mainPreferencesSection].push([
                                            CHANGED_PREFERENCES_MESSAGE_IDS_MAPPING.mainSectionsIds[
                                                mainPreferencesSection
                                            ],
                                            ...CHANGED_PREFERENCES_MESSAGE_IDS_MAPPING.getSubSectionsMessageIds(
                                                changedSection
                                            ),
                                        ]);
                                        messageIDs[mainPreferencesSection].push([
                                            CHANGED_PREFERENCES_MESSAGE_IDS_MAPPING.mainSectionsIds['deliverySettings'],
                                            ...CHANGED_PREFERENCES_MESSAGE_IDS_MAPPING.getSubSectionsMessageIds(
                                                'reportOrder'
                                            ),
                                        ]);
                                        break;
                                    case 'newsSources':
                                    case 'companySources':
                                    case 'legalSources':
                                        let thirdLevelSectionKeys = keys(secondLevelSections[changedSection]);
                                        if (thirdLevelSectionKeys.length > 0) {
                                            thirdLevelSectionKeys.forEach((section) => {
                                                messageIDs[mainPreferencesSection].push([
                                                    CHANGED_PREFERENCES_MESSAGE_IDS_MAPPING.mainSectionsIds[
                                                        mainPreferencesSection
                                                    ],
                                                    ...CHANGED_PREFERENCES_MESSAGE_IDS_MAPPING.getSubSectionsMessageIds(
                                                        changedSection + '_' + section
                                                    ),
                                                ]);
                                            });
                                        } else {
                                            messageIDs[mainPreferencesSection].push([
                                                CHANGED_PREFERENCES_MESSAGE_IDS_MAPPING.mainSectionsIds[
                                                    mainPreferencesSection
                                                ],
                                                ...CHANGED_PREFERENCES_MESSAGE_IDS_MAPPING.getSubSectionsMessageIds(
                                                    changedSection
                                                ),
                                            ]);
                                        }
                                        break;
                                    case 'shouldContainResearchSummary':
                                        messageIDs[mainPreferencesSection].push([
                                            CHANGED_PREFERENCES_MESSAGE_IDS_MAPPING.mainSectionsIds['deliverySettings'],
                                            ...CHANGED_PREFERENCES_MESSAGE_IDS_MAPPING.getSubSectionsMessageIds(
                                                mainPreferencesSection
                                            ),
                                            ...CHANGED_PREFERENCES_MESSAGE_IDS_MAPPING.getSubSectionsMessageIds(
                                                changedSection
                                            ),
                                        ]);
                                        break;
                                    case 'prefsUpdatedByAdmin':
                                    case 'searchType':
                                    case 'sort':
                                    case 'alertsOptions':
                                    case 'pageSize':
                                    case 'isTrendsExpanded':
                                    case 'isFilterSectionCollapsed':
                                    case 'isDarkMode':
                                    case 'displayRiskScores':
                                    case 'sanctionsSources':
                                        break;
                                    default:
                                        const mainSectionId =
                                            CHANGED_PREFERENCES_MESSAGE_IDS_MAPPING.mainSectionsIds[
                                                mainPreferencesSection
                                            ];
                                        const subSectionMessageIds =
                                            CHANGED_PREFERENCES_MESSAGE_IDS_MAPPING.getSubSectionsMessageIds(
                                                changedSection
                                            );
                                        if (mainSectionId && !isEmpty(subSectionMessageIds)) {
                                            messageIDs[mainPreferencesSection].push([
                                                mainSectionId,
                                                ...subSectionMessageIds,
                                            ]);
                                        }
                                }
                            });
                        } else {
                            messageIDs[mainPreferencesSection].push([
                                CHANGED_PREFERENCES_MESSAGE_IDS_MAPPING.mainSectionsIds['deliverySettings'],
                                ...CHANGED_PREFERENCES_MESSAGE_IDS_MAPPING.getSubSectionsMessageIds('reportBuilder'),
                                ...CHANGED_PREFERENCES_MESSAGE_IDS_MAPPING.getSubSectionsMessageIds(
                                    mainPreferencesSection
                                ),
                            ]);
                            messageIDs[mainPreferencesSection].push([
                                CHANGED_PREFERENCES_MESSAGE_IDS_MAPPING.mainSectionsIds['deliverySettings'],
                                ...CHANGED_PREFERENCES_MESSAGE_IDS_MAPPING.getSubSectionsMessageIds('results'),
                                ...CHANGED_PREFERENCES_MESSAGE_IDS_MAPPING.getSubSectionsMessageIds(
                                    mainPreferencesSection
                                ),
                            ]);
                        }

                        messageIDs[mainPreferencesSection] = uniqWith(messageIDs[mainPreferencesSection], isEqual);
                        messageIDs = isEmpty(messageIDs[mainPreferencesSection]) ? {} : messageIDs;
                    }
                });
            }
            return messageIDs;
        };

        updateMessageId = (updatedByAdmin: boolean): string => {
            const newUserMessage = 'UserPreferences_Notifications.updatingPreferencesMessage.user';
            const newAdminMessage = 'UserPreferences_Notifications.updatingPreferencesMessage.admin';

            if (updatedByAdmin) return newAdminMessage;

            return newUserMessage;
        };

        checkPreferencesAreObsolete = (): void => {
            const { user } = this.props;
            const { lastPreferencesUpdateInfo } = user;
            const { lastUserPreferencesRetrieval, payload } = user.preferences;
            const { lastUpdatedPreferencesDate, updatedByAdmin } = lastPreferencesUpdateInfo;

            if (
                Number.isInteger(lastUserPreferencesRetrieval) &&
                lastUserPreferencesRetrieval < lastUpdatedPreferencesDate
            ) {
                this.refreshPreferences(payload, updatedByAdmin);
            }
        };

        hideUserPreferencesBanner = (): void => {
            this.setState({
                showUserPreferencesBanner: false,
            });

            this.updateStickyPosition('.selection-header');
            this.updateStickyPosition('.single-article-header-actions-bar');
        };

        updateStickyPosition = (selector) => {
            stickybits(selector, {
                stickyBitStickyOffset: HEADER_HEIGHT,
            });
        };

        render() {
            return this.props.isMIP ? (
                <WrappedComponent {...this.props} />
            ) : (
                <React.Fragment>
                    <WrappedComponent
                        checkPreferencesAreObsolete={this.checkPreferencesAreObsolete}
                        checkForUpdatesAndUpdatePreferences={this.checkForUpdatesAndUpdatePreferences}
                        {...this.props}
                    />
                    <UserPreferencesBanner
                        showUserPreferencesBanner={this.state.showUserPreferencesBanner}
                        hideUserPreferencesBanner={this.hideUserPreferencesBanner}
                        messageID={this.state.messageID}
                        messageIDs={this.state.messageIDs}
                        isDarkMode={this.isDarkMode}
                    />
                </React.Fragment>
            );
        }
    };
};

export default withPreferenceRefresh;
