import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import ResultsSnapshot from './components/ResultsSnapshot';
import ResultsList from './components/ResultsList';
import PopupModal from '@reusable/PopupModal/PopupModal.index';
import { bindActionCreators } from 'redux';
import breadcrumbsActions from '@reusable/Breadcrumbs/redux/Breadcrumbs.actions';
import categoryUtils from '@utils/categoryUtils';
import { CATEGORY_TYPE_UBO_CHILD, PREFERENCES_KEY } from '@constants';
import uboActions from '../StartPage/redux/Ubo.actions';
import mainActions from '@pages/Main/Main.actions';
import SnapshotDeliveryUtils from '@utils/snapshotDeliveryUtils';
import ReportBuilderApi from '@pages/ReportBuilder/ReportBuilderMain.api';
import adHocSearchActions from '@reusable/AdHocSearch/redux/AdHocSearch.actions';

class MainSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            popupModalVisible: false,
        };
        this.showPopupModal = this.showPopupModal.bind(this);
        this.closePopupModal = this.closePopupModal.bind(this);
        this.shouldRenderSnapshot = this.shouldRenderSnapshot.bind(this);
        this.getArticleList = this.getArticleList.bind(this);
        this.getEnabledCategory = this.getEnabledCategory.bind(this);
    }

    componentDidMount() {
        // Reset the adhoc search object from Redux when refreshing the page and the default is All News, All Languages
        if (categoryUtils.isDefaultNewsSearchSourceForAdhoc(this.props.adHocSearch)) {
            this.props.updateAdHocSearchObject({});
        }
    }

    componentDidUpdate(prevProps) {
        // Reset snapshot delivery when new search is started
        if (this.props.searchState.snapshotDelivery && !this.props.isSearchComplete) {
            this.props.setSnapshotDelivery(null);
        }

        // Save snapshot delivery object & update report snapshot delivery if report exists or the searchResults have changed
        if (this.props.isSearchComplete && (!this.props.searchState.snapshotDelivery || prevProps.searchResults !== this.props.searchResults)) {
            const everyAvailableCategory = Object.keys(this.props.searchResults).filter(
                (key) => this.props.searchResults[key].available
            );
            const everyCategoryLoaded = everyAvailableCategory.every((key) => this.props.searchResults[key].loaded);

            if (everyCategoryLoaded) {
                const snapshotDelivery = SnapshotDeliveryUtils.getSnapshotDeliveryObject({
                    adHocSearch: {
                        adHocNewsDateRange: this.props.adHocNewsDateRange,
                        adHocLegalDateRange: this.props.adHocLegalDateRange,
                        adHocCompanyDateRange: this.props.adHocCompanyDateRange,
                    },
                    user: {
                        preferences: {
                            language: this.props.language,
                            generalSettings: this.props.generalSettings,
                        },
                    },
                    searchResults: this.props.searchResults,
                    searchParams: this.props.searchParams,
                    suggestedNames: this.props.suggestedNames,
                    negativeNewsVisualisation: this.props.negativeNewsVisualisation,
                    sanctionsRisk: this.props.sanctionsRisk,
                });

                this.props.setSnapshotDelivery(snapshotDelivery);

                if (this.props.currentReport.reportId) {
                    ReportBuilderApi.updateReportSnapshotDelivery(this.props.currentReport.reportId, snapshotDelivery);
                }
            }
        }
    }

    closePopupModal() {
        this.setState({
            popupModalVisible: false,
        });
    }

    showPopupModal() {
        this.setState({
            popupModalVisible: true,
        });
    }

    getEnabledCategory(categoryName, searchResults) {
        if (categoryUtils.isDnbCategory(categoryName)) {
            // for "ubo" we try to get the first child since "ubo" category is the parent
            if (categoryUtils.getFirstChild(categoryName)) {
                categoryName = categoryUtils.getFirstChild(categoryName).name;
            }
        }
        const category = searchResults[categoryName];

        return category;
    }

    shouldRenderSnapshot() {
        //if not list or category is missing, go to snapshot
        if (this.props.location.query.list !== 'true' || !this.props.location.query.category) {
            return true;
        }
        let categoryName = this.props.location.query.category;
        let category = this.getEnabledCategory(categoryName, this.props.searchResults);

        if (category) {
            return false;
        } else {
            return true;
        }
    }

    getArticleList() {
        let categoryName = this.props.location.query.category;
        let category = this.getEnabledCategory(categoryName, this.props.searchResults);

        return category || {};
    }

    render() {
        let shouldRenderSnapshot = this.shouldRenderSnapshot();
        let articleList = shouldRenderSnapshot ? {} : this.getArticleList();

        const dnbSubcategories = Object.values(this.props.searchResults).filter(
            (category) => category.type === CATEGORY_TYPE_UBO_CHILD
        );
        const newsSource = this.props.adHocNewsSource || this.props.preferencesNewsSource;
        return (
            <div>
                {this.props.popupModel.isVisible && <PopupModal closeButtonHandler={this.closePopupModal} />}
                {shouldRenderSnapshot ? (
                    <ResultsSnapshot
                        searchResults={this.props.searchResults}
                        location={this.props.location}
                        showSnapshot={this.props.showSnapshot}
                        news={this.props.news}
                        customNews={this.props.customNews}
                        financialReports={this.props.financialReports}
                        directors={this.props.directors}
                        sanctions={this.props.sanctions}
                        peps={this.props.peps}
                        biographical={this.props.biographical}
                        agencyDecision={this.props.agencyDecision}
                        legal={this.props.legal}
                        dockets={this.props.dockets}
                        federalDockets={this.props.federalDockets}
                        lawReviews={this.props.lawReviews}
                        verdicts={this.props.verdicts}
                        lawSources={this.props.lawSources}
                        ubo={this.props.ubo}
                        esgRatings={this.props.esgRatings}
                        dnbSubcategories={dnbSubcategories}
                        fuzzyNames={this.props.fuzzyNames}
                        suggestedNames={this.props.suggestedNames}
                        isSuggestedNamesEnabled={this.props.isSuggestedNamesEnabled}
                        suggestedNamesFeatureEnabled={this.props.suggestedNamesFeatureEnabled}
                        isFuzzyEnabled={this.props.isFuzzyEnabled}
                        isFuzzyLoaded={this.props.isFuzzyLoaded}
                        adHocNewsDateRange={this.props.adHocNewsDateRange}
                        adHocCompanyDateRange={this.props.adHocCompanyDateRange}
                        adHocLegalDateRange={this.props.adHocLegalDateRange}
                        generalSettings={this.props.generalSettings}
                        updateBreadcrumbs={this.props.updateBreadcrumbs}
                        adHocNewsSource={this.props.adHocNewsSource}
                        newsSource={newsSource}
                        personCheckNewsSource={this.props.personCheckNewsSource}
                        withoutRefresh={true}
                    />
                ) : (
                    <ResultsList
                        articleType={articleList.name}
                        articleList={articleList}
                        showPopupHandler={this.showPopupModal}
                        withoutRefresh={true}
                        route={this.props.route}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = function (state) {
    return {
        showSnapshot: state.user.preferences.generalSettings.showSnapshot,
        news: state.searchResults.news,
        customNews: state.searchResults.customNews,
        financialReports: state.searchResults.financialReports,
        sanctions: state.searchResults.sanctions,
        peps: state.searchResults.peps,
        biographical: state.searchResults.biographical,
        directors: state.searchResults.directors,
        agencyDecision: state.searchResults.agencyDecision,
        legal: state.searchResults.legal,
        dockets: state.searchResults.dockets,
        federalDockets: state.searchResults.federalDockets,
        lawReviews: state.searchResults.lawReviews,
        verdicts: state.searchResults.verdicts,
        lawSources: state.searchResults.lawSources,
        ubo: state.searchResults.ubo,
        esgRatings: state.searchResults.esgRatings,
        fuzzyNames: state.fuzzyNames,
        suggestedNamesFeatureEnabled: state.user.suggestedNamesEnabled,
        suggestedNames: state.suggestedNames,
        isSuggestedNamesEnabled: state.user.preferences.personCheck.suggestedNames,
        isFuzzyEnabled: state.user.preferences.generalSettings.isFuzzyEnabled,
        isFuzzyLoaded: state.user.preferences.generalSettings.isFuzzyLoaded,
        generalSettings: state.user.preferences.generalSettings,
        adHocNewsDateRange: state.adHocSearch.newsDateRange,
        adHocCompanyDateRange: state.adHocSearch.companyDateRange,
        adHocLegalDateRange: state.adHocSearch.legalDateRange,
        adHocNewsSource: state.adHocSearch.newsSearchSources,
        preferencesNewsSource: state.user.preferences[PREFERENCES_KEY[state.searchParams.searchType]].newsSearchSources,
        searchResults: state.searchResults,
        popupModel: state.popupModel,
        uboConfig: state.ubo,
        isSearchComplete: state.searchStatus.isSearchComplete,
        currentReport: state.currentReport,
        searchParams: state.searchParams,
        searchState: state.searchState,
        language: state.user.preferences.language,
        negativeNewsVisualisation: state.negativeNewsVisualisation,
        adHocSearch: state.adHocSearch,
        sanctionsRisk: state.sanctionsRisk,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            updateBreadcrumbs: breadcrumbsActions.updateBreadcrumbs,
            uboInit: uboActions.init,
            setSnapshotDelivery: mainActions.setSnapshotDelivery,
            updateAdHocSearchObject: adHocSearchActions.updateAdHocSearchObject,
        },
        dispatch
    );
};

export { MainSearch as TestMainSearch };
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MainSearch));
