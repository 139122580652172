import React from 'react';
import * as DilProps from '@utils/props';
import { DefaultBottomLegend, DefaultHeader, DefaultRows } from './components/defaults';
import utils from '@utils/utilities';

const composeTable = (
    HeaderComponent = DefaultHeader,
    RowsComponent = DefaultRows,
    LegendComponent = DefaultBottomLegend
) => {
    const composeTableComponent = (props) => {
        //props.data.rows = props.data.rows && props.data.rows.map(props.onTransformRow);

        const headers = (
            <HeaderComponent
                headers={props.headers}
                onChangeSorting={props.sorting ? props.sorting.onChangeSorting : null}
            />
        );
        const rows = <RowsComponent {...props} />;
        const legend = <LegendComponent legend={props.legend} />;

        return (
            <div className={'table ' + (props.className ? props.className : '')} data-testid="composed-table">
                <div className={'headers'}>{headers}</div>
                <div className={'rows'}>{rows}</div>
                <div className={'legend'}>{legend}</div>
            </div>
        );
    };

    composeTableComponent.displayName = `composedTable(${utils.getDisplayName(HeaderComponent)}, ${utils.getDisplayName(
        RowsComponent
    )})`;

    composeTableComponent.propTypes = {
        //onTransformRow: PropTypes.func.isRequired,
        data: DilProps.TableData.isRequired,
        headers: DilProps.TableHeaders.isRequired,
    };

    return composeTableComponent;
};

export default composeTable;
