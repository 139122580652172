import React from 'react';

const ThemeSwitcher = ({ isDarkMode, toggleTheme }) => {
    return (
        <div className={`theme-switcher-wrapper ${isDarkMode ? 'dark-theme' : ''}`}>
            <label className="theme-switcher-switch">
                <input
                    type="checkbox"
                    className="theme-switcher-input"
                    onChange={(event) => toggleTheme(!event.target.checked)}
                    checked={!isDarkMode}
                />
                <span className="theme-switcher-slider" />
            </label>
        </div>
    );
};

export default ThemeSwitcher;
