// @flow
import * as React from 'react';
import { Transition } from 'semantic-ui-react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { FILTER_INFO } from '@constants';
import InformationIcon from '../InformationIcon/InformationIcon';

type Props = {
    label: string,
    isOpen?: boolean,
    className: string,
    resetFilter?: (name: string, searchFieldName?: string) => void,
    name: string,
    searchFieldName?: string,
    showInfoIcon?: boolean,
    infoItems?: {
        infoModalClass: string,
        infoContent: HTMLElement,
        infoTitle: string,
    },
    showResetButton?: boolean,
    children: React$Node,
    isDisabled: boolean,
    togglePostfilterVisibility: (key: string, value: boolean) => void,
};

const FilterPod = (props: Props) => {
    const {
        name,
        isOpen,
        togglePostfilterVisibility,
        resetFilter,
        searchFieldName,
        showInfoIcon,
        infoItems,
        isDisabled,
        className,
        label,
        showResetButton,
        children
    } = props;
    const { infoTitle, infoContent, infoModalClass } = infoItems || {};
    const withInfoIcon = showInfoIcon === true;


    const toggleVisibility = () => {
        togglePostfilterVisibility(name, !isOpen);
    };

    const handleResetFilter = () => {
        resetFilter && resetFilter(name, searchFieldName);
    };



    return (
        <div
            disabled={isDisabled}
            className={className}
            data-track={`type-filter-${name}`}
        >
            <div className={`type-filter-category ${withInfoIcon ? 'with-info-icon' : ''}`}>
                <button
                    className='type-filter-category-dropdown'
                    onClick={toggleVisibility}
                    name={name + '_visibilityBtn'}
                    data-testid={`type-filter-category-button-${name}`}
                >
                    {isOpen ? <span className='la-TriangleUp' /> : <span className='la-TriangleDown' />}
                    {label && <FormattedMessage id={label} />}
                </button>
                {withInfoIcon && (
                    <InformationIcon infoModalClass={infoModalClass} content={infoContent} title={infoTitle} />
                )}
                {(showResetButton ||
                    (searchFieldName &&
                        searchFieldName !== FILTER_INFO.PUBLICATION_DATE &&
                        searchFieldName !== FILTER_INFO.FUZZY_SEARCH)) && (
                        <button
                            className='reset-filter-category-dropdown'
                            onClick={handleResetFilter}
                            name={`${name}_resetBtn`}
                        >
                            <FormattedMessage id='General.label.reset' />
                        </button>
                    )}
            </div>
            <Transition.Group animation='slide down' duration='400'>
                {isOpen && <div className='filter-pod-content'>{children}</div>}
            </Transition.Group>
        </div>
    );
}

export default (injectIntl(FilterPod): React.AbstractComponent <Props, State>);
