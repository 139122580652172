import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import formatRichMessage from '@utils/formatRichMessage';

const ShareViewSearchbar = ({ setShareViewUserSearchText, setSearchbarEnabled, disabled }) => {
    const [inputField, setInputField] = useState('');
    const intl = useIntl();

    const resetInputField = () => {
        setInputField('');
        setShareViewUserSearchText('');
        setSearchbarEnabled(true);
    };

    return (
        <div className="popup-modal__share-view-user-searchbar">
            <div className="filter-search-box share-view-user-search">
                <input
                    data-testid="share-view-search-input"
                    className="share-view-user-search-input"
                    placeholder={formatRichMessage({ id: 'FilterSearchBox.shareView' }, intl)}
                    value={inputField}
                    onChange={(e) => {
                        setShareViewUserSearchText(e.target.value);
                        setInputField(e.target.value);
                    }}
                    disabled={disabled}
                />
                {!disabled &&
                    (inputField.length ? (
                        <button
                            className="la-ClearSearch share-view-search-clear"
                            onClick={resetInputField}
                            data-testid="share-view-clear-search-button"
                        />
                    ) : (
                        <button className="la-Search" disabled />
                    ))}
            </div>
        </div>
    );
};

export default ShareViewSearchbar;
