import React, { Fragment } from 'react';
import { ReactComponent as BannerErrorIcon } from '../../../../assets/icons/BannerErrorIcon.svg';
import { ReactComponent as BannerErrorIconDark } from '../../../../assets/icons/BannerErrorIconDark.svg';
import { useSelector } from 'react-redux';

const ResultsSnapshotError = (props) => {
    const isDarkMode = useSelector((state) => state.user.preferences.generalSettings.isDarkMode);
    return (
        <Fragment>
            {props.internalServerError && (
                <div className={`embedded-message error-occurred notranslate ${isDarkMode ? 'dark-mode' : ''}`}>
                    <div className="embedded-message__icon">
                        {isDarkMode ? <BannerErrorIconDark /> : <BannerErrorIcon />}
                    </div>
                    <div className="embedded-message__message" dangerouslySetInnerHTML={{ __html: props.internalServerError }} />
                </div>
            )}
            {props.invalidQueryError && (
                <div className={`embedded-message error-occurred notranslate ${isDarkMode ? 'dark-mode' : ''}`}>
                    <div className="embedded-message__icon">
                        {isDarkMode ? <BannerErrorIconDark /> : <BannerErrorIcon />}
                    </div>
                    <div className="embedded-message__message" dangerouslySetInnerHTML={{ __html: props.invalidQueryError }} />
                </div>
            )}
        </Fragment>
    );
};

export default ResultsSnapshotError;
