import React from 'react';
import { injectIntl } from 'react-intl';
import { COMPANY_SEARCH } from '@constants';
import utils from '@utils/utilities';
import UboSearchBar from '@reusable/SearchBar/UboSearchBar';
import SearchBar from '@reusable/SearchBar/SearchBar';

const StartPageSearchBar = (props) => {
    const { searchType } = props.searchParams;

    if (searchType === COMPANY_SEARCH && utils.isUboEnabled() && utils.isUboTypeaheadEnabled()) {
        return <UboSearchBar {...props} />;
    } else {
        return <SearchBar {...props} />;
    }
};

export default injectIntl(StartPageSearchBar);
