/**
 * HEADER MAIN MENU
 *
 * @version 0.1
 */

import React from 'react';
import { withRouter, Link } from 'react-router';
import commonApi from '@api/Common.api';
import SubMenu from './Header.subMenu.jsx';
import SearchBarHeader from '@reusable/SearchBarHeader/SearchBarHeader';
import ReportBuilderSelector from '@reusable/ReportBuilderSelector/ReportBuilderSelector';
import UserSelector from '@reusable/UserSelector/UserSelector';
import Logo from './Header.logo';
import DilQueue, { queueSettings } from '@utils/promiseQueue';
import {
    EDIT_LOCATION_HASH,
    HELP_URLS,
    START_LOCATION_HASH,
    LAUNCHED_SEARCH_FROM,
    ROUTES,
    SAVE_SEARCH_LEAVE_ACTION,
} from '@constants';
import ManageAlertsSelector from '@reusable/ManageAlertsSelector/ManageAlertsSelector';
import PublicRecordsSelector from '@reusable/PublicRecordsSelector/PublicRecordsSelector';
import { FormattedMessage, injectIntl } from 'react-intl';
import utils from '@utils/utilities';
import costCodeUtils from '@utils/costCodeUtils';
import { cloneDeep } from 'lodash';
import GoogleAnonymizedSelector from '@reusable/GoogleAnonimyzedSelector/GoogleAnonymizedSelector';
import userPreferenceApi from '@pages/UserPreferances/UserPreferencesApi.api';
import ProductSwitcher from './ProductSwitcher';
import { withAppContext } from '@utils/contexts';
import ThemeSwitcher from './ThemeSwitcher';
import withThemeSwitcherContext from '@contexts/hoc/withThemeSwitcher';
import EntityViewLink from '@reusable/EntityViewLink/EntityViewLink';
import { searchStatusActions } from '@pages/MainSearch/redux/SearchStatus.action';
import reduxStore from '@reduxStore';

class MainMenu extends React.Component {
    constructor(props) {
        super(props);

        this.generateChargeDescription = this.generateChargeDescription.bind(this);
        this.logOutUser = this.logOutUser.bind(this);
        this.proceedWithLogout = this.proceedWithLogout.bind(this);
        this.showPreferencesError = this.showPreferencesError.bind(this);
        this.showPreferencesUpdateMessage = this.showPreferencesUpdateMessage.bind(this);
        this.skipToContentButton = React.createRef();
        this.state = {
            publicRecordTypes: [],
            wamInfo: {},
        };
    }

    generateChargeDescription() {
        let chargeDescription = costCodeUtils.getClientIdPrefix();
        let billingId = costCodeUtils.generateBillingId();
        chargeDescription += billingId;
        return chargeDescription;
    }

    componentDidMount() {
        //setup some defaults values TODO: use the costcode from app
        this.setState({
            clientId: 'muClientId',
            costCode: 'myCostCode',
        });
    }

    logOutUser() {
        const isSearchLaunchedFromScreening =
            this.props.location.query?.searchFrom === LAUNCHED_SEARCH_FROM.SCREENING_PAGE;
        if (
            this.props.isBatchReportsEnabled &&
            isSearchLaunchedFromScreening &&
            this.props.location.pathname === ROUTES.MAIN_SEARCH
        ) {
            // show SAVE popup for ResultsList only scenario
            reduxStore.dispatch(searchStatusActions.showSaveSearchModal(SAVE_SEARCH_LEAVE_ACTION.logout));
            return;
        }

        const queue = DilQueue();
        const preferencesSaveCall = queue
            .executionPipe()
            .find((item) => item.data.url.indexOf(queueSettings.maximPriorityUrl) > -1);

        if (preferencesSaveCall) {
            this.showPreferencesUpdateMessage();
            preferencesSaveCall.xhrPromise
                .then(() => {
                    this.proceedWithLogout(queue);
                })
                .catch(() => {
                    this.showPreferencesError();
                    this.proceedWithLogout(queue);
                });
        } else {
            if (this.props.shouldSavePreferences && !this.props.context.isUserMIP) {
                //strip out content from preferences to post back
                let prefCopy = utils.prepareUserPreferencesForSave(cloneDeep(this.props.user.preferences));
                let userLocks = utils.convertUserLocksObjToArr(prefCopy.userLocks);
                delete prefCopy.userLocks;

                this.showPreferencesUpdateMessage();
                this.props.updateUserPrefsDirtiness(false);
                userPreferenceApi
                    .updateUserPreference({ permissions: userLocks, userPreferences: prefCopy })
                    .then(() => {
                        this.proceedWithLogout(queue);
                    })
                    .catch(() => {
                        this.showPreferencesError();
                        this.proceedWithLogout(queue);
                    });
            } else {
                this.proceedWithLogout(queue);
            }
        }
    }

    proceedWithLogout(queue) {
        const logoutArgs = {
            adminPermissionChanged: this.props.adminPermissionChanged,
        };

        queue.abortAllTasks();
        commonApi.userLogOut(logoutArgs);
        this.props.updateBreadcrumbs([]);
    }

    showPreferencesError() {
        utils.showNotificationsMessage({
            messageText: 'UserPreferences_Notifications.errorUpdatePreferencesMessage',
            messageType: 'error',
        });
    }

    showPreferencesUpdateMessage() {
        utils.showNotificationsMessage({
            messageText: 'UserPreferences_Notifications.updatingPreferencesMessage',
            messageType: 'info',
        });
    }

    // Skips focus  past the navigation links to main content div on click/enter of skip to content link
    handleClickOnSelectSkipToContent = () => {
        this.props.skipToContent && this.props.skipToContent();
        this.props.location.state = {};
    };
    // makes the 'Skip-to-content' button visible on focus
    handleFocusOfSkipToContentAnchor = () => {
        this.skipToContentButton.current.className = 'skip-to-content-visible';
    };

    //makes the 'skip-to-content' button invisible on blur
    handleBlurOfSkipToContentAnchor = () => {
        this.skipToContentButton.current.className = 'skip-to-content-invisible';
    };

    render() {
        const {
            isBatchReportsEnabled,
            isDarkMode,
            toggleTheme,
            user,
            resetLaunchedFrom,
            updatePrevPath,
            diligencePlusMarketingOverlay,
            isSpecifiedCustomer,
            isTrailUser,
        } = this.props;
        const name = {
            first: user.firstName,
            last: user.lastName,
        };
        const currentPageHash = window.location.hash;
        const helpUrlRoot = utils.getHelpUrl(this.props.user, HELP_URLS.HELP_URL.key);
        const isOnStartPage = currentPageHash === START_LOCATION_HASH;
        const isThemeSwitcherDisplayed =
            currentPageHash === START_LOCATION_HASH || currentPageHash === EDIT_LOCATION_HASH;
        const noEntityViewAccess = !isBatchReportsEnabled && !isSpecifiedCustomer && !isTrailUser;
        const shouldDisplayEntityView =
            !noEntityViewAccess && (diligencePlusMarketingOverlay || isBatchReportsEnabled || isSpecifiedCustomer);

        return this.props.user.isLoggedIn ? (
            <div className="diligence-header__menu-wrapper notranslate">
                <div className="diligence-header-menu-left">
                    <ul className="diligence-header-list-items">
                        <ProductSwitcher language={this.props.user.preferences.language} />
                        <div
                            id="skip-to-content-button"
                            ref={this.skipToContentButton}
                            className="skip-to-content-invisible"
                            onFocus={() => this.handleFocusOfSkipToContentAnchor()}
                            onBlur={() => this.handleBlurOfSkipToContentAnchor()}
                            onClick={(e) => this.handleClickOnSelectSkipToContent(e)}
                        >
                            <div className="la-ArrowDown customIcon" />
                            <Link
                                to={{
                                    ...this.props.location,
                                    pathname: this.props.location.pathname,
                                }}
                                onClick={() => {
                                    resetLaunchedFrom();
                                    updatePrevPath(this.props.location);
                                }}
                            >
                                <FormattedMessage id={'General.accesibility.bypassBlock'} />
                            </Link>
                        </div>
                        <Logo
                            resetLaunchedFrom={resetLaunchedFrom}
                            updatePrevPath={updatePrevPath}
                            location={this.props.location}
                            showDilligencePlusLogo={this.props.isBatchReportsEnabled}
                        />
                    </ul>
                </div>
                {!isOnStartPage && (
                    <div className="diligence-header-menu-center">
                        <SearchBarHeader
                            isSearchComplete={this.props.isSearchComplete}
                            useDocumentsCountEndpoint={this.props.user.useDocumentsCountEndpoint}
                            searchParams={this.props.searchParams}
                            prevPath={this.props.prevPath}
                            fuzzyNames={this.props.fuzzyNames}
                            suggestedNames={this.props.suggestedNames}
                            isFuzzyEnabled={this.props.isFuzzyEnabled}
                            isSnapshotEnabled={this.props.isSnapshotEnabled}
                            contentTypes={this.props.contentTypes}
                            changeSearchType={this.props.changeSearchType}
                            changeQuery={this.props.changeQuery}
                            setBooleanTerms={this.props.setBooleanTerms}
                            resetQuery={this.props.resetQuery}
                            setBillingId={this.props.setBillingId}
                            resetFuzzyNames={this.props.resetFuzzyNames}
                            resetSuggestedNames={this.props.resetSuggestedNames}
                            location={this.props.location}
                            selectedCostCode={this.props.selectedCostCode}
                            isCostCodeRequired={this.props.isCostCodeRequired}
                            chooseOnlyFromCostCodesList={this.props.chooseOnlyFromCostCodesList}
                            costCodesList={this.props.costCodesList}
                            useNewResearchSummary={this.props.useNewResearchSummary}
                            adHocSearch={this.props.adHocSearch}
                            emptyEditSearch={this.props.emptyEditSearch}
                            withoutRefresh={true}
                            user={this.props.user}
                        />
                    </div>
                )}
                <div className="diligence-header-menu-right">
                    {isThemeSwitcherDisplayed && (
                        <div className="diligence-header-theme-switcher">
                            <ThemeSwitcher toggleTheme={toggleTheme} isDarkMode={isDarkMode} />
                        </div>
                    )}
                    <ul className="diligence-header-list-items">
                        {shouldDisplayEntityView && <EntityViewLink isEnabled={isBatchReportsEnabled} />}
                        <GoogleAnonymizedSelector />
                        <ManageAlertsSelector />
                        {this.props.user.appSettings.prsOn && (
                            <PublicRecordsSelector
                                links={this.props.user.appSettings.publicRecordTypes}
                                publicRecordsApplicationUrl={this.props.user.appSettings.publicRecordsApplicationUrl}
                                chargeDescription={this.generateChargeDescription()}
                                clientId={this.props.user.preferences.generalSettings.selectedCostCode}
                            />
                        )}
                        <ReportBuilderSelector location={this.props.location} />
                        <UserSelector
                            userName={this.props.user.name}
                            name={name}
                            helpUrlRoot={helpUrlRoot}
                            logOutUser={this.logOutUser}
                            signInProfile={this.props.user.appSettings.authenticationInformation.wamSignIn}
                            resetLaunchedFrom={resetLaunchedFrom}
                            updatePrevPath={this.props.updatePrevPath}
                            location={this.props.location}
                            shouldSavePreferences={this.props.shouldSavePreferences}
                            adminPreferencesAreSaving={this.props.adminPreferencesAreSaving}
                        />
                    </ul>
                </div>
            </div>
        ) : (
            <div className="diligence-header__menu-wrapper">
                <Logo />
                <div className="diligence-header__placeholder">
                    {this.props.subMenuMessage ? (
                        <SubMenu messageId={this.props.subMenuMessage} />
                    ) : (
                        <div className="submenu"></div>
                    )}
                </div>
            </div>
        );
    }
}

export { MainMenu as TestMainMenu };
export default injectIntl(withRouter(withAppContext(withThemeSwitcherContext(MainMenu))));
