import EsgRatingsDonutChart from './EsgRatingsDonutChart';
import React from 'react';
import EsgRatingsSubcategory from './EsgRatingsSubcategory';
import { isEmpty } from 'lodash';
import { ESG_RATINGS_VISUALISATIONS } from '@constants';

const EsgRatingsCategory = (props) => {
    const { elements, rating, ratingCategory, tooltip, title, hideSubcategories, columnIndex } = props;
    const hasElements = elements && !isEmpty(elements);

    return (
        <div className="esg-ratings-category" data-track="esg_ratings-category-container">
            <div className="esg-ratings-category-title" data-track="esg_ratings-category-title">
                {title}
            </div>
            {rating && (
                <EsgRatingsDonutChart
                    size={ESG_RATINGS_VISUALISATIONS.CATEGORY_DONUT.SIZE}
                    fontSize={ESG_RATINGS_VISUALISATIONS.CATEGORY_DONUT.FONT_SIZE}
                    rating={rating}
                    ratingCategory={ratingCategory}
                    tooltip={tooltip}
                    columnIndex={columnIndex}
                />
            )}
            {hasElements &&
                !hideSubcategories &&
                Object.values(elements).map((subcategory, index) => (
                    <EsgRatingsSubcategory key={index} {...subcategory} columnIndex={columnIndex} />
                ))}
        </div>
    );
};

export default EsgRatingsCategory;
