import React, { Fragment } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
    ALERT_ATTACHMENT_TYPE_LABELS,
    ALERT_DELIVERY_TYPE,
    ALERT_DELIVERY_TYPE_DEFAULT,
    ALERT_FREQUENCY_TYPE,
    ALERT_FREQUENCY_TYPE_OPTIONS_TAGS,
    DOC_TYPE,
    SHOW_NO_RESULTS_ALERTS_WARNING,
} from '@constants';
import formatRichMessage from '@utils/formatRichMessage';
import withThemeSwitcherContext from '@contexts/hoc/withThemeSwitcher';
import { ReactComponent as BannerWarningIcon } from '../../../../assets/icons/BannerWarningIcon.svg';
import { ReactComponent as BannerWarningIconDark } from '../../../../assets/icons/BannerWarningIconDark.svg';

const AlertFrequency = (props) => {
    const showNoResultsWarning =
        props.shouldEnableNoResultsWarning &&
        SHOW_NO_RESULTS_ALERTS_WARNING.indexOf(props.frequencyType) > -1 &&
        props.shouldTriggerForNoResults;
    const handleDropdownChange = (section) => (value) => props.handleDropdownChange(value, section);

    const mainFrequencyOption = (
        <select
            data-testid="main-frequency-select-id"
            className="create-alert_select"
            onChange={handleDropdownChange('frequencyType')}
            value={props.frequencyType}
        >
            {Object.keys(ALERT_FREQUENCY_TYPE).map((key, index) => (
                <option key={index} value={key}>
                    {formatRichMessage({ id: ALERT_FREQUENCY_TYPE[key].label }, props.intl)}
                </option>
            ))}
        </select>
    );

    const selectedFrequencyOptions =
        !!ALERT_FREQUENCY_TYPE[props.frequencyType] && ALERT_FREQUENCY_TYPE[props.frequencyType].options;

    const secondaryFrequencyOption =
        props.frequencyType !== ALERT_FREQUENCY_TYPE.HOURLY.value &&
        selectedFrequencyOptions &&
        selectedFrequencyOptions.map((option, index) => (
            <Fragment key={index}>
                {option.connector && (
                    <span className="create-alert-alert-frequency-container_body__frequency__options__connector">
                        <FormattedMessage id={option.connector} />
                    </span>
                )}
                <select
                    key={index}
                    className="create-alert_select"
                    onChange={handleDropdownChange(option.tag)}
                    value={props[option.tag] || option.defaultValue}
                >
                    {Object.keys(option.values).map((key, optionIndex) => (
                        <option key={optionIndex} value={option.values[key].value}>
                            {option.tag !== ALERT_FREQUENCY_TYPE_OPTIONS_TAGS.HOURS
                                ? formatRichMessage({ id: option.values[key].label }, props.intl)
                                : option.values[key].label}
                        </option>
                    ))}
                </select>
            </Fragment>
        ));

    return (
        <div className="create-alert-alert-frequency-container">
            <div className="create-alert-alert-frequency-container_body">
                <span className="create-alert_label">
                    <FormattedMessage id={'Alerts.createAlert.chooseFrequency'} />
                </span>
                <div className="create-alert-alert-frequency-container_body__frequency">
                    {mainFrequencyOption}
                    <div className="create-alert-alert-frequency-container_body__frequency__options">
                        {secondaryFrequencyOption}
                    </div>
                </div>

                <div className="create-alert-sending-options-container_send-as">
                    <div className="create-alert-send-as">
                        <span className="create-alert_label">
                            <FormattedMessage id={'Alerts.createAlert.sendAs'} />
                        </span>
                        <select
                            data-testid="send-as-select-id"
                            className="create-alert_select"
                            onChange={handleDropdownChange('deliveryType')}
                            value={props.deliveryType}
                        >
                            {Object.keys(ALERT_DELIVERY_TYPE).map((key, index) => (
                                <option key={index} value={key}>
                                    {formatRichMessage({ id: ALERT_DELIVERY_TYPE[key].label }, props.intl)}
                                </option>
                            ))}
                        </select>
                    </div>
                    {props.deliveryType !== ALERT_DELIVERY_TYPE_DEFAULT && (
                        <div className="create-alert-format">
                            <span className="create-alert_label">
                                <FormattedMessage id={'General_CoreFunctionality_fileFormat'} />
                            </span>
                            <select
                                className="create-alert_select"
                                onChange={handleDropdownChange('attachType')}
                                value={props.attachType}
                            >
                                {Object.keys(DOC_TYPE).map((key, index) => (
                                    <option key={index} value={key}>
                                        {formatRichMessage({ id: ALERT_ATTACHMENT_TYPE_LABELS[key] }, props.intl)}
                                    </option>
                                ))}
                            </select>
                        </div>
                    )}
                </div>
                <div className="toggle-no-results-alerts">
                    <div className="custom-toggle">
                        <input
                            id="alerts-toggle-no-results"
                            className="custom-toggle-checkbox"
                            type="checkbox"
                            title={'Send alerts even when there are no results.'}
                            checked={props.shouldTriggerForNoResults}
                            onChange={props.handleNoResultsToggle}
                        />
                        <label htmlFor={'alerts-toggle-no-results'} className="custom-toggle-label"></label>
                    </div>
                    <span className="toggle-no-results-alerts_message">
                        <FormattedMessage id={'Alerts.createAlert.noResultsLabel'} />
                    </span>
                </div>
                {showNoResultsWarning && (
                    <div className={`alert-warning-message ${props.isDarkMode ? 'dark-mode' : ''}`}>
                        <div className="alert-warning-message__icon">
                            {props.isDarkMode ? <BannerWarningIconDark/> : <BannerWarningIcon />}
                        </div>
                        <div className="alert-warning-message__text">
                            <FormattedMessage id={'Alerts.createAlert.noResultsWarning'} />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default injectIntl(withThemeSwitcherContext(AlertFrequency));
