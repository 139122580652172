import React from 'react';
import utils from '@utils/utilities';

// improper naming since we are not actually using any endpoints
// maybe after UBO caching is implemented we can move pagination to BE side, similar to alerts/history
const withUboEndpoints = (HandlerProvider) => (props) => {
    const filterData = (object) => {
        let filteredData = [];

        if (!props.state.filtering.filteringDropdownSelected) {
            return object;
        } else {
            object.map((item) => {
                if (item.beneficialOwnershipPercentage >= props.state.filtering.filteringDropdownSelected) {
                    filteredData.push(item);
                }
            });
        }

        return filteredData;
    };

    const handleApiGet = (loadParams) => {
        const { pagination } = loadParams;

        props.state.updateSortingAndFiltering({
            sorting: {
                sortBy: props.state.sorting.sortBy,
                direction: props.state.sorting.direction,
            },
            filtering: {
                filteringDropdownSelected: props.state.filtering.filteringDropdownSelected,
            },
        });

        // sort all the Ubo table data
        let rowsToBeShown = [...props.state.uboData.tableData].sort(utils.compare(props.state.sorting));
        // filter the data based on the filtering option selected
        rowsToBeShown = filterData(rowsToBeShown);
        // show only the necessary rows based on pagination
        rowsToBeShown = rowsToBeShown.slice(
            pagination.pageNumber * pagination.pageSize,
            pagination.pageNumber * pagination.pageSize + pagination.pageSize
        );

        return new Promise((resolve, reject) => {
            resolve(rowsToBeShown);
            reject('error');
        });
    };

    const handleApiCount = () => {
        return new Promise((resolve, reject) => {
            resolve(filterData(props.state.uboData.tableData).length);
            reject('error');
        });
    };

    return <HandlerProvider {...props} onApiGet={handleApiGet} onApiCount={handleApiCount} />;
};

export default withUboEndpoints;
