import React, { Component } from 'react';

export class SanctionsHeaderTemplate extends Component {
    getCompatibleCell(uncertainCell) {
        const text = uncertainCell.text;
        return { ...uncertainCell, text };
    }

    update(cell, cellToMerge) {
        return this.getCompatibleCell({ ...cell, text: cellToMerge.text });
    }

    render(cell) {
        const content = cell.text;

        return (
            <div className='sanctions-table-header'>
                <p>{content}</p>
            </div>
        );
    }
}
