import React from 'react';
import { AdminLockContext } from '../AdminLockContextProvider';
import utils from '@utils/utilities';

const withAdminLockContext = (Component) => {
    const withAdminLockContext = (props) => (
        <AdminLockContext.Consumer>
            {(adminLocks) => <Component {...props} adminLocks={adminLocks} />}
        </AdminLockContext.Consumer>
    );

    withAdminLockContext.displayName = `withAdminLockContext(${utils.getDisplayName(Component)})`;
    withAdminLockContext.meta = Component.meta;

    return withAdminLockContext;
};

export default withAdminLockContext;
