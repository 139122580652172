import React, { Fragment } from 'react';
import utils from '@utils/utilities';
import { DefaultCheckbox } from '../defaults';

/**
 * Adds a simple checkbox before whatever component
 * @param WrappedComponent
 */

const withSelectBox = (WrappedComponent) => {
    const withSelectBoxComponent = (props) => (
        <Fragment>
            <DefaultCheckbox
                onToggleSelect={props.onToggleSelect}
                item={props.row}
                checked={props.row.selected}
                value={props.row.key}
            />
            <WrappedComponent {...props} />
        </Fragment>
    );

    withSelectBoxComponent.displayName = `WithSelectBox(${utils.getDisplayName(WrappedComponent)})`;

    return withSelectBoxComponent;
};

export default withSelectBox;
