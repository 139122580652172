export const SET_UPLOAD_STATUS = 'SET_UPLOAD_STATUS';
export const SELECTED_ARTICLE = 'SELECTED_ARTICLE';
export const SELECTED_ARTICLES = 'SELECTED_ARTICLES';
export const CLEAR_SELECTED_ARTICLE = 'CLEAR_SELECTED_ARTICLE';
export const CLEAR_SELECTED_ARTICLES = 'CLEAR_SELECTED_ARTICLES';
export const SELECTED_DUPLICATES = 'SELECTED_DUPLICATES';
export const CLEAR_SELECTED_DUPLICATES = 'CLEAR_SELECTED_DUPLICATES';
export const SET_SELECTED_ARTICLES_INFO = 'SET_SELECTED_ARTICLES_INFO';

let articlesManagerActions = {
    selectArticle: (article, researchSummary, query, searchType, reportId, searchReportId) => {
        return {
            type: SELECTED_ARTICLE,
            payload: {
                selectedArticle: {
                    article: article,
                    searchQuery: query,
                    searchType: searchType,
                    researchSummary: researchSummary,
                    reportId: reportId,
                    searchReportId: searchReportId,
                },
            },
        };
    },

    selectArticles: (articles, categoryName, isSelected) => {
        return {
            type: SELECTED_ARTICLES,
            payload: { articles, categoryName, isSelected },
        };
    },

    setSelectedArticlesInfo: (articleInfo, isSelected) => {
        return {
            type: SET_SELECTED_ARTICLES_INFO,
            payload: { articleInfo, isSelected },
        };
    },

    selectDuplicates: (articles, categoryName, isSelected) => {
        return {
            type: SELECTED_DUPLICATES,
            payload: { articles, categoryName, isSelected },
        };
    },

    clearSelectedArticles: (categoryNames) => {
        return {
            type: CLEAR_SELECTED_ARTICLES,
            payload: { categoryNames },
        };
    },

    clearSelectedArticle: () => {
        return {
            type: CLEAR_SELECTED_ARTICLE,
            payload: {},
        };
    },

    clearSelectedDuplicates: (categoryNames) => {
        return {
            type: CLEAR_SELECTED_DUPLICATES,
            payload: { categoryNames },
        };
    },

    setUploadState: (articlesHashs, categoryName, isUploading) => {
        return {
            type: SET_UPLOAD_STATUS,
            payload: { articlesHashs, categoryName, isUploading },
        };
    },
};

export default articlesManagerActions;
