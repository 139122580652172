let graphContext = {
    modules: {},
    content: {},
};

function initSimulation(d3, svg, nodes, links, config, handlers = {}) {
    graphContext.content = {
        links: paintLinks(svg, links),
        rootNode: paintRootNode(d3, svg, nodes.root, handlers),
        personNodes: paintPersonNodes(d3, svg, nodes.person),
        companyNodes: paintCompanyNodes(d3, svg, nodes.company, handlers),
        defs: generateSimpleDefs(svg),
    };

    const simulation = d3
        .forceSimulation(nodes.all)
        .force(
            'link',
            d3
                .forceLink(links)
                .id((d) => d.id)
                .distance((d) => {
                    return d.source.isMain || d.target.isMain ? config.forceDistanceFromMainNode : config.forceDistance;
                })
        )
        .force(
            'charge',
            d3.forceManyBody().strength((d) => {
                return d.isMain ? config.chargeStrengthFromMainNode : config.chargeStrength;
            })
        )
        .force('center', d3.forceCenter(config.width / 2, config.height / 2))
        .force(
            'collision',
            d3.forceCollide().radius((d) => d.radius + 20)
        );

    return simulation;
}

function getIntersection(dx, dy, cx, cy, w, h) {
    if (Math.abs(dy / dx) < h / w) {
        // Hit vertical edge of target rect
        return {
            x: cx + (dx > 0 ? w : -w),
            y: cy + (dy * w) / Math.abs(dx),
        };
    } else {
        // Hit horizontal edge of target rect
        return {
            x: cx + (dx * h) / Math.abs(dy),
            y: cy + (dy > 0 ? h : -h),
        };
    }
}

function simulationTick(d3, svg) {
    const { rootNode, personNodes, companyNodes, links } = graphContext.content;

    rootNode.attr('transform', (d) => `translate(${d.x}, ${d.y})`);
    personNodes.attr('transform', (d) => `translate(${d.x}, ${d.y})`);
    companyNodes.attr('transform', (d) => `translate(${d.x}, ${d.y})`);

    links.attr('d', (d) => {
        if (d.target.entitytype === 'person') {
            // Total difference in x and y from source to target
            const diffX = d.target.x - d.source.x;
            const diffY = d.target.y - d.source.y;

            // Length of path from center of source node to center of target node
            const pathLength = Math.sqrt(Math.pow(diffX, 2) + Math.pow(diffY, 2));

            // x and y distances from center to outside edge of target node (circle)
            const offsetX = (diffX * d.target.radius) / pathLength;
            const offsetY = (diffY * d.target.radius) / pathLength;

            return 'M' + d.source.x + ',' + d.source.y + 'L' + (d.target.x - offsetX) + ',' + (d.target.y - offsetY);
        } else {
            // get half width and half height
            const hWidth = d.target.size.width / 2;
            const hHeight = d.target.size.height / 2;

            // get top left coordinates
            const x = d.target.x - hWidth;
            const y = d.target.y - hHeight;

            // get target center coordinates
            const targetCX = x + hWidth;
            const targetCY = y + hHeight;

            // get distance between centers
            const diffX = targetCX - d.source.x;
            const diffY = targetCY - d.source.y;
            const intersection = getIntersection(-diffX, -diffY, targetCX, targetCY, hWidth, hHeight);

            return 'M' + d.source.x + ',' + d.source.y + 'L' + intersection.x + ',' + intersection.y;
        }
    });

    const pathInfo = svg.selectAll('.mid-line-info-text');
    pathInfo.attr('transform', (d) => {
        const posX = (d.source.x + d.target.x) / 2,
            posY = (d.source.y + d.target.y) / 2;

        return `translate(${posX}, ${posY})`;
    });
}

function paintLinks(svg, links) {
    const wrapper = svg.append('g').attr('class', 'paths-wrapper');

    const pathContainer = wrapper
        .selectAll('g.paths')
        .append('g')
        .data(links)
        .enter()
        .append('g')
        .attr('class', 'path');

    const paths = pathContainer
        .append('path')
        .attr('class', (d) => `path link ${d.relationshipType}`)
        .attr('marker-end', `url(#arrow)`);

    const pathText = paintTextOnRect(pathContainer, 'role', 'mid-line-info-text', 10, 0);
    pathText.attr('class', 'mid-line-info-text');

    return paths;
}

function paintRootNode(d3, svg, nodes, handlers = {}) {
    const radius = 70;

    //create a container for each node
    let mainCompanyNode = svg
        .selectAll('g.root-nodes')
        .data(nodes)
        .enter()
        .append('g')
        .attr('id', 'root-node')
        .attr('class', (d) => `nodes root-node ${d.cadastralStatus}`)
        .on('mouseover', handlers.onHover)
        .on('mousemove', handlers.onMouseMove)
        .on('mouseout', handlers.onMouseOut);

    //append circle
    mainCompanyNode
        .append('circle')
        .attr('r', (d) => (d.radius = radius))
        .attr('class', 'root-node circle');

    //append title
    const titleMaxLines = 2;
    const titleCharsPerLine = 12;

    const title = mainCompanyNode
        .append('text')
        .attr('class', 'root-node title')
        .attr('font-size', 15)
        .attr('x', 0)
        .attr('y', 10)
        .text((d) => d.name.toUpperCase())
        .call(wrapText(), d3, titleMaxLines, titleCharsPerLine);

    //append taxId
    const titleHeight = (title.node() && title.node().getBoundingClientRect().height) || 0;
    const titleLines = (title.node() && title.node().childNodes.length) || 0;

    mainCompanyNode
        .append('text')
        .attr('class', 'root-node tax-id')
        .attr('x', 0)
        .attr('y', titleHeight + titleLines * 3 + 3)
        .text((d) => d.cnpjFormattedNumber);

    paintIconOnNode(
        mainCompanyNode,
        'root-node icon',
        {
            width: 35,
            height: 35,
            dx: 0,
            dy: 27.5,
        },
        '../assets/brazilian_ownership/bov-main-company-icon.svg'
    );

    return mainCompanyNode;
}

function paintPersonNodes(d3, svg, nodes) {
    const radius = 35;

    //create a container for each node
    let personNodes = svg.selectAll('g.person-node').data(nodes).enter().append('g').attr('class', 'nodes person-node');

    //append circle
    personNodes
        .append('circle')
        .attr('r', (d) => (d.radius = radius))
        .attr('class', 'person-node circle');

    paintTextOnRect(personNodes, 'name', 'person-node-title', 10, 55);
    paintTextOnRect(personNodes, 'cpfFormatedNumber', 'person-node-cpf-number', 9, 69);

    paintIconOnNode(
        personNodes,
        'person-node icon',
        {
            width: 25,
            height: 25,
            dx: 0,
            dy: 0,
        },
        '../assets/brazilian_ownership/bov-person-icon.svg'
    );

    return personNodes;
}

function paintCompanyNodes(d3, svg, nodes, handlers = {}) {
    const rectSize = {
        width: 200,
        height: 60,
    };

    //create a container for each node
    let companyNodes = svg
        .selectAll('g.company-node')
        .data(nodes)
        .enter()
        .append('g')
        .attr('class', 'nodes company-node')
        .on('mouseover', handlers.onHover)
        .on('mousemove', handlers.onMouseMove)
        .on('mouseout', handlers.onMouseOut);

    //append rect
    companyNodes
        .append('rect')
        .attr('class', 'company-node rect')
        .attr('x', -rectSize.width / 2)
        .attr('y', -rectSize.height / 2)
        .attr('width', rectSize.width)
        .attr('height', rectSize.height)
        .attr('size', (d) => (d.size = rectSize));

    //append title
    const titleMaxLines = 1;
    const titleCharsPerLine = 20;

    companyNodes
        .append('text')
        .attr('class', 'company-node title')
        .attr('font-size', 14)
        .attr('x', 0)
        .attr('y', -3)
        .text((d) => {
            d.hasTruncatedTitle = d.name.length > titleCharsPerLine * titleMaxLines;
            return d.name.toUpperCase();
        })
        .call(wrapText(), d3, titleMaxLines, titleCharsPerLine);

    companyNodes
        .append('text')
        .attr('class', 'company-node tax-id')
        .attr('x', 0)
        .attr('y', 14)
        .text((d) => d.cnpjFormatedNumber);

    return companyNodes;
}

function paintTextOnRect(parent, textField, className, fontSize, yPos, isTransparent = false) {
    const padding = 10;

    function getTextBox(selection) {
        selection.each(function (d) {
            d.bbox = this.getBBox();
        });
    }

    const textContainer = parent.append('g').attr('class', className);

    textContainer
        .append('text')
        .attr('pointer-events', 'none')
        .attr('x', 0)
        .attr('y', yPos)
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'middle')
        .attr('font-size', fontSize)
        .text((d) => d[textField])
        .call(getTextBox);

    textContainer
        .insert('rect', 'text')
        .attr('pointer-events', 'none')
        .attr('x', (d) => d.bbox.x - padding)
        .attr('y', (d) => d.bbox.y)
        .attr('width', (d) => d.bbox.width + 2 * padding)
        .attr('height', (d) => d.bbox.height)
        .style('fill', isTransparent ? 'none' : '#ffffff');

    return textContainer;
}

function paintIconOnNode(node, className, settings, path) {
    const image = node
        .append('svg:image')
        .attr('class', className)
        .attr('xlink:href', path)
        .attr('x', -(settings.width / 2 + settings.dx || 0))
        .attr('y', -(settings.height / 2 + settings.dy || 0))
        .attr('width', settings.width)
        .attr('height', settings.height);

    return image;
}

function wrapText(wrappingMode = 'NODE', textInput = '') {
    return (element, d3, maxLines = 2, maxCharsPerLine = 12) => {
        const composeWordsFromText = (text) => {
            let words = [];
            const regex = new RegExp(`(?:\\s|^)(\\S.{0,${maxCharsPerLine - 1}}|\\S+)(?!\\S)`);

            words = text
                .split(regex)
                .map((w) => w.trim())
                .filter(Boolean);

            //if the first word is longer than 12 characters, reduce the text to 1 line
            if (words[0].length > maxCharsPerLine) words.splice(1, words.length);

            //apply ellipsis
            words = words.map(mapEllipsis(words));

            return words;
        };

        const hasMoreWords = (wordsArray, index) => wordsArray[index + 1] != null;

        const mapEllipsis = (words) => (word, index) => {
            const maxLength = word.length < maxCharsPerLine - 3 ? word.length : maxCharsPerLine - 3;

            if (hasMoreWords(words, index)) {
                return index === maxLines - 1 ? word.substring(0, maxLength) + '...' : word;
            } else {
                return word.length > maxCharsPerLine ? word.substring(0, maxLength) + '...' : word;
            }
        };

        const wrap = (textElement, text) => {
            const words = composeWordsFromText(text);

            let lines = [],
                x = textElement.attr('x'),
                y = textElement.attr('y');

            textElement.text(null);

            words.forEach((word, index) => {
                if (index >= maxLines) return;

                const lineHeight = lines.length * (lines.length > 0 ? 15 : 0);
                const tspan = textElement
                    .append('tspan')
                    .attr('x', x)
                    .attr('y', parseFloat(y, 10) + lineHeight + 'px')
                    .attr('font-size', textElement.attr('font-size') || 18)
                    .attr('text-anchor', 'middle')
                    .attr('alignment-baseline', 'hanging')
                    .attr('dominant-baseline', 'hanging')
                    .text(word);

                lines.push(tspan);
            });
        };

        const wrapNodesText = (nodes) => {
            nodes.each(function (data) {
                const textElement = d3.select(this);
                const initialText = data.name.toUpperCase();

                wrap(textElement, initialText);
            });
        };

        const wrapElementText = (textElement, initialText) => wrap(textElement, initialText);

        switch (wrappingMode) {
            case 'NODE':
                wrapNodesText(element);
                break;
            case 'CONTAINER':
                wrapElementText(element, textInput);
                break;
            default:
                wrapElementText(element, textInput);
        }
    };
}

function generateSimpleDefs(svg) {
    const defs = svg.append('defs');
    generateArrowMarker(defs);

    return defs;
}

function generateArrowMarker(defs) {
    defs.append('svg:marker')
        .attr('id', 'arrow')
        .attr('viewBox', '0 -5 10 10')
        .attr('fill', '#656565')
        .attr('refX', 10) //so that it comes towards the center.
        .attr('markerWidth', 7)
        .attr('markerHeight', 7)
        .attr('orient', 'auto')
        .append('svg:path')
        .attr('d', 'M0,-5L10,0L0,5');
}

try {
    module.exports = {
        initSimulation,
        simulationTick,
        wrapText,
    };
} catch (e) {
    console.log(e);
}
