import React from 'react';
import { formatDunsNumber } from '@sagas/helpers/uboHelper';

const MatchItem = React.forwardRef(({ children, onClick, selected = false, className = '', itemIndex }, ref) => {
    function handleClick() {
        onClick(itemIndex);
    }
    return (
        <div className={`match-item ${className} ${selected ? 'selected' : ''}`} ref={ref} onClick={handleClick}>
            <div className={'item-content'}>{children}</div>
        </div>
    );
});

export const CompanyMatchItem = React.forwardRef(
    ({ title, address, locality, country, duns, className = '', ...props }, ref) => {
        return (
            <MatchItem {...props} className={`ubo-company-match-item ${className}`} ref={ref}>
                <div className={'company-name'}>{title}</div>
                <div className={'company-address'}>
                    {address}, {locality}, {country}
                </div>
                {duns && duns.length && <div className={'company-duns'}>{formatDunsNumber(duns)}</div>}
            </MatchItem>
        );
    }
);

export default MatchItem;
