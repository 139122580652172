import React, { Component } from 'react';
import CheckboxFilter from '../CheckboxFilter';

export default class SimilarNamesPostFilter extends Component {
    handleChange = (options, option, e) => {
        // for now just pass the handler up, any transformations should happen here
        this.props.onChange(options, option, e);
    };

    render() {
        return (
            <div className={'similarNames-postfilter'}>
                <CheckboxFilter
                    {...this.props}
                    options={this.props.options}
                    onChange={this.handleChange}
                    fieldId={'similarNames-postfilter'}
                />
            </div>
        );
    }
}
