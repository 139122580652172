import { ESG_RATINGS_COLORS, TOOLTIP_PLACEMENT } from '@constants';

const EsgRatingsUtils = {
    buildPropertiesForEsgHeader(content) {
        if (!content) {
            return {};
        }

        const parser = new DOMParser();
        const htmlDoc = parser.parseFromString(content, 'text/html');

        return {
            dataSchema: htmlDoc.querySelector('[name="csrhub-data-schema"]')?.getAttribute('content'),
            dataSources: htmlDoc.querySelector('[name="csrhub-data-sources"]')?.getAttribute('content'),
            ratingsMethodology: htmlDoc.querySelector('[name="csrhub-ratings-methodology"]')?.getAttribute('content'),
            logo: htmlDoc.querySelector('.CSRHubLogo img')?.getAttribute('src'),
        };
    },

    getRatingRange: (rating) => {
        const ratingLabel = 'Rating_';

        if (rating < 30) return `${ratingLabel}0`;
        if (rating >= 30 && rating < 40) return `${ratingLabel}30`;
        if (rating >= 40 && rating < 50) return `${ratingLabel}40`;
        if (rating >= 50 && rating < 60) return `${ratingLabel}50`;
        if (rating >= 60 && rating < 80) return `${ratingLabel}60`;
        if (rating >= 80 && rating <= 100) return `${ratingLabel}80`;
    },

    buildPropertiesForEsgDocument(content) {
        if (!content) {
            return {};
        }

        const parser = new DOMParser();
        const htmlDoc = parser.parseFromString(content, 'text/html');

        let esgDocumentProperties = {};
        esgDocumentProperties.overallRating = htmlDoc.querySelector('.ESGRating > .CSRRatingScore > span')?.textContent;
        esgDocumentProperties.overallRatingCategory = htmlDoc
            .querySelector('.ESGRating > .CSRRatingScore')
            ?.getAttribute('data-score-range');
        esgDocumentProperties.sourceCount = htmlDoc.querySelector('.SourceCount')?.textContent;
        esgDocumentProperties.dateRange = htmlDoc.querySelector('.DateRange')?.textContent;
        esgDocumentProperties.copyright = htmlDoc.querySelector('.CSRHubCopyright div')?.textContent;
        esgDocumentProperties.categories = {};

        const categories = htmlDoc.querySelectorAll('.ESG_subcategory');
        const arrayOfCategories = [...categories];

        if (arrayOfCategories?.length) {
            arrayOfCategories.forEach((subcategory, key) => {
                const subcategoryInfoElements = subcategory.querySelectorAll('.ESG_SubcategoryInfo');

                esgDocumentProperties.categories[key] = {
                    title: subcategory.querySelector('.ESGSubcategoryTitle')?.textContent,
                    rating: subcategory.querySelector('.ESGSubcategoryTitle + div .CSRRatingScore span')?.textContent,
                    ratingCategory: subcategory
                        .querySelector('.ESGSubcategoryTitle + div .CSRRatingScore')
                        ?.getAttribute('data-score-range'),
                    tooltip: subcategory.querySelector('.ESGSubcategoryTitle + div .tooltip')?.textContent,
                    elements: {},
                };

                const arrayOfSubcategories = [...subcategoryInfoElements];
                if (arrayOfSubcategories?.length) {
                    arrayOfSubcategories.forEach((infoElement, infoKey) => {
                        esgDocumentProperties.categories[key].elements[infoKey] = {
                            title: infoElement.querySelector('.ESGSubcategoryInfoTitle')?.textContent,
                            rating: infoElement.querySelector('.ESGSubcategoryInfoTitle + span')?.textContent,
                            ratingCategory: infoElement
                                .querySelector('.CSRRatingScore')
                                ?.getAttribute('data-score-range'),
                            tooltip: infoElement.querySelector('.tooltip')?.textContent,
                        };
                    });
                }
            });
        }

        return esgDocumentProperties;
    },

    buildCompanySourceInformationContent(content) {
        if (!content) {
            return {};
        }

        const parser = new DOMParser();
        const htmlDoc = parser.parseFromString(content, 'text/html');

        return htmlDoc.querySelectorAll('body > *:not(.ESGRating):not(.toggleSubCategoryView):not(.CSRHubCopyright)');
    },

    getColorsBasedOnCategory(ratingCategory) {
        if (ratingCategory === 'N/A' || ratingCategory === undefined) {
            return { defaultColor: ESG_RATINGS_COLORS.NAColor, fillColor: ESG_RATINGS_COLORS.NAColor };
        }

        const defaultColor = ESG_RATINGS_COLORS.default;
        const colorIndex = ratingCategory?.split('_')[1] || null;
        return { defaultColor, fillColor: colorIndex ? ESG_RATINGS_COLORS[colorIndex] : defaultColor };
    },

    getTooltipPositionBasedOnColumnIndex(columnIndex) {
        return columnIndex === 0
            ? TOOLTIP_PLACEMENT.RIGHT
            : columnIndex === 3
            ? TOOLTIP_PLACEMENT.LEFT
            : TOOLTIP_PLACEMENT.BOTTOM;
    },
};

export default EsgRatingsUtils;
