import React, { Fragment } from 'react';
import * as DilProps from '@utils/props';
import utils from '@utils/utilities';
import { FormattedMessage } from 'react-intl';

/**
 * Handles download, adds a button and provides download handlers
 * @param TableComponent
 * @returns {function(*)}
 */

const withDownload = (TableComponent) => {
    const withDownload = (props) => {
        const { popupType, onDownloadData } = props.data.download;
        const isDisabled = props.data.isLoading || !props.data?.rows?.length;

        return (
            <Fragment>
                <button
                    className={`download download-header button-primary-sm ${isDisabled ? 'disabled' : ''}`}
                    disabled={isDisabled ?? true}
                    value={popupType}
                    onClick={() => onDownloadData(popupType)}
                >
                    <div className="la-Download" />
                    <span>
                        <FormattedMessage id={'PopupBuilder.title.download'} />
                    </span>
                </button>
                <TableComponent {...props} />
            </Fragment>
        );
    };

    withDownload.propTypes = {
        data: DilProps.TableData.isRequired,
    };

    withDownload.displayName = `WithDownload(${utils.getDisplayName(TableComponent)})`;

    return withDownload;
};

export default withDownload;
