import { uniq } from 'lodash';

export const GRAPH_TAGS = [
    { name: 'over25', filterFn: (node) => node.beneficialOwnershipPercentage >= 25, value: 25 },
    { name: 'over50', filterFn: (node) => node.beneficialOwnershipPercentage >= 50, value: 50 },
];

export function selectTags(node, tags) {
    return tags.filter((tag) => tag.filterFn(node)).map((tag) => tag.name);
}

/**
 * Will walk the array and tag the links and nodes based on percentages
 * @param graph
 * @return {*}
 */
export function tagGraph(graph) {
    // taggedGraph will be mutated
    const taggedGraph = {
        links: Object.assign([], graph.links),
        nodes: Object.assign([], graph.nodes),
    };
    graphWalkAndTag(0, taggedGraph, GRAPH_TAGS);
    return taggedGraph;
}

/**
 * Walks the array from the root until it hits a leaf, and then on the way back tags the path and the nodes on the path
 * @param startIndex - the start index for walking up the graph
 * @param graph - { nodes, links } -- WILL BE MUTATED !!
 * @param tags - { name, filterFn }
 */
export function graphWalkAndTag(startIndex, graph, tags) {
    const allPaths = graph.links.filter((link) => link.sourceIndex === startIndex); // unvisited paths
    const paths = allPaths.filter((link) => !link.visited); // unvisited paths
    if (paths.length === 0) {
        // when we hit a leaf return the tags and end the recursive function (going back on path)
        let selectedTags;
        if (allPaths.length) {
            // we have some paths but they are already visited, we use the already selected tags
            selectedTags = graph.nodes[startIndex].tags;
        } else {
            // this is a leaf, we selecet the tag from it
            selectedTags = selectTags(graph.nodes[startIndex], tags);
            graph.nodes[startIndex].tags = selectedTags;
        }
        return selectedTags;
    }
    paths.forEach((path) => {
        if (!path.visited) {
            // mark the link as visited, so we dont walk the same path
            path.visited = true;
            const selectedTags = graphWalkAndTag(path.targetIndex, graph, tags);
            // on the way back, merge the tags on path and source node
            path.tags = uniq([...(selectedTags || []), ...(path.tags || [])]);
            graph.nodes[startIndex].tags = uniq([...(selectedTags || []), ...(graph.nodes[startIndex].tags || [])]);
        }
    });
    return graph.nodes[startIndex].tags || [];
}
