/**
 * HEADER REDUX ACTIONS
 *
 * @version 0.1
 */

import reduxStore from '@reduxStore';

export const UPDATE_HEADER = 'UPDATE_HEADER';

const actions = {
    updateHeader: function (location) {
        reduxStore.dispatch({
            type: UPDATE_HEADER,
            payload: location,
        });
    },
};

export default actions;
