import {
    ADHOC_SEARCH_ADD_CONTENT_TYPE,
    ADHOC_SEARCH_REMOVE_CONTENT_TYPE,
    CHANGE_ADHOC_DATE_RANGE,
    CLOSE_POPUP,
    SET_COMMENTS,
    SET_DOC_TYPE,
    SET_EMAIL_ADDRESS,
    SET_EMAIL_COMMENTS,
    SET_EMAIL_SUBJECT,
    SET_FILE_NAME,
    SET_MODAL_TYPE,
    SET_MODEL,
    SET_REPORT_LIST,
    SET_RESULT_LIST_TYPE,
    UPDATE_ADHOC_PROPERTIES,
    UPDATE_ALL_BUTTON_STATUS,
    TOGGLE_BUTTON_STATUS_BY_TYPE,
    UPDATE_CUSTOM_QUERY_LANGUAGE,
    UPDATE_CUSTOM_NEWS_QUERY_TITLE,
    UPDATE_LEGAL_SEARCH_NAME_PARTY_ENTITY,
    UPDATE_DELIVERY_CONTENT_OPTIONS,
    UPDATE_MODAL_COMPONENTS,
    TOGGLE_PRIMARY_BUTTON_DISABLED_STATUS,
    TOGGLE_DELIVERY_OPTIONS_DISPLAY,
    REPORT_TYPE_CHANGE,
    REORDER_SELECTED_REPORTS,
    CLEAR_POPUP,
} from './PopupModel.actions';
import { cloneDeep } from 'lodash';
import update from 'immutability-helper';

function popupModel(state = {}, action) {
    let contentTypes = {};
    let buttons;

    switch (action.type) {
        case CLOSE_POPUP:
            return Object.assign({}, state, { isVisible: false });

        case SET_MODAL_TYPE:
            return Object.assign({}, state, { type: action.payload });

        case SET_COMMENTS:
            return Object.assign({}, state, { comments: action.payload });

        case SET_DOC_TYPE:
            return Object.assign({}, state, { docType: action.payload });

        case SET_FILE_NAME:
            return Object.assign({}, state, { fileName: action.payload });

        case SET_EMAIL_ADDRESS:
            return Object.assign({}, state, { emailAddress: action.payload });

        case SET_EMAIL_SUBJECT:
            return Object.assign({}, state, { emailSubject: action.payload });

        case SET_EMAIL_COMMENTS:
            return Object.assign({}, state, { emailComments: action.payload });

        case SET_REPORT_LIST:
            if (!action.payload || action.payload.length === 0) {
                return state;
            }

            return Object.assign({}, state, { reportListIds: action.payload });

        case SET_RESULT_LIST_TYPE:
            return Object.assign({}, state, { resultListType: action.payload });

        case CHANGE_ADHOC_DATE_RANGE:
            if (!action.payload || !action.payload.dateRange || !action.payload.section) {
                return state;
            }

            const { dateRange, section } = action.payload;
            return update(state, { [section]: { range: { $set: dateRange } } });

        case ADHOC_SEARCH_ADD_CONTENT_TYPE:
            contentTypes = cloneDeep(state.sources);
            contentTypes.forEach((contentTypesItem) => {
                if (action.payload instanceof Array) {
                    action.payload.forEach((source) => {
                        if (contentTypesItem.key === source) {
                            contentTypesItem.checked = true;
                        }
                    });
                }
            });
            return update(state, { sources: { $set: contentTypes } });

        case ADHOC_SEARCH_REMOVE_CONTENT_TYPE:
            contentTypes = cloneDeep(state.sources);
            contentTypes.forEach((item) => {
                action.payload.forEach((source) => {
                    if (item.key === source) {
                        item.checked = false;
                    }
                });
            });
            return update(state, { sources: { $set: contentTypes } });

        case UPDATE_ADHOC_PROPERTIES:
            return update(state, { [action.payload.key]: { $set: action.payload.value } });

        case TOGGLE_BUTTON_STATUS_BY_TYPE:
            let { type, status } = action.payload;
            buttons = cloneDeep(state.buttons);

            buttons.forEach((button) => {
                if (button.type === type) {
                    button.isDisabled = status;
                }
            });
            return update(state, { buttons: { $set: buttons } });

        case TOGGLE_PRIMARY_BUTTON_DISABLED_STATUS:
            buttons = cloneDeep(state.buttons);
            buttons.forEach((button) => {
                if (button.primary) {
                    button.isDisabled = action.payload;
                }
            });
            return update(state, { buttons: { $set: buttons } });

        case UPDATE_ALL_BUTTON_STATUS:
            buttons = cloneDeep(state.buttons);

            buttons.forEach((button) => {
                button.isDisabled = action.payload;
            });

            return update(state, { buttons: { $set: buttons } });

        case UPDATE_CUSTOM_QUERY_LANGUAGE:
            let customQuery = cloneDeep(state.customQuery);

            customQuery.forEach((query) => {
                query.checked = action.payload.indexOf(query.language) > -1;
            });
            return update(state, { customQuery: { $set: customQuery } });

        case UPDATE_CUSTOM_NEWS_QUERY_TITLE:
            return update(state, { editableTitle: { $set: action.payload } });

        case SET_MODEL:
            return Object.assign({}, action.payload);

        case UPDATE_LEGAL_SEARCH_NAME_PARTY_ENTITY:
            return update(state, { searchNamePartyEntity: { $set: action.payload } });

        case UPDATE_DELIVERY_CONTENT_OPTIONS:
            let { option, value } = action.payload;

            let contentsOptions = cloneDeep(state.contentsOptions);
            contentsOptions[option.key] = value;

            return update(state, { contentsOptions: { $set: contentsOptions } });

        case UPDATE_MODAL_COMPONENTS:
            return update(state, { components: { $set: action.payload } });

        case TOGGLE_DELIVERY_OPTIONS_DISPLAY:
            return update(state, { showDeliveryOptions: { $set: action.payload } });

        case REPORT_TYPE_CHANGE:
            return update(state, { reportType: { $set: action.payload } });

        case REORDER_SELECTED_REPORTS:
            if (!action.payload || action.payload.length === 0) {
                return state;
            }

            return update(state, { selectedReports: { $set: action.payload } });

        case CLEAR_POPUP:
            return update(state, {
                $set: {
                    isVisible: false,
                    title: '',
                    buttons: [],
                    comments: '',
                    docType: 'PDF',
                },
            });

        default:
            return state;
    }
}

export default popupModel;
