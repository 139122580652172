import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import ReactTooltip from 'react-tooltip';
import formatRichMessage from '@utils/formatRichMessage';

class AdminLock extends Component {
    static propTypes = {
        isLocked: PropTypes.bool,
        isDisabled: PropTypes.bool,
        isSectionLock: PropTypes.bool, //Elements that use this component with isSectionLock true need to have a position set (other than static) for the section border to appear
        lockName: PropTypes.string,
        onClickLock: PropTypes.func,
    };

    static defaultProps = {
        isLocked: false,
        isSectionLock: false,
        isDisabled: false,
        lockName: '',
    };

    constructor(props) {
        super(props);

        this.toggleLock = this.toggleLock.bind(this);
    }

    toggleLock() {
        if (this.props.onClickLock) {
            this.props.onClickLock(this.props.lockName);
        }
    }

    render() {
        return (
            // ReactTooltip does not display on buttons that are disabled, wrapping in span is required for this scenario.

            <span
                data-tip={formatRichMessage(
                    { id: 'General_CoreFunctionality_UIText_general.lockedByAdminUser' },
                    this.props.intl
                )}
                data-for={this.props.lockName}
                className={this.props.isSectionLock ? 'section-lock' : 'user-section-lock'}
            >
                <button
                    type="button"
                    name={this.props.lockName}
                    disabled={this.props.isDisabled}
                    onClick={this.toggleLock}
                    className={
                        this.props.isLocked ? 'admin-lock la-LockedNote locked' : 'admin-lock la-LockedNote unlocked'
                    }
                    data-testid={`admin-locker-${this.props.lockName}`}
                >
                    {this.props.isDisabled && (
                        <ReactTooltip
                            id={this.props.lockName}
                            type="light"
                            border={true}
                            className="tooltips"
                            place="bottom"
                        />
                    )}
                </button>
            </span>
        );
    }
}

export default injectIntl(AdminLock);
