import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { NO_DOCUMENTS_OF_INTEREST } from '@constants';

export default class ModalEditArticleNote extends Component {
    static propTypes = {
        article: PropTypes.object,
        report: PropTypes.object,
        reports: PropTypes.array,
        isVisible: PropTypes.bool,
    };
    static defaultProps = {
        article: null,
        isVisible: false,
    };

    constructor(props) {
        super(props);
        this.state = {
            input: null,
            isValid: true,
        };
    }

    validateArticleNote(articleNote) {
        if (articleNote.trim() === '') {
            this.setState({
                isValid: false,
            });
        } else {
            this.setState({
                isValid: true,
            });
        }
    }

    addNoteInput(event, article) {
        article.note = event.target.value;
        this.validateArticleNote(article.note);
        this.setState({
            input: event.target.value,
        });
    }

    render() {
        if (this.props.isVisible && this.props.article) {
            return (
                <div className="add-note-to-article">
                    {this.props.article &&
                        (this.props.article.documentId !== '-1' ||
                            this.props.article.documentId !== NO_DOCUMENTS_OF_INTEREST) && (
                            <span>
                                <FormattedMessage id={'ModalEdit_ArticleNote_AddNoteToDocument'} />
                            </span>
                        )}
                    {!this.state.isValid && (
                        <div className="validationAlertMessage">
                            <FormattedMessage id={'ReportNote.ValidationMessage'} />
                        </div>
                    )}

                    <textarea
                        onChange={(event) => {
                            this.addNoteInput(event, this.props.article);
                        }}
                        value={this.props.article.note}
                        className={`text-area-standard ${!this.state.isValid ? 'textarea-error' : ''}`}
                        id={`text-area-add-note-to-article-${this.props.article.id}`}
                    />
                </div>
            );
        } else {
            return null;
        }
    }
}
