import manageAlertsApi from '../ManageAlertsApi';
import { ALERT_DIRECTION, ALERT_SORT_BY, ALERT_TYPE, NOTIFICATION_STORE_KEYS, ROUTES } from '@constants';
import reduxStore from '@reduxStore';
import utils from '@utils/utilities';
import manageSelectedAlertsActions from './ManageSelectedAlerts.actions';
import notificationService from '@utils/notificationService';
import mainActions from '@scripts/pages/Main/Main.actions';
import {ACTION_TYPES} from '@constants';
import snackbarUtils from '@scripts/reusable/SnackbarWithAutohide/snackbarUtils';

export const DELETE_SELECTED_ALERTS = 'DELETE_SELECTED_ALERTS';
export const TOGGLE_ALERT = 'TOGGLE_ALERT';
export const DELETE_ALERT = 'DELETE_ALERT';
export const SET_ALERTS = 'SET_ALERTS';
export const UPDATE_ALERTS_LOADING_STATUS = 'UPDATE_ALERTS_LOADING_STATUS';
export const UPDATE_PUBLIC_ALERTS_LOADING_STATUS = 'UPDATE_PUBLIC_ALERTS_LOADING_STATUS';
export const TOGGLE_ALERTS = 'TOGGLE_ALERTS';
export const SET_ALERT_FILTER_NAME = 'SET_ALERT_FILTER_NAME';
export const SET_IS_NEW_ALERT = 'SET_IS_NEW_ALERT';
export const SET_PUBLIC_RECORD_ALERTS = 'SET_PUBLIC_RECORD_ALERTS';
export const TOGGLE_ALERT_SELECTION = 'TOGGLE_ALERT_SELECTION';
export const SET_ALERTS_COUNT = 'SET_ALERTS_COUNT';
export const SET_ACTIVE_ALERTS_COUNT = 'SET_ACTIVE_ALERTS_COUNT';
export const SET_PUBLIC_RECORDS_COUNT = 'SET_PUBLIC_RECORDS_COUNT';
export const SET_DOWNLOAD_ALERTS_BUTTON_STATE = 'SET_DOWNLOAD_ALERTS_BUTTON_STATE';
export const UPDATE_POLLING_STATUS = 'UPDATE_POLLING_STATUS';

let manageAlertsActions = {
    setPublicRecordAlerts: (alerts, count, status, pageNumber, pageSize) => (dispatch) => {
        dispatch(manageSelectedAlertsActions.clearAllSelectedAlerts(ALERT_TYPE.PUBLIC_RECORD_ALERT));
        dispatch({
            type: SET_PUBLIC_RECORD_ALERTS,
            payload: { alerts, count, status, pageNumber, pageSize },
        });
    },

    deletePublicRecordsAlerts: (alertIds) => (dispatch, getState) => {
        dispatch(manageAlertsActions.updateLoadingStatus(ALERT_TYPE.PUBLIC_RECORD_ALERT, false));
        let { pageSize } = getState().alerts[ALERT_TYPE.PUBLIC_RECORD_ALERT];
        manageAlertsApi
            .deletePublicRecordAlerts(alertIds, pageSize)
            .then((response) => {
                reduxStore.dispatch(manageSelectedAlertsActions.clearAllSelectedAlerts(ALERT_TYPE.PUBLIC_RECORD_ALERT));
                let alerts = response.items || [];
                let count = response.total;
                reduxStore.dispatch(manageAlertsActions.setPublicRecordAlerts(alerts, count, true, 0, pageSize));
            })
            .catch(() => {
                dispatch(manageAlertsActions.updateLoadingStatus(ALERT_TYPE.PUBLIC_RECORD_ALERT, true));
                utils.showNotificationsMessage({
                    messageText: 'ManageAlerts.ErrorMessage.deleteAlert',
                    terms: {
                        term: 'ManageAlerts.ErrorMessage.deleteAlerts',
                    },
                    messageType: 'system-error',
                });
            });
    },

    setAlerts: (alerts, alertType, count, status, pageNumber, pageSize) => (dispatch) => {
        dispatch(manageSelectedAlertsActions.clearAllSelectedAlerts(ALERT_TYPE.MY_ALERT));
        dispatch({
            type: SET_ALERTS,
            payload: { alerts, alertType, count, status, pageNumber, pageSize },
        });
    },

    setFilterAlertName: (filterName) => {
        return {
            type: SET_ALERT_FILTER_NAME,
            payload: filterName,
        };
    },

    updateLoadingStatus: (alertType, status) => {
        return {
            type: UPDATE_ALERTS_LOADING_STATUS,
            payload: { alertType, status },
        };
    },

    toggleAlertSelection: (alerts, alertType, isSelected) => {
        return {
            type: TOGGLE_ALERT_SELECTION,
            payload: { alerts, alertType, isSelected },
        };
    },

    toggleAlert: (alertId, isActive) => {
        return {
            type: TOGGLE_ALERT,
            payload: { alertId, isActive },
        };
    },

    deleteSelectedAlerts: (selectedAlertIds, alertType) => (dispatch, getState) => {
        dispatch(manageAlertsActions.updateLoadingStatus(ALERT_TYPE.MY_ALERT, false));
        let { currentPageIndex, pageSize, filterAlertName } = getState().alerts[alertType];

        const onFinishedDeletePolling = () => {
            reduxStore.dispatch(manageSelectedAlertsActions.clearAllSelectedAlerts(alertType));
            manageAlertsApi.getMyAlertsCount(false, filterAlertName).then((count) => {
                manageAlertsApi
                    .getMyAlerts(currentPageIndex, pageSize, ALERT_SORT_BY, ALERT_DIRECTION, filterAlertName)
                    .then((response) => {
                        dispatch(
                            manageAlertsActions.setAlerts(response, alertType, count, true, currentPageIndex, pageSize)
                        );
                    })
                    .catch(() => {
                        utils.showNotificationsMessage({
                            messageText: 'ManageAlerts.ErrorMessage.getAlerts',
                            messageType: 'system-error',
                        });
                        reduxStore.dispatch(this.updateLoadingStatus(true));
                    });
            });
        };

        manageAlertsApi
            .deleteMyAlerts(selectedAlertIds)
            .then((batchId) => {
                //set the initial data for the delete snackbar
                const deleteAlertsNotificationData = snackbarUtils.getProcessingStatusInitialData({
                    actionType: ACTION_TYPES.DELETE.concat('ALERT'),
                    totalCount: selectedAlertIds.length,
                });
                reduxStore.dispatch(mainActions.addNewBatchId({batchId, data: deleteAlertsNotificationData, keyInTheStore: NOTIFICATION_STORE_KEYS.DELETE_ALERT}));
                notificationService.pollDeleteAlert({
                    route: ROUTES.MANAGE_ALERTS,
                    batchId,
                    onFinished: () => onFinishedDeletePolling(),
                });
            })
            .catch(() => {
                utils.showNotificationsMessage({
                    messageText: 'ManageAlerts.ErrorMessage.deleteAlert',
                    terms: { term: 'ManageAlerts.ErrorMessage.deleteAlerts' },
                    messageType: 'system-error',
                });
            });
    },

    setIsNewAlert: (isNewAlert) => {
        return {
            type: SET_IS_NEW_ALERT,
            payload: isNewAlert,
        };
    },

    toggleAlerts: (alertsIds, isActive) => {
        return {
            type: TOGGLE_ALERTS,
            payload: { alertsIds, isActive },
        };
    },

    setAlertsCount: (alertsCount) => {
        return {
            type: SET_ALERTS_COUNT,
            payload: alertsCount,
        };
    },

    setActiveAlertsCount: (activeAlertsCount) => {
        return {
            type: SET_ACTIVE_ALERTS_COUNT,
            payload: activeAlertsCount,
        };
    },

    setPublicRecordsCount: (publicRecordsCount) => {
        return {
            type: SET_PUBLIC_RECORDS_COUNT,
            payload: publicRecordsCount,
        };
    },

    setDownloadAlertsButtonDisabledState: (isDownloadAlertsButtonDisabled) => {
        return {
            type: SET_DOWNLOAD_ALERTS_BUTTON_STATE,
            payload: isDownloadAlertsButtonDisabled,
        };
    },

    updatePollingStatus: (status) => {
        return {
            type: UPDATE_POLLING_STATUS,
            payload: status,
        };
    },
};

export default manageAlertsActions;
