import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { ReactComponent as BannerInfoIcon } from '../../../../../assets/icons/BannerInfoIcon.svg';
import { ReactComponent as BannerInfoIconDark } from '../../../../../assets/icons/BannerInfoIconDark.svg';

export const ReportBuilderHeader = (props) => {
    const isDarkMode = useSelector((state) => state.user.preferences.generalSettings.isDarkMode);

    const message = () => {
        return (
        <div className="multiline-message">
            {props.publicRecordsOn
                ? (<li><FormattedMessage id={'ReportBuilderPage_Notifications.expiryDatePRsOn'}/></li>)
                : (<li><FormattedMessage id={'ReportBuilderPage_Notifications.expiryDate'}/></li>)
            }
            <li><FormattedMessage id={'ReportBuilderPage_Tooltips.reportMaxDownloadLimit'}/></li>
        </div>
        );
    }

    return (
        <div className="reports-list-header" id="reports-header">
            <div className="reports-list-title">
                <h1>
                    <FormattedMessage id={'UserPreferences.leftSideNavigation.reportBuilder'} />
                </h1>
            </div>
            <div className="horizontal-divider"></div>
            <div className="message-notification-wrapper">
                <div
                    className={`message-notification slide-down ${isDarkMode ? 'dark-mode' : ''}`}
                    id="expiry-notification"
                >
                    <div className="message-notification__icon">
                        {isDarkMode ? <BannerInfoIconDark /> : <BannerInfoIcon />}
                    </div>
                    <div className="message-notification__message">
                        <div className="message-notification__message-container">
                            {message()}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
