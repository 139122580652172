import React from 'react';
import withStateProvider from '@reusable/Table/providers/hoc/withStateProvider';
import TableHandlersProvider from '@reusable/Table/providers/TableHandlersProvider';
import TableStateProvider from '@reusable/Table/providers/TableStateProvider';
import { SORT_DIRECTION } from '@constants';
import withPRAlertsEndpoints from './endpoints/withPRAlertsEndpoints';
import PRAlertsTableConsumer from './consumers/PRAlertsTable';

/**
 *  This is the main alerts table component, basically composing it with the following:
 *  - Table Handler Provider with attached Alerts Endpoints <AlertsTableHandlersProvider>
 *  - Table Handler Consumer with all the specifics for Alerts (handlers, row configuration, header configuration)
 *  - All these attached to a generic Table State Provider, initiated with a default sorting
 */

const AlertsTableHandlersProvider = withPRAlertsEndpoints(TableHandlersProvider); // adds alerts specific endpoints

const PRAlertsTable = (props) => {
    return (
        <AlertsTableHandlersProvider
            {...props.stateProviderProps}
            provideHandlers={(handlerProviderProps) => <PRAlertsTableConsumer {...handlerProviderProps} />}
        />
    );
};

const initAlertState = {
    sorting: {
        sortBy: 'lastUpdated',
        direction: SORT_DIRECTION.DESC,
    },
};

export default withStateProvider(TableStateProvider, initAlertState)(PRAlertsTable);
