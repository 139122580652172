import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router';
import ReactTooltip from 'react-tooltip';
import { injectIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import formatRichMessage from '@utils/formatRichMessage';
import { ROUTES } from '@constants';

import OverlaySpinner from '@scripts/reusable/OverlaySpinner/OverlaySpinner';

const ManageColumns = (props) => {
    // refs
    const popoverRef = useRef(null);
    const manageColumnsRef = useRef(null);
    // state
    const [isOpen, setIsOpen] = useState(false);
    //props
    const { elementsVisibility: contentTypes, updateElementsVisibility, resetTable, isLoading } = props;

    const popupText = formatRichMessage({ id: 'BatchUpload.hideColumnsPopup.preferencesPopupText' });
    
    const someContentTypesAreDisabled = contentTypes.some(
        ({ enabledInGeneralSettings }) => !enabledInGeneralSettings
    );

    const handleOpen = () => {
        setIsOpen((prevIsOpen) => !prevIsOpen);
    };

    const handleClickOutside = useCallback(
        (event) => {
            if (
                popoverRef.current &&
                !popoverRef.current.contains(event.target) &&
                !manageColumnsRef.current.contains(event.target)
            ) {
                handleOpen();
            }
        },
        [popoverRef, manageColumnsRef]
    );

    useEffect(() => {
        isOpen && document.addEventListener('mousedown', handleClickOutside);
        ReactTooltip.rebuild();

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [handleClickOutside, isOpen]);

    const renderContentTypes = () => {
        return contentTypes.map((contentType, index) => {
            const { enabledInGeneralSettings, columnId, hidden, label } = contentType;
            const shouldContentTypeBeDisabled =
                index === 0 || index === contentTypes.length - 1 || !enabledInGeneralSettings;

            return (
                <div key={index} className='custom-checkbox'>
                    <input
                        id={`${columnId}`}
                        disabled={shouldContentTypeBeDisabled}
                        type='checkbox'
                        name={columnId}
                        checked={!hidden || !enabledInGeneralSettings}
                        onChange={updateElementsVisibility}
                        data-testid={`checkbox-${columnId}-${
                            hidden || !enabledInGeneralSettings ? 'hidden' : 'unhidden'
                        }-testid`}
                    />
                    <label htmlFor={`${columnId}`} className='checkbox-label'>
                        {label}
                    </label>
                </div>
            );
        });
    };

    return (
        <div className='manage-columns-filter-container'>
            <div
                ref={manageColumnsRef}
                className={`la-Columns manage-columns-button ${isOpen ? 'active' : ''}`}
                onClick={handleOpen}
                data-track='manage-columns-button'
                data-testid='manage-columns-button'
            >
                <p>{formatRichMessage({ id: 'BatchScreening.page.manageColumns' })}</p>
                <div className={isOpen ? 'la-TriangleUp active' : 'la-TriangleDown'} />
            </div>
            {isOpen && (
                <>
                    <div ref={popoverRef} className={`manage-columns-popover ${isLoading ? 'disabled' : ''}`} data-track='manage-columns-popover' data-testid='manage-columns-popover'>
                        <button className='link-button' data-track='manage-columns-reset-table-button' data-testid='manage-columns-reset-table-button' onClick={resetTable}>
                            <FormattedMessage id='BatchScreening.page.contextMenu.resetTable' />
                        </button>
                        {contentTypes.length !== 0 && renderContentTypes()}
                        <Link
                            className='link-button'
                            to={ROUTES.USER_PREFERENCES}
                            onClick={handleOpen}
                            data-tip={popupText}
                            data-for='column-disabled-in-preferences'
                            data-testid='manage-columns-preferences-link-button'
                        >
                            <FormattedMessage id='BatchUpload.hideColumnsPopup.preferencesLink' />
                        </Link>
                        {isLoading && (
                            <>
                                <div className='loading-overlay' data-track='manage-columns-loading-overlay' data-testid='manage-columns-loading-overlay'/>
                                <OverlaySpinner className='overlay-spinner' size='large' />
                            </>
                        )}
                    </div>

                    {someContentTypesAreDisabled && (
                        <ReactTooltip
                            id='column-disabled-in-preferences'
                            type='light'
                            event='mouseenter'
                            eventOff='mouseleave'
                            border={true}
                            effect='solid'
                            className='tooltips'
                            place='right'
                        />
                    )}
                </>
            )}
        </div>
    );
};

ManageColumns.propTypes = {
    elementsVisibility: PropTypes.array,
    updateElementsVisibility: PropTypes.func,
    resetTable: PropTypes.func,
};

export default injectIntl(ManageColumns);
