import React, { Fragment } from 'react';
import withAutosave from '../HOC/WithAutosave';
import { FE_LOCK_NAMES } from '@constants';
import { FormattedMessage } from 'react-intl';
import LegalSourcesDropdown from './LegalSourcesDropdown';
import AdminLock from '@reusable/AdminLock/AdminLock';
import LegalSourcesNamePartyEntity from './LegalSourcesNamePartyEntity';

const LegalSearches = (props) => {
    const handleLegalSourceChange = (section) => (source) => {
        props.handleAutosave(props.changeLegalSource, [source, section]);
    };

    return (
        <Fragment>
            <div className="user-preferences-category-container__row">
                <h3 className="user-preferences-category-container__topic">
                    <FormattedMessage id="UserPreferences_topic_legal_source_searches" />
                </h3>
                {props.autoSaveMessage}
            </div>

            <LegalSourcesDropdown
                legalSources={props.legalSources}
                legalSource={props.legalSource}
                onChange={handleLegalSourceChange(props.section)}
                adminLock={
                    (props.userIsAdmin || (props.legalSourceLock && !props.masterLock)) && (
                        <AdminLock
                            lockName={FE_LOCK_NAMES.legalSourceDefaultLegalSources}
                            onClickLock={props.onClickLock}
                            isLocked={props.legalSourceLock}
                            isDisabled={!props.userIsAdmin && (props.masterLock || props.legalSourceLock)}
                        />
                    )
                }
                disabledDropdown={!props.userIsAdmin && (props.masterLock || props.legalSourceLock)}
                hasInformationIcons={true}
                sourcesInformation={props.sourcesInformation}
            >
                <div className="user-preferences-category-container__note">
                    <FormattedMessage id="UserPreferences_legal_sources_label_legal_source" />
                </div>
            </LegalSourcesDropdown>

            <div className="search-name-party-entity">
                <LegalSourcesNamePartyEntity
                    searchNamePartyEntity={props.searchNamePartyEntity}
                    changeSearchNamePartyEntity={props.changeSearchNamePartyEntity}
                    userIsAdmin={props.userIsAdmin}
                    handleAutosave={props.handleAutosave}
                />
            </div>
        </Fragment>
    );
};

export default withAutosave()(LegalSearches);
