// @flow
import React from 'react';

type Props = {
    url?: string,
    relp?: string,
    className: string,
    children: React$Node,
};

const ProductItem = (props: Props): React$Element<'a'> => {
    const { url, relp, className, children } = props;
    /* eslint-disable */
    return (
        <a
            href={url ? url : ''}
            rel={relp ? relp : null}
            target="_blank"
            className="diligence-header__menu-link diligence-header__menu-link--dropdown-item--productSwitcher"
        >
            <span className={`icon-span ${className}`}>{children}</span>
        </a>
    );
    /* eslint-enable */
};

export default ProductItem;
