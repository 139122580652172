import React, { Fragment } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import withAdminLock from '@reusable/AdminLock/hoc/withAdminLock';
import { DELIVERY_CONTENT_OPTIONS, FE_LOCK_NAMES, START_ARTICLE_ON_NEW_PAGE } from '@constants';
import Toggle from '@reusable/Toggle/Toggle';
import formatRichMessage from '@utils/formatRichMessage';
import { compose } from 'redux';
import withAutosave from '../HOC/WithAutosave';

const TogglePreference = (props) => {
    return (
        <div className="user-preferences-category-container__list" data-testid={`${props.id}-${props.checked ? 'enabled' : 'disabled'}`}>
            <div className={props.className}>
                <Toggle
                    checked={props.checked}
                    id={props.id}
                    handleChange={props.handleToggle}
                    disabled={props.disabled}
                />
                <span>{props.label}</span>
            </div>
        </div>
    );
};

const ResearchSummaryToggle = compose(
    withAdminLock(FE_LOCK_NAMES.deliveryIncludeResearchSummary, FE_LOCK_NAMES.deliverySettingsAllOptions)
)(TogglePreference);

const DocumentOnNewPageToggle = compose(
    withAdminLock(FE_LOCK_NAMES.deliveryDocumentOnNewPage, FE_LOCK_NAMES.deliverySettingsAllOptions)
)(TogglePreference);

const DeliveryPreferences = (props) => {
    const labelResearchSummary = formatRichMessage({ id: 'UserPreferences.IncludeResearchSummary' }, props.intl);
    const labelDocumentOnNewPage = formatRichMessage({ id: 'UserPreferences.DocumentOnNewPage' }, props.intl);

    const onToggle = (key, checked) => {
        props.handleAutosave(props.handleToggleDeliverySettings, [key, checked]);
    };

    return (
        <Fragment>
            <div className="user-preferences-category-container__row">
                <h2 className="user-preferences-category-container__segment delivery-settings-container__title">
                    <FormattedMessage id="UserPreferences.leftSideNavigation.deliverySettings" />
                </h2>
                {props.autoSaveMessage}
            </div>
            <div className="user-preferences-category-container delivery-settings">
                <span className="user-preferences-category-container__note">
                    <FormattedMessage id="UserPreferences_topic_delivery_settings_explanation" />
                </span>

                <ResearchSummaryToggle
                    className={'toggle-delivery-settings'}
                    label={labelResearchSummary}
                    id={'user-preferences-include-research-summary'}
                    handleToggle={(checked) => {
                        onToggle(DELIVERY_CONTENT_OPTIONS.researchSummary, checked);
                    }}
                    checked={props.deliverySettings.includeResearchSummary}
                />

                <DocumentOnNewPageToggle
                    className={'toggle-delivery-settings'}
                    label={labelDocumentOnNewPage}
                    id={'user-preferences-document-on-new-page'}
                    handleToggle={(checked) => {
                        onToggle(START_ARTICLE_ON_NEW_PAGE, checked);
                    }}
                    checked={props.deliverySettings.startEachArticleOnNewPage}
                />
            </div>
        </Fragment>
    );
};

export default injectIntl(withAutosave()(DeliveryPreferences));
