import {
    NEGATIVE_NEWS_MAX_FOR_DISPLAYING_LABELS,
    NEGATIVE_NEWS_MAX_NUMBER_OF_LABELS,
    NEGATIVITY_BUCKET_NAMES,
    NEGATIVITY_BUCKETS_TO_LEVEL,
    NEGATIVITY_LEVELS_ORDER,
    NEGATIVITY_PILL_STATES,
    NEGATIVITY_COLORS,
    TERMS_NEGATIVITY_MAPPING,
} from '@constants';
import { isEmpty } from 'lodash';
import utils from '@utils/utilities';

const NegativeNewsVisualisationsUtils = {
    processTopNegativeTerms(topNegativeTerms) {
        if (!topNegativeTerms || !topNegativeTerms.length) {
            return [];
        }

        return topNegativeTerms.map((negativeTerm) => {
            const { term, count, negativity, buckets, languageCode } = negativeTerm;

            return {
                negativity,
                term,
                count,
                languageCode,
                buckets: {
                    negativeNewsBuckets: buckets,
                },
            };
        });
    },

    getDateWithCorrectFormat(date) {
        if (!date) {
            return null;
        }

        const values = date.split('/');

        if (values && values.length === 3) {
            return `${values[2]}/${values[1]}/${values[0]}`;
        } else {
            return null;
        }
    },

    getStartAndEndDateFromRange(dateRange) {
        if (dateRange) {
            const dates = dateRange.split(';');
            let startDate,
                endDate = '';

            if (dates[0]) {
                startDate = new Date(this.getDateWithCorrectFormat(dates[0]));
            }

            if (dates[1]) {
                endDate = new Date(this.getDateWithCorrectFormat(dates[1]));
            }

            return { startDate, endDate };
        }

        return dateRange;
    },

    getDatePropertiesFromDate(date, languageKey) {
        if (date && date instanceof Date) {
            const month = date.toLocaleString(languageKey || 'default', { month: 'short' });
            const year = date.getFullYear();
            const day = date.getDate();

            return { day, month, year };
        }

        return null;
    },

    getMonthsDifferenceBetweenDates(startDate, endDate) {
        let months = 0;

        if (startDate instanceof Date && endDate instanceof Date) {
            months = (endDate.getFullYear() - startDate.getFullYear()) * 12;
            months -= startDate.getMonth();
            months += endDate.getMonth();
        }

        return months <= 0 ? 0 : months;
    },

    buildLinesForLineGraph(buckets) {
        let lines = [];

        if (buckets) {
            const { negativeNewsBuckets, highNegativeNewsBuckets, mediumNegativeNewsBuckets, lowNegativeNewsBuckets } =
                buckets;

            if (negativeNewsBuckets && negativeNewsBuckets.length) {
                lines.push({
                    name: TERMS_NEGATIVITY_MAPPING.DEFAULT.field,
                    data: negativeNewsBuckets,
                    color: TERMS_NEGATIVITY_MAPPING.DEFAULT.color,
                });
            }

            if (highNegativeNewsBuckets && highNegativeNewsBuckets.length) {
                lines.push({
                    name: TERMS_NEGATIVITY_MAPPING.HIGH.field,
                    data: highNegativeNewsBuckets,
                    color: TERMS_NEGATIVITY_MAPPING.HIGH.color,
                });
            }

            if (mediumNegativeNewsBuckets && mediumNegativeNewsBuckets.length) {
                lines.push({
                    name: TERMS_NEGATIVITY_MAPPING.MEDIUM.field,
                    data: mediumNegativeNewsBuckets,
                    color: TERMS_NEGATIVITY_MAPPING.MEDIUM.color,
                });
            }

            if (lowNegativeNewsBuckets && lowNegativeNewsBuckets.length) {
                lines.push({
                    name: TERMS_NEGATIVITY_MAPPING.LOW.field,
                    data: lowNegativeNewsBuckets,
                    color: TERMS_NEGATIVITY_MAPPING.LOW.color,
                });
            }
        }

        return lines;
    },

    buildDistinctDatesForLineGraph(buckets) {
        let values = [];

        if (buckets) {
            const { lowNegativeNewsBuckets, mediumNegativeNewsBuckets, highNegativeNewsBuckets, negativeNewsBuckets } =
                buckets;

            if (negativeNewsBuckets && negativeNewsBuckets.length) {
                values = [...values, ...negativeNewsBuckets];
            }

            if (highNegativeNewsBuckets && highNegativeNewsBuckets.length) {
                values = [...values, ...highNegativeNewsBuckets];
            }

            if (mediumNegativeNewsBuckets && mediumNegativeNewsBuckets.length) {
                values = [...values, ...mediumNegativeNewsBuckets];
            }

            if (lowNegativeNewsBuckets && lowNegativeNewsBuckets.length) {
                values = [...values, ...lowNegativeNewsBuckets];
            }

            const distinct = [...new Set(values.map((value) => value.key))];
            return distinct.sort((a, b) => a - b);
        }

        return values;
    },

    getTickIntervalForLineGraph(values, tick, divide) {
        if (values && values.length) {
            const count = values.length / divide;
            const tickInterval = tick;
            const { startDate: dateRangeStart } = this.getStartAndEndDateFromRange(values[0].dateRange);
            const { endDate: dateRangeEnd } = this.getStartAndEndDateFromRange(values[values.length - 1].dateRange);
            const differenceBetweenDates = this.getMonthsDifferenceBetweenDates(dateRangeStart, dateRangeEnd);

            const maxNumberOfItems =
                differenceBetweenDates < 3
                    ? NEGATIVE_NEWS_MAX_NUMBER_OF_LABELS.DAILY_LABELS
                    : NEGATIVE_NEWS_MAX_NUMBER_OF_LABELS.YEARLY_AND_MONTHLY_LABELS;

            return count <= maxNumberOfItems
                ? tickInterval + 1
                : this.getTickIntervalForLineGraph(values, tickInterval + 1, divide + 1);
        }

        return tick;
    },

    buildLabelsForLineGraph(values, language) {
        if (values && values.length) {
            const { startDate: dateRangeStart } = this.getStartAndEndDateFromRange(values[0].dateRange);
            const { endDate: dateRangeEnd } = this.getStartAndEndDateFromRange(values[values.length - 1].dateRange);
            const differenceBetweenDates = this.getMonthsDifferenceBetweenDates(dateRangeStart, dateRangeEnd);

            return values.map((value) => {
                const languageKey = utils.getLanguageKeyForDate(language);
                const { startDate } = this.getStartAndEndDateFromRange(value.dateRange);
                const { day, month, year } = this.getDatePropertiesFromDate(startDate, languageKey);

                if (differenceBetweenDates > NEGATIVE_NEWS_MAX_FOR_DISPLAYING_LABELS.YEARS * 12) {
                    return `${year}`;
                } else if (differenceBetweenDates > NEGATIVE_NEWS_MAX_FOR_DISPLAYING_LABELS.MONTHS) {
                    return `${month} ${year}`;
                } else {
                    return `${day} ${month} ${year}`;
                }
            });
        }
    },

    buildSeriesDataWithYProperties(buckets) {
        if (buckets && buckets.length) {
            return buckets.sort((bucketA, bucketB) => bucketA.key - bucketB.key).map((bucket) => bucket.doc_count);
        }

        return [];
    },

    buildSeriesForHistogram(values, buckets) {
        const lines = this.buildLinesForLineGraph(buckets);

        if (lines && lines.length) {
            lines.forEach((line) => {
                if (values && values.length) {
                    const counts = [];

                    values.forEach((value, index) => {
                        if (line && line.data && line.data.length) {
                            const item = line.data.find((bucketValue) => bucketValue.key === value);
                            if (item) {
                                counts[index] = {
                                    y: item.doc_count,
                                    dateRange: item.dateRange,
                                };
                            } else {
                                counts[index] = null;
                            }
                        }
                    });
                    line.data = counts;
                }
            });
        }

        return lines;
    },

    buildTooltipDateForLineGraph(dateRange, language) {
        if (dateRange) {
            const { startDate, endDate } = this.getStartAndEndDateFromRange(dateRange);
            const languageKey = utils.getLanguageKeyForDate(language);
            const {
                day: startDay,
                month: startMonth,
                year: startYear,
            } = this.getDatePropertiesFromDate(startDate, languageKey);
            const {
                day: endDay,
                month: endMonth,
                year: endYear,
            } = this.getDatePropertiesFromDate(endDate, languageKey);
            const differenceBetweenMonths = this.getMonthsDifferenceBetweenDates(startDate, endDate);

            if (differenceBetweenMonths > 6) {
                return startDate.getFullYear();
            }

            if (endYear !== startYear) {
                return `${startDay} ${startMonth} ${startYear} - ${endDay} ${endMonth} ${endYear}`;
            }

            if (startMonth !== endMonth) {
                return `${startDay} ${startMonth} - ${endDay} ${endMonth} ${endYear}`;
            } else {
                if (startDay !== endDay) {
                    return `${startDay} - ${endDay} ${endMonth} ${endYear}`;
                } else {
                    return `${startDay} ${startMonth} ${startYear}`;
                }
            }
        }
    },

    buildTooltipForLineGraph(node, language, labels) {
        if (node && node.point && node.point.options && node.point.options) {
            const { dateRange, y } = node.point.options;
            const articleLabel = y === 1 ? labels.articleLabel : labels.articlesLabel;
            const countFormattedLabel = utils.formatNumbersByUserLocale(language, y);

            return `<b>${labels.dateLabel}</b> ${this.buildTooltipDateForLineGraph(
                dateRange,
                language
            )}<br/><b>${countFormattedLabel}</b> ${articleLabel}`;
        }

        return '';
    },

    getNegativityPillsForGraph(preferencesNegativityLevels, negativeNewsBuckets) {
        let negativityLevels = {};

        NEGATIVITY_LEVELS_ORDER.forEach((level) => {
            negativityLevels[level] = NEGATIVITY_PILL_STATES.DISABLED;
        });

        if (preferencesNegativityLevels && preferencesNegativityLevels.length) {
            preferencesNegativityLevels.forEach((level) => {
                negativityLevels[level] = NEGATIVITY_PILL_STATES.DESELECTED;
            });
        }

        Object.values(NEGATIVITY_BUCKET_NAMES).forEach((bucketName) => {
            if (negativeNewsBuckets && negativeNewsBuckets[bucketName] && negativeNewsBuckets[bucketName].length) {
                negativityLevels[NEGATIVITY_BUCKETS_TO_LEVEL[bucketName]] = NEGATIVITY_PILL_STATES.SELECTED;
            }
        });

        return negativityLevels;
    },

    buildDonutChartSeries(negativityRiskDocCounts, negativityLevels) {
        if (
            !negativityRiskDocCounts ||
            isEmpty(negativityRiskDocCounts) ||
            !negativityLevels ||
            isEmpty(negativityLevels)
        ) {
            return [];
        }

        return Object.keys(negativityRiskDocCounts)
            .filter((level) => negativityLevels.indexOf(level) > -1 && negativityRiskDocCounts[level] !== 0)
            .map((level) => ({
                name: level,
                y: negativityRiskDocCounts[level],
                color: NEGATIVITY_COLORS[level.toUpperCase()],
            }));
    },
};

export default NegativeNewsVisualisationsUtils;
