import React, { useState } from 'react';
import {
    modalDescriptions,
    modalTitles,
    upgradeText,
    learnMoreText,
    modalBackgrounds,
    modalPages as pages,
} from './assets/marketingOverlayAssets';
import { DILIGENCE_PLUS_LEARN_MORE_LANGUAGE_MAP } from '@constants';
import { FormattedMessage, injectIntl } from 'react-intl';
import ModalPagination from '@reusable/ModalPagination/ModalPagination';
import formatRichMessage from '@utils/formatRichMessage';

const MarketingOverlayModal = ({ isOpen, onClose, intl, currentLanguage }) => {
    const [contentIndex, setContentIndex] = useState(0);

    const imageUrl = modalBackgrounds[contentIndex] ?? modalBackgrounds[0];
    const tradeMark = formatRichMessage({ id: 'Trademark_symbol_superscript' }, intl);
    const description = formatRichMessage({ id: modalDescriptions[contentIndex] }, intl, {
        tradeMarkSymbol: tradeMark,
    });
    const title = formatRichMessage({ id: modalTitles[contentIndex] }, intl, {
        tradeMarkSymbol: tradeMark,
    });
    const learnMoreButtonHref = DILIGENCE_PLUS_LEARN_MORE_LANGUAGE_MAP[currentLanguage];

    return (
        isOpen && (
            <div className="marketing_overlay_modal_overlay">
                <div
                    className={`marketing_overlay_modal${isOpen ? ' marketing_overlay_modal--open' : '_closed'}`}
                    style={{ backgroundImage: `url(${imageUrl})` }}
                >
                    <div className="marketing_overlay_modal__upper">
                        <div>
                            <FormattedMessage id={upgradeText} />
                        </div>
                        <h4 className="marketing_overlay_modal_title">{title}</h4>
                        <button
                            className="la-CloseRemove app-modal__close-button marketing_overlay_modal_close_button"
                            onClick={onClose}
                        />
                    </div>
                    <div className="marketing_overlay_modal__lower">
                        <p
                            className="marketing_overlay_modal_description"
                            dangerouslySetInnerHTML={{ __html: description }}
                        />
                        <ModalPagination contentIndex={contentIndex} setContentIndex={setContentIndex} pages={pages} />
                        {learnMoreButtonHref && (
                            <a href={learnMoreButtonHref} rel="noreferrer" target="_blank">
                                <button className="marketing_overlay_modal_learn_more_button">
                                    <FormattedMessage id={learnMoreText} />
                                </button>
                            </a>
                        )}
                    </div>
                </div>
            </div>
        )
    );
};

export default injectIntl(MarketingOverlayModal);
