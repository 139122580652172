// @flow
import React from 'react';
import type { Node } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import AdminLock from '@reusable/AdminLock/AdminLock';
import { FE_LOCK_NAMES } from '@constants';
import withAutosave from '../HOC/WithAutosave';
import { withAppContext } from '@utils/contexts';
import { compose } from 'redux';
import { connect } from 'react-redux';

type Props = {
    suggestedNames: boolean,
    isReadonly: boolean,
    userIsAdmin: boolean,
    handleSuggestedNamesToggle: (event: SyntheticInputEvent<HTMLInputElement>) => void,
    handleLockClick: (lockName: string) => void,
};

type InjectedProps = {|
    +intl: Object,
    +user: Object,
    handleAutosave: (handler: (params: Object) => void, params: Object) => void,
    autoSaveMessage: Node,
|};

const SuggestedNames = (props: Props & InjectedProps) => {
    const handleSuggestedNamesToggle = (event: Object) => {
        props.handleAutosave(props.handleSuggestedNamesToggle, [event]);
    };

    return (
        <div className="user-preferences-category-container user-preferences-suggested-names container-section__segment">
            {props.userIsAdmin ? (
                <AdminLock
                    lockName={FE_LOCK_NAMES.suggestedNames}
                    onClickLock={props.handleLockClick}
                    isSectionLock={true}
                    isLocked={props.isReadonly}
                />
            ) : (
                props.isReadonly && <AdminLock lockName={FE_LOCK_NAMES.suggestedNames} isDisabled={true} />
            )}
            <div className="user-preferences-category-container__row container-section__segment__header">
                <h3 className="user-preferences-suggested-names-topic">
                    <FormattedMessage id="UserPreferences_topic_sources_suggestedNames" />
                </h3>
                {props.autoSaveMessage}
            </div>

            <div className="include-suggested-names-match">
                <div className={!props.userIsAdmin && props.isReadonly ? 'custom-toggle disabled' : 'custom-toggle'}>
                    <input
                        type="checkbox"
                        checked={props.suggestedNames === true ? 'checked' : ''}
                        onChange={handleSuggestedNamesToggle}
                        className="custom-toggle-checkbox"
                        id="suggested_names_toggle"
                        data-testid={`suggested-names-toggle-${props.suggestedNames ? 'enabled' : 'disabled'}`}
                    />
                    <label className="custom-toggle-label" htmlFor="suggested_names_toggle" />
                </div>
                <FormattedMessage id={'UserPreferences_topic_sources_suggestedNames.toggle'} />
            </div>
        </div>
    );
};

const mapStateToProps = function (state) {
    return {
        user: state.user,
    };
};

export default (compose(
    connect(mapStateToProps),
    injectIntl,
    withAutosave(),
    withAppContext
)(SuggestedNames): React$StatelessFunctionalComponent<Props>);
