import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import FilterPod from '@reusable/Form/FilterPod';
import { HELP_URLS, NEGATIVE_NEWS_LANGUAGES, PERSON_CHECK, POSTFILTER_TYPE } from '@constants';
import PostFilter from './PostFilter';
import utils from '@utils/utilities';
import formatRichMessage from '@utils/formatRichMessage';
import { injectIntl } from 'react-intl';

class PostFiltersContainer extends Component {
    static propTypes = {
        postFilterConfig: PropTypes.object,
        togglePostfilterCategoryVisibility: PropTypes.func,
        filtersCollapsingState: PropTypes.object
    };

    static defaultProps = {
        postFilterConfig: {},
    };

    shouldComponentUpdate(nextProps) {
        return (
            JSON.stringify(this.props.postFilterConfig) !== JSON.stringify(nextProps.postFilterConfig) ||
            nextProps.language !== this.props.language ||
            nextProps.activePostFilters !== this.props.activePostFilters ||
            nextProps.filtersCollapsingState !== this.props.filtersCollapsingState
        );
    }

    getPostfilterLabel(key) {
        if (key === POSTFILTER_TYPE.SIMILAR_NAMES) {
            return 'PostFilters.Title.CompanyNames';
        }
        return this.props.postFilterConfig[key].label;
    }

    render() {
        let showResetButton = true;
        let showInfoIcon = false;

        return (
            <Fragment>
                {Object.keys(this.props.postFilterConfig).map((key) => {
                    const postFilterType = this.props.postFilterConfig[key].type;
                    const isDisabled =
                        this.props.activePostFilters.length !== 0 &&
                        this.props.activePostFilters.indexOf(postFilterType) === -1;
                    let infoItems = {};

                    if (
                        Array.isArray(this.props.postFilterConfig[key].values) &&
                        this.props.postFilterConfig[key].values.length === 0
                    )
                        return <Fragment key={key}></Fragment>;

                    // @TODO remove this after replacing publicationName with sourceName for all languages
                    let shouldShowPostFilter =
                        (this.props.contentLanguage === NEGATIVE_NEWS_LANGUAGES.portuguese.value &&
                            key === POSTFILTER_TYPE.SOURCE_NAME) ||
                        (this.props.contentLanguage !== NEGATIVE_NEWS_LANGUAGES.portuguese.value &&
                            key === POSTFILTER_TYPE.SOURCE) ||
                        (key !== POSTFILTER_TYPE.SOURCE_NAME && key !== POSTFILTER_TYPE.SOURCE);
                    // @TODO - to be removed when implementing sourceName for all languages

                    if (key === POSTFILTER_TYPE.FUZZY_SEARCH) {
                        showResetButton = false;
                        showInfoIcon = true;
                        // TODO: find a way to define multiple links format in formatRichMessage
                        const message = formatRichMessage(
                            { id: 'ResultsList.postFilters.fuzzySearch.help' },
                            this.props.intl,
                            {
                                a: (chunks) =>
                                    `<a href='#/user-preferences?section=${PERSON_CHECK}'>${chunks.join('')}</a>`,
                                a1: (chunks) =>
                                    `<a href='${utils.getHelpUrl(
                                        this.props.user,
                                        HELP_URLS.FUZZY_HELP_URL.key
                                    )}' target='_blank'>${chunks.join('')}</a>`,
                            }
                        );

                        infoItems.infoContent = (
                            <Fragment>
                                <div dangerouslySetInnerHTML={{ __html: message }} />
                                <span className="la-ExternalLink" />
                            </Fragment>
                        );
                        infoItems.infoTitle = formatRichMessage(
                            { id: 'PostFilters.Title.FuzzySearch' },
                            this.props.intl
                        );
                        infoItems.infoModalClass = 'fuzzy-search';
                    } else {
                        showInfoIcon = false;
                        showResetButton = true;
                    }

                    if (key === POSTFILTER_TYPE.NEGATIVITY_LEVELS) {
                        shouldShowPostFilter = false;
                    }

                    return shouldShowPostFilter ? (
                        <FilterPod
                            key={'Filter' + key}
                            showResetButton={showResetButton}
                            showInfoIcon={showInfoIcon}
                            infoItems={infoItems}
                            label={this.getPostfilterLabel(key)}
                            className={'type-filter-category-container'}
                            name={key}
                            searchFieldName={this.props.postFilterConfig[key].searchFieldName}
                            isOpen={this.props.filtersCollapsingState[key]}
                            resetFilter={this.props.resetFilter}
                            isDisabled={isDisabled}
                            togglePostfilterVisibility={this.props.togglePostfilterCategoryVisibility}
                        >
                            <PostFilter
                                key={'PostFilter' + key}
                                config={this.props.postFilterConfig[key]}
                                onChange={this.props.onChange}
                                onChangeSort={this.props.onChangeSort}
                                onFuzzyThresholdChange={this.props.onFuzzyThresholdChange}
                                defaultDate={this.props.defaultDate}
                                postFilterType={key} // check if needed
                                category={this.props.category}
                                updatePostFilterConfig={this.props.updatePostFilterConfig}
                                historyCategory={this.props.historyCategory}
                                updatePostFilterTreeData={this.props.updatePostFilterTreeData}
                                shouldUpdateCustomDatesState={this.props.shouldUpdateCustomDatesState}
                                updateCustomDatesBasedOnNNV={this.props.updateCustomDatesBasedOnNNV}
                            />
                        </FilterPod>
                    ) : null;
                })}
            </Fragment>
        );
    }
}

export default injectIntl(PostFiltersContainer);
