//@flow
import * as React from 'react';
import utils from '@utils/utilities';
import ReportEditableTitle from './components/ReportEditableTitle';
import ReportTitle from './components/ReportTitle';
import DownloadAction from './components/DownloadAction';
import AdditionalActions from './components/AdditionalActions';
import { asProp } from '@utils/hoc';
import withSelectBox from '@reusable/Table/components/hoc/withSelectBox';
import { withHideWhileLoading, withDeliveryLoadingSpinner } from '@ReportBuilder/HOC/withReportHoc';
import type { LiteChildReport, GenericReportType } from '@ReportBuilder/redux/flow/ReportBuilder.type.guards';
import ReportBuilderUtils from '@pages/ReportBuilder/utils/ReportBuilderUtils';
import type { ReportTitleTooltipData } from './components/ReportEditableTitle';
import { REPORT_EXPIRATION_DATE_RANGE } from '@constants';

type Props = {
    report: GenericReportType,
    index: number,
    selectBox?: string,
    handleToggleReport: (reportId: string) => Promise<Object>,
    updateReportProperty: ({
        reportId: string,
        reportProperty: string,
        propertyValue: mixed,
        isSelected?: boolean,
    }) => void,
    downloadReport: (event: SyntheticEvent<HTMLButtonElement>) => void,
    reportDeliveryAvailabilityDays?: number,
    prReportDeliveryAvailabilityDays?: number,
    showDownloadReportPopup?: (popupType: string) => void,
    handleDeleteReport?: (reportId: string, title: string) => void,
    openOrderCategoriesSection?: (event: SyntheticInputEvent<HTMLButtonElement>) => Promise<Object> | void,
    downloadLinkMessage: {
        downloadNow: string,
        downloadAgain: string,
    },
    countMessage: string,
};

const ReportHeader = (props: Props) => {
    const {
        report,
        index,
        selectBox,
        handleToggleReport,
        updateReportProperty,
        downloadReport,
        reportDeliveryAvailabilityDays,
        prReportDeliveryAvailabilityDays,
        showDownloadReportPopup,
        handleDeleteReport,
        openOrderCategoriesSection,
        countMessage,
    } = props;

    const currentReportSelected = report ? report.isSelected : false;
    const arrow = currentReportSelected ? 'la-TriangleDown' : 'la-TriangleRight';
    const className = `reports-list-row ${currentReportSelected ? 'expanded' : ''}`;
    const isReportBatch = ReportBuilderUtils.isReportBatch(report.deliveryType);
    const reportDeliveryExpirationDays = isReportBatch ? REPORT_EXPIRATION_DATE_RANGE : reportDeliveryAvailabilityDays;

    const buildTooltipData = (): ReportTitleTooltipData | string => {
        if (ReportBuilderUtils.isReportCombined(report.deliveryType) && report.liteChildReports) {
            const liteChildReportsTitles: Array<string> = report.liteChildReports.map(
                (childReport: LiteChildReport) => childReport.title
            );

            if (liteChildReportsTitles.length > 0) {
                return {
                    message: 'Report.combined.titleTooltip',
                    terms: {
                        combinedReportTitle: report.title,
                        childReportsTitles: liteChildReportsTitles.join(', '),
                    },
                };
            } else {
                return report.title;
            }
        }

        return report.title;
    };

    return (
        <div className={className} onClick={() => handleToggleReport(report.id)}>
            {!isReportBatch && (
                <div
                    className="report-header-select-box"
                    onClick={(e: SyntheticEvent<HTMLDivElement>) => e.stopPropagation()}
                >
                    {selectBox}
                </div>
            )}

            <div className={`report-row ${isReportBatch ? 'report-row_batch' : ''}`}>
                <div id={report ? report.id : ''} className={arrow} data-track={`expand-arrow-report-${index}`} />
                {isReportBatch && <div className="la-Folder" />}
                {isReportBatch ? (
                    <ReportTitle
                        title={report ? report.title : ''}
                        id={report ? report.id : ''}
                        index={index}
                        updateReportProperty={updateReportProperty}
                        reportType={report ? report.deliveryType : ''}
                        hasUpdates={report ? report.hasUpdates : false}
                        toolTipData={buildTooltipData()}
                        countMessage={countMessage}
                    />
                ) : (
                    <ReportEditableTitle
                        title={report ? report.title : ''}
                        id={report ? report.id : ''}
                        index={index}
                        updateReportProperty={updateReportProperty}
                        reportType={report ? report.deliveryType : ''}
                        hasUpdates={report ? report.hasUpdates : false}
                        isSelected={report ? report.isSelected : false}
                        toolTipData={buildTooltipData()}
                        countMessage={countMessage}
                    />
                )}

                <div className="reports-list-column expiryDate">
                    {utils.formatDateForExpiryDate(report.lastUpdated)}
                </div>

                <ToggledDownloadAction
                    downloadReport={downloadReport}
                    report={report}
                    index={index}
                    reportDeliveryAvailabilityDays={reportDeliveryExpirationDays}
                    prReportDeliveryAvailabilityDays={prReportDeliveryAvailabilityDays}
                    showDownloadReportPopup={showDownloadReportPopup}
                    downloadMessage={props.downloadLinkMessage}
                    showSpinner={report ? report.showSpinner : false}
                    className={'reports-list-column downloadActions'}
                />

                <AdditionalActionsWithSpinner
                    handleDeleteReport={handleDeleteReport}
                    showSpinner={report ? report.showSpinner : false}
                    id={report ? report.id : ''}
                    title={report ? report.title : ''}
                    index={index}
                    openOrderCategoriesSection={openOrderCategoriesSection}
                    reportType={report ? report.deliveryType : ''}
                />
            </div>
        </div>
    );
};

const AdditionalActionsWithSpinner = withDeliveryLoadingSpinner(AdditionalActions);
const ToggledDownloadAction = withHideWhileLoading(DownloadAction);

export default (asProp('selectBox', withSelectBox)(ReportHeader): React$StatelessFunctionalComponent <Props>);
