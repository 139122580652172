import React, { Fragment } from 'react';
import utils from '@utils/utilities';
import { MassiveLoader } from '../components/defaults';
import * as DilProps from '@utils/props';

const hasOverlayLoader =
    (LoaderComponent = MassiveLoader) =>
    (TableComponent) => {
        const hasOverlayLoaderComponent = (props) =>
            props.additionalData.isLoading ? (
                <Fragment>
                    <div className={'overlay'}>
                        <LoaderComponent {...props} />
                    </div>
                    <TableComponent {...props} />
                </Fragment>
            ) : (
                <TableComponent {...props} />
            );

        hasOverlayLoaderComponent.displayName = `hasOverlayLoader(${utils.getDisplayName(TableComponent)})`;

        hasOverlayLoaderComponent.propTypes = {
            data: DilProps.TableData.isRequired,
        };

        return hasOverlayLoaderComponent;
    };

export default hasOverlayLoader;
