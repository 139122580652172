import React, { Fragment } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import ReactTooltip from 'react-tooltip';
import utils from '@utils/utilities';
import formatRichMessage from '@utils/formatRichMessage';
import { ReactComponent as BannerWarningIcon } from '../../../assets/icons/BannerWarningIcon.svg';
import { ReactComponent as BannerWarningIconDark } from '../../../assets/icons/BannerWarningIconDark.svg';

const withUboTradeUpWarningNote = (WrappedComponent) => {
    const withUboTradeUpWarningNote = (props) => {
        return props.isTradeUp ? (
            <Fragment>
                <div className={`tradeup-container ${props.isDarkMode ? 'dark-mode' : ''}`}>
                    <div className='tradeup-container__icon'>
                    {props.isDarkMode ? <BannerWarningIconDark /> : <BannerWarningIcon />}
                        </div>
                    <div className='tradeup-container__message'>
                        <FormattedMessage id={'UboDocumentView.Warning.Tradeup.Description'} />
                        <ReactTooltip
                            id="ubo-tradeup"
                            multiline={true}
                            place="bottom"
                            type="light"
                            border={true}
                            effect="solid"
                            className="tooltips"
                        />
                        <span
                            id="whatIsTradeup"
                            data-for="ubo-tradeup"
                            data-tip={formatRichMessage(
                                { id: 'UboDocumentView.Warning.Tradeup.WhatIsTradeup.PopUpText' },
                                props.intl
                            )}
                        >
                            <FormattedMessage id={'UboDocumentView.Warning.Tradeup.WhatIsTradeup'} />
                        </span>
                    </div>
                </div>
                <WrappedComponent {...props} />
            </Fragment>
        ) : (
            <WrappedComponent {...props} />
        );
    };

    withUboTradeUpWarningNote.displayName = `withUboTradeUpWarningNoteMessage(${utils.getDisplayName(
        WrappedComponent
    )})`;

    return injectIntl(withUboTradeUpWarningNote);
};

export default withUboTradeUpWarningNote;
