import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

export default class InformationModal extends Component {
    static propTypes = {
        closeButtonHandler: PropTypes.func,
        content: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]),
        title: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    };

    static defaultProps = {
        content: [],
        title: '',
    };

    closeModal = (event) => {
        if (event.currentTarget === event.target) {
            if (typeof this.props.closeButtonHandler === 'function') {
                this.props.closeButtonHandler();
            }
        }
    };

    render() {
        const modalPortal = document.getElementById('modal-portal');
        const modalContent = (
            <div className={`app-modal app-modal-information ${this.props.infoModalClass}`} onClick={this.closeModal}>
                <div className="app-modal__wrapper" onClick={this.closeModal}>
                    <div className="app-modal__content-box">
                        <div className="app-modal__content">
                            {typeof this.props.closeButtonHandler === 'function' ? (
                                <button
                                    type="button"
                                    className="la-CloseRemove app-modal__close-button"
                                    onClick={this.props.closeButtonHandler}
                                    tabIndex="-1"
                                ></button>
                            ) : null}
                            <div className="popup-modal">
                                <div className="popup-modal-header">{this.props.title}</div>
                                <div className="popup-modal-body">{this.props.content}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );

        return ReactDOM.createPortal(modalContent, modalPortal);
    }
}
