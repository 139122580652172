//@flow
import * as React from 'react';
import type { AbstractComponent, Element } from 'react';
import ReactTooltip from 'react-tooltip';
import PopupModel from '@reusable/PopupModal/PopupBuilder';
import popupModelActions from '@reusable/PopupModal/redux/PopupModel.actions';
import reduxStore from '@reduxStore';
import {
    ACTION_MODAL_TYPE_REPORTS_CATEGORY,
    DOWNLOAD_DEFAULT_FILENAME,
    MODAL_TYPE,
    REPORT_TYPE,
    COMBINED_REPORT_DEFAULT_TITLE,
    REPORTS_MAX_DOWNLOAD_LIMIT,
} from '@constants';
import utils from '@utils/utilities';
import { injectIntl } from 'react-intl';
import { withAppContext } from '@utils/contexts';
import { withCustomNewsTranslation } from '@utils/categoryUtils';
import ReportBuilderService from '@ReportBuilder/ReportBuilderService';
import withDownloadMultipleReportsModal from '@reusable/Modal/hoc/withDownloadMultipleReportsModal';
import { compose } from 'redux';
import type {
    CategoryDeleteRequest,
    CombinedReportType,
    RegularReportType
} from '@ReportBuilder/redux/flow/ReportBuilder.type.guards';
import type { ContentOptions } from '@reusable/PopupModal/Components/ModalContentOptions';
import type { Context } from '@utils/flow/utilities.type.guards';
import formatRichMessage from '@utils/formatRichMessage';
import { PublicRecordsUtils } from '@utils/publicRecordsUtils';

type Props = {
    actionsDisabled?: boolean,
    contentsOptions: ContentOptions,
    deselectAllReports?: (selectedItems: Array<RegularReportType | CombinedReportType>) => void,
    emailAddress: string,
    interfaceLanguage?: string,
    numberOfSelectedItems?: number,
    onDelete?: ({ action?: string, title?: string }) => void,
    onCategoryDelete: (categoryDeleteRequest: CategoryDeleteRequest) => void,
    onMultipleReportsDelivery: (modalProps: ModalProps, onConfirm: (options: Array<Option>) => void) => void,
    pageSize?: number,
    publicRecordsOn?: boolean,
    selectedItems?: Array<RegularReportType | CombinedReportType>,
    showDeliveryOptions: boolean,
    startEachArticleOnNewPage?: boolean,
    timezone?: string,
    type?: string,
    searchQuery?: string,
    categoryLabel?: string,
    reportId?: string,
    categoryName?: string,
    snippetsCount?: number,
    index?: number,
};

type InjectedProps = {
    context: Context,
    intl: {
        //#2 should i make a typw for the whole intl object or just for what i'm using currently
        formatMessage: (object1: { id: string }, object2?: { section: string }) => string, //#3 how to make the signature of the function while this function is declared by a 3rd party
    },
};

type Action = {
    action: string,
    title: string,
};

type Option = {
    key: string,
    label: string,
    type?: string,
    value: boolean,
    popupType: string,
};

type ModalProps = {
    title: string,
    description: string,
    primaryBtnText: string,
    options: Array<Option>,
};

class ReportActions extends React.Component<Props & InjectedProps> {
    constructor(props: Props & InjectedProps) {
        super(props);
    }

    showDeliveryPopups: (Action) => void = ({ action, title }) => {
        let selectedReportsCount: number = this.props.selectedItems ? this.props.selectedItems.length : 0;
        selectedReportsCount > 1
            ? this.deliverMultipleReports({ title, action })
            : this.showReportPopups({ popupType: action, reportType: REPORT_TYPE.REPORTS });
    };

    deliverMultipleReports: (Action) => void = ({ title, action }) => {
        const modalProps = {
            title: title,
            description: formatRichMessage({ id: 'Modal.delivery.multiple.description' }, this.props.intl),
            primaryBtnText: 'General_CoreFunctionality_UIText_general.next',
            options: [
                {
                    key: REPORT_TYPE.REPORTS,
                    label: formatRichMessage({ id: 'Modal.delivery.multiple.separate' }, this.props.intl),
                    type: 'radio',
                    value: true,
                    popupType: action + 'Separate',
                },
                {
                    key: REPORT_TYPE.COMBINED,
                    label: formatRichMessage({ id: 'Modal.delivery.multiple.combined' }, this.props.intl),
                    type: 'radio',
                    value: false,
                    popupType: action + 'Combined',
                },
            ],
        };

        // @TODO to be removed when combined reports feature will be enabled in prod
        const separateReportsOption = modalProps.options[0];
        this.props.context.areCombinedReportsAvailable
            ? this.props.onMultipleReportsDelivery(modalProps, this.onConfirm)
            : this.showReportPopups({
                  popupType: separateReportsOption.popupType,
                  reportType: separateReportsOption.key,
              });
    };

    onConfirm: (options: Array<Option>) => void = (options) => {
        const deliveryOption =
            options &&
            options
                .filter((option) => option.value)
                .map((option) => ({ popupType: option.popupType, reportType: option.key }));

        if (deliveryOption) {
            this.showReportPopups(...deliveryOption);
        }
    };

    showReportPopups: ({ popupType: string, reportType: string }) => void = ({ popupType, reportType }) => {
        const currentDate = new Date();
        let fileName: string;

        if (reportType === REPORT_TYPE.COMBINED) {
            fileName =
                COMBINED_REPORT_DEFAULT_TITLE + ' - ' + utils.formatDateWithTimezoneOffset(currentDate.getTime());
        } else {
            fileName =
                this.props.type === ACTION_MODAL_TYPE_REPORTS_CATEGORY
                    ? `${DOWNLOAD_DEFAULT_FILENAME}${utils.formatReportFileName(
                          `${this.props.searchQuery ?? ''}_`
                      )}${utils.formatFileName(this.props.categoryLabel)}_${utils.formatReportDateWithtTimezoneOffset(
                          currentDate.getTime()
                      )}`
                    : `${DOWNLOAD_DEFAULT_FILENAME}${utils.formatReportDateWithtTimezoneOffset(currentDate.getTime())}`;
        }
        let selectedReportsCount = this.props.selectedItems ? this.props.selectedItems.length : 0;
        let hasOnlyPublicRecords = this.containsOnlyPublicRecords(this.props.categoryName, this.props.selectedItems);

        const deliveryData = {
            timezone: this.props.timezone,
            interfaceLanguage: this.props.interfaceLanguage,
            startEachArticleOnNewPage: this.props.startEachArticleOnNewPage,
            publicRecordsOn: this.props.publicRecordsOn,
            pageSize: this.props.pageSize,
        };

        let popupModel = new PopupModel.Builder(popupType)
            .setPopupProperty('isVisible', true)
            .setPopupProperty('fileName', fileName)
            .setPopupProperty('emailAddress', this.props.emailAddress)
            .setPopupProperty('reportId', this.props.reportId ? this.props.reportId : '')
            .setPopupProperty('reportCategory', this.props.categoryName ? this.props.categoryName : '')
            .setPopupProperty('count', this.props.snippetsCount ? this.props.snippetsCount : '')
            .setPopupProperty('selectedItemsCount', selectedReportsCount)
            .setPopupProperty('selectedReports', this.props.selectedItems)
            .setPopupProperty('contentsOptions', this.props.contentsOptions)
            .setPopupProperty('showDeliveryOptions', this.props.showDeliveryOptions)
            .setPopupProperty('deselectAllReports', this.props.deselectAllReports)
            .setPopupProperty('reportType', reportType)
            .setPopupProperty('deliveryData', deliveryData)
            .setPopupProperty('isSnapshotDisabled', hasOnlyPublicRecords);

        popupModel.build();
        reduxStore.dispatch(popupModelActions.setPopupModel(popupModel));

        ReportBuilderService.changeUpdatesFlagForMultipleReports(this.props.selectedItems, false);
    };

    render(): React.Node {
        let printAction =
            this.props.type === ACTION_MODAL_TYPE_REPORTS_CATEGORY
                ? MODAL_TYPE.PRINT_REPORT_CATEGORY
                : MODAL_TYPE.PRINT_REPORTS;
        let downloadAction =
            this.props.type === ACTION_MODAL_TYPE_REPORTS_CATEGORY
                ? MODAL_TYPE.DOWNLOAD_REPORT_CATEGORY
                : MODAL_TYPE.DOWNLOAD_REPORTS;
        let emailAction =
            this.props.type === ACTION_MODAL_TYPE_REPORTS_CATEGORY
                ? MODAL_TYPE.EMAIL_REPORT_CATEGORY
                : MODAL_TYPE.EMAIL_REPORTS;
        let hasDeleteAction =
            this.props.type !== ACTION_MODAL_TYPE_REPORTS_CATEGORY &&
            !!this.props.onDelete &&
            typeof this.props.onDelete === 'function';
        let hasCategoryDeleteAction =
            this.props.type === ACTION_MODAL_TYPE_REPORTS_CATEGORY &&
            !!this.props.onCategoryDelete &&
            typeof this.props.onCategoryDelete === 'function';
        let categoryName =
            this.props.type === ACTION_MODAL_TYPE_REPORTS_CATEGORY && this.props.categoryName
                ? '_' + this.props.categoryName
                : '';
        let index = this.props.index ? '_' + this.props.index : '';

        let translatedCategoryName = this.props.categoryName
            ? formatRichMessage(
                  {
                      id: 'General.categoryName.label.' + this.props.categoryName,
                      defaultMessage: withCustomNewsTranslation(this.props.categoryName),
                  },
                  this.props.intl
              )
            : '';
        const isUserAnonimysed = this.props.context && this.props.context.isUserAnonymized;

        const isReportDownloadLimitReached = this.props.numberOfSelectedItems > REPORTS_MAX_DOWNLOAD_LIMIT;

        return (
            <div className="report-actions" data-track="report-delivery-actions">
                <WrappedActionButton
                    translatedCategoryName={translatedCategoryName}
                    className={'la-Print'}
                    handleClick={this.showDeliveryPopups}
                    action={printAction}
                    disabled={this.props.actionsDisabled}
                    id={'qa-report-action_' + printAction + categoryName + index}
                    title={formatRichMessage({ id: 'Modal.delivery.multiple.print' }, this.props.intl)}
                    numberOfSelectedItems={this.props.numberOfSelectedItems}
                    tooltipMessage={formatRichMessage({ id: 'ReportActions.printReportSection' }, this.props.intl, {
                        section: translatedCategoryName,
                    })}
                />

                <WrappedActionButton
                    translatedCategoryName={translatedCategoryName}
                    className={'la-Email'}
                    handleClick={this.showDeliveryPopups}
                    action={emailAction}
                    disabled={this.props.actionsDisabled || isUserAnonimysed}
                    isAnonimysed={isUserAnonimysed}
                    id={'qa-report-action_' + emailAction + categoryName + index}
                    title={formatRichMessage({ id: 'Modal.delivery.multiple.email' }, this.props.intl)}
                    numberOfSelectedItems={this.props.numberOfSelectedItems}
                    tooltipMessage={formatRichMessage({ id: 'ReportActions.emailReportSection' }, this.props.intl, {
                        section: translatedCategoryName,
                    })}
                />

                <WrappedActionButton
                    translatedCategoryName={translatedCategoryName}
                    className={'la-Download'}
                    handleClick={this.showDeliveryPopups}
                    action={downloadAction}
                    disabled={this.props.actionsDisabled || isReportDownloadLimitReached}
                    id={'qa-report-action_' + downloadAction + categoryName + index}
                    title={formatRichMessage({ id: 'Modal.delivery.multiple.download' }, this.props.intl)}
                    numberOfSelectedItems={this.props.numberOfSelectedItems}
                    tooltipMessage={formatRichMessage({ id: 'ReportActions.downloadReportSection' }, this.props.intl, {
                        section: translatedCategoryName,
                    })}
                    downloadAction={downloadAction}
                />

                {hasDeleteAction && (
                    <ActionButton
                        tooltipMessage={formatRichMessage({ id: 'Report.deleteReport' }, this.props.intl, {
                            section: translatedCategoryName,
                        })}
                        disabled={this.props.numberOfSelectedItems === 0}
                        // $FlowFixMe
                        handleClick={this.props.onDelete}
                        id={'qa-report-action_' + downloadAction + categoryName + index}
                        className={'la-Delete'}
                    />
                )}

                {hasCategoryDeleteAction && (
                    <ActionButton
                        tooltipMessage={formatRichMessage({ id: 'Report.deleteReport' }, this.props.intl, {
                            section: translatedCategoryName,
                        })}
                        disabled={this.props.numberOfSelectedItems === 0}
                        // $FlowFixMe
                        handleClick={this.props.onCategoryDelete}
                        params={this.getCategoryDeletePayload()}
                        id={'qa-report-action' + categoryName + index}
                        action={`qa-report-action_${MODAL_TYPE.DELETE_REPORT_CATEGORY}${categoryName}`}
                        className={'la-Delete'}
                    />
                )}
            </div>
        );
    }

    getCategoryDeletePayload() {
        const snippetIds = this.props.categorySnippets.map(({id}) =>id);

        return { category: this.props.categoryName, reportId: this.props.reportId, snippetIds};
    }

    containsOnlyPublicRecords(categoryName, selectedItems) {
        if (PublicRecordsUtils.isCategoryPublicRecords(categoryName)) {
            return true;
        }
        return selectedItems?.every((report) => PublicRecordsUtils.doesReportContainOnlyPublicRecords(report));
    }
}

type ActionButtonProps = {
    action?: string,
    className: string,
    disabled: boolean,
    handleClick: ({ action?: string, title?: string, params?: any }) => void,
    id: string,
    title?: string,
    tooltipMessage: string,
};

const ActionButton = ({ action, disabled, handleClick, id, tooltipMessage, className, title, params }: ActionButtonProps) => {
    return (
        <React.Fragment>
            <ReactTooltip
                event="mouseenter"
                eventOff="mouseleave"
                type="light"
                border={true}
                effect="solid"
                place="bottom"
                className="tooltips"
                id={`${id}-tooltip`}
            />
            <button
                data-tip={tooltipMessage}
                data-for={id}
                className="icon-box"
                data-testid={action}
                id={id}
                disabled={disabled}
                onClick={() => handleClick({ action, title, params })}
            >
                <div className={className} />
            </button>
        </React.Fragment>
    );
};

type RequiredProps = {
    disabled: boolean,
    numberOfSelectedItems: number,
    isAnonimysed?: boolean,
    id: string,
    intl: {
        formatMessage: (object1: { id: string }) => string,
    },
};

const withWarningTooltip = <HOCProps: {} & RequiredProps>(
    Component: AbstractComponent<HOCProps>
): AbstractComponent<HOCProps> | Element<'div'> => {
    const tooltipWrapper = (props: HOCProps & RequiredProps) => {
        const tooltipMessage = props.action === props.downloadAction
            ? formatRichMessage({id: 'ReportBuilderPage_Tooltips.reportMaxDownloadLimit'}, props.intl)
            : formatRichMessage({id: 'ReportActions.tooManyReports'}, props.intl);
        return props.disabled && props.numberOfSelectedItems > 0 && !props.isAnonimysed ? (
            <div
                className="report-actions-wrapper"
                data-for={`wrapper_${props.id}`}
                data-tip={tooltipMessage}
            >
                <Component {...props} />
                <ReactTooltip
                    type="light"
                    border={true}
                    effect="solid"
                    place="bottom"
                    className="tooltips"
                    id={`wrapper_${props.id}`}
                    data-place="bottom"
                />
            </div>
        ) : (
            <Component {...props} />
        );
    };

    tooltipWrapper.displayName = `tooltipWrapper(${utils.getDisplayName(Component)})`;

    return tooltipWrapper;
};
export { withWarningTooltip as testWithWarningTooltip };

const WrappedActionButton = injectIntl(withWarningTooltip(ActionButton));

export { ReportActions as TestReportActions };
export default (compose(
    injectIntl,
    withAppContext,
    withDownloadMultipleReportsModal
)(ReportActions): AbstractComponent<Props>);
