import React, { Component } from 'react';
import RadioFilterComponent from '../RadioFilterComponent';
import formatRichMessage from '@utils/formatRichMessage';
import { injectIntl } from 'react-intl';

class ProximityPostFilter extends Component {
    handleChange = (options, option, e) => {
        // for now just pass the handler up, any transformations should happen here
        this.props.onChange(options, option, e);
    };

    render() {
        const options = this.props.options.map((option) => ({
            ...option,
            label: formatRichMessage({ id: 'General.Proximity.' + option.count }, this.props.intl),
        }));
        return (
            <div className={'proximity-postfilter'}>
                <RadioFilterComponent
                    {...this.props}
                    options={options}
                    onChange={this.handleChange}
                    fieldId={'proximity-postfilter'}
                />
            </div>
        );
    }
}

export default injectIntl(ProximityPostFilter);
