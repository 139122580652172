export const UPDATE_BREADCRUMBS = 'UPDATE_BREADCRUMBS';
export const UPDATE_PREV_PATH = 'UPDATE_PREV_PATH';

let breadcrumbsActions = {
    updateBreadcrumbs: (breadcrumbsItems) => (dispatch, getState) => {
        if (JSON.stringify(breadcrumbsItems) !== JSON.stringify(getState().breadcrumbs.items)) {
            dispatch({
                type: UPDATE_BREADCRUMBS,
                payload: breadcrumbsItems,
            });
        }
    },
    updatePrevPath: function (prevPath) {
        return {
            type: UPDATE_PREV_PATH,
            payload: prevPath,
        };
    },
};

export default breadcrumbsActions;
