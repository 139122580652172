import React from 'react';
import * as DilProps from '@utils/props';
import utils from '@utils/utilities';
import userPreferenceApi from '@pages/UserPreferances/UserPreferencesApi.api';
import errorUtils from '@utils/errors/error-utils';
import { bindActionCreators } from 'redux';
import actions from '@pages/UserPreferances/redux/UserPreferences.actions';
import { connect } from 'react-redux';

/**
 * Handles alerts sorting with save preference, adds sorting arrows to table headers and provides sorting handlers
 * Use with DefaultSortableHeader component to show the sorting arrows
 * @param TableComponent
 * @returns {function(*)}
 */

const withAlertsSortPersist = (TableComponent) => {
    const withAlertsSortPersist = (props) => {
        const handleSortingWithPreferenceSave = (sortingInfo) => {
            userPreferenceApi
                .updateUserPreference({
                    // save user alerts sorting to UM
                    userPreferences: {
                        generalSettings: {
                            alertsOptions: {
                                sorting: sortingInfo,
                            },
                        },
                    },
                })
                .catch((err) => {
                    errorUtils.logAppError('Failed to save alerts sort to user preferences', { err }, 'AlertsManager');
                });

            props.sorting.onChangeSorting(sortingInfo);
            props.updateUserAlertsSorting(sortingInfo); // update user alert options from redux
        };

        const sortingProp = { ...props.sorting, onChangeSorting: handleSortingWithPreferenceSave };

        return <TableComponent {...props} sorting={sortingProp} />;
    };

    withAlertsSortPersist.displayName = `withAlertsSortPersist(${utils.getDisplayName(TableComponent)})`;

    withAlertsSortPersist.propTypes = {
        sorting: DilProps.TableSorting,
    };

    const mapDispatchToProps = (dispatch) => {
        return bindActionCreators(
            {
                updateUserAlertsSorting: actions.updateUserAlertsSorting,
            },
            dispatch
        );
    };

    return connect(null, mapDispatchToProps)(withAlertsSortPersist);
};

export default withAlertsSortPersist;
