import React from 'react';
import utils from '@scripts/utils/utilities';
import { FormattedMessage } from 'react-intl';

const SimpleTable = ({ id, columns, rows, emptyTableMessage = 'BatchScreening.page.table.search.noMatch' }) => {

    return (
        <table id={id} className='simple-table'>
            <thead>
                <tr>
                    {columns.map((column, index) => (
                        <th key={`column-header-${index}`}>{column}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {
                    rows.length ? rows.map((row) => (
                        <tr key={`row-${utils.generateUUID()}`}>
                            {
                                Array.isArray(row) ?
                                    row.map((cell) => (
                                        <td key={`row-cell-${utils.generateUUID()}`}>{cell}</td>
                                    ))
                                    :
                                    Object.getOwnPropertyNames(row).map((key) => (
                                        <td key={`row-cell-${utils.generateUUID()}`}>{row[key]}</td>
                                    ))
                            }
                        </tr>
                    )) :
                        <tr>
                            <td colSpan={columns.length}>
                                <div className='no-data-found'>
                                    <FormattedMessage id={emptyTableMessage} />
                                </div>
                            </td>
                        </tr>
                }
            </tbody>
        </table>
    )
}

export default React.memo(SimpleTable);