import { FormattedMessage } from 'react-intl';
import React, { useState } from 'react';
import { withAnonymizedHideView } from '@utils/contexts';
import * as AdminPermissionsBannerUtils from './utils/AdminPermissionBannerUtils';
import { ReactComponent as DismissIcon } from '../../../assets/icons/BannerDismissIcon.svg';
import { ReactComponent as DismissIconDark } from '../../../assets/icons/DismissIconDark.svg';
import { ReactComponent as BannerInfoIcon } from '../../../assets/icons/BannerInfoIcon.svg';
import { ReactComponent as BannerInfoIconDark } from '../../../assets/icons/BannerInfoIconDark.svg';
import { useSelector } from 'react-redux';

const AdminPermissionBanner = (props) => {
    const isDarkMode = useSelector((state) => state.user.preferences.generalSettings.isDarkMode);
    const [adminPermissionChanged, setAdminPermissionChanged] = useState(props.showAdminPermissionChangedBanner);

    const hideAdminPermissionChangedBanner = () => {
        AdminPermissionsBannerUtils.hideAdminPermissionBanner();
        setAdminPermissionChanged(false);
    };

    return (
        <div
            className={
                (adminPermissionChanged
                    ? 'diligence-header__admin-permission slide'
                    : 'diligence-header__admin-permission')
                    + (isDarkMode ? ' dark-mode' : '')
            }
        >
            <div className='diligence-header__admin-permission__icon'>
            {isDarkMode ? <BannerInfoIconDark /> : <BannerInfoIcon />}
            </div>
            <div className="diligence-header__admin-permission__message">
                <FormattedMessage id={'StartPage.AdminPermissionChangedBanner.message'} values={props.values} />
            </div>
            <button type="button" className="diligence-header__admin-permission__button" onClick={hideAdminPermissionChangedBanner}>
            {isDarkMode ? <DismissIconDark /> : <DismissIcon />}
            </button>
        </div>
    );
};

export default withAnonymizedHideView(AdminPermissionBanner);
