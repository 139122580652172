import { take, all } from 'redux-saga/effects';
import * as listeners from './listeners/uboListeners';
import * as flows from './flows/uboFlows';
import { TOGGLE_UBO } from '@pages/Main/Main.reducers';

/**
 * Main saga for ubo
 * Start listening FOR:
 *  - first init - will launch looped flow
 *  - every init - maintenance operations like keeping the query search in sync
 *  - search triggers
 *  - reset triggers
 */

export default function* uboSaga() {
    const uboToggleAction = yield take(TOGGLE_UBO); // Wait until TOGGLE_UBO action is triggered
    if (uboToggleAction.payload) {
        // if the toggle is true then UBO is enabled and we launch the listeners
        yield all([
            listeners.onEveryInit(flows.initUbo),
            listeners.onSearch(flows.startSuggestionSearch),
            listeners.onSearchCount(flows.count),
            listeners.onReset(flows.resetUbo),
            listeners.onSubmit(flows.submit),
        ]);
    }
}
