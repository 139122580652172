import React from 'react';
import { FormattedMessage } from 'react-intl';
import { CATEGORY_NAMES, UBO_MAIN_CATEGORY, MENTION_TYPES } from '@constants';
import ArticlesUtils from '@pages/MainSearch/ArticlesUtils';
import utils from '@utils/utilities';

const SingleArticleSidebar = (props) => {
    const showArticleLength =
        props.articleType !== CATEGORY_NAMES.STATE_DOCKETS && props.articleType !== CATEGORY_NAMES.FEDERAL_DOCKETS;
    const hideAboutSection = props.articleType === UBO_MAIN_CATEGORY || ArticlesUtils.isBrazilianArticle(props.article);
    let mentions = [];

    if (props.articleType === UBO_MAIN_CATEGORY) {
        mentions = props.article.tableData;
    } else if (props.article.nerMentions &&  props.article.nerMentions.length) {
        mentions = props.article.nerMentions;
    } else if (props.article.lexalyticsMentions && props.article.lexalyticsMentions.length) {
        mentions = props.article.lexalyticsMentions;
    }

    const mappedMentions = mapArticleMentions(mentions);
    const { peopleMentions, companyMentions, organisationMentions } = mappedMentions;

    // eliminate duplicate entries from mentions
    const peopleMentionsDedup = peopleMentions.filter((item, index) => peopleMentions.indexOf(item) === index);
    const companyMentionsDedup = companyMentions.filter((item, index) => companyMentions.indexOf(item) === index);
    const organisationMentionsDedup = organisationMentions.filter(
        (item, index) => organisationMentions.indexOf(item) === index
    );

    return (
        <div className="sidebar-section-header notranslate">
            <div
                hidden={peopleMentionsDedup.length === 0}
                className="sidebar-section-header-element"
                data-testid="people-mentioned-section"
            >
                <div className="sidebar-section-header-element-title">
                    <p className="entity-title">
                        <FormattedMessage id={'SingleArticleSideBar.peopleMentioned'} />
                    </p>
                </div>
                <div className="sidebar-section-header-element-content">
                    <div className="sidebar-element-name_mentioned">
                        {peopleMentionsDedup.map((item, index) => {
                            return <p key={'person_' + index}>{item}</p>;
                        })}
                    </div>
                </div>
            </div>
            <div
                hidden={companyMentionsDedup.length === 0}
                className="sidebar-section-header-element"
                data-testid="company-mentioned-section"
            >
                <div className="sidebar-section-header-element-title">
                    <p className="entity-title">
                        <FormattedMessage id={'SingleArticleSideBar.companyMentioned'} />
                    </p>
                </div>
                <div className="sidebar-section-header-element-content">
                    <div className="sidebar-element-name_mentioned">
                        {companyMentionsDedup.map((item, index) => {
                            return <p key={'company_' + index}>{item}</p>;
                        })}
                    </div>
                </div>
            </div>
            <div
                hidden={organisationMentionsDedup.length === 0}
                className="sidebar-section-header-element"
                data-testid="organization-mentioned-section"
            >
                <div className="sidebar-section-header-element-title">
                    <p className="entity-title">
                        <FormattedMessage id={'SingleArticleSideBar.organisationMentioned'} />
                    </p>
                </div>
                <div className="sidebar-section-header-element-content">
                    <div className="sidebar-element-name_mentioned">
                        {organisationMentionsDedup.map((item, index) => {
                            return <p key={'organisation_' + index}>{item}</p>;
                        })}
                    </div>
                </div>
            </div>
            <div
                hidden={hideAboutSection}
                className="sidebar-section-header-element"
                data-testid="about-this-article-test-id"
            >
                <p className="sidebar-section-header-element-title">
                    <FormattedMessage id="SingleArticleSideBar.aboutThisArticle" />
                </p>
                <div className="sidebar-section-header-element-content">
                    {showArticleLength && (
                        <div data-testid={'article-length-testid'}>
                            <FormattedMessage id="SingleArticleSideBar.length" />:{' '}
                            <FormattedMessage
                                id="SingleArticleSideBar.wordCount"
                                values={{
                                    wordCount: props.article.wordCount
                                        ? utils.formatNumbersByUserLocale(props.language, props.article.wordCount)
                                        : props.article.wordCount,
                                }}
                            />
                        </div>
                    )}
                    <div>
                        <span className="sidebar-element-name">
                            <FormattedMessage id="SingleArticleSideBar.language" />:{' '}
                        </span>
                        <span className="sidebar-element-value">{props.article.language}</span>
                    </div>
                    <div>
                        <span className="sidebar-element-name">
                            <FormattedMessage id="SingleArticleSideBar.publicationType" />:{' '}
                        </span>
                        <span className="sidebar-element-value">
                            {props.article.publicationType && props.article.publicationType.length > 0 ? (
                                props.article.publicationType.map((item, index) => (index ? ', ' : '') + item)
                            ) : (
                                <FormattedMessage id="General_CoreFunctionality_UIText_general.notAvailable" />
                            )}
                        </span>
                    </div>
                    <div>
                        <span className="sidebar-element-name">
                            <FormattedMessage id="SingleArticleSideBar.publishedDate" />:{' '}
                        </span>
                        <span className="sidebar-element-value">{props.article.date}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapArticleMentions = (mentions) => {
    let peopleMentions = [];
    let companyMentions = [];
    let organisationMentions = [];

    if (!mentions || mentions.length === 0) {
        return { peopleMentions, companyMentions, organisationMentions };
    }

    peopleMentions = mentions
        .filter(
            (mention) =>
                mention.type === MENTION_TYPES.PERSON || mention.beneficiaryType === MENTION_TYPES.UBO_INDIVIDUAL
        )
        .map((mention) => mention.value || mention.beneficiaryName);
    companyMentions = mentions
        .filter(
            (mention) =>
                mention.type === MENTION_TYPES.COMPANY || mention.beneficiaryType === MENTION_TYPES.UBO_BUSINESS
        )
        .map((mention) => mention.value || mention.beneficiaryName);
    organisationMentions = mentions
        .filter((mention) => mention.type === MENTION_TYPES.ORGANIZATION)
        .map((mention) => mention.value);

    return { peopleMentions, companyMentions, organisationMentions };
};

export default SingleArticleSidebar;
