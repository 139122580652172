import React from 'react';
import {
    CALENDAR_DATE_FORMAT_FE,
    COMPANY_FILTER_MAX_CHARS,
    FILTER_BAR_DATE_RANGE_CUSTOM_LABEL,
    FILTERS_MAX_CHARS,
    POSTFILTER_COMPONENTS,
} from '@constants';
import { FormattedMessage, injectIntl } from 'react-intl';
import ReactTooltip from 'react-tooltip';
import utils from '@utils/utilities';
import costCodeUtils from '@utils/costCodeUtils';
import { formatDunsNumber } from '@sagas/helpers/uboHelper';
import formatRichMessage from '@utils/formatRichMessage';

const ActiveFilterItemsGroup = (props) => {
    let filterValuesName = [];
    let filterValues;
    let dateRange;
    let dateRangeFilter = false;
    let uniqueId = props.uniqueId; // uniqueId will be sent through props just for snapshot tests

    let maxChars = FILTERS_MAX_CHARS;

    if (props.values) {
        props.values.forEach((value) => {
            filterValuesName.push(value.name);
        });
        filterValues = filterValuesName.join(', ');
    }

    if (
        props.type === POSTFILTER_COMPONENTS.DATE_RANGE.type &&
        filterValues === FILTER_BAR_DATE_RANGE_CUSTOM_LABEL &&
        props.values[0].value
    ) {
        let dates = props.values[0].value.split(';');
        let dateFromFormatted = new Date(dates[0].split('/').reverse().join('/'));
        let dateToFormatted = new Date(dates[1].split('/').reverse().join('/'));
        dateRange =
            dates[0] &&
            formatRichMessage({ id: 'General.range.from' }, props.intl) +
                ' ' +
                utils.getUserLocaleDateWithoutTimezone(dateFromFormatted).format(CALENDAR_DATE_FORMAT_FE) +
                ' ';
        dateRange +=
            dates[1] &&
            formatRichMessage({ id: 'General.range.to' }, props.intl) +
                ' ' +
                utils.getUserLocaleDateWithoutTimezone(dateToFormatted).format(CALENDAR_DATE_FORMAT_FE);
        dateRangeFilter = true;
        filterValues = formatRichMessage({ id: 'General.DateRange.custom' }, props.intl);
    } else if (
        props.type === POSTFILTER_COMPONENTS.DATE_RANGE.type &&
        filterValues !== FILTER_BAR_DATE_RANGE_CUSTOM_LABEL
    ) {
        filterValues = formatRichMessage({ id: 'General.DateRange.' + props.values[0].value }, props.intl);
        dateRangeFilter = true;

        dateRange = formatRichMessage({ id: 'General.DateRange.' + props.values[0].value }, props.intl);
    } else if (props.type === POSTFILTER_COMPONENTS.PROXIMITY_NEGATIVE_TERMS.type && props.values[0].count) {
        filterValues = formatRichMessage({ id: 'General.Proximity.' + props.values[0].count }, props.intl);
    } else if (props.type === POSTFILTER_COMPONENTS.DUNS_FILTER.type) {
        filterValues = [formatDunsNumber(props.values[0])];
    } else if (props.type === POSTFILTER_COMPONENTS.NEGATIVITY_LEVELS.type) {
        if (props.values && props.values.length) {
            const translatedLevels = props.values.map((value) =>
                formatRichMessage(
                    { id: 'NegativeNewsVisualisations.label.' + value.name.toString().toLowerCase() },
                    props.intl
                )
            );
            filterValues = translatedLevels.join(', ');
        }
    }

    if (props.type === POSTFILTER_COMPONENTS.COMPANY.type) {
        maxChars = COMPANY_FILTER_MAX_CHARS;
    }

    return (
        <div
            className="active-filter-items-group"
            data-tip={dateRangeFilter ? dateRange : filterValues}
            data-for={uniqueId}
        >
            <span className="active-filter-items-group__name">
                <FormattedMessage id={props.name} />:
            </span>
            <ReactTooltip id={uniqueId} type="light" border={true} className="tooltips" place="bottom" />
            <span className="active-filter-items-group__trimmed-values">
                {filterValues && filterValues.length > FILTERS_MAX_CHARS
                    ? filterValues.substring(0, maxChars) + '...'
                    : filterValues}
            </span>
            {props.resetFilter && props.showResetButton && (
                <button className="la-CloseRemove" onClick={props.resetFilter} value={props.type}></button>
            )}
        </div>
    );
};

ActiveFilterItemsGroup.defaultProps = {
    uniqueId: costCodeUtils.generateBillingId(),
};

export default injectIntl(ActiveFilterItemsGroup);
