import React from 'react';
import { injectIntl } from 'react-intl';
import SearchBar from './SearchBar';
import { extractTermAtPosition, isCursorInRange } from '@sagas/helpers/uboHelper';
import { CompanyMatchesContainer } from '../UBO/CompanyMatchesContainer';
import uboActions from '@pages/StartPage/redux/Ubo.actions';
import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { UBO_MIN_QUERY_LENGTH } from '@constants';

const UboSearchBar = (props) => {
    // change handler that is not debounced, clears the suggestion list for any immediate change to the query
    const handleChangeQuery = () => {
        props.onClearSuggestions();
    };

    // debounced handler, overrides changeQuery to also start the suggestion search
    const handleChangeQueryDebounced = (searchQuery, extra) => {
        // pass the handler upwards unchanged
        props.changeQuery(searchQuery);

        // get the full query untrimmed and the cursor position
        const { cursorPosition, query } = extra;
        const searchTerm = extractTermAtPosition(query, cursorPosition);
        // start the suggestion search if cursor is in range and minimum length
        if (isCursorInRange(query, cursorPosition) && searchTerm.length >= UBO_MIN_QUERY_LENGTH) {
            props.onSuggestionSearch({ query, cursorPosition, searchTerm });
        }
    };

    const handleSelectCompany = (company) => {
        props.onSelectCompany(company);
    };

    return (
        <div className={'ubo-search-bar'}>
            <SearchBar
                {...props}
                isLoading={props.isLoading}
                onChangeQuery={handleChangeQuery}
                changeQuery={handleChangeQueryDebounced}
                uboSelected={props.selected}
            />

            {props.matches && props.matches.length > 0 && (
                <CompanyMatchesContainer
                    matches={props.matches}
                    matchCount={props.matches.length} // use it's actual length  since it may be filtered
                    className={'start-page-ubo'}
                    searchTerm={props.searchTerm}
                    onClearSuggestions={props.onClearSuggestions}
                    onSelectCompany={handleSelectCompany}
                />
            )}
        </div>
    );
};

const matchStateToProps = (state) => ({
    matches: state.ubo.company.list,
    selected: state.ubo.company.selected,
    matchCount: state.ubo.company.count,
    isLoading: state.ubo.company.isLoading,
    searchTerm: state.ubo.company.searchTerm,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            onSuggestionSearch: uboActions.initSearch,
            onSelectCompany: uboActions.addItem,
            onClearSuggestions: uboActions.clearSuggestions,
        },
        dispatch
    );

export default compose(connect(matchStateToProps, mapDispatchToProps), injectIntl)(UboSearchBar);
