import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import AddPredefinedCostCodes from './AddPredefiendCostCodes';
import { cloneDeep } from 'lodash';
import utils from '@utils/utilities';

export default class AdminCostCodeContainer extends Component {
    constructor(props) {
        super(props);
        this.handleCostCodeForAll = this.handleCostCodeForAll.bind(this);
        this.handleCostCodeFromList = this.handleCostCodeFromList.bind(this);
        this.isAdminCostCodesListDisabled = this.isAdminCostCodesListDisabled.bind(this);
    }

    handleCostCodeForAll(event) {
        this.props.updateAdminCostCodeIsRequired(event.target.checked);
    }

    handleCostCodeFromList(event) {
        this.props.updateAdminCostCodeOnlyFromCostCodeList(event.target.checked);
    }

    isAdminCostCodesListDisabled() {
        let tempCostCodesList = cloneDeep(this.props.adminCostCodesList);
        tempCostCodesList = utils.removeEmptyValues(tempCostCodesList);
        if (tempCostCodesList && tempCostCodesList.length > 0) {
            return false;
        } else {
            if (
                tempCostCodesList &&
                tempCostCodesList.length === 0 &&
                this.props.adminChooseOnlyFromCostCodesList !== false
            ) {
                this.props.updateAdminCostCodeOnlyFromCostCodeList(false);
            }
            return true;
        }
    }

    render() {
        return (
            <div className="admin-cost-code-container">
                <h3 className="cost-code-header">
                    <FormattedMessage id={'UserPreferences_CostCode_header'} />
                </h3>
                <div className="user-preference-checkbox">
                    <input
                        id={'cost-code-checkbox_require-users-all'}
                        type="checkbox"
                        checked={this.props.adminIsCostCodeRequired === true ? 'checked' : ''}
                        onChange={this.handleCostCodeForAll}
                        data-testid={`cost-code-checkbox_require-users-all-${this.props.adminIsCostCodeRequired ? 'enabled' : 'disabled'}`}
                    />
                    <label htmlFor={'cost-code-checkbox_require-users-all'} className="preference-checkbox-label">
                        <FormattedMessage id={'UserPreferences_CostCode_checkboxes_requireUsers_all'} />
                    </label>
                </div>
                <div className="user-preference-checkbox">
                    <input
                        id={'cost-code-checkbox_require-users-existingList'}
                        type="checkbox"
                        disabled={this.isAdminCostCodesListDisabled()}
                        checked={this.props.adminChooseOnlyFromCostCodesList === true ? 'checked' : ''}
                        onChange={this.handleCostCodeFromList}
                        data-testid={`cost-code-checkbox_require-users-existingList-${this.props.adminChooseOnlyFromCostCodesList ? 'enabled' : 'disabled'}`}
                    />
                    <label
                        htmlFor={'cost-code-checkbox_require-users-existingList'}
                        className="preference-checkbox-label"
                    >
                        <FormattedMessage id={'UserPreferences_CostCode_checkboxes_requireUsers_existingList'} />
                    </label>
                </div>

                <b>
                    <FormattedMessage id={'UserPreferences_CostCode_addPredefinedCostCodes'} />
                </b>
                <AddPredefinedCostCodes
                    adminCostCodesList={this.props.adminCostCodesList}
                    addCostCodeToList={this.props.addCostCodeToList}
                    deleteCostCodeFromList={this.props.deleteCostCodeFromList}
                />
            </div>
        );
    }
}
