import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ChecksGroup from './ChecksGroup';
import preventAllUnchecks from './hoc/preventAllUnchecks';
import withLimitToggle from './hoc/withLimitToggle';
import withSelectAll from './hoc/withSelectAll';
import { FormattedMessage } from 'react-intl';
import { compose } from 'redux';
import withInlineSortFilter from './hoc/withInlineSortFilter';
import { isNil } from 'lodash';
import { connect } from 'react-redux';
import utils from '@utils/utilities';

const ChecksComponent = compose(withInlineSortFilter, withSelectAll, preventAllUnchecks, withLimitToggle)(ChecksGroup);

export const FilterCheckOptionType = PropTypes.shape({
    label: PropTypes.any,
    checked: PropTypes.any,
    count: PropTypes.number,
});

const FilterChecksGroup = (props) => {
    /**
     * Sends options back up, but in the same format as they arrived
     * @options options
     */
    const handleChange = function (options, option, event) {
        const unFormatOption = (option) => ({
            ...option,
            label: option.rawLabel ? option.rawLabel : option.label,
        });
        props.onChange(options.map(unFormatOption), option, event);
    };

    // overwriting the label from option to include the count prop to send downwards
    const formatOption = (option) => ({
        ...option,
        label: (
            <Fragment>
                <div className={'label-text'}>
                    {option.label === 'missingUnclassified' ? (
                        <FormattedMessage id={'General.postFilters.unclassified'} />
                    ) : (
                        option.label
                    )}
                </div>
                <span className={'filter-count'}>
                    {option.checked && props.displayItemsCount === true ? (
                        option.count ? (
                            utils.formatNumbersByUserLocale(props.language, option.count)
                        ) : (
                            option.count
                        )
                    ) : (
                        <span className="la-ZoomOut" />
                    )}
                </span>
            </Fragment>
        ),
        rawLabel: option.label,
    });

    const options = props.options.map(formatOption);
    const filterClassName = isNil(props.className) ? '' : props.className;

    return (
        <div className={`filter-checks-group ${filterClassName}`}>
            <ChecksComponent {...props} options={options} onChange={handleChange} />
        </div>
    );
};

FilterChecksGroup.propTypes = {
    onChange: PropTypes.func,
    options: PropTypes.arrayOf(FilterCheckOptionType),
    className: PropTypes.string,
    label: PropTypes.any,
    hasError: PropTypes.any,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    fieldId: PropTypes.string,
    visibleItemCount: PropTypes.number,
    displayItemsCount: PropTypes.bool,
};

FilterChecksGroup.defaultProps = {
    visibleItemCount: -1,
    displayItemsCount: true,
    label: null,
    hasError: false,
    disabled: false,
    required: false,
    className: '',
};

const mapStateToProps = (state) => ({
    language: state.user.preferences.language,
});

export { FilterChecksGroup as TestFilterChecksGroup };
export default connect(mapStateToProps)(FilterChecksGroup);
