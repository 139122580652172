import React from 'react';
import NegativityLevelPill from './negativityLevelPill/NegativityLevelPill';

const NegativityLevelPillContainer = ({
    levelsList,
    isShortLabel,
    isInteractive,
    onNegativityLevelChange,
    dataTrack,
}) => {
    if (!levelsList || !Object.keys(levelsList).length) {
        return null;
    }

    return (
        <div className="negativityLevelPillContainer">
            {Object.keys(levelsList).map((level) => {
                const pillProps = { level, isShortLabel, isInteractive, dataTrack };

                return (
                    <NegativityLevelPill
                        key={level}
                        {...pillProps}
                        className={levelsList[level]}
                        onNegativityLevelChange={onNegativityLevelChange}
                    />
                );
            })}
        </div>
    );
};

export default NegativityLevelPillContainer;
