import reduxStore from '@reduxStore';

import { BILLING_ID_DATE_FORMAT, COST_CODE } from '@constants';
import utils from './utilities';

let costCodeUtils = {
    getCostCode() {
        const state = reduxStore.getState();
        const historyCategoryName = state.historyCategory.categoryName;
        const historyCostCode =
            historyCategoryName && state.editSearch[historyCategoryName]
                ? state.editSearch[historyCategoryName].costCode
                : null;
        const adHocCostCode = state.adHocSearch.costCode;
        const userPreferencesCostCode = state.user.preferences.generalSettings.selectedCostCode;
        let costCode = historyCostCode || adHocCostCode || userPreferencesCostCode;

        if (costCode === COST_CODE.customCostCode.name) {
            costCode = '';
        }

        return costCode;
    },
    generateBillingId() {
        let billingID = utils.getUserLocaleDate(Date.now()).format(BILLING_ID_DATE_FORMAT);
        return billingID;
    },
    getClientIdPrefix() {
        return 'KYC8700-';
    },
};

export default costCodeUtils;
