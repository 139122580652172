import React from 'react';
import TruncatedText from './TruncatedText';
import { Link } from 'react-router';
import utils from '@utils/utilities';

const TruncatedLink = (props) => {
    return (
        <TruncatedText
            tooltipId="truncated-title"
            hasChild={true}
            tooltipHtml={false}
            checkTruncation={utils.isHtmlTextTruncated}
            TruncatedComponent={TitleLink}
            {...props}
        />
    );
};

const TitleLink = (props) => {
    const { article } = props;
    const isVisited = utils.visitedArticlesController.isVisited(article.id);

    return (
        <Link
            className={isVisited ? 'visited-article' : ''}
            to={props.linkTo}
            dangerouslySetInnerHTML={{ __html: article.title }}
            onClick={props.onClick}
            data-for={props.tooltipId}
            data-tip={props.isTruncated ? article.unhighlightedTitle : null}
            data-testid={'article-title-link'}
        />
    );
};

export default TruncatedLink;
