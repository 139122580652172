import React from 'react';
import { withToggleVisibility } from '../HOC/withToggleVisibility';
import TabbedContent from '@reusable/TabbedContent/TabbedContent';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';
import ModalContentOptions from './ModalContentOptions';
import ModalFileFormat from './ModalFileFormat';
import ModalComments from './ModalComments';
import ModalFileName from './ModalFileName';
import ModalEmail from './ModalEmail';
import ModalDescription from './ModalDescription';
import { MODAL_TAB_TYPE, MODAL_TYPE } from '@constants';
import formatRichMessage from '@utils/formatRichMessage';
import ModalReorderItems from './ModalReorderItems';

function ModalTabbedContent(props) {
    const isComponentVisible = (component) => {
        const tabsContent = [];
        props.model.tabbedComponents.forEach((tab) => tabsContent.push(...tab.content));
        return tabsContent.indexOf(component) > -1;
    };

    const getTabDescription = (type) => {
        let description = '';
        let term = '';

        switch (type) {
            case MODAL_TAB_TYPE.BASIC + MODAL_TYPE.DOWNLOAD_REPORTS_SEPARATE:
            case MODAL_TAB_TYPE.BASIC + MODAL_TYPE.DOWNLOAD_REPORTS_COMBINED:
                description = 'PopupBuilder.warning.downloadReport';
                break;
            case MODAL_TAB_TYPE.ADVANCED + MODAL_TYPE.DOWNLOAD_REPORTS_SEPARATE:
            case MODAL_TAB_TYPE.ADVANCED + MODAL_TYPE.EMAIL_REPORTS_SEPARATE:
            case MODAL_TAB_TYPE.ADVANCED + MODAL_TYPE.PRINT_REPORTS_SEPARATE:
                description = 'PopupBuilder.tabbedContent.download.separate.description';
                break;
            case MODAL_TAB_TYPE.ADVANCED + MODAL_TYPE.DOWNLOAD_REPORTS_COMBINED:
            case MODAL_TAB_TYPE.ADVANCED + MODAL_TYPE.EMAIL_REPORTS_COMBINED:
            case MODAL_TAB_TYPE.ADVANCED + MODAL_TYPE.PRINT_REPORTS_COMBINED:
                description = 'PopupBuilder.tabbedContent.download.combined.description';
                break;
            default:
                description = 'PopupBuilder.description.genericAction';
        }
        return formatRichMessage({ id: description }, props.intl, { term: term });
    };

    const getTabbedContent = () => {
        const tabs = [];
        props.model.tabbedComponents.forEach((tab) => {
            const content = tab.content.map((componentName) => {
                const isVisible = isComponentVisible(componentName);
                const description = getTabDescription(tab.type + props.model.popupType);
                return MapStringToComponent(props, isVisible, componentName, description);
            });
            tabs.push({
                label: formatRichMessage({ id: tab.label }, props.intl),
                content: content,
            });
        });
        return tabs;
    };
    return (
        <div className={'popup-modal__tabbed-content'}>
            <TabbedContent visible={true} defaultTabIndex={0} tabs={getTabbedContent()} />
        </div>
    );
}

const MapStringToComponent = (props, isVisible, componentName, description) => {
    const components = {
        ModalContentOptions: (
            <ModalContentOptions
                key={componentName}
                isVisible={isVisible}
                contentsOptions={props.model.contentsOptions}
                showDeliveryOptions={props.model.showDeliveryOptions}
                handleChange={props.onChangeDeliveryOptions}
                toggleDeliveryOptionsDisplay={props.toggleDeliveryOptionsDisplay}
                userLocks={props.userLocks}
                isFullDocsDisabled={props.isFullDocsDisabled}
                modalType={props.getModalType()}
                isSnapshotVisible={props.isSnapshotVisible}
                isSnapshotDisabled={props.isSnapshotDisabled}
            />
        ),
        ModalFileFormat: (
            <ModalFileFormat
                key={componentName}
                isVisible={isVisible}
                docType={props.model.docType}
                docTypeChanged={props.docTypeChanged}
                popupType={props.model.popupType}
            />
        ),
        ModalComments: (
            <ModalComments
                key={componentName}
                popupType={props.model.popupType}
                isVisible={isVisible}
                popupComments={props.popupComments}
                popupCommentsChange={props.popupCommentsChange}
                label="PopupModal.label.comments"
            />
        ),
        ModalFileName: (
            <ModalFileName
                key={componentName}
                isVisible={isVisible}
                popupType={props.model.popupType}
                isFilenameValid={props.isFileNameValid}
                fileNameRemainingCharactersMessage={props.fileNameRemainingCharactersMessage}
                fileNameErrorMessage={props.fileNameErrorMessage}
                fileName={props.fileName}
                fileNameChange={props.fileNameChange}
            />
        ),
        ModalEmail: (
            <ModalEmail
                key={componentName}
                popupType={props.model.popupType}
                isVisible={isVisible}
                isEmailValid={props.isEmailValid}
                emailErrorMessage={props.emailErrorMessage}
                emailAddress={props.emailAddress}
                emailSubject={props.emailSubject}
                emailComments={props.emailComments}
                emailAddressChange={props.emailAddressChange}
                emailSubjectChange={props.emailSubjectChange}
                emailCommentsChange={props.emailCommentsChange}
            />
        ),
        ModalDescription: (
            <ModalDescription
                key={componentName}
                popupType={props.model.popupType}
                isVisible={isVisible}
                description={description}
            />
        ),
        ModalReorderItems: (
            <ModalReorderItems
                dropTargetId={'modal-reorder-items'}
                isVisible={isVisible}
                key={componentName}
                items={props.model.selectedReports}
            />
        ),
    };

    return components[componentName];
};

export default compose(withToggleVisibility, injectIntl)(ModalTabbedContent);
