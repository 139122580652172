import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FilterChecksGroup, { TestFilterChecksGroup } from '@reusable/Form/FilterChecksGroup';

// TODO: More source related logic goes here
export default class CheckboxFilter extends Component {
    static propTypes = {
        onChange: PropTypes.func,
        onChangeSort: PropTypes.func,
        options: TestFilterChecksGroup.propTypes.options,
        selectAllText: PropTypes.string,
    };

    render() {
        return (
            <FilterChecksGroup
                onChange={this.props.onChange}
                postFilterType={this.props.postFilterType}
                searchFieldName={this.props.searchFieldName}
                visibleItemCount={this.props.visibleItemCount}
                {...this.props}
            />
        );
    }
}
