import React from 'react';
import { FormattedMessage } from 'react-intl';

const RadioButtonsHeaderCell = ({ rKey, risk, onChangeHandler, disabled }) => {
    return (
        <div key={rKey} className='user-preferences-category-container__authorities__simple-table__radio-header-cell'>
            {['HIGH', 'MEDIUM', 'LOW'].map((riskType) => {
                return (
                    <div key={riskType} className='user-preferences-category-container__authorities__simple-table__radio-header-cell__input'>
                        <div className='custom-radio'>
                            <input
                                id={`user-preferences-header-checkbox-risk-type-${riskType}`}
                                type='radio'
                                name={`user-preferences-sanctions-header-radio-${riskType}`}
                                value={riskType}
                                checked={risk === riskType}
                                onChange={() =>
                                    onChangeHandler(riskType)
                                }
                                disabled={disabled}
                                data-testid={`user-preferences-header-checkbox-risk-type-${riskType}`}

                            />
                            <label htmlFor={`user-preferences-header-checkbox-risk-type-${riskType}`} className='radio-label'>
                                <FormattedMessage id={`NegativeNewsVisualisations.label.${riskType.toLowerCase()}`} />
                            </label>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export default RadioButtonsHeaderCell;