import React, { useState, useEffect, useMemo } from 'react';
import { Loader } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import { filterShareViewUsers } from '../helpers/shareViewSearchHelper';
import utils from '@utils/utilities';
import EntityViewApi from '@pages/EntityView/api/EntityViewApi';

const ShareViewUserSelect = ({
    selectedViewId,
    closeModal,
    setSearchbarEnabled = () => {},
    shareViewSearchbarText,
}) => {
    const [users, setUsers] = useState([]);
    const [selectAllChecked, setSelectAllChecked] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [patchUsersPayload, setPatchUsersPayload] = useState({
        addedUsers: [],
        removedUsers: [],
    });

    const getShareViewUsers = async (viewId) => {
        setIsLoading(true);
        const [response, error] = await EntityViewApi.getShareViewUsers(viewId);

        if (response) {
            const allUsersSelected = response.length && response.every(({ isSharedWith }) => isSharedWith);
            setSelectAllChecked(allUsersSelected);
            setUsers(response);
            setIsLoading(false);
            setSearchbarEnabled(true);
        }
        if (error) {
            utils.showNotificationsMessage({
                messageText: 'BatchScreening.shareview.loadusers.error',
                messageType: 'system-error',
            });
            setIsLoading(false);
            closeModal && closeModal();
        }
    };

    const filteredUsers = useMemo(
        () => filterShareViewUsers(users, shareViewSearchbarText),
        [shareViewSearchbarText, users]
    );

    useEffect(() => {
        let isMounted = true;

        isMounted && !shareViewSearchbarText?.length && getShareViewUsers(selectedViewId);

        return () => {
            isMounted = false;
        };
    }, [selectedViewId, shareViewSearchbarText]);

    useEffect(() => {
        const shouldPatchUsers = patchUsersPayload.addedUsers.length || patchUsersPayload.removedUsers.length;
        shouldPatchUsers && handlePatchUsers();
    }, [selectedViewId, patchUsersPayload]);

    const onChangeUserSelect = (userId) => {
        filteredUsers.forEach((user) => {
            if (user.userPermId === userId) {
                const { isSharedWith } = user;

                if (isSharedWith) {
                    setPatchUsersPayload((prev) => ({
                        addedUsers: [...prev.addedUsers],
                        removedUsers: [...prev.removedUsers, userId],
                    }));
                } else {
                    setPatchUsersPayload((prev) => ({
                        addedUsers: [...prev.addedUsers, userId],
                        removedUsers: [...prev.removedUsers],
                    }));
                }
            }
        });
    };

    const handleSelectAll = () => {
        const newSelectAllChecked = !selectAllChecked;
        setSelectAllChecked(newSelectAllChecked);

        const updatedUsers = filteredUsers.map((user) => ({
            ...user,
            isSharedWith: newSelectAllChecked,
        }));

        setUsers(updatedUsers);

        if (newSelectAllChecked) {
            const allUserIds = updatedUsers.map(({ userPermId }) => userPermId);
            setPatchUsersPayload({
                addedUsers: allUserIds,
                removedUsers: [],
            });
        } else {
            handleClearAll();
        }
    };

    const handleClearAll = () => {
        const updatedUsers = filteredUsers.map((user) => ({
            ...user,
            isSharedWith: false,
        }));

        setUsers(updatedUsers);
        setSelectAllChecked(false);

        const allUserIds = updatedUsers.map(({ userPermId }) => userPermId);

        setPatchUsersPayload({
            addedUsers: [],
            removedUsers: allUserIds,
        });
    };

    const selectedUsersCount = users.filter(({ isSharedWith }) => isSharedWith).length;

    const handlePatchUsersError = (addedUsers, removedUsers) => {
        utils.showNotificationsMessage({
            messageText: 'BatchScreening.shareview.patchusers.error',
            messageType: 'system-error',
        });
        // reverting to the previous selection state if there is an API error
        setUsers((prevUsers) =>
            prevUsers.map((user) => {
                const isUserAdded = addedUsers.includes(user.userPermId);
                const isUserRemoved = removedUsers.includes(user.userPermId);

                if (isUserAdded && !isUserRemoved) {
                    return {
                        ...user,
                        isSharedWith: true,
                    };
                }

                if (!isUserAdded && isUserRemoved) {
                    return {
                        ...user,
                        isSharedWith: false,
                    };
                }

                return user;
            })
        );
    };

    const handlePatchUsers = () => {
        const { addedUsers, removedUsers } = patchUsersPayload;

        EntityViewApi.patchShareViewUsers(addedUsers, removedUsers, selectedViewId).then(([response, error]) => {
            if (error) handlePatchUsersError(addedUsers, removedUsers);
            if (response) setPatchUsersPayload({ addedUsers: [], removedUsers: [] });
        });
    };

    const handleSelectUser = (userId) => {
        const updatedUsers = filteredUsers.map((user) => ({
            ...user,
            isSharedWith: userId === user.userPermId ? !user.isSharedWith : user.isSharedWith,
        }));

        setUsers(updatedUsers);
        onChangeUserSelect(userId);
    };

    const noSearchTermUsers = shareViewSearchbarText?.length && !filteredUsers.length && !isLoading;

    return (
        <div className="share-view-user-selection-container">
            <div className="popup-modal__share-view-user-selection">
                <div className="share-view-user-selection-header">
                    <input
                        id="share-view-user-selection-select-all"
                        data-testid="share-view-user-selection-select-all"
                        type="checkbox"
                        checked={selectAllChecked}
                        disabled={isLoading || noSearchTermUsers}
                        onChange={handleSelectAll}
                    />
                    <div className="share-view-user-selection-header-inner">
                        <p>{selectedUsersCount}</p>
                        <p>
                            <FormattedMessage id="PopupModal.ModalItemsList.Selected" />
                        </p>
                    </div>
                    <button
                        className={`share-view-user-selection-clear-all${!selectedUsersCount ? '-disabled' : ''}`}
                        data-testid="share-view-user-selection-clear-all"
                        onClick={handleClearAll}
                        disabled={!selectedUsersCount || noSearchTermUsers}
                    >
                        <FormattedMessage id="General.label.deselectAll" />
                    </button>
                </div>
                {isLoading ? (
                    <div style={{ margin: '12rem 0' }} data-testid="share-view-user-select-loader">
                        <Loader size="massive" inline="centered" active inverted />
                    </div>
                ) : noSearchTermUsers ? (
                    <div className="share-view-no-search-results" data-testid="no-share-view-users-match-text">
                        <img src="/assets/Screening_Icon.svg" alt="empty-table-message-icon" />
                        <p>
                            <FormattedMessage id="BatchScreening.page.table.shareview.search.noMatch" />
                        </p>
                    </div>
                ) : (
                    filteredUsers?.map((user, index) => {
                        const { firstName, lastName, email: userEmail, userPermId: userId, isSharedWith } = user ?? {};
                        const uniqueCheckboxId = `share-view-user-checkbox-input-${userEmail}`;
                        const userName = `${firstName} ${lastName}`;
                        return (
                            <div key={index} className="share-view-user-selection-item">
                                <input
                                    id={uniqueCheckboxId}
                                    data-testid={`${uniqueCheckboxId}-testid`}
                                    type="checkbox"
                                    checked={isSharedWith}
                                    onChange={() => handleSelectUser(userId)}
                                />
                                <div>
                                    <h5>{userName}</h5>
                                    <h6>{userEmail}</h6>
                                </div>
                            </div>
                        );
                    })
                )}
            </div>
        </div>
    );
};

export default ShareViewUserSelect;
