import { DELIVERIES_TOC_OPTIONS, POPUP_BUTTON_TYPES } from '@constants';

export const CLOSE_POPUP = 'closePopup';
export const SET_MODAL_TYPE = 'setModalType';
export const SET_COMMENTS = 'setComments';
export const SET_MODEL = 'setModel';
export const SET_DOC_TYPE = 'setDocType';
export const SET_FILE_NAME = 'setFileName';
export const SET_EMAIL_ADDRESS = 'setEmailAddress';
export const SET_EMAIL_SUBJECT = 'setEmailSubject';
export const SET_EMAIL_COMMENTS = 'setEmailComments';
export const SET_REPORT_LIST = 'setReportListIds';
export const SET_RESULT_LIST_TYPE = 'setResultListType';
export const CHANGE_ADHOC_DATE_RANGE = 'changeAdhocDateRange';
export const ADHOC_SEARCH_ADD_CONTENT_TYPE = 'adhocSearchAddContentType';
export const ADHOC_SEARCH_REMOVE_CONTENT_TYPE = 'adhocSearchRemoveContentType';
export const UPDATE_ADHOC_PROPERTIES = 'updateAdhocProperties';
export const TOGGLE_BUTTON_STATUS_BY_TYPE = 'TOGGLE_BUTTON_STATUS_BY_TYPE';
export const UPDATE_ALL_BUTTON_STATUS = 'UPDATE_ALL_BUTTON_STATUS';
export const UPDATE_CUSTOM_QUERY_LANGUAGE = 'UPDATE_CUSTOM_QUERY_LANGUAGE';
export const UPDATE_CUSTOM_NEWS_QUERY_TITLE = 'UPDATE_CUSTOM_NEWS_QUERY_TITLE';
export const UPDATE_LEGAL_SEARCH_NAME_PARTY_ENTITY = 'UPDATE_LEGAL_SEARCH_NAME_PARTY_ENTITY';
export const UPDATE_DELIVERY_CONTENT_OPTIONS = 'UPDATE_DELIVERY_CONTENT_OPTIONS';
export const UPDATE_MODAL_COMPONENTS = 'UPDATE_MODAL_COMPONENTS';
export const TOGGLE_PRIMARY_BUTTON_DISABLED_STATUS = 'TOGGLE_PRIMARY_BUTTON_DISABLED_STATUS';
export const TOGGLE_DELIVERY_OPTIONS_DISPLAY = 'TOGGLE_DELIVERY_OPTIONS_DISPLAY';
export const REPORT_TYPE_CHANGE = 'REPORT_TYPE_CHANGE';
export const REORDER_SELECTED_REPORTS = 'REORDER_SELECTED_REPORTS';
export const CLEAR_POPUP = 'CLEAR_POPUP';

let popupModelActions = {
    closePopup: function () {
        return {
            type: CLOSE_POPUP,
            payload: true,
        };
    },

    setComments: function (comments) {
        return {
            type: SET_COMMENTS,
            payload: comments,
        };
    },

    setDocType: function (docType) {
        return {
            type: SET_DOC_TYPE,
            payload: docType,
        };
    },

    setFileName: function (fileName) {
        return {
            type: SET_FILE_NAME,
            payload: fileName,
        };
    },

    setEmailAddress: function (emailAddress) {
        return {
            type: SET_EMAIL_ADDRESS,
            payload: emailAddress,
        };
    },

    setEmailSubject: function (emailSubject) {
        return {
            type: SET_EMAIL_SUBJECT,
            payload: emailSubject,
        };
    },

    setEmailComments: function (emailComments) {
        return {
            type: SET_EMAIL_COMMENTS,
            payload: emailComments,
        };
    },

    setModalType: function (type) {
        return {
            type: SET_MODAL_TYPE,
            payload: type,
        };
    },

    setPopupModel: function (model) {
        return {
            type: SET_MODEL,
            payload: model,
        };
    },

    setReportListIds: function (reportsList) {
        return {
            type: SET_REPORT_LIST,
            payload: reportsList,
        };
    },

    setResultListType: (resultListType) => (dispatch) => {
        dispatch({
            type: SET_RESULT_LIST_TYPE,
            payload: resultListType,
        });
        return Promise.resolve();
    },

    updateAdHocDateRange: function (dateRange, section) {
        return {
            type: CHANGE_ADHOC_DATE_RANGE,
            payload: { dateRange, section },
        };
    },

    addAdHocSearchContentType: function (sources) {
        return {
            type: ADHOC_SEARCH_ADD_CONTENT_TYPE,
            payload: sources,
        };
    },

    deleteAdHocSearchContentType: function (sources) {
        return {
            type: ADHOC_SEARCH_REMOVE_CONTENT_TYPE,
            payload: sources,
        };
    },

    updateAdhocProperties: function (key, value) {
        return {
            type: UPDATE_ADHOC_PROPERTIES,
            payload: { key, value },
        };
    },

    toggleButtonStatusByType: function (type, status) {
        return {
            type: TOGGLE_BUTTON_STATUS_BY_TYPE,
            payload: { type, status },
        };
    },

    togglePrimaryButtonsStatus: function (status) {
        return {
            type: TOGGLE_PRIMARY_BUTTON_DISABLED_STATUS,
            payload: status,
        };
    },

    updateAllButtonStatus: function (status) {
        return {
            type: UPDATE_ALL_BUTTON_STATUS,
            payload: status,
        };
    },

    updateCheckedLanguagesUponKey: function (languages) {
        return {
            type: UPDATE_CUSTOM_QUERY_LANGUAGE,
            payload: languages,
        };
    },
    updateCustomNewsQueryTitle: function (title) {
        return {
            type: UPDATE_CUSTOM_NEWS_QUERY_TITLE,
            payload: title,
        };
    },

    updateSearchNamePartyEntity: function (searchNamePartyEntity) {
        return {
            type: UPDATE_LEGAL_SEARCH_NAME_PARTY_ENTITY,
            payload: searchNamePartyEntity,
        };
    },

    updateDeliveryContentOptions: (option, value) => (dispatch, getState) => {
        dispatch({
            type: UPDATE_DELIVERY_CONTENT_OPTIONS,
            payload: { option, value },
        });

        let { contentsOptions, buttons } = getState().popupModel;

        let values = Object.keys(contentsOptions).map((key) => contentsOptions[key]);
        let checkedOptions = values.filter((valueItem) => valueItem && valueItem !== DELIVERIES_TOC_OPTIONS.none);
        let isDisabled = checkedOptions && checkedOptions.length === 0;
        let button = buttons.find((buttonItem) => (buttonItem.type = POPUP_BUTTON_TYPES.download));

        if (button && button.isDisabled !== isDisabled) {
            dispatch({
                type: TOGGLE_PRIMARY_BUTTON_DISABLED_STATUS,
                payload: isDisabled,
            });
        }
    },

    updateModalComponents: function (components) {
        return {
            type: UPDATE_MODAL_COMPONENTS,
            payload: components,
        };
    },

    toggleDeliveryOptionsDisplay: function (status) {
        return {
            type: TOGGLE_DELIVERY_OPTIONS_DISPLAY,
            payload: status,
        };
    },

    reportTypeChange: function (status) {
        return {
            type: REPORT_TYPE_CHANGE,
            payload: status,
        };
    },

    reorderSelectedReports: function (orderedReports) {
        return {
            type: REORDER_SELECTED_REPORTS,
            payload: orderedReports,
        };
    },

    clearPopup: function () {
        return {
            type: CLEAR_POPUP,
            payload: {},
        };
    },
};

export default popupModelActions;
