import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';
import { UBO_GRAPH } from '@constants';
import ReactTooltip from 'react-tooltip';
import { FormattedMessage } from 'react-intl';
import { UboPersistedNode } from '@utils/props';
import { xorBy } from 'lodash';
import { GraphFilterDropdown } from './uboGraph';
import { GRAPH_TAGS } from '@sagas/helpers/uboGraphHelpers';
const { initSimulation, simulationTick } = require('@graph/ubo/graph.js');

const beneficiaryFilter = (node, index) => node.isBeneficiary === true || index === 0;
const nodeLinkFilter = (nodes) => (link) => {
    const sourceId = link.source.id,
        targetId = link.target.id;
    return nodes.filter((node) => node.id === sourceId || node.id === targetId).length === 2;
};

export default class UboSimpleGraph extends Component {
    static propTypes = {
        duns: PropTypes.string,
        data: PropTypes.object.isRequired,
        width: PropTypes.number,
        height: PropTypes.number,
        forceDistance: PropTypes.number,
        forceStrength: PropTypes.number,
        layerSize: PropTypes.number,
        leafWidth: PropTypes.number,
        zoomStep: PropTypes.number,
        persistedNodes: PropTypes.arrayOf(UboPersistedNode),
        onPersistNodes: PropTypes.func,
        filtering: PropTypes.object,
        updateSortingAndFiltering: PropTypes.func.isRequired,
    };

    static defaultProps = {
        ...UBO_GRAPH,
        persistedNodes: [],
        fitlerState: 0,
        onPersistNodes: null,
    };

    constructor(props) {
        super(props);

        this.state = {
            zoomLevel: 1,
            scrollH: 0,
            scrollV: 0,
            height: UBO_GRAPH.height,
            width: UBO_GRAPH.width,
        };

        this.zoomLevel = null;
        this.zoom = null;

        this.generateGraph = this.generateGraph.bind(this);
        this.handleZoomIn = this.handleZoomIn.bind(this);
        this.handleZoomOut = this.handleZoomOut.bind(this);
        this.handleNodeClick = this.handleNodeClick.bind(this);

        this.svg = null;
        this.simulation = null;
        this.svgElement = null;
        this.container = null;
    }

    generateGraph(data) {
        const { forceDistance, chargeStrength, layerSize, leafWidth, forceStrength } = this.props;

        const width = this.container.clientWidth || 800,
            height = 800;
        const { nodes, links } = data;

        const filteredNodes = nodes.filter(beneficiaryFilter);
        const filteredLinks = links.filter(nodeLinkFilter(filteredNodes));

        this.svgElement = d3.select('#ubo-simple-graph-container svg#uboSimpleGraph');

        // init the svg
        this.svg = this.svgElement.attr('width', width).attr('height', height).select('#simple-graph-container');

        // position the key after we compute the width
        d3.select('#graph-key foreignObject').attr('x', width - 195);

        // add zoom handlers
        this.zoom = d3
            .zoom()
            .scaleExtent(UBO_GRAPH.zoomLevels)
            .on(
                'zoom',
                function (event) {
                    this.svg.attr('transform', event.transform);
                    this.zoomLevel = event.transform;
                }.bind(this)
            );

        this.svgElement.call(this.zoom);

        // prepare config for simulation
        const simulationConfig = {
            forceDistance,
            chargeStrength,
            layerSize,
            leafWidth,
            forceStrength,
            width,
            height,
        };

        const handlers = {
            onClick: this.handleNodeClick,
            onHover: this.handleNodeHover,
            onMouseOut: this.handleNodeMouseOut,
        };

        this.simulation = initSimulation(d3, this.svg, filteredNodes, filteredLinks, simulationConfig, handlers);

        this.simulation.on('tick', simulationTick(d3, this.svg, filteredNodes));
    }

    coordinates(point) {
        let scale = this.zoom.scale(),
            translate = this.zoom.translate();
        return [(point[0] - translate[0]) / scale, (point[1] - translate[1]) / scale];
    }

    point(coordinates) {
        let scale = this.zoom.scale(),
            translate = this.zoom.translate();
        return [coordinates[0] * scale + translate[0], coordinates[1] * scale + translate[1]];
    }

    handleNodeClick(d, e) {
        if (this.props.onPersistNodes) {
            const { id } = e;
            const currentNode = d3.select(`#node_${id} .nodeDetails`);
            const currentClass = currentNode.attr('class');
            this.props.onPersistNodes(xorBy(this.props.persistedNodes, [e], (node) => node.id)); // xorBy acts as toggle item in array

            if (currentClass.indexOf('visible') > -1) {
                currentNode.attr('class', 'nodeDetails hidden');
            } else {
                currentNode.attr('class', 'nodeDetails visible');
            }
        }
    }

    handleNodeHover(d, e) {
        const { id } = e;
        const link = d3.select(`#link_${id}`);
        link.attr('marker-end', 'url(#arrowHover)');
        link.attr('class', 'line hover');
    }

    handleNodeMouseOut(d, e) {
        const { id } = e;
        const link = d3.select(`#link_${id}`);
        link.attr('marker-end', 'url(#arrow)');
        link.attr('class', 'line');
    }

    componentDidMount() {
        this.generateGraph(this.props.data);
    }

    handleZoomIn() {
        const theSvgElement = d3.select('#ubo-simple-graph-container svg');
        this.zoom.scaleBy(theSvgElement.transition().duration(750), 1.3);
    }

    handleZoomOut() {
        const theSvgElement = d3.select('#ubo-simple-graph-container svg');
        this.zoom.scaleBy(theSvgElement.transition().duration(750), 0.7);
    }

    componentWillUnmount() {
        if (this.simulation) {
            this.simulation.stop();
        }
    }

    render() {
        const graphTag = GRAPH_TAGS.find((tag) => tag.value === this.props.filtering.filteringDropdownSelected);
        const graphClassName = graphTag ? graphTag.name : '';

        return (
            <div id={'ubo-simple-graph-container'} ref={(el) => (this.container = el)} className={graphClassName}>
                <GraphFilterDropdown
                    updateSortingAndFiltering={this.props.updateSortingAndFiltering}
                    documentPreview={this.props.documentPreview}
                    filtering={this.props.filtering}
                />
                <div id={'graph-key'}>
                    <div xmlns="http://www.w3.org/1999/xhtml" className={'container'}>
                        <div className={'legend directOwnership'}>
                            <span>
                                <FormattedMessage id="UboDocumentView.TreeViewLegend.DirectOwnership" />
                            </span>
                        </div>
                        <div className={'legend indirectOwnership'}>
                            <span>
                                <FormattedMessage id="UboDocumentView.TreeViewLegend.IndirectOwnership" />
                            </span>
                        </div>
                        <div className={'legend degreesOfSeparation'}>
                            <span>1</span>
                            <FormattedMessage id="UboDocumentView.TreeViewLegend.DegreeOfSeparation" />
                        </div>
                    </div>
                </div>
                <ReactTooltip
                    type="dark"
                    border={true}
                    effect="solid"
                    className="tooltips notranslate"
                    place={'left'}
                />
                <div className={'ubo-graph-controls'} data-tip={'Zoom'}>
                    <div className={'zoom-control zoom-in'} onClick={this.handleZoomIn}>
                        <span className="la-ZoomIn" />
                    </div>
                    <div className={'zoom-control zoom-out'} onClick={this.handleZoomOut}>
                        <span className="la-ZoomOut" />
                    </div>
                </div>
                <svg ref={(ref) => (this.svgElement = ref)} id={'uboSimpleGraph'} xmlns="http://www.w3.org/2000/svg">
                    <g id={'simple-graph-container'} />
                </svg>
            </div>
        );
    }
}
