import React, { Fragment } from 'react';
import { FE_LOCK_NAMES } from '@constants';
import { FormattedMessage } from 'react-intl';
import withAdminLock from '@reusable/AdminLock/hoc/withAdminLock';
import PropTypes from 'prop-types';

const LegalSourcesNamePartyEntity = (props) => {
    const handleSearchEntitiesChange = (event) => {
        props.changeSearchNamePartyEntity(event.target.checked);
        if (props.handleAutosave) {
            props.handleAutosave(props.changeSearchNamePartyEntity, [event.target.checked, props.userIsAdmin]);
        }
    };

    return (
        <Fragment>
            <div className={`user-preference-checkbox`}>
                <input
                    id="specific-search-entities"
                    type="checkbox"
                    title="Name and Party only"
                    value="specific-search-entities"
                    checked={props.searchNamePartyEntity === true ? 'checked' : ''}
                    onChange={handleSearchEntitiesChange}
                    data-testid={`source-component-checkbox-${props.searchNamePartyEntity ? 'enabled' : 'disabled'}`}
                />
                <label htmlFor="specific-search-entities" className="preference-checkbox-label">
                    <FormattedMessage id={'UserPreferences.LegalSourceSearches.NamePartyEntities'} />
                </label>
            </div>
        </Fragment>
    );
};

LegalSourcesNamePartyEntity.propTypes = {
    searchNamePartyEntity: PropTypes.bool,
    userIsAdmin: PropTypes.any,
    handleAutosave: PropTypes.func,
    changeSearchNamePartyEntity: PropTypes.func,
};

LegalSourcesNamePartyEntity.defaultProps = {
    searchNamePartyEntity: false,
    userIsAdmin: undefined,
};

export default withAdminLock(
    FE_LOCK_NAMES.legalSearchNamePartyEntity,
    FE_LOCK_NAMES.masterLockLegalSourceSearches
)(LegalSourcesNamePartyEntity);
