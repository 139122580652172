import React, { Fragment } from 'react';
import { withToggleVisibility } from '../HOC/withToggleVisibility';

class ModalEditableTitle extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            editTitle: false,
        };

        this.titleInput = React.createRef();

        this.openEditTitleBox = this.openEditTitleBox.bind(this);
        this.handleChangeTitle = this.handleChangeTitle.bind(this);
        this.changeEditableState = this.changeEditableState.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
    }

    openEditTitleBox() {
        this.setState(
            {
                editTitle: true,
            },
            () => {
                this.titleInput.current.focus();
            }
        );
    }

    handleChangeTitle(event) {
        this.props.handleEditableTitleSave(event.target.value);
    }

    handleKeyPress(e) {
        if (e.key === 'Enter' || e.keyCode === 13) {
            this.changeEditableState(e);
        }
    }

    changeEditableState() {
        this.setState({
            editTitle: this.props.title.trim() === '',
        });
    }

    render() {
        const title = this.props.title ? this.props.title : null;
        const hasError = this.props.queryError && title === null;

        return (
            <Fragment>
                {this.state.editTitle ? (
                    <input
                        value={this.props.title}
                        className={`text-field-standard-sm popup-modal__editable-title-input ${
                            hasError ? 'text-field-error' : ''
                        }`}
                        onChange={this.handleChangeTitle}
                        onKeyPress={this.handleKeyPress}
                        onBlur={this.changeEditableState}
                        ref={this.titleInput}
                    />
                ) : (
                    <div
                        className={`popup-modal__editable-title ${this.props.isReadOnly ? 'readOnly' : ''}`}
                        onClick={this.openEditTitleBox}
                    >
                        <span className="popup-modal__editable-title__text">{title}</span>
                        {!this.props.isReadOnly && <span className="la-Edit" />}
                    </div>
                )}
            </Fragment>
        );
    }
}

export default withToggleVisibility(ModalEditableTitle);
