import { takeLatest, takeEvery, all, take, call } from 'redux-saga/effects';
import {
    UBO_SEARCH_INIT,
    UBO_INIT,
    UBO_ITEM_ADD,
    UBO_SUBMIT,
    UBO_SEARCH_COUNT,
} from '@pages/StartPage/redux/Ubo.actions';
import { SEARCH_PARAM_RESET } from '@pages/MainSearch/redux/SearchParams.actions';

/**
 * Listener that will wait for one UBO_INIT and then launch flow
 * Will execute only once, after this the listener is no longer active
 * @param flow
 */
export function* onFirstInit(flow) {
    yield take(UBO_INIT);
    yield call(flow);
}

/**
 * Listener that will continuously listen for the UBO_INIT and launch flow
 * @param flow
 */
export function* onEveryInit(flow) {
    yield takeLatest(UBO_INIT, flow);
}

/**
 * take latest UBO_SEARCH_INIT - any pending searches will get the cancelled flow
 * @param flow
 */
export function* onSearch(flow) {
    yield takeLatest(UBO_SEARCH_INIT, flow);
}

/**
 * The reset listener for ubo, this is an array because the signal may come from multiple places other than UBO
 * like the SEARCH_PARAM_RESET that is triggered from other places
 * add here other triggers that would reset the UBO
 * @param flow
 */
export function* onReset(flow) {
    yield all([
        takeEvery(SEARCH_PARAM_RESET, flow),
        //takeEvery()
    ]);
}

// One time listener
export function* onItemAdd() {
    return yield take(UBO_ITEM_ADD);
}

export function* onSubmit(flow) {
    yield takeLatest(UBO_SUBMIT, flow);
}

export function* onSearchCount(flow) {
    yield takeLatest(UBO_SEARCH_COUNT, flow);
}
