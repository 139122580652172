import React from 'react';

const MarketingTooltip = ({ children, content, direction = 'top', isActive = false }) => {
    return (
        <div className="marketing-tooltip-wrapper" data-testid="marketing-tooltip">
            {children}
            {isActive && (
                <div className={`marketing-tooltip-tip ${direction}`} data-testid="marketing-tooltip-tip">
                    {content}
                </div>
            )}
        </div>
    );
};

export default MarketingTooltip;
