import React from 'react';

const Toggle = (props) => {
    return (
        <div
            className={`custom-toggle ${props.disabled && 'disabled'}`}
            data-for={'disabled-tooltip'}
            data-place={'bottom'}
            data-tip={props.dataTip || ''}
        >
            <input
                type="checkbox"
                className="custom-toggle-checkbox"
                checked={props.checked ? 'checked' : ''}
                onChange={() => props.handleChange(props.checked)}
                id={props.id}
            />
            <label className="custom-toggle-label" htmlFor={props.id} />
        </div>
    );
};

export default Toggle;
