import React from 'react';
import { FormattedMessage } from 'react-intl';
import { withAnonymizedHideView } from '@utils/contexts';
import { ReactComponent as DismissIcon } from '../../../assets/icons/BannerDismissIcon.svg';
import { ReactComponent as DismissIconDark } from '../../../assets/icons/DismissIconDark.svg';
import { ReactComponent as BannerInfoIcon } from '../../../assets/icons/BannerInfoIcon.svg';
import { ReactComponent as BannerInfoIconDark } from '../../../assets/icons/BannerInfoIconDark.svg';

const UserPreferencesBanner = (props) => {
    return (
        <div
            className={
                (props.showUserPreferencesBanner
                    ? 'diligence-header__user-preferences slide'
                    : 'diligence-header__user-preferences')
                + (props.isDarkMode ? ' dark-mode' : '')
            }
        >
            <div className='diligence-header__user-preferences__icon'>
            {props.isDarkMode ? <BannerInfoIconDark /> : <BannerInfoIcon />}
            </div>
            <div className="diligence-header__user-preferences__message">
                {props.messageID && (
                    <div className="diligence-header__user-preferences__message__header">
                        <FormattedMessage id={props.messageID} />
                    </div>
                )}
                {props.messageIDs &&
                    Object.keys(props.messageIDs).map(
                        (section) =>
                            props.messageIDs[section] &&
                            props.messageIDs[section].map((ids, index) => (
                                <div key={index}>
                                    {ids &&
                                        ids.length > 1 &&
                                        ids.map(
                                            (id, index) =>
                                                id && (
                                                    <span
                                                        className={index === ids.length - 1 ? 'bold' : ''}
                                                        key={index}
                                                    >
                                                        <FormattedMessage id={id} />
                                                        {index < ids.length - 1 && ' >'}{' '}
                                                    </span>
                                                )
                                        )}
                                </div>
                            ))
                    )}
            </div>
            <button type="button" className="diligence-header__user-preferences__button" onClick={props.hideUserPreferencesBanner}>
                {props.isDarkMode ? <DismissIconDark /> : <DismissIcon />}
            </button>
        </div>
    );
};

export default withAnonymizedHideView(UserPreferencesBanner);
