import React from 'react';
import { FormattedMessage } from 'react-intl';
import utils from '@utils/utilities';

function ModalEntitySearchTerm({ entityNameError, entitySearchTerm, editAction }) {
    const onChange = (event) => {
        const value = event.target.value;
        const error = utils.validateSearchQuery(value);

        editAction({ entityName: value, entityNameError: error });
    };

    return (
        <div className="popup-modal__entity-name">
            <div className="popup-field-name">
                <FormattedMessage id="BatchScreening.popup.editSearch.searchTerm" />
            </div>
            {entityNameError && (
                <div className="validationAlertMessage">
                    <FormattedMessage id={entityNameError} />
                </div>
            )}
            <input
                className={`text-field-standard-lg ${entityNameError ? 'text-field-error' : ''}`}
                data-testid="modal-entity-searchTerm-testid"
                value={entitySearchTerm}
                onChange={onChange}
            />
        </div>
    );
}

export default ModalEntitySearchTerm;
