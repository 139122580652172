import { resetCategory } from '@reduxStore';
import {
    CATEGORY_NAMES,
    FILTER_INFO,
    PERSON_SEARCH,
    POST_FILTER_COMPANY_MENTIONS,
    POST_FILTER_DOCKET_STATUS,
    POST_FILTER_DUNS_FILTER,
    POST_FILTER_FUZZY_SEARCH,
    POST_FILTER_PERSON_MENTIONS,
    POSTFILTER_COMPONENT_TYPE,
    POSTFILTER_COMPONENTS,
    POSTFILTER_TYPE,
} from '@constants';
import { cloneDeep } from 'lodash';
import categoryUtils from '@utils/categoryUtils';
import utils from '@utils/utilities';
import { determineDateRange } from '@MainSearch/SearchUtils';

export const UPDATE_SEARCH_CATEGORY = 'UPDATE_SEARCH_CATEGORY';
export const UPDATE_SEARCH_CATEGORIES_LIST = 'UPDATE_SEARCH_CATEGORIES_LIST';
export const RESET_CATEGORIES_RESULTS = 'RESET_CATEGORIES_RESULTS';
export const RESET_CATEGORIES_GROUP = 'RESET_CATEGORIES_GROUP';
export const UPDATE_CATEGORY_PROPERTY = 'UPDATE_CATEGORY_PROPERTY';
export const SEARCH_PARAM_REMOVE_TERM = 'SEARCH_PARAM_REMOVE_TERM';
export const SEARCH_PARAM_ADD_TERM = 'SEARCH_PARAM_ADD_TERM';
export const CLEAR_TERMS_CATEGORY = 'CLEAR_TERMS_CATEGORY';
export const SET_SORT_OPTION = 'SET_SORT_OPTION';
export const SET_LAST_SORT_OPTION = 'SET_LAST_SORT_OPTION';
export const UPDATE_POST_FILTERS = 'UPDATE_POST_FILTERS';
export const UPDATE_CATEGORY_POSTFILTER = 'UPDATE_CATEGORY_POSTFILTER';
export const UPDATE_CATEGORY_POSTFILTERS = 'UPDATE_CATEGORY_POSTFILTERS';
export const UPDATE_DUPLICATE_SIZE = 'UPDATE_DUPLICATE_SIZE';
export const SEARCH_UBO_ADD_CATEGORY = 'SEARCH_UBO_ADD_CATEGORY';
export const SEARCH_UBO_CLEAR_CATEGORIES = 'SEARCH_UBO_CLEAR_CATEGORIES';
export const UPDATE_SEARCH_CATEGORY_LAST_UPDATED = 'UPDATE_SEARCH_CATEGORY_LAST_UPDATED';
export const CLEAR_CATEGORY_NEGATIVE_TERMS = 'CLEAR_CATEGORY_NEGATIVE_TERMS';
export const SET_FILTERS_APPLIED_FOR_CATEGORY = 'SET_FILTERS_APPLIED_FOR_CATEGORY';
export const SET_SEARCH_RESULTS = 'SET_SEARCH_RESULTS';

function resetRadioList(values, postFilterType, preferences, adHocPreferences, categoryName) {
    let checkedItem;

    switch (postFilterType) {
        case POSTFILTER_TYPE.PROXIMITY_NEGATIVE_TERMS:
            checkedItem = values.find((value) => {
                return preferences.proximity === value.count;
            });
            break;

        case POSTFILTER_TYPE.COMPANY_MENTIONS:
        case POSTFILTER_TYPE.PERSON_MENTIONS:
            checkedItem = {};
            checkedItem.count = null;
            break;

        case POSTFILTER_TYPE.FUZZY_SEARCH:
            checkedItem = values.find((value) => value.checked);
            break;

        case POSTFILTER_TYPE.DATE_RANGE:
            const isAdHocEmpty = Object.keys(adHocPreferences).length === 0 && adHocPreferences.constructor === Object;
            const newsDateRange =
                isAdHocEmpty || !adHocPreferences.newsDateRange || !adHocPreferences.newsDateRange.range
                    ? preferences.newsSources.dateRange
                    : adHocPreferences.newsDateRange.range;
            const companyDateRange =
                isAdHocEmpty || !adHocPreferences.companyDateRange || !!adHocPreferences.companyDateRange.range
                    ? preferences.companySources.dateRange
                    : adHocPreferences.companyDateRange.range;
            const legalDateRange =
                isAdHocEmpty || !adHocPreferences.legalDateRange || !adHocPreferences.companyDateRange.range
                    ? preferences.legalSources.dateRange
                    : adHocPreferences.legalDateRange.range;
            const otherCategoriesDateRange = determineDateRange(adHocPreferences);

            switch (categoryName) {
                case CATEGORY_NAMES.NEWS:
                case CATEGORY_NAMES.NEGATIVE_NEWS:
                case CATEGORY_NAMES.NEGATIVE_NEWS_EN:
                case CATEGORY_NAMES.NEGATIVE_NEWS_FR:
                case CATEGORY_NAMES.NEGATIVE_NEWS_NL:
                case CATEGORY_NAMES.NEGATIVE_NEWS_DE:
                case CATEGORY_NAMES.NEGATIVE_NEWS_ES:
                case CATEGORY_NAMES.NEGATIVE_NEWS_TR:
                case CATEGORY_NAMES.NEGATIVE_NEWS_SV:
                case CATEGORY_NAMES.NEGATIVE_NEWS_MS:
                case CATEGORY_NAMES.NEGATIVE_NEWS_PT:
                case CATEGORY_NAMES.NEGATIVE_NEWS_JA:
                case CATEGORY_NAMES.NEGATIVE_NEWS_ZH:
                case CATEGORY_NAMES.NEGATIVE_NEWS_IT:
                case CATEGORY_NAMES.NEGATIVE_NEWS_AR:
                case CATEGORY_NAMES.NEGATIVE_NEWS_RU:
                case CATEGORY_NAMES.NEGATIVE_NEWS_PL:
                case CATEGORY_NAMES.CUSTOM_NEWS:
                case CATEGORY_NAMES.CUSTOM_NEWS1:
                case CATEGORY_NAMES.CUSTOM_NEWS2:
                case CATEGORY_NAMES.CUSTOM_NEWS3:
                case CATEGORY_NAMES.LN_CUSTOM_NEWS1:
                case CATEGORY_NAMES.ADMIN_CUSTOM_NEWS1:
                case CATEGORY_NAMES.ADMIN_CUSTOM_NEWS2:
                case CATEGORY_NAMES.ADMIN_CUSTOM_NEWS3:
                    checkedItem = values.find((value) => {
                        return newsDateRange === value.count;
                    });
                    break;
                case CATEGORY_NAMES.COMPANY_RESOURCES:
                    checkedItem = values.find((value) => {
                        return companyDateRange === value.count;
                    });
                    break;
                case CATEGORY_NAMES.AGENCY_DECISION:
                case CATEGORY_NAMES.STATE_DOCKETS:
                case CATEGORY_NAMES.FEDERAL_DOCKETS:
                case CATEGORY_NAMES.LAW_REVIEWS:
                case CATEGORY_NAMES.CASES:
                case CATEGORY_NAMES.VERDICTS:
                    checkedItem = values.find((value) => {
                        return legalDateRange === value.count;
                    });
                    break;
                case CATEGORY_NAMES.BIOGRAPHICAL:
                case CATEGORY_NAMES.PEPS:
                case CATEGORY_NAMES.SANCTIONS_WATCHLIST:
                case CATEGORY_NAMES.FINANCIAL_REPORT:
                    checkedItem = values.find((value) => {
                        return otherCategoriesDateRange === value.count;
                    });
                    break;
            }
            break;
    }
    return checkedItem && checkedItem.count;
}

let searchResultsActions = {
    updateArticlesDuplicateSize: (duplicateGroupsCount, categoryName) => {
        return {
            type: UPDATE_DUPLICATE_SIZE,
            payload: {
                duplicateGroupsCount,
                categoryName,
            },
        };
    },

    updateCategory: function (categoryResponse) {
        return {
            type: UPDATE_SEARCH_CATEGORY,
            payload: categoryResponse,
        };
    },

    updateCategoriesList: function (categoryResponse) {
        return {
            type: UPDATE_SEARCH_CATEGORIES_LIST,
            payload: categoryResponse,
        };
    },

    updateCategoryLastUpdated: function (categoryName) {
        return {
            type: UPDATE_SEARCH_CATEGORY_LAST_UPDATED,
            payload: categoryName,
        };
    },

    updateCategoryProperty: function (categoryName, propertyName, propertyValue) {
        return {
            type: UPDATE_CATEGORY_PROPERTY,
            payload: { name: categoryName, propertyName, propertyValue },
        };
    },

    updateSearchPostFilters: function (postFilters) {
        return {
            type: UPDATE_POST_FILTERS,
            payload: { ...postFilters },
        };
    },
    updatePostFilterForCategory: (categoryName, postFilterName, postFilterValue) => {
        return {
            type: UPDATE_CATEGORY_POSTFILTER,
            payload: { categoryName, postFilterName, postFilterValue },
        };
    },

    resetPostFilterToDefaultValues:
        (categoryName, postFilterType, postFilterName, values, dynamic) => (dispatch, getState) => {
            let componentType = '';
            let postFilterValue = values.map((item) => {
                return item.rawLabel || item.label;
            });

            const state = getState();
            const preferences = state.user.preferences.generalSettings;
            const adHocPreferences = state.adHocSearch;
            const { personCheck, companyCheck } = state.user.preferences;
            const searchType = state.searchParams.searchType;

            Object.keys(POSTFILTER_COMPONENTS).forEach((key) => {
                if (POSTFILTER_COMPONENTS[key].type === postFilterType) {
                    componentType = POSTFILTER_COMPONENTS[key].componentType;
                }
            });
            switch (componentType) {
                case POSTFILTER_COMPONENT_TYPE.CHECKBOX_LIST:
                    // if filter is dynamic send empty string to get all
                    if (dynamic) {
                        postFilterValue = [];
                    }
                    //all items should be checked
                    if (postFilterName === FILTER_INFO.FUZZY_NAMES || postFilterName === FILTER_INFO.SUGGESTED_NAMES) {
                        postFilterValue = {};
                        postFilterValue.list = values.map((item) => {
                            return { name: item.label, selected: true };
                        });
                    }

                    if (postFilterName === FILTER_INFO.DOCKET_STATUS) {
                        if (postFilterValue !== undefined) {
                            postFilterValue = utils.getDocketStatusValue(postFilterValue);
                        }
                    }

                    if (postFilterName === FILTER_INFO.NEGATIVITY_LEVELS) {
                        postFilterValue =
                            searchType === PERSON_SEARCH ? personCheck.negativityLevel : companyCheck.negativityLevel;
                    }

                    break;

                case POSTFILTER_COMPONENT_TYPE.RADIO_LIST:
                    postFilterValue = resetRadioList(values, postFilterType, preferences);

                    break;
                case POSTFILTER_COMPONENT_TYPE.DATE_RADIO_LIST:
                    postFilterValue = resetRadioList(
                        values,
                        postFilterType,
                        preferences,
                        adHocPreferences,
                        categoryName
                    );
                    break;
                case POSTFILTER_COMPONENT_TYPE.TEXT_FILTER:
                    postFilterValue = '';
                    break;
            }

            dispatch({
                type: UPDATE_CATEGORY_POSTFILTER,
                payload: { categoryName, postFilterName, postFilterValue },
            });
        },

    resetAllPostFiltersToDefaultValues: (categoryName, categoryPostFilters) => (dispatch, getState) => {
        const state = getState();
        let componentType = '';
        const preferences = state.user.preferences.generalSettings;
        const adHocPreferences = state.adHocSearch;
        const searchResults = state.searchResults;
        let postFilters = cloneDeep(searchResults[categoryName].postFilters);
        const { personCheck, companyCheck } = state.user.preferences;
        const searchType = state.searchParams.searchType;

        Object.keys(categoryPostFilters).forEach((postFilterType) => {
            Object.keys(POSTFILTER_COMPONENTS).forEach((key) => {
                if (POSTFILTER_COMPONENTS[key].type === postFilterType) {
                    componentType = POSTFILTER_COMPONENTS[key].componentType;
                }
            });

            let values = categoryPostFilters[postFilterType].values;
            let postFilterValues = [];

            let postFilterName = categoryPostFilters[postFilterType].searchFieldName;

            if (values.length > 0) {
                switch (componentType) {
                    case POSTFILTER_COMPONENT_TYPE.CHECKBOX_LIST:
                        //all items should be checked
                        if (postFilterName === FILTER_INFO.FUZZY_NAMES) {
                            postFilterValues = {};
                            postFilterValues.list = values.map((item) => {
                                return { name: item.label, selected: true };
                            });
                        }
                        //all items should be checked
                        if (postFilterName === FILTER_INFO.SUGGESTED_NAMES) {
                            postFilterValues = {};
                            postFilterValues.list = values.map((item) => {
                                return { name: item.label, selected: true };
                            });
                        }

                        if (postFilterName === FILTER_INFO.NEGATIVITY_LEVELS) {
                            postFilterValues =
                                searchType === PERSON_SEARCH
                                    ? personCheck.negativityLevel
                                    : companyCheck.negativityLevel;
                        }

                        break;

                    case POSTFILTER_COMPONENT_TYPE.RADIO_LIST:
                        postFilterValues = resetRadioList(values, postFilterType, preferences);

                        break;
                    case POSTFILTER_COMPONENT_TYPE.DATE_RADIO_LIST:
                        postFilterValues = resetRadioList(
                            values,
                            postFilterType,
                            preferences,
                            adHocPreferences,
                            categoryName
                        );
                        break;
                    case POSTFILTER_COMPONENT_TYPE.TEXT_FILTER:
                        postFilterValues = '';
                }
                if (
                    postFilterValues ||
                    postFilterName === POST_FILTER_PERSON_MENTIONS ||
                    postFilterName === POST_FILTER_COMPANY_MENTIONS ||
                    postFilterName === POST_FILTER_DUNS_FILTER
                ) {
                    if (postFilterName === FILTER_INFO.PUBLICATION_DATE) {
                        postFilterName = FILTER_INFO.DATE_RANGE;
                    }
                    postFilters[postFilterName] = postFilterValues;
                }

                if (
                    postFilterName === POST_FILTER_FUZZY_SEARCH &&
                    postFilterValues !== undefined &&
                    postFilterValues !== null
                ) {
                    postFilters[postFilterName] = postFilterValues;
                }

                if (postFilterName === POST_FILTER_DOCKET_STATUS && postFilterValues !== undefined) {
                    postFilters[postFilterName] = utils.getDocketStatusValue(postFilterValues);
                }
            }
        });

        dispatch({
            type: UPDATE_CATEGORY_POSTFILTERS,
            payload: { categoryName, postFilters },
        });
    },

    resetCategoriesResults: function () {
        return function (dispatch, getState) {
            const categoriesResults = getState().searchResults;

            Object.keys(categoriesResults).forEach((key) => {
                const { isCategoryAvailable, isCategoryEnabled } = categoryUtils.getCategoryActiveStatus(
                    getState(),
                    key
                );
                const sortOption = getState().user.preferences.generalSettings.sort;

                categoriesResults[key] = resetCategory(key, isCategoryEnabled, isCategoryAvailable, sortOption);
            });

            dispatch({
                type: RESET_CATEGORIES_RESULTS,
                payload: categoriesResults,
            });
        };
    },

    setSearchResults: function (searchResults) {
        return {
            type: SET_SEARCH_RESULTS,
            payload: searchResults,
        };
    },

    resetSingleCategoryResults: function (categoryName) {
        return function (dispatch, getState) {
            const categories = [];
            const sortOption = getState().user.preferences.generalSettings.sort;

            if (categoryUtils.hasChildren(categoryName)) {
                categoryUtils.getCategoryKeys(categoryName).forEach((subCategory) => {
                    const { isCategoryAvailable, isCategoryEnabled } = categoryUtils.getCategoryActiveStatus(
                        getState(),
                        subCategory
                    );

                    categories.push(resetCategory(subCategory, isCategoryEnabled, isCategoryAvailable, sortOption));
                });
            } else {
                const { isCategoryAvailable, isCategoryEnabled } = categoryUtils.getCategoryActiveStatus(
                    getState(),
                    categoryName
                );

                categories.push(resetCategory(categoryName, isCategoryEnabled, isCategoryAvailable, sortOption));
            }

            dispatch({
                type: RESET_CATEGORIES_GROUP,
                payload: categories,
            });
        };
    },

    addTerms: (term, type, category) => {
        return {
            type: SEARCH_PARAM_ADD_TERM,
            payload: { term, type, category },
        };
    },

    removeTerms: (term, type, category) => {
        return {
            type: SEARCH_PARAM_REMOVE_TERM,
            payload: { term, type, category },
        };
    },

    clearCategoryTerms: (category) => {
        return {
            type: CLEAR_TERMS_CATEGORY,
            payload: category,
        };
    },

    setSortOption: (categoryName, sort) => {
        return {
            type: SET_SORT_OPTION,
            payload: { categoryName, sort },
        };
    },

    setLastSort: (categoryName, lastSort) => {
        return {
            type: SET_LAST_SORT_OPTION,
            payload: { categoryName, lastSort },
        };
    },

    uboAddCategory: ({ categoryName, term, duns, title }) => {
        return {
            type: SEARCH_UBO_ADD_CATEGORY,
            payload: { categoryName, term, duns, title },
        };
    },

    uboClearCategories: () => {
        return {
            type: SEARCH_UBO_CLEAR_CATEGORIES,
            payload: {},
        };
    },

    clearCategoryNegativeTerms: (category) => {
        return {
            type: CLEAR_CATEGORY_NEGATIVE_TERMS,
            payload: category,
        };
    },

    setFiltersAppliedForCategory: (categoryName, filtersApplied) => {
        return {
            type: SET_FILTERS_APPLIED_FOR_CATEGORY,
            payload: { categoryName, filtersApplied },
        };
    },
};

export default searchResultsActions;
