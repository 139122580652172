// @flow
import { call, put, select, take } from 'redux-saga/effects';

import * as workers from '../workers/investigationWorkers';
import * as mainSelectors from '../selectors/main';
import * as selectors from '../selectors/investigationSelectors';
import * as helpers from '../helpers/investigationHelpers';
import * as uboSelectors from '../selectors/uboSelectors';

import {
    COMPANY_SEARCH,
    FUZZY_SEARCH_TYPE,
    PREFERENCES_KEY,
    POST_FILTER_TERMS,
    TERMS,
    CATEGORY_NAMES,
} from '@constants';
import { EVENT_SUBTYPES, EVENT_TYPES, ACTION_TYPES } from '../constants/investigationConstants';
import { ACTIONS } from '../constants/metricConstants';
import investigationActions, { SET_INVESTIGATION } from '@pages/MainSearch/redux/Investigation.actions';
import { isError } from 'lodash';
import searchResultsActions from '@pages/MainSearch/redux/SearchResults.actions';
import { searchStatusActions } from '@pages/MainSearch/redux/SearchStatus.action';
import { getCategoryNameAndSubcategory } from '../helpers/investigationHelpers';

import searchUtils from '@pages/MainSearch/SearchUtils';
import categoryUtils from '@utils/categoryUtils';
import utils from '@utils/utilities';

import type {
    SearchStartedFromHistoryAction,
    SearchStartedAction,
    DocumentOperationActionType,
    DocumentOperationCategoryType,
    FilterOperationActionType,
    FilterOperationProcessedData,
    ReportCreatedActionType,
    ReportOperationActionType,
    CategoryRevisitedActionType,
    CategoryVisitedActionType,
    CategoryVisitedProcessedData,
    SearchUpdateActionType,
    StartSearchActionType,
    InvestigationDataType,
    InvestigationType,
    FuzzyConfigType,
    CountsRetrievedActionType,
} from '../investigationTypeGuards/investigationFlows.type.guards.js';
import type { ProcessedRevisitedCategoryDataType } from '../investigationTypeGuards/investigationHelpers.typeGuard';
import type {
    GetInvestigationData,
    ExtractedUboPostFiltersType,
} from '../investigationTypeGuards/investigationWorkers.type.guards';
import type { CurrentReport } from '@ReportBuilder/redux/flow/ReportBuilder.type.guards';

/**
 * Executes on every instance of start_investigation,
 * Retrieves the investigation id or the report corresponding to the investigation
 */

export function* startInvestigation(): Generator<any, void, boolean & string & GetInvestigationData & Error> {
    yield call(workers.resetInvestigation);
    yield call(workers.resetCurrentReport); // old flow

    const praOn: boolean = yield select(mainSelectors.isPublicRecordsOn);
    const searchQueryType: string = yield select(mainSelectors.theSearchType);
    const searchQuery: string = yield select(mainSelectors.theSearchQuery);
    //prefilterQuery has a whitespace in front of the first AND
    const prefilterQuery: string = yield select(mainSelectors.thePreFilterQuery);
    const createdDate: number = new Date().getTime();
    const fullSearchQuery: string =
        prefilterQuery && prefilterQuery.length > 0 ? `${searchQuery} ${prefilterQuery}` : searchQuery;
    const data: GetInvestigationData | Error = yield call(workers.getInvestigation, {
        praOn,
        searchQuery,
        fullSearchQuery,
        searchQueryType,
        createdDate,
    });

    if (isError(data)) {
        yield put(investigationActions.setError());
    } else {
        yield call(workers.setInvestigation, data);
        yield put({ type: ACTION_TYPES.retrieveInvestigationId });
    }
}

export function* setSameInvestigation(): Generator<any, void, InvestigationType & CurrentReport> {
    const investigation: InvestigationType = yield select(selectors.getInvestigation);
    const currentReport: CurrentReport = yield select(selectors.getFullCurrentReport);

    if (investigation && currentReport) {
        const data = {
            investigationId: investigation.id,
            searchQuery: investigation.searchQuery,
            searchQueryType: investigation.searchQueryType,
            reportId: currentReport.reportId,
            reportSnippets: currentReport.articlesSnippets,
            researchSummary: currentReport.researchSummary,
            hasReport: currentReport.reportId !== null,
        };

        yield put(investigationActions.setInvestigation({ ...data }));
        yield put({ type: ACTION_TYPES.retrieveInvestigationId });
    }
}

/**
 * Executes on every instance of set_investigation,
 *
 */

export function* startSearch(action: StartSearchActionType): Generator<any, void, InvestigationDataType> {
    //@TODO add UBO flow

    const investigationData: InvestigationDataType = yield take(SET_INVESTIGATION); //make sure investigationId is set

    const searchFlowsMapping = {
        [EVENT_SUBTYPES.normal]: searchStarted,
        [EVENT_SUBTYPES.filters]: searchFromFiltersResetStarted,
        [EVENT_SUBTYPES.history]: searchFromHistoryStarted,
        [EVENT_SUBTYPES.alert]: searchFromEditAlertStarted,
    };

    yield call(searchFlowsMapping[action.type], {
        search: action.payload,
        investigation: investigationData.payload,
        type: action.type,
    });
}

/**
 * Part of the startSearch flow,
 *
 */

export function* searchStarted(
    action: SearchStartedAction
): Generator<any, void, string & ExtractedUboPostFiltersType & FuzzyConfigType> {
    const investigationId: string = action.investigation.investigationId;
    if (!investigationId) return;

    const uboPostfilters: ExtractedUboPostFiltersType | null = yield call(workers.extractUBOPostFilters);
    const newsSource: string = yield select(
        selectors.getNewsContentSources,
        PREFERENCES_KEY[action.investigation.searchQueryType]
    );
    const fuzzyConfig: FuzzyConfigType = yield select(
        selectors.getFuzzyConfig(PREFERENCES_KEY[action.investigation.searchQueryType])
    );

    const investigationPayload = {
        createdDate: action.search.timestamp || new Date().getTime(),
        eventType: EVENT_TYPES.searchPerformed,
        eventSubType: action.type,
        payload: {
            searchQuery: action.investigation.searchQuery,
            searchQueryType: action.investigation.searchQueryType,
            prefilterQuery: action.investigation.prefilterQuery,
            fuzzySearchType: fuzzyConfig.fuzzyOn ? fuzzyConfig.fuzzyThreshold.toUpperCase() : FUZZY_SEARCH_TYPE.OFF,
            filters: helpers.processDataForSearchStarted({ data: action.search.data, newsSource, uboPostfilters }),
        },
    };

    // for company search remove 'fuzzySearchType' from payload
    if (action.investigation.searchQueryType === COMPANY_SEARCH) delete investigationPayload.payload.fuzzySearchType;

    yield call(workers.sendInvestigationEvent, { investigationId, payload: investigationPayload });
}

export function* searchFromFiltersResetStarted(
    action: SearchStartedAction
): Generator<any, void, ExtractedUboPostFiltersType & string & null & FuzzyConfigType> {
    const investigationId: string = action.investigation.investigationId;
    if (!investigationId) return;

    const uboPostfilters: ExtractedUboPostFiltersType | null = yield call(workers.extractUBOPostFilters);
    const newsSource: string = yield select(
        selectors.getNewsContentSources,
        PREFERENCES_KEY[action.investigation.searchQueryType]
    );
    const fuzzyConfig: FuzzyConfigType = yield select(
        selectors.getFuzzyConfig(PREFERENCES_KEY[action.investigation.searchQueryType])
    );

    const investigationPayload = {
        createdDate: action.search.timestamp || new Date().getTime(),
        eventType: EVENT_TYPES.searchPerformed,
        eventSubType: action.type,
        payload: {
            searchQuery: action.investigation.searchQuery,
            searchQueryType: action.investigation.searchQueryType,
            prefilterQuery: action.investigation.prefilterQuery,
            fuzzySearchType: fuzzyConfig.fuzzyOn ? fuzzyConfig.fuzzyThreshold.toUpperCase() : FUZZY_SEARCH_TYPE.OFF,
            filters: helpers.processDataForSearchStarted({ data: action.search.data, newsSource, uboPostfilters }),
        },
    };

    // for company search remove 'fuzzySearchType' from payload
    if (action.investigation.searchQueryType === COMPANY_SEARCH) delete investigationPayload.payload.fuzzySearchType;

    yield call(workers.sendInvestigationEvent, { investigationId, payload: investigationPayload });
}

export function* searchFromHistoryStarted(
    action: SearchStartedFromHistoryAction
): Generator<any, void, ExtractedUboPostFiltersType | null> {
    const investigationId: string = action.investigation.investigationId;
    if (!investigationId) return;

    const uboPostfilters: ExtractedUboPostFiltersType | null = yield call(workers.extractUBOPostFilters);

    const investigationPayload = {
        createdDate: action.search.timestamp || new Date().getTime(),
        eventType: EVENT_TYPES.searchPerformed,
        eventSubType: action.type,
        payload: {
            searchQuery: action.investigation.searchQuery,
            searchQueryType: action.investigation.searchQueryType,
            prefilterQuery: action.investigation.prefilterQuery,
            lastSearchRunDate: action.search.lastSearchRunDate,
            fuzzySearchType: !!action.search.fuzzyThreshold
                ? action.search.fuzzyThreshold.toUpperCase()
                : FUZZY_SEARCH_TYPE.OFF,
            filters: helpers.processDataForSearchFromHistory({ data: action.search.data, uboPostfilters }),
        },
    };

    // for company search remove 'fuzzySearchType' from payload
    if (action.investigation.searchQueryType === COMPANY_SEARCH) delete investigationPayload.payload.fuzzySearchType;

    yield call(workers.sendInvestigationEvent, { investigationId, payload: investigationPayload });
}

export function* searchFromEditAlertStarted(action: any): Generator<any, any, any> {
    const investigationId: string = action.investigation.investigationId;

    if (!investigationId) return;

    const investigationPayload = {
        createdDate: action.search.timestamp || new Date().getTime(),
        eventType: EVENT_TYPES.searchPerformed,
        eventSubType: action.type,
        payload: {
            searchQuery: action.investigation.searchQuery,
            searchQueryType: action.investigation.searchQueryType,
            prefilterQuery: action.investigation.prefilterQuery,
            filters: helpers.processDataForSearchFromEditAlert({ data: action.search.data }),
        },
    };

    yield call(workers.sendInvestigationEvent, { investigationId, payload: investigationPayload });
}

/**
 * Executes on every instance of search performed,
 */

export function* countsRetrieved(action: CountsRetrievedActionType) {
    const results: mixed = yield select(selectors.getSearchResults);
    const investigationId: string = yield select(selectors.getInvestigationId);
    const { sendEventWithoutTriggeringTheFlow } = action.payload;

    // this flag is used when we only need to send the counts availalbe action in redux to trigger the loadDocuments flow manually, and we don't want to propagate the count available event to the back-end
    if (sendEventWithoutTriggeringTheFlow) return;
    if (!investigationId) return;

    const payload = {
        createdDate: action.payload.timestamp || new Date().getTime(),
        eventType: EVENT_TYPES.searchPerformed,
        eventSubType: action.type,
        payload: {
            counts: helpers.getCountsFromSearchResults(results),
        },
    };

    yield call(workers.sendInvestigationEvent, { investigationId, payload });
}

export function* searchUpdate(action: SearchUpdateActionType) {
    const { name, count }: { name: string, count: number } = action.payload.categoryData;
    const { category, subCategory }: { category: string, subCategory: ?string } = getCategoryNameAndSubcategory(name);
    const investigationId: string = yield select(selectors.getInvestigationId);

    if (!investigationId) return;

    const payload = {
        createdDate: action.payload.timestamp || new Date().getTime(),
        eventType: EVENT_TYPES.searchPerformed,
        eventSubType: action.type,
        payload: {
            counts: {
                category,
                subCategory,
                docCount: count,
            },
        },
    };

    yield call(workers.sendInvestigationEvent, { investigationId, payload });
}

export function* loadDocuments(action) {
    let { categoryName, filteredFields, isSnapshotVisible, isSearchRerun } = action.payload;
    const isUserAnonymized = yield select(selectors.isUserAnonymized);

    if (categoryName === CATEGORY_NAMES.DNB) {
        // for "ubo" we try to get the first child since "ubo" category is the parent
        const categoryData = yield call([categoryUtils, 'getFirstChild'], categoryName);
        categoryName = (categoryData && categoryData.name) ?? categoryName;
    }

    const { category, payload } = yield call(
        searchUtils.loadMoreDocumentsForAvailableCategories,
        categoryName,
        0,
        isSearchRerun,
        true
    );
    yield put(searchStatusActions.toggleDocumentsLoadingStatus(false));

    if (!isSnapshotVisible && !isSearchRerun) {
        yield put({ type: ACTIONS.metricGather });
    }

    yield put(investigationActions.markVisitedCategories(categoryName));
    yield put(
        investigationActions.investigationAction({
            type: EVENT_SUBTYPES.firstTime,
            payload: {
                categoryData: category,
                searchPayload: payload,
                eventSubType: null,
            },
        })
    );

    if (!isUserAnonymized) {
        yield put(searchResultsActions.updateCategoryProperty(categoryName, 'markedInHistory', true));
        yield call(searchUtils.updateHistory, categoryName, filteredFields);
    }
}

/**
 * Executes on every instance of category visited
 */

export function* categoryVisited(action: CategoryVisitedActionType) {
    const {
        categoryData,
        searchPayload,
        categoryData: { name: categoryName },
        searchPayload: { newsSource },
    } = action.payload;
    const currentTime = yield call(utils.getCurrentTime);
    const isNegativeNewsCategory = yield call([categoryUtils, 'isChildOf'], CATEGORY_NAMES.NEGATIVE_NEWS, categoryName);
    const results: mixed = yield select(selectors.getSearchResults);
    const investigationId: string = yield select(selectors.getInvestigationId);

    yield put(investigationActions.markVisitedCategories(categoryName));

    if (!investigationId) return;

    const uboPostfilters: mixed = yield select(uboSelectors.theUboCategoryPostFilters, categoryName);
    const searchType: string = yield select(mainSelectors.theSearchType);
    const newsSourceFromState: string = yield select(selectors.getNewsContentSources, PREFERENCES_KEY[searchType]);
    const categoryPayload: CategoryVisitedProcessedData = yield call(helpers.processDataForCategoryVisited, {
        categoryData,
        payload: searchPayload,
        newsSource: newsSource || newsSourceFromState,
        uboPostfilters,
        counts: isNegativeNewsCategory
            ? helpers
                  .getCountsFromSearchResults(results)
                  .filter((item) => item.category === categoryUtils.getParent(categoryName))
            : [],
    });
    const payload = {
        createdDate: currentTime,
        eventType: EVENT_TYPES.categoryVisited,
        eventSubType: action.type,
        payload: categoryPayload,
    };

    yield call(workers.sendInvestigationEvent, { investigationId, payload });
    yield put({ type: EVENT_TYPES.categoryVisited, payload: { categoryName } });
}

export function* categoryRevisited(action: CategoryRevisitedActionType) {
    const {
        categoryData,
        categoryData: { name: categoryName },
    } = action.payload;
    const currentTime = yield call(utils.getCurrentTime);
    const isNegativeNewsCategory = yield call([categoryUtils, 'isChildOf'], CATEGORY_NAMES.NEGATIVE_NEWS, categoryName);
    const results: mixed = yield select(selectors.getSearchResults);
    const investigationId: string = yield select(selectors.getInvestigationId);

    if (!investigationId) return;

    const uboPostfilters: mixed = yield select(uboSelectors.theUboCategoryPostFilters, categoryName);
    const categoryPayload: ProcessedRevisitedCategoryDataType = yield call(helpers.processDataForCategoryRevisited, {
        categoryData,
        uboPostfilters,
        counts: isNegativeNewsCategory
            ? helpers
                  .getCountsFromSearchResults(results)
                  .filter((item) => item.category === categoryUtils.getParent(categoryName))
            : [],
    });

    const payload = {
        createdDate: currentTime,
        eventType: EVENT_TYPES.categoryVisited,
        eventSubType: action.type,
        payload: categoryPayload,
    };
    yield call(workers.sendInvestigationEvent, { investigationId, payload });
    yield put({ type: EVENT_TYPES.categoryVisited, payload: { categoryName } });
}

/**
 * Executes on every instance of filter operation
 */

export function* filterOperation(action: FilterOperationActionType) {
    const investigationId: string = yield select(selectors.getInvestigationId);

    if (!investigationId) return;

    const { payload: data, eventSubType }: FilterOperationProcessedData =
        action.payload.eventSubType === EVENT_SUBTYPES.all
            ? helpers.processDataForAllPostFiltersRemoval(action.payload)
            : helpers.processDataForFiltersInvestigationEvents(action.payload);

    const payload = {
        createdDate: new Date().getTime(),
        eventType: action.type,
        eventSubType: eventSubType,
        payload: data,
    };

    yield call(workers.sendInvestigationEvent, { investigationId, payload });
}

/**
 * Executes on every instance of report operation
 */

export function* reportCreated({
    action,
    investigationId,
}: {
    action: ReportCreatedActionType,
    investigationId: string,
}) {
    const payload = {
        createdDate: new Date().getTime(),
        eventType: action.type,
        eventSubType: action.payload.eventSubtype,
        payload: {
            reportId: action.payload.reportId,
        },
    };

    yield call(workers.sendInvestigationEvent, { investigationId, payload });
}

/**
 * Executes on every instance of report operation
 * made to centralise report operations
 * the operations are identifiable by their subtype and a flow can be trigger accordingly
 */

export function* reportOperation(action: ReportOperationActionType) {
    const investigationId: string = yield select(selectors.getInvestigationId);
    if (!investigationId || !action.payload) return;

    if (action.payload.eventSubtype === EVENT_SUBTYPES.created) yield call(reportCreated, { action, investigationId });
}

/**
 * Executes on every instance of document operation
 */

export function* documentOperation(action: DocumentOperationActionType) {
    const { articles, articleType, eventSubType } = action.payload;
    const category: DocumentOperationCategoryType = helpers.getCategoryNameAndSubcategory(articleType);
    const investigationId: string = yield select(selectors.getInvestigationId);
    if (!investigationId) return;

    const payload = {
        createdDate: new Date().getTime(),
        eventType: EVENT_TYPES.documentOperation,
        eventSubType,
        payload: {
            ...category,
            snippetIds: articles,
        },
    };

    yield call(workers.sendInvestigationEvent, { investigationId, payload });
}

export function* applyNegativeTerm(action) {
    const { categoryName } = action.data.payload;
    const searchResults = yield select(selectors.getSearchResults);
    const searchData = searchResults[categoryName];
    const { filterTerm, name, count, payload } = helpers.getAppliedTermAndPostfilterData(searchData);

    if (!!filterTerm) {
        yield put(
            investigationActions.investigationAction({
                type: EVENT_TYPES.filterApplied,
                payload: {
                    eventType: EVENT_TYPES.filterApplied,
                    eventSubType: null,
                    searchPayload: payload,
                    postFilterFieldName: POST_FILTER_TERMS,
                    categoryData: { name, count },
                    postFilterType: TERMS,
                    options: [filterTerm],
                },
            })
        );
    }
}
