import React from 'react';
import popupModelActions from '../PopupModal/redux/PopupModel.actions';
import { CATEGORIES_ORDER, COMPANY_SEARCH, MODAL_TYPE, PERSON_SEARCH } from '@constants';
import reduxStore from '@reduxStore';
import PopupModal from '../PopupModal/PopupModal.index';
import PopupModel from '../PopupModal/PopupBuilder';
import utils from '@utils/utilities';
import { cloneDeep } from 'lodash';
import { FormattedMessage } from 'react-intl';

const AdHocSearch = (props) => {
    const isAdHockLocked = () => {
        let {
            masterLockNewsCompanyLegalSearches,
            sourcesIncluded,
            masterLockNewsSearchesCompany,
            masterLockNewsSearchesPerson,
            newsSearchesDateRange,
            companySource,
            legalSourceDefaultDateRange,
            legalSourceDefaultLegalSources,
            newsSearchSourcesPerson,
            newsSearchSourcesCompany,
        } = props.userLocks;

        // cost codes do not lock
        let costCodeLock = false;

        // general settings locks
        let masterLock =
            (masterLockNewsCompanyLegalSearches && sourcesIncluded && costCodeLock) ||
            (sourcesIncluded &&
                newsSearchesDateRange &&
                companySource &&
                legalSourceDefaultDateRange &&
                legalSourceDefaultLegalSources &&
                costCodeLock);

        // person || company check locks
        switch (props.searchType) {
            case PERSON_SEARCH:
                masterLock = masterLock && (masterLockNewsSearchesPerson || newsSearchSourcesPerson) && costCodeLock;
                break;
            case COMPANY_SEARCH:
                masterLock = masterLock && (masterLockNewsSearchesCompany || newsSearchSourcesCompany) && costCodeLock;
                break;
        }

        return masterLock;
    };

    const getCustomNewsDisabledStatus = () => {
        let tempContentTypes = cloneDeep(props.contentTypes);
        let availableQueries = getAvailableQueries();

        tempContentTypes.forEach((contentType) => {
            if (contentType.name === CATEGORIES_ORDER.customNews.key) {
                contentType.disabled = availableQueries === undefined;
                contentType.checked = contentType.checked && availableQueries !== undefined;
            }
        });
        return tempContentTypes;
    };

    const getAvailableQueries = () => {
        const customNewsQueries = [...props.personCustomNewsQuery, ...props.companyCustomNewsQuery];

        const adminPersonCustomNews = props.adminPersonCustomNewsQuery;
        const adminCompanyCustomNews = props.adminCompanyCustomNewsQuery;

        const lnPersonCustomNewsQuery = props.lnPersonCustomNewsQuery;
        const lnCompanyCustomNewsQuery = props.lnCompanyCustomNewsQuery;

        if (adminCompanyCustomNews && adminPersonCustomNews) {
            customNewsQueries.push(...adminPersonCustomNews, ...adminCompanyCustomNews);
        }

        if (lnPersonCustomNewsQuery && lnCompanyCustomNewsQuery) {
            customNewsQueries.push(...lnPersonCustomNewsQuery, ...lnCompanyCustomNewsQuery);
        }

        return customNewsQueries.find(
            (customQuery) =>
                customQuery.checked &&
                (customQuery.isDeleted === false || !customQuery.isDeleted) &&
                customQuery.query &&
                customQuery.query.length > 0
        );
    };

    const showPopup = async (event) => {
        let popupType = event.currentTarget.value;
        await props.refreshPreferencesIfNeeded();
        let contentTypes = getCustomNewsDisabledStatus();
        let lawSources = cloneDeep(props.lawSources);
        let newsSearchSources = cloneDeep(props.newsSearchSources);
        let customQuery = cloneDeep(props.customQuery);

        if (props.adHocSearch.searchQueryType === props.searchType) {
            newsSearchSources = props.adHocSearch.newsSearchSources;
            customQuery = props.adHocSearch.customQuery;
        }

        let popupModel = new PopupModel.Builder(popupType)
            .setPopupProperty('isVisible', true)
            .setPopupProperty('originalSources', props.adHocSearch.sources || utils.getSearchSources(contentTypes))
            .setPopupProperty('sources', props.searchBarContentTypes)
            .setPopupProperty('lawSources', props.adHocSearch.lawSources || lawSources)
            .setPopupProperty('lawSource', props.adHocSearch.lawSource || props.lawSource)
            .setPopupProperty(
                'costCode',
                typeof props.adHocSearch.costCode === 'string' ? props.adHocSearch.costCode : props.costCode
            )
            .setPopupProperty('isCustomCostCode', props.adHocSearch.isCustomCostCode || false)
            .setPopupProperty('costCodes', props.adHocSearch.costCodes || props.costCodes)
            .setPopupProperty(
                'chooseOnlyFromCostCodesList',
                props.adHocSearch.chooseOnlyFromCostCodesList || props.chooseOnlyFromCostCodesList
            )
            .setPopupProperty(
                'isCostCodeRequired',
                props.adHocSearch.isCostCodeRequired === undefined
                    ? props.isCostCodeRequired
                    : props.adHocSearch.isCostCodeRequired
            )
            .setPopupProperty(
                'buttonIsDisabled',
                props.adHocSearch.buttonIsDisabled === undefined ? true : props.adHocSearch.buttonIsDisabled
            )
            .setPopupProperty('searchQueryType', props.searchType)
            .setPopupProperty('customQuery', customQuery)
            .setPopupProperty('newsSearchSources', newsSearchSources)
            .setPopupProperty(
                'searchNamePartyEntity',
                props.adHocSearch.searchNamePartyEntity || props.searchNamePartyEntity
            )
            .setPopupProperty('userLocks', props.userLocks)
            .setPopupProperty('adHocMasterLock', isAdHockLocked())
            .setPopupProperty(
                'updateUserPreferencesCheckbox',
                props.adHocSearch.updateUserPreferencesCheckbox === undefined
                    ? false
                    : props.adHocSearch.updateUserPreferencesCheckbox
            )
            .build();
        reduxStore.dispatch(popupModelActions.setPopupModel(popupModel));
    };

    return (
        <div className="my-search-preferences-wrapper">
            {props.popupModel.isVisible && <PopupModal />}
            <button
                className="my-search-preferences-button"
                value={MODAL_TYPE.ADHOC_SEARCH}
                onClick={showPopup}
                data-testid="search-preferences"
            >
                <FormattedMessage id="AdHocSearch.mySearchPreferences" />
            </button>
        </div>
    );
};

export { AdHocSearch as TestAdHocSearch };
export default AdHocSearch;
