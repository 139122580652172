import React from 'react';
import { withToggleViewForExpandedReport } from '@ReportBuilder/HOC/withReportHoc';
import { withLoadingSpinner } from '@reusable/HOC/withLoadingSpinner';
import { compose } from 'redux';
import { Segment } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import { REPORT_DELIVERY_STATUSES } from '@constants';

const Content = (props) => {
    return (
        <div className="batch-report">
            <Segment>
                <div className="columns">
                    {props.report.batchEntities?.map((entity, index) => (
                        <div key={entity.reportId} className="batch-entry">
                            <p className="batch-entry_index">{index + 1}.</p>
                            {entity.reportDeliveryStatus === REPORT_DELIVERY_STATUSES.ERROR && (
                                <div className="batch-entry_error-dot" />
                            )}
                            <p
                                className={`batch-entry_query ${
                                    entity.reportDeliveryStatus === REPORT_DELIVERY_STATUSES.ERROR &&
                                    'batch-entry_error'
                                }`}
                            >
                                {entity.searchQuery}
                            </p>
                        </div>
                    ))}
                </div>
            </Segment>
        </div>
    );
};

export default compose(withLoadingSpinner, withToggleViewForExpandedReport, injectIntl)(Content);
export { Content as TestBatchReportContent };
