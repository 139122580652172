import { Component } from 'react';
import PropTypes from 'prop-types';

export default class TableStateProvider extends Component {
    static propTypes = {
        provideState: PropTypes.func.isRequired,
        initState: PropTypes.object,
    };

    static defaultProps = {
        initState: {},
    };

    constructor(props) {
        super(props);

        this.state = {
            data: {
                rows: [],
                totalCount: 0,
                filterCount: 0,
                hasError: false,
                isLoading: false,
            },
            selection: {
                selectedItems: [],
            },
            sorting: {
                sortBy: null,
                direction: null,
            },
            pagination: {
                pageNumber: 0,
                pageSize: 10,
            },
            filtering: {
                searchString: null,
                filters: null,
                filteringDropdownOptions: [],
                filteringDropdownSelected: null,
            },
            additionalData: {
                isLoading: false,
            },
            ...this.props.initState,
        };

        this.handleUpdateState = this.handleUpdateState.bind(this);
    }

    handleUpdateState(state, callback) {
        this.setState(state, callback);
    }

    render() {
        const stateProviderProps = {
            state: this.state,
            onUpdateState: this.handleUpdateState,
        };

        return this.props.provideState(stateProviderProps);
    }
}
