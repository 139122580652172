import { call, cancelled } from 'redux-saga/effects';
import { CancelToken } from 'axios';
import axios from '@utils/axios';
import SearchUtils from '@pages/MainSearch/SearchUtils';

// function that wraps a request so if the flow in which is included is being cancelled,
// use axios cancel token to also cancel the request to free up the request spots
export function request(config = {}) {
    return call(function* () {
        const source = CancelToken.source();
        try {
            return yield call(axios.request, { cancelToken: source.token, ...config });
        } finally {
            if (yield cancelled()) {
                source.cancel();
            }
        }
    });
}

// a simple saga function that retries once if a request fails
export function* requestWithRetry(config) {
    try {
        return yield request(config);
    } catch (e) {
        /* eslint-disable */
        try {
            return yield request(config);
        } catch (err) {
            throw err;
        }
        /* eslint-enable */
    }
}

export function* updateFuzzyNames(query) {
    yield SearchUtils.getCustomFuzzy(query);
}
