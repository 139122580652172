import React from 'react';
import { hashHistory, Router } from 'react-router';
import routes from './reactV3Helpers/routes'

const ReactRouterProvider = (props) => {
    if(props.useRouterVersion6) {
        //TODO return RR6 component
        return <div id='react-router-v6'/>
    }
    return (
        <Router history={hashHistory}>{routes}</Router>
    )
}

export default ReactRouterProvider;