import React, { Component } from 'react';
import NestedCheckboxFilter from '../NestedCheckboxFilter';
import utils from '@utils/utilities';

// Receives flat geography paths,
export default class GeographyPostFilter extends Component {
    handleChange = (options, option, e) => {
        // transform from hierarchic to flat
        const values = utils.flattenTreeValues(options);

        this.props.onChange(values, option, e, this.props.postFilterType, this.props.searchFieldName);
    };

    render() {
        // transform from flat to hierarchic after we add the path for each option
        let options = utils.convertFlatOptions(this.props.options);

        return (
            <div className={'geography-postfilter'}>
                <NestedCheckboxFilter
                    {...this.props}
                    options={options}
                    onChange={this.handleChange}
                    fieldId={'geography-postfilter'}
                />
            </div>
        );
    }
}
