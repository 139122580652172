// @flow
import * as React from 'react';
import { hashHistory, withRouter } from 'react-router';
import SingleArticleSidebar from '@reusable/SingleArticleSidebar/SingleArticleSidebar.index';
import SingleArticleHeader from '@MainSearch/components/SingleArticleHeader';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import breadcrumbsActions from '@reusable/Breadcrumbs/redux/Breadcrumbs.actions';
import articleNavigationActions from '@MainSearch/redux/ArticleNavigation.actions';
import utils from '@utils/utilities';
import BreadCrumbsModel from '@utils/breadCrumbBuilder';
import {
    BREADCRUMBS,
    DOWNLOAD_DEFAULT_FILENAME,
    BILLING_ID,
    UBO_DEFAULT_SORTING_AND_FILTERING,
    UBO_ERRORS,
    UBO_MAIN_CATEGORY,
    DOCUMENT_PAGE_TITLE,
} from '@constants';
import costCodeUtils from '@utils/costCodeUtils';
import UboDocumentView from './UboDocumentView';
import categoryUtils, { getCategoryOrder } from '@utils/categoryUtils';
import { withContentSourceFilter } from '@utils/utilities';
import PopupModel from '@reusable/PopupModal/PopupBuilder';
import popupModelActions from '@reusable/PopupModal/redux/PopupModel.actions';
import PopupModal from '@reusable/PopupModal/PopupModal.index';
import uboActions from '../StartPage/redux/Ubo.actions';
import { FormattedMessage } from 'react-intl';
import ErrorMessage from '@reusable/ErrorMessage/ErrorMessage';
// $FlowFixMe
import { ReactComponent as BannerWarningIcon } from '../../../assets/icons/BannerWarningIcon.svg';
// $FlowFixMe
import { ReactComponent as BannerWarningIconDark } from '../../../assets/icons/BannerWarningIconDark.svg';
import type {
    UboArticleNavigation,
    UboArticleType,
    UboLocation,
    UboParams,
    UboPersistedNodeType,
} from './redux/flow/UboDocument.type.guards';
import type { SearchParams } from '@reusable/SearchBar/redux/flow/SearchBar.type.guards';
import type { PopupModelType, RouteType, RouterType, RoutesType } from '@utils/flow/utilities.type.guards';
import type { ContentTypesType } from '@MainSearch/components/typeGuards/ResultsList.typeGuards';

type State = {
    hasError: boolean,
    persistedNodes: Array<UboPersistedNodeType>,
    popupModalVisible?: boolean,
};

type Props = {
    article: UboArticleType,
    articleNavigation: UboArticleNavigation,
    children?: any,
    duns: string,
    emailAddress: string,
    filtering: {
        filteringDropdownSelected: number,
    },
    initViewArticle: (params: Object) => void,
    billingId: string,
    isLoading: boolean,
    isSnapshotVisible: boolean,
    location: UboLocation,
    params: UboParams,
    popupModel: PopupModelType,
    prevQuery: Object,
    previousRoute: string,
    route: RouteType,
    routeParams: Object,
    router: RouterType,
    routes: Array<RoutesType>,
    searchParams: SearchParams,
    searchResults: Object,
    selectedArticle: Object,
    useNewResearchSummary: boolean,
    contentTypes: Array<ContentTypesType>,
    selectedReport: () => void,
    setPopupModel: (params: Object) => void,
    updateBreadcrumbs: (params: any) => void,
    updateSortingAndFiltering: (params: Object) => void,
    isDarkMode: boolean,
};

class UboDocument extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            hasError: false,
            persistedNodes: [],
        };
    }

    UNSAFE_componentWillMount() {
        let { billingId } = this.props.location.query,
            id = this.props.router.params.duns,
            category = UBO_MAIN_CATEGORY,
            isBranch = this.props.router.params.isBranch === '1',
            name = this.props.router.params.name;

        // let investigationId = billingId;//get investigationId from URL
        //get investigationId from props
        if (!billingId) {
            billingId = this.props.billingId;
        }
        if (!billingId) {
            billingId = localStorage.getItem(BILLING_ID);
        }
        billingId = billingId || costCodeUtils.generateBillingId();

        const costCode = costCodeUtils.getCostCode();

        this.props.initViewArticle({
            id,
            category,
            billingId,
            costCode,
            articleData: { isBranch, name },
        });
    }

    componentWillUnmount() {
        this.props.updateSortingAndFiltering(UBO_DEFAULT_SORTING_AND_FILTERING);
    }

    handleError = () => {
        this.setState({
            hasError: true,
        });
    };

    handlePersistNodes = (persistedNodes: Array<UboPersistedNodeType>) => {
        this.setState({
            persistedNodes: persistedNodes.map((node: UboPersistedNodeType) => ({ id: node.id })),
        });
    };

    componentDidMount() {
        document.title = DOCUMENT_PAGE_TITLE;
        let selectedArticle = this.props.selectedArticle;

        if (!selectedArticle && !selectedArticle.article && !selectedArticle.article.articleType) {
            this.handleError();
        }

        if (!this.state.hasError) {
            this.setBreadcrumbs();
        }

        // reset sorting and filtering fields to default
        this.props.updateSortingAndFiltering(UBO_DEFAULT_SORTING_AND_FILTERING);
    }

    componentDidUpdate(prevProps) {
        if (!this.state.hasError) {
            this.setBreadcrumbs();
        }

        if (this.props.selectedArticle?.article?.id) {
            const { selectedArticle: prevSelectedArticle } = prevProps || {};
            const { selectedArticle } = this.props;
            if (prevSelectedArticle?.article?.id !== selectedArticle.article.id) this.updatePath();
        }
    }

    updatePath() {
        if (!this.props.selectedArticle) return;

        const { router, params, selectedArticle } = this.props;
        const { query } = params;
        const { article } = selectedArticle;

        let pathname = `/ubo-document/${article.id}/${encodeURIComponent(query)}/${article.isBranch ? 1 : 0
            }/${encodeURIComponent(article.title)}`;

        router.replace({ pathname });
    }

    setBreadcrumbs() {
        let articleType = '';
        let selectedArticle = this.props.selectedArticle;

        if (selectedArticle.article) {
            articleType = selectedArticle.article.articleType || utils.getUrlQueryParam('category');
        }

        let breadcrumbs = new BreadCrumbsModel.Builder(BREADCRUMBS.DOCUMENT)
            .setProperty('category', this.props.searchParams.category)
            .setProperty('articleType', articleType)
            .setProperty('searchQuery', utils.sanitizeSearchStringInput(this.props.searchParams.query))
            .setProperty('searchQueryType', this.props.searchParams.searchType)
            .setProperty('isSnapshotVisible', this.props.isSnapshotVisible)
            .setProperty('prevPath', this.props.previousRoute)
            .setProperty('launchedFrom', this.props.searchState.launchedFrom)
            .build().breadcrumbs;
        this.props.updateBreadcrumbs(breadcrumbs);
    }

    closePopupModal = () => {
        this.setState({
            popupModalVisible: false,
        });
    };

    handleErrorMessageRedirect() {
        hashHistory.push('/start');
    }

    showPopup = (event: SyntheticEvent<HTMLButtonElement>) => {
        let popupType = event.currentTarget.value;
        let { query, searchType } = this.props.searchParams;
        let { article } = this.props.selectedArticle;
        const researchSummary = !this.props.useNewResearchSummary
            ? this.props.articleNavigation.params.researchSummary
            : undefined;
        const categoryOrder = this.props.useNewResearchSummary ? getCategoryOrder(this.props.contentTypes) : undefined;
        const searchQueryFromUrl = this.props.routeParams.query;
        const { emailAddress } = this.props;

        let filteredArticle = Object.assign({}, article);
        filteredArticle = {
            ...filteredArticle,
            persistedNodes: this.state.persistedNodes,
        };
        delete filteredArticle.nodes;
        delete filteredArticle.tableData;
        delete filteredArticle.links;

        if (query) {
            let articleType = article.articleType || utils.getUrlQueryParam('category');
            let currentDate = new Date();
            let fileNameExtension = query + '_';
            let fileName =
                DOWNLOAD_DEFAULT_FILENAME +
                utils.formatReportFileName(fileNameExtension) +
                utils.formatFileName(categoryUtils.getCategoryName(articleType)) +
                '_' +
                utils.formatReportDateWithtTimezoneOffset(currentDate.getTime());
            let categoryConfig = withContentSourceFilter().extendConfig(articleType);
            let postFilters = this.props.searchResults[articleType].postFilters;
            let totalArticlesCount = this.props.searchResults[articleType].count;

            let popupModel = new PopupModel.Builder(popupType)
                .setPopupProperty('isVisible', true)
                .setPopupProperty('fileName', fileName)
                .setPopupProperty('emailAddress', emailAddress)
                .setPopupProperty('articles', [filteredArticle])
                .setPopupProperty('researchSummary', researchSummary)
                .setPopupProperty('categoryOrder', categoryOrder)
                .setPopupProperty('searchQuery', query)
                .setPopupProperty('searchQueryType', searchType)
                .setPopupProperty('totalArticlesCount', totalArticlesCount)
                .setPopupProperty('postFilters', postFilters)
                .setPopupProperty('articleType', categoryConfig ? categoryConfig.category : articleType);

            popupModel.build();
            this.props.setPopupModel(popupModel);
        } else {
            let currentDate = new Date();
            let fileNameExtension = searchQueryFromUrl + '_';
            let fileName =
                DOWNLOAD_DEFAULT_FILENAME +
                utils.formatReportFileName(fileNameExtension) +
                utils.formatFileName(categoryUtils.getCategoryName(UBO_MAIN_CATEGORY)) +
                '_';
            utils.formatReportDateWithtTimezoneOffset(currentDate.getTime());

            let popupModel = new PopupModel.Builder(popupType)
                .setPopupProperty('isVisible', true)
                .setPopupProperty('fileName', fileName)
                .setPopupProperty('emailAddress', emailAddress)
                .setPopupProperty('articles', [filteredArticle])
                .setPopupProperty('searchQuery', searchQueryFromUrl)
                .setPopupProperty('searchQueryType', searchType)
                .setPopupProperty('articleType', UBO_MAIN_CATEGORY);

            popupModel.build();
            this.props.setPopupModel(popupModel);
        }
    };

    render(): React.Node {
        const isTradeUp =
            this.props.article &&
            this.props.article.inquiryDetails &&
            this.props.article.inquiryDetails.tradeUp === 'true';
        const organizationName = this.props.article.organization && this.props.article.organization.primaryName;

        const articleTitle = !isTradeUp ? this.props.article.name || organizationName : organizationName;

        const article = {
            ...this.props.article,
            persistedNodes: this.state.persistedNodes,
            title: articleTitle,
        };
        const query = !!this.props.searchParams.query;
        const hasLoadingError = this.props.articleNavigation.hasError;
        const hasUboError = !!this.props.article.errorCode;

        return (
            <div id="ubo-document">
                {hasLoadingError ? (
                    <ErrorMessage
                        header="SingleDocumentView.ErrorMessage.documentNotFound.header"
                        body="UboSingleDocumentView.ErrorMessage.documentNotFound.body"
                        action={this.handleErrorMessageRedirect}
                    />
                ) : (
                    <div className="single-article-view">
                        {this.props.popupModel.isVisible && <PopupModal closeButtonHandler={this.closePopupModal} />}
                        <SingleArticleHeader
                            article={article}
                            showPopup={this.showPopup}
                            query={query}
                            title={articleTitle}
                            currentArticleID={this.props.article && this.props.article.id}
                        />
                        <div
                            className={`single-article-result__general ${hasUboError ? 'with-error' : ''}`}
                            ref={(ref) => (this.singleArticleContent = ref)}
                        >
                            {this.props.article.errorCode ? (
                                <UboError errorCode={this.props.article.errorCode} isDarkMode={this.props.isDarkMode} />
                            ) : (
                                <React.Fragment>
                                    <div className="single-article-content ubo-document-body">
                                        <UboDocumentView
                                            uboArticle={this.props.article}
                                            articleTitle={articleTitle}
                                            isTradeUp={isTradeUp}
                                            duns={this.props.duns}
                                            persistedNodes={this.state.persistedNodes}
                                            onPersistNodes={this.handlePersistNodes}
                                            updateSortingAndFiltering={this.props.updateSortingAndFiltering}
                                            filtering={this.props.filtering}
                                        />
                                    </div>
                                    <SingleArticleSidebar
                                        article={this.props.article}
                                        articleType={UBO_MAIN_CATEGORY}
                                    />
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

const UboError = ({ errorCode, isDarkMode }) => (
    <div className={`ubo-error ${isDarkMode ? 'dark-mode' : ''}`}>
        <div className='ubo-error__icon'>
            {isDarkMode ? <BannerWarningIconDark /> : <BannerWarningIcon />}
        </div>
        <div className='ubo-error__message'>
            <FormattedMessage id={UBO_ERRORS[errorCode]['firstPart']} />
            &nbsp;
            {UBO_ERRORS[errorCode]['secondPart'] && <FormattedMessage id={UBO_ERRORS[errorCode]['secondPart']} />}
        </div>
    </div>
);

const mapStateToProps = function (state, ownProps) {
    return {
        filtering: state.ubo.docViewDeliveryInfo.filtering,
        searchResults: state.searchResults,
        articleNavigation: state.articleNavigation,
        article: state.articlesManager.selectedArticle.article || {},
        selectedArticle: state.articlesManager.selectedArticle,
        isLoading: state.articlesManager.selectedArticle && state.articlesManager.selectedArticle.length === 0,
        searchParams: state.searchParams,
        previousRoute: state.breadcrumbs.prevPath,
        prevQuery: state.breadcrumbs.query,
        selectedReport: state.reportBuilder.selectedReport,
        popupModel: state.popupModel,
        isSnapshotVisible: state.user.preferences.generalSettings.showSnapshot,
        billingId: state.investigation.billingId,
        emailAddress: state.user.email,
        duns: ownProps.duns || (ownProps.router && ownProps.router.params && ownProps.router.params.uboNumber),
        contentTypes: state.user.preferences.generalSettings.contentTypes,
        useNewResearchSummary: state.user.useNewResearchSummary,
        searchState: state.searchState,
        isDarkMode: state.user.preferences.generalSettings.isDarkMode
    };
};

const mapDispatchToProps = function (dispatch) {
    return bindActionCreators(
        {
            updateBreadcrumbs: breadcrumbsActions.updateBreadcrumbs,
            initViewArticle: articleNavigationActions.initViewArticle,
            updateSortingAndFiltering: uboActions.updateSortingAndFiltering,
            setPopupModel: popupModelActions.setPopupModel,
        },
        dispatch
    );
};

export default (compose(connect(mapStateToProps, mapDispatchToProps), withRouter)(UboDocument): React.AbstractComponent <Props,State>);
