export const ACTIONS = {
    metricSearchStarted: 'METRIC_SEARCH_STARTED',
    metricSearchEnd: 'METRIC_SEARCH_END',
    metricGather: 'METRIC_GATHER',
};

// Corresponding to the BE metric naming
export const CONTENT_TYPES_BE = {
    negativeNews: 'NEGATIVE_NEWS',
    news: 'NEWS',
    customNews: 'CUSTOM_NEWS',
    directors: 'COMPANY_SOURCES',
    sanctions: 'SANCTIONS_WATCHLISTS',
    peps: 'PEP',
    biographical: 'BIO_REF_DIRECTORIES',
    lawSources: 'LEGAL_SOURCES',
    financialReports: 'CREDIT_REPORTS',
    ubo: 'UBO',
    esgRatings: 'ESG_RATINGS',
};

export const SEARCH_TYPES = {
    company: 'COMPANY',
    people: 'PERSON',
};

export const ADDITIONAL_MESSAGES_BE = {
    LOAD: '_LOAD',
    COMPLETE_LOAD: '_COMPLETE_LOAD',
    ERROR: '_ERROR',
    SEARCH: '_SEARCH',
};

export const CATEGORY_SEARCH_STATE = {
    completeLoad: 'COMPLETE_LOAD',
    searchError: 'SEARCH_ERROR',
};

export const BAD_REQUEST = 400;

export const INTERNAL_USER = 'internal';
