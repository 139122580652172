// @flow
import * as React from 'react';
import { injectIntl } from 'react-intl';
import TruncatedTitle from '../TruncatedElements/TruncatedTitle';
import type { ReportTitleTooltipData } from '@pages/ReportBuilder/components/ReportsList/ReportTypes/sharedComponents/Header/components/ReportEditableTitle';
import { FILENAME_CHARACTERS_LIMIT } from '@constants';
import formatRichMessage from '@utils/formatRichMessage';

type Props = {
    className: string,
    index: number,
    qaId: string,
    saveText: (title: string) => void,
    title: string,
    validateTextInput: (title: string) => Object,
    toolTipData: string | ReportTitleTooltipData,
    countMessage: string,
};

type InjectedProps = {
    +intl: Object,
};

type State = {
    lastValidText: string,
    text: string,
    textWasChanged: boolean,
    editText: boolean,
    validationState: Object | null,
};

class EditableText extends React.Component<Props & InjectedProps, State> {
    state = {
        lastValidText: '',
        text: '',
        textWasChanged: false,
        editText: false,
        validationState: null,
    };

    textInput: { current: null | HTMLInputElement };
    truncatedTextRef: { current: null };

    constructor(props: Props & InjectedProps) {
        super(props);
        this.textInput = React.createRef();
        this.truncatedTextRef = React.createRef();
    }

    UNSAFE_componentWillMount(): void {
        this.setState({
            lastValidText: this.props.title,
            text: this.props.title,
            editText: this.state.editText,
        });
    }

    handleShowInput = (e: SyntheticEvent<HTMLDivElement>): void => {
        e.stopPropagation();

        if (this.truncatedTextRef.current) this.truncatedTextRef.current.hideCurrentTooltip();

        this.setState(
            {
                editText: true,
            },
            () => {
                if (this.textInput.current) this.textInput.current.focus();
            }
        );
    };

    handleTextChange = (event: SyntheticInputEvent<HTMLInputElement>): void => {
        const inputText: string = event.target.value;
        const validationResult: Object = this.props.validateTextInput(inputText);

        this.setState({
            text: inputText.substring(0, FILENAME_CHARACTERS_LIMIT),
            textWasChanged: validationResult.isValid,
            validationState: validationResult,
        });
    };

    handleKeyPress = (e: SyntheticInputEvent<HTMLInputElement> & SyntheticKeyboardEvent<*>): void => {
        if (e.key === 'Enter' || e.keyCode === 13) {
            const validatedText: string =
                this.state.validationState && !this.state.validationState.isValid
                    ? this.state.lastValidText
                    : e.target.value;
            this.props.saveText(validatedText);
            this.setState({
                lastValidText: validatedText,
                text: validatedText,
            });
        }
    };

    handleSaveText = (event: SyntheticInputEvent<HTMLInputElement>): void => {
        const validatedText: string =
            this.state.validationState && !this.state.validationState.isValid
                ? this.state.lastValidText
                : event.target.value;

        if (this.state.textWasChanged) {
            this.props.saveText(validatedText);
        }
        this.setState({
            lastValidText: validatedText,
            text: validatedText,
            editText: false,
            textWasChanged: false,
            validationState: null,
        });
    };

    handleClick = (e: SyntheticEvent<HTMLButtonElement>): void => {
        e.stopPropagation();
    };

    render(): React.Node {
        const { editText, text, validationState } = this.state;
        const { title, className, qaId, toolTipData, intl, countMessage } = this.props;

        const formattedTooltipData =
            typeof toolTipData !== 'string' && toolTipData.message
                ? formatRichMessage({ id: toolTipData.message }, intl, { ...toolTipData.terms })
                : toolTipData;
        const remainingCharactersCount = FILENAME_CHARACTERS_LIMIT - text.length;
        const remainingCharactersMessage = formatRichMessage(
            { id: 'DownloadReportFileNameValidation.RemainingCharacters' },
            intl,
            { count: remainingCharactersCount }
        );

        return editText ? (
            <div className={'editable-text'}>
                <span className={'characters-count remaining'}>{remainingCharactersMessage}</span>

                <input
                    value={text}
                    className={`text-field-standard-sm ${
                        !!validationState && !validationState.isValid ? 'text-field-error' : ''
                    }`}
                    onChange={this.handleTextChange}
                    onClick={this.handleClick}
                    onKeyPress={this.handleKeyPress}
                    onBlur={this.handleSaveText}
                    ref={this.textInput}
                />
                {!!validationState && !validationState.isValid ? (
                    <span className={'characters-count max-limit'}> {validationState.errorMessage} </span>
                ) : (
                    <span className="count">{countMessage}</span>
                )}
            </div>
        ) : (
            <div id={qaId} className={className} onClick={this.handleShowInput}>
                <div className="title">
                    <TruncatedTitle
                        id={'truncated-title'}
                        text={title}
                        tooltipData={formattedTooltipData}
                        truncatedTextRef={this.truncatedTextRef}
                    />
                    <span className="la-Edit" />
                </div>
                <span className="count" data-testid={'documentsCount'}>
                    {countMessage}
                </span>
            </div>
        );
    }
}

export default (injectIntl(EditableText): React.AbstractComponent<Props>);
