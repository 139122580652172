import React from 'react';
import ClearAll from '../Buttons/ClearAll';

const NotificationsContainerLayout = ({children, clearAll}) => {
    return (
        <div
            className='notifications-container-layout'
            data-testid='notifications-container-layout'
        >
            <div className='notifications-header'>
                <ClearAll onClick={clearAll} />
            </div>
            <div className="notifications-body">
                {children}
            </div>
        </div>
    )
};

export default NotificationsContainerLayout;