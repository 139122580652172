import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { debounce } from 'lodash';
import formatRichMessage from '@utils/formatRichMessage';
import { useIntl } from 'react-intl';
import { MULTIPLE_ENTITIES_NONE_SELECTED_VIEW, MULTIPLES_ENTITIES_CREATE_NEW_VIEW, VIEWNAME_CHARACTERS_LIMIT } from '@constants';
import CustomDropdown from '@reusable/CustomDropdown/CustomDropdown';


const ModalUploadEntities = ({ views, handleChangeView, createNewView, errorMessage = '' }) => {
    const uploadPollingActive = useSelector((state) => state.entityViewInformation.uploadPollingActive);
    const [inputField, setInputField] = useState('');
    const [showCreateNewViewInput, setShowCreateNewViewInput] = useState(false);
    const intl = useIntl();

    useEffect(() => {
        if (showCreateNewViewInput) {
            const handleClickOutside = (event) => {
                if (event.target.className !== "input-container" && inputField === '') {
                    setShowCreateNewViewInput(false);
                }
            };
            document.addEventListener("click", handleClickOutside);
            return () => {
                document.removeEventListener("click", handleClickOutside);
            };
        } else {
            setInputField('');
        }
    }, [showCreateNewViewInput, inputField]);

    const remainingCharactersCount = VIEWNAME_CHARACTERS_LIMIT - inputField.length;
    const remainingCharactersMessage = formatRichMessage(
            { id: 'BatchScreening.page.createViews.ViewNameValidation.RemainingCharacters' },
            intl,
            { count: remainingCharactersCount }
        );

    const defaultView = {
        name: formatRichMessage({ id: 'BatchUpload.uploadSection.defaultView' }, intl),
        id: MULTIPLE_ENTITIES_NONE_SELECTED_VIEW,
        isShareable: true,
    };
    const createNewViewOption = {
        name: formatRichMessage({ id: 'BatchUpload.uploadSection.createNewView' }, intl),
        id: MULTIPLES_ENTITIES_CREATE_NEW_VIEW,
        isShareable: false,
        newView: true
    }
    
    const ownViews = views?.filter((each) => each.isShareable === true);

    let viewDropdownOptions = [createNewViewOption, defaultView];

    const updateValueInTheParentState = debounce((value) => {
       createNewView({
            ...createNewViewOption,
            name: value,
       });
      }, 200);

    if (ownViews) {
        viewDropdownOptions = [...viewDropdownOptions, ...ownViews];
    }

    const handleChangeOption = (view) => {
        handleChangeView(view);
        if(view.id !== MULTIPLES_ENTITIES_CREATE_NEW_VIEW){
            setShowCreateNewViewInput(false);
            setInputField('')
        }
    }

    return (
        <div className='modal-upload-entities'>
            <CustomDropdown
                label="BatchUpload.uploadSection.selectView"
                defaultValue={defaultView}
                options={viewDropdownOptions}
                changeOption={handleChangeOption}
                setShowCreateNewViewInput={setShowCreateNewViewInput}
                disabled={uploadPollingActive}
                showCreateNewViewInput={showCreateNewViewInput}
                inputField={inputField}
            />
            {showCreateNewViewInput && (
                <div className='create-new-view'>
                    <span
                        className="characters-count remaining"
                        data-testid="characters-count-remaining"
                    >
                        {remainingCharactersMessage}
                    </span>
                    <input
                        maxLength={VIEWNAME_CHARACTERS_LIMIT}
                        data-testid="create-new-view-input"
                        className="input-container"
                        value={inputField}
                        disabled={uploadPollingActive}
                        onChange={(e) => {
                            setInputField(e.target.value);
                            updateValueInTheParentState && updateValueInTheParentState(e.target.value);
                        }}
                    />
                    <span
                        className="characters-count max-limit"
                        data-testid="characters-count-max-limit"
                    >
                    {errorMessage}
                </span>
                </div>
            )}
        </div>
    )

};

export default ModalUploadEntities;