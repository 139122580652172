import { UPDATE_HISTORY_CURRENT_CATEGORY } from '@Main/Main.reducers';

function historyCategory(state = null, action) {
    switch (action.type) {
        case UPDATE_HISTORY_CURRENT_CATEGORY: {
            return action.payload;
        }

        default:
            return state;
    }
}

export default historyCategory;
