import { put, fork, select, call, all } from 'redux-saga/effects';
import uboActions from '@pages/StartPage/redux/Ubo.actions';
import * as selectors from '../selectors/uboSelectors';
import * as requests from '../requests/uboRequests';
import * as helpers from '@sagas/helpers/uboHelper';
import { requestWithRetry, updateFuzzyNames } from '../helpers/mainHelper';
import * as mainSelectors from '../selectors/main';
import postFilterConfigActions from '@pages/MainSearch/redux/PostFilterConfiguration.actions';
import { CATEGORIES, CATEGORY_TYPE_UBO, CATEGORY_TYPE_UBO_CHILD, CATEGORY_NAMES, UBO_USE_MEMO } from '@constants';
import searchResultsActions from '@pages/MainSearch/redux/SearchResults.actions';
import searchParamsActions from '@pages/MainSearch/redux/SearchParams.actions';
import { ACTIONS } from '../constants/metricConstants';
import { shouldGatherMetrics } from '../helpers/uboHelper';

/**
 * Search worker, gets the suggestions and dispatches the search fill suggestions action
 * Uses memo if enabled to limit the calls, filters selected
 * @param query
 */
export function* doSuggestionSearch({ searchTerm }, useMemo = UBO_USE_MEMO) {
    // get the memo data
    const uboSelectedDunses = yield select(selectors.theUboSelectedDunses);
    const memoSearchTerm = helpers.processMemoTerm(searchTerm);
    const memoData = yield select(selectors.theMemoFor(memoSearchTerm));

    let suggestionList;
    // check for memo data
    if (memoData && useMemo) {
        suggestionList = memoData;
    } else {
        // if not in memo or memo not enabled, run the search
        const { data } = yield call(requestWithRetry, requests.getUboSearchRequestParams({ searchTerm }));
        const mappedData = data
            .map(helpers.suggestionItemApiMap)
            .filter((item) => uboSelectedDunses.indexOf(item.duns) === -1);
        // store the memo if enabled
        if (useMemo) {
            yield put(uboActions.addMemo({ searchTerm: memoSearchTerm, data: mappedData }));
        }
        suggestionList = mappedData;
    }
    // filter already selected suggestions
    suggestionList = suggestionList.filter((item) => uboSelectedDunses.indexOf(item.duns) === -1);
    yield put(uboActions.searchSuccess(suggestionList));
}

export function* markUboCategoryLoad(error = '') {
    const historyCategory = yield select(selectors.historyCategoryName);

    // Only if the search is a regular one
    if (shouldGatherMetrics(historyCategory)) {
        let payload = {
            timeStamp: new Date().getTime(),
        };

        if (error) {
            payload.error = error;
        }

        // Mark category end of load
        yield put({
            type: ACTIONS.metricSearchEnd,
            payload: {
                ubo: payload,
            },
        });
    }
}

export function* countDunsSearch(company) {
    const { duns, title } = company;
    const categoryName = helpers.uboCategoryNameFormat({ company });
    try {
        const postFilters = yield select(selectors.theUboCategoryPostFilters, categoryName);
        // add subcategory and update it with loaded false state
        yield call(addUboSubcategory, categoryName, { duns, title });
        yield call(updateUboSubcategory, categoryName, { loaded: false, postFilters });

        // pick up investigationId and costCode
        const billingId = yield select(selectors.billingId);
        const costCode = yield select(selectors.costCode);

        // request the count
        const response = yield call(
            requestWithRetry,
            requests.getUboCountSearchRequestParams({
                dunsList: [duns],
                postFilters,
                billingSearchEvent: {
                    billingId: billingId,
                    costCode: costCode,
                },
            })
        );
        const count = response && response.data && response.data[0].companies_with_duns;

        // udpate subcategory with count and loaded state true
        yield call(updateUboSubcategory, categoryName, { loaded: true, count });

        // Mark category end of load
        yield call(markUboCategoryLoad);

        //send the count back to the main count flow
        return count;
    } catch (e) {
        // if something went wrong, update the subcategory and send 0 as count back to the count flow
        console.error(e);
        yield call(updateUboSubcategory, categoryName, { loaded: true, count: 0 });

        // Mark category end of load, use the error
        yield call(markUboCategoryLoad, e);

        return 0;
    }
}

export function* countTermSearch(term) {
    const categoryName = helpers.uboCategoryNameFormat({ term });
    try {
        const postFilters = yield select(selectors.theUboCategoryPostFilters, categoryName);
        // add subcategory and update it with loaded false state
        yield call(addUboSubcategory, categoryName, { term, title: term });
        yield call(updateUboSubcategory, categoryName, { loaded: false, postFilters });

        //pick up billingId and costCode
        const billingId = yield select(selectors.billingId);
        const costCode = yield select(selectors.costCode);

        // request the count
        const response = yield call(
            requestWithRetry,
            requests.getUboCountSearchRequestParams({
                searchTerms: [term],
                postFilters,
                billingSearchEvent: {
                    billingId: billingId,
                    costCode: costCode,
                },
            })
        );
        const count = response && response.data && response.data[0][term] && response.data[0][term].count;

        // update subcategory with count and loaded state true
        yield call(updateUboSubcategory, categoryName, { loaded: true, count });

        // Mark category end of load
        yield call(markUboCategoryLoad);

        //send the count back to the main count flow
        return count;
    } catch (e) {
        // if something went wrong, update the subcategory and send 0 as count back to the count flow
        console.error(e);
        yield call(updateUboSubcategory, categoryName, { loaded: true, count: 0 });

        // Mark category end of load, use the error
        yield call(markUboCategoryLoad, e);

        return 0;
    }
}

export function* selectCompany(company, query, position) {
    const { title } = company;
    // get the new query with the term replaced by the company
    const newQuery = helpers.replaceTermAtPosition(query, position, title);

    // clean out the duns again, to make sure the user didn't replace an already selected item
    yield call(clearMissingDuns, newQuery);

    // update the query with the company replaced
    yield put(searchParamsActions.updateQueryString(helpers.cleanAndTrim(newQuery)));

    // force update the fuzzynames with the company in it; non-blocking operation
    yield fork(updateFuzzyNames, newQuery);
}

export function* addUboSubcategory(categoryName, { duns, term, title }) {
    if (!CATEGORIES.ubo.children) {
        CATEGORIES.ubo.children = {};
    }
    yield put(searchResultsActions.uboAddCategory({ categoryName, duns, term, title }));
    CATEGORIES.ubo.children = {
        ...CATEGORIES.ubo.children,
        [categoryName]: { name: categoryName, key: categoryName, duns, term, title },
    };
}

export function* updateUboSubcategory(categoryName, categorySettings) {
    yield put(
        searchResultsActions.updateCategory({
            name: categoryName,
            type: CATEGORY_TYPE_UBO_CHILD,
            ...categorySettings,
        })
    );
}

export function* updateMainCategory(categorySettings) {
    yield put(
        searchResultsActions.updateCategory({
            name: CATEGORY_NAMES.DNB,
            type: CATEGORY_TYPE_UBO,
            ...categorySettings,
        })
    );
}

export function clearSubcategories() {
    delete CATEGORIES.ubo.children;
}

export function* clearMissingDuns(query) {
    // get the selected ubo
    const selected = yield select(selectors.theUboSelected);

    // get the missing companies
    const missingCompanies = helpers.getMissingCompaniesInQuery(query, selected);

    // dispatch remove action for each missing company
    yield all(
        missingCompanies.map((company) => {
            const index = selected.indexOf(company);
            if (index > -1) {
                return put(uboActions.removeItem({ index }));
            }
        })
    );
}

export function* setPostFilters(postFilters) {
    // update ubo repository with the postfilter
    yield put(uboActions.setPostFilters(postFilters));
    // update the app postFilterConfig
    const updatePostFilterConfigActions = Object.keys(postFilters).map((category) =>
        put(postFilterConfigActions.updateCategoryPostfilters(category, postFilters[category]))
    );
    yield all(updatePostFilterConfigActions);
}

export function* syncPostFilterConfig() {
    const uboPostFilters = yield select(selectors.theUboPostFilters);
    const uboPostFilterConfig = yield select(mainSelectors.thePostfilterConfiguration(CATEGORY_NAMES.DNB));

    for (const categoryName in uboPostFilters) {
        const categoryPostFilters = {};
        for (const postFilterName in uboPostFilterConfig) {
            const categoryType = uboPostFilters[categoryName].uboCategoryType,
                postFilterSearchField = uboPostFilterConfig[postFilterName].searchFieldName;
            if (categoryType && helpers.isPostFilterAllowed(categoryType, postFilterSearchField)) {
                const values =
                    uboPostFilters[categoryName] && uboPostFilters[categoryName][postFilterSearchField]
                        ? [uboPostFilters[categoryName][postFilterSearchField]]
                        : [''];
                categoryPostFilters[postFilterName] = {
                    ...uboPostFilterConfig[postFilterName],
                    values,
                };
            }
        }
        yield put(postFilterConfigActions.updateCategoryPostfilters(categoryName, categoryPostFilters));
    }
}
