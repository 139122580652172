import { USER_ROLE_CHANGED, USER_ROLES } from '@constants';
import { permissions, userHas } from '@permissions';

export const hideAdminPermissionBanner = () => {
    const adminPermissionChanged = {
        showAdminPermissionChangedBanner: false,
        prevRole: userHas(permissions.admin.edit) ? USER_ROLES.ADMINISTRATOR : USER_ROLES.USER,
    };
    localStorage.setItem(USER_ROLE_CHANGED, JSON.stringify(adminPermissionChanged));
};
