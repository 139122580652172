import React from 'react';
import { Link } from 'react-router';
import MainSearchApi from '@MainSearch/MainSearch.api';
import ElasticDocumentContent from './ElasticDocumentContent';
import utils from '@utils/utilities';
import { FormattedMessage } from 'react-intl';
import { Loader } from 'semantic-ui-react';

class DuplicateArticlesList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showDuplicates: this.props.showDuplicatesState[this.props.heroArticle.duplicateGroupId] || false,
            showDuplicateSpinner: false,
            articlesList: {},
        };

        this.checkArticle = this.checkArticle.bind(this);
        this.isArticleSelected = this.isArticleSelected.bind(this);
        this.toggleDuplicatesVisibility = this.toggleDuplicatesVisibility.bind(this);
        this.getDuplicates = this.getDuplicates.bind(this);
    }

    checkArticle(article, event) {
        this.props.setSelectedDuplicates([article], this.props.articleType, event.target.checked);
        this.props.setSelectedArticles([article], this.props.articleType, event.target.checked);
    }

    isArticleSelected(article) {
        let selectedArticles = this.props.selectedArticles[this.props.articleType] || [];
        let selectedArticle = selectedArticles.find((item) => {
            return item.id === article.id;
        });

        return selectedArticle !== undefined;
    }

    getDuplicates(duplicateGroupId) {
        let duplicatesIds = [];
        let { articleType, searchType, query, prefilterQuery } = this.props;

        return MainSearchApi.getElasticDocumentDuplicates(
            searchType,
            query,
            articleType,
            prefilterQuery,
            duplicateGroupId
        ).then((duplicates) => {
            if (duplicates.entity.length > 0) {
                duplicates.entity.forEach((article) => {
                    if (article.id !== this.props.heroArticle.id) {
                        duplicatesIds.push(article.id);
                    }
                });
            }
            return {
                duplicatesIds,
                articlesList: duplicates.entity,
            };
        });
    }

    toggleDuplicatesVisibility(duplicateGroupId) {
        let duplicatesIds = [];
        let duplicates = this.props.duplicateArticles[duplicateGroupId];
        this.setState(() => {
            return {
                showDuplicateSpinner: true,
            };
        });

        if (!this.state.showDuplicates && !duplicates) {
            this.getDuplicates(duplicateGroupId)
                .then((data) => {
                    this.setState(
                        (state) => {
                            return {
                                showDuplicates: !state.showDuplicates,
                                showDuplicateSpinner: false,
                            };
                        },
                        () => {
                            this.props.setVisibleDuplicates(data.duplicatesIds, this.state.showDuplicates);
                            this.props.setDuplicatesSectionState(duplicateGroupId, this.state.showDuplicates);
                            this.props.setDuplicatesForArticle(duplicateGroupId, data.articlesList);
                        }
                    );
                })
                .catch(() => {
                    utils.showNotificationsMessage({
                        messageText: 'General_CoreFunctionality_Error_error.retrieveDuplicatesError',
                        messageType: 'system-error',
                    });
                });
        } else {
            duplicates.forEach((article) => {
                if (article.id !== this.props.heroArticle.id) {
                    duplicatesIds.push(article.id);
                }
            });

            this.setState(
                (state) => {
                    return {
                        showDuplicates: !state.showDuplicates,
                        showDuplicateSpinner: false,
                    };
                },
                () => {
                    this.props.setVisibleDuplicates(duplicatesIds, this.state.showDuplicates);
                    this.props.setDuplicatesSectionState(duplicateGroupId, this.state.showDuplicates);
                }
            );
        }
    }

    render() {
        let { duplicateArticles, heroArticle, selectedArticlesList } = this.props;
        let { duplicateSize, duplicateGroupId } = heroArticle;

        let duplicates = duplicateArticles[duplicateGroupId] || [];

        let articlesList = duplicates.filter((duplicate) => {
            return duplicate.id !== heroArticle.id;
        });

        return (
            duplicateSize > 0 && (
                <li className="duplicate-articles-section notranslate">
                    <Link
                        className="duplicate-articles-section__show-documents"
                        data-testid={'show-documents'}
                        onClick={() => {
                            this.toggleDuplicatesVisibility(duplicateGroupId);
                        }}
                    >
                        <span className={this.state.showDuplicates ? 'la-CollapseTree' : 'la-ExpandTree'} />
                        <span className="duplicate-articles-section__documents-number">
                            <FormattedMessage
                                id={'DuplicateArticleList.duplicateArticleCount'}
                                values={{ duplicateSize }}
                            ></FormattedMessage>
                        </span>
                    </Link>
                    {this.state.showDuplicateSpinner ? <Loader size={'large'} inline="centered" active /> : null}
                    {this.state.showDuplicates && (
                        <ol className="duplicate-articles-list">
                            {articlesList.map((article, index) => {
                                const isArticleItemSelected = selectedArticlesList.some(
                                    (articleElement) => articleElement.id === article.id
                                );

                                return (
                                    <ElasticDocumentContent
                                        key={article.id}
                                        isArticleSelected={this.isArticleSelected}
                                        showSingleArticle={this.props.showSingleArticle}
                                        getArticleSnipetsForReport={this.props.getArticleSnipetsForReport}
                                        checkArticle={this.checkArticle}
                                        article={article}
                                        index={index}
                                        query={this.props.query}
                                        prefilterQuery={this.props.prefilterQuery}
                                        searchType={this.props.searchType}
                                        articleType={this.props.articleType}
                                        showIndex={false}
                                        handlePreviewArticle={this.props.handlePreviewArticle}
                                        singleSource={this.props.singleSource}
                                        proximity={this.props.proximity}
                                        fuzzyThreshold={this.props.fuzzyThreshold}
                                        newFuzzyEnabled={this.props.newFuzzyEnabled}
                                        isArticleItemSelected={isArticleItemSelected}
                                        language={this.props.language}
                                    />
                                );
                            })}
                        </ol>
                    )}
                </li>
            )
        );
    }
}

export default DuplicateArticlesList;
