import React, { Component } from 'react';
import EsgRatingsDonutChart from '@pages/EsgRatings/components/EsgRatingsDonutChart';
import EsgRatingsUtils from '@pages/EsgRatings/EsgRatingsUtils';
import { BREADCRUMBS_PREV_PATH, ESG_RATINGS_VISUALISATIONS, LAUNCHED_SEARCH_FROM, BOV_NODE_TYPES } from '@constants';
import costCodeUtils from '@utils/costCodeUtils';
import { hashHistory } from 'react-router';
import mainActions from 'scripts/pages/Main/Main.actions';
import breadcrumbsActions from '@reusable/Breadcrumbs/redux/Breadcrumbs.actions';
import searchParamsActions from 'scripts/pages/MainSearch/redux/SearchParams.actions';
import searchResultsActions from '@MainSearch/redux/SearchResults.actions';
import ArticleNavigationActions from '@pages/MainSearch/redux/ArticleNavigation.actions';

export class EsgRatingsCellTemplate extends Component {
    getCompatibleCell(uncertainCell) {
        const entity = uncertainCell.text;
        return { ...uncertainCell, entity };
    }

    update(cell, cellToMerge) {
        return this.getCompatibleCell({ ...cell, text: cellToMerge.text });
    }

    render(cell) {
        const { entity, isLoading } = cell;
        const { dispatch } = entity.extraProps;
        const rating = entity?.esgRatings?.overallRating;
        const ratingCategory = EsgRatingsUtils.getRatingRange(rating);

        const goToDocument = () => {
            const payload = {
                lni: entity?.esgRatings.lni,
                searchQuery: entity?.entityName,
                searchQueryType: BOV_NODE_TYPES.COMPANY,
                category: entity?.category,
                billingId: entity?.extraProps.investigation?.billingId,
                costCode: costCodeUtils.getCostCode(),
            };
            const prevPath = {
                pathname: BREADCRUMBS_PREV_PATH.SCREENING_PAGE,
                query: encodeURIComponent(payload.searchQuery),
            };

            dispatch(mainActions.searchLaunchedFrom(LAUNCHED_SEARCH_FROM.SCREENING_PAGE));
            dispatch(searchResultsActions.resetSingleCategoryResults(entity?.category));
            dispatch(breadcrumbsActions.updatePrevPath(prevPath));
            dispatch(searchParamsActions.updateQueryString(payload.searchQuery));
            dispatch(searchParamsActions.updateQueryType(payload.searchQueryType));
            dispatch(searchParamsActions.setCategory(payload.category));
            dispatch(mainActions.setCurrentEntity(entity));
            dispatch(
                ArticleNavigationActions.initArticleNavigation({
                    index: null,
                    list: [],
                    startPage: null,
                    pageSize: null,
                    totalCount: null,
                    params: {},
                    isShowMoreActive: false,
                })
            );

            const linkTo = `/document?query=${encodeURIComponent(payload.searchQuery)}&category=${
                payload.category
            }&type=${payload.searchQueryType}&id=${payload.lni}&costCode=${payload.costCode}`;

            hashHistory.push(linkTo);
        };

        if (isLoading) {
            return <div className="skeleton-loading" />;
        }

        return (
            <div
                className={`esg-ratings-cell ${entity?.displayName?.toLowerCase()}`}
                data-track="esg_ratings-category-container"
            >
                {entity?.esgRatings?.overallRating && (
                    <EsgRatingsDonutChart
                        size={ESG_RATINGS_VISUALISATIONS.MULTIPLE_ENTITIES.SIZE}
                        fontSize={ESG_RATINGS_VISUALISATIONS.MULTIPLE_ENTITIES.FONT_SIZE}
                        titleColor={ESG_RATINGS_VISUALISATIONS.MULTIPLE_ENTITIES.TITLE_COLOR}
                        rating={rating}
                        ratingCategory={ratingCategory}
                        onChartClick={goToDocument}
                    />
                )}
            </div>
        );
    }
}

export default EsgRatingsCellTemplate;
