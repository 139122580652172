import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';

export default class PillList extends PureComponent {
    static propTypes = {
        items: PropTypes.array.isRequired,
        onRemoveItem: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
        extra: PropTypes.object,
        className: PropTypes.string,
    };

    static defaultProps = {
        showAll: true,
        onRemoveItem: null,
        className: '',
    };

    constructor(props) {
        super(props);

        this.state = { showAll: false };
        this.pillItems = [];

        this.toggleShowAll = this.toggleShowAll.bind(this);
        this.removeItem = this.removeItem.bind(this);
        this.addItem = this.addItem.bind(this);
        this.setPillsSettings = this.setPillsSettings.bind(this);
    }

    componentDidMount() {
        this.setPillsSettings(this.state);
    }

    componentDidUpdate(prevProps, prevState) {
        this.setPillsSettings(prevState);
    }

    setPillsSettings = (state) => {
        if (!state.visibleItemCount && this.props.pillListContainerWidth) {
            let pillsTotalWidth = 0;
            let visibleItemCount = 0;

            let pillListWidth = this.props.pillListContainerWidth;
            let pillListContainerWidth = this.props.pillListContainerWidth;
            let headerWidth = this.props.pillListHeaderWidth;

            if (headerWidth) {
                pillListWidth -= headerWidth;
                pillListContainerWidth -= headerWidth;
            }

            if (this.toggle) {
                pillListWidth -= this.toggle.clientWidth;
            }

            if (this.toggleSelectionRef) {
                pillListWidth -= this.toggleSelectionRef.clientWidth;
            }

            if (this.pillItems) {
                this.pillItems.forEach((item) => {
                    if (item) {
                        pillsTotalWidth += item.clientWidth;
                        if (pillsTotalWidth < pillListWidth) {
                            visibleItemCount++;
                        }
                    }
                });
            }

            this.setState({
                visibleItemCount,
                pillListWidth: pillListContainerWidth - 10,
            });
        }
    };

    toggleShowAll = () => {
        this.setState({ showAll: !this.state.showAll });
    };

    removeItem = (item, index) => {
        if (item.onRemove) {
            // if item has custom remove handler, run it. If that returns true, also run the list item remove handler
            if (item.onRemove() === true && this.props.onRemoveItem) {
                this.props.onRemoveItem(item, index);
            }
        } else if (this.props.onRemoveItem) {
            // No custom remove handler for this item, call the list item remove handler if any
            this.props.onRemoveItem(item, index);
        }
    };

    addItem = (item, index) => {
        if (item.onAdd) {
            // if item has custom remove handler, run it. If that returns true, also run the list item remove handler
            if (item.onAdd() === true && this.props.onAddItem) {
                this.props.onAddItem(item, index);
            }
        } else if (this.props.onAddItem) {
            // No custom remove handler for this item, call the list item remove handler if any
            this.props.onAddItem(item, index);
        }
    };

    renderShowMore = () => (
        <div onClick={this.toggleShowAll} className={'toggle'} ref={(ref) => (this.toggle = ref)}>
            {this.state.showAll ? (
                <FormattedMessage id={'General_CoreFunctionality_UIText_general.seeLess'} />
            ) : (
                <FormattedMessage
                    id={'General_CoreFunctionality_UIText_general.more'}
                    values={{ count: this.props.items.length - this.state.visibleItemCount }}
                />
            )}
        </div>
    );

    selectAll = (items, isSuggestedNames = false) => {
        const message = !isSuggestedNames ? (
            <FormattedMessage id={'StartPage_CoreFunctionality_UIText_fuzzy.addAll'} />
        ) : (
            <FormattedMessage id={'StartPage_SuggestedNames.selectAll'} values={{ number: items.length }} />
        );

        const showSelectAll = isSuggestedNames ? items.length > 1 : true;

        return (
            showSelectAll && (
                <button
                    onClick={() => this.props.onAllSelectionChange(items, true, this.props.groupIndex)}
                    ref={(ref) => (this.toggleSelectionRef = ref)}
                    className="toggle-selection"
                >
                    {message}
                    <span className="la-ZoomIn" />
                </button>
            )
        );
    };

    deselectAll = (items, isSuggestedNames = false) => {
        const message = !isSuggestedNames
            ? 'StartPage_CoreFunctionality_UIText_fuzzy.removeAll'
            : 'StartPage_SuggestedNames.removeNames';

        const showDeselectAll = isSuggestedNames ? items.length > 1 : true;

        return (
            showDeselectAll && (
                <button
                    onClick={() => this.props.onAllSelectionChange(items, false, this.props.groupIndex)}
                    ref={(ref) => (this.toggleSelectionRef = ref)}
                    className="toggle-selection"
                >
                    <FormattedMessage id={message} />
                    <span className="la-CloseRemove" />
                </button>
            )
        );
    };

    render() {
        const withTrimmedPills = this.props.withTrimmedPills !== false;
        const isTrimmed =
            withTrimmedPills && this.state.visibleItemCount && this.props.items.length > this.state.visibleItemCount;
        const items =
            isTrimmed && !this.state.showAll
                ? this.props.items.slice(0, this.state.visibleItemCount)
                : this.props.items;
        const doUnselectedItemsExist = items.find((item) => item.selected === false);
        const isSuggestedNames = this.props.isSuggestedNames;
        const PillListComponent = !isSuggestedNames ? PillItem : SuggestedNamesPillItem;

        const pillListStyle = this.state.pillListWidth && {
            width: this.state.pillListWidth,
        };

        return (
            items.length > 0 && (
                <div
                    className={`pill-list ${this.props.className}`}
                    style={pillListStyle}
                    ref={(ref) => (this.pillList = ref)}
                >
                    <div className="pill-list-container">
                        {items.map((item, key) => (
                            <div className="pill-list__item" key={key} ref={(ref) => (this.pillItems[key] = ref)}>
                                <PillListComponent
                                    item={item}
                                    index={key}
                                    onRemove={
                                        item.selected && (item.onRemove || this.props.onRemoveItem) && this.removeItem
                                    }
                                    onAdd={!item.selected && (item.onAdd || this.props.onAddItem) && this.addItem}
                                />
                            </div>
                        ))}
                    </div>

                    {this.props.onAllSelectionChange &&
                        (doUnselectedItemsExist
                            ? this.selectAll(items, isSuggestedNames)
                            : this.deselectAll(items, isSuggestedNames))}
                    {isTrimmed && this.state.visibleItemCount && this.renderShowMore()}
                </div>
            )
        );
    }
}

export const SuggestedNamesPillItem = ({ item, index, onRemove = null, onAdd = null }) => {
    const handleRemove = () => onRemove(item, index);
    const handleAdd = () => onAdd(item, index);
    const selectedClass = item.selected ? 'pill-item__selected' : '';
    const removeClass = onRemove ? '' : 'readonly';
    const addClass = onAdd ? '' : 'readonly';
    const itemClassName = item.className ? item.className : '';
    const spanClass = `pill-item notranslate ${selectedClass} ${removeClass} ${addClass} ${itemClassName}`;

    return (
        item && (
            <span className={spanClass} title={item.name} onClick={onRemove ? handleRemove : handleAdd}>
                {onRemove && <span className={`selection-icon check`} onClick={handleRemove} />}
                {onAdd && <span className={`selection-icon`} onClick={handleAdd} />}
                <label>{item.name}</label>
            </span>
        )
    );
};

export const PillItem = ({ item, index, onRemove = null, onAdd = null }) => {
    function handleRemove() {
        onRemove(item, index);
    }

    function handleAdd() {
        onAdd(item, index);
    }

    const spanClass =
        'pill-item notranslate' +
        (item.selected ? ' pill-item__selected' : ' ') +
        (onRemove ? ' ' : ' readonly ') +
        (onAdd ? ' ' : ' readonly ') +
        (item.className ? item.className : '');
    return (
        item && (
            <span className={spanClass} title={item.name}>
                <label>{item.name}</label>
                {onRemove && <span className="close-btn  la-CloseRemove" onClick={handleRemove} />}
                {onAdd && <span className="close-btn la-ZoomIn" onClick={handleAdd} />}
            </span>
        )
    );
};
