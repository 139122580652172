import React, { Component, Fragment } from 'react';
import InformationModal from '../InformationModal/InformationModal';
import PropTypes from 'prop-types';
import { Loader } from 'semantic-ui-react';

export default class InformationIcon extends Component {
    state = {
        showInfoModal: false,
    };

    static propTypes = {
        content: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]),
        title: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
        sourceKey: PropTypes.string,
        onClickHandler: PropTypes.func,
        isIconSpinning: PropTypes.bool,
        infoModalClass: PropTypes.string,
    };

    static defaultProps = {
        content: [],
        title: '',
        sourceKey: '',
        isIconSpinning: false,
        infoModalClass: '',
    };

    closeModal = () => {
        this.setState({
            showInfoModal: false,
        });
    };

    openModal = () => {
        this.setState({
            showInfoModal: true,
        });
    };

    handleIconClick = () => {
        if (this.props.onClickHandler) {
            this.props.onClickHandler(this.props.sourceKey);
        }

        this.openModal();
    };

    render() {
        return (
            <Fragment>
                {this.props.isIconSpinning ? (
                    <span className="loading-icon">
                        <Loader size="small" active />
                    </span>
                ) : (
                    <span className={`la-InfoHover information-icon`} onClick={this.handleIconClick} />
                )}
                {this.state.showInfoModal && (
                    <InformationModal
                        infoModalClass={this.props.infoModalClass}
                        closeButtonHandler={this.closeModal}
                        content={this.props.content}
                        title={this.props.title}
                    />
                )}
            </Fragment>
        );
    }
}
