import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AlertsApi from '@ManageAlerts/ManageAlertsApi';
import { ERRORS } from '@constants';

const withPRAlertsEndpoints = (HandlerProvider) =>
    class PRAlertsEndpoints extends Component {
        static propTypes = {
            provideHandlers: PropTypes.func.isRequired,
            state: PropTypes.object.isRequired,
            onUpdateState: PropTypes.func.isRequired,
        };

        handleApiGet(loadParams) {
            const { pagination, filtering, sorting } = loadParams;
            const nameFilter = filtering.filters && filtering.filters.nameFilter ? filtering.filters.nameFilter : '';
            return AlertsApi.getPublicRecordAlerts(
                pagination.pageNumber,
                pagination.pageSize,
                sorting.sortBy,
                sorting.direction,
                nameFilter
            );
        }

        handleAlertsDelete(alerts, pageSize = 50) {
            return AlertsApi.deletePublicRecordAlerts(
                alerts.map((alert) => alert.compositeIdAsPathSegment),
                pageSize
            );
        }

        setIsLoading(isLoading, callback) {
            this._setState(
                {
                    data: {
                        ...this.props.state.data,
                        isLoading: isLoading,
                    },
                },
                callback,
                true
            );
        }

        _setState(state, callback = null, useForce = false) {
            if (!this.props.state.data.isLoading || useForce) {
                this.props.onUpdateState(state, callback);
            }
        }

        _getLoadParams() {
            return {
                sorting: this.props.state.sorting,
                pagination: this.props.state.pagination,
                filtering: this.props.state.filtering,
            };
        }

        handleLoadData = () => {
            this._setState({
                data: {
                    rows: null,
                    hasError: false,
                },
            });
            this.setIsLoading(true);

            return this.handleApiGet(this._getLoadParams())
                .then((response) => {
                    this.setIsLoading(false, () => {
                        const data = {
                            rows: (response && response.items) || [],
                            filterCount: (response && response.items && response.items.length) || 0,
                            totalCount: (response && response.total) || 0,
                            hasError: false,
                        };
                        this._setState({ data });
                    });
                })
                .catch((e) => {
                    console.error(e);
                    this.setIsLoading(false, () => {
                        this._setState({
                            data: {
                                rows: null,
                                hasError: true,
                            },
                        });
                    });
                    throw ERRORS.TABLE_LOAD_DATA_GET;
                });
        };

        render() {
            const initHandlers = {
                alerts: {
                    onAlertsDelete: this.handleAlertsDelete,
                },
            };

            return (
                <HandlerProvider
                    {...this.props}
                    onApiGet={() => {}}
                    onApiCount={() => {}}
                    initHandlers={initHandlers}
                    provideHandlers={(handlers) => {
                        return this.props.provideHandlers({
                            ...handlers,
                            // Overriding the data load handler (this functionality can be separated into a HOC if necessary)
                            data: {
                                ...handlers.data,
                                onLoadData: this.handleLoadData,
                            },
                        });
                    }}
                />
            );
        }
    };

export default withPRAlertsEndpoints;
