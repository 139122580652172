import React from 'react';
import { injectIntl } from 'react-intl';
import utils from '@utils/utilities';
import formatRichMessage from '@utils/formatRichMessage';
import { connect } from 'react-redux';
import { HELP_URLS } from '@constants';
import {ReactComponent as BannerErrorIcon} from '../../../assets/icons/BannerErrorIcon.svg'
import {ReactComponent as BannerErrorIconDark} from '../../../assets/icons/BannerErrorIconDark.svg'

/**
 * If there would be added more links in the translations for the boxErrorMessage, please sanitise them as following
 *  https://formatjs.io/docs/react-intl/upgrade-guide-4x
 * @param {*} param0
 * @returns Html error message
 */
const BoxErrorMessage = ({ messageId, className, values = {}, intl, liteUserState, isDarkMode}) => {
    const customerSupportLink = utils.getHelpUrl(liteUserState, HELP_URLS.CUSTOMER_SUPPORT_URL.key);

    const newValues = {
        ...values,
        a: (...chunks) => `<a href="${customerSupportLink}" target='_blank'>${chunks.join('')}</a>`,
    };
    const message = formatRichMessage({ id: messageId }, intl, newValues);
    const computeClass = `${className ?? 'box-error-message'} embedded-message error${isDarkMode ? ' dark-mode' : ''}`;
    return <div className={computeClass} >
        <div className = "embedded-message__icon">
            {isDarkMode ? <BannerErrorIconDark /> : <BannerErrorIcon />}
        </div>
        <div className = "embedded-message__message" dangerouslySetInnerHTML={{ __html: message }}></div>
    </div>;
};

const mapStateToProps = (state) => ({
    liteUserState: {
        helpUrlRoot: state.user.helpUrlRoot,
        preferences: {
            language: state.user.preferences.language,
        },
    },
    isDarkMode: state.user.preferences.generalSettings?.isDarkMode,
});

export default injectIntl(connect(mapStateToProps, null)(BoxErrorMessage));
