import React from 'react';
import { AddToReportBtnElastic } from '@reusable/Buttons/redux/AddToReportBtn.containers';
import { FormattedMessage, injectIntl } from 'react-intl';
import categoryUtils, { withCustomNewsTranslation } from '@utils/categoryUtils';
import formatRichMessage from '@utils/formatRichMessage';

class NoResultsDocumentItem extends React.Component {
    constructor(props) {
        super(props);

        this.capitalizeNegativeTerms = this.capitalizeNegativeTerms.bind(this);
    }

    capitalizeNegativeTerms(negativeTopics) {
        if (negativeTopics) {
            let formattedNegativeTopics = negativeTopics.split(' AND ') || negativeTopics;
            // always the first item in array is the searched term, the second is the negative topics and the third is the date
            if (formattedNegativeTopics[1]) {
                formattedNegativeTopics[1] = formattedNegativeTopics[1]
                    .replace(/\b\w/g, (newTopic) => newTopic.toUpperCase())
                    .replace(/Or/g, 'or');
                formattedNegativeTopics = formattedNegativeTopics.join(' AND ');
            }
            return formattedNegativeTopics;
        }
    }

    render() {
        const article = this.props.article;
        const articleTypeResolved = categoryUtils.resolvedToTranslatableCategory(this.props.articleType);
        const contentType = formatRichMessage(
            {
                id: 'General.categoryName.label.' + articleTypeResolved,
                defaultMessage: withCustomNewsTranslation(this.props.articleType),
            },
            this.props.intl
        );
        const title = formatRichMessage({ id: 'NoResultsDocumentItem.noDocuments' }, this.props.intl, { contentType });

        return (
            <div className="no-results-0 notranslate">
                <div className="no-documents-row">
                    <div className="no-documents-title">
                        <span dangerouslySetInnerHTML={{ __html: title }} />
                    </div>
                    <div className="no-documents-button">
                        <AddToReportBtnElastic article={article} articleType={this.props.articleType} />
                    </div>
                </div>
                <div className="horizontal-divider" />
                <div className="no-documents-search-terms">
                    <span className="search-terms">
                        <FormattedMessage id={'NoResultsDocumentItem.searchTerm'} />
                    </span>
                    <span>{this.capitalizeNegativeTerms(article.content)}</span>
                </div>
                <div className="no-documents-source">
                    <span className="source-highlight">
                        <FormattedMessage id={'NoResultsDocumentItem.documentSource'} />{' '}
                    </span>
                    <span>{article.source}</span>
                </div>
            </div>
        );
    }
}

export default injectIntl(NoResultsDocumentItem);
