import React from 'react';
import { withToggleViewForExpandedReport } from '@ReportBuilder/HOC/withReportHoc';
import { withLoadingSpinner } from '@reusable/HOC/withLoadingSpinner';
import { compose } from 'redux';
import { Loader, Segment } from 'semantic-ui-react';
import ReportNotes from '../sharedComponents/Notes/ReportNotes';
import ReportFooter from '../sharedComponents/Footer/ReportFooter';
import ReportInfo from './contentComponents/ReportInfo';
import ReportBuilderService from '@ReportBuilder/ReportBuilderService';
import { cloneDeep } from 'lodash';
import { ReportInfoHeading, SubHeading } from './contentComponents/ReportInfoHeading';
import { withDragAndDrop } from 'scripts/reusable/HOC/withDragAndDrop';
import { DRAG_AND_DROP } from '@constants';

class Content extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            overlayIsVisible: false,
            droppedChildReportId: null,
            hoverIndex: null,
            currentIndex: null,
            canDrop: true,
            visibleMenu: null,
        };
        this.updateDroppedReportId = this.updateDroppedReportId.bind(this);
        this.setOverlay = this.setOverlay.bind(this);
        this.updateVisualDropTarget = this.updateVisualDropTarget.bind(this);
        this.removeVisualTarget = this.removeVisualTarget.bind(this);
    }

    setOverlay(overlayIsVisible) {
        this.setState({
            overlayIsVisible: overlayIsVisible,
        });
    }

    updateVisualDropTarget(currentIndex, hoverIndex) {
        let prevHoverIndex = this.state.hoverIndex;
        if ((hoverIndex === undefined || currentIndex === hoverIndex) && prevHoverIndex !== null) {
            this.setState({
                currentIndex: null,
                hoverIndex: null,
                canDrop: true,
            });
        }
        if (prevHoverIndex !== hoverIndex && Math.abs(currentIndex - hoverIndex)) {
            this.setState({
                currentIndex,
                hoverIndex,
                canDrop: true,
            });
        }
    }
    removeVisualTarget() {
        this.setState({
            canDrop: false,
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            report: nextProps.report,
            canDrop: true,
        });
    }

    UNSAFE_componentWillMount() {
        this.intervalId = null;
    }

    componentWillUnmount() {
        clearInterval(this.intervalId);
    }

    updateDroppedReportId(droppedChildReportId) {
        this.setState({
            droppedChildReportId,
            hoverIndex: null,
        });

        clearInterval(this.intervalId);
        this.intervalId = setTimeout(() => {
            this.setState({
                droppedChildReportId: null,
            });
        }, 2500);
    }

    requestReorder = async (index, hoverIndex, currentElement) => {
        this.setOverlay(true);
        this.props.showAutosaveMessage(`combined-report-${this.props.report.id}`);

        const childReports = cloneDeep(this.props.report.childReports);
        let sortedChildReports = childReports.slice();

        while (index < 0) {
            index += sortedChildReports.length;
        }
        while (hoverIndex < 0) {
            hoverIndex += sortedChildReports.length;
        }
        if (hoverIndex >= sortedChildReports.length) {
            let k = hoverIndex - sortedChildReports.length;
            while (k-- + 1) {
                sortedChildReports.push(undefined);
            }
        }

        sortedChildReports.splice(hoverIndex, 0, sortedChildReports.splice(index, 1)[0]);
        const sortedChildReportsIDs = sortedChildReports.map((report) => report.reportId);

        await ReportBuilderService.sortChildReportsForCombined(
            this.props.report.id,
            sortedChildReportsIDs,
            () => this.updateDroppedReportId(currentElement.reportId),
            () => this.updateVisualDropTarget()
        );

        this.props.removeAutosaveMessage(false, `combined-report-${this.props.report.id}`);
        this.setOverlay(false);
    };

    setActiveMenu = (id) => {
        this.setState({ visibleMenu: id });
    };

    render() {
        const { droppedChildReportId, hoverIndex, currentIndex, canDrop, visibleMenu } = this.state;
        const hasMenuOpen = visibleMenu !== null;
        const { connectDropTarget } = this.props;
        const childReports = this.props.report.childReports;

        return connectDropTarget(
            <div className="combined-report">
                <Segment>
                    <div className="combined-report-content">
                        <ReportInfoHeading
                            modifiedSections={this.props.modifiedSections}
                            section={`combined-report-${this.props.report.id}`}
                        />
                        <div className="horizontal-divider" />
                        <SubHeading />
                        <div className="combined-report-content__list">
                            {this.state.overlayIsVisible && (
                                <div className="report-overlay">
                                    <Loader size={'big'} active></Loader>
                                </div>
                            )}
                            <ol className={`reports-list-childReports-table ${hasMenuOpen ? 'with-menu-open' : ''}`}>
                                {childReports.map((childReport, index) => (
                                    <ReportInfo
                                        key={`child-report-row-${index}`}
                                        index={index}
                                        childReport={childReport}
                                        disabled={childReport.isDeleted || childReport.isExpired}
                                        viewIncludedReport={this.props.viewIncludedReport}
                                        removeIncludedReport={this.props.removeIncludedReport}
                                        //reordering props
                                        parentReport={this.props.report}
                                        droppedChildReportId={droppedChildReportId}
                                        requestReorder={this.requestReorder}
                                        setOverlay={this.setOverlay}
                                        showDropTarget={hoverIndex === (index || 0)}
                                        insertAbove={hoverIndex < currentIndex}
                                        updateVisualDropTarget={this.updateVisualDropTarget}
                                        canDrop={canDrop}
                                        visibleMenu={visibleMenu}
                                        setActiveMenu={this.setActiveMenu}
                                    />
                                ))}
                            </ol>
                        </div>
                        <ReportNotes
                            editMode={
                                !(this.props.report && this.props.report.note && this.props.report.note.length > 0)
                            }
                            reportId={this.props.report.id}
                            notes={this.props.report.note || ''}
                            addNoteToReport={this.props.addNoteToReport}
                            deleteReportNote={this.props.showDownloadReportPopup}
                            index={this.props.index}
                        />
                        <ReportFooter
                            report={this.props.report}
                            handleDeleteReport={this.props.handleDeleteReport}
                            downloadReport={this.props.showDownloadReportPopup}
                            index={this.props.index}
                        />
                    </div>
                </Segment>
            </div>
        );
    }
}

const dndHookInfo = {
    componentType: 'parent',
    accept: DRAG_AND_DROP.CHILD_REPORT,
};

export default compose(withLoadingSpinner, withToggleViewForExpandedReport, withDragAndDrop(dndHookInfo))(Content);

export { Content as TestCombinedReportContent };
