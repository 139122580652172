import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import formatRichMessage from '@utils/formatRichMessage';

import EmailListInput from '@reusable/Input/EmailInput/EmailListInput';

const AlertDeliveryOptions = (props) => {
    return (
        <div className="create-alert-delivery-options-container">
            <div className="create-alert-delivery-options-container_header">
                <h3>
                    <FormattedMessage id="Alerts.createAlert.alertDeliveryOptions" />
                </h3>
            </div>
            <div className="create-alert-delivery-options-container_body">
                <div className="alert-delivery-options_alert-name">
                    <h4>
                        <FormattedMessage id="Alerts.createAlert.alertName" />
                    </h4>
                    {!props.name && (
                        <div className="validationAlertMessage">
                            <FormattedMessage id={'Alerts.createAlert.nameValidationMessage'} />
                        </div>
                    )}
                    <input
                        className={`text-field-standard-lg create-alert_input ${!props.name ? 'text-field-error' : ''}`}
                        type="text"
                        placeholder={formatRichMessage({ id: 'Alerts.editAlert.name.placeholder' }, props.intl)}
                        value={props.name || ''}
                        onChange={props.handleAlertNameChange}
                    />
                </div>
                <EmailListInput
                    isEmailValid={props.isEmailValid}
                    emailErrorMessage={props.emailErrorMessage}
                    emails={props.emails}
                    handleEmailAddressChange={props.handleEmailAddressChange}
                    showEmailList={props.showEmailList}
                    hideEmailList={props.hideEmailList}
                    handleEmailKeyPress={props.handleEmailKeyPress}
                    showEmailDropdownList={props.showEmailDropdownList}
                    recentEmails={props.recentEmails}
                />
                <div className="alert-delivery-options_comments">
                    <div className="alert-delivery-options_comments-header">
                        <span className="alert-delivery-options_labels">
                            <FormattedMessage id="Alerts.createAlert.comments" />:
                        </span>
                    </div>
                    <div className="alert-delivery-options_comments-subHeader">
                        <FormattedMessage id="Alerts.createAlert.tip" />
                        <span className="characters-remaining">
                            <FormattedMessage
                                id="Alerts.createAlert.charactersRemaining"
                                values={{ charsLeft: props.charsLeft }}
                            />
                        </span>
                    </div>
                    {props.charsLeft <= 0 && (
                        <div className="validationAlertMessage">
                            <FormattedMessage id={'Alerts.createAlert.maxCharsValidationMessage'} />
                        </div>
                    )}
                    <textarea
                        className={`text-area-standard alert-delivery-options_input ${
                            props.charsLeft <= 0 ? 'text-field-error' : ''
                        }`}
                        onChange={props.handleCommentChange}
                        onKeyPress={props.handleCommentKeyPress}
                        value={props.comment ?? ''}
                    />
                </div>
            </div>
        </div>
    );
};

export default injectIntl(AlertDeliveryOptions);
