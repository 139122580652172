/**
 * MAIN HEADER VIEW TEMPLATE
 *
 * @version 0.1
 */

import React from 'react';

import MainMenu from './templates/Header.mainMenu.jsx';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import breadcrumbsActions from '../Breadcrumbs/redux/Breadcrumbs.actions';
import searchParamsActions from '@pages/MainSearch/redux/SearchParams.actions';
import investigationActions from '@pages/MainSearch/redux/Investigation.actions';
import userPreferencesActions from '@pages/UserPreferances/redux/UserPreferences.actions';
import editSearchActions from '@pages/StartPage/redux/EditSearch.actions';
import suggestedNamesActions from '../SuggestedNames/redux/SuggestedNames.actions';
import mainActions from 'scripts/pages/Main/Main.actions.js';
import { useSelector } from 'react-redux';

const Header = (props) => {
    const {
        user: {
            appSettings: { isBatchReportsEnabled },
        },
    } = props;

    const diligencePlusMarketingOverlay = useSelector((state) => state.user.diligencePlusMarketingOverlay);
    const isSpecifiedCustomer = useSelector((state) => state.user.appSettings.isSpecifiedCustomer);
    const isMultipleEntitiesTrial = useSelector((state) => state.user.appSettings.isMultipleEntitiesTrial);

    return (
        <div className={`diligence-header noprint ${props.googleTranslateStatus ? 'google-translate-toolbar-on' : ''}`}>
            <MainMenu
                skipToContent={props.skipToContent}
                updateBreadcrumbs={props.updateBreadcrumbs}
                user={props.user}
                searchParams={props.searchParams}
                userPreferencesSearchType={props.generalSettings.searchType}
                prevPath={props.prevPath}
                fuzzyNames={props.fuzzyNames}
                suggestedNames={props.suggestedNames}
                isFuzzyEnabled={props.generalSettings.isFuzzyEnabled}
                isSnapshotEnabled={props.generalSettings.showSnapshot}
                contentTypes={props.generalSettings.contentTypes}
                selectedCostCode={props.generalSettings.selectedCostCode}
                isCostCodeRequired={props.generalSettings.isCostCodeRequired}
                chooseOnlyFromCostCodesList={props.generalSettings.chooseOnlyFromCostCodesList}
                costCodesList={props.generalSettings.costCodesList}
                changeSearchType={props.changeSearchType}
                changeQuery={props.changeQuery}
                setBooleanTerms={props.setBooleanTerms}
                resetQuery={props.resetQuery}
                setBillingId={props.setBillingId}
                resetFuzzyNames={props.resetFuzzyNames}
                resetSuggestedNames={props.resetSuggestedNames}
                adHocSearch={props.adHocSearch}
                isSearchComplete={props.isSearchComplete}
                shouldSavePreferences={props.shouldSavePreferences}
                adminPreferencesAreSaving={props.adminPreferencesAreSaving}
                updateUserPrefsDirtiness={props.updateUserPrefsDirtiness}
                useNewResearchSummary={props.useNewResearchSummary}
                emptyEditSearch={props.emptyEditSearch}
                adminPermissionChanged={props.adminPermissionChanged}
                isBatchReportsEnabled={isBatchReportsEnabled}
                resetLaunchedFrom={props.resetLaunchedFrom}
                updatePrevPath={props.updatePrevPath}
                diligencePlusMarketingOverlay={diligencePlusMarketingOverlay}
                isSpecifiedCustomer={isSpecifiedCustomer}
                isMultipleEntitiesTrial={isMultipleEntitiesTrial}
            />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.user,
        googleTranslateStatus: state.user.preferences.googleTranslateStatus,
        isLoggedIn: state.user.isLoggedIn,
        searchParams: state.searchParams,
        prevPath: state.breadcrumbs.prevPath,
        fuzzyNames: state.fuzzyNames,
        suggestedNames: state.suggestedNames,
        generalSettings: state.user.preferences.generalSettings || {},
        adHocSearch: state.adHocSearch,
        billingId: state.investigation.id,
        isSearchComplete: state.searchStatus.isSearchComplete,
        shouldSavePreferences: state.user.preferences.isDirty,
        adminPreferencesAreSaving: state.user.adminPreferences.isDirty,
        useNewResearchSummary: state.user.useNewResearchSummary,
        adminPermissionChanged: state.user.adminPermissionChanged,
        appSettings: state.user.appSettings,
        diligencePlusMarketingOverlay: state.user.diligencePlusMarketingOverlay,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            resetLaunchedFrom: mainActions.resetLaunchFrom,
            updateBreadcrumbs: breadcrumbsActions.updateBreadcrumbs,
            changeSearchType: searchParamsActions.updateQueryType,
            changeQuery: searchParamsActions.updateQueryString,
            setBooleanTerms: searchParamsActions.setBooleanTerms,
            resetQuery: searchParamsActions.resetQuery,
            setBillingId: investigationActions.setBillingId,
            resetFuzzyNames: userPreferencesActions.resetFuzzyNames,
            resetSuggestedNames: suggestedNamesActions.resetSuggestedNames,
            updateUserPrefsDirtiness: userPreferencesActions.updateIsDirty,
            emptyEditSearch: editSearchActions.emptyEditSearch,
            updatePrevPath: breadcrumbsActions.updatePrevPath,
        },
        dispatch
    );
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Header));
