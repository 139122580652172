// @flow
import React, { Fragment } from 'react';
import type { AbstractComponent } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import PillList from '../PillList/PillList';
import LoadingPillList from '../PillList/LoadingPillList';
import { compose } from 'redux';
import { withAppContext } from '@utils/contexts';
import { PERSON_CHECK } from '@constants';
import formatRichMessage from '@utils/formatRichMessage';
import type { ContextType } from '@pages/MainSearch/components/typeGuards/ResultsList.typeGuards';

type Item = { index: number, name: string, selected: boolean };
type Props = {
    suggestedNames: {
        query: string,
        list: Array<Item>,
        isSuggestedNamesLoaded: boolean,
        hasExceededSuggestedNames: boolean,
        loadedSuggestionsForTerms: { [string | number]: { isLoaded: boolean } },
    },
    onSuggestedNamesSelectionChange: (item: Object, state: boolean) => void,
    onAllSuggestedNamesSelectionChange: (item: Array<Object>, state: boolean) => void,
    isSuggestedNamesPreferenceEnabled: boolean,
    hasExceededSuggestedNames: boolean,
};

type InjectedProps = {|
    +intl: Object,
    context: ContextType,
|};

type State = {
    pillListContainerWidth?: null | number,
};

class SuggestedNames extends React.Component<Props & InjectedProps, State> {
    state: State;
    constructor(props: Props & InjectedProps) {
        super(props);
        this.state = {};
    }

    pillListContainer: ?HTMLElement;

    componentDidUpdate(prevProps, prevState) {
        if (this.props.suggestedNames !== null && !prevState.pillListContainerWidth && this.pillListContainer) {
            this.setState({
                pillListContainerWidth: this.pillListContainer.clientWidth,
            });
        }
    }

    deselectSuggestedNamesOption = (item: Item) => {
        this.props.onSuggestedNamesSelectionChange(item, false);
    };

    selectSuggestedNamesOption = (item: Item) => {
        this.props.onSuggestedNamesSelectionChange(item, true);
    };

    render() {
        let groupedSuggestedNames = {};

        if (this.props.suggestedNames && this.props.suggestedNames.list) {
            this.props.suggestedNames.list.forEach((item) => {
                const { index, name, selected } = item;

                if (groupedSuggestedNames[index]) {
                    groupedSuggestedNames[index].push({ name, selected, index });
                } else {
                    groupedSuggestedNames[index] = [{ name, selected, index }];
                }
            });
        }
        const userPreferencesLink = `#/user-preferences?section=${PERSON_CHECK}`;
        const hideSuggestedNamesMessage = formatRichMessage(
            { id: 'StartPage_SuggestedNames.hideSuggestedNames' },
            this.props.intl,
            { a: (...chunks) => `<a href=${userPreferencesLink}>${chunks.join('')}</a>` }
        );
        const hasSuggestions = groupedSuggestedNames && Object.keys(groupedSuggestedNames).length;

        return (
            <Fragment>
                {this.props.isSuggestedNamesPreferenceEnabled ? (
                    <div className="suggested-names">
                        <div className="suggested-names-wrapper">
                            <div className="label" data-track="suggested-names-label">
                                <FormattedMessage id="StartPage_SuggestedNames.label" />
                            </div>
                            {this.props.hasExceededSuggestedNames && (
                                <div className="error">
                                    <FormattedMessage id="StartPage_SuggestedNames.maximumPersons" />
                                </div>
                            )}
                            {!hasSuggestions && (
                                <div className="default-message">
                                    <FormattedMessage id="StartPage_SuggestedNames.defaultMessage" />
                                </div>
                            )}
                        </div>
                        {groupedSuggestedNames &&
                            Object.keys(groupedSuggestedNames) &&
                            Object.keys(groupedSuggestedNames).map((key) => {
                                return (
                                    <div
                                        className="suggested-names-wrapper__container"
                                        ref={(ref) => (this.pillListContainer = ref)}
                                        key={key}
                                    >
                                        {this.props.suggestedNames.loadedSuggestionsForTerms &&
                                        this.props.suggestedNames.loadedSuggestionsForTerms[key] &&
                                        this.props.suggestedNames.loadedSuggestionsForTerms[key].isLoaded ? (
                                            <PillList
                                                onRemoveItem={this.deselectSuggestedNamesOption}
                                                onAddItem={this.selectSuggestedNamesOption}
                                                onAllSelectionChange={this.props.onAllSuggestedNamesSelectionChange}
                                                isSuggestedNames={true}
                                                items={groupedSuggestedNames[key]}
                                                pillListContainerWidth={this.state.pillListContainerWidth}
                                                groupIndex={key}
                                                withTrimmedPills={false}
                                            />
                                        ) : (
                                            <LoadingPillList
                                                pillListContainerWidth={this.state.pillListContainerWidth}
                                            />
                                        )}
                                    </div>
                                );
                            })}
                        <div className="hide-message">
                            <div dangerouslySetInnerHTML={{ __html: hideSuggestedNamesMessage }} />
                        </div>
                    </div>
                ) : null}
            </Fragment>
        );
    }
}

export default (compose(withAppContext, injectIntl, withRouter)(SuggestedNames): AbstractComponent<Props, State>);
