import React from 'react';
import { Loader } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import backgroundActions from './redux/BackgroundMessages.actions';
import { Link } from 'react-router';
import { BACKGROUND_MESSAGE_TYPE_DOWNLOAD, BACKGROUND_MESSAGE_TYPE_PRINT, MESSAGE_TIMEOUT } from '@constants';
import reportBuilderActions from '@pages/ReportBuilder/redux/ReportBuilder.action';
import { FormattedMessage } from 'react-intl';

export class BackgroundMessage extends React.Component {
    constructor(props) {
        super(props);
        this.download = this.download.bind(this);
        this.editReport = this.editReport.bind(this);
    }

    download(e, downloadURL) {
        const { parameters } = this.props.backgroundMessages;

        window.open(downloadURL, '_self');
        this.props.clearBackgroundMessages();

        if (parameters && parameters.reportId) {
            this.props.updateReportDeliveredStatus(parameters.reportId);
        }
    }

    editReport(link) {
        let parser = document.createElement('a');
        parser.href = link;
        const reportId = parser.search.replace('?reportId=', '');

        if (reportId && reportId !== '') {
            this.props.setSelectedReportById(null);
            this.props.setSelectedReportById(reportId);
        }

        setTimeout(() => {
            this.props.clearBackgroundMessages();
        }, MESSAGE_TIMEOUT);
    }

    render() {
        let { title, link, type, message, isVisible, icon, shrink, buttons, actionLink, close, messageParameters } =
            this.props.backgroundMessages || {};
        let hasNoTitle = title === undefined || title === null;
        return isVisible ? (
            <div className={`background-message-wrapper notranslate ${hasNoTitle && 'noTitle'}`}>
                <div className={!icon ? 'background-message-spinner' : 'background-message-spinner hidden'}>
                    <Loader size="large" active={true} inverted />
                </div>
                <div className={icon && 'background-message-icon ' + icon} />
                <div className={'background-message-content ' + (shrink ? 'shrink' : '')}>
                    <div className="background-message-box">
                        <div className="background-message-title">{title ? <FormattedMessage id={title} /> : null}</div>
                        {link ? (
                            <div>
                                {type === BACKGROUND_MESSAGE_TYPE_PRINT ? (
                                    <a className="background-message-link-for-download" onClick={link}>
                                        <div className="background-message-body">
                                            {message ? (
                                                <FormattedMessage id={message} values={{ term: messageParameters }} />
                                            ) : null}
                                        </div>
                                    </a>
                                ) : type === BACKGROUND_MESSAGE_TYPE_DOWNLOAD ? (
                                    <button
                                        id="download-button-message"
                                        onClick={(e) => {
                                            this.download(e, link);
                                        }}
                                        className="background-message-button-for-download"
                                    >
                                        {message ? (
                                            <FormattedMessage id={message} values={{ term: messageParameters }} />
                                        ) : null}
                                    </button>
                                ) : (
                                    <a className="background-message-link-for-download" href={link}>
                                        <div className="background-message-body">
                                            {message ? (
                                                <FormattedMessage id={message} values={{ term: messageParameters }} />
                                            ) : null}
                                        </div>
                                    </a>
                                )}
                            </div>
                        ) : (
                            <div className="background-message-body">
                                {message ? (
                                    <FormattedMessage
                                        id={message}
                                        values={{ term: messageParameters }}
                                    ></FormattedMessage>
                                ) : null}
                            </div>
                        )}

                        {buttons && (
                            <div className="background-message-action-buttons">
                                <button className="button-primary-sm" onClick={() => this.editReport(actionLink)}>
                                    <Link to={actionLink}>
                                        <span>
                                            <FormattedMessage id="General_CoreFunctionality_UIText_general.edit" />
                                        </span>
                                    </Link>
                                </button>
                                <button className="button-secondary-sm" onClick={this.props.clearBackgroundMessages}>
                                    <FormattedMessage id="General_CoreFunctionality_UIText_general.cancel" />
                                </button>
                            </div>
                        )}
                    </div>
                    {close && (
                        <div
                            className={'background-message-button la-CloseRemove'}
                            onClick={this.props.clearBackgroundMessages}
                        />
                    )}
                </div>
            </div>
        ) : null;
    }
}

const mapStateToProps = (state) => {
    return {
        backgroundMessages: state.backgroundMessages,
    };
};

const mapDispatchToProps = function (dispatch) {
    return bindActionCreators(
        {
            clearBackgroundMessages: backgroundActions.clearBackgroundMessages,
            setSelectedReportById: reportBuilderActions.setSelectedReportById,
            updateReportDeliveredStatus: reportBuilderActions.updateReportDeliveredStatus,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(BackgroundMessage);
