import React from 'react';
import withStateProvider from '@reusable/Table/providers/hoc/withStateProvider';
import TableHandlersProvider from '@reusable/Table/providers/TableHandlersProvider';
import TableStateProvider from '@reusable/Table/providers/TableStateProvider';
import { SORT_DIRECTION } from '@constants';
import withMyAlertsEndpoints from './endpoints/withMyAlertsEndpoints';
import MyAlertsTableConsumer from './consumers/MyAlertsTable';
import { connect } from 'react-redux';

/**
 *  This is the main alerts table component, basically composing it with the following:
 *  - Table Handler Provider with attached Alerts Endpoints <AlertsTableHandlersProvider>
 *  - Table Handler Consumer with all the specifics for Alerts (handlers, row configuration, header configuration)
 *  - All these attached to a generic Table State Provider, initiated with a default sorting
 */

const AlertsTableHandlersProvider = withMyAlertsEndpoints(TableHandlersProvider); // adds alerts specific endpoints

const MyAlertsTable = (props) => {
    return (
        <AlertsTableHandlersProvider
            {...props.stateProviderProps}
            state={{
                ...props.stateProviderProps.state,
                pagination: {
                    ...props.stateProviderProps.state.pagination,
                    pageSize: props.generalPageSize,
                },
                sorting: props.sorting,
                language: props.language,
                setPopupModel: props.setPopupModel,
                billingId: props.billingId,
                timezone: props.timezone,
                startEachArticleOnNewPage: props.startEachArticleOnNewPage,
                isBatchReportsEnabled: props.isBatchReportsEnabled,
            }}
            provideHandlers={(handlerProviderProps) => <MyAlertsTableConsumer {...handlerProviderProps} />}
        />
    );
};

const initAlertState = {
    sorting: {
        sortBy: 'lastUpdated',
        direction: SORT_DIRECTION.DESC,
    },
};

const mapStateToProps = (state) => {
    return {
        generalPageSize: state.user.preferences.generalSettings.generalPageSize,
        sorting: state.user.preferences.generalSettings.alertsOptions.sorting,
        language: state.user.preferences.language,
        billingId: state.investigation.billingId,
        timezone: state.user.timezone,
        startEachArticleOnNewPage: state.user.preferences.startEachArticleOnNewPage,
        isBatchReportsEnabled: state.user.appSettings.isBatchReportsEnabled,
    };
};

export default withStateProvider(TableStateProvider, initAlertState)(connect(mapStateToProps)(MyAlertsTable));
