import React from 'react';
import { FormattedMessage } from 'react-intl';
import utils from '@utils/utilities';
import { withAnonymizedHideView } from '@utils/contexts';
import { withMIPUserHideView } from '@utils/contexts';
import { ReactComponent as DismissIcon } from '../../../assets/icons/BannerDismissIcon.svg';
import { ReactComponent as DismissIconDark } from '../../../assets/icons/DismissIconDark.svg';
import { ReactComponent as BannerInfoIcon } from '../../../assets/icons/BannerInfoIcon.svg';
import { ReactComponent as BannerInfoIconDark } from '../../../assets/icons/BannerInfoIconDark.svg';
import { useSelector } from 'react-redux';

const LoginHistoryBanner = (props) => {
    const isDarkMode = useSelector((state) => state.user.preferences.generalSettings.isDarkMode);
    return (
        <div
            className={
                (props.showLoginBanner ? 'diligence-header__login-history slide ' : 'diligence-header__login-history') + (isDarkMode ? ' dark-mode' : '')
            }
        >
            <div className='diligence-header__login-history__icon'>
                {isDarkMode ? <BannerInfoIconDark /> : <BannerInfoIcon />}
            </div>
            <div className="diligence-header__login-history__message-container">
                <div className='diligence-header__login-history__message'>
                    <FormattedMessage
                        id={'LogInHistoryBanner.message'}
                        values={{
                            firstName: <span className="bold">{props.firstName}</span>,
                            lastName: <span className="bold">{props.lastName}</span>,
                            date: <span className="bold">{utils.formatDateDayDateTime(props.lastLoginDate)}</span>,
                        }}
                    />
                </div>

                <a
                    className='diligence-header__login-history_hyperlink'
                    target="_blank"
                    rel="noopener noreferrer"
                    href={props.loginHistoryLink}
                    data-testid="view-history-link"
                >
                    <FormattedMessage id="LogInHistoryBanner.viewHistory" />
                </a>
            </div>
            <button
                type="button"
                className="diligence-header__login-history__button"
                onClick={props.hideLoginHistoryBanner}
                data-testid="hide-banner-button"
            >
                {isDarkMode ? <DismissIconDark /> : <DismissIcon />}
            </button>
        </div>
    );
};

export default withMIPUserHideView(withAnonymizedHideView(LoginHistoryBanner));
