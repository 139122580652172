// @flow
import { put, select, call } from 'redux-saga/effects';
import * as requests from '../requests/investigationRequests';
import * as mainSelectors from '../selectors/main';
import * as selectors from '../selectors/investigationSelectors';
import * as helpers from '../helpers/investigationHelpers';
import investigationActions from '@pages/MainSearch/redux/Investigation.actions';
import { requestWithRetry } from '../helpers/mainHelper';
import currentReportActions from '@pages/ReportBuilder/redux/CurrentReport.actions';
import actions from '@pages/UserPreferances/redux/UserPreferences.actions';
import * as uboSelectors from '../selectors/uboSelectors';

import type {
    GetInvestigationParams,
    GetInvestigationData,
    RequestedGetInvestigationPayload,
    OldRsReportDataType,
    ExtractedUboPostFiltersType,
} from '../investigationTypeGuards/investigationWorkers.type.guards';
import type { InvestigationEventType } from '../investigationTypeGuards/investigationRequests.type.guards';
import type { UboPostFilterExtendedType } from '@pages/MainSearch/typeGuards/PostFilter.typeGuard';

export function* resetInvestigation(): Generator<any, void, empty> {
    yield put(investigationActions.resetInvestigation());
}

export function* resetCurrentReport(): Generator<any, void, empty> {
    let resetReport = {
        reportId: null,
        articlesSnippets: [],
    };
    yield put(currentReportActions.setCurrentReport(resetReport));
}

export function* getInvestigation({
    praOn,
    searchQuery,
    fullSearchQuery,
    searchQueryType,
    createdDate,
}: GetInvestigationParams): Generator<any, GetInvestigationData | Error, empty> {
    try {
        const { data }: RequestedGetInvestigationPayload = yield call(
            requestWithRetry,
            requests.getInvestigation({ praOn, searchQuery, fullSearchQuery, searchQueryType, createdDate })
        );

        return data;
    } catch (err) {
        return err;
    }
}

export function* setInvestigation(data: GetInvestigationData): Generator<any, void, boolean & string> {
    const query: string = yield select(mainSelectors.theSearchQuery);
    const type: string = yield select(mainSelectors.theSearchType);

    const useNewResearchSummary: boolean = yield select(selectors.shouldUseNewResearchSummary);

    if (helpers.isInvestigationFromCurrentSearch(data, query, type)) {
        const hasReport: boolean = helpers.hasReport(data);
        const hasInvestigation: boolean = helpers.hasInvestigation(data);

        if (!hasInvestigation) {
            yield call(setCurrentReport, data);
            yield put(actions.updateUseNewResearchSummary(false));
        } else {
            if (!useNewResearchSummary) yield put(actions.updateUseNewResearchSummary(true));
            yield put(investigationActions.setInvestigation({ ...data, hasReport }));

            //@TODO - we will temporary keep the report in the old structure. The refactoring will be covered in another ticket
            if (hasReport) {
                yield call(setCurrentReport, data);
            }
        }
    }
}

export function* extractUBOPostFilters(): Generator<
    any,
    ExtractedUboPostFiltersType | null,
    UboPostFilterExtendedType & boolean
> {
    const isUboChecked: boolean = yield select(uboSelectors.isUboCategoryChecked);

    if (isUboChecked) {
        const uboPostfilters: UboPostFilterExtendedType = yield select(uboSelectors.theUboPostFilters);
        const extractedUboPostFilters = {};

        Object.keys(uboPostfilters).forEach((key) => {
            //Disabled the eslint to not warn about the unused constants
            //because on the next line I removed the unnecessary properties without mutating the original object as it was happening before
            //eslint-disable-next-line
            const { searchQuery, searchQueryType, includeTerms, excludeTerms, ...restObj } = uboPostfilters[key];
            extractedUboPostFilters[key] = restObj;
        });

        return extractedUboPostFilters;
    }

    return null;
}

// use old flow for reports (temporary)
export function* setCurrentReport(data: OldRsReportDataType): Generator<any, void, mixed> {
    const currentReport = {};

    currentReport.reportId = data.reportId;
    currentReport.articlesSnippets = data.reportSnippets || [];
    currentReport.researchSummary = data.researchSummary || [];

    yield put(currentReportActions.setCurrentReport(currentReport));
}

export function* sendInvestigationEvent(data: InvestigationEventType): Generator<any, void, empty> {
    try {
        yield call(requestWithRetry, requests.sendInvestigationEvent(data));
    } catch (err) {
        console.error('INVESTIGATION NOT UPDATED');
    }
}
