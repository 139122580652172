import { merge, isEmpty, pickBy } from 'lodash';
import update from 'immutability-helper';
import {
    CLEAR_SELECTED_ARTICLE,
    CLEAR_SELECTED_ARTICLES,
    CLEAR_SELECTED_DUPLICATES,
    SELECTED_ARTICLE,
    SELECTED_ARTICLES,
    SELECTED_DUPLICATES,
    SET_SELECTED_ARTICLES_INFO,
    SET_UPLOAD_STATUS,
} from './ArticlesManager.actions';
import errorUtils from '@utils/errors/error-utils';
const APP_MODULE = 'articlesManager.reducers';

function articlesManager(state = {}, action) {
    let selectedArticles;
    let categoryNames;

    switch (action.type) {
        case SELECTED_ARTICLE:
            let selectedArticle = action.payload.selectedArticle;

            if (!selectedArticle || !selectedArticle.article) {
                errorUtils.logAppError(
                    'Missing all parameters in ' + SELECTED_ARTICLE,
                    {
                        payload: action.payload,
                    },
                    APP_MODULE
                );

                return state;
            }
            return update(state, { selectedArticle: { $set: action.payload.selectedArticle } });

        case CLEAR_SELECTED_ARTICLES:
            categoryNames = action.payload.categoryNames;
            if (!categoryNames) {
                errorUtils.logAppError(
                    'Missing categoryNames parameter in ' + CLEAR_SELECTED_ARTICLES,
                    {
                        payload: action.payload,
                    },
                    APP_MODULE
                );

                return state;
            }
            selectedArticles = merge({}, state);
            selectedArticles.selectedArticles = pickBy(
                state.selectedArticles,
                (value, key) => action.payload.categoryNames.indexOf(key) === -1
            );
            return merge({}, selectedArticles);
        case CLEAR_SELECTED_ARTICLE:
            return { ...state, selectedArticle: action.payload };
        case CLEAR_SELECTED_DUPLICATES:
            categoryNames = action.payload.categoryNames;
            if (!categoryNames || isEmpty(action.payload)) {
                errorUtils.logAppError(
                    'Missing categoryNames parameter in ' + CLEAR_SELECTED_DUPLICATES,
                    {
                        payload: action.payload,
                    },
                    APP_MODULE
                );

                return state;
            }

            const selectedDups = merge({}, state);
            selectedDups.selectedDuplicates = pickBy(
                state.selectedDuplicates,
                (value, key) => action.payload.categoryNames.indexOf(key) === -1
            );
            return merge({}, selectedDups);

        case SELECTED_ARTICLES:
            if (
                !action.payload ||
                isEmpty(action.payload) ||
                !action.payload.articles ||
                !action.payload.categoryName ||
                action.payload.isSelected === undefined
            ) {
                errorUtils.logAppError(
                    'Missing parameters in ' + SELECTED_ARTICLES,
                    {
                        payload: action.payload,
                    },
                    APP_MODULE
                );

                return state;
            }

            selectedArticles = state.selectedArticles || {};
            let selectedArticlesArray = selectedArticles[action.payload.categoryName] || [];

            if (action.payload.isSelected) {
                selectedArticlesArray = selectedArticlesArray.concat(action.payload.articles);
            } else {
                action.payload.articles.forEach((article) => {
                    let articleIndex;
                    articleIndex = selectedArticlesArray.findIndex((item) => {
                        return article.id === item.id;
                    });
                    selectedArticlesArray.splice(articleIndex, 1);
                });
            }
            selectedArticles[action.payload.categoryName] = selectedArticlesArray;
            return merge({}, state, { selectedArticles: selectedArticles });

        case SET_SELECTED_ARTICLES_INFO:
            if (!action.payload || action.payload.isSelected === undefined) {
                return state;
            }

            if (!action.payload.articleInfo.length) {
                return update(state, { selectedArticlesInfo: { $set: [] } });
            }

            const { articleInfo, isSelected } = action.payload;
            let selectedArticlesInfo = state.selectedArticlesInfo || [];
            let selectedArticlesInfoArray = selectedArticlesInfo || [];

            if (isSelected) {
                selectedArticlesInfoArray = selectedArticlesInfoArray.concat(articleInfo);
            } else {
                articleInfo.forEach((article) => {
                    const uncheckedElementIndex = selectedArticlesInfoArray.findIndex(
                        (item) => article.documentRank === item.documentRank
                    );
                    if (uncheckedElementIndex > -1) {
                        selectedArticlesInfoArray.splice(uncheckedElementIndex, 1);
                    }
                });
            }

            selectedArticlesInfo = selectedArticlesInfoArray.sort((a, b) => a.documentRank - b.documentRank);

            return update(state, { selectedArticlesInfo: { $set: selectedArticlesInfo } });

        case SELECTED_DUPLICATES:
            if (
                !action.payload ||
                isEmpty(action.payload) ||
                !action.payload.articles ||
                !action.payload.categoryName ||
                action.payload.isSelected === undefined
            ) {
                errorUtils.logAppError(
                    'Missing parameters in ' + SELECTED_DUPLICATES,
                    {
                        payload: action.payload,
                    },
                    APP_MODULE
                );

                return state;
            }

            let selectedDuplicates = state.selectedDuplicates || {};
            let selectedDuplicatesArray = selectedDuplicates[action.payload.categoryName] || [];

            if (action.payload.isSelected) {
                selectedDuplicatesArray = selectedDuplicatesArray.concat(action.payload.articles);
            } else {
                action.payload.articles.forEach((article) => {
                    let articleIndex;
                    articleIndex = selectedDuplicatesArray.findIndex((item) => {
                        return article.id === item.id;
                    });
                    selectedDuplicatesArray.splice(articleIndex, 1);
                });
            }
            selectedDuplicates[action.payload.categoryName] = selectedDuplicatesArray;
            return merge({}, state, { selectedDuplicates: selectedDuplicates });

        case SET_UPLOAD_STATUS:
            let { articlesHashs, categoryName, isUploading } = action.payload;
            if (
                !action.payload ||
                isEmpty(action.payload) ||
                !articlesHashs ||
                !categoryName ||
                isUploading === undefined
            ) {
                errorUtils.logAppError(
                    'Missing parameters in ' + SET_UPLOAD_STATUS,
                    {
                        payload: action.payload,
                    },
                    APP_MODULE
                );

                return state;
            }

            let uploadingDocuments = state.uploadingDocuments || {};
            let uploadingDocumentsArray = uploadingDocuments[action.payload.categoryName] || [];
            if (action.payload.isUploading) {
                //upload
                uploadingDocumentsArray = uploadingDocumentsArray.concat(action.payload.articlesHashs);
            } else {
                //upload finish
                action.payload.articlesHashs.forEach((id) => {
                    uploadingDocumentsArray.splice(uploadingDocumentsArray.indexOf(id), 1);
                });
            }
            uploadingDocuments[action.payload.categoryName] = uploadingDocumentsArray;
            return merge({}, state, uploadingDocuments);

        default:
            return state;
    }
}
export default articlesManager;
