import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import ElasticSingleDocumentContent from '@pages/MainSearch/components/ElasticSingleDocumentContent';
import { DefaultLoader } from '../Table/components/defaults';
import Tabs from '../TabbedContent/Tabs';
import BovTreeView from './BovTreeView';
import { withDocumentTabsViewTitle } from '../HOC/withDocumentTabsViewTitle';
import { bovOrganizationInfo, formatTreeViewData } from './bovHelpers';
import BoxErrorMessage from '../ErrorMessage/BoxErrorMessage';
import { DOC_TYPE_SEARCH_EVENT_REQUEST } from '@constants';

class BovDocumentView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    searchDocAccessEvent(activeTab) {
        const data = {
            ...this.props.originalArticleInfo,
            docType: activeTab === 1 ? DOC_TYPE_SEARCH_EVENT_REQUEST.DOC : DOC_TYPE_SEARCH_EVENT_REQUEST.TREE,
        };
        let selectedArticle = this.props.article;
        if (selectedArticle && selectedArticle.bovOrganization) {
            this.props.searchDocAccessEvent(data);
        }
    }
    componentDidUpdate(prevProps: Readonly<P>) {
        // when accesing the page, bovOrganization was not previously set
        // when navigating to next/previous page, originalArticleInfo changes
        if (
            (!prevProps.article.bovOrganization && this.props.article.bovOrganization) ||
            prevProps.originalArticleInfo.id !== this.props.originalArticleInfo.id
        ) {
            this.searchDocAccessEvent(this.props.activeTab);
        }
    }

    componentDidMount() {
        if (this.props.article.bovOrganization) {
            this.searchDocAccessEvent(this.props.activeTab);
        }
    }

    handleChangeTab = (activeTab: number) => {
        this.props.handleHideHitsNavigator(activeTab);
        this.searchDocAccessEvent(activeTab);
    };

    render() {
        const { article, disclaimerMessage, setArticleTitleRef, setArticleContentRef, hasError } = this.props;
        const bovOrganization = bovOrganizationInfo(article);
        const data = bovOrganization.exists ? formatTreeViewData(bovOrganization.values) : {};

        const bovTabs = [
            {
                label: <FormattedMessage id={'BovDocumentView.Tabs.TreeView'} />,
                content: (
                    <BovTreeView
                        data-testid={'bov-tree-view-testid'}
                        hasParsingError={!bovOrganization.exists}
                        data={data}
                    />
                ),
            },
            {
                label: <FormattedMessage id={'BovDocumentView.Tabs.DocumentView'} />,
                content: (
                    <ElasticSingleDocumentContent
                        disclaimerMessage={disclaimerMessage}
                        article={article}
                        setArticleTitleRef={setArticleTitleRef}
                        setArticleContentRef={setArticleContentRef}
                        hasError={hasError}
                        data-testid={'elastic-single-document-testid'}
                    />
                ),
            },
        ];

        const tabsView = (
            <React.Fragment>
                <div className="tabs-wrapper" data-testid={'bov-tabs-wrapper-testid'}>
                    <Tabs
                        tabs={bovTabs}
                        activeTabIndex={this.props.activeTab}
                        onChange={this.handleChangeTab}
                        data-testid={'bov-tabs-testid'}
                    />
                </div>
                {bovTabs.map((tab, tabIndex) => (
                    <div
                        className={this.props.activeTab === tabIndex ? 'visible_graph' : 'hidden_graph'}
                        key={`tab_${tabIndex}`}
                    >
                        {tab.content}
                    </div>
                ))}
            </React.Fragment>
        );

        return hasError ? (
            <BoxErrorMessage messageId={'SingleArticleContent.Error'} data-testid={'box-error-testid'} />
        ) : article.content == null ? (
            <DefaultLoader data-testid={'bov-article-loader-testid'} />
        ) : (
            <div className="bov-document-view">{tabsView}</div>
        );
    }
}

export { BovDocumentView as TestBovDocumentView };
export default withDocumentTabsViewTitle(BovDocumentView);
