import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import RadioWrapperComponent from './RadioButtons';

class InputComponent extends Component {
    render() {
        return this.props.options.map((option) => (
            <div key={option.key} className={`option custom-${option.type}`}>
                <input
                    type={option.type}
                    name={option.key}
                    id={option.key}
                    checked={option.value}
                    disabled={option.disabled}
                    data-testid={
                        option.value ? 'input-component-checkbox-checked' : 'input-component-checkbox-unchecked'
                    }
                    onChange={(e) => this.props.onChange(this.props.options, option, e)}
                />
                <label htmlFor={option.key} data-disabled={option.disabled} className={`${option.type}-label`}>
                    {option.label}
                </label>
                {/*@TODO add children type radio or checkbox*/}
                {option.children ? (
                    <RadioWrapperComponent
                        options={option.children}
                        className={this.props.className + '__children'}
                        onChange={this.props.onSubCategoriesChange}
                        parentOption={option}
                    />
                ) : null}
            </div>
        ));
    }
}

export default injectIntl(InputComponent);
