import React from 'react';
import AdminLock from '../AdminLock';
import withAdminLockContext from '@reusable/AdminLock/context/hoc/withAdminLockContext';
import utils from '@utils/utilities';

const withAdminLock =
    (lockName, masterLockName = null, isSectionLock = false) =>
    (WrappedComponent) => {
        const withAdminLock = (props) => {
            const isLocked = masterLockName
                ? props.adminLocks.userLocks[lockName] ||
                  (props.adminLocks.userLocks[masterLockName] && !props.adminLocks.isEditable)
                : props.adminLocks.userLocks[lockName];

            let classes = [];
            if (isSectionLock) classes.push('with-section-lock');
            if (isLocked) classes.push('is-locked');
            if (props.adminLocks.isEditable) classes.push('is-editable');

            return (
                <div className={'admin-lock-wrapper ' + classes.join(' ')}>
                    <WrappedComponent
                        {...props}
                        disabled={(isLocked || props.disabled) && !props.adminLocks.isEditable}
                    />
                    <AdminLock
                        lockName={lockName}
                        isSectionLock={isSectionLock}
                        onClickLock={props.adminLocks.onChange}
                        isDisabled={!props.adminLocks.isEditable}
                        isLocked={isLocked}
                    />
                </div>
            );
        };

        withAdminLock.displayName = `withAdminLock(${utils.getDisplayName(WrappedComponent)})`;

        // forward meta upwards
        withAdminLock.meta = WrappedComponent.meta;

        return withAdminLockContext(withAdminLock);
    };

export default withAdminLock;
