import React from 'react';
import TruncatedText from '@reusable/TruncatedElements/TruncatedText';
import utils from '@utils/utilities';

const TruncatedTerm = (props) => {
    return (
        <TruncatedText
            tooltipId="truncated-search-term"
            hasChild={false}
            tooltipHtml={false}
            checkTruncation={utils.isHtmlTextTruncated}
            TruncatedComponent={Term}
            {...props}
        />
    );
};

const Term = (props) => {
    return (
        <div data-for={props.tooltipId} data-tip={props.isTruncated ? props.searchTerm : null}>
            {props.searchTerm}
        </div>
    );
};

export default TruncatedTerm;
export { Term };
