import React, { Component, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import CategoryDateSelect from './CategoryDateSelect';
import DateRangesDropdown, { isCustomDateRange } from './DateRangesDropdown';
import { forEach, uniq } from 'lodash';
import { DATE_RANGE_MIXED, RANGE_TYPE_CUSTOM } from '@constants';

class GroupDateSelect extends Component {
    static propTypes = {
        components: PropTypes.arrayOf(PropTypes.object).isRequired,
        messages: PropTypes.shape({
            title: PropTypes.any,
            context: PropTypes.any,
            hideItems: PropTypes.any,
            showItems: PropTypes.any,
        }).isRequired,
        onChange: PropTypes.func.isRequired,
        dateRanges: PropTypes.array,
        values: PropTypes.object,
        disabled: PropTypes.bool,
    };

    static defaultProps = {
        values: {},
        disabled: false,
    };

    constructor(props) {
        super(props);

        this.state = {
            isOpen: reduceValue(props.values) === DATE_RANGE_MIXED,
        };

        this.handleToggle = this.handleToggle.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleToggle() {
        this.setState({
            isOpen: !this.state.isOpen,
        });
    }

    /**
     * Change handler for master dropdown
     * @param value
     */
    handleChange(value) {
        forEach(this.props.values, (val, key) => {
            this.props.onChange(value, key);
        });
    }

    renderAllCategoriesDateComponentWithToggle = (dateRange, dateRanges) => (
        <Fragment>
            <h3 className={'title'} data-track={'group-date-select-container-title'}>
                {this.props.messages.title}
            </h3>
            <div className={'content'}>
                <div className={'content-text'}>{this.props.messages.content}</div>
                <DateRangesDropdown
                    dateRanges={dateRanges}
                    dateRange={dateRange}
                    onChange={this.handleChange}
                    disabled={this.props.disabled}
                    customDataTestId="all-categories-date-range"
                />
            </div>
            <div>
                <ViewToggle
                    onToggle={this.handleToggle}
                    isOpen={this.state.isOpen}
                    hideMsg={this.props.messages.hideItems}
                    showMsg={this.props.messages.showItems}
                >
                    {this.renderCategoryDateComponents()}
                </ViewToggle>
            </div>
        </Fragment>
    );

    renderCategoryDateComponents = () => {
        const { components } = this.props;

        return components.map((component) => (
            <div className={'group-date-component'} key={component.meta}>
                {components.length > 1 && <div className="sub-section-separator" />}
                <CategoryDateSelect
                    onChange={(value) => this.props.onChange(value, component.meta)}
                    dateRanges={this.props.dateRanges}
                    dateRange={this.props.values[component.meta]}
                    disabled={this.props.disabled}
                    locks={component.locks}
                    dataTestId={component.dataTestId}
                >
                    <div className="category-date-select-title">
                        <h4>
                            <FormattedMessage id={component.title} />
                        </h4>
                        {components.length === 1 && this.props.autoSaveMessage}
                    </div>
                    <p>
                        <FormattedMessage id={'UserPreferences.CustomDates.chooseCustomDate'} />
                    </p>
                </CategoryDateSelect>
            </div>
        ));
    };

    render() {
        const dateRange = reduceValue(this.props.values);
        const dateRanges = uniq([...this.props.dateRanges, dateRangeValue(dateRange)]);

        return (
            <div className={'groupDateSelect'}>
                {this.props.components.length > 1
                    ? this.renderAllCategoriesDateComponentWithToggle(dateRange, dateRanges)
                    : this.renderCategoryDateComponents()}
            </div>
        );
    }
}

const reduceValue = (values) => Object.values(values).reduce(mixedReducer);

const mixedReducer = (accumulator, current) => (accumulator === current ? accumulator : DATE_RANGE_MIXED);

const dateRangeValue = (dateRange) => (isCustomDateRange(dateRange) ? RANGE_TYPE_CUSTOM : dateRange);

// this can be a reusable component
const ViewToggle = ({ isOpen = false, onToggle, hideMsg, showMsg, children }) => {
    const hideMsgWithCaret = (
        <Fragment>
            <div className="la-TriangleUp caret" />
            {hideMsg}
        </Fragment>
    );
    const showMsgWithCaret = (
        <Fragment>
            <div className="la-TriangleDown caret" />
            {showMsg}
        </Fragment>
    );
    return (
        <div className="toggle-view">
            <div onClick={onToggle} className={'toggle-text'}>
                {isOpen ? hideMsgWithCaret : showMsgWithCaret}
            </div>
            <div>{isOpen ? children : null}</div>
        </div>
    );
};

export default GroupDateSelect;
