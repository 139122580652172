import React from 'react';
import { FormattedMessage } from 'react-intl';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    // Method only available in React 16.6. Currently using React 16.3.
    // For future upgrading of React, use this method to render a fallback UI instead of componentDidCatch
    // Use componentDidCatch for logging error information

    // static getDerivedStateFromError(error) {
    //     // Update state so the next render will show the fallback UI.
    //     return { hasError: true };
    // }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        // Catch errors in any components below and re-render with error message
        this.setState({
            error: error,
            errorInfo: errorInfo,
        });
    }

    render() {
        if (this.state.error) {
            // You can render any custom fallback UI

            return (
                <div className="app-wrapper" id="app-wrapper-id">
                    <div className="diligence-header noprint">
                        <div className="diligence-header__menu-wrapper notranslate">
                            <div className="diligence-header-menu-left">
                                <a href={'/'} className="diligence-header__logo">
                                    <div className="diligence-header__logo--product">
                                        <FormattedMessage id="General_CoreFunctionality_UIText_general.diligence" />
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="error-page">
                        <div className="error-500">
                            <div className="error-500__title">
                                <FormattedMessage id="General_CoreFunctionality_Error_error.500Title" />
                            </div>
                        </div>
                        <div className="error-page__navigation">
                            <button
                                onClick={() => (window.location.href = '/')}
                                className="button button-green-lg"
                                id="qa-error-page-button"
                            >
                                <FormattedMessage id="General_CoreFunctionality_UIText_general.goToHomepage" />
                            </button>
                        </div>
                    </div>
                </div>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
