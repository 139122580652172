import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { CheckOptionType } from '../ChecksGroup';

const withLimitToggle = (ChecksComponent) =>
    class WithLimitToggle extends Component {
        static propTypes = {
            visibleItemCount: PropTypes.number,
            onChange: PropTypes.func,
            initialToggle: PropTypes.bool,
            options: PropTypes.arrayOf(CheckOptionType),
            scroll: PropTypes.bool,
        };

        static defaultProps = {
            visibleItemCount: -1,
            initialToggle: true,
            scroll: true,
        };

        constructor(props) {
            super(props);
            this.state = {
                toggled: props.initialToggle,
            };

            this.handleToggle = this.handleToggle.bind(this);
            this.handleChange = this.handleChange.bind(this);
        }

        handleToggle() {
            this.setState(
                this.state.toggled
                    ? { toggled: false, height: this.wrapper.offsetHeight }
                    : { toggled: true, height: null }
            );
        }

        handleChange(options, option) {
            this.props.onChange(
                this.isLimiting() ? [...options, ...this.props.options.slice(this.props.visibleItemCount)] : options,
                option
            );
        }

        isLimiting = () =>
            this.state.toggled && this.props.visibleItemCount && this.props.options && this.props.options.length;

        getStyle = () => ({
            height: this.state.height || 'auto',
            overflowY: this.state.toggled ? 'visible' : 'scroll',
        });

        render() {
            const options = this.isLimiting()
                ? this.props.options.slice(0, this.props.visibleItemCount)
                : this.props.options;

            return (
                <Fragment>
                    <div ref={(el) => (this.wrapper = el)} style={this.getStyle()}>
                        <ChecksComponent {...this.props} options={options} onChange={this.handleChange} />
                    </div>
                    {this.props.visibleItemCount > -1 && this.props.visibleItemCount < this.props.options.length && (
                        <a
                            onClick={this.handleToggle}
                            className={'toggle-visibility'}
                            data-testid={'limit-toggle-show-all'}
                        >
                            {this.state.toggled ? (
                                <FormattedMessage id="General.label.more" />
                            ) : (
                                <FormattedMessage id="General.label.less" />
                            )}
                        </a>
                    )}
                </Fragment>
            );
        }
    };

export default withLimitToggle;
