import React from 'react';

import { FormattedMessage } from 'react-intl';
import DraggableCategory from './DraggableCategory';
import { FE_LOCK_NAMES, DRAG_AND_DROP } from '@constants';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import withAutosave from '../HOC/WithAutosave';
import AdminLock from '@reusable/AdminLock/AdminLock';
import { withDragAndDrop } from 'scripts/reusable/HOC/withDragAndDrop';

class ReportBuilderContentTypes extends React.Component {
    static propTypes = {
        categories: PropTypes.arrayOf(PropTypes.object),
    };

    constructor(props) {
        super(props);

        this.state = {
            hoverIndex: null,
            currentIndex: null,
            droppedCategoryName: null,
            visibleMenu: null,
        };
        this.updateVisualDropTarget = this.updateVisualDropTarget.bind(this);
        this.reorderCategories = this.reorderCategories.bind(this);
    }

    updateVisualDropTarget(currentIndex, hoverIndex) {
        let prevHoverIndex = this.state.hoverIndex;
        if ((hoverIndex === undefined || currentIndex === hoverIndex) && prevHoverIndex !== null) {
            this.setState({
                currentIndex: null,
                hoverIndex: null,
            });
        }
        if (prevHoverIndex !== hoverIndex && Math.abs(currentIndex - hoverIndex)) {
            this.setState({
                currentIndex,
                hoverIndex,
            });
        }
    }

    UNSAFE_componentWillMount() {
        this.intervalId = null;
    }

    componentWillUnmount() {
        clearInterval(this.intervalId);
    }

    reorderCategories(categories, currentIndex, hoverIndex, droppedCategoryName) {
        let newCategories = cloneDeep(categories);
        let currentCategory = newCategories[currentIndex];
        newCategories.splice(currentIndex, 1);
        newCategories.splice(hoverIndex, 0, currentCategory);
        newCategories.forEach((category, index) => {
            category.reportOrder = index;
        });

        this.props.setReportBuilderContentOrder(newCategories);

        this.setState({
            droppedCategoryName,
            hoverIndex: null,
        });

        clearInterval(this.intervalId);

        this.intervalId = setTimeout(() => {
            this.setState({
                droppedCategoryName: null,
            });
        }, 2500);
    }

    requestReorder = (index, hoverIndex, currentElement) => {
        const categories = this.props.categories || [];
        this.reorderCategories(categories, index, hoverIndex, currentElement.categoryName || currentElement.name);
    };

    setActiveMenu = (id) => {
        this.setState({ visibleMenu: id });
    };

    render() {
        const orderedReportContentTypes = this.props.categories || [];

        const { connectDropTarget } = this.props;
        const { hoverIndex, currentIndex, droppedCategoryName, visibleMenu } = this.state;

        return connectDropTarget(
            <div
                className={
                    this.props.userIsAdmin
                        ? 'section-enabled'
                        : this.props.isReadonly
                        ? 'section-disabled'
                        : 'section-enabled'
                }
            >
                <div className="user-preferences-category-container report-builder-container">
                    <div className="user-preferences-category-container__row">
                        <h3 className="user-preferences-category-container__segment report-builder">
                            <FormattedMessage id="UserPreferences.leftSideNavigation.reportBuilder" />
                        </h3>
                        {this.props.autoSaveMessage}
                        {this.props.userIsAdmin ? (
                            <AdminLock
                                lockName={FE_LOCK_NAMES.rearrangeOrder}
                                onClickLock={this.props.handleLockClick}
                                isSectionLock={true}
                                isLocked={this.props.isReadonly}
                            />
                        ) : (
                            this.props.isReadonly && (
                                <AdminLock lockName={FE_LOCK_NAMES.rearrangeOrder} isDisabled={true} />
                            )
                        )}
                    </div>
                    <div className="user-preferences-category-container__row">
                        <span className="user-preferences-category-container__note">
                            <FormattedMessage id="UserPreferences_topic_report_builder_explanation" />
                        </span>
                    </div>

                    <div className="user-preferences-category-container__list">
                        {orderedReportContentTypes.map(
                            (category, index) =>
                                category.value && (
                                    <DraggableCategory
                                        key={index}
                                        name={category.name}
                                        index={index || 0}
                                        userIsAdmin={this.props.userIsAdmin}
                                        categories={orderedReportContentTypes}
                                        droppedCategoryName={droppedCategoryName}
                                        showDropTarget={hoverIndex === (index || 0)}
                                        insertAbove={hoverIndex < currentIndex}
                                        updateVisualDropTarget={this.updateVisualDropTarget}
                                        requestReorder={this.requestReorder}
                                        setReportBuilderContentOrder={this.props.setReportBuilderContentOrder}
                                        isReadonly={this.props.isReadonly}
                                        setActiveMenu={this.setActiveMenu}
                                        visibleMenu={visibleMenu}
                                    />
                                )
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

const dndHookInfo = {
    componentType: 'parent',
    accept: DRAG_AND_DROP.CATEGORY,
};

export default withAutosave()(withDragAndDrop(dndHookInfo)(ReportBuilderContentTypes));
