import React from 'react';
import {
    ALL_ARTICLES_REPORT,
    MODAL_TYPE,
    NO_DOCUMENTS_FOUND,
    NO_DOCUMENTS_OF_INTEREST,
    SELECTED_ARTICLES,
} from '@constants';
import formatRichMessage from '@utils/formatRichMessage';
import { FormattedMessage, injectIntl } from 'react-intl';

function ModalResultList(props) {
    if (!props.isVisible) {
        return <span></span>;
    }

    return (
        <div className="popup-modal__result-list">
            <div className="radio-list">
                <div className="custom-radio">
                    <input
                        type="radio"
                        id="selected_doc"
                        data-testid={'change-selection-selected-articles'}
                        disabled={props.selectedArticlesCount === 0}
                        checked={!props.fullListCheckboxState || !props.noDocumentOfInterestCheckbox}
                        onChange={props.articleSelectionChanged}
                        value={SELECTED_ARTICLES}
                    />
                    <label htmlFor="selected_doc" className="radio-label">
                        <FormattedMessage id={'ModalResultList_SelectedDocuments'} /> - {props.selectedArticlesCount}
                    </label>
                </div>

                {props.popupType === MODAL_TYPE.ADD_TO_REPORT ? (
                    props.totalArticlesCount === 0 ? (
                        <div className="custom-radio">
                            <input
                                type="radio"
                                id="no_documents_found"
                                checked={props.noDocumentsFoundCheckbox}
                                onChange={props.articleSelectionChanged}
                                value={NO_DOCUMENTS_FOUND}
                            />
                            <label htmlFor="no_documents_found" className="radio-label">
                                <FormattedMessage id={'ReportBuilderPage_NoDocumentsFound'} />
                            </label>
                        </div>
                    ) : (
                        <div className="custom-radio">
                            <input
                                type="radio"
                                id="no_documents_of_interest"
                                data-testid={'change-selection-no-documents-of-interest'}
                                checked={props.noDocumentOfInterestCheckbox}
                                onChange={props.articleSelectionChanged}
                                value={NO_DOCUMENTS_OF_INTEREST}
                            />
                            <label htmlFor="no_documents_of_interest" className="radio-label">
                                <FormattedMessage id={'ModalResultList_NoDocumentsOfInterestNote'} />
                            </label>
                        </div>
                    )
                ) : (
                    <div className="custom-radio">
                        <input
                            type="radio"
                            id="full_results"
                            data-testid={'change-selection-all-articles'}
                            checked={props.fullListCheckboxState}
                            onChange={props.articleSelectionChanged}
                            value={ALL_ARTICLES_REPORT}
                        />
                        <label htmlFor="full_results" className="radio-label">
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: formatRichMessage(
                                        { id: props.fullListOptionKey || 'ModalResultList_FullResultsList' },
                                        props.intl,
                                        {
                                            count:
                                                props.category === 'ubo'
                                                    ? props.totalArticlesCount
                                                    : props.maxNumberOfArticles,
                                        }
                                    ),
                                }}
                            />
                        </label>
                    </div>
                )}
            </div>
        </div>
    );
}

export default injectIntl(ModalResultList);
