import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
export class EmptyTableTemplate extends Component {
    constructor(isCustomView) {
        super();
        this.isCustomView = isCustomView;
    }

    getCompatibleCell(uncertainCell) {
        const text = uncertainCell.text;
        return { ...uncertainCell, text };
    }

    update(cell, cellToMerge) {
        return this.getCompatibleCell({ ...cell, text: cellToMerge.text });
    }

    render(cell) {
        const { searchEntity } = cell;
        return (
            <div className="empty-table-batch-screening">
                <img src="/assets/Screening_Icon.svg" alt="empty-table-message-icon" />
                {searchEntity && (
                    <div data-testid="no-entities-match-text">
                        <FormattedMessage  id="BatchScreening.page.table.search.noMatch" />
                    </div>
                )}
                { this.isCustomView ?
                 <FormattedMessage id="BatchScreening.page.table.emptyEntitiesView.message" /> :
                 <FormattedMessage id="BatchScreening.page.table.emptyTable.message" /> 
                }

            </div>
        );
    }
}

export default EmptyTableTemplate;
