import { CUSTOM_DATES_LABEL, CALENDAR_DATE_FORMAT_BE, FILTERS_DATE_RANGE_DEFAULTS, RANGE_TYPE_ALL } from '@constants';
import utils from '@utils/utilities';
import { cloneDeep } from 'lodash';

export const getDefaultValuesFromProps = (props) => {
    const defaultValue = props.defaultValue ? props.defaultValue : props.defaultDate; // comes from preferences
    const checkedOpt = props.options.find((option) => option.checked);
    const isDefaultDateCustom = defaultValue && isDateCustom(defaultValue);
    const isCheckedOptionCustom = checkedOpt && isDateCustom(checkedOpt.count);

    return {
        defaultValue,
        checkedOpt,
        isDefaultDateCustom,
        isCheckedOptionCustom,
    };
};

export const getOptionsVisibility = (options) => {
    options.forEach((option) => {
        option.show = true;
    });
    return options;
};

// update the default dates list if user prefs default date is included in this list
export const setDefaultDatesList = (defaultDate, options) => {
    let defaultDatesValues;
    let index = FILTERS_DATE_RANGE_DEFAULTS.indexOf(defaultDate);

    // modify the default dates list if the user preference date exists in the default dates list and show the dates chronologically
    if (index > -1 && defaultDate !== RANGE_TYPE_ALL) {
        defaultDatesValues = [];
        for (let i = FILTERS_DATE_RANGE_DEFAULTS.length - 1; i >= 0; i--) {
            defaultDatesValues.push(FILTERS_DATE_RANGE_DEFAULTS[i]);
        }
    } else {
        defaultDatesValues = cloneDeep(FILTERS_DATE_RANGE_DEFAULTS);
        // if the date does not exist in the defaults date, remove any default dates that are larger than the date
        if (index < 0) {
            options.forEach((option) => {
                index = defaultDatesValues.indexOf(option.count);
                if (!option.show && index > -1) {
                    defaultDatesValues.splice(index, 1);
                }
            });
        }
    }
    return defaultDatesValues;
};

export const isDateCustom = (date) => {
    return date && !!utils.getDateFromString(date.split(';')[0], CALENDAR_DATE_FORMAT_BE);
};

export const extractDates = (props, values) => {
    let initialValues;
    if (utils.getDateFromString(values.split(props.dateRangeDelimiter)[0], CALENDAR_DATE_FORMAT_BE)) {
        const dateRangeSplit = values.split(props.dateRangeDelimiter);
        if (dateRangeSplit && dateRangeSplit.length) {
            initialValues = {
                from: utils.getDateFromString(dateRangeSplit[0], props.dateFormatOutbound),
                to: utils.getDateFromString(dateRangeSplit[1], props.dateFormatOutbound),
            };
        }
    }
    return initialValues;
};

// calculates the height of the main component depending on the number and height of the entries in the default list
export const calcHeight = (defaultRadioButtons, defaultList) => {
    let totalHeight = 0;
    // the parameter 'defaultList' was added to the function to compute the 'showList' in order to deal with the case when
    // the user is on the main-search page -> selects a date from the graphic -> goes to the history page ->
    // reruns the search -> is redirected back to the main-search with the Custom dates option displayed and
    // instead of computing the height for the calendar we return "null" to let the height to be set on auto
    // and the calendar and Custom dates to be properly displayed.
    const showList = defaultList?.filter((each) => each.show === true);
    if (showList?.length === 1 && showList[0].label === CUSTOM_DATES_LABEL) return null;
    defaultRadioButtons.forEach((radio) => {
        if (radio) {
            totalHeight += radio.offsetHeight || 32;
        }
    });
    return totalHeight + 1;
};
