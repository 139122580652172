import React, { Component, Fragment } from 'react';
import ConfirmationModal from '@reusable/ConfirmationModal/ConfirmationModal';
import { CUSTOM_UNCHECK_PREVENT_ARRAY, OPTION_CHECK } from '@constants';
import { FormattedMessage, injectIntl } from 'react-intl';
import utils from '@utils/utilities';
import formatRichMessage from '@utils/formatRichMessage';

// this HOC helps prevent the user unselecting all checksboxes

function preventAllUnchecks(ChecksComponent) {
    const newVar = class PreventAllUnchecks extends Component {
        constructor(props) {
            super(props);

            this.closeModal = this.closeModal.bind(this);
            this.handleChange = this.handleChange.bind(this);

            this.state = {
                showModal: false,
            };
        }

        handleChange(options, option) {
            const uncheckedOptions = options.filter((optionItem) => optionItem.checked === OPTION_CHECK.UNCHECKED);
            if (uncheckedOptions.length === options.length) {
                this.setState({
                    showModal: true,
                });
            } else {
                this.props.onChange(options, option);
            }
        }

        closeModal() {
            this.setState({
                showModal: false,
            });
        }

        render() {
            let messageValue = this.props.postFilterType ? this.props.postFilterType.toLowerCase() : '';
            const shouldDisplayCustomText = utils.isPostFilterCustomType(
                this.props.postFilterType,
                CUSTOM_UNCHECK_PREVENT_ARRAY
            );
            const buttons = [
                {
                    text: formatRichMessage({ id: 'General_CoreFunctionality_UIText_general.ok' }, this.props.intl),
                    primary: true,
                    click: this.closeModal,
                },
            ];

            if (shouldDisplayCustomText) {
                messageValue = <FormattedMessage id={`PreventAllUnchecks.description.${this.props.postFilterType}`} />;
            }

            return (
                <Fragment>
                    {this.state.showModal && (
                        <ConfirmationModal closeButtonHandler={this.closeModal} buttons={buttons}>
                            <h2 className="modal-header">
                                <FormattedMessage id="PreventAllUnchecks.sourceSelection" />
                            </h2>
                            <div className="modal-description font-body">
                                <FormattedMessage
                                    id="PreventAllUnchecks.description"
                                    values={{ postFilterType: messageValue }}
                                />
                            </div>
                        </ConfirmationModal>
                    )}
                    <ChecksComponent {...this.props} onChange={this.handleChange} />
                </Fragment>
            );
        }
    };
    return injectIntl(newVar);
}

export default preventAllUnchecks;
