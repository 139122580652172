import React, { Fragment } from 'react';
import CustomDocumentContent from './CustomDocumentContent';
import { FormattedMessage } from 'react-intl';
import { MATOMO } from '@constants';
import callMatomoEvent from '@utils/matomoUtils';

class CustomDocumentItem extends React.Component {
    constructor(props) {
        super(props);

        this.checkArticle = this.checkArticle.bind(this);
        this.showSingleArticle = this.showSingleArticle.bind(this);
        this.isArticleSelected = this.isArticleSelected.bind(this);
    }

    checkArticle(article, event) {
        this.props.setSelectedArticles([article], this.props.articleType, event.target.checked);
    }

    isArticleSelected(article) {
        let selectedArticles = this.props.selectedArticles[this.props.articleType] || [];
        let selectedArticle = selectedArticles.find((item) => {
            return item.id === article.id;
        });

        return selectedArticle !== undefined;
    }

    showSingleArticle() {
        const { article, articleType, index, prefilterQuery, showSingleArticle } = this.props;

        callMatomoEvent({
            pageName: MATOMO.pageName.resultsList,
            component: MATOMO.component.searchResultsList,
            subComponent: MATOMO.subComponent.delivery,
            event: MATOMO.event.docOpen,
            value: article.title,
            action: MATOMO.action.click,
            contextData: {
                documentInfo: {
                    documentRank: index + 1,
                    pcsi: article.pcsi,
                    lni: article.id,
                },
                contentType: articleType,
            },
        });
        showSingleArticle({ ...article, articleType, index, prefilterQuery });
    }

    getArticleSnipetsForReport(sanction) {
        let snippetArray = sanction.documentSnippets.map((item) => {
            return item;
        });
        return snippetArray.map((snippet, index) => {
            if (snippet.includes('Head Office')) {
                snippet = snippet.replace('Head Office', '');
                return (
                    <Fragment key={index}>
                        <div className="article-summary-content">
                            <div className="article-summary-content-headOffice">
                                <FormattedMessage id="UboResultsList.ArticleSummary.HeadOffice" />
                            </div>
                            <div className="article-summary-content-text">{snippet}</div>
                        </div>
                    </Fragment>
                );
            }

            return (
                <div className="article-summary-content" key={index}>
                    {snippet}
                </div>
            );
        });
    }

    getEsgLocation = (location) => {
        if (!location || !Object.keys(location).length) {
            return '';
        }

        if (Object.keys(location).length === 1) {
            return location[Object.keys(location)[0]];
        }

        return `${location.state}, ${location.country}`;
    };

    render() {
        let { article, index, setSelectedArticlesInfo } = this.props;
        let { query, searchType, prefilterQuery } = this.props.searchParams;

        return (
            <Fragment>
                <CustomDocumentContent
                    isArticleSelected={this.isArticleSelected}
                    showSingleArticle={this.showSingleArticle}
                    getArticleSnipetsForReport={this.getArticleSnipetsForReport}
                    checkArticle={this.checkArticle}
                    article={article}
                    index={index}
                    query={query}
                    prefilterQuery={prefilterQuery}
                    searchType={searchType}
                    articleType={this.props.articleType}
                    showIndex={true}
                    isArticleItemSelected={this.props.isArticleItemSelected}
                    getEsgLocation={this.getEsgLocation}
                    setSelectedArticlesInfo={setSelectedArticlesInfo}
                />
            </Fragment>
        );
    }
}

export default CustomDocumentItem;
