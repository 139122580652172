// @flow
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import AdminLock from '@reusable/AdminLock/AdminLock';
import { FE_LOCK_NAMES, HELP_URLS } from '@constants';
import withAutosave from '../HOC/WithAutosave';
import { withAppContext } from '@utils/contexts';
import { compose } from 'redux';
import { connect } from 'react-redux';
import utils from '@utils/utilities';
import formatRichMessage from '@utils/formatRichMessage';

type Props = {
    fuzzyOn: boolean,
    fuzzyThreshold: string,
    isReadonly: boolean,
    userIsAdmin: boolean,
    handleFuzzyToggle: (event: SyntheticInputEvent<HTMLInputElement>) => void,
    handleSelectFuzzyThreshold: (event: SyntheticInputEvent<HTMLInputElement>) => void,
    handleLockClick: (lockName: string) => void,
};

type InjectedProps = {|
    +intl: Object,
    +user: Object,
    handleAutosave: (handler: (params: Object) => void, params: Object) => void,
    autoSaveMessage: Object,
|};

const FuzzyNameThreshold = (props: Props & InjectedProps) => {
    const handleFuzzyToggle = (event: Object) => {
        props.handleAutosave(props.handleFuzzyToggle, [event]);
    };

    const handleSelectFuzzyThreshold = (event: Object) => {
        props.handleAutosave(props.handleSelectFuzzyThreshold, [event]);
    };

    const diligenceHelpLink = utils.getHelpUrl(props.user, HELP_URLS.FUZZY_HELP_URL.key);
    const helpMessage = formatRichMessage({ id: 'UserPreferences_topic_sources_fuzzyThreshold.help' }, props.intl, {
        a: (...chunks) => `<a href=${diligenceHelpLink} target='_blank'>${chunks.join('')}</a>`,
    });

    const fuzzyDefinition: Object = {
        definition1: formatRichMessage(
            { id: 'UserPreferences_topic_sources_fuzzyThresholdMatch.' + props.fuzzyThreshold + 'Definition1' },
            props.intl
        ),
        definition2: formatRichMessage(
            { id: 'UserPreferences_topic_sources_fuzzyThresholdMatch.' + props.fuzzyThreshold + 'Definition2' },
            props.intl
        ),
        definition3: formatRichMessage(
            { id: 'UserPreferences_topic_sources_fuzzyThresholdMatch.' + props.fuzzyThreshold + 'Definition3' },
            props.intl
        ),
        help: helpMessage,
    };

    return (
        <div className="user-preferences-category-container user-preferences-similar-names container-section__segment">
            {props.userIsAdmin ? (
                <AdminLock
                    lockName={FE_LOCK_NAMES.fuzzyThresholdPerson}
                    onClickLock={props.handleLockClick}
                    isSectionLock={true}
                    isLocked={props.isReadonly}
                />
            ) : (
                props.isReadonly && <AdminLock lockName={FE_LOCK_NAMES.fuzzyThresholdPerson} isDisabled={true} />
            )}
            <div className="user-preferences-category-container__row container-section__segment__header">
                <h3 className="user-preferences-similar-names-topic">
                    <FormattedMessage id="UserPreferences_topic_sources_fuzzyThreshold" />
                </h3>
                {props.autoSaveMessage}
            </div>

            <div className="include-similar-name-match">
                <div
                    className={!props.userIsAdmin && props.isReadonly ? 'custom-toggle disabled' : 'custom-toggle'}
                    data-track="include-similar-name-match-custom-toggle"
                >
                    <input
                        type="checkbox"
                        checked={props.fuzzyOn === true ? 'checked' : ''}
                        onChange={handleFuzzyToggle}
                        className="custom-toggle-checkbox"
                        id="mytoggle"
                        data-testid={`include-similar-name-match-custom-toggle-${props.fuzzyOn ? 'enabled' : 'disabled'}`}
                    />
                    <label className="custom-toggle-label" htmlFor="mytoggle" />
                </div>
                <FormattedMessage id={'UserPreferences_topic_sources_fuzzyThreshold.toggle'} />
            </div>
            <div disabled={!props.fuzzyOn} className="fuzzy-threshold-content">
                <div className="include-similar-match-explanation">
                    <FormattedMessage id={'UserPreferences_topic_sources_fuzzyThreshold.description'} />
                </div>

                <div className="fuzzy-threshold-radio-group" data-track="fuzzy-threshold-radio-group">
                    <div className="custom-radio">
                        <input
                            id="closeMatch"
                            value="close"
                            checked={props.fuzzyThreshold === 'close'}
                            onChange={handleSelectFuzzyThreshold}
                            disabled={!props.userIsAdmin && props.isReadonly}
                            type="radio"
                        />
                        <label htmlFor="closeMatch" className="radio-label">
                            <FormattedMessage id={'UserPreferences_topic_sources_fuzzyThresholdMatch.closeButton'} />
                        </label>
                    </div>
                    <div className="custom-radio">
                        <input
                            id="moderateMatch"
                            value="moderate"
                            checked={props.fuzzyThreshold === 'moderate'}
                            onChange={handleSelectFuzzyThreshold}
                            name="radio"
                            disabled={!props.userIsAdmin && props.isReadonly}
                            type="radio"
                        />
                        <label htmlFor="moderateMatch" className="radio-label">
                            <FormattedMessage id={'UserPreferences_topic_sources_fuzzyThresholdMatch.moderateButton'} />
                        </label>
                    </div>
                    <div className="custom-radio">
                        <input
                            id="looseMatch"
                            value="loose"
                            checked={props.fuzzyThreshold === 'loose'}
                            onChange={handleSelectFuzzyThreshold}
                            name="radio"
                            disabled={!props.userIsAdmin && props.isReadonly}
                            type="radio"
                        />
                        <label htmlFor="looseMatch" className="radio-label">
                            <FormattedMessage id={'UserPreferences_topic_sources_fuzzyThresholdMatch.looseButton'} />
                        </label>
                    </div>
                </div>
            </div>
            <div className="fuzzy-match-description">
                <div className="include-fuzzy-match-explanation1">
                    <span dangerouslySetInnerHTML={{ __html: fuzzyDefinition.definition1 }} />
                </div>
                <div className="include-fuzzy-match-explanation2">
                    <span dangerouslySetInnerHTML={{ __html: fuzzyDefinition.definition2 }} />
                </div>
                <div className="include-fuzzy-match-explanation3">
                    <span dangerouslySetInnerHTML={{ __html: fuzzyDefinition.definition3 }} />
                </div>
                <div className="include-fuzzy-match-help">
                    <span dangerouslySetInnerHTML={{ __html: fuzzyDefinition.help }} />
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = function (state) {
    return {
        user: state.user,
    };
};

export default (compose(
    connect(mapStateToProps),
    injectIntl,
    withAutosave(),
    withAppContext
)(FuzzyNameThreshold): React$StatelessFunctionalComponent<Props>);
