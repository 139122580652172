import React from 'react';
import { useIntl } from 'react-intl';

export const RICH_TAGS = {
    br: <br />,
    // b: (...chunks) => chunks.map((e, i) => <b key={i}>{e}</b>),
    b: (chunks) => `<b>${chunks}</b>`,
    p: (chunks) => `<p>${chunks}</p>`,
};

export const RICH_TAGS_NOTIFICATIONS = {
    br: <br />,
    b: (chunks) => <b>{chunks}</b>,
    p: (chunks) => <p>{chunks}</p>,
};

const formatRichMessage = (descriptor, intl, values = {}) => {
    const intlRef = intl || useIntl();
    return intlRef.formatMessage(descriptor, { ...RICH_TAGS, ...values });
};

export default formatRichMessage;
