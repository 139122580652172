import { take, call, fork, actionChannel } from 'redux-saga/effects';
import { buffers } from 'redux-saga';
import articleNavigationSaga from './articleNavigationSaga';
import uboSaga from './uboSaga';
import investigationSaga from './investigationSaga';
import metricSaga from './metricSaga';
import searchSaga from './searchSaga';

export function* rootSaga() {
    yield fork(investigationSaga);
    yield fork(articleNavigationSaga);
    yield fork(uboSaga);
    yield fork(metricSaga);
    yield fork(searchSaga);
}

// ignore below this line
export function* watchRequests() {
    console.log('watching');
    // create a channel to queue incoming requests
    const requestChannel = yield actionChannel('REQUEST', buffers.expanding());

    // create 'n' worker 'threads'
    for (let i = 0; i < 6; i++) {
        yield fork(handleRequest, requestChannel);
    }
}

export function* handleRequest(chan) {
    while (true) {
        const action = yield take(chan);
        console.log('start: ', action, action.payload);
        const response = yield call(mockPromise);
        console.log('end: ', action, action.payload, response);
    }
}

export function mockPromise() {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(true);
        }, 6000);
    });
}

export default rootSaga;
