import React from 'react';
import { MODAL_TYPE } from '@constants';
import { FormattedMessage } from 'react-intl';

const ReportDocumentNote = (props) => (
    <div className={'note-on-article ' + (props.isDragging ? 'dragging' : '')}>
        <div className="existing-note-on-article">
            <div className="existing-note-on-article--content">
                <div className="existing-note-on-article--header">
                    <FormattedMessage id="Report.DocumentItem.notes" />:
                </div>
                <div className="existing-note-on-article--note">{props.article.note}</div>
            </div>
            <div className="existing-note-on-article-actions">
                <button
                    className="existing-note-on-article-button"
                    onClick={() => {
                        props.openArticleNote(props.report.id, props.article);
                    }}
                >
                    <FormattedMessage id="General_CoreFunctionality_UIText_general.edit" />
                </button>
                <div className="vertical-divider" />
                <button
                    className="existing-note-on-article-button"
                    value={MODAL_TYPE.DELETE_NOTE_FROM_DOCUMENT}
                    onClick={props.handleDeleteNoteArticle}
                >
                    <FormattedMessage id="General_CoreFunctionality_UIText_general.delete" />
                </button>
            </div>
        </div>
    </div>
);

export default ReportDocumentNote;
