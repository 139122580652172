import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';
import { mean } from 'lodash';
import {
    paintBusinessNodes,
    paintIndividualsNodes,
    generateDefs,
    isBusinessNode,
    isIndividualNode,
    paintLinks,
    isActiveNode,
    calculateLinkCoords,
} from '@sagas/helpers/uboHelper';
import { UBO_ERRORS, UBO_EXTENDED_GRAPH_MAX_NODES, UBO_FILTER_DROPDOWN_OPTIONS, UBO_GRAPH } from '@constants';
import ReactTooltip from 'react-tooltip';
import { FormattedMessage } from 'react-intl';
import { throttle } from 'lodash';
import withFilter from '../Table/hoc/withFilter';
import { EmptyComponent } from '@utils/hoc';
import { GRAPH_TAGS } from '@sagas/helpers/uboGraphHelpers'
import { ReactComponent as BannerWarningIcon } from '../../../assets/icons/BannerWarningIcon.svg';
import { ReactComponent as BannerWarningIconDark } from '../../../assets/icons/BannerWarningIconDark.svg';

const isTheNode = (node) => node.id === '490726028';
const layerFilter = isActiveNode || isTheNode;
const linkLayerFilter = (link, nodes) => {
    return (
        nodes[link.sourceIndex] &&
        nodes[link.targetIndex] &&
        layerFilter(nodes[link.sourceIndex]) &&
        layerFilter(nodes[link.targetIndex])
    );
};

const layerNodes = (layer, nodes) => nodes.filter((node) => node.degreeOfSeparation === layer).length;

// ["669070018","004919486","989276399","410341176","985480701","778908111","004919486","093120871"]}
// 989276399
//// WARNING!! some uses of props here are by refs -- fix later

export default class UboGraph extends Component {
    static propTypes = {
        data: PropTypes.object.isRequired,
        updateSortingAndFiltering: PropTypes.func.isRequired,
        width: PropTypes.number,
        height: PropTypes.number,
        forceDistance: PropTypes.number,
        forceStrength: PropTypes.number,
        layerSize: PropTypes.number,
        leafWidth: PropTypes.number,
        zoomStep: PropTypes.number,
        zoomAdjustThrottleTime: PropTypes.number,
        filtering: PropTypes.object,
        prefix: PropTypes.string,
        isDarkMode: PropTypes.bool,
    };

    static defaultProps = {
        ...UBO_GRAPH,
        prefix: 'extended_',
    };

    constructor(props) {
        super(props);

        this.state = {
            zoomLevel: 1,
            scrollH: 0,
            scrollV: 0,
            height: UBO_GRAPH.height,
            width: UBO_GRAPH.width,
            isZoomBlocked: false,
        };

        this.zoomLevel = null;
        this.zoom = null;
        this.isZoomBlocked = false; // no need to have it in state, avoids rerender

        this.generateGraph = this.generateGraph.bind(this);
        this.handleZoomIn = this.handleZoomIn.bind(this);
        this.handleZoomOut = this.handleZoomOut.bind(this);
        this.handleNodeClick = this.handleNodeClick.bind(this);
        this.adjustZoom = throttle(this.adjustZoom, this.props.zoomAdjustThrottleTime); // throttling the zoom adjust function

        this.svg = null;
        this.simulation = null;
        this.svgElement = null;
        this.container = null;
    }

    generateGraph(data) {
        const { forceDistance, chargeStrength, layerSize } = this.props;
        //const { width, height } = document;
        const width = this.container.clientWidth || 800,
            height = 800;
        this.graphWidth = width;
        this.graphHeight = height;
        const { nodes, links } = data;

        if (nodes.length > UBO_EXTENDED_GRAPH_MAX_NODES) {
            return;
        }

        const filteredNodes = nodes.filter(layerFilter);
        const filteredLinks = links.filter((link) => linkLayerFilter(link, filteredNodes));

        this.svgElement = d3.select('#ubo-graph-container svg#uboGraph').on('wheel', () => {
            this.isZoomBlocked = true;
        });

        // init the svg
        this.svg = this.svgElement.attr('width', width).attr('height', height).select(`#${this.props.prefix}container`);

        const that = this;

        this.zoom = d3
            .zoom()
            .scaleExtent(UBO_GRAPH.zoomLevels)
            .on('zoom', function (event) {
                that.svg.attr('transform', event.transform);
                that.zoomLevel = event.transform;
            });

        this.svgElement.call(this.zoom);

        // init the force object that will put the icons layered in position
        this.simulation = d3
            .forceSimulation()
            .nodes(filteredNodes)
            .force(
                'link',
                d3.forceLink(filteredLinks).distance((d) => {
                    if (isGroup(d)) {
                        return forceDistance * 0.8;
                    }
                    return forceDistance;
                })
            )
            .force(
                'charge',
                d3.forceManyBody().strength((d) => {
                    if (!isIndividualNode(d)) {
                        return chargeStrength * 1.5;
                    }
                    return chargeStrength;
                })
            )
            .force(
                'gravity',
                d3.forceManyBody().strength(() => {
                    return 95;
                })
            )
            .force('center', d3.forceCenter(width / 2, height / 2))
            .force(
                'y',
                d3
                    .forceY()
                    .y(
                        (d) =>
                            (layerNodes(d.degreeOfSeparation, filteredNodes) * 100 + d.degreeOfSeparation * layerSize) *
                            -1
                    )
            )
            .force(
                'collision',
                d3.forceCollide().radius((d) => (isBusinessNode(d) ? 250 : 100))
            );

        // paint the links, nodes by business/individual
        paintLinks(this.svg, filteredLinks);
        paintIndividualsNodes(this.svg, filteredNodes.filter(isIndividualNode), this.props.prefix);
        paintBusinessNodes(this.svg, filteredNodes.filter(isBusinessNode), this.handleNodeClick);
        generateDefs(this.svg, this.props.prefix);

        const svg = this.svg;

        // this function will execute on each frame until it finds its final position
        this.simulation.on('tick', function () {
            // place the nodes
            svg.selectAll('.node').attr('transform', (d) => `translate(${d.x}, ${d.y})`);

            // place the icon in its position, for business nodes have them aligned center on X
            svg.selectAll('g.leafs').attr(
                'transform',
                (d) => `translate(${isBusinessNode(d) ? d.x - 100 : d.x}, ${d.y})`
            );

            svg.selectAll('.line')
                .attr('d', (d) => {
                    const { sourceX, sourceY, targetX, targetY, x, y } = calculateLinkCoords(d);
                    return `M${sourceX},${sourceY} S${x},${y} ${targetX},${targetY}`;
                })
                .attr('id', (d) => `link_${d.source.id}_${d.target.id}`)
                .attr('transform-origin', (d) => `${d.source.x} ${d.source.y}`)
                .attr('marker-end', `url(#${that.props.prefix}arrow)`);

            svg.selectAll('.line-rev')
                .attr('id', (d) => `link_rev_${d.source.id}_${d.target.id}`)
                .attr('d', (d) => {
                    const { sourceX, sourceY, targetX, targetY, x, y } = calculateLinkCoords(d);
                    return `M${targetX},${targetY} S${x},${y} ${sourceX},${sourceY}`;
                })
                .attr('transform-origin', (d) => `${d.source.x} ${d.source.y}`);

            // Add the share percentage text to the link lines
            svg.selectAll('.shares_group').attr('transform', (d) => {
                const x = mean([d.source.x, d.target.x]) + UBO_GRAPH.percentageTextOffsetX;
                const y = mean([d.source.y, d.target.y]) + UBO_GRAPH.percentageTextOffsetY;
                //if owns itself
                if (d.targetIndex === d.sourceIndex) {
                    return `translate(${d.source.x - 105}, ${d.source.y - 30})`;
                }
                // if its two way relationship
                if (d.isTwoWay) {
                    let curveX = UBO_GRAPH.curveOffsetX - 25;
                    if (d.sourceIndex > d.targetIndex) {
                        curveX = -curveX;
                    }
                    return `translate(${x + curveX}, ${y})`;
                } else {
                    return `translate(${x}, ${y})`;
                }
            });

            that.adjustZoom();
        });
    }

    coordinates(point) {
        let scale = this.zoom.scale(),
            translate = this.zoom.translate();
        return [(point[0] - translate[0]) / scale, (point[1] - translate[1]) / scale];
    }

    point(coordinates) {
        let scale = this.zoom.scale(),
            translate = this.zoom.translate();
        return [coordinates[0] * scale + translate[0], coordinates[1] * scale + translate[1]];
    }

    adjustZoom() {
        if (!this.isZoomBlocked) {
            const { width, height } = this.svg.node().getBBox();
            // take smallest ratio X vs Y
            const ratio = Math.min(this.graphWidth / width, this.graphHeight / height);
            if (ratio < 1) {
                // zoom out only
                const theSvgElement = d3.select('#ubo-graph-container svg');
                this.zoom.scaleTo(theSvgElement.transition().duration(750), ratio - 0.3 * ratio); // (0.3 * ratio) - an extra nudge to the zoom out
            }
        }
    }

    updateGraph() {
        const { forceDistance, forceStrength, leafWidth } = this.props;
        const { nodes, links } = this.props.data;

        const filteredNodes = nodes; //.filter(layerFilter);
        const filteredLinks = links; //.filter(link => linkLayerFilter(link, filteredNodes));
        this.simulation
            .nodes(filteredNodes)
            .force('link', d3.forceLink(filteredLinks).distance(forceDistance).strength(forceStrength))
            .force(
                'x',
                d3.forceX().x((d) => {
                    return (
                        (d.degreeOfSeparation - layerNodes(d.degreeOfSeparation, filteredNodes) / 2) * leafWidth * -1
                    );
                })
            )
            .restart()
            .alpha(0.3);

        paintLinks(this.svg, filteredLinks);
        paintIndividualsNodes(this.svg, filteredNodes.filter(isIndividualNode));
        paintBusinessNodes(this.svg, filteredNodes.filter(isBusinessNode), this.handleNodeClick);
    }

    handleNodeClick() {
        return;
    }

    componentDidMount() {
        this.generateGraph(this.props.data);
    }

    handleZoomIn() {
        const theSvgElement = d3.select('#ubo-graph-container svg');
        this.zoom.scaleBy(theSvgElement.transition().duration(750), 1.3);
        this.isZoomBlocked = true;
    }

    handleZoomOut() {
        const theSvgElement = d3.select('#ubo-graph-container svg');
        this.zoom.scaleBy(theSvgElement.transition().duration(750), 0.7);
        this.isZoomBlocked = true;
    }

    componentWillUnmount() {
        if (this.simulation) {
            this.simulation.stop();
        }
    }

    render() {
        const graphTag = GRAPH_TAGS.find((tag) => tag.value === this.props.filtering.filteringDropdownSelected);
        const graphClassName = graphTag ? graphTag.name : '';
        const nodesLimitExceeded =
            this.props.data && this.props.data.nodes && this.props.data.nodes.length > UBO_EXTENDED_GRAPH_MAX_NODES;

        return (
            <div id={'ubo-graph-container'} ref={(el) => (this.container = el)} className={graphClassName}>
                <div id={`ubo-graph-container${nodesLimitExceeded ? '__nodes-limit-exceeded' : ''}`}>
                    <GraphFilterDropdown
                        documentPreview={this.props.documentPreview}
                        updateSortingAndFiltering={this.props.updateSortingAndFiltering}
                        filtering={this.props.filtering}
                    />
                    {nodesLimitExceeded ? (
                        <UboMaxNodesError isDarkMode={this.props.isDarkMode} />
                    ) : (
                        <Fragment>
                            <ReactTooltip
                                type="dark"
                                border={true}
                                effect="solid"
                                className="tooltips notranslate"
                                place={'left'}
                            />
                            <div className={'ubo-graph-controls'} data-tip={'Zoom'}>
                                <div className={'zoom-control zoom-in'} onClick={this.handleZoomIn}>
                                    <span className="la-ZoomIn" />
                                </div>
                                <div className={'zoom-control zoom-out'} onClick={this.handleZoomOut}>
                                    <span className="la-ZoomOut" />
                                </div>
                            </div>
                            <svg
                                ref={(ref) => (this.svgElement = ref)}
                                id={'uboGraph'}
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <g id={this.props.prefix + 'container'} />
                            </svg>
                        </Fragment>
                    )}
                </div>
            </div>
        );
    }
}

// in order to use the same filter as in the table view, we try to emulate the table props and use an empty component instead of a table
const FilterDropdown = withFilter(EmptyComponent);

export const GraphFilterDropdown = ({ documentPreview, filtering = {}, updateSortingAndFiltering }) => {
    const handleUpdateFilter = (filter) => {
        updateSortingAndFiltering({
            filtering: { filteringDropdownSelected: parseInt(filter) },
        });
    };
    return (
        <div className={'graph-filter-container'}>
            <FilterDropdown
                documentPreview={documentPreview}
                filtering={{
                    filteringDropdownSelected: filtering.filteringDropdownSelected,
                    filteringDropdownOptions: UBO_FILTER_DROPDOWN_OPTIONS,
                    onSelectOption: handleUpdateFilter,
                }}
                pagination={{
                    onChangePagination: () => { },
                    pageNumber: 0,
                    pageSize: 0,
                }}
                data={{}}
            />
        </div>
    );
};

const UboMaxNodesError = (props) => (
    <div className={`ubo-error ${props.isDarkMode ? 'dark-mode' : ''}`}>
        <div className='ubo-error__icon'>
            {props.isDarkMode ? <BannerWarningIconDark /> : <BannerWarningIcon />}
        </div>
        <div className='ubo-error__message'>
            <FormattedMessage id={UBO_ERRORS.maxNodes.firstPart} values={{ maxNodes: UBO_EXTENDED_GRAPH_MAX_NODES }} />
        </div>
    </div>
);

const isGroup = (node) => node && node.children && node.children.filter(isIndividualNode).length > 10;
