import React from 'react';
import { capitalize } from 'lodash';
import NegativityLevelPill from '@MainSearch/components/negativeNewsVisualisations/negativityLevel/negativityLevelPill/NegativityLevelPill';
import NegativeNewsSnapshotLineGraph from '@MainSearch/components/negativeNewsVisualisations/snapshot/lineGraph/NegativeNewsSnapshotLineGraph';

const NegativityTermSnapshotItem = ({ termItem, handleNegativeTermClick }) => {
    const { term, count, negativity, buckets, languageCode } = termItem;
    const processedLanguageCode = capitalize(languageCode);
    const categoryName = languageCode ? `negativeNews${processedLanguageCode}` : '';
    const matomoTrackingPropertiesTermClick = {
        'data-gm-u-pn': 'nexisDiligence_snapshot',
        'data-gm-u-c': 'negativeNewsTerm',
        'data-gm-u-cc': 'negativeNewsTerm',
        'data-gm-u-e': 'pod_click',
        'data-gm-u-v': term,
        'data-gm-u-a': 'click',
    };

    return (
        <div className="negative-terms-container-item">
            <div className="negative-terms-container-column label">
                <p
                    className="negative-terms-container-column-term"
                    onClick={() => handleNegativeTermClick(term, processedLanguageCode, categoryName)}
                    {...matomoTrackingPropertiesTermClick}
                >
                    {term}
                </p>
                {languageCode && <p className="languageCode">{languageCode}</p>}
            </div>
            <div className="negative-terms-container-column negativity">
                <div className="negative-terms-container-item-negativity-pill">
                    <NegativityLevelPill level={negativity} isShortLabel={true} />
                </div>
            </div>
            <div className="negative-terms-container-column count">{count}</div>
            <div className="negative-terms-container-column buckets">
                <NegativeNewsSnapshotLineGraph buckets={buckets} />
            </div>
        </div>
    );
};

export default NegativityTermSnapshotItem;
