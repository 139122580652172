import React from 'react';
import FocusTrap from 'focus-trap-react';
import ReactDOM from 'react-dom';

class ConfirmationModal extends React.Component {
    constructor(props) {
        super(props);
        this.handlerEscape = this.handlerEscape.bind(this);
    }

    handlerEscape(event) {
        if (event.key === 'Escape' || event.key === 'Esc' || event.keyCode === 27) this.props.closeButtonHandler();
    }

    render() {
        let buttons = [];

        if (this.props.buttons) {
            for (let i = 0; i < this.props.buttons.length; i++) {
                let button = this.props.buttons[i],
                    classes = button.primary ? 'button-primary-sm' : 'button-secondary-sm';

                classes += button.isDisabled ? ' disabled' : '';
                buttons.push(
                    <button
                        type="button"
                        key={'app-confirmation-modal-button-' + Math.random() + '-' + i}
                        className={classes}
                        onClick={button.click ? button.click.bind(this) : null}
                        tabIndex={button.tabIndex ? button.tabIndex : null}
                    >
                        {button.text}
                    </button>
                );
            }
        }

        const modalPortal = document.getElementById('modal-portal');
        const modalContent = (
            <FocusTrap
                focusTrapOptions={{
                    fallbackFocus: '#dialog',
                }}
            >
                <div id="dialog" className={`app-confirmation-modal ${this.props.className ?? ''}`}>
                    <div className="app-confirmation-modal__wrapper" onKeyDown={this.handlerEscape}>
                        <div className="app-confirmation-modal__content-box">
                            <div className="app-confirmation-modal__content">
                                {typeof this.props.closeButtonHandler === 'function' ? (
                                    <button
                                        type="button"
                                        className="la-CloseRemove app-confirmation-modal__close-button"
                                        onClick={this.props.closeButtonHandler}
                                        tabIndex="-1"
                                    ></button>
                                ) : null}

                                {this.props.children}
                            </div>
                            {buttons.length > 0 ? (
                                <div className="app-confirmation-modal__buttons">{buttons}</div>
                            ) : null}
                        </div>
                    </div>
                </div>
            </FocusTrap>
        );

        return ReactDOM.createPortal(modalContent, modalPortal);
    }
}

export default ConfirmationModal;
