// @flow
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import withAutosave from '../HOC/WithAutosave';
import { compose } from 'redux';
import { withAppContext } from '@utils/contexts';
import ExcludeNews from './ExcludeNews';

type Props = {
    userIsAdmin: boolean,
    masterLock: boolean,
    excludeNewsWires: boolean,
    excludeNonBusinessNews: boolean,
    handleExcludeNewsWires: (event: SyntheticInputEvent<HTMLInputElement>) => void,
    handleExcludeNonBusinessNews: (event: SyntheticInputEvent<HTMLInputElement>) => void,
};

type InjectedProps = {|
    handleAutosave: (handler: (params: Object) => void, params: Object) => void,
|};

const ExcludeNewsWrapper = (props: Props & InjectedProps) => {
    const getExcludeNonBusinessNewsModalContent = () => {
        const infoItems = [
            'UserPreferences.ExcludeNonBusinessNews.Info.Option.DeathsObituaries',
            'UserPreferences.ExcludeNonBusinessNews.Info.Option.DeathsDeathRates',
            'UserPreferences.ExcludeNonBusinessNews.Info.Option.WeddingsEngagements',
            'UserPreferences.ExcludeNonBusinessNews.Info.Option.CookingEntertaining',
            'UserPreferences.ExcludeNonBusinessNews.Info.Option.BirthsBirthRates',
            'UserPreferences.ExcludeNonBusinessNews.Info.Option.SportsRecreation',
            'UserPreferences.ExcludeNonBusinessNews.Info.Option.Weather',
            'UserPreferences.ExcludeNonBusinessNews.Info.Option.ReviewsCriticism',
            'UserPreferences.ExcludeNonBusinessNews.Info.Option.ArtsCriticismReviews',
            'UserPreferences.ExcludeNonBusinessNews.Info.Option.LettersComments',
            'UserPreferences.ExcludeNonBusinessNews.Info.Option.Celebrities',
            'UserPreferences.ExcludeNonBusinessNews.Info.Option.Anniversaries',
            'UserPreferences.ExcludeNonBusinessNews.Info.Option.EditorialsOpinions',
        ];

        const content = infoItems.map((item, index) => (
            <div key={item} className="information-item">
                <FormattedMessage id={item} key={index} />
            </div>
        ));

        const title = [<FormattedMessage key={0} id="UserPreferences.ExcludeNonBusinessNews.Info.Title" />];
        const infoModalClass = 'exclude-non-business-news';

        return { content, title, infoModalClass };
    };

    const nonBusinessNewsInfo: {
        content?: Array<React$Element<'div'>>,
        title?: Array<React$Element<'div'>>,
        infoModalClass?: string,
    } = getExcludeNonBusinessNewsModalContent();

    return (
        <Fragment>
            <ExcludeNews
                {...props}
                type="news-wires"
                title="Exclude news wires"
                messageId="UserPreferences.NewsSearches.ExcludeNewsWires"
                checked={props.excludeNewsWires}
                onChange={props.handleExcludeNewsWires}
            />
            <ExcludeNews
                {...props}
                type="non-business-news"
                title="Exclude non-business news"
                messageId="UserPreferences.NewsSearches.ExcludeNonBusinessNews"
                onChange={props.handleExcludeNonBusinessNews}
                withInformationIcon={true}
                checked={props.excludeNonBusinessNews}
                infoModal={nonBusinessNewsInfo}
            />
        </Fragment>
    );
};

export default (compose(withAutosave(), withAppContext)(ExcludeNewsWrapper): React$StatelessFunctionalComponent<Props>);
