import React from 'react';

import AlertFrequency from 'scripts/pages/Alerts/components/AlertFrequency';

const ModalAlertFrequency = (props) =>
    props.isVisible ? (
        <AlertFrequency
            frequencyType={props.alert.frequencyType}
            hourOfDay={props.alert.hourOfDay}
            dayOfMonth={props.alert.dayOfMonth}
            dayOfWeek={props.alert.dayOfWeek}
            deliveryType={props.alert.deliveryType}
            attachType={props.alert.attachType}
            accessType={props.alert.accessType}
            shouldTriggerForNoResults={props.alert.shouldTriggerForNoResults}
            handleDropdownChange={props.handleDropdownChange}
            handleNoResultsToggle={props.handleNoResultsToggle}
            shouldEnableNoResultsWarning={false}
        />
    ) : (
        <span></span>
    );

export default ModalAlertFrequency;
