import React from 'react';
import RadioDateFilterComponent from '@MainSearch/components/RadioDateFilterComponent';

const DateRangePostFilter = (props) => {
    return (
        <div className={'dateRange-postfilter'}>
            <RadioDateFilterComponent {...props} fieldId={'dateRange-postfilter'} />
        </div>
    );
};

export default DateRangePostFilter;
