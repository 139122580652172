import {
    CLEAR_BACKGROUND_MESSAGES,
    SET_BACKGROUND_MESSAGES,
    SET_FAILURE_BACKGROUND_MESSAGES,
    SET_PROCESSING_BACKGROUND_MESSAGES,
    SET_SUCCESS_BACKGROUND_MESSAGES,
} from './BackgroundMessages.actions';
import { merge } from 'lodash';

function backgroundMessages(state = {}, action) {
    switch (action.type) {
        case SET_BACKGROUND_MESSAGES:
            return merge({}, state, {
                title: action.payload.title,
                message: action.payload.message,
                timeout: action.payload.timeout,
                messageParameters: action.payload.messageParameters,
                isVisible: true,
                shrink: true,
                close: false,
                type: action.payload.type,
            });

        case SET_PROCESSING_BACKGROUND_MESSAGES:
            return merge({}, state, {
                shrink: false,
                message: null,
                title: null,
            });

        case SET_SUCCESS_BACKGROUND_MESSAGES:
            return Object.assign({}, state, {
                title: action.payload.title,
                message: action.payload.message,
                messageParameters: action.payload.messageParameters,
                parameters: action.payload.parameters ? action.payload.parameters : null,
                icon: 'la-DeliveryComplete',
                timeout: action.payload.timeout,
                link: action.payload.link,
                shrink: true,
                isVisible: action.payload.isVisible ? action.payload.isVisible : state.isVisible,
                close: true,
                type: action.payload.type || state.type,
            });

        case SET_FAILURE_BACKGROUND_MESSAGES:
            return merge({}, state, {
                title: action.payload.title,
                message: action.payload.message,
                messageParameters: action.payload.messageParameters,
                icon: 'la-Error',
                timeout: action.payload.timeout,
                buttons: action.payload.buttons,
                actionLink: action.payload.actionLink,
                shrink: true,
                isVisible: action.payload.isVisible ? action.payload.isVisible : state.isVisible,
                close: true,
            });

        case CLEAR_BACKGROUND_MESSAGES:
            return merge({}, state, {
                title: null,
                message: null,
                icon: null,
                timeout: null,
                link: null,
                isVisible: false,
                shrink: false,
                buttons: false,
                messageParameters: '',
                actionLink: '',
                type: '',
            });

        default:
            return state;
    }
}

export default backgroundMessages;
