import React, { Component, createContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ALERTS_ENABLED_DEFAULT, PUBLIC_RECORDS_ENABLED_DEFAULT } from '@constants';
import { permissions, userHas } from '@permissions';

/**
 * A collection of higher order functions and components.
 * It contains 3 exports:
 * withAlertToggleView - wrap component in this HOC to toggle visibility based on flag
 * withAlertRouteToggle - wrap page component to redirect based on flag
 * default export - class to wrap the area of the app where to cast the toggle flag. connects to redux and sends to
 * children through context
 */

/******
 * APP CONTEXT
 *****/
const AppContext = createContext({ alertsEnabled: ALERTS_ENABLED_DEFAULT, isUserAnonymized: false });

// HOC to consume app context through the prop 'context'
export const withAppContext = (Component) => (props) =>
    <AppContext.Consumer>{(state) => <Component {...props} context={state} />}</AppContext.Consumer>;

// HOC to hide alert related components based on context
const alertToggleView = (Component) => (props) => {
    return props.context.alertsEnabled && <Component {...props} />;
};

// HOC to be used on components to toggle view based on context
export const withAlertToggleView = (Component) => withAppContext(alertToggleView(Component));

// Component to show when alert is disabled, needs to be inside a withRouter to redirect
const AlertRouteRedirecterComponent = (props) => {
    if (!props.router) {
        console.error('This alertRouteRedirecter needs to be inside a withRouter to redirect');
    } else {
        props.router.push('/start');
    }
    return null;
};
// Toggles alert based on received context
const alertRouteRedirecter = (Component) => (props) => {
    if (!props.context) {
        console.error('The alertRouteRedirecter needs to be inside an app context. Use withAlertToggle HOC instead');
        return <Component {...props} />;
    }
    return props.context.alertsEnabled ? <Component {...props} /> : <AlertRouteRedirecterComponent {...props} />;
};

// HOC to be used on alert pages to block or allow access
export const withAlertRouteToggle = (Component) => withAppContext(alertRouteRedirecter(Component));

// HOC to hide components based on anonymized flag
const anonymizedToggleView = (Component) => (props) => {
    return props.context.isUserAnonymized ? <Component {...props} /> : null;
};

// HOC to be used on components to toggle view based on anonymized
export const withAnonymizedToggleView = (Component) => withAppContext(anonymizedToggleView(Component));

const anonymizedHideView = (Component) => (props) => {
    return !props.context.isUserAnonymized && <Component {...props} />;
};

// HOC to be used on components to hide view based on anonymized
export const withAnonymizedHideView = (Component) => withAppContext(anonymizedHideView(Component));

const mipUserHideView = (Component) => (props) => {
    return !props.context.isUserMIP && <Component {...props} />;
};

// HOC to be used on components to hide view based on MIP Users
export const withMIPUserHideView = (Component) => withAppContext(mipUserHideView(Component));

// HOC to show component only on the provided location hash
const onPageToggleView = (Component, pagesHash) => (props) => {
    return window.location.hash && pagesHash.indexOf(window.location.hash) > -1 ? <Component {...props} /> : null;
};
export const withOnPageToggleView = (Component, pagesHash) => withAppContext(onPageToggleView(Component, pagesHash));

// HOC to show the component except on the provided location hash
const notOnPageToggleView = (Component, pagesHash) => (props) => {
    return window.location.hash && pagesHash.indexOf(window.location.hash) < 0 ? <Component {...props} /> : null;
};
export const withNotOnPageToggleView = (Component, pagesHash) =>
    withAppContext(notOnPageToggleView(Component, pagesHash));

// HOC to show the component except on the provided location hash
const adminHideView = (Component) => (props) => {
    return !props.userIsAdmin ? <Component {...props} /> : null;
};
export const withAdminHideView = (Component) => withAppContext(adminHideView(Component));

// HOC to hide the component if has disable prop
const hideOnDisabledProp = (Component) => (props) => {
    return !props.disabled ? <Component {...props} /> : null;
};
export const withHideOnDisabledProp = (Component) => withAppContext(hideOnDisabledProp(Component));

// Redirects based on route parameter, needs to be inside a withRouter to redirect
const Redirecter = (props) => {
    if (!props.router) {
        console.error('This Redirecter needs to be inside a withRouter to redirect');
    } else {
        props.router.push(props.redirectTo);
    }

    return null;
};

// HOC to be used on pages to redirect based on route parameter
export const withRouteRedirecter = (argsBasedOnProps) => (WrappedComponent) => (props) => {
    const { shouldRedirect, redirectTo } = argsBasedOnProps(props);

    return shouldRedirect ? <Redirecter {...props} redirectTo={redirectTo} /> : <WrappedComponent {...props} />;
};

/***
 * Component that connects to redux to fetch data and passes downwards using context
 */
class AppContextProvider extends Component {
    static propTypes = {
        settings: PropTypes.object,
    };

    static defaultProps = {
        settings: { alertsOn: ALERTS_ENABLED_DEFAULT, prsOn: PUBLIC_RECORDS_ENABLED_DEFAULT },
    };

    constructor(props) {
        super(props);

        this.state = {};

        this.handleChangeContext = this.handleChangeContext.bind(this);
    }

    handleChangeContext(state) {
        this.setState({ ...state });
    }

    render() {
        return (
            <AppContext.Provider
                value={{
                    alertsEnabled: this.props.settings.alertsOn,
                    publicRecordsEnabled: this.props.settings.prsOn,
                    uboEnabled: this.props.settings.uboEnabled,
                    uboTypeahead: this.props.settings.uboTypeahead,
                    publicRecordApplicationUrl: this.props.settings.publicRecordApplicationUrl,
                    onChangeContext: this.handleChangeContext,
                    isUserAnonymized: this.props.isAnonymized,
                    isUserMIP: this.props.isMIP,
                    isUserAdmin: userHas(permissions.admin.edit),
                    maxNumberOfArticles: this.props.maxNumberOfArticles,
                    maxNumberOfReports: this.props.maxNumberOfReports,
                    maxNumberOfHistoryEntries: this.props.maxNumberOfHistoryEntries,
                    areCombinedReportsAvailable: this.props.areCombinedReportsAvailable,
                    ...this.state,
                }}
            >
                {this.props.children}
            </AppContext.Provider>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    settings: state.user.appSettings,
    isAnonymized: state.user.isAnonymized,
    isMIP: state.user.isMIP,
    maxNumberOfArticles: state.user.maxNumberOfArticles,
    maxNumberOfHistoryEntries: state.user.maxNumberOfHistoryEntries,
    maxNumberOfReports: state.user.maxNumberOfReports,
    areCombinedReportsAvailable: state.user.areCombinedReportsAvailable,
    ...ownProps,
});

export default connect(mapStateToProps)(AppContextProvider);

/*******
 * END APP CONTEXT
 ******/
