import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import categoryUtils from '@utils/categoryUtils';
import { ERROR_CODES } from '@constants';
import { useSelector } from 'react-redux';
import utils from '@utils/utilities';
import formatRichMessage from '@utils/formatRichMessage';
import { ReactComponent as BannerErrorIcon } from '../../../../assets/icons/BannerErrorIcon.svg';
import { ReactComponent as BannerErrorIconDark } from '../../../../assets/icons/BannerErrorIconDark.svg';

const ResultListError = (props) => {
    const isDarkMode = useSelector((state) => state.user.preferences.generalSettings.isDarkMode);
    let errorMessageId = 'ResultsSnapshot.internalServerError';

    const formatCategoryName = (categoryName) => {
        const formattedCategoryName = utils.translateTitleForCustomNews(categoryName);
        let translatedCategoryName = formattedCategoryName.shouldBeTranslated
            ? formatRichMessage(formattedCategoryName.titleContent, props.intl)
            : formattedCategoryName.titleContent;

        const parentKey = categoryUtils.getParent(categoryName);
        if (parentKey) {
            return `${formatRichMessage(
                { id: 'General.categoryName.label.' + parentKey },
                props.intl
            )} - ${translatedCategoryName}`;
        }

        return translatedCategoryName;
    };

    if (props.errorCode === ERROR_CODES.INVALID_QUERY_STRING) errorMessageId = 'ResultsSnapshot.invalidNewsQuery';

    return (
        <div className={`embedded-message error-occurred notranslate ${isDarkMode ? 'dark-mode' : ''}`}>
            <div className="embedded-message__icon">
                {isDarkMode ? <BannerErrorIconDark/> :<BannerErrorIcon />}
            </div>
            <div className="embedded-message__message">
                <FormattedMessage
                    id={errorMessageId}
                    values={{ categories: props.title || formatCategoryName(props.category) }}
                />
            </div>
        </div>
    );
};

export default injectIntl(ResultListError);
