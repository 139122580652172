import React, { useState } from 'react';
import formatRichMessage from '@utils/formatRichMessage';
import { useIntl } from 'react-intl';

const FilterSearchBox = ({ setControlledInputValue, controlledInputValue, handleSearch, customClassName = '', dataTestId = '', placeholder = 'General_CoreFunctionality_UIText_general.search', disabled = false }) => {
    const intl = useIntl();
    const [inputField, setInputField] = useState('');

    const resetInputField = () => {
        setInputField('');
        setControlledInputValue && setControlledInputValue('');
    }

    const handleKeyPress = (e) => {
        if ((e.key === 'Enter' || e.keyCode === 13) && e.target.value !== '') {
            handleSearch(inputField);
        }
    }

    const changeInputField = (e) => {
        let input = e.target.value;
        setInputField(input);
        setControlledInputValue && setControlledInputValue(input);
    }
    
    return (
        <div className={`filter-search-box ${customClassName}`}>
            <input
                id='filter-search-box-input'
                placeholder={formatRichMessage({ id: placeholder }, intl)}
                value={controlledInputValue !== undefined ? controlledInputValue : inputField}
                onKeyPress={handleKeyPress}
                onChange={changeInputField}
                className='text-field-standard-sm'
                data-testid={`search-submit-input-${dataTestId}`}
                disabled={disabled}
            />
            {!inputField.length || (controlledInputValue !== undefined && !controlledInputValue.length) ? (
                <button
                    className='la-Search'
                    data-testid={`search-submit-button-${dataTestId}`}
                    disabled={disabled}
                />
            ) : (
                <button
                    className='la-ClearSearch'
                    onClick={resetInputField}
                    data-testid={`clear-search-button-${dataTestId}`}
                />
            )}
        </div>
    );
}

export default FilterSearchBox;
