export const INIT_VIEW_ARTICLE = 'INIT_VIEW_ARTICLE';
export const VIEW_ARTICLE = 'VIEW_ARTICLE';
export const SET_ARTICLE_NAVIGATION = 'SET_ARTICLE_NAVIGATION';
export const INIT_ARTICLE_NAVIGATION = 'INIT_ARTICLE_NAVIGATION';
export const ARTICLE_NAVIGATION_NEXT = 'ARTICLE_NAVIGATION_NEXT';
export const ARTICLE_NAVIGATION_PREV = 'ARTICLE_NAVIGATION_PREV';
export const ARTICLE_NAVIGATION_LOAD_NEXT_PAGE = 'ARTICLE_NAVIGATION_LOAD_NEXT_PAGE';
export const ARTICLE_NAVIGATION_LOAD_PREV_PAGE = 'ARTICLE_NAVIGATION_LOAD_PREV_PAGE';
export const ARTICLE_NAVIGATION_ERROR = 'ARTICLE_NAVIGATION_ERROR';
export const ARTICLE_NAVIGATION_FILL = 'ARTICLE_NAVIGATION_FILL';
export const SEARCH_DOC_ACCESS_EVENT = 'SEARCH_DOC_ACCESS_EVENT';
export const SET_ARTICLE_SHOW_MORE_ACTIVE = 'SET_ARTICLE_SHOW_MORE_ACTIVE';

let articleNavigationActions = {
    initArticleNavigation: (payload) => ({
        type: INIT_ARTICLE_NAVIGATION,
        payload,
    }),
    setArticleNavigation: (payload) => ({
        type: SET_ARTICLE_NAVIGATION,
        payload,
    }),
    initViewArticle: (payload) => ({
        type: INIT_VIEW_ARTICLE,
        payload,
    }),
    viewArticle: (payload) => ({
        type: VIEW_ARTICLE,
        payload,
    }),
    nextArticle: (payload) => ({
        type: ARTICLE_NAVIGATION_NEXT,
        payload,
    }),
    prevArticle: (payload) => ({
        type: ARTICLE_NAVIGATION_PREV,
        payload,
    }),
    loadNextPage: (payload) => ({
        type: ARTICLE_NAVIGATION_LOAD_NEXT_PAGE,
        payload,
    }),
    loadPreviousPage: (payload) => ({
        type: ARTICLE_NAVIGATION_LOAD_PREV_PAGE,
        payload,
    }),
    hasError: (payload) => ({
        type: ARTICLE_NAVIGATION_ERROR,
        payload,
    }),
    fillList: (payload) => ({
        type: ARTICLE_NAVIGATION_FILL,
        payload,
    }),
    searchDocAccessEvent: (payload) => ({
        type: SEARCH_DOC_ACCESS_EVENT,
        payload,
    }),
    setArticleShowMoreActive: (payload) => ({
        type: SET_ARTICLE_SHOW_MORE_ACTIVE,
        payload,
    }),
};

export default articleNavigationActions;
