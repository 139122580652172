import React from 'react';
import hasError from '@reusable/Table/hoc/hasError';
import hasLoader from '@reusable/Table/hoc/hasLoader';
import withCounter from '@reusable/Table/hoc/withCounter';
import withPagination from '@reusable/Table/hoc/withPagination';
import withSelection from '@reusable/Table/hoc/withSelection';
import withAutoLoad from '@reusable/Table/hoc/withAutoLoad';
import withDownload from '@reusable/Table/hoc/withDowload';
import composeTable from '@reusable/Table/composeTable';
import { compose } from 'redux';

import {
    ALL_ITEMS_LIST,
    BACKGROUND_MESSAGE_TYPE_DOWNLOAD,
    DOWNLOAD_HISTORY_DEFAULT_FILENAME,
    MODAL_TYPE,
} from '@constants';
import { DefaultHeader, DefaultSelectableRows, DefaultBottomLegend } from '@reusable/Table/components/defaults';
import { FormattedMessage, injectIntl } from 'react-intl';
import utils from '@utils/utilities';
import formatRichMessage from '@utils/formatRichMessage';
import {
    HistoryCheckAndType,
    HistoryDates,
    HistorySearchTerms,
    HistorySources,
    PRHistorySearchTerms,
} from '../HistoryDataFormatters';
import PopupModel from '@reusable/PopupModal/PopupBuilder';
import reduxStore from '@reduxStore';
import popupModelActions from '@reusable/PopupModal/redux/PopupModel.actions';
import backgroundActions from '@reusable/BackgroundMessage/redux/BackgroundMessages.actions';
import notificationService from '@utils/notificationService';
import hasOverlayLoader from '@reusable/Table/hoc/hasOverlayLoader';
import withDeleteAll from '@reusable/Table/hoc/withDeleteAll';

const Table = compose(
    // Composing the functionalities
    hasError(/* Default error component */),
    withAutoLoad,
    withSelection,
    withDeleteAll,
    withDownload,
    withCounter,
    withPagination,
    hasOverlayLoader(/* Big spinner that overlays the whole page*/)
)(
    composeTable(
        DefaultHeader,
        compose(
            // Adding a loader when is loading and
            hasLoader(/* Default loader component */)
        )(DefaultSelectableRows),
        DefaultBottomLegend
    )
);

const MyHistoryTableConsumer = (props) => {
    const transformRow = (object) => ({
        key: object.id ? object.id : object.recentActivityId,
        columns: {
            check: <HistoryCheckAndType data={object} />,
            sourceType: <HistorySources data={object} />,
            searchTerms: props.publicRecordsEnabled ? (
                <PRHistorySearchTerms data={object} />
            ) : (
                <HistorySearchTerms
                    data={object}
                    contentTypes={props.contentTypes}
                    onSearchFromHistory={handleSearchFromHistory}
                />
            ),
            numberOfDocs: utils.formatNumbersByUserLocale(props.language, object.numberOfResults),
            expirationDate: <HistoryDates data={object} />,
            costCode: object.costCode,
        },
        object: { ...object, name: object.searchQuery },
    });

    const getSelectedItems = () =>
        props.selection.selectedItems.map((selectedItem) =>
            selectedItem.object.id ? selectedItem.object.id : selectedItem.object.recentActivityId
        );
    const checkHeaderLabel = props.publicRecordsEnabled ? (
        <FormattedMessage id="History.table.column.publicRecords.checkType" />
    ) : (
        <FormattedMessage id="History.table.column.checkType" />
    );

    const headers = [
        { columnKey: 'check', label: checkHeaderLabel },
        { columnKey: 'sourceType', label: <FormattedMessage id="History.table.column.sourceType" /> },
        { columnKey: 'searchTerms', label: <FormattedMessage id="History.table.column.searchTerm" /> },
        { columnKey: 'numberOfDocs', label: <FormattedMessage id="History.table.column.numberOfDocuments" /> },
        { columnKey: 'expirationDate', label: <FormattedMessage id="History.table.column.expirationDate" /> },
        { columnKey: 'costCode', label: <FormattedMessage id="History.table.column.costCode" /> },
    ];

    const legend = [
        { label: <FormattedMessage id="History.table.legend.line1" /> },
        { label: <FormattedMessage id="History.table.legend.line2" /> },
        { label: <FormattedMessage id="History.table.legend.line3" /> },
    ];

    const handleLoadData = () => {
        return props.data.onLoadData().catch((e) => {
            console.error(e);
        });
    };

    const showPopupModal = (popupType) => {
        let currentDate = new Date(),
            fileName =
                DOWNLOAD_HISTORY_DEFAULT_FILENAME + utils.formatReportDateWithtTimezoneOffset(currentDate.getTime()),
            selectedItems = getSelectedItems();

        let maxNumberOfHistoryEntries = props.maxNumberOfHistoryEntries;
        let buttonIsDisabled = selectedItems.length > maxNumberOfHistoryEntries;
        let warningMessage;

        if (selectedItems.length > maxNumberOfHistoryEntries) {
            warningMessage = formatRichMessage({ id: 'PopupBuilder.warning.tooManyDocumentsSelected' }, props.intl, {
                count: maxNumberOfHistoryEntries,
            });
        } else {
            warningMessage = null;
        }

        let popupModel = new PopupModel.Builder(popupType)
            .setPopupProperty('isVisible', true)
            .setPopupProperty('fileName', fileName)
            .setPopupProperty('items', selectedItems)
            .setPopupProperty('action', (state) => downloadHistory(state))
            .setPopupProperty('totalArticlesCount', props.data.totalCount)
            .setPopupProperty('maxNumberOfArticles', maxNumberOfHistoryEntries)
            .setPopupProperty('buttonIsDisabled', buttonIsDisabled)
            .setPopupProperty('warning', warningMessage);

        popupModel.build();

        reduxStore.dispatch(popupModelActions.setPopupModel(popupModel));
    };

    const downloadHistory = (data) => {
        let { items, fileName, docType, resultListType } = data;
        let shouldDownloadFullList = resultListType === ALL_ITEMS_LIST;
        let language = props.language && props.language.replace('-', '_');

        let payload = {
            timezone: props.timezone,
            praOn: props.publicRecordsEnabled,
            fileName: fileName,
            fileType: docType,
            language: language,
        };

        props.history
            .onDownloadHistory(payload, items, shouldDownloadFullList)
            .then((response) => {
                let reportId = response.reportId;
                let buildId = response.id;
                reduxStore.dispatch(backgroundActions.clearBackgroundMessages());
                reduxStore.dispatch(
                    backgroundActions.setBackgroundMessages({
                        title: 'ReportBuilderPage_BackgroundMessage.processing',
                        message: 'ReportBuilderPage_BackgroundMessage.processingMessage',
                        type: BACKGROUND_MESSAGE_TYPE_DOWNLOAD,
                    })
                );
                notificationService.pollReportDownload(reportId, buildId);
            })
            .catch(() => {
                utils.showNotificationsMessage({
                    messageText: 'History.failedToDownloadHistoryData.ErrorMessage',
                    messageType: 'system-error',
                });
            });
    };

    const handleSearchFromHistory = (id) => {
        props.additionalData
            .onRetrieveAdditionalData(id, props.publicRecordsEnabled, props.onHistoryRunSearch)
            .catch(() => {
                utils.showNotificationsMessage({
                    messageText: 'History.noHistoryData.ErrorMessage',
                    messageType: 'system-error',
                });
            });
    };

    const showDeleteAllPopupModal = (popupType) => {
        let popupModel = new PopupModel.Builder(popupType)
            .setPopupProperty('isVisible', true)
            .setPopupProperty('acceptAction', () => deleteAllHistory());

        popupModel.build();

        reduxStore.dispatch(popupModelActions.setPopupModel(popupModel));
    };

    const deleteAllHistory = () => {
        props.history
            .onDeleteAllHistory()
            .then(() => {
                reduxStore.dispatch(backgroundActions.clearBackgroundMessages());
                props.data.onChangeLoadState(false, props.data.onResetState);
            })
            .catch(() => {
                utils.showNotificationsMessage({
                    messageText: 'History.deleteHistoryData.ErrorMessage',
                    messageType: 'system-error',
                });
            });
    };

    const HISTORY_MESSAGES = {
        errorMessage: 'History.failedToGetHistoryData.ErrorMessage',
    };

    const HISTORY_DROPDOWN_MESSAGES = {
        clearAllSelectedItems: 'SelectedDocumentDropdown.clearAllSelectedItems',
        noSelectedItems: 'SelectedDocumentDropdown.noSelectedItems',
        selectedItems: 'SelectedDocumentDropdown.selectedItems',
        selectedItemsButton: 'SelectedDocumentDropdown.selectedItemsButton',
        myItems: 'History.tabs.myHistory',
    };

    return (
        <div className={'generic-table history-table myHistory-table'}>
            <Table
                {...props}
                headers={headers}
                data={{
                    ...props.data,
                    language: props.language,
                    rows: props.data.rows && props.data.rows.map(transformRow),
                    onLoadData: handleLoadData,
                    delete: {
                        onDeleteData: showDeleteAllPopupModal,
                        popupType: MODAL_TYPE.DELETE_ALL_HISTORY,
                    },
                    download: {
                        onDownloadData: showPopupModal,
                        popupType: MODAL_TYPE.DOWNLOAD_HISTORY,
                    },
                }}
                onTransformRow={transformRow}
                selection={{
                    ...props.selection,
                }}
                defaultMessages={HISTORY_MESSAGES}
                dropdownMessages={HISTORY_DROPDOWN_MESSAGES}
                legend={legend}
            />
        </div>
    );
};

export default injectIntl(MyHistoryTableConsumer);
