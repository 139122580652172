import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DateRangeSelect from './DateRangeSelect';
import {
    CALENDAR_DATE_FORMAT_BE,
    CALENDAR_DATE_FORMAT_FE,
    CALENDAR_RANGE_DELIMITER,
    RANGE_TYPE_ALL,
    RANGE_TYPE_CUSTOM,
} from '@constants';
import utils from '@utils/utilities';
import { injectIntl } from 'react-intl';
import formatRichMessage from '@utils/formatRichMessage';

export const isCustomDateRange = (range) => range && range.indexOf(CALENDAR_RANGE_DELIMITER) > -1; // not the best way to do this, but good enough

class DateRangesDropdown extends Component {
    static propTypes = {
        onChange: PropTypes.func,
        dateFormat: PropTypes.string,
        dateFormatOutbound: PropTypes.string,
        dateRanges: PropTypes.array,
        dateRangeFormat: PropTypes.string,
        dateRange: PropTypes.string,
        disabled: PropTypes.bool, // this should be the generic disabled prop,
        customDataTestId: PropTypes.string || undefined,
    };

    static defaultProps = {
        dateFormat: CALENDAR_DATE_FORMAT_FE,
        dateFormatOutbound: CALENDAR_DATE_FORMAT_BE,
        dateRanges: [],
        dateRangeDelimiter: CALENDAR_RANGE_DELIMITER,
        disabled: false,
        customDataTestId: '',
    };

    constructor(props) {
        super(props);

        this.handleDateRangeChange = this.handleDateRangeChange.bind(this);
        this.handleDropdownChange = this.handleDropdownChange.bind(this);
        this.updateRange = this.updateRange.bind(this);
        this.getRange = this.getRange.bind(this);
        this.translateDateRange = this.translateDateRange.bind(this);

        let selectedOption = this.getOption(props.dateRange);
        let selectedRange = this.getRange(selectedOption, props);

        this.state = {
            selectedOption,
            selectedRange,
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        let selectedOption = this.getOption(nextProps.dateRange);
        let selectedRange = this.getRange(selectedOption, nextProps);

        if (this.state.selectedOption !== selectedOption || this.state.selectedRange !== selectedRange) {
            this.setState({
                selectedOption,
                selectedRange,
            });
        }
    }

    handleDropdownChange(e) {
        const value = e.target.value;
        this.setState({ selectedOption: value }, () => this.updateRange());
    }

    handleDateRangeChange(from, to) {
        const selectedRange = { from, to };
        this.setState({ selectedRange }, () => this.updateRange());
    }

    updateRange() {
        if (this.state.selectedOption === RANGE_TYPE_CUSTOM) {
            if (this.state.selectedRange.from && this.state.selectedRange.to) {
                const formattedDates = {
                    from: utils
                        .getUserLocaleDateWithoutTimezone(this.state.selectedRange.from)
                        .format(this.props.dateFormatOutbound),
                    to: utils
                        .getUserLocaleDateWithoutTimezone(this.state.selectedRange.to)
                        .format(this.props.dateFormatOutbound),
                };
                this.props.onChange(formattedDates.from + this.props.dateRangeDelimiter + formattedDates.to);
            }
        } else {
            this.props.onChange(this.state.selectedOption);
        }
    }

    getRange(selectedOption, props) {
        let selectedRange = { from: null, to: null };
        if (selectedOption === RANGE_TYPE_CUSTOM) {
            const dateRangeSplit = props.dateRange.split(props.dateRangeDelimiter);
            if (dateRangeSplit && dateRangeSplit.length) {
                selectedRange = {
                    from: utils.getDateFromString(dateRangeSplit[0], props.dateFormatOutbound),
                    to: utils.getDateFromString(dateRangeSplit[1], props.dateFormatOutbound),
                };
            }
        }
        return selectedRange;
    }

    getOption = (range) => (range ? (isCustomDateRange(range) ? RANGE_TYPE_CUSTOM : range) : RANGE_TYPE_ALL);

    translateDateRange(dateRange) {
        return formatRichMessage({ id: 'General.DateRange.' + dateRange }, this.props.intl);
    }

    render() {
        const isCustomDateSelected = this.state.selectedOption === RANGE_TYPE_CUSTOM;

        return (
            <div
                className={`${isCustomDateSelected && 'custom-date-selected'} date-ranges-dropdown`}
                data-track={'date-ranges-dropdown'}
            >
                <div className="sort-date-range">
                    {this.props.children}

                    <select
                        onChange={this.handleDropdownChange}
                        disabled={this.props.disabled || this.props.disabledDropdown}
                        value={this.getOption(this.state.selectedOption)}
                        data-testid={this.props.customDataTestId}
                    >
                        {this.props.dateRanges.map((dateRange, index) => (
                            <option key={index} value={dateRange}>
                                {this.translateDateRange(dateRange)}
                            </option>
                        ))}
                    </select>
                </div>

                {isCustomDateSelected && (
                    <div className={'custom-date-picker'}>
                        <DateRangeSelect
                            onRangeSelect={this.handleDateRangeChange}
                            disabled={this.props.disabled || this.props.disabledDropdown}
                            dateFormat={this.props.dateFormat}
                            initialValues={this.state.selectedRange}
                        />
                    </div>
                )}
                {this.props.adminLock}
            </div>
        );
    }
}

export default injectIntl(DateRangesDropdown);
