import React, { createContext } from 'react';
import { connect } from 'react-redux';
import utils from '@utils/utilities';

export const ThemeSwitcherContext = createContext({ isDarkMode: false, toggleTheme: () => null });

const ThemeSwitcherProvider = (props) => {
    const toggleTheme = () => utils.saveStylingThemeToggle(!props.isDarkMode);
    const { isDarkMode } = props;
    const contextValues = { isDarkMode, toggleTheme };

    return <ThemeSwitcherContext.Provider value={contextValues}>{props.children}</ThemeSwitcherContext.Provider>;
};

const mapStateToProps = function (state) {
    return {
        isDarkMode: state.user.preferences.generalSettings.isDarkMode,
    };
};

export default connect(mapStateToProps)(ThemeSwitcherProvider);
