import React, { Fragment } from 'react';
import utils from '@utils/utilities';
import { DefaultCounter, DefaultLoader } from '../components/defaults';
import * as DilProps from '@utils/props';
import hasLoader from './hasLoader';
/**
 * Adds a counter and displays loader while is loading, good for dynamic tables
 * @param TableComponent
 */

const withCounter = (TableComponent, Counter = DefaultCounter) => {
    const CounterLoader = () => (
        <div className="table-count">
            <DefaultLoader size={'small'} inline={'inline'} />
        </div>
    );

    const CounterWithLoader = hasLoader(CounterLoader)(Counter);

    const withCounter = (props) => (
        <Fragment>
            <CounterWithLoader data={{ ...props.data, pagination: props.pagination }} size={'small'} />
            <TableComponent {...props} />
        </Fragment>
    );

    withCounter.propTypes = {
        data: DilProps.TableData.isRequired,
    };

    withCounter.displayName = `withCounter(${utils.getDisplayName(TableComponent)})`;

    return withCounter;
};

export default withCounter;
