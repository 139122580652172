import { merge } from 'lodash';
import { SET_SCROLL } from './ScrollingPage.actions';

function scrollingPage(state = {}, action) {
    switch (action.type) {
        case SET_SCROLL:
            let page = {};
            let pageName = action.payload.page;
            let scrollY = action.payload.scrollY;
            page[pageName] = scrollY;
            return merge({}, state, page);
        default:
            return state;
    }
}
export default scrollingPage;
