import { ISO_COUNTRIES } from '@constants';

export const getCountryName = (countryCode) => {
    const hasCountryCode = Object.prototype.hasOwnProperty.call(ISO_COUNTRIES, countryCode);

    if (hasCountryCode) {
        return ISO_COUNTRIES[countryCode];
    } else {
        return countryCode;
    }
};
