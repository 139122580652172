import React from 'react';
import PropTypes from 'prop-types';
import DateRangesDropdown from './DateRangesDropdown';
import withAdminLock from '@reusable/AdminLock/hoc/withAdminLock';
import { Lock as LockProp } from '@utils/props';

// Layer which adds category specific text and handles the lock part

export const CategoryDateSelect = ({ locks, ...props }) => {
    const DateRangesDropdownWithLock = withAdminLock(locks.id, locks.masterId)(DateRangesDropdown);

    return (
        <div className={'category-date-select'}>
            <div className={'category-text'}>{props.children}</div>
            <DateRangesDropdownWithLock
                onChange={props.onChange}
                dateRanges={props.dateRanges}
                dateRange={props.dateRange}
                disabled={props.disabled}
                customDataTestId={props.dataTestId}
            />
        </div>
    );
};

CategoryDateSelect.propTypes = {
    onChange: PropTypes.func.isRequired,
    dateRanges: PropTypes.array,
    locks: LockProp.isRequired,
};

CategoryDateSelect.defaultProps = {
    dateRanges: [],
};

export default CategoryDateSelect;
