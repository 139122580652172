import React from 'react';
import { FormattedMessage } from 'react-intl';
import { DOWNLOAD_ENTITIES_FORMAT_TYPE } from '@constants';

const ModalDownloadEntitiesFormat = ({ isVisible, downloadEntitiesFormat, downloadEntitiesFormatChanged, isReadOnly }) => {
    if (!isVisible) {
        return <span></span>;
    }

    return (
        <div className="popup-modal__file-format">
            <div className="popup-field-name">
                <FormattedMessage id={'General_CoreFunctionality_fileFormat'} />
            </div>
            <div className="radio-list">
                <div className="custom-radio">
                    <input
                        type="radio"
                        id="download-entities-format"
                        checked={downloadEntitiesFormat === DOWNLOAD_ENTITIES_FORMAT_TYPE.TABLE_FORMAT}
                        disabled={isReadOnly}
                        onChange={() => {
                            downloadEntitiesFormatChanged(DOWNLOAD_ENTITIES_FORMAT_TYPE.TABLE_FORMAT);
                        }}
                    />
                    <label htmlFor="download-entities-format" className="radio-label">
                        <FormattedMessage id='BatchScreening.page.download.entities.table' />
                    </label>
                </div>
                <div className="custom-radio">
                    <input
                        type="radio"
                        disabled={isReadOnly}
                        id="upload-entities-format"
                        checked={downloadEntitiesFormat === DOWNLOAD_ENTITIES_FORMAT_TYPE.UPLOAD_FORMAT}
                        onChange={() => {
                            downloadEntitiesFormatChanged(DOWNLOAD_ENTITIES_FORMAT_TYPE.UPLOAD_FORMAT);
                        }}
                    />
                    <label htmlFor="upload-entities-format" className="radio-label" id="">
                        <FormattedMessage id='BatchScreening.page.download.entities.upload' />
                    </label>
                </div>
            </div>
        </div>
    );
};

export default ModalDownloadEntitiesFormat;
