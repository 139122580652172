import React, { Component } from 'react';
import utils from '@utils/utilities';
import * as DilProps from '@utils/props';
import { POLLING_STATUS } from 'scripts/constants';

/**
 * Simple HOC to trigger onLoadData on mount for any component that has the "data: DilProps.TableData" as prop
 * @param TableComponent
 * @returns {withAutoLoad}
 */

const withAutoLoad = (TableComponent) => {
    class withAutoLoad extends Component {
        static propTypes = {
            data: DilProps.TableData.isRequired,
        };

        componentDidMount() {
            this.props.data && this.props.data.onLoadData();
        }

        componentDidUpdate(prevProps) {
            if (
                this.props.autoRefresh &&
                prevProps.autoRefresh !== this.props.autoRefresh &&
                this.props.autoRefresh === POLLING_STATUS.FINISHED
            )
                this.props.data && this.props.data.onLoadData();
        }

        render() {
            return <TableComponent {...this.props} />;
        }
    }

    withAutoLoad.displayName = `withAutoLoad(${utils.getDisplayName(TableComponent)})`;

    return withAutoLoad;
};

export default withAutoLoad;
