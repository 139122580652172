import React from 'react';
import { hashHistory, withRouter } from 'react-router';
import breadcrumbsActions from '@reusable/Breadcrumbs/redux/Breadcrumbs.actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import investigationActions from '@MainSearch/redux/Investigation.actions';
import searchParamsActions from '@MainSearch/redux/SearchParams.actions';
import searchUtils from '@MainSearch/SearchUtils';
import { ACTION_TYPES, RESET_LOAD_DOCS_LISTENER } from '@sagas/constants/investigationConstants';

import {
    BREADCRUMBS,
    FILTER_INFO,
    FUZZY_NAMES,
    POST_FILTER_COMPANY,
    POST_FILTER_COMPANY_MENTIONS,
    POST_FILTER_PERSON_MENTIONS,
    POST_FILTER_DATE_RANGE,
    POST_FILTER_EXCLUDE_TERMS,
    POST_FILTER_FULL_GEOGRAPHY,
    POST_FILTER_GEOGRAPHY,
    POST_FILTER_INCLUDE_TERMS,
    POST_FILTER_LANGUAGE,
    POST_FILTER_PROXIMITY,
    POST_FILTER_SEARCH_CONTENT_TYPE,
    POST_FILTER_SEARCH_PUBLICATION_NAME,
    POST_FILTER_SEARCH_SOURCE_NAME,
    POST_FILTER_SEARCH_QUERY,
    POST_FILTER_SEARCH_QUERY_TYPE,
    POST_FILTER_SORT,
    POST_FILTER_SOURCE,
    POST_FILTER_SOURCE_TYPE,
    POSTFILTER_COMPONENTS,
    PRE_FILTER_QUERY,
    POST_FILTER_SEARCH_SPECIFICALLY,
    POST_FILTER_DOCKET_STATUS,
    POST_FILTER_SEARCH_NAME_PARTY_ENTITY,
    POST_FILTER_DUNS_FILTER,
    POST_FILTER_SUBJECT,
    POST_FILTER_INDUSTRY,
    POST_FILTER_FUZZY_SEARCH,
    COST_CODE_KEY,
    POST_FILTERS_KEY,
    CONTENT_SOURCE_KEY,
    POST_FILTER_FUZZY_THRESHOLD,
    IS_CUSTOM_FUZZY,
    POST_FILTER_EXCLUDE_NEWS_WIRES,
    POST_FILTER_EXCLUDE_NON_BUSINESS_NEWS,
    SUGGESTED_NAMES,
    AND,
    POST_FILTER_NEGATIVITY_LEVELS,
    POST_FILTER_TERMS,
    CATEGORY_NAMES,
    POST_FILTER_ESG_FACTORS,
    HISTORY_PAGE_TITLE,
} from '@constants';
import BreadCrumbsModel from '@utils/breadCrumbBuilder';
import historyActions from './redux/History.actions';
import categoryUtils from '@utils/categoryUtils';
import PopupModal from '@reusable/PopupModal/PopupModal.index';
import { FormattedMessage, injectIntl } from 'react-intl';
import TabbedContent from '@reusable/TabbedContent/TabbedContent';
import { compose } from 'redux';

import HistoryTable from './components/tables/HistoryTable';
import { withAppContext } from '@utils/contexts';
import adHocSearchActions from '@reusable/AdHocSearch/redux/AdHocSearch.actions';
import editSearchActions from '../StartPage/redux/EditSearch.actions';
import formatRichMessage from '@utils/formatRichMessage';
import sanctionsRiskActions from '../MainSearch/components/sanctionsRisk/redux/SanctionsRisk.actions';

class History extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.handleSearchFromHistory = this.handleSearchFromHistory.bind(this);
        this.historyTabbedContent = this.historyTabbedContent.bind(this);
    }

    componentDidMount() {
        document.title = HISTORY_PAGE_TITLE;
        this.props.resetQuery();
        this.props.updatePrevPath(this.props.location);
        this.setBreadcrumbs();
        this.props.updateAdHocSearchObject({});
        this.props.emptyEditSearch({});
    }

    setBreadcrumbs() {
        let breadcrumbs = new BreadCrumbsModel.Builder(BREADCRUMBS.HISTORY).build().breadcrumbs;
        this.props.updateBreadcrumbs(breadcrumbs);
    }

    historyTabbedContent() {
        let historyTabbedContent = [];

        historyTabbedContent.push(this.regularHistoryContent());

        if (this.props.context.publicRecordsEnabled) {
            historyTabbedContent.push(this.publicRecordsHistoryContent());
        }

        return historyTabbedContent;
    }

    regularHistoryContent = () => {
        return {
            label: formatRichMessage({ id: 'History.tabs.myHistory' }, this.props.intl),
            content: (
                <HistoryTable
                    onHistoryRunSearch={this.handleSearchFromHistory}
                    publicRecordsEnabled={false}
                    language={this.props.language}
                    timezone={this.props.timezone}
                    contentTypes={this.props.contentTypes}
                />
            ),
        };
    };

    publicRecordsHistoryContent() {
        return {
            label: formatRichMessage({ id: 'History.tabs.PRHistory' }, this.props.intl),
            content: (
                <HistoryTable
                    onHistoryRunSearch={this.handleSearchFromHistory}
                    publicRecordsEnabled={this.props.context.publicRecordsEnabled}
                    timezone={this.props.timezone}
                    language={this.props.language}
                    contentTypes={this.props.contentTypes}
                />
            ),
        };
    }

    async handleSearchFromHistory(searchData) {
        let prefilterQuery = '';
        let category = categoryUtils.postfilterToCategory(searchData);
        if (categoryUtils.isDnbCategory(category)) {
            category = searchData.uboSubcategory;
        }
        let dnbSelectedCategories = { selected: searchData.dnbSelectedCategories };
        let path =
            '/main-search?searchType=' +
            searchData.searchQueryType +
            '&q=' +
            searchData.searchQuery +
            '&list=true&category=' +
            category;
        if (searchData.prefilterQuery) {
            prefilterQuery = `${AND} ${searchData.prefilterQuery}`;
            path += '&prefilterQuery=' + prefilterQuery;
        }

        let filteredFields = { [category]: [] };

        this.props.sendInvestigationAction({ type: RESET_LOAD_DOCS_LISTENER, payload: null });
        this.props.resetSanctionsRisk();

        Object.keys(POSTFILTER_COMPONENTS).forEach((key) => {
            if (POSTFILTER_COMPONENTS[key].listed) {
                if (searchData[FILTER_INFO[key]] && searchData[FILTER_INFO[key]].length) {
                    filteredFields[category].push(FILTER_INFO[key]);
                }
            }
        });

        if (filteredFields[category].length) {
            path += '&filteredFields=' + encodeURIComponent(JSON.stringify(filteredFields));
        }

        let editHistorySearch = {
            category: category,
            postFilters: {},
            costCode: '',
            contentSource: '',
        };
        this.props.updateEditSearch(editHistorySearch);
        if (searchData.contentSource) {
            this.props.updateEditSearchProperty(category, CONTENT_SOURCE_KEY, searchData.contentSource);
        }
        editHistorySearch.postFilters.dateRange = searchData.dateRange;
        this.props.updateEditSearchProperty(
            editHistorySearch.category,
            POST_FILTERS_KEY,
            editHistorySearch.postFilters
        );
        this.props.updateEditSearchProperty(editHistorySearch.category, COST_CODE_KEY, searchData.costCode);

        this.props.updateQueryString(searchData.searchQuery);
        this.props.setBooleanTerms(prefilterQuery);
        this.props.updateQueryType(searchData.searchQueryType);

        let postFilters = {};
        let postFilterKeys = [
            FUZZY_NAMES,
            SUGGESTED_NAMES,
            PRE_FILTER_QUERY,
            POST_FILTER_SEARCH_QUERY,
            POST_FILTER_SEARCH_QUERY_TYPE,
            POST_FILTER_SEARCH_SPECIFICALLY,
            POST_FILTER_SEARCH_NAME_PARTY_ENTITY,
            POST_FILTER_SORT,
            POST_FILTER_INCLUDE_TERMS,
            POST_FILTER_EXCLUDE_TERMS,
            POST_FILTER_PROXIMITY,
            POST_FILTER_DATE_RANGE,
            POST_FILTER_GEOGRAPHY,
            POST_FILTER_FULL_GEOGRAPHY,
            POST_FILTER_LANGUAGE,
            POST_FILTER_SOURCE,
            POST_FILTER_SOURCE_TYPE,
            POST_FILTER_COMPANY_MENTIONS,
            POST_FILTER_PERSON_MENTIONS,
            POST_FILTER_COMPANY,
            POST_FILTER_SEARCH_CONTENT_TYPE,
            POST_FILTER_SEARCH_PUBLICATION_NAME,
            POST_FILTER_SEARCH_SOURCE_NAME,
            POST_FILTER_DOCKET_STATUS,
            POST_FILTER_DUNS_FILTER,
            POST_FILTER_SUBJECT,
            POST_FILTER_INDUSTRY,
            POST_FILTER_FUZZY_SEARCH,
            POST_FILTER_FUZZY_THRESHOLD,
            IS_CUSTOM_FUZZY,
            POST_FILTER_NEGATIVITY_LEVELS,
            POST_FILTER_TERMS,
            POST_FILTER_ESG_FACTORS,
        ];

        if (this.props.newsExcludeTogglesEnabled) {
            postFilterKeys = [...postFilterKeys, POST_FILTER_EXCLUDE_NEWS_WIRES, POST_FILTER_EXCLUDE_NON_BUSINESS_NEWS];
        }


        postFilterKeys.forEach((key) => {
            if (key === FUZZY_NAMES) {
                postFilters[FUZZY_NAMES] = searchData[FUZZY_NAMES].map((item) => {
                    return { name: item, selected: true };
                });
            } else if (key === SUGGESTED_NAMES) {
                postFilters[SUGGESTED_NAMES] = searchData[SUGGESTED_NAMES].map((item) => {
                    return { name: item, selected: true };
                });
            } else {
                postFilters[key] = searchData[key];
            }
        });

        // Append existing newsQueries to history post-filters
        if (searchData.newsQueries && searchData.newsQueries.length) {
            postFilters.newsQueries = searchData.newsQueries;
        }

        this.props.updateHistoryCategory(category);

        if (categoryUtils.isDnbCategory(category)) {
            postFilters.term = searchData.term;
        }

        if (searchData.category === CATEGORY_NAMES.NEGATIVE_NEWS && searchData.terms && searchData.terms.length > 0) {
            this.props.sendInvestigationAction({ type: ACTION_TYPES.handleNegativeTermFilter });
        }

        await searchUtils.searchArticlesFromHistory({
            searchQuery: searchData.searchQuery,
            searchQueryType: searchData.searchQueryType,
            prefilterQuery: searchData.prefilterQuery,
            postFilters: postFilters,
            categoryName: category,
            contentSource: searchData.contentSource,
            newsSource: searchData.newsSource,
            dnbSelectedCategories: dnbSelectedCategories,
            lastSearchRunDate: searchData.createdDate,
        });
        hashHistory.push(path);
    }

    render() {
        return (
            <div className="history-page">
                {this.props.popupModel.isVisible && <PopupModal />}

                <h1 className="manage-alerts-header notranslate">
                    <FormattedMessage id="BREADCRUMBS.history" />
                </h1>

                <div className="manage-alerts-body">
                    <TabbedContent visible={true} defaultTabIndex={0} tabs={this.historyTabbedContent()} />
                </div>
            </div>
        );
    }
}

const mapStateToProps = function (state) {
    return {
        searchResults: state.searchResults,
        timezone: state.user.timezone,
        popupModel: state.popupModel,
        language: state.user.preferences.language,
        newsExcludeTogglesEnabled: state.user.newsExcludeTogglesEnabled,
        contentTypes: state.user.userOfferings,
    };
};

const mapDispatchToProps = function (dispatch) {
    return bindActionCreators(
        {
            updateBreadcrumbs: breadcrumbsActions.updateBreadcrumbs,
            updatePrevPath: breadcrumbsActions.updatePrevPath,
            updateQueryString: searchParamsActions.updateQueryString,
            setBooleanTerms: searchParamsActions.setBooleanTerms,
            updateQueryType: searchParamsActions.updateQueryType,
            updateHistoryCategory: historyActions.updateHistoryCategory,
            updateAdHocSearchObject: adHocSearchActions.updateAdHocSearchObject,
            updateEditSearch: editSearchActions.updateEditSearch,
            emptyEditSearch: editSearchActions.emptyEditSearch,
            updateEditSearchProperty: editSearchActions.updateEditSearchProperty,
            sendInvestigationAction: investigationActions.investigationAction,
            resetSanctionsRisk: sanctionsRiskActions.resetSanctionsRisk,
            resetQuery: searchParamsActions.resetQuery,
        },
        dispatch
    );
};

export { History as TestHistory };
export default compose(connect(mapStateToProps, mapDispatchToProps), withRouter, withAppContext, injectIntl)(History);
