import React from 'react';
import { FormattedMessage } from 'react-intl';

const ErrorMessage = (props) => {
    return (
        <div className="error-message-wrapper">
            <div className="error-message-content">
                <h3 className="error-message-content__header">
                    <FormattedMessage id={props.header} />
                </h3>
                <div className="error-message-content__body">
                    <FormattedMessage id={props.body} />
                    <button className="button-primary-sm error-message-content__body__button" onClick={props.action}>
                        <FormattedMessage id="General_CoreFunctionality_UIText_general.ok" />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ErrorMessage;
