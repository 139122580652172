//@flow
import { CATEGORY_NAMES, FILTER_INFO } from '@constants';
import type {
    ACTION_TYPES_FLOW_TYPE,
    EVENT_SUBTYPES_FLOW_TYPE,
    EVENT_TYPES_FLOW_TYPE,
    SEARCH_FILTERS_KEYS_FLOW_TYPE,
    USER_FILTERS_KEYS_FLOW_TYPE,
} from '../investigationTypeGuards/investigationConstants.type.guards';

export const RESET_LOAD_DOCS_LISTENER = 'RESET_LOAD_DOCS_LISTENER';

// corresponding to the BE event types
export const EVENT_TYPES: EVENT_TYPES_FLOW_TYPE = {
    searchPerformed: 'SEARCH_PERFORMED',
    startInvestigation: 'START_INVESTIGATION',
    setSameInvestigation: 'SET_SAME_INVESTIGATION',
    categoryVisited: 'CATEGORY_VISITED',
    filterApplied: 'FILTER_APPLIED',
    filterRemoved: 'FILTER_REMOVED',
    filterReset: 'FILTER_RESET',
    reportOperation: 'REPORT_OPERATION',
    documentOperation: 'DOCUMENT_OPERATION',
};

export const EVENT_SUBTYPES: EVENT_SUBTYPES_FLOW_TYPE = {
    normal: 'NORMAL',
    update: 'UPDATE',
    all: 'ALL',
    created: 'CREATED',
    added: 'ADDED',
    removed: 'REMOVED',
    counts: 'CATEGORY_COUNTS_AVAILABLE',
    revisited: 'REVISITED',
    firstTime: 'FIRST_TIME',
    history: 'FROM_HISTORY',
    alert: 'FROM_ALERT',
    filters: 'FILTERS_RESET',
};

// used on FE to trigger listeners
export const ACTION_TYPES: ACTION_TYPES_FLOW_TYPE = {
    uboCountDone: 'UBO_SEARCH_COUNT_DONE',
    uboSearchStart: 'UBO_SEARCH_START',
    searchStarted: 'SEARCH_STARTED',
    countsRetrieved: 'COUNTS_RETRIEVED',
    reportOperationStarted: 'REPORT_OPERATION_STARTED',
    createReport: 'CREATE_REPORT',
    retrieveInvestigationId: 'INVESTIGATION_ID_RETRIEVED',
    handleNegativeTermFilter: 'HANDLE_NEGATIVE_TERM',
    applyNegativeTermFilter: 'APPLY_NEGATIVE_TERM',
    loadDocuments: 'LOAD_DOCUMENTS',
};

//@TODO replace with constants

export const USER_FILTERS_KEYS: USER_FILTERS_KEYS_FLOW_TYPE = {
    [CATEGORY_NAMES.NEGATIVE_NEWS]: [
        'contentSource',
        'dateRange',
        'fuzzyNames',
        'proximity',
        'isCustomFuzzy',
        'negativityLevels',
    ],
    [CATEGORY_NAMES.CUSTOM_NEWS]: ['contentSource', 'dateRange', 'fuzzyNames', 'proximity', 'isCustomFuzzy'],
    [CATEGORY_NAMES.NEWS]: ['contentSource', 'dateRange', 'fuzzyNames', 'isCustomFuzzy'],
    [CATEGORY_NAMES.LAW_SOURCES]: [
        'dateRange',
        'fuzzyNames',
        'searchNamePartyEntity',
        'isCustomFuzzy',
        'contentSource',
    ],
    [CATEGORY_NAMES.STATE_DOCKETS]: [
        'dateRange',
        'fuzzyNames',
        'searchNamePartyEntity',
        'isCustomFuzzy',
        'contentSource',
    ],
    [CATEGORY_NAMES.FEDERAL_DOCKETS]: [
        'dateRange',
        'fuzzyNames',
        'searchNamePartyEntity',
        'isCustomFuzzy',
        'contentSource',
    ],
    [CATEGORY_NAMES.LAW_REVIEWS]: [
        'dateRange',
        'fuzzyNames',
        'searchNamePartyEntity',
        'isCustomFuzzy',
        'contentSource',
    ],
    [CATEGORY_NAMES.VERDICTS]: ['dateRange', 'fuzzyNames', 'searchNamePartyEntity', 'isCustomFuzzy', 'contentSource'],
    [CATEGORY_NAMES.AGENCY_DECISION]: [
        'dateRange',
        'fuzzyNames',
        'searchNamePartyEntity',
        'isCustomFuzzy',
        'contentSource',
    ],
    [CATEGORY_NAMES.CASES]: ['dateRange', 'fuzzyNames', 'searchNamePartyEntity', 'isCustomFuzzy', 'contentSource'],
    [CATEGORY_NAMES.SANCTIONS_WATCHLIST]: ['fuzzyNames', 'isCustomFuzzy'],
    [CATEGORY_NAMES.PEPS]: ['fuzzyNames', 'isCustomFuzzy'],
    [CATEGORY_NAMES.BIOGRAPHICAL]: ['fuzzyNames', 'isCustomFuzzy'],
    [CATEGORY_NAMES.COMPANY_RESOURCES]: ['dateRange', 'fuzzyNames', 'isCustomFuzzy', 'searchSpecifically'],
    [CATEGORY_NAMES.FINANCIAL_REPORT]: ['searchSpecifically'],
    [CATEGORY_NAMES.ESG_RATINGS]: [],
};

export const SEARCH_FILTERS_KEYS: SEARCH_FILTERS_KEYS_FLOW_TYPE = {
    [CATEGORY_NAMES.NEGATIVE_NEWS]: [],
    [CATEGORY_NAMES.CUSTOM_NEWS]: [
        FILTER_INFO.PUBLICATION_DATE,
        FILTER_INFO.COMPANY_MENTIONS,
        FILTER_INFO.PERSON_MENTIONS,
        FILTER_INFO.COMPANY,
        FILTER_INFO.PROXIMITY,
        FILTER_INFO.PUBLICATION_NAME,
        FILTER_INFO.SOURCE_NAME,
        FILTER_INFO.LANGUAGE,
        FILTER_INFO.GEOGRAPHY,
        FILTER_INFO.SUBJECT,
        FILTER_INFO.INDUSTRY,
        FILTER_INFO.ESG_FACTORS,
    ],
    [CATEGORY_NAMES.NEWS]: [
        FILTER_INFO.PUBLICATION_DATE,
        FILTER_INFO.COMPANY_MENTIONS,
        FILTER_INFO.PERSON_MENTIONS,
        FILTER_INFO.COMPANY,
        FILTER_INFO.PUBLICATION_NAME,
        FILTER_INFO.SOURCE_NAME,
        FILTER_INFO.LANGUAGE,
        FILTER_INFO.GEOGRAPHY,
        FILTER_INFO.SUBJECT,
        FILTER_INFO.INDUSTRY,
        FILTER_INFO.ESG_FACTORS,
    ],
    [CATEGORY_NAMES.LAW_SOURCES]: ['dateRange', 'fuzzyNames', 'searchNamePartyEntity', 'isCustomFuzzy'],
    [CATEGORY_NAMES.STATE_DOCKETS]: ['dateRange', 'fuzzyNames', 'searchNamePartyEntity', 'isCustomFuzzy'],
    [CATEGORY_NAMES.FEDERAL_DOCKETS]: ['dateRange', 'fuzzyNames', 'searchNamePartyEntity', 'isCustomFuzzy'],
    [CATEGORY_NAMES.LAW_REVIEWS]: ['dateRange', 'fuzzyNames', 'searchNamePartyEntity', 'isCustomFuzzy'],
    [CATEGORY_NAMES.VERDICTS]: ['dateRange', 'fuzzyNames', 'searchNamePartyEntity', 'isCustomFuzzy'],
    [CATEGORY_NAMES.AGENCY_DECISION]: ['dateRange', 'fuzzyNames', 'searchNamePartyEntity', 'isCustomFuzzy'],
    [CATEGORY_NAMES.CASES]: ['dateRange', 'fuzzyNames', 'searchNamePartyEntity', 'isCustomFuzzy'],
    [CATEGORY_NAMES.SANCTIONS_WATCHLIST]: ['fuzzyNames', 'isCustomFuzzy'],
    [CATEGORY_NAMES.PEPS]: ['fuzzyNames', 'isCustomFuzzy'],
    [CATEGORY_NAMES.BIOGRAPHICAL]: ['fuzzyNames', 'isCustomFuzzy'],
    [CATEGORY_NAMES.COMPANY_RESOURCES]: [
        'dateRange',
        'fuzzyNames',
        'isCustomFuzzy',
        'searchSpecifically',
        'isCustomFuzzy',
    ],
    [CATEGORY_NAMES.FINANCIAL_REPORT]: ['searchSpecifically'],
    [CATEGORY_NAMES.DNB]: ['searchTerm'],
    [CATEGORY_NAMES.ESG_RATINGS]: [],
};
