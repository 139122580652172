import PropTypes from 'prop-types';
import React, { Component } from 'react';

export default class Tabs extends Component {
    static propTypes = {
        tabs: PropTypes.array,
        activeTabIndex: PropTypes.number,
        onChange: PropTypes.func,
    };

    static defaultProps = {
        tabs: [],
        activeTabIndex: 0,
    };

    renderTabs = () =>
        this.props.tabs.map(
            (tab, index) =>
                tab && (
                    <div
                        className={
                            'tab ' +
                            (this.props.activeTabIndex === index ? 'active' : '') +
                            (!tab.content ? ' empty' : '')
                        }
                        onClick={() => this.props.onChange(index)}
                        key={'tab_' + index}
                        data-testid={'tab-' + index + '-testid'}
                        data-track={tab.dataTrack ? 'tab-' + tab.dataTrack : ''}
                    >
                        {tab.label}
                    </div>
                )
        );

    render = () => <div className="tabs"> {this.renderTabs()} </div>;
}
