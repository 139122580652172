//@flow
import { BILLING_ID } from '@constants';
import type { Action } from './flow/Investigation.reducers.type.guards';
import type { InvestigationAction, InvestigationData } from './flow/Investigation.actions.type.guards';

export const SET_BILLING_ID: 'SET_BILLING_ID' = ('SET_BILLING_ID': 'SET_BILLING_ID');
export const START_INVESTIGATION: 'START_INVESTIGATION' = ('START_INVESTIGATION': 'START_INVESTIGATION');
export const RESET_INVESTIGATION: 'RESET_INVESTIGATION' = ('RESET_INVESTIGATION': 'RESET_INVESTIGATION');
export const SET_INVESTIGATION: 'SET_INVESTIGATION' = ('SET_INVESTIGATION': 'SET_INVESTIGATION');
export const UPDATE_VISITED_CATEGORIES: 'UPDATE_VISITED_CATEGORIES' =
    ('UPDATE_VISITED_CATEGORIES': 'UPDATE_VISITED_CATEGORIES');

const investigationActions = {
    setBillingId: (billingId: string): Action => {
        //store billingId to localStorage
        localStorage.setItem(BILLING_ID, billingId);
        if (!billingId) {
            console.log('try to add an empty investigation id');
        }
        return {
            type: SET_BILLING_ID,
            payload: billingId,
        };
    },

    resetInvestigation: (): Action => {
        return {
            type: RESET_INVESTIGATION,
            payload: {},
        };
    },

    setError: (): Action => {
        return {
            type: RESET_INVESTIGATION,
            payload: {
                hasError: true,
            },
        };
    },

    setInvestigation: (investigationData: InvestigationData): Action => {
        return {
            type: SET_INVESTIGATION,
            payload: investigationData,
        };
    },

    investigationAction: ({ type, payload }: InvestigationAction): Action => {
        return {
            type: type,
            payload: payload,
        };
    },

    markVisitedCategories: (clickedContentType: string): Action => {
        return {
            type: UPDATE_VISITED_CATEGORIES,
            payload: clickedContentType,
        };
    },
};

export default investigationActions;
