import React from 'react';
import utils from '@utils/utilities';
import ReportBuilderUtils from '@pages/ReportBuilder/utils/ReportBuilderUtils';
import { FormattedMessage } from 'react-intl';
import { Loader } from 'semantic-ui-react';

export const withHideForBatchReports = (Component) => {
    const hideForBatch = (props) => {
        const isBatchReport = ReportBuilderUtils.isReportBatch(props.reportType);
        return isBatchReport ? <Component {...props} /> : null;
    };

    hideForBatch.displayName = `hideForBatch(${utils.getDisplayName(Component)})`;

    return hideForBatch;
};

export const withHideForCombinedReports = (Component) => {
    const hideForCombined = (props) => {
        const isReportCombined = ReportBuilderUtils.isReportCombined(props.reportType);
        const isBatchReport = ReportBuilderUtils.isReportBatch(props.reportType);
        return isReportCombined || isBatchReport ? null : <Component {...props} />;
    };

    hideForCombined.displayName = `hideForCombined(${utils.getDisplayName(Component)})`;

    return hideForCombined;
};

export const withShowForCombinedReports = (Component) => {
    const showForCombined = (props) => {
        const isReportCombined = ReportBuilderUtils.isReportCombined(props.reportType);
        return isReportCombined ? <Component {...props} /> : null;
    };

    showForCombined.displayName = `showForCombined(${utils.getDisplayName(Component)})`;

    return showForCombined;
};

export const withToggleViewForExpandedReport = (Component) => {
    const showForExpanded = (props) => {
        return props.isReportExpanded ? <Component {...props} /> : null;
    };

    showForExpanded.displayName = `showForExpanded(${utils.getDisplayName(Component)})`;

    return showForExpanded;
};

export const withDeliveryLoadingSpinner = (Component) => {
    const showSpinner = (props) => {
        return props.showSpinner ? <ReportSpinner /> : <Component {...props} />;
    };
    showSpinner.displayName = `showSpinner(${utils.getDisplayName(Component)})`;
    return showSpinner;
};

export const withHideWhileLoading = (Component) => {
    const hideComponent = (props) => {
        return props.showSpinner ? <div className={props.className} /> : <Component {...props} />;
    };

    hideComponent.displayName = `hideComponent(${utils.getDisplayName(Component)})`;

    return hideComponent;
};

const ReportSpinner = () => {
    return (
        <div className="loader-report-build-wrapper">
            <div className="loader-report-build-text">
                <FormattedMessage id="Report.reportBuilding" />
            </div>
            <div className="loader-report-build">
                <Loader size="medium" active />
            </div>
        </div>
    );
};

export { ReportSpinner as TestReportSpinner };
