// @flow
import * as React from 'react';
import PopupModel from '@reusable/PopupModal/PopupBuilder';
import utils from '@utils/utilities';
import reduxStore from '@reduxStore';
import popupModelActions from '@reusable/PopupModal/redux/PopupModel.actions';
import CombinedReport from './ReportTypes/CombinedReport';
import RegularReport from './ReportTypes/RegularReport';
import ReportBuilderUtils from '@pages/ReportBuilder/utils/ReportBuilderUtils';
import { injectIntl } from 'react-intl';
import ReportBuilderService from '@ReportBuilder/ReportBuilderService';
import type { GenericReportType } from '@ReportBuilder/redux/flow/ReportBuilder.type.guards';
import type { ContentOptions } from '@reusable/PopupModal/Components/ModalContentOptions';
import BatchReport from './ReportTypes/BatchReport';
import DeliveryService from '@utils/deliveryService';
import formatRichMessage from '@utils/formatRichMessage';
import { PublicRecordsUtils } from '@utils/publicRecordsUtils';

type Props = {
    row: GenericReportType,
    report: GenericReportType,
    language: string,
    showDeliveryOptions: boolean,
    contentsOptions: Array<ContentOptions>,
    emailAddress: string,
    batchReportsPooling: Array<string>,
    +getConnectedReports: (report: GenericReportType) => void,
    +getDocumentsCountPerCategory: (report: GenericReportType) => void,
    +setSelectedReport: (reportId: string) => void,
    +discardSelectedReport: (reportId: string) => void,
    +updateReportDeliveredStatus: (reportId: string) => void,
    +lazyLoadArticlesForReport: (report: GenericReportType) => void,
    +onSelectionChange: (remainingItems: Array<GenericReportType>) => void,
    +onUpdateReportBuilderPage: (reportId: string) => void,
    +getBatchReportEntities: (report: GenericReportType) => void,
};

type InjectedProps = {|
    index: number,
    updateReportProperty: ({
        reportId: string,
        reportProperty: string,
        propertyValue: mixed,
        isSelected?: boolean,
    }) => void,
    +intl: Object,
|};

// wrapper used for single reports side-effects ?

const GeneralReportWrapper = (props: Props & InjectedProps): React$Element<typeof React.Fragment> => {
    const showDownloadReportPopup = (popupType: string): void => {
        //check if categoryOrder matches publicRecords, seems to be only way to reliably tell if report has only publicRecords
        let isSnapshotDisabled = PublicRecordsUtils.doesReportContainOnlyPublicRecords(props.report);
        let warningMessage = formatRichMessage({ id: 'PopupBuilder.warning.downloadReport' }, props.intl);
        let popupModel = new PopupModel.Builder(popupType)
            .setPopupProperty('isVisible', true)
            .setPopupProperty('fileName', utils.formatReportFileName(props.report.title))
            .setPopupProperty('warning', warningMessage)
            .setPopupProperty('reportId', props.report.id)
            .setPopupProperty('contentsOptions', props.contentsOptions)
            .setPopupProperty('showDeliveryOptions', props.showDeliveryOptions)
            .setPopupProperty('onSelectionChange', props.onSelectionChange)
            .setPopupProperty('onSelectionChange', props.onSelectionChange)
            .setPopupProperty('isSnapshotDisabled', isSnapshotDisabled)
            .build();
        reduxStore.dispatch(popupModelActions.setPopupModel(popupModel));
        ReportBuilderService.changeUpdatesFlagForSingleReport(props.report, false);
    };

    const startBatchReportPooling = (model) => {
        const deliveryService = new DeliveryService(model);
        deliveryService.doDelivery();
    };

    const downloadReport = (event): void => {
        event.stopPropagation();
        let params = event.target.value;
        let reportId = params.split('|')[0];
        let buildId = params.split('|')[1];

        if (ReportBuilderUtils.isReportBatch(props.report.deliveryType)) {
            const s3DownloadUrl =
                props.report.availableDownloads[props.report.availableDownloads.length - 1].s3SignedUrl;
            window.open(s3DownloadUrl, ` _self`);
        } else {
            window.open(`api/reportdelivery/report/download/${reportId}/${buildId}`, ` _self`);
        }

        //TODO: do not commit the following line, use the previous (works on DEV ENV)
        // window.open(`http://localhost:8090/api/reportdelivery/report/download/${reportId}/${buildId}`,`_self`);

        props.updateReportDeliveredStatus(reportId);
        ReportBuilderService.changeUpdatesFlagForSingleReport(props.report, false);
    };

    const handleToggleReport = async (reportId): Promise<Object> => {
        if (!reportId) return null;
        const { report } = props;
        if (report.isSelected) {
            props.discardSelectedReport(reportId);
            return null;
        } else {
            props.setSelectedReport(report.id);
            return handleExpandReport(report);
        }
    };

    const handleExpandReport = (report): Promise<Object> | void => {
        return ReportBuilderUtils.isReportCombined(report.deliveryType)
            ? handleExpandCombinedReport(report)
            : ReportBuilderUtils.isReportBatch(report.deliveryType)
            ? handleExpandBatchReport(report)
            : handleExpandRegularReport(report);
    };

    const handleExpandRegularReport = (report): void => {
        props.getConnectedReports(report);
        props.getDocumentsCountPerCategory(report);
        return props.lazyLoadArticlesForReport(report);
    };

    const handleExpandCombinedReport = (report): void => {
        props.getConnectedReports(report);
        ReportBuilderService.changeUpdatesFlagForSingleReport(report, false);
    };

    const handleExpandBatchReport = (report): void => {
        return props.getBatchReportEntities(report);
    };

    return (
        <React.Fragment>
            <CombinedReport
                {...props}
                showDownloadReportPopup={showDownloadReportPopup}
                downloadReport={downloadReport}
                onToggleReport={handleToggleReport}
            />
            <RegularReport
                {...props}
                showDownloadReportPopup={showDownloadReportPopup}
                downloadReport={downloadReport}
                onToggleReport={handleToggleReport}
            />
            <BatchReport
                {...props}
                startBatchReportPooling={startBatchReportPooling}
                downloadReport={downloadReport}
                onToggleReport={handleToggleReport}
            />
        </React.Fragment>
    );
};

export { GeneralReportWrapper as TestGeneralReportWrapper };
export default (injectIntl(GeneralReportWrapper): React$StatelessFunctionalComponent<Props>);
