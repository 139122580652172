// @flow
import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import utilities from '@utils/utilities';

type Props = {
    options: Object,
};

const LineGraph = (props: Props) => {
    const { options } = props;

    return options && !utilities.isEmptyObject(options) ? (
        <HighchartsReact highcharts={Highcharts} options={options} />
    ) : null;
};

export default (LineGraph: React$StatelessFunctionalComponent<Props>);
