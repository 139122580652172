import React from 'react';
import utils from '@utils/utilities';

/**
 * HOC to connect provider to another handler component
 * @param ProviderComponent
 */

const withHistoryEvents = (ProviderComponent) => (WrappedComponent) => {
    const withHistoryEvents = (props) => (
        <ProviderComponent
            {...props}
            provideHistoryEvents={(historyEventsProviderProps) => (
                <WrappedComponent historyEventsProviderProps={historyEventsProviderProps} {...props} />
            )}
        />
    );

    withHistoryEvents.displayName = `withHistoryEvents(${utils.getDisplayName(
        ProviderComponent
    )})(${utils.getDisplayName(WrappedComponent)})`;

    return withHistoryEvents;
};

export default withHistoryEvents;
