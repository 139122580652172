import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { getNotificationObject } from '@scripts/reusable/SnackbarWithAutohide/snackbarUtils';
import mainActions from '@scripts/pages/Main/Main.actions';
import SnackbarWithAutohide from '@reusable/SnackbarWithAutohide/SnackbarWithAutohide';
import {ACTION_TYPES} from '@scripts/constants';

const mapReduxStateToActionType = {
    "deleteAlertsStatus": ACTION_TYPES.DELETE_ALERT,
    "createAlertsStatus": ACTION_TYPES.CREATE,
    "copyEntitiesStatus": ACTION_TYPES.COPY,
    "deleteEntitiesStatus": ACTION_TYPES.DELETE,
    "refreshEntitiesStatus": ACTION_TYPES.REFRESH,
    "uploadEntitiesStatus": ACTION_TYPES.UPLOAD,
};

const Notification = ({connectionToStore, id}) => {
    const [notificationData, setNotificationData] = useState({});
    const batchIdsList = useSelector((state) => state.notificationStatus[connectionToStore].batchIdsList);  
    const numberOfBatchIdsInProgress = Object.keys(batchIdsList).length;
    const dispatch = useDispatch();

    useEffect(() => {

        const batchIdsData = Object.values(batchIdsList);
        const data = getNotificationObject(mapReduxStateToActionType[connectionToStore], dispatch, mainActions, batchIdsData);
        setNotificationData(data);

    }, [connectionToStore, batchIdsList]);

    return numberOfBatchIdsInProgress ? (
        <SnackbarWithAutohide
            key={`${id}-autohide`}
            id={`${id}-autohide`}
            {...notificationData}
        />
    ) : <></>;
};

export default Notification;