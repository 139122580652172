import { take, takeLatest, takeEvery, all, put, race, fork } from 'redux-saga/effects';
import {
    EVENT_SUBTYPES,
    EVENT_TYPES,
    ACTION_TYPES,
    RESET_LOAD_DOCS_LISTENER,
} from '../constants/investigationConstants';
import * as flows from '@flows/investigationFlows';

/**
 * Listener that will wait for START_INVESTIGATION and then launch the corresponding flow
 * Will continuously listen for the action to be triggered
 * The action is triggered every time a user does a new search
 */
export function* watchStartInvestigation() {
    yield takeLatest(EVENT_TYPES.startInvestigation, flows.startInvestigation);
}

export function* watchSetSameInvestigation() {
    yield takeLatest(EVENT_TYPES.setSameInvestigation, flows.setSameInvestigation);
}

/**
 * Listener that will wait for SEARCH_PERFORMED action's subtypes and then launch flow
 * Will continuously listen for the action to be triggered
 * The action is triggered every time a search is done
 */
export function* watchSearchPerformed() {
    yield all([
        takeLatest(EVENT_SUBTYPES.normal, flows.startSearch),
        takeLatest(EVENT_SUBTYPES.history, flows.startSearch),
        takeLatest(EVENT_SUBTYPES.alert, flows.startSearch),
        takeLatest(EVENT_SUBTYPES.filters, flows.startSearch),
        takeLatest(EVENT_SUBTYPES.counts, flows.countsRetrieved),
        takeEvery(EVENT_SUBTYPES.update, flows.searchUpdate), //used for search calls that may change results count, like pag number change, sort, page size change
    ]);
}

/**
 * Listener that will wait for one CATEGORY_VISITED subtypes and then launch flow
 * Will continuously listen for the action to be triggered
 * The action is triggered every time a category is visited in results list
 */
export function* watchCategoryVisited() {
    yield all([
        takeEvery(EVENT_SUBTYPES.firstTime, flows.categoryVisited),
        takeEvery(EVENT_SUBTYPES.revisited, flows.categoryRevisited),
    ]);
}

/**
 * Listener that will wait for one FILTER_APPLIED and FILTER_REMOVED and then launch flow
 * Will continuously listen for the action to be triggered
 * The action is triggered every time a category is visited in results list
 */
export function* watchFilterActions() {
    yield all([
        takeEvery(EVENT_TYPES.filterApplied, flows.filterOperation),
        takeEvery(EVENT_TYPES.filterRemoved, flows.filterOperation),
        takeEvery(EVENT_TYPES.filterReset, flows.filterOperation),
    ]);
}

export function* watchReportOperation() {
    yield all([takeEvery(EVENT_TYPES.reportOperation, flows.reportOperation)]);
}

export function* watchDocumentOperations() {
    yield all([takeEvery(EVENT_TYPES.documentOperation, flows.documentOperation)]);
}

export function* watchCountsDone() {
    while (true) {
        const { reset } = yield race({
            payload: all([
                take(ACTION_TYPES.uboCountDone),
                take(ACTION_TYPES.countsRetrieved),
                take(ACTION_TYPES.retrieveInvestigationId),
            ]),
            reset: take(RESET_LOAD_DOCS_LISTENER),
        });

        if (!reset) {
            yield put({ type: EVENT_SUBTYPES.counts, payload: { sendEventWithoutTriggeringTheFlow: false } });
        }
    }
}

export function* watchloadDocuments() {
    while (true) {
        const { loadDocumentsPayload, resetListener } = yield race({
            loadDocumentsPayload: all([take(EVENT_SUBTYPES.counts), take(ACTION_TYPES.loadDocuments)]),
            resetListener: take(RESET_LOAD_DOCS_LISTENER),
        });

        if (!resetListener) {
            yield fork(flows.loadDocuments, loadDocumentsPayload[1]);
        }
    }
}

export function* watchNegativeTermHandling() {
    while (true) {
        yield take(ACTION_TYPES.handleNegativeTermFilter);

        const action = yield take(EVENT_TYPES.categoryVisited);
        yield put({ type: ACTION_TYPES.applyNegativeTermFilter, data: action });
    }
}

export function* watchNegativeTermApplyOperation() {
    yield all([takeEvery(ACTION_TYPES.applyNegativeTermFilter, flows.applyNegativeTerm)]);
}
