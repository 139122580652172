// @flow
import * as React from 'react';
import LineGraph from '@MainSearch/components/negativeNewsVisualisations/LineGraph';
import utilities from '@utils/utilities';
import type { LineGraphLineType } from '@MainSearch/components/negativeNewsVisualisations/flow/NegativeNewsVisualisations.typeGuards';
import NegativeNewsVisualisationsUtils from '@MainSearch/components/negativeNewsVisualisations/resultsList/NegativeNewsVisualisationsUtils';

type Props = {
    buckets: Object,
};

const NegativeNewsSnapshotLineGraph = (props: Props) => {
    const { buckets } = props;
    const distinctValues: Array<number> = NegativeNewsVisualisationsUtils.buildDistinctDatesForLineGraph(buckets);

    // Build series based on buckets
    const seriesForHistogram: Array<LineGraphLineType> = NegativeNewsVisualisationsUtils.buildSeriesForHistogram(
        distinctValues,
        buckets
    );

    const shouldDisplayMarker = distinctValues.length === 1;

    const options = {
        chart: {
            height: 40,
            width: 100,
            margin: [0, 0, 5, 0],
            style: {
                marginLeft: 'auto',
            },
        },
        credits: {
            enabled: false,
        },
        title: {
            text: '',
        },
        xAxis: {
            tickLength: 1,
            labels: {
                enabled: false,
            },
            gridLineWidth: 0,
            lineWidth: 1,
        },
        yAxis: {
            title: {
                text: '',
            },
            labels: {
                enabled: false,
            },
            gridLineWidth: 0,
        },
        plotOptions: {
            line: {
                marker: {
                    enabled: shouldDisplayMarker,
                    radius: 3,
                },
            },
            series: {
                connectNulls: true,
                label: {
                    connectorAllowed: false,
                },
                states: {
                    hover: {
                        enabled: false,
                    },
                },
            },
        },
        legend: {
            enabled: false,
        },
        series: [...seriesForHistogram],
        tooltip: {
            enabled: false,
        },
    };

    return options && !utilities.isEmptyObject(options) ? <LineGraph options={options} /> : null;
};

export default (NegativeNewsSnapshotLineGraph: React$StatelessFunctionalComponent<Props>);
