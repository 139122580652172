/**
 * SLIDER ENTRY POINT
 *
 * This component can be used to display a modal with custom content and custom
 * buttons. The buttons can have event handlers attached if specified.
 *
 * @version 0.1
 */

import React from 'react';
import ReactDOM from 'react-dom';
import FocusTrap from 'focus-trap-react';
import { FormattedMessage } from 'react-intl';
import { POPUP_SIZE_SMALL } from '@constants';

class Slider extends React.Component {
    constructor(props) {
        super(props);
        this.handlerEscape = this.handlerEscape.bind(this);
    }

    handlerEscape(event) {
        if (event.key === 'Escape' || event.key === 'Esc' || event.keyCode === 27 || event.type === 'click') {
            this.props.closeButtonHandler();
        }
    }

    render() {
        let buttons = [];

        if (this.props.buttons) {
            for (let i = 0; i < this.props.buttons.length; i++) {
                let button = this.props.buttons[i],
                    classes = button.primary ? 'button-primary-' : 'button-secondary-';
                classes += button.small ? 'sm' : 'lg';
                classes += button.rightAligned ? ` ${classes}__right-aligned` : '';

                classes += button.isDisabled ? ' disabled' : '';

                buttons.push(
                    <button
                        type="button"
                        key={'app-modal-button-' + Math.random() + '-' + i}
                        className={classes + ' notranslate'}
                        disabled={button.isDisabled}
                        onClick={button.click ? button.click.bind(this) : null}
                        tabIndex={button.tabIndex ? button.tabIndex : null}
                    >
                        <FormattedMessage id={button.text} />
                    </button>
                );
            }
        }
        let className = this.props.popupSize
            ? 'app-slider__content-box ' + this.props.popupSize
            : 'app-slider__content-box ' + POPUP_SIZE_SMALL;
        className = this.props.slideOut ? className + ' slide-out' : className;

        const sliderPortal = document.getElementById('slider-portal');
        const fadeBackground = this.props.slideOut ? '' : 'fade-background';

        const sliderContent = (
            <FocusTrap
                focusTrapOptions={{
                    fallbackFocus: '#dialog',
                }}
            >
                <div id="dialog" className="app-slider">
                    <div
                        className={`app-slider__wrapper ${this.props.className} ${fadeBackground}`}
                        onKeyDown={this.handlerEscape}
                        onClick={this.handlerEscape}
                    >
                        <div
                            className={className}
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            <div className={'app-slider__content-box__header'}>
                                {typeof this.props.closeButtonHandler === 'function' ? (
                                    <button
                                        type="button"
                                        className="la-CloseRemove app-slider__content-box__header__close-button"
                                        onClick={this.props.closeButtonHandler}
                                    >
                                        {/*// tabIndex = {buttons.length ? "-1" : "1"}>*/}
                                    </button>
                                ) : null}
                            </div>
                            <div className="app-slider__content-box__body">{this.props.children}</div>
                        </div>
                        {buttons.length > 0 ? <div className="app-slider__buttons">{buttons}</div> : null}
                    </div>
                </div>
            </FocusTrap>
        );

        return ReactDOM.createPortal(sliderContent, sliderPortal);
    }
}

export default Slider;
