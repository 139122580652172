// @flow

import update from 'immutability-helper';
import {
    RESET_INVESTIGATION,
    SET_BILLING_ID,
    SET_INVESTIGATION,
    UPDATE_VISITED_CATEGORIES,
} from './Investigation.actions';
import errorUtils from '@utils/errors/error-utils';
import * as investigationHelpers from '@sagas/helpers/investigationHelpers';
import { cloneDeep } from 'lodash';

import type { InvestigatonState, Action } from '../redux/flow/Investigation.reducers.type.guards';

const APP_MODULE = 'investigation.reducers';

function investigation(state: InvestigatonState = {}, action: Action): InvestigatonState {
    let currentInvestigation;
    let report;

    switch (action.type) {
        case SET_BILLING_ID:
            if (!action.payload || (action.payload.length && action.payload.length === 0)) {
                errorUtils.logAppError(
                    'Missing parameter in ' + SET_BILLING_ID,
                    {
                        payload: action.payload,
                    },
                    APP_MODULE
                );

                return state;
            }

            return update(state, { billingId: { $set: action.payload } });

        case RESET_INVESTIGATION:
            const { hasError } = action.payload;

            currentInvestigation = {
                ...state,
                id: null,
                hasError: hasError || false,
                visitedContentTypes: {},
            };

            return update(state, { $set: currentInvestigation });

        case SET_INVESTIGATION:
            const { searchQuery, searchQueryType, investigationId } = action.payload;

            report = investigationHelpers.getDefaultReport();

            currentInvestigation = {
                ...state,
                id: investigationId,
                searchQuery,
                searchQueryType,
                hasError: false,
                visitedContentTypes: {},
                report,
            };

            return update(state, { $set: currentInvestigation });

        case UPDATE_VISITED_CATEGORIES:
            let newVisited: { [categoryName: string]: boolean } = cloneDeep(state.visitedContentTypes);

            if (!newVisited || !action.payload || (action.payload.length && action.payload.length === 0)) {
                errorUtils.logAppError(
                    'Missing parameter in ' + UPDATE_VISITED_CATEGORIES,
                    { visitedContentTypes: newVisited, categoryName: action.payload },
                    APP_MODULE
                );
                return state;
            }

            newVisited[action.payload] = true;

            return update(state, { visitedContentTypes: { $set: newVisited } });

        default:
            return state;
    }
}

export default investigation;
