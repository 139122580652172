import React, { Fragment } from 'react';
import CustomDocumentItem from './CustomDocumentItem';
import CustomNoResultsDocumentItem from './CustomNoResultsDocumentItem';
import ResultListError from './ResultListError';
import ReactPaginate from 'react-paginate';
import PageSizeSelector from '@reusable/PageSizeSelector/PageSizeSelector';
import { PROMISE_CANCELED } from '@utils/promiseQueue';
import errorUtils from '@utils/errors/error-utils';
import { CATEGORY_NAMES } from '@constants';

const CustomDocumentsList = (props) => {
    let showErrorMessage = props.articleList.hasError && props.articleList.errorMessage !== PROMISE_CANCELED;
    let errorCode = errorUtils.failedCategoriesCollector.getCategoryKeyErrorCode(props.articleType);
    let noArticlesLoaded =
        props.articleList &&
        props.articleList.count === 0 &&
        props.articleList.loaded === true &&
        props.articleList.hasError !== true;
    const categoryType =
        props.articleType === CATEGORY_NAMES.ESG_RATINGS ? 'ESG Ratings' : 'Ultimate Beneficial Ownership';

    if (noArticlesLoaded) {
        const source = props.articleList?.articles[0]?.source ?? 'N/A';
        return (
            <CustomNoResultsDocumentItem
                article={{
                    id: '-1',
                    source: source,
                    title: `No documents found for your ${categoryType} search ${
                        props.searchResults[props.articleType].title
                    }`,
                }}
                title={
                    props.searchResults[props.articleType].title ??
                    props.searchResults[props.articleType].postFilters?.searchQuery
                }
                articleType={props.articleType}
            />
        );
    } else {
        let pageNumbers = Math.ceil(props.articleList.count / props.pageSize);
        const articleList = props.articleList.articles.map((article, index) => {
            const isArticleItemSelected = props.selectedArticlesList.some(
                (articleElement) => articleElement.id === article.id
            );

            return (
                <CustomDocumentItem
                    key={props.articleType + index}
                    searchParams={props.searchParams}
                    selectedArticles={props.selectedArticles || []}
                    setSelectedArticles={props.setSelectedArticles}
                    index={index + props.pageSize * props.articleList.currentPageIndex}
                    article={article}
                    report={props.report}
                    articleType={props.articleType}
                    showSingleArticle={props.showSingleArticle}
                    isArticleItemSelected={isArticleItemSelected}
                    setSelectedArticlesInfo={props.setSelectedArticlesInfo}
                />
            );
        });

        return (
            <Fragment>
                <ol className="article-list">{articleList}</ol>
                {errorCode && showErrorMessage && (
                    <ResultListError category={props.articleType} title={props.articleList.title} />
                )}
                {props.articleList.loaded && (
                    <div className="pagination-container">
                        <ReactPaginate
                            previousLabel={''}
                            nextLabel={''}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            forcePage={props.articleList.currentPageIndex}
                            pageCount={pageNumbers}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={4}
                            onPageChange={props.loadMoreArticles}
                            containerClassName={'pagination'}
                            subContainerClassName={'pages pagination'}
                            activeClassName={'active'}
                            nextLinkClassName={'next'}
                        />
                        <PageSizeSelector pageSize={props.pageSize} handlePageSizeChange={props.handlePageSizeChange} />
                    </div>
                )}
            </Fragment>
        );
    }
};

export default CustomDocumentsList;
