import React from 'react';
import { Link } from 'react-router';
import { FormattedMessage } from 'react-intl';
import categoryUtils, { withCustomNewsTranslation } from '@utils/categoryUtils';
import { processBreadcrumbLabel } from '@sagas/helpers/uboHelper';
import { LAUNCHED_SEARCH_FROM, QUERY_SEARCH_TYPES, ROUTES } from '@constants';
import searchSagaActions from '@pages/MainSearch/redux/searchSaga.actions';
import { connect } from 'react-redux';
import utils from '@utils/utilities';

class Breadcrumbs extends React.Component {
    handleOnClick = (url) => {
        const { dispatch, searchResults, currentEntity, showSnapshot } = this.props;
        const { category } = currentEntity;
        let currentEntityChecked = {...currentEntity};

        currentEntityChecked.isSnapshotVisible = currentEntityChecked.isSnapshotVisible ??  showSnapshot;

        if (
            this.props.searchState.launchedFrom === LAUNCHED_SEARCH_FROM.SCREENING_PAGE &&
            url.includes(ROUTES.MAIN_SEARCH) &&
            searchResults[category] &&
            !searchResults[category].loaded
        ) {
            const { entityName, searchQueryType, prefilterQuery, id, postFilters } = currentEntityChecked;
            // eslint-disable-next-line unused-imports/no-unused-vars
            const { isSnapshotVisible, ...rest } = currentEntityChecked;

            let payload = {
                searchQuery: entityName,
                searchQueryType: QUERY_SEARCH_TYPES[searchQueryType],
                prefilterQuery: utils.setCorrectPrefilterQuery(prefilterQuery),
                launchedFrom: LAUNCHED_SEARCH_FROM.SCREENING_PAGE,
                entityId: id,
                checkPreferencesAreObsolete: () => null,
                postFilters,
                currentEntity: rest,
            };
            const categoryFromUrl = utils.getURLParameter(url, 'category')
            categoryFromUrl && (payload.categoryName = categoryFromUrl);

            dispatch(searchSagaActions.runSearch(payload));
        }
    };

    render() {
        const {
            breadcrumbs: { items },
            location,
        } = this.props;
        const breadcrumbItems = [];

        if (items && items.length > 0) {
            items.forEach((item, index) => {
                if (index === items.length - 1) {
                    breadcrumbItems.push({ label: item.label });
                } else {
                    breadcrumbItems.push({ label: item.label, url: item.url });
                    breadcrumbItems.push({ isSeparator: true });
                }
            });
        }

        const showBreadcrumbs = location?.pathname?.includes(ROUTES.START_PAGE) ? 'hide' : '';
        return breadcrumbItems.length === 0 ? null : (
            <div className={`breadcrumbs-container notranslate ${showBreadcrumbs}`}>
                {breadcrumbItems.map((item, index) => {
                    let matomoTrackingProperties = {};
                    const breadcrumbsParts = item.label ? item.label.split('.') : [];

                    if (breadcrumbsParts.length) {
                        const lastPart = breadcrumbsParts[breadcrumbsParts.length - 1];
                        matomoTrackingProperties = {
                            'data-gm-u-pn': 'nexisDiligence_global',
                            'data-gm-u-c': 'breadcrumb',
                            'data-gm-u-cc': `breadcrumb_${lastPart}`,
                            'data-gm-u-e': 'link',
                            'data-gm-u-v': `${lastPart}`,
                            'data-gm-u-a': 'click',
                        };
                    }
                    return item.url !== undefined ? (
                        <Link
                            key={index}
                            to={item.url}
                            className="breadcrumb"
                            onClick={() => this.handleOnClick(item.url)}
                            {...matomoTrackingProperties}
                        >
                            <FormattedMessage
                                id={processBreadcrumbLabel(item.label)}
                                defaultMessage={withCustomNewsTranslation(item.label)}
                            />
                        </Link>
                    ) : item.isSeparator === true ? (
                        <span key={index} className="la-ChevronRight"></span>
                    ) : (
                        <span className="breadcrumb" key={index}>
                            {!categoryUtils.isCustomNewsSource(item.label)
                                ? <FormattedMessage
                                    defaultMessage={withCustomNewsTranslation(item.label)}
                                    id={processBreadcrumbLabel(item.label)}
                                /> 
                                : withCustomNewsTranslation(item.label)
                            }
                        </span>
                    );
                })}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        currentEntity: state.entityViewInformation.currentEntity,
        showSnapshot: state.user.preferences.generalSettings.showSnapshot,
    };
};

export { Breadcrumbs as TestBreadcrumbs };
export default connect(mapStateToProps)(Breadcrumbs);
