import { SET_GOOGLE_TRANSLATE_LANGUAGE, SET_GOOGLE_TRANSLATE_STATUS } from '@pages/Main/Main.reducers';

let GoogleTranslateActions = {
    setUserLanguage: (language) => {
        return {
            type: SET_GOOGLE_TRANSLATE_LANGUAGE,
            payload: language,
        };
    },
    setGoogleTranslateStatus: (googleStatus) => {
        return {
            type: SET_GOOGLE_TRANSLATE_STATUS,
            payload: googleStatus,
        };
    },
};
export default GoogleTranslateActions;
