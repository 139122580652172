// @flow
import React from 'react';

type Props = {
    disabled: boolean,
    handleDragOver?: (e: SyntheticDragEvent<HTMLDivElement>) => void,
    onDrop?: (e: SyntheticDragEvent<HTMLDivElement>) => void,
    isValid: boolean,
    children: React$Node,
};

type State = {
    isDragging: boolean,
    counter: number,
};

class DragAndDropBox extends React.Component<Props, State> {
    state: State = {
        isDragging: false,
        counter: 0,
    };
    dragAndDropBoxRef: { current: any | HTMLDivElement } = React.createRef();

    componentDidMount() {
        this.dragAndDropBoxRef.current.addEventListener('dragenter', this.handleDragEnter);
        this.dragAndDropBoxRef.current.addEventListener('dragleave', this.handleDragLeave);
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.disabled !== this.props.disabled && this.props.disabled) {
            this.dragAndDropBoxRef.current.removeEventListener('dragenter', this.handleDragEnter);
            this.dragAndDropBoxRef.current.removeEventListener('dragleave', this.handleDragLeave);
        } else if (prevProps.disabled !== this.props.disabled && !this.props.disabled) {
            this.dragAndDropBoxRef.current.addEventListener('dragenter', this.handleDragEnter);
            this.dragAndDropBoxRef.current.addEventListener('dragleave', this.handleDragLeave);
        }
    }

    componentWillUnmount() {
        this.dragAndDropBoxRef.current.removeEventListener('dragenter', this.handleDragEnter);
        this.dragAndDropBoxRef.current.removeEventListener('dragleave', this.handleDragLeave);
    }

    handleDragEnter: (e: DragEvent) => void = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (this.state.counter === 0) {
            this.setState({ isDragging: true });
        }

        this.setState((prevState) => ({
            counter: prevState.counter + 1,
        }));
    };

    handleDragLeave: (e: DragEvent) => void = (e) => {
        e.preventDefault();
        e.stopPropagation();

        this.setState((prevState) => {
            if (prevState.counter === 1) {
                return {
                    isDragging: false,
                    counter: 0,
                };
            }

            return {
                counter: prevState.counter - 1,
            };
        });
    };

    handleDragOver: (e: SyntheticDragEvent<HTMLDivElement>) => void = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (typeof this.props.handleDragOver === 'function') {
            this.props.handleDragOver(e);
        }
    };

    handleDropEvent: (e: SyntheticDragEvent<HTMLDivElement>) => void = (e) => {
        e.preventDefault();
        e.stopPropagation();

        this.setState({
            isDragging: false,
            counter: 0,
        });

        if (typeof this.props.onDrop === 'function') {
            this.props.onDrop(e);
        }
    };

    render(): React$Element<'div'> {
        const { isDragging } = this.state;
        const { children, isValid, disabled } = this.props;

        return (
            <div
                className={`drop-area ${isDragging ? 'dragging' : ''} ${!isValid ? 'error' : ''}`}
                data-testid="drop-area-container-testid"
                ref={this.dragAndDropBoxRef}
                onDrop={disabled ? null : this.handleDropEvent}
                onDragOver={disabled ? null : this.handleDragOver}
            >
                {children}
            </div>
        );
    }
}

export default DragAndDropBox;
