//@flow
import React from 'react';
import { MODAL_TYPE } from '@constants';
import reportBuilderUtils from '@pages/ReportBuilder/utils/ReportBuilderUtils';
import { FormattedMessage } from 'react-intl';
import type { CombinedReportType, RegularReportType } from '@ReportBuilder/redux/flow/ReportBuilder.type.guards';

type Props = {
    index: number,
    report: CombinedReportType | RegularReportType,
    downloadReport: (popupType: string) => void,
    handleDeleteReport: (reportId: string, title: string) => void,
};

const ReportFooter = (props: Props): React$Element<'div'> => {
    return (
        <div className="articles-list-footer">
            <div className="report-buttons-group">
                {props.report.latestDocument && reportBuilderUtils.isBuildValid(props.report) ? (
                    <button
                        disabled={
                            props.report.showSpinner ||
                            (props.report.availableDownloads && props.report.availableDownloads.length > 0)
                        }
                        className="button-primary-lg"
                        id={'qa-report-footer-download_' + props.index}
                        title={props.report.title}
                        onClick={() => props.downloadReport(MODAL_TYPE.DOWNLOAD_REPORT)}
                    >
                        <FormattedMessage id="ReportFooter.downloadReport" />
                    </button>
                ) : (
                    <button
                        disabled={
                            (props.report.content && props.report.content.length === 0) || props.report.showSpinner
                        }
                        className="button-primary-lg"
                        id={'qa-report-footer-download_' + props.index}
                        title={props.report.title}
                        onClick={() => props.downloadReport(MODAL_TYPE.DOWNLOAD_REPORT)}
                    >
                        <FormattedMessage id="ReportFooter.downloadReport" />
                    </button>
                )}
                <button
                    className="button-secondary-lg"
                    id={'qa-report-footer-delete_' + props.index}
                    value={MODAL_TYPE.DELETE_REPORT}
                    onClick={(e) => {
                        e.stopPropagation();
                        props.handleDeleteReport(props.report.id, props.report.title);
                    }}
                >
                    <FormattedMessage id="ReportFooter.deleteReport" />
                </button>
            </div>
        </div>
    );
};

export default ReportFooter;
