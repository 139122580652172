//@flow

import {
    DELETE_REPORT,
    RESET_REPORTBUILDER_STATE,
    SELECTED_REPORT,
    SELECTED_REPORT_BY_ID,
    SET_LOADING_STATUS,
    SET_REPORT_COUNT,
    SET_REPORTS,
    UPDATE_CURRENT_PAGE,
    UPDATE_NOTE_ON_ARTICLE,
    DELETE_NOTE_FROM_ARTICLE,
    UPDATE_REPORT_PROPERTY,
    UPDATE_REPORT_DELIVERY,
    UPDATE_REPORT_DELIVERED_STATUS,
    DELETE_DOCUMENT,
    DISCARD_REPORT,
    DISCARD_SELECTED_REPORT,
    REORDER_CHILD_REPORTS_FOR_COMBINED,
    UPDATE_LITE_CHILD_REPORTS_TITLE,
    UPDATE_REPORT_DOCUMENTS_COUNTS,
    ADD_BATCH_REPORT_TO_POOLING,
    REMOVE_BATCH_REPORT_FROM_POOLING,
    UPDATE_LAST_BATCH_REPORT_PROPERTY,
    UPDATE_POLLING_STATUS,
} from './ReportBuilder.action';
import _ from 'lodash';
import type {
    Action,
    ReportBuilderState,
    ReportProperty,
    LatestDocument,
    Report,
    LiteChildReportProperty,
    ExpandedRegularReportType,
} from './flow/ReportBuilder.type.guards';
import update from 'immutability-helper/index';
import errorUtils from '@utils/errors/error-utils';
import ReportBuilderUtils from '@pages/ReportBuilder/utils/ReportBuilderUtils';
const APP_MODULE: string = 'reportBuilder.reducers';

type Indexes = {
    reportIndex: number,
    categoryIndex: number,
    articleIndexInCategory: number,
};

function reportBuilder(state: ReportBuilderState = {}, action: Action): ReportBuilderState {
    let indexes: Indexes = {};
    let reportsArray: Array<Report>;
    let reportIndex: number = -1;

    switch (action.type) {
        case UPDATE_REPORT_PROPERTY:
            const { reportId, propertyName, propertyValue }: ReportProperty = action.payload;

            if (!reportId || !propertyName || propertyValue === undefined) {
                errorUtils.logAppError(
                    'Missing parameter in ' + UPDATE_REPORT_PROPERTY,
                    {
                        reportId: reportId,
                        propertyName: propertyName,
                        propertyValue: propertyValue,
                    },
                    APP_MODULE
                );
                return state;
            }
            reportIndex = _.findIndex(state.reports, (entry: Report): boolean => entry.id === reportId);

            if (reportIndex === -1) {
                console.log(`Report not found or report not on page`);
                return state;
            }

            return update(state, {
                reports: {
                    [reportIndex]: { [propertyName]: { $set: propertyValue } },
                },
            });

        case UPDATE_LITE_CHILD_REPORTS_TITLE:
            const { parentId, childReportId, childReportTitle }: LiteChildReportProperty = action.payload;

            if (!parentId || !childReportId || childReportTitle === undefined) {
                errorUtils.logAppError(
                    'Missing parameter in ' + UPDATE_LITE_CHILD_REPORTS_TITLE,
                    {
                        parentId: parentId,
                        childReportId: childReportId,
                        childReportTitle: childReportTitle,
                    },
                    APP_MODULE
                );
                return state;
            }

            reportIndex = _.findIndex(state.reports, (entry: Report): boolean => entry.id === parentId);

            if (reportIndex === -1) {
                console.log(`Report not found or report not on page`);
                return state;
            }

            const liteChildReports = _.cloneDeep(state.reports[reportIndex].liteChildReports);
            const updatedLiteChildReports = liteChildReports.map((childReport) =>
                childReport.reportId === childReportId ? { ...childReport, title: childReportTitle } : childReport
            );

            return update(state, {
                reports: {
                    [reportIndex]: { liteChildReports: { $set: updatedLiteChildReports } },
                },
            });

        case DELETE_REPORT:
            if (!action.payload) return state;

            reportIndex = _.findIndex(state.reports, (entry: Report): boolean => entry.id === action.payload);

            if (reportIndex < 0) return state;

            reportsArray = _.cloneDeep(state.reports);
            reportsArray.splice(reportIndex, 1);

            let totalReportsCount: number = state.totalReportsCount;
            totalReportsCount = totalReportsCount ? totalReportsCount - 1 : (reportsArray && reportsArray.length) || 0;

            return update(state, { reports: { $set: reportsArray }, totalReportsCount: { $set: totalReportsCount } });

        case DELETE_DOCUMENT:
            indexes = ReportBuilderUtils.getIndexForReportArticleAndCategory(
                _.cloneDeep(state.reports),
                action.payload.reportId,
                action.payload.articleId
            );

            if (indexes.reportIndex === -1 || indexes.categoryIndex === -1 || indexes.articleIndexInCategory === -1) {
                errorUtils.logAppError(
                    'Error identifying article in category ' + DELETE_NOTE_FROM_ARTICLE,
                    {
                        payload: action.payload,
                        reportId: action.payload.reportId,
                        article: action.payload.articleId,
                    },
                    APP_MODULE
                );

                return state;
            }

            return update(state, {
                reports: {
                    [indexes.reportIndex]: {
                        categories: {
                            [indexes.categoryIndex]: {
                                snippets: { $splice: [[indexes.articleIndexInCategory, 1]] },
                            },
                        },
                    },
                },
            });

        case SET_REPORT_COUNT:
            if (typeof action.payload !== 'number') {
                errorUtils.logAppError(
                    'Missing parameter in ' + SET_REPORT_COUNT,
                    {
                        payload: action.payload,
                    },
                    APP_MODULE
                );
                return state;
            }

            return update(state, { totalReportsCount: { $set: action.payload } });

        case SELECTED_REPORT:
            reportsArray = state.reports.map((entry: Report) => {
                entry.isSelected = entry.id === action.payload;
                return entry;
            });

            return update(state, { reports: { $set: reportsArray } });

        case DISCARD_REPORT:
            reportsArray = state.reports.map((entry) => {
                if (entry.id === action.payload) {
                    entry.isSelected = false;
                }
                return entry;
            });
            return update(state, { reports: { $set: reportsArray } });

        case SET_REPORTS:
            if (!action.payload) {
                errorUtils.logAppError(
                    'Missing parameter in ' + SET_REPORTS,
                    {
                        payload: action.payload,
                    },
                    APP_MODULE
                );

                return state;
            }

            reportsArray = JSON.parse(JSON.stringify(action.payload));
            return update(state, { reports: { $set: reportsArray } });

        case RESET_REPORTBUILDER_STATE:
            return Object.assign(
                {},
                {
                    reports: [],
                    singleArticle: {},
                    loadingStatus: false,
                    selectedReport: {},
                    totalReportsCount: 0,
                    batchReportsPooling: state.batchReportsPooling,
                    lastBatchReportStatus: {},
                }
            );

        case SET_LOADING_STATUS:
            return Object.assign({}, state, { loadingStatus: action.payload });

        case SELECTED_REPORT_BY_ID:
            let selectedReport = state.reports.find((entry) => {
                return entry.id === action.payload;
            });
            return Object.assign({}, state, { selectedReport: selectedReport });

        case DISCARD_SELECTED_REPORT:
            return Object.assign({}, state, { selectedReport: {} });

        case REORDER_CHILD_REPORTS_FOR_COMBINED:
            let changedReports: Array<Report> = state.reports.map((entry) => {
                if (entry && entry.id === action.payload.reportId) {
                    entry.childReports = action.payload.childReports;
                }
                return entry;
            });

            return update(state, { reports: { $set: changedReports } });

        case DELETE_NOTE_FROM_ARTICLE:
            if (!action.payload || !action.payload.reportId || !action.payload.articleId) {
                errorUtils.logAppError(
                    'Missing parameter in ' + DELETE_NOTE_FROM_ARTICLE,
                    {
                        payload: action.payload,
                        reportId: action.payload.reportId,
                        article: action.payload.articleId,
                    },
                    APP_MODULE
                );

                return state;
            }

            indexes = ReportBuilderUtils.getIndexForReportArticleAndCategory(
                state.reports,
                action.payload.reportId,
                action.payload.articleId
            );

            if (indexes.reportIndex === -1 || indexes.categoryIndex === -1 || indexes.articleIndexInCategory === -1) {
                errorUtils.logAppError(
                    'Error identifying article in category ' + DELETE_NOTE_FROM_ARTICLE,
                    {
                        payload: action.payload,
                        reportId: action.payload.reportId,
                        article: action.payload.articleId,
                    },
                    APP_MODULE
                );

                return state;
            }

            return update(state, {
                reports: {
                    [indexes.reportIndex]: {
                        categories: {
                            [indexes.categoryIndex]: {
                                snippets: {
                                    [indexes.articleIndexInCategory]: { note: { $set: null } },
                                },
                            },
                        },
                    },
                },
            });

        case UPDATE_NOTE_ON_ARTICLE:
            if (!action.payload || !action.payload.reportId || !action.payload.articleId) {
                errorUtils.logAppError(
                    'Missing parameter in ' + UPDATE_NOTE_ON_ARTICLE,
                    {
                        payload: action.payload,
                        reportId: action.payload.reportId,
                        articleId: action.payload.articleId,
                        note: action.payload.note,
                    },
                    APP_MODULE
                );

                return state;
            }
            indexes = ReportBuilderUtils.getIndexForReportArticleAndCategory(
                state.reports,
                action.payload.reportId,
                action.payload.articleId
            );
            if (indexes.reportIndex === -1 || indexes.categoryIndex === -1 || indexes.articleIndexInCategory === -1) {
                errorUtils.logAppError(
                    'Error identifying article in category ' + UPDATE_NOTE_ON_ARTICLE,
                    {
                        payload: action.payload,
                        reportId: action.payload.reportId,
                        articleId: action.payload.articleId,
                        note: action.payload.note,
                    },
                    APP_MODULE
                );

                return state;
            }

            return update(state, {
                reports: {
                    [indexes.reportIndex]: {
                        categories: {
                            [indexes.categoryIndex]: {
                                snippets: {
                                    [indexes.articleIndexInCategory]: { note: { $set: action.payload.note } },
                                },
                            },
                        },
                    },
                },
            });

        case UPDATE_REPORT_DELIVERY:
            let { availableDownloads, lastUpdated, latestDocument } = action.payload || {};
            if (!action.payload || !action.payload.reportId) {
                errorUtils.logAppError(
                    'Missing parameter in ' + UPDATE_REPORT_DELIVERY,
                    {
                        payload: action.payload,
                    },
                    APP_MODULE
                );

                return state;
            }

            reportIndex = _.findIndex(state.reports, (report) => {
                return report.id === action.payload.reportId;
            });
            if (reportIndex === -1) {
                console.info('Report not found on page');
                return state;
            }
            return update(state, {
                reports: {
                    [reportIndex]: {
                        availableDownloads: { $set: availableDownloads },
                        lastUpdated: { $set: lastUpdated },
                        latestDocument: { $set: latestDocument },
                    },
                },
            });

        case UPDATE_REPORT_DELIVERED_STATUS:
            const { latestAvailableDownload }: { latestAvailableDownload: LatestDocument } = action.payload;

            if (!action.payload || !action.payload.reportId) {
                errorUtils.logAppError(
                    'Missing parameter in ' + UPDATE_REPORT_DELIVERED_STATUS,
                    {
                        payload: action.payload,
                    },
                    APP_MODULE
                );

                return state;
            }

            const indexOfReport = _.findIndex(state.reports, (report) => {
                return report.id === action.payload.reportId;
            });

            if (indexOfReport === -1) {
                errorUtils.logAppError(
                    'Error identifying article in category ' + UPDATE_REPORT_DELIVERED_STATUS,
                    {
                        payload: action.payload,
                    },
                    APP_MODULE
                );

                return state;
            }

            return update(state, {
                reports: {
                    [indexOfReport]: {
                        latestDocument: { $set: latestAvailableDownload },
                    },
                },
            });

        case UPDATE_CURRENT_PAGE:
            return update(state, { currentPage: { $set: action.payload } });

        case ADD_BATCH_REPORT_TO_POOLING:
            if (!_.isString(action.payload)) {
                errorUtils.logAppError(
                    'The required payload is not a string in ' + ADD_BATCH_REPORT_TO_POOLING,
                    {
                        payload: action.payload,
                    },
                    APP_MODULE
                );

                return state;
            }

            return update(state, {
                batchReportsPooling: { $set: _.uniq([...state.batchReportsPooling, action.payload]) },
            });

        case REMOVE_BATCH_REPORT_FROM_POOLING:
            if (!_.isString(action.payload)) {
                errorUtils.logAppError(
                    'The required payload is not a string in ' + REMOVE_BATCH_REPORT_FROM_POOLING,
                    {
                        payload: action.payload,
                    },
                    APP_MODULE
                );

                return state;
            }

            const updatedBatchReportsPooling = state.batchReportsPooling.filter((id) => id !== action.payload);

            return update(state, { batchReportsPooling: { $set: updatedBatchReportsPooling } });

        case UPDATE_REPORT_DOCUMENTS_COUNTS: {
            const { snippetIds, category } = action.payload;
            const id: string = action.payload.reportId;

            reportIndex = _.findIndex(state.reports, (report) => report.id === id);

            if (reportIndex > -1) {
                const changedReport: ExpandedRegularReportType = state.reports[reportIndex];

                const numberOfDocumentsPerCategory = _.cloneDeep(changedReport.numberOfDocumentsPerCategory);
                let snippetsCount = _.cloneDeep(changedReport.snippetsCount);
                snippetsCount -= snippetIds.length;

                numberOfDocumentsPerCategory.forEach((entry) => {
                    if (entry.category === category) {
                        entry.count -= snippetIds.length;
                    }
                });

                return update(state, {
                    reports: {
                        [reportIndex]: {
                            numberOfDocumentsPerCategory: { $set: numberOfDocumentsPerCategory },
                            snippetsCount: { $set: snippetsCount },
                        },
                    },
                });
            } else {
                return state;
            }
        }

        case UPDATE_LAST_BATCH_REPORT_PROPERTY:
            const { batchPropertyName, batchPropertyValue } = action.payload;
            if (!batchPropertyName || batchPropertyValue === undefined) {
                errorUtils.logAppError(
                    'Missing parameter in ' + UPDATE_LAST_BATCH_REPORT_PROPERTY,
                    {
                        propertyName: batchPropertyName,
                        propertyValue: batchPropertyValue,
                    },
                    APP_MODULE
                );
                return state;
            }

            return update(state, {
                lastBatchReportStatus: {
                    [batchPropertyName]: { $set: batchPropertyValue },
                },
            });

        case UPDATE_POLLING_STATUS:
            if (!action.payload) {
                errorUtils.logAppError(
                    'Missing parameter in ' + UPDATE_POLLING_STATUS,
                    {
                        propertyName: 'pollingStatus',
                        propertyValue: action.payload,
                    },
                    APP_MODULE
                );
                return state;
            }

            return update(state, {
                pollingStatus: { $set: action.payload },
            });
        default:
            return state;
    }
}
export default reportBuilder;
