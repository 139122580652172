import React from 'react';
import SanctionsTable from '../SanctionsTable/SanctionsTable';
import formatRichMessage from '@utils/formatRichMessage';
import { useIntl } from 'react-intl';
import { SANCTIONS_WATCHLISTS_SECTION } from '@constants';

const SanctionsPodContent = ({ sanctionsRisk }) => {
    const intl = useIntl();
    const { documents } = sanctionsRisk;

    const disclaimerLabel = formatRichMessage({ id: 'SanctionsWatchLists.label.disclaimer' }, intl, {
        a: (...chunks) =>
            `<a data-testid='negativenews-preferences-link' href=#/user-preferences?section=${SANCTIONS_WATCHLISTS_SECTION}>${chunks.join('')}</a>`,
    });

    return (
        <div className='sanctions-pod' data-testid='sanctions-pod-content'>
            <div className='card-disclaimer'>
                <div dangerouslySetInnerHTML={{ __html: disclaimerLabel }} />
            </div>
            <SanctionsTable data={documents} />
        </div>
    );
};

export default SanctionsPodContent;
