import React from 'react';
import NegativeNewsSearchTerms from './NegativeNewsSearchTerms';
import { COMPANY_CHECK } from '@Main/Main.reducers';
import { FE_LOCK_NAMES, USER_PREFERENCES_SECTIONS } from '@constants';
import { FormattedMessage } from 'react-intl';
import AdminLock from '@reusable/AdminLock/AdminLock';
import CustomNewsSearches from './CustomNewsSearches';

const CompanyCheck = (props) => {
    return (
        <div
            className={
                props.userIsAdmin
                    ? 'section-enabled'
                    : props.userLocks.masterLockNewsSearchesCompany
                    ? 'section-disabled section-master-lock'
                    : 'section-enabled'
            }
        >
            <div className="user-preferences-category-container company-check-container">
                {props.userIsAdmin ? (
                    <AdminLock
                        lockName={FE_LOCK_NAMES.masterLockNewsSearchesCompany}
                        onClickLock={props.handleLockClick}
                        isSectionLock={true}
                        isLocked={props.userLocks.masterLockNewsSearchesCompany}
                    />
                ) : (
                    props.userLocks.masterLockNewsSearchesCompany && (
                        <AdminLock lockName={FE_LOCK_NAMES.masterLockNewsSearchesCompany} isDisabled={true} />
                    )
                )}
                <h2
                    className="user-preferences-category-container__segment company-check-container__title"
                    data-track="user-preferences-company-check-container__title"
                >
                    <FormattedMessage id="UserPreferences.leftSideNavigation.companyCheck" />
                </h2>

                <NegativeNewsSearchTerms
                    categories={props.settings.categories}
                    segment={COMPANY_CHECK}
                    handleSelectAll={props.handleSelectAll}
                    userLocks={props.userLocks}
                    updateNegativityLevel={props.updateNegativityLevel}
                    negativityLevel={props.settings.negativityLevel}
                    negativityLevels={props.negativityLevels}
                    languages={props.languages}
                    defaultSources={props.settings.newsSearchSources}
                    updateNewsSourcesSelection={props.updateNewsSourcesSelection}
                    handleNegativeNewsLanguageSelection={props.handleNegativeNewsLanguageSelection}
                    updateCheckedLanguagesUponKey={props.updateCheckedLanguagesUponKey}
                    searchSpecifically={props.settings.searchSpecifically}
                    changeSourcesSearchOption={(option, isAdminUser) =>
                        props.updateCompanySourcesSearchOption(option, isAdminUser)
                    }
                    // admin props
                    userIsAdmin={props.userIsAdmin}
                    handleLockClick={props.handleLockClick}
                    isNegativeSearchSourcesReadonly={props.userLocks.newsSearchSourcesCompany}
                    negativeSearchSourcesLockName={FE_LOCK_NAMES.newsSearchSourcesCompany}
                    negativeSearchLanguageLockName={FE_LOCK_NAMES.negativeSearchLanguageCompany}
                    isNegativeSearchLanguageReadonly={props.userLocks.negativeSearchLanguageCompany}
                    isNegativeSearchLevelReadonly={props.userLocks.negativeSearchNegativityLevelsCompany}
                    negativeSearchLevelLockName={FE_LOCK_NAMES.negativeSearchNegativityLevelsCompany}
                    masterLock={props.userLocks.masterLockNewsSearchesCompany}
                    // auto-save props
                    modifiedSections={props.modifiedSections}
                    arePreferencesSaving={props.arePreferencesSaving}
                    autosaveHasError={props.autosaveHasError}
                    changeMade={props.changeMade}
                />
                <div className="sub-section-separator__no-margins" />
                <div
                    className={
                        props.userIsAdmin
                            ? 'section-enabled'
                            : props.userLocks.customNewsQueryCompanyAll || props.userLocks.masterLockNewsSearchesCompany
                            ? 'section-disabled'
                            : 'section-enabled'
                    }
                >
                    <CustomNewsSearches
                        customNewsQuery={props.customNewsQuery}
                        checkCustomNewsQuery={props.checkCustomNewsQuery}
                        checkLnCustomNewsQuery={props.checkLnCustomNewsQuery}
                        lnCustomNewsQueries={props.lnCustomNewsQueries}
                        segment={COMPANY_CHECK}
                        checkCustomNewsSourceAvailability={props.checkCustomNewsSourceAvailability}
                        adminCustomNewsQuery={props.adminCustomNewsQuery}
                        checkAdminCustomNewsQuery={props.checkAdminCustomNewsQuery}
                        setSuccessBackgroundMessages={props.setSuccessBackgroundMessages}
                        clearBackgroundMessages={props.clearBackgroundMessages}
                        setPopupModel={props.setPopupModel}
                        customNewsSearchHelpUrl={props.customNewsSearchHelpUrl}
                        //admin props
                        isReadonly={
                            (props.userIsAdmin && props.userLocks.customNewsQueryCompanyAll) ||
                            (!props.userIsAdmin &&
                                (props.userLocks.customNewsQueryCompanyAll ||
                                    props.userLocks.masterLockNewsSearchesCompany))
                        }
                        userIsAdmin={props.userIsAdmin}
                        handleLockClick={props.handleLockClick}
                        userLocks={props.userLocks}
                        lockName={FE_LOCK_NAMES.customNewsQueryCompanyAll}
                        //auto-save props
                        modifiedSections={props.modifiedSections}
                        arePreferencesSaving={props.arePreferencesSaving}
                        autosaveHasError={props.autosaveHasError}
                        changeMade={props.changeMade}
                        section={USER_PREFERENCES_SECTIONS[COMPANY_CHECK].CUSTOM_NEWS}
                    />
                </div>
            </div>
        </div>
    );
};

export default CompanyCheck;
