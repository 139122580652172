import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import utils from '@utils/utilities';
import {
    calcHeight,
    getOptionsVisibility,
    getDefaultValuesFromProps,
    setDefaultDatesList,
    isDateCustom,
    extractDates,
} from './utils/dateRangeUtils';
import {
    CALENDAR_DATE_FORMAT_BE,
    CALENDAR_DATE_FORMAT_FE,
    CALENDAR_RANGE_DELIMITER,
    RANGE_TYPE_CUSTOM,
} from '@constants';
import { cloneDeep, isEqual } from 'lodash';
import PostFilterDateRangeSelect from '../DateSelect/PostFilterDateRangeSelect';

export const RadioOptionType = PropTypes.shape({
    label: PropTypes.any,
    checked: PropTypes.any,
    extra: PropTypes.object,
});
// TODO: This needs some serious refactoring, a lot of unrelated logic built up in here and is no longer reusable

class DateRangeRadioGroup extends Component {
    static propTypes = {
        onChange: PropTypes.func,
        handleDateRangeSelect: PropTypes.func,
        options: PropTypes.arrayOf(RadioOptionType),
        selectAll: PropTypes.any,
        className: PropTypes.string,
        label: PropTypes.any,
        hasError: PropTypes.any,
        disabled: PropTypes.bool,
        required: PropTypes.bool,
        fieldId: PropTypes.string,
        visibleItemCount: PropTypes.number,
        dateFormat: PropTypes.string,
        dateFormatOutbound: PropTypes.string,
    };

    static defaultProps = {
        visibleItemCount: -1, // -1 with no limitation
        label: null,
        hasError: false,
        disabled: false,
        required: false,
        className: '',
        fieldId: 'dateRangeRadioGroup',
        defaultListFieldId: 'defaultListDateRadioGroup',
        dateFormat: CALENDAR_DATE_FORMAT_FE,
        dateFormatOutbound: CALENDAR_DATE_FORMAT_BE,
        dateRangeDelimiter: CALENDAR_RANGE_DELIMITER,
    };

    constructor(props) {
        super(props);

        this.state = {};

        this.radioButtons = [];
        this.defaultRadioButtons = [];

        // Internet Explorer 6-11
        this.isIE = /*@cc_on!@*/ false || !!document.documentMode;

        // Edge 20+
        this.isEdge = !this.isIE && !!window.StyleMedia;
    }

    UNSAFE_componentWillUpdate(nextProps, nextState) {
        let result = true;
        if (!nextState[nextProps.category]) {
            this.setDatesListSettings(nextProps);
        } else {
            const categoryState = this.state[nextProps.category];
            if (categoryState && categoryState.options) {
                const options = this.state[nextProps.category].options;

                result = true;
                nextProps.options.forEach((option, index) => {
                    if (!isEqual(option, options[index])) {
                        result = false;
                    }
                });
            }

            if (!result) {
                if (nextProps.options.some((option) => option.show === undefined)) {
                    this.updateDatesPostFilterOptions(nextProps);
                }

                //default state of a newly checked category date filter should show only the default list
                if (nextProps.category !== this.props.category) {
                    let categorySettings = this.state[nextProps.category] ? this.state[nextProps.category] : {};
                    categorySettings.showAll = false;
                    this.setState(
                        {
                            [nextProps.category]: categorySettings,
                        },
                        () => {
                            this.setDatesListSettings(nextProps);
                        }
                    );
                } else {
                    this.setDatesListSettings(nextProps);
                }
            }
        }
    }

    componentDidMount() {
        if (!this.state[this.props.category]) {
            this.updateDatesPostFilterOptions(this.props);
        }
    }

    setDatesListSettings = (props) => {
        let categorySettings = this.state[props.category] ? cloneDeep(this.state[props.category]) : {};
        let { defaultValue, checkedOpt, isDefaultDateCustom, isCheckedOptionCustom } = getDefaultValuesFromProps(props);
        let defaultListOptions;
        if (defaultValue) {
            if (isDefaultDateCustom && isDateCustom(checkedOpt.count)) {
                categorySettings.showCalendar = true;
                categorySettings.initialValues = extractDates(props, defaultValue);
            }
            if (checkedOpt?.count !== 'custom' && !isDateCustom(checkedOpt?.count)) {
                categorySettings.showCalendar = false;
            }
            if (isDefaultDateCustom && !isDateCustom(checkedOpt.count)) {
                categorySettings.showCalendar = false;
            }

            let defaultDatesValues = setDefaultDatesList(defaultValue, props.options);
            defaultListOptions = this.createDefaultDatesList(props, checkedOpt, categorySettings, defaultDatesValues);
        }

        // on a regular search, if the category date was changed to custom and is different from the default date
        // when coming back to the category after a fooling around with other categories
        // extract the dates and show the calendar

        if (
            !!checkedOpt &&
            (isCheckedOptionCustom || checkedOpt.count === RANGE_TYPE_CUSTOM) &&
            checkedOpt.count !== defaultValue
        ) {
            categorySettings.showCalendar = true;
            categorySettings.initialValues = extractDates(props, checkedOpt.count);
        }
        this.setState(
            {
                [props.category]: { ...categorySettings, ...defaultListOptions },
            },
            () => {
                this.setComponentHeight(this.state[props.category]);
            }
        );
    };

    // sets the height of the component depending on the dynamic default list
    setComponentHeight = (componentSettings) => {
        if (componentSettings && componentSettings.defaultList && this.defaultRadioButtons.length > 0) {
            componentSettings.totalHeight = calcHeight(this.defaultRadioButtons, componentSettings.defaultList);
            componentSettings.defaultListTotalHeight = componentSettings.totalHeight;
            this.setState(
                {
                    [this.props.category]: componentSettings,
                },
                () => {
                    let componentSettings = cloneDeep(this.state[this.props.category]);

                    if (componentSettings.scrollList) {
                        this.listScrollToOption(componentSettings);
                    } else {
                        if (componentSettings.scrollDefaultCalendar) {
                            this.defaultListScrollToBottom();
                        } else if (componentSettings.scrollEpandedCalendar) {
                            this.scrollToBottom();
                        }
                    }
                }
            );
        }
    };

    updateDatesPostFilterOptions = (props) => {
        /*
         * defaultValue (for regular search and rerun searches) can be a custom date or a regular date and it will be:
         *   - the existing selected value of a previous search (history item/alert), if search is rerun
         *   - the value from preferences, if search is not rerun
         */
        // eslint-disable-next-line  unused-imports/no-unused-vars
        let { defaultValue, checkedOpt, isDefaultDateCustom } = getDefaultValuesFromProps(props);
        // create a list of options to determine which options we should show and manipulate if necessary
        let options = props.options.map((option) => {
            option.show = option.show || option?.count === checkedOpt?.count;
            return option;
        });

        // sets the default dates list and the expanded list options visibility
        if (options) {
            options = getOptionsVisibility(options);
        }

        // if the un-mounting of the component was triggered by a category that does not have a date range
        // when we come back to the original category, the component is mounted. If on the preference, we already have all the options as they suppose to be,
        // the update of the component is not triggered, therefore, the dates list settings are not set
        // so we need to trigger it

        let willUpdate = false;
        this.props.options.forEach((option, index) => {
            if (!isEqual(option, options[index])) {
                willUpdate = true;
            }
        });

        if (!willUpdate) {
            this.setDatesListSettings(props);
        }
    };

    // create the default radios list in the component based on the default date
    createDefaultDatesList = (props, checkedOpt, categorySettings, defaultDatesValues) => {
        let defaultOptionsList = cloneDeep(props.options);
        let newOptionAdded = categorySettings.newOptionAddedToDefaultList;
        let oldOptionRemoved = false;
        let scrollList;

        defaultOptionsList.forEach((option) => {
            option.show = false;

            if (defaultDatesValues.indexOf(option.count) > -1 || isDateCustom(option.count)) {
                option.show = true;
            } else if (checkedOpt && checkedOpt.count === option.count) {
                option.show = true;
                option.checked = true;
                newOptionAdded = !categorySettings.newOptionAddedToDefaultList;
            }

            if (props.defaultDate === option.count) {
                option.show = true;
            }
        });

        if (!this.isEdge && !this.isIE) {
            if (this.radioGroup) {
                if (categorySettings.newOptionAddedToDefaultList && this.radioButtons[0]) {
                    scrollList = this.radioGroup.scrollTop + this.radioButtons[0].clientHeight;
                } else {
                    scrollList = this.radioGroup.scrollTop;
                }
            }
        }
        return {
            defaultList: defaultOptionsList,
            defaultDatesValues,
            newOptionAddedToDefaultList: newOptionAdded,
            oldOptionRemovedFromDefaultList: oldOptionRemoved,
            scrollList,
        };
    };

    // when the custom date calendar appears, scroll the component down to focus on the calendar
    scrollToBottom = () => {
        if (!this.isEdge && !this.isIE && this.radioButtons && this.radioGroup) {
            if (this.radioGroup && typeof this.radioGroup.scrollTo === 'function') {
                this.radioGroup.scrollTo(0, this.radioGroup.scrollHeight);
            }
        }
    };

    defaultListScrollToBottom = () => {
        if (!this.isEdge && !this.isIE) {
            if (this.defaultListRadioGroup && typeof this.defaultListRadioGroup.scrollTo === 'function') {
                this.defaultListRadioGroup.scrollTo(0, this.defaultListRadioGroup.scrollHeight);
            }
        }
    };

    // keep scroll in the expanded list at re-render
    listScrollToOption = (scrollList) => {
        if (!this.isEdge && !this.isIE && this.radioButtons && this.radioGroup) {
            this.radioGroup.scrollTo(0, scrollList);
        }
    };

    // triggered by ShowMore button
    handleToggleVisibility = () => {
        let categorySettings = this.state[this.props.category];
        categorySettings.showAll = !categorySettings.showAll;
        this.setState(
            {
                [this.props.category]: categorySettings,
            },
            () => {
                // if the user collapses the extensive list, add the checked option to the defaults list (if it does not show there), so the user sees what he checked
                if (!this.state[this.props.category].showAll) {
                    if (categorySettings && categorySettings.checkedOpt) {
                        // reconstruct the default radios list
                        this.createDefaultDatesList(
                            this.props,
                            categorySettings.checkedOpt,
                            categorySettings,
                            categorySettings.defaultDatesValues
                        );
                    }
                } else {
                    // remove the height so the default list scroll won't appear
                    if (categorySettings.showCalendar) {
                        categorySettings.defaultListTotalHeight = 'unset';

                        this.setState({
                            [this.props.category]: categorySettings,
                        });
                    }
                }
            }
        );
    };

    applyCustomDate = (e) => {
        let categorySettings = cloneDeep(this.state[this.props.category]);

        let fromDate = utils
            .getUserLocaleDateWithoutTimezone(categorySettings.initialValues.from)
            .format(this.props.dateFormatOutbound);
        let toDate = utils
            .getUserLocaleDateWithoutTimezone(categorySettings.initialValues.to)
            .format(this.props.dateFormatOutbound);

        if (fromDate && toDate) {
            const options = this.props.options.map((opt) =>
                opt.label === utils.dateRangeToText(RANGE_TYPE_CUSTOM)
                    ? { ...opt, count: fromDate + ';' + toDate }
                    : { ...opt }
            );

            let customDateOption = options.find((option) => option.label === utils.dateRangeToText(RANGE_TYPE_CUSTOM));
            categorySettings.checkedOpt = customDateOption;

            this.setState({
                [this.props.category]: categorySettings,
            });
            this.props.onChange(options, customDateOption, e);
        }
    };

    onRangeSelect = (fromDate, toDate) => {
        let categorySettings = this.state[this.props.category];

        if (fromDate) {
            if (categorySettings.initialValues) {
                categorySettings.initialValues.from = fromDate;
            } else {
                categorySettings.initialValues = {
                    from: fromDate,
                };
            }

            this.setState({
                [this.props.category]: categorySettings,
            });
        }

        if (toDate) {
            if (categorySettings.initialValues) {
                categorySettings.initialValues.to = toDate;
            } else {
                categorySettings.initialValues = {
                    to: toDate,
                };
            }

            this.setState({
                [this.props.category]: categorySettings,
            });
        }
    };

    // triggered by onChange()
    handleDefaultListDateSelect = (option, e, categorySettings) => {
        categorySettings.defaultList = categorySettings.defaultList.map((defaultOption) => {
            return isEqual(defaultOption, option) ? { ...option, checked: true } : { ...defaultOption, checked: false };
        });

        categorySettings.showCalendar = option.label === utils.dateRangeToText(RANGE_TYPE_CUSTOM);
        e.persist();
        this.handleDateRangeSelect(option, e, categorySettings);
    };

    // triggered by onChange()
    handleDateRangeSelect = (option, e, categorySettings) => {
        const options = this.props.options.map((opt) => {
            return opt.count === option.count ? { ...opt, checked: true } : { ...opt, checked: false };
        });
        option.checked = !option.checked;

        if (option.count === RANGE_TYPE_CUSTOM) {
            categorySettings.showCalendar = true;

            e.persist();
            this.setState(
                {
                    [this.props.category]: categorySettings,
                },
                () => {
                    // scroll to bottom to see calendar only in the expanded list
                    if (!this.isIE && !this.isEdge) {
                        if (e.target.name === this.props.fieldId) {
                            this.scrollToBottom();
                            categorySettings.scrollDefaultCalendar = false;
                            categorySettings.scrollEpandedCalendar = true;
                        }
                        // scroll to calendar in default List if the calendar appears
                        if (e.target.name === this.props.defaultListFieldId) {
                            this.defaultListScrollToBottom();
                            categorySettings.scrollDefaultCalendar = true;
                            categorySettings.scrollEpandedCalendar = false;
                        }
                    }
                }
            );
        } else {
            categorySettings.showCalendar = !!isDateCustom(option.count);
        }

        categorySettings.checkedOpt = option;
        this.setState({
            [this.props.category]: categorySettings,
        });
        this.props.onChange(options, option, e);
    };

    render() {
        let categorySettings = this.state[this.props.category] ? this.state[this.props.category] : {};
        categorySettings.options = this.props.options;
        const shouldHaveDefaultList = categorySettings && categorySettings.defaultList;
        const defaultList = shouldHaveDefaultList
            ? categorySettings.defaultList
                  .map(
                      (option, index) =>
                          option.show && (
                              <div
                                  key={this.props.defaultListFieldId + index}
                                  className={'default-list-radio-option custom-radio'}
                                  ref={(ref) => (this.defaultRadioButtons[index] = ref)}
                              >
                                  <input
                                      type="radio"
                                      name={this.props.defaultListFieldId}
                                      id={this.props.defaultListFieldId + index}
                                      data-testid={this.props.defaultListFieldId + index}
                                      checked={option.checked}
                                      onChange={(e) => this.handleDefaultListDateSelect(option, e, categorySettings)}
                                      {...option.extra}
                                  />
                                  <label className="radio-label" htmlFor={this.props.defaultListFieldId + index}>
                                      <FormattedMessage
                                          id={
                                              'General.DateRange.' +
                                              (utils.getDateFromString(
                                                  option.count.split(';')[0],
                                                  CALENDAR_DATE_FORMAT_BE
                                              )
                                                  ? RANGE_TYPE_CUSTOM
                                                  : option.count)
                                          }
                                      />
                                  </label>
                              </div>
                          )
                  )
                  .filter((radio) => {
                      return radio;
                  })
            : null;
        const radioButtons =
            categorySettings && categorySettings.options
                ? categorySettings.options
                      .map(
                          (option, index) =>
                              option.show && (
                                  <div
                                      key={this.props.fieldId + index}
                                      className={'radio-option custom-radio'}
                                      ref={(ref) => (this.radioButtons[index] = ref)}
                                  >
                                      <input
                                          type="radio"
                                          name={this.props.fieldId}
                                          id={this.props.fieldId + index}
                                          checked={option.checked}
                                          disabled={!!option.disabled || this.props.disabled}
                                          onChange={(e) => this.handleDateRangeSelect(option, e, categorySettings)}
                                          {...option.extra}
                                      />
                                      <label className="radio-label" htmlFor={this.props.fieldId + index}>
                                          <FormattedMessage
                                              id={
                                                  'General.DateRange.' +
                                                  (utils.getDateFromString(
                                                      option.count.split(';')[0],
                                                      CALENDAR_DATE_FORMAT_BE
                                                  )
                                                      ? RANGE_TYPE_CUSTOM
                                                      : option.count)
                                              }
                                          />
                                      </label>
                                  </div>
                              )
                      )
                      .filter((radio) => {
                          return radio;
                      })
                : null;

        let visibleOptions =
            shouldHaveDefaultList && !categorySettings.showAll
                ? 0
                : this.props.visibleItemCount === -1 || categorySettings.showAll
                ? this.props.options.length
                : this.props.visibleItemCount;

        const radioWrapperStyle = categorySettings && {
            minHeight: categorySettings.totalHeight,
        };

        const defaultRadioWrapperStyle = categorySettings && {
            minHeight: categorySettings.defaultListTotalHeight,
        };
        // display 'More' button only if there are more than 1 options to be shown
        const shoulDisplayMoreLink = shouldHaveDefaultList && this.props.options.filter((item) => item.show).length > 1;

        return (
            radioButtons && (
                <>
                    <div
                        className="radios-wrapper__content"
                        style={radioWrapperStyle}
                        ref={(ref) => (this.radioGroup = ref)}
                    >
                        <>
                            <div
                                className="radios-wrapper__content__default-list"
                                style={defaultRadioWrapperStyle}
                                ref={(ref) => (this.defaultListRadioGroup = ref)}
                            >
                                {!categorySettings.showAll && defaultList}
                                {radioButtons.slice(0, visibleOptions)}
                            </div>
                            {categorySettings.showCalendar && (
                                <PostFilterDateRangeSelect
                                    testid="calendar"
                                    onRangeSelect={this.onRangeSelect}
                                    initialValues={categorySettings.initialValues}
                                    dateFormat={this.props.dateFormat}
                                    fromResultsList={true}
                                    applyCustomDate={this.applyCustomDate}
                                    disabled={categorySettings.disableCalendar}
                                    radioGroupReference={this.radioGroup}
                                    shouldUpdateCustomDatesState={this.props.shouldUpdateCustomDatesState}
                                    updateCustomDatesBasedOnNNV={this.props.updateCustomDatesBasedOnNNV}
                                />
                            )}
                        </>
                    </div>
                    {shoulDisplayMoreLink && (
                        <a onClick={this.handleToggleVisibility} className={'toggle-visibility'}>
                            {categorySettings && categorySettings.showAll ? (
                                <FormattedMessage id="General.label.less" />
                            ) : (
                                <FormattedMessage id="General.label.more" />
                            )}
                        </a>
                    )}
                </>
            )
        );
    }
}
export default injectIntl(DateRangeRadioGroup, { forwardRef: true });
