import React, { Fragment } from 'react';

export const withDocumentTabsViewTitle = (WrappedComponent) => (props) => {
    const documentPreview = props.documentPreview;
    return !documentPreview ? (
        <Fragment>
            <h2
                className="tabs-view-article-title"
                dangerouslySetInnerHTML={{
                    __html: props.article ? props.article.unhighlightedTitle : props.articleTitle,
                }}
            />
            <WrappedComponent {...props} />
        </Fragment>
    ) : (
        <WrappedComponent {...props} />
    );
};
