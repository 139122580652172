import React from 'react';

const LOADING = 'LOADING';
const DONE = 'DONE';
const ERROR = 'ERROR';

export default function withSpinner(SomeComponent, customClasses) {
    class WithSpinner extends React.Component {
        constructor(props) {
            super(props);

            this.state = { _widgetState: DONE };

            if (this.props.parent) {
                if (!this.props.name) {
                    throw new Error('Name is required when parent is present');
                }
            }

            this.showSpinner = this.showSpinner.bind(this);
            this.hideSpinner = this.hideSpinner.bind(this);
            this.showError = this.showError.bind(this);
        }

        showSpinner() {
            this.setState({ _widgetState: LOADING });
            if (this.props.parent && this.props.parent.startChildLoad) {
                this.props.parent.startChildLoad(this.props.name);
            }
        }

        hideSpinner() {
            this.setState({ _widgetState: DONE });
            if (this.props.parent && this.props.parent.finishChildLoad) {
                this.props.parent.finishChildLoad(this.props.name);
            }
        }

        showError() {
            this.setState({ _widgetState: ERROR });
            if (this.props.parent && this.props.parent.handleChildError) {
                this.props.parent.handleChildError(this.props.name);
            }
        }

        render() {
            return (
                <div
                    className={
                        'spinner-wrapper' +
                        (this.state._widgetState === LOADING ? ' loading' : ' loaded') +
                        (customClasses ? ' ' + customClasses : '')
                    }
                >
                    <div className={'spinner-wrapper__content'}>
                        <SomeComponent {...this.props} spinner={this} />
                    </div>
                </div>
            );
        }
    }

    return WithSpinner;
}
