export const UPDATE_NEGATIVITY_POD_RISK = 'UPDATE_NEGATIVITY_POD_RISK';
export const UPDATE_TOP_NEGATIVE_TERMS = 'UPDATE_TOP_NEGATIVE_TERMS';
export const CLEAR_NEGATIVE_NEWS_VISUALIZATION = 'CLEAR_NEGATIVE_NEWS_VISUALIZATION';

const negativeNewsVisualisationActions = {
    updateNegativityPodRisk: (negativityRisk) => {
        return {
            type: UPDATE_NEGATIVITY_POD_RISK,
            payload: negativityRisk,
        };
    },

    updateTopNegativeTerms: (negativeTerms) => {
        return {
            type: UPDATE_TOP_NEGATIVE_TERMS,
            payload: negativeTerms,
        };
    },

    clearNegativeNewsVisualization: () => {
        return {
            type: CLEAR_NEGATIVE_NEWS_VISUALIZATION,
            payload: null,
        };
    },
};

export default negativeNewsVisualisationActions;
