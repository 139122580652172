import { useState } from 'react';

const useEntities = () => {
    const [selectedItems, setSelectedItems] = useState([]);
    const [selectAllSetting, setSelectAllSetting] = useState({
        includeDB: false,
        limit: 0,
    });
    const [unselectedItemIds, setUnselectedItemIds] = useState([]);

    const clearAllSelectedItems = () => {
        setSelectedItems([]);
        setSelectAllSetting({ includeDB: false, limit: 0 });
        setUnselectedItemIds([]);
    };

    return {
        selectedItems,
        setSelectedItems,
        selectAllSetting,
        setSelectAllSetting,
        unselectedItemIds,
        setUnselectedItemIds,
        clearAllSelectedItems,
    };
};

export default useEntities;
