/**
 * COMMON API ENDPOINTS
 */

import xhr from '@utils/xhr';
import utils from '@utils/utilities';
import reduxStore from '@reduxStore';
import { hideAdminPermissionBanner } from '@reusable/AdminPermissionBanner/utils/AdminPermissionBannerUtils';
import { ADMIN_PERMISSION_CHANGED, API_URL_IS_TRIAL_USER, URL_NON_DILIGENCE_PLUS_UPGRADE_LIST } from '@constants';
import { URL_PR_SEARCH_FORM } from '@utils/promiseQueue';

const api = {
    userLogOut(args = {}) {
        return xhr
            .post({
                url: '/api/user/logout',
            })
            .then((response) => {
                if (response.ok) {
                    reduxStore.dispatch({
                        type: 'USER_LOGOUT_SUCCESS',
                    });

                    utils.clearUserSession();

                    if (Object.keys(args).includes(ADMIN_PERMISSION_CHANGED) && args[ADMIN_PERMISSION_CHANGED]) {
                        hideAdminPermissionBanner();
                    }

                    let auth = response.body;
                    let dilSettings = JSON.parse(localStorage.getItem('diligence'));

                    window.location =
                        auth.wamLogout +
                        '&redirecturl=' +
                        encodeURIComponent(auth.customerUi) +
                        '&lnsi=' +
                        dilSettings.authInfo.lnsi;
                }
            })
            .catch((error) => {
                console.log('********************** Failed to log out ********************');
                console.error(error);
                reduxStore.dispatch({
                    type: 'USER_LOGOUT_FAIL',
                });
                xhr.handleAllErrors(error);
            });
    },

    fetchContentTypeSources: () => {
        return xhr.post({
            url: '/api/search/content-type-sources',
        });
    },

    adminPreferences: (language) => {
        return xhr.get({ url: `/api/user/admin/preferences?language=${language}` });
    },

    userPreferences: (language) => {
        return xhr.get({ url: `/api/user/preferences?language=${language}` });
    },

    publicRecordsAuthorization: () => {
        return xhr.get({
            url: URL_PR_SEARCH_FORM,
        });
    },

    uboAuthorization: () => {
        return xhr.get({
            url: '/api/ubo/isAuthorized?wamCustomerId=' + reduxStore.getState().user.customerId,
        });
    },

    entityViewAuthorization: () => {
        return xhr.get({
            url: '/api/multipleentities/isAuthorized',
        });
    },

    trialUserAuthorization: () => {
        return xhr.get({ url: API_URL_IS_TRIAL_USER });
    },

    isNonDiligencePlusUserForUpgradeAuthorization: () => {
        return xhr.get({
            url: URL_NON_DILIGENCE_PLUS_UPGRADE_LIST,
        });
    },
};

export default api;
