import React from 'react';
import { injectIntl } from 'react-intl';
import { withToggleVisibility } from '../HOC/withToggleVisibility';
import formatRichMessage from '@utils/formatRichMessage';

function ModalExplanation(props) {
    return (
        <div className="popup-modal__explanation">
            <div className="explanation">
                {formatRichMessage({ id: props.explanation.explanation }, props.intl, { term: props.explanation.term })}
            </div>
            <div className="horizontal-divider" />
        </div>
    );
}

export { ModalExplanation as TestModalExplanation };
export default withToggleVisibility(injectIntl(ModalExplanation));
