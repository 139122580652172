//@flow

import update from 'immutability-helper/index';
import {
    UPDATE_ADHOC_SEARCH,
    UPDATE_ADHOC_SEARCH_SOURCES,
    UPDATE_ADHOC_PROPERTY,
    RESET_ADHOC_OBJECT,
} from './AdHocSearch.actions';

import type { Action, State } from './AdHocSearch.actions';
import type { AdHocSearchType } from '../flow/AdHocSearch.typeGuards';

function adHocSearch(state: State = {}, action: Action): State {
    switch (action.type) {
        case UPDATE_ADHOC_SEARCH: {
            const adHocSettings: AdHocSearchType = { ...state, ...action.payload.adHocSettings };

            return update(state, { $set: adHocSettings });
        }

        case RESET_ADHOC_OBJECT: {
            return update(state, { $set: {} });
        }

        case UPDATE_ADHOC_SEARCH_SOURCES: {
            return Object.assign({}, state, { sources: action.payload });
        }

        case UPDATE_ADHOC_PROPERTY: {
            const { property, value }: { property: string, value: mixed } = action.payload;

            return update(state, { [property]: { $set: value } });
        }

        default:
            return state;
    }
}

export default adHocSearch;
