import { isEmpty, isNil, uniqBy, uniqueId } from 'lodash';
import { BOV_NODE_TYPES, BOV_RELATIONSHIPS_TYPES, BOV_TOOLTIP_CONTENT_ORDER } from '@constants';

export const bovOrganizationInfo = (article) => {
    const exists = !!(article && article.bovOrganization && !isEmpty(article.bovOrganization));
    const values = exists ? article.bovOrganization : null;

    return {
        exists,
        values,
    };
};

export const flatTooltipContent = (content) => {
    if (isNil(content)) {
        return [];
    }

    return Object.keys(content)
        .sort((a, b) => BOV_TOOLTIP_CONTENT_ORDER.indexOf(a) - BOV_TOOLTIP_CONTENT_ORDER.indexOf(b))
        .map((key) => content[key]);
};

export const mapNodes = (shareholdersInfo) => {
    let nodes = [];

    if (!shareholdersInfo) return nodes;

    const recursively = (structure) => {
        structure.map((shareholder) => {
            let node = {
                entitytype: shareholder.entitytype,
                id: shareholder.cnpjNumber || shareholder.cpfNumber,
                name: shareholder.name || shareholder.orgName,
                relationshipType: shareholder.relationshipType,
                relationships: shareholder.relationships || null,
                role: shareholder.role,
            };

            node =
                shareholder.entitytype == BOV_NODE_TYPES.PERSON
                    ? {
                          ...node,
                          cpfFormatedNumber: shareholder.cpfFormatedNumber,
                      }
                    : {
                          ...node,
                          cnpjFormatedNumber: shareholder.cnpjFormattedNumber,
                      };

            nodes.push(node);

            if (shareholder.relationships) {
                recursively(shareholder.relationships);
            }
        });
    };
    recursively(shareholdersInfo);

    return uniqBy(
        nodes.sort((node) => (node.relationships ? -1 : 1)),
        (node) => node.id
    );
};

export const destructureByEntitytype = (nodes) => {
    const root = [nodes[0]];
    const person = nodes.filter((n, i) => i !== 0 && n.entitytype === BOV_NODE_TYPES.PERSON);
    const company = nodes.filter((n, i) => i !== 0 && n.entitytype === BOV_NODE_TYPES.COMPANY);

    return {
        all: nodes,
        root,
        person,
        company,
    };
};

export const mapLinks = (nodes) => {
    const links = [];

    nodes.map((node) => {
        if (node.relationships) {
            node.relationships.map((child) => {
                let relationshipType = child.relationshipType;

                if (child.relationshipType === BOV_RELATIONSHIPS_TYPES.UNKNOWN) {
                    relationshipType =
                        node.entitytype === BOV_NODE_TYPES.PERSON && node.entitytype === child.entitytype
                            ? BOV_RELATIONSHIPS_TYPES.FAMILY
                            : BOV_RELATIONSHIPS_TYPES.PROFESSIONAL;
                }

                links.push({
                    id: uniqueId(),
                    source: node.id,
                    target: child.cpfNumber || child.cnpjNumber,
                    role: child.role,
                    relationshipType,
                });
            });
        }
    });

    return links;
};

export const formatTreeViewData = (bovInfo) => {
    const { name, address, cnpjNumber, cnpjFormattedNumber, shareholderInfo, cadastralStatus, cadastralSituation } =
        bovInfo;
    const nodes = destructureByEntitytype([
        {
            name,
            address,
            cadastralStatus,
            cadastralSituation,
            id: cnpjNumber,
            cnpjFormattedNumber,
            relationships: shareholderInfo,
            isMain: true,
        },
        ...mapNodes(shareholderInfo),
    ]);

    return {
        nodes,
        links: mapLinks(nodes.all),
    };
};
