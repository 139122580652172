import React from "react";
import { FormattedMessage } from 'react-intl';

const ClearAll = ({ onClick, className }) => {
    return (
        <button
            className={`notification-clear-all-button  ${className ?? ''}`}
            data-testid="notification-clear-all-button "
            onClick={() => onClick()}
        >
            <FormattedMessage id={'General.label.clearAll'} />
        </button>
    )
};

export default ClearAll;
