import React from 'react';
import { Loader } from 'semantic-ui-react';
import { FormattedMessage, injectIntl } from 'react-intl';
import utils from '@utils/utilities';
import formatRichMessage from '@utils/formatRichMessage';

const renderCommentsList = (commentsList, onEdit, onDelete, deletedCommentId) =>
    commentsList.map((comm, index) => {
        const isLoading = comm.id === deletedCommentId;
        const dateFormat = utils.getUserLocaleDate(comm.createdOn).format('ll');
        const timeFormat = utils.getUserLocaleDate(comm.createdOn).format('LT');
        const readableTimestamp = `${dateFormat}: ${timeFormat}`;

        return (
            <Comment
                key={comm.id}
                index={index}
                commentId={comm.id}
                text={comm.text}
                createdOn={readableTimestamp}
                isLoading={isLoading}
                onEdit={() => onEdit(comm.id, comm.text)}
                onDelete={() => onDelete(comm.id)}
            />
        );
    });

const CommentsSection = (props) => {
    const shouldRenderComments = props.commentsList && props.commentsList.length > 0;

    return (
        <div className="comments-section-wrapper">
            <div className="comments-list-wrapper">
                <div className="comments-list-wrapper__header">
                    <h3 className="comments-list-username">{props.username}</h3>
                    <button
                        type="button"
                        className="la-CloseRemove comments-list-close-button"
                        data-testid="comment-section-close-button"
                        onClick={props.onClose}
                    />
                </div>
                <div className="comments-list-wrapper__content">
                    {shouldRenderComments &&
                        renderCommentsList(props.commentsList, props.onEdit, props.onDelete, props.deletedCommentId)}
                </div>
            </div>
            <div className="comments-input-wrapper">
                <CommentInput
                    maxLength={props.maxLength}
                    value={props.value}
                    onChange={props.onChange}
                    onSubmit={props.onSubmit}
                    isLoading={props.isLoading}
                    errorMessage={props.errorMessage}
                    intl={props.intl}
                    isInputDisabled={props.isInputDisabled}
                />
            </div>
        </div>
    );
};

const Comment = (props) => (
    <div className="comment-wrapper" data-testid={`comment-wrapper-${props.index}`}>
        <div className="comment-content-wrapper">
            <p className="comment-content">{props.text}</p>
            <span className="comment-timestamp">{props.createdOn}</span>
        </div>
        <div className={`comment-actions-wrapper ${props.isLoading ? 'loading' : ''}`}>
            {props.isLoading ? (
                <Loader data-testid={`deleting-comment-loading-spinner-${props.commentId}`} active size="small" />
            ) : (
                <>
                    <button
                        className="comment-action-button"
                        data-testid="comment-action-delete-button"
                        onClick={props.onDelete}
                    >
                        <FormattedMessage id="General_CoreFunctionality_UIText_general.delete" />
                    </button>
                    <button
                        className="comment-action-button"
                        data-testid="comment-action-edit-button"
                        onClick={props.onEdit}
                    >
                        <FormattedMessage id="General_CoreFunctionality_UIText_general.edit" />
                    </button>
                </>
            )}
        </div>
    </div>
);

const CommentInput = (props) => {
    const remainingNrCharacters = props.maxLength - props.value.length;
    const isLoadingClassName = props.isLoading ? 'loading' : '';
    const placeholderMessage = formatRichMessage(
        { id: 'General_CoreFunctionality_UIText_general.addComment' },
        props.intl
    );
    const isCommentSubmitDisabled = !(props.value.length > 0) || props.isLoading;

    return (
        <>
            {props.errorMessage && (
                <span className="validationAlertMessage">
                    <FormattedMessage id={props.errorMessage} />
                </span>
            )}
            <div className={`comment-input-textarea-wrapper ${isLoadingClassName}`}>
                <textarea
                    className="comment-input-textarea"
                    data-testid="comment-section-input"
                    value={props.value}
                    placeholder={placeholderMessage}
                    disabled={props.isInputDisabled || props.isLoading}
                    onChange={(e) => props.onChange(e.target.value)}
                    maxLength={props.maxLength}
                    onKeyDown={props.onSubmit}
                    autoFocus
                />
            </div>
            {props.isLoading && <Loader data-testid="comment-section-input-loading-spinner" active size="small" />}
            <button
                className={`la-Comment-submit-button${isCommentSubmitDisabled ? '_disabled' : ''}`}
                disabled={isCommentSubmitDisabled}
                data-testid="entity-view-comment-submit-button"
                onClick={props.onSubmit}
            />
            {!props.isLoading && (
                <span className="characters-remaining">
                    <FormattedMessage
                        id="Alerts.createAlert.charactersRemaining"
                        values={{ charsLeft: remainingNrCharacters }}
                    />
                </span>
            )}
        </>
    );
};

export default injectIntl(CommentsSection);
