import React from 'react';
import utils from '@utils/utilities';

/**
 * HOC to connect provider to another handler component
 * @param ProviderComponent
 * @param initState
 */

const withStateProvider =
    (ProviderComponent, initState = {}) =>
    (WrappedComponent) => {
        const withStateProvider = (props) => (
            <ProviderComponent
                initState={initState}
                provideState={(stateProviderProps) => (
                    <WrappedComponent stateProviderProps={stateProviderProps} {...props} />
                )}
            />
        );

        withStateProvider.displayName = `withStateProvider(${utils.getDisplayName(
            ProviderComponent
        )})(${utils.getDisplayName(WrappedComponent)})`;

        return withStateProvider;
    };

export default withStateProvider;
