import articlesManagerActions from '@pages/MainSearch/redux/ArticlesManager.actions';
import articlesUtils from '@pages/MainSearch/ArticlesUtils';
import ReportBuilderApi from '@pages/ReportBuilder/ReportBuilderMain.api';
import utils from '@utils/utilities';
import costCodeUtils from '@utils/costCodeUtils';
import ReportBuilderService from '../ReportBuilderService';
import * as investigationEvents from '@sagas/constants/investigationConstants';

export const SET_CURRENT_REPORT = 'SET_CURRENT_REPORT';
export const UPDATE_SNIPPETS = 'UPDATE_SNIPPETS';
export const UPDATE_VISITED_CATEGORIES = 'UPDATE_VISITED_CATEGORIES';

let currentReportActions = {
    setCurrentReport: function (report) {
        return {
            type: SET_CURRENT_REPORT,
            payload: report,
        };
    },

    markVisitedCategories: function (clickedContentType) {
        return {
            type: UPDATE_VISITED_CATEGORIES,
            payload: clickedContentType,
        };
    },

    addArticlesToReportThunk: (addToReportParams) => (dispatch, getState) => {
        const state = getState();
        const { searchResults, searchParams, investigation, articlesManager, user } = state;

        let { articles, articleType, forcedFilters, originalArticleType } = addToReportParams;
        forcedFilters = forcedFilters ? forcedFilters : [];

        const loadingDocuments = articlesManager.uploadingDocuments[articleType] || [];

        // diff with loading documents, exclude them
        const articleIds = articles
            .map((article) => article.id)
            .filter((articleId) => loadingDocuments.indexOf(articleId) === -1);

        if (articleIds.length === 0) {
            // if no articles left after diff with loading docs => exit
            return;
        }

        let results = searchResults[articleType].count;
        if (originalArticleType) {
            results = searchResults[originalArticleType].count;
        }
        dispatch(articlesManagerActions.setUploadState(articleIds, articleType, true));

        let buildElasticDocumentParams = {
            articles,
            category: articleType,
            searchQueryType: searchParams.searchType,
            searchQuery: searchParams.query,
            results,
            investigationId: investigation.id,
            billingId: investigation.billingId,
            forcedFilters,
            prefilterQuery: searchParams.prefilterQuery,
            originalArticleType,
        };
        let snippetList = articlesUtils.buildElasticDocumentsList(buildElasticDocumentParams, state);

        let costCode = costCodeUtils.getCostCode();

        return articlesUtils
            .getReport({ searchParams, costCode, investigation, language: user.preferences.language })
            .then((report) => {
                return ReportBuilderApi.addElasticDocumentsToReportApi(report.reportId, snippetList);
            })
            .then((reportSnippets) => {
                // added title in order to differentiate the 0 results
                // if the all the snippets were already added the response will be an empty array
                if (reportSnippets && reportSnippets.length > 0) {
                    reportSnippets[0].title = articles[0].title;
                    let newReport = getState().currentReport;
                    newReport.articlesSnippets = newReport.articlesSnippets.concat(reportSnippets);
                    dispatch(currentReportActions.setCurrentReport(newReport));
                    dispatch(articlesManagerActions.setUploadState(articleIds, articleType, false));

                    //new research summary saga
                    dispatch({
                        type: investigationEvents.EVENT_TYPES.documentOperation,
                        payload: {
                            articles: reportSnippets,
                            articleType: originalArticleType,
                            eventSubType: investigationEvents.EVENT_SUBTYPES.added,
                        },
                    });
                }
            })
            .catch((errorObj) => {
                dispatch(articlesManagerActions.setUploadState(articleIds, articleType, false));

                if (errorObj.messageType && errorObj.messageText) {
                    utils.showNotificationsMessage({
                        messageText: errorObj.messageText,
                        messageType: errorObj.messageType,
                    });
                    throw errorObj.error;
                } else {
                    utils.showNotificationsMessage({
                        messageText: 'ReportBuilderPage_Errors.addArticlesToReport',
                        messageType: 'system-error',
                    });
                }
                throw errorObj; // pass the error
            });
    },

    removeArticleFromReportThunk: (article, articleType) => async (dispatch, getState) => {
        const state = getState();
        const { currentReport: report } = state;

        dispatch(articlesManagerActions.setUploadState(article.id, articleType, true));
        let reportSnippet = report.articlesSnippets.find((reportSnippet) => {
            if (article.id === '-1') {
                return reportSnippet.title === article.title;
            } else {
                return reportSnippet.documentId === article.id;
            }
        });

        const [response, error] = await ReportBuilderApi.deleteArticleFromReport(reportSnippet.id);

        if (response) {
            let index = report.articlesSnippets.findIndex((element) => {
                if (article.id === '-1') {
                    return element.title === article.title;
                } else {
                    return element.documentId === article.id;
                }
            });

            report.articlesSnippets.splice(index, 1);
            dispatch(currentReportActions.setCurrentReport(report));
            dispatch(articlesManagerActions.setUploadState([article.id], articleType, false));

            let remainingSnippets = report.articlesSnippets.length;

            if (0 === remainingSnippets) {
                await ReportBuilderService.deleteReport(report.reportId, report.title || '');
            }
            // NEW research summary saga action
            dispatch({
                type: investigationEvents.EVENT_TYPES.documentOperation,
                payload: {
                    articles: reportSnippet.id,
                    articleType: articleType,
                    eventSubType: investigationEvents.EVENT_SUBTYPES.removed,
                },
            });

            if (error) {
                dispatch(articlesManagerActions.setUploadState([article.id], articleType, false));
                utils.showNotificationsMessage({
                    messageText: 'ReportBuilderPage_Errors.deleteArticlesFromReport',
                    messageType: 'system-error',
                });
            }
        }
    },

    updateSnippets: (currentReport, snippets) => {
        return {
            type: UPDATE_SNIPPETS,
            payload: { currentReport, snippets },
        };
    },
};

export default currentReportActions;
