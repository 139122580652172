//@flow
import * as React from 'react';

import {
    CALENDAR_DATE_FORMAT_BE,
    CALENDAR_DATE_FORMAT_FE,
    CALENDAR_RANGE_DELIMITER,
    DATE_RANGE_MIXED,
    DEFAULT_DATE_RANGES,
    RANGE_TYPE_ALL,
    RANGE_TYPE_CUSTOM,
} from '@constants';
import utils from '@utils/utilities';
import formatRichMessage from '@utils/formatRichMessage';
import { injectIntl } from 'react-intl';
import DateRangeSelect from '@reusable/DateSelect/DateRangeSelect';
import { cloneDeep } from 'lodash';
import type { DateRanges } from './flow/Dates.typeGuards';

export const isCustomDateRange = (range: string): boolean => !!range && range.indexOf(CALENDAR_RANGE_DELIMITER) > -1; // not the best way to do this, but good enough
export const isFormattedCustomDateRange = (range: string): boolean => !!range && range.indexOf(' - ') > -1; // not the best way to do this, but good enough

type Props = {
    dateRange: string,
    onChange: (date: string) => void,
    type: string,
    handleCustomDateSelection: () => Promise<any>,
    disabledDropdown?: boolean,
    disabled: boolean,
    dateRanges: DateRanges,
    dateRangeDelimiter: string,
    dateFormatOutbound: string,
    dateFormat: string,
    showCustomCalendar?: boolean,
};

type State = {
    selectedOption: string,
    selectedRange: {
        from: Date | null,
        to: Date | null,
    },
    dateRanges: DateRanges,
    showCustomCalendar: boolean,
};

type InjectedProps = {|
    +intl: Object,
|};

class DateRangesDropDownSearchBar extends React.Component<Props & InjectedProps, State> {
    state: State;

    static defaultProps = {
        dateFormat: CALENDAR_DATE_FORMAT_FE,
        dateFormatOutbound: CALENDAR_DATE_FORMAT_BE,
        dateRanges: DEFAULT_DATE_RANGES,
        dateRangeDelimiter: CALENDAR_RANGE_DELIMITER,
        showCustomCalendar: true,
        disabled: false,
        disabledDropdown: false,
    };

    constructor(props: Props & InjectedProps) {
        super(props);

        let selectedOption: string = this.getOption(props.dateRange);
        let selectedRange: { from: Date | null, to: Date | null } = this.getRange(selectedOption, props);

        this.state = {
            selectedOption,
            selectedRange,
            dateRanges: this.props.dateRanges,
            showCustomCalendar: false,
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        const selectedOption: string = this.getOption(nextProps.dateRange);
        const selectedRange: { from: Date | null, to: Date | null } = this.getRange(selectedOption, nextProps);

        if (this.state.selectedOption !== selectedOption || this.state.selectedRange !== selectedRange) {
            this.setState((prevState) => {
                return {
                    selectedOption,
                    selectedRange,
                    dateRanges: nextProps.dateRanges,
                    showCustomCalendar: prevState.showCustomCalendar && isCustomDateRange(selectedOption),
                };
            });
        }
    }

    handleDropdownChange = async (e: SyntheticEvent<HTMLSelectElement>): Promise<any> => {
        const target: HTMLSelectElement = e.currentTarget;
        const value: string = target.value;
        await this.props.handleCustomDateSelection();
        this.setState({ selectedOption: value }, () => this.updateRange());
    };

    handleDateRangeChange = (from: Date, to: Date) => {
        const selectedRange: { from: Date | null, to: Date | null } = { from, to };
        this.setState({ selectedRange }, () => this.updateRange());
    };

    updateRange = (): void => {
        if (this.state.selectedOption === RANGE_TYPE_CUSTOM) {
            if (this.state.selectedRange.from && this.state.selectedRange.to) {
                const formattedDates: { from: string, to: string } = {
                    from: utils
                        .getUserLocaleDateWithoutTimezone(this.state.selectedRange.from)
                        .format(this.props.dateFormatOutbound),
                    to: utils
                        .getUserLocaleDateWithoutTimezone(this.state.selectedRange.to)
                        .format(this.props.dateFormatOutbound),
                };
                this.setState({ showCustomCalendar: false });

                this.props.onChange(formattedDates.from + this.props.dateRangeDelimiter + formattedDates.to);
            } else {
                this.setState({
                    showCustomCalendar: true,
                });
            }
        } else {
            this.props.onChange(this.state.selectedOption);
            this.setState({
                showCustomCalendar: false,
            });
        }
    };

    getRange = (selectedOption: string, props: Props): { from: Date | null, to: Date | null } => {
        let selectedRange = { from: null, to: null };

        if (selectedOption === RANGE_TYPE_CUSTOM) {
            const dateRangeSplit = props.dateRange.split(props.dateRangeDelimiter);
            if (dateRangeSplit && dateRangeSplit.length) {
                selectedRange = {
                    from: utils.getDateFromString(dateRangeSplit[0], props.dateFormatOutbound),
                    to: utils.getDateFromString(dateRangeSplit[1], props.dateFormatOutbound),
                };
            }
        }
        return selectedRange;
    };

    getOption = (range: string): string => {
        return range ? range : RANGE_TYPE_ALL;
    };

    translateDateRange = (dateRange) => {
        return formatRichMessage({ id: 'General.DateRange.' + dateRange }, this.props.intl);
    };

    getSelectedOption = (option, ranges) => {
        let dateRanges = cloneDeep(ranges);

        if (option && isCustomDateRange(option)) {
            const dateRangeSplit = option.split(this.props.dateRangeDelimiter);

            const selectedRange = {
                from: utils.getDateFromString(dateRangeSplit[0], this.props.dateFormatOutbound),
                to: utils.getDateFromString(dateRangeSplit[1], this.props.dateFormatOutbound),
            };

            const opt =
                utils.getUserLocaleDateWithoutTimezone(selectedRange.from).format(this.props.dateFormat) +
                ' - ' +
                utils.getUserLocaleDateWithoutTimezone(selectedRange.to).format(this.props.dateFormat);

            if (dateRanges.indexOf(opt) < 0) {
                dateRanges.push(opt);
            }

            return {
                option: opt,
                dateRanges,
            };
        }

        if (dateRanges.indexOf(option) < 0) {
            dateRanges.push(option);
        }

        return {
            option,
            dateRanges,
        };
    };

    render(): React.Node {
        const { option, dateRanges } = this.getSelectedOption(this.state.selectedOption, this.state.dateRanges);

        return (
            <div className={`${this.state.showCustomCalendar ? 'custom-date-selected' : ''} date-ranges-dropdown`}>
                <div className="sort-date-range">
                    <select
                        onChange={this.handleDropdownChange}
                        disabled={this.props.disabled || this.props.disabledDropdown}
                        value={this.getOption(option)}
                    >
                        {dateRanges.map((dateRange, index) => (
                            <option
                                key={index}
                                value={dateRange}
                                hidden={isFormattedCustomDateRange(dateRange) || dateRange === DATE_RANGE_MIXED}
                            >
                                {isFormattedCustomDateRange(dateRange) ? dateRange : this.translateDateRange(dateRange)}
                            </option>
                        ))}
                    </select>
                </div>

                {this.state.showCustomCalendar && (
                    <React.Fragment>
                        <div className={'custom-date-picker'}>
                            <DateRangeSelect
                                onRangeSelect={this.handleDateRangeChange}
                                disabled={this.props.disabled || this.props.disabledDropdown}
                                dateFormat={this.props.dateFormat}
                                initialValues={this.state.selectedRange}
                            />
                        </div>
                        <div className="horizontal-divider" />
                    </React.Fragment>
                )}
            </div>
        );
    }
}

export default (injectIntl(DateRangesDropDownSearchBar): React.AbstractComponent<Props>);
