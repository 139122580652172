// @flow
import * as React from 'react';
import GeographyPostFilter from './specific/GeographyPostFilter';
import SimilarNamesPostFilter from './specific/SimilarNamesPostFilter';
import ProximityPostFilter from './specific/ProximityPostFilter';
import SourcePostFilter from './specific/SourcePostFilter';
import LanguagePostFilter from './specific/LanguagePostFilter';
import CompanyPostFilter from './specific/CompanyPostFilter';
import CompanyMentionsPostFilter from './specific/CompanyMentionesPostFilter';
import PersonMentionsPostFilter from './specific/PersonMentionsPostFilter';
import DateRangePostFilter from './specific/DateRangePostFilter';
import SourceTypePostFilter from './specific/SourceTypePostfilter';
import DocketStatusPostFilter from './specific/DocketStatusPostFilter';
import DunsFilterPostFilter from './specific/DunsFilterPostFilter';
import SubjectPostFilter from './specific/SubjectPostFilter';
import IndustryPostFilter from './specific/IndustryPostFilter';
import FuzzySearchPostFilter from './specific/FuzzySearchPostFilter';
import { POSTFILTER_TYPE } from '@constants';
import SuggestedNamesPostFilter from './specific/SuggestedNamesPostFilter';
import NegativityLevelsPostFilter from './specific/NegativityLevelsPostFilter';
import EsgFactorsPostFilter from './specific/EsgFactorsPostFilter';

// MAPPING OF POSTFILTER <-> COMPONENT

type Option = {
    label: string,
    count: number | boolean,
    checked: boolean,
};

type Options = Array<Option>;

type Props = {
    config: {
        values: Options,
        sort: string,
        type: string,
        searchFieldName: string,
        visibleItems: number,
        defaultValue: string,
        hasFullTreeLoaded: boolean,
    },
    defaultDate: string,
    category: string,
    historyCategory: string,
    updatePostFilterConfig?: (
        categoryName: string,
        postFilterType: string,
        values: Array<Object>,
        enabled: boolean
    ) => void,
    updatePostFilterTreeData?: (searchFieldName: string) => void,
    onChangeSort?: (type: string, sort: string) => void,
    onChange?: (
        options: Options,
        option: Option,
        e?: SyntheticEvent<HTMLElement>,
        configType?: string,
        searchFieldName?: string
    ) => void,
    shouldUpdateCustomDatesState: boolean,
    updateCustomDatesBasedOnNNV?: (shouldUpdate: boolean) => void,
    onFuzzyThresholdChange: (option: Option) => void,
};

class PostFilter extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
    }

    handleChange = (options: Array<Option>, option: Option, e?: SyntheticEvent<HTMLElement>) => {
        // adding the postFilterType and searchFieldName params in the change handler
        if (typeof this.props.onChange === 'function') {
            this.props.onChange(options, option, e, this.props.config.type, this.props.config.searchFieldName);
        }
    };

    handleChangeSort = (sort: string) => {
        // adding the postFilterType in the change sort handler
        if (typeof this.props.onChangeSort === 'function') {
            this.props.onChangeSort(this.props.config.type, sort);
        }
    };

    handleFuzzyThresholdChange = (option: Option) => {
        if (typeof this.props.onFuzzyThresholdChange === 'function') {
            this.props.onFuzzyThresholdChange(option);
        }
    };

    render() {
        let PostFilterComponent = null;
        // in an ideal scenario, this mapping should have been done in the postfilter configuration, as a linked component property
        switch (this.props.config && this.props.config.type) {
            case POSTFILTER_TYPE.GEOGRAPHY:
                PostFilterComponent = GeographyPostFilter;
                break;
            case POSTFILTER_TYPE.SIMILAR_NAMES:
                PostFilterComponent = SimilarNamesPostFilter;
                break;
            case POSTFILTER_TYPE.SUGGESTED_NAMES:
                PostFilterComponent = SuggestedNamesPostFilter;
                break;
            case POSTFILTER_TYPE.DATE_RANGE:
                PostFilterComponent = DateRangePostFilter;
                break;
            case POSTFILTER_TYPE.SOURCE:
                PostFilterComponent = SourcePostFilter;
                break;
            case POSTFILTER_TYPE.SOURCE_NAME:
                PostFilterComponent = SourcePostFilter;
                break;
            case POSTFILTER_TYPE.SOURCE_TYPE:
                PostFilterComponent = SourceTypePostFilter;
                break;
            case POSTFILTER_TYPE.COMPANY_MENTIONS:
                PostFilterComponent = CompanyMentionsPostFilter;
                break;
            case POSTFILTER_TYPE.PERSON_MENTIONS:
                PostFilterComponent = PersonMentionsPostFilter;
                break;
            case POSTFILTER_TYPE.LANGUAGE:
                PostFilterComponent = LanguagePostFilter;
                break;
            case POSTFILTER_TYPE.COMPANY:
                PostFilterComponent = CompanyPostFilter;
                break;
            case POSTFILTER_TYPE.PROXIMITY_NEGATIVE_TERMS:
                PostFilterComponent = ProximityPostFilter;
                break;
            case POSTFILTER_TYPE.DOCKET_STATUS:
                PostFilterComponent = DocketStatusPostFilter;
                break;
            case POSTFILTER_TYPE.DUNS_FILTER:
                PostFilterComponent = DunsFilterPostFilter;
                break;
            case POSTFILTER_TYPE.SUBJECT:
                PostFilterComponent = SubjectPostFilter;
                break;
            case POSTFILTER_TYPE.INDUSTRY:
                PostFilterComponent = IndustryPostFilter;
                break;
            case POSTFILTER_TYPE.FUZZY_SEARCH:
                PostFilterComponent = FuzzySearchPostFilter;
                break;
            case POSTFILTER_TYPE.NEGATIVITY_LEVELS:
                PostFilterComponent = NegativityLevelsPostFilter;
                break;
            case POSTFILTER_TYPE.ESG_FACTORS:
                PostFilterComponent = EsgFactorsPostFilter;
                break;

            default:
                console.warn('POSTFILTER TYPE IS MISSING: ', this.props.config && this.props.config.type);
                return null;
        }

        return (
            <PostFilterComponent
                options={this.props.config.values}
                onChange={this.handleChange}
                sort={this.props.config.sort}
                onChangeSort={this.handleChangeSort}
                onFuzzyThresholdChange={this.handleFuzzyThresholdChange}
                defaultDate={this.props.defaultDate}
                category={this.props.category}
                postFilterType={this.props.config.type}
                searchFieldName={this.props.config.searchFieldName}
                visibleItemCount={this.props.config.visibleItems}
                defaultValue={this.props.config.defaultValue}
                updatePostFilterConfig={this.props.updatePostFilterConfig}
                historyCategory={this.props.historyCategory}
                updatePostFilterTreeData={this.props.updatePostFilterTreeData}
                hasFullTreeLoaded={this.props.config.hasFullTreeLoaded}
                shouldUpdateCustomDatesState={this.props.shouldUpdateCustomDatesState}
                updateCustomDatesBasedOnNNV={this.props.updateCustomDatesBasedOnNNV}
            />
        );
    }
}

export default (PostFilter: React.AbstractComponent<Props>);
