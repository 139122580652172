import React from 'react';

function ModalWarning(props) {
    if (!props.isVisible) {
        return <span></span>;
    }

    return (
        <div className="popup-modal__warning">
            {props.message ? (
                <div className="modal-warning">
                    <span dangerouslySetInnerHTML={{ __html: props.message }} />
                </div>
            ) : null}
        </div>
    );
}

export default ModalWarning;
