import request from 'superagent-bluebird-promise';
import { hashHistory } from 'react-router';

import utils from './utilities';
import errorUtils from './errors/error-utils';
import reduxStore from '@reduxStore';

import errorMessagesActions from '@reusable/MessagePopup/redux/MessagePopup.actions';
import DilQueue, { PROMISE_CANCELED } from './promiseQueue';
import Promise from 'bluebird';

let xhr = {
    /**
     * An XHR error handler to call in case uniform treatment of all errors is needed.
     *
     * @param   {Object} error   The XHR error object.
     * @return  nothing
     */

    handleAllErrors: function (error) {
        // Route in case of errors
        switch (error.status) {
            case 400:
                errorMessagesActions.setMessages({
                    messages: [
                        {
                            titleId: 'General_CoreFunctionality_Error_error.400Title',
                            messageType: 'system-error',
                            messageId: 'General_CoreFunctionality_Error_error.400Title',
                        },
                    ],
                });
                break;

            case 401:
                reduxStore.dispatch({
                    type: 'USER_INFO_FAIL',
                    payload: error.response.body,
                });
                reduxStore.dispatch({
                    type: 'USER_AUTHENTICATION_TYPE',
                    payload: { authenticationInformation: error.response.body },
                });
                utils.clearUserSession();

                console.warn(reduxStore.getState().user.appSettings.authenticationInformation.wamLogin);
                console.log(reduxStore.getState().user);

                let auth = reduxStore.getState().user.appSettings.authenticationInformation;

                console.log('=========== handling first response');
                console.log(window.location.hash);

                localStorage.setItem(
                    'diligence-deep-link',
                    JSON.stringify({
                        mainUrl: window.location.hash,
                    })
                );

                window.location =
                    auth.wamLogin + '?back=' + encodeURIComponent(auth.customerUi) + '&aci=' + auth.wamAciCode;
                // console.log("============== redirection should happen ==========")

                // hashHistory.push('/login');
                return;

            case 403:
                errorMessagesActions.setMessages({
                    messages: [
                        {
                            titleId: 'General_CoreFunctionality_Error_error.403Title',
                            messageType: 'system-error',
                            messageId: 'General_CoreFunctionality_Error_error.403PageHeading',
                        },
                    ],
                });
                break;

            case 404:
                errorMessagesActions.setMessages({
                    messages: [
                        {
                            titleId: 'General_CoreFunctionality_Error_error.404Title',
                            messageType: 'system-error',
                            messageId: 'General_CoreFunctionality_Error_error.404Message',
                        },
                    ],
                });
                break;

            default: {
                errorMessagesActions.setMessages({
                    messages: [
                        {
                            titleId: 'General_CoreFunctionality_Error_error.500Title',
                            messageType: 'system-error',
                            messageId: 'General_CoreFunctionality_Error_error.500Message',
                        },
                    ],
                });
            }
        }

        hashHistory.push('/error');
    },

    processParams: function (params) {
        let mockServer = 'http://localhost:8125';
        let files = [
            'abracadabra_164171_company_0_10',
            'abracadabra_agencyDecision_company_0_10',
            'abracadabra_168607_company_0_10',
            'abracadabra_141009_company_0_10',
            'abracadabra_3652_company_0_10',
            'abracadabra_directors_company_0_10',
            'abracadabra_396179_company_0_10',
            'abracadabra_240891_company_0_10',
            'abracadabra_169331_company_0_10',
            'abracadabra_361471_company_0_10',
            'abracadabra_verditcs_company_0_10',

            'abracadabra_5754_company_0_10',
            'abracadabra_legal_company_0_10',
            'abracadabra_286191_company_0_10',
            'abracadabra_sanctions_company_0_10',
            'abracadabra_297582_company_0_10',
            'abracadabra_286185_company_0_10',
            'abracadabra_10763_company_0_10',
            'abracadabra_6760_company_0_10',
            'abracadabra_314048_company_0_10',
            'abracadabra_375838_company_0_10',
            'abracadabra_375838_company_1_10',
            'abracadabra_375838_company_2_10',
            'abracadabra_388783_company_0_10',
        ];
        if (params.url === '/api/search/documents') {
            let fileName = params.data.searchQuery + '_';
            if (params.data.csid) {
                fileName += params.data.csi + '_';
            }
            if (params.data.category) {
                fileName += params.data.category + '_';
            }
            fileName += params.data.searchQueryType + '_' + params.data.pageNumber + '_' + params.data.pageSize;
            if (files.indexOf(fileName) > -1) {
                params.url = mockServer + '/api/search/documents/' + fileName + '.json';
                return;
            } else {
                console.log('file not found in mock:', fileName);
            }
        }

        if (params.url === '/api/search/newsSources') {
            params.url = mockServer + '/api/search/newsSources.json';
            return;
        }

        if (params.url === '/api/search/articles/count') {
            let qParam = params.data;
            if (qParam.searchQuery === 'abracadabra' && qParam.searchQueryType === 'company') {
                params.url = mockServer + '/api/search/articles/negativeNews.json';
                return;
            }
        }
    },

    /**
     * Make XHR call.
     *
     * @param   {string}  params.method    Can be "POST" or "GET".
     * @param   {string}  params.url       The request URL.
     * @param   {Object}  params.data      The XHR call parameters.
     * @param   {string}  params.headers   The request headers.
     * @param   {Array}   params.files     Files that will be sent along with the request. This array contains objects
     *                                     with the following format: {name: 'fileFieldName', content: new File()}.
     * @param   {number}  params.timeout   The request timeout interval specified in ms. Default: 60 seconds
     * @param   {string}  params.error     Callback which gets executed if the request fails.
     * @param   {string}  params.success   Callback which gets executed if the request succeeds.
     * @return  {Promise}                  The promise which wraps the XHR call.
     */
    call: function (params) {
        //make sure this line will be commented in prod
        // this.processParams(params);

        let queue = DilQueue();
        let promise = queue.add(params);
        return promise
            .then((response) => {
                errorUtils.logXHR({ ...params, ...response });
                return Promise.resolve(response);
            })
            .catch((error) => {
                errorUtils.logXHR({ ...params, error });
                if (error.message !== PROMISE_CANCELED) {
                    errorUtils.logError({ error });
                }
                return Promise.reject(error);
            });
    },

    executeCall: function (params) {
        return new Promise(function (resolve, reject, onCancel) {
            let method = params.method ? params.method.toLowerCase() : 'get';
            let userPermIdUnhashed = reduxStore.getState().user.userPermIdUnhashed;

            // Specify the method and URL
            let req = request[method](params.url);

            // Set the headers if present
            if (utils.isObject(params.headers)) {
                req = req.set(params.headers);
            }

            if (userPermIdUnhashed) {
                req = req.set(
                    'cid',
                    userPermIdUnhashed + '-' + new Date().getTime() + '-' + Math.floor(Math.random() * 1000000)
                );
            }

            // Add the request parameters if present (either as query
            // string in the URL or as POST response body parameters)
            if (params.data) {
                let dataSendMethod = method === 'get' || method === 'head' ? 'query' : 'send';
                req = req[dataSendMethod](params.data);
            }

            // In case the user wants to add query params to a POST, PUT or DELETE
            if (params.queryParams) {
                req = req.query(params.queryParams);
            }

            // Treat multipart file upload requests
            if (utils.isArray(params.files)) {
                for (let i = 0; i < params.files.length; i++) {
                    let file = params.files[i];

                    if (utils.isObject(file) && typeof file.name === 'string' && file.content) {
                        req = req.attach(file.name, file.content);
                    }
                }
            }

            // Add timeout if specified
            if (typeof params.timeout === 'number' && params.timeout >= 1) {
                req = req.timeout(parseInt(params.timeout, 10));
            } else {
                req = req.timeout(90000); // Default timeout is 60 seconds
            }

            if (!utils.isUserSessionValid()) {
                utils.clearUserSession();
            }

            if (typeof onCancel === 'function') {
                onCancel(() => {
                    req.abort();
                });
            }

            // Send the request
            req.end(function (error, response) {
                if (error || !response.ok) {
                    error = error ? error : response;
                    return reject(error);
                } else if (response.ok) {
                    return resolve(response);
                }
            });
        });
    },

    /**
     * Make GET XHR call.
     *
     * @param   {string}  params.url       The request URL.
     * @param   {string}  params.data      The XHR call parameters.
     * @param   {string}  params.headers   The request headers.
     * @param   {number}  params.timeout   The request timeout interval specified in ms. Default: 60 seconds.
     * @return  {Promise}                  The promise which wraps the XHR call.
     */
    get: function (params) {
        params.method = 'get';
        return xhr.call(params);
    },

    /**
     * Make POST XHR call.
     *
     * @param   {string}  params.url           The request URL.
     * @param   {string}  params.data          The XHR call parameters.
     * @param   {string}  params.headers       The request headers.
     * @param   {Object}  params.queryParams   Query params to be sent along with the request.
     * @param   {Array}   params.files         Files that will be sent along with the request. This array contains
     *                                         objects with the format: {name: 'fileFieldName', content: new File()}.
     * @param   {number}  params.timeout       The request timeout interval specified in ms. Default: 60 seconds.
     * @return  {Promise}                      The promise which wraps the XHR call.
     */
    post: function (params) {
        params.method = 'post';
        return xhr.call(params);
    },

    /**
     * Make PUT XHR call.
     *
     * @param   {string}  params.url           The request URL.
     * @param   {string}  params.data          The XHR call parameters.
     * @param   {string}  params.headers       The request headers.
     * @param   {Object}  params.queryParams   Query params to be sent along with the request.
     * @param   {Array}   params.files         Files that will be sent along with the request. This array contains
     *                                         objects with the format: {name: 'fileFieldName', content: new File()}.
     * @param   {number}  params.timeout       The request timeout interval specified in ms. Default: 60 seconds.
     * @return  {Promise}                      The promise which wraps the XHR call.
     */
    put: function (params) {
        params.method = 'put';
        return xhr.call(params);
    },

    /**
     * Make HEAD XHR call.
     *
     * @param   {string}  params.url       The request URL.
     * @param   {string}  params.headers   The request headers.
     * @param   {number}  params.timeout   The request timeout interval specified in ms. Default: 60 seconds.
     * @return  {Promise}                  The promise which wraps the XHR call.
     */
    head: function (params) {
        params.method = 'head';
        return xhr.call(params);
    },

    /**
     * Make DELETE XHR call.
     *
     * @param   {string}  params.url           The request URL.
     * @param   {string}  params.data          The XHR call parameters.
     * @param   {string}  params.headers       The request headers.
     * @param   {Object}  params.queryParams   Query params to be sent along with the request.
     * @param   {number}  params.timeout       The request timeout interval specified in ms. Default: 60 seconds.
     * @return  {Promise}                      The promise which wraps the XHR call.
     */
    delete: function (params) {
        params.method = 'del';
        return xhr.call(params);
    },

    patch: function (params) {
        params.method = 'patch';
        return xhr.call(params);
    },
};

export default xhr;
