import React from 'react';
import xhr from '@utils/xhr';
import utils from '@utils/utilities';
import reduxStore from '@reduxStore';
import Header from '@reusable/Header/Header.index.jsx';
import Footer from '@reusable/Footer/Footer.index.jsx';

export default class UnsubscribePage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            unsubscribed: false,
        };
    }

    componentDidMount() {
        let loaderPage = document.getElementById('diligence-loader-page');
        if (loaderPage) {
            loaderPage.parentNode.removeChild(loaderPage);
        }

        let params = [];
        try {
            params = utils.getAllUrlParams();
            xhr.post({
                url: '/login/unsubscribe',
                data: params,
            })
                .then(() => {
                    this.setState({ unsubscribed: true });
                })
                .catch((e) => {
                    this.errorPage(e);
                });
        } catch (e) {
            this.errorPage(e);
        }
    }

    errorPage(e) {
        xhr.handleAllErrors(e);
    }

    render() {
        return (
            <div className="app-wrapper" id="app-wrapper-id">
                <Header disableMenu />
                <div className="app-content-wrapper">
                    {this.state.unsubscribed ? (
                        <h2 className={'single-message'}>You have unsubscribed</h2>
                    ) : (
                        <div></div>
                    )}
                </div>
                <Footer version={reduxStore.getState().header.version} />
            </div>
        );
    }
}
