import React from 'react';
import { Link } from 'react-router';
import { AddToReportBtnUbo } from '@reusable/Buttons/redux/AddToReportBtn.containers';
import { FormattedMessage, useIntl } from 'react-intl';
import { CATEGORY_NAMES , UBO_DOCUMENT_NAME_REFERENCE } from '@constants';
import ReactTooltip from 'react-tooltip';
import formatRichMessage from '@utils/formatRichMessage';
import costCodeUtils from '@utils/costCodeUtils';

const CustomDocumentContent = (props) => {
    const intl = useIntl();
    const checkArticle = (e, article) => {
        const articleIndex = props.index + 1;
        const { pcsi, id } = article;
        const articleInfo = {
            documentRank: articleIndex,
            pcsi,
            lni: id,
        };
        props.checkArticle(article, e);
        props.setSelectedArticlesInfo([articleInfo], e.target.checked);
    };
    const articleSnippets = props.getArticleSnipetsForReport(props.article);
    const isEsgArticleType = props.articleType === CATEGORY_NAMES.ESG_RATINGS;
    const costCode = costCodeUtils.getCostCode();
    const esgAddress = isEsgArticleType ? props.getEsgLocation(props.article.location) : '';
    let articleLink = `/ubo-document/${props.article.id}/${encodeURIComponent(props.query)}/${
        props.article.isBranch ? 1 : 0
    }/${encodeURIComponent(props.article.title)}`;

    if (isEsgArticleType) {
        articleLink = `/document?query=${encodeURIComponent(props.query)}&category=${
            props.articleType
        }&type=company&id=${props.article.id}&costCode=${costCode}`;
    }

    const { hasData } = props.article;

    const isDisabledUboDocument = !hasData && props.articleType.includes(UBO_DOCUMENT_NAME_REFERENCE);

    return (
        <li key={props.article.id} className="article-row">
            {isDisabledUboDocument && (
                <ReactTooltip
                    type="light"
                    border={true}
                    effect="solid"
                    event="mouseenter"
                    eventOff="mouseleave"
                    place="bottom"
                    className="tooltips ubo-no-data-tooltip"
                    id="ubo-data-available-tooltip"
                />
            )}
            <div className="article-item-content-checkbox-wrapper">
                <div className="custom-checkbox">
                    <input
                        className="checkbox"
                        id={props.article.id}
                        type="checkbox"
                        checked={props.isArticleSelected(props.article)}
                        data-track={'article-checkbox-' + props.index}
                        onChange={(e) => checkArticle(e, props.article)}
                    />
                    <label htmlFor={props.article.id} className="checkbox-label" />
                </div>

                {props.showIndex && (
                    <span className="index-of-article" data-track={'index-of-article-' + props.index}>
                        {props.index + 1 + '.'}
                    </span>
                )}
            </div>

            <div
                data-track="article-item-content-wrapper"
                className={`article-item-content-wrapper ${props.isArticleItemSelected ? 'selected' : ''}`}
            >
                <span className="article-item-content">
                    <div className="article-list-head">
                        {!isDisabledUboDocument ? (
                            <h4 className="article-title">
                                <Link
                                    to={articleLink}
                                    dangerouslySetInnerHTML={{
                                        __html: isEsgArticleType
                                            ? props.article.unhighlightedTitle
                                            : props.article.title,
                                    }}
                                    onClick={props.showSingleArticle}
                                />
                            </h4>
                        ) : (
                            <h4
                                data-for="ubo-data-available-tooltip"
                                data-tip={formatRichMessage({ id: 'UboDocumentView.noDocumentData' }, intl)}
                                className="article-title disabled"
                            >
                                {props.article.title}
                            </h4>
                        )}

                        <AddToReportBtnUbo article={props.article} articleType={props.articleType} />
                    </div>
                    {props.article.source && (
                        <div className="article-information" data-track={'information-for-article-' + props.index}>
                            <div className="article-source">{props.article.source}</div>
                            {!isEsgArticleType && (
                                <div className="article-word-count">
                                    {props.article.wordCount + ' '}
                                    <FormattedMessage id={'ElasticDocumentContent.articleWorldCount'} />
                                </div>
                            )}
                            {isEsgArticleType && <div className="vertical-divider vertical-divider-small" />}
                            <div className="article-date">{props.article.date}</div>
                        </div>
                    )}
                    {isEsgArticleType && esgAddress && (
                        <div className="article-esg-address">
                            <p>{esgAddress}</p>
                        </div>
                    )}
                    {articleSnippets.length > 0 && !isEsgArticleType && (
                        <div className="article-summary-ubo" data-track={'article-summary-ubo-' + props.index}>
                            {articleSnippets}
                        </div>
                    )}
                </span>
            </div>
        </li>
    );
};

export default CustomDocumentContent;
