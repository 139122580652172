import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import categoryUtils from '@utils/categoryUtils';
import TriggerComponent from '@reusable/Popover/TriggerComponent';
import { DropdownRow } from '@reusable/Dropdown/Dropdown';
import Dropdown from '@reusable/Dropdown/Dropdown';
import { RowActionBtn } from '@reusable/Table/components/defaults';
import { AnonymizedWarningRedirectHandler } from '@Alerts/hoc/withAnonymized';

export const AlertName = ({ alert }) => <div>{alert.name}</div>;

export const EditDropdown = ({ params, alert, onEditAlertSettings = null }) => {
    const searchQueryEncoded = encodeURI(alert.searchQuery);

    return (
        <Dropdown {...params}>
            <DropdownRow icon={'la-Edit'} link={`/edit-alert-search/${alert.id}`}>
                <span data-track="edit-alert-search">
                    <FormattedMessage id={'ManageAlerts.editAlert.editSearch'} />
                </span>
            </DropdownRow>
            <DropdownRow
                icon={'la-Options'}
                link={`/edit-alert/${alert.id}?searchQuery=${searchQueryEncoded}`}
                onClick={onEditAlertSettings}
            >
                <span data-track="edit-alert-delivery-options">
                    <FormattedMessage id={'ManageAlerts.editAlert.editAlertDeliveryOptions'} />
                </span>
            </DropdownRow>
        </Dropdown>
    );
};

export const CustomNewsMenuDropdown = (props) => {
    return (
        <Dropdown {...props.params} className="custom-news-menu-dropdown">
            {props.queryInfo.actions.map((action) => (
                <DropdownRow
                    key={`${props.queryInfo.id}-${action.label}`}
                    onClick={() => props.onClick(props.queryInfo, action.type, action.descriptionId)}
                >
                    <FormattedMessage id={`General_CoreFunctionality_UIText_general.${action.label}`} />
                </DropdownRow>
            ))}
        </Dropdown>
    );
};

export const AlertSources = ({ sources }) => {
    function formatSources(sources) {
        let newSources = [];
        sources.forEach((source) => {
            newSources.push(categoryUtils.getCategory(source).name);
        });
        newSources = newSources.join(', ');

        return newSources;
    }

    return formatSources(sources);
};

export const ResumePauseBtn = ({ isActive, onResume, onPause, object }) =>
    isActive ? (
        <RowActionBtn icon={'la-Pause'} onClick={() => onPause(object)}>
            <span className="row-action-btn__text">
                <FormattedMessage id="General_CoreFunctionality_UIText_general.pause" />
            </span>
        </RowActionBtn>
    ) : (
        <RowActionBtn
            icon={'la-Play'}
            onClick={() => onResume(object)}
            disabled={!object.recipientData.emails.length || object.active === null}
        >
            <span className="row-action-btn__text">
                <FormattedMessage id="General_CoreFunctionality_UIText_general.resume" />
            </span>
        </RowActionBtn>
    );

export const MyAlertActions = ({ object, onPause, onResume, onDelete, onEditAlertSettings = null }) => {
    return (
        <Fragment>
            {onPause && onResume && (
                <ResumePauseBtn isActive={object.active} onPause={onPause} onResume={onResume} object={object} />
            )}
            <TriggerComponent
                render={(params) => (
                    <EditDropdown params={params} alert={object} onEditAlertSettings={onEditAlertSettings} />
                )}
            >
                <RowActionBtn icon={'la-Edit'} className={'with-caret alert-edit-btn'}>
                    <span className="row-action-btn__text">
                        <FormattedMessage id="General_CoreFunctionality_UIText_general.edit" />
                    </span>
                    <span className={'btn-caret'} />
                </RowActionBtn>
            </TriggerComponent>
            <RowActionBtn icon={'la-Delete'} onClick={() => onDelete(object)} disabled={object.active === null}>
                <span className="row-action-btn__text">
                    <FormattedMessage id="General_CoreFunctionality_UIText_general.delete" />
                </span>
            </RowActionBtn>
        </Fragment>
    );
};

// Adding onClick handler to override regular behavior,
export const AnonymizedMyAlertActions = (props) => {
    const searchQueryEncoded = encodeURIComponent(props.object.searchQuery);

    return (
        <AnonymizedWarningRedirectHandler
            redirectUrl={`/edit-alert/${props.object.id}?searchQuery=${searchQueryEncoded}`}
            overrideHandler={(anonymizedWarningHandler) => (
                <MyAlertActions {...props} onEditAlertSettings={anonymizedWarningHandler} />
            )}
        />
    );
};

export const PRAlertActions = ({ object, onDelete, onEdit }) => {
    return (
        <Fragment>
            <RowActionBtn icon={'la-Edit'} onClick={() => onEdit(object)} className={'with-caret alert-edit-btn'}>
                <FormattedMessage id={'General_CoreFunctionality_UIText_general.edit'} />
                <span className={'btn-caret'} />
            </RowActionBtn>
            <RowActionBtn icon={'la-Delete'} onClick={() => onDelete(object)} disabled={object.active === null}>
                <FormattedMessage id={'General_CoreFunctionality_UIText_general.delete'} />
            </RowActionBtn>
        </Fragment>
    );
};

export const MyAlertsEmpty = () => <div className={'empty-table-msg'} />;

export const CheckNewResultsBtn = ({ alert, onClick }) => (
    <button className={'publicRecord-alert-check-for-results-button'} onClick={() => onClick(alert)}>
        <span className="la-Restore" />
        <FormattedMessage id={'PublicRecords.view.alert'} />
    </button>
);
