import { last, values, intersection, uniq } from 'lodash';
import reduxStore from './reduxStore';

const userRights = () => reduxStore.getState().user.permissions || [];

export const permissions = {
    admin: { edit: 'ADMIN' },
};

const permissionGroups = {
    ADMIN_PREFERENCES_EDIT: [...values(permissions.admin)],
};

const defaultPermissions = [];

/**
 * define paths protected by permissions. can accept permissions as string(single permission) or object (group of permissions)
 * or array (multiple permissions)
 * @type {{/[path]: string/object/array}}
 */
export const pathPermissions = {
    '/admin-preferences': permissions.admin.edit,
};

/**
 * Returns if user has certain permission(s). If multiple permissions, will return true if at least one is true
 * @param action - string(single permission) or object (group of permissions) or array (multiple permissions)
 * @returns {boolean}
 */
export function userHas(action) {
    const permissions = [...composePermissions(userRights()), ...defaultPermissions];
    if (typeof action === 'string') {
        // individual action
        return permissions.indexOf(action) > -1;
    } else {
        // any action in group
        const permissionList = values(action);
        return intersection(permissionList, permissions).length > 0;
    }
}

export function routeCheckPermission(prevState, nextState, redirect) {
    const path = last(nextState.routes).path;
    if (pathPermissions[path] && !userHas(pathPermissions[path])) {
        redirect({ location: '/start' });
    }
}

function composePermissions(rights) {
    let permissions = [];
    rights.forEach((right) => {
        // only pick up permissions we support
        if (permissionGroups[right]) {
            permissions.push(...permissionGroups[right]);
        }
    });
    return uniq(permissions);
}
