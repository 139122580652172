import React, { Component } from 'react';
import utils from '@utils/utilities';
import moment from 'moment';

export class DateTemplate extends Component {
    getCompatibleCell(uncertainCell) {
        const text = uncertainCell.text;
        return { ...uncertainCell, text };
    }

    update(cell, cellToMerge) {
        return this.getCompatibleCell({ ...cell, text: cellToMerge.text });
    }

    render(cell) {
        if (!cell) return <span>-</span>;
        if (cell?.isLoading) return <div className="skeleton-loading" />;

        const { text } = cell;

        if(cell.columnId == 'dateCreated' || cell.columnId == 'lastUpdated'){
            return <div>{utils.formatDateWithoutHoursAndMinutes(text)}</div>;
        }

        const convertUnixTime = (date) => moment.utc(date).valueOf();

        const datestring = utils.formatDateWithHoursAndMinutes(convertUnixTime(text))

        return <div>{datestring}</div>;
    }
}
