import React, { Component, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

const withInlineSortFilter = (ChecksComponent) =>
    class WithInlineSortFilter extends Component {
        static propTypes = {
            options: PropTypes.array,
            onChange: PropTypes.func,
            inlineFilterSortProperties: PropTypes.object,
        };

        static defaultProps = {
            options: [],
            inlineFilterSortProperties: null,
        };

        handlePostFiltersChangeSort = (event) => {
            let sortOption = event.target.dataset.sort;
            this.props.onChangeSort(sortOption);
        };

        componentDidUpdate(prevProps) {
            if (this.props.inlineFilterSortProperties) {
                let currentOptions = this.props.options;
                let needsUpdate = currentOptions.some((option) => {
                    let originalOption = prevProps.options.find(
                        (prevPropsOption) => prevPropsOption.rawLabel === option.rawLabel
                    );

                    if (originalOption !== undefined) {
                        return option.count !== originalOption.count;
                    }

                    return true;
                });

                if (needsUpdate) this.props.onChangeSort(this.props.sort);
            }
        }

        render() {
            let { inlineFilterSortProperties } = this.props;

            if (inlineFilterSortProperties === undefined) {
                return <ChecksComponent {...this.props} onChange={this.props.onChange} />;
            }

            let { sortOptions } = inlineFilterSortProperties || {};
            let displayInlineFilter = inlineFilterSortProperties && sortOptions && sortOptions.length > 0;
            let selectedOption = this.props.sort;

            let buttons =
                displayInlineFilter &&
                sortOptions.map((option) => (
                    <button
                        className={`sort-option ${selectedOption === option.value && 'selected'}`}
                        data-sort={option.value}
                        key={option.value}
                        onClick={this.handlePostFiltersChangeSort}
                    >
                        <FormattedMessage id={option.label} />
                    </button>
                ));

            return (
                <Fragment>
                    {displayInlineFilter && <div className="inline-sort-filter">{buttons}</div>}
                    <ChecksComponent {...this.props} />
                </Fragment>
            );
        }
    };

export default withInlineSortFilter;
