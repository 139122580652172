// @flow
import React from 'react';
import NegativeNewsSearchTerms from './NegativeNewsSearchTerms';
import { PERSON_CHECK } from '@Main/Main.reducers';
import { FE_LOCK_NAMES, USER_PREFERENCES_SECTIONS } from '@constants';
import SimilarNameMatch from './SimilarNameMatch';
import { FormattedMessage } from 'react-intl';
import AdminLock from '@reusable/AdminLock/AdminLock';
import CustomNewsSearches from './CustomNewsSearches';
import FuzzyNameThreshold from './FuzzyNameThreshold';
import SuggestedNames from './SuggestedNames';

type Props = {
    newFuzzyEnabled: boolean,
    userIsAdmin: boolean,
    userLocks: Object,
    handleLockClick: (lockName: string) => void,
    section: string,
    changeMade: (section: string) => void,
    autosaveHasError: boolean,
    arePreferencesSaving: boolean,
    modifiedSections: Array<string>,
    fuzzyOn: boolean,
    fuzzyThreshold: string,
    handleFuzzyToggle: (event: SyntheticInputEvent<HTMLInputElement>) => void,
    handleSuggestedNamesToggle: (event: SyntheticInputEvent<HTMLInputElement>) => void,
    handleSelectFuzzyThreshold: (event: SyntheticInputEvent<HTMLInputElement>) => void,
    updateNegativityLevel: (event: SyntheticInputEvent<HTMLInputElement>) => void,
    updateNewsSourcesSelection: (type: string, action: string) => void,
    handleNegativeNewsLanguageSelection: (type: string, language: string, iChecked: boolean) => void,
    customNewsQuery: string,
    lnCustomNewsQueries: string,
    adminCustomNewsQuery: string,
    isFuzzyEnabled: boolean,
    fuzzyMatch: string,
    handleFuzzyEnabled: (event: SyntheticInputEvent<HTMLInputElement>) => void,
    handleSelectFuzzyMatch: (event: SyntheticInputEvent<HTMLInputElement>) => void,
    checkCustomNewsQuery: (segment: string, queryName: string, checked: boolean) => void,
    checkLnCustomNewsQuery: (segment: string, queryName: string, checked: boolean) => void,
    checkAdminCustomNewsQuery: (segment: string, queryName: string, checked: boolean) => void,
    checkCustomNewsSourceAvailability: (type: string, languages: Array<string>) => void,
    updateCheckedLanguagesUponKey: () => void,
    settings: Object,
    negativityLevels: Array<string>,
    suggestedNames: boolean,
    languages: Array<Language>,
    suggestedNamesEnabled: boolean,
    setSuccessBackgroundMessages: (message: string) => void,
    clearBackgroundMessages: () => void,
    setPopupModel: (params: Object) => void,
    customNewsSearchHelpUrl: string,
};

type Language = {
    language: string,
    checked: boolean,
    lowDefaultQueryString: string,
    lowQueryString: string,
    mediumDefaultQueryString: string,
    mediumQueryString: string,
    highDefaultQueryString: string,
    highQueryString: string,
};

const PersonCheck = (props: Props) => {
    return (
        <div
            className={
                props.userIsAdmin
                    ? 'section-enabled'
                    : props.userLocks.masterLockNewsSearchesPerson
                    ? 'section-disabled section-master-lock'
                    : 'section-enabled'
            }
        >
            <div className="user-preferences-category-container person-check-container">
                {props.userIsAdmin ? (
                    <AdminLock
                        lockName={FE_LOCK_NAMES.masterLockNewsSearchesPerson}
                        onClickLock={props.handleLockClick}
                        isSectionLock={true}
                        isLocked={props.userLocks.masterLockNewsSearchesPerson}
                    />
                ) : (
                    props.userLocks.masterLockNewsSearchesPerson && (
                        <AdminLock lockName={FE_LOCK_NAMES.masterLockNewsSearchesPerson} isDisabled={true} />
                    )
                )}
                <h2
                    className="person-check-container__title"
                    data-track="user-preferences-person-check-container__title"
                >
                    <FormattedMessage id="UserPreferences.leftSideNavigation.personCheck" />
                </h2>
                <NegativeNewsSearchTerms
                    segment={PERSON_CHECK}
                    categories={props.settings.categories}
                    userLocks={props.userLocks}
                    updateNegativityLevel={props.updateNegativityLevel}
                    negativityLevel={props.settings.negativityLevel}
                    negativityLevels={props.negativityLevels}
                    languages={props.languages}
                    defaultSources={props.settings.newsSearchSources}
                    updateNewsSourcesSelection={props.updateNewsSourcesSelection}
                    handleNegativeNewsLanguageSelection={props.handleNegativeNewsLanguageSelection}
                    updateCheckedLanguagesUponKey={props.updateCheckedLanguagesUponKey}
                    userIsAdmin={props.userIsAdmin}
                    handleLockClick={props.handleLockClick}
                    masterLock={props.userLocks.masterLockNewsSearchesPerson}
                    isNegativeSearchSourcesReadonly={props.userLocks.newsSearchSourcesPerson}
                    negativeSearchSourcesLockName={FE_LOCK_NAMES.newsSearchSourcesPerson}
                    negativeSearchLanguageLockName={FE_LOCK_NAMES.negativeSearchLanguagePerson}
                    isNegativeSearchLanguageReadonly={props.userLocks.negativeSearchLanguagePerson}
                    isNegativeSearchLevelReadonly={props.userLocks.negativeSearchNegativityLevelsPerson}
                    negativeSearchLevelLockName={FE_LOCK_NAMES.negativeSearchNegativityLevelsPerson}
                    // auto-save props
                    modifiedSections={props.modifiedSections}
                    arePreferencesSaving={props.arePreferencesSaving}
                    autosaveHasError={props.autosaveHasError}
                    changeMade={props.changeMade}
                />
                <div className="sub-section-separator__no-margins" />
                <div
                    className={
                        props.userIsAdmin
                            ? 'section-enabled'
                            : props.userLocks.customNewsQueryPersonAll || props.userLocks.masterLockNewsSearchesPerson
                            ? 'section-disabled'
                            : 'section-enabled'
                    }
                >
                    <CustomNewsSearches
                        customNewsQuery={props.customNewsQuery}
                        lnCustomNewsQueries={props.lnCustomNewsQueries}
                        checkCustomNewsQuery={props.checkCustomNewsQuery}
                        checkLnCustomNewsQuery={props.checkLnCustomNewsQuery}
                        segment={PERSON_CHECK}
                        checkCustomNewsSourceAvailability={props.checkCustomNewsSourceAvailability}
                        adminCustomNewsQuery={props.adminCustomNewsQuery}
                        checkAdminCustomNewsQuery={props.checkAdminCustomNewsQuery}
                        setSuccessBackgroundMessages={props.setSuccessBackgroundMessages}
                        clearBackgroundMessages={props.clearBackgroundMessages}
                        setPopupModel={props.setPopupModel}
                        customNewsSearchHelpUrl={props.customNewsSearchHelpUrl}
                        //admin props
                        isReadonly={
                            (props.userIsAdmin && props.userLocks.customNewsQueryPersonAll) ||
                            (!props.userIsAdmin &&
                                (props.userLocks.customNewsQueryPersonAll ||
                                    props.userLocks.masterLockNewsSearchesPerson))
                        }
                        userIsAdmin={props.userIsAdmin}
                        handleLockClick={props.handleLockClick}
                        userLocks={props.userLocks}
                        lockName={FE_LOCK_NAMES.customNewsQueryPersonAll}
                        //auto-save props
                        modifiedSections={props.modifiedSections}
                        arePreferencesSaving={props.arePreferencesSaving}
                        autosaveHasError={props.autosaveHasError}
                        changeMade={props.changeMade}
                        section={USER_PREFERENCES_SECTIONS[PERSON_CHECK].CUSTOM_NEWS}
                    />
                </div>
                <div className="sub-section-separator__no-margins" />
                {props.newFuzzyEnabled ? (
                    <div
                        className={
                            props.userIsAdmin
                                ? 'section-enabled'
                                : props.userLocks.fuzzyThresholdPerson || props.userLocks.masterLockNewsSearchesPerson
                                ? 'section-disabled'
                                : 'section-enabled'
                        }
                    >
                        <FuzzyNameThreshold
                            handleSelectFuzzyThreshold={props.handleSelectFuzzyThreshold}
                            handleFuzzyToggle={props.handleFuzzyToggle}
                            fuzzyThreshold={props.fuzzyThreshold}
                            fuzzyOn={props.fuzzyOn}
                            //admin props
                            isReadonly={
                                (props.userIsAdmin && props.userLocks.fuzzyThresholdPerson) ||
                                (!props.userIsAdmin &&
                                    (props.userLocks.fuzzyThresholdPerson ||
                                        props.userLocks.masterLockNewsSearchesPerson))
                            }
                            userIsAdmin={props.userIsAdmin}
                            handleLockClick={props.handleLockClick}
                            //auto-save props
                            modifiedSections={props.modifiedSections}
                            arePreferencesSaving={props.arePreferencesSaving}
                            autosaveHasError={props.autosaveHasError}
                            changeMade={props.changeMade}
                            section={USER_PREFERENCES_SECTIONS[PERSON_CHECK].SIMILAR_NAMES}
                        />
                    </div>
                ) : (
                    <div
                        className={
                            props.userIsAdmin
                                ? 'section-enabled'
                                : props.userLocks.similarNameMatchPerson || props.userLocks.masterLockNewsSearchesPerson
                                ? 'section-disabled'
                                : 'section-enabled'
                        }
                    >
                        <SimilarNameMatch
                            handleFuzzyEnabled={props.handleFuzzyEnabled}
                            handleSelectFuzzyMatch={props.handleSelectFuzzyMatch}
                            fuzzyMatch={props.fuzzyMatch}
                            isFuzzyEnabled={props.isFuzzyEnabled}
                            //admin props
                            isReadonly={
                                (props.userIsAdmin && props.userLocks.similarNameMatchPerson) ||
                                (!props.userIsAdmin &&
                                    (props.userLocks.similarNameMatchPerson ||
                                        props.userLocks.masterLockNewsSearchesPerson))
                            }
                            userIsAdmin={props.userIsAdmin}
                            handleLockClick={props.handleLockClick}
                            //auto-save props
                            modifiedSections={props.modifiedSections}
                            arePreferencesSaving={props.arePreferencesSaving}
                            autosaveHasError={props.autosaveHasError}
                            changeMade={props.changeMade}
                            section={USER_PREFERENCES_SECTIONS[PERSON_CHECK].SIMILAR_NAMES}
                        />
                    </div>
                )}
                <div className="sub-section-separator__no-margins" />
                {props.suggestedNamesEnabled ? (
                    <div
                        className={
                            props.userIsAdmin
                                ? 'section-enabled'
                                : props.userLocks.suggestedNames || props.userLocks.masterLockNewsSearchesPerson
                                ? 'section-disabled'
                                : 'section-enabled'
                        }
                    >
                        <SuggestedNames
                            handleSuggestedNamesToggle={props.handleSuggestedNamesToggle}
                            suggestedNames={props.suggestedNames}
                            suggestedNamesEnabled={props.suggestedNamesEnabled}
                            //admin props
                            isReadonly={
                                (props.userIsAdmin && props.userLocks.suggestedNames) ||
                                (!props.userIsAdmin &&
                                    (props.userLocks.suggestedNames || props.userLocks.masterLockNewsSearchesPerson))
                            }
                            userIsAdmin={props.userIsAdmin}
                            handleLockClick={props.handleLockClick}
                            //auto-save props
                            modifiedSections={props.modifiedSections}
                            arePreferencesSaving={props.arePreferencesSaving}
                            autosaveHasError={props.autosaveHasError}
                            changeMade={props.changeMade}
                            section={USER_PREFERENCES_SECTIONS[PERSON_CHECK].SUGGESTED_NAMES}
                        />
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default (PersonCheck: React$StatelessFunctionalComponent<Props>);
