import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import SourcesInformationIcon from './SourcesInformationIcon';
import formatRichMessage from '@utils/formatRichMessage';

class LegalSourcesDropdown extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedOption: props.legalSource,
            isInfoIconSpinning: false,
        };

        this.handleDropdownChange = this.handleDropdownChange.bind(this);
        this.updateLegalSource = this.updateLegalSource.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.legalSource !== nextProps.legalSource) {
            if (nextProps.legalSource !== this.state.selectedOption) {
                this.setState({ selectedOption: nextProps.legalSource });
            }
        }
    }

    handleDropdownChange(e) {
        this.setState({ selectedOption: e.target.value, isInfoIconSpinning: true }, () => {
            this.updateLegalSource(this.state.selectedOption);
            setTimeout(() => {
                this.setState(() => ({
                    isInfoIconSpinning: false,
                }));
            }, 1500);
        });
    }

    updateLegalSource(legalSource) {
        this.props.onChange(legalSource);
    }

    render() {
        return (
            <div className="legal-sources-options ">
                {this.props.children}
                <div className="legal-sources-options dropdown">
                    <select
                        onChange={this.handleDropdownChange}
                        disabled={this.props.disabledDropdown}
                        value={this.state.selectedOption}
                        data-testid="legal-sources-dropdown"
                    >
                        {this.props.legalSources.map((legalSource, index) => (
                            <option key={index} value={legalSource}>
                                {formatRichMessage(
                                    { id: `General.categoryName.label.${legalSource}` },
                                    this.props.intl
                                )}
                            </option>
                        ))}
                    </select>
                    {this.props.hasInformationIcons && (
                        <SourcesInformationIcon
                            sourceKey={this.state.selectedOption}
                            isIconSpinning={this.state.isInfoIconSpinning}
                            sourcesInformation={this.props.sourcesInformation}
                        />
                    )}
                    {this.props.adminLock}
                </div>
            </div>
        );
    }
}

export default injectIntl(LegalSourcesDropdown);
