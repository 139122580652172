export const sendMetrics = (metricData) => {
    const url = `/api/customMetrics/registerTimeMetricsForActions`;

    return {
        url,
        method: 'post',
        data: metricData,
    };
};

export const sendMetric = (metricData) => {
    const url = `/api/customMetrics/registerTimeMetricsForAction`;

    return {
        url,
        method: 'post',
        data: metricData,
    };
};
