import React, { useState, useEffect, useCallback, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import * as DilProps from '@utils/props';
import { connect } from 'react-redux';
import utils from '@utils/utilities';
import { DefaultCheckbox } from '@reusable/Table/components/defaults';
import { useSelector } from 'react-redux';

export const DEFAULT_MESSAGES = {
    clearAllSelectedItems: 'SelectedDocumentDropdown.clearAllSelectedItems',
    noSelectedItems: 'SelectedDocumentDropdown.noSelectedItems',
    selectedItems: 'SelectedDocumentDropdown.selectedItems',
    selectedItemsButton: 'SelectedDocumentDropdown.selectedItemsButton',
    myItems: 'ManageAlerts.alertTab.myAlerts',
};

const SelectedItemsDropdown = ({
    selectedItems,
    onRemoveItem,
    onClearAll,
    onSelectAll,
    language,
    noPopup = false,
    messages = DEFAULT_MESSAGES,
    countNoForAllSelected = null,
    shouldDropdownCheckboxBeVisible = false,
    isDropdownCheckboxChecked = false,
    isDropdownCheckboxDisabled = false,
}) => {
    const [isDropdownCheckboxVisible, setIsDropdownCheckboxVisible] = useState(false);

    const selectAllPopoverRef = useRef(null);

    const entityViewCount = useSelector((state) => state.entityViewInformation.entitiesCount);
    const countNo = countNoForAllSelected ? countNoForAllSelected : selectedItems.length;

    const handleClickOutside = useCallback(
        (event) => {
            const isOutsideSelectAllPopover =
                selectAllPopoverRef.current && !selectAllPopoverRef.current.contains(event.target);

            if (isOutsideSelectAllPopover) {
                setIsDropdownCheckboxVisible(false);
            }
        },
        [selectAllPopoverRef]
    );

    useEffect(() => {
        if (!shouldDropdownCheckboxBeVisible) {
            setIsDropdownCheckboxVisible(false);
        }
    }, [shouldDropdownCheckboxBeVisible]);

    useEffect(() => {
        isDropdownCheckboxVisible && document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [handleClickOutside, isDropdownCheckboxVisible]);

    return (
        <div className={'selected-articles-dropdown-container ' + (selectedItems.length ? 'not-empty' : 'empty')}>
            <div className="selected-articles-count">
                <div
                    className={`la-TriangleDown withoutFlip fromLeft ${
                        !shouldDropdownCheckboxBeVisible ? 'not-visible' : ''
                    }`}
                    onClick={() => {
                        if (shouldDropdownCheckboxBeVisible) {
                            setIsDropdownCheckboxVisible((prev) => !prev);
                        }
                    }}
                />
                <div className="count">
                    <FormattedMessage
                        id={messages.selectedItemsButton}
                        values={{ number: utils.formatNumbersByUserLocale(language, countNo) }}
                    />
                </div>
                <div className={`la-TriangleDown ${noPopup ? 'not-visible' : ''}`} />
            </div>
            {!noPopup && (
                <div className="selected-articles-dropdown">
                    <div className="selected-articles-dropdown-header">
                        <div className="selected-entities">
                            <FormattedMessage id={messages.selectedItems} />
                            <span> </span>
                            <FormattedMessage id={messages.myItems} />
                        </div>
                        <button
                            className="button-secondary-sm"
                            onClick={onClearAll}
                            data-testid={'selection-clear-all'}
                            disabled={selectedItems.length === 0}
                        >
                            <FormattedMessage id={messages.clearAllSelectedItems} />
                        </button>
                    </div>
                    {selectedItems.length === 0 ? (
                        <div className="selected-articles-dropdown-no-entities">
                            <FormattedMessage id={messages.noSelectedItems} />
                        </div>
                    ) : (
                        <div className="selected-articles-dropdown-list">
                            {selectedItems.map((item, index) => {
                                return (
                                    <li key={index} className="selected-articles-dropdown-item">
                                        <span
                                            className="selected-articles-dropdown-item-title"
                                            data-testid={'selection-title-item-' + index}
                                        >
                                            {item.label}
                                        </span>
                                        <button
                                            onClick={() => onRemoveItem(item)}
                                            className="la-CloseRemove"
                                            data-testid={'selection-clear-item-' + index}
                                        />
                                    </li>
                                );
                            })}
                        </div>
                    )}
                </div>
            )}
            {isDropdownCheckboxVisible && (
                <div ref={selectAllPopoverRef} className="select-all-popover" data-testid="select-all-popover">
                    <div className="select-all-popover__input">
                        <DefaultCheckbox
                            onToggleSelect={() => {
                                onSelectAll(isDropdownCheckboxChecked);
                            }}
                            checked={isDropdownCheckboxChecked}
                            id={'selectAll_DB'}
                            value={'selectAll_DB'}
                            disabled={isDropdownCheckboxDisabled} // TODO: CHECK --> is disabled when entities are not loaded?
                        />
                        <FormattedMessage
                            id={'BatchScreening.popup.selectAllIncludeDB'}
                            values={{ count: entityViewCount }}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

SelectedItemsDropdown.propTypes = {
    selectedItems: DilProps.PlainList.isRequired,
};

const mapStateToProps = (state) => ({
    language: state.user.preferences.language,
});

export default connect(mapStateToProps)(SelectedItemsDropdown);
