/**
 * MAIN COMPONENT ENTRY POINT
 *
 * The main component is a wrapper which encapsulates other components. Also,
 * the router inserts the required components here depending on the URL.
 *
 * @version 0.1
 */

import React from 'react';

import './Main.style.scss';

import reduxStore from '@reduxStore';
import Header from '@reusable/Header/Header.index.jsx';
import BackToTop from '@reusable/BackToTop/BackToTop.index.jsx';
import Footer from '@reusable/Footer/Footer.index.jsx';
import MessagePopups from '@reusable/MessagePopup/MessagePopup.index.jsx';
import BackgroundMessage from '@reusable/BackgroundMessage/BackgroundMessage.index';
import Breadcrumbs from '@reusable/Breadcrumbs/Breadcrumbs.index';
import { connect } from 'react-redux';
import breadcrumbsActions from '@reusable/Breadcrumbs/redux/Breadcrumbs.actions';
import { bindActionCreators, compose } from 'redux';
import utils from '@utils/utilities';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import AppContextComponent from '@utils/contexts';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import withPreferenceRefresh from './hoc/withPreferenceRefresh';
import uboActions from '../StartPage/redux/Ubo.actions';
import {
    EDIT_LOCATION_HASH,
    START_LOCATION_HASH,
    USER_ROLE_CHANGED,
    USER_ROLES,
    LAUNCHED_SEARCH_FROM,
} from '@constants';
import { hashHistory } from 'react-router';
import AdminPermissionBanner from '@reusable/AdminPermissionBanner/AdminPermissionBanner';
import { permissions, userHas } from '@permissions';
import withThemeSwitcherContext from '@contexts/hoc/withThemeSwitcher';
import mainActions from './Main.actions';

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = reduxStore.getState();
        this.skipToContentAnchorTag = React.createRef();
        this.appWrapperRef = React.createRef();
    }

    UNSAFE_componentWillMount() {
        this.unsubscribe = reduxStore.subscribe(() => {
            this.setState(reduxStore.getState());
        });
        // rudimentary check of an extra #, in which case we redirect to homepage without the #
        if (window.location.hash.lastIndexOf('#') > 0) {
            hashHistory.push('/start');
        }
    }

    componentDidMount() {
        let loaderPage = document.getElementById('diligence-loader-page');
        if (loaderPage) {
            loaderPage.parentNode.removeChild(loaderPage);
        }

        if (!this.props.isAnonymized) {
            utils.initializeTracking(this.state);
        }
        if (utils.isUboEnabled()) {
            this.props.uboInit();
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.location.pathname !== nextProps.location.pathname) {
            // if users goes to document don't update prevPath but update it if start page
            if (
                nextProps.location.pathname.indexOf('start') === -1 &&
                (this.props.location.pathname.indexOf('document') > -1 ||
                    nextProps.location.pathname.indexOf('document') > -1)
            ) {
                return;
            }
            //set the prev path
            if (this.props.searchState.launchedFrom !== LAUNCHED_SEARCH_FROM.SCREENING_PAGE) {
                this.props.updatePrevPath(this.props.location);
            }
        }
    }

    componentWillUnmount() {
        this.unsubscribe();
    }

    skipToContent = (history) => {
        if (this.appWrapperRef.current) {
            this.appWrapperRef.current.focus();
        }
        history && history.replace({ state: {} });
    };

    render() {
        const { breadcrumbs, isDarkMode, resetLaunchedFrom, updatePrevPath, location, searchResults, searchState } =
            this.props;
        const currentPageHash = window.location.hash;
        const isDarkModeActive =
            isDarkMode && (currentPageHash === START_LOCATION_HASH || currentPageHash === EDIT_LOCATION_HASH);
        const userRoleChanged = localStorage.getItem(USER_ROLE_CHANGED)
            ? JSON.parse(localStorage.getItem(USER_ROLE_CHANGED))
            : null;
        const prevRole = userRoleChanged ? userRoleChanged.prevRole : null;

        const nextRole = { role: userHas(permissions.admin.edit) ? USER_ROLES.ADMINISTRATOR : USER_ROLES.USER };
        // if role changes again while in a session we should display the banner again
        if (prevRole && prevRole !== nextRole.role) {
            localStorage.removeItem(USER_ROLE_CHANGED);
        }

        const showAdminPermissionChangedBanner = localStorage.getItem(USER_ROLE_CHANGED)
            ? JSON.parse(localStorage.getItem(USER_ROLE_CHANGED)).showAdminPermissionChangedBanner
            : this.props.showAdminPermissionChangedBanner;

        return (
            <ErrorBoundary>
                <AppContextComponent>
                    <DndProvider backend={HTML5Backend}>
                        <div className={`app-wrapper ${isDarkModeActive ? 'dark-theme' : ''}`} id="app-wrapper-id">
                            <Header skipToContent={this.skipToContent} />
                            <div className="app-content-wrapper" ref={this.appWrapperRef} tabIndex="0">
                                <MessagePopups
                                    googleTranslateStatus={this.props.googleTranslateStatus}
                                    popupMessages={this.props.popupMessages}
                                />
                                <AdminPermissionBanner
                                    showAdminPermissionChangedBanner={showAdminPermissionChangedBanner}
                                    values={nextRole}
                                />
                                <BackgroundMessage />
                                <Breadcrumbs
                                    breadcrumbs={breadcrumbs}
                                    resetLaunchedFrom={resetLaunchedFrom}
                                    updatePrevPath={updatePrevPath}
                                    location={location}
                                    searchResults={searchResults}
                                    searchState={searchState}
                                />
                                {this.props.children}
                            </div>
                            <BackToTop />
                            <Footer language={this.props.language} isDarkMode={isDarkModeActive} />
                            <div id={'modal-portal'} />
                            <div id={'popover-portal'} />
                            <div id={'slider-portal'} />
                        </div>
                        <iframe id="print-portal" name="print-portal" title="print portal" />
                    </DndProvider>
                </AppContextComponent>
            </ErrorBoundary>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        breadcrumbs: state.breadcrumbs,
        searchState: state.searchState,
        searchResults: state.searchResults,
        language: state.user.preferences.language,
        googleTranslateStatus: state.user.preferences.googleTranslateStatus,
        isAnonymized: state.user.isAnonymized,
        isMIP: state.user.isMIP,
        preferencesRefreshPeriod: state.user.preferencesRefreshPeriod,
        preferencesHash: state.user.preferences.hash,
        userPreferences: state.user.preferences.payload,
        maxNumberOfArticles: state.user.maxNumberOfArticles,
        maxNumberOfReports: state.user.maxNumberOfReports,
        showAdminPermissionChangedBanner: state.user.adminPermissionChanged,
        lastUserPreferencesRetrieval: state.user.preferences.lastUserPreferencesRetrieval,
        lastPreferencesUpdateInfo: state.user.lastPreferencesUpdateInfo,
        userPermId: state.user.userPermIdUnhashed,
        user: state.user,
        popupMessages: state.popupMessages,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            resetLaunchedFrom: mainActions.resetLaunchFrom,
            updatePrevPath: breadcrumbsActions.updatePrevPath,
            uboInit: uboActions.init,
        },
        dispatch
    );
};

export { Main as TestMain };
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(compose(withPreferenceRefresh)(withThemeSwitcherContext(Main)));
