import React, { Component } from 'react';
import { DefaultLoader } from '@reusable/Table/components/defaults';

export class TableLoaderTemplate extends Component {
    getCompatibleCell(uncertainCell) {
        const text = uncertainCell.text;
        return { ...uncertainCell, text };
    }

    update(cell, cellToMerge) {
        return this.getCompatibleCell({ ...cell, text: cellToMerge.text });
    }

    render() {
        return <DefaultLoader />;
    }
}
