import {all, call, fork, put, race, select, take} from 'redux-saga/effects';
import {difference} from 'lodash';
import uboActions, {UBO_CLEAR} from '@pages/StartPage/redux/Ubo.actions';
import * as selectors from '../selectors/uboSelectors';
import * as listeners from '../listeners/uboListeners';
import * as workers from '../workers/uboWorkers';
import * as helpers from '../helpers/uboHelper';
import {checkNonLatinCharacters, decodeUboUrlParam, getAllTerms, sanitizeTerm} from '../helpers/uboHelper';
import * as mainSelectors from '../selectors/main';
import {CATEGORY_NAMES, PERSON_SEARCH, UBO_MIN_QUERY_LENGTH, UBO_URL_PARAM} from '@constants';
import {RESET_CATEGORIES_RESULTS} from '@pages/MainSearch/redux/SearchResults.actions';
import {RESET_ALL_POSTFILTERS_CONFIG_VALUES} from '@pages/MainSearch/redux/PostFilterConfiguration.actions';
import utils from '@utils/utilities';
import {ACTIONS} from '../constants/metricConstants';
import {ACTION_TYPES} from '../constants/investigationConstants';
import EntityViewApi from "@pages/EntityView/api/EntityViewApi";
import searchUtils from '@pages/MainSearch/SearchUtils';

/**
 * Executes on every instance of ubo_init, checks for URL vars to see if we have something save and runs the submission
 * @param action
 * @return {Generator<<"PUT", PutEffectDescriptor<{payload: {selected: (*|[])}, type: string}>>, void, ?>}
 */
export function* initUbo() {
    const uboParam = utils.getURLParameter(window.location.href, UBO_URL_PARAM);
    if (uboParam) {
        const uboSelected = decodeUboUrlParam(uboParam);
        yield put(uboActions.submit({ selected: uboSelected }));
    } else {
        // in case we don't have uboParam, init the ubo with 0 count after the first "reset" of categories has been done
        yield put(uboActions.submit({ selected: null }));
    }
}

/**
 * Search flow - once triggered by "queryUpdate" flow, this should be cancellable by taking latest call
 * if search fails, reset ubo silently
 * @param action
 */
export function* startSuggestionSearch(action) {
    let { query, cursorPosition, searchTerm } = action.payload;

    // clean out the duns that don't match in the query anymore
    yield call(workers.clearMissingDuns, query);

    // starting the suggestion search, cancel if any UBO_CLEAR is triggered
    try {
        // race the search with the clear effect, if clear is triggered while searching, the race will cancel the search
        yield race({
            search: call(workers.doSuggestionSearch, { searchTerm }),
            onClear: take(UBO_CLEAR),
        });
    } catch (e) {
        console.error(e);
        yield put(uboActions.hasError());
    }

    //suggestion search done, wait for any incoming add actions from our list
    const itemAddAction = yield call(listeners.onItemAdd);

    // we launch the select company flow
    yield call(workers.selectCompany, itemAddAction.payload.item, query, cursorPosition);

    // clear the suggestions after selection is made
    yield put(uboActions.clearSuggestions());
}

/**
 * Reset flow - for now just calls the UBO_RESET action to clear the store
 */
export function* resetUbo() {
    yield put(uboActions.clear());
}

export function* submit(action) {
    const isUboEnabled = yield select(selectors.isUboEnabled);
    const searchType = yield select(mainSelectors.theSearchType);
    if (!isUboEnabled || searchType === PERSON_SEARCH) {
        return;
    }

    // wait for RESET actions dispatched by the search to finish -- we dont want our data to be overwritten by these
    yield take(RESET_CATEGORIES_RESULTS);
    yield take(RESET_ALL_POSTFILTERS_CONFIG_VALUES);

    // pick up the duns list and their titles
    const dunsList = yield select(selectors.theUboSelected);
    const selectedCompaniesTitles = yield select(selectors.theUboSelectedTitles);

    // pick up the terms from the search query
    const query = yield select(mainSelectors.theSearchQuery);
    const terms = getAllTerms(query)
        .map(sanitizeTerm)
        .filter((term) => term.length); // filtering out empty terms;

    // check for non Latin term
    const hasNonLatinTerms = terms
        .map((term) => !checkNonLatinCharacters(term))
        .reduce((acc, curr) => acc || curr, false);

    if (hasNonLatinTerms) {
        utils.showNotificationsMessage({
            messageText: 'UboDocumentView.Warning.nonLatin',
            messageType: 'system-error',
        });
        yield fork(workers.updateMainCategory, { hasError: true });
    }

    //set the ubo postfilters
    const postFilters = (action.payload && action.payload.postFilters) || {};
    yield call(workers.setPostFilters, helpers.generateUboPostFilters({ postFilters, dunsList, terms }));
    yield call(workers.syncPostFilterConfig);

    // eliminate the terms that are actually company titles
    const searchTerms = difference(terms, selectedCompaniesTitles)
        .map(sanitizeTerm)
        .filter((term) => term.length >= UBO_MIN_QUERY_LENGTH);

    // dispatch the search with the duns and the terms
    yield put(uboActions.searchCount({ dunsList, searchTerms }));
}

// >>> triggered by uboActions.searchCount, UBO_SEARCH_COUNT
export function* count(action) {
    const { dunsList = [], searchTerms = [] } = action.payload;
    const isUboCategoryChecked = yield select(selectors.isUboCategoryChecked);

    // if UBO category is not checked in the searchResults Redux state, it means it was unchecked in the Adhoc search, so we shouldn't proceed with getting any UBO counts
    if(!isUboCategoryChecked) return;

    const isSnapshotEnabled = yield select(selectors.isSnapshotEnabled);

    // resets subcategories
    workers.clearSubcategories();

    // set main category to loading state
    yield fork(workers.updateMainCategory, { loaded: false });

    // stack the count calls, each request will return 0 if failed, no need for error handling here
    const requestCalls = [...dunsList.map(workers.countDunsSearch), ...searchTerms.map(workers.countTermSearch)];

    // run the count calls
    const counts = yield all(requestCalls);
    const totalCount = counts.reduce((acc, curr) => acc + parseInt(curr, 10), 0);

    // set main category to loaded state and set the count
    yield fork(workers.updateMainCategory, { loaded: true, count: totalCount });
    yield put({ type: ACTION_TYPES.uboCountDone });

    // if entityId is present from state, update screening entity counts
    const entityId = yield select(selectors.entityId);
    const viewId = yield select(selectors.viewId);
    if (entityId) {
        const [, error] = yield call([EntityViewApi, 'updateEntityCategoryCount'], entityId, CATEGORY_NAMES.DNB, totalCount);
        if(error) {
            utils.showNotificationsMessage({
                messageText: 'General_CoreFunctionality_Error_error.500Title',
                messageType: 'system-error',
            });
        }
    }

        // if entityId is present from state, update screening entity postfilters 
        if (entityId) {
            const dataForPayload = {
                user: yield select(selectors.user),
                investigation: yield select(selectors.investigation),
                useNewResearchSummary: yield select(selectors.useNewResearchSummary),
                contentTypes: yield select(selectors.contentTypes),
                searchResults: yield select(selectors.searchResults),
                postFiltersConfig: yield select(selectors.searchResults),
                searchParams: yield select(selectors.searchParams),
                popupModel: yield select(selectors.popupModel),
            }

            const computedPayload = yield call([searchUtils, 'computePayload'], dataForPayload);
            const requestPayload = yield call([searchUtils, 'filterPostfiltersToCategoryFromPayload'], CATEGORY_NAMES.DNB, computedPayload);
            const [, error] = yield call([EntityViewApi, 'updateEntityPostfilters'], entityId, requestPayload.postFilters, viewId);
    
            if(error) {
                utils.showNotificationsMessage({
                    messageText: 'General_CoreFunctionality_Error_error.500Title',
                    messageType: 'system-error',
                });
            }
        }

    // should be send only when snapshot is visible
    if (isSnapshotEnabled) {
        yield put({ type: ACTIONS.metricGather });
    }
}
