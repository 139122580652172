import { PREFERENCES_ACTIVE_TAB } from './UserPreferences.actions';
import { merge } from 'lodash';

function userPreferences(state = {}, action) {
    switch (action.type) {
        case PREFERENCES_ACTIVE_TAB: {
            return merge({}, state, { activeTab: action.payload });
        }
        default:
            return state;
    }
}

export default userPreferences;
