import categoryUtils from '@utils/categoryUtils';
import { resetCategory } from '@reduxStore';
import { CATEGORY_NAMES } from '@constants';

export function buildResetSearchResultsPayload(searchResults, state) {
    return Object.keys(searchResults).reduce((transformedSearchResults, categoryKey) => {
        const { isCategoryAvailable, isCategoryEnabled } = categoryUtils.getCategoryActiveStatus(state, categoryKey);
        const sortOption = state.user.preferences.generalSettings.sort;
        transformedSearchResults[categoryKey] = resetCategory(
            categoryKey,
            isCategoryEnabled,
            isCategoryAvailable,
            sortOption
        );

        return transformedSearchResults;
    }, {});
}

export function mapParentCatPostFiltersForV4Payload(postFilters) {
    let parentCategoriesPostFilters = {
        negativeNews: [],
        customNews: [],
        lawSources: [],
    };

    postFilters &&
        postFilters.length > 0 &&
        postFilters.forEach((categoryPostFilter) => {
            const { category: categoryName = '' } = categoryPostFilter;

            switch (categoryName) {
                case CATEGORY_NAMES.NEGATIVE_NEWS:
                    parentCategoriesPostFilters.negativeNews.push(categoryPostFilter);
                    break;
                case CATEGORY_NAMES.CUSTOM_NEWS:
                    parentCategoriesPostFilters.customNews.push(categoryPostFilter);
                    break;
                case CATEGORY_NAMES.CASES:
                case CATEGORY_NAMES.STATE_DOCKETS:
                case CATEGORY_NAMES.FEDERAL_DOCKETS:
                case CATEGORY_NAMES.AGENCY_DECISION:
                case CATEGORY_NAMES.VERDICTS:
                case CATEGORY_NAMES.LAW_REVIEWS:
                    parentCategoriesPostFilters.lawSources.push(categoryPostFilter);
                    break;
                default:
                    break;
            }
        });

    return parentCategoriesPostFilters;
}
