import React from 'react';

const TextCell = ({ rKey, text, handleTextTruncation }) => {
    return (
        <div
            key={rKey}
            data-testid={`user-preferences-category-container__authorities__simple-table__text-cell-${text}`}
            className="user-preferences-category-container__authorities__simple-table__text-cell"
        >
            <p
                data-tip={text}
                data-for="authority-tooltip"
                onMouseOver={(e) => handleTextTruncation(e)}
                onMouseOut={(e) => handleTextTruncation(e)}
            >
                {text}
            </p>
        </div>
    );
};

export default TextCell;
