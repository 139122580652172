import xhr from '@utils/xhr';

const AlertApi = {
    saveAlert(alert) {
        return xhr.post({
            url: '/api/alerts/alert',
            data: alert,
        });
    },
    editAlert(alert) {
        return xhr.put({
            url: '/api/alerts/alert',
            data: alert,
        });
    },
    getAlert(alertId) {
        return xhr
            .get({
                url: `/api/alerts/alert/${alertId}`,
            })
            .then((response) => response.body)
            .catch((error) => [undefined, error]);
    },
    getRecentEmails() {
        return xhr
            .get({
                url: '/api/alerts/recent-emails',
            })
            .then((response) => [response.body, undefined])
            .catch((error) => [undefined, error]);
    },

    getActionsInProgress: () => {
        return xhr
            .get({
                url: '/api/alerts/actions-in-progress',
            })
            .then((response) => [response.body, undefined])
            .catch((error) => [undefined, error]);
    },
    
    getBatchAlertsStatus: (batchId) =>
        xhr
            .get({
                url: `/api/alerts/action-status/${batchId}`,
            })
            .then((response) => [response.body, null])
            .catch((error) => [null, error]),
};

export default AlertApi;
