import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { NEGATIVE_NEWS_LANGUAGES } from '@constants';

const EditSubcategoriesDropdown = ({ source, onSave }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [sources, setSources] = useState([]);

    useEffect(() => {
        if (!source?.children) return;

        const subcategories = source.children;
        const defaultSources = Object.keys(subcategories).map((key) => ({
            key: subcategories[key].key,
            name: subcategories[key].name,
            checked: subcategories[key].checked,
        }));

        setSources(defaultSources);
    }, [source]);

    const handleChildSelection = (name, isChecked) => {
        const updatedSubcategories = sources.map((child) =>
            child.name === name && child.name !== NEGATIVE_NEWS_LANGUAGES.multiple.value
                ? { ...child, checked: isChecked }
                : child
        );

        onSave?.(source.key, updatedSubcategories);
    };

    return (
        <div className="edit-subcategories-dropdown">
            {isOpen ? (
                <div className="selected-sources-dropdown-content">
                    {sources.map((child, index) => (
                        <div
                            key={child.name + '_' + index}
                            className="edit-subcategories-list-item"
                            id={child.key}
                            onClick={() => handleChildSelection(child.name, !child.checked)}
                        >
                            <div className={'user-preference-checkbox'}>
                                <input
                                    id={'edit-subcategories-item' + child.key + '_' + index}
                                    type="checkbox"
                                    value={child.key}
                                    name={child.name}
                                    checked={child.checked}
                                    onChange={(e) => handleChildSelection(e.target.value, e.target.checked)}
                                />
                                <label
                                    htmlFor={'edit-subcategories-item' + child.key + '_' + index}
                                    className={'preference-checkbox-label'}
                                >
                                    <FormattedMessage id={'General.categoryName.label.' + child.key} />
                                </label>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <div className="closed" onClick={() => setIsOpen(true)}>
                    {sources.length && sources.some(({ checked }) => checked) ? (
                        <FormattedMessage
                            id={'General.categoryName.label.' + sources.filter(({ checked }) => checked)[0]['key']}
                        />
                    ) : (
                        <FormattedMessage id="Alerts.createAlert.contentSources.selectSource" />
                    )}
                </div>
            )}
        </div>
    );
};

export default EditSubcategoriesDropdown;
