import React from 'react';
import { FormattedMessage } from 'react-intl';
import AlertsSourcesComponent from '@Alerts/components/AlertsSourcesComponent';

const AlertContent = (props) => {
    return (
        <div className="create-alert-content-container">
            <div className="create-alert-content-container_body">
                <h4>
                    <FormattedMessage id="Alerts.createAlert.alertContent" />
                </h4>
                <span className="create-alert-content-container_body_note">
                    <FormattedMessage id="Alerts.createAlert.alertContentExplanation" />
                </span>
            </div>
            <AlertsSourcesComponent {...props} />
        </div>
    );
};

export default AlertContent;
