import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { withToggleViewForExpandedReport } from '@ReportBuilder/HOC/withReportHoc';

const ConnectedReports = ({ parentReports, viewParentReport }) => {
    if (parentReports && parentReports.length) {
        parentReports = parentReports.filter((report) => !report.isDeleted && !report.isExpired);
    }

    return parentReports && parentReports.length ? (
        <Fragment>
            <div className="regular-report-connected-reports">
                <span>
                    <FormattedMessage id="Report.connectedParentReports" />
                </span>
                {parentReports.map((parent, index) => (
                    <span
                        onClick={() => viewParentReport(parent.reportId)}
                        key={`parent-report-${index}`}
                        className={'parent-link'}
                    >
                        {parent.title}
                    </span>
                ))}
            </div>
            <div className="horizontal-divider" />
        </Fragment>
    ) : null;
};

export { ConnectedReports as TestConnectedReports };
export default withToggleViewForExpandedReport(ConnectedReports);
