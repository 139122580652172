import { UPDATE_BREADCRUMBS, UPDATE_PREV_PATH } from './Breadcrumbs.actions';
import { merge } from 'lodash';

function breadcrumbs(state = {}, action) {
    switch (action.type) {
        case UPDATE_BREADCRUMBS:
            let prev = JSON.stringify(state.items),
                next = JSON.stringify(action.payload);
            if (prev !== next) {
                return Object.assign({}, state, { items: JSON.parse(next) });
            } else {
                return state;
            }
        case UPDATE_PREV_PATH:
            if (!action.payload || !action.payload.pathname || !action.payload.query) {
                return state;
            }

            let prevPath = action.payload.pathname;
            let query = action.payload.query;
            return merge({}, { items: [], prevPath: prevPath, query: query });

        default:
            return state;
    }
}
export default breadcrumbs;
