import EntityViewApi from './api/EntityViewApi';
import utils from '@utils/utilities';
import mainActions from '@pages/Main/Main.actions';

const handleReturnAllCountsError = () => {
    utils.showNotificationsMessage({
        messageText: 'BatchScreening.generalError.message',
        messageType: 'system-error',
    });
    return 0;
};

const handleReturnNotificationsError = () => {
    utils.showNotificationsMessage({
        messageText: 'BatchScreening.generalError.message',
        messageType: 'system-error',
    });
};

//This will update the redux value for the total number of entities within context of search or view (passed in as urlParams)
export const updateAllEntitiesInContextCount = (entityViewErrorHandler, urlParams = {}) => {
    return async (dispatch) => {
        const [totalNum, error] = await EntityViewApi.getNumberOfBatchEntities(urlParams);
        if (error) return entityViewErrorHandler(error, urlParams);
        dispatch(mainActions.setEntityViewCount(totalNum));
    };
};

//This will return the value for the current total number of entities, regardless of search or view params
export const returnAllEntitiesCount = async () => {
    const [totalNum, error] = await EntityViewApi.getNumberOfBatchEntities({});
    if (error) return handleReturnAllCountsError();
    return totalNum;
};

export const getUserNotifications = async () => {
    const [notifications, error] = await EntityViewApi.getUserNotifications();
    if (error) return handleReturnNotificationsError();
    return notifications;
}

export const patchUserNotifications = async (notificationIds) => {
    const [response, error] = await EntityViewApi.patchUserNotifications(notificationIds);
    if (error) return handleReturnNotificationsError();
    return response;
}

export const showNotifications = (notifications) => {
    const numberOfNotifications = notifications.length;

    switch (numberOfNotifications) {
        case 1:
            utils.showNotificationsMessage({
                messageText: `BatchScreening.shareview.notifications.message.${notifications[0].viewNotificationActionType.toLowerCase()}`,
                messageType: 'success',
                terms: { username: notifications[0].userNameDoingAction }
            });
            break
        case 0:
            break;
        default:
            const notificationMessages = notifications.map((notification) => {
                return {
                    messageId: `BatchScreening.shareview.notifications.message.${notification.viewNotificationActionType.toLowerCase()}`,
                    messageType: 'success',
                    values: { username: notification.userNameDoingAction }
                }
            });
            utils.showMultipleNotificationsMessages(notificationMessages, 10000);
            break;
    }
}
