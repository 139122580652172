import React, { Component, Fragment } from 'react';
import CustomCostCodeInputBox from './CustomCostCodeInputBox';
import { FormattedMessage, injectIntl } from 'react-intl';
import { COST_CODE, COST_CODE_KEY, EMPTY } from '@constants';
import formatRichMessage from '@utils/formatRichMessage';
import utils from '@utils/utilities';
import { cloneDeep, isEqual, omit } from 'lodash';
import withAutosave from '../HOC/WithAutosave';

class UserCostCodeDropdown extends Component {
    constructor(props) {
        super(props);

        this.state = {
            costCodesList: [],
            customCostCodeSelected: false,
        };
        this.updateCostCode = this.updateCostCode.bind(this);
        this.updateCostCodeDropdown = this.updateCostCodeDropdown.bind(this);
        this.handleChangeCostCode = this.handleChangeCostCode.bind(this);
    }

    UNSAFE_componentWillMount() {
        this.updateCostCodeDropdown(this.props);
    }

    componentDidUpdate(prevProps) {
        if (!isEqual(omit(prevProps, [COST_CODE_KEY, 'children']), omit(this.props, [COST_CODE_KEY, 'children']))) {
            this.updateCostCodeDropdown(this.props);
        }
    }

    updateCostCodeDropdown(props) {
        let tempCostCodes = cloneDeep(utils.removeEmptyValues(props.costCodes)).sort();
        tempCostCodes.unshift(COST_CODE.empty.name);

        if (!props.chooseOnlyFromCostCodesList) {
            tempCostCodes.push(COST_CODE.customCostCode.name);
        }

        this.setState({
            costCodesList: tempCostCodes,
            ...(this.props.isHomepageModal && { customCostCodeSelected: this.props.isCustomCostCode }),
        });
    }

    updateCostCode(event) {
        event.persist();
        const customCostCodeSelected = event.target.value === COST_CODE.customCostCode.name;

        if (this.props.isHomepageModal) {
            this.props.handleAutosave(
                this.props.changeCostCode,
                customCostCodeSelected ? [COST_CODE.empty.value, true] : [event.target.value, false]
            );

            return;
        }

        if (!customCostCodeSelected) {
            this.props.handleAutosave(this.props.changeCostCode, [event.target.value]);
        }

        this.setState({ customCostCodeSelected });
    }

    handleChangeCostCode(text) {
        if (this.state.costCodesList.indexOf(text) > -1) {
            this.setState({
                customCostCodeSelected: false,
            });
        }

        this.props.handleAutosave(this.props.changeCostCode, [text, true]);
    }

    render() {
        const isCostCodeUserDefined =
            this.state.costCodesList.indexOf(this.props.costCode) === -1 &&
            this.props.costCode !== COST_CODE.empty.value;
        const isCostCodeFromAList =
            this.state.costCodesList.indexOf(this.props.costCode) > -1 && this.props.costCode !== COST_CODE.empty.value;
        const customCostCodeSelected = this.state.customCostCodeSelected;

        const shouldShowCustomInputBox = this.props.isHomepageModal
            ? (isCostCodeUserDefined || (customCostCodeSelected && !isCostCodeFromAList)) &&
              !this.props.chooseOnlyFromCostCodesList
            : (isCostCodeUserDefined || customCostCodeSelected) && !this.props.chooseOnlyFromCostCodesList;

        return (
            <Fragment>
                <div className="user-preferences-category-container__row">
                    <h3 className="user-preferences-category-container__topic">
                        <FormattedMessage id="UserPreferences_topic_cost_code" />
                    </h3>
                    {this.props.autoSaveMessage}
                </div>
                <div className="cost-code-container">
                    <div className="cost-code-options">
                        {this.props.children}
                        <select
                            onChange={this.updateCostCode}
                            value={
                                isCostCodeUserDefined || (customCostCodeSelected && !isCostCodeFromAList)
                                    ? COST_CODE.customCostCode.value
                                    : this.props.costCode
                            }
                        >
                            {this.state.costCodesList.map((costCode, index) => {
                                return costCode !== COST_CODE.customCostCode.name &&
                                    costCode !== COST_CODE.empty.name ? (
                                    <option key={index} value={costCode}>
                                        {costCode}
                                    </option>
                                ) : (
                                    <option key={index} value={costCode}>
                                        {formatRichMessage({ id: COST_CODE[costCode || EMPTY].label }, this.props.intl)}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                    {shouldShowCustomInputBox && (
                        <CustomCostCodeInputBox
                            changeCostCode={this.handleChangeCostCode}
                            isCostCodeUserDefined={isCostCodeUserDefined}
                            costCode={this.props.costCode}
                            isHomepageModal={this.props.isHomepageModal}
                        />
                    )}
                </div>
            </Fragment>
        );
    }
}

export { UserCostCodeDropdown as TestUserCostCodeDropdown };

export default injectIntl(withAutosave()(UserCostCodeDropdown));
