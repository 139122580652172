import React from 'react';
import { injectIntl } from 'react-intl';
import formatRichMessage from '@utils/formatRichMessage';

const NegativityTermsContainer = ({ negativeTermsHeaders, children, intl, isSnapshot }) => {
    return (
        <div className={`negative-terms-container ${isSnapshot ? 'snapshot' : ''}`}>
            <div className="negative-terms-container-headers">
                {negativeTermsHeaders.map((termsHeader) => (
                    <span key={termsHeader} className="negative-terms-container-headers-label">
                        <p data-track={`negative-terms-container-headers-label-${termsHeader}`}>
                            {formatRichMessage(
                                {
                                    id: `NegativeNewsVisualisations.${
                                        isSnapshot ? 'snapshot.' : ''
                                    }label.${termsHeader}`,
                                },
                                intl
                            )}
                        </p>
                    </span>
                ))}
            </div>
            <div className="negative-terms-container-content">{children}</div>
        </div>
    );
};

export default injectIntl(NegativityTermsContainer);
