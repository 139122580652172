import { call, select } from 'redux-saga/effects';
import { setSearchEnd, setSearchStart, setGatherMetrics, resetMetric } from '../workers/metricWorkers';
import * as selectors from '../selectors/metricSelectors';
import * as mainSelectors from '../selectors/main';
import { areAllCategoriesLoaded, processDataForMetricGather } from '../helpers/metricHelpers';

/**
 * Executes on every instance of METRIC_SEARCH_STARTED,
 * Sets the search start timestamp
 */
export function* startSearch(action) {
    yield call(resetMetric);
    yield call(setSearchStart, { createdDate: action.payload.timestamp });
}

/**
 * Executes on every instance of METRIC_SEARCH_END,
 * Sets the search end of a specific element (timestamp + error)
 */
export function* endSearch(action) {
    yield call(setSearchEnd, action.payload);
}

/**
 * Executes on every instance of METRIC_GATHER,
 * Gathers the search results and checks if all categories are loaded
 * Starts to gather the metrics in order to do the request
 */
export function* gatherMetrics() {
    const searchResults = yield select(selectors.getSearchResults);
    const userPermId = yield select(mainSelectors.getUserPermId);
    const userCustomerClass = yield select(mainSelectors.getUserCustomerClass);
    const areCategoriesLoaded = areAllCategoriesLoaded(searchResults);

    if (areCategoriesLoaded) {
        const searchType = yield select(selectors.getSearchType);
        const start = yield select(selectors.getMetricStart);
        const end = yield select(selectors.getMetricEnd);
        const data = processDataForMetricGather({ searchType, start, end, userPermId, userCustomerClass });

        yield call(setGatherMetrics, data);
    }
}
