import axios from 'axios';
import errorUtils from './errors/error-utils';

// small axios wrapper to add interceptor
axios.interceptors.request.use(
    function (config) {
        return config;
    },
    function (error) {
        errorUtils.logXHR(error.config);
        errorUtils.logError({ error });
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    function (response) {
        errorUtils.logXHR({ ...response.config, status: response.status, body: response.data });
        return response;
    },
    function (error) {
        if (!axios.isCancel(error)) {
            errorUtils.logError({ error });
        }
        return Promise.reject(error);
    }
);

export default axios;
