import {
    CATEGORY_NAMES,
    PERSON_SEARCH,
    POST_FILTERS_DEFAULT_SORT,
    POSTFILTER_COMPONENT_TYPE,
    POSTFILTER_COMPONENTS,
    POSTFILTER_TYPE,
} from '@constants';
import { cloneDeep } from 'lodash';
import utils from '@utils/utilities';
import { determineDateRange } from '@MainSearch/SearchUtils';

export const RESET_ALL_POSTFILTERS_CONFIG_VALUES = 'RESET_ALL_POSTFILTERS_CONFIG_VALUES';
export const UPDATE_POSTFILTER_CONFIG_VALUES = 'UPDATE_POSTFILTER_CONFIG_VALUES';
export const UPDATE_POSTFILTER_CONFIG_VALUES_LIST = 'UPDATE_POSTFILTER_CONFIG_VALUES_LIST';
export const UPDATE_POSTFILTER_CONFIG_TREE_STATE = 'UPDATE_POSTFILTER_CONFIG_TREE_STATE';
export const UPDATE_POSTFILTER_SORT = 'UPDATE_POSTFILTER_SORT';
export const UPDATE_CATEGORY_POSTFILTERS = 'UPDATE_POSTFILTER_CONFIG_VALUES';
export const SET_CATEGORY_POSTFILTERS = 'SET_CATEGORY_POSTFILTERS';

export function resetRadioList(values, postFilterType, preferences, adHocPreferences, categoryName) {
    return values.map((item) => {
        let checked = false;
        switch (postFilterType) {
            case POSTFILTER_TYPE.PROXIMITY_NEGATIVE_TERMS:
                checked = preferences.proximity === item.count;
                break;

            case POSTFILTER_TYPE.FUZZY_SEARCH:
                checked = item.checked;
                break;

            case POSTFILTER_TYPE.DATE_RANGE:
                let isAdHocEmpty =
                    Object.keys(adHocPreferences).length === 0 && adHocPreferences.constructor === Object;
                let newsDateRange =
                    isAdHocEmpty || !adHocPreferences.newsDateRange || !adHocPreferences.newsDateRange.range
                        ? preferences.newsSources.dateRange
                        : adHocPreferences.newsDateRange.range;
                let companyDateRange =
                    isAdHocEmpty || !adHocPreferences.companyDateRange || !adHocPreferences.companyDateRange.range
                        ? preferences.companySources.dateRange
                        : adHocPreferences.companyDateRange.range;
                let legalDateRange =
                    isAdHocEmpty || !adHocPreferences.legalDateRange || !adHocPreferences.legalDateRange.range
                        ? preferences.legalSources.dateRange
                        : adHocPreferences.legalDateRange.range;
                const otherCategoriesDateRange = determineDateRange(adHocPreferences)

                switch (categoryName) {
                    case CATEGORY_NAMES.NEWS:
                    case CATEGORY_NAMES.NEGATIVE_NEWS:
                    case CATEGORY_NAMES.NEGATIVE_NEWS_EN:
                    case CATEGORY_NAMES.NEGATIVE_NEWS_FR:
                    case CATEGORY_NAMES.NEGATIVE_NEWS_NL:
                    case CATEGORY_NAMES.NEGATIVE_NEWS_DE:
                    case CATEGORY_NAMES.NEGATIVE_NEWS_ES:
                    case CATEGORY_NAMES.NEGATIVE_NEWS_TR:
                    case CATEGORY_NAMES.NEGATIVE_NEWS_SV:
                    case CATEGORY_NAMES.NEGATIVE_NEWS_MS:
                    case CATEGORY_NAMES.NEGATIVE_NEWS_PT:
                    case CATEGORY_NAMES.NEGATIVE_NEWS_JA:
                    case CATEGORY_NAMES.NEGATIVE_NEWS_ZH:
                    case CATEGORY_NAMES.NEGATIVE_NEWS_IT:
                    case CATEGORY_NAMES.NEGATIVE_NEWS_AR:
                    case CATEGORY_NAMES.NEGATIVE_NEWS_RU:
                    case CATEGORY_NAMES.NEGATIVE_NEWS_PL:
                    case CATEGORY_NAMES.CUSTOM_NEWS:
                    case CATEGORY_NAMES.CUSTOM_NEWS1:
                    case CATEGORY_NAMES.CUSTOM_NEWS2:
                    case CATEGORY_NAMES.CUSTOM_NEWS3:
                    case CATEGORY_NAMES.LN_CUSTOM_NEWS1:
                    case CATEGORY_NAMES.ADMIN_CUSTOM_NEWS1:
                    case CATEGORY_NAMES.ADMIN_CUSTOM_NEWS2:
                    case CATEGORY_NAMES.ADMIN_CUSTOM_NEWS3:
                        checked = newsDateRange === item.count;
                        break;
                    case CATEGORY_NAMES.BIOGRAPHICAL:
                    case CATEGORY_NAMES.PEPS:
                    case CATEGORY_NAMES.SANCTIONS_WATCHLIST:
                    case CATEGORY_NAMES.FINANCIAL_REPORT:
                        checked = otherCategoriesDateRange === item.count;
                        break;
                    case CATEGORY_NAMES.COMPANY_RESOURCES:
                        checked = companyDateRange === item.count;
                        break;
                    case CATEGORY_NAMES.AGENCY_DECISION:
                    case CATEGORY_NAMES.STATE_DOCKETS:
                    case CATEGORY_NAMES.FEDERAL_DOCKETS:
                    case CATEGORY_NAMES.LAW_REVIEWS:
                    case CATEGORY_NAMES.CASES:
                    case CATEGORY_NAMES.VERDICTS:
                        checked = legalDateRange === item.count;
                        break;
                    default:
                        break;
                }
                break;
            default:
                break;
        }
        return { label: item.label, checked, count: item.count };
    });
}

let postFilterConfigActions = {
    resetAllPostFiltersConfigValues: (
        searchQueryType,
        subjectAndIndustryAvailable,
        newFuzzyEnabled,
        suggestedNamesEnabled
    ) => {
        return {
            type: RESET_ALL_POSTFILTERS_CONFIG_VALUES,
            payload: { searchQueryType, subjectAndIndustryAvailable, newFuzzyEnabled, suggestedNamesEnabled },
        };
    },

    resetPostFilterConfigValues: (categoryName, postFilterType, values) => (dispatch, getState) => {
        const store = getState();
        let componentType = '';
        let resetedValues = values.slice(0);
        const preferences = store.user.preferences.generalSettings;
        const adHocPreferences = store.adHocSearch;
        const { personCheck, companyCheck } = store.user.preferences;
        const searchType = store.searchParams.searchType;

        Object.keys(POSTFILTER_COMPONENTS).forEach((key) => {
            if (POSTFILTER_COMPONENTS[key].type === postFilterType) {
                componentType = POSTFILTER_COMPONENTS[key].componentType;
            }
        });
        switch (componentType) {
            case POSTFILTER_COMPONENT_TYPE.GEOGRAPHY:
            case POSTFILTER_COMPONENT_TYPE.CHECKBOX_LIST:
            case POSTFILTER_COMPONENT_TYPE.NESTED_CHECKBOX_LIST:
                if (postFilterType === POSTFILTER_TYPE.DOCKET_STATUS) {
                    // All items should be checked, no count needed
                    resetedValues = values.map((item) => {
                        return { label: item.label, checked: true, originalLabel: item.originalLabel };
                    });
                } else if (utils.isPostFilterCustomType(postFilterType)) {
                    // All items should be checked, label id is also needed
                    resetedValues = values.map((item) => {
                        return { label: item.label, checked: true, count: item.count, labelId: item.labelId };
                    });
                } else if (postFilterType === POSTFILTER_TYPE.NEGATIVITY_LEVELS) {
                    const { negativityLevel } = searchType === PERSON_SEARCH ? personCheck : companyCheck;

                    resetedValues = values.map((item) => {
                        const isOptionChecked = negativityLevel.indexOf(item.label) > -1;

                        return { label: item.label, checked: isOptionChecked, count: item.count };
                    });
                } else {
                    //all items should be checked
                    resetedValues = values.map((item) => {
                        return { label: item.label, checked: true, count: item.count };
                    });
                }
                break;

            case POSTFILTER_COMPONENT_TYPE.RADIO_LIST:
                resetedValues = resetRadioList(values, postFilterType, preferences);

                break;
            case POSTFILTER_COMPONENT_TYPE.DATE_RADIO_LIST:
                resetedValues = resetRadioList(values, postFilterType, preferences, adHocPreferences, categoryName);
                break;
            default:
                break;
        }

        dispatch({
            type: UPDATE_POSTFILTER_CONFIG_VALUES,
            payload: { categoryName, postFilterType, values: resetedValues, enabled: true },
        });
    },

    resetToDefaultAllPostFiltersConfigValues: (categoryName, categoryPostFilters) => (dispatch, getState) => {
        const store = getState();
        const preferences = store.user.preferences.generalSettings;
        const adHocPreferences = store.adHocSearch;
        const { personCheck, companyCheck } = store.user.preferences;
        const searchType = store.searchParams.searchType;
        let componentType = '';
        let postFilters = {};

        Object.keys(categoryPostFilters).forEach((postFilterType) => {
            Object.keys(POSTFILTER_COMPONENTS).forEach((key) => {
                if (POSTFILTER_COMPONENTS[key].type === postFilterType) {
                    componentType = POSTFILTER_COMPONENTS[key].componentType;
                }
            });
            let values = categoryPostFilters[postFilterType].values.slice(0);
            let resetedValues;
            postFilters[postFilterType] = cloneDeep(categoryPostFilters[postFilterType]);

            switch (componentType) {
                case POSTFILTER_COMPONENT_TYPE.GEOGRAPHY:
                case POSTFILTER_COMPONENT_TYPE.CHECKBOX_LIST:
                case POSTFILTER_COMPONENT_TYPE.NESTED_CHECKBOX_LIST:
                    if (postFilterType === POSTFILTER_TYPE.DOCKET_STATUS) {
                        // All items should be checked, no count needed
                        resetedValues = values.map((item) => {
                            return { label: item.label, checked: true, originalLabel: item.originalLabel };
                        });
                    } else if (utils.isPostFilterCustomType(postFilterType)) {
                        // All items should be checked, label id is also needed
                        resetedValues = values.map((item) => {
                            return { label: item.label, checked: true, count: item.count, labelId: item.labelId };
                        });
                    } else if (postFilterType === POSTFILTER_TYPE.NEGATIVITY_LEVELS) {
                        const { negativityLevel } = searchType === PERSON_SEARCH ? personCheck : companyCheck;

                        resetedValues = values.map((item) => {
                            const isOptionChecked = negativityLevel.indexOf(item.label) > -1;

                            return { label: item.label, checked: isOptionChecked, count: item.count };
                        });
                    } else {
                        //all items should be checked
                        resetedValues = values.map((item) => {
                            return { label: item.label, checked: true, count: item.count };
                        });
                    }
                    break;

                case POSTFILTER_COMPONENT_TYPE.RADIO_LIST:
                    resetedValues = resetRadioList(values, postFilterType, preferences);

                    break;
                case POSTFILTER_COMPONENT_TYPE.DATE_RADIO_LIST:
                    resetedValues = resetRadioList(values, postFilterType, preferences, adHocPreferences, categoryName);
                    break;
                case POSTFILTER_COMPONENT_TYPE.TEXT_FILTER:
                    resetedValues = [''];
                    break;
                default:
                    resetedValues = [];
            }

            if (Object.keys(POST_FILTERS_DEFAULT_SORT).indexOf(postFilterType) > -1) {
                postFilters[postFilterType].sort = POST_FILTERS_DEFAULT_SORT[postFilterType];
            }

            postFilters[postFilterType].values = resetedValues;
            postFilters[postFilterType].enabled = true;
        });

        dispatch({
            type: SET_CATEGORY_POSTFILTERS,
            payload: { category: categoryName, postFilters },
        });
    },

    updatePostFilterConfigValues: (categoryName, postFilterType, values, enabled = true) => {
        return {
            type: UPDATE_POSTFILTER_CONFIG_VALUES,
            payload: { categoryName, postFilterType, values, enabled },
        };
    },

    updatePostFilterConfigValuesList: (payload) => {
        return {
            type: UPDATE_POSTFILTER_CONFIG_VALUES_LIST,
            payload,
        };
    },

    markFullTreeLoaded: (categoryName, searchFieldName) => {
        return {
            type: UPDATE_POSTFILTER_CONFIG_TREE_STATE,
            payload: { categoryName, searchFieldName },
        };
    },

    updatePostFiltersSort: (categoryName, postFilterType, sort, enabled = true) => {
        return {
            type: UPDATE_POSTFILTER_SORT,
            payload: { categoryName, postFilterType, sort, enabled },
        };
    },

    updateCategoryPostfilters: (category, postFilters) => {
        return {
            type: SET_CATEGORY_POSTFILTERS,
            payload: { category, postFilters },
        };
    },
};

export default postFilterConfigActions;
