import React from 'react';
import {
    SELECTED_DROPDOWN_ALERTS_LIST,
    SELECTED_DROPDOWN_HISTORY_LIST,
    SELECTED_DROPDOWN_RESULTS_LIST,
    UBO_MAIN_CATEGORY,
} from '@constants';
import { FormattedMessage } from 'react-intl';
import { find } from 'lodash';
import categoryUtils from '@utils/categoryUtils';
import { withCustomNewsTranslation } from '@utils/categoryUtils';
import utils from '@utils/utilities';
import { connect } from 'react-redux';

class SelectedDocumentsDropdown extends React.Component {
    constructor(props) {
        super(props);

        this.clearAllSelectedItems = this.clearAllSelectedItems.bind(this);
        this.clearItemFromList = this.clearItemFromList.bind(this);
    }

    clearAllSelectedItems() {
        if (this.props.dropdownType === SELECTED_DROPDOWN_RESULTS_LIST) {
            this.props.clearSelectedArticles(this.props.articleType);
            this.props.clearSelectedDuplicates(this.props.articleType);
        } else if (this.props.dropdownType === SELECTED_DROPDOWN_ALERTS_LIST) {
            this.props.clearSelectedAlerts(this.props.selectedAlerts);
        } else if (this.props.dropdownType === SELECTED_DROPDOWN_HISTORY_LIST) {
            this.props.clearSelectedItems(this.props.selectedItems);
        }
    }

    clearItemFromList(item) {
        if (this.props.dropdownType === SELECTED_DROPDOWN_RESULTS_LIST) {
            this.props.setSelectedArticles([item], this.props.articleType, false);
            if (find(this.props.selectedDuplicates[this.props.articleType], item)) {
                this.props.setSelectedDuplicates([item], this.props.articleType, false);
            }
        } else if (this.props.dropdownType === SELECTED_DROPDOWN_ALERTS_LIST) {
            this.props.clearSelectedAlerts([item]);
        } else if (this.props.dropdownType === SELECTED_DROPDOWN_HISTORY_LIST) {
            this.props.clearSelectedItems([item]);
        }
    }

    render() {
        let selectedItems = [];
        let categoryKey;
        if (this.props.dropdownType === SELECTED_DROPDOWN_RESULTS_LIST) {
            categoryKey = categoryUtils.getCategoryKeyForTranslation(this.props.articleType);
            selectedItems = this.props.selectedArticles[this.props.articleType] || [];
        } else if (this.props.dropdownType === SELECTED_DROPDOWN_ALERTS_LIST) {
            selectedItems = this.props.selectedAlerts || [];
        } else if (this.props.dropdownType === SELECTED_DROPDOWN_HISTORY_LIST) {
            selectedItems = this.props.selectedItems || [];
        }

        if (categoryUtils.isDnbCategory(categoryKey)) {
            categoryKey = UBO_MAIN_CATEGORY;
        }

        return (
            <div
                className={
                    'selected-articles-dropdown-container ' + (this.props.selectedCount !== 0 ? 'not-empty' : 'empty')
                }
            >
                <div className="selected-articles-count">
                    <div className="count">
                        <FormattedMessage
                            id={'SelectedDocumentDropdown.selectedItemsButton'}
                            values={{
                                number: utils.formatNumbersByUserLocale(this.props.language, this.props.selectedCount),
                            }}
                        />
                    </div>
                    <div className="la-TriangleDown"></div>
                </div>
                <div className="selected-articles-dropdown">
                    <div className="selected-articles-dropdown-header">
                        <div className="selected-entities">
                            <FormattedMessage id={'SelectedDocumentDropdown.selectedItems'} />
                            {categoryKey ? (
                                <span>
                                    {' '}
                                    <FormattedMessage
                                        defaultMessage={withCustomNewsTranslation(categoryKey)}
                                        id={'BREADCRUMBS.snapshot.' + categoryUtils.resolvedToTranslatableCategory(categoryKey)}
                                    />
                                </span>
                            ) : (
                                <FormattedMessage id={'ManageAlerts.alertTab.myAlerts'} />
                            )}
                        </div>
                        <button
                            className="button-secondary-sm"
                            onClick={this.clearAllSelectedItems}
                            disabled={selectedItems.length === 0}
                        >
                            <FormattedMessage id={'SelectedDocumentDropdown.clearAllSelectedItems'} />
                        </button>
                    </div>
                    {selectedItems.length === 0 ? (
                        <div className="selected-articles-dropdown-no-entities">
                            <FormattedMessage id={'SelectedDocumentDropdown.noSelectedItems'} />
                        </div>
                    ) : (
                        <div className="selected-articles-dropdown-list">
                            {selectedItems.map((item, index) => {
                                return (
                                    <li key={index} className="selected-articles-dropdown-item">
                                        {this.props.dropdownType === SELECTED_DROPDOWN_RESULTS_LIST ? (
                                            <span
                                                className="selected-articles-dropdown-item-title"
                                                dangerouslySetInnerHTML={{ __html: item.title }}
                                            ></span>
                                        ) : (
                                            <span className="selected-articles-dropdown-item-title">{item.name}</span>
                                        )}
                                        <button
                                            data-track={`remove-selected-article-from-list-${index}`}
                                            onClick={() => this.clearItemFromList(item)}
                                            className="la-CloseRemove"
                                        />
                                    </li>
                                );
                            })}
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    language: state.user.preferences.language,
});

export { SelectedDocumentsDropdown as TestSelectedDocumentsDropdown };
export default connect(mapStateToProps)(SelectedDocumentsDropdown);
