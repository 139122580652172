import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { hashHistory, withRouter } from 'react-router';
import {
    CATEGORY_NAMES,
    EDIT_ALERT_SEARCH_PAGE_TITLE,
    FILTER_INFO,
    MESSAGE_TIMEOUT,
    PERSON_SEARCH,
    POST_FILTER_FUZZY_SEARCH,
    POST_FILTER_FUZZY_THRESHOLD,
    POST_FILTER_NEGATIVITY_LEVELS,
    POST_FILTER_TERMS,
    POSTFILTER_TYPE,
} from '@constants';
import AlertApi from './AlertsApi.api';
import { Loader } from 'semantic-ui-react';
import ResultsList from '@MainSearch/components/ResultsList';
import PopupModal from '@reusable/PopupModal/PopupModal.index';
import SearchUtils, { updateNewsQueriesBasedOnNegativityLevels } from '@MainSearch/SearchUtils';
import SearchResultsActions from '@MainSearch/redux/SearchResults.actions';
import reduxStore from '@reduxStore';
import EditAlertContext from '@contexts/EditAlert';
import utils from '@utils/utilities';
import { withAppContext } from '@utils/contexts';
import backgroundActions from '@reusable/BackgroundMessage/redux/BackgroundMessages.actions';
import searchResultsActions from '@MainSearch/redux/SearchResults.actions';
import { cloneDeep, difference, intersection } from 'lodash';
import { withAlertRouteToggle } from '@utils/contexts';
import categoryUtils from '@utils/categoryUtils';
import investigationActions from '@MainSearch/redux/Investigation.actions';
import { RESET_LOAD_DOCS_LISTENER } from '@sagas/constants/investigationConstants';
class EditAlertSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            alert: null,
            postFilters: null,
            isSaving: false,
            isLoading: true,
            toggleView: true,
        };

        this.getArticleList = this.getArticleList.bind(this);
        this.getEnabledCategory = this.getEnabledCategory.bind(this);
        this.handleSaveAlert = this.handleSaveAlert.bind(this);
        this.onSaveSuccess = this.onSaveSuccess.bind(this);
        this.onSaveError = this.onSaveError.bind(this);
        this.handlePostfilterChange = this.handlePostfilterChange.bind(this);
        this.handleSearchWithin = this.handleSearchWithin.bind(this);
        this.resetAllFilters = this.resetAllFilters.bind(this);
        this.updatePostFilterValue = this.updatePostFilterValue.bind(this);
    }

    componentDidMount() {
        document.title = EDIT_ALERT_SEARCH_PAGE_TITLE;

        if (this.props.context && this.props.context.onChangeContext) {
            this.props.context.onChangeContext({ editingAlert: true });
        }
        this.props.resetInvestigation();
        this.props.sendInvestigationAction({ type: RESET_LOAD_DOCS_LISTENER, payload: null });
        reduxStore.dispatch(searchResultsActions.resetCategoriesResults());

        AlertApi.getAlert(this.props.params.alertId)
            .then((alert) => {
                let postFilters = {};

                //fixing issue generated by public records added
                alert.postFilters = alert.postFilters.filter(
                    (postFilter) => postFilter.category !== CATEGORY_NAMES.PUBLIC_RECORDS
                );
                alert.contentTypes = alert.contentTypes.filter((type) => type !== CATEGORY_NAMES.PUBLIC_RECORDS);
                let updatedCategories = [];
                alert.postFilters.forEach((postFilter) => {
                    if (postFilter && postFilter.negativityLevels && postFilter.negativityLevels.length) {
                        // Update news queries based on negativity levels
                        postFilter = updateNewsQueriesBasedOnNegativityLevels(postFilter);
                    }
                    updatedCategories.push({
                        name: postFilter.category,
                        postFilters: postFilter,
                        enabled: true,
                    });
                    const category = categoryUtils.postfilterToCategory(postFilter);
                    postFilters[category] = {
                        ...postFilter,
                        category,
                    };
                });

                reduxStore.dispatch(SearchResultsActions.updateCategoriesList(updatedCategories));

                // disabling the rest of categories
                const alertCategories = this.getPostFilterCategories(alert.postFilters);
                const allCategories = categoryUtils.getFlatList().map((category) => category.key);
                const disabledCategories = difference(allCategories, alertCategories);

                this.setState({ alert });
                SearchUtils.searchArticlesForAlerts({
                    searchQuery: alert.searchQuery,
                    searchQueryType: alert.searchQueryType,
                    prefilterQuery: alert.prefilterQuery,
                    countOnly: true,
                    postFilters,
                    disabledCategories,
                    alertCategories,
                }).then(() => {
                    this.setState({ isLoading: false, toggleView: true });
                });

                return alert;
            })
            .catch((e) => {
                console.error(e);
                // TODO: Maybe a message here that says alert doesn't exist?
                hashHistory.push('/manage-alerts');
            });
    }

    componentWillUnmount() {
        if (this.props.context && this.props.context.onChangeContext) {
            this.props.context.onChangeContext({ editingAlert: false });
        }
    }

    getEnabledCategory(categoryName, searchResults) {
        let category = null;
        if (categoryName && searchResults[categoryName].enabled) {
            category = searchResults[categoryName];
        } else {
            let keys = Object.keys(searchResults);
            for (let i = 0; i < keys.length; i++) {
                if (searchResults[keys[i]].enabled) {
                    category = searchResults[keys[i]];
                    break;
                }
            }
        }

        return category;
    }

    getArticleList(searchResults) {
        // Filter out inactive languages for Negative News
        const postFilters = this.state.alert.postFilters.filter(postFilter => {
            const isNegativeNews = postFilter.category === CATEGORY_NAMES.NEGATIVE_NEWS;
            return !isNegativeNews || (
                isNegativeNews && this.state.alert.contentLanguages.includes(postFilter.contentLanguage)
            );
        });
    
        const categoryName =  this.props.location.query.category 
            || utils.getFirstAvailableCategory(postFilters);
    
        return this.getEnabledCategory(categoryName, searchResults) || {};
    }

    async handleSaveAlert() {
        if (!this.state.isSaving) {
            this.setState({ isSaving: true });
            const alert = this.createAlertObject();

            try {
                const response = await AlertApi.editAlert(alert, this.props.params.alertId);
                this.onSaveSuccess(response);
            } catch (e) {
                this.onSaveError(e);
            }
        }
    }

    onSaveSuccess(response) {
        this.setState({ isSaving: false });
        this.props.clearBackgroundMessages();
        reduxStore.dispatch(
            backgroundActions.setSuccessBackgroundMessages({
                message: 'Alerts.editAlert.alertEdited',
                messageParameters: this.state.alert.name,
                isVisible: true,
            })
        );
        setTimeout(() => {
            this.props.clearBackgroundMessages();
        }, MESSAGE_TIMEOUT);
        hashHistory.push('/manage-alerts');

        return response;
    }

    onSaveError(e) {
        this.setState({ isSaving: false });
        console.error(e);
        utils.showNotificationsMessage({
            messageText: 'Alerts.editAlert.alertEditError',
            messageType: 'system-error',
        });
    }

    handleSearchWithin(articleType, withInType) {
        this.setState({
            alert: {
                ...this.state.alert,
                postFilters: this.state.alert.postFilters.map((postFilter) => {
                    if (categoryUtils.postfilterToCategory(postFilter) === articleType) {
                        let searchCategory = reduxStore.getState().searchResults[articleType];
                        if (searchCategory && searchCategory.postFilters) {
                            postFilter[withInType] = searchCategory.postFilters[withInType];
                        }
                    }
                    return postFilter;
                }),
            },
        });
    }

    handlePostfilterChange(articleType, postFilterType, options) {
        if (!options) {
            options = this.props.postFilterConfiguration[articleType][postFilterType].values;
        }

        this.setState({
            alert: {
                ...this.state.alert,
                postFilters: this.state.alert.postFilters.map((postFilter) => {
                    if (categoryUtils.postfilterToCategory(postFilter) === articleType) {
                        const { searchFieldName, value } = this.updatePostFilterValue(postFilterType, options);

                        postFilter[searchFieldName] = value;
                        postFilter[POST_FILTER_FUZZY_THRESHOLD] = postFilter[POST_FILTER_FUZZY_SEARCH]
                            ? this.props.fuzzyThreshold
                            : '';

                        const customQuery = this.props.searchParams.searchType === PERSON_SEARCH 
                        ? this.props.personCheck 
                        : this.props.companyCheck;

                        // must use options[checked=true] here, using values does not work for all selected negativityLevels 
                        const selectedOptions = options.filter((o) => o.checked).map((o) => o.label);

                        if ( postFilter.newsQueries && postFilter.newsQueries.length > 0 ){
                            postFilter.newsQueries[0].negativeNewsQueries = 
                                SearchUtils.updateNegativeNewsQueries(postFilter, selectedOptions, customQuery);
                        }   
                    }

                    return postFilter;
                }),
            },
        });
    }

    resetAllFilters(articleType) {
        const categoryPostFilters = this.props.postFilterConfiguration[articleType];
        const alertsPostFilters = cloneDeep(this.state.alert.postFilters);
        const categoryAlertsPostFilters = alertsPostFilters.find(
            (postFilter) => categoryUtils.postfilterToCategory(postFilter) === articleType
        );
        const searchType = this.props.searchParams.searchType;

        Object.values(categoryPostFilters).forEach((postFilter) => {
            const { searchFieldName, value } = this.updatePostFilterValue(postFilter.type, postFilter.values);
            categoryAlertsPostFilters[searchFieldName] = value;
        });

        categoryAlertsPostFilters[POST_FILTER_FUZZY_THRESHOLD] = categoryAlertsPostFilters[POST_FILTER_FUZZY_SEARCH]
            ? this.props.fuzzyThreshold
            : '';
        categoryAlertsPostFilters[POST_FILTER_NEGATIVITY_LEVELS] =
            searchType === PERSON_SEARCH
                ? this.props.personCheck.negativityLevel
                : this.props.companyCheck.negativityLevel;
        categoryAlertsPostFilters[POST_FILTER_TERMS] = [];

        this.setState({
            alert: {
                ...this.state.alert,
                postFilters: alertsPostFilters,
            },
        });
    }

    updatePostFilterValue(postFilterType, values) {
        const componentInfo = utils.getComponentInfo(postFilterType);
        let searchFieldName =
            componentInfo.searchFieldName === FILTER_INFO.PUBLICATION_DATE
                ? FILTER_INFO.DATE_RANGE
                : componentInfo.searchFieldName;

        if (postFilterType === POSTFILTER_TYPE.TERMS) {
            searchFieldName = postFilterType;
        }

        const value = SearchUtils.getPostfilterSelection({
            componentType: componentInfo.componentType,
            type: postFilterType,
            values,
        }, true); 

        return { searchFieldName, value }; 
    }

    createAlertObject() {
        // include and exclude terms - take them from redux since they are not practically a postfilter, therefore not on the postfilter change handler
        // include the last sort option in the alert post filters
        let suggestedNames = [];
        if (utils.isPersonSearch(this.state.alert.searchQueryType)) {
            if (this.props.suggestedNamesEnabled) {
                suggestedNames = this.state.alert.suggestedNames || utils.getFuzzyNameList(this.props.suggestedNames);
            }
        }

        const alertPostFilters = this.state.alert.postFilters.map((postFilter) =>
            this.props.searchResults[postFilter.category]
                ? {
                      ...postFilter,
                      sort: this.props.searchResults[postFilter.category].postFilters.sort,
                      // includeTerms: this.props.searchResults[postFilter.category].postFilters.includeTerms,
                      // excludeTerms: this.props.searchResults[postFilter.category].postFilters.excludeTerms
                  }
                : postFilter
        );
        this.setState({ alert: { ...this.state.alert, postFilters: alertPostFilters } });
        return {
            ...this.state.alert,
            fuzzyNames: utils.getFuzzyNameList(this.props.fuzzyNames),
            suggestedNames: suggestedNames,
        };
    }

    getPostFilterCategories(postFilters) {
        return postFilters.map((postFilter) => categoryUtils.postfilterToCategory(postFilter));
    }

    getSearchResults() {
        let contentLanguages = this.state.alert.contentLanguages;

        //get alert categories and for NegativeNews filter them according to contentLanguages
        let alertCategories = this.getPostFilterCategories(this.state.alert.postFilters).filter((categoryName) => {
            if (categoryUtils.isChildOf(CATEGORY_NAMES.NEGATIVE_NEWS, categoryName)) {
                let language = categoryUtils.getCategory(categoryName).extendConfig().contentLanguage;
                return contentLanguages.indexOf(language) > -1;
            } else {
                return true;
            }
        });

        let searchResults = cloneDeep(this.props.searchResults);

        for (let contentType in searchResults) {
            searchResults[contentType].enabled = alertCategories.indexOf(contentType) > -1;
            if (categoryUtils.isParent(contentType)) {
                if (intersection(alertCategories, categoryUtils.getCategoryKeys(contentType)).length) {
                    searchResults[contentType].enabled = true;
                }
            }
        }

        return searchResults;
    }

    updateHistory(articleType, forcedFilters) {
        if (!this.props.context.isUserAnonymized) {
            return SearchUtils.updateHistory(articleType, forcedFilters);
        }
    }

    render() {
        if (this.state.alert && !this.state.isSaving) {
            const searchResults = this.getSearchResults();
            let articleList = this.getArticleList(searchResults);
            return (
                <EditAlertContext.Provider
                    value={{
                        editingAlert: this.state.alert,
                        onSaveAlert: this.handleSaveAlert,
                        saveAlertDisabled: this.state.isSaving,
                    }}
                >
                    <div>
                        {this.props.popupModel.isVisible && <PopupModal closeButtonHandler={this.closePopupModal} />}
                        {this.state.toggleView && (
                            <ResultsList
                                articleType={articleList.name}
                                onPostfilterChange={this.handlePostfilterChange}
                                onSearchWhithinChange={this.handleSearchWithin}
                                resetAllFilters={this.resetAllFilters}
                                articleList={articleList}
                                searchResults={searchResults}
                                searchParams={{ query: this.state.alert.searchQuery }}
                                withoutRefresh={true}
                            />
                        )}
                    </div>
                </EditAlertContext.Provider>
            );
        } else {
            return <Loader size="large" active />;
        }
    }
}

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            resetInvestigation: investigationActions.resetInvestigation,
            sendInvestigationAction: investigationActions.investigationAction,
            clearBackgroundMessages: backgroundActions.clearBackgroundMessages,
        },
        dispatch
    );

const mapStateToProps = (state) => ({
    searchResults: state.searchResults,
    popupModel: state.popupModel,
    searchParams: state.searchParams,
    fuzzyNames: state.fuzzyNames,
    suggestedNames: state.suggestedNames,
    suggestedNamesEnabled: state.user.suggestedNamesEnabled,
    postFilterConfiguration: state.postFilterConfiguration,
    fuzzyThreshold: state.user.preferences.personCheck.fuzzyThreshold,
    personCheck: state.user.preferences.personCheck,
    companyCheck: state.user.preferences.companyCheck,
});

export { EditAlertSearch as TestEditAlertSearch };
export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(withAppContext(withAlertRouteToggle(EditAlertSearch)))
);
