import { takeLatest, takeEvery, takeLeading } from 'redux-saga/effects';
import {
    INIT_ARTICLE_NAVIGATION,
    INIT_VIEW_ARTICLE,
    VIEW_ARTICLE,
    ARTICLE_NAVIGATION_NEXT,
    ARTICLE_NAVIGATION_PREV,
    ARTICLE_NAVIGATION_LOAD_NEXT_PAGE,
    ARTICLE_NAVIGATION_LOAD_PREV_PAGE,
    SEARCH_DOC_ACCESS_EVENT,
} from '@pages/MainSearch/redux/ArticleNavigation.actions';

export function* articleNavigation(flow) {
    yield takeLatest(INIT_ARTICLE_NAVIGATION, flow);
}

export function* singleArticleView(flow) {
    yield takeLatest(INIT_VIEW_ARTICLE, flow);
}

// this is an action triggered by different sagas in order to be cancellable from anywhere
export function* articleLoader(flow) {
    yield takeLatest(VIEW_ARTICLE, flow);
}

export function* nextArticle(flow) {
    yield takeEvery(ARTICLE_NAVIGATION_NEXT, flow);
}

export function* previousArticle(flow) {
    yield takeEvery(ARTICLE_NAVIGATION_PREV, flow);
}

export function* loadNextPage(flow) {
    yield takeLeading(ARTICLE_NAVIGATION_LOAD_NEXT_PAGE, flow);
}

export function* loadPreviousPage(flow) {
    yield takeLeading(ARTICLE_NAVIGATION_LOAD_PREV_PAGE, flow);
}

export function* searchDocAccessEvent(flow) {
    yield takeLatest(SEARCH_DOC_ACCESS_EVENT, flow);
}
