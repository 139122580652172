/**
 * FOOTER ENTRY POINT
 *
 * The footer component contains the logo and the navigation links.
 *
 * @version 0.1
 */

import React from 'react';
import { FormattedMessage } from 'react-intl';
import reduxStore from '@reduxStore';
import { DEFAULT_LANGUAGE, EDIT_LOCATION_HASH, GENERIC_PRIVACY_POLICY_LINK, LOCALES, START_LOCATION_HASH } from '@constants';

const Footer = (props) => {
    let version = reduxStore.getState().header.version;
    let appVersion = version ? version : null;
    const currentDate = new Date(),
        copyrightYear = currentDate.getFullYear();
    let aboutLink = LOCALES[props.language] ? LOCALES[props.language].about : LOCALES[DEFAULT_LANGUAGE].about;

    let privacyLink;
    if (GENERIC_PRIVACY_POLICY_LINK.enabled) {
        privacyLink = GENERIC_PRIVACY_POLICY_LINK.url;
    } else {
        privacyLink = LOCALES[props.language] ? LOCALES[props.language].privacy : LOCALES[DEFAULT_LANGUAGE].privacy;
    }

    let copyrightLink = LOCALES[props.language]
        ? LOCALES[props.language].copyright
        : LOCALES[DEFAULT_LANGUAGE].copyright;
    let termsLink = LOCALES[props.language] ? LOCALES[props.language].terms : LOCALES[DEFAULT_LANGUAGE].terms;
    let logoLink = LOCALES[props.language] ? LOCALES[props.language].logo : LOCALES[DEFAULT_LANGUAGE].logo;
    const isHomepage = window.location.hash === START_LOCATION_HASH || window.location.hash === EDIT_LOCATION_HASH;

    return (
        /* Add the "notranslate" class so that Google Translate doesn't change the footer when in Preview Article */
        <div className={`footer noprint notranslate ${isHomepage ? 'transparent-background' : ''}`}>
            <div className="footer__wrapper">
                <div className="footer__links-left">
                    <a href={logoLink} target="_blank" rel="noopener noreferrer">
                        <img
                            className="footer__logo--lexis"
                            src={
                                props.isDarkMode
                                    ? '/assets/LexisNexisFooterLogoDark.svg'
                                    : '/assets/LexisNexisFooterLogo.svg'
                            }
                            alt="LexisNexis logo"
                        />
                    </a>

                    <a href={aboutLink} target="_blank" rel="noopener noreferrer" className="footer__link">
                        <FormattedMessage id="HeaderFooterNav_Legal_UIText_footer.about" />
                        <sup>&reg;</sup>
                    </a>

                    <a href={privacyLink} target="_blank" rel="noopener noreferrer" className="footer__link">
                        <FormattedMessage id="HeaderFooterNav_Legal_UIText_footer.privacy" />
                    </a>

                    <button id="ot-sdk-btn" className="ot-sdk-show-settings">
                        <FormattedMessage id="HeaderFooterNav_Legal_UIText_footer.cookies" />
                    </button>

                    <a href={termsLink} target="_blank" rel="noopener noreferrer" className="footer__link">
                        <FormattedMessage id="HeaderFooterNav_Legal_UIText_footer.terms" />
                    </a>

                    <a href={copyrightLink} target="_blank" rel="noopener noreferrer" className="footer__link">
                        <span>&copy; </span>
                        {copyrightYear}
                        &nbsp;
                        <FormattedMessage id="HeaderFooterNav_Legal_UIText_footer.copyright" />
                    </a>
                </div>

                <div className="footer__links-right">
                    <a
                        href="https://www.relx.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="footer__relx-link"
                    >
                        <img
                            className="footer__logo--relx"
                            src={props.isDarkMode ? '/assets/RelxLogoDark.svg' : '/assets/RelxLogo.svg'}
                            alt="RELX logo"
                        />
                    </a>
                </div>
            </div>

            <div className="version">{appVersion}</div>
        </div>
    );
};

export default Footer;
