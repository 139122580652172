/**
 * POLYFILLS FILE
 *
 * This file exports a function which applies all required polyfills in order
 * for the app to work.
 *
 * @version 0.1
 */

/**
 * The Intl polyfill is required by older browsers and also Safari in order
 * for the react-intl (internationalization module) to work properly.
 */
export let babelPolyfill = () => {
    if (typeof _babelPolyfill === 'undefined') {
        require('babel-polyfill');
    }
};

/**
 * Babel polyfill for full ES6 support.
 */
export let intl = () => {
    if (!window.Intl) {
        window.Intl = require('intl');
    }
};

/**
 * Applies all polyfills
 */
export default function applyAllPolyfills() {
    babelPolyfill();
    intl();
}
