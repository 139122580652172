import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

const ModalSaveSearch = (props) => {
    if (!props.isVisible) {
        return <span />;
    }

    return (
        <div className="popup-modal__save-search-body">
            <FormattedMessage id="PopupBuilder.description.saveSearch" />
        </div>
    );
};

export default injectIntl(ModalSaveSearch);
