/**
 * MESSAGE_POPUP REDUX ACTIONS
 *
 * @version 0.1
 */

import reduxStore from '@reduxStore';

export const SET_MESSAGES = 'SET_MESSAGES';
export const CLEAR_MESSAGES = 'CLEAR_MESSAGES';
export const REMOVE_MESSAGE = 'REMOVE_MESSAGE';
export const APPEND_MESSAGES = 'APPEND_MESSAGES';

var actions = {
    setMessages: function (messages) {
        reduxStore.dispatch({
            type: SET_MESSAGES,
            payload: messages,
        });
    },

    removeMessage: function () {
        reduxStore.dispatch({
            type: REMOVE_MESSAGE,
        });
    },

    appendMessages: function (messages) {
        reduxStore.dispatch({
            type: APPEND_MESSAGES,
            payload: messages,
        });
    },

    clearMessages: function () {
        reduxStore.dispatch({
            type: CLEAR_MESSAGES,
        });
    },
};

export default actions;
