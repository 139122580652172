//@flow

import type {
    InvestigationEventType,
    InvestigationPayloadDataType,
    InvestigationPayloadType,
    InvestigationRequestType,
} from '../investigationTypeGuards/investigationRequests.type.guards';

export const getInvestigation = ({
    praOn,
    searchQuery,
    fullSearchQuery,
    searchQueryType,
    createdDate,
}: InvestigationPayloadType): InvestigationRequestType => {
    const url: string = '/api/report/searchInvestigation?praOn=' + praOn.toString();
    const data: InvestigationPayloadDataType = {
        searchQuery,
        fullSearchQuery,
        searchQueryType,
        createdDate,
    };

    return {
        url,
        method: 'post',
        data,
    };
};

export const sendInvestigationEvent = ({
    investigationId,
    payload,
}: InvestigationEventType): InvestigationRequestType => {
    const url: string = `/api/research-summary/${investigationId}/events`;

    return {
        url,
        method: 'post',
        data: payload,
    };
};
