import xhr from '@utils/xhr';
import reduxStore from '@reduxStore';
import manageAlertsActions from './redux/ManageAlerts.actions';
import utils from '@utils/utilities';
import { API_RESPONSE_MESSAGE_SUCCESS } from '@constants';

let extra = '';

const api = {
    getPublicRecordAlerts(pageNumber, pageSize) {
        return xhr
            .get({
                url: '/api/alerts/publicRecords/list?start=' + pageNumber * pageSize + '&chunkSize=' + pageSize,
            })
            .then((response) => {
                const alertsCount = response.body ? response.body.total : 0;
                reduxStore.dispatch(manageAlertsActions.setPublicRecordsCount(alertsCount));
                reduxStore.dispatch(manageAlertsActions.setDownloadAlertsButtonDisabledState(false));
                return response.body;
            });
    },

    deletePublicRecordAlerts(alertIds, pageSize) {
        //we can iterate throw alerts list
        let fullUrl = '/api/alerts/publicRecords/deleteAndRefresh';

        alertIds.forEach(function (item, index) {
            if (index === 0) {
                fullUrl += '?';
            } else {
                fullUrl += '&';
            }
            fullUrl += 'alertId=' + item;
        });

        fullUrl += '&chunkSize=' + pageSize;

        return xhr
            .get({
                url: fullUrl,
            })
            .then((response) => {
                return response.body;
            });
    },

    getMyAlerts(pageNumber, pageSize, sortBy, direction, nameFilter) {
        if (nameFilter === undefined || nameFilter === '') {
            extra = '';
        } else {
            extra = '&nameFilter=' + nameFilter;
        }
        return xhr
            .get({
                url:
                    '/api/alerts/?pageNumber=' +
                    pageNumber +
                    '&pageSize=' +
                    pageSize +
                    '&sortBy=' +
                    sortBy +
                    '&direction=' +
                    direction +
                    extra,
            })
            .then((response) => {
                const alertsCount = response.body ? response.body.length : 0;
                reduxStore.dispatch(manageAlertsActions.setAlertsCount(alertsCount));
                reduxStore.dispatch(manageAlertsActions.setDownloadAlertsButtonDisabledState(false));
                return response.body;
            });
    },

    getMyAlertsCount(activeOnly, nameFilter) {
        if (nameFilter === undefined || nameFilter === '') {
            extra = '';
        } else {
            extra = `${activeOnly ? '&' : '?'}nameFilter=${nameFilter}`;
        }
        const searchForActiveAlerts = activeOnly ? `?active=${activeOnly}` : '';

        return xhr
            .get({
                url: `/api/alerts/count/${searchForActiveAlerts}${extra}`,
            })
            .then((response) => {
                return response.body;
            });
    },

    activateMyAlerts(alertIds) {
        reduxStore.dispatch(manageAlertsActions.setDownloadAlertsButtonDisabledState(true));

        const enforceAlertsLimit = reduxStore.getState().user.enforceAlertsLimit;
        const activeAlertsLimit = reduxStore.getState().user.preferences.generalSettings.activeAlertsLimit;

        let url = 'api/alerts/activate';

        if (enforceAlertsLimit && activeAlertsLimit) {
            url = `api/alerts/activate?alertLimit=${activeAlertsLimit}`;
        }

        return xhr
            .post({
                url,
                data: alertIds,
            })
            .then((response) => {
                reduxStore.dispatch(manageAlertsActions.setDownloadAlertsButtonDisabledState(false));
                const responseMessage = response.body?.message;

                if (enforceAlertsLimit && responseMessage !== API_RESPONSE_MESSAGE_SUCCESS) {
                    utils.showNotificationsMessage({
                        messageText: 'ManageAlerts.ErrorMessage.alertsLimitReached',
                        messageType: 'user-error',
                        terms: {
                            maximumNumber: activeAlertsLimit,
                        },
                    });
                }

                return response.body;
            });
    },

    deactivateMyAlerts(alertIds) {
        reduxStore.dispatch(manageAlertsActions.setDownloadAlertsButtonDisabledState(true));

        return xhr
            .post({
                url: '/api/alerts/deactivate',
                data: alertIds,
            })
            .then((response) => {
                reduxStore.dispatch(manageAlertsActions.setDownloadAlertsButtonDisabledState(false));

                return response.body;
            });
    },

    deleteMyAlerts(alertIds) {
        reduxStore.dispatch(manageAlertsActions.setDownloadAlertsButtonDisabledState(true));

        return xhr
            .delete({
                url: '/api/v2/alerts/',
                data: alertIds,
            })
            .then((response) => response.text);
    },

    downloadAlertsList({ userLanguage, filename, alertsSorting, timezone }) {
        return xhr
            .post({
                url: '/api/alerts/export',
                data: {
                    filename,
                    timezone,
                    sorting: alertsSorting,
                },
                headers: {
                    'Accept-Language': userLanguage,
                },
            })
            .then((response) => response.body);
    },
};

export default api;
