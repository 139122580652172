import React from 'react';
import DocumentItem from './DocumentItem';
import utils from '@utils/utilities';
import { Loader } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import { cloneDeep } from 'lodash';
import ReportBuilderService from '@ReportBuilder/ReportBuilderService';
import { withDragAndDrop } from 'scripts/reusable/HOC/withDragAndDrop';
import { DRAG_AND_DROP } from '@constants';

class ReportCategoryList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            articles: props.category.snippets,
            report: props.report,
            category: props.category,
            overlayIsVisible: false,
            droppedArticleId: null,
            hoverIndex: null,
            currentIndex: null,
            canDrop: true,
            visibleMenu: null,
        };
        this.reorderArticles = this.reorderArticles.bind(this);
        this.setOverlay = this.setOverlay.bind(this);
        this.updateVisualDropTarget = this.updateVisualDropTarget.bind(this);
        this.removeVisualTarget = this.removeVisualTarget.bind(this);
    }

    setOverlay(overlayIsVisible) {
        this.setState({
            overlayIsVisible: overlayIsVisible,
        });
    }

    updateVisualDropTarget(currentIndex, hoverIndex) {
        let prevHoverIndex = this.state.hoverIndex;
        if ((hoverIndex === undefined || currentIndex === hoverIndex) && prevHoverIndex !== null) {
            this.setState({
                currentIndex: null,
                hoverIndex: null,
                canDrop: true,
            });
        }
        if (prevHoverIndex !== hoverIndex && Math.abs(currentIndex - hoverIndex)) {
            this.setState({
                currentIndex,
                hoverIndex,
                canDrop: true,
            });
        }
    }
    removeVisualTarget() {
        this.setState({
            canDrop: false,
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            articles: nextProps.category.snippets,
            report: nextProps.report,
            category: nextProps.category,
            canDrop: true,
        });
    }

    UNSAFE_componentWillMount() {
        this.intervalId = null;
    }

    componentWillUnmount() {
        clearInterval(this.intervalId);
    }

    reorderArticles(newOrderArticlesIds, droppedArticleId) {
        const { articles } = this.state;
        let newArticles = [];
        articles.forEach((article) => {
            let newArticle = newOrderArticlesIds.find((item) => {
                return item.id === article.id;
            });
            if (newArticle) {
                article.order = newArticle.order;
            } else {
                console.log('!!article not found in response ', article.id);
            }
            newArticles.push(article);
        });

        let currentCategory = this.state.category.categoryName;
        let currentReport = cloneDeep(this.state.report);
        currentReport.categories.find((category) => category.categoryName === currentCategory).snippets = newArticles;

        this.setState({
            articles: newArticles,
            droppedArticleId,
            report: currentReport,
            hoverIndex: null,
        });

        clearInterval(this.intervalId);
        this.intervalId = setTimeout(() => {
            this.setState({
                droppedArticleId: null,
            });
        }, 2500);
    }

    requestReorder = async (index, hoverIndex, currentElement) => {
        const { report, category } = this.state;
        const categoryId = report.id + '_' + category.categoryName;

        this.setOverlay(true);
        this.props.showAutosaveMessage(categoryId);

        await ReportBuilderService.sortArticlesInCategory(
            currentElement.reportId,
            category.categoryName,
            currentElement.id,
            hoverIndex,
            (response) => {
                this.reorderArticles(response, currentElement.id);
                this.props.removeAutosaveMessage(false, categoryId);
            },
            () => {
                this.updateVisualDropTarget();
                this.props.removeAutosaveMessage(true, categoryId);
            }
        );

        this.setOverlay(false);
    };

    setActiveMenu = (id) => {
        this.setState({ visibleMenu: id });
    };

    render() {
        const { articles, category, report, droppedArticleId, hoverIndex, currentIndex, visibleMenu } = this.state;
        const hasMenuOpen = visibleMenu !== null;
        const { connectDropTarget } = this.props;
        return connectDropTarget(
            <div>
                <div className="report-list-articles-list-container">
                    {this.state.overlayIsVisible && (
                        <div className="category-overlay">
                            <Loader size={'big'} active></Loader>
                        </div>
                    )}
                    <div className="article-list-header">
                        <div className="article-list-header__title">
                            <FormattedMessage id="ReportCategoryList.documentTitle" />
                        </div>
                        <div className="article-list-header__date">
                            <FormattedMessage id="ReportCategoryList.documentExpiryDate" />
                        </div>
                    </div>
                    <ol className={`reports-list-articles-table ${hasMenuOpen ? 'with-menu-open' : ''}`}>
                        {utils.sortByProperty(articles, 'order').map((article, index) => (
                            <DocumentItem
                                key={index}
                                article={article}
                                index={article.order || 0}
                                report={report}
                                category={category}
                                language={this.props.language}
                                droppedArticleId={droppedArticleId}
                                requestReorder={this.requestReorder}
                                setOverlay={this.setOverlay}
                                showDropTarget={hoverIndex === (article.order || 0)}
                                insertAbove={hoverIndex < currentIndex}
                                updateVisualDropTarget={this.updateVisualDropTarget}
                                articleOrderIndex={index}
                                canDrop={this.state.canDrop}
                                categoryId={report.id + '_' + category.categoryName}
                                showAutosaveMessage={this.props.showAutosaveMessage}
                                removeAutosaveMessage={this.props.removeAutosaveMessage}
                                onSelectionChange={this.props.onSelectionChange}
                                onUpdateReportBuilderPage={this.props.onUpdateReportBuilderPage}
                                setActiveMenu={this.setActiveMenu}
                                visibleMenu={visibleMenu}
                            />
                        ))}
                    </ol>
                </div>
            </div>
        );
    }
}

const dndHookInfo = {
    componentType: 'parent',
    accept: DRAG_AND_DROP.ARTICLE,
};

export default withDragAndDrop(dndHookInfo)(ReportCategoryList);
