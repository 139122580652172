//@flow
import * as React from 'react';
import utils from '@utils/utilities';

// HOC to show the component except on the provided location hash

type RequiredProps = {
    isVisible: boolean,
};

export const withToggleVisibility = <Props: {} & RequiredProps>(
    Component: React.AbstractComponent<Props>
): React.AbstractComponent<Props> | null => {
    const toggleVisibility = (props: Props & RequiredProps) => {
        return props.isVisible ? <Component {...props} /> : null;
    };

    toggleVisibility.displayName = `toggleVisibility(${utils.getDisplayName(Component)})`;

    return toggleVisibility;
};
