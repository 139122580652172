// @flow
import utils from '@utils/utilities';
import categoryUtils from '@utils/categoryUtils';
import errorUtils from '@utils/errors/error-utils';
import { cloneDeep } from 'lodash';
import { REPORT_TYPE } from '@constants';
import type {
    Category,
    DeliveryData,
    ExpandedReport,
    ParentReport,
    Report,
    ResearchSummaryEntry,
    CategoryOrder,
    Snippet,
    State,
    GenericReportType,
    ExpandedRegularReportType,
} from '../redux/flow/ReportBuilder.type.guards';

const APP_MODULE = 'ReportBuilderUtils';

const ReportBuilderUtils = {
    isBuildValid(report: Report): boolean {
        let buildEnable = false;
        let availableDownloads = report.availableDownloads || [];
        availableDownloads.forEach((build) => {
            if (
                build.createdDate > report.lastUpdated ||
                // for combined reports at creation created date and last update date are equal and build should be available
                (ReportBuilderUtils.isReportCombined(report.deliveryType) && build.createdDate >= report.lastUpdated)
            ) {
                buildEnable = true;
            }
        });
        return buildEnable;
    },

    sortCategoriesAndArticles(report: GenericReportType, reportSnippets: Snippet): Array<Category> {
        let categoriesObject: Category = {};

        const categories = utils.isArrayPopulated(report.researchSummary)
            ? report.researchSummary
            : report.categoryOrder;
        categories.forEach((category) => {
            categoriesObject[category.sourceType] = {};
            categoriesObject[category.sourceType].order = category.order;
            categoriesObject[category.sourceType].show = false;
            categoriesObject[category.sourceType].categoryName = category.sourceType;
            categoriesObject[category.sourceType].categoryLabel = categoryUtils.getCategoryName(category.sourceType);
        });

        //sort and add snippets to corresponding category
        utils.sortByProperty(reportSnippets, 'order').forEach((snippet) => {
            let category = categoriesObject[snippet.reportSnippetType];
            if (category) {
                if (!category.snippets) {
                    category.snippets = [];
                    category.show = true;
                }
            } else {
                //there are snippets that have no entry in research summary
                errorUtils.logAppError(
                    'There are snippets that have no entry in research summary',
                    { reportSnippetId: reportSnippets.id },
                    APP_MODULE
                );
                category = {};
                category.show = true;
                category.snippets = [];
                category.categoryName = snippet.reportSnippetType;
                category.persistedNodes = snippet.persistedNodes;
                categoriesObject[snippet.reportSnippetType] = category;
            }
            category.snippets.push(snippet);
        });

        let reportCategories = Object.keys(categoriesObject).map((category) => {
            return categoriesObject[category];
        });
        //sort categories by order
        reportCategories = utils.sortByProperty(reportCategories, 'order');
        return reportCategories;
    },

    updateCategoriesOrder(
        categories: Array<Category>,
        researchSummary: Array<ResearchSummaryEntry | CategoryOrder>
    ): Array<Category> {
        let orderedCategories = cloneDeep(categories);

        orderedCategories.forEach((category) => {
            const entry = researchSummary.find((item) => item.sourceType === category.categoryName);
            if (!!entry) category.order = entry.order;
        });

        return utils.sortByProperty(orderedCategories, 'order');
    },

    updateReportBuilderSelection(
        reports: Array<Report>,
        deletedReportId: string,
        onSelectionChange: (Array<Report>) => void
    ) {
        let remainingItems = reports;

        if (remainingItems.length) {
            remainingItems = remainingItems.filter((item) => item.id !== deletedReportId);
        }

        onSelectionChange(remainingItems);
    },

    isReportCombined(reportDeliveryType: string): boolean {
        return reportDeliveryType === REPORT_TYPE.COMBINED;
    },

    isReportBatch(reportDeliveryType: string): boolean {
        return reportDeliveryType === REPORT_TYPE.BATCH;
    },

    processDeliveryData(report: GenericReportType): DeliveryData {
        const availableDownloads =
            report.reportDelivery && report.reportDelivery.length > 0 ? report.reportDelivery : [];

        utils.sortByProperty(availableDownloads, 'lastUpdated');

        const lastUpdated = report.lastUpdated;
        const latestDocument =
            availableDownloads.length === 0 ? null : availableDownloads[availableDownloads.length - 1];

        return { availableDownloads, latestDocument, lastUpdated, reportId: report.id };
    },

    extractParentReportIds: function (state: State, reportId: string): Array<string | void> {
        const report = state.reportBuilder.reports.find((entry) => entry.id === reportId);

        if (report && report.parentReports && report.parentReports.length) {
            const parentReports: Array<?ParentReport> = report.parentReports;

            return parentReports.map((parentReport) => (parentReport ? parentReport.reportId : undefined));
        }

        return [];
    },

    getIndexForReportArticleAndCategory(
        reports: Array<ExpandedReport>,
        reportId: string,
        articleId: string
    ): { reportIndex: number, categoryIndex: number, articleIndexInCategory: number } {
        let reportIndex = -1;
        let categoryIndex = -1;
        let articleIndexInCategory = -1;

        if (reports) {
            reports.forEach((report: ExpandedRegularReportType | Object, repIndex: number) => {
                if (report.id === reportId) {
                    reportIndex = repIndex;
                    if (report.categories && report.categories.length) {
                        report.categories.forEach((category, catIndex) => {
                            let snippets = category.snippets || [];
                            for (let i = 0; i < snippets.length; i++) {
                                if (snippets[i].id === articleId) {
                                    categoryIndex = catIndex;
                                    articleIndexInCategory = i;
                                    break;
                                }
                            }
                        });
                    }
                }
            });
        }

        return { reportIndex, categoryIndex, articleIndexInCategory };
    },
};

export default ReportBuilderUtils;
