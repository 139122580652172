import React from 'react';
import utils from '@utils/utilities';
import { DefaultLoader } from '../components/defaults';
import * as DilProps from '@utils/props';

const hasLoader =
    (LoaderComponent = DefaultLoader) =>
    (TableComponent) => {
        const hasLoaderComponent = (props) =>
            props.data.isLoading ? <LoaderComponent {...props} /> : <TableComponent {...props} />;

        hasLoaderComponent.displayName = `hasLoader(${utils.getDisplayName(TableComponent)})`;

        hasLoaderComponent.propTypes = {
            data: DilProps.TableData.isRequired,
        };

        return hasLoaderComponent;
    };

export default hasLoader;
