import {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {entitiesTableFocus, MULTIPLE_ENTITIES_NONE_SELECTED_VIEW, TOOLBAR_ACTIONS} from '@constants';
import EntityViewApi from '../api/EntityViewApi';
import utils from 'scripts/utils/utilities';
import mainActions from '@pages/Main/Main.actions';
import articlesManagerActions from '@pages/MainSearch/redux/ArticlesManager.actions';
import notificationService from '@utils/notificationService';
import {
    buildUpdateViewPayload,
    getSelectionPayload,
    isSelectAllChecked,
} from '@pages/EntityView/components/EntityViewGridTable/helpers/batchHelpers';

const useViews = (
    views,
    setViews,
    selectedItems,
    setSelectedItems,
    selectAllSetting,
    unselectedItemIds,
    searchEntity,
    sortInfo,
    clearAllSelectedItems,
    updateEntityViewPreferences,
    handleEntityViewApiError,
    setLastPerformedAction,
    setTableFocus,
    route,
) => {
    const [selectedView, setSelectedView] = useState({});

    const columnsPreferences = useSelector((state) => state.user.preferences.screeningEntity);
    const userPermId = useSelector((state) => state.user.userPermIdUnhashed);
    const shouldGetAllViews = useSelector((state) => state.entityViewInformation.shouldGetAllViews);
    const lastSelectedView = useSelector((state) => state.user.preferences.screeningEntity.lastSelectedView);
    const displayRiskScores = useSelector((state) => state.user.preferences.generalSettings.displayRiskScores);

    const dispatch = useDispatch();

    const clearSelectedArticle = () => dispatch(articlesManagerActions.clearSelectedArticle());

    // get the user views and clear selected article
    useEffect(() => {
        clearSelectedArticle();
        getAllViews();
    }, [shouldGetAllViews]);

    const changeView = (currentView) => {
        if (!currentView || currentView?.id !== lastSelectedView) setLastPerformedAction(TOOLBAR_ACTIONS.changeView);

        let changeViewPayload = {
            columns: { ...columnsPreferences.columns },
            sorting: { ...columnsPreferences.sorting },
        };
        clearAllSelectedItems();
        // when no currentView is provided, set the default view and update the state accordingly
        if (!currentView || !Object.keys(currentView).length) {
            changeViewPayload.lastSelectedView = MULTIPLE_ENTITIES_NONE_SELECTED_VIEW;
            changeViewPayload.isShareable = false;
            updateEntityViewPreferences(changeViewPayload);
            return;
        }
        setSelectedView(currentView);
        
        changeViewPayload.lastSelectedView = currentView.id;
        changeViewPayload.isShareable = currentView.isShareable;

        updateEntityViewPreferences(changeViewPayload);
    };

    const createView = async (viewName) => {
        const selectedItemsForPayload = {};

        if (selectedItems?.length) {
            selectedItems.forEach((item) => {
                selectedItemsForPayload[item.id] = item.displayName;
            });
        }

        const createViewPayloadData = {
            name: viewName,
            userPermId,
            screeningEntityIdsAndDisplayNames: isSelectAllChecked(selectAllSetting) ? {} : selectedItemsForPayload,
            ...getSelectionPayload(selectAllSetting, [], unselectedItemIds, sortInfo, displayRiskScores),
        };
        // createView api doesn’t support entityIds
        delete createViewPayloadData.entityIds;

        const urlParams = {
            searchTerm: searchEntity,
            viewId: selectedView.id ?? '',
        };

        const [response, error] = await EntityViewApi.createView(createViewPayloadData, urlParams);

        if (error) {
            handleEntityViewApiError(error, urlParams);
            return;
        }
        setLastPerformedAction(TOOLBAR_ACTIONS.createView);

        const { batchId, viewId } = response;
        notificationService.pollCreateView({
            batchId,
            onFinished: () => onFinishedPollCreateView(viewId, viewName),
        });
        return [response, error];
    };

    const onFinishedPollCreateView = (viewId, viewName) => {
        const newView = { id: viewId, name: viewName, isShareable: true };
        const updatedViews = [...views, newView].sort((a, b) => a.name.localeCompare(b.name));

        setViews(updatedViews);
        clearAllSelectedItems();
        setTableFocus(entitiesTableFocus);
        changeView(newView);
    };

    const deleteView = async (viewId) => {
        const [, error] = await EntityViewApi.deleteView(viewId);

        if (error) {
            handleEntityViewApiError(error, { viewId });
            return;
        }

        setViews((prevViews) => prevViews.filter((view) => view.id !== viewId));
    };

    const updateView = async ({ viewId, selectedItems = [], currentViewId, searchTerm, isForAll = false }) => {
        if (!selectedItems.length) return;

        const payload = buildUpdateViewPayload({
            userPermId,
            selectedItems,
            searchTerm,
            currentViewId,
            isForAll,
        });

        const [data, error] = await EntityViewApi.updateView(viewId, payload);

        if (error) {
            handleEntityViewApiError(error, { viewId });
            return;
        }

        setLastPerformedAction(TOOLBAR_ACTIONS.actionPolling);

        const { batchId } = data;
        notificationService.pollUploadEntityView(
            {
                batchId,
                route: route ?? '',
                onFinished: () => setLastPerformedAction(TOOLBAR_ACTIONS.updateView),
            },
            true
        );
    };

    const resetView = () => {
        setSelectedView({});
        changeView();
    };

    const getAllViews = useCallback(async () => {
        if (!shouldGetAllViews && views?.length) return;

        const [response, error] = await EntityViewApi.getAllViews(userPermId);

        if (error) {
            utils.showNotificationsMessage({
                messageText: 'BatchScreening.generalError.message',
                messageType: 'system-error',
            });
            return;
        }

        // When the selected view is not in the list we should select the default view
        if (
            lastSelectedView?.length &&
            lastSelectedView !== MULTIPLE_ENTITIES_NONE_SELECTED_VIEW &&
            !response.find((view) => view.id === lastSelectedView)
        ) {
            setSelectedView({});
            changeView();
        }

        setViews && setViews(response);

        dispatch(mainActions.setShouldGetAllViews(false));
    }, [lastSelectedView, setSelectedView, changeView, shouldGetAllViews]);

    return {
        selectedView,
        setSelectedView,
        getAllViews,
        changeView,
        createView,
        deleteView,
        resetView,
        updateView,
    };
};

export default useViews;
