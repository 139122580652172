import React from 'react';
import { injectIntl } from 'react-intl';
import formatRichMessage from '@utils/formatRichMessage';

const SaveSearchButton = ({ onClickHandler, isButtonDisabled, isSavingSearch, intl }) => {
    return (
        <button
            className="button-primary-lg save-search"
            id="save-search-button"
            data-track="snapshot-page-save-search-button"
            data-testid="save-search-button"
            onClick={() => onClickHandler()}
            disabled={isButtonDisabled}
        >
            {!isSavingSearch ? <span className="la-SaveUpdates" /> : <span className="dots-circle-spinner loading" />}
            {formatRichMessage(
                { id: `General_CoreFunctionality_UIText_general.${!isSavingSearch ? 'saveSearch' : 'savingChanges'}` },
                intl
            )}
        </button>
    );
};

export default injectIntl(SaveSearchButton);
