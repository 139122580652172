import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import InputComponent from './InputComponent';
import formatRichMessage from '@utils/formatRichMessage';

class CheckboxWrapperComponent extends Component {
    handleChange = (options, option, e) => {
        // for now just pass the handler up, any transformations should happen here
        this.props.onChange(options, option, e);
    };

    render() {
        const options = this.props.options.map((option) => ({
            ...option,
            label: formatRichMessage({ id: 'Delivery.options.' + option.key }, this.props.intl),
            type: 'checkbox',
        }));

        return (
            <div className={this.props.className} data-testid={'checkbox-wrapper-component'}>
                <InputComponent
                    {...this.props}
                    options={options}
                    onChange={this.handleChange}
                    onSubCategoriesChange={this.props.onSubCategoriesChange}
                    fieldId={'delivery-options'}
                />
            </div>
        );
    }
}

export default injectIntl(CheckboxWrapperComponent);
