//@flow
import * as React from 'react';

import { UPDATE_TITLE } from '@ReportBuilder/ReportBuilderService';
import EditableText from '@reusable/EditableText/EditableText';
import ReactTooltip from 'react-tooltip';
import { injectIntl } from 'react-intl';
import { withShowForCombinedReports } from '@ReportBuilder/HOC/withReportHoc';
import { compose } from 'redux';
import utils from '@utils/utilities';
import formatRichMessage from '@utils/formatRichMessage';
import type { ReportFileNameValidation } from '@utils/flow/utilities.type.guards';

type Props = {
    updateReportProperty: ({
        reportId: string,
        reportProperty: string,
        propertyValue: mixed,
        isSelected?: boolean,
    }) => void,
    id: string,
    index: number,
    title: string,
    reportType: string,
    hasUpdates: ?boolean,
    isSelected?: boolean,
    toolTipData: ReportTitleTooltipData | string,
    countMessage: string,
};

type InjectedProps = {
    index: number,
    +intl: Object,
};

export type ReportTitleTooltipData = {
    message: string,
    terms: {
        combinedReportTitle: string,
        childReportsTitles: string,
    },
};

type TitleValidationResult = {|
    isValid: boolean,
    errorMessage: string,
|};

const ReportEditableTitle = (props: Props & InjectedProps): React$Element<'div'> => {
    const {
        updateReportProperty,
        id,
        index,
        title,
        reportType,
        hasUpdates,
        isSelected,
        toolTipData,
        intl,
        countMessage,
    } = props;

    const saveTitle = (reportId: string, reportTitle: string): void => {
        updateReportProperty({
            reportId,
            reportProperty: UPDATE_TITLE,
            propertyValue: reportTitle,
            isSelected: isSelected,
        });
    };

    const validateReportTitle = (reportTitle: string): TitleValidationResult => {
        let validationResult: TitleValidationResult = {
            isValid: true,
            errorMessage: '',
        };

        const reportTitleValidation: ReportFileNameValidation = utils.isReportFileNameValid(reportTitle);

        if (reportTitleValidation.invalidLength)
            validationResult.errorMessage = formatRichMessage(
                { id: 'DownloadReportFileNameValidation.CharactersLimitCount' },
                intl,
                { count: reportTitleValidation.maxLength }
            );

        if (reportTitleValidation.invalidCharacters || reportTitleValidation.currentLength === 0)
            validationResult.errorMessage = formatRichMessage(
                { id: 'DownloadReportFileNameValidation.ValidReportTitle' },
                intl
            );

        validationResult.isValid = !(reportTitleValidation.invalidCharacters || reportTitleValidation.invalidLength);

        return validationResult;
    };

    return (
        <div className={'report-title'}>
            <EditableText
                index={index}
                title={title}
                saveText={(title) => saveTitle(id, title)}
                validateTextInput={validateReportTitle}
                qaId={'qa-report-title_' + index}
                className={'report-list-title'}
                toolTipData={toolTipData}
                countMessage={countMessage}
            />
            <WrappedInfoIcon reportType={reportType} hasUpdates={hasUpdates} index={index} />
        </div>
    );
};

const InfoIcon = (props: InjectedProps): React$Element<typeof React.Fragment> => {
    const { index, intl } = props;

    return (
        <React.Fragment>
            <ReactTooltip id={`report-info-${index}`} type="light" border={true} effect="solid" className="tooltips" />
            <span
                className={`la-InfoHover information-icon`}
                data-tip={formatRichMessage({ id: 'Report.combined.changeDetected' }, intl)}
                data-for={`report-info-${index}`}
                data-testid="info-icon"
            />
        </React.Fragment>
    );
};

type RequiredWithHasUpdatesProps = {
    hasUpdates: boolean,
};
//probably will reuse - not sure yet
export const withHasUpdates = <Props: {} & RequiredWithHasUpdatesProps>(
    Component: React.AbstractComponent<Props>
): React.AbstractComponent<Props> | null => {
    const showForUpdates = (props: Props & RequiredWithHasUpdatesProps) => {
        return props.hasUpdates ? <Component {...props} /> : null;
    };

    showForUpdates.displayName = `showForUpdates(${utils.getDisplayName(Component)})`;

    return showForUpdates;
};

const WrappedInfoIcon = compose(injectIntl, withShowForCombinedReports, withHasUpdates)(InfoIcon);

export { ReportEditableTitle as TestReportEditableTitle };
export { InfoIcon as TesInfoIcon };

export default (injectIntl(ReportEditableTitle): React$StatelessFunctionalComponent<Props>);
