import * as React from 'react';

const DonutChartLabel = ({ risk, label, percentage }) => {
    return (
        <div className="donut-graph-label" data-track="donut-graph-label">
            <div className="donut-graph-negativity-risk-label" data-track="donut-graph-negativity-risk-label">
                <span
                    className={`donut-graph-negativity-risk-label__dot ${risk}`}
                    data-track={`donut-graph-negativity-risk-label__dot ${risk}`}
                />
                <span
                    className="donut-graph-negativity-risk-label__risk"
                    data-track="donut-graph-negativity-risk-label__risk"
                >
                    {label}
                </span>
            </div>
            <span className="donut-graph-label__percent" data-track="donut-graph-label__percent">
                {percentage}%
            </span>
        </div>
    );
};

export default DonutChartLabel;
