import React, { Fragment } from 'react';
import { MODAL_TYPE, UBO_MAIN_CATEGORY, HEADER_HEIGHT } from '@constants';
import GoogleTranslateWithoutI18n from '@reusable/GoogleTranslate/GoogleTranslate';
import ReactTooltip from 'react-tooltip';
import { AddToReportBtnElastic, AddToReportBtnUbo } from '@reusable/Buttons/redux/AddToReportBtn.containers';
import { hashHistory } from 'react-router';
import { injectIntl } from 'react-intl';
import { withAppContext } from '@utils/contexts';
import ArticleNavigation from '@reusable/ArticleNavigation/ArticleNavigation';
import reduxStore from '@reduxStore';
import utils from '@utils/utilities';
import formatRichMessage from '@utils/formatRichMessage';
import HitsNavigator from './HitsNavigator';
import stickybits from 'stickybits';
import { isEmpty } from 'lodash';
import ArticleActionWrapper from './ArticleActionWrapper';

class SingleArticleHeader extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isTitleTruncated: false,
            didTooltipRebuild: false,
            actionButtonsDisabled: false,
        };
    }

    componentDidMount() {
        window.addEventListener('resize', this.rebuildToolTip);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.rebuildToolTip);
    }

    componentDidUpdate(prevProps) {
        if (this.props.article.id !== prevProps.article.id) {
            this.rebuildToolTip();
        } else {
            if (this.state.didTooltipRebuild === false) {
                this.rebuildToolTip();
                this.setState({ didTooltipRebuild: true });
            }
        }

        // pin action bar to the top of the window
        if (this.props.article.content || this.props.article.nodes) {
            stickybits('.single-article-header-actions-bar', {
                stickyBitStickyOffset: utils.calculateMainBannersHeight() + HEADER_HEIGHT,
                useStickyClasses: true,
            });
        }
    }

    rebuildToolTip = () => {
        let isTitleTruncated = utils.isHtmlTextTruncated(document.getElementById('truncated-title'));
        this.setState({ isTitleTruncated });
        ReactTooltip.rebuild();
    };

    render() {
        const shouldDisplayHits =
            !this.props.shouldHideHitsNavigator &&
            this.props.hits &&
            this.props.hits.length > 0 &&
            !this.state.actionButtonsDisabled;

        return (
            <Fragment>
                <div className="single-article-header-container">
                    {!this.state.actionButtonsDisabled && (
                        <ReactTooltip
                            id="action-tips"
                            event="mouseenter"
                            eventOff="mouseleave"
                            type="light"
                            border={true}
                            effect="solid"
                            className="tooltips notranslate"
                            place="bottom"
                        />
                    )}
                    {this.state.isTitleTruncated && (
                        <ReactTooltip
                            id="full-title"
                            event="mouseenter"
                            eventOff="mouseleave"
                            place="bottom"
                            type="light"
                            border={true}
                            effect="solid"
                            className="tooltips notranslate"
                        />
                    )}
                    <div className="single-article-header">
                        <div className="single-article-header-title">
                            {this.props.query && !this.props.internalLink && (
                                <button
                                    className="icon-box"
                                    data-tip={formatRichMessage(
                                        { id: 'ManageAlerts.backToResultsButton' },
                                        this.props.intl
                                    )}
                                    data-for="action-tips"
                                    onClick={() => {
                                        hashHistory.go(-1);
                                    }}
                                >
                                    <div className="la-TriangleLeft" />
                                </button>
                            )}
                            {this.props.internalLink && (
                                <button
                                    className="icon-box"
                                    data-tip={formatRichMessage(
                                        { id: 'SingleArticleHeader.backToOriginalDoc' },
                                        this.props.intl
                                    )}
                                    data-for="action-tips"
                                    onClick={this.props.backToOriginalDoc}
                                >
                                    <div className="la-TriangleLeft" />
                                </button>
                            )}
                            <h1
                                className="truncated-title"
                                id="truncated-title"
                                data-testid="truncated-title-test"
                                data-tip={this.state.isTitleTruncated ? this.props.article.unhighlightedTitle : null}
                                data-for="full-title"
                                dangerouslySetInnerHTML={{
                                    __html: this.state.actionButtonsDisabled
                                        ? this.props.article.unhighlightedTitle
                                        : this.props.article.title || this.props.title,
                                }}
                            />
                        </div>
                    </div>
                </div>
                {!this.props.hasError && !isEmpty(this.props.article) && (
                    <div className="single-article-header-actions-bar">
                        <div className="single-article-header-content">
                            <div className="single-article-header-actions">
                                {this.state.actionButtonsDisabled && (
                                    <ReactTooltip
                                        id="disabled-action"
                                        multiline={true}
                                        place="bottom"
                                        type="light"
                                        border={true}
                                        effect="solid"
                                        event="mouseenter"
                                        eventOff="mouseleave"
                                        className="tooltips create-alert-disabled"
                                    />
                                )}
                                <ArticleActionWrapper isDisabled={this.state.actionButtonsDisabled}>
                                    <button
                                        data-tip={formatRichMessage(
                                            { id: 'SingleArticleHeader.printDocument' },
                                            this.props.intl
                                        )}
                                        data-for="action-tips"
                                        className="icon-box"
                                        value={MODAL_TYPE.ELASTIC_PRINT_DOCUMENT}
                                        onClick={this.props.showPopup}
                                        disabled={this.state.actionButtonsDisabled}
                                    >
                                        <div className="la-Print" />
                                    </button>
                                </ArticleActionWrapper>
                                <ArticleActionWrapper isDisabled={this.state.actionButtonsDisabled}>
                                    <button
                                        data-tip={formatRichMessage(
                                            { id: 'SingleArticleHeader.emailDocument' },
                                            this.props.intl
                                        )}
                                        data-for="action-tips"
                                        className="icon-box"
                                        value={MODAL_TYPE.ELASTIC_EMAIL_DOCUMENT}
                                        onClick={this.props.showPopup}
                                        disabled={
                                            (this.props.context && this.props.context.isUserAnonymized) ||
                                            this.state.actionButtonsDisabled
                                        }
                                    >
                                        <div className="la-Email" />
                                    </button>
                                </ArticleActionWrapper>
                                <ArticleActionWrapper isDisabled={this.state.actionButtonsDisabled}>
                                    <button
                                        data-tip={formatRichMessage(
                                            { id: 'SingleArticleHeader.downloadDocument' },
                                            this.props.intl
                                        )}
                                        data-for="action-tips"
                                        className="icon-box"
                                        disabled={this.state.actionButtonsDisabled}
                                        value={MODAL_TYPE.ELASTIC_DOWNLOAD_DOCUMENT}
                                        data-testid={MODAL_TYPE.ELASTIC_DOWNLOAD_DOCUMENT}
                                        onClick={this.props.showPopup}
                                    >
                                        <div className="la-Download" />
                                    </button>
                                </ArticleActionWrapper>
                                {shouldDisplayHits && <HitsNavigator hits={this.props.hits} />}
                                {this.props.query && this.props.article.articleType !== UBO_MAIN_CATEGORY ? (
                                    <AddToReportBtnElastic
                                        article={this.props.article}
                                        articleType={this.props.article.articleType}
                                    />
                                ) : null}
                                {this.props.query && this.props.article.articleType === UBO_MAIN_CATEGORY ? (
                                    <AddToReportBtnUbo
                                        article={this.props.article}
                                        articleType={this.props.article.articleType}
                                    />
                                ) : null}
                            </div>
                            <div className="action-right">
                                <div className="google-translate-dropdown">
                                    {(this.props.article.title || this.props.title) && !reduxStore.getState().user.isAnonymized && <GoogleTranslateWithoutI18n />}
                                </div>
                                {!this.props.internalLink && <ArticleNavigation />}
                            </div>
                        </div>
                    </div>
                )}
            </Fragment>
        );
    }
}

export default injectIntl(withAppContext(SingleArticleHeader));
