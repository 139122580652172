import React, { Fragment } from 'react';
import { withHideOnDisabledProp } from '@utils/contexts';
import formatRichMessage from '@utils/formatRichMessage';
import ReactTooltip from 'react-tooltip';
import { FormattedMessage, injectIntl } from 'react-intl';
import EditSubcategoriesModal from './EditSubcategoriesModal';
import EditSubcategoriesDropdown from './EditSubcategoriesDropdown';
import { CATEGORY_NAMES, NEGATIVE_NEWS_LANGUAGES, EDIT_SUBCATEGORIES_DISPLAY_MODE } from '@constants';

class EditSubcategories extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showModal: false,
        };

        this.onSave = this.onSave.bind(this);
        this.getSubSourcesRelatedMessages = this.getSubSourcesRelatedMessages.bind(this);
    }

    componentDidMount() {
        this.toggleClickOutsideListener(true);
    }

    componentDidUpdate() {
        ReactTooltip.rebuild();
    }

    componentWillUnmount() {
        this.toggleClickOutsideListener(false);
    }

    toggleClickOutsideListener(shouldAddListener) {
        if (this.props.displayMode !== EDIT_SUBCATEGORIES_DISPLAY_MODE.DROPDOWN) return;

        const method = shouldAddListener ? 'addEventListener' : 'removeEventListener';
        document[method]('mousedown', this.handleClickOutside);
    }

    handleClickOutside = (event) => {
        if (!event) return;

        const validClassnames = [
            'la-TriangleDown',
            'closed',
            'user-preference-checkbox',
            'preference-checkbox-label',
            'edit-subcategories-list-item',
            'selected-sources-dropdown-content',
        ];
        const validId = `edit-subcategories-${this.props.source.key}`;

        const { className: elementClassname, id: elementId } = event.target;

        const isElementPartOfDropdown = validClassnames.includes(elementClassname) || validId === elementId;

        if (!isElementPartOfDropdown) {
            setTimeout(() => {
                this.toggleModal(false);
            }, 250);
        }
    };

    toggleModal = (showModal, event) => {
        if (event) event.preventDefault();
        this.setState({ showModal });
    };

    onSave(parentKey, subSources) {
        this.props.onSave(parentKey, subSources);
    }

    getSubSourcesRelatedMessages(source) {
        let subSourcesCount, subSourcesCountMessage, toolTipData;
        switch (source.key) {
            case CATEGORY_NAMES.NEGATIVE_NEWS:
                if (!source.children || !source.children.length) break;

                subSourcesCount = {
                    checked: source.children.filter(
                        (subSource) => subSource.name !== NEGATIVE_NEWS_LANGUAGES['multiple'].value && subSource.checked
                    ).length,
                    all: source.children.filter(
                        (subSource) => subSource.name !== NEGATIVE_NEWS_LANGUAGES['multiple'].value
                    ).length,
                };

                subSourcesCountMessage =
                    subSourcesCount && subSourcesCount.checked < subSourcesCount.all
                        ? formatRichMessage(
                              { id: 'General.categoryName.label.ContentLanguagesCount' },
                              this.props.intl,
                              { count: subSourcesCount.checked }
                          )
                        : formatRichMessage({ id: 'General.categoryName.label.AllContentLanguages' }, this.props.intl);

                toolTipData = source.children
                    .filter((child) => child.checked)
                    .map((child) => child.name)
                    .join(', ');

                break;

            case CATEGORY_NAMES.LAW_SOURCES:
                if (!source.children || !source.children.length) break;

                subSourcesCount = {
                    checked: source.children.filter((subSource) => subSource.checked).length,
                    all: source.children.length,
                };

                subSourcesCountMessage =
                    subSourcesCount && subSourcesCount.checked < subSourcesCount.all
                        ? formatRichMessage({ id: 'General.categoryName.label.count.sources' }, this.props.intl, {
                              count: subSourcesCount.checked,
                          })
                        : formatRichMessage({ id: 'General.categoryName.label.all.sources' }, this.props.intl);

                toolTipData = source.children
                    .filter((child) => child.checked)
                    .map((child) => child.name)
                    .join(', ');
                break;

            default:
                subSourcesCount = null;
                subSourcesCountMessage = null;
                toolTipData = null;
        }

        return {
            subSourcesCount,
            subSourcesCountMessage,
            toolTipData,
        };
    }

    render() {
        const { subSourcesCount, subSourcesCountMessage, toolTipData } = this.getSubSourcesRelatedMessages(
            this.props.source
        );

        return (
            <Fragment>
                {subSourcesCountMessage &&
                    subSourcesCount &&
                    subSourcesCount.checked > 0 &&
                    this.props.displayMode === EDIT_SUBCATEGORIES_DISPLAY_MODE.MODAL && (
                        <span
                            data-for={'edit-subcategories-count-info'}
                            data-tip={toolTipData}
                            data-testid={'edit-subcategories-count-info'}
                            data-count={subSourcesCountMessage}
                        >
                            {' '}
                            - <span dangerouslySetInnerHTML={{ __html: subSourcesCountMessage }} />
                        </span>
                    )}
                <ReactTooltip
                    id="edit-subcategories-count-info"
                    type="light"
                    border={true}
                    effect="solid"
                    className="tooltips"
                    data-testid="edit-subcategories-toolTip"
                />
                <a
                    href="#/"
                    className="edit-subcategories"
                    onClick={(e) => this.toggleModal(!this.state.showModal, e)}
                    data-testid="edit-subcategories-link"
                    id={`edit-subcategories-${this.props.source.key}`}
                >
                    <FormattedMessage id={'General_CoreFunctionality_UIText_general.edit'} />
                </a>
                {this.state.showModal && this.props.displayMode === EDIT_SUBCATEGORIES_DISPLAY_MODE.MODAL && (
                    <EditSubcategoriesModal
                        closeButtonHandler={() => this.toggleModal(false)}
                        source={this.props.source}
                        onSave={this.onSave}
                    />
                )}
                {this.state.showModal && this.props.displayMode === EDIT_SUBCATEGORIES_DISPLAY_MODE.DROPDOWN && (
                    <EditSubcategoriesDropdown source={this.props.source} onSave={this.onSave} />
                )}
            </Fragment>
        );
    }
}

export default withHideOnDisabledProp(injectIntl(EditSubcategories));
