import React, { PureComponent } from 'react';
import { cloneDeep } from 'lodash';

const GENERIC_PILL_WIDTH = 115;

export default class LoadingPillList extends PureComponent {
    constructor(props) {
        super(props);

        this.state = { showAll: false };
        this.pillItems = [];
    }

    componentDidMount() {
        // create a starting pills count and an array to map for rendering
        if (!this.state.visibleItemCount && this.props.pillListContainerWidth) {
            let pillListContainerWidth = this.props.pillListContainerWidth;
            let headerWidth = this.props.pillListHeaderWidth;

            if (headerWidth) {
                pillListContainerWidth -= headerWidth;
            }

            let visibleItemCount = [];

            while (pillListContainerWidth > 0) {
                if (pillListContainerWidth - GENERIC_PILL_WIDTH > 0) {
                    visibleItemCount.push(GENERIC_PILL_WIDTH);
                }
                pillListContainerWidth -= GENERIC_PILL_WIDTH;
            }
            this.setState({
                visibleItemCount,
                pillListWidth: pillListContainerWidth,
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        // remove any additional pills after style is applied (margins and/or padding might interfere with previous counts)
        if (this.state.visibleItemCount && this.props.pillListContainerWidth && !prevState.pillListWidth) {
            if (this.pillItems) {
                let pillsTotalWidth = 0;
                let visibleItemCount = cloneDeep(this.state.visibleItemCount);
                let pillListContainerWidth = this.props.pillListContainerWidth;
                let headerWidth = this.props.pillListHeaderWidth;

                if (headerWidth) {
                    pillListContainerWidth -= headerWidth;
                }

                this.pillItems.forEach((item) => {
                    if (item) {
                        pillsTotalWidth += item.clientWidth;
                        if (pillsTotalWidth > pillListContainerWidth - item.clientWidth) {
                            visibleItemCount.pop();
                        }
                    }
                });
                this.setState({
                    visibleItemCount,
                    pillListWidth: pillListContainerWidth - 10,
                });
            }
        }
    }

    render() {
        const pillListStyle = this.state.pillListWidth
            ? {
                  width: this.state.pillListWidth,
              }
            : {};

        return (
            <div className={'pill-list-loading '} style={pillListStyle}>
                {this.state.visibleItemCount
                    ? this.state.visibleItemCount.map((item, key) => (
                          <div
                              className="pill-list-loading__item"
                              key={key}
                              style={{ width: item }}
                              ref={(ref) => (this.pillItems[key] = ref)}
                          ></div>
                      ))
                    : null}
            </div>
        );
    }
}
