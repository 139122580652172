import { BREADCRUMBS, CATEGORY_NAMES, BREADCRUMBS_PREV_PATH, LAUNCHED_SEARCH_FROM } from '@constants';
import categoryUtils from './categoryUtils';
import utils from '@utils/utilities';

export default class BreadCrumbsModel {
    constructor(builder) {
        this.breadcrumbs = builder.breadcrumbs;
    }
    static get Builder() {
        class Builder {
            constructor(page) {
                this.page = page;
            }

            setProperty(propertyName, value) {
                this[propertyName] = value;
                return this;
            }

            build() {
                this.breadcrumbs = [];
                switch (this.page) {
                    case BREADCRUMBS.SNAPSHOT:
                        this.breadcrumbs = this.breadcrumbsForSnapshot();
                        break;
                    case BREADCRUMBS.RESULTS:
                        this.breadcrumbs = this.breadcrumbsForResults();
                        break;
                    case BREADCRUMBS.DOCUMENT:
                        this.breadcrumbs = this.breadcrumbsForDocument();
                        break;
                    case BREADCRUMBS.REPORT_BUILDER:
                        this.breadcrumbs = this.breadcrumbsForReportBuilder();
                        break;
                    case BREADCRUMBS.PREFERENCES:
                        this.breadcrumbs = this.breadcrumbsForPreferences();
                        break;
                    case BREADCRUMBS.ADMIN_PREFERENCES:
                        this.breadcrumbs = this.breadcrumbsForAdminPreferences();
                        break;
                    case BREADCRUMBS.HISTORY:
                        this.breadcrumbs = this.breadcrumbsForHistory();
                        break;
                    case BREADCRUMBS.CREATE_ALERT:
                        this.breadcrumbs = this.breadcrumbsForCreateAlert();
                        break;
                    case BREADCRUMBS.MANAGE_ALERTS:
                        this.breadcrumbs = this.breadcrumbsForManageAlerts();
                        break;
                    case BREADCRUMBS.EDIT_ALERT:
                        this.breadcrumbs = this.breadcrumbsForEditAlert();
                        break;
                    case BREADCRUMBS.PUBLIC_RECORDS:
                        this.breadcrumbs = this.breadcrumbsForPublicRecords();
                        break;
                    case BREADCRUMBS.BATCH_SCREENING:
                        this.breadcrumbs = this.breadcrumbsForEntityView();
                        break;
                    default:
                        this.breadcrumbs = [];
                        break;
                }
                return new BreadCrumbsModel(this);
            }

            breadcrumbsForPreferences() {
                return [
                    { label: 'BREADCRUMBS.home', url: '/' },
                    { label: 'BREADCRUMBS.preferences', url: '#' },
                ];
            }

            breadcrumbsForAdminPreferences() {
                return [
                    { label: 'BREADCRUMBS.home', url: '/' },
                    { label: 'BREADCRUMBS.adminPreferences', url: '#' },
                ];
            }

            breadcrumbsForHistory() {
                return [
                    { label: 'BREADCRUMBS.home', url: '/' },
                    { label: 'BREADCRUMBS.history', url: '#' },
                ];
            }

            breadcrumbsForPublicRecords() {
                return [
                    { label: 'BREADCRUMBS.home', url: '/' },
                    { label: 'BREADCRUMBS.publicRecords', url: '#' },
                ];
            }

            breadcrumbsForManageAlerts() {
                let breadcrumbs = [];

                if (this.isNewAlert && this.searchType && this.q && this.list && this.category) {
                    breadcrumbs.push({
                        label: 'BREADCRUMBS.home',
                        url: '/',
                    });
                    if (this.isSnapshotVisible) {
                        breadcrumbs.push({
                            label: 'BREADCRUMBS.snapshot',
                            url: '/main-search?searchType=' + this.searchType + '&q=' + this.q,
                        });
                    }
                    breadcrumbs.push({
                        label: 'BREADCRUMBS.results',
                        url:
                            '/main-search?searchType=' +
                            this.searchType +
                            '&q=' +
                            this.q +
                            '&list=' +
                            this.list +
                            '&category=' +
                            this.category,
                    });
                    breadcrumbs.push({
                        label: 'BREADCRUMBS.manageAlerts',
                        url: '/',
                    });
                } else {
                    breadcrumbs.push({
                        label: 'BREADCRUMBS.home',
                        url: '/',
                    });
                    breadcrumbs.push({
                        label: 'BREADCRUMBS.manageAlerts',
                        url: '/',
                    });
                }
                return breadcrumbs;
            }

            breadcrumbsForEditAlert() {
                let breadcrumbs = [];

                breadcrumbs.push({
                    label: 'BREADCRUMBS.home',
                    url: '/',
                });

                if (this.searchQuery && this.searchQueryType) {
                    breadcrumbs.push({
                        label: 'BREADCRUMBS.manageAlerts',
                        url: '/manage-alerts',
                    });
                }

                breadcrumbs.push({
                    label: 'BREADCRUMBS.editAlert',
                    url: '/',
                });

                return breadcrumbs;
            }

            breadcrumbsForReportBuilder() {
                let breadcrumbs = [];
                if (this.category && this.reportId && this.searchQuery) {
                    let breadcrumbLabel = 'BREADCRUMBS.snapshot.' + this.category;

                    breadcrumbs.push({ label: 'BREADCRUMBS.home', url: '/' });
                    if (this.prevPath === BREADCRUMBS_PREV_PATH.SCREENING_PAGE) {
                        breadcrumbs.push({
                            label: 'BREADCRUMBS.batchScreening',
                            url: '/screening',
                        });
                    }
                    if (this.isSnapshotVisible === true) {
                        breadcrumbs.push({
                            label: 'BREADCRUMBS.snapshot',
                            url: '/main-search?searchType=' + this.searchQueryType + '&q=' + this.searchQuery,
                        });
                    }
                    breadcrumbs.push({
                        label: breadcrumbLabel,
                        url:
                            '/main-search?searchType=' +
                            this.searchQueryType +
                            '&q=' +
                            this.searchQuery +
                            '&list=true&category=' +
                            this.category,
                    });
                    breadcrumbs.push({
                        label: 'BREADCRUMBS.reportBuilder',
                        url: '#',
                    });
                } else {
                    breadcrumbs.push({
                        label: 'BREADCRUMBS.home',
                        url: '/',
                    });
                    breadcrumbs.push({
                        label: 'BREADCRUMBS.reportBuilder',
                        url: '/',
                    });
                }
                return breadcrumbs;
            }

            breadcrumbsForDocument() {
                let breadcrumbs = [];

                if (this.searchQueryType && this.searchQuery && this.articleType) {
                    let breadcrumbLabel = 'BREADCRUMBS.snapshot.' + this.articleType;
                    breadcrumbs.push({ label: 'BREADCRUMBS.home', url: '/' });
                    switch (this.prevPath) {
                        case BREADCRUMBS_PREV_PATH.HISTORY_PAGE:
                            breadcrumbs.push({
                                label: 'BREADCRUMBS.history',
                                url: '/history',
                            });
                            break;

                        case BREADCRUMBS_PREV_PATH.MANAGE_ALERTS_PAGE:
                            breadcrumbs.push({
                                label: 'BREADCRUMBS.manageAlerts',
                                url: '/manage-alerts',
                            });
                            break;

                        case BREADCRUMBS_PREV_PATH.SCREENING_PAGE:
                            breadcrumbs.push({
                                label: 'BREADCRUMBS.batchScreening',
                                url: '/screening',
                            });
                            break;

                        default:
                            break;
                    }

                    if (this.isSnapshotVisible) {
                        breadcrumbs.push({
                            label: 'BREADCRUMBS.snapshot',
                            url: '/main-search?searchType=' + this.searchQueryType + '&q=' + this.searchQuery,
                        });
                    }

                    if (!this.internalLink) {
                        breadcrumbs.push({
                            label: breadcrumbLabel,
                            url:
                                '/main-search?searchType=' +
                                this.searchQueryType +
                                '&q=' +
                                this.searchQuery +
                                '&list=true&category=' +
                                this.articleType,
                        });
                    } else {
                        breadcrumbs.push({
                            label: 'BREADCRUMBS.results',
                            url: `/main-search?q=${this.searchQuery}&category=${this.articleType}&searchType=${this.searchType}&list=true&articleIndex=${this.articleIndex}`,
                        });
                    }

                    breadcrumbs.push({
                        label: 'BREADCRUMBS.document',
                        url: '#',
                    });
                } else {
                    let params = utils.getAllUrlParams();

                    breadcrumbs.push({
                        label: 'BREADCRUMBS.home',
                        url: '/',
                    });

                    if (params['query'] && params['type'] && this.isSnapshotVisible && this.prevPath !== '/' && this.launchedFrom !== null) {
                            breadcrumbs.push({
                                label: 'BREADCRUMBS.snapshot',
                                url: '/main-search?searchType=' + params['type'] + '&q=' + params['query'],
                            });
                    }

                    breadcrumbs.push({
                        label: 'BREADCRUMBS.document',
                        url: '#',
                    });
                }
                return breadcrumbs;
            }

            breadcrumbsForResults() {
                let breadcrumbLabel = 'BREADCRUMBS.snapshot.' + this.articleType;
                if (categoryUtils.isChildOf(CATEGORY_NAMES.CUSTOM_NEWS, this.articleType)) {
                    breadcrumbLabel = this.articleType;
                }

                let breadcrumbs = [];
                breadcrumbs.push({ label: 'BREADCRUMBS.home', url: '/' });
                switch (this.prevPath) {
                    case BREADCRUMBS_PREV_PATH.HISTORY_PAGE:
                        breadcrumbs.push({
                            label: 'BREADCRUMBS.history',
                            url: '/history',
                        });

                        this.isSnapshotVisible &&
                            !this.category &&
                            breadcrumbs.push({
                                label: 'BREADCRUMBS.snapshot',
                                url: '/main-search?searchType=' + this.searchQueryType + '&q=' + this.searchQuery,
                            });
                        break;
                        
                    case BREADCRUMBS_PREV_PATH.MANAGE_ALERTS_PAGE:
                        breadcrumbs.push({
                            label: 'BREADCRUMBS.manageAlerts',
                            url: '/manage-alerts',
                        });

                        this.isSnapshotVisible &&
                            !this.category &&
                            breadcrumbs.push({
                                label: 'BREADCRUMBS.snapshot',
                                url: '/main-search?searchType=' + this.searchQueryType + '&q=' + this.searchQuery,
                            });
                        break;

                    case BREADCRUMBS_PREV_PATH.SCREENING_PAGE:
                        // snapshot should be displayed in the breadcrumbs if it's enabled in the preferences and no category was selected yet OR
                        // if it's enabled and a search was done through the header from the Entity View page
                        const shouldDisplaySnapshotInBreadcrumbs =
                            this.isSnapshotVisible;

                        if (!this.searchFrom || this.searchFrom !== LAUNCHED_SEARCH_FROM.HEADER) {
                            breadcrumbs.push({
                                label: 'BREADCRUMBS.batchScreening',
                                url: '/screening',
                            });
                        }

                        shouldDisplaySnapshotInBreadcrumbs &&
                            breadcrumbs.push({
                                label: 'BREADCRUMBS.snapshot',
                                url: '/main-search?searchType=' + this.searchQueryType + '&q=' + this.searchQuery,
                            });

                        break;
                    case BREADCRUMBS_PREV_PATH.EDIT_SEARCH:
                        if (
                            (!this.searchFrom || this.searchFrom !== LAUNCHED_SEARCH_FROM.HEADER) &&
                            this.launchedFrom === LAUNCHED_SEARCH_FROM.EDIT_SEARCH_PAGE_FROM_SCREENING
                        ) {
                            breadcrumbs.push({
                                label: 'BREADCRUMBS.batchScreening',
                                url: '/screening',
                            });
                        }
                        this.isSnapshotVisible === true &&
                            !this.category &&
                            breadcrumbs.push({
                                label: 'BREADCRUMBS.snapshot',
                                url: '/main-search?searchType=' + this.searchQueryType + '&q=' + this.searchQuery,
                            });
                        break;
                    default:
                        if (this.isSnapshotVisible === true) {
                            breadcrumbs.push({
                                label: 'BREADCRUMBS.snapshot',
                                url: '/main-search?searchType=' + this.searchQueryType + '&q=' + this.searchQuery,
                            });
                        }
                }

                breadcrumbs.push({
                    label: breadcrumbLabel,
                    url: '#',
                });

                return breadcrumbs;
            }

            breadcrumbsForCreateAlert() {
                let breadcrumbs = [];
                if (this.searchQuery) {
                    let breadcrumbLabel = 'BREADCRUMBS.results';

                    breadcrumbs.push({ label: 'BREADCRUMBS.home', url: '/' });

                    if (this.prevPath === BREADCRUMBS_PREV_PATH.SCREENING_PAGE) {
                        breadcrumbs.push({
                            label: 'BREADCRUMBS.batchScreening',
                            url: '/screening',
                        });
                    }

                    if (this.isSnapshotVisible) {
                        breadcrumbs.push({
                            label: 'BREADCRUMBS.snapshot',
                            url: '/main-search?searchType=' + this.searchQueryType + '&q=' + this.searchQuery,
                        });
                    }
                    breadcrumbs.push({
                        label: breadcrumbLabel,
                        url:
                            '/main-search?searchType=' +
                            this.searchQueryType +
                            '&q=' +
                            this.searchQuery +
                            '&list=true&category=' +
                            this.category,
                    });
                    breadcrumbs.push({
                        label: 'BREADCRUMBS.createAlert',
                        url: '#',
                    });
                } else {
                    breadcrumbs.push({
                        label: 'BREADCRUMBS.home',
                        url: '/',
                    });
                    breadcrumbs.push({
                        label: 'BREADCRUMBS.createAlert',
                        url: '/',
                    });
                }
                return breadcrumbs;
            }

            breadcrumbsForSnapshot() {
                let breadcrumbs = [{ label: 'BREADCRUMBS.home', url: '/' }];
                const filterPrevPath = () => {
                    if(this.prevPath && this.prevPath.includes(BREADCRUMBS_PREV_PATH.EDIT_ALERT_SEARCH)) return BREADCRUMBS_PREV_PATH.EDIT_ALERT_SEARCH;
                    return this.prevPath;
                }
                const filteredPrevPath = filterPrevPath();
                switch (filteredPrevPath) {
                    case BREADCRUMBS_PREV_PATH.SCREENING_PAGE:
                        if (!this.searchFrom || this.searchFrom !== LAUNCHED_SEARCH_FROM.HEADER) {
                            breadcrumbs.push({
                                label: 'BREADCRUMBS.batchScreening',
                                url: '/screening',
                            });
                        }
                        break;
                    case BREADCRUMBS_PREV_PATH.EDIT_SEARCH:
                        if (
                            (!this.searchFrom || this.searchFrom !== LAUNCHED_SEARCH_FROM.HEADER) &&
                            this.launchedFrom === LAUNCHED_SEARCH_FROM.EDIT_SEARCH_PAGE_FROM_SCREENING
                        ) {
                            breadcrumbs.push({
                                label: 'BREADCRUMBS.batchScreening',
                                url: '/screening',
                            });
                        }
                        break;
                    case BREADCRUMBS_PREV_PATH.EDIT_ALERT_SEARCH:
                        breadcrumbs.push({
                            label: 'BREADCRUMBS.manageAlerts',
                            url: '/manage-alerts',
                        });
                        break;

                    case BREADCRUMBS_PREV_PATH.MANAGE_ALERTS_PAGE:
                        breadcrumbs.push({
                            label: 'BREADCRUMBS.manageAlerts',
                            url: '/manage-alerts',
                        });
                        break;

                    case BREADCRUMBS_PREV_PATH.HISTORY_PAGE:
                        breadcrumbs.push({
                            label: 'BREADCRUMBS.history',
                            url: '/history',
                        });
                        break;
                    default:
                        break;
                }

                if (this.isSnapshotVisible === true) {
                    breadcrumbs.push({
                        label: 'BREADCRUMBS.snapshot',
                        url: '/main-search?searchType=' + this.searchQueryType + '&q=' + this.searchQuery,
                    });
                }
                return breadcrumbs;
            }

            breadcrumbsForEntityView() {
                return [
                    { label: 'BREADCRUMBS.home', url: '/' },
                    { label: 'BREADCRUMBS.batchScreening', url: '/#' },
                ];
            }
        }

        return Builder;
    }
}
