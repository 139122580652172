import React, { Fragment } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { EXCLUDE, INCLUDE } from '@constants';
import formatRichMessage from '@utils/formatRichMessage';

const SearchWithinResults = (props) => {
    return (
        <Fragment>
            <div className="search-within-radio-buttons">
                <div className="custom-radio">
                    <input
                        type="radio"
                        id="radio-search-include"
                        value={INCLUDE}
                        readOnly="readOnly"
                        checked={props.searchWhitinValue === INCLUDE}
                        onChange={props.changeIncludeExclude}
                    />
                    <label htmlFor="radio-search-include" className="radio-label">
                        <FormattedMessage id="SearchWithin.include" />
                    </label>
                </div>
                <div className="custom-radio">
                    <input
                        type="radio"
                        id="radio-search-exclude"
                        value={EXCLUDE}
                        readOnly="readOnly"
                        checked={props.searchWhitinValue === EXCLUDE}
                        onChange={props.changeIncludeExclude}
                    />
                    <label htmlFor="radio-search-exclude" className="radio-label">
                        <FormattedMessage id="SearchWithin.exclude" />
                    </label>
                </div>
            </div>
            {props.errorMessage && (
                <div className="validationAlertMessage">
                    <FormattedMessage id={props.errorMessage} />
                </div>
            )}
            <div className={`search-within-input-container ${props.errorMessage ? 'text-field-error' : ''}`}>
                <input
                    className="search-within-input"
                    value={props.inputSearchWithin}
                    placeholder={formatRichMessage({ id: 'SearchWithin.placeholder' }, props.intl)}
                    onChange={props.handleIncludeTermsChange}
                    onKeyDown={props.handleKeyPress}
                    type="search"
                />
                <button type="button" className="la-SWResearch" onClick={props.searchIncludeTerms}></button>
            </div>
        </Fragment>
    );
};

export default injectIntl(SearchWithinResults);
