import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withToggleVisibility } from '../HOC/withToggleVisibility';
import { CUSTOM_NEWS_QUERY_TYPES_ID, PERSON_CHECK } from '@constants';
import formatRichMessage from '@utils/formatRichMessage';

class ModalEditCustomNewsQuery extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            changesMade: false,
        };

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.setState({
            changesMade: true,
        });
        this.props.changeCustomNewsQuery(event.target.value);
    }

    render() {
        const isQueryError =
            (this.props.queryError && this.props.isPopupDirty && this.props.query.length <= 0) ||
            this.props.customNewsWildcardError;
        return (
            <div className="popup-modal__edit-negative-custom-news-query">
                <div className="popup-modal__edit-negative-custom-news-query__textarea">
                    {this.props.queryError && this.props.isPopupDirty && (
                        <span className="popup-modal__edit-negative-custom-news-query__textarea__error-message">
                            <FormattedMessage id={'PopupBuilder.body.error.blankQueryAndTitle'} />
                        </span>
                    )}

                    {this.props.customNewsWildcardError && (
                        <span className="popup-modal__edit-negative-custom-news-query__textarea__error-message">
                            <FormattedMessage id={'SearchResults_Notifications.insufficientCharacters'} />
                        </span>
                    )}
                    <textarea
                        value={this.props.query && this.props.query.length > 0 ? this.props.query : ''}
                        onChange={(e) => this.handleChange(e)}
                        readOnly={this.props.isReadOnly}
                        placeholder={
                            this.props.segment === PERSON_CHECK
                                ? formatRichMessage(
                                      { id: 'PopupBuilder.body.placeholder.customNewsQuery.person' },
                                      this.props.intl
                                  )
                                : formatRichMessage(
                                      { id: 'PopupBuilder.body.placeholder.customNewsQuery.company' },
                                      this.props.intl
                                  )
                        }
                        className={isQueryError ? 'textarea-error' : ''}
                    />
                </div>
                {this.props.customNewsCreatedBy === CUSTOM_NEWS_QUERY_TYPES_ID.LN && !this.props.isReadOnly && (
                    <button
                        className="popup-modal__edit-negative-custom-news-query__action"
                        onClick={() => this.props.resetCustomNewsQuery(this.props.customNewsLnDefaultQuery)}
                        disabled={this.props.customNewsLnDefaultQuery === this.props.query}
                        hidden={this.props.customNewsLnDefaultQuery === this.props.query}
                    >
                        <div className="edit-button">
                            <span className="la-Edit" />
                            <div className="edit-label">
                                <FormattedMessage id="PopupBuilder.explanation.customNewsQuery.resetButton" />
                            </div>
                        </div>
                    </button>
                )}
            </div>
        );
    }
}

export default injectIntl(withToggleVisibility(ModalEditCustomNewsQuery));
