/**
 * HEADER MAIN MENU VIEW TEMPLATE
 *
 * @version 0.1
 */

import React from 'react';
import { FormattedMessage } from 'react-intl';

function Template({ messageId }) {
    return (
        <div className="submenu">
            <div className="submenu__link">
                <FormattedMessage id={messageId} />
            </div>
        </div>
    );
}

export default Template;
