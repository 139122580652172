import xhr from '@utils/xhr';

const HistoryApi = {
    getHistory(pageNumber, pageSize, praOn) {
        return xhr
            .get({
                url: '/api/history/',
                data: { pageNumber, pageSize, praOn },
            })
            .then((response) => {
                return response.body;
            });
    },

    getHistoryCount(prsOn) {
        return xhr
            .get({
                url: `/api/history/count?praOn=${prsOn}`,
            })
            .then((response) => {
                return response.body;
            });
    },

    getHistoryData(historyItemId, praOn) {
        return xhr
            .get({
                url: `/api/history/${historyItemId}`,
                data: { praOn },
            })
            .then((response) => {
                return response.body;
            });
    },

    updateHistory(historyData) {
        //remove v2 from payload
        delete historyData.version;
        return xhr
            .post({
                url: '/api/history/',
                data: historyData,
            })
            .then((response) => {
                return response.body;
            });
    },

    downloadHistory(payload, selectedItems, shouldDownloadFullList) {
        let url = shouldDownloadFullList ? '/api/history/download' : '/api/history/download-selected-items';

        selectedItems = shouldDownloadFullList ? [] : selectedItems;

        return xhr
            .post({
                url: url,
                queryParams: payload,
                data: selectedItems,
            })
            .then((response) => {
                return response.body;
            });
    },

    deleteAllHistory() {
        return xhr
            .delete({
                url: '/api/history/',
            })
            .then((response) => {
                return response.body;
            });
    },
};

export default HistoryApi;
