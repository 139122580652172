import {
    SET_ADD_TO_ENTITY_VIEW,
    SET_IS_SAVING_SEARCH,
    SET_MULTIPLE_ENTITIES_PAGE_SPINNER,
    SET_SEARCH_POSTFILTERS_CHANGED,
    SHOW_SAVE_SEARCH_MODAL,
    TOGGLE_DOCUMENTS_LOADING_STATUS,
    TOGGLE_SEARCH,
} from './SearchStatus.action';

function toggleSearchState(state = {}, action) {
    switch (action.type) {
        case TOGGLE_SEARCH:
            return {
                ...state,
                isSearchComplete: action.payload,
            };

        case TOGGLE_DOCUMENTS_LOADING_STATUS:
            return {
                ...state,
                areDocumentsLoading: action.payload,
            };
        case SET_IS_SAVING_SEARCH:
            return {
                ...state,
                isSavingSearch: action.payload,
            };

        case SET_ADD_TO_ENTITY_VIEW:
            return {
                ...state,
                isAddingToEntityView: action.payload,
            };

        case SHOW_SAVE_SEARCH_MODAL:
            return {
                ...state,
                showSaveSearchModalBeforeLeave: action.payload,
            };
        case SET_MULTIPLE_ENTITIES_PAGE_SPINNER:
            return {
                ...state,
                isMultipleEntitiesPageSpinnerVisible: action.payload,
            };
        case SET_SEARCH_POSTFILTERS_CHANGED:
            return {
                ...state,
                arePostFiltersChanged: action.payload,
            };
        default:
            return state;
    }
}
export default toggleSearchState;
