import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { Link } from 'react-router';
import FocusTrap from 'focus-trap-react';

const DROPDOWN_ALIGN_RIGHT = 'right';
const DROPDOWN_ALIGN_LEFT = 'left';

export default class Dropdown extends React.Component {
    static propTypes = {
        position: PropTypes.shape({
            x: PropTypes.number,
            y: PropTypes.number,
        }),
        align: PropTypes.oneOf([DROPDOWN_ALIGN_RIGHT, DROPDOWN_ALIGN_LEFT]),
        className: PropTypes.string,
        onClose: PropTypes.func,
        positionAuto: PropTypes.bool,
        bounds: PropTypes.object, // needed to snap on edge of trigger component
        callback: PropTypes.func
    };

    static defaultProps = {
        position: { x: 0, y: 0 },
        align: DROPDOWN_ALIGN_RIGHT,
        closeOnMouseLeave: true,
        shouldUseFocusTrap: false,
        shouldExitOnEscape: true,
        positionAuto: false,
    };

    constructor(props) {
        super(props);

        this.node = null;

        this.state = {
            dropdownWidth: 0,
        };

        this.handleMouseLeave = this.handleMouseLeave.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleMouseDown, false);
        this.props.shouldExitOnEscape && document.addEventListener('keydown', this.handleKeyPress, false);
        this.setState({ dropdownWidth: this.node.offsetWidth });
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleMouseDown, false);
        this.props.shouldExitOnEscape && document.addEventListener('keydown', this.handleKeyPress, false);
    }

    /**
     * Method that checks if the clicks are inside the component, if not, hide popover
     * @param e
     */
    handleMouseDown = (e) => {
        if (!this.node.contains(e.target)) {
            this.handleClose();

            if (!e.target.className.includes('la-TriangleUp') && !e.target.className.includes('la-Redo')) {
                this.props.callback && this.props.callback();
            }
        }
    };

    /**
     * Closes the popover on escape.
     * @param  {KeyboardEvent} event   The key down event.
     */
    handleKeyPress = (e) => {
        if (e.key === 'Escape' || e.key === 'Esc' || e.keyCode === 27) {
            this.handleClose();
        }
    };

    handleClose() {
        this.props.onClose();
    }

    handleMouseLeave() {
        this.props.closeOnMouseLeave && this.handleClose();
    }

    /**
     * Calculates the popover position relative to position prop (if snap is enabled, will take bounds into account)
     * @returns {{top: number, left: *}}
     */
    getDropdownStyle() {
        let finalStyle = {};
        if (
            this.props.positionAuto &&
            this.node &&
            window.innerHeight - (this.props.bounds.top + this.node.offsetHeight) < 0
        ) {
            // dropdown would overflow the viewport at the bottom - display it upward, but take into consideration the top bar which is 80px plus a safe margin of 20px
            finalStyle = { bottom: window.innerHeight - this.props.bounds.top + this.props.bounds.height - 100 };

        } else {
            finalStyle = { top: this.props.bounds.top + this.props.bounds.height };
        }

        if (this.props.align === DROPDOWN_ALIGN_RIGHT) {
            return {
                ...finalStyle,
                right: window.innerWidth - this.props.bounds.left - this.props.bounds.width,
            };
        } else {
            return {
                ...finalStyle,
                left: this.props.bounds.left,
            };
        }
    }

    render() {
        const alignmentClass = this.props.align === DROPDOWN_ALIGN_LEFT ? 'left-align' : 'right-align';
        const customClassName = this.props.className ? this.props.className : '';
        const dropdownWrapper = (
            <div
                style={this.getDropdownStyle()}
                ref={(popover) => {
                    this.node = popover;
                }}
                onMouseLeave={this.handleMouseLeave}
                className={`dil-dropdown ${alignmentClass} ${customClassName}`}
            >
                <div className="dropdown__content" data-testid={this.props.testId}>{this.props.children}</div>
            </div>
        );
        const popoverPortal = document.getElementById('popover-portal');
        const popoverContent = this.props.shouldUseFocusTrap ? (
            <FocusTrap>{dropdownWrapper}</FocusTrap>
        ) : (
            dropdownWrapper
        );

        return ReactDOM.createPortal(popoverContent, popoverPortal);
    }
}

export const DropdownRow = ({ icon = null, children, onClick = null, link = null, testId = null }) => {
    const dropdownContent = (
        <Fragment>
            {icon && <span className={`la-icon ${icon}`} />}
            {children}
        </Fragment>
    );
    if (link) {
        return (
            <Link to={link} className={'dropdown__row'} onClick={onClick}>
                {dropdownContent}
            </Link>
        );
    } else {
        return (
            <div className={'dropdown__row'} onClick={onClick} data-testid={testId}>
                {dropdownContent}
            </div>
        );
    }
};
