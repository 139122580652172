import React from 'react';
import { FormattedMessage } from 'react-intl';
import { DOC_TYPE } from '@constants';

const ModalFileFormat = ({ isVisible, docType, docTypeChanged, isReadOnly }) => {
    if (!isVisible) {
        return <span></span>;
    }

    return (
        <div className="popup-modal__file-format">
            <div className="popup-field-name">
                <FormattedMessage id={'General_CoreFunctionality_fileFormat'} />
            </div>
            <div className="radio-list">
                <div className="custom-radio">
                    <input
                        type="radio"
                        id="pdf"
                        checked={docType === DOC_TYPE.PDF}
                        disabled={isReadOnly}
                        onChange={() => {
                            docTypeChanged(DOC_TYPE.PDF);
                        }}
                    />
                    <label htmlFor="pdf" className="radio-label">
                        <FormattedMessage id={'General_CoreFunctionality_pdf'} />
                    </label>
                </div>
                <div className="custom-radio">
                    <input
                        type="radio"
                        disabled={isReadOnly}
                        id="docx"
                        checked={docType !== DOC_TYPE.PDF}
                        onChange={() => {
                            docTypeChanged(DOC_TYPE.DOCX);
                        }}
                    />
                    <label htmlFor="docx" className="radio-label" id="">
                        <FormattedMessage id={'General_CoreFunctionality_docx'} />
                    </label>
                </div>
            </div>
        </div>
    );
};

export default ModalFileFormat;
