import composeTable from '@reusable/Table/composeTable';
import * as DilProps from '@utils/props';

const Table = composeTable();

Table.propTypes = {
    headers: DilProps.TableHeaders.isRequired,
    rows: DilProps.TableRows,
};

Table.defaultProps = {
    rows: null,
};

export default Table;
