import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Tabs from './Tabs';

export const TabShape = {
    label: PropTypes.any,
    content: PropTypes.any,
};

export default class TabbedContent extends Component {
    static propTypes = {
        tabs: PropTypes.arrayOf(PropTypes.shape(TabShape)).isRequired,
        defaultTabIndex: PropTypes.number,
        onChange: PropTypes.func,
        visible: PropTypes.bool,
    };

    static defaultProps = {
        tabs: [],
        defaultTabIndex: null,
        visible: false,
    };

    constructor(props) {
        super(props);
        let activeTabIndex = props.defaultTabIndex;
        props.tabs.forEach((tab, index) => {
            if (tab.content && activeTabIndex === null) {
                activeTabIndex = index;
            }
        });
        this.state = {
            activeTab: activeTabIndex,
        };

        this.onChange = this.onChange.bind(this);
    }

    onChange(tabIndex) {
        if (this.props.tabs[tabIndex].content) {
            if (typeof this.props.onChange === 'function') {
                this.props.onChange(tabIndex);
            }
            this.setState({ activeTab: null }, () => {
                // to trigger unmount on option when switching
                this.setState({ activeTab: tabIndex });
            });
        }
    }

    render() {
        return (
            <div className={this.props.visible ? 'visible' : 'hidden'}>
                <div className="tabs-wrapper">
                    <Tabs tabs={this.props.tabs} activeTabIndex={this.state.activeTab} onChange={this.onChange} />
                </div>
                {this.state.activeTab !== null &&
                    this.props.tabs[this.state.activeTab] &&
                    this.props.tabs[this.state.activeTab].content}
            </div>
        );
    }
}
