import React from 'react';
import { FormattedMessage } from 'react-intl';
import { QUERY_SEARCH_TYPES } from '@constants';

const ModalEntityType = ({ entityType, editAction }) => {
    return (
        <div className="popup-modal__file-format">
            <div className="popup-field-name">
                <FormattedMessage id={'BatchScreening.popup.editSearch.searchType'} />
            </div>
            <div className="radio-list">
                <div className="custom-radio">
                    <input
                        type="radio"
                        id="company"
                        data-testid="modal-company-radio-input"
                        checked={entityType === QUERY_SEARCH_TYPES.SEARCH_FOR_COMPANY}
                        onChange={() => {
                            editAction({ entityType: QUERY_SEARCH_TYPES.SEARCH_FOR_COMPANY });
                        }}
                    />
                    <label htmlFor="company" className="radio-label">
                        <FormattedMessage id={'General.label.company'} />
                    </label>
                </div>
                <div className="custom-radio">
                    <input
                        type="radio"
                        id="person"
                        data-testid="modal-person-radio-input"
                        checked={entityType === QUERY_SEARCH_TYPES.SEARCH_FOR_PERSON}
                        onChange={() => {
                            editAction({ entityType: QUERY_SEARCH_TYPES.SEARCH_FOR_PERSON });
                        }}
                    />
                    <label htmlFor="person" className="radio-label">
                        <FormattedMessage id={'General.label.person'} />
                    </label>
                </div>
            </div>
        </div>
    );
};

export default ModalEntityType;
