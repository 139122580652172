import React from 'react';

import { FormattedMessage } from 'react-intl';
import { withAnonymizedToggleView } from '@utils/contexts';

const EmailListInput = (props) => (
    <div className="alert-delivery-options_send-to">
        <span className="alert-delivery-options_labels">
            <FormattedMessage id="Alerts.createAlert.sendTo" />:
        </span>
        {!props.isEmailValid && (
            <div className="validationAlertMessage">
                <FormattedMessage id={'EmailModalEmailValidation.' + props.emailErrorMessage} />
            </div>
        )}
        <AnonymizedWarning />
        <input
            className={`text-field-standard-lg create-alert_input ${!props.isEmailValid ? 'text-field-error' : ''} `}
            type="email"
            autoComplete="off"
            value={props.emails}
            onChange={props.handleEmailAddressChange}
            onFocus={props.showEmailList}
            onBlur={props.hideEmailList}
            onKeyPress={props.handleEmailKeyPress}
            data-testid="email-list-input-id"
        />
        {props.showEmailDropdownList && props.recentEmails && props.recentEmails.length > 0 && (
            <div className="alert-delivery-options_send-to_recent-emails">
                <React.Fragment>
                    <span className="alert-delivery-options_send-to_recent-emails_header">
                        <FormattedMessage id="Alerts.createAlert.recentEmail" />:
                    </span>
                    {props.recentEmails.map((email, index) => (
                        <li key={index} className="emails-dropdown-item">
                            <button
                                className="emails-dropdown-item-title"
                                onClick={() => props.chooseFromRecentEmails(email)}
                                data-testid={`emails-recent-dropdown-${email}-select-btn`}
                            >
                                {email}
                            </button>
                        </li>
                    ))}
                </React.Fragment>
            </div>
        )}
        <span className="alert-delivery-options_send-to_note">
            <FormattedMessage id="Alerts.createAlert.emailMessage" />
            <br />
            <FormattedMessage id="Alerts.createAlert.sharedUsernameMessage" />
        </span>
    </div>
);

const AnonymizedWarning = withAnonymizedToggleView(() => (
    <span className="email-note">
        <FormattedMessage id={'Alerts.createAlert.anonymized.emailWarning'} />
    </span>
));

export default EmailListInput;
