import React, { Component } from 'react';
import { SortBtn } from '@reusable/Table/components/defaults';
import { SORT_DIRECTION } from '@constants';
import ReactTooltip from 'react-tooltip';
import overridePosition from '@reusable/GridTable/utils/overridePosition';
import formatRichMessage from '@utils/formatRichMessage';

export class BatchHeaderTemplate extends Component {
    getCompatibleCell(uncertainCell) {
        const text = uncertainCell.text;
        return { ...uncertainCell, text };
    }

    update(cell, cellToMerge) {
        return this.getCompatibleCell({ ...cell, text: cellToMerge.text });
    }

    render(cell) {
        const headerValue = cell.text;

        const contextMenuTooltipContent = formatRichMessage({ id: 'ArticleAction.batchHeaderContextMenuTooltip' });

        const showAscendingSort = cell.sort && cell.sort === SORT_DIRECTION.ASC;
        const showDescendingSort = cell.sort && cell.sort === SORT_DIRECTION.DESC;

        const tableHasData = cell.tableHasData;

        return (
            <div
                className="generic-table grid-table-batch-header-template"
                data-for="batch-header-tooltip"
                data-tip={contextMenuTooltipContent}
            >
                <div>
                    {tableHasData && (
                        <ReactTooltip
                            id="batch-header-tooltip"
                            type="light"
                            offset={{ bottom: 0, left: 12 }}
                            overridePosition={overridePosition}
                            border={true}
                            effect="solid"
                            place="right"
                            html={true}
                            className="tooltips context-menu-toolip"
                            delayShow={3000}
                        />
                    )}
                    {headerValue}
                    <div className="sort-btn-container">
                        {showAscendingSort && <SortBtn className={'sort-action-btn la-TriangleUp'}></SortBtn>}
                        {showDescendingSort && <SortBtn className={'sort-action-btn la-TriangleDown'}></SortBtn>}
                    </div>
                </div>
            </div>
        );
    }
}
