import React, { Component, Fragment } from 'react';
import Modal from '@reusable/Modal/Modal.index';
import { POPUP_SIZE_SMALL } from '@constants';

const withConfirmationModal = (WrappedComponent, config = {}) => {
    class withConfirmation extends Component {
        constructor(props) {
            super(props);

            this.state = {
                modalVisible: false,
                title: null,
                description: null,
                primaryBtnText: 'General_CoreFunctionality_UIText_general.ok',
                secondaryBtnText: 'General_CoreFunctionality_UIText_general.cancel',
            };
            this.handleConfirmModal = this.handleConfirmModal.bind(this);
            this.handleCloseModal = this.handleCloseModal.bind(this);
            this.onConfirmAction = null;

            this.config = {
                popupSize: POPUP_SIZE_SMALL,
                className: 'confirmation-modal',
                ...config,
            };
        }

        handleConfirmModal(modalProps, onConfirm) {
            this.onConfirmAction = () => {
                this.setState({ modalVisible: false });
                onConfirm();
            };
            this.setState({
                modalVisible: true,
                ...modalProps,
            });
        }

        handleCloseModal() {
            this.setState({ modalVisible: false });
        }

        render() {
            const buttons = [
                {
                    text: this.state.primaryBtnText,
                    primary: true,
                    click: this.onConfirmAction,
                    rightAligned: true,
                    testid: 'popup-modal-confirmation-btn',
                },
                {
                    text: this.state.secondaryBtnText,
                    secondary: true,
                    click: this.handleCloseModal,
                    rightAligned: true,
                    testid: 'popup-modal-cancel-btn',
                },
            ];

            return (
                <Fragment>
                    {this.state.modalVisible && (
                        <Modal closeButtonHandler={this.handleCloseModal} buttons={buttons} {...this.config}>
                            <div className="popup-modal notranslate" data-test-id="confirmation-modal">
                                <div className="popup-modal-header">{this.state.title}</div>
                                <div className="popup-modal-body">
                                    <div className="popup-modal__description">{this.state.description}</div>
                                </div>
                            </div>
                        </Modal>
                    )}
                    <WrappedComponent {...this.props} onConfirmModal={this.handleConfirmModal} />
                </Fragment>
            );
        }
    }

    return withConfirmation;
};

export default withConfirmationModal;
