import React from 'react';
import PropTypes from 'prop-types';
import * as DilProps from '@utils/props';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ArticleNavigationActions from '@pages/MainSearch/redux/ArticleNavigation.actions';
import { FormattedMessage } from 'react-intl';
import { NextButton, PrevButton } from './components';
import utils from '@utils/utilities';
import { Link } from 'react-router';
import searchUtils from '@pages/MainSearch/SearchUtils';
import categoryUtils from '@utils/categoryUtils';
import uboActions from '@pages/StartPage/redux/Ubo.actions';
import { UBO_DEFAULT_SORTING_AND_FILTERING, MATOMO } from '@constants';
import callMatomoEvent from '@utils/matomoUtils';

const ArticleNavigation = (props) => {
    const isNextDisabled = () => props.articleNavigation.index + 1 === props.articleNavigation.totalCount;
    const isNextLoading = () => !isNextDisabled() && !props.articleNavigation.list[props.articleNavigation.index + 1];
    const nextAltText = () =>
        !isNextDisabled() &&
        !isNextLoading() &&
        utils.htmlDecode(props.articleNavigation.list[props.articleNavigation.index + 1].title);

    const isPrevDisabled = () => props.articleNavigation.index === 0;
    const isPrevLoading = () => !isPrevDisabled() && !props.articleNavigation.list[props.articleNavigation.index - 1];
    const prevAltText = () =>
        !isPrevDisabled() &&
        !isPrevLoading() &&
        utils.htmlDecode(props.articleNavigation.list[props.articleNavigation.index - 1].title);

    const query = utils.getUrlQueryParam('query');
    const category = utils.getUrlQueryParam('category');
    const type = utils.getUrlQueryParam('type');
    const prefilterQuery = utils.getUrlQueryParam('prefilterQuery');
    const articleIndex = props.articleNavigation.index;
    let backUrl = `/main-search?q=${query}&category=${category}&searchType=${type}&prefilterQuery=${prefilterQuery}&list=true&articleIndex=${articleIndex}`;

    if (categoryUtils.isDnbCategory(props.articleNavigation.params.category)) {
        backUrl = `/main-search?q=${props.articleNavigation.params.query}&category=${props.articleNavigation.params.category}&searchType=${props.articleNavigation.params.searchType}&prefilterQuery=${props.articleNavigation.params.prefilterQuery}&list=true&articleIndex=${articleIndex}`;
    }

    const handleGoBack = () => {
        const currentPage =
            (props.articleNavigation.index - (props.articleNavigation.index % props.articleNavigation.pageSize)) /
            props.articleNavigation.pageSize;
        const startPage = props.articleNavigation.startPage;
        if (currentPage !== startPage) {
            searchUtils.loadMoreDocumentsForAvailableCategories(props.articleNavigation.params.category, currentPage);
        }
    };

    const handleNavigationClick = (isNextClick) => {
        const {
            articleNavigation: {
                index,
                list,
                article: { articleType },
            },
        } = props;
        const isUboCategory = categoryUtils.isDnbCategory(props.articleNavigation.params.category);

        if (isUboCategory) {
            props.uboUpdateSortingAndFiltering(UBO_DEFAULT_SORTING_AND_FILTERING);
        }

        isNextClick ? props.next() : props.previous();

        if (window['_mtm']) {
            const nextArticle = isNextClick ? list[index + 1] : list[index - 1];
            const documentRank = isNextClick ? index + 2 : index;
            const { unhighlightedTitle, title, id, pcsi } = nextArticle;
            const documentInfo = {
                documentRank,
                pcsi,
                lni: id,
            };

            callMatomoEvent({
                pageName: MATOMO.pageName.document,
                component: MATOMO.component.searchResultsList,
                subComponent: MATOMO.subComponent.delivery,
                event: MATOMO.event.docNext,
                value: isUboCategory ? title : unhighlightedTitle,
                action: MATOMO.action.click,
                contextData: {
                    documentInfo,
                    contentType: articleType,
                },
            });
        }
    };

    return (
        props.articleNavigation.index !== null && (
            <div className={'article-navigation'}>
                <PrevButton
                    onClick={() => handleNavigationClick(false)}
                    disabled={isPrevDisabled()}
                    loading={isPrevLoading()}
                    altText={prevAltText()}
                />
                <div className={'counter'}>
                    <FormattedMessage
                        id={'General_CoreFunctionality_UIText_general.pageNumberOf'}
                        values={{
                            pageNumber: utils.formatNumbersByUserLocale(
                                props.language,
                                props.articleNavigation.index + 1
                            ),
                            totalCount: utils.formatNumbersByUserLocale(
                                props.language,
                                props.articleNavigation.totalCount
                            ),
                        }}
                    />
                </div>
                <div className={'back'}>
                    <Link to={backUrl} onClick={handleGoBack}>
                        <FormattedMessage id={'General_CoreFunctionality_UIText_general.resultsList'} />
                    </Link>
                </div>

                <NextButton
                    onClick={() => handleNavigationClick(true)}
                    disabled={isNextDisabled()}
                    loading={isNextLoading()}
                    altText={nextAltText()}
                />
            </div>
        )
    );
};

ArticleNavigation.propTypes = {
    articleNavigation: DilProps.ArticleNavigation,
    next: PropTypes.func,
    previous: PropTypes.func,
};

const mapStateToProps = (state) => ({
    articleNavigation: state.articleNavigation,
    key: state.articleNavigation.index,
    language: state.user.preferences.language,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            next: ArticleNavigationActions.nextArticle,
            previous: ArticleNavigationActions.prevArticle,
            uboUpdateSortingAndFiltering: uboActions.updateSortingAndFiltering,
        },
        dispatch
    );

export { ArticleNavigation as TestArticleNavigation };
export default connect(mapStateToProps, mapDispatchToProps)(ArticleNavigation);
