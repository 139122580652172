const callMatomoEvent = (props) => {
    if (window['_mtm']) {
        const { pageName, component, subComponent, componentCustom, event, value, action, contextData } = props;

        const eventData = {
            UI_PageName: pageName,
            UI_Component: component,
            UI_SubComponent: subComponent,
            UI_Component_Custom: componentCustom ?? 'N/A',
            UI_Event: event,
            UI_Value: value,
            UI_Action: action,
        };
        if (contextData && Object.keys(contextData).length) {
            eventData.contextData = contextData;
        }

        window['_mtm'].push({
            event: 'dlv_track',
            dlv_eventData: eventData,
        });
    }
};

export default callMatomoEvent;
