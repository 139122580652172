import React from 'react';
import UserCostCodeDropdown from '@pages/UserPreferances/components/UserCostCodeDropdown';
import { FormattedMessage } from 'react-intl';

const ModalCostCodes = (props) => {
    return (
        <div>
            <div className="popup-modal__addHoc-scrollable-area__cost-code">
                <div className="horizontal-divider" />
                <div className="cost-code-container-main">
                    <div className="cost-code-explanation">
                        <div className="cost-code">
                            <FormattedMessage id="UserPreferences_topic_cost_code" />
                        </div>
                        <div className="explanation">
                            <FormattedMessage id="UserPreferences_cost_code_label.adhoc" />
                        </div>
                    </div>
                    <div className="cost-code-dropdown">
                        <UserCostCodeDropdown
                            costCodes={props.costCodes}
                            costCode={props.costCode}
                            chooseOnlyFromCostCodesList={props.chooseOnlyFromCostCodesList}
                            isCostCodeRequired={props.isCostCodeRequired}
                            isCustomCostCode={props.isCustomCostCode}
                            changeCostCode={props.changeCostCode}
                            isHomepageModal={true}
                        ></UserCostCodeDropdown>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalCostCodes;
