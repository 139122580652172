import { isEmpty } from 'lodash';
import errorUtils from '@utils/errors/error-utils';
import { bindActionCreators } from 'redux';
import investigationActions from '@MainSearch/redux/Investigation.actions';
import { connect } from 'react-redux';

const APP_MODULE = 'INVESTIGATION_EVENTS_PROVIDER';

const InvestigationEventsProvider = (props) => {
    const sendInvestigationEvent = (investigationEvent) => {
        if (!investigationEvent || isEmpty(investigationEvent)) {
            errorUtils.logAppError('Missing investigation event', {}, APP_MODULE);

            return;
        }
        props.investigationActions(investigationEvent);
    };

    return props.provideInvestigationEvents({
        sendInvestigationEvent,
    });
};

const mapDispatchToProps = function (dispatch) {
    return bindActionCreators(
        {
            investigationActions: investigationActions.investigationAction,
        },
        dispatch
    );
};

export default connect(null, mapDispatchToProps)(InvestigationEventsProvider);
