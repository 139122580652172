import React from 'react';
import { Link } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { withMIPUserHideView } from '@utils/contexts';

function ReportBuilderSelector() {
    return (
        <li className="diligence-header__menu-item diligence-header__menu-item__report-builder notranslate">
            <Link to="/report-builder">
                <div className="diligence-header__menu-link" data-track="diligence-header__menu-link__report-builder">
                    <span className="diligence-header__menu-link__report-builder">
                        <FormattedMessage id="BREADCRUMBS.reportBuilder" />
                    </span>
                </div>
            </Link>
        </li>
    );
}

export default withMIPUserHideView(ReportBuilderSelector);
