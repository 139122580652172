export const headerStyleObj = {
    background: '#fff',
    paddingLeft: '2rem',
    border: {
        top: {
            width: '0',
        },
        bottom: {
            width: '0.1rem',
            style: 'solid',
            color: 'rgb(224, 224, 224)',
        },
        left: {
            width: '0',
        },
        right: {
            width: '0',
        },
    },
};

export const emptyCellStyleObj = {
    background: '#fff',
    paddingLeft: 'calc(50% - 163px)',
    border: {
        top: {
            width: '0',
        },
        bottom: {
            width: '0',
        },
        left: {
            width: '0',
        },
        right: {
            width: '0',
        },
    },
};

export const loadingCellStyleObj = {
    background: '#fff',
    paddingLeft: '0',
    border: {
        top: {
            width: '0',
        },
        bottom: {
            width: '0',
        },
        left: {
            width: '0',
        },
        right: {
            width: '0',
        },
    },
};

export const cellStyleObj = (index) => ({
    background: index % 2 === 0 ? '#fff' : '#F0F3F5',
    paddingLeft: '2rem',
    border: {
        top: {
            width: '0',
        },
        bottom: {
            width: '0',
        },
        left: {
            width: '0',
        },
        right: {
            width: '0',
        },
    },
});
