// @flow
import React from 'react';
import { injectIntl } from 'react-intl';
import formatRichMessage from '@utils/formatRichMessage';

type Props = {
    selectedFiltering: number,
};

type InjectedProps = {|
    +intl: Object,
|};

const UboNoFilteringResults = (props: Props & InjectedProps): React$Element<'div'> | null => {
    return props.selectedFiltering ? (
        <div
            className="ubo-no-filtering-results"
            dangerouslySetInnerHTML={{
                __html: formatRichMessage(
                    { id: 'UboDocumentView.Tabs.TableView.NoFilteringOwnershipResults' },
                    props.intl,
                    { filtering: props.selectedFiltering }
                ),
            }}
        />
    ) : null;
};

export default (injectIntl(UboNoFilteringResults): React$StatelessFunctionalComponent<Props>);
