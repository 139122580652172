import React, { Component } from 'react';
import CheckboxFilter from '../CheckboxFilter';
import formatRichMessage from '@utils/formatRichMessage';
import { injectIntl } from 'react-intl';

class DocketStatusPostFilter extends Component {
    handleChange = (options, option, e) => {
        if (options.length > 0) {
            options.forEach((opt) => {
                if (opt.originalLabel) {
                    opt.label = opt.originalLabel;
                }
            });
        }

        this.props.onChange(options, option, e);
    };

    render() {
        let options = this.props.options.map((option) => {
            let label = option.originalLabel || option.label,
                translationsKey = 'General.postFilters.docketStatus.' + label,
                formattedMessage = formatRichMessage({ id: translationsKey }, this.props.intl);

            return {
                ...option,
                label: formattedMessage !== translationsKey ? formattedMessage : label,
            };
        });

        return (
            <div className={'docket-status-postfilter'}>
                <CheckboxFilter
                    {...this.props}
                    options={options}
                    onChange={this.handleChange}
                    displayItemsCount={false}
                    fieldId={'docket-status-postfilter'}
                />
            </div>
        );
    }
}

export default injectIntl(DocketStatusPostFilter);
