import React from 'react';
import { Link } from 'react-router';
import { hashHistory } from 'react-router';
import { FormattedMessage, injectIntl } from 'react-intl';
import utils from '@utils/utilities';
import formatRichMessage from '@utils/formatRichMessage';

const PublicRecordsSelector = (props) => {
    const navigateToPr = (targetUrl) => {
        hashHistory.push('/public-records?param=' + targetUrl);
    };

    let links = [];
    props.links.forEach((pr) => {
        links.push({
            label: formatRichMessage({ id: 'PublicRecords.link.' + pr.featureId }, props.intl),
            ...pr,
        });
    });

    links = utils.sortByProperty(links, 'label');

    let mainMenu;
    links = links.filter(link => {
        if (link.featureId === '1000200') {
            mainMenu = link;
            return false;
        }

        return true;
    });

    mainMenu && links.unshift(mainMenu);

    const prLinks = links.map((link, index) => {
        let encodedUrl = btoa(
            props.publicRecordsApplicationUrl +
                'entrypoint.aspx?clientId=' +
                props.clientId +
                '&ddfeatureID=' +
                link.featureId +
                '&chargeDescription=' +
                props.chargeDescription
        );
        return (
            <Link
                key={'pr_' + index}
                className="diligence-header__menu-link diligence-header__menu-link--dropdown-item"
                onClick={() => {
                    navigateToPr(encodedUrl);
                }}
            >
                {link.label}
            </Link>
        );
    });

    return (
        <li className="diligence-header__menu-item diligence-header__menu-item__public-records">
            <div
                className="diligence-header__menu-link diligence-header__menu-link__public-records"
                data-track="diligence-header__menu-link__public-records"
            >
                <span>
                    <FormattedMessage id="General.categoryName.label.publicRecords" />
                </span>
            </div>
            <div className="dropdown-content dropdown-content__public-record">{prLinks}</div>
        </li>
    );
};

export default injectIntl(PublicRecordsSelector);
