import {
    OPERATOR_OR,
    OR,
    CATEGORY_TYPE_UBO_CHILD,
    CATEGORY_TYPE_UBO,
    CATEGORY_NAMES,
    OPERATOR_AND,
    OPERATOR_LIST,
    DNB_LABEL,
    AND,
    UBO_EXTENDED_GRAPH_MAX_NODES,
    UBO_CATEGORY_DUNS,
    UBO_CATEGORY_TERM,
    UBO_POST_FILTERS,
    UBO_GRAPH,
    UBO_BENEFICIARY_TYPE_INDIVIDUAL_CODE,
    UBO_BENEFICIARY_TYPE_BUSINESS_CODE,
} from '@constants';
import { isEmpty, findIndex, replace, toLower, camelCase, capitalize, mean, isNil } from 'lodash';
import { getCountryName } from '@utils/countryUtils';
import categoryUtils from '@utils/categoryUtils';
import * as d3 from 'd3';
import { union } from 'lodash';
import { wrap } from '@graph/ubo/graph';
import { GRAPH_TAGS, selectTags } from './uboGraphHelpers';

// API Mapping for the suggestion item
export const suggestionItemApiMap = (item) => ({
    title: sanitizeCompanyName(item.name),
    address: item.address,
    locality: item.locality,
    country: item.country && getCountryName(item.country.isoCode),
    duns: item.duns,
    isBranch: item.isBranch,
});

export const isUboCompany = (item) => !!item.duns;

export const isUboTerm = (item) => !item.duns;

export const uboCategoryNameFormat = ({ company = {}, term = '' }) => `ubo_${camelCase(company.title || term)}`;

export const uboCategorySearchQueryFormat = ({ company = {}, term = '' }) => `ubo_${camelCase(company.title || term)}`;

export const isUboCategory = (category) =>
    category.type === CATEGORY_TYPE_UBO_CHILD || category.type === CATEGORY_TYPE_UBO;

export const uboFormatQuery = (selected) => selected.map((item) => (item.title ? item.title : item.value)).join(' OR ');

export const encodeUboUrlParam = (uboSelected = []) =>
    encodeURIComponent(
        JSON.stringify(uboSelected.map((item) => ({ duns: item.duns, title: encodeURIComponent(item.title) })))
    );

export const decodeUboUrlParam = (uboParam = '') =>
    JSON.parse(decodeURIComponent(uboParam)).map((item) => ({
        ...item,
        title: decodeURIComponent(item.title),
    }));

/**
 * Retrieves the category type for the given "categoryName",
 * will return type ubo parent or ubo child, null if not a ubo category
 * @param categoryName
 * @return CATEGORY_NAMES.DNB | null
 */
export const getUboCategoryType = (categoryName) => {
    if(categoryName === CATEGORY_NAMES.DNB) return CATEGORY_TYPE_UBO;
    if(categoryName !== CATEGORY_NAMES.DNB && categoryName.includes(CATEGORY_NAMES.DNB)) return CATEGORY_TYPE_UBO_CHILD;
    return null;
};

export const uboApiSimpleMap = (response) => {
    const { nodes } = response;
    const rootNode = nodes[0];
    let linksByIndex = [];

    nodes.forEach((node) => {
        if (!isParentNode(node)) {
            const tags = selectTags(node, GRAPH_TAGS);
            linksByIndex.push({
                id: node.id,
                source: node, //findIndex(nodes, sourceNode => sourceNode.id === node.id),
                sourceIndex: findIndex(nodes, (sourceNode) => sourceNode.id === node.id),
                target: rootNode, //findIndex(nodes, sourceNode => sourceNode.id === rootNode.id),
                targetIndex: findIndex(nodes, (sourceNode) => sourceNode.id === rootNode.id),
                sharePercentage: node.beneficialOwnershipPercentage,
                tags: tags,
            });
            node.tags = tags;
        }
    });

    return { nodes, links: linksByIndex };
};

/**
 * Maps the response from api to the format that is used by the graph
 * by adding children references in parents and calculating layer position
 * @param response
 * @returns {{nodes: Array, links: Array}}
 */
export const uboApiMap = (response) => {
    const { nodes, links } = response;
    if (response.nodes.length > UBO_EXTENDED_GRAPH_MAX_NODES) {
        return { nodes: response.nodes, links: [] };
    }
    let linksByIndex = [];
    nodes.forEach((node) => {
        const childrenIds = links
            .filter((link) => link.source === node.id)
            .map((link) => ({
                id: link.target,
                sharePercentage: link.sharePercentage,
                relationshipType: link.relationshipType,
            }));
        childrenIds.forEach((child) => {
            linksByIndex.push({
                id: node.id,
                source: findIndex(nodes, (sourceNode) => sourceNode.id === node.id),
                sourceIndex: findIndex(nodes, (sourceNode) => sourceNode.id === node.id),
                target: findIndex(nodes, (sourceNode) => sourceNode.id === child.id),
                targetIndex: findIndex(nodes, (sourceNode) => sourceNode.id === child.id),
                sharePercentage: child.sharePercentage,
                relationshipType: child.relationshipType,
            });
            childrenIds.forEach((childID) => {
                const targetNode = nodes.find((n) => childID.id === n.id);
                if (targetNode) {
                    targetNode.parentID = node.id;
                }
            });
            if (!isParentNode(node)) {
                node.childrenIds = [];
            }
            node.childrenIds.push(child.id);
        });
    });

    linksByIndex = linksByIndex.map((link) => {
        const isTwoWay =
            linksByIndex.filter(
                (item) => item.targetIndex === link.sourceIndex && item.sourceIndex === link.targetIndex
            ).length > 0;
        if (isTwoWay) {
            return { ...link, isTwoWay: true };
        } else {
            return { ...link, isTwoWay: false };
        }
    });

    return { nodes, links: linksByIndex };
};

export const cleanAndTrim = (query) => query?.split(' AND ')[0].trim();

export const processMemoTerm = (term) => toLower(term.trim());

export const sanitizeCompanyName = (companyName, operators = [OR, AND], sanitizeFn = capitalize) => {
    companyName = companyName.trim();

    operators.forEach((operator) => {
        let operatorSearch = ' ' + operator + ' ';
        let operatorReplace = ' ' + sanitizeFn(operator) + ' ';
        // search and replace in middle - operator has a space before and after it
        companyName = replace(companyName, new RegExp(operatorSearch, 'g'), operatorReplace);

        // search at the beginning, no space before and at 0 index
        operatorSearch = operator + ' ';
        operatorReplace = sanitizeFn(operator) + ' ';
        if (companyName.indexOf(operatorSearch) === 0) {
            companyName = companyName.replace(operatorSearch, operatorReplace);
        }

        // search at the end, no space after it and placed at the end
        operatorSearch = ' ' + operator;
        operatorReplace = ' ' + sanitizeFn(operator);
        const lastSegment = companyName.substr(-1 * operatorSearch.length);
        if (lastSegment === operatorSearch) {
            companyName = companyName.substr(0, companyName.length - operatorSearch.length) + operatorReplace;
        }
    });

    return companyName;
};

/**
 * Should clean the query from the selected UBO's,
 * also will clear any adjacent "OR" operators
 * @param selected
 * @return {function(...[*]=)}
 */
export const cleanSelectedUbo =
    (companies = []) =>
    (query = '') => {
        companies.forEach((company) => {
            query = cleanCompanyAndOr(query, company);
        });
        return query;
    };

export const cleanCompanyAndOr = (query, company) => {
    const queryCompany = findCompanyInQuery(query, company);
    let startPos = queryCompany.startPos,
        endPos = queryCompany.endPos;
    company = queryCompany.companyString;

    if (startPos > -1) {
        // find the OR before it
        const firstSegment = query.substring(0, startPos);
        if (firstSegment.trim().substr(-3) === ' ' + OR) {
            startPos = firstSegment.length - OR.length - 1;
        } else {
            // if not search after it
            const lastSegment = query.substring(startPos + company.length);
            if (lastSegment.trim().substring(0, 3) === OR + ' ') {
                endPos += lastSegment.indexOf(OR) + OR.length + 1;
            }
        }
        if (startPos === 0) {
            return query.substring(endPos).trim();
        } else {
            return query.substring(0, startPos).trim() + ' ' + query.substring(endPos).trim();
        }
    } else {
        return query;
    }
};

export const getMissingCompaniesInQuery = (query, companies) => {
    let missingCompanies = [];
    companies.forEach((company) => {
        let previousQuery = query;
        query = cleanCompanyAndOr(query, company.title);
        // if nothing has been cleaned, it means it's not in the query anymore
        if (query === previousQuery) {
            missingCompanies.push(company);
        }
    });
    return missingCompanies;
};

export const findCompanyInQuery = (query = '', company = '') => {
    // when we have just one company in query
    if (query === company) {
        return {
            startPos: 0,
            endPos: company.length,
            companyString: company,
        };
    }

    let startPos = -1;
    if (query.indexOf(' ' + company + ' ') > -1) {
        company = ' ' + company + ' ';
        startPos = query.indexOf(company);
    } else if (query.indexOf(company + ' ') === 0) {
        company = company + ' ';
        startPos = query.indexOf(company);
    } else if (query.substr(-1 * (company.length + 1)) === ' ' + company) {
        company = ' ' + company;
        startPos = query.indexOf(company);
    }
    const endPos = startPos + company.length;
    return {
        startPos,
        endPos,
        companyString: company,
    };
};

/**
 * If cursor is in a valid range for typeahead, for now what is after AND operator is not valid
 * @param query
 * @param position
 * @return {*}
 */
export const isCursorInRange = (query, position) => {
    return query.indexOf(OPERATOR_AND) === -1 || position <= query.indexOf(OPERATOR_AND);
};

export const extractTermAtPosition = (query = '', position = 0, operators = OPERATOR_LIST) => {
    //query = cleanAndTrim(query);
    const leftBound = findClosestOperatorLeft(query, position, operators),
        rightBound = findClosestOperatorRight(query, position, operators);
    const term = query.substring(leftBound, rightBound);
    if (hasOperator(term, operators)) {
        return '';
    } else {
        return term;
    }
};

export const replaceTermAtPosition = (query, position, replaceStr, operators = OPERATOR_LIST) => {
    const leftBound = findClosestOperatorLeft(query, position, operators),
        rightBound = findClosestOperatorRight(query, position, operators),
        term = query.substring(leftBound, rightBound);

    if (hasOperator(term, operators)) {
        return query;
    } else {
        return query.substring(0, leftBound) + replaceStr + query.substring(rightBound);
    }
};

const findClosestOperatorRight = (str = '', fromPosition = 0, operators = OPERATOR_LIST) => {
    let positions = [];
    operators.forEach((operator) => {
        positions.push(str.indexOf(operator, fromPosition));
    });
    if (Math.max(...positions) === -1) {
        return str.length;
    } else {
        positions = positions.filter((position) => position > -1);
        return Math.min(...positions);
    }
};

const findClosestOperatorLeft = (str = '', fromPosition = str.length, operators = OPERATOR_LIST) => {
    const positions = [];
    str = str.substring(0, fromPosition);
    operators.forEach((operator) => {
        const pos = str.lastIndexOf(operator, fromPosition);
        if (pos === -1) {
            positions.push(-1);
        } else {
            positions.push(pos + operator.length);
        }
    });
    const result = Math.max(...positions);
    if (result === -1) {
        return 0;
    } else {
        return result;
    }
};

export const hasOperator = (str = '', operators = OPERATOR_LIST) => {
    const positions = [];
    operators.forEach((operator) => {
        positions.push(str.indexOf(operator));
    });
    return Math.max(...positions) > -1;
};

export const getAllTerms = (query) => {
    query = cleanAndTrim(query);
    return query?.split(OPERATOR_OR).map((term) => term.trim());
};

/**
 * Paints the individual nodes with the appropriate styled elements
 * @param svg
 * @param individualNodes
 */
export function paintIndividualsNodes(svg, individualNodes, prefix = '') {
    let individualNodeGroup = svg
        .selectAll('g.individualNodes')
        .data(individualNodes)
        .enter()
        .append('g')
        .attr('class', (node) => 'leafs ' + (node.tags ? node.tags.join(' ') : ''));

    individualNodeGroup.append('circle').attr('r', 40).style('fill', '#64B5F6');

    individualNodeGroup
        .append('svg:image')
        .attr('width', 40)
        .attr('height', 40)
        .attr('x', -20)
        .attr('y', -18);

    individualNodeGroup
        .append('circle')
        .attr('r', 20)
        .attr('cx', 30)
        .attr('cy', -30)
        .attr('stroke', '5D34B0')
        .attr('stroke-width', '0.5')
        .style('fill', '#FFF')
        .style('filter', `url(#${prefix}drop-shadow)`);

    individualNodeGroup
        .append('text')
        .text(function (d) {
            return d.name;
        })
        .attr('font-size', 10)
        .attr('id', (d) => 'node_text_' + d.id)
        .attr('x', -30)
        .attr('y', 50);

    individualNodeGroup
        .append('text')
        .text((d) => (d.beneficialOwnershipPercentage === null ? 'N/A' : d.beneficialOwnershipPercentage + '%'))
        .attr('font-size', 12)
        .attr('class', 'ownership')
        .attr('font-weight', 'bold')
        .attr('x', 20)
        .attr('y', -26.5);

    // centering the text nodes
    individualNodes.forEach((node) => {
        const textNode = svg.select('#node_text_' + node.id);
        const svgNode = textNode.node();
        textNode.attr('x', svgNode && (svgNode.getBoundingClientRect().width / 2) * -1);
    });
}

export const processBreadcrumbLabel = (label) => {
    const category = label.replace('BREADCRUMBS.snapshot.', '');
    if (categoryUtils.isDnbCategory(category)) {
        return 'BREADCRUMBS.snapshot.ubo';
    }
    return label;
};

export function sanitizeTerm(term) {
    term = ` ${term} `; // add spaces so that it matches the first and last, we will trim it when we return it
    // strip connectors
    const connectors = [
        'pre/[0-9]+',
        'pre/s',
        'pre/p',
        'w/[0-9]+',
        'w/s',
        'w/p',
        '/[sS]',
        '/[pP]',
        'onear/[0-9]+',
        'near/[0-9]+',
        'atleast[0-9]+',
        '/[0-9]+',
    ];
    connectors.forEach((connector) => {
        term = replace(term, new RegExp(` ${connector} `, 'gi'), ' ');
    });

    // strip quotes
    const quotes = ["'", '"'];
    quotes.forEach((quote) => {
        term = replace(term, new RegExp(quote, 'g'), '');
    });

    //strip wildcards
    const wildcards = ['*', '/', '?'];
    wildcards.forEach((wildcard) => {
        if (term.indexOf(wildcard) > -1) {
            term = term.substring(0, term.indexOf(wildcard));
        }
    });

    return term.trim();
}

/**
 * Paints the business nodes with the appropriate styled elements
 * @param svg
 * @param businessNodes
 */
export function paintBusinessNodes(svg, businessNodes, onClick) {
    const boxWidth = 200;
    const boxHeight = 60;

    let businessNodeGroup = svg
        .selectAll('g.businessNodes')
        .data(businessNodes)
        .enter()
        .append('g')
        .attr(
            'class',
            (node) =>
                'leafs ' + (node.tags ? node.tags.join(' ') : '') + (node.degreeOfSeparation === 0 ? ' root_node' : '')
        )
        .on('click', onClick);

    businessNodeGroup
        .append('rect')
        .attr('width', boxWidth)
        .attr('height', boxHeight)
        .attr('fill', (d) => (d.degreeOfSeparation === 0 ? '#5D34B0' : '#3E75D2'));

    businessNodeGroup
        .append('text')
        .attr('x', 10)
        .attr('y', 25)
        .attr('fill', '#FFF')
        .attr('font-size', 14)
        .attr('width', 190)
        .attr('height', 100)
        .text(function (d) {
            return d.name;
        })
        .call(
            wrap,
            180,
            d3,
            {
                size: 14,
                weight: 300,
                name: 'sans-serif',
            },
            2,
            '...'
        );
}

export const calculateLinkCoords = (d) => {
    const nodeRadiusIndividual = 50;
    const nodeRadiusBusiness = 100;
    const arrowHeadLength = 10;

    let x1 = d.source.x,
        y1 = d.source.y,
        x2 = d.target.x,
        y2 = d.target.y,
        angle = Math.atan2(y2 - y1, x2 - x1),
        angleDegree = (angle * 180) / Math.PI;

    let nodeRadius = isBusinessNode(d.target) ? nodeRadiusBusiness : nodeRadiusIndividual,
        sourceX = x1,
        sourceY = y1 + 35,
        targetX = x2 - Math.cos(angle) * (nodeRadius + arrowHeadLength),
        targetY = y2 - Math.sin(angle) * (nodeRadius + arrowHeadLength);

    if (isBusinessNode(d.target)) {
        // calculating where to point the arrow at a bussiness node (irregular rect shape)
        const varX =
            x2 -
            (Math.cos(angle) * (nodeRadius + arrowHeadLength) -
                ((Math.cos(angle) * (nodeRadius + arrowHeadLength)) % 100));

        if (Math.abs(angleDegree) <= UBO_GRAPH.arrowAngle) {
            // to right
            targetX = x2 - 105;
            targetY = y2 + 30; //varY ;
        } else if (Math.abs(angleDegree) >= 180 - UBO_GRAPH.arrowAngle) {
            // to left
            targetX = x2 + 105;
            targetY = y2 + 30; //varY;
        } else if (angleDegree < 0) {
            // to bottom
            targetX = varX;
            targetY = y2 + 70;
        } else if (angleDegree >= 0) {
            // to top
            targetX = varX;
            targetY = y2 - 10;
        }
    }
    //if owns itself
    if (d.targetIndex === d.sourceIndex) {
        return `M${sourceX - 75},${sourceY - 40} a 30 30 0 1 0 -30 30`;
    }
    // if its two way relationship
    let curveX = UBO_GRAPH.curveOffsetX;
    if (d.sourceIndex > d.targetIndex || d.source.y < d.target.y) {
        curveX = -curveX;
    }
    const x = mean([sourceX, targetX]) + curveX;
    const y = mean([sourceY, targetY]);
    return { sourceX, sourceY, x, y, targetX, targetY };
};

export const paintLinks = (svg, links) => {
    svg.selectAll('.link')
        .data(links)
        .enter()
        .append('g')
        .attr('class', (link) => 'lines ' + (link.tags ? link.tags.join(' ') : ''))
        .append('path')
        .attr('class', 'line');

    svg.selectAll('.lines').data(links).append('path').attr('class', 'line-rev');

    // add the percentages in the links in the group
    const lines = svg
        .selectAll('.lines')
        .data(links)
        .append('g')
        .attr('class', (d) => (d.relationshipType !== 'Persons of Significant Control' ? 'shares_group' : 'hidden'));

    svg.selectAll('.lines')
        .data(links)
        .append('text')
        .attr('x', 50)
        .attr('dy', -5)
        .attr('class', 'has_psc')
        .attr('text-anchor', 'top')
        .append('textPath')
        .attr('xlink:href', (d) => `#link_rev_${d.source.id}_${d.target.id}`)
        .text((d) => (d.relationshipType === 'Persons of Significant Control' ? 'Has PSC' : ''));

    lines.append('circle').attr('class', 'shares_bg').attr('r', 16).attr('fill', '#F4F4F5');

    lines
        .append('text')
        .attr('class', 'shares')
        .attr('x', -12)
        .attr('y', 5)
        .text((d) => d.sharePercentage || 'N/A');
};

// generate defs for simple graph
export function generateDefs(group, prefix = '') {
    // filters go in defs element
    let defs = group.append('defs');
    addShadowFilter(defs, prefix);
    addArrowMarker(defs, prefix);
}

function addShadowFilter(defs, prefix = '') {
    // create filter with id #drop-shadow
    // height=130% so that the shadow is not clipped
    let filter = defs.append('filter').attr('id', prefix + 'drop-shadow');

    // SourceAlpha refers to opacity of graphic that this filter will be applied to
    // convolve that with a Gaussian with standard deviation 3 and store result
    // in blur
    filter.append('feGaussianBlur').attr('in', 'SourceAlpha').attr('stdDeviation', 2).attr('result', 'blur');

    // overlay original SourceGraphic over translated blurred opacity by using
    // feMerge filter. Order of specifying inputs is important!
    let feMerge = filter.append('feMerge');

    feMerge.append('feMergeNode').attr('in', 'offsetBlur');
    feMerge.append('feMergeNode').attr('in', 'SourceGraphic');
}

function addArrowMarker(defs, prefix = '') {
    defs.append('svg:marker')
        .attr('id', prefix + 'arrow')
        .attr('viewBox', '0 -5 10 10')
        .attr('fill', '#656565')
        .attr('refX', 10) //so that it comes towards the center.
        .attr('markerWidth', 10)
        .attr('markerHeight', 10)
        .attr('orient', 'auto')
        .append('svg:path')
        .attr('d', 'M0,-5L10,0L0,5');
}

export function formatDunsNumber(dunsNumber) {
    return [dunsNumber.slice(0, 2), '-', dunsNumber.slice(2, 5), '-', dunsNumber.slice(5, dunsNumber.length)].join('');
}

export function formatAddress(organization) {
    let addressToShow = '';
    const headOffice = 'Head Office';
    const addressArray = [headOffice, organization.address, organization.locality, organization.country.name];
    const filteredAddressArray = addressArray.filter((item) => !isNil(item));

    if (!isHeadOffice(organization)) {
        addressToShow = filteredAddressArray.slice(1).join(', ');
    } else {
        // removing comma after 'Head Office' label
        addressToShow = filteredAddressArray.join(', ').replace(',', '');
    }

    return addressToShow;
}

export function isHeadOffice(organization) {
    let isHeadOffice = false;

    if (
        organization.corporateLinkage.familytreeRolesPlayed &&
        !isEmpty(organization.corporateLinkage.familytreeRolesPlayed)
    ) {
        organization.corporateLinkage.familytreeRolesPlayed.map((item) => {
            if (item.description === 'Parent/Headquarters' && item.dnbCode === 9141) {
                isHeadOffice = true;
            }
        });
    }

    return isHeadOffice;
}

export function replaceSelectedCompanies(query, selected) {
    selected.forEach((item) => {
        let done = false,
            offset = 0;
        while (!done) {
            const position = query.indexOf(item.title, offset);
            if (position > -1) {
                const term = extractTermAtPosition(query, position);
                if (term && term.trim() === item.title) {
                    query = replaceTermAtPosition(query, position, formatDunsNumber(item.duns));
                    done = true;
                } else {
                    offset = position + term.length;
                }
            } else {
                done = true;
            }
        }
    });
    return query;
}

export function replaceSelectedCompaniesForHistory(query, selected) {
    selected.forEach((item) => {
        let done = false,
            offset = 0;
        while (!done) {
            const position = query.indexOf(item.title, offset);
            if (position > -1) {
                const term = extractTermAtPosition(query, position);
                if (term && term.trim() === item.title) {
                    query = replaceTermAtPosition(query, position, item.title + ' AND ' + formatDunsNumber(item.duns));
                    done = true;
                } else {
                    offset = position + term.length;
                }
            } else {
                done = true;
            }
        }
    });
    return query;
}

export const isBusinessNode = (node) => node.beneficiaryTypeCode === UBO_BENEFICIARY_TYPE_BUSINESS_CODE;

export const isIndividualNode = (node) =>
    !node.beneficiaryTypeCode || node.beneficiaryTypeCode === UBO_BENEFICIARY_TYPE_INDIVIDUAL_CODE;

export const isParentNode = (node) => node.childrenIds;

export const isOnLayer = (layer) => (node) => node.layer === layer;

export const isActiveNode = (node) => node.active === true || true;

export const mapOrganizationToCategory = (organization) => {
    return {
        id: organization.duns,
        title: organization.name,
        name: organization.name,
        isBranch: organization.isBranch,
        hasData: organization.hasData,
        organization: organization.globalUltimate,
        content: '',
        documentSnippets: [DNB_LABEL, formatAddress(organization), formatDunsNumber(organization.duns)],
    };
};

export function isPostFilterAllowed(categoryType, postFilterSearchField) {
    return !!UBO_POST_FILTERS[categoryType].find((postFilter) => postFilter.searchFieldName === postFilterSearchField);
}

export const formatUboCategoryPostFilters = (postFilters, category) => ({
    searchQueryType: CATEGORY_TYPE_UBO,
    searchQuery: category.searchQuery,
    term: category.searchQuery,
    category: CATEGORY_NAMES.DNB,
    uboCategoryType: category.type,
    includeTerms: [], // do we need these?
    excludeTerms: [],
    ...postFilters,
});

// generates ubo post filters, values only
export function generateUboPostFilters({ postFilters = {}, dunsList = [], terms = [] }) {
    const uboCategories = generateUboSubcategories({ dunsList, terms });

    uboCategories.forEach((category) => {
        postFilters[category.name] = formatUboCategoryPostFilters(postFilters[category.name], category);
    });
    return postFilters;
}

export function generateUboSubcategories({ dunsList = [], terms = [] }) {
    return [
        ...dunsList.map((company) => ({
            name: uboCategoryNameFormat({ company }),
            searchQuery: company.title,
            type: UBO_CATEGORY_DUNS,
        })),
        ...terms.map((term) => ({ name: uboCategoryNameFormat({ term }), searchQuery: term, type: UBO_CATEGORY_TERM })),
    ];
}

export function getUboPostFilterFields() {
    const postFilterList = union(...Object.values(UBO_POST_FILTERS));
    return postFilterList.map((postFilter) => postFilter.searchFieldName);
}

export function collide(node) {
    return function (quad) {
        var updated = false;
        if (quad.point && quad.point !== node) {
            var x = node.x - quad.point.x,
                y = node.y - quad.point.y,
                xSpacing = (quad.point.width + node.width) / 2,
                ySpacing = (quad.point.height + node.height) / 2,
                absX = Math.abs(x),
                absY = Math.abs(y),
                l,
                lx,
                ly;

            if (absX < xSpacing && absY < ySpacing) {
                l = Math.sqrt(x * x + y * y);

                lx = (absX - xSpacing) / l;
                ly = (absY - ySpacing) / l;

                // the one that's barely within the bounds probably triggered the collision
                if (Math.abs(lx) > Math.abs(ly)) {
                    lx = 0;
                } else {
                    ly = 0;
                }

                node.x -= x *= lx;
                node.y -= y *= ly;
                quad.point.x += x;
                quad.point.y += y;

                updated = true;
            }
        }
        return updated;
    };
}

export const checkNonLatinCharacters = (searchTerm) =>
    searchTerm && searchTerm.match("^[0-9A-z\u00C0-\u00ff\\s'\\.,-\\/#!$%\\^&\\*;:{}=\\-_`~()]+$");

export const shouldGatherMetrics = (historyCategory) => !historyCategory;

export const computeUboPostFiltersForPayload = (postFilters) => {
    let uboPostFiltersForPayload;

    postFilters.forEach((postFilter) => {
        const term = postFilter.term;
        postFilter.category = uboCategoryNameFormat({ term });
    });

    postFilters.forEach((postFilter) => {
        uboPostFiltersForPayload = { ...uboPostFiltersForPayload, [postFilter.category]: {} };
        getUboPostFilterFields().forEach(
            (field) => (uboPostFiltersForPayload[postFilter.category][field] = postFilter[field])
        );
    });

    return uboPostFiltersForPayload;
};
