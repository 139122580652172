import React from 'react';
import { FormattedMessage } from 'react-intl';

function PageSizeSelector(props) {
    let pageSizeList = [10, 25, 50, 100];
    return (
        <div className="page-size-container notranslate">
            <span>
                <FormattedMessage id="PageSizeSelector.label.resultsPerPage" />:
            </span>
            <select onChange={props.handlePageSizeChange} value={props.pageSize} data-testid={'page-size-select'}>
                {pageSizeList.map((item, index) => (
                    <option key={index} value={item}>
                        {item}
                    </option>
                ))}
            </select>
        </div>
    );
}

export default PageSizeSelector;
