//@flow
import * as React from 'react';
import ReportActions from '@ReportBuilder/components/ReportBuilderActions/ReportActions';
import { ACTION_MODAL_TYPE_REPORTS_CATEGORY } from '@constants';
import { FormattedMessage } from 'react-intl';
import categoryUtils from '@utils/categoryUtils';
import { TagPill } from '@reusable/Table/components/defaults';
import withAutosave from '@UserPreferences/HOC/WithAutosave';
import { Loader } from 'semantic-ui-react';
import type { ContentOptions } from '@reusable/PopupModal/Components/ModalContentOptions';
import utils from '@utils/utilities';
import type { CategoryDeleteRequest } from '@ReportBuilder/redux/flow/ReportBuilder.type.guards';

type Props = {
    categoryName: string,
    categoryLabel: string,
    reportId: string,
    searchQuery: string,
    snippetsCount: number,
    language: string,
    index: number,
    emailAddress: string,
    contentsOptions: ContentOptions,
    showDeliveryOptions: boolean,
    numberOfDocumentsPerCategory: Array<{ category: string, count: number }>,
    loadingDocumentsCounts: boolean,
    documentsCountsLoaded: boolean,
    modifiedSections: Array<string>,
    section: string,
    autosaveHasError: boolean,
    containsPublicRecords: boolean,
    onCategoryDelete: (categoryDeleteRequest: CategoryDeleteRequest) => void,
    categorySnippets: Array<{}>,
};

const CategoryHeader = (props: Props) => {
    let documentsCount: number = 0;
    if (props.numberOfDocumentsPerCategory) {
        const item: { category: string, count: number } | void = props.numberOfDocumentsPerCategory.find(
            (entry) => entry.category === props.categoryName
        );
        if (item) {
            documentsCount = utils.formatNumbersByUserLocale(props.language, item.count);
        }
    }
    return (
        <div>
            <div className="article-list-category-name">
                <div className="article-list-category-title">
                    <FormattedMessage id={'General.categoryName.label.' + props.categoryName} />
                    {categoryUtils.isNewCategory(props.categoryName) && <TagPill />}
                </div>
                <ReportActions
                    index={props.index}
                    categoryName={props.categoryName}
                    categoryLabel={props.categoryLabel}
                    emailAddress={props.emailAddress}
                    reportId={props.reportId}
                    searchQuery={props.searchQuery}
                    snippetsCount={props.snippetsCount}
                    contentsOptions={props.contentsOptions}
                    showDeliveryOptions={props.showDeliveryOptions}
                    isSnapshotDisabled={props.isSnapshotDisabled}
                    type={ACTION_MODAL_TYPE_REPORTS_CATEGORY}
                    onCategoryDelete={props.onCategoryDelete}
                    categorySnippets={props.categorySnippets}
                />
            </div>

            {props.loadingDocumentsCounts && <Loader size="small" active />}
            {props.documentsCountsLoaded && (
                <span className="count" data-testid={'documentCounts'}>
                    {' '}
                    {documentsCount}{' '}
                    <FormattedMessage id={`${documentsCount === 1 ? 'General.document' : 'General.documents'}`} />
                </span>
            )}

            <div className="horizontal-divider" />
        </div>
    );
};

export default (withAutosave()(CategoryHeader): React$StatelessFunctionalComponent<Props>);
