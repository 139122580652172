/**
 * LOGIN PAGE API CALLS
 *
 * @version 0.1
 */

import reduxStore from '@reduxStore';
import xhr from '@utils/xhr';

import { SET_HELP_URL_ROOT, USER_INFO_SUCCESS } from '@pages/Main/Main.reducers';

let api = {
    getHelpUrlRoot: function () {
        const { isAnonymized } = reduxStore.getState().user;
        const helpUrl = isAnonymized ? '/login/anonHelpUrlRoot' : '/login/helpUrlRoot';

        return xhr
            .get({
                url: helpUrl,
            })
            .then((response) => {
                reduxStore.dispatch({
                    type: SET_HELP_URL_ROOT,
                    payload: response.text,
                });
            })
            .then(() =>
                xhr.get({ url: '/login/version' }).then((response) => {
                    reduxStore.dispatch({
                        type: 'SET_VERSION',
                        payload: response.text,
                    });

                    return response;
                })
            )
            .then(() =>
                xhr.get({ url: '/api/user/settings' }).then((response) => {
                    reduxStore.dispatch({
                        type: 'USER_AUTHENTICATION_TYPE',
                        payload: response.body,
                    });
                })
            );
    },

    getUserInfo: function () {
        return xhr
            .get({
                url: '/api/user',
            })
            .then((response) => {
                // Successfully fetched user data and present the start screen (either Batch Upload or Dashboard)
                reduxStore.dispatch({
                    type: USER_INFO_SUCCESS,
                    payload: response.body,
                });
            });
    },

    getUserPreferences() {
        return xhr.get({
            url: '/api/user/preferences',
        });
    },

    getAvailableProducts() {
        return xhr
            .get({
                url: '/api/user/authorizations',
            })
            .then((response) => {
                return response ? response.body : [];
            })
            .catch(() => {
                return [];
            });
    },

    loginWam: function (searchParam) {
        return xhr.get({
            url: '/login/wam/' + searchParam,
        });
    },
};

export default api;
