import React from 'react';
import hasLoader from '../Table/hoc/hasLoader';
import { SmallLoader } from '../Table/components/defaults';
import ReactTooltip from 'react-tooltip';
import { TOOLTIP_MAX_SIZE } from '@constants';

export const NavigationButton = ({ onClick, disabled, altText, children, className = '', id }) => {
    const handleClick = () => {
        if (!disabled && onClick) {
            onClick();
        }
    };

    const getText = () => {
        // trim the string; if it's long string the react-tooltip will crash like a POS while trying to reposition itself until the end of times and/or CPU
        if (altText && altText.length > TOOLTIP_MAX_SIZE) {
            return altText.substr(0, TOOLTIP_MAX_SIZE) + '...';
        } else if (altText && altText.trim().length === 0) {
            return null;
        } else {
            return altText;
        }
    };

    return (
        <div
            className={className + ' navigation-button ' + (disabled ? 'disabled' : '')}
            onClick={handleClick}
            data-tip={altText}
            data-place={'bottom'}
            data-effect={'solid'}
            data-for={id}
        >
            {children}
            <ReactTooltip
                id={id}
                getContent={[
                    () => {
                        return getText();
                    },
                ]}
            />
        </div>
    );
};

export const PrevButton = ({ onClick, disabled = false, loading = false, altText }) => {
    const NavButton = hasLoader(SmallLoader)(NavigationButton);

    return (
        <NavButton
            onClick={onClick}
            disabled={disabled}
            data={{ isLoading: loading }}
            id={'navigation-tooltip-prev'}
            altText={altText}
        >
            <div className={'caret-left'} />
        </NavButton>
    );
};

export const NextButton = ({ onClick, disabled = false, loading = false, altText }) => {
    const NavButton = hasLoader(SmallLoader)(NavigationButton);

    return (
        <NavButton
            onClick={onClick}
            disabled={disabled}
            id={'navigation-tooltip-next'}
            data={{ isLoading: loading }}
            altText={altText}
        >
            <div className={'caret-right'} />
        </NavButton>
    );
};
