import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Transition } from 'semantic-ui-react';
import { withAdminHideView } from '@utils/contexts';

const AutosavingMessage = (props) => {
    let changesSaved = (props.isSectionSaved && !props.hasError) || false;
    let error = (props.isSectionSaved && props.hasError) || false;

    return (
        <span className="autosave-message">
            {props.isSectionSaving && <FormattedMessage id="UserPreferences_saving" />}
            <Transition visible={changesSaved} animation="fade" duration={3000} unmountOnHide={true}>
                <span>
                    <FormattedMessage id="UserPreferences_saved" />
                </span>
            </Transition>
            <Transition visible={error} animation="fade" duration={3000} unmountOnHide={true}>
                <span>
                    <FormattedMessage id="UserPreferences_autosave_error" />
                </span>
            </Transition>
        </span>
    );
};

export default withAdminHideView(AutosavingMessage);
export { AutosavingMessage as TestAutosaveMessage };
