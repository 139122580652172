import React, { Fragment } from 'react';
import { CATEGORY_NAMES, NEGATIVE_NEWS_LANGUAGES } from '@constants';
import { FormattedMessage, injectIntl } from 'react-intl';
import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';

class EditSubcategoriesModal extends React.Component {
    static propTypes = {
        closeButtonHandler: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            subCategories: props.source.children,
            selectedCount: 0,
        };

        this.onSave = this.onSave.bind(this);
        this.handleSelectAll = this.handleSelectAll.bind(this);
        this.handleChildSelection = this.handleChildSelection.bind(this);
        this.getTitle = this.getTitle.bind(this);
    }

    componentDidMount() {
        if (this.props.source.key === CATEGORY_NAMES.NEGATIVE_NEWS) {
            let orderedLanguages = [];
            Object.keys(NEGATIVE_NEWS_LANGUAGES).forEach((negativeNewsLanguage) => {
                this.props.source.children.forEach((child) => {
                    if (child.name === NEGATIVE_NEWS_LANGUAGES[negativeNewsLanguage].value) {
                        orderedLanguages.push(child);
                    }
                });
            });
            this.setState({
                subCategories: orderedLanguages,
            });
        }

        this.setState({
            selectedCount: this.getSelectedCount(this.props.source.children),
        });
    }

    closeModal = (event) => {
        if (event.currentTarget === event.target) {
            if (typeof this.props.closeButtonHandler === 'function') {
                this.props.closeButtonHandler();
            }
        }
    };

    onSave() {
        let subSources = this.state.subCategories.filter((child) => child.checked);
        this.props.onSave(this.props.source.key, subSources);
        this.props.closeButtonHandler();
    }

    handleSelectAll(e, checked) {
        e.preventDefault();
        let tempSubCategories = cloneDeep(this.state.subCategories);
        tempSubCategories.forEach((child) => {
            if (child.name !== NEGATIVE_NEWS_LANGUAGES['multiple'].value && child.enabled) {
                child.checked = checked;
            }
        });
        let selectedCount = this.getSelectedCount(tempSubCategories);
        this.setState({ subCategories: tempSubCategories, selectedCount });
    }

    handleChildSelection(e) {
        let selectedSubcategory = e.target.name;
        let isChecked = e.target.checked;

        let tempSubCategories = cloneDeep(this.state.subCategories);
        let subCategory = tempSubCategories.find(
            (child) => child.name === selectedSubcategory && child.name !== NEGATIVE_NEWS_LANGUAGES['multiple'].value
        );
        subCategory.checked = isChecked;

        let selectedCount = this.getSelectedCount(tempSubCategories);
        this.setState({ subCategories: tempSubCategories, selectedCount });
    }

    getSelectedCount(updatedSubcategories) {
        let array = updatedSubcategories ? updatedSubcategories : this.props.source.children;
        return array.filter(
            (subCategory) => subCategory.name !== NEGATIVE_NEWS_LANGUAGES['multiple'].value && subCategory.checked
        ).length;
    }

    getTitle(source) {
        switch (source) {
            case CATEGORY_NAMES.NEGATIVE_NEWS:
                return (
                    <Fragment>
                        <FormattedMessage id={`Preferences_Source.${source}`} /> -{' '}
                        <FormattedMessage id={`General.categoryName.label.languageSelection`} />
                    </Fragment>
                );
            case CATEGORY_NAMES.LAW_SOURCES:
                return (
                    <Fragment>
                        <FormattedMessage id={`Preferences_Source.${source}`} /> -{' '}
                        <FormattedMessage id={`General.categoryName.label.sourceSelection`} />
                    </Fragment>
                );
            default:
                return null;
        }
    }

    getExplanation(source) {
        if (source === CATEGORY_NAMES.LAW_SOURCES) {
            return <FormattedMessage id={`Alerts.sources.dockets.explanation`} />;
        }

        return null;
    }

    render() {
        const sourceKey = this.props.source.key;

        let availableSubCategoriesCount = this.state.subCategories.filter(
            (subCategory) => subCategory.name !== NEGATIVE_NEWS_LANGUAGES['multiple'].value && subCategory.enabled
        ).length;
        const subcategories = this.state.subCategories.map((child, index) => {
            return (
                child.name !== NEGATIVE_NEWS_LANGUAGES['multiple'].value && (
                    <li key={child.name + '_' + index} className="edit-subcategories-list-item" id={child.key}>
                        <div className={'user-preference-checkbox'}>
                            <input
                                id={'edit-subcategories-item' + child.key + '_' + index}
                                type="checkbox"
                                value={child.name}
                                name={child.name}
                                checked={child.checked ? 'checked' : ''}
                                onChange={this.handleChildSelection}
                                disabled={!child.enabled}
                            />
                            <label
                                htmlFor={'edit-subcategories-item' + child.key + '_' + index}
                                className={'preference-checkbox-label'}
                            >
                                <FormattedMessage id={'General.categoryName.label.' + child.key} />
                            </label>
                        </div>
                    </li>
                )
            );
        });

        const header = this.getTitle(sourceKey);
        const explanation = this.getExplanation(sourceKey);

        return (
            <div
                className="app-modal app-modal-edit-subcategories"
                data-testid="edit-subcategories-modal"
                onClick={this.closeModal}
            >
                <div className="app-modal__wrapper" onClick={this.closeModal}>
                    <div className="app-modal__content-box">
                        <div className="app-modal__content">
                            {typeof this.props.closeButtonHandler === 'function' ? (
                                <button
                                    type="button"
                                    className="la-CloseRemove app-modal__close-button"
                                    onClick={this.props.closeButtonHandler}
                                    tabIndex="-1"
                                ></button>
                            ) : null}
                            <div className="popup-modal">
                                <div className="popup-modal-header">{header}</div>
                                <div className="select-all-links">
                                    <a
                                        className={
                                            this.state.selectedCount >= availableSubCategoriesCount ? 'disabled' : ''
                                        }
                                        data-testid="select-all-sub-categories"
                                        href={'#'}
                                        onClick={(e) => this.handleSelectAll(e, true)}
                                    >
                                        <FormattedMessage id={'General.label.selectAll'} />
                                    </a>
                                    <a
                                        className={this.state.selectedCount <= 0 ? 'disabled' : ''}
                                        data-testid="deselect-all-sub-categories"
                                        href={'#'}
                                        onClick={(e) => this.handleSelectAll(e, false)}
                                    >
                                        <FormattedMessage id={'General.label.clearAll'} />
                                    </a>
                                </div>
                                <div className="popup-modal-body">
                                    {subcategories.length > 0 ? (
                                        <ul className={`edit-subcategories-container-list ${sourceKey}-subcategories`}>
                                            {subcategories}
                                        </ul>
                                    ) : null}
                                    <span data-testid={'edit-subcategories-dockets-explanation'}>{explanation}</span>
                                </div>
                                <div className="app-modal__buttons app-modal__buttons__right-aligned">
                                    <button
                                        type="button"
                                        data-testid="save-sub-categories"
                                        key={'edit-subcategories-modal-button-save'}
                                        className="button-primary-sm right-aligned"
                                        onClick={this.onSave}
                                    >
                                        <FormattedMessage id={'General_CoreFunctionality_UIText_general.save'} />
                                    </button>
                                    <button
                                        type="button"
                                        key={'edit-subcategories-modal-button-close'}
                                        className="button-secondary-sm right-aligned"
                                        onClick={this.closeModal}
                                    >
                                        <FormattedMessage id={'General.label.cancel'} />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default injectIntl(EditSubcategoriesModal);
