// @flow
import * as React from 'react';
import SingleArticleSidebar from '@reusable/SingleArticleSidebar/SingleArticleSidebar.index';
import PopupModal from '@reusable/PopupModal/PopupModal.index';
import SingleArticleHeader from './SingleArticleHeader';
import utils from '@utils/utilities';
import withDisclaimer from '../HOC/withDisclaimer';
import type { Article, OriginalArticleInfo } from '../redux/flow/MainSearch.type.guards';
import ElasticSingleDocumentContent from './ElasticSingleDocumentContent';
import ArticlesUtils from '@MainSearch/ArticlesUtils';
import BovDocumentView from '@reusable/BOV/BovDocumentView';
import { CATEGORY_NAMES } from '@constants';

type Props = {
    article: Article,
    backToOriginalDoc: () => void,
    category: string,
    closePopupModal: () => void,
    disclaimerMessage: string,
    handleInternalLink: (e?: MouseEvent, link?: HTMLElement) => void,
    onDownloadTable: (link?: HTMLElement | MouseEvent) => void,
    hasError: boolean,
    internalLink: boolean,
    location: {
        action: string,
        hash: string,
        key: string,
        pathname: string,
        query: {
            category: string,
            costCode: string,
            id: string,
            prefilterQuery: string,
            proximity: string,
            query: string,
            type: string,
        },
        search: string,
    },
    negativeTopics: Array<string>,
    originalArticleInfo: OriginalArticleInfo,
    popupModel: {
        buttons: Array<string>,
        comments: string,
        docType: string,
        isVisible: boolean,
        title: string,
    },
    query: boolean,
    showPopup: () => void,
    language: string,
    isBrazilianOwnershipEnabled: boolean,
    searchDocAccessEvent: () => void,
};

type State = {
    articleHits: Array<?HTMLSpanElement>,
    activeTab: number,
};

class ElasticSingleDocumentView extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
    }

    state: State = {
        articleHits: [],
        activeTab: 0,
    };

    articleContentRef: React.Ref<any> | null = React.createRef();
    articleTitleRef: React.Ref<any> | null = React.createRef();

    componentDidUpdate(prevProps: Props) {
        this.addHandlersForInternalLinks(prevProps);
        this.addHandlersForTableDownload(prevProps);

        const articleHits: Array<?HTMLSpanElement> = utils.getRenderedHitsFromReferences([
            this.articleTitleRef,
            this.articleContentRef,
        ]);

        if (articleHits.length) {
            if (this.hitsDidUpdate(articleHits)) this.setState({ articleHits });
        } else {
            if (this.state.articleHits.length > 0) this.setState({ articleHits: [] });
        }
    }

    addHandlersForInternalLinks: Function = (prevProps: Props) => {
        if (this.isNewArticle(prevProps)) {
            const internalLinks: Array<?HTMLElement> = [...document.querySelectorAll('.SS_EmbeddedLink')];

            if (internalLinks && internalLinks.length > 0) {
                internalLinks.forEach((link) => {
                    if (link) {
                        link.addEventListener('click', (e: MouseEvent) => {
                            this.props.handleInternalLink(e, link);
                        });
                    }
                });
            }
        }
    };

    addHandlersForTableDownload: Function = (prevProps: Props) => {
        if (this.isNewArticle(prevProps)) {
            const downloadTableLink: Array<?HTMLElement> = [
                ...document.querySelectorAll('[data-action="DILDownloadTable"]'),
            ];

            if (downloadTableLink && downloadTableLink.length > 0) {
                downloadTableLink.forEach((link) => {
                    if (link) {
                        link.addEventListener('click', () => {
                            this.props.onDownloadTable(link);
                        });
                    }
                });
            }
        }
    };

    hitsDidUpdate: Function = (hitsList: Array<?HTMLSpanElement>): boolean => {
        if (hitsList.length !== this.state.articleHits.length) {
            return true;
        } else {
            for (let hit = 0; hit < hitsList.length; hit++) {
                if (hitsList[hit] !== this.state.articleHits[hit]) {
                    return true;
                }
            }
        }

        return false;
    };

    hideHitsNavigator: Function = (state: number) => {
        this.setState({ activeTab: state });
    };

    componentWillUnmount() {
        const internalLinks: Array<?HTMLElement> = [...document.querySelectorAll('.SS_EmbeddedLink')];
        const downloadTableLinks: Array<?HTMLElement> = [
            ...document.querySelectorAll('[data-action="DILDownloadTable"]'),
        ];

        if (internalLinks && internalLinks.length > 0) {
            internalLinks.forEach((link) => {
                if (link) {
                    link.removeEventListener('click', this.props.handleInternalLink);
                }
            });
        }

        if (downloadTableLinks && downloadTableLinks.length > 0) {
            downloadTableLinks.forEach((link) => {
                if (link) {
                    link.removeEventListener('click', this.props.onDownloadTable);
                }
            });
        }
    }

    isNewArticle: Function = (prevProps: Props): boolean => {
        return (
            this.props.article &&
            prevProps.article &&
            (this.props.article.content !== prevProps.article.content ||
                this.props.article.id !== prevProps.article.id ||
                this.props.hasError !== prevProps.hasError)
        );
    };

    setArticleTitleRef: React.Ref<any> = (ref) => (this.articleTitleRef = ref);
    setArticleContentRef: React.Ref<any> = (ref) => (this.articleContentRef = ref);

    render(): React.Node {
        const {
            isBrazilianOwnershipEnabled,
            disclaimerMessage,
            article,
            hasError,
            searchDocAccessEvent,
            originalArticleInfo,
        } = this.props;
        const shouldShowBrazilian = !!(
            isBrazilianOwnershipEnabled && ArticlesUtils.isBrazilianArticle(this.props.article)
        );
        const singleDocumentContentProps = {
            disclaimerMessage,
            article,
            hasError,
            setArticleTitleRef: this.setArticleTitleRef,
            setArticleContentRef: this.setArticleContentRef,
            searchDocAccessEvent,
            activeTab: this.state.activeTab,
            handleHideHitsNavigator: this.hideHitsNavigator,
            originalArticleInfo,
        };

        const category = originalArticleInfo.category;
        const isEsgRatingsCategory = category === CATEGORY_NAMES.ESG_RATINGS;

        return (
            <div className="single-article-view">
                {this.props.popupModel.isVisible && <PopupModal closeButtonHandler={this.props.closePopupModal} />}
                <SingleArticleHeader
                    article={this.props.article}
                    showPopup={this.props.showPopup}
                    query={this.props.query}
                    internalLink={this.props.internalLink}
                    originalArticleInfo={this.props.originalArticleInfo}
                    currentArticleID={this.props.article && this.props.article.id}
                    backToOriginalDoc={this.props.backToOriginalDoc}
                    hasError={this.props.hasError}
                    hits={this.state.articleHits}
                    activeTab={this.state.activeTab}
                    key={this.props.article.id + ' - ' + this.state.articleHits.length}
                    isEsgRatingsCategory={isEsgRatingsCategory}
                />
                {
                    // eslint-disable-next-line
                    <div className="single-article-result__general" ref={'singleArticleContent'}>
                        <div
                            className={`single-article-content ${
                                shouldShowBrazilian ? `bov-document-body ${hasError ? 'error' : ''}` : ''
                            }${isEsgRatingsCategory ? ' esg-ratings-document-body' : ''}`}
                        >
                            {shouldShowBrazilian ? (
                                <BovDocumentView {...singleDocumentContentProps} />
                            ) : (
                                <ElasticSingleDocumentContent
                                    {...singleDocumentContentProps}
                                    isEsgRatingsCategory={isEsgRatingsCategory}
                                />
                            )}
                        </div>
                        <SingleArticleSidebar
                            article={this.props.article}
                            query={this.props.location.query.query}
                            searchQueryType={this.props.location.query.type}
                            articleType={this.props.location.query.category}
                            negativeTopics={this.props.negativeTopics}
                            language={this.props.language}
                        />
                    </div>
                }
            </div>
        );
    }
}

export { ElasticSingleDocumentView as TestElasticSingleDocumentView };
export default (withDisclaimer(ElasticSingleDocumentView): any);
