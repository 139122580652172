import React from 'react';
import utils from '@utils/utilities';
import { Loader, Segment } from 'semantic-ui-react';

export const withLoadingSpinner = (Component) => {
    const showLoadingSpinner = (props) =>
        props.isLoading && props.loader ? (
            <div className={props.loader.wrapperClassName}>
                <Segment className={props.loader.loaderClassName}>
                    <Loader size="large" active />
                </Segment>
            </div>
        ) : (
            <Component {...props} />
        );

    showLoadingSpinner.displayName = `showLoadingSpinner(${utils.getDisplayName(Component)})`;

    return showLoadingSpinner;
};
