import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { MODAL_TYPE, NEGATIVE_NEWS_LANGUAGES } from '@constants';
import PropTypes from 'prop-types';
import utils from '@utils/utilities';
import AdminLock from '@reusable/AdminLock/AdminLock';
import withAutosave from '../HOC/WithAutosave';
import { isEqual } from 'lodash';

class NegativeNewsLanguageSelection extends Component {
    static defaultProps = {
        fieldId: 'negativeNewsLanguages',
    };

    static propTypes = {
        fieldId: PropTypes.string,
        showPopup: PropTypes.func,
        toggleLanguageEditMode: PropTypes.func,
        segment: PropTypes.string,
        negativeNewsLanguages: PropTypes.object,
        isEditModeEnabled: PropTypes.bool,
    };

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidUpdate(prevProps) {
        if (!this.props.userIsAdmin && !isEqual(this.props.languages, prevProps.languages)) {
            this.props.changeMade(this.props.section);
        }
    }

    updateCheckedLanguage = (segment, language, checked) => {
        this.props.handleAutosave(this.props.handleNegativeNewsLanguageSelection, [segment, language, checked]);
    };

    render() {
        let orderedLanguages = [];
        Object.keys(NEGATIVE_NEWS_LANGUAGES).forEach((negativeNewsLanguage) => {
            this.props.languages.forEach((key) => {
                if (key.language === NEGATIVE_NEWS_LANGUAGES[negativeNewsLanguage].value) {
                    orderedLanguages.push(key);
                }
            });
        });

        return (
            <div id={this.props.section} ref={this.props.section} className="user-preferences-negativeNews-languages">
                <div className="user-preferences-category-container user-preferences-negativeNews-languages">
                    {this.props.userIsAdmin ? (
                        <AdminLock
                            lockName={this.props.lockName}
                            onClickLock={this.props.handleLockClick}
                            isSectionLock={true}
                            isLocked={this.props.isReadonly}
                        />
                    ) : (
                        this.props.isReadonly && <AdminLock lockName={this.props.lockName} isDisabled={true} />
                    )}

                    <div className="user-preferences-category-container__row  container-section__segment__header">
                        <h3
                            className="user-preferences-negativeNews-language-topic"
                            data-track={`user-preferences-negativeNews-language-topic__${this.props.segment}`}
                        >
                            <FormattedMessage id={'UserPreferences.negativeNews.languages.topic'} />
                        </h3>
                        {this.props.autoSaveMessage}
                    </div>
                    <div className="user-preferences-negativeNews-languages__header__toggle-edit">
                        <div
                            className="user-preferences-negativeNews-languages__header__toggle-edit-info"
                            data-track={`user-preferences-negativeNews-languages__header__toggle-edit-info__${this.props.segment}`}
                        >
                            <span className="la-Edit user-preferences-negativeNews-languages__header__toggle-edit__icon" />
                            <span className="user-preferences-negativeNews-languages__header__toggle-edit__label">
                                <FormattedMessage id={'UserPreferences.negativeNews.languages.toggle.edit'} />
                            </span>
                        </div>
                        <div
                            className={
                                !this.props.userIsAdmin && this.props.isReadonly
                                    ? 'custom-toggle disabled'
                                    : 'custom-toggle'
                            }
                            data-track={`user-preferences-negativeNews-languages__header__toggle-edit__custom-toggle__${this.props.segment}`}
                        >
                            <input
                                type="checkbox"
                                checked={this.props.isEditModeEnabled}
                                onChange={(e) => {
                                    this.props.toggleLanguageEditMode(e);
                                }}
                                className="custom-toggle-checkbox"
                                id={this.props.fieldId + this.props.segment}
                            />
                            <label className="custom-toggle-label" htmlFor={this.props.fieldId + this.props.segment} />
                        </div>
                    </div>
                    <div>
                        <FormattedMessage id={'UserPreferences.negativeNews.languages.topicExplanation'} />
                    </div>
                    <ul className="user-preferences-category-container__list">
                        {orderedLanguages.map((item) => {
                            let language = item.language;
                            return (
                                utils.negativeNewsLanguageObject(orderedLanguages, language) &&
                                utils.negativeNewsLanguageObject(orderedLanguages, language).language === language &&
                                language !== NEGATIVE_NEWS_LANGUAGES['multiple'].value && (
                                    <li
                                        key={this.props.segment + '_' + language}
                                        className="user-preference-list-item user-preferences-negativeNews-languages__list-item"
                                        id={language}
                                    >
                                        <div
                                            className="user-preference-checkbox"
                                            data-track={`user-preference-language-checkbox-${this.props.segment}-${language}`}
                                        >
                                            <input
                                                id={'user-preferences-check_' + this.props.segment + '_' + language}
                                                type="checkbox"
                                                value={language}
                                                title={language}
                                                checked={item.checked === true ? 'checked' : ''}
                                                onChange={() => {
                                                    this.updateCheckedLanguage(
                                                        this.props.segment,
                                                        language,
                                                        !item.checked
                                                    );
                                                }}
                                                disabled={
                                                    (!this.props.userIsAdmin && this.props.isReadonly) ||
                                                    this.props.isLanguageDisabled(
                                                        language,
                                                        this.props.defaultSources
                                                    ) === false
                                                }
                                                data-testid={`user-preferences-check-${this.props.segment}-${language}-${item.checked ? 'enabled' : 'disabled'}`}
                                            />
                                            <label
                                                htmlFor={
                                                    'user-preferences-check_' + this.props.segment + '_' + language
                                                }
                                                className={
                                                    !this.props.userIsAdmin && this.props.isReadonly
                                                        ? 'preference-checkbox-label disabled'
                                                        : 'preference-checkbox-label'
                                                }
                                            >
                                                {this.props.negativeNewsLanguages[language.toLowerCase()] && (
                                                    <FormattedMessage
                                                        id={
                                                            this.props.negativeNewsLanguages[language.toLowerCase()]
                                                                .label
                                                        }
                                                    />
                                                )}
                                            </label>
                                        </div>

                                        {this.props.isEditModeEnabled && item.checked && (
                                            <button
                                                className="user-preferences-negativeNews-languages__list-item__action"
                                                value={MODAL_TYPE.USER_PREFERENCES_EDIT_NEGATIVE_SEARCH_TERMS}
                                                onClick={this.props.showPopup}
                                                title={language}
                                                disabled={this.props.isReadonly}
                                            >
                                                <div
                                                    className="edit-button"
                                                    data-track={`user-preference-language-checkbox-${this.props.segment}-${language}__edit-button`}
                                                >
                                                    <span className="la-Edit" />
                                                    <FormattedMessage id="General_CoreFunctionality_UIText_general.edit" />
                                                </div>
                                            </button>
                                        )}
                                    </li>
                                )
                            );
                        })}
                    </ul>
                </div>
            </div>
        );
    }
}

export default withAutosave()(NegativeNewsLanguageSelection);
