import React from 'react';

import utils from '@utils/utilities';
import xhr from '@utils/xhr';
import reduxStore from '@reduxStore';

import Header from '@reusable/Header/Header.index.jsx';
import Footer from '@reusable/Footer/Footer.index.jsx';
import LoadingSpinner from '@reusable/LoadingSpinner/LoadingSpinner.index.jsx';
import { FormattedMessage } from 'react-intl';

// WARNING
// This page is used by WAM to redirect to our logout page. It should not be used internally by our app.

const LOADING_STATE = 'LOADING_STATE';
const SUCCESS_STATE = 'SUCCESS_STATE';
const FAIL_STATE = 'FAIL_STATE';

export default class LogoutPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            requestState: LOADING_STATE,
        };
    }

    componentDidMount() {
        let loaderPage = document.getElementById('diligence-loader-page');
        if (loaderPage) {
            loaderPage.parentNode.removeChild(loaderPage);
        }

        xhr.post({
            url: '/api/user/logout',
        })
            .then((response) => {
                if (response.ok) {
                    reduxStore.dispatch({
                        type: 'USER_LOGOUT_SUCCESS',
                    });
                    utils.clearUserSession();
                    this.setState({ requestState: SUCCESS_STATE });
                }
            })
            .catch((error) => {
                utils.logError(error, 'Fail to log out');
                reduxStore.dispatch({
                    type: 'USER_LOGOUT_FAIL',
                });
                this.setState({ requestState: FAIL_STATE });
            });
    }

    render() {
        return (
            <div className="app-wrapper" id="app-wrapper-id">
                {this.state.requestState === LOADING_STATE ? (
                    <LoadingSpinner fixedSpinner={true} isVisible={true} />
                ) : null}
                <Header disableMenu />
                <div className="app-content-wrapper">
                    {this.state.requestState === SUCCESS_STATE ? (
                        <FormattedMessage id="Authentication_CoreFunctionality_UIText_logout.success" />
                    ) : null}
                    {this.state.requestState === FAIL_STATE ? (
                        <FormattedMessage id="Authentication_CoreFunctionality_UIText_logout.fail" />
                    ) : null}
                </div>
                <Footer version={reduxStore.getState().header.version} />
            </div>
        );
    }
}
