import React from 'react';
import { FormattedMessage } from 'react-intl';
import utils from '@utils/utilities';
import { MAX_INPUT_LENGTH } from '@constants';

function ModalEntityLabel({ displayNameError, entityLabel, editAction }) {
    const onChange = (event) => {
        const value = event.target.value;
        const error = utils.isEntityNameValid(value)
            ? null
            : 'General_CoreFunctionality_UIText_general.entityNameValidation';

        editAction({ displayName: value, displayNameError: error });
    };

    return (
        <div className="popup-modal__entity-name">
            <div className="popup-field-name">
                <FormattedMessage id="BatchScreening.popup.editSearch.createLabel" />
            </div>
            {displayNameError && (
                <div className="validationAlertMessage">
                    <FormattedMessage id={displayNameError} />
                </div>
            )}
            <input
                className={`text-field-standard-lg ${displayNameError ? 'text-field-error' : ''}`}
                data-testid="modal-entity-label-testid"
                value={entityLabel}
                onChange={onChange}
                maxLength={MAX_INPUT_LENGTH}
            />
        </div>
    );
}

export default ModalEntityLabel;
