// @flow
import React, { Fragment } from 'react';
import withAutosave from '../HOC/WithAutosave';
import { FE_LOCK_NAMES } from '@constants';
import { FormattedMessage } from 'react-intl';
import ProximitySelect from './ProximitySelect';
import AdminLock from '@reusable/AdminLock/AdminLock';
import ExcludeNewsWrapper from './ExcludeNewsWrapper';

type Props = {
    autoSaveMessage: Object,
    onClickLock: () => void,
    userIsAdmin: boolean,
    proximityLock: boolean,
    masterLock: boolean,
    newsExcludeTogglesEnabled: boolean,
    proximities: Array<string>,
    proximity: string,
    excludeNewsWires: boolean,
    excludeNonBusinessNews: boolean,
    changeProximity: (proximity: string) => void,
    changeExcludeNewsWires: (event: SyntheticInputEvent<HTMLInputElement>) => void,
    changeExcludeNonBusinessNews: (event: SyntheticInputEvent<HTMLInputElement>) => void,
};

type InjectedProps = {|
    handleAutosave: (handler: (params: Object) => void, params: Object) => void,
|};

const NewsSearches = (props: Props & InjectedProps) => {
    const changeProximity = (proximity: string) => {
        props.handleAutosave(props.changeProximity, [proximity]);
    };

    const handleExcludeNewsWires = (event) => {
        props.handleAutosave(props.changeExcludeNewsWires, [event]);
    };

    const handleExcludeNonBusinessNews = (event) => {
        props.handleAutosave(props.changeExcludeNonBusinessNews, [event]);
    };

    return (
        <Fragment>
            <div className="user-preferences-category-container__row">
                <h3 className="user-preferences-category-container__topic" data-track="news_source_searches-title">
                    <FormattedMessage id="UserPreferences_topic_news_source_searches" />
                </h3>
                {props.autoSaveMessage}
            </div>

            <ProximitySelect
                proximities={props.proximities}
                proximity={props.proximity}
                changeProximity={changeProximity}
                adminLock={
                    (props.userIsAdmin || (props.proximityLock && !props.masterLock)) && (
                        <AdminLock
                            lockName={FE_LOCK_NAMES.newsSearchesProximitySetting}
                            onClickLock={props.onClickLock}
                            isLocked={props.proximityLock}
                            isDisabled={!props.userIsAdmin && (props.masterLock || props.proximityLock)}
                        />
                    )
                }
                disabledDropdown={!props.userIsAdmin && (props.masterLock || props.proximityLock)}
            >
                <div className="user-preferences-category-container__note">
                    <FormattedMessage id="UserPreferences_proximity_label_news_source" />
                </div>
            </ProximitySelect>
            {props.newsExcludeTogglesEnabled && (
                <ExcludeNewsWrapper
                    userIsAdmin={props.userIsAdmin}
                    masterLock={props.masterLock}
                    excludeNewsWires={props.excludeNewsWires}
                    handleExcludeNewsWires={handleExcludeNewsWires}
                    excludeNonBusinessNews={props.excludeNonBusinessNews}
                    handleExcludeNonBusinessNews={handleExcludeNonBusinessNews}
                />
            )}
        </Fragment>
    );
};

export default (withAutosave()(NewsSearches): React$StatelessFunctionalComponent<Props>);
