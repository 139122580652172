import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Button from '../Buttons/Button';
import { FormattedMessage } from 'react-intl';

export default class DunsInput extends Component {
    static propTypes = {
        value: PropTypes.string,
        onChange: PropTypes.func,
        hasConfirm: PropTypes.bool,
        placheholderTextId: PropTypes.string,
        className: PropTypes.string,
    };

    static defaultProps = {
        dunsNumber: '',
        value: '',
        hasConfirm: true,
        placheholderTextId: '',
        className: 'search-input',
    };

    constructor(props) {
        super(props);

        this.state = {
            hasError: false,
            duns: props.value,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.inputEl = null;
    }

    handleChange(e) {
        const duns = cleanDuns(e.target.value);
        const oldDuns = this.state.duns;
        let selectionStart = this.inputEl.selectionStart;
        this.setState({ duns }, () => {
            // jumping over "-" in duns number
            if (duns.length - oldDuns.length === 1 && (selectionStart === 3 || selectionStart === 7)) {
                // we jump forward one space
                selectionStart++;
            }
            if (oldDuns.length - duns.length === 1 && (selectionStart === 3 || selectionStart === 7)) {
                //  we jump back one space
                selectionStart--;
            }
            // setting the cursor to the right place
            this.inputEl.selectionStart = selectionStart;
            this.inputEl.selectionEnd = selectionStart;
            if (!this.props.hasConfirm) {
                this.handleSubmit();
            }
            // clear error if all good
            if (this.state.hasError && isValidDuns(this.state.duns)) {
                this.setState({ hasError: false });
            }
        });
    }

    handleSubmit() {
        if (isValidDuns(this.state.duns)) {
            this.setState({ hasError: false });
            this.props.onChange(this.state.duns);
        } else {
            this.setState({ hasError: true });
        }
    }

    render() {
        return (
            <Fragment>
                {this.state.hasError && <InvalidDunsMsg />}
                <div
                    className={`edit-third-party__about__input-group ${this.props.className} ${
                        this.state.hasError ? 'edit-third-party__about__input-group__warning' : ''
                    } `}
                >
                    <input
                        type="text"
                        ref={(el) => (this.inputEl = el)}
                        maxLength="11"
                        id="edit-third-party__about__duns"
                        name="dunsNumber"
                        placeholder="00-000-0000"
                        value={formatDunsNumber(this.state.duns)}
                        onChange={this.handleChange}
                    />
                    {this.props.hasConfirm && <DunsConfirmBtn onClick={this.handleSubmit} />}
                </div>
            </Fragment>
        );
    }
}

export const DunsConfirmBtn = (props) => <Button className={'la-SWResearch'} {...props} />;

export const InvalidDunsMsg = () => (
    <div className={'validationAlertMessage'}>
        <FormattedMessage id="StartPage.UBO.dunsFilter.dunsError" />
    </div>
);

const cleanDuns = (dunsNumber) => {
    return dunsNumber.trim().split('-').join('');
};

const formatDunsNumber = (duns) => {
    let dunsNumber = duns;
    if (duns.length > 2) {
        dunsNumber = duns.slice(0, 2) + '-' + duns.slice(2, 5);
    }
    if (duns.length > 5) {
        dunsNumber = duns.slice(0, 2) + '-' + duns.slice(2, 5) + '-' + duns.slice(5, 9);
    }
    return dunsNumber;
};

const isValidDuns = (duns) => {
    if (duns.length !== 9) {
        return false;
    }

    return duns.split('').reduce((acc, current) => acc && !isNaN(current), true);
};
