import React from 'react';
import ModalDefaultSources from './ModalDefaultSources';
import { COMPANY_SEARCH, FE_LOCK_NAMES, PERSON_SEARCH } from '@constants';
import ModalCostCodes from './ModalCostCodes';

export const ModalAdHocSearch = (props) => {
    if (!props.isVisible) {
        return null;
    }

    const getLocks = (searchQueryType) => {
        switch (searchQueryType) {
            case PERSON_SEARCH:
                return {
                    masterLock: props.userLocks.masterLockNewsSearchesPerson,
                    newsLock: props.userLocks.newsSearchSourcesPerson,
                    newsLockName: FE_LOCK_NAMES.newsSearchSourcesPerson,
                };
            case COMPANY_SEARCH:
                return {
                    masterLock: props.userLocks.masterLockNewsSearchesCompany,
                    newsLock: props.userLocks.newsSearchSourcesCompany,
                    newsLockName: FE_LOCK_NAMES.newsSearchSourcesCompany,
                };
            default:
                return {};
        }
    };

    return (
        <div className="addHoc-scrollable-area">
            <ModalDefaultSources
                lawSources={props.lawSources}
                sources={props.sources}
                lawSource={props.lawSource}
                changeLegalSource={props.changeLegalSource}
                newsSearchSources={props.newsSearchSources}
                changeLanguageSelection={props.changeLanguageSelection}
                masterLock={props.userLocks.masterLockNewsCompanyLegalSearches}
                legalSourceSearchesLock={props.userLocks.legalSourceDefaultLegalSources}
                searchTypeLock={getLocks(props.searchQueryType)}
                adHocMasterLock={props.adHocMasterLock}
                changeSearchNamePartyEntity={props.changeSearchNamePartyEntity}
                searchNamePartyEntity={props.searchNamePartyEntity}
            />

            <ModalCostCodes
                costCode={props.costCode}
                costCodes={props.costCodes}
                chooseOnlyFromCostCodesList={props.chooseOnlyFromCostCodesList}
                isCostCodeRequired={props.isCostCodeRequired}
                isCustomCostCode={props.isCustomCostCode}
                changeCostCode={props.changeCostCode}
            />
        </div>
    );
};
