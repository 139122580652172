import React from 'react';
import { FormattedMessage } from 'react-intl';

function ModalReportList(props) {
    if (!props.isVisible) {
        return <span></span>;
    }

    return (
        <div className="popup-modal__report-list">
            {!props.isReportsSelectionValid && (
                <div className="validationAlertMessage">
                    <FormattedMessage id={'ReportSelectionModalValidation.' + props.reportsSelectionErrorMessage} />
                </div>
            )}
            <div className="select-all-buttons">
                <button
                    className="button-select-reports"
                    id="qa-select-builder-page-button"
                    onClick={() => {
                        props.handleSelectAll(true);
                    }}
                    disabled={props.checkedAll}
                >
                    <FormattedMessage id="General.label.selectAll" />
                </button>
                <button
                    className="button-select-reports"
                    id="qa-deselect-builder-page-button"
                    onClick={() => {
                        props.handleSelectAll(false);
                    }}
                    disabled={!props.checkedAll}
                >
                    <FormattedMessage id="General.label.deselectAll" />
                </button>
            </div>
            <div className="divider"></div>
            <div className="reports-list-titles">
                {props.reports.map((report) => {
                    return (
                        <div key={report.id} className="reports-list-title">
                            <div className="custom-checkbox">
                                <input
                                    type="checkbox"
                                    id={report.id}
                                    value={report.id}
                                    onChange={props.handleCheck}
                                    checked={props.isReportChecked(report.id)}
                                />
                                <label htmlFor={report.id} className="checkbox-label">
                                    {report.title}
                                </label>
                            </div>
                        </div>
                    );
                })}
            </div>
            <div className="divider"></div>
        </div>
    );
}

export default ModalReportList;
