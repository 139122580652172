const test502BadGateway = (req) => {
    return req.error && req.error.status === 502;
};

const test403 = (req) => {
    return req.error && req.error.status === 403;
};

const test404 = (req) => {
    return req.error && req.error.status === 404;
};

// add here more test functions for silencing the error logger
export const requestFilterFn = [test403, test404, test502BadGateway];

// function to clean up the redux state, QueryString, "payload", "sourcesInformation" props are usually large
export const cleanFunc = (key) =>
    key.indexOf('QueryString') > -1 || // Query Strings
    key === 'payload' || // Payload
    key === 'sourcesInformation'; // Sources information
