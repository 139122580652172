import utils from './utilities';
import xhr from './xhr';
import SingletonMixin from 'singleton-mixin';
import Promise from 'bluebird';
import { findIndex } from 'lodash';

export const PRIORITY_5 = 5;
export const PRIORITY_3 = 3;
export const PRIORITY_2 = 2;
export const PRIORITY_1 = 1;
export const PRIORITY_0 = 0;
export const PROMISE_CANCELED = 'promise canceled';

export const URL_FUZZY = '/api/search/fuzzy';
export const URL_REPORT_SEARCH = '/api/report/search';
export const URL_PR_SEARCH_FORM = '/api/publicrecords/searchforms';
export const URL_LOGOUT = '/api/user/logout';
export const URL_CREATE_REPORT = 'api/report/save';
export const URL_SUGGESTED_NAMES = '/api/search/suggestedNames';

export const queueSettings = {
    queueSize: 6,
    maximPriorityUrl: 'user/preferences',
    highPriorityUrlFragment: 'logout',
    lowPriorityUrlFragment: 'search',
    billingUrlFragment: 'searchEvent',
    defaultPriority: PRIORITY_2,
};

function DilQueue(settings) {
    settings = Object.assign(queueSettings, settings);
    Object.defineProperties(this, {
        defaultPriority: {
            enabled: true,
            writable: true,
            value: settings.defaultPriority,
        },
        billingUrlFragment: {
            enumerable: true,
            writable: true,
            value: settings.billingUrlFragment,
        },
        lowPriorityUrlFragment: {
            enumerable: true,
            writable: true,
            value: settings.lowPriorityUrlFragment,
        },
        highPriorityUrlFragment: {
            enumerable: true,
            writable: true,
            value: settings.highPriorityUrlFragment,
        },
        maximPriorityUrl: {
            enumerable: true,
            writable: true,
            value: settings.maximPriorityUrl,
        },
        queueSize: {
            enumerable: true,
            writable: true,
            value: settings.queueSize,
        },
        executionPipe: {
            enumerable: true,
            writable: false,
            value: getExecutionPipe,
        },
        add: {
            enumerable: true,
            writable: false,
            value: addToQueue,
        },
        next: {
            enumerable: true,
            writable: false,
            value: run,
        },
        abortLowPriorityTasks: {
            enumerable: true,
            writable: false,
            value: abortLowPriorityTasks,
        },
        abortAllTasks: {
            enumerable: true,
            writable: false,
            value: abortAllTasks,
        },
        abortCallByUrl: {
            enumerable: true,
            writable: false,
            value: abortCallByUrl,
        },
        autoRun: {
            enumerable: true,
            writable: true,
            value: true,
        },
    });
    let queue = [];
    let executionPipe = [];
    let idDebugEnabled = false;

    function log() {
        if (idDebugEnabled) {
            console.log(Object.values(arguments));
        }
    }

    function getExecutionPipe() {
        return executionPipe;
    }

    function removeItemFromQueue(task) {
        let currentIndex = -1;
        queue.forEach((item, index) => {
            if (item === task) {
                currentIndex = index;
            }
        });
        if (currentIndex > -1) {
            queue.splice(currentIndex, 1);
        } else {
            log('!!!item not found in queue', getUrlFromRequest(task.data));
        }
    }

    function abortCallByUrl(url) {
        log('---   begin aborting url ----', url, queue.length, executionPipe.length);
        let itemsToBeRemoved = queue.filter((item) => {
            return item.data.url === url;
        });
        itemsToBeRemoved.forEach((item) => {
            log('-reject task from queue ', getUrlFromRequest(item.data));
            item.rejectTask(new Error(PROMISE_CANCELED));
            removeItemFromQueue(item);
        });
        for (let i = queue.length - 1; i >= 0; i--) {
            if (queue[i].data.url === url) {
                log(' item should be already removed from queue ', getUrlFromRequest(queue[i].data));
                queue[i].reject(new Error(PROMISE_CANCELED));
                queue.splice(i, 1);
            }
        }

        executionPipe.forEach((item) => {
            if (item.data.url === url) {
                log(' canceling promise from pipeline', getUrlFromRequest(item.data));
                item.xhrPromise.cancel();
                item.rejectTask(new Error(PROMISE_CANCELED));
            }
        });
        log('----------after aborting low priority tasks ---------', queue.length, executionPipe.length);
    }

    function abortLowPriorityTasks() {
        let promises = [];
        //remove low priority from queue
        log('---------- begin aborting low priority tasks ---------', queue.length, executionPipe.length);
        let itemsToBeRemoved = queue.filter((item) => {
            return item.priority === PRIORITY_5;
        });
        itemsToBeRemoved.forEach((item) => {
            promises.push(item.promise);
            log('-reject task from queue ', getUrlFromRequest(item.data));
            item.rejectTask(new Error(PROMISE_CANCELED));
            // removeItemFromQueue(item);
        });
        //TODO: combine with next iterator
        // for (let i = queue.length - 1; i >= 0; i--) {
        //     if (queue[i].priority === PRIORITY_5) {
        //         log(' item should be already removed from queue ', getUrlFromRequest(queue[i].data));
        //         queue[i].reject(new Error(PROMISE_CANCELED));
        //         queue.splice(i, 1)
        //     }
        // }

        executionPipe.forEach((item) => {
            if (item.priority === PRIORITY_5) {
                promises.push(item);
                log(' canceling promise from pipeline', getUrlFromRequest(item.data));
                item.xhrPromise.cancel();
                item.rejectTask(new Error(PROMISE_CANCELED));
            }
        });
        log('----------after aborting low priority tasks ---------', queue.length, executionPipe.length);
        return Promise.all(promises).catch((error) => {
            if (error.message !== PROMISE_CANCELED) {
                console.log('--->promise all error ', error.message);
            }
        });
    }

    function abortAllTasks() {
        queue.forEach((item) => {
            item.rejectTask(new Error(PROMISE_CANCELED));
        });
        queue.length = 0;
        executionPipe.forEach((item) => {
            item.xhrPromise.cancel();
            item.rejectTask(new Error(PROMISE_CANCELED));
        });
        executionPipe.length = 0;
    }

    function getTaskPriority(data) {
        let priority = this.defaultPriority;
        if (data.url.indexOf(this.billingUrlFragment) > -1 || data.url.indexOf(URL_REPORT_SEARCH) > -1) {
            priority = PRIORITY_2;
        } else if (data.url.indexOf(this.lowPriorityUrlFragment) > -1 && data.url.indexOf(URL_FUZZY) === -1) {
            if (data.data && data.data.pageSize !== 0) {
                priority = PRIORITY_3;
            } else {
                priority = PRIORITY_5;
            }
        } else if (data.url.indexOf(this.highPriorityUrlFragment) > -1) {
            priority = PRIORITY_1;
        } else if (data.url.indexOf(this.maximPriorityUrl) > -1) {
            priority = PRIORITY_0;
        }

        return priority;
    }

    function getUrlFromRequest(data) {
        let url = data.url;
        if (data.data) {
            url += '/' + data.data.category + '/' + data.data.csi;
        }
        return url;
    }

    function addToQueue(data) {
        let promise;
        let resolveTask, rejectTask;
        promise = new Promise((resolve, reject, onCancel) => {
            resolveTask = resolve;
            rejectTask = reject;
            onCancel(() => {
                return reject(new Error(PROMISE_CANCELED));
            });
        });

        let priority = getTaskPriority.call(this, data);
        queue.push({ priority, data, promise, resolveTask, rejectTask });
        log('--adding to queue ', priority, getUrlFromRequest(data), queue.length, executionPipe.length);

        this.next();

        return promise;
    }

    function removeTaskFromPipeLine(task) {
        let currentIndex = -1;
        executionPipe.forEach((item, index) => {
            if (item === task) {
                currentIndex = index;
            }
        });
        if (currentIndex > -1) {
            executionPipe.splice(currentIndex, 1);
            log('-- remove task from pipeline ', getUrlFromRequest(task.data));
        }

        this.next();
        return null;
    }

    function isBlockingPriorityTaskInPipeLine() {
        let index = findIndex(executionPipe, (item) => {
            return item.priority === PRIORITY_0;
        });
        return index > -1;
    }

    function run() {
        if (queue.length === 0) {
            log('running queue, empty, exiting ', queue.length, executionPipe.length);
            return;
        }
        if (executionPipe.length < this.queueSize) {
            let sortedQueue = utils.sortByProperty(queue, 'priority');
            if (sortedQueue[0].priority === PRIORITY_5 && executionPipe.length >= this.queueSize - 1) {
                //ignore the item
                log(
                    'pipeline busy ',
                    executionPipe.length,
                    sortedQueue[0].priority,
                    getUrlFromRequest(sortedQueue[0].data)
                );
                return;
            } else {
                //check if an blocking priority task is running
                if (isBlockingPriorityTaskInPipeLine()) {
                    return;
                }
                let task = sortedQueue.shift();
                executionPipe.push(task);
                let promise = xhr
                    .executeCall(task.data)
                    .then((response) => {
                        removeTaskFromPipeLine.call(this, task);
                        task.resolveTask(response);
                        return response;
                    })
                    .catch((error) => {
                        removeTaskFromPipeLine.call(this, task);
                        if (error.status == 401) {
                            if (task.data.url === URL_PR_SEARCH_FORM || task.data.url === URL_LOGOUT) {
                                // do nothing
                            } else {
                                localStorage.setItem(
                                    'diligence-deep-link',
                                    JSON.stringify({
                                        mainUrl: window.location.hash,
                                    })
                                );

                                let clientidauthtoken = utils.getUrlQueryParam('clientidauthtoken')
                                    ? 'clientidauthtoken=' + utils.getUrlQueryParam('clientidauthtoken')
                                    : null;
                                let clientauthtoken = utils.getUrlQueryParam('clientauthtoken')
                                    ? 'clientauthtoken=' + encodeURIComponent(utils.getUrlQueryParam('clientauthtoken'))
                                    : null;
                                let identityprofileid = utils.getUrlQueryParam('identityprofileid')
                                    ? 'identityprofileid=' + utils.getUrlQueryParam('identityprofileid')
                                    : null;
                                let federationidp = utils.getUrlQueryParam('federationidp')
                                    ? 'federationidp=' + utils.getUrlQueryParam('federationidp')
                                    : null;
                                let queryParams = [clientidauthtoken, clientauthtoken, identityprofileid, federationidp]
                                    .filter(Boolean)
                                    .join('&');
                                queryParams = queryParams && queryParams.length ? `?${queryParams}` : queryParams;

                                window.location = `${error.response.body.wamLogin}?back=${encodeURIComponent(
                                    error.response.body.customerUi
                                )}${encodeURIComponent(queryParams)}&aci=${error.response.body.wamAciCode}`;

                                return null;
                            }
                        }
                        task.rejectTask(error);
                        return null;
                    })
                    .finally(() => {
                        if (promise.isCancelled()) {
                            log('---- xhr canceled: ', getUrlFromRequest(task.data));
                            removeTaskFromPipeLine.call(this, task);
                            return task.rejectTask(new Error(PROMISE_CANCELED));
                        }
                    });
                task.xhrPromise = promise;
                if (executionPipe.length < this.queueSize - 1 && sortedQueue.length > 0) {
                    this.next();
                }
                return promise;
            }
        } else {
            log('!!pipeline full, waiting');
        }
    }
}

function getQueueInstance(settings) {
    settings = Object.assign(queueSettings, settings);
    return DilQueue.getInstance(settings);
}

Object.assign(DilQueue, SingletonMixin);

export default getQueueInstance;
