import { UPDATE_HISTORY_CURRENT_CATEGORY } from '@Main/Main.reducers';

let historyActions = {
    updateHistoryCategory: (categoryName) => {
        return {
            type: UPDATE_HISTORY_CURRENT_CATEGORY,
            payload: { categoryName },
        };
    },
};

export default historyActions;
