//@flow

import DateRangesWrapper from './DateRangesWrapper';
import * as React from 'react';
import utils from '@utils/utilities';
import { CATEGORY_NAMES } from '@constants';
import type { DateRanges } from './flow/Dates.typeGuards';
import type { AdHocSearchType } from '@reusable/AdHocSearch/flow/AdHocSearch.typeGuards';
import type { UserOfferings } from '@reusable/SearchBar/flow/SearchBar.typeGuards.js';
type Props = {
    adHocSearch: AdHocSearchType,
    dateRanges: DateRanges,
    newsDateRange: string,
    companyDateRange: string,
    legalDateRange: string,
    userLocks: {
        categoriesDefaultDateRange: boolean,
        newsSearchesDateRange: boolean,
        companySource: boolean,
        legalSourceDefaultDateRange: boolean,
    },
    updateAdHocProperty: (property: string, value: any) => void,
    onChange?: (args: Object) => void,
    refreshPreferencesIfNeeded: () => void,
    userOfferings: Array<UserOfferings> | [],
    isReadOnly: boolean,
};

const DateRangesDataWrapper = (props: Props) => {
    const getDateRanges = (): { newsDateRange: string, companyDateRange: string, legalDateRange: string } => {
        const adHocNewsDateRange: { range: string } | void = props.adHocSearch.newsDateRange;
        const adHocCompanyDateRange: { range: string } | void = props.adHocSearch.companyDateRange;
        const adHocLegalDateRange: { range: string } | void = props.adHocSearch.legalDateRange;

        if (!!adHocNewsDateRange && !!adHocCompanyDateRange && !!adHocLegalDateRange) {
            return {
                newsDateRange: adHocNewsDateRange.range,
                companyDateRange: adHocCompanyDateRange.range,
                legalDateRange: adHocLegalDateRange.range,
            };
        }

        return {
            newsDateRange: props.newsDateRange,
            companyDateRange: props.companyDateRange,
            legalDateRange: props.legalDateRange,
        };
    };

    const { newsDateRange, companyDateRange, legalDateRange } = getDateRanges();
    const areLegalSourcesAvailable = utils.filterContentTypesByOfferings(props.userOfferings, {
        name: CATEGORY_NAMES.LAW_SOURCES,
    });
    const areCompanySourcesAvailable = utils.filterContentTypesByOfferings(props.userOfferings, {
        name: CATEGORY_NAMES.COMPANY_RESOURCES,
    });

    return (
        <DateRangesWrapper
            updateAdHocProperty={props.updateAdHocProperty}
            dateRanges={props.dateRanges}
            newsDateRange={newsDateRange}
            legalDateRange={legalDateRange}
            companyDateRange={companyDateRange}
            userLocks={props.userLocks}
            refreshPreferencesIfNeeded={props.refreshPreferencesIfNeeded}
            areLegalSourcesAvailable={areLegalSourcesAvailable}
            areCompanySourcesAvailable={areCompanySourcesAvailable}
            onChange={props.onChange}
            isReadOnly={props.isReadOnly}
        />
    );
};

export default (DateRangesDataWrapper: React$StatelessFunctionalComponent<Props>);
