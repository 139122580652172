import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import CategoriesGroupDateSelect, {
    CategoriesGroupDateSelectWithLock,
} from '@reusable/DateSelect/GroupDateSelect/CategoriesGroupDateSelect';
import { debounce } from 'lodash';
import withAutosave from '@UserPreferences/HOC/WithAutosave';

const UPDATE_TRIGGER_DEBOUNCE_TIME = 50;
// The preference container for Categories Date Select
// Basically handles the autosaving -- which is specific for preferences
const PreferencesCategoriesGroupDateSelect = (props) => {
    const handleTriggerUpdate = debounce(() => {
        if (props.handleAutosave) {
            props.handleAutosave(() => {}, []);
        }
    }, UPDATE_TRIGGER_DEBOUNCE_TIME);

    const handleChange = (section, value) => {
        props.onChange(section, value);
        handleTriggerUpdate();
    };

    const messages = {
        ...props.messages,
        title: (
            <Fragment>
                {props.messages.title}
                <span className={'autosave-msg'}>{props.autoSaveMessage || null}</span>
            </Fragment>
        ),
    };

    return (
        <div className={'categories-date-range from-preferences with-lock'}>
            <CategoriesGroupDateSelectWithLock {...props} messages={messages} onChange={handleChange} />
        </div>
    );
};

PreferencesCategoriesGroupDateSelect.propTypes = {
    ...CategoriesGroupDateSelect.propTypes,
    handleAutosave: PropTypes.func.isRequired,
    autoSaveMessage: PropTypes.any.isRequired,
    components: PropTypes.any,
};

PreferencesCategoriesGroupDateSelect.defaultProps = {
    ...CategoriesGroupDateSelect.defaultProps,
};

export default withAutosave()(PreferencesCategoriesGroupDateSelect);
