/**
 * HEADER REDUX REDUCERS
 *
 * @version 0.1
 */

import { UPDATE_HEADER } from './Header.actions';

const headerOptions = {
    getStartedNewUser: {
        menuStateClass: 'getStarted',
        subMenuMessage: 'HeaderFooterNav_SubMenu_UIText_subMenu.getStarted',
        helpUrl: 'firstimedashboardtips-field?lbu=US&locale=en_US&audience=all',
    },
    getStartedReturningUser: {
        menuStateClass: 'getStarted',
        subMenuMessage: 'HeaderFooterNav_SubMenu_UIText_subMenu.getStarted',
        helpUrl: 'getstarteddashboardtips-field?lbu=US&locale=en_US&audience=all',
    },
    getStartedDashboard: {
        menuStateClass: '',
        subMenuMessage: '',
        helpUrl: 'getstarteddashboardtips-field?lbu=US&locale=en_US&audience=all',
    },
    getStartedDashboardEntityProfile: {
        menuStateClass: '',
        subMenuMessage: 'HeaderFooterNav_SubMenu_UIText_subMenu.getStartedEntityProfile',
        helpUrl: 'aboutentitytips-field?lbu=US&locale=en_US&audience=all',
    },
    batchUpload: {
        menuStateClass: 'uploads',
        subMenuMessage: 'HeaderFooterNav_SubMenu_UIText_subMenu.batchUpload',
        helpUrl: 'batchuploadtips-field?lbu=US&locale=en_US&audience=all',
    },
    checkFormatting: {
        menuStateClass: 'uploads',
        subMenuMessage: 'HeaderFooterNav_SubMenu_UIText_subMenu.checkFormatting',
        helpUrl: 'checkformattingtips-field?lbu=US&locale=en_US&audience=all',
    },
    fileReadingError: {
        menuStateClass: 'uploads',
        subMenuMessage: 'HeaderFooterNav_SubMenu_UIText_subMenu.fileReadingError',
        helpUrl: 'filereadingerrortips-field?lbu=US&locale=en_US&audience=all',
    },
    // to-do: need help url for cap exceeded, add us + gb i18n for submenu text
    subscriptionPlanExceeded: {
        menuStateClass: 'uploads',
        subMenuMessage: 'HeaderFooterNav_SubMenu_UIText_subMenu.subscriptionPlanExceeded',
        helpUrl: 'filereadingerrortips-field?lbu=US&locale=en_US&audience=all',
    },
    reviewCompanies: {
        menuStateClass: 'uploads',
        subMenuMessage: 'HeaderFooterNav_SubMenu_UIText_subMenu.reviewCompanies',
        helpUrl: 'reviewcompaniestips-field?lbu=US&locale=en_US&audience=all',
    },
    //extends reviewCompanies
    thirdPartyListing: {
        menuStateClass: 'companies',
        subMenuMessage: 'HeaderFooterNav_SubMenu_UIText_subMenu.thirdPartyListing',
        helpUrl: 'managecompaniestips-field?lbu=US&locale=en_US&audience=all',
    },
    previewCompany: {
        menuStateClass: 'uploads',
        subMenuMessage: 'HeaderFooterNav_SubMenu_UIText_subMenu.previewCompany',
        helpUrl: 'previewresultstips-field?lbu=US&locale=en_US&audience=all',
    },
    previewArticle: {
        menuStateClass: 'uploads',
        subMenuMessage: 'HeaderFooterNav_SubMenu_UIText_subMenu.previewCompany',
        helpUrl: 'previewnewstips-field?lbu=US&locale=en_US&audience=all',
    },
    previewArticleFromEdit: {
        menuStateClass: 'companies',
        subMenuMessage: 'HeaderFooterNav_SubMenu_UIText_subMenu.previewCompany',
        helpUrl: 'previewnewstips-field?lbu=US&locale=en_US&audience=all',
    },
    editThirdParty: {
        menuStateClass: 'companies',
        subMenuMessage: 'HeaderFooterNav_SubMenu_UIText_subMenu.editThirdParty',
        helpUrl: 'editcompanytips-field?lbu=US&locale=en_US&audience=all',
    },
    createNewEntity: {
        menuStateClass: 'companies',
        subMenuMessage: 'HeaderFooterNav_SubMenu_UIText_subMenu.createNewEntity',
        helpUrl: 'createsingleentitytips-field?lbu=US&locale=en_US&audience=all',
    },
    feedListing: {
        menuStateClass: 'feeds',
        subMenuMessage: 'HeaderFooterNav_SubMenu_UIText_subMenu.feedListing',
        helpUrl: 'managersstips-field?lbu=US&locale=en_US&audience=all',
    },
    viewFeedContent: {
        menuStateClass: '',
        subMenuMessage: 'HeaderFooterNav_SubMenu_UIText_subMenu.viewFeedContent',
        helpUrl: 'viewrssfeed_hdi-task?lbu=US&locale=en_US&audience=all',
    },
    addRssFeed: {
        menuStateClass: 'feeds',
        subMenuMessage: 'HeaderFooterNav_SubMenu_UIText_subMenu.addNewRssFeed',
        helpUrl: 'addrsstips-field?lbu=US&locale=en_US&audience=all',
    },
    editFeed: {
        menuStateClass: 'feeds',
        subMenuMessage: 'HeaderFooterNav_SubMenu_UIText_subMenu.editFeed',
        helpUrl: 'editrssfeedtips-field?lbu=US&locale=en_US&audience=all',
    },
    errorPage500: {
        menuStateClass: 'error500',
        subMenuMessage: 'HeaderFooterNav_SubMenu_UIText_subMenu.error500',
        helpUrl: 'getstarteddashboardtips-field?lbu=US&locale=en_US&audience=all',
    },
    errorPage404: {
        menuStateClass: 'error404',
        subMenuMessage: 'HeaderFooterNav_SubMenu_UIText_subMenu.error404',
        helpUrl: 'getstarteddashboardtips-field?lbu=US&locale=en_US&audience=all',
    },
    errorPageFailedLogin: {
        menuStateClass: 'error404',
        subMenuMessage: 'HeaderFooterNav_SubMenu_UIText_subMenu.errorFailedLogin',
        helpUrl: 'getstarteddashboardtips-field?lbu=US&locale=en_US&audience=all',
    },
    logOut: {
        menuStateClass: '',
        subMenuMessage: '',
        helpUrl: '',
    },
    emptySubMenu: {
        menuStateClass: '',
        subMenuMessage: '',
        helpUrl: '',
    },

    // This entry is for displaying an empty submenu
    emptySubMenuVisible: {
        menuStateClass: '',
        subMenuMessage: ' ',
        helpUrl: '',
    },
};

function updateHeader(state = {}, action) {
    switch (action.type) {
        case UPDATE_HEADER:
            if (typeof action.payload === 'string') {
                return Object.assign({}, state, headerOptions[action.payload]);
            } else {
                return Object.assign({}, state, action.payload);
            }

        case 'SET_VERSION':
            return Object.assign({}, state, { version: action.payload });

        default:
            return state;
    }
}

export default updateHeader;
