import React, { createContext } from 'react';
import PropTypes from 'prop-types';
import { permissions, userHas } from '@permissions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import actions from '@pages/UserPreferances/redux/UserPreferences.actions';

export const AdminLockContext = createContext({ isEditable: false, userLocks: {} });

const AdminLocksProvider = (props) => {
    const isEditable = props.isEditable && userHas(permissions.admin.edit);

    const userLocks = isEditable ? props.adminLocks : props.userLocks;

    const handleChange = (lockName) => {
        const lockState = userLocks[lockName];
        props.updateLocks({ [lockName]: !lockState });
    };

    return (
        <AdminLockContext.Provider
            value={{
                isEditable,
                userLocks,
                onChange: handleChange,
            }}
        >
            {props.children}
        </AdminLockContext.Provider>
    );
};

AdminLocksProvider.propTypes = {
    updateLocks: PropTypes.func.isRequired,
    userLocks: PropTypes.object,
    isEditable: PropTypes.bool,
};

AdminLocksProvider.defaultProps = {
    isEditable: false,
    userLocks: {},
};

const mapStateToProps = (state, ownProps) => ({
    ...ownProps,
    userLocks: state.user.preferences.userLocks,
    adminLocks: (state.user.adminPreferences && state.user.adminPreferences.userLocks) || {},
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            updateLocks: actions.updateLocks,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(AdminLocksProvider);
