import React from 'react';
import { FormattedMessage } from 'react-intl';
import utils from '@utils/utilities';
import { REPORT_EXPIRATION_DATE_RANGE, REPORT_EXPIRATION_DATE_RANGE_PUBLIC_RECORDS, DRAG_AND_DROP } from '@constants';
import useDragAndDrop from '@scripts/hooks/useDragAndDrop';

const ReportInfo = (props) => {
    const { childReport, index, showDropTarget, droppedChildReportId, insertAbove, parentReport, visibleMenu } = props;

    const dndHookInfo = {
        componentType: 'child',
        accept: DRAG_AND_DROP.CHILD_REPORT,
    };

    const { connectDragSource, isDragging, connectDropTarget } = useDragAndDrop(props, dndHookInfo);

    const id = `report-${index}`;
    const showDroppedAnimation = childReport.reportId === droppedChildReportId;
    const isCurrentMenuVisible = visibleMenu === id;
    const isDragAndDropDisabled = visibleMenu !== null;
    const hasSiblings = props.parentReport.childReports.length > 1;

    let changeItemsOrderMenu = hasSiblings ? <div className="draggable-icon"></div> : null;

    let reportInfoItem = (
        <div
            className={`reports-list-child-row-container ${isDragging ? 'dragging' : ''} ${
                hasSiblings ? 'with-multiple-items' : ''
            } ${isCurrentMenuVisible ? 'selected-row' : ''} ${isDragAndDropDisabled ? 'drag-and-drop-disabled' : ''}`}
        >
            <li
                className={
                    'reports-list-child-row ' +
                    (isDragging ? 'dragging' : '') +
                    (showDropTarget ? (insertAbove ? ' drop-top ' : ' drop-bottom ') : '') +
                    (showDroppedAnimation ? 'dropped' : '')
                }
                id={'qa-child-report_' + parentReport.id}
                key={parentReport.id}
            >
                <div className="child-report-title-date">
                    <div className="child-report-title-index">
                        {props.disabled ? (
                            <DisabledReport {...props} changeOrderMenu={changeItemsOrderMenu} />
                        ) : (
                            <EnabledReport {...props} changeOrderMenu={changeItemsOrderMenu} />
                        )}
                    </div>
                </div>
            </li>
        </div>
    );

    return hasSiblings ? connectDragSource(connectDropTarget(reportInfoItem)) : reportInfoItem;
};

const DisabledReport = (props) => {
    return (
        <div className="combined-report-content__list__report disabled">
            <div className={'section left-aligned'}>
                {props.changeOrderMenu}
                <span className="section disabled">{props.childReport.title}</span>
            </div>
            <div className={'section right-aligned'}>
                <span className="section">
                    <FormattedMessage id={'CombinedReport.expanded.content.disabled'} />
                </span>
            </div>
        </div>
    );
};
export { DisabledReport as TestDisabledReport };

const EnabledReport = (props) => {
    const { viewIncludedReport, removeIncludedReport, parentReport, changeOrderMenu } = props;
    const { title, lastAddedDocumentDate, reportId, lastAddedPrDocumentDate } = props.childReport;
    let daysInMilliseconds,
        expiryDate,
        PrExpiryDate = 0,
        DocumentExpiryDate = 0;

    if (lastAddedPrDocumentDate) {
        daysInMilliseconds = utils.daysToMSConverter(REPORT_EXPIRATION_DATE_RANGE_PUBLIC_RECORDS);
        PrExpiryDate = utils.addXDaysToDate(lastAddedPrDocumentDate, daysInMilliseconds);
    }
    if (lastAddedDocumentDate) {
        daysInMilliseconds = utils.daysToMSConverter(REPORT_EXPIRATION_DATE_RANGE);
        DocumentExpiryDate = utils.addXDaysToDate(lastAddedDocumentDate, daysInMilliseconds);
    }

    expiryDate = utils.formatDateForExpiryDate(Math.max(PrExpiryDate, DocumentExpiryDate));

    return (
        <div className="combined-report-content__list__report">
            <div className="section left-aligned">
                {changeOrderMenu}
                <span>{title}</span>
            </div>
            <div className="section right-aligned">
                <span>{expiryDate}</span>
                <Actions
                    id={reportId}
                    parentId={parentReport.id}
                    view={viewIncludedReport}
                    remove={removeIncludedReport}
                />
            </div>
        </div>
    );
};
export { EnabledReport as TestEnabledReport };

const Actions = ({ view, remove, id, parentId }) => {
    return (
        <div className="combined-report-content__list__report__actions">
            <span className={'action-link'} onClick={() => view(id)} data-testid={'view'}>
                <FormattedMessage id="General_CoreFunctionality_UIText_general.view" />
            </span>
            <span className={'vertical-divider'} />
            <span className={'action-link'} onClick={() => remove(id, parentId)} data-testid={'remove'}>
                <FormattedMessage id="General_CoreFunctionality_UIText_general.remove" />
            </span>
        </div>
    );
};
export { Actions as TestCombinedReportActions };

export default ReportInfo;

export { ReportInfo as TestCombinedReportInfo };
