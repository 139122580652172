// @flow
import {
    ADDITIONAL_POSTFILTER_COMPONENTS,
    CATEGORY_NAMES,
    POST_FILTER_CONTENT_SOURCE,
    POSTFILTER_COMPONENT_TYPE,
    POSTFILTER_COMPONENTS,
} from '@constants';
import categoryUtils from '@utils/categoryUtils';
import { EVENT_TYPES, USER_FILTERS_KEYS } from '../constants/investigationConstants';
import { cloneDeep, isEmpty, isNil } from 'lodash';

import type {
    ProcessDataForSearchStartedArgsType,
    ProcessDataForSearchFromHistoryArgsType,
    ProcessedFilterType,
    GetCountsFromSearchResultsArgsType,
    CategoryResultsCount,
    FilterDataCategoryVisitedType,
    ProcessDataForFiltersInvestigationArgs,
    FilterDataCategoryRevisitedType,
    ProcessedRevisitedCategoryDataType,
} from '../investigationTypeGuards/investigationHelpers.typeGuard';
import type { PostFilterValue } from '@pages/MainSearch/typeGuards/PostFilter.typeGuard';
import type { InvestigationType } from '../investigationTypeGuards/investigationFlows.type.guards';
import type { GetInvestigationData } from '../investigationTypeGuards/investigationWorkers.type.guards';

/** START_INVESTIGATION flow helpers */

export function isInvestigationFromCurrentSearch(
    investigation: GetInvestigationData,
    query: string,
    searchType: string
): boolean {
    const { searchQueryType, searchQuery } = investigation;
    return searchQueryType === searchType && searchQuery.toLowerCase() === query.toLowerCase();
}

export function hasReport(investigation: InvestigationType | GetInvestigationData): boolean {
    return investigation.reportId ? true : false;
}

export function hasInvestigation(investigation: GetInvestigationData): boolean {
    const { investigationId } = investigation;
    return !!investigationId;
}

export function getDefaultReport(): { id: null, articlesSnippets: Array<?string> } {
    return {
        id: null,
        articlesSnippets: [],
    };
}

/** SEARCH_PERFORMED, NORMAL/FROM_HISTORY flow helpers */

export function processDataForSearchStarted({
    data,
    newsSource,
    uboPostfilters,
}: ProcessDataForSearchStartedArgsType): Array<ProcessedFilterType> {
    const filters: Array<ProcessedFilterType> = [];

    data.forEach((entry) => {
        const categoryFilters: ProcessedFilterType = {};

        if (USER_FILTERS_KEYS[entry.category]) {
            USER_FILTERS_KEYS[entry.category].forEach((key) => {
                categoryFilters[key] = entry[key];
                // in the data for search only some of the content sources are used, so this is for retrieving applying any of the content types applied
                if (key === POST_FILTER_CONTENT_SOURCE) {
                    categoryFilters[key] = newsSource;
                }
            });
        }

        if (entry.subCategories) {
            entry.subCategories.forEach((subCategory) => {
                if (entry.newsQueries) {
                    // eslint-disable-next-line
                    const { category, ...extension } = getCategoryNameAndChild(subCategory);
                    const language = Object.values(extension)[0];
                    categoryFilters.newsQuery = entry.newsQueries.find(
                        (query) => query.contentLanguage === language || query.name === language
                    );
                }
                filters.push({ ...categoryFilters, category: entry.category, subCategory });
            });
        } else {
            filters.push({ ...categoryFilters, category: entry.category });
        }
    });

    if (!isEmpty(uboPostfilters)) {
        // $FlowIssue
        filters.push(...Object.values(uboPostfilters));
    }

    return filters;
}

function processRerunSearchPostFilters(data): Array<ProcessedFilterType> {
    const filters: Array<ProcessedFilterType> = [];

    data.forEach((entry) => {
        const categoryFilters: ProcessedFilterType = {};

        if (USER_FILTERS_KEYS[entry.category]) {
            USER_FILTERS_KEYS[entry.category].forEach((key) => {
                if (key === 'fuzzyNames' && !Array.isArray(entry[key])) {
                    categoryFilters[key] = entry[key] && entry[key].list;
                } else {
                    categoryFilters[key] = entry[key];
                }
            });

            const filterData: ProcessedFilterType = {
                ...categoryFilters,
                category: entry.category,
                subCategory: entry.subCategory,
                newsQuery: entry.newsQuery,
            };
            Object.keys(filterData).forEach(
                (key) => (filterData[key] === undefined || filterData[key] === null) && delete filterData[key]
            );

            filters.push(filterData);
        }
    });

    return filters;
}

export function processDataForSearchFromHistory({
    data,
    uboPostfilters,
}: ProcessDataForSearchFromHistoryArgsType): Array<ProcessedFilterType> {
    const filters: Array<ProcessedFilterType> = processRerunSearchPostFilters(data);

    if (!isEmpty(uboPostfilters)) {
        // $FlowIssue
        filters.push(...Object.values(uboPostfilters));
    }

    return filters;
}

export function processDataForSearchFromEditAlert({ data }: any): Array<ProcessedFilterType> {
    return processRerunSearchPostFilters(data);
}

/** SEARCH_PERFORMED, CATEGORY_AVAILABLE_COUNTS flow helpers */

export function getCountsFromSearchResults(results: GetCountsFromSearchResultsArgsType): Array<CategoryResultsCount> {
    const counts = [];

    for (let key in results) {
        if (Object.prototype.hasOwnProperty.call(results, key) && results[key].enabled) {
            if (!categoryUtils.isParent(key)) {
                if (categoryUtils.isNewsSource(key)) {
                    const { category }: { category: string } = categoryUtils.extendConfig(key);

                    counts.push({
                        category,
                        subCategory: key,
                        docCount: results[key].count,
                    });
                } else {
                    counts.push({
                        category: categoryUtils.getParent(key),
                        subCategory: key,
                        docCount: results[key].count,
                    });
                }
            } else {
                if (!categoryUtils.isExtended(key)) {
                    counts.push({
                        category: key,
                        docCount: results[key].count,
                    });
                }
            }
        }
    }

    return counts;
}
/** CATEGORY_VISITED flow helpers */

export function processDataForCategoryVisited({
    categoryData,
    payload,
    newsSource,
    uboPostfilters,
    counts,
}: FilterDataCategoryVisitedType): { filters: ProcessedFilterType, docCount: number } {
    const { name, count } = categoryData;
    const { category, subCategory } = getCategoryNameAndSubcategory(name);
    const userFilters = {};
    let filters: ProcessedFilterType = {};

    if (USER_FILTERS_KEYS[category]) {
        USER_FILTERS_KEYS[category].forEach((key) => {
            if (key === 'fuzzyNames' && !Array.isArray(payload[key])) {
                userFilters[key] = payload[key] && payload[key].list;
            } else {
                userFilters[key] = key === POST_FILTER_CONTENT_SOURCE ? newsSource : payload[key];
            }
        });

        if (payload.newsQueries) {
            // eslint-disable-next-line
            const { category, ...extension } = getCategoryNameAndChild(subCategory);
            const language = Object.values(extension)[0];
            userFilters.newsQuery = payload.newsQueries.find(
                (query) => query.contentLanguage === language || query.name === language
            );
        }

        filters = { ...userFilters, category, subCategory };

        if (!subCategory) {
            delete filters.subCategory;
        }
    }
    if (
        !isEmpty(uboPostfilters) &&
        (categoryUtils.isDnbCategory(category) || categoryUtils.isDnbChildCategory(category))
    ) {
        filters = cloneDeep(uboPostfilters);

        delete filters.searchQuery;
        delete filters.searchQueryType;
        delete filters.includeTerms;
        delete filters.excludeTerms;
    }

    return {
        filters,
        docCount: count,
        counts,
    };
}

/** CATEGORY_REVISITED flow helper*/

export function processDataForCategoryRevisited({
    categoryData,
    uboPostfilters,
    counts,
}: FilterDataCategoryRevisitedType): ProcessedRevisitedCategoryDataType {
    const { name, count }: { name: string, count: number } = categoryData;
    const { category, subCategory }: { category: string, subCategory: ?string } = getCategoryNameAndSubcategory(name);

    if (
        !isEmpty(uboPostfilters) &&
        (categoryUtils.isDnbCategory(category) || categoryUtils.isDnbChildCategory(category))
    ) {
        const { term, category }: { term: string, category: string } = cloneDeep(uboPostfilters);

        return {
            subCategory: null,
            category,
            term,
            docCount: count,
            counts,
        };
    }

    return {
        subCategory: subCategory || null,
        category,
        docCount: count,
        counts,
    };
}

/** FILTER_APPLIED, FILTER_REMOVED, FILTER_RESET flow helpers */

export function processDataForFiltersInvestigationEvents({
    categoryData,
    postFilterType,
    options,
    eventType,
    eventSubType,
}: ProcessDataForFiltersInvestigationArgs): {
    payload: {
        category: string,
        subCategory: ?string,
        docCount: number,
        values?: Array<string>,
        value?: string | PostFilterValue,
    },
    eventSubType: string,
} {
    const { name, count } = categoryData;
    const { category, subCategory } = getCategoryNameAndSubcategory(name);
    const payload = { category, subCategory, docCount: count, value: undefined, values: undefined };

    if (!subCategory) {
        delete payload.subCategory;
    }

    let values;
    switch (eventType) {
        case EVENT_TYPES.filterApplied:
            values = options.filter((option) => option.checked);
            break;
        case EVENT_TYPES.filterRemoved:
            values = options.filter((option) => !option.checked);
            break;
        case EVENT_TYPES.filterReset:
            values = options;
            break;
        default:
            break;
    }

    const filtersConfiguration = Object.values({ ...POSTFILTER_COMPONENTS, ...ADDITIONAL_POSTFILTER_COMPONENTS });
    // $FlowIssue
    const filter = filtersConfiguration.find((filterConfig) => filterConfig.type === postFilterType);

    if (filter) {
        if (filter.componentType === POSTFILTER_COMPONENT_TYPE.CHECKBOX_LIST || filter.dynamic) {
            payload.values = values && values.map((option) => option.label);
        } else {
            payload.value = values && values.length && !isNil(values[0].count) ? values[0].count : options[0];
        }
        // $FlowIssue
        eventSubType = filter.eventSubType;
    }

    return {
        eventSubType,
        payload,
    };
}

export function processDataForAllPostFiltersRemoval({
    categoryData,
    eventSubType,
}: {
    categoryData: { name: string, count: number },
    eventSubType: string,
}): { eventSubType: string, payload: { category: string, subCategory: ?string, docCount: number } } {
    const { name, count } = categoryData;
    const { category, subCategory } = getCategoryNameAndSubcategory(name);
    const payload = { category, subCategory, docCount: count };

    if (!subCategory) {
        delete payload.subCategory;
    }

    return {
        eventSubType: eventSubType,
        payload,
    };
}

export function getCategoryNameAndChild(name: string): { category: string, subCategory?: string } {
    if (categoryUtils.isDnbChildCategory(name)) return { category: CATEGORY_NAMES.DNB, subcategory: name };
    if (categoryUtils.isLegalSource(name)) return { category: CATEGORY_NAMES.LAW_SOURCES, subcategory: name };
    if (categoryUtils.isChild(name)) return categoryUtils.extendConfig(name);

    return { category: name };
}

export function getCategoryNameAndSubcategory(name: string): { category: string, subCategory: string | null } {
    const categoryName = getCategoryNameAndChild(name);
    const { category } = categoryName;

    return {
        category,
        subCategory: category !== name ? name : null,
    };
}

export function getAppliedTermAndPostfilterData(searchData) {
    const {
        postFilters: { terms },
        name,
        count,
        payload,
    } = searchData;
    const filterTerm = !terms || !terms.length ? '' : terms[0];

    return {
        filterTerm,
        name,
        count,
        payload,
    };
}
