// @flow
import * as React from 'react';
import { Link } from 'react-router';
import { FormattedMessage } from 'react-intl';
import LanguageSelector from '../LanguageSelector/LanguageSelector.index';
import { permissions, userHas } from '@permissions';
import { withAppContext } from '@utils/contexts';
import type { UserSelectorContextType } from './flow/UserSelector.type.guards';

type Props = {
    userName: string,
    helpUrlRoot: string,
    logOutUser: () => void,
    signInProfile: string,
    context: UserSelectorContextType,
    resetLaunchedFrom: () => void,
    updatePrevPath: () => void,
    location: string,
    name: {
        first: string,
        last: string,
    },
    shouldSavePreferences: boolean,
    adminPreferencesAreSaving: boolean,
};

const UserSelector = (props: Props) => {
    const userName =
        props.name && props.name.first && props.name.last ? props.name.first + ' ' + props.name.last : props.userName;
    const logoutButtonDisabledClass = props.shouldSavePreferences || props.adminPreferencesAreSaving ? 'disabled' : '';

    return (
        <li className="diligence-header__menu-item diligence-header__menu-item__user">
            <div
                className="diligence-header__menu-link diligence-header__menu-link__user"
                data-track="diligence-header__menu-link__user"
                data-testid={'diligence-header__menu-link__user'}
            >
                <div className="la-Profile" />
                <span className="notranslate">{userName}</span>
            </div>
            <div className="dropdown-content dropdown-content__user">
                <Link
                    to={'/user-preferences'}
                    className="diligence-header__menu-link diligence-header__menu-link--dropdown-item"
                    data-track="diligence-header__menu-link__user-preferences"
                    data-testid={'diligence-header__menu-link__user-preferences'}
                >
                    <FormattedMessage id="HeaderFooterNav_CoreFunctionality_Dropdown_nav.user.userPreferences" />
                </Link>
                {!props.context.isUserAnonymized && !props.context.isUserMIP && (
                    <Link
                        to={'/history'}
                        className="diligence-header__menu-link diligence-header__menu-link--dropdown-item"
                        data-testid={'diligence-header__menu-link__history'}
                    >
                        <FormattedMessage id="HeaderFooterNav_CoreFunctionality_Dropdown_nav.user.history" />
                    </Link>
                )}

                <LanguageSelector />
                {userHas(permissions.admin.edit) && (
                    <Link
                        to={'/admin-preferences'}
                        className="diligence-header__menu-link diligence-header__menu-link--dropdown-item"
                        data-testid={'diligence-header__menu-link__admin-preferences'}
                    >
                        <FormattedMessage id="HeaderFooterNav_CoreFunctionality_Dropdown_nav.user.adminPreferences" />
                    </Link>
                )}
                {
                    // eslint-disable-next-line
                    <a
                        href={props.helpUrlRoot}
                        target="_blank"
                        className="diligence-header__menu-link diligence-header__menu-link--dropdown-item"
                        data-testid={'diligence-header__menu-link__help'}
                    >
                        <FormattedMessage id="HeaderFooterNav_CoreFunctionality_Dropdown_nav.user.help" />
                        <span className="la-ExternalLink" />
                    </a>
                }
                {!props.context.isUserMIP && (
                    // eslint-disable-next-line
                    <a
                        href={props.signInProfile}
                        target="_blank"
                        className="diligence-header__menu-link diligence-header__menu-link--dropdown-item"
                        data-testid={'diligence-header__menu-link__signInProfile'}
                    >
                        <FormattedMessage id="HeaderFooterNav_CoreFunctionality_Dropdown_nav.user.signIn" />
                        <span className="la-ExternalLink"></span>
                    </a>
                )}
                <div className="diligence-header-logout-link-wrapper">
                    <a
                        className={`diligence-header__menu-link diligence-header__menu-link--dropdown-item ${logoutButtonDisabledClass}`}
                        data-testid="diligence-header-logout-button-id"
                        onClick={props.logOutUser}
                    >
                        <FormattedMessage id="HeaderFooterNav_CoreFunctionality_Dropdown_nav.user.signout" />
                    </a>
                </div>
            </div>
        </li>
    );
};

export default (withAppContext(UserSelector): React.AbstractComponent<Props>);
