//@flow
import * as XLSX from 'xlsx/xlsx.mjs';
import { DEFAULT_FILE_NAMES } from '@constants';

const deliveryUtilities = {
    exportToExcel: (table: HTMLTableElement, bookType: string = 'xls') => {
        const workbook = XLSX.utils.table_to_book(table, { sheet: 'Sheet' });
        XLSX.writeFile(workbook, `${DEFAULT_FILE_NAMES.excel}.${bookType}`);
    },
};

export default deliveryUtilities;
