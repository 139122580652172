import React from 'react';
import withConfirmationModal from '@reusable/Modal/hoc/withConfirmationModal';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { withAppContext } from '@utils/contexts';

// Component used to override usually a click handler, showing an anomymized warning modal and redirect upon confirming
export const AnonymizedWarningRedirectHandler = compose(
    withConfirmationModal,
    withRouter,
    withAppContext
)((props) => {
    const handleAnonymizedWarning = (e) => {
        if (props.context && props.context.isUserAnonymized) {
            e.preventDefault();
            props.onConfirmModal(
                {
                    primaryBtnText: 'General_CoreFunctionality_UIText_general.continue',
                    secondaryBtnText: 'General_CoreFunctionality_UIText_general.ignore',
                    title: <FormattedMessage id={'Alerts.createAlert.anonymized.modalTitle'} />,
                    description: <AnonymyzedModalBody />,
                },
                () => {
                    props.router.push(props.redirectUrl);
                }
            );
        }
    };

    return props.overrideHandler(handleAnonymizedWarning);
});

export const AnonymyzedModalBody = () => (
    <div>
        <p>
            <FormattedMessage id={'Alerts.createAlert.anonymized.modalBody1'} />
        </p>
        <p>
            <FormattedMessage id={'Alerts.createAlert.anonymized.modalBody2'} />
        </p>
        <p>
            <FormattedMessage id={'Alerts.createAlert.anonymized.modalBody3'} />
        </p>
    </div>
);
