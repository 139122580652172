import React from 'react';
import { Link } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { withAlertToggleView, withMIPUserHideView } from '@utils/contexts';

const ManageAlertsSelector = () => {
    return (
        <li className="diligence-header__menu-item diligence-header__menu-item__manage-alerts notranslate">
            <Link to="/manage-alerts">
                <div className="diligence-header__menu-link" data-track="diligence-header__menu-link__manage-alerts">
                    <span className="diligence-header__menu-link__manage-alerts">
                        <FormattedMessage id="ManageAlerts.label.manageAlerts" />
                    </span>
                </div>
            </Link>
        </li>
    );
};

export default withMIPUserHideView(withAlertToggleView(ManageAlertsSelector));
