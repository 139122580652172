import React from 'react';
import PropTypes from 'prop-types';
import { DEBOUNCE_QUERY_CHANGED_TIMEOUT } from '@constants';
import { debounce } from 'lodash';
import utils from '@utils/utilities';
import { difference } from 'lodash';

const MAX_TEXTAREA_HEIGHT = 480; // 480px = 30rem

class SearchBox extends React.Component {
    static propTypes = {
        onChangeQuery: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.textareaInput = React.createRef();
        this.state = {
            inputTrimmedVisible: true,
            trimmedValue: props.query === '' ? props.placeholder : props.query,
            textarea: '',
            textareaStyle: { height: '1.3rem' },
        };

        this.handleEditMode = this.handleEditMode.bind(this);
        this.queryChange = this.queryChange.bind(this);
        this.textareaBlurHandler = this.textareaBlurHandler.bind(this);
        this.resetSearchQuery = this.resetSearchQuery.bind(this);
        this.textareaKeyPress = this.textareaKeyPress.bind(this);
        this.partiallyResetSearchQuery = this.partiallyResetSearchQuery.bind(this);

        this.deboucedQueryChanged = debounce((cursorPosition) => {
            this.props.queryChanged(this.state.textarea, cursorPosition);
        }, DEBOUNCE_QUERY_CHANGED_TIMEOUT).bind(this);
    }

    handleEditMode() {
        this.setState(
            {
                inputTrimmedVisible: false,
                textarea: this.props.query,
            },
            () => {
                if (this.textareaInput) {
                    if (this.textareaInput.current.scrollHeight > MAX_TEXTAREA_HEIGHT) {
                        this.textareaInput.current.style.height = MAX_TEXTAREA_HEIGHT + 'px';
                    } else {
                        this.textareaInput.current.style.height = this.textareaInput.current.scrollHeight + 'px';
                    }
                }
            }
        );
    }

    resetSearchQuery() {
        this.setState(
            {
                textarea: '',
                inputTrimmedVisible: true,
                textareaStyle: { height: '1.3rem' },
            },
            () => {
                this.props.resetQuery();
            }
        );
    }

    partiallyResetSearchQuery() {
        this.setState(
            {
                textarea: '',
                textareaStyle: { height: '1.3rem' },
            },
            () => {
                this.props.resetQuery();
            }
        );
    }

    queryChange(event) {
        const value = utils.removeLineBreaks(utils.sanitizeSearchStringInput(event.target.value));
        const cursorPosition = this.textareaInput.current.selectionStart;

        this.props.onChangeQuery && this.props.onChangeQuery(value, cursorPosition);

        this.setState(
            {
                textarea: value,
                textareaStyle: { height: event.target.scrollHeight + 'px' },
            },
            () => {
                if (this.state.textarea && this.state.textarea.length > 0) {
                    this.deboucedQueryChanged(cursorPosition);
                } else if (this.state.textarea === '') {
                    this.props.queryChanged(this.state.textarea, cursorPosition);
                    this.partiallyResetSearchQuery();
                }
            }
        );
    }

    textareaBlurHandler() {
        this.setState({
            inputTrimmedVisible: true,
        });
    }

    textareaKeyPress(e) {
        if (e.key === 'Enter' || e.keyCode === 13) {
            this.setState(
                {
                    inputTrimmedVisible: true,
                },
                () => {
                    if (this.props.selectedSources === 0 || this.props.hasInsufficientCharacters) {
                        return;
                    }
                    //due to the fact that redux is updated(prev line), but the change is not reflected into component, passing the new query
                    this.textareaInput.current.blur();
                    this.props.handleSearchEvent(e, this.state.textarea);
                }
            );
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.uboSelected && this.props.uboSelected.length) {
            // DISCLAIMER: since this is a semi-controlled component, we have to trigger things manually around here (sigh!)
            // rudimentary way to handle when ubo changes, but (at least) without interfering with existing flow
            const oldDuns = prevProps.uboSelected.map((item) => item.duns);
            const newDuns = this.props.uboSelected.map((item) => item.duns);
            const diff = difference(newDuns, oldDuns);
            if (diff.length) {
                // simulate some interaction if something in the ubo changed
                this.textareaBlurHandler();
                setTimeout(this.handleEditMode, 0); // kids, don't try this at home
            }
        }
        if (!this.state.inputTrimmedVisible) {
            this.textareaInput.current.focus();
        }
    }

    render() {
        return (
            <div className={'search-box-container'}>
                <div className="search-input-trimmed-container">
                    <div
                        className={'search-input-trimmed' + (!this.state.inputTrimmedVisible ? ' hidden' : '')}
                        onClick={this.handleEditMode}
                        data-track={this.props.dataTrack}
                    >
                        {this.props.query === '' ? (
                            <div className="search-input-placeholder">{this.props.placeholder}</div>
                        ) : (
                            <div>{this.props.query}</div>
                        )}
                    </div>
                </div>
                <div className={'search-input-container ' + (this.state.inputTrimmedVisible ? ' hidden' : '')}>
                    <textarea
                        className="search-input-full"
                        style={this.state.textareaStyle}
                        ref={this.textareaInput}
                        value={this.state.textarea}
                        onChange={this.queryChange}
                        onBlur={this.textareaBlurHandler}
                        onKeyPress={this.textareaKeyPress}
                    />
                </div>
                {(this.props.query !== '' || !this.state.inputTrimmedVisible) && (
                    <button className="la-CloseRemove" onClick={this.resetSearchQuery} />
                )}
            </div>
        );
    }
}

export default SearchBox;
