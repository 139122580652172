import React from 'react';
import * as DilProps from '@utils/props';
import utils from '@utils/utilities';

/**
 * Handles sorting, adds sorting arrows to table headers and provides sorting handlers
 * Use with DefaultSortableHeader component to show the sorting arrows
 * @param TableComponent
 * @returns {function(*)}
 */

const withSorting = (TableComponent) => {
    const withSorting = (props) => {
        const handleSorting = (sortingInfo) => {
            props.sorting.onChangeSorting(sortingInfo);
            props.pagination.onChangePagination({ pageNumber: 0 }, props.data.onLoadData);
        };

        const sortingProp = { ...props.sorting, onChangeSorting: handleSorting };

        return <TableComponent {...props} sorting={sortingProp} />;
    };

    withSorting.displayName = `withSorting(${utils.getDisplayName(TableComponent)})`;

    withSorting.propTypes = {
        sorting: DilProps.TableSorting,
    };

    return withSorting;
};

export default withSorting;
