import React, { Fragment } from 'react';
import { Loader } from 'semantic-ui-react';
import withSelectBox from './hoc/withSelectBox';
import { FormattedMessage } from 'react-intl';
import hasLoader from '../hoc/hasLoader';
import * as DilProps from '@utils/props';
import BoxErrorMessage from '@reusable/ErrorMessage/BoxErrorMessage';
import { SORT_DIRECTION } from '@constants';
import utils from '@utils/utilities';

export const DEFAULT_MESSAGES = {
    errorMessage: 'General_CoreFunctionality_Error_general.error',
};

export const DefaultCell = (props) => (
    <div className={'cell ' + (props.className ? props.className : '')}>{props.children}</div>
);

export const DefaultHeaderCell = (props) => (
    <div className={'cell ' + (props.className ? props.className : '')}>{props.children}</div>
);

export const DefaultHeader = (props) => (
    <Fragment>
        {props.headers.map((header) => (
            <DefaultHeaderCell key={header.columnKey} className={'header-' + header.columnKey}>
                {header.label}
            </DefaultHeaderCell>
        ))}
    </Fragment>
);

export const DefaultSortableHeader = (props) => {
    function getComposedClass(header) {
        let className = header.direction === SORT_DIRECTION.DESC ? 'la-TriangleUp' : 'la-TriangleDown';
        className += header.useActiveClass ? ' ' + header.useActiveClass : ''; //mark the active sorting field

        return className;
    }

    function handleChangeSorting(e) {
        let sortingInfo = {
            sortBy: e.target.dataset.sortedby,
            direction: e.target.dataset.direction,
        };

        props.onChangeSorting(sortingInfo);
    }

    return (
        <Fragment>
            {props.headers.map((header) => (
                <DefaultHeaderCell key={header.columnKey} className={'header-' + header.columnKey}>
                    <div className="header-text">{header.label}</div>
                    {header.sortable &&
                        (header.sortedBy ? (
                            <SortBtn
                                className={getComposedClass(header)}
                                onClick={handleChangeSorting}
                                direction={
                                    header.direction === SORT_DIRECTION.DESC ? SORT_DIRECTION.ASC : SORT_DIRECTION.DESC
                                }
                                sortedBy={header.columnKey}
                            ></SortBtn>
                        ) : (
                            <SortBtn
                                className={getComposedClass(header)}
                                onClick={handleChangeSorting}
                                direction={
                                    header.direction === SORT_DIRECTION.DESC ? SORT_DIRECTION.ASC : SORT_DIRECTION.DESC
                                }
                                sortedBy={header.columnKey}
                            ></SortBtn>
                        ))}
                </DefaultHeaderCell>
            ))}
        </Fragment>
    );
};

// this should be a temporary solution until all sortable headers will be the same by default
export const AlertsSortableHeader = (props) => {
    const getActiveClass = (header) => {
        if (!header.useActiveClass) return '';

        const className = header.direction === SORT_DIRECTION.DESC ? 'la-TriangleUp-active' : 'la-TriangleDown-active';
        return className;
    };

    function handleChangeSorting(e) {
        let sortingInfo = {
            sortBy: e.target.dataset.sortedby,
            direction: e.target.dataset.direction,
        };

        props.onChangeSorting(sortingInfo);
    }

    return (
        <Fragment>
            {props.headers.map((header) => (
                <DefaultHeaderCell key={header.columnKey} className={'header-' + header.columnKey}>
                    <div className="header-text">{header.label}</div>
                    {header.sortable &&
                        (header.sortedBy ? (
                            <AlertsSortBtn
                                className={getActiveClass(header)}
                                onClick={handleChangeSorting}
                                direction={
                                    header.direction === SORT_DIRECTION.DESC ? SORT_DIRECTION.ASC : SORT_DIRECTION.DESC
                                }
                                sortedBy={header.columnKey}
                            ></AlertsSortBtn>
                        ) : (
                            <AlertsSortBtn
                                className={getActiveClass(header)}
                                onClick={handleChangeSorting}
                                direction={
                                    header.direction === SORT_DIRECTION.DESC ? SORT_DIRECTION.ASC : SORT_DIRECTION.DESC
                                }
                                sortedBy={header.columnKey}
                            ></AlertsSortBtn>
                        ))}
                </DefaultHeaderCell>
            ))}
        </Fragment>
    );
};

export const DefaultRow = ({ row, headers }) =>
    row.renderRow ? row.renderRow(row, headers) : <DefaultRowCells row={row} headers={headers} />;

export const DefaultRowCells = ({ row, headers }) =>
    headers.map((header, index) => (
        <DefaultCell className={header.columnKey ? 'col-' + header.columnKey : ''} key={index}>
            {row.columns[header.columnKey]}
        </DefaultCell>
    ));

export const DefaultRows = ({ data, headers }) =>
    data.rows &&
    data.rows.map((row) => (
        <div className={'row'} key={row.key}>
            <DefaultRow headers={headers} row={row} />
        </div>
    ));

export const DefaultLoader = ({ size = 'large', inline = 'centered' }) => <Loader size={size} inline={inline} active />;

export const SmallLoader = (props) => <DefaultLoader size={'small'} {...props} />;

export const MassiveLoader = ({ size = 'massive' }) => <Loader size={size} inline={'centered'} active />;

export const DefaultSelectableRow = (props) => {
    const Row = hasLoader(SmallLoader)(withSelectBox(DefaultRow));
    return <Row {...props} />;
};

export const DefaultSelectableRows = ({ data, headers, onToggleSelect }) =>
    data.rows &&
    data.rows.map((row) => (
        <div className={'row'} key={row.key} data-testid="selectable-rows">
            <DefaultSelectableRow
                headers={headers}
                row={row}
                data={{ isLoading: row.isLoading || false }}
                selected={row.selected}
                onToggleSelect={onToggleSelect}
            />
        </div>
    ));

export const DefaultError = ({ defaultMessages = DEFAULT_MESSAGES }) => (
    <BoxErrorMessage messageId={defaultMessages && defaultMessages.errorMessage} />
);

export const DefaultIsEmpty = () => <div>Empty</div>;

export const DefaultCheckbox = ({ onToggleSelect, value, item, checked, children, disabled }) => {
    function handleToggleCheck() {
        onToggleSelect(item, value);
    }
    return (
        <div className="custom-checkbox">
            <input
                type="checkbox"
                id={value}
                value={value}
                onChange={handleToggleCheck}
                data-testid={'select-checkbox-' + value}
                checked={checked}
                disabled={disabled ?? false}
            />
            <label htmlFor={value} className="checkbox-label">
                {children}
            </label>
        </div>
    );
};

export const DefaultCounter = ({ data }) => (
    <div className="table-count">
        <FormattedMessage
            id={'DefaultTable.header.showingCountNumber'}
            values={{
                itemsLength: utils.formatNumbersByUserLocale(data.language, data.filterCount),
                itemsCount: utils.formatNumbersByUserLocale(data.language, data.totalCount),
            }}
        />
    </div>
);

DefaultCounter.propTypes = {
    data: DilProps.TableData,
};

export const DefaultActivePill = ({ isActive }) => (
    <div className="status-pill">
        {isActive ? (
            <div className="active">
                <FormattedMessage id="ManageAlertsSingleAlert.activeAlert" />
            </div>
        ) : (
            <div className="paused">
                <FormattedMessage id="ManageAlertsSingleAlert.pausedAlert" />
            </div>
        )}
    </div>
);

export const ActionBtn = ({ onClick, icon, children, disabled = false }) => (
    <button className="action-button" disabled={disabled} onClick={onClick}>
        <span className={icon} />
        {children}
    </button>
);

export const PauseBtn = ({ onClick, disabled }) => (
    <ActionBtn onClick={onClick} icon={'la-Pause'} disabled={disabled}>
        <FormattedMessage id={'General_CoreFunctionality_UIText_general.pause'} />
    </ActionBtn>
);

export const ResumeBtn = ({ onClick, disabled }) => (
    <ActionBtn onClick={onClick} icon={'la-Play'} disabled={disabled}>
        <FormattedMessage id={'General_CoreFunctionality_UIText_general.resume'} />
    </ActionBtn>
);
export const DeleteBtn = ({ onClick, disabled }) => (
    <ActionBtn onClick={onClick} icon={'la-Delete'} disabled={disabled}>
        <FormattedMessage id={'General_CoreFunctionality_UIText_general.delete'} />
    </ActionBtn>
);

export const SortBtn = ({ onClick, className = '', sortedBy, direction }) => (
    <button
        className={'sort-action-btn ' + className}
        data-direction={direction}
        data-sortedby={sortedBy}
        onClick={onClick}
    ></button>
);

export const AlertsSortBtn = ({ onClick, className = '', sortedBy, direction }) => (
    <div className="chevron-wrapper">
        <button
            className={'sort-action-btn la-TriangleUp ' + className}
            data-direction={direction}
            data-sortedby={sortedBy}
            onClick={onClick}
        ></button>
        <button
            className={'sort-action-btn la-TriangleDown ' + className}
            data-direction={direction}
            data-sortedby={sortedBy}
            onClick={onClick}
        ></button>
    </div>
);

export const RowActionBtn = ({ onClick, icon, children, className = '', disabled = false }) => (
    <button className={'row-action-btn ' + className} disabled={disabled} onClick={onClick}>
        <span className={icon} />
        {children}
    </button>
);

export const TagPill = (props) => (
    <div className={'new-label'}>
        {props.label ? props.label : <FormattedMessage id={'General_CoreFunctionality_UIText_general.new'} />}
    </div>
);

export const DefaultBottomLegend = (props) => {
    return props.legend ? (
        <Fragment>
            {' '}
            {props.legend.map((row, index) => (
                <div key={index}>{row.label}</div>
            ))}
        </Fragment>
    ) : null;
};
