import categoryUtils from '@utils/categoryUtils';
import { withContentSourceFilter } from '@utils/utilities';
import { getCategoryRequestPayload, getUboCategoryRequestPayload } from '@pages/MainSearch/SearchUtils';
import { API_SEARCH_VERSION, ID_TYPES } from '@constants';

// TODO: refactor params
export const getDocumentRequestParams = (
    id,
    searchQuery,
    searchQueryType,
    category,
    billingId,
    docId,
    prefilterQuery,
    proximity,
    includeTerms,
    costCode,
    singleSource,
    fuzzyThreshold,
    suggestedNames,
    articleData = {},
    terms
) => {
    let version = '';
    let idType = ID_TYPES.DOC_ID;
    includeTerms = Array.isArray(includeTerms)
        ? includeTerms
        : includeTerms && includeTerms.length !== 0
        ? includeTerms.split(',')
        : [];

    terms = Array.isArray(terms) ? terms : terms && terms.length !== 0 ? terms.split(',') : [];

    if (docId) {
        id = docId;
    } else {
        idType = ID_TYPES.LNI;
    }

    let extraProperties = {};

    if (fuzzyThreshold !== null && fuzzyThreshold !== undefined && fuzzyThreshold !== '') {
        extraProperties.fuzzyThreshold = fuzzyThreshold;
    }

    let data = {
        [idType]: id,
        searchQuery,
        searchQueryType,
        category,
        billingId: billingId,
        prefilterQuery,
        proximity,
        includeTerms,
        costCode,
        singleSource,
        suggestedNames,
        terms,
        ...extraProperties,
    };

    if (category) {
        data = withContentSourceFilter().extendConfig(category, data);
    }

    if (data.newsQueries && data.newsQueries.length) {
        data.query = data.newsQueries[0].query;
        data.negativeNewsQueries = data.newsQueries[0].negativeNewsQueries;
        delete data.newsQueries;
    }

    if (categoryUtils.isDnbCategory(category)) {
        const name = articleData.name || articleData.title;
        return {
            url: `/api/ubo/details?duns=${id}&tradeUp=${articleData.isBranch}&name=${name}&billingId=${billingId}&costCode=${costCode}`,
            method: 'get',
            data: {
                duns: id,
            },
        };
    }

    return {
        url: '/api/' + version + 'search/document',
        method: 'post',
        data,
    };
};

export const getLoadPageRequestParams = (category, pageNumber) => {
    const ts = new Date().getTime();
    if (categoryUtils.isDnbCategory(category.name)) {
        const url = `/api/ubo/resultsList`;
        const data = getUboCategoryRequestPayload(category);
        return {
            url,
            method: 'post',
            params: { ts },
            data: {
                ...data,
                pageNumber,
            },
        };
    } else {
        const data = getCategoryRequestPayload(category);
        const version = API_SEARCH_VERSION;
        const url = `/api/${version}/search/documents`;

        return {
            url,
            method: 'post',
            params: { ts },
            data: {
                ...data,
                pageNumber,
            },
        };
    }
};

export const searchDocAccessEvent = (request, documentAccessType) => {
    return {
        url: `/api/billing/searchDocAccessEvent?documentAccessType=${documentAccessType}`,
        method: 'post',
        data: { ...request, version: API_SEARCH_VERSION },
    };
};
