import React from 'react';
import { ALERT_MESSAGE_TYPE } from '@constants';
import AlertMessage from '@reusable/AlertMessage/AlertMessage';

const ModalAnonymizedWarning = ({ isVisible }) => {
    return isVisible ? (
        <AlertMessage
            type={ALERT_MESSAGE_TYPE.WARNING}
            ids={[
                'Alerts.createAlert.anonymized.modalBody1',
                'Alerts.createAlert.anonymized.modalBody2',
                'Alerts.createAlert.anonymized.modalBody3',
            ]}
        />
    ) : null;
};

export default ModalAnonymizedWarning;
