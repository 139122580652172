import React, { useState, useEffect, useRef, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { MULTIPLES_ENTITIES_CREATE_NEW_VIEW } from '@constants';

const CustomDropdown = ({
    label = '',
    defaultValue = {},
    options = [],
    changeOption,
    inputField,
    showCreateNewViewInput,
    setShowCreateNewViewInput,
    disabled = false,
}) => {
    // state
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);

    // refs
    const popoverRef = useRef(null);
    const inputRef = useRef(null);

    const handleClickOutside = useCallback(
        (event) => {
            if (
                popoverRef.current &&
                !popoverRef.current.contains(event.target) &&
                !inputRef.current.contains(event.target) && 
                !event.target.className.includes('input-container')
            ) {
                handleOpen();
            }
        },
        [popoverRef, inputRef]
    );

    useEffect(() => {
        
        if(Object.keys(defaultValue).length) {
            setSelectedOption(defaultValue)
            changeOption(defaultValue)
        }
    }, []);

    useEffect(() => {
        if(!showCreateNewViewInput && inputField === '') {
            setSelectedOption(defaultValue);
        };
    }, [showCreateNewViewInput])

    useEffect(() => {
        isOpen && document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [handleClickOutside, isOpen]);

    const handleOpen = () => {
        !disabled && setIsOpen((prevIsOpen) => !prevIsOpen);
    };

    const handleSelect = (currentOption) => {
        if (currentOption.id === MULTIPLES_ENTITIES_CREATE_NEW_VIEW) {
            setShowCreateNewViewInput((selectedOption) => !selectedOption );
            setSelectedOption(currentOption);
        } else {
            setSelectedOption(currentOption);
            changeOption(currentOption);
            // Close dropdown when selected option changes
            setIsOpen(false);
        }
    };

    const renderOptions = () => {
        return options?.map((option, idx) => {
            if (option.id === MULTIPLES_ENTITIES_CREATE_NEW_VIEW) {
                return (
                    <div
                        key="create-new-view-key"
                        data-testid="create-new-view"
                        onClick={() => handleSelect(option)}
                    >
                        <div
                            className="dropdown-popover__options__option"
                        >
                            <span className="la-ZoomIn icon"></span>
                            <span>
                                <FormattedMessage id={'BatchUpload.uploadSection.createNewView'} />
                            </span>
                        </div>
                        <div className="horizontal-divider"></div>
                    </div>
                )
            } else {
                return (
                    <div
                        key={`${option.id}-${idx}`}
                        onClick={() => handleSelect(option)}
                        className="dropdown-popover__options__option"
                        disabled={!option.isShareable}
                        data-testid={`${option.id}`}
                    >
                        <span>{option.name}</span>
                    </div>
                )
            }
        });
    };

    return (
        <div className="dropdown-container" data-testid="custom-dropdown-container">
            {Boolean(label) && (
                <div className="label-container" data-testid="custom-dropdown-label">
                    <FormattedMessage id={label} />
                </div>
            )}
            <div
                ref={inputRef}
                className={`input-container ${disabled ? 'disabled-dropdown' : ''}`}
                onClick={handleOpen}
                data-track="custom-dropdown-input"
                data-testid="custom-dropdown-input"
            >
                <p data-testid="custom-dropdown-input-placeholder">{selectedOption?.name ?? ''}</p>
                <div className={isOpen ? 'la-TriangleUp' : 'la-TriangleDown'} />
            </div>
            {isOpen && (
                    <div ref={popoverRef} className="dropdown-popover" data-testid="custom-dropdown-ref">
                        <div className="dropdown-popover__options" data-testid="custom-dropdown-options">
                            {renderOptions()}
                        </div>
                    </div>
                )
            }
        </div>
    );
};

export default CustomDropdown;
