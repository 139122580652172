import React from 'react';
import { FormattedMessage } from 'react-intl';

function ModalComments(props) {
    if (!props.isVisible) {
        return <span></span>;
    }

    return (
        <div className="popup-modal__comments">
            <div className="popup-field-name">
                <FormattedMessage id={props.label} />:
            </div>
            {props.popupCommentsCharsLeft <= 0 && (
                <div className="validationAlertMessage">
                    <FormattedMessage id={'Alerts.createAlert.maxCharsValidationMessage'} />
                </div>
            )}
            <textarea
                className="text-area-standard"
                data-testid="popup-modal-comments-id"
                id="qa-report-comments-text-field"
                value={props.popupComments}
                onChange={props.popupCommentsChange}
            />
            {props.description && (
                <div className="popup-modal__comments__description">
                    <FormattedMessage id={props.description} />
                </div>
            )}
        </div>
    );
}

export default ModalComments;
