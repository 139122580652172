export const SET_METRIC_START = 'SET_METRIC_START';
export const RESET_METRIC = 'RESET_METRIC';
export const SET_METRIC_END = 'SET_METRIC_END';

let metricsActions = {
    resetMetric: () => {
        return {
            type: RESET_METRIC,
            payload: {},
        };
    },

    setMetricStart: (metricData) => {
        return {
            type: SET_METRIC_START,
            payload: metricData,
        };
    },

    setMetricEnd: (metricData) => {
        return {
            type: SET_METRIC_END,
            payload: metricData,
        };
    },
};

export default metricsActions;
