import React, { Fragment } from 'react';
import * as DilProps from '@utils/props';
import utils from '@utils/utilities';
import formatRichMessage from '@utils/formatRichMessage';
import { injectIntl } from 'react-intl';

/**
 * Handles filtering, adds a filter dropdown and provides filter handlers
 * @param TableComponent
 * @returns {function(*)}
 */

const withFilter = (TableComponent) => {
    const withFilter = (props) => {
        const handleSelectOption = (e) => {
            props.filtering.onSelectOption(e.target.value);
            props.pagination.onChangePagination({ pageNumber: 0 }, props.data.onLoadData);
        };

        return (
            <Fragment>
                <select
                    disabled={props.documentPreview}
                    data-testid="select"
                    className="filter-dropdown"
                    onChange={handleSelectOption}
                    value={props.filtering.filteringDropdownSelected.toString()}
                >
                    {props.filtering.filteringDropdownOptions.map((dropdownOption, index) => {
                        return (
                            <option
                                key={index}
                                value={dropdownOption[Object.keys(dropdownOption)]}
                            >
                                {formatRichMessage({ id: Object.keys(dropdownOption).toString() }, props.intl)}
                            </option>
                        );
                    })}
                </select>
                <TableComponent {...props} />
            </Fragment>
        );
    };

    withFilter.propTypes = {
        filtering: DilProps.TableFiltering.isRequired,
        data: DilProps.TableData.isRequired,
        pagination: DilProps.TablePagination,
    };

    withFilter.displayName = `WithFilter(${utils.getDisplayName(TableComponent)})`;

    return injectIntl(withFilter);
};

export default withFilter;
