import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import GroupDateSelect from '../GroupDateSelect';
import withAdminLock from '@reusable/AdminLock/hoc/withAdminLock';
import { FE_LOCK_NAMES, CATEGORY_NAMES } from '@constants';
import utils from '@utils/utilities';

// Categories variant of GroupDateSelect -- all specific category values and components go here
// Also LOCKS

export const CategoriesGroupDateSelect = (props) => {
    const areLegalSourcesAvailable = utils.filterContentTypesByOfferings(props.userOfferings, {
        name: CATEGORY_NAMES.LAW_SOURCES,
    });
    const areCompanySourcesAvailable = utils.filterContentTypesByOfferings(props.userOfferings, {
        name: CATEGORY_NAMES.COMPANY_RESOURCES,
    });
    const permissionMap = {
        newsSources: FE_LOCK_NAMES.newsSearchesDateRange,
        companySources: FE_LOCK_NAMES.companySource,
        legalSources: FE_LOCK_NAMES.legalSourceDefaultDateRange,
    };
    const componentsMap = [
        {
            meta: 'newsSources',
            enabled: true,
            title: 'UserPreferences.CategoriesDefaultDates.news',
            locks: { id: permissionMap.newsSources, masterId: FE_LOCK_NAMES.categoriesDefaultDateRange },
            dataTestId: 'news-sources-date-range',
        },
        {
            meta: 'companySources',
            enabled: areCompanySourcesAvailable,
            title: 'UserPreferences.CategoriesDefaultDates.company',
            locks: { id: permissionMap.companySources, masterId: FE_LOCK_NAMES.categoriesDefaultDateRange },
            dataTestId: 'company-sources-date-range',
        },
        {
            meta: 'legalSources',
            enabled: areLegalSourcesAvailable,
            title: 'UserPreferences.CategoriesDefaultDates.legal',
            locks: { id: permissionMap.legalSources, masterId: FE_LOCK_NAMES.categoriesDefaultDateRange },
            dataTestId: 'legal-sources-date-range',
        },
    ];
    const availableComponents = componentsMap.filter((compMap) => compMap.enabled);
    const areAllComponentsDisabled = !Object.values(permissionMap)
        .map((lockName) => props.adminLocks.userLocks[lockName])
        .filter((locked) => !locked).length;
    const isDisabled = !props.adminLocks.isEditable && (props.disabled || areAllComponentsDisabled);

    // change handler modified to not accept changes from locked sections
    const handleChange = (value, section) => {
        // cutting off change event on the way up if this is disabled or section is disabled
        if ((!props.disabled && !props.adminLocks.userLocks[permissionMap[section]]) || props.adminLocks.isEditable) {
            props.onChange(value, section);
        }
    };

    return (
        <GroupDateSelect {...props} components={availableComponents} onChange={handleChange} disabled={isDisabled} />
    );
};

CategoriesGroupDateSelect.propTypes = {
    ...GroupDateSelect.propTypes,
    components: PropTypes.any,
    disabled: PropTypes.bool,
};

CategoriesGroupDateSelect.defaultProps = {
    messages: {
        title: (
            <Fragment>
                <b>
                    <FormattedMessage id={'UserPreferences.CategoriesDefaultDates.defaultTitlePart1'} />
                    &nbsp;
                </b>
                <FormattedMessage id={'UserPreferences.CategoriesDefaultDates.defaultTitlePart2'} />
            </Fragment>
        ),
        content: <FormattedMessage id={'UserPreferences.CategoriesDefaultDates.defaultText'} />,
        hideItems: <FormattedMessage id={'UserPreferences.CategoriesDefaultDates.hideCategories'} />,
        showItems: <FormattedMessage id={'UserPreferences.CategoriesDefaultDates.showCategories'} />,
    },
    disabled: false,
};

export const CategoriesGroupDateSelectWithLock = withAdminLock(
    FE_LOCK_NAMES.categoriesDefaultDateRange,
    null,
    true
)(CategoriesGroupDateSelect);

export default CategoriesGroupDateSelect;
