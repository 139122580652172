import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DateSelect from './DateSelect';
import { CALENDAR_DATE_FORMAT_FE } from '@constants';
import formatRichMessage from '@utils/formatRichMessage';
import { injectIntl } from 'react-intl';

class DateRangeSelect extends Component {
    static propTypes = {
        onRangeSelect: PropTypes.func.isRequired,
        initialValues: PropTypes.shape({
            from: PropTypes.instanceOf(Date),
            to: PropTypes.instanceOf(Date),
        }),
        className: PropTypes.string,
        isValidating: PropTypes.bool,
        dateFormat: PropTypes.string,
    };

    static defaultProps = {
        initialValues: null,
        className: '',
        isValidating: false,
        dateFormat: CALENDAR_DATE_FORMAT_FE,
    };

    constructor(props) {
        super(props);

        this.state = {
            from: props.initialValues ? props.initialValues.from : null,
            to: props.initialValues ? props.initialValues.to : null,
        };

        this.handleSelectFromDate = this.handleSelectFromDate.bind(this);
        this.handleSelectToDate = this.handleSelectToDate.bind(this);
    }

    handleSelectFromDate(from) {
        this.setState({ from }, this.updateRange);
    }

    handleSelectToDate(to) {
        this.setState({ to }, this.updateRange);
    }

    updateRange() {
        if (this.state.from && this.state.to) {
            this.props.onRangeSelect(this.state.from, this.state.to);
        } else {
            if (this.state.from) {
                this.props.onRangeSelect(this.state.from, this.props.initialValues.to || null);
            }
            if (this.state.to) {
                this.props.onRangeSelect(this.props.initialValues.from || null, this.state.to);
            }
        }
    }

    render() {
        return (
            <div className={'date-range-select ' + this.props.className}>
                <DateSelect
                    onChange={this.handleSelectFromDate}
                    className={this.props.isValidating ? (this.state.from === null ? 'with-error' : '') : ''}
                    label={formatRichMessage({ id: 'General.range.from' }, this.props.intl)}
                    name={'date-from'}
                    value={this.props.initialValues ? this.props.initialValues.from : null}
                    maxDate={this.props.initialValues ? this.props.initialValues.to : null}
                    disabled={this.props.disabled}
                />
                <DateSelect
                    onChange={this.handleSelectToDate}
                    className={this.props.isValidating ? (this.state.to === null ? 'with-error' : '') : ''}
                    label={formatRichMessage({ id: 'General.range.to' }, this.props.intl)}
                    name={'date-to'}
                    value={this.props.initialValues ? this.props.initialValues.to : null}
                    minDate={this.props.initialValues ? this.props.initialValues.from : null}
                    disabled={this.props.disabled}
                />
            </div>
        );
    }
}

export default injectIntl(DateRangeSelect);
