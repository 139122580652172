import React from 'react';
import {
    EXCLUDE,
    INCLUDE,
    REMOVE_ALL_INCLUDE_TERMS,
    POSTFILTER_COMPONENTS,
    FILTER_BAR_DATE_RANGE_CUSTOM_LABEL,
    CALENDAR_DATE_FORMAT_BE,
    PERSON_SEARCH,
    CATEGORY_NAMES,
    TERMS,
} from '@constants';
import ActiveFilterItem from './ActiveFilterItem';
import { List, Transition } from 'semantic-ui-react';
import ActiveFilterItemsGroup from './ActiveFilterItemsGroup';
import utils from '@utils/utilities';
import { FormattedMessage, injectIntl } from 'react-intl';
import formatRichMessage from '@utils/formatRichMessage';
import categoryUtils from '@utils/categoryUtils';
import { cloneDeep } from 'lodash';

class ActiveFilterList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showResetButton: false,
        };
    }

    shouldComponentUpdate(nextProps) {
        let sameTerms =
            this.props.includeTerms === nextProps.includeTerms && this.props.excludeTerms === nextProps.excludeTerms;
        let sameDateRange = this.props.dateRange && this.props.dateRange === nextProps.dateRange;
        let sameSimilarNames = this.props.similarNames && this.props.similarNames === nextProps.similarNames;
        let sameSuggestedNames = this.props.suggestedNames && this.props.suggestedNames === nextProps.suggestedNames;
        let sameLanguage = this.props.language && this.props.language === nextProps.language;
        let sameSource = this.props.source && this.props.source === nextProps.source;
        let sameSourceName = this.props.sourceName && this.props.sourceName === nextProps.sourceName;
        let sameProximity = this.props.proximity && this.props.proximity === nextProps.proximity;
        let sameGeography = this.props.geography && this.props.geography === nextProps.geography;
        let sameCompany = this.props.company && this.props.company === nextProps.company;
        let sameSourceType = this.props.sourceType && this.props.sourceType === nextProps.sourceType;
        let sameCompanyMentions =
            this.props.companyMentions && this.props.companyMentions === nextProps.companyMentions;
        let samePersonMentions = this.props.personMentions && this.props.personMentions === nextProps.personMentions;
        let sameDocketStatus = this.props.docketStatus && this.props.docketStatus === nextProps.docketStatus;
        let sameIndustry = this.props.industry && this.props.industry === nextProps.industry;
        let sameSubject = this.props.subject && this.props.subject === nextProps.subject;
        let sameEsgFactors = this.props.esgFactors && this.props.esgFactors === nextProps.esgFactors;

        let sameLoadingState =
            this.props.isArticleListLoaded && this.props.isArticleListLoaded === nextProps.isArticleListLoaded;

        if (
            sameTerms &&
            sameDateRange &&
            sameSimilarNames &&
            sameSuggestedNames &&
            sameLoadingState &&
            sameLanguage &&
            sameSource &&
            sameSourceName &&
            sameProximity &&
            sameGeography &&
            sameCompany &&
            sameSourceType &&
            sameCompanyMentions &&
            samePersonMentions &&
            sameDocketStatus &&
            sameIndustry &&
            sameSubject &&
            sameEsgFactors
        ) {
            return false;
        }
        return true;
    }

    getSimilarNameLabel() {
        return 'PostFilters.Title.CompanyNames';
    }

    getSuggestedNamesLabel() {
        return 'PostFilters.Title.SuggestedNames';
    }

    getFuzzySearchLabel = (originalValues) => {
        let values = cloneDeep(originalValues);

        let label = formatRichMessage({ id: 'ResultsListHeader.postFilters.fuzzySearch.off' }, this.props.intl);
        let thresholdBasedLabel = this.props.fuzzyThreshold
            ? formatRichMessage(
                  { id: `ResultsListHeader.postFilters.fuzzySearch.on.${this.props.fuzzyThreshold}` },
                  this.props.intl
              )
            : formatRichMessage({ id: `ResultsListHeader.postFilters.fuzzySearch.on.close` }, this.props.intl);

        if (values && values.length) {
            if (values[0].count === true) {
                label = thresholdBasedLabel;
            }

            values[0].name = label;

            return values;
        } else {
            if (this.props.fuzzyThresholdPersonLock) {
                if (this.props.fuzzyOn) {
                    label = thresholdBasedLabel;
                }

                return [
                    {
                        name: label,
                    },
                ];
            }
        }
    };

    render() {
        let showDateRangeResetButton = false;
        let showProximityResetButton = false;
        let showCompanyMentionsResetButton = false;
        let showPersonMentionsResetButton = false;
        let showDocketStatusResetButton = false;
        let showNegativityLevelsResetButton = false;

        if (this.props.dateRange && this.props.dateRange.length > 0) {
            if (
                this.props.defaultDateRange &&
                utils.getDateFromString(this.props.defaultDateRange.split(';')[0], CALENDAR_DATE_FORMAT_BE)
            ) {
                showDateRangeResetButton = this.props.dateRange[0].name !== FILTER_BAR_DATE_RANGE_CUSTOM_LABEL;
            } else {
                showDateRangeResetButton =
                    utils.dateRangeToText(this.props.defaultDateRange) !== this.props.dateRange[0].name;
            }
        }

        if (this.props.proximity && this.props.proximity.length > 0) {
            showProximityResetButton =
                utils.proximityToText(this.props.defaultProximity) !== this.props.proximity[0].name;
        }

        if (this.props.companyMentions && this.props.companyMentions.length > 0) {
            showCompanyMentionsResetButton = true;
        }

        if (this.props.personMentions && this.props.personMentions.length > 0) {
            showPersonMentionsResetButton = true;
        }

        if (this.props.docketStatus && this.props.docketStatus.length === 1) {
            showDocketStatusResetButton = true;
        }

        if (this.props.negativityLevels && this.props.defaultNegativityLevels) {
            let hasSameItems = false;

            if (this.props.negativityLevels.length === this.props.defaultNegativityLevels.length) {
                hasSameItems = this.props.negativityLevels.every(
                    (level) => this.props.defaultNegativityLevels.indexOf(level.name) > -1
                );
            }

            showNegativityLevelsResetButton = !hasSameItems;
        }

        const renderCategoryLabel = () => {
            const categoryKey = categoryUtils.getCategoryLabel(this.props.articleType, true);
            const articleTypeResolved = categoryUtils.resolvedToTranslatableCategory(categoryKey);

            const categoryName = formatRichMessage(
                { id: `General.categoryName.label.${articleTypeResolved}` },
                this.props.intl
            );
            const currentCategory = formatRichMessage({ id: `PostFilters.Title.CurrentCategory` }, this.props.intl, {
                categoryName,
            });
            return <span data-testid="category-label" dangerouslySetInnerHTML={{ __html: currentCategory }}></span>;
        };

        return (
            <div className="active-filter-container notranslate">
                <div className="active-filter-list">
                    <Transition.Group animation="slide right" duration="500">
                        <div className="filters-list">
                            <div className="category-filter">{renderCategoryLabel()}</div>
                            {this.props.includeTerms &&
                                this.props.includeTerms.map((filter) => {
                                    return (
                                        <List.Item key={filter}>
                                            <ActiveFilterItem
                                                key={filter}
                                                filter={filter}
                                                name={
                                                    formatRichMessage({ id: `SearchWithin.include` }, this.props.intl) +
                                                    ': '
                                                }
                                                label={INCLUDE}
                                                removeFilter={this.props.removeFilter}
                                            />
                                        </List.Item>
                                    );
                                })}
                            {this.props.excludeTerms &&
                                this.props.excludeTerms.map((filter) => {
                                    return (
                                        <List.Item key={filter}>
                                            <ActiveFilterItem
                                                key={filter}
                                                filter={filter}
                                                name={
                                                    formatRichMessage({ id: `SearchWithin.exclude` }, this.props.intl) +
                                                    ': '
                                                }
                                                label={EXCLUDE}
                                                removeFilter={this.props.removeFilter}
                                            />
                                        </List.Item>
                                    );
                                })}
                        </div>
                    </Transition.Group>
                    {this.props.isArticleListLoaded &&
                        this.props.similarNames &&
                        this.props.similarNames.length > 0 && (
                            <ActiveFilterItemsGroup
                                name={this.getSimilarNameLabel()}
                                values={this.props.similarNames}
                                type={POSTFILTER_COMPONENTS.SIMILAR_NAMES.type}
                                showResetButton={this.props.defaultSimilarNames}
                                resetFilter={this.props.resetFilter}
                                uniqueId={this.props.uniqueId}
                            />
                        )}
                    {this.props.suggestedNamesEnabled &&
                        this.props.isArticleListLoaded &&
                        this.props.suggestedNames &&
                        this.props.suggestedNames.length > 0 && (
                            <ActiveFilterItemsGroup
                                name={this.getSuggestedNamesLabel()}
                                values={this.props.suggestedNames}
                                type={POSTFILTER_COMPONENTS.SUGGESTED_NAMES.type}
                                showResetButton={this.props.defaultSuggestedNames}
                                resetFilter={this.props.resetFilter}
                                uniqueId={this.props.uniqueId}
                            />
                        )}
                    {this.props.isArticleListLoaded &&
                        this.props.source &&
                        this.props.source.length > 0 &&
                        this.props.contentLanguage !== 'Portuguese' && (
                            <ActiveFilterItemsGroup
                                name={POSTFILTER_COMPONENTS.SOURCE.label}
                                values={this.props.source}
                                type={POSTFILTER_COMPONENTS.SOURCE.type}
                                resetFilter={this.props.resetFilter}
                                showResetButton={this.props.defaultSource}
                                uniqueId={this.props.uniqueId}
                            />
                        )}
                    {this.props.isArticleListLoaded &&
                        this.props.sourceName &&
                        this.props.sourceName.length > 0 &&
                        this.props.contentLanguage === 'Portuguese' && (
                            <ActiveFilterItemsGroup
                                name={POSTFILTER_COMPONENTS.SOURCE_NAME.label}
                                values={this.props.sourceName}
                                type={POSTFILTER_COMPONENTS.SOURCE_NAME.type}
                                resetFilter={this.props.resetFilter}
                                showResetButton={this.props.defaultSourceName}
                                uniqueId={this.props.uniqueId}
                            />
                        )}
                    {this.props.isArticleListLoaded && this.props.sourceType && this.props.sourceType.length > 0 && (
                        <ActiveFilterItemsGroup
                            name={POSTFILTER_COMPONENTS.SOURCE_TYPE.label}
                            values={this.props.sourceType}
                            type={POSTFILTER_COMPONENTS.SOURCE_TYPE.type}
                            resetFilter={this.props.resetFilter}
                            showResetButton={this.props.defaultSourceType}
                            uniqueId={this.props.uniqueId}
                        />
                    )}
                    {this.props.isArticleListLoaded && this.props.dateRange && this.props.dateRange.length > 0 && (
                        <ActiveFilterItemsGroup
                            name={POSTFILTER_COMPONENTS.DATE_RANGE.label}
                            values={this.props.dateRange}
                            type={POSTFILTER_COMPONENTS.DATE_RANGE.type}
                            resetFilter={this.props.resetFilter}
                            showResetButton={showDateRangeResetButton}
                            uniqueId={this.props.uniqueId}
                        />
                    )}
                    {this.props.isArticleListLoaded && this.props.language && this.props.language.length > 0 && (
                        <ActiveFilterItemsGroup
                            name={POSTFILTER_COMPONENTS.LANGUAGE.label}
                            values={this.props.language}
                            type={POSTFILTER_COMPONENTS.LANGUAGE.type}
                            resetFilter={this.props.resetFilter}
                            showResetButton={this.props.defaultLanguage}
                            uniqueId={this.props.uniqueId}
                        />
                    )}
                    {this.props.isArticleListLoaded && this.props.proximity && this.props.proximity.length > 0 && (
                        <ActiveFilterItemsGroup
                            name={POSTFILTER_COMPONENTS.PROXIMITY_NEGATIVE_TERMS.label}
                            values={this.props.proximity}
                            type={POSTFILTER_COMPONENTS.PROXIMITY_NEGATIVE_TERMS.type}
                            resetFilter={this.props.resetFilter}
                            showResetButton={showProximityResetButton}
                            uniqueId={this.props.uniqueId}
                        />
                    )}
                    {this.props.isArticleListLoaded && this.props.geography && this.props.geography.length > 0 && (
                        <ActiveFilterItemsGroup
                            name={POSTFILTER_COMPONENTS.GEOGRAPHY.label}
                            values={this.props.geography}
                            type={POSTFILTER_COMPONENTS.GEOGRAPHY.type}
                            resetFilter={this.props.resetFilter}
                            showResetButton={this.props.defaultGeography}
                            uniqueId={this.props.uniqueId}
                        />
                    )}

                    {this.props.isArticleListLoaded && this.props.company && this.props.company.length > 0 && (
                        <ActiveFilterItemsGroup
                            name={POSTFILTER_COMPONENTS.COMPANY.label}
                            values={this.props.company}
                            type={POSTFILTER_COMPONENTS.COMPANY.type}
                            resetFilter={this.props.resetFilter}
                            showResetButton={this.props.defaultCompany}
                            uniqueId={this.props.uniqueId}
                        />
                    )}
                    {this.props.isArticleListLoaded &&
                        this.props.companyMentions &&
                        this.props.companyMentions.length > 0 && (
                            <ActiveFilterItemsGroup
                                name={POSTFILTER_COMPONENTS.COMPANY_MENTIONS.label}
                                values={this.props.companyMentions}
                                type={POSTFILTER_COMPONENTS.COMPANY_MENTIONS.type}
                                resetFilter={this.props.resetFilter}
                                showResetButton={showCompanyMentionsResetButton}
                                uniqueId={this.props.uniqueId}
                            />
                        )}

                    {this.props.isArticleListLoaded &&
                        this.props.personMentions &&
                        this.props.personMentions.length > 0 && (
                            <ActiveFilterItemsGroup
                                name={POSTFILTER_COMPONENTS.PERSON_MENTIONS.label}
                                values={this.props.personMentions}
                                type={POSTFILTER_COMPONENTS.PERSON_MENTIONS.type}
                                resetFilter={this.props.resetFilter}
                                showResetButton={showPersonMentionsResetButton}
                                uniqueId={this.props.uniqueId}
                            />
                        )}

                    {this.props.isArticleListLoaded &&
                        this.props.docketStatus &&
                        this.props.docketStatus.length > 0 && (
                            <ActiveFilterItemsGroup
                                name={POSTFILTER_COMPONENTS.DOCKET_STATUS.label}
                                values={this.props.docketStatus}
                                type={POSTFILTER_COMPONENTS.DOCKET_STATUS.type}
                                resetFilter={this.props.resetFilter}
                                showResetButton={showDocketStatusResetButton}
                                uniqueId={this.props.uniqueId}
                            />
                        )}

                    {this.props.isArticleListLoaded &&
                        this.props.dunsFilter &&
                        this.props.dunsFilter[0] &&
                        this.props.dunsFilter[0].length > 0 && (
                            <ActiveFilterItemsGroup
                                name={'PostFilters.Title.DunsFilterLabel'}
                                values={this.props.dunsFilter}
                                type={POSTFILTER_COMPONENTS.DUNS_FILTER.type}
                                resetFilter={this.props.resetFilter}
                                showResetButton
                                uniqueId={this.props.uniqueId}
                            />
                        )}

                    {this.props.isArticleListLoaded && this.props.subject && this.props.subject.length > 0 && (
                        <ActiveFilterItemsGroup
                            name={POSTFILTER_COMPONENTS.SUBJECT.label}
                            values={this.props.subject}
                            type={POSTFILTER_COMPONENTS.SUBJECT.type}
                            resetFilter={this.props.resetFilter}
                            showResetButton={this.props.defaultSubject}
                            uniqueId={this.props.uniqueId}
                        />
                    )}

                    {this.props.isArticleListLoaded && this.props.industry && this.props.industry.length > 0 && (
                        <ActiveFilterItemsGroup
                            name={POSTFILTER_COMPONENTS.INDUSTRY.label}
                            values={this.props.industry}
                            type={POSTFILTER_COMPONENTS.INDUSTRY.type}
                            resetFilter={this.props.resetFilter}
                            showResetButton={this.props.defaultIndustry}
                            uniqueId={this.props.uniqueId}
                        />
                    )}

                    {this.props.isArticleListLoaded &&
                        this.props.searchType === PERSON_SEARCH &&
                        this.props.newFuzzyEnabled &&
                        ((this.props.fuzzySearch && this.props.fuzzySearch.length > 0) ||
                            this.props.fuzzyThresholdPersonLock) && (
                            <ActiveFilterItemsGroup
                                name={POSTFILTER_COMPONENTS.FUZZY_SEARCH.label}
                                values={this.getFuzzySearchLabel(this.props.fuzzySearch)}
                                type={POSTFILTER_COMPONENTS.FUZZY_SEARCH.type}
                                resetFilter={this.props.resetFilter}
                                showResetButton={false}
                                uniqueId={this.props.uniqueId}
                            />
                        )}
                    {this.props.isArticleListLoaded &&
                        categoryUtils.isChildOf(CATEGORY_NAMES.NEGATIVE_NEWS, this.props.articleType) && (
                            <ActiveFilterItemsGroup
                                name={POSTFILTER_COMPONENTS.NEGATIVITY_LEVELS.label}
                                values={this.props.negativityLevels}
                                type={POSTFILTER_COMPONENTS.NEGATIVITY_LEVELS.type}
                                showResetButton={showNegativityLevelsResetButton}
                                resetFilter={this.props.resetFilter}
                                uniqueId={this.props.uniqueId}
                            />
                        )}
                    {this.props.terms &&
                        this.props.terms.length > 0 &&
                        this.props.terms.map((term, index) => {
                            return (
                                <List.Item key={`${term}${index}`}>
                                    <ActiveFilterItem
                                        key={term}
                                        filter={term}
                                        name={
                                            formatRichMessage({ id: `PostFilters.Title.Term` }, this.props.intl) + ': '
                                        }
                                        label={TERMS}
                                        removeFilter={this.props.removeFilter}
                                    />
                                </List.Item>
                            );
                        })}

                    {this.props.isArticleListLoaded && this.props.esgFactors && this.props.esgFactors.length > 0 && (
                        <ActiveFilterItemsGroup
                            name={POSTFILTER_COMPONENTS.ESG_FACTORS.label}
                            values={this.props.esgFactors}
                            type={POSTFILTER_COMPONENTS.ESG_FACTORS.type}
                            resetFilter={this.props.resetFilter}
                            showResetButton={this.props.defaultEsgFactors}
                            uniqueId={this.props.uniqueId}
                        />
                    )}

                    {(this.props.includeTerms.length > 0 ||
                        this.props.excludeTerms.length > 0 ||
                        showDateRangeResetButton ||
                        showProximityResetButton ||
                        showCompanyMentionsResetButton ||
                        showPersonMentionsResetButton ||
                        showDocketStatusResetButton ||
                        showNegativityLevelsResetButton ||
                        this.props.defaultDatesValues ||
                        this.props.defaultSimilarNames ||
                        this.props.defaultSuggestedNames ||
                        this.props.defaultLanguage ||
                        this.props.defaultSource ||
                        this.props.defaultSourceName ||
                        this.props.defaultSourceType ||
                        this.props.defaultCompany ||
                        (this.props.dunsFilter && this.props.dunsFilter[0] && this.props.dunsFilter[0].length) ||
                        this.props.defaultSubject ||
                        this.props.defaultIndustry ||
                        this.props.defaultGeography ||
                        this.props.defaultEsgFactors ||
                        (this.props.terms && this.props.terms.length > 0)) && (
                        <button
                            className="clear-filters-button"
                            value={REMOVE_ALL_INCLUDE_TERMS}
                            onClick={this.props.removeFilter}
                        >
                            <FormattedMessage id="General.label.reset" />
                        </button>
                    )}
                </div>
            </div>
        );
    }
}

export default injectIntl(ActiveFilterList);
