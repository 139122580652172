import React, { Component } from 'react';
import PropTypes from 'prop-types';
import InformationIcon from '@reusable/InformationIcon/InformationIcon';
import { FormattedMessage } from 'react-intl';
import { CATEGORY_NAMES, LEGAL_SOURCES_ALL_OF_THEM, LEGAL_SOURCES_TITLES } from '@constants';

export default class SourcesInformationIcon extends Component {
    state = {
        modalContent: null,
    };

    static propTypes = {
        sourceKey: PropTypes.string,
        isIconSpinning: PropTypes.bool,
        sourcesInformation: PropTypes.object,
    };

    static defaultProps = {
        sourceKey: '',
        isIconSpinning: false,
        sourcesInformation: {},
    };

    modalTitle = '';

    getModalContent = (sourceKey) => {
        let legalSourcesKeys = Object.keys(LEGAL_SOURCES_TITLES),
            isLegalSourceKey = legalSourcesKeys.indexOf(sourceKey) !== -1 || sourceKey === LEGAL_SOURCES_ALL_OF_THEM,
            shouldDisplayAllLegalSources =
                sourceKey === LEGAL_SOURCES_ALL_OF_THEM || sourceKey === CATEGORY_NAMES.LAW_SOURCES,
            titleKey = isLegalSourceKey ? 'Preferences_Source.lawSources' : 'Preferences_Source.' + sourceKey,
            modalContent = [];

        if (shouldDisplayAllLegalSources) {
            legalSourcesKeys.forEach((sourceKey) => {
                if (sourceKey !== 'all') {
                    modalContent.push(this.getSourcesContent(sourceKey, true));
                }
            });
        } else {
            modalContent = this.getSourcesContent(sourceKey, isLegalSourceKey);
        }

        this.modalTitle = [
            <FormattedMessage id={titleKey} key="1" />,
            ' - ',
            <FormattedMessage id="UserPreferences.InformationModal.SourceList" key="2" />,
        ];

        this.setState({ modalContent });
    };

    getSourcesContent = (sourceKey, isLegalSourceKey) => {
        let sourcesInformation = this.props.sourcesInformation,
            sourcesHeader =
                isLegalSourceKey &&
                sourcesInformation &&
                sourcesInformation[sourceKey] &&
                sourcesInformation[sourceKey].length ? (
                    <div key={sourceKey} className="information-content-header">
                        <FormattedMessage id={'Preferences_Source.' + sourceKey} />
                    </div>
                ) : (
                    ''
                ),
            sourcesItems =
                sourcesInformation && sourcesInformation[sourceKey]
                    ? sourcesInformation[sourceKey].map((item, index) => (
                          <div className="information-item" key={index}>
                              {item}
                          </div>
                      ))
                    : 'No sources available',
            sourcesContent = sourcesHeader ? [sourcesHeader, sourcesItems] : sourcesItems;

        return sourcesContent;
    };

    render() {
        return (
            <InformationIcon
                sourceKey={this.props.sourceKey}
                isIconSpinning={this.props.isIconSpinning}
                content={this.state.modalContent}
                title={this.modalTitle}
                onClickHandler={this.getModalContent}
            />
        );
    }
}
