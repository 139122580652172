import React, { Fragment } from 'react';
import * as DilProps from '@utils/props';
import utils from '@utils/utilities';
import { differenceBy } from 'lodash';
import { DefaultCheckbox } from '../components/defaults';
import SelectedItemsDropdown, { DEFAULT_MESSAGES } from '../components/SelectedItemsDropdown';
import { REPORT_TYPE } from '@constants';

/**
 * Selection HOC for table components. Adds "selected" property to row, adds handlers for toggling select
 * Use with SelectableRows component or similar to show the checkbox for the rows
 * @param TableComponent
 * @returns {function(*)}
 */

const SELECTION_DEFAULT_CONFIG = {
    dropdownMessages: DEFAULT_MESSAGES,
};

const withSelection = (TableComponent, config = {}) => {
    // extending config
    config = {
        ...SELECTION_DEFAULT_CONFIG,
        ...config,
    };
    const withSelection = (props) => {
        const isRowSelected = (row) => !!props.selection.selectedItems.find((item) => item.key === row.key);
        const byKey = (item) => item.key;

        const handleToggleSelect = (row) => {
            let selectedItems = props.selection.selectedItems;
            if (isRowSelected(row)) {
                selectedItems = selectedItems.filter((item) => item.key !== row.key);
            } else {
                selectedItems.push(row);
            }
            props.selection.onChangeSelection(selectedItems);
        };

        const handleSelectAll = () => {
            let selectedItems = [];
            // this will prevent Batch reports from being selected when using the Select all option
            let customSelection = [];
            if (props.data.rows[0].deliveryType) {
                customSelection = [...props.data.rows.filter((report) => report.deliveryType !== REPORT_TYPE.BATCH)];
            } else {
                customSelection = [...props.data.rows];
            }
            // adding or substracting items from the selectedItems list depending on what is selected
            if (differenceBy(customSelection, props.selection.selectedItems, byKey).length > 0) {
                selectedItems = [
                    ...props.selection.selectedItems,
                    ...differenceBy(customSelection, props.selection.selectedItems, byKey),
                ];
            } else {
                selectedItems = differenceBy(props.selection.selectedItems, customSelection, byKey);
            }

            props.selection.onChangeSelection(selectedItems);
        };

        const handleRemoveItem = (item) => {
            handleToggleSelect(item.row);
        };

        const handleClearAll = () => {
            props.selection.onChangeSelection([]);
        };

        // Adding selected property to the row, the Selectable Row component should handle it
        const rows =
            props.data.rows &&
            props.data.rows.map((row) => ({
                ...row,
                selected: isRowSelected(row),
            }));

        const data = {
            ...props.data,
            rows,
        };

        const nonBatchReports = props.data.rows.filter((report) => report.deliveryType !== REPORT_TYPE.BATCH);
        const isAllSelected =
            props.data.rows &&
            props.data.rows.length &&
            (props.data.rows[0].deliveryType
                ? nonBatchReports.length &&
                  differenceBy(nonBatchReports, props.selection.selectedItems, byKey).length === 0
                : differenceBy(props.data.rows, props.selection.selectedItems, byKey).length === 0);

        // transform selected item to a plain list item (with label/value properties)
        const selectedItemsList = props.selection.selectedItems.map((item) => ({
            value: item.key,
            label: props.selection.onGetSelectedName(item),
            row: item,
        }));

        return (
            <Fragment>
                <SelectionHeader
                    onRemoveItem={handleRemoveItem}
                    onSelectAll={handleSelectAll}
                    onClearAll={handleClearAll}
                    selectedItemsList={selectedItemsList}
                    isAllSelected={isAllSelected}
                    actions={props.selection.actions}
                    dropdownMessages={props.dropdownMessages || config.dropdownMessages}
                    thereAreReports={props?.reports?.length || props?.data?.rows.length}
                />
                <TableComponent
                    {...props}
                    data={data}
                    onToggleSelect={handleToggleSelect}
                    className={'withSelection ' + (props.className ? props.className : '')}
                />
            </Fragment>
        );
    };

    withSelection.propTypes = {
        selection: DilProps.TableSelection,
        data: DilProps.TableData,
    };

    withSelection.displayName = `WithSelection(${utils.getDisplayName(TableComponent)})`;

    return withSelection;
};

const SelectionHeader = ({
    onSelectAll,
    selectedItemsList,
    onRemoveItem,
    onClearAll,
    isAllSelected,
    actions,
    dropdownMessages,
    thereAreReports,
}) => (
    <div className={'selection-header'}>
        <DefaultCheckbox
            onToggleSelect={onSelectAll}
            checked={isAllSelected}
            id={'selectAll'}
            value={'selectAll'}
            disabled={!thereAreReports}
        />
        <SelectedItemsDropdown
            selectedItems={selectedItemsList}
            onRemoveItem={onRemoveItem}
            onClearAll={onClearAll}
            messages={dropdownMessages}
        />
        <div className={'table-actions'}>{actions}</div>
    </div>
);

export default withSelection;
