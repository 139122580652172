import React from 'react';
import TruncatedText from '@reusable/TruncatedElements/TruncatedText';
import utils from '@utils/utilities';

const TruncatedEmails = (props) => {
    // tooltipId should be sent through props only for snapshot tests
    const tooltipId = props.tooltipId || generateRandomId();

    return (
        <TruncatedText
            tooltipId={`truncated-email-${tooltipId}`}
            hasChild={false}
            tooltipHtml={true}
            checkTruncation={checkTruncation}
            TruncatedComponent={RecipientsList}
            {...props}
        />
    );
};

const RecipientsList = (props) => {
    return (
        <div data-for={props.tooltipId} data-tip={props.isTruncated ? props.emails.join(',<br /> ') : null}>
            {props.isTruncated ? truncateRecipientsList(props.emails).join(', ') : props.emails.join(', ')}
        </div>
    );
};

const generateRandomId = () => {
    const randLetter = String.fromCharCode(65 + Math.floor(Math.random() * 26));
    return `${randLetter}${Date.now()}`;
};

const checkTruncation = () => window.innerWidth < 1024;

const truncateRecipientsList = (emails) => emails.map((email) => utils.limitText(email, 15));

export default TruncatedEmails;
export { RecipientsList };
