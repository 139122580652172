import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import categoryUtils, { withCustomNewsTranslation } from '@utils/categoryUtils';
import utils from '@utils/utilities';
import { Link } from 'react-router';
import { CATEGORY_NAMES, COMPANY_SEARCH, NEGATIVE_NEWS_LANGUAGES, POST_FILTER_DUNS_FILTER } from '@constants';
import { replaceSelectedCompaniesForHistory } from '@sagas/helpers/uboHelper';

export const HistorySources = ({ data }) => {
    function formatCategoryNames(postFilterData) {
        const { categoryName, searchQueryType, customNewsTitle } = postFilterData;
        const category = categoryUtils.postfilterToCategory(postFilterData);
        let categoryTitle = categoryName ? (
            categoryName
        ) : category ? (
            <FormattedMessage
                defaultMessage={withCustomNewsTranslation(category, searchQueryType)}
                id={'BREADCRUMBS.snapshot.' + categoryUtils.getCategoryKeyForTranslation(category)}
            />
        ) : (
            '-'
        );

        if (category === CATEGORY_NAMES.CUSTOM_NEWS && customNewsTitle) {
            categoryTitle = customNewsTitle;
        }

        return categoryTitle;
    }

    return formatCategoryNames(data);
};

export const HistoryDates = ({ data }) => {
    function formatDates({ createdDate, expiration }) {
        return (
            <Fragment>
                <div>{createdDate ? utils.formatDateWithHoursAndMinutes(createdDate) : '-'}</div>
                <div>
                    {expiration} <FormattedMessage id="History.table.row.dayLeft" />
                </div>
            </Fragment>
        );
    }

    return formatDates(data);
};

export const HistorySearchTerms = ({ data, onSearchFromHistory, contentTypes }) => {
    let { searchTermLabel, id } = data;
    function formatSearchTermLink(searchTerm) {
        return (
            <button className="link-button" onClick={() => onSearchFromHistory(id)}>
                {searchTerm ? searchTerm : '-'}
            </button>
        );
    }

    function formatSearchTermText(searchTerm) {
        return <div>{searchTerm ? searchTerm : '-'}</div>;
    }

    if (
        categoryUtils.isDnbCategory(data.category) &&
        data[POST_FILTER_DUNS_FILTER] &&
        data[POST_FILTER_DUNS_FILTER].length
    ) {
        // removing the ( )
        searchTermLabel = searchTermLabel.slice(1, searchTermLabel.length - 1);
        searchTermLabel = replaceSelectedCompaniesForHistory(searchTermLabel, [
            {
                title: data.term,
                duns: data[POST_FILTER_DUNS_FILTER],
            },
        ]);
        // adding back the ( )
        searchTermLabel = `(${searchTermLabel})`;
    }

    const contentType = contentTypes.find((item) => item.contentType === data.category);
    return (contentType && contentType.enabled && data.contentLanguage !== NEGATIVE_NEWS_LANGUAGES['multiple'].value) ||
        (utils.isUboEnabled() && categoryUtils.isDnbCategory(data.category))
        ? formatSearchTermLink(searchTermLabel)
        : formatSearchTermText(searchTermLabel);
};

export const PRHistorySearchTerms = ({ data }) => {
    let { searchTermLabel, link } = data;

    return (
        <Link className="history-table-link-button" to={'/public-records?param=' + btoa(link)}>
            {searchTermLabel ? searchTermLabel : '-'}
        </Link>
    );
};

export const HistoryCheckAndType = ({ data }) => {
    let { searchQuery, searchQueryType } = data;

    return (
        <Fragment>
            <div className="history-search-query" data-testid="history-search-query">
                {searchQuery}
            </div>
            {searchQueryType ? (
                <div className="history-search-query-type" data-testid="history-search-query-type">
                    {searchQueryType === COMPANY_SEARCH ? (
                        <FormattedMessage id="General.label.company" />
                    ) : (
                        <FormattedMessage id="General.label.person" />
                    )}
                </div>
            ) : null}
        </Fragment>
    );
};
