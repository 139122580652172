import { all, spawn } from 'redux-saga/effects';
import * as listeners from './listeners/metricListeners';

/**
 * Main saga for metric
 * Listening for:
 *  - search started
 *  - search ended for an item
 *  - gathering metrics
 */

export default function* metricSaga() {
    yield all([
        spawn(listeners.watchSearchStarted),
        spawn(listeners.watchSearchEnd),
        spawn(listeners.watchGatherMetrics),
    ]);
}
