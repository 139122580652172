export const SET_PROCESSING_BACKGROUND_MESSAGES = 'SET_PROCESSING_BACKGROUND_MESSAGES';
export const SET_FAILURE_BACKGROUND_MESSAGES = 'SET_FAILURE_BACKGROUND_MESSAGES';
export const SET_SUCCESS_BACKGROUND_MESSAGES = 'SET_SUCCESS_BACKGROUND_MESSAGES';
export const CLEAR_BACKGROUND_MESSAGES = 'CLEAR_BACKGROUND_MESSAGES';
export const SET_BACKGROUND_MESSAGES = 'SET_BACKGROUND_MESSAGES';

let actions = {
    setBackgroundMessages: function (messages) {
        return {
            type: SET_BACKGROUND_MESSAGES,
            payload: messages,
        };
    },

    setProcessingBackgroundMessages: function (messages) {
        return {
            type: SET_PROCESSING_BACKGROUND_MESSAGES,
            payload: messages,
        };
    },

    setSuccessBackgroundMessages: function (messages) {
        return {
            type: SET_SUCCESS_BACKGROUND_MESSAGES,
            payload: messages,
        };
    },

    setFailureBackgroundMessages: function (messages) {
        return {
            type: SET_FAILURE_BACKGROUND_MESSAGES,
            payload: messages,
        };
    },

    clearBackgroundMessages: function () {
        return {
            type: CLEAR_BACKGROUND_MESSAGES,
        };
    },
};

export default actions;
