export const TOGGLE_SEARCH = 'TOGGLE_SEARCH';
export const TOGGLE_DOCUMENTS_LOADING_STATUS = 'TOGGLE_DOCUMENTS_LOADING_STATUS';
export const SET_IS_SAVING_SEARCH = 'SET_IS_SAVING_SEARCH';
export const SET_ADD_TO_ENTITY_VIEW = 'SET_ADD_TO_ENTITY_VIEW';
export const SHOW_SAVE_SEARCH_MODAL = 'SHOW_SAVE_SEARCH_MODAL';
export const SET_MULTIPLE_ENTITIES_PAGE_SPINNER = 'SET_MULTIPLE_ENTITIES_PAGE_SPINNER';
export const SET_SEARCH_POSTFILTERS_CHANGED = 'SET_SEARCH_POSTFILTERS_CHANGED';

export const toggleStatus = function (status) {
    return function (dispatch, getState) {
        const isSearchComplete = getState().searchStatus.isSearchComplete;
        if (isSearchComplete !== status) {
            dispatch({
                type: TOGGLE_SEARCH,
                payload: status,
            });
        }
    };
};

export const searchStatusActions = {
    toggleDocumentsLoadingStatus: (status) => ({
        type: TOGGLE_DOCUMENTS_LOADING_STATUS,
        payload: status,
    }),

    setIsSavingSearch: (isSavingSearch) => ({
        type: SET_IS_SAVING_SEARCH,
        payload: isSavingSearch,
    }),

    setIsAddingToEntityView: (isAddingToEntityView) => ({
        type: SET_ADD_TO_ENTITY_VIEW,
        payload: isAddingToEntityView,
    }),

    showSaveSearchModal: (showSaveSearchModal) => ({
        type: SHOW_SAVE_SEARCH_MODAL,
        payload: showSaveSearchModal,
    }),

    setMultipleEntitiesPageSpinner: (payload) => {
        return {
            type: SET_MULTIPLE_ENTITIES_PAGE_SPINNER,
            payload,
        };
    },

    setPostFiltersChanged: (payload) => {
        return {
            type: SET_SEARCH_POSTFILTERS_CHANGED,
            payload,
        };
    },
};
