import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { COST_CODE_MAX_CHARS } from '@constants';
import formatRichMessage from '@utils/formatRichMessage';
import { compose } from 'redux';
import { connect } from 'react-redux';

class CustomCostCodeInputBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputText: '',
            inputValid: true,
            inputFocused: false,
            charsLeft: COST_CODE_MAX_CHARS,
            hasError: false,
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.costCodeInputValidation = this.costCodeInputValidation.bind(this);
        this.handleOnFocus = this.handleOnFocus.bind(this);
    }

    handleInputChange(e) {
        const inputText = e.target.value.substring(0, COST_CODE_MAX_CHARS);
        const charsLeft = COST_CODE_MAX_CHARS - inputText.length;

        this.setState({
            inputText,
            charsLeft,
            hasError: charsLeft === 0,
        });
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.costCodeInputValidation();
        }
    }

    handleOnFocus(inputFocused) {
        this.setState({ inputFocused });
    }

    costCodeInputValidation() {
        const inputText = this.state.inputText;

        this.setState(
            {
                inputValid: inputText.length > 0 && inputText.length <= COST_CODE_MAX_CHARS,
                inputText: '',
                charsLeft: COST_CODE_MAX_CHARS,
            },
            () => {
                if (this.state.inputValid) {
                    this.props.changeCostCode(inputText);

                    this.setState({
                        hasError: false,
                    });
                }
            }
        );
    }

    render() {
        return (
            <div className="custom-cost-code-inputBox">
                <div className="user-specified-cost-code-warnings">
                    <div className="validationMessage">
                        {this.state.hasError && (
                            <FormattedMessage id={'UserPreferences_cost_code.custom.validationMessage'} />
                        )}
                    </div>

                    {!this.props.isHomepageModal && (
                        <div className="max-number-of-characters">
                            <FormattedMessage
                                id="General_CoreFunctionality_UIText_general.charactersRemaining"
                                values={{ count: this.state.charsLeft }}
                            />
                        </div>
                    )}
                </div>
                <div className="user-specified-cost-input-container">
                    <input
                        id="text-field-lg"
                        type="text"
                        className={`text-field-standard-lg ${this.state.hasError ? 'text-field-error' : ''}`}
                        placeholder={
                            this.props.isCostCodeUserDefined
                                ? this.props.costCode
                                : formatRichMessage(
                                      { id: 'UserPreferences_CostCode.customCodesInputBox.placeholder' },
                                      this.props.intl
                                  )
                        }
                        onChange={(e) => {
                            this.handleInputChange(e);
                        }}
                        onKeyPress={(e) => {
                            this.handleKeyPress(e);
                        }}
                        onFocus={() => this.handleOnFocus(true)}
                        onBlur={() => this.handleOnFocus(false)}
                        value={this.props.isCostCodeUserDefined ? '' : this.state.inputText}
                        disabled={this.props.isCostCodeUserDefined}
                    />
                    <button
                        className="button-primary-sm"
                        disabled={this.state.inputText.length === 0}
                        onClick={() => {
                            this.costCodeInputValidation();
                        }}
                    >
                        <FormattedMessage id={'UserPreferences_cost_code.button.use'} />
                    </button>
                </div>
                {this.props.isHomepageModal && this.state.inputFocused && (
                    <div className="max-number-of-characters" data-track={'max-number-of-characters'}>
                        <FormattedMessage
                            id="General_CoreFunctionality_UIText_general.charactersRemaining"
                            values={{ count: this.state.charsLeft }}
                        />
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = function (state) {
    return {
        user: state.user,
    };
};

export { CustomCostCodeInputBox as TestCustomCostCodeInputBox };

export default compose(connect(mapStateToProps), injectIntl)(CustomCostCodeInputBox);
