import React from 'react';
import ActionButtonPopover from './components/ActionButtonPopover';
import RegularActionButton from './components/RegularActionButton';

const ActionButton = ({
    disabled,
    handleClick,
    id,
    testid = '',
    className,
    dataTip,
    dataFor = '',
    popover,
    shouldDisplayPopover,
    callback,
}) => {
    return (
        <span data-for={dataFor} data-tip={dataTip}>
            {shouldDisplayPopover ? (
                <ActionButtonPopover
                    id={id}
                    testid={testid}
                    classNames={className}
                    popover={popover}
                    disabled={disabled}
                    onClick={handleClick}
                    callback={callback}
                />
            ) : (
                <RegularActionButton
                    id={id}
                    testid={testid}
                    handleClick={handleClick}
                    className={className}
                    popover={popover}
                    disabled={disabled}
                />
            )}
        </span>
    );
};

export default ActionButton;